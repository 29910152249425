import React from "react";

import styles from "./styles.scss";

export interface ErrorMessageProps {
  "data-test-id"?: string;
  message: string;
}
/**
 * Simple wrapper for an error message for a form
 */
export const FormErrorMessage = ({
  "data-test-id": dataTestId,
  message,
}: ErrorMessageProps) => (
  <span data-test-id={dataTestId} className={styles.error}>
    {message}
  </span>
);
