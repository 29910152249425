import { RootState } from "src/domains/Beacon/store";

export const selectTwilioState = (state: RootState) => state.twilio;

export const selectLocalParticipant = (state: RootState) =>
  state.twilio.room?.localParticipant;

export const selectHasRemoteJoined = (state: RootState) =>
  state.twilio.remoteHasJoinedRoom;

export const selectHasConsoleJoined = (state: RootState) =>
  state.twilio.consoleHasJoinedRoom;

export const selectRoomParticipants = (state: RootState) =>
  state.twilio.room?.participants;

export const selectParticipants = (state: RootState) =>
  state.twilio.participants;

export const selectDominantSpeaker = (state: RootState) =>
  state.twilio.dominantSpeaker;

// Refresh Frames
export const selectIsLoadingRefreshFrames = (state: RootState) =>
  state.twilio.loadingRefreshFrames;

export const selectDuplicateParticipantDisconnected = (state: RootState) =>
  state.twilio.duplicateParticipantDisconnected;
