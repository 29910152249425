import React from 'react';

import { Dropdown } from 'semantic-ui-react';
/* Styles */
import './Search.scss';

const Search = () => (
	<Dropdown
		clearable
		multiple
		search
		selection
		fluid
		placeholder="Search Users"
		className="dropdown--custom"
		icon={null}
		noResultsMessage={null}
	/>
);

Search.propTypes = {};

export default Search;
