// TODO: linter complaining! add no-shadow entry in tsconfig.json, or fix this

/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { LoginPage } from "src/domains/Login";
import {
  LoginMFAToken,
  SendPayloadProps,
} from "src/domains/Login/components/MFA/types";
import { useOnPageRefresh } from "src/hooks/useOnPageRefresh";
import { history } from "src/reducer";
import { clearUserSession } from "src/utils/clearUserSession";

import { sendLoginPasscodeAction } from "avail-web-application/Features/Authentication/AuthenticationActions";
import withAuthentication from "avail-web-application/Features/Authentication/Components/withAuthentication";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

import styles from "./styles.scss";

interface Props {
  sendLoginPasscodeAction: ({ mfa_token, mfa_type }: SendPayloadProps) => void;
  loginMfaToken: LoginMFAToken;
}

const SelectChannel = ({ sendLoginPasscodeAction, loginMfaToken }: Props) => {
  useOnPageRefresh(() => clearUserSession());

  const { handleSubmit } = useForm();
  const [channelType, setChannelType] = useState("");

  useEffect(() => {
    // checks for empty session storage
    if (sessionStorage.getItem("mfaAuthInfo") === null) {
      history.push("/login");
    }
    if (!!loginMfaToken) {
      setChannelType(loginMfaToken?.previousSelectedMfaType);
    }
  }, [loginMfaToken]);

  const onSubmit = () => {
    sendLoginPasscodeAction({
      mfa_token: loginMfaToken?.mfaToken,
      mfa_type: channelType,
    });
  };

  const handleChannelType = (event: any): void => {
    setChannelType(event.target.value);
  };

  return (
    <LoginPage className={styles.mfaChannelType}>
      <div className={styles.mfaChannelHeading}>
        {DomainConstants.label.mfaRequired}
      </div>

      <div className={styles.mfaChannelWidth}>
        <form
          className={styles.mfaChannelForm}
          onSubmit={handleSubmit(onSubmit)}
        >
          <span>{DomainConstants.label.mfaAuthMethod}</span>
          <RadioGroup value={channelType} onChange={handleChannelType}>
            <FormControlLabel
              value={DomainConstants.label.mfaEmail}
              control={<Radio color="primary" />}
              label={`${DomainConstants.label.email} ${loginMfaToken?.email}`}
            />
            <FormControlLabel
              value={DomainConstants.label.mfaSms}
              control={<Radio color="primary" />}
              label={`${DomainConstants.label.mfaSendSMSTo} ${loginMfaToken?.phone}`}
            />
            <FormControlLabel
              value={DomainConstants.label.mfaCall}
              control={<Radio color="primary" />}
              label={`${DomainConstants.label.mfaCallTo} ${loginMfaToken?.phone}`}
            />
          </RadioGroup>
          {/* TODO: move styles later */}
          <Button
            disableRipple
            style={{
              width: "100%",
              backgroundColor: "#7EBB45",
              borderRadius: "0.3rem",
              marginTop: "1.5rem",
            }}
            variant="contained"
            type="submit"
          >
            {DomainConstants.label.mfaSendPasscode}
          </Button>
        </form>
      </div>
    </LoginPage>
  );
};

const mapStateToProps = (state: {
  homeReducer: { loginMfaToken: LoginMFAToken };
}) => ({
  loginMfaToken: state.homeReducer.loginMfaToken,
});

const mapDispatchToProps = {
  sendLoginPasscodeAction,
};

export default withAuthentication(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectChannel))
);
