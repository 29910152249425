import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { LoginPage } from "src/domains/Login";
import { useOnPageRefresh } from "src/hooks/useOnPageRefresh";
import { history } from "src/reducer";
import { clearUserSession } from "src/utils/clearUserSession";

import { resendLoginPasscodeAction } from "avail-web-application/Features/Authentication/AuthenticationActions";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

import withAuthentication from "../../../../../../avail-web-application/src/Features/Authentication/Components/withAuthentication";

const UserSuspendedView = ({
  // TODO: remove these linter comments later
  // eslint-disable-next-line @typescript-eslint/no-shadow
  resendLoginPasscodeAction,
  errorMessage,
  loginMfaToken,
}) => {
  useOnPageRefresh(() => clearUserSession());

  useEffect(() => {
    // checks for empty session storage
    if (sessionStorage.getItem("mfaAuthInfo") === null) {
      history.push("/login");
    }
  }, []);

  const handleUserSuspension = () => {
    resendLoginPasscodeAction({
      mfaToken: loginMfaToken?.mfaToken,
    });
  };

  // TODO: move this to interceptor
  const getRemainingTime = (): string => {
    const array: string[] = errorMessage.split(" ");
    return array[array.length - 2];
  };

  return (
    <LoginPage className="login">
      <Grid container spacing={2} direction={"column"}>
        <Grid item>
          <Box m={2}>
            <Typography
              variant="h4"
              align={"center"}
              sx={{ fontWeight: "medium" }}
            >
              {DomainConstants.label.mfaUserSuspendedTitle}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box mb={2}>
            <Typography variant="body1" align={"center"}>
              {`Please retry after ${getRemainingTime()} minutes!`}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box m={2}>
            <Typography
              variant="h2"
              align={"center"}
              sx={{ fontWeight: "medium" }}
            >
              {`${getRemainingTime()} min`}
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box m={2}>
            {/* TODO: move styles later */}
            <Button
              fullWidth
              style={{
                backgroundColor: "#7EBB45",
              }}
              onClick={handleUserSuspension}
            >
              {DomainConstants.label.refreshTimer}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LoginPage>
  );
};

const mapStateToProps = (state) => ({
  errorMessage: state.authenticationReducer.errorMessage,
  loginMfaToken: state.homeReducer.loginMfaToken,
});

const mapDispatchToProps = {
  resendLoginPasscodeAction,
};

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(UserSuspendedView)
);
