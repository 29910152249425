import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API } from "../../../api";
import { logger } from "../../../common/logger";
import { AppState, MeetingStateType, UserState } from "../../models";
import { logActivity } from "../../twilio/actions";
import { UserActionKeys } from "../../user/actionTypes";

const MEETING_HEARTBEAT_PERIOD_SECS = 60;

export function* heartbeat() {
  while (true) {
    try {
      yield delay(MEETING_HEARTBEAT_PERIOD_SECS * 1000);

      const { joinId, callSid }: MeetingStateType = yield select(
        (state: AppState) => state.meeting
      );

      const { identity }: UserState = yield select(
        (state: AppState) => state.user
      );

      const { error } = yield call(
        API.POST.meetingHeartbeat,
        identity,
        callSid,
        joinId
      );
      if (error) {
        logger().error(`Heartbeat returned with an error: ${error}`);
      }
    } catch (error) {
      logger().error("Error sending meeting Heartbeat.", JSON.stringify(error));
      yield put(logActivity("Error sending meeting Heartbeat."));
    }
  }
}

export function* watchMeetingHeartbeat() {
  yield takeLatest(UserActionKeys.SET_HAS_JOINED_ROOM, heartbeat);
}
