import React, { PureComponent } from 'react';
import queryString from 'query-string';

import AccountSetupForm from './Components/AccountSetupForm/AccountSetupForm';
import './AccountSetup.scss';

import { LoginPage } from "/src/domains/Login";

class AccountSetup extends PureComponent {
	getToken = () => queryString.parse(this.props.location.search).token;

	render() {
		return (
			<LoginPage className="login">
        <div>
          <AccountSetupForm token={this.getToken()} />
        </div>
			</LoginPage>
		);
	}
}

AccountSetup.propTypes = {};

export default AccountSetup;
