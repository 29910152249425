import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import resources from '../../../../../../../Common/Constants/resources';
import { notificationService, urlService } from '../../../../../../../Common/Services';
import ApiHelper from '../../../../../../../Common/Services/ApiHelper';
import arrayService from '../../../../../../../Common/Services/arrayService';
import { DEPARTMENTS } from './DepartmentsActionTypes';

function* getDepartmentByHospital(action) {
	const payloadClone = { ...action.payload };
	delete payloadClone.hospitalId;
	const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.hospitalDepartments, action.payload.hospitalId), payloadClone);
	const filteredResponse = arrayService.sortQuery(response.content, payloadClone);
	yield put({
		type: DEPARTMENTS.SET_DEPARTMENTS_BY_HOSPITAL,
		payload: filteredResponse,
	});
}

function* getProceduresByDepartment(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.departmentProcedures, action.payload));
		yield put({
			type: DEPARTMENTS.SET_PROCEDURES_BY_DEPARTMENT,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updateDepartment(action) {
	try {
		yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.department, action.departmentId), action.departmentData);
		yield all([notificationService.success('You have successfully updated department')]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getDepartment(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.department, action.payload));
		yield put({
			type: DEPARTMENTS.SET_DEPARTMENT,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* deleteDepartment(action) {
	try {
		const response = yield call(ApiHelper.remove, 'coreregistry', urlService.formatString(resources.department, action.payload));
		const currentDepartmentList = yield select(state => state.departmentReducer.departments);
		const newDepartmentList = currentDepartmentList.filter(department => department.id !== response.content.id);

		yield all([put({
			type: DEPARTMENTS.SET_DEPARTMENTS_BY_HOSPITAL,
			payload: newDepartmentList,
		}), notificationService.success('You successfully deleted department')]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getRoomsByDepartment(action) {
	const { hospitalId, ...payloadClone} = action.payload 
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.departmentRooms, action.payload.departmentId), payloadClone);
    const filteredResponse = arrayService.sortQuery(response.content, payloadClone);
		yield put({
			type: DEPARTMENTS.SET_ROOMS_BY_DEPARTMENT,
			payload: filteredResponse,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createRoom(action) {
	try {
		yield call(ApiHelper.post, 'coreregistry', urlService.formatString(resources.departmentRooms, action.id), action.roomData);
		yield all([
      notificationService.success('You successfully created room'), 
      getRoomsByDepartment({
        payload: {departmentId: action.id },
      })
    ]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* deleteRoom(action) {
	try {
		const response = yield call(ApiHelper.remove, 'coreregistry', urlService.formatString(resources.room, action.payload));
		const currentDepartmentRoomsList = yield select(state => state.departmentReducer.departmentRooms);
		const newDepartmentRoomsList = currentDepartmentRoomsList.filter(room => room.id !== response.content.id);
		yield all([put({
			type: DEPARTMENTS.SET_ROOMS_BY_DEPARTMENT,
			payload: newDepartmentRoomsList,
		}), notificationService.success('You successfully deleted room')]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getAllTherapies() {
	const response = yield call(ApiHelper.get, 'coreregistry', resources.therapies);
	yield put({
		type: DEPARTMENTS.SET_ALL_THERAPIES,
		payload: response.content,
	});
}

function* createNewDepartment(action) {
	try {
		const departmentResponse = yield call(ApiHelper.post, 'coreregistry', urlService.formatString(resources.hospitalDepartments, action.hospitalId), action.departmentData);
		yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.assignTherapiesToDepartment, departmentResponse.content.id), action.therapies);
		yield all([notificationService.success('You successfully created department'), getDepartmentByHospital({
			payload: {
				hospitalId: action.hospitalId
			},
		})]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getRoom(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.room, action.payload));
		yield put({
			type: DEPARTMENTS.SET_ROOM,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getRoomProcedures(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.roomProcedures, action.payload));
		yield put({
			type: DEPARTMENTS.SET_ROOM_PROCEDURES,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getRoomTherapies(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', `rooms/${action.payload}/room-therapies`);
		yield put({
			type: DEPARTMENTS.SET_ROOM_THERAPIES,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updateRoomProcedures(action) {
	try {
		yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.roomProcedures, action.roomId), action.procedures);
		yield getRoomTherapies({ payload: action.roomId });
		yield notificationService.success('You have successfully updated room procedures');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getAllDepartmentsRoomIsAssociated(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.roomDepartments, action.payload));
		yield put({
			type: DEPARTMENTS.SET_ALL_DEPARTMENTS_ROOM_IS_ASSOCIATED,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updateRoom(action) {
	try {
		yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.room, action.roomId), action.roomData);
		yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.roomDepartments, action.roomId), action.departmentIds);
		yield getAllDepartmentsRoomIsAssociated({ payload: action.roomId });
		yield notificationService.success('You have successfully edited room');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getAllRoomTypes() {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', resources.roomTypes);
		yield put({
			type: DEPARTMENTS.SET_ALL_ROOM_TYPES,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updateDepartmentProcedures(action) {
	try {
		yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.departmentProcedures, action.departmentId), action.procedures);
		yield getProceduresByDepartment({ payload: action.departmentId });
		yield notificationService.success('You have successfully updated department procedures');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getAllDepartmentsTherapiesRoomIsAssociated(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', `rooms/${action.payload}/department-therapies`);
		yield put({
			type: DEPARTMENTS.SET_ALL_DEPARTMENTS_THERAPIES_ROOM_IS_ASSOCIATED,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* hospitalsSaga() {
	yield takeLatest(DEPARTMENTS.GET_DEPARTMENTS_BY_HOSPITAL, getDepartmentByHospital);
	yield takeLatest(DEPARTMENTS.DELETE_DEPARTMENT, deleteDepartment);
	yield takeLatest(DEPARTMENTS.GET_PROCEDURES_BY_DEPARTMENT, getProceduresByDepartment);
	yield takeLatest(DEPARTMENTS.UPDATE_DEPARTMENT, updateDepartment);
	yield takeLatest(DEPARTMENTS.GET_DEPARTMENT, getDepartment);
	yield takeLatest(DEPARTMENTS.GET_ROOMS_BY_DEPARTMENT, getRoomsByDepartment);
	yield takeLatest(DEPARTMENTS.CREATE_ROOM, createRoom);
	yield takeLatest(DEPARTMENTS.DELETE_ROOM, deleteRoom);
	yield takeLatest(DEPARTMENTS.GET_ALL_THERAPIES, getAllTherapies);
	yield takeLatest(DEPARTMENTS.CREATE_NEW_DEPARTMENT, createNewDepartment);
	yield takeLatest(DEPARTMENTS.GET_ROOM, getRoom);
	yield takeLatest(DEPARTMENTS.GET_ROOM_PROCEDURES, getRoomProcedures);
	yield takeLatest(DEPARTMENTS.UPDATE_ROOM, updateRoom);
	yield takeLatest(DEPARTMENTS.GET_ALL_ROOM_TYPES, getAllRoomTypes);
	yield takeLatest(DEPARTMENTS.UPDATE_DEPARTMENT_PROCEDURES, updateDepartmentProcedures);
	yield takeLatest(DEPARTMENTS.GET_ALL_DEPARTMENTS_ROOM_IS_ASSOCIATED, getAllDepartmentsRoomIsAssociated);
	yield takeLatest(DEPARTMENTS.GET_ALL_DEPARTMENTS_THERAPIES_ROOM_IS_ASSOCIATED, getAllDepartmentsTherapiesRoomIsAssociated);
	yield takeLatest(DEPARTMENTS.GET_ROOM_THERAPIES, getRoomTherapies);
	yield takeLatest(DEPARTMENTS.UPDATE_ROOM_PROCEDURES, updateRoomProcedures);
}
