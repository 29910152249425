import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import clsx from "clsx";

import { Snackbar, Slide } from "@mui/material";

import { Alert } from "src/components/Alert";
import { uiActions } from "src/domains/Beacon/store/ui";
import { selectNotifications } from "src/domains/Beacon/store/ui/selectors";
import {
  UINotification,
  UINotificationTypes,
} from "src/domains/Beacon/store/ui/types";
import { timeoutCallback } from "src/utils/timeout";

import styles from "./styles.scss";

// simple function to allow use to change the direction the notification slides from
function TransitionSlideLeft(props) {
  return <Slide {...props} direction="left" />;
}

const TOP_RIGHT_POSITION = { vertical: "top", horizontal: "right" };
const BOTTOM_RIGHT_POSITION = { vertical: "bottom", horizontal: "right" };

export const NotificationSystem = () => {
  const notifications = useSelector(selectNotifications);

  const [open, setOpen] = useState(false);
  const [activeNotification, setActiveNotification] = useState(undefined);

  const dispatch = useDispatch();

  // trying to do something smart where we only show 1 notification at a time, once its done then remove it from the list
  useEffect(() => {
    if (notifications.length && !activeNotification) {
      // Set a new notification when we don't have an active one
      setActiveNotification({ ...notifications[0] });
      dispatch(uiActions.setNotifications(notifications.slice(1)));

      setOpen(true);
    } else if (notifications.length && activeNotification && open) {
      // Close an active notification when a new one is added
      setOpen(false);
    }
  }, [notifications, activeNotification, open]);

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    // this if block prevents the notification from clearly if the user clicks anywhere outside of the notification box
    if (reason === "clickaway") {
      // we dont want to do anything in this case
      return;
    }
    setOpen(false);
    setActiveNotification(undefined);
  };

  // clear the active notification once its exited, with a slight delay to make sure it doesn't instantly disappear
  const handleExited = () => {
    timeoutCallback(() => setActiveNotification(undefined), 200);
  };

  const position =
    activeNotification && activeNotification.variant === "default"
      ? TOP_RIGHT_POSITION
      : BOTTOM_RIGHT_POSITION;

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      // TODO figure out the correct position for this, its overlapping the top bar currently
      // for some reason MUI doesn't like the position object but its not a big enough deal to figure out a fix
      // @ts-ignore
      anchorOrigin={position}
      classes={{
        root: clsx(styles.root, styles.notification, {
          [styles.default]: activeNotification?.variant === "default",
        }),
      }}
      TransitionProps={{ onExited: handleExited }}
      TransitionComponent={TransitionSlideLeft}
      onClose={handleClose}
    >
      <Alert
        title={activeNotification && activeNotification.title}
        subtitle={activeNotification && activeNotification.subtitle}
        type={activeNotification && activeNotification.type}
        variant={activeNotification && activeNotification.variant}
        onClose={handleClose}
      />
    </Snackbar>
  );
};

// Find the correct home for this but for now it lives here
// for specific types (besides success/error), we have hardcoded title/subtitles
// this function returns a full notification object with the correct values
export const generateNotification = (
  type: UINotificationTypes
): UINotification => {
  switch (type) {
    case UINotificationTypes.POOR_NETWORK_CONNECTION:
      return {
        title: "Your network connection is poor",
        subtitle: "Some features may not work properly",
        type: UINotificationTypes.POOR_NETWORK_CONNECTION,
        variant: "default",
      };
    case UINotificationTypes.LOCAL_MIC_MUTED:
      return {
        title: "Your mic is muted",
        subtitle: "Unmute for other participants to hear you",
        type: UINotificationTypes.LOCAL_MIC_MUTED,
        variant: "default",
      };
    case UINotificationTypes.CONSOLE_AUDIO_MUTED:
      return {
        title: "The console audio is muted",
        subtitle: "You also can find console audio status in settings",
        type: UINotificationTypes.CONSOLE_AUDIO_MUTED,
        variant: "default",
      };
    case UINotificationTypes.CONSOLE_AUDIO_UNMUTED:
      return {
        title: "The console audio is unmuted",
        subtitle: "You also can find console audio status in settings",
        type: UINotificationTypes.CONSOLE_AUDIO_UNMUTED,
        variant: "default",
      };
    case UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BUILT_IN_MIC:
      return {
        title: "Console is switching from Headset to built-in microphone",
        type: UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BUILT_IN_MIC,
        variant: "default",
      };
    case UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BLUETOOTH:
      return {
        title: "Console is switching from built-in microphone to Headset",
        type: UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BLUETOOTH,
        variant: "default",
      };
    case UINotificationTypes.CONSOLE_NOISE_REDUCTION_ON:
      return {
        title: "Console noise reduction is on",
        type: UINotificationTypes.CONSOLE_NOISE_REDUCTION_ON,
        variant: "default",
      };
    case UINotificationTypes.CONSOLE_NOISE_REDUCTION_OFF:
      return {
        title: "Console noise reduction is off",
        type: UINotificationTypes.CONSOLE_NOISE_REDUCTION_OFF,
        variant: "default",
      };
    case UINotificationTypes.SIDEBAR_MODE_ON:
      return {
        title: "The host has turned Sidechat ON",
        subtitle: "The procedure room cannot hear you",
        type: UINotificationTypes.SIDEBAR_MODE_ON,
        variant: "default",
      };
    case UINotificationTypes.SIDEBAR_MODE_OFF:
      return {
        title: "The host has turned Sidechat OFF",
        subtitle: "The procedure room can hear you now",
        type: UINotificationTypes.SIDEBAR_MODE_OFF,
        variant: "default",
      };
    case UINotificationTypes.HOST_MUTES_PARTICIPANT:
      return {
        title: "The host has muted your microphone",
        subtitle: "The other participants cannot hear you",
        type: UINotificationTypes.HOST_MUTES_PARTICIPANT,
        variant: "default",
      };
  }
};
