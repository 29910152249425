import React from "react";
import { NHighlightLocationEvent } from "availkit-js/dist/Models/Events/NHighlightLocationEvent";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { removeHighlightPoint } from "../../store/pointer/actions";
import pointerVector from "../../static/pointer_vector.svg";

interface DispatchProps {
  removeHighlightPoint: (id: string) => void;
}

interface OwnProps {
  highlight: NHighlightLocationEvent;
}

type Props = DispatchProps & OwnProps;

class Pointer extends React.Component<Props> {
  componentDidMount() {
    setTimeout(() => {
      this.props.removeHighlightPoint(
        this.props.highlight.uniqueIdentifier.toString()
      );
    }, 1000);
  }

  render() {
    const { highlight } = this.props;

    return (
      <img
        src={pointerVector}
        className="pointer"
        style={{
          left: highlight.x || 0,
          top: highlight.y || 0
        }}
        alt="pointer"
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    removeHighlightPoint: (id: string) => {
      dispatch(removeHighlightPoint(id));
    }
  };
};

export default connect(null, mapDispatchToProps)(Pointer);
