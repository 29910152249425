// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_modal-body_-eOwSm{min-height:50px}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/MinimumBrowserSizeModal/styles.scss"],"names":[],"mappings":"AACA,0BACE,eAAA","sourcesContent":["\n.modal-body {\n  min-height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-body": "styles_modal-body_-eOwSm",
	"modalBody": "styles_modal-body_-eOwSm"
};
export default ___CSS_LOADER_EXPORT___;
