export enum ImageActionKeys {
  CLEAR_IMAGE_FREEZE = "CLEAR_IMAGE_FREEZE",
  SET_IMAGE_FREEZE = "SET_IMAGE_FREEZE",
  SET_FROZEN_FRAME = "SET_FROZEN_FRAME",
  UNSET_FROZEN_FRAME = "UNSET_FROZEN_FRAME",
  SET_LEFT_FROZEN_FRAME = "SET_LEFT_FROZEN_FRAME",
  SET_RIGHT_FROZEN_FRAME = "SET_RIGHT_FROZEN_FRAME",
  SET_FULLSCREEN_FROZEN_FRAME = "SET_FULLSCREEN_FROZEN_FRAME",
  UNSET_LEFT_FROZEN_FRAME = "UNSET_LEFT_FROZEN_FRAME",
  UNSET_RIGHT_FROZEN_FRAME = "UNSET_RIGHT_FROZEN_FRAME",
  UNSET_FULLSCREEN_FROZEN_FRAME = "UNSET_FULLSCREEN_FROZEN_FRAME",
  SET_FREEZE_FRAME_IMAGE = "SET_FREEZE_FRAME_IMAGE"
}

interface ClearFreezeFrame {
  type: ImageActionKeys.CLEAR_IMAGE_FREEZE;
}

interface SetImageFreeze {
  type: ImageActionKeys.SET_IMAGE_FREEZE;
  frozen: boolean;
}

interface SetFrozenFrame {
  type: ImageActionKeys.SET_FROZEN_FRAME;
  transferIdentifier: string;
}

interface SetLeftFrozenFrame {
  type: ImageActionKeys.SET_LEFT_FROZEN_FRAME;
  transferIdentifier: string;
}
interface SetRightFrozenFrame {
  type: ImageActionKeys.SET_RIGHT_FROZEN_FRAME;
  transferIdentifier: string;
}
interface SetFullsreenFrozenFrame {
  type: ImageActionKeys.SET_FULLSCREEN_FROZEN_FRAME;
  transferIdentifier: string;
}

interface UnsetLeftFrozenFrame {
  type: ImageActionKeys.UNSET_LEFT_FROZEN_FRAME;
}
interface UnsetRightFrozenFrame {
  type: ImageActionKeys.UNSET_RIGHT_FROZEN_FRAME;
}
interface UnsetFullscreenFrozenFrame {
  type: ImageActionKeys.UNSET_FULLSCREEN_FROZEN_FRAME;
}

interface UnsetFrozenFrame {
  type: ImageActionKeys.UNSET_FROZEN_FRAME;
}

interface SetFreezeFrameImage {
  type: ImageActionKeys.SET_FREEZE_FRAME_IMAGE;
  data: string; //TODO is this the best way to type
  transferIdentifier: string;
}

export type ImageActionType =
  | ClearFreezeFrame
  | SetImageFreeze
  | SetFrozenFrame
  | UnsetFrozenFrame
  | SetLeftFrozenFrame
  | SetRightFrozenFrame
  | SetFullsreenFrozenFrame
  | UnsetLeftFrozenFrame
  | UnsetRightFrozenFrame
  | UnsetFullscreenFrozenFrame
  | SetFreezeFrameImage;
