export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
export const HELP_LINK =
  process.env.REACT_APP_HELP_LINK ||
  `${window.location.hostname}/AvailHelpCenter/index.html`;
export const IPAD_APP_STORE_LINK =
  "https://apps.apple.com/us/app/avail-remote-app/id1490348120";

// parse to coerce into boolean
export const ENABLE_RECAPTCHA = JSON.parse(
  process.env.REACT_APP_ENABLE_RECAPTCHA || "true"
);

// new help center link which is hosted outside of our app
// TODO set via new env variable
export const HELP_CENTER_LINK = "https://helpcenter.avail.io/help";
export const LEARNING_CENTER_LINK =
  "https://www.avail.io/learning-center/?learn=true";

// this is the pdf version of the eula hosted in hubspot, the URL is the same for all envs since it points
// to a place outside of our react app
// TODO set this via node env (in case it changes in the future)
export const EULA_PDF_URL_PATH =
  "https://fs.hubspotusercontent00.net/hubfs/8305858/PDFs/Avail_EULA_and_Terms_of_Service.pdf";

export const FEATURE_FLAG_ID = process.env.REACT_APP_FEATURE_FLAG_ID;

export const DEFAULT_TELESTRATION_THICKNESS = 4;

// Leverage it in dev mode to override default thickness
export const OVERRIDE_TELESTRATION_THICKNESS =
  process.env.REACT_APP_OVERRIDE_TELESTRATION_THICKNESS ||
  localStorage.getItem("OVERRIDE_TELESTRATION_THICKNESS") ||
  DEFAULT_TELESTRATION_THICKNESS.toString();

export const PAN_DELTA: number =
  parseFloat(`${process.env.REACT_APP_PAN_DELTA}`) || 0.02;

export const TILT_DELTA: number =
  parseFloat(`${process.env.REACT_APP_TILT_DELTA}`) || 0.02;
