import { DEPARTMENTS } from './DepartmentsActionTypes';

export const getDepartmentByHospitalAction = payload => ({
	type: DEPARTMENTS.GET_DEPARTMENTS_BY_HOSPITAL,
	payload,
});

export const getAllTherapiesAction = () => ({
	type: DEPARTMENTS.GET_ALL_THERAPIES,
});

export const setDepartmentsAction = departments => ({
	type: DEPARTMENTS.SET_DEPARTMENTS_BY_HOSPITAL,
	payload: departments,
});

export const createNewDepartmentAction = (hospitalId, departmentData, therapies) => ({
	type: DEPARTMENTS.CREATE_NEW_DEPARTMENT,
	hospitalId,
	departmentData,
	therapies,
});

export const deleteDepartmentAction = departmentId => ({
	type: DEPARTMENTS.DELETE_DEPARTMENT,
	payload: departmentId,
});

export const getProceduresByDepartmentAction = departmentId => ({
	type: DEPARTMENTS.GET_PROCEDURES_BY_DEPARTMENT,
	payload: departmentId,
});

export const updateDepartmentAction = (departmentId, departmentData) => ({
	type: DEPARTMENTS.UPDATE_DEPARTMENT,
	departmentId,
	departmentData,
});

export const getDepartmentAction = departmentId => ({
	type: DEPARTMENTS.GET_DEPARTMENT,
	payload: departmentId,
});

export const getRoomsByDepartmentAction = payload => ({
	type: DEPARTMENTS.GET_ROOMS_BY_DEPARTMENT,
	payload,
});

export const createNewRoomAction = (id, roomData) => ({
	type: DEPARTMENTS.CREATE_ROOM,
	id,
	roomData,
});

export const getAllRoomTypesAction = () => ({
	type: DEPARTMENTS.GET_ALL_ROOM_TYPES,
});

export const deleteRoomAction = id => ({
	type: DEPARTMENTS.DELETE_ROOM,
	payload: id,
});

export const getRoomAction = roomId => ({
	type: DEPARTMENTS.GET_ROOM,
	payload: roomId,
});

export const updateRoomAction = (roomId, roomData, departmentIds) => ({
	type: DEPARTMENTS.UPDATE_ROOM,
	roomId,
	roomData,
	departmentIds,
});

export const updateDepartmentProceduresAction = (departmentId, procedures) => ({
	type: DEPARTMENTS.UPDATE_DEPARTMENT_PROCEDURES,
	departmentId,
	procedures,
});

export const getAllDepartmentsRoomIsAssociatedAction = roomId => ({
	type: DEPARTMENTS.GET_ALL_DEPARTMENTS_ROOM_IS_ASSOCIATED,
	payload: roomId,
});

export const getAllDepartmentsTherapiesRoomIsAssociatedAction = roomId => ({
	type: DEPARTMENTS.GET_ALL_DEPARTMENTS_THERAPIES_ROOM_IS_ASSOCIATED,
	payload: roomId,
});

export const getRoomProceduresAction = roomId => ({
	type: DEPARTMENTS.GET_ROOM_PROCEDURES,
	payload: roomId,
});

export const getRoomTherapiesAction = roomId => ({
	type: DEPARTMENTS.GET_ROOM_THERAPIES,
	payload: roomId,
});

export const updateRoomProceduresAction = (roomId, procedures) => ({
	type: DEPARTMENTS.UPDATE_ROOM_PROCEDURES,
	roomId,
	procedures,
});
