/** Slider documentation
 */
import clsx from "clsx";

import {
  Slider as MUISlider,
  SliderProps as MUISlideProps,
} from "@mui/material";

import { Tooltip } from "src/components/Tooltip";

import { TriangleMask } from "./TriangleMask";
import styles from "./styles.scss";

export const ThicknessSlider = ({ onChange, value }: MUISlideProps) => {
  return (
    <Tooltip title={value} placement="top" color="gray">
      <div style={{ position: "relative" }}>
        <MUISlider
          classes={{
            root: clsx(styles.root),
            track: clsx(styles.track), // selected range
            rail: clsx(styles.rail), // unselected range
            thumb: clsx(styles.thumb), // the grabbing knob
          }}
          max={5}
          min={1}
          step={1}
          onChange={onChange}
          value={value}
          data-test-id="palette-slider"
        />
        <TriangleMask className={clsx(styles.mask)} />
      </div>
    </Tooltip>
  );
};
