import { useDispatch } from "react-redux";

import { Button } from "src/components/Button";
import { Font } from "src/components/Font";
import { Modal, ModalBody, ModalFooter } from "src/components/Modal";
import { useAppSelector } from "src/domains/Beacon/store";
import { leaveCallThunk } from "src/domains/Beacon/store/twilio/thunks/leaveCall";
import { selectLastParticipantModalOpen } from "src/domains/Beacon/store/ui/selectors";
import { cancelInitiateLastParticipantTimerThunk } from "src/domains/Beacon/store/ui/thunks/lastParticipantThunks";

import styles from "./styles.scss";

export const LastParticipantRemainingModal = () => {
  const dispatch = useDispatch();

  const stayOnCall = () => dispatch(cancelInitiateLastParticipantTimerThunk());
  const leaveCall = () => {
    dispatch(cancelInitiateLastParticipantTimerThunk());
    dispatch(leaveCallThunk());
  };

  const open = useAppSelector(selectLastParticipantModalOpen);

  return (
    <Modal
      open={open}
      data-test-id="last-participant-remaining-modal"
      unmountOnExit
      showVeil
      className={styles.modal}
    >
      <ModalBody className={styles.modalBody}>
        <Font variant="h1" color="light" textAlign="center">
          You are the last participant and the event will end automatically in 2
          minutes
        </Font>
      </ModalBody>
      <ModalFooter className={styles.modalFooter}>
        <Button
          className={styles.leaveButton}
          theme="red"
          data-test-id="last-participant-remaining-leave-button"
          onClick={leaveCall}
          label="Leave now"
        />
        <Button
          data-test-id="last-participant-remaining-stay-button"
          onClick={stayOnCall}
          label="Stay on the call"
          variant="text"
        />
      </ModalFooter>
    </Modal>
  );
};
