import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
/* Styles & Images */
import { addNewHospital } from '../../../../Common/Assets/Images';
/* Components */
import { Button, Fields, validators } from '../../../../Common/Components';
import ModalComponent from '../../../../Common/Components/Modal/Modal';
import { Table } from '../../Common';
/* Actions */
import { createNewConsoleAction, deleteConsoleAction, activateConsoleAction } from '../../Features/Consoles/ConsolesActions';
import { getAllHospitalsAction } from '../../Features/Organisation/Hospitals/HospitalsActions';
import AddNewEntity from '../AddNewEntity/AddNewEntity';
import ConsoleTableRow from './Components/ConsoleTableRow/ConsoleTableRow';
import './ConsolesTable.scss';


class ConsolesTable extends PureComponent {
	constructor(props) {
		super(props);
		this.columns = [{
			title: 'Serial ID',
			sortValue: 'serialNumber',
			alias: 'serialNumber',
			excluded: false,
		}, {
			title: 'Facility',
			sortValue: 'hospital',
			excluded: false,
		}, {
			title: 'Room Name',
			sortValue: 'room',
			excluded: false,
		}, {
			title: 'Console Status',
			excluded: true,
		}];

		if (!props.isHospital) {
			this.columns.push({
				title: 'Debug Mode',
				action: true,
				excluded: true,
			});
		}

		this.state = { pageSize: 10 };
	}

	state = {
		isDeleteModalOpened: false,
		isActivateModal: false,
	};

	componentDidMount() {
		this.params = {
			size: this.state.pageSize,
			page: 0,
		};
		this.props.getAllHospitals();
	}

	onPageChangeHandler = (e, { activePage }) => {
		const { isHospital = false } = this.props;
		this.params = {
			...this.params,
			size: this.state.pageSize,
			page: activePage - 1,
			[this.parameter]: this.sortParams,
		};
		const payload = isHospital ? { ...this.params, hospitalId: this.props.hospitalId } : this.params;
		this.props.getAllConsoles(payload);
	};

	onDeleteConsoleHandler = () => {
		this.props.deleteConsole(this.state.selectedConsole.id, this.props.hospitalId);
		this.toggleDeleteModal();
	};

	onActivateConsoleHandler = () => {
		const data = {
			debugMode: !this.state.selectedConsole.debugMode,
			hospitalId: this.state.selectedConsole.hospital.id,
			macAddress: this.state.selectedConsole.macAddress,
			serialNumber: this.state.selectedConsole.serialNumber,
			version: this.state.selectedConsole.version,
		};

		this.props.activateConsole(this.state.selectedConsole.id, data);
		this.toggleActivateModal();
	}

	onCreateNewConsoleToggleHandler = () => {
		this.props.resetReduxForm('consoleForm');
		this.setState({ isCreateNewModalOpened: !this.state.isCreateNewModalOpened });
	};

	onCreateNewConsoleHandler = (data) => {
		let consoleData = data;
		this.onCreateNewConsoleToggleHandler();
		consoleData = this.props.isHospital ? {
			...consoleData,
			hospitalId: this.props.hospitalId,
		} : consoleData;
		this.props.createNewConsole(consoleData, this.props.isHospital, this.params);
	};

	toggleDeleteModal = (selectedConsole = {}) => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
			selectedConsole,
		});
	};
	toggleActivateModal = (selectedConsole = {}) => {
		this.setState({
			isActivateModal: !this.state.isActivateModal,
			selectedConsole,
		});
	};

	handleVariablePaginationChange = (option) => {
		if (!option) return;

		this.setState({ pageSize: option });
		const params = ({
			size: option,
			page: 0,
			[this.parameter]: this.sortParams,
		});

		const payload = this.props.isHospital
			? { ...params, hospitalId: this.props.hospitalId }
			: params;
		this.props.getAllConsoles(payload);
	}

	onTableFilterHandler = (value, parameter = 'name') => {
		const { isHospital = false } = this.props;
		this.sortParams = value;
		this.parameter = parameter;

		this.params = {
			hospitalId: this.props.hospitalId,
			size: this.params.size,
			[this.parameter]: this.sortParams,
		};

    // always move to page 0 when updating the filter
    this.params.page = 0;

		const payload = isHospital ? { ...this.params, hospitalId: this.props.hospitalId } : this.params;
		this.props.getAllConsoles(payload);
	};

	onTableSortHandler = (value, parameter = 'name') => {
		const { isHospital = false } = this.props;
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			...this.params,
			[this.parameter]: this.sortParams,
		};
		const payload = isHospital ? { ...this.params, hospitalId: this.props.hospitalId } : this.params;
		this.props.getAllConsoles(payload);
	};

	renderDeleteConsoleModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onDeleteConsoleHandler}
			onCancel={this.toggleDeleteModal}
			confirmText="Delete"
		>
			{this.state.selectedConsole && (
				<span>
					You are about to delete console <span className="text--bold">{this.state.selectedConsole.serialNumber}</span>.
					Would you like to proceed with this action?
				</span>
			)}
		</ModalComponent>
	);

	renderActivateConsoleModal = () => (
		<ModalComponent
			open={this.state.isActivateModal}
			onConfirm={this.onActivateConsoleHandler}
			onCancel={this.toggleActivateModal}
			confirmText="Change"
		>
			{this.state.selectedConsole && (
				<span>
					You are about to change debug mode for console <span className="text--bold">{this.state.selectedConsole.serialNumber}</span>.
					Would you like to proceed with this action?
				</span>
			)}
		</ModalComponent>
	);


	renderCreateNewConsoleModal = () => {
		const { t } = this.props;

		return (
			<ModalComponent
				open={this.state.isCreateNewModalOpened}
				title="Add New Console"
				onCancel={this.onCreateNewConsoleToggleHandler}
				hasActionButtons={false}
			>
				<form
					onSubmit={this.props.handleSubmit(this.onCreateNewConsoleHandler)}
					className="justify-content--space-evenly display--flex align--center flex-direction--column"
				>
					<div>
						<Fields.InputField
							labelText={t('common.form.id.label')}
							placeholder={t('common.form.id.placeholder')}
							name="serialNumber"
							validate={validators.required}
						/>
						<Fields.InputField
							labelText={t('common.form.mac.label')}
							placeholder={t('common.form.mac.placeholder')}
							name="macAddress"
							validate={[validators.required, validators.macAddress, validators.exactLength17]}
						/>
						<Fields.InputField
							labelText={t('common.form.version.label')}
							placeholder={t('common.form.version.placeholder')}
							name="version"
						/>
						{
							!this.props.isHospital && (
								<Fields.SelectField
									labelText="Assign console to facility"
									placeholder="Select facility"
									name="hospitalId"
									options={this.props.hospitals && this.props.hospitals.map(hospital => ({
										key: hospital.id,
										value: hospital.id,
										text: hospital.name,
									}))}
									validate={validators.required}
									className="console__dropdown"
								/>
							)
						}
					</div>
					<div className="button__wrapper width--full">
						<Button isGhost buttonSmall noBorder onClick={this.onCreateNewConsoleToggleHandler}>Cancel</Button>
						<Button isFull buttonSmall type="submit">Create</Button>
					</div>
				</form>
			</ModalComponent>
		);
	};

	render() {
    const currentUser = this.props.currentUser
		return (
			<React.Fragment>
				<Table
					row={ConsoleTableRow}
					columns={this.columns}
					tableData={this.props.consoles}
					paginationConfig={this.props.pagination}
					openModal={this.toggleDeleteModal}
					openReinvitationModal={this.toggleActivateModal}
					onPageChangeHandler={this.onPageChangeHandler}
					onSortTableHandler={this.onTableSortHandler}
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					className="console__table"
					noItemsClass="height--full justify-content--center align--center"
					onAddNewHandler={this.onCreateNewConsoleToggleHandler}
					headerTitle={this.props.headerTitle}
					noEntityComponent={<AddNewEntity
						image={addNewHospital}
						addNewEntityHandler={this.onCreateNewConsoleToggleHandler}
						title="Add New Console"
					/>
					}
          tableActions={currentUser.userType === "AVAIL_ADMIN"}
					consoleData
					currentUser={currentUser}
					sortable
					search
					isHospital={this.props.isHospital}
					variablePagination
					onVariablePaginationChange={this.handleVariablePaginationChange}
				/>
				{this.renderDeleteConsoleModal()}
				{this.renderActivateConsoleModal()}
				{this.renderCreateNewConsoleModal()}
			</React.Fragment>
		);
	}
}

ConsolesTable.defaultProps = {
	consoles: [],
	pagination: null,
};

ConsolesTable.propTypes = {
	consoles: PropTypes.array,
	pagination: PropTypes.object,
};

const mapStateToProps = state => ({
	hospitals: state.hospitalsReducer.hospitals,
	currentUser: state.homeReducer.currentUser,
	pagination: state.consolesReducer.pagination,
});

const mapDispatchToProps = dispatch => ({
	deleteConsole: (consoleId, hospitalId) => dispatch(deleteConsoleAction(consoleId, hospitalId)),
	activateConsole: (consoleId, data) => dispatch(activateConsoleAction(consoleId, data)),
	getAllHospitals: params => dispatch(getAllHospitalsAction(params)),
	createNewConsole: (consoleData, isHospital, param) => dispatch(createNewConsoleAction(consoleData, isHospital, param)),
	resetReduxForm: formName => dispatch(reset(formName)),
});

const ConsolesTableWithTranslation = withTranslation()(ConsolesTable);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'consoleForm',
})(ConsolesTableWithTranslation));
