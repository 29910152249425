import { NAnnotationInstruction } from "availkit-js/dist/Models/NAnnotation";
import { NAnnotationPoint } from "availkit-js/dist/Models/NAnnotationPoint";

import {
  DEFAULT_TELESTRATION_THICKNESS,
  OVERRIDE_TELESTRATION_THICKNESS
} from "src/constants";

export function getVideoDimensions(
  video: HTMLVideoElement
): { width: number; height: number; offsetLeft: number; offsetTop: number } {
  // offset = actual dimensions, video = original dimensions (default: 1280x720)
  const {
    offsetWidth,
    offsetHeight,
    videoWidth,
    videoHeight,
    offsetLeft,
    offsetTop
  } = video;
  const offsetRatio = offsetWidth / offsetHeight;
  const videoRatio = videoWidth / videoHeight;

  let width = offsetWidth;
  let height = offsetHeight;

  // If the video element is short and wide (landscape)
  if (offsetRatio > videoRatio) {
    width = height * videoRatio;
  }
  // If the video element is tall and thin (portrait), or exactly equal to the original ratio
  else {
    height = width / videoRatio;
  }

  return {
    width,
    height,
    offsetLeft,
    offsetTop
  };
}

export function getTelestrationLineThickness() {
  let thickness = DEFAULT_TELESTRATION_THICKNESS;
  try {
    /* If a different override thickness is configured, use the overriden value */
    if (thickness.toString() !== OVERRIDE_TELESTRATION_THICKNESS) {
      thickness = parseInt(OVERRIDE_TELESTRATION_THICKNESS, 10);
      if (!thickness || isNaN(thickness)) {
        thickness = DEFAULT_TELESTRATION_THICKNESS;
      }
    }
  } catch (e) {
    thickness = DEFAULT_TELESTRATION_THICKNESS;
  }

  return thickness;
}

export function normalizeInstruction(
  anInstruction: NAnnotationInstruction,
  containerBounds: { width: number; left: number; top: number }
) {
  if (!anInstruction.x || !anInstruction.y) {
    throw new Error("Could not denormalize coordinates for instruction");
  }
  const video = document.querySelector(
    ".large-media video"
  ) as HTMLVideoElement;
  const videoArea = getVideoDimensions(video); // actual video bounds

  let aPoint: NAnnotationPoint = new NAnnotationPoint(
    anInstruction.x / videoArea.width,
    anInstruction.y / videoArea.height,
    getTelestrationLineThickness()
  );
  return aPoint;
}

export function denormalizeInstruction(
  instruction: NAnnotationInstruction,
  containerBounds: { width: number }
): NAnnotationInstruction {
  if (!instruction.x || !instruction.y) {
    throw new Error("Could not denormalize coordinates for instruction");
  }
  // TODO: Is there any alternative to this?
  const video = document.querySelector(
    ".large-media video"
  ) as HTMLVideoElement;
  const videoArea = getVideoDimensions(video); // actual video bounds
  const { width } = containerBounds; // large-media bounds
  const margins = (width - videoArea.width) / 2;

  return new NAnnotationPoint(
    margins + videoArea.width * instruction.x,
    videoArea.height * instruction.y,
    instruction.widthFromPoint || 5
  );
}

// Source: https://css-tricks.com/converting-color-spaces-in-javascript/
export function rgbToHex(red: number, green: number, blue: number) {
  let r = Math.floor(red * 255).toString(16);
  let g = Math.floor(green * 255).toString(16);
  let b = Math.floor(blue * 255).toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;

  return "#" + r + g + b;
}

// Needed for Telestration Data read from Presence server
export function rgbaToHex(r: number, g: number, b: number, a: number) {
  const outParts = [
    Math.trunc(r * 255).toString(16),
    Math.trunc(g * 255).toString(16),
    Math.trunc(b * 255).toString(16),
    Math.round(a * 255)
      .toString(16)
      .substring(0, 2)
  ];

  // Pad single-digit output values
  outParts.forEach(function (part, i) {
    if (part.length === 1) {
      outParts[i] = "0" + part;
    }
  });

  return "#" + outParts.join("");
}
