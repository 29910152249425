import {
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
} from "twilio-video";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { uiActions } from "src/domains/Beacon/store/ui";
import {
  getTwilioLoginId,
  isConsoleParticipant,
  whoPublishedTrack,
} from "src/domains/Beacon/utils/twilio";
import { logger } from "src/logging/logger";

/**
 * Room subscription to detect when a participant remove its audio/video tracks
 * from the call. TODO: For now it works for host but would it be implemented in a
 * future for console and panelists?
 */
export const useRoomTrackUnsubscribed = () => {
  const { room } = useAppSelector(selectTwilioState);
  const { callDetails, mode } = useAppSelector(selectMeetingState);
  const dispatch = useDispatch();

  useEffect(() => {
    room.on(
      "trackUnsubscribed",
      (
        track: RemoteTrack,
        publication: RemoteTrackPublication,
        participant: RemoteParticipant
      ) => {
        logger().verbose("Unsubscribing tracks...");
        if (mode === CallModes.MP) {
          whoPublishedTrack({
            participant,
            publication,
            callDetails,
            wasHost: () => {
              // Host has unpublished its video/audio tracks to Twilio
              if (publication.kind === "video") {
                dispatch(streamActions.setHostVideoTrack(null));
                dispatch(uiActions.stopPipVideo());
              } else if (publication.kind === "audio") {
                dispatch(streamActions.setHostAudioTrack(null));
              }
            },
            wasConsole: () => {
              // Console has unpublished its video/audio tracks to Twilio
              if (publication.kind === "video") {
                dispatch(streamActions.setConsoleVideoTrack(null));
              } else if (publication.kind === "audio") {
                dispatch(streamActions.setConsoleAudioTrack(null));
              }
            },
            wasParticipant: () => {
              if (publication.kind === "video") {
                // TODO: panelist with video? could be implemented in a future
              } else if (publication.kind === "audio") {
                const loginId = getTwilioLoginId(participant.identity);
                dispatch(
                  streamActions.setParticipantAudioTrack({
                    loginId,
                    audioTrack: null,
                  })
                );
              }
            },
          });
        } else if (mode === CallModes.P2P) {
          const isConsole = isConsoleParticipant(participant);
          if (isConsole) {
            // Console has unpublished its video/audio tracks to Twilio
            if (publication.kind === "video") {
              dispatch(streamActions.setConsoleVideoTrack(null));
            } else if (publication.kind === "audio") {
              dispatch(streamActions.setConsoleAudioTrack(null));
            }
          } else {
            // Host has unpublished its video/audio tracks to Twilio
            if (publication.kind === "video") {
              dispatch(streamActions.setHostVideoTrack(null));
              dispatch(uiActions.resumePipVideo());
            } else if (publication.kind === "audio") {
              dispatch(streamActions.setHostAudioTrack(null));
            }
          }
        }
      }
    );
  }, []);
};
