import {
  PresenceServerSession,
  PresenceServerConsoles,
  PresenceServerFreezeFrame,
  PresenceServerParticipant, // PresenceServerTelestration,
  UploadFreezeFrameResponse,
  SessionDetailsResponse,
  ThumbnailListResponse,
} from "src/services/ApiClient/presence/types";

import ApiQuery from "../ApiQuery";
import { PRESENCE_API_URL } from "../utils";

// getCallSession GET call session data
export const getPresenceForCallSession = (callSessionId: string) => {
  return ApiQuery<PresenceServerSession>(
    `${PRESENCE_API_URL}/callSessions/${callSessionId}`
  );
};

// getConsoles - GET data for all consoles on call
export const getPresenceForConsoles = (callSessionId: string) => {
  return ApiQuery<PresenceServerConsoles>(
    `${PRESENCE_API_URL}/callSessions/${callSessionId}/consoles`
  );
};

//  getFreezeFrames - GET all freeze frame data
export const getPresenceForFreezeFrames = (callSessionId: string) => {
  return ApiQuery<PresenceServerFreezeFrame[]>(
    `${PRESENCE_API_URL}/callSessions/${callSessionId}/freezeFrames`
  );
};

// getFreezeFrame - GET freeze frame data by id
export const getPresenceForFreezeFrame = (
  callSessionId: string,
  freezeFrameId: string
) => {
  return ApiQuery<PresenceServerFreezeFrame>(
    `${PRESENCE_API_URL}/callSessions/${callSessionId}/freezeFrames/${freezeFrameId}`
  );
};

//  getFreezeFrameContent- GET freeze frame data content by id
export const getPresenceForFreezeFrameById = (
  callSessionId: string,
  freezeFrameId: string
) => {
  return ApiQuery<string>(
    `${PRESENCE_API_URL}/callSessions/${callSessionId}/freezeFrames/${freezeFrameId}/content`,
    "GET",
    null,
    {},
    true
  );
};

// getParticipants - GET call session participants data
export const getPresenceForParticipants = (callSessionId: string) => {
  return ApiQuery<PresenceServerParticipant[]>(
    `${PRESENCE_API_URL}/callSessions/${callSessionId}/participants`
  );
};

// getTelestrations - GET call session telestration data
export const getPresenceForTelestrations = (callSessionId: string) => {
  // TODO get real type, not any
  // {content: PresenceServerTelestration[]}
  return ApiQuery<any>(
    `${PRESENCE_API_URL}/callSessions/${callSessionId}/telestrations`
  );
};

// deleteTelestrations - DELETE call telestration data
export const deletePresenceForTelestrations = (callSessionId: string) => {
  // TODO get real type, not any
  // {content: PresenceServerTelestration[]}
  return ApiQuery<any>(
    `${PRESENCE_API_URL}/callSessions/${callSessionId}/telestrations`,
    "DELETE"
  );
};

// uploadFreezeFrame - POST freeze frame data
export const uploadFreezeFrame = (callSessionId: string, payload: FormData) => {
  return ApiQuery<UploadFreezeFrameResponse, FormData>(
    `${PRESENCE_API_URL}/callSessions/${callSessionId}/freezeFrames`,
    "POST",
    payload
  );
};

// deleteFreezeFrameById - DELETE transferId
export const deleteFreezeFrameById = (
  callSessionId: string,
  transferId: string
) => {
  return ApiQuery<any>(
    `${PRESENCE_API_URL}/callSessions/${callSessionId}/freezeFrames/${transferId}`,
    "DELETE"
  );
};

// Gets the session's details like freeze frames, noise cancellation, input resizing
export const getCallSessionById = (callSessionId: string) => {
  return ApiQuery<SessionDetailsResponse>(
    `${PRESENCE_API_URL}/callSessions/${callSessionId}`
  );
};

// Format the "-" inside the thumbnailId to "_"
// Should call the endpoint for all thumbnails, the map will be done locally per camera
export const getCameraThumbnail = (callSid: string) => {
  return ApiQuery<ThumbnailListResponse>(
    `${PRESENCE_API_URL}/callSessions/${callSid}/thumbnailsWithContent`
  );
};
