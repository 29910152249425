import { useEffect, useMemo, useRef, useState } from "react";

import clsx from "clsx";

import { Indicator } from "src/components/Indicator/Indicator";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectStreamState } from "src/domains/Beacon/store/stream/selectors";
import { LayoutTypes } from "src/domains/Beacon/store/stream/types";
import {
  selectCallStep,
  selectSidePanelContent,
  selectSidePAnelFullyState,
  selectSidePanelLayouts,
  selectSidePanelOpen,
} from "src/domains/Beacon/store/ui/selectors";
import {
  CallSteps,
  UISidePanelContentOptions,
} from "src/domains/Beacon/store/ui/types";
import { getVideoPositioning } from "src/domains/Beacon/utils/canvas";
import { useEventListener } from "src/hooks/useEventListener";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { useScssVariable } from "src/hooks/useScssVariable";
import { logger } from "src/logging/logger";

import styles from "./styles.scss";

interface Props {
  useSidePanelOffset: boolean;
}

export const LayoutsBorder = ({ useSidePanelOffset }: Props) => {
  const { tungstenExternalLa } = useFeatureFlags();
  const { layoutType } = useAppSelector(selectStreamState);
  const [borderDimensions, setBorderDimensions] = useState<{
    top: number;
    left: number;
    height: number;
    width: number;
    multiPaneWidth: number;
    multiPaneHeight: number;
    multiPaneMiddle: number;
  }>();
  const [shouldShowBorders, setShouldShowBorder] = useState<boolean>(false);

  // Passing variables to Scss
  const { ref } = useScssVariable<HTMLDivElement>(
    "--top",
    `${borderDimensions?.top}px`
  );
  useScssVariable("--left", `${borderDimensions?.left}px`, ref);
  useScssVariable("--height", `${borderDimensions?.height}px`, ref);
  useScssVariable("--width", `${borderDimensions?.width / 2}px`, ref);
  useScssVariable(
    "--multiPaneWidth",
    `${borderDimensions?.multiPaneWidth}px`,
    ref
  );
  useScssVariable(
    "--multiPaneHeight",
    `${borderDimensions?.multiPaneHeight}px`,
    ref
  );
  useScssVariable(
    "--multiPaneMiddle",
    `${borderDimensions?.multiPaneMiddle}px`,
    ref
  );

  const isSidePanelOpen = useAppSelector(selectSidePanelOpen);
  const content = useAppSelector(selectSidePanelContent);
  const { frameSelected } = useAppSelector(selectSidePanelLayouts);
  // Must know if sidePanel is fully open to show the blue border with animation
  const sidePanelFully = useAppSelector(selectSidePAnelFullyState);
  const callStep = useAppSelector(selectCallStep);

  // LAYOUTS_PRESETS replaces LAYOUTS when using tungstenExternalLa
  const isLayoutsOpen =
    isSidePanelOpen &&
    content ===
      (tungstenExternalLa
        ? UISidePanelContentOptions.LAYOUTS_PRESETS
        : UISidePanelContentOptions.LAYOUTS);

  // Listeners cannot read state, thus, a ref is needed to pass the current value
  const openRef = useRef<boolean>(isLayoutsOpen);
  openRef.current = useMemo(() => isLayoutsOpen, [isLayoutsOpen]);

  const handleBordersResize = () => {
    logger().info(
      "Layouts blue border",
      "Calculating blue border based on video dimensions..."
    );
    // Get video positioning with SidePanel open

    // cant use drawer width if the side panel is overlayed on top
    // so if we want to use the offset then it should be true,
    // if we dont want to use the offset then it should be false
    const dimensions = getVideoPositioning(useSidePanelOffset);
    // if four view layout is not active, default to window height
    const multiPaneHeight =
      layoutType !== LayoutTypes.FOUR_VIEW ? 0 : dimensions.multiPaneHeight;

    setBorderDimensions({ ...dimensions, multiPaneHeight });
    setShouldShowBorder(true);
  };

  // Only when layouts are open and sidePanel is fully open, we recalculate the borders
  // based on video dimensions and show the borders with animation
  useEffect(() => {
    if (isLayoutsOpen && sidePanelFully === "open") {
      handleBordersResize();
    }
  }, [isLayoutsOpen, layoutType, sidePanelFully, useSidePanelOffset]);

  // Must restart the show borders state if layouts isn't open
  useEffect(() => {
    if (!isLayoutsOpen && callStep === CallSteps.IN_CALL) {
      logger().info(
        "Layouts blue border",
        "Hiding blue border with transition..."
      );
      setShouldShowBorder(false);
    }
  }, [isLayoutsOpen]);

  // Must listen to the window resize to re-calculate the video dimensions
  useEventListener(
    "resize",
    () => {
      const layoutsOpen = openRef.current;
      if (layoutsOpen) {
        logger().info("Layouts blue border - window resize");
        handleBordersResize();
      }
    },
    window,
    useSidePanelOffset
  );

  return (
    <div ref={ref}>
      {["leftTop", "rightTop", "leftBottom", "rightBottom", "fullscreen"].map(
        (side) => (
          <Indicator
            key={`${side}-indicator`}
            color="blue"
            show={
              // only show fullscreen Indicator when LayoutType is fullscreen,
              // else the side of frameSelected
              shouldShowBorders &&
              (layoutType === LayoutTypes.FULLSCREEN
                ? side === "fullscreen"
                : frameSelected === side)
            }
            className={clsx(styles.indicator, styles[side], {
              // Must hide borders immediately before video stretches back,
              // otherwise, animation might look weird
              [styles.hideNow]: !isLayoutsOpen,
            })}
          />
        )
      )}
    </div>
  );
};
