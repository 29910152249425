import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";
import { AVKTelestrationEndEvent } from "availkit-js/dist/Models/Events/AVKTelestrationEndEvent";
import { AVKTelestrationStartEvent } from "availkit-js/dist/Models/Events/AVKTelestrationStartEvent";
import { NAnnotation } from "availkit-js/dist/Models/NAnnotation";
import { NAnnotationPoint } from "availkit-js/dist/Models/NAnnotationPoint";
import { Guid } from "guid-typescript";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { InstructionMemory } from "src/domains/Beacon/components/Easel/constants";
import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { telestrationActions } from "src/domains/Beacon/store/telestration/telestrationSlice";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

export const sendVideoAnnotationEventThunk = createAsyncThunk<
  // Return type of the payload creator, we return nothing so void
  void,
  // First argument to the payload creator, no args so void
  { points: NAnnotationPoint[] },
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "telestration/sendVideoAnnotationEventThunk",
  async ({ points }, { getState, dispatch }) => {
    const {
      telestration: {
        penStyle: { color: penColor },
        isDrawModeOn,
        activeTelestrationId,
        activeSequence,
        telestrationEventsMap,
        telestrationHistory,
        totalAnnotations,
      },
      meeting: { isUserHost, callSid, joinId },
    } = getState();
    const availKit = AvailKitService.instance;
    const isHostDrawing = isUserHost && isDrawModeOn;
    if (!isHostDrawing) {
      // only the Host can send drawing events
      return;
    }
    try {
      const annotation: NAnnotation = new NAnnotation();
      annotation.setRGBA(penColor); // setRGBA takes rgba string ex. "x1ac451"
      annotation.backingInstructions = points;

      // converting a Guid to string
      const activeTelestrationIdString = activeTelestrationId
        .toString()
        .toLowerCase();

      const annotationEvent = new AVKAnnotationEvent(
        callSid,
        annotation,
        activeTelestrationIdString as any
      );

      annotationEvent.sender = joinId;
      annotationEvent.sequence = activeSequence;

      dispatch(telestrationActions.setActiveSequence(activeSequence + 1));

      let annotationEvents: AVKAnnotationEvent[] = telestrationEventsMap.get(
        activeTelestrationIdString
      ) as AVKAnnotationEvent[];

      if (!annotationEvents) {
        annotationEvents = [];
      }

      annotationEvents = [...annotationEvents, annotationEvent];

      // updating mapped state
      const telestrationEventsMapCopy = new Map(telestrationEventsMap);
      telestrationEventsMapCopy.set(
        activeTelestrationIdString,
        annotationEvents
      );
      dispatch(
        telestrationActions.setTelestrationEventsMap(telestrationEventsMapCopy)
      );

      if (availKit) {
        try {
          /* Save the history */
          const newSet: InstructionMemory = {
            instructions: [...points],
            style: penColor,
          };

          const newHistory = [...telestrationHistory, newSet];
          dispatch(telestrationActions.setTelestrationHistory(newHistory));
          await availKit.eventService.broadcast(annotationEvent);

          // updateMappedState
          const totalAnnotationsCopy = new Map(totalAnnotations);
          totalAnnotationsCopy.set(
            activeTelestrationId,
            annotationEvent.sequence + 1
          );
          dispatch(
            telestrationActions.setTotalAnnotations(totalAnnotationsCopy)
          );
        } catch (error) {
          logger().logWithFields(
            LoggerLevels.error,
            {
              feature: "Telestration",
              fileInfo: "sendEventThunks/sendVideoAnnotationEvent",
            },
            `Could not send annotationEvent: ${error?.message}`
          );
        }
      } else {
        logger().logWithFields(
          LoggerLevels.info,
          {
            feature: "Telestration",
          },
          "Cannot send AnnotationEvent. No instance of AvailKit"
        );
      }

      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Telestration",
          fileInfo: "sendEventThunks/sendVideoAnnotationEvent",
        },
        "Annotation Sent: " +
          activeTelestrationIdString +
          " : sequence  :" +
          (annotationEvent.sequence + 1)
      );
    } catch (error) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "Telestration",
          fileInfo: "sendEventThunks/sendVideoAnnotationEvent",
        },
        `Could not send annotationEvent: ${error?.message}`
      );
    }
  }
);

export const sendTelestrationStartEventThunk = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const {
    telestration: { isDrawModeOn },
    meeting: { isUserHost, callSid, joinId },
  } = getState();
  const isHostDrawing = isUserHost && isDrawModeOn;
  const availKit = AvailKitService.instance;
  if (!isHostDrawing) {
    // only the Host can send drawing events
    return;
  }

  if (availKit) {
    try {
      let telestrationId: Guid | null = Guid.create();
      telestrationId = telestrationId.toString().toLowerCase() as any;

      dispatch(telestrationActions.setActiveTelestrationId(telestrationId));
      dispatch(telestrationActions.setActiveSequence(0));

      const teleStrationStartEvent = new AVKTelestrationStartEvent(
        callSid,
        telestrationId
      );
      teleStrationStartEvent.sender = joinId;

      availKit.eventService.broadcast(teleStrationStartEvent);

      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Telestration",
          fileInfo: "sendEventThunks/sendTelestrationStartEventThunk",
        },
        `Start Sent: ${telestrationId} by ${joinId}`
      );
    } catch (error) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "Telestration",
          fileInfo: "sendEventThunks/sendTelestrationStartEventThunk",
        },
        `Could not send telestration startEvent: ${error?.message}`
      );
    }
  } else {
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Telestration",
        fileInfo: "sendEventThunks/sendTelestrationStartEventThunk",
      },
      "Cannot send TelestrationStartEvent. No instance of AvailKit"
    );
  }
};

export const sendTelestrationEndEventThunk = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const {
    telestration: { isDrawModeOn, activeTelestrationId, totalAnnotations },
    meeting: { isUserHost, callSid, joinId },
  } = getState();
  const isHostDrawing = isUserHost && isDrawModeOn;
  const availKit = AvailKitService.instance;
  if (!isHostDrawing) {
    // only the Host can send drawing events
    return;
  }

  if (activeTelestrationId && availKit) {
    try {
      const activeTelestrationIdString = activeTelestrationId
        .toString()
        .toLowerCase();

      const teleStrationEndEvent = new AVKTelestrationEndEvent(
        callSid,
        activeTelestrationIdString as any
      );
      teleStrationEndEvent.sender = joinId;
      const ta = totalAnnotations.get(activeTelestrationId);
      if (!ta) {
        logger().logWithFields(
          LoggerLevels.info,
          {
            feature: "Telestration",
            fileInfo: "sendEventThunks/sendTelestrationEndEventThunk",
          },
          "totalAnnotations cannot find the requested activeTelestrationId, cannot send TelestrationEndEvent"
        );
        return;
      }
      teleStrationEndEvent.totalAnnotations = ta;

      availKit.eventService.broadcast(teleStrationEndEvent);
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Telestration",
          fileInfo: "sendEventThunks/sendTelestrationEndEventThunk",
        },
        `End Sent: ${activeTelestrationIdString} : Total Annotations : ${teleStrationEndEvent.totalAnnotations}`
      );
    } catch (error) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "Telestration",
          fileInfo: "sendEventThunks/sendTelestrationEndEventThunk",
        },
        `Could not send telestration endEvent: ${error?.message}`
      );
    }
  } else {
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Telestration",
        fileInfo: "sendEventThunks/sendTelestrationEndEventThunk",
      },
      "Cannot send TelestrationEndEvent. !activeTelestrationId || !availKit"
    );
  }
};
