import { TwoDimensionPosition } from "src/services/ApiClient/presence";

export interface BarDimensions extends TwoDimensionPosition {
  height: number;
}

export const barColors = {
  0: "#fff", // $white
  1: "#f80000", // $alert-red
  2: "#ffd600", // $warning-yellow
  3: "#ffd600",
  4: "#00cf43", // $success-green
  5: "#00cf43",
};

// values come from Figma svg.
export const barsDimensions: BarDimensions[] = [
  { x: 1.5, y: 17.0034, height: 4.99863 },
  { x: 6, y: 13.998, height: 8.00391 },
  { x: 10.5, y: 11.002, height: 11 },
  { x: 15, y: 6.9978, height: 15.0041 },
  { x: 19.5, y: 1.99805, height: 20.0039 },
];
