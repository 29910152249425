import { RootState } from "src/domains/Beacon/store";
import { AppDispatch } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { unpublishShareScreenTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishShareScreenTrackThunk";
import { uiActions } from "src/domains/Beacon/store/ui";
import { logger } from "src/logging/logger";

interface IParams {
  enableORTelligence: boolean;
}

// Handles stopping the screen sharing track and also, reset all the layouts based
// on the latest snapshot
export const stopShareScreenTrackThunk = ({
  enableORTelligence,
}: IParams) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const {
      stream: { integrationActive },
      ui: {
        sidePanel: { open: isSidePanelOpen },
      },
    } = getState();

    // is the feature flag ON && an integration (like ORT) in a layout view?
    const isIntegrationActive = enableORTelligence && integrationActive;

    if (!isIntegrationActive) {
      logger().info(
        "Integration isn't active, ignoring stop share screen thunk"
      );
      // no need to run the thunk if integration is not active
      return;
    }

    logger().info("User has stopped screen sharing");

    // Must stop the screen sharing track to reset the Host's video Pip
    await dispatch(unpublishShareScreenTrackThunk());

    // Screen sharing was deactivated, must enable the 3rd party integration option again
    dispatch(streamActions.setIntegrationActive(false));

    // close side panel after sharing stopped
    if (isSidePanelOpen) {
      logger().info("Closing SidePanel due to TPI has being stopped...");
      dispatch(uiActions.setSidePanelOpen(false));
    }
  } catch (error: any) {
    logger().error(
      "Error while stopping screen sharing tracks.",
      error?.message
    );
    throw new Error("Error while stopping screen sharing tracks.");
  }
};
