// Third Party Integration utils
import { RemoteVideoTrack } from "twilio-video";

// Checks if Host has an Integration track showing, it replaces the Host's camera video,
// if the track name has `avail-integration-` text means that the track is indeed an Integration track
export const isTPITrackActive = (remoteVideoTrack: RemoteVideoTrack) => {
  if (!remoteVideoTrack?.name) {
    // Must check for null value, otherwise it returns a "null" instead of "false"
    return false;
  }
  return remoteVideoTrack?.name.indexOf("avail-integration-") !== -1;
};
