// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_depth_Rm9AaW{z-index:95}.styles_canvas_C\\+y0zu{position:absolute}.styles_container_qnw89B{align-items:center;display:flex;height:100%;justify-content:center;position:absolute;width:100%}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/FreezeFrameCanvas/styles.scss"],"names":[],"mappings":"AAEA,qBACE,UAAA,CAGF,uBACE,iBAAA,CAGF,yBACE,kBAAA,CACA,YAAA,CACA,WAAA,CACA,sBAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":["@import \"/src/global.scss\";\n\n.depth {\n  z-index: map-get($map: $beacon-zindex, $key: freezeFrameImg);\n}\n\n.canvas {\n  position: absolute;\n}\n\n.container {\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"depth": "styles_depth_Rm9AaW",
	"canvas": "styles_canvas_C+y0zu",
	"container": "styles_container_qnw89B"
};
export default ___CSS_LOADER_EXPORT___;
