import { eventChannel } from 'redux-saga';
import { call, put, take, takeLatest } from 'redux-saga/effects';
import { setShowIdleAlertAction } from './HomeActions';
import { HOME } from './HomeActionTypes';
import { USER_IDLE_THRESHOLD } from '../../Common/Constants/settings';
import UserSessionService from "src/services/UserSessionService";

function* startIdleTimer() {
	// eslint-disable-next-line no-use-before-define
	const channel = yield call(subscribeToTimer);
	while (true) {
		const action = yield take(channel);
		yield put(action);
	}
}

function subscribeToTimer() {
	return eventChannel((emit) => {
		let time;

		function showAlert() {
			emit(setShowIdleAlertAction(true));
		}

		function resetTimer() {
			emit(setShowIdleAlertAction(false));
			clearTimeout(time);
			time = setTimeout(showAlert, USER_IDLE_THRESHOLD * 60 * 1000);
		}

		// DOM Events
		window.onload = resetTimer;
		const events = ['onmousemove', 'onkeypress', 'onmousedown', 'ontouchstart', 'onclick'];
		// eslint-disable-next-line no-use-before-define
		events.forEach((event) => { document[event] = debounce(250, resetTimer); });

		return () => {};
	});
}

// FIXME Not sure where to place this tbh, will figure it out later
/**
 * debounce
 * @param {integer} milliseconds This param indicates the number of milliseconds
 *     to wait after the last call before calling the original function.
 * @param {object} What "this" refers to in the returned function.
 * @return {function} This returns a function that when called will wait the
 *     indicated number of milliseconds after the last call before
 *     calling the original function.
 */
// eslint-disable-next-line func-names
const debounce = function (milliseconds, context) {
	const baseFunction = context;
	let timer = null;
	const wait = milliseconds;

	// eslint-disable-next-line func-names
	return function () {
		const self = context;
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;

		function complete() {
			baseFunction.apply(self, args);
			timer = null;
		}

		if (timer) {
			clearTimeout(timer);
		}

		timer = setTimeout(complete, wait);
	};
};

export default function* homeSaga() {
	yield takeLatest(HOME.START_IDLE_TIMER, startIdleTimer);
  yield takeLatest(HOME.SET_CURRENT_USER_INFO, setCurrentUserInfoInSession);
}

function* setCurrentUserInfoInSession(action) {
  yield UserSessionService.setCachedUserInfo(action.payload);
};
