/** CameraInputSelectors documentation
 */
import { ReactNode, useRef } from "react";

import clsx from "clsx";

import { Select } from "src/components/Select";
import { LayoutType, LayoutTypes } from "src/domains/Beacon/store/stream/types";
import { cameraLabelDisplay } from "src/domains/Beacon/utils/layouts";
import { capitalizeText } from "src/utils/general";

import styles from "./styles.scss";

export interface CameraOptions {
  title: string;
  value: string;
  disabled: boolean;
  leftSide?: ReactNode;
}

export interface CameraInputSelectorsProps {
  layoutType: LayoutType;
  onClose: () => void;
  onOpen: () => void;
  onChange: () => void;
  fullScreenFrameName: string;
  layoutFrames: any;
  options: CameraOptions[];
  "data-test-id"?: string;
}

export const CameraInputSelectors = ({
  layoutType,
  onClose,
  onOpen,
  onChange,
  fullScreenFrameName,
  layoutFrames,
  options,
}) => {
  // this ref gets the width of both selects together inside the same div, this is necessary
  // to customize the width of each cameras list
  const selectsContainerRef = useRef<HTMLDivElement>();

  // show the respective selector dependent upon which frame has expanded fullscreen
  const layoutView =
    layoutType === LayoutTypes.TWO_VIEW || layoutType === LayoutTypes.FOUR_VIEW;
  const showLeftTopCameraSelector =
    layoutView || fullScreenFrameName === "leftTop";
  const showRightTopCameraSelector =
    layoutView || fullScreenFrameName === "rightTop";
  const showLeftBottomCameraSelector =
    layoutType === LayoutTypes.FOUR_VIEW ||
    fullScreenFrameName === "leftBottom";
  const showRightBottomCameraSelector =
    layoutType === LayoutTypes.FOUR_VIEW ||
    fullScreenFrameName === "rightBottom";

  const cameraControlsSides = [
    showLeftTopCameraSelector ? "leftTop" : null,
    showRightTopCameraSelector ? "rightTop" : null,
    showLeftBottomCameraSelector ? "leftBottom" : null,
    showRightBottomCameraSelector ? "rightBottom" : null,
  ];

  return (
    <div ref={selectsContainerRef} className={styles.selectors}>
      {cameraControlsSides.map(
        (side) =>
          // Must filter the "null" values in the array
          side && (
            <Select
              formControlClass={styles[`${side}Select`]}
              selectClass={styles.layoutsSelect}
              listClass={clsx(styles.layoutsList)}
              menuItemClass={styles.menuItem}
              label={
                layoutType === LayoutTypes.FULLSCREEN
                  ? null
                  : capitalizeText(cameraLabelDisplay(side, layoutType))
              }
              name={side}
              onOpen={() => {
                // show indicator on corresponding side of the screen
                onOpen(side);
              }}
              onClose={onClose}
              onChange={(event) => {
                onChange(event, side);
              }}
              value={layoutFrames[side].cameraId}
              options={options}
              // Must disable showing the camera's thumbnail when selected, the value will be only the camera name instead
              disableLeftSideInsideValue
              // Must customize list's width since it should have the width of both selects together (left & right)
              listWidth={`${selectsContainerRef.current?.offsetWidth}px`}
            />
          )
      )}
    </div>
  );
};
