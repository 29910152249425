/**
 * debounce
 * @param {integer} milliseconds This param indicates the number of milliseconds
 *     to wait after the last call before calling the original function.
 * @param {object} What "this" refers to in the returned function.
 * @return {function} This returns a function that when called will wait the
 *     indicated number of milliseconds after the last call before
 *     calling the original function.
 */
export const debounce = function (milliseconds: number, context: any) {
  const baseFunction = context;
  let timer: number | null = null;
  const wait = milliseconds;

  return function () {
    const self = context;
    const args = arguments;

    function complete() {
      baseFunction.apply(self, args);
      timer = null;
    }

    if (timer) {
      clearTimeout(timer);
    }

    timer = window.setTimeout(complete, wait);
  };
};
