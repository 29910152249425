import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Table} from 'semantic-ui-react';
import {withRouter} from 'react-router-dom';
import DropdownComponent from '../../../../Common/DropdownComponent/DropdownComponent';
import classNames from 'classnames';

class BrandTableRow extends PureComponent {
	redirect = () =>
		this.props.history.push(`/vendors/profile?brId=${this.props.rowData.id}`);

	openDeleteUserModal = () => {
		this.props.openModal(this.props.rowData);
	}

	render() {
		const {name} = this.props.rowData, active = true;
		return (
			<Table.Row className={classNames({'table__row--inactive': !active,})}>
				<Table.Cell onClick={this.redirect} title={[name].join(' ')}>
					<div className="table__username-wrapper table__link display--flex">
						<span>{name}</span>
					</div>
				</Table.Cell>
				<Table.Cell className="table__action-table-cell">
					<div className="justify-content--flex-end">
						<DropdownComponent
							dropdownOptions={[
								...active ? [{
									className: 'dropdown__menu-item--red',
									label: 'Delete',
									text: 'Delete',
									action: this.openDeleteUserModal,
									icon: 'icon-trash-2-outline',
								}] : [],
							]}
						/>
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}

BrandTableRow.defaultProps = {
	rowData: null,
	openModal: () => {
	},
};

BrandTableRow.propTypes = {
	rowData: PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
		logoLocation: PropTypes.string,
		domains: PropTypes.array,
	}),
	openModal: PropTypes.func,
};

export default withRouter(BrandTableRow);
