import moment from "moment";

import LinkIcon from "@mui/icons-material/Link";
import { Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { Chip } from "src/components/legacy/ChipInput/Chip";
import {
  checkCurrentUserAParticipant,
  checkCurrentUserEventCreator,
  checkEventJoinable,
  checkEventStarted,
  participantDisplayValue,
} from "src/domains/Events/utils";
import { MultiPartyEvent, EventStatus } from "src/services/ApiClient/scheduler";

import DomainConstants from "avail-web-ui/domains/DomainConstants";
import { getUserInfo } from "avail-web-ui/services/UserSessionService";

import styles from "./styles.scss";

interface Props {
  event: MultiPartyEvent;
  onJoinCall: () => void;
  onDetailsClick: () => void;
}

export function EventItem({ event, onJoinCall, onDetailsClick }: Props) {
  const host = event.participants.find((p) => p.role === "HOST");
  const panelists = event.participants.filter((p) => p.role !== "HOST");
  const time = formatTime(event.startDate, event.endDate);
  const userInfo = getUserInfo();

  const isEventStarted = checkEventStarted(event);
  const isCurrentUserEventCreator = checkCurrentUserEventCreator(
    event,
    userInfo.id
  );
  const isCurrentUserAParticipant = checkCurrentUserAParticipant(
    event,
    userInfo.loginId
  );
  const isEventEnded = event.status === EventStatus.ended;

  const isEventJoinable = checkEventJoinable(event);

  const copyLink = () => {
    const link = event.eventLink;

    // TODO: add a tooltip to let user know the link is in clipboard?
    navigator.clipboard.writeText(link);
  };

  return (
    <li className={styles.item} data-test-id="event-item">
      <div className={styles.time}>
        <Typography variant="h6">{time}</Typography>
      </div>

      <div className={styles.info}>
        <Typography
          data-test-id="event-title"
          className={styles.infoTitle}
          variant="h5"
        >
          {event.subject}
          {isEventStarted ? (
            <Chip
              label="In Progress"
              size="small"
              className={styles.chip}
              color="success"
            />
          ) : null}
        </Typography>

        <div className={styles.infoItem}>
          <Typography>Facility:</Typography>
          <Typography>{event.hospitalName}</Typography>
        </div>

        <div className={styles.infoItem}>
          <Typography>Surgeon:</Typography>
          <Typography>{event.surgeonName}</Typography>
        </div>

        <div className={styles.infoItem}>
          <Typography>{DomainConstants.label.host}:</Typography>
          <Typography>{participantDisplayValue(host)}</Typography>
        </div>

        <div className={styles.infoItem}>
          <Typography>Panelists:</Typography>
          <Typography>
            {panelists
              .map((p) => {
                if (p.isMember) {
                  return `${p.firstName} ${p.lastName}`;
                } else {
                  return p.email; // guest users should only show emails
                }
              })
              .join(", ")}
          </Typography>
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          className={styles.details}
          variant="text"
          onClick={onDetailsClick}
        >
          {isCurrentUserEventCreator ? "Edit Details" : "View Details"}
        </Button>

        {/* The Join/Start button should only be visible on current day's events */}
        {isEventJoinable ? (
          <Button
            onClick={onJoinCall}
            theme={isEventStarted || isEventEnded ? "green" : "primary"}
            disabled={!isCurrentUserAParticipant}
            data-test-id="event-join-button"
          >
            {isEventStarted || isEventEnded ? "Join" : "Start"}
          </Button>
        ) : null}

        <Button className={styles.copy} variant="text" onClick={copyLink}>
          <LinkIcon />
          Copy Link
        </Button>
      </div>
    </li>
  );
}

function formatTime(startDate: string, endDate: string) {
  // start and end is formatted to be in the users local timezone using their system time
  const start = moment(startDate).format("h:mmA"); // "3:25 pm"
  const end = moment(endDate).format("h:mmA");

  // take their local time zone name so we can put it in the string to let the user know what timezone these time are in
  const t = moment.tz.guess();
  const tzName = moment.tz(t).zoneAbbr();

  return `${start} - ${end} ${tzName}`;
}
