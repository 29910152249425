import { logger } from "../../../common/logger";
import {
  setCallState,
  setCamerasList,
  setFreezeFrameHasUpdate,
  setFreezeFrameState,
  setLayoutFrames,
  setLayoutHasUpdate,
  setZoomState
} from "../../../store/meeting/actions";
import {
  AppState,
  CallState,
  CallStateType,
  FreezeFrameStateType,
  ImageState,
  LayoutFrame,
  MediaStateType,
  MenuOptionType,
  PresetState,
  ZoomStateType
} from "../../../store/models";
import CameraControlMenuItem from "../CameraControlMenuItem";
import MenuItem from "../MenuItem";
import "../MenuItem/MenuItem.scss";
import MenuItemText from "../MenuItemText";
import "./MenuGroup.scss";
import { Nurep } from "availkit-js";
import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { 
  AVKCameraCheck, 
  AVKCameraIdentifier, 
  AVKExternalInputIdentifier, 
  CameraDisplayName, 
  CameraIdentifier 
} from "portalcall/commoncall/components/Utils/CameraUtils";
import { AVKVideoLayoutEvent } from "availkit-js/dist/Models/Events/AVKVideoLayoutEvent";
import { AVKLayout } from "availkit-js/dist/Models/AVKLayout";
import { withFeatureFlag } from "src/hooks/useFeatureFlag";
import { AVKUserPresetEvent } from "availkit-js/dist/Models/Events/AVKUserPresetEvent";

interface StateProps {
  availKit: Nurep | null;
  callSid: string;
  callState: string;
  cameras: Array<AVKCamera | AVKExternalInput>;
  consoleHasExternalInputResizing: boolean;
  freezeFrameState: FreezeFrameStateType;
  imageState: ImageState;
  layoutFrames: LayoutFrame[];
  layoutHasUpdate: boolean;
  mediaState: MediaStateType;
  preset: PresetState;
  ssFFEnabled: boolean;
  zoomState: ZoomStateType;
}

interface DispatchProps {
  setCallState: (callState: CallStateType) => void;
  setLayoutFrames: (layoutFrames: LayoutFrame[]) => void;
  setLayoutHasUpdate: (isUpdated: boolean) => void;
  setCameras: (cameras: Array<AVKCamera | AVKExternalInput>) => void;
  setZoomState: (zoomState: ZoomStateType) => void;
  setFreezeFrameState: (freezeFrameState: FreezeFrameStateType) => void;
  setFreezeFrameHasUpdate: (isUpdated: boolean) => void;
}

interface MenuProps {
  externalInputsImageResizing: boolean;
  selectedCallControl: string;
}

type Props = StateProps & DispatchProps & MenuProps;

class MenuGroup extends React.Component<Props> {
  state = {
    selectedLeftCamera: "",
    selectedRightCamera: "",
    selectedPreset: "none"
  };

  constructor(props: Props) {
    super(props);
    this.showPresetDialog = this.showPresetDialog.bind(this);
  }

  showPresetDialog(): void {
    this.props.setCallState(CallState.NEWPRESET);
  }

  render() {
    const { selectedCallControl, mediaState } = this.props;
    const className = selectedCallControl + "-call-control-menu-group";
    if (mediaState.console.video === "mute") {
      return <div></div>;
    }
    return (
      <div className={className}>
        {selectedCallControl === "left-frame" && this.renderLeftFrame()}
        {selectedCallControl === "right-frame" && this.renderRightFrame()}
        {selectedCallControl === "pinch-zoom" && this.renderPinchZoom()}
        {selectedCallControl === "freeze-frame" && this.renderFreezeFrame()}
        {selectedCallControl === "refresh-frames"}
        {selectedCallControl === "preset" && this.renderPreset()}
      </div>
    );
  }

  setFullScreen(
    onFrame: string,
    inFSMode: boolean,
    layoutFrames: LayoutFrame[]
  ) {
    // If we're going into Full Screen  mode
    if (inFSMode) {
      layoutFrames.forEach((frame: LayoutFrame) => {
        // make all Full Screen buttons not visible
        frame.menuOptions[frame.menuOptions.length - 1].isVisible = false;
        // Set my frame as Full Screen
        frame.isFullScreen = frame.name === onFrame;
      });
      // then disable cameraId's for all but me
      layoutFrames
        .filter((frame: LayoutFrame) => frame.name !== onFrame)
        .map((frame) => (frame.cameraId = ""));
    } else {
      // We're going into Multi-Frame mode
      layoutFrames.forEach((frame: LayoutFrame) => {
        // Enable all the Full Screen buttons
        frame.menuOptions[frame.menuOptions.length - 1].isVisible = true;
        // turn off isFullScreen
        frame.isFullScreen = false;
      });
    }
  }

  handlePresetChange(presetName: string) {
    logger().info("Change preset to " + presetName);
    const { cameras, preset } = this.props;

    /* Handle Layout Change */
    const presetSnapshot = preset.preset[presetName];
    if (!presetSnapshot) {
      return;
    }

    const presetSnapshotClone = presetSnapshot;

    const snapshotLayoutFrames = presetSnapshotClone.layoutFrames;
    const snapshotCameras = presetSnapshotClone.cameras;
    const snapshotZoomState = presetSnapshotClone.zoomState;

    let shouldUpdateLayouts = false;
    if (snapshotLayoutFrames && snapshotLayoutFrames.length > 0) {
      shouldUpdateLayouts = true;
    }

    if (shouldUpdateLayouts) {
      this.setState({ ...this.state, selectedPreset: presetName });
      this.props.setLayoutFrames([...snapshotLayoutFrames]);
    }

    /* Handle Camera Change */
    const updatedCameras: Array<AVKCamera | AVKExternalInput> = [];
    const _self = this;
    let cameraRef: AVKCamera | AVKExternalInput | null;
    // Merge it with snapshotCameras.
    cameras.forEach((camera) => {
      cameraRef = _self.cameraFromSnapshot(
        CameraIdentifier(camera),
        snapshotCameras
      );
      if (cameraRef) {
        updatedCameras.push(JSON.parse(JSON.stringify(cameraRef)));
      } else {
        updatedCameras.push(JSON.parse(JSON.stringify(camera)));
      }
    });

    this.props.setCameras([...updatedCameras]);
    this.props.setZoomState(snapshotZoomState);

    /* Fire a preset event */
    this.sendLoadPresetMessage(snapshotCameras);
  }

  cameraFromSnapshot(
    cameraIdentifier: string,
    camerasList: Array<AVKCamera | AVKExternalInput>
  ): AVKCamera | AVKExternalInput | null {
    let cameraRef: AVKCamera | AVKExternalInput | null = null;
    camerasList.forEach((camera) => {
      if (cameraIdentifier === camera['cameraIdentifier'] || cameraIdentifier === camera['id']) {
        cameraRef = camera;
      }
    });
    return cameraRef;
  }

  sendLoadPresetMessage(cameras: Array<AVKCamera | AVKExternalInput>) {
    const { availKit, callSid, consoleHasExternalInputResizing, externalInputsImageResizing } = this.props;
    const hasExternalInputResizing = consoleHasExternalInputResizing && externalInputsImageResizing;

    if (hasExternalInputResizing) {
      const userPresetEvent = new AVKVideoLayoutEvent(callSid);
      const camerasList = cameras.filter(camera => AVKCameraIdentifier(camera)) as AVKCamera[];
      const externalInputsList = cameras.filter(camera => AVKExternalInputIdentifier(camera)) as AVKExternalInput[];
      const layoutInfo: AVKLayout[] = [];
      cameras.map(camera => {
        const newLayout = new AVKLayout();
        newLayout.id = CameraIdentifier(camera);
        newLayout.label = CameraDisplayName(camera);
        layoutInfo.push(newLayout);
      })
      userPresetEvent.cameras = camerasList;
      userPresetEvent.externalInputs = externalInputsList;
      userPresetEvent.layoutInfo = layoutInfo;
      userPresetEvent.presetEvent = true;

      logger().info(
        `User Preset Event initiated with cameras: ${JSON.stringify(camerasList)} 
        & external inputs: ${JSON.stringify(externalInputsList)}`
      );
  
      availKit?.eventService.broadcast(userPresetEvent);
    } else {
      const avkCameras = cameras as AVKCamera[]
      const userPresetEvent = new AVKUserPresetEvent(callSid);
      userPresetEvent.cameras = avkCameras;

      logger().info(`User Preset Event initiated: ${JSON.stringify(cameras)}`);

      availKit?.eventService.broadcast(userPresetEvent)
    }
  }

  handleCameraChange(inGroup: string, uid: string, label: string, cameraId: string) {
    const { layoutFrames } = this.props;
  
    switch (inGroup) {
      case "left":
        if (layoutFrames[0].isFullScreen) {
          this.getLayoutFrame("left").cameraId = uid;
          this.getLayoutFrame("left").cameraLabel = label;
          this.setFullScreen("left", true, layoutFrames);
        } else {
          if (uid === "left_Full") {
            this.setFullScreen("left", true, layoutFrames);
          } else {
            this.setFullScreen("left", false, layoutFrames);
            Object.assign(layoutFrames.filter(
              (frame) => frame.name === "left"
            )[0], {
              cameraId: uid,
              cameraLabel: label
            });
            this.getLayoutFrame("left").cameraId = uid;
          }
        }
        break;
      case "right":
        if (layoutFrames[1].isFullScreen) {
          this.getLayoutFrame("right").cameraId = uid;
          this.getLayoutFrame("right").cameraLabel = label;
          this.setFullScreen("right", true, layoutFrames);
        } else {
          if (uid === "right_Full") {
            this.setFullScreen("right", true, layoutFrames);
          } else {
            this.setFullScreen("right", false, layoutFrames);
            Object.assign(layoutFrames.filter(
              (frame) => frame.name === "right"
            )[0], {
              cameraId: uid,
              cameraLabel: label
            });
            this.getLayoutFrame("right").cameraId = uid;
          }
        }
        break;
      default:
        break;
    }

    this.props.setLayoutFrames([...layoutFrames]);
    /* Compute zoomState using layoutFrames */
    this.updateZoomStateFromLayoutFrames([...layoutFrames], inGroup);
    this.props.setLayoutHasUpdate(true);
  }

  updateZoomStateFromLayoutFrames(layoutFrames: LayoutFrame[], group: string) {
    const { consoleHasExternalInputResizing, externalInputsImageResizing, zoomState } = this.props;
    const hasExternalInputResizing = consoleHasExternalInputResizing && externalInputsImageResizing;
    let localZoomState: ZoomStateType = {...zoomState};
    /* Updated checked and enabled */
    let isLeftChosen = false;
    let isRightChosen = false;
    let isFullScreen = false;
    let selectedCameraId = "";

    for (let i = 0; i < layoutFrames.length; i++) {
      if (layoutFrames[i].isFullScreen) {
        isFullScreen = true;
      }
      selectedCameraId = layoutFrames[i].cameraId;
      if (group === 'left') {
        isLeftChosen = true;
        localZoomState.radioButtonLeft.checked = true;
        localZoomState.radioButtonRight.checked = false;
        localZoomState.sliderZoom[0].cameraId = layoutFrames[0].cameraId;
      } else {
        isRightChosen = true;
        localZoomState.radioButtonLeft.checked = false;
        localZoomState.radioButtonRight.checked = true;
        localZoomState.sliderZoom[1].cameraId = layoutFrames[1].cameraId;
      }
    }

    const areBothCamerasNotSelected = !isLeftChosen && !isRightChosen;

    if (hasExternalInputResizing) {
      localZoomState.radioButtonLeft.radioEnabled = true;
      localZoomState.radioButtonRight.radioEnabled = true;
      if (areBothCamerasNotSelected) {
        localZoomState.radioButtonLeft.checked = false;
        localZoomState.radioButtonRight.checked = false;
      } else if (!isFullScreen) {
        if (isLeftChosen) {
          localZoomState.radioButtonLeft.checked = true;
          localZoomState.radioButtonRight.checked = false;
        } else if (isRightChosen) {
          localZoomState.radioButtonLeft.checked = false;
          localZoomState.radioButtonRight.checked = true;
        }
      }

      if (isFullScreen) {
        if (isLeftChosen) {
          // enable left radio, disable right PTZ option
          localZoomState.radioButtonLeft.checked = true;
          localZoomState.radioButtonLeft.radioEnabled = true;
          localZoomState.radioButtonRight.radioEnabled = false;
          localZoomState.radioButtonRight.checked = false;
        } else if (isRightChosen) {
          // enable right radio, disabled left PTZ
          localZoomState.radioButtonLeft.checked = false;
          localZoomState.radioButtonLeft.radioEnabled = false;
          localZoomState.radioButtonRight.radioEnabled = true;
          localZoomState.radioButtonRight.checked = true;
        }
      }
    } else {
      localZoomState.radioButtonLeft.radioEnabled = AVKCameraCheck(layoutFrames[0]);
      localZoomState.radioButtonRight.radioEnabled = AVKCameraCheck(layoutFrames[1]);

      /* Front and Overhead aren't selected */
      if (areBothCamerasNotSelected) {
        localZoomState.radioButtonLeft.checked = false;
        localZoomState.radioButtonRight.checked = false;
      } else if (!isFullScreen) {
        if (AVKCameraCheck(layoutFrames[0])) {
          localZoomState.radioButtonLeft.checked = true;
          localZoomState.radioButtonRight.checked = false;
        } else if (AVKCameraCheck(layoutFrames[1])) {
          localZoomState.radioButtonLeft.checked = false;
          localZoomState.radioButtonRight.checked = true;
        }
      }

      if (isFullScreen) {
        if (isLeftChosen) {
          // enable left radio, disable right PTZ option
          localZoomState.radioButtonLeft.radioEnabled = AVKCameraCheck(layoutFrames[0]);
          localZoomState.radioButtonLeft.checked = true;
          localZoomState.radioButtonRight.checked = false;
          localZoomState.radioButtonRight.radioEnabled = false;
        } else if (isRightChosen) {
          // enable right radio, disabled left PTZ
          localZoomState.radioButtonLeft.checked = false;
          localZoomState.radioButtonLeft.radioEnabled = false;
          localZoomState.radioButtonRight.radioEnabled = AVKCameraCheck(layoutFrames[1]);
          localZoomState.radioButtonRight.checked = true;
        }
      }
    }

    this.props.setZoomState(localZoomState);
  }

  makeMenuSettings(name: string, itemId: string): MenuOptionType {
    const settings: MenuOptionType = {
      name,
      id: itemId,
      memberOf: "",
      isVisible: true,
      isDisabled: false,
      css: {
        normal: "call-control-menu-item",
        hover: "call-control-menu-item:hover",
        selected: "call-control-menu-item call-control-menu-item-selected",
        disabled: "call-control-menu-item call-control-menu-item-disabled"
      }
    };
    return settings;
  }

  getLayoutFrame(name: string): LayoutFrame {
    const { layoutFrames } = this.props;
    const results: LayoutFrame[] = layoutFrames.filter((frame) => {
      return name === frame.name;
    });
    if (results.length > 0) {
      return results[0];
    } else {
      return {
        name: "not found",
        isFullScreen: false,
        menuOptions: [],
        cameraId: "",
        cameraLabel: ""
      };
    }
  }

  renderLeftFrame() {
    const ourFrameOptions: LayoutFrame = this.getLayoutFrame("left");
    return (
      <>
        {ourFrameOptions.menuOptions
          .filter((optToShow) => optToShow.isVisible)
          .map((option) => {
            return (
              <MenuItem
                key={option.id}
                settings={option}
                currentSelected={ourFrameOptions.cameraId}
                onClick={() => {
                  this.handleCameraChange(
                    ourFrameOptions.name,
                    option.id,
                    option.name,
                    ourFrameOptions.cameraId
                  )
                }}
              >
                <MenuItemText>{option.name}</MenuItemText>
              </MenuItem>
            );
          })}
      </>
    );
  }

  renderRightFrame() {
    const ourFrameOptions = this.getLayoutFrame("right");
    return (
      <>
        {ourFrameOptions.menuOptions
          .filter((optToShow) => optToShow.isVisible)
          .map((option) => {
            return (
              <MenuItem
                key={option.id}
                settings={option}
                currentSelected={ourFrameOptions.cameraId}
                onClick={() =>{
                  this.handleCameraChange(
                    ourFrameOptions.name,
                    option.id,
                    option.name,
                    ourFrameOptions.cameraId
                  )
                }}
              >
                <MenuItemText>{option.name}</MenuItemText>
              </MenuItem>
            );
          })}
      </>
    );
  }

  renderPinchZoom() {
    return <CameraControlMenuItem />;
  }

  handleFFOClick(which: string) {
    const { freezeFrameState } = this.props;
    freezeFrameState.freezeType = which;
    this.props.setFreezeFrameState(freezeFrameState);
    this.props.setFreezeFrameHasUpdate(true);
  }

  setFFUIOption(defaultOpt: MenuOptionType) {
    /*
    State Table for Left/Right/Fullscreen menu options
        [LRF]    show option
        State :: L  R  F
        000  :: f  f  f
        f00  :: u  f  X
        0f0  :: f  u  X
        ff0  :: u  u  X
        00f  :: X  X  u
        f == frozen :: show "Freeze"
      0/u == unfrozen :: show "Unfreeze"
                    :: X == disabled
    */
    const { layoutFrames, ssFFEnabled } = this.props;
    const inFullScreen = layoutFrames.some(
      (frame: LayoutFrame) => frame.isFullScreen
    );

    const frameIsFrozen = (frameType: string): boolean => {
      const { imageState } = this.props;
      switch (frameType) {
        case "FFO_LEFT":
          return imageState.leftTransferIdentifier !== "";
        case "FFO_RIGHT":
          return imageState.rightTransferIdentifier !== "";
        case "FFO_FULLSCREEN":
        default:
          return imageState.fullscreenTransferIdentifier !== "";
      }
    };

    const ffoShouldBeEnabled = (frameType: string): boolean => {
      const lrFrameFrozen =
        frameIsFrozen("FFO_LEFT") || frameIsFrozen("FFO_RIGHT");
      const fullFrameFrozen = frameIsFrozen("FFO_FULLSCREEN");

      switch (frameType) {
        case "FFO_LEFT":
        case "FFO_RIGHT":
          return !fullFrameFrozen && !inFullScreen;
        case "FFO_FULLSCREEN":
        default:
          return !lrFrameFrozen;
      }
    };

    const ffoIsVisible = (frameType: string): boolean => {
      // 1/2021 - Disabling UI to prevent feature availability at this date
      //  rely upon reducer initialization to mark SS options as invisible
      switch (frameType) {
        case "FFO_LEFT":
        case "FFO_RIGHT":
          return ssFFEnabled;
        default:
          return true;
      }
    };

    const uiOption = { ...defaultOpt };
    uiOption.name =
      (frameIsFrozen(defaultOpt.id) ? "Unfreeze" : "Freeze") +
      " " +
      uiOption.name;
    uiOption.isDisabled = !ffoShouldBeEnabled(defaultOpt.id);
    uiOption.isVisible = ffoIsVisible(defaultOpt.id);

    return uiOption;
  }

  renderFreezeFrame() {
    const { freezeFrameState, ssFFEnabled } = this.props;

    const guiOptions = freezeFrameState.menuOptions
      .map((option) => this.setFFUIOption(option))
      .filter((bizRule) => bizRule.isVisible);

    if (ssFFEnabled) {
      return (
        <>
          {guiOptions.map((option) => {
            return (
              <MenuItem
                key={option.id}
                settings={option}
                currentSelected=""
                onClick={() => this.handleFFOClick(option.id)}
              >
                <MenuItemText>{option.name}</MenuItemText>
              </MenuItem>
            );
          })}
        </>
      );
    } else {
      return <></>;
    }
  }

  renderPreset() {
    const { preset } = this.props;
    const keys = Object.keys(preset.preset);

    return (
      <>
        <div
          className={classNames({
            "preset-list-wrapper": keys.length > 10
          })}
        >
          {keys.map((name, pnum) => {
            return (
              <MenuItem
                settings={this.makeMenuSettings(name, `PRESET${pnum}`)}
                currentSelected={this.state.selectedPreset}
                onClick={() => this.handlePresetChange(`${name}`)}
                key={name}
              >
                <MenuItemText>
                  <div>{name}</div>
                </MenuItemText>
              </MenuItem>
            );
          })}
        </div>
        <MenuItem
          settings={this.makeMenuSettings("Save New Preset", `PRESETSAVE`)}
          currentSelected={""}
          onClick={this.showPresetDialog}
        >
          <MenuItemText>
            <div>Save new preset</div>
          </MenuItemText>
        </MenuItem>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  availKit: state.availKit.availKitInstance,
  callSid: state.meeting.callSid,
  callState: state.meeting.callState,
  cameras: state.meeting.cameras,
  consoleHasExternalInputResizing: state.meeting.consoleHasExternalInputResizing,
  freezeFrameState: state.meeting.freezeFrameState,
  imageState: state.image,
  layoutFrames: state.meeting.layoutFrames,
  layoutHasUpdate: state.meeting.layoutHasUpdate,
  mediaState: state.user.mediaState,
  preset: state.preset,
  ssFFEnabled: state.meeting.enableSSFF,
  zoomState: state.meeting.zoomState,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setCallState: (callState: CallStateType) =>
      dispatch(setCallState(callState)),
    setLayoutFrames: (layoutFrames: LayoutFrame[]) =>
      dispatch(setLayoutFrames(layoutFrames)),
    setLayoutHasUpdate: (isUpdated: boolean) =>
      dispatch(setLayoutHasUpdate(isUpdated)),
    setCameras: (cameras: Array<AVKCamera | AVKExternalInput>) => dispatch(setCamerasList(cameras)),
    setZoomState: (zoomState: ZoomStateType) =>
      dispatch(setZoomState(zoomState)),
    setFreezeFrameState: (freezeFrameState: FreezeFrameStateType) =>
      dispatch(setFreezeFrameState(freezeFrameState)),
    setFreezeFrameHasUpdate: (updated: boolean) =>
      dispatch(setFreezeFrameHasUpdate(updated)),
  };
};

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(withFeatureFlag(MenuGroup));
