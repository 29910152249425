import clsx from "clsx";

import {
  Avatar as MUIAvatar,
  AvatarProps as MUIAvatarProps,
} from "@mui/material";

import styles from "./styles.scss";

interface Props extends MUIAvatarProps {
  "data-test-id"?: string;
  label?: string; // Typically used to show initials
  color?: "steel" | "bright-blue";
  size?: "small" | "medium" | "large";
}

export const Avatar = ({
  color = "steel",
  size = "large",
  label = "",
  alt = "",
  src = "",
  "data-test-id": dataTestId,
}: Props) => {
  return (
    <MUIAvatar
      data-test-id={dataTestId}
      alt={alt}
      src={src}
      classes={{
        root: clsx(
          styles.root,
          styles[`avatar-${size}`],
          styles[`color-${color}`]
        ),
      }}
    >
      {label}
    </MUIAvatar>
  );
};
