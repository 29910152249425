import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Banner } from "src/components/legacy/Banner";
import { useGetUserTrialUsage } from "src/queries/trialUsage";
import { useGetUserById } from "src/queries/users";
import { locations } from "src/utils/locations";

import { setCurrentUserInfoAction } from "avail-web-application/Features/Home/HomeActions";

import { getCurrentUser } from "../../selectors/homeReducer";
import { isTrialUser } from "../../utils/user";

interface ITrialBannerProps {
  // TODO: Determine whether it would be a User or UserDetails type
  currentUser?: any;
}

const TrialBannerInner = ({ currentUser }: ITrialBannerProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: userData } = useGetUserById(currentUser.id);
  const { data } = useGetUserTrialUsage(currentUser);
  const trialUsageInfo = data?.content;
  const fetchedUser = userData?.content;

  useEffect(() => {
    /**
     * This will update user's info on page refresh
     */
    dispatch(setCurrentUserInfoAction(fetchedUser));
  }, []);

  useEffect(() => {
    /**
     * Why here?: It's focused for TRIAL users only, if the user isn't a TRIAL we don't need to detect when the TRIAL usage changes.
     * How it works?: Whenever an Avail Admin upgrades from TRIAL to MEMBER, the usageInfo changes automatically
     * that's when this useEffect works, so to update the user's object
     */
    dispatch(setCurrentUserInfoAction(fetchedUser));
  }, [trialUsageInfo]);

  return (
    <Banner
      show={true}
      type={
        currentUser.memberType === "TRIAL" ? "warning-banner" : "error-banner"
      }
      description={
        currentUser.memberType === "TRIAL" ? (
          <>
            You have{" "}
            <span className="text--bold">
              {trialUsageInfo?.availableTrialMinutes}
            </span>
            /{trialUsageInfo?.addedTrialMinutes} minutes or{" "}
            <span className="text--bold">
              {trialUsageInfo?.availableTrialDays}
            </span>{" "}
            days remaining on your trial account.
          </>
        ) : (
          <>Your trial membership has expired.</>
        )
      }
      actionText={
        currentUser.memberType === "TRIAL" ? "See details" : "Upgrade"
      }
      actionLink={() => history.push(locations.usage)}
    />
  );
};

export const TrialBanner = () => {
  const currentUser = useSelector(getCurrentUser);
  const isUserTrial = isTrialUser(currentUser);
  return <>{isUserTrial && <TrialBannerInner currentUser={currentUser} />}</>;
};
