import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
/* Components */
import { Button, Fields, validators } from '../../../../../../../Common/Components';
import { maximumLength48 } from '../../../../../../../Common/Components/FormFields/validators';
import ModalComponent from '../../../../../../../Common/Components/Modal/Modal';
import { Table } from '../../../../../Common';
import RoomTableRow from '../../Features/RoomProfile/Components/RoomTableRow/RoomTableRow';
/* Actions */
import { deleteRoomAction, getAllRoomTypesAction, getRoomsByDepartmentAction } from '../../Features/DepartmentProfile/DepartmentsActions';
import { notificationService } from '../../../../../../../Common/Services';
import { deleteHospitalRoomAction, getHospitalRoomsAction } from '../../HospitalsActions';

class Rooms extends PureComponent {
	state = {
		isCreateNewRoomModalOpened: false,
		isDeleteModalOpened: false,
		selectedRoom: null,
	};

	componentDidMount() {
    const { profileType } = this.props;
		this.params = {
			...(profileType === 'department' && {departmentId: this.props.departmentId}),
			...(profileType === 'hospital' && {hospitalId: this.props.hospitalId}),
			size: this.state.pageSize,
			page: 0,
		};
		this.props.getAllRoomTypes();
    this.handleRoomQuery(profileType, this.params);
	}

  handleRoomQuery = (type, data) => {
    if (type === 'department') {
      this.props.getRoomsByDepartment(data);
    } else {
      this.props.getHospitalRooms(data);
    }
  }

	onCreateNewRoomHandler = (data) => {
		this.toggleCreateRoomModal();

		if (this.props.departments && !this.props.departments.length) {
			notificationService.warning('You must have at least one Clinical Department in order to create a room!');
			return;
		}

		this.props.onCreateNewRoomHandler(data);
	};

	onConfirmDelete = () => {
    (this.props.profileType === 'department' ? 
      this.props.deleteDepartmentRoom(this.state.selectedRoom.id) : 
      this.props.deleteHospitalRoom(this.state.selectedRoom.id)
    );
		this.toggleDeleteRoomModal();
	}

	onTableFilterHandler = (value, parameter = 'name') => {
    const { profileType } = this.props;
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			...(profileType === 'department' && {departmentId: this.props.departmentId}),
			...(profileType === 'hospital' && {hospitalId: this.props.hospitalId}),
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			...(profileType === 'department' && {departmentId: this.props.departmentId}),
			...(profileType === 'hospital' && {hospitalId: this.props.hospitalId}),
			size: this.params.size,
			page: this.params.page,
			[this.parameter]: this.sortParams,
		};
    this.handleRoomQuery(this.props.profileType, this.params);
	};

	onTableSortHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			...this.params,
			[this.parameter]: this.sortParams,
		};
    this.handleRoomQuery(this.props.roomType, this.params);
	};

	columns = [{
		title: 'Room Name',
		sortValue: 'name',
	}, {
		title: 'Room Type',
		sortValue: 'type',
	}];

	toggleCreateRoomModal = () => {
		this.props.resetReduxForm('roomForm');
		this.setState({ isCreateNewRoomModalOpened: !this.state.isCreateNewRoomModalOpened });
	};

	toggleDeleteRoomModal = (selectedRoom) => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
			selectedRoom,
		});
	}

	renderCreateNewRoomModal = () => {
		const { t } = this.props;

		return (
			<ModalComponent
				open={this.state.isCreateNewRoomModalOpened}
				hasActionButtons={false}
				onCancel={this.toggleCreateRoomModal}
				title="Create new Room"
			>
				<form onSubmit={this.props.handleSubmit(this.onCreateNewRoomHandler)}>
					<Fields.InputField
						className="user-profile__general-input"
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.label')}
						name="room.name"
						validate={[validators.required, validators.minimumLength3, maximumLength48]}
					/>
					{this.props.roomTypes && (<Fields.SelectField
						placeholder="Select room type"
						labelText="Select room type"
						name="room.type"
						options={this.props.roomTypes.map(roomType => ({
							key: roomType,
							value: roomType,
							text: roomType,
						}))}
						validate={validators.required}
					/>)}
					{this.props.departments && this.props.departments.length > 0 && (
						<Fields.MultiselectField
							labelText="Associate room to Clinical Department(s)"
							placeholder="Associate room to Clinical Department(s)"
							name="departments"
							options={this.props.departments.map(department => ({
								key: department.id,
								value: department.id,
								label: department.name,
							}))}
							validate={validators.required}
						/>
					)}

					<div className="button__wrapper">
						<Button isGhost buttonSmall onClick={this.toggleCreateRoomModal}>
							Cancel
						</Button>
						<Button isFull buttonSmall type="submit">
							Create
						</Button>
					</div>
				</form>
			</ModalComponent>
		);
	}

	renderDeleteHospitalModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onConfirmDelete}
			onCancel={this.toggleDeleteRoomModal}
			confirmText="Delete"
		>
			{
				this.state.selectedRoom &&
				<span>
					You are about to delete room <span className="text--bold">{this.state.selectedRoom.name}</span>.
					Would you like to proceed with this action?
				</span>
			}
		</ModalComponent>
	);
	
	render() {
		return (
			<div>
				<Table
					className="table__profile-wrapper"
					tableHeaderClassName="table__profile-wrapper"
					row={RoomTableRow}
					tableData={this.props.rooms ? this.props.rooms : this.props.hospitalRooms}
					columns={this.columns}
					openModal={this.toggleDeleteRoomModal}
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					onSortTableHandler={this.onTableSortHandler}
					search
					sortable
					onAddNewHandler={!this.props.addingNewRoomDisabled ? this.toggleCreateRoomModal : undefined}
					onAddNewTitle={!this.props.addingNewRoomDisabled ? "Create room" : undefined}
					noEntityComponent={
						<div className="justify-content--space-between width--full">
							<div className="profile__right-content-wrapper">No rooms available</div>
						</div>}
					urlParams={this.props.urlParams}
					tableActions={false}
					{...this.props}
				/>
				{this.renderCreateNewRoomModal()}
				{this.renderDeleteHospitalModal()}
			</div>
		);
	}
}

Rooms.propTypes = {};

const mapStateToProps = state => ({
  departmentRooms: state.departmentReducer.departmentRooms,
	hospitalRooms: state.hospitalsReducer.hospitalRooms,
	roomTypes: state.departmentReducer.roomTypes,
});

const mapDispatchToProps = dispatch => ({
	getAllRoomTypes: () => dispatch(getAllRoomTypesAction()),
	getHospitalRooms: hospitalId => dispatch(getHospitalRoomsAction(hospitalId)),
	getRoomsByDepartment: departmentId => dispatch(getRoomsByDepartmentAction(departmentId)),
	deleteHospitalRoom: id => dispatch(deleteHospitalRoomAction(id)),
	deleteDepartmentRoom: id => dispatch(deleteRoomAction(id)),
	resetReduxForm: formName => dispatch(reset(formName)),
});

const RoomsWithTranslation = withTranslation()(Rooms);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'roomForm',
	enableReinitialize: true,
})(RoomsWithTranslation)));
