import { AVKConsoleBluetoothAudioEvent } from "availkit-js/dist/Models/Events/AVKConsoleBluetoothAudioEvent";
import { AVKExternalInputMoveEvent } from "availkit-js/dist/Models/Events/AVKExternalInputMoveEvent";
import { AVKRemoteMutedYouEvent } from "availkit-js/dist/Models/Events/AVKRemoteMutedYouEvent";
import { AVKThumbnailAvailableEvent } from "availkit-js/dist/Models/Events/AVKThumbnailAvailableEvent";
import { FeatureAvailabilityEvent } from "availkit-js/dist/Models/Events/FeatureAvailabilityEvent";
import { NoiseCancellationErrorEvent } from "availkit-js/dist/Models/Events/NoiseCancellationErrorEvent";
import { NoiseCancellationEvent } from "availkit-js/dist/Models/Events/NoiseCancellationEvent";
import { AVKMediaServiceListener } from "availkit-js/dist/Services/Listeners/AVKMediaServiceListener";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import {
  selectIsUserHost,
  selectMeetingState,
} from "src/domains/Beacon/store/meeting/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { setConsoleCapabilitiesThunk } from "src/domains/Beacon/store/stream/thunks/setConsoleCapabilitiesThunk";
import { setConsoleFeaturesThunk } from "src/domains/Beacon/store/stream/thunks/setConsoleFeaturesThunk";
import { selectLocalParticipant } from "src/domains/Beacon/store/twilio/selectors";
import { uiActions } from "src/domains/Beacon/store/ui";
import { UINotificationTypes } from "src/domains/Beacon/store/ui/types";
import { disableAudioTrack } from "src/domains/Beacon/utils/twilio";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";
import UserSessionService from "src/services/UserSessionService";

// AvailKit takes care of sending and receiving all the events being passed
// in and out of the pubnub channel we are in, we have to set up the individual
// listeners (functions) that respond to certain types of events

// In this case, we listen for events being retrieved by the mediaService
// for reference, check `AVKMediaService` on `availkit-js` project.

export const useMediaSubscriptions = () => {
  const dispatch = useDispatch();
  const availKit = AvailKitService.instance;

  const currentUser = UserSessionService.getCachedUserInfo();
  const currentUserLoginId = currentUser.loginId;

  const localParticipant = useAppSelector(selectLocalParticipant);
  const isUserHost = useAppSelector(selectIsUserHost);
  const { mode } = useAppSelector(selectMeetingState);

  useEffect(() => {
    const mediaServiceListener: Partial<AVKMediaServiceListener> = {
      receivedFeatureAvailability,
      onNoiseCancellationError,
      onRemoteMutedYou,
      onConsoleBluetoothAudioChange,
      onNoiseCancellationChange,
      onExternalInputMove,
      onAVKThumbnailAvailable,
    };

    availKit.avkMediaService.addEventListener(mediaServiceListener);

    // When this is unmounted, remove the listeners
    return () =>
      availKit?.avkMediaService.removeEventListener(mediaServiceListener);
  }, [availKit, localParticipant, isUserHost, mode]);

  // Triggered by AVK when there's new thumbnails available
  const onAVKThumbnailAvailable = (_, event: AVKThumbnailAvailableEvent) => {
    logger().info(
      `Received onAVKThumbnailAvailable event ${JSON.stringify(event)}`
    );

    // Must update the react-query for thumbnails to fetch the latest thumbnails
    dispatch(streamActions.toggleThumbnailsFetch());
  };

  // These functions are triggered by AvailKit when their corresponding
  // events are fired through pubnub
  const onRemoteMutedYou = (_, event: AVKRemoteMutedYouEvent) => {
    try {
      // Make sure current user loginId exists in event loginIds to mute
      if ([...event.loginIds].includes(currentUserLoginId)) {
        logger().info(
          `Muting user after remote muted them: ${currentUserLoginId}`
        );

        // dispatch notification to let the user know that they were muted by the host
        dispatch(
          uiActions.triggerNotification(
            UINotificationTypes.HOST_MUTES_PARTICIPANT
          )
        );

        // disable the local audio tracks, then update the UI state of the mic to muted
        disableAudioTrack(localParticipant);
        dispatch(uiActions.muteMicrophone());
      }
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useMediaSubscriptions",
        },
        "Error while receiving Remote muted you.",
        error?.message
      );
    }
  };

  const onNoiseCancellationError = (_, event: NoiseCancellationErrorEvent) => {
    logger().info("Received noiseCancellationError", event);
  };

  const onConsoleBluetoothAudioChange = (
    _,
    event: AVKConsoleBluetoothAudioEvent
  ) => {
    try {
      const { isBluetoothDevice } = event;

      // if isBluetoothDevice is true then we need to set this flag to false and vice versa
      dispatch(streamActions.setConsoleAudioIsBuiltInMic(!isBluetoothDevice));

      const notificationType = isBluetoothDevice
        ? UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BLUETOOTH
        : UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BUILT_IN_MIC;

      dispatch(uiActions.triggerNotification(notificationType));
      // noise cancellation is not supported with bluetooth
      dispatch(streamActions.setNoiseCancellationAvailable(!isBluetoothDevice));
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useMediaSubscriptions",
        },
        "Error while receiving Console bluetooth audio change.",
        error?.message
      );
    }
  };

  const onNoiseCancellationChange = (_, event: NoiseCancellationEvent) => {
    try {
      const { noiseCancellation: isEnabled } = event;

      dispatch(streamActions.setNoiseCancellationEnabled(isEnabled));
      const notificationType = event.noiseCancellation
        ? UINotificationTypes.CONSOLE_NOISE_REDUCTION_ON
        : UINotificationTypes.CONSOLE_NOISE_REDUCTION_OFF;

      if (!isUserHost) {
        dispatch(uiActions.triggerNotification(notificationType));
      }
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useMediaSubscriptions",
        },
        "Error while receiving Noise cancellation change.",
        error?.message
      );
    }
  };

  // This event is fired in
  // - MP calls when Console has joined and Webcall is ready, will send all its features state
  // - P2P calls when Console receives `NRKCameraDiscoveryEvent` that is broadcasted by Tungsten in `useCameraDiscovery`
  const receivedFeatureAvailability = (_, event: FeatureAvailabilityEvent) => {
    try {
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Console Capabilities",
          fileInfo: "useMediaSubscriptions/receivedFeatureAvailability",
        },
        `Console capabilities received: ${JSON.stringify(event?.features)}`
      );

      // Console capabilities are for let us know what the Console is capable of support
      dispatch(setConsoleCapabilitiesThunk({ features: event?.features }));

      // Console features is the state true/false per feature coming from Console
      dispatch(setConsoleFeaturesThunk({ features: event?.features }));
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "Console Capabilities",
          fileInfo: "useMediaSubscriptions/receivedFeatureAvailability",
        },
        "Error while receiving Feature availability.",
        error?.message
      );
    }
  };

  const onExternalInputMove = (_, event: AVKExternalInputMoveEvent) => {
    logger().info(`onExternalInputMove - ${event}`);
  };
};
