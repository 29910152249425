import React from "react";
import { NavLink as RouterLink } from "react-router-dom";

import clsx from "clsx";

import styles from "./styles.module.scss";

interface IProps {
  to: string;
  children: React.ReactNode;
  id?: string;
  className?: string;
  disabled?: boolean;
  "aria-label"?: string;
}

/**
 * Bare router link used to surround elements with a link that will take the user somewhere
 * no extra styling on this so use it in conjuction with another component
 */
export const Link = (props: IProps) => {
  if (props.disabled) {
    return (
      <span id={props.id} className={clsx(styles.routerLink, props.className)}>
        {props.children}
      </span>
    );
  }
  return (
    <RouterLink
      id={props.id}
      to={props.to}
      aria-label={props["aria-label"]}
      className={clsx(styles.routerLink, props.className)}
    >
      {props.children}
    </RouterLink>
  );
};

/**
 * Link with the correct styling which goes to the specified URL
 * Use this in places where you want the user to be able to right click and open in new tab
 */
export const StyledLink = (props: IProps) => {
  return (
    <RouterLink
      id={props.id}
      to={props.to}
      className={`${styles.routerLink} ${styles.link} ${props.className}`}
    >
      {props.children}
    </RouterLink>
  );
};

interface IActionLinkProps {
  onClick: (event?: any) => void;
  text: React.ReactChild;
  id?: string;
  className?: string;
  context?: string;
}

/**
 * If you need an <a> (anchor) with the correct style and perhaps an onClick for it
 * but you dont want to give it an href and fix the text discoloration yourself, use this component.
 *
 * Pass in "text" prop with a string to automatically style that text. Note that "text" prop is a react child type so if you need to
 * use an icon or something then you can do so in the "text" prop
 */
export const ActionLink = React.forwardRef(
  (props: IActionLinkProps, ref: React.Ref<HTMLSpanElement>) => {
    return (
      <span
        className={clsx(styles.link, props.className)}
        onClick={(e) => props.onClick(e)}
        id={props.id}
        data-link="1"
        data-context={props.context}
        ref={ref}
      >
        {props.text}
      </span>
    );
  }
);
