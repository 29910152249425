import { securityService } from ".";
import SessionStorageService from "../../../../src/services/SessionStorageService";
import { getIsLoaderActive } from "../../Features/Home/HomeSelectors";
import resources from "../Constants/resources";
import LocalStorageService from "./localStorageService";
import axios from "axios";
import { locations } from "src/utils/locations";

const getNewRefreshToken = (refresh_token) => {
	const appUrl = process.env.REACT_APP_API_URL;
	const microServiceUrl = "auth";
	const params = { token: refresh_token };

	return axios({
		url: `${appUrl}/${microServiceUrl}/${resources.refreshToken}`,
		method: "POST",
		headers: {
			Authorization: securityService.getTokenForRequest(),
		},
		params,
	}).then(
		(response) => response.data,
		({ response }) => Promise.reject(response && response.data)
	);
};

export default class InterceptorService {
	static setupResponseInterceptors(toggleLoaderAction, logoutAction, store) {
		axios.interceptors.request.use((request) => {
			if (!getIsLoaderActive(store.getState())) {
				toggleLoaderAction();
			}
			return request;
		});
		axios.interceptors.response.use(
			(response) => {
				if (getIsLoaderActive(store.getState())) {
					toggleLoaderAction();
				}
				return response;
			},
			(error) => {
				const { response } = error;
				const { url } = response.config;

				if (getIsLoaderActive(store.getState())) {
					toggleLoaderAction();
				}
        
        if (response?.status === 400 && url.includes("/settings/mfa-phone-otp")) {
          return {
						data: {
							errorMessage: response.data?.message,
							statusCode: response.status,
						},
					};
        }

				if (
					response &&
					(response.status === 401 || response.status === 429) &&
					url.includes("/settings/mfa-phone-otp") ||
          url.includes("/settings/mfa-phone-resend-otp")
				) {
					return {
						data: {
							errorMessage: response.data?.message,
							triesRemaining: response.data?.triesRemaining,
							statusCode: response.status,
						},
					};
				}

				if (
					response &&
					response.status === 401 &&
					!url.includes("/auth")
				) {
					const refresh_token = SessionStorageService.get(
						"avail_refresh_token"
					);

					return getNewRefreshToken(refresh_token).then(
						(response) => {
							if (response.error) {
								logoutAction();
								return Promise.reject(error);
							}

							const {
								access_token: access,
								refresh_token: refresh,
							} = response;

							if (!access || !refresh)
								return Promise.reject(error);

							securityService.login(access, refresh);

							// eslint-disable-next-line no-param-reassign
							error.config.headers.Authorization = `Bearer ${access}`;

							return axios.request(error.config);
						},
						(error) => {
							if (error.errorCode === 401) {
								securityService.logout();
								// This refresh, instates the Redux state to defaults
								window.location.href =
									window.location.hostname + locations.login;
							}
						}
					);
				}

				// mfa-authentication
				if (
					(response?.status === 401 || response?.status === 429 || response?.status === 423) 
          && (url.includes("/auth/verify-mfa-otp") ||
						url.includes("/auth/resend-mfa-otp") ||
            url.includes("/auth/login"))
				) {
					return {
						data: {
							errorMessage: response.data?.message,
							statusCode: response.status,
						},
					};
				}

				return Promise.reject(error);
			}
		);
		axios.interceptors.request.use((config) => {
			// the EULA asset is managed by DevOps team and is hosted with different
			// rules so adding this header will cause an error.
			if (config.url.includes("/eula")) {
				const headerWithoutClientVersion = {
					...config.headers,
				};
				delete headerWithoutClientVersion["X-AVAIL-CLIENT-VERSION"];

				return {
					...config,
					headers: {
						...headerWithoutClientVersion,
					},
				};
			}

			return config;
		});
	}
}
