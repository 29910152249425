import { MEETING } from "./MeetingActionTypes";

export const setIncomingCallAction = (data) => ({
	type: MEETING.MEETING_CALL_SET_INCOMING,
	payload: data,
});

export const setCallBackAction = mode => ({
	type: MEETING.MEETING_CALL_SET_CALLING,
	payload: mode,
});

export const sendCallbackAction = meetingData => ({
	type: MEETING.MEETING_SEND_CALLBACK,
	payload: meetingData,
});

export const setCallingMeetingInfoAction = meetingData => ({
	type: MEETING.MEETING_CALL_SET_CALLING_MEETING_INFO,
	payload: meetingData,
});

export const cancelCallbackAction = meetingData => ({
	type: MEETING.MEETING_CANCEL_CALLBACK,
	payload: meetingData,
});

export const updateMeetingInfoAction = (info) => ({
	type: MEETING.MEETING_UPDATE_CALL_INFO,
	payload: info,
});

export const sendMeetingCallAcceptAction = (data) => ({
	type: MEETING.MEETING_CALL_ACCEPT,
	payload: data,
});

export const sendMeetingCallRejectAction = (data) => ({
	type: MEETING.MEETING_CALL_REJECT,
	payload: data,
});

export const setTwilioTokenAction = token => ({
	type: MEETING.MEETING_SET_TWILIO_TOKEN,
	payload: token,
});
