import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { selectFreezeFrameState } from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { selectHasConsoleJoined } from "src/domains/Beacon/store/twilio/selectors";
import { uiActions } from "src/domains/Beacon/store/ui";
import { drawPresenceFreezeFrameImage } from "src/domains/Beacon/utils/canvas";
import {
  getPresenceForFreezeFrameById,
  PresenceServerConsoleDetails,
} from "src/services/ApiClient/presence";

// Freeze Frame images must be retrieved only once the Participant has joined the call
export const useGetFreezeFrames = (
  sessionDetails: PresenceServerConsoleDetails
) => {
  const { callSid } = useAppSelector(selectMeetingState);
  const freezeFrameState = useAppSelector(selectFreezeFrameState);
  const consoleHasJoined = useAppSelector(selectHasConsoleJoined);
  const dispatch = useDispatch();

  // Freeze Frame should only be retrieved once at the beginning of the call, then
  // the state should be stored
  useEffect(() => {
    const getFreezeFrameState = async () => {
      const freezeFrameId = sessionDetails.freezeFrames[0].id;
      const imageData = await getPresenceForFreezeFrameById(
        callSid,
        freezeFrameId.toString()
      );

      // draws the freeze frame image coming from Presence Server
      // inside the Canvas
      drawPresenceFreezeFrameImage(imageData);

      // Stores the FreezeFrame state
      dispatch(
        streamActions.setFreezeFrame({
          type: "fullscreen",
          active: true,
          transferId: freezeFrameId.toString(),
          imageData,
        })
      );
    };

    if (sessionDetails?.freezeFrames.length > 0) {
      getFreezeFrameState();
    }
  }, [sessionDetails]);

  // If Freeze Frame is active and Console has joined, we show the orange indicator,
  // this useEffect takes care of that
  useEffect(() => {
    if (consoleHasJoined && freezeFrameState.active) {
      // Shows the Frozen indicator
      dispatch(
        uiActions.setIndicator({
          color: "orange",
          label: "Frozen",
          show: true,
        })
      );
    }
  }, [consoleHasJoined, freezeFrameState.active]);
};
