import ApiQuery from "src/services/ApiClient/ApiQuery";
import { PartnerIframeResponse } from "src/services/ApiClient/integrations";
import { PARTNERSHIP_API_URL } from "src/services/ApiClient/utils";
import { encodeParamWithSpaces } from "src/utils/url";

// Returns the third party integration iframe url to be displayed
export const getPartnerIframe = (
  procedureName: string,
  procedureType: string,
  eventId: string,
  hospitalName: string
) => {
  return ApiQuery<PartnerIframeResponse>(
    `${PARTNERSHIP_API_URL}/tungsten/iframe?procedure_name=${encodeParamWithSpaces(
      procedureName
    )}&procedure_type=${encodeParamWithSpaces(
      procedureType
    )}&event_id=${eventId}&facility_name=${encodeParamWithSpaces(hospitalName)}`
  );
};
