import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { handleLayoutChangeThunk } from "src/domains/Beacon/store/stream/thunks/handleLayoutChangeThunk";
import { LayoutFrames } from "src/domains/Beacon/store/stream/types";
import { logger } from "src/logging/logger";

// closes the current fullscreen view back to whatever the user had before (split screen view)
export const closeFullscreenThunk = createAsyncThunk<
  // Return type of the thunk
  void,
  // First argument to the payload creator
  { externalInputsImageResizing: boolean; newLayoutFrames?: LayoutFrames },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "stream/closeFullscreenThunk",
  (
    { externalInputsImageResizing, newLayoutFrames },
    { getState, dispatch }
  ) => {
    logger().info("User is closing fullscreen");
    const { stream } = getState();
    const { layoutFrames } = stream;

    // updating left/right layout frames with full screen set to false
    const newCurrentLayoutFrames = {
      leftTop: {
        ...layoutFrames.leftTop,
        isFullScreen: false,
      },
      rightTop: {
        ...layoutFrames.rightTop,
        isFullScreen: false,
      },
      leftBottom: {
        ...layoutFrames.leftBottom,
        isFullScreen: false,
      },
      rightBottom: {
        ...layoutFrames.rightBottom,
        isFullScreen: false,
      },
    } as LayoutFrames;

    dispatch(
      handleLayoutChangeThunk({
        externalInputsImageResizing,
        newLayoutFrames: newLayoutFrames ?? newCurrentLayoutFrames,
      })
    );
  }
);
