import { CONSOLES } from './ConsolesActionTypes';

export const createNewConsoleAction = (consoleData, isHospital, param) => ({
	type: CONSOLES.CREATE_NEW_CONSOLE,
	consoleData,
	isHospital,
	param
});

export const getAllConsolesAction = payload => ({
	type: CONSOLES.GET_ALL_CONSOLES,
	payload,
});

export const deleteConsoleAction = (consoleId, hospitalId) => ({
	type: CONSOLES.DELETE_CONSOLE,
	consoleId,
	hospitalId,
});

export const activateConsoleAction = (consoleId, data) => ({
	type: CONSOLES.ACTIVATE_CONSOLES,
	consoleId,
	data
})

export const getConsoleByIdAction = consoleId => ({
	type: CONSOLES.GET_CONSOLE_BY_ID,
	payload: consoleId,
});

export const updateConsoleAction = (consoleId, data) => ({
	type: CONSOLES.UPDATE_CONSOLE,
	consoleId,
	data,
});

export const getConsolesByHospitalAction = payload => ({
	type: CONSOLES.GET_CONSOLES_BY_HOSPITAL,
	payload,
});

export const selectConsoles = payload => ({
	type: CONSOLES.SELECT_CONSOLES,
	payload,
})
