import React from 'react';
import cx from 'classnames';
import { Button } from 'semantic-ui-react';
import { Fields, validators } from '../../../../../../../Common/Components';

export const customInput = (props) => {
	const { input, type, meta } = props;
	return (
		<div
			className={cx(
				'custom-input-container',
				{ 'flex-row-reverse': type === 'checkbox' },
				{ dirty: meta.dirty || meta.initial },
			)}
			style={{ width: 250 }}
		>
			<Fields.InputField
				style={{
					width: 250,
				}}
				{...input}
				type={type}
				autoFocus={props.autoFocus}
				placeholder="Email"
			/>
		</div>
	);
};


export const emails = ({ fields }) => (
	<div className="custom-field-array-container" >
		{fields.map((code, index) => (
			<div
				key={code}
				className="field-array-item justify-content--flex-end"
				style={{ justifyContent: 'flex-start' }}
			>
				<Fields.InputField
					className="user-profile__general-input"
					name={code}
					type="text"
					component={customInput}
					style={{ width: 'inherit !important' }}
					validate={[
						validators.email,
					]}
					autoFocus
				/>
				<Button
					className="user-profile__general-input-index"
					isGhost
					type="button"
					onClick={() => fields.remove(index)}
				>
					&times;
				</Button>
			</div>
		))}
		<Button
			type="button"
			className="user-profile__general-input-button2"
			onClick={() => fields.push()}
		>
			Add {!fields.length ? 'Participant email(s)' : 'Another email'}
		</Button>
	</div>
);
