import { AVKFreezeFramePostedEvent } from "availkit-js/dist/Models/Events/AVKFreezeFramePostedEvent";
import { AVKFreezeFrameService } from "availkit-js/dist/Services/AVKFreezeFrameService";

import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { uiActions } from "src/domains/Beacon/store/ui";
import { drawPresenceFreezeFrameImage } from "src/domains/Beacon/utils/canvas";
import { logger, LoggerLevels } from "src/logging/logger";
import { getPresenceForFreezeFrameById } from "src/services/ApiClient/presence";
import UserSessionService from "src/services/UserSessionService";

// Must draw the posted FF image for all Participants if host has done freeze frame
export const useOnReceiveFreezeFramePosted = () => {
  const { callSid } = useAppSelector(selectMeetingState);
  const user = UserSessionService.getCachedUserInfo();
  const dispatch = useDispatch();

  // Listener only for participants, when host does Freeze Frame, participants
  // receive this event in order to fetch the image and visualize it
  const onReceiveFreezeFramePosted = async (
    freezeFrameService: AVKFreezeFrameService,
    event: AVKFreezeFramePostedEvent
  ) => {
    try {
      logger().info("Receive Freeze Frame Posted");
      if (event.sender === user.loginId) {
        // noop if current user sent clearFF
        return;
      }

      // Must get the FF imageData from Presence
      const { transferIdentifier: freezeFrameId } = event;
      if (freezeFrameId) {
        const imageData = await getPresenceForFreezeFrameById(
          callSid,
          freezeFrameId.toString()
        );

        // draws the freeze frame image coming from Presence Server
        // inside the img tag for participants
        drawPresenceFreezeFrameImage(imageData);

        // Stores the FreezeFrame state
        dispatch(
          streamActions.setFreezeFrame({
            type: "fullscreen",
            active: true,
            transferId: freezeFrameId.toString(),
            imageData,
          })
        );

        // Shows the orange indicator with "Frozen" message
        dispatch(
          uiActions.setIndicator({
            color: "orange",
            label: "Frozen",
            show: true,
          })
        );
      }
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "onReceiveFreezeFramePosted",
        },
        "Error while receiving Freeze Frame Posted event.",
        error?.message
      );
    }
  };

  return onReceiveFreezeFramePosted;
};
