import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
} from "@mui/material";

interface Props extends CheckboxProps {
  label?: string;
  "data-test-id"?: string;
}

export const Checkbox = ({
  color = "primary",
  disabled = false,
  size = "medium",
  required = false,
  label,
  name,
  "data-test-id": dataTestId,
  ...props
}: Props) => {
  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          data-test-id={dataTestId}
          {...props}
          name={name}
          disabled={disabled}
          required={required}
          size={size}
          color={color}
        />
      }
      label={label}
    />
  );
};
