import { TELESTRATION_VIDEO_DIMENSIONS_ADJUST_BUFFER } from "src/domains/Beacon/constants";
import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { clearCanvasThunk } from "src/domains/Beacon/store/telestration/thunks/clearCanvasThunk";
import { drawRemoteAnnotationsThunk } from "src/domains/Beacon/store/telestration/thunks/drawRemoteAnnotationsThunk";
import { drawTelestrationHistoryThunk } from "src/domains/Beacon/store/telestration/thunks/drawTelestrationHistoryThunk";
import { getVideoDimensions } from "src/domains/Beacon/utils/canvas";
import { logger, LoggerLevels } from "src/logging/logger";
import { timeoutCallback } from "src/utils/timeout";

export const redrawDueResizingThunk = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  try {
    const redraw = () => {
      const {
        telestration: { presenceAnnotations, telestrationHistory, isResizing },
        ui: {
          sidePanel: { transitionEnded },
        },
      } = getState();

      if (typeof transitionEnded === "boolean") {
        // SidePanel transition is still active, do nothing until transition
        // ends to reduce recalculations and loggers.
        return;
      }

      if (isResizing) {
        // if resize of Console Video element still taking place: return to reduce recalculations and loggers.
        return;
      }

      const areThereDrawings =
        presenceAnnotations.length > 0 || telestrationHistory.length > 0;

      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Telestration",
        },
        `Resizing event received, redrawing Telestration`
      );

      // update to new video element dimensions
      const newDimensions = getVideoDimensions();
      dispatch(telestrationActions.setVideoDimensions(newDimensions));

      if (areThereDrawings) {
        logger().info(
          `There are drawings, clearing Canvas in order to redraw Telestration`
        );
        // clear canvas of previous telestrations if there are any
        dispatch(clearCanvasThunk());

        // redraw
        dispatch(drawRemoteAnnotationsThunk());
        dispatch(drawTelestrationHistoryThunk());
      }
    };

    // allow time for the resize event re-calculations to take place before redrawing
    timeoutCallback(redraw, TELESTRATION_VIDEO_DIMENSIONS_ADJUST_BUFFER);
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      {
        feature: "Telestration",
      },
      "Error occurred while receiving a resize event. Couldn't redraw telestration"
    );
  }
};
