import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';
import allUserTypes from '../../../../../../../../Common/Constants/userTypes';
import UserService from '../../../../../../../../Common/Services/userService';
import { ProfileImage, Pill } from '../../../../../../Common';


class OrganisationUsersRow extends PureComponent {
	render() {
		const { firstName, lastName, userTypes } = this.props.rowData;

		return (
			<Table.Row>
				<Table.Cell onClick={this.redirect} title={`${firstName} ${lastName}`}>
					<div className="admins-and-managers-item">
						<ProfileImage
							size="sm"
							className={`profile-image--${allUserTypes[userTypes[0]].className} `}
							initials={UserService.getUserInitials(firstName, lastName)}
						/>
						<span>{`${firstName} ${lastName}`}</span>
					</div>
				</Table.Cell>
				<Table.Cell>
					<Pill
						class={`user-type--${allUserTypes[userTypes[0]].className} `}
						title={allUserTypes[userTypes[0]].title}
					/>
				</Table.Cell>
			</Table.Row>
		);
	}
}

export default OrganisationUsersRow;
