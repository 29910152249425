import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";
import { AVKTelestrationEndReportEvent } from "availkit-js/dist/Models/Events/AVKTelestrationEndReportEvent";
import { NAcknowledgement } from "availkit-js/dist/Models/Events/NAcknowledgement";
import { AVKAnnotationService } from "availkit-js/dist/Services/AVKAnnotationService";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectIsUserHost } from "src/domains/Beacon/store/meeting/selectors";
import { clearLocalAnnotationsThunk } from "src/domains/Beacon/store/telestration/thunks/clearLocalAnnotationsThunk";
import { drawReceivedPointsLiveThunk } from "src/domains/Beacon/store/telestration/thunks/drawReceivedPointsLiveThunk";
import { onReceiveAVKTelestrationEndReportThunk } from "src/domains/Beacon/store/telestration/thunks/onReceiveAVKTelestrationEndReportThunk";
import { onReceiveAcknowledgementThunk } from "src/domains/Beacon/store/telestration/thunks/onReceiveAcknowledgementThunk";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

export const useTelestrationSubscriptions = () => {
  const dispatch = useDispatch();
  const isHostUser = useAppSelector(selectIsUserHost);
  const availKit = AvailKitService.instance;

  const onAVKTelestrationStart = (): void => {
    // intentionally left blank, no current actions need to take place
  };

  // live draws Telestration from host
  const onAVKVideoAnnotation = (
    annotationService: AVKAnnotationService,
    annotationEvent: AVKAnnotationEvent
  ): void => {
    try {
      // TODO: if we ever enable drawing for more than one user, this condition would be:
      // if (annotationEvent.sender !== currentUser.joinId)
      if (!isHostUser) {
        /* These requests must be honored only on participant */
        dispatch(drawReceivedPointsLiveThunk(annotationEvent));
      }
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useTelestrationSubscriptions",
        },
        "Error occurred while receiving AVKVideoAnnotation event",
        error?.message
      );
    }
  };

  const onAVKTelestrationEnd = (): void => {
    // intentionally left blank, no current actions need to take place
  };

  const onAVKTelestrationClearReceived = () => {
    try {
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Telestration",
        },
        "Received AVKTelestrationClear. Clearing local annotations"
      );
      // clears Easel UI & telestration data
      dispatch(clearLocalAnnotationsThunk());
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useTelestrationSubscriptions",
        },
        "Error occurred while receiving AVKTelestrationClear event",
        error?.message
      );
    }
  };

  // TODO: turn this into a thunk => get rid of updateListenerState
  const onReceiveAVKTelestrationEndReport = (
    annotationService: AVKAnnotationService,
    event: AVKTelestrationEndReportEvent
  ): void => {
    dispatch(onReceiveAVKTelestrationEndReportThunk(event));
  };

  const onReceiveAcknowledgement = (
    annotationService: AVKAnnotationService,
    acknowledgementEvent: NAcknowledgement
  ): void => {
    dispatch(onReceiveAcknowledgementThunk(acknowledgementEvent));
  };

  const telestrationListener = {
    onAVKTelestrationStart,
    onAVKVideoAnnotation,
    onAVKTelestrationEnd,
    onAVKTelestrationClearReceived,
    onReceiveAVKTelestrationEndReport,
    onReceiveAcknowledgement,
  };

  // add telestration eventListener
  useEffect(() => {
    if (!availKit || isHostUser) {
      return;
    }
    availKit.avkAnnotationService.addEventListener(telestrationListener);

    return () =>
      availKit.avkAnnotationService.removeEventListener(telestrationListener);
  }, [availKit]);
};
