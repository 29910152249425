// TODO: linter complaining! add no-shadow entry in tsconfig.json, or fix this

/* eslint-disable @typescript-eslint/no-shadow */
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Button } from "src/components/legacy/Button";
import { LoginPage } from "src/domains/Login";
import { useOnPageRefresh } from "src/hooks/useOnPageRefresh";
import { clearUserSession } from "src/utils/clearUserSession";

import { mfaSetupPhoneNumberAction } from "avail-web-application/Features/Authentication/AuthenticationActions";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

import styles from "./styles.scss";

const SetupPhone = ({ mfaSetupPhoneNumberAction, user }) => {
  useOnPageRefresh(() => clearUserSession());

  const { handleSubmit } = useForm({
    mode: "onChange",
  });

  const onSubmit = () => {
    mfaSetupPhoneNumberAction({
      user,
      setupPhone: true,
    });
  };

  const handleMayBeLater = () => {
    mfaSetupPhoneNumberAction({
      user,
      setupPhone: false,
    });
  };

  return (
    <LoginPage className={styles.mfaSetupPhone}>
      <div className={styles.mfaPhoneHeading}>
        {DomainConstants.label.mfaPhoneSetup}
      </div>
      <div className={styles.mfaPhoneWidth}>
        {DomainConstants.label.mfaPhoneSetupText}
      </div>
      <div className={styles.mfaPhoneWidth}>
        <form className={styles.mfaPhoneForm} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.mfaPasscodeAlignCenter}>
            <Button
              disableRipple
              variant="text"
              onClick={handleMayBeLater}
              className={`${styles.mfaPhoneButton} ${styles.singularWidth}`}
            >
              {DomainConstants.label.mfaMaybeLater}
            </Button>
            {/* TODO: move styles later */}
            <Button
              disableRipple
              style={{
                width: "50%",
                backgroundColor: "#3ca65e",
                borderRadius: "2rem",
              }}
              variant="contained"
              type="submit"
              className={`${styles.mfaPhoneButton} ${styles.singularWidth}`}
            >
              {DomainConstants.label.mfaYes}
            </Button>
          </div>
        </form>
      </div>
    </LoginPage>
  );
};

const mapStateToProps = (state) => ({
  user: state.homeReducer.currentUser,
});

const mapDispatchToProps = {
  mfaSetupPhoneNumberAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SetupPhone)
);
