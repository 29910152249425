import { ReactNode, useRef, useState } from "react";

import clsx from "clsx";

import { Check } from "@mui/icons-material";
import { default as MUIFormControl } from "@mui/material/FormControl";
import { default as MUIInputLabel } from "@mui/material/InputLabel";
import { default as MUIMenuItem } from "@mui/material/MenuItem";
import { default as MUISelect, SelectProps } from "@mui/material/Select";

import { Font } from "src/components/Font";
import { useScssVariable } from "src/hooks/useScssVariable";

import styles from "./styles.scss";

export interface ISelectOption {
  value: any; // TODO: add a value Type
  title: string; // white text of an option
  subtitle?: string; // grey small text below the title
  leftSide?: ReactNode; // optional left content of an option
  rightSide?: ReactNode; // optional right content of an option
  disabled?: boolean; // disables the option if true
}

interface ISelectOptionProps {
  option: ISelectOption;
  selectedIndicator?: boolean;
  value: any; // TODO: add a value Type
  disableLeftSideInsideValue?: boolean;
}

const SelectOption = ({
  option,
  selectedIndicator = false,
  value,
  disableLeftSideInsideValue,
}: ISelectOptionProps) => {
  return (
    <>
      {option?.leftSide && !disableLeftSideInsideValue && (
        <div className={styles.leftContent} data-test-id="left-option-content">
          {option.leftSide}
        </div>
      )}
      <div className={styles.content} data-test-id="option-content">
        <Font variant="h3" className={styles.title}>
          {option.title}
        </Font>
        <Font variant="b3" className={styles.subtitle}>
          {option.subtitle}
        </Font>
      </div>
      <div className={styles.rightSide} data-test-id="right-option-content">
        {option?.rightSide && <div>{option.rightSide}</div>}
        {selectedIndicator &&
          (option.value === value ? (
            <Check className={styles.checkIcon} data-test-id="icon" />
          ) : null)}
      </div>
    </>
  );
};

interface IProps extends SelectProps {
  label: string;
  name: string;
  options?: ISelectOption[];
  // CSS class for the Select's form control
  formControlClass?: string;
  // CSS class for the Select component
  selectClass?: string;
  listClass?: string;
  menuItemClass?: string;
  "data-test-id"?: string;
  theme?: "dark" | "light";
  // if true, shows the blue Check icon of the selected option
  selectedIndicator?: boolean;
  required?: boolean;
  error?: boolean;
  listWidth?: string; // Must be a CSS value
  // If true, the value selected won't display the option's leftSide component
  disableLeftSideInsideValue?: boolean;
}

export const Select = ({
  name,
  label,
  options = [],
  formControlClass,
  selectClass,
  listClass,
  menuItemClass,
  "data-test-id": dataTestId = "select",
  selectedIndicator = false,
  value,
  theme = "dark",
  required = false,
  error = false,
  disabled = false,
  listWidth,
  disableLeftSideInsideValue,
  ...props
}: IProps) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>();

  // Passes the drawer width to a Scss var, './styles.scss'
  // Allows to make the Select's list width match with the Select width
  const { ref } = useScssVariable<HTMLDivElement>(
    "--list-width",
    listWidth ?? `${selectRef.current?.offsetWidth}px`
  );

  // TODO: cleanup the use of "classNames" to override CSS in MUI components. Use "classes" instead to remove the use of !important, see ThicknessSlider for an example
  return (
    <MUIFormControl
      ref={selectRef}
      sx={{ m: 1, width: "100%" }}
      classes={{ root: clsx(styles.root, styles.formControl) }}
      className={formControlClass}
    >
      <MUIInputLabel
        id={`select-label`}
        className={clsx(styles.label, {
          [styles.disabled]: disabled,
        })}
      >
        {label}
      </MUIInputLabel>
      <MUISelect
        ref={ref}
        name={name}
        labelId={`select-label`}
        label={label}
        classes={{ select: selectClass }}
        className={clsx(styles.select, styles[theme], {
          [styles.open]: open,
          [styles.notRequired]: !required,
          [styles.noError]: !error,
          [styles.disabled]: disabled,
        })}
        MenuProps={{
          disablePortal: true,
          classes: {
            paper: clsx(styles.paper, {
              [styles[theme]]: true,
              [styles.error]: error,
            }),
            list: clsx(styles.list, listClass),
          },
          // Must add this in order to set the proper spot from where to display
          // the items list based on parent's anchor
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderValue={(currentValue) => {
          const option = options.find((o) => o.value === currentValue);
          const optionLeftSide = option.leftSide;
          return (
            <SelectOption
              option={{
                ...option,
                leftSide: !disableLeftSideInsideValue ? optionLeftSide : null,
              }}
              value={currentValue}
            />
          );
        }}
        data-test-id={dataTestId}
        inputProps={{
          ["data-test-id"]: `${dataTestId}-input`,
        }}
        error={error}
        value={value}
        disabled={disabled}
        {...props}
      >
        {options.map((option, index) => (
          <MUIMenuItem
            key={index}
            value={option.value}
            disabled={option.disabled}
            classes={{ root: menuItemClass }}
            className={styles.option}
            data-test-id={`${dataTestId}-menu-option`}
          >
            <SelectOption
              option={option}
              selectedIndicator={selectedIndicator}
              value={value}
            />
          </MUIMenuItem>
        ))}
      </MUISelect>
    </MUIFormControl>
  );
};
