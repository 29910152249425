import { AVKConsoleCallbackAcceptEvent } from "availkit-js/dist/Models/Events/AVKConsoleCallbackAcceptEvent";
import { NIncomingCallEvent } from "availkit-js/dist/Models/Events/NIncomingCallEvent";
import { NIncomingServerCallEvent } from "availkit-js/dist/Models/Events/NIncomingServerCallEvent";

import { ThirdPartyIntegration } from "src/services/ApiClient/integrations";
import { MultiPartyEvent } from "src/services/ApiClient/scheduler";

export interface MeetingState {
  meetingToken: MeetingToken | null; // Newly added property. The properties from this seem to be used a lot across the app so I added it in here
  callState: CallState;
  callSid: string;
  pubnub_channel: string; // Must connect to new PubNub session channel after tokens refresh
  joinId: string;
  mode: CallModes;
  // ⬇️ ⬇️ ⬇️ ⬇️ ⬇️
  // Instead of having a separate state property for every type of call's details/info,
  // just have one property to hold the information and filter whatever we need using the `mode`
  // Includes: P2P calls, multi-party events, and callbacks. TODO: revisit this once implementation starts to see if it's feasible
  callDetails: CallDetails | null;
  isCallback: boolean;
  // callbackInfo: CallBackEventStateType | null; // Might need this because callback isn't currently included in `mode`, so there might not be a proper way to filter this out
  enableInCallControls: boolean;
  isUserHost: boolean;
  triggerRefreshFrames: boolean;
  // this ID is the setInterval function id for the heatbeat API calls
  // we need to store this ID so we can stop the setInterval function when the user leaves
  callSessionHeartbeatFunctionId: number | null;
  isRequestingScreenSharePermission: boolean;
  accessTokenIntervalKey: NodeJS.Timer; // Avail access token interval key for token refresh
  thirdPartyIntegration: ThirdPartyIntegration[]; // Allows to know when user has fetched features from presence, must only fetch once in MP calls
  hasFetchedFeaturesFromPresence: boolean;
  launchDarklyFetchDone: boolean; // Necessary to wait until LD fetches the latest values
}

/**
 * NIncomingCallEvent - P2P call ~
 * NIncomingServerCallEvent - P2P call
 * MultiPartyEvent - MP call
 * CallBackEventStateType - P2P call
 */

export type CallDetails =
  | NIncomingCallEvent
  | NIncomingServerCallEvent
  | MultiPartyEvent
  | CallBackEventStateType;

export enum CallModes {
  MP = "MP",
  P2P = "P2P",
  NONE = "",
}

export enum CallState {
  READY = "ready",
  INCOMING = "incoming",
  ANSWERED = "answered",
  DECLINED = "declined",
  INPROGRESS = "inprogress",
  ENDCALLCONFIRMATION = "endcallconfirmation",
  ENDCALLCONFIRMED = "endcallconfirmed",
  NEWPRESET = "newpreset",
}

export enum MPUserRoles {
  PARTICIPANT = "PARTICIPANT",
  HOST = "HOST",
  CONSOLE = "CONSOLE",
  // TODO: any other?
}

export interface CallBackEventStateType {
  twilioToken: string;
  event: AVKConsoleCallbackAcceptEvent;
}

export interface MeetingToken {
  eventId: string;
  joinId: string;
  mode: CallModes;
  userRole: MPUserRoles;
  role: string; // Only on P2P calls
  session_id?: string; // Only on P2P calls
  callback?: boolean; // Callback call is a P2P style call
  join_id?: string; // Only on P2P calls
  profile_id?: string; // not sure, seems to be user profile? TODO figure this out
  // telepresenceSimulator: boolean;

  // event object is used for callback events when the user misses a call from the console
  // and then the user clicks on the "call back" link in the portal
  // various info like session id and the twilio token are found here instead of the regular flow in the call back case
  event?: {
    _brand: string;
    _channel: string;
    _console_id: string;
    _creationDate: string;
    _organization: string;
    _sender: string;
    _session_id: string;
    _transmissionDate: string;
    _uniqueIdentifier: string;
    _user_joinId: string;
    _user_name: string;
    _user_profileId: string;
    _user_pubnub_channel: string;
  };
  twilioToken?: string;
}
