import React from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form';
import DatePicker from '../../DatePicker/DatePicker';

import '../FormFields.scss';

export default ({
	className,
	...props
}) => (
	<Field
		component={DatePicker}
		{...props}
		className={classNames({
			[className]: className,
		})}
	/>
);
