import { UserMemberTypes } from "src/services/ApiClient/users/types";

export const MEMBER_TYPE_DISPLAY_NAMES = {
  GUEST: "Guest",
  MEMBER: "Member",
  TRIAL: "Active Trial",
  TRIAL_EXPIRED: "Expired Trial",
};

export const MEMBER_TYPES: UserMemberTypes[] = [
  "GUEST",
  "MEMBER",
  "TRIAL",
  "TRIAL_EXPIRED",
];
