import { getQueryString } from "../../../utils/ApiHelper";
import ApiQuery from "../ApiQuery";
import { CORE_REGISTRY_API_URL } from "../utils";
import {
  BrandPayload,
  BusinessUnit,
  BusinessUnitSettingsResponse,
  BusinessUnitDistricts,
  BusinessUnitFacilities,
  BusinessUnitListResponse,
  BusinessUnitUsers,
} from "./types";

export const createVendor = (payload: BusinessUnit) => {
  return ApiQuery(`${CORE_REGISTRY_API_URL}/vendors`, "POST", payload);
};

export const updateVendor = (payload: BrandPayload, vendorId: string) => {
  return ApiQuery(
    `${CORE_REGISTRY_API_URL}/vendors/${vendorId}`,
    "PUT",
    payload
  );
};

export const deleteVendor = (vendorId: string) => {
  return ApiQuery(`${CORE_REGISTRY_API_URL}/vendors/${vendorId}`, "DELETE");
};

export const getAllVendors = (
  page: number = 0,
  size: number | "all" = 10,
  sort: string = "",
  extraParam: string = ""
) => {
  return ApiQuery<BusinessUnitListResponse>(
    `${CORE_REGISTRY_API_URL}/vendors${getQueryString({
      page,
      size,
      sort,
      extraParam,
    })}`
  );
};

export const getVendorDistrict = (
  vendorId: string = "",
  page: number = 0,
  size: number | "all" = 10,
  sort: string = "",
  extraParam: string = ""
) => {
  return ApiQuery<BusinessUnitDistricts>(
    `${CORE_REGISTRY_API_URL}/vendors/${vendorId}/districts${getQueryString({
      page,
      size,
      sort,
      extraParam,
    })}`
  );
};

export const getVendorUsersList = (
  vendorId: string = "",
  page: number = 0,
  size: number | "all" = 10,
  sort: string = "",
  extraParam: string = ""
) => {
  return ApiQuery<BusinessUnitUsers>(
    `${CORE_REGISTRY_API_URL}/vendors/${vendorId}/users${getQueryString({
      page,
      size,
      sort,
      extraParam,
    })}`
  );
};

export const getHospitalApplicationsForVendor = (
  vendorId: string = "",
  page: number = 0,
  size: number | "all" = 10,
  sort: string = "",
  extraParam: string = ""
) => {
  return ApiQuery<BusinessUnitFacilities>(
    `${CORE_REGISTRY_API_URL}/vendors/${vendorId}/hospitals${getQueryString({
      page,
      size,
      sort,
      extraParam,
    })}`
  );
};

export const getVendorTherapies = (vendorId: string) => {
  return ApiQuery<any>(
    `${CORE_REGISTRY_API_URL}/vendors/${vendorId}/therapies`
  );
};

export const getVendorDetail = (vendorId) => {
  return ApiQuery(`${CORE_REGISTRY_API_URL}/vendors/${vendorId}`);
};

export const getBrandVendorList = (vendorId) => {
  return ApiQuery(`${CORE_REGISTRY_API_URL}/brand/${vendorId}/vendors`, "GET");
};

export const getVendorManagerList = (vendorId) => {
  return ApiQuery(
    `${CORE_REGISTRY_API_URL}/vendors/${vendorId}/managers`,
    "GET"
  );
};

// TODO Figure out where these two functions belong
// in the BE context Business units are a classification of organizations
export const updateOrganizationSettings = (
  organizationId: string,
  payload: any // TODO figure out payload type, just an object with types it seems
) => {
  return ApiQuery(
    `${CORE_REGISTRY_API_URL}/organizations/${organizationId}/settings`,
    "PUT",
    payload
  );
};

export const getOrganizationSettings = (organizationId: string) => {
  return ApiQuery<BusinessUnitSettingsResponse>(
    `${CORE_REGISTRY_API_URL}/organizations/${organizationId}/settings`,
    "GET"
  );
};
