import { NetworkQualityLevel } from "twilio-video";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { networkStatusThunk } from "src/domains/Beacon/store/twilio/thunks/networkStatusThunk";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { logger } from "src/logging/logger";

/**
 * A listener added to Twilio room's localParticipant that updates
 * state.twilio.localNetworkQualityLevel when networkQualityLevelChanged
 */

export const useNetworkQualityLevelSubscribed = () => {
  const { tungstenExternalLa, QaAutomationFeatureFlag } = useFeatureFlags();
  const { room } = useAppSelector(selectTwilioState);
  const dispatch = useDispatch();

  useEffect(() => {
    room?.localParticipant.on(
      "networkQualityLevelChanged",
      (networkQualityLevel: NetworkQualityLevel) => {
        const logMessage = `Network Quality Level changed to ${networkQualityLevel}`;
        if (networkQualityLevel <= 2) {
          logger().warn(
            `${logMessage}.${
              networkQualityLevel < 2 ? " Local Network has become poor." : ""
            }`
          );
        } else {
          logger().info(logMessage);
        }

        // thunk will address other network-related state
        if (tungstenExternalLa) {
          // set NetworkQualityLevel
          dispatch(
            twilioActions.setLocalNetworkQualityLevel(networkQualityLevel)
          );
          // only displaying and addressing network with tungstenExternalLa
          dispatch(
            networkStatusThunk({
              networkStatus: networkQualityLevel,
              QaAutomationFeatureFlag,
            })
          );
        }
      }
    );
  }, []);
};
