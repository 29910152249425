import React from "react";

import clsx from "clsx";

import CloseIcon from "@mui/icons-material/Close";
import { Fade } from "@mui/material";

import { IconButton } from "src/components/Button";
import { Font } from "src/components/Font";
import { useOnEscapeKeyDown } from "src/hooks/useOnEscapeKeyDown";

import styles from "./styles.scss";

interface IModalProps {
  open: boolean;
  id?: string;
  className?: string;
  containerClassName?: string;
  // when the modal is closing sometimes we want to clear some state, so use onExited prop
  onExited?: () => void;
  children: React.ReactNode;
  "data-test-id"?: string;
  size?: "small" | "medium" | "large";
  // showVeil refers to the background blur that happens behind the modal
  showVeil?: boolean;
  // transparentVeil refers to if you want that background to be transparant (meaning see through slightly)
  // if its set to false then the background will be a solid color
  transparentVeil?: boolean;
  // slight optizimation prop to unmount components when they fade out, check MUI docs for details
  unmountOnExit?: boolean;
}

interface IHeaderProps {
  "data-test-id"?: string;
  closeModal: () => void;
  title: string;
  className?: string;
}

interface IUndismissableHeaderProps {
  "data-test-id"?: string;
  title: string;
  className?: string;
}

interface IBodyProps {
  "data-test-id"?: string;
  children: React.ReactNode;
  className?: string;
}

interface IFooterProps {
  "data-test-id"?: string;
  children: React.ReactNode;
  className?: string;
}

/** Use this header for modals that should not be "closable" by the user
 */
export const UndismissableModalHeader = (props: IUndismissableHeaderProps) => {
  return (
    <div
      className={clsx(styles.header, props.className)}
      data-test-id={props["data-test-id"] || null}
      role="modal-header"
    >
      <Font variant="h1" color="light">
        {props.title}
      </Font>
    </div>
  );
};

export const ModalHeader = ({
  closeModal,
  title,
  className,
  "data-test-id": dataTestId,
}: IHeaderProps) => {
  // call the close function passed in (which is the same function we use when the user
  // clicks on the close button) when the "esc" aka "escape" key is hit
  useOnEscapeKeyDown(closeModal);

  return (
    <div
      className={clsx(styles.header, className)}
      data-test-id={dataTestId}
      role="modal-header"
    >
      <div className={clsx(styles.close)}>
        <IconButton
          aria-label="close"
          color="white"
          onClick={closeModal}
          data-test-id={dataTestId ? `${dataTestId}-close-button` : null}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Font
        variant="h1"
        color="light"
        data-test-id={dataTestId ? `${dataTestId}-title` : null}
        className={className}
      >
        {title}
      </Font>
    </div>
  );
};

export const ModalBody = (props: IBodyProps) => {
  return (
    <div
      className={clsx(styles.body, props.className)}
      data-test-id={props["data-test-id"] || null}
      role="modal-body"
    >
      {props.children}
    </div>
  );
};

export const ModalFooter = (props: IFooterProps) => {
  return (
    <div
      className={clsx(styles.footer, props.className)}
      data-test-id={props["data-test-id"] || null}
      role="modal-footer"
    >
      {props.children}
    </div>
  );
};

export const Modal = ({
  size = "small",
  showVeil = true,
  transparentVeil = true,
  unmountOnExit,
  ...props
}: IModalProps) => {
  return (
    <Fade
      // if open is false then we wont see the modal, when open changes the modal will fade in/out
      in={props.open}
      // when the modal is closing sometimes we want to clear some state, so this line lets
      // us run some function when the modal is closing
      addEndListener={props.open === false ? props.onExited : null}
      unmountOnExit={unmountOnExit}
    >
      <div
        className={clsx(styles.modalContainer, props.containerClassName, {
          [styles.modalVeil]: showVeil,
          [styles.transparentVeil]: transparentVeil,
        })}
        id={props.id}
      >
        <div className={clsx(styles.modalWrapper)}>
          <div
            data-test-id={props["data-test-id"] || "modal"}
            className={clsx(styles.modal, styles[size], props.className)}
            role="modal"
          >
            {props.children}
          </div>
        </div>
      </div>
    </Fade>
  );
};
