import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, FieldArray } from 'redux-form';
/* Components */
import { Fields, validators } from '../../../../../../../Common/Components';
import { notificationService, urlService } from '../../../../../../../Common/Services';
import ApiHelper from '../../../../../../../Common/Services/ApiHelper';
import resources from '../../../../../../../Common/Constants/resources';
/* Styles */
import '../../../Scheduler.scss';
import '../../../../../../../Common/Components/FormFields/FormFields.scss';
import { emails } from './EmailSelector';

export class CreateEventForm extends PureComponent {
	state = {
		rooms: [],
	}

	componentDidMount() {
		if (this.props.hospitalId) {
			this.getHospitalRooms(this.props.hospitalId);
		}
	}

	onHospitalChange = (id) => {
		this.getHospitalRooms(id);
	}

	getHospitalRooms = async (id) => {
		try {
			const response = await ApiHelper.get('coreregistry', urlService.formatString(resources.hospitalRooms, id));

			if (!response.content.length) {
				notificationService.warning('There are no rooms associated to this hospital.');
			}

			this.setState({ rooms: response.content });
		} catch (e) {
			notificationService.error(e);
		}
	}

	render() {
		const { hospitals } = this.props;
		const { rooms } = this.state;

		return (
			<React.Fragment>
				<div>
					<Fields.SelectField
						labelText="Facility"
						placeholder="Select Facility"
						name="hospitalId"
						options={hospitals && hospitals.map(hospital => ({
							key: hospital.id,
							value: hospital.id,
							text: hospital.name,
						}))}
						onChange={id => this.onHospitalChange(id)}
						validate={validators.required}
						className="select__dropdown"
					/>
				</div>
				<div>
					<Fields.SelectField
						labelText="Department/Room"
						placeholder="Select Department/Room"
						name="roomId"
						disabled={!rooms.length}
						options={rooms && rooms.map(room => ({
							key: room.id,
							value: room.id,
							text: room.name,
						}))}
						validate={validators.required}
						className="select__dropdown"
					/>
				</div>
				<div>
					<Fields.TextAreaField
						labelText="Description"
						placeholder="Enter event description"
						name="description"
					/>
				</div>
				<div>
					<FieldArray
						name="participants"
						className="emails__general-input"
						component={emails}
						validate={validators.required}
					/>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	createEventFormValues: getFormValues('createEventForm')(state),
	timezone: state.usersReducer.timezone,
});

export default reduxForm({
	form: 'createEventForm',
	destroyOnUnmount: false,
	enableReinitialize: true,
})(connect(mapStateToProps, undefined)(CreateEventForm));
