import { useDispatch } from "react-redux";

import { Fade } from "@mui/material";

import { BackgroundPatternSVG } from "src/components/CustomIcons/BackgroundPatternSVG";
import { Logo } from "src/components/Logo";
import { PreCallModal } from "src/domains/Beacon/components/PreCallHandler/PreCallModal";
import { useAppSelector } from "src/domains/Beacon/store";
import { joinCallThunk } from "src/domains/Beacon/store/meeting/thunks/joinCallThunk";
import { selectCallStep } from "src/domains/Beacon/store/ui/selectors";
import { CallSteps } from "src/domains/Beacon/store/ui/types";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";

import styles from "./styles.scss";

// All pre call state stuff will go here in this component
// Stuff like the setting up your media devices (mic/cam/speakers)
// using this component as sort of a container for redux state/actions and then passing them down to the pre call modal
export const PreCallHandler = () => {
  const dispatch = useDispatch();
  const {
    oRtelligence: enableORTelligence,
    externalInputsImageResizing,
  } = useFeatureFlags();

  // const leaveCallModalOpen = useAppSelector(selectLeaveCallModalOpen);
  const currentCallStep = useAppSelector(selectCallStep);

  const joinCallAction = async () => {
    await dispatch(
      joinCallThunk({
        enableORTelligence,
        externalInputsImageResizing,
      })
    );
  };
  // we only want the pre call components to show up if we are in the pre call state
  const open = currentCallStep === CallSteps.PRE_CALL;

  // TODO pass any functions necessary for cam/mic/speaker selection to the preCallModal later
  // replace the functions in that pre call modal component below with redux functions

  return (
    <>
      {/* This div sits in the background to prevent weird flashes when moving directly from PRECALL TO LEAVECALL
          if the user clicks on "leave" in the pre call modal then they are taken straight to leave call
          if they click on "join" then they will be in the call and wont see this background anymore
      */}
      <Fade in={currentCallStep !== CallSteps.IN_CALL} appear={false}>
        <div className={styles.background} />
      </Fade>

      {/* we only want to show the leave call screen when the call is in the "leave call" state
          it will appear on top of the whole screen only once show is set to true
      */}
      <Fade
        // we'll see this leave call screen when show is set to true
        in={open}
        // appear is weirdly named but setting it to false means it doesn't fade in, it only fades out
        // on initial page load we want this background to show without fading in, when the user joins it fades out as expected using the in={open} prop
        appear={false}
      >
        <div data-test-id="pre-call-background" className={styles.background}>
          {/* adding BackgroundPattern here.Making veil visible in PreCallModal obscures Logo */}
          <BackgroundPatternSVG />
          <div className={styles.content}>
            <Logo type="horizontal-light" />
          </div>
        </div>
      </Fade>

      <PreCallModal open={open} joinCallAction={joinCallAction} />
    </>
  );
};
