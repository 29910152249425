import { useState, useEffect } from 'react';

export function useTimer(initialMinutes, initialSeconds) {
	const [minutes, setMinutes] = useState(initialMinutes);
	const [seconds, setSeconds] = useState(initialSeconds);

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}

			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(interval);
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [minutes, seconds]);

	return { minutes, seconds };
}
