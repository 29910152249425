import { useEffect } from "react";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { selectHasConsoleJoined } from "src/domains/Beacon/store/twilio/selectors";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

// Only do discoverCameras in P2P
// we don't call the presence server for this in a P2P call
export const useCameraDiscovery = () => {
  const { mode, callSid } = useAppSelector(selectMeetingState);
  const consoleHasJoined = useAppSelector(selectHasConsoleJoined);
  const availKit = AvailKitService.instance;

  useEffect(() => {
    if (consoleHasJoined && mode === CallModes.P2P) {
      try {
        logger().info("Sending Discover cameras...");
        availKit.cameraDiscoveryService.discoverCameras(callSid);
      } catch (error: any) {
        logger().logWithFields(
          LoggerLevels.error,
          {
            feature: "useCameraDiscovery",
          },
          "Failed to send CameraDiscovery.",
          error?.message
        );
      }
    }
  }, [availKit, consoleHasJoined]);
};
