// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root_ASy7qF{height:100%;width:100%}.styles_root_ASy7qF.styles_hidden_vG0cr4{visibility:hidden}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/Video/styles.scss"],"names":[],"mappings":"AAAA,oBACE,WAAA,CACA,UAAA,CAEA,yCACE,iBAAA","sourcesContent":[".root {\n  height: 100%;\n  width: 100%;\n\n  &.hidden {\n    visibility: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root_ASy7qF",
	"hidden": "styles_hidden_vG0cr4"
};
export default ___CSS_LOADER_EXPORT___;
