import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { selectTelestrationState } from "src/domains/Beacon/store/telestration/selectors";
import {
  sendTelestrationEndEventThunk,
  sendTelestrationStartEventThunk,
  sendVideoAnnotationEventThunk,
} from "src/domains/Beacon/store/telestration/thunks/sendEventThunks";
import { sendEventTypes } from "src/domains/Beacon/store/telestration/types";

/**
 * Sending telestration events from within this hook to ease use of the current
 * Redux state.
 * Using these thunks directly  within the mouseEventHandlerThunks is resource
 * heavy and causes lag resulting in blockier drawings.
 */

export const useSendTelestrationEvents = () => {
  // Hooks
  const dispatch = useDispatch();
  const { sendEvent } = useAppSelector(selectTelestrationState);

  useEffect(() => {
    if (!sendEvent || sendEvent.type === null) {
      return;
    }
    switch (sendEvent.type) {
      case sendEventTypes.start:
        dispatch(sendTelestrationStartEventThunk());
        break;
      case sendEventTypes.end:
        dispatch(sendTelestrationEndEventThunk());
        break;
      case sendEventTypes.annotation:
        dispatch(sendVideoAnnotationEventThunk({ points: sendEvent.payload }));
        break;
    }
    dispatch(telestrationActions.setSendEvent(null));
  }, [sendEvent]);
};
