// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root_gvMe1-{margin-right:4px;padding:4px 8px}.styles_root_gvMe1-.styles_hover_K3Qf0M{background-color:rgba(255,255,255,.2);border-radius:6px;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/NetworkStatus/styles.scss"],"names":[],"mappings":"AAGA,oBACE,gBAAA,CACA,eAAA,CAEA,wCACE,qCAAA,CACA,iBAAA,CACA,cAAA","sourcesContent":["@use \"sass:color\";\n@import \"/src/colors.scss\";\n\n.root {\n  margin-right: 4px;\n  padding: 4px 8px;\n\n  &.hover {\n    background-color: color.adjust($white, $alpha: -0.8);\n    border-radius: 6px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root_gvMe1-",
	"hover": "styles_hover_K3Qf0M"
};
export default ___CSS_LOADER_EXPORT___;
