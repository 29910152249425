import { HOSPITALS } from './HospitalsActionTypes';

export const initialState = {
	hospitals: null,
	allHospitals: [],
	hospital: null,
	pagination: {
		page: 0,
		pageSize: 10,
		totalPages: 1,
	},
	hospitalRooms: null,
	hospitalAdmins: null,
	hospitalConsoles: null,
	mfaOrgLevelFrequency: "every_day",
	mfaOrgLevelTwoFactor: true,
	mfaFrequencyTypes: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case HOSPITALS.SET_ORG_REMEMBER_FREQUENCY:
			return {
				...state,
				mfaOrgLevelFrequency: action.payload
			};
		case HOSPITALS.SET_ORG_TWO_FACTOR_AUTH:
			return {
				...state,
				mfaOrgLevelTwoFactor: action.payload
			};
		case HOSPITALS.SET_ORG_FREQUENCY_TYPES:
			return {
				...state,
				mfaFrequencyTypes: action.payload
			};
		case HOSPITALS.SET_HOSPITALS:
			return {
				...state,
				hospitals: action.payload,
			};
		case HOSPITALS.SET_ALL_HOSPITALS:
			return { ...state, allHospitals: action.payload };
		case HOSPITALS.SET_HOSPITALS_PAGINATION: {
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload.pageNumber,
					pageSize: action.payload.pageSize,
					totalPages: action.payload.totalPages,
				},
			};
		}
		case HOSPITALS.SET_HOSPITAL:
			return {
				...state,
				hospital: action.payload,
			};
		case HOSPITALS.SET_HOSPITAL_ROOMS:
			return {
				...state,
				hospitalRooms: action.payload,
			};
		case HOSPITALS.HOSPITAL_SET_ALL_HOSPITAL_ADMINS:
			return {
				...state,
				hospitalAdmins: action.payload,
			};
		default:
			return state;
	}
}
