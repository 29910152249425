// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_background-bar_h88kSM{background-color:#f1f1f1;height:22px;width:100%}.styles_background-bar_h88kSM .styles_progress-bar_sarf0V{background-color:#4caf50;color:#fff;height:22px;transition:var(--transition);width:var(--width)}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/LevelIndicator/styles.scss"],"names":[],"mappings":"AAAA,8BACE,wBAAA,CACA,WAAA,CACA,UAAA,CAEA,0DACE,wBAAA,CACA,UAAA,CACA,WAAA,CACA,4BAAA,CACA,kBAAA","sourcesContent":[".background-bar {\n  background-color: #f1f1f1;\n  height: 22px;\n  width: 100%;\n\n  .progress-bar {\n    background-color: #4CAF50;\n    color: #FFF;\n    height: 22px;\n    transition: var(--transition);\n    width: var(--width);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background-bar": "styles_background-bar_h88kSM",
	"backgroundBar": "styles_background-bar_h88kSM",
	"progress-bar": "styles_progress-bar_sarf0V",
	"progressBar": "styles_progress-bar_sarf0V"
};
export default ___CSS_LOADER_EXPORT___;
