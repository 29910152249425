import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './CreateNewVendor.scss';

import CreateNewVendorInfo from './CreateNewVendorInfo';
import CreateNewVendorTherapies from './CreateNewVendorTherapies';
import CreateNewVendorHospitals from './CreateNewVendorHospitals';

class CreateNewVendor extends PureComponent {
	state = {
		page: 1,
	};

	nextPage = () => {
		this.setState({ page: this.state.page + 1 });
	};

	previousPage = () => {
		this.setState({ page: this.state.page - 1 });
	};

	render() {
		const { onSubmit, toggleCreateNewVendorModal } = this.props;
		const { page } = this.state;
		return (
			<div>
				{page === 1 && (
					<CreateNewVendorInfo
						onSubmit={this.nextPage}
						toggleCreateNewVendorModal={toggleCreateNewVendorModal}
					/>
				)}
				{page === 2 && (
					<CreateNewVendorTherapies
						previousPage={this.previousPage}
						onSubmit={this.nextPage}
					/>
				)}
				{page === 3 && (
					<CreateNewVendorHospitals
						previousPage={this.previousPage}
						onSubmit={onSubmit}
					/>
				)}
			</div>
		);
	}
}

CreateNewVendor.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default CreateNewVendor;
