import { ACCOUNT_CREATION } from './AccountCreationActionTypes';

const initialState = {
	therapies: null,
	timezones: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ACCOUNT_CREATION.SET_THERAPIES:
			return {
				...state,
				therapies: action.payload,
			};
		case ACCOUNT_CREATION.SET_ALL_TIMEZONES:
			return {
				...state,
				timezones: action.payload,
			};
		default:
			return state;
	}
}
