import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";

import {
  drawAnnotation,
  rgbToHex,
} from "src/domains/Beacon/components/Easel/helpers";
import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { logger, LoggerLevels } from "src/logging/logger";

export const drawRemoteAnnotationsThunk = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  try {
    logger().info(`Drawing presence annotations thunk...`);
    const {
      telestration: {
        presenceAnnotations,
        videoDimensions: videoElementDimensions,
      },
    } = getState();

    logger().info(
      `Drawing presence annotations thunk... ${presenceAnnotations?.length}`
    );
    if (presenceAnnotations?.length === 0) {
      logger().info(
        "No remote annotations found. Drawing remote annotations will be skipped ..."
      );
      return;
    }

    presenceAnnotations.forEach((annotationEvent: AVKAnnotationEvent) => {
      // ignore below removes faulty error message about annotationEvent.annotations being undefined
      /* @ts-ignore */
      annotationEvent.annotations.forEach((eventAnnotation) => {
        const {
          backingInstructions: eventBackingInstructions,
          red,
          green,
          blue,
        } = eventAnnotation;

        drawAnnotation(
          rgbToHex(red, green, blue),
          eventBackingInstructions,
          videoElementDimensions
        );
      });
    });

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Telestration",
      },
      `Drawing Telestration based on presence annotations: ${presenceAnnotations?.length}`
    );
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      {
        feature: "Telestration",
      },
      "Error happened when drawing remote annotations",
      error?.message
    );
    throw error;
  }
};
