import Twilio, { LocalAudioTrack } from "twilio-video";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/domains/Beacon/store";
import { logger, LoggerLevels } from "src/logging/logger";

/**
 * Allows to publish the new audio track if the current participant,
 * publishing the audio track will update every participant's audio for this participant.
 * The update occurs in the TwilioSubscriptions component
 */
// Automatically generates pending, fulfilled and rejected action types
// (see `createAsyncThunk` docs: https://redux-toolkit.js.org/api/createAsyncThunk)
export const publishAudioTrackThunk = createAsyncThunk<
  // Return type of the payload creator
  LocalAudioTrack,
  // First argument to the payload creator
  void,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("stream/publishAudioTrackThunk", async (_arg, { getState }) => {
  try {
    logger().info("User is publishing audio tracks to Twilio");

    const { stream, twilio, ui } = getState();
    const {
      localMedia: { microphone },
    } = stream;
    const {
      room: { localParticipant },
    } = twilio;
    const { microphone: micState } = ui;

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "publishAudioTrackThunk",
      },
      `Creating LocalAudioTrack using microphone device: ${JSON.stringify(
        microphone ?? "null"
      )}`
    );

    const audioTrack = await Twilio.createLocalAudioTrack({
      deviceId: microphone.deviceId,
    });

    const tracks = Array.from(localParticipant.audioTracks?.values() ?? []).map(
      (trackPublication) => trackPublication.track
    );

    await localParticipant.unpublishTracks(tracks);

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "publishAudioTrackThunk",
      },
      `audioTracks to be published to Twilio: ${JSON.stringify(
        audioTrack ?? "null"
      )}`
    );

    // Must check first the mic state in order to disable or not the track
    if (micState.muted) {
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "publishAudioTrackThunk",
        },
        `Microphone audio is currently muted, disabling it before publishing to Twilio`
      );
      audioTrack.disable();
    }

    const { track } = await localParticipant.publishTrack(audioTrack);
    return track as LocalAudioTrack;
  } catch (error: any) {
    logger().error("Error publishing audio tracks", error?.message);
    throw new Error("Error publishing new audio tracks.");
  }
});
