import { reduxForm } from "redux-form";

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Button } from "src/components/legacy/Button";
import { useOnPageRefresh } from "src/hooks/useOnPageRefresh";
import { reCaptchaWrapper, showReCaptcha } from "src/services/ReCaptchaHelper";
import { clearUserSession } from "src/utils/clearUserSession";

import { Fields, validators } from "avail-web-application/Common/Components";
import userSessionService from "avail-web-application/Common/Services/userSesionService";
import { submitLoginAction } from "avail-web-application/Features/Authentication/AuthenticationActions";
import withAuthentication from "avail-web-application/Features/Authentication/Components/withAuthentication";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

import { LoginPage } from "../../../../Login";

const { label } = DomainConstants;
interface DispatchProps {
  handleSubmit: (data) => any;
  submitLogin: (data) => any;
}

interface StateProps {
  otpInfo: any;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

function PasswordScreen({
  handleSubmit,
  history,
  otpInfo,
  submitLogin,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  useOnPageRefresh(() => clearUserSession());

  useEffect(() => {
    showReCaptcha();
    // checks for empty session storage
    if (localStorage.getItem("MFAAuthDetails") === null) {
      history.push("/event-login");
    }
  }, []);

  const handleLoginFormSubmit = (loginData) => {
    const { username } = otpInfo;
    const acceptedEula = !!userSessionService.getEulaVersion();
    const payload = {
      ...loginData,
      username,
    };
    if (acceptedEula) {
      setIsLoading(true);
      reCaptchaWrapper("login", (captchaVersion, captchaToken) =>
        submitLogin({
          ...payload,
          captchaToken,
          captchaVersion,
        })
      );
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const redirectForget = () => history.push("/forgot-password");

  return (
    <LoginPage className="login">
      <div className="login__subheading">{label.loginHub}</div>
      <form
        className="login__form"
        onSubmit={handleSubmit(handleLoginFormSubmit)}
      >
        <Fields.InputField
          data-test-id="password"
          labelText="Password"
          placeholder="Please enter password"
          name="password"
          type="password"
          validate={[validators.required]}
        />
        <Button
          data-test-id="loginSubmit"
          theme="green"
          className="login__button singular"
          type="submit"
          loading={isLoading}
          // TODO: move styles into custom MUI Button
          style={{
            marginTop: "20px",
            textTransform: "none",
          }}
        >
          {label.next}
        </Button>
      </form>
      <div className="login__sign-up">
        <a onClick={redirectForget} className="login__sign-up-button">
          Forgot password?
        </a>
      </div>
      <div className="login__sign-up">
        Is your employer an Avail member?
        <br />
        Contact&nbsp;
        <a href="mailto:support@avail.io">Avail Technical Support</a>
        &nbsp;to link your {label.memberHub} profile!
      </div>
    </LoginPage>
  );
}

const mapStateToProps = (state) => ({
  otpInfo: state.authenticationReducer.otpInfo,
});

const mapDispatchToProps = (dispatch) => ({
  submitLogin: (data) => dispatch(submitLoginAction(data)),
});

export default reduxForm({
  form: "loginForm",
})(
  withAuthentication(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordScreen))
  )
);
