import { createSlice } from "@reduxjs/toolkit";

import { AppState } from "avail-web-ui/dux/Store";
import {
  UserDetails,
  UserRole,
} from "avail-web-ui/services/ApiClient/Users/types";

export interface AuthState {
  profile: UserDetails | null;
  profiles: UserRole[];
}

const initialState: AuthState = {
  profile: null,
  profiles: [],
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    storeProfiles: (state, action) => {
      state.profiles = action.payload;
    },
    storeProfile: (state, action) => {
      state.profile = action.payload;
    },
    selectRole: (state) => {
      // no op
      return state;

      // TODO - figure out best convention? Do we create an action creator directory instead or let
      //        create slice handle all even when there is a noop
      // see The extraReducers "builder callback" notation
      // https://redux-toolkit.js.org/api/createSlice#the-extrareducers-builder-callback-notation
    },
    finalizeLogin: (state) => state,
    submitLogin: (state) => state,
    onLoginSuccess: (state) => state,
  },
});

export const {
  finalizeLogin: finalizeLoginAction,
  submitLogin: submitLoginAction,
  storeProfile: storeProfileAction,
  storeProfiles: storeProfilesAction,
  selectRole: selectRoleAction,
  onLoginSuccess: onLoginSuccessAction,
} = AuthSlice.actions;

export const AuthReducers = AuthSlice.reducer;
export const authSelector = (state: AppState) => state.auth;
