import { PresentToAllRounded } from "@mui/icons-material";

import { Font } from "src/components/Font";
import { Modal, ModalBody } from "src/components/Modal";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";

import styles from "./styles.scss";

export const ScreenShareModal = () => {
  const { isRequestingScreenSharePermission } = useAppSelector(
    selectMeetingState
  );
  return (
    <Modal
      open={isRequestingScreenSharePermission}
      containerClassName={styles.modalContainer}
      className={styles.modal}
    >
      <ModalBody>
        <div className={styles.badge}>
          <PresentToAllRounded fontSize="large" className={styles.icon} />
          <Font variant="b1" color="light">
            Click the Share button above to send application to the Avail
            Console
          </Font>
        </div>
      </ModalBody>
    </Modal>
  );
};
