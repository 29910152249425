import {
  LocalAudioTrack,
  LocalVideoTrack,
  NetworkQualityLevel,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
  Room
} from "twilio-video";

import { TwilioLogger } from "../../common/TwilioLogger";
import { LocalOrRemoteMediaTrack } from "../../types";
import { NetworkQualityStats } from "../../types/network-quality";
import { TwilioCredentials } from "../models";
import { TwilioActionKeys, TwilioActionType } from "./actionTypes";

export const getTwilioCredentials = (): TwilioActionType => ({
  type: TwilioActionKeys.GET_CREDENTIALS
});

export const clearTwilioTracks = (): TwilioActionType => ({
  type: TwilioActionKeys.CLEAR_TWILIO_TRACKS
});

export const refreshFrames = (): TwilioActionType => ({
  type: TwilioActionKeys.REFRESH_FRAMES
});

export const getTwilioCredentialsRequest = (): TwilioActionType => ({
  type: TwilioActionKeys.GET_CREDENTIALS_REQUEST
});

export const getTwilioCredentialsSuccess = (
  data: TwilioCredentials
): TwilioActionType => ({
  type: TwilioActionKeys.GET_CREDENTIALS_SUCCESS,
  twilioCredentials: data
});

export const getTwilioCredentialsFailed = (
  errorStatus?: string
): TwilioActionType => ({
  type: TwilioActionKeys.GET_CREDENTIALS_FAILED,
  errorStatus
});

export const connectCall = (): TwilioActionType => ({
  type: TwilioActionKeys.CONNECT_CALL
});

export const connectCallRequest = (): TwilioActionType => ({
  type: TwilioActionKeys.CONNECT_CALL_REQUEST
});

export const connectCallSuccess = (data: Room): TwilioActionType => ({
  type: TwilioActionKeys.CONNECT_CALL_SUCCESS,
  room: data
});

export const connectCallFailed = (): TwilioActionType => ({
  type: TwilioActionKeys.CONNECT_CALL_FAILED
});

export const attachLocalTracks = (
  tracks: Array<LocalAudioTrack | LocalVideoTrack>
): TwilioActionType => ({
  type: TwilioActionKeys.ATTACH_LOCAL_TRACKS,
  tracks
});

export const updateParticipants = (
  participants: Participant[]
): TwilioActionType => ({
  type: TwilioActionKeys.UPDATE_PARTICIPANTS,
  participants
});

export const updateDominantSpeaker = (uuid: string): TwilioActionType => ({
  type: TwilioActionKeys.UPDATE_DOMINANT_SPEAKER,
  uuid
});

export const updateTracks = (
  tracks: Array<RemoteAudioTrack | RemoteVideoTrack>
): TwilioActionType => ({
  type: TwilioActionKeys.UPDATE_TRACKS,
  tracks
});

export const updateDimensions = (needsUpdate: boolean): TwilioActionType => ({
  type: TwilioActionKeys.UPDATE_DIMENSIONS,
  needsUpdate
});

export const updateBannerNeedsUpdate = (
  bannerNeedsUpdate: boolean
): TwilioActionType => ({
  type: TwilioActionKeys.UPDATE_BANNER_NEEDS_UPDATE,
  bannerNeedsUpdate
});

export const updateDuplicateParticipantDisconnected = (
  duplicateParticipantDisconnected: boolean
): TwilioActionType => ({
  type: TwilioActionKeys.UPDATE_DUPLICATE_PARTICIPANT_DISCONNECTED,
  duplicateParticipantDisconnected
});

export const updateLocalNetworkQualityLevel = (
  localNetworkQualityLevel: NetworkQualityLevel
): TwilioActionType => ({
  type: TwilioActionKeys.UPDATE_LOCAL_NETWORK_QUALITY_LEVEL,
  localNetworkQualityLevel
});

export const updateLocalNetworkDisconnected = (
  localNetworkDisconnected: boolean
): TwilioActionType => ({
  type: TwilioActionKeys.UPDATE_LOCAL_NETWORK_DISCONNECTED,
  localNetworkDisconnected
});

export const updateFailed = (): TwilioActionType => ({
  type: TwilioActionKeys.UPDATE_FAILED
});

export const localMediaNotFound = (): TwilioActionType => ({
  type: TwilioActionKeys.LOCAL_MEDIA_NOT_FOUND
});

export const cameraIssueEncountered = (): TwilioActionType => ({
  type: TwilioActionKeys.CAMERA_ISSUE_ENCOUNTERED
});

export const mediaErrorEncountered = (errorCode: string): TwilioActionType => ({
  type: TwilioActionKeys.MEDIA_ERROR_ENCOUNTERED,
  errorCode
});

export const disconnectFromRoomRequest = (): TwilioActionType => ({
  type: TwilioActionKeys.DISCONNECT_CALL_REQUEST
});

export const disconnectFromRoomSuccess = (): TwilioActionType => ({
  type: TwilioActionKeys.DISCONNECT_CALL_SUCCESS
});

export const disconnectFromRoomFailed = (): TwilioActionType => ({
  type: TwilioActionKeys.DISCONNECT_CALL_FAILED
});

export const nqPostMetrics = (): TwilioActionType => ({
  type: TwilioActionKeys.NETWORK_QUALITY_POST_METRICS
});
export const nqPostMetricsRequest = (): TwilioActionType => ({
  type: TwilioActionKeys.NETWORK_QUALITY_POST_METRICS_REQUEST
});
export const nqStartPolling = (): TwilioActionType => ({
  type: TwilioActionKeys.NETWORK_QUALITY_START_POLLING
});

export const nqStopPolling = (): TwilioActionType => ({
  type: TwilioActionKeys.NETWORK_QUALITY_STOP_POLLING
});

export const cqMergeSum = (stats: NetworkQualityStats): TwilioActionType => ({
  type: TwilioActionKeys.CALL_QUALITY_MERGE_SUM,
  stats
});

export const setRemoteHasJoinedRoom = (data: boolean): TwilioActionType => ({
  type: TwilioActionKeys.SET_REMOTE_HAS_JOINED_ROOM,
  remoteHasJoinedRoom: data
});

export const setConsoleHasJoinedRoom = (data: boolean): TwilioActionType => ({
  type: TwilioActionKeys.SET_CONSOLE_HAS_JOINED_ROOM,
  consoleHasJoinedRoom: data
});

export const setHostHasJoinedRoom = (data: boolean): TwilioActionType => ({
  type: TwilioActionKeys.SET_HOST_HAS_JOINED_ROOM,
  hostHasJoinedRoom: data
});

export const setHostTracks = (
  tracks: LocalOrRemoteMediaTrack[]
): TwilioActionType => ({
  type: TwilioActionKeys.SET_HOST_TRACKS,
  tracks
});

export const logActivity = (message: string): TwilioActionType => ({
  type: TwilioActionKeys.LOG_ACTIVITY,
  message
});

export const twilioReconnection = (): TwilioActionType => ({
  type: TwilioActionKeys.TWILIO_RECONNECTION
});

export const startTwilioReconnectionTimer = (): TwilioActionType => ({
  type: TwilioActionKeys.TWILIO_RECONNECTION_TIMER
});

export const updateTwilioLogger = (
  logger: typeof TwilioLogger
): TwilioActionType => ({
  type: TwilioActionKeys.TWILIO_UPDATE_LOGGER,
  logger
});
