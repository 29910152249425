import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { refreshFramesThunk } from "src/domains/Beacon/store/twilio/thunks/refreshFramesThunk";
import { logger, LoggerLevels } from "src/logging/logger";
import { timeoutCallback } from "src/utils/timeout";

export const consoleVideoTrackNotStartedThunk = createAsyncThunk<
  // Return type of the payload creator, we return nothing so void
  void,
  // First argument to the payload creator, no args so void
  { videoId: string; offerDeviceSelection?: boolean },
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "meeting/consoleVideoTrackNotStarted",
  ({ videoId, offerDeviceSelection }, { getState, dispatch }) => {
    try {
      const {
        stream: {
          mpRemoteTracks: {
            console: { video: consoleVideoTrack },
          },
        },
        twilio: { consoleHasJoinedRoom },
      } = getState();
      const SECONDS_TO_WAIT: number = 5;

      timeoutCallback(() => {
        // prevents running when console has not yet joined
        if (consoleVideoTrack.isStarted === false && consoleHasJoinedRoom) {
          logger().logWithFields(
            LoggerLevels.info,
            {
              feature: "meeting/thunks",
              fileInfo: "consoleVideoTrackNotStartedThunk",
            },
            `Console Video has not started at video#${videoId} for ${SECONDS_TO_WAIT} seconds, refreshing Twilio call`
          );
          dispatch(refreshFramesThunk({ offerDeviceSelection }));
        }
      }, SECONDS_TO_WAIT * 1000);
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "meeting/thunks",
          fileInfo: "consoleVideoTrackNotStartedThunk",
        },
        "Error while setting refresh Twilio timer",
        error?.message
      );
    }
  }
);
