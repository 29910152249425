import { ENDPOINTS } from "../constants";
import { ApiResponse } from "./types";
import { NetworkQualityStats } from "../types/network-quality";

export interface NetworkQualityPayload {
  data: NetworkQualityStats;
  cid: string;
  sid: string;
}

/**
 * @param payload Defined structure for the metrics backend to process accordingly (NetworkQualityPayload)
 * Example use: const { data, error } = await API.POST.networkQualityPoll(payload)
 */
const networkQualityPoll = async (
  cid: string,
  sid: string,
  payload: any
): Promise<ApiResponse<any>> => {
  try {
    const url = `${ENDPOINTS.NETWORK_QUALITY_POLLING}/cid/${cid}/sid/${sid}`;
    const headers = { type: "text/plain; charset=UTF-8" };
    const blob = new Blob([JSON.stringify(payload)], headers);
    const res = navigator.sendBeacon(url, blob);

    return { data: { success: res } };
  } catch (error) {
    return { error };
  }
};

export default networkQualityPoll;

// import { ENDPOINTS } from "../constants";
// import { ApiResponse } from "./types";
// import { NetworkQualityStats } from "../types/network-quality";

// export interface NetworkQualityPayload {
//   data: NetworkQualityStats;
//   cid: string;
//   sid: string;
// }

// /**
//  * @param payload Defined structure for the metrics backend to process accordingly (NetworkQualityPayload)
//  * Example use: const { data, error } = await API.POST.networkQualityPoll(payload)
//  */
// const networkQualityPoll = async (
//   cid: string,
//   sid: string,
//   payload: any // for now
// ): Promise<ApiResponse<any>> => {
//   try {
//     const url = `${ENDPOINTS.NETWORK_QUALITY_POLLING}/cid/${cid}/sid/${sid}`;

//     const res = await fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json"
//       },
//       body: JSON.stringify(payload)
//     });

//     return { data: { success: res } };
//   } catch (error) {
//     return { error };
//   }
// };

// export default networkQualityPoll;
