import { Guid } from "guid-typescript";

import { VideocamOffOutlined as VideoCamOff } from "@mui/icons-material";
import { Fade } from "@mui/material";

import { Font } from "src/components/Font";
import { LayoutType, LayoutTypes } from "src/domains/Beacon/store/stream/types";

import styles from "./styles.scss";

export interface IProps {
  open: boolean;
  layoutType: LayoutType;
}

const ConsoleMutedMessage = () => {
  return (
    <div className={styles.consoleMutedMessage}>
      <div className={styles.icons}>
        <VideoCamOff
          sx={{ fontSize: 40 }}
          htmlColor="#9ea6ad" // mid-grey
        />
      </div>
      <Font variant="h2" color="disabled" className="console-video-muted-title">
        Console Video has been turned off
      </Font>
    </div>
  );
};

const typeToQuantity = {
  [LayoutTypes.TWO_VIEW]: 2,
  [LayoutTypes.FULLSCREEN]: 1,
};

// allows us to generate a given number of ConsoleVideoMuted instead of using a ternary,
// though it's overkill now, it will be useful when we implement 2X2 and other layouts
const generateMessagesFromLayoutType = (layoutType: LayoutType) => {
  const consolesMuted = [];
  for (let i = 0; i < typeToQuantity[layoutType]; i++) {
    consolesMuted.push(<ConsoleMutedMessage key={Guid.create().toString()} />);
  }
  return consolesMuted;
};

export const ConsoleVideoMuted = ({ open, layoutType }: IProps) => {
  return (
    <>
      <Fade in={open} timeout={200}>
        <div className={styles.noConsoleMessageContainer}>
          {generateMessagesFromLayoutType(layoutType)}
        </div>
      </Fade>
    </>
  );
};
