import { put, select, takeLatest } from "redux-saga/effects";
import { AppState } from "../../models";
import { setRefreshInProgress } from "../../user/actions";
import { UserActionKeys } from "../../user/actionTypes";

export function* startMeetingSaga() {
  const { refreshInProgress } = yield select((state: AppState) => state.user);

  /* If start meeting was initiated from refresh-frames, we shouldn't reset cameras */
  if (refreshInProgress) {
    yield put(setRefreshInProgress(false));
    return;
  }
}

export function* watchStartMeeting() {
  // Fired after join session event
  yield takeLatest(UserActionKeys.SET_HAS_JOINED_ROOM, startMeetingSaga);
}
