import { useQuery } from "react-query";

import { FetchError } from "src/services/ApiClient/ApiQuery";
import {
  getAllHospitals,
  HospitalListResponse,
} from "src/services/ApiClient/hospital";

const KEY = "hospitals";

export function useGetHospitals() {
  return useQuery<HospitalListResponse, FetchError>([`${KEY}`], () => {
    // TODO allow passing in these values as args when needed
    return getAllHospitals(0, "all", "name");
  });
}
