import { Guid } from "guid-typescript";
import {
  all,
  call,
  Effect,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";

import { API } from "../../../api";
import { logger } from "../../../common/logger";
import { setFreezeFrameImage } from "../../image/actions";
import {
  AppState,
  AvailKitState,
  CallModeType,
  FeaturesToSync,
  MediaStateType,
  MeetingStateType,
  MultiPartyInitializeState,
  PortalIdentity,
} from "../../models";
import { TwilioActionKeys } from "../../twilio/actionTypes";
import { UserActionKeys } from "../../user/actionTypes";
import { setMediaState } from "../../user/actions";
import { MeetingActionKeys } from "../actionTypes";
import {
  setConsoleHasExternalInputResizingAction,
  setMultiPartyEventDetails,
  setMultiPartyTelestrationHistory,
  setNoiseCancellationAvailableAction,
  setBluetoothEnabledAction,
  setNoiseCancellationAction,
} from "../actions";
import { MULTI_PARTY_LAUNCH_MODE } from "./../../../constants";

function* getTelestrations() {
  const sessionId: string = yield select(
    (state: AppState) => state.twilio.twilioCredentials.callSid
  );

  const identity: PortalIdentity = yield select(
    (s: AppState) => s.user.identity
  );

  try {
    const { data: telestrationHistory } = yield call(
      API.GET.telestrationsBySessionId,
      sessionId,
      identity.access_token
    );

    yield put(setMultiPartyTelestrationHistory(telestrationHistory));
  } catch (error) {
    console.error("Something went wrong");
  }
}

function* getSidebarStatus() {
  const sessionId: string = yield select(
    (state: AppState) => state.twilio.twilioCredentials.callSid
  );

  const identity: PortalIdentity = yield select(
    (s: AppState) => s.user.identity
  );

  try {
    // Note: Just for demonstration
    // To test the new presence API under `src/`, we can do so by calling that
    // function directly using similar yield expression like this:
    /*
      import { getPresenceForCallSession } from "src/services/ApiClient/presence"; // import up top

      const newAPIData = yield call(
        getPresenceForCallSession,
        sessionId,
      );
    */
    // The only difference is the format in whoch the response comes back, we can 
    // re-arrange that, but the API call should work.
    const { data } = yield call(
      API.GET.callSessionBySessionId,
      sessionId,
      identity.access_token
    );

    const dataFeatures = data.features;
    const originalMediaState: MediaStateType = yield select(
      (state: AppState) => state.user.mediaState
    );
    const sidebarActive = data.sidebarActive;

    if (data.freezeFrames.length > 0) {
      const transferIdentifier: Guid = data.freezeFrames[0].id;
      const { data: imageData } = yield call(
        API.GET.freezeFrameImageById,
        sessionId,
        identity.access_token,
        transferIdentifier
      );

      yield put(setFreezeFrameImage(imageData, transferIdentifier.toString()));
    }

    yield put(setBluetoothEnabledAction(dataFeatures.bluetoothAudio));
    if (dataFeatures.hasOwnProperty("noiseCancellation")) {
      yield put(setNoiseCancellationAvailableAction(true));
      yield put(setNoiseCancellationAction(dataFeatures.noiseCancellation));
    }
    if (dataFeatures.hasOwnProperty('externalInputResizing')) {
      yield put(setConsoleHasExternalInputResizingAction(dataFeatures.externalInputResizing));
    }

    yield put(
      setMediaState({
        ...originalMediaState,
        self: {
          ...originalMediaState.self,
          sidebar: sidebarActive ? "active" : "inactive",
        },
      })
    );
  } catch (error) {
    logger().warn(
      "Sidebar information could not be read from Presence Service. Continuing without latest sidebar status."
    );
  }
}

export function* initializeMultiPartyCall(action: Effect) {
  const callMode: CallModeType = yield select(
    (state: AppState) => state.meeting.mode
  );

  if (callMode !== MULTI_PARTY_LAUNCH_MODE) {
    console.warn("Attempted to initialize multi-party call during a non multi-party call. Skipping ...");
    return;
  }

  let initialMultiPartyState: MultiPartyInitializeState = (action.payload as unknown) as MultiPartyInitializeState;
  if (!initialMultiPartyState || !initialMultiPartyState.meetingToken) {
    const multiPartyCallEventInfo = yield select(
      (s: AppState) => s.meeting.multiPartyCallEventInfo
    );
    initialMultiPartyState = {
      meetingToken: multiPartyCallEventInfo,
      featuresToSync: FeaturesToSync.presence,
    };
  }
  const { featuresToSync } = initialMultiPartyState;

  logger().info(
    "Received a request to initialize a MultiParty Event with : " +
      featuresToSync
  );

  if (featuresToSync === FeaturesToSync.eventDetails) {
    logger().info("Updating event details");
    yield call(getMPEventDetails, action);
    return;
  }

  /* If there was a request to sync all, then we need access token, hence wait
   * until we get the relevant success events.
   */
  if (featuresToSync === FeaturesToSync.all) {
    // Avoids race conditions on these dependencies
    yield all([
      take(UserActionKeys.GET_IDENTITY_SUCCESS),
      take(TwilioActionKeys.GET_CREDENTIALS_SUCCESS),
    ]);

    // Setup MP Call
    yield call(getMPEventDetails, action);
  }

  logger().info("Obtaining callsession details from Presence Service");

  if (featuresToSync === FeaturesToSync.presence) {
    const { availKitInstance }: AvailKitState = yield select(
      (state: AppState) => state.availKit
    );

    const { callSid }: MeetingStateType = yield select(
      (state: AppState) => state.meeting
    );
    const identity: PortalIdentity = yield select(
      (s: AppState) => s.user.identity
    );

    availKitInstance.eventService.join(identity.pubnub_channel);
    availKitInstance.eventService.join(callSid);
  }

  // Init Call State with Presence Service
  yield all([
    call(getSidebarStatus),
    call(getTelestrations),
    // call(getFreezeFrames)
  ]);
  // TODO - add camera information here
}

function* getMPEventDetails(action: Effect) {
  const {
    meetingToken,
  } = (action.payload as unknown) as MultiPartyInitializeState;
  const { eventId } = meetingToken;
  const identity: PortalIdentity = yield select(
    (s: AppState) => s.user.identity
  );

  const { data } = yield call(
    API.GET.eventInfo,
    eventId,
    identity.access_token
  );

  yield put(setMultiPartyEventDetails(data));
}

// Notice, this effect must run first in the app because of its dependencies.
// If any of the three actions above are dispatched before we will hang.
export function* watchInitializeMultiPartyEvent() {
  yield takeLatest(
    MeetingActionKeys.INITIALIZE_MULTI_PARTY_CALL,
    initializeMultiPartyCall
  );
  yield takeLatest(
    MeetingActionKeys.GET_MULTI_PARTY_CALL_DETAILS,
    getMPEventDetails
  )
}

// TODO add watcher for telestration refresh midSession
