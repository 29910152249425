/* eslint-disable no-console */

/* eslint-disable no-empty */
import axios, { AxiosRequestConfig } from "axios";

import { SPLUNK_CONFIG, SPLUNK_URL_FALLBACK, AVAIL_LOG_ENV } from "./constants";

export enum LoggerLevels {
  off = 0,
  error,
  warn,
  info,
  verbose,
  debug,
}

class SplunkLoggerWrapper {
  static fallbackConfig = {
    token: "",
    url: SPLUNK_URL_FALLBACK,
  };

  private static commonFields: any = {
    component: "portalcall",
    env: AVAIL_LOG_ENV,
    callSessionId: "",
    profileId: "",
    loggerKey: "build",
    tic: -1,
    message: null,
  };

  private static _logLevel: LoggerLevels = LoggerLevels.info;

  static getLoggerCredentials() {
    try {
      const creds = {
        loggerUrl: new URL(SPLUNK_CONFIG.url),
        token: SPLUNK_CONFIG.token,
      };
      return creds;
    } catch (error) {
      const fbCreds = {
        loggerUrl: new URL(this.fallbackConfig.url),
        token: this.fallbackConfig.token,
      };
      this.commonFields.loggerKey = "identity";
      return fbCreds;
    }
  }

  static sendLogWithFields(
    severity: LoggerLevels,
    customFields: any,
    params: any
  ) {
    // Filter whether this message gets posted to Splunk
    if (severity > this._logLevel) {
      return;
    }

    const { loggerUrl, token } = this.getLoggerCredentials();

    const trace = new Error().stack?.split("\n");
    let fileInfo = "";
    if (trace && trace[3]) {
      // eslint-disable-next-line
      var path = trace[3].match(/(?:webpack\:)(([^)]+))/g);
      if (path && path[0]) {
        const locn = path[0].split("/");
        fileInfo = locn && locn[locn.length - 1];
      }
    }
    const msg = params.join("");
    const eventFields = { ...this.commonFields, ...customFields };
    const ourEvent = {
      event: {
        ...eventFields,
        message: msg,
        tic: Date.now(),
        fileInfo,
        level: LoggerLevels[severity],
      },
    };

    const postContent = JSON.stringify(ourEvent);

    if (window.location.host.includes("localhost")) {
      console.log(postContent);
      return;
    }

    const opt: AxiosRequestConfig = {
      baseURL: `${loggerUrl.protocol.toLowerCase()}//${loggerUrl.host}`,
      url: loggerUrl.pathname,
      method: "POST",
      headers: {
        "content-type": "application/json",
        // "content-length": Buffer.byteLength(postContent), // TODO: see if this works w/out this field
        // I'd like to remove these polyfills into NodeJS-specific
        // libraries
        Authorization: "Splunk " + token,
      },
      data: postContent,
    };

    async function makeCall() {
      try {
        const res = await axios.request(opt);
        if (res.status !== 200) {
          console.error(`Write to log returned: ${res.status}`);
        }
      } catch (error) {
        console.error(`Log API Error: ${error.message}`);
      }
    }
    makeCall();
  }

  static sendLog(severity: LoggerLevels, params: any) {
    this.sendLogWithFields(severity, {}, params);
  }

  static setCommonFields(fields: any) {
    this.commonFields = { ...this.commonFields, ...fields };
    try {
      console.log(`Logger common fields: ${JSON.stringify(this.commonFields)}`);
    } catch (e) {
      console.error(`Logger common fields could not be json stringified`);
    }
  }

  static setFallbackToken(key: string) {
    this.fallbackConfig.token = key;
  }

  static set LogLevel(level: LoggerLevels) {
    this._logLevel = level;
  }
}

// tslint:disable: no-console
export const logger = () => {
  return {
    setFallbackToken: (key: string) => {
      SplunkLoggerWrapper.setFallbackToken(key);
    },
    setCommonFields: (fields: any) => {
      SplunkLoggerWrapper.setCommonFields(fields);
      try {
        console.log(`setCommonFields: ${JSON.stringify(fields)}`);
      } catch (e) {
        console.error(`setCommonFields could not be json stringified`);
      }
    },
    setLogLevel: (level: LoggerLevels) => {
      SplunkLoggerWrapper.LogLevel = level;
      console.log(
        `Setting Logging Level to: ${LoggerLevels[level]} [${level}]`
      );
    },
    logWithFields: (level: LoggerLevels, fields: any, ...params: any) => {
      SplunkLoggerWrapper.sendLogWithFields(level, fields, params);
    },
    info: (...params: any) => {
      SplunkLoggerWrapper.sendLog(LoggerLevels.info, params);
      try {
        console.info(params);
      } catch (e) {}
    },
    warn: (...params: any) => {
      SplunkLoggerWrapper.sendLog(LoggerLevels.warn, params);
      try {
        console.warn(params);
      } catch (e) {}
    },
    error: (...params: any) => {
      SplunkLoggerWrapper.sendLog(LoggerLevels.error, params);
      try {
        console.error(params);
      } catch (e) {}
    },
    debug: (...params: any) => {
      SplunkLoggerWrapper.sendLog(LoggerLevels.debug, params);
      try {
        console.debug(params);
      } catch (e) {}
    },
    verbose: (...params: any) => {
      SplunkLoggerWrapper.sendLog(LoggerLevels.verbose, params);
      try {
        console.log(params);
      } catch (e) {}
    },
  };
};
