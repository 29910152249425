import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import UserSessionStorage from "src/services/UserSessionService";

import ModalComponent from "avail-web-application/Common/Components/Modal/Modal";
import resources from "avail-web-application/Common/Constants/resources";
import ApiHelper from "avail-web-application/Common/Services/ApiHelper";
import notificationService from "avail-web-application/Common/Services/notificationService";

import styles from "./styles.scss";

const { getEulaVersion, setEulaVersion } = UserSessionStorage;

interface EulaModalProps {
  storageType: "local" | "session";
}

interface EulaResponse {
  data: string;
  version: string;
}

export function EulaModal({ storageType }: EulaModalProps) {
  const previousEulaVersion = getEulaVersion(storageType);
  const [eulaData, setEulaData] = useState<EulaResponse>();
  const [isEulaAccepted, setIsEulaAccepted] = useState(!!previousEulaVersion);

  useEffect(() => {
    async function getEulaData() {
      try {
        const { data, headers } = await ApiHelper.getWithResponseType(
          "",
          resources.eula,
          undefined,
          "text/plain"
        );

        const version = headers.version;

        if (!data || !version) {
          // This is very unlikely to happen but adding these checks in case the
          // shape of the data that is returned is ever changed by mistake
          throw new Error("An error occurred while requesting EULA data");
        }

        // only update if EULA version is different or previously didn't exist
        if (previousEulaVersion || previousEulaVersion !== version) {
          setEulaData({
            data,
            version,
          });
        }
      } catch (e) {
        notificationService.error(e);
      }
    }
    getEulaData();
  }, []);

  const onConfirm = () => {
    setEulaVersion(eulaData.version, storageType);
    setIsEulaAccepted(true);
  };

  if (isEulaAccepted || !eulaData) {
    return null;
  }

  // Modals show up outside the flow of React children so it makes more sense to
  // render them at a singular area.
  // TODO - refactor modals to use the portal to improve DOM flow.
  return ReactDOM.createPortal(
    <ModalComponent
      open={!isEulaAccepted}
      onConfirm={onConfirm}
      confirmText="Agree"
      className={styles.eulaModal}
      dataTestId="eula-modal"
    >
      {eulaData && (
        <div className={styles.eulaContainer}>
          <iframe
            className={styles.eulaData}
            title="Avail EULA"
            srcDoc={eulaData.data}
            frameBorder="0"
          />
        </div>
      )}
    </ModalComponent>,
    document.getElementById("root") // For now, append modals to top level
  );
}
