// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_list_dzSimC{align-items:center;display:flex;flex-direction:column;margin-top:10%}.styles_group_FBh50v{align-items:center;display:flex;margin-bottom:20px;width:100%}.styles_group_FBh50v select{height:35px;margin-right:5%;width:70%}.styles_group_FBh50v button{height:35px;width:25%}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/SidePanel/SettingsContent/VideoSettings/styles.scss"],"names":[],"mappings":"AAAA,oBACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,cAAA,CAGF,qBACE,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,UAAA,CAEA,4BACE,WAAA,CACA,eAAA,CACA,SAAA,CAGF,4BACE,WAAA,CACA,SAAA","sourcesContent":[".list {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin-top: 10%;\n}\n\n.group {\n  align-items: center;\n  display: flex;\n  margin-bottom: 20px;\n  width: 100%;\n\n  select {\n    height: 35px;\n    margin-right: 5%;\n    width: 70%;\n  }\n\n  button {\n    height: 35px;\n    width: 25%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "styles_list_dzSimC",
	"group": "styles_group_FBh50v"
};
export default ___CSS_LOADER_EXPORT___;
