import { logger } from "../common/logger";
import { MediaDeviceEnumeration } from "../store/models";
import { ApiResponse } from "./types";

const localMedia = async (): Promise<ApiResponse<MediaDeviceEnumeration>> => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const deviceEnumeration = {
      audioinput: devices.filter(
        (media) => media.kind === "audioinput" && media.deviceId !== "default"
      ),
      audiooutput: devices.filter(
        (media) => media.kind === "audiooutput" && media.deviceId !== "default"
      ),
      videoinput: devices.filter(
        (media) => media.kind === "videoinput" && media.deviceId !== "default"
      )
    };
    const videoInputs = deviceEnumeration.videoinput;
    logger().info("videoInputs", JSON.stringify(videoInputs));

    if (!videoInputs.length) {
      logger().error("No video inputs are available");
      return {
        data: {
          localMedia: null,
          audioinput: deviceEnumeration.audioinput,
          audiooutput: deviceEnumeration.audiooutput,
          videoinput: deviceEnumeration.videoinput
        }
      };
    }

    // Check if there is a video input with a valid capturer label
    const validInput = videoInputs[0];

    logger().info(
      "videoinput being used : " +
        validInput.label +
        " : " +
        validInput.deviceId
    );
    // Return a valid video input
    return {
      data: {
        localMedia: validInput,
        audioinput: deviceEnumeration.audioinput,
        audiooutput: deviceEnumeration.audiooutput,
        videoinput: deviceEnumeration.videoinput
      }
    };
  } catch (error) {
    // Error getting local media...
    return { error };
  }
};

export default localMedia;
