import React from 'react';
import { Tab } from 'semantic-ui-react';

import './TabsWithMenu.scss';

function TabsWithMenu({ options, activeIndex, onTabChange }) {
	return (
		<div className="tabs-with-menu">
			<Tab
				panes={options}
				activeIndex={activeIndex}
				onTabChange={onTabChange}
				menu={{
					fluid: true,
					vertical: true,
					tabular: true,
				}}
			/>
		</div>
	);
}

export default TabsWithMenu;
