import { NETWORK_QUALITY_CHECK_FREQUENCY } from "../../constants";
import { LocalOrRemoteMediaTrack } from "../../types";
import { deepMergeSum } from "../../utils";
import { TwilioState } from "../models";
import { TwilioActionKeys, TwilioActionType } from "./actionTypes";
import { logger, LoggerLevels } from "src/logging/logger";

const initialState: TwilioState = {
  twilioCredentials: {
    token: ""
  },
  room: null,
  localTracks: [],
  remoteTracks: [],
  participants: [],
  error: false,
  errorMessage: [],
  remoteHasJoinedRoom: false,
  consoleHasJoinedRoom: false,
  hostHasJoinedRoom: false,
  enableCameraDiscovery: false,
  isPollingNQ: false,
  statsCQ: null, // Global stats for call quality
  callStartTime: new Date().getTime(),
  callTime: 0,
  log: [],
  dimensionsNeedUpdate: false,
  duplicateParticipantDisconnected: false,
  localNetworkQualityLevel: -1,
  localNetworkDisconnected: false,
  bannerNeedsUpdate: false,
  dominantSpeaker: "",
  mpLiteRemoteTracks: {
    console: [],
    host: [],
    participants: []
  },
  mediaErrorCode: null,
  twilioLogger: undefined
};

const twilio = (
  state = initialState,
  action: TwilioActionType
): TwilioState => {
  switch (action.type) {
    case TwilioActionKeys.GET_CREDENTIALS_SUCCESS:
      return {
        ...state,
        twilioCredentials: action.twilioCredentials,
        error: false
      };

    case TwilioActionKeys.GET_CREDENTIALS_FAILED: {
      const errorMessage: string[] = [...state.errorMessage];
      if (action.errorStatus) {
        errorMessage.push(action.errorStatus);
      }

      return {
        ...state,
        error: true,
        errorMessage
      };
    }
    case TwilioActionKeys.CONNECT_CALL_SUCCESS:
      return {
        ...state,
        room: action.room,
        error: false,
        callStartTime: new Date().getTime()
      };

    case TwilioActionKeys.CONNECT_CALL_FAILED:
      return { ...state, error: true };

    case TwilioActionKeys.ATTACH_LOCAL_TRACKS:
      return { ...state, localTracks: action.tracks };

    case TwilioActionKeys.LOCAL_MEDIA_NOT_FOUND:
    case TwilioActionKeys.CAMERA_ISSUE_ENCOUNTERED:
      let errorMessage: string[] = [...state.errorMessage];

      return {
        ...state,
        error: true,
        errorMessage
      };

    case TwilioActionKeys.MEDIA_ERROR_ENCOUNTERED:
      return {
        ...state,
        error: true,
        mediaErrorCode: action.errorCode
      };

    case TwilioActionKeys.UPDATE_PARTICIPANTS:
      const updatedRemoteTracks = action.participants.length
        ? state.remoteTracks
        : [];
      const consolePTracks: LocalOrRemoteMediaTrack[] = [];

      if (action.participants.length) {
        const consoleParticipant = action.participants.find((p) =>
          p.identity.includes("CONSOLE")
        );

        // Get the video from remoteTracks that belongs to console
        updatedRemoteTracks.forEach((track: any) => {
          if (track.sid) {
            const sid = track.sid;
            if (consoleParticipant?.tracks.has(sid)) {
              logger().logWithFields(LoggerLevels.info, {
              fileInfo: `twilio/reducer.ts`,
              feature: `Redux Store`,
              },
              `Adding Console ${track.kind} track, ${track.identity}`);
              consolePTracks.push(track);
            }
          }
        });
      }

      return {
        ...state,
        participants: action.participants,
        remoteTracks: updatedRemoteTracks,
        mpLiteRemoteTracks: {
          ...state.mpLiteRemoteTracks,
          console: consolePTracks
        },
        error: false
      };

    case TwilioActionKeys.UPDATE_DOMINANT_SPEAKER:
      return {
        ...state,
        dominantSpeaker: action.uuid
      };

    case TwilioActionKeys.UPDATE_TRACKS:
      const newRemoteTracks = new Set([
        ...state.remoteTracks,
        ...action.tracks
      ]);
      const consoleTracks: LocalOrRemoteMediaTrack[] = [];
      const { participants } = state;
      if (participants.length) {
        const consoleParticipant = participants.find((p) =>
          p.identity.includes("CONSOLE")
        );

        // Get the video from remoteTracks that belongs to console
        newRemoteTracks.forEach((track: any) => {
          console.log("Check if its a console track " + track);
          if (track.sid) {
            console.log("Checking against " + track.sid);
            const sid = track.sid;
            if (consoleParticipant?.tracks.has(sid)) {
              console.log("Its a console track");
              consoleTracks.push(track);
            } else {
              console.log("Its not a console track");
            }
          }
        });
      }

      return {
        ...state,
        mpLiteRemoteTracks: {
          ...state.mpLiteRemoteTracks,
          console: [...consoleTracks]
        },
        remoteTracks: [...newRemoteTracks],
        error: false
      };

    case TwilioActionKeys.SET_HOST_TRACKS:
      return {
        ...state,
        mpLiteRemoteTracks: {
          ...state.mpLiteRemoteTracks,
          host: [...action.tracks]
        },
        error: false
      };

    case TwilioActionKeys.CLEAR_TWILIO_TRACKS:
      return {
        ...state,
        mpLiteRemoteTracks: {
          console: [],
          host: [],
          participants: []
        },
        participants: [],
        remoteTracks: []
      };

    case TwilioActionKeys.UPDATE_BANNER_NEEDS_UPDATE:
      return { ...state, bannerNeedsUpdate: action.bannerNeedsUpdate };

    case TwilioActionKeys.UPDATE_DIMENSIONS:
      return { ...state, dimensionsNeedUpdate: action.needsUpdate };

    case TwilioActionKeys.UPDATE_DUPLICATE_PARTICIPANT_DISCONNECTED:
      return {
        ...state,
        duplicateParticipantDisconnected: action.duplicateParticipantDisconnected
      };

    case TwilioActionKeys.UPDATE_LOCAL_NETWORK_QUALITY_LEVEL:
      return {
        ...state,
        localNetworkQualityLevel: action.localNetworkQualityLevel
      };
    case TwilioActionKeys.UPDATE_LOCAL_NETWORK_DISCONNECTED:
      return {
        ...state,
        localNetworkDisconnected: action.localNetworkDisconnected
      };

    case TwilioActionKeys.UPDATE_FAILED:
      return { ...state, error: true };

    case TwilioActionKeys.DISCONNECT_CALL_SUCCESS:
      return { ...state, error: false };

    case TwilioActionKeys.DISCONNECT_CALL_FAILED:
      return { ...state, error: true };

    case TwilioActionKeys.NETWORK_QUALITY_START_POLLING:
      return { ...state, isPollingNQ: true };

    case TwilioActionKeys.NETWORK_QUALITY_STOP_POLLING:
      return { ...state, isPollingNQ: false };

    case TwilioActionKeys.CALL_QUALITY_MERGE_SUM:
      if (state.statsCQ === null) {
        return {
          ...state,
          statsCQ: action.stats,
          callTime: state.callTime + NETWORK_QUALITY_CHECK_FREQUENCY
        };
      }
      const statsCQ = deepMergeSum(state.statsCQ, action.stats);
      return {
        ...state,
        statsCQ,
        callTime: state.callTime + NETWORK_QUALITY_CHECK_FREQUENCY
      };

    case TwilioActionKeys.SET_REMOTE_HAS_JOINED_ROOM:
      const enableCameraDiscovery =
        state.enableCameraDiscovery || action.remoteHasJoinedRoom;
      return {
        ...state,
        remoteHasJoinedRoom: action.remoteHasJoinedRoom,
        enableCameraDiscovery
      };

    case TwilioActionKeys.SET_CONSOLE_HAS_JOINED_ROOM:
      const enableCameraDiscoveryConsole =
        state.enableCameraDiscovery || action.consoleHasJoinedRoom;
      return {
        ...state,
        consoleHasJoinedRoom: action.consoleHasJoinedRoom,
        enableCameraDiscovery: enableCameraDiscoveryConsole
      };

    case TwilioActionKeys.SET_HOST_HAS_JOINED_ROOM:
      const enableCameraDiscoveryHost =
        state.enableCameraDiscovery || action.hostHasJoinedRoom;
      return {
        ...state,
        hostHasJoinedRoom: action.hostHasJoinedRoom,
        enableCameraDiscovery: enableCameraDiscoveryHost
      };

    case TwilioActionKeys.LOG_ACTIVITY:
      return { ...state, log: [...state.log, action.message] };

    case TwilioActionKeys.TWILIO_UPDATE_LOGGER:
      return { ...state, twilioLogger: action.logger };

    default:
      return state;
  }
};

export default twilio;
