import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
/* Components */
import { EntityProfileSidebar, ProfileBreadcrumbs, TabsWithMenu } from '../../../../Common';
/* Actions */
import { getAllStatesAction } from '../../../Organisation/OrganisationActions';
import { getProviderByIdAction, updateProviderByIdAction } from '../../ProviderActions';
/* Services */
import { urlService } from '../../../../../../Common/Services';
/* Styles */
import '../../Provider.scss';
import ProviderUsers
	from '../../Components/ProviderUsers/ProviderUsers';
import ProviderInfo from '../../Components/ProviderInfo/ProviderInfo';

class ProviderProfile extends PureComponent { 
	state = {
    activeTabIndex: 0,
  }

	componentDidMount() {
		this.providerId = urlService.getQueryParam(this.props.location, 'providerId');
		this.props.getProviderById(this.providerId);
		this.props.getAllStates();
	}

	handleTabChange = (data) => {
		this.setState({
      activeTabIndex: data.activeIndex 
    });
	};

	renderTabs = () => {
		const { provider } = this.props;
		return [{
			menuItem: 'General Info',
			render: () => <ProviderInfo />,
		}, {
			menuItem: 'Users',
			render: () =>
				<ProviderUsers providerId={provider.id} providerType="district" />,
		}];
	};

	renderOptions() {
		const { provider } = this.props;
		return [{
			title: provider.name,
			key: 'test',
			className: 'profile-breadcrumbs__menu-item--active',
			to: `/provider/profile?providerId=${provider.id}`,
		}];
	}

	render() {
		const { t } = this.props;
		return (
			<div className="display--flex height--full flex--one">
				<EntityProfileSidebar title={t('features.provider.name')}>
					{this.props.provider &&
					<ProfileBreadcrumbs options={this.renderOptions()} />
					}
				</EntityProfileSidebar>
				<div className="vendor-profile__right-side">
					{this.props.provider && 
            <TabsWithMenu 
              activeIndex={this.state.activeTabIndex}
              onTabChange={this.handleTabChange}
              options={this.renderTabs()} 
            />
          }
				</div>
			</div>
		);
	}
}

ProviderProfile.propTypes = {};

const mapStateToProps = state => ({
	provider: state.providerReducer.provider,
	states: state.organisationsReducer.states,
});

const mapDispatchToProps = dispatch => ({
	getProviderById: providerId => dispatch(getProviderByIdAction(providerId)),
	updateProviderById: (providerId, providerData) => dispatch(updateProviderByIdAction(providerId, providerData)),
	getAllStates: () => dispatch(getAllStatesAction()),
});

const ProviderProfileWithTranslation = withTranslation()(ProviderProfile);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'providerProfileForm',
	enableReinitialize: true,
})(ProviderProfileWithTranslation));
