import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { NCameraChangeEvent } from "availkit-js/dist/Models/Events/NCameraChangeEvent";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/domains/Beacon/store";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { LayoutFrames } from "src/domains/Beacon/store/stream/types";
import { CameraIdentifier } from "src/domains/Beacon/utils/availKit";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

export const setCameraChangeEventThunk = createAsyncThunk<
  void,
  { layoutFrames: LayoutFrames },
  {
    state: RootState;
  }
>("stream/setCameraChangeEventThunk", ({ layoutFrames }, { getState }) => {
  try {
    logger().info("setCameraChangeEventThunk User selected a preset");

    const availKit = AvailKitService.instance;
    const {
      meeting: { callSid, isUserHost, mode, joinId },
      stream: { cameras },
    } = getState();
    const cameraChangeEvent = new NCameraChangeEvent(callSid);
    const avkCameras = cameras as AVKCamera[];

    // Create ordered array of the Cameras in each frame
    const framedCameraIds: string[] = Object.values(layoutFrames).map(
      (layoutFrame) => layoutFrame.cameraId
    );

    const fullscreenSource = Object.fromEntries(
      Object.entries(layoutFrames).filter(([_, value]) => value.isFullScreen)
    );

    const localList: AVKCamera[] = [];

    if (Object.keys(fullscreenSource).length !== 0) {
      const fullscreenSourceInfo = Object.values(fullscreenSource)[0];

      localList.push(
        ...avkCameras.filter(
          (camera) => CameraIdentifier(camera) === fullscreenSourceInfo.cameraId
        )
      );
    } else {
      framedCameraIds.forEach((cameraId) => {
        localList.push(
          (cameras as AVKCamera[]).filter(
            (camera) => CameraIdentifier(camera) === cameraId
          )[0]
        );
      });
    }

    logger().logWithFields(
      LoggerLevels.info,
      { feature: "setCameraChangeEventThunk" },
      `Selecting cameras: ${framedCameraIds}`
    );

    // for 1.5 consoles, ensure not to have multipane events
    cameraChangeEvent.targetCameras = localList.splice(0, 2);
    cameraChangeEvent.sender = joinId;

    if (!(mode === CallModes.MP && !isUserHost)) {
      availKit?.eventService.broadcast(cameraChangeEvent);
    }
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      { feature: "setCameraChangeEventThunk" },
      `Error while changing between presets without EIR flag. ${error?.message}`
    );
    throw new Error("Error setting video layout event.");
  }
});
