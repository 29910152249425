import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
/* Helpers */
import userTypes from '../../../../../../Common/Constants/userTypes';
import userService from '../../../../../../Common/Services/userService';
/* Components */
import { AddNewButtonWithIcon, Pill, ProfileImage } from '../../../../Common';

class VendorApprovalTableRow extends PureComponent {
	onOpenConfirmationModal = isApproved => this.props.openModal(isApproved, this.props.rowData.applicant);

	renderTableAction = (status) => {
		switch (status) {
			case 'PENDING':
				return (
					<div className="flex-direction--row justify-content--flex-end">
						<AddNewButtonWithIcon
							title="Approve"
							titleClass="table__action-title"
							iconClass="icon-checkmark-outline icon--green table__action-icon table__action-icon--blue"
							onClick={this.onOpenConfirmationModal}
							argument={{ approved: true }}
						/>
						<AddNewButtonWithIcon
							title="Reject"
							titleClass="table__action-title table__action-title--red"
							iconClass="icon-close-outline icon--red-secondary table__action-icon table__action-icon--red"
							onClick={this.onOpenConfirmationModal}
							argument={{ approved: false }}
						/>
					</div>
				);
			case 'APPROVED':
				return (
					<div className="justify-content--flex-end">
						<AddNewButtonWithIcon
							title="Reject"
							titleClass="table__action-title table__action-title--red"
							iconClass="icon-close-outline icon--red-secondary table__action-icon table__action-icon--red"
							onClick={this.onOpenConfirmationModal}
							argument={{ approved: false }}
						/>
					</div>
				);
			case 'REJECTED':
				return (
					<div className="justify-content--flex-end">
						<AddNewButtonWithIcon
							title="Approve"
							titleClass="table__action-title"
							iconClass="icon-checkmark-outline icon--green table__action-icon table__action-icon--blue"
							onClick={this.onOpenConfirmationModal}
							argument={{ approved: true }}
						/>
					</div>
				);
			default:
				return null;
		}
	};

	render() {
		const { applicant, status } = this.props.rowData;

		return (
			applicant ? (
				<Table.Row>
					<Table.Cell onClick={this.redirect} title={[applicant.firstName].join(' ')}>
						<div className="table__name-wrapper display--flex">
							<div className="position--relative">
								<ProfileImage
									size="sm"
									className={`profile-image--${userTypes[applicant.userTypes[0]].className} `}
									initials={userService.getUserInitials(applicant.firstName, applicant.lastName)}
								/>
							</div>
							<span className="table__username users__table-username">{`${applicant.firstName} ${applicant.lastName}`}</span>
						</div>
					</Table.Cell>
					<Table.Cell title={[userTypes[applicant.userTypes[0]].title].join(' ')}>
						<Pill
							isDisabled={status === 'DELETED'}
							class={`user-type--${userTypes[applicant.userTypes[0]].className} `}
							title={userTypes[applicant.userTypes[0]].title}
						/>
					</Table.Cell>
					<Table.Cell title={[status].join(' ')}>
						<Pill
							class={`status status--${status.toLowerCase()} `}
							title={status.toLowerCase()}
						/>
					</Table.Cell>
					<Table.Cell className="table__action-table-cell">
						{this.renderTableAction(status)}
					</Table.Cell>
				</Table.Row>
			) : null
		);
	}
}

VendorApprovalTableRow.propTypes = {};

export default VendorApprovalTableRow;

