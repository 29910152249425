import { RootState } from "src/domains/Beacon/store";

export const selectMeetingState = (state: RootState) => state.meeting;

export const selectIsUserHost = (state: RootState) => state.meeting.isUserHost;

export const selectIsLaunchDarklyFetchDone = (state: RootState) =>
  state.meeting.launchDarklyFetchDone;

export const selectEventDetails = (state: RootState) =>
  state.meeting.callDetails;

export const selectTriggerRefreshFrames = (state: RootState) =>
  state.meeting.triggerRefreshFrames;

export const selectCallMeetingModeState = (state: RootState) =>
  state.meeting.mode;

export const selectMeetingCallSid = (state: RootState) => state.meeting.callSid;

// 3rd party integration
export const selectThirdPartyIntegration = (state: RootState) =>
  state.meeting.thirdPartyIntegration;

// PubNub
export const selectPubNubChannel = (state: RootState) =>
  state.meeting.pubnub_channel;
