import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { getcurrentUserTypes } from '../../HomeSelectors';
import AccessControlService from '../../../../Common/Services/accessControlService';

class RestrictedRoute extends PureComponent {
	shouldRenderChildren = () => {
		const { currentUserTypes, context } = this.props;
		const action = 'visit';
		return AccessControlService.doesUserHaveAccess(currentUserTypes, action, context);
	};

	render() {
		const { component: Component, isLoggedIn, ...rest } = this.props;
		return (
			<Route
				{...rest}
				render={props => (
					this.shouldRenderChildren() ? (
						<Component {...props} />
					) : (
						<Redirect exact to="/not-found" />
					)
				)}
			/>
		);
	}
}

const mapStateToProps = state => ({
	currentUserTypes: getcurrentUserTypes(state),
});

export default withRouter(connect(mapStateToProps, undefined)(RestrictedRoute));
