import clsx from "clsx";

import CancelIcon from "@mui/icons-material/Cancel";
import { Chip as MuiChip } from "@mui/material";

import styles from "./styles.scss";

export type ChipColorTypes =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "success";
interface Props {
  // TODO handle these colors in styles.scss file so we can customize them with our colors
  color?: ChipColorTypes;
  size?: "medium" | "small";
  label: string;
  className?: string;
  handleDelete?: () => void;
  disabled?: boolean;
  "data-test-id"?: string;
}

export const Chip = ({
  label,
  handleDelete,
  color = "primary",
  size = "medium",
  disabled = false,
  className,
  "data-test-id": dataTestId = "chip",
  ...props
}: Props) => {
  return (
    <MuiChip
      classes={{
        root: styles.root,
      }}
      className={clsx(styles.chip, className, styles[color])}
      size={size}
      label={label}
      onDelete={handleDelete}
      color={color}
      disabled={disabled}
      {...props}
      data-test-id={dataTestId}
      deleteIcon={<CancelIcon data-test-id={`${dataTestId}-cancel`} />}
    />
  );
};
