import React from 'react';
import { Table } from 'semantic-ui-react';

function getMinutesBetweenDates(startDate, endDate) {
	const diff = endDate.getTime() - startDate.getTime();
	return Math.abs(Math.ceil(diff / 60000));
}

function CallDetailRecordTableRow({ rowData }) {
	const { startTime, endTime, email, callState, firstName, lastName } = rowData;

	const duration = (!startTime || !endTime)
		? 0
		: getMinutesBetweenDates(new Date(startTime), new Date(endTime));

	const startDateTime = startTime
		? (`${new Date(startTime).toLocaleTimeString()} - ${new Date(startTime).toDateString()}`)
		: '';

	return (
		<Table.Row>
			<Table.Cell>{startDateTime}</Table.Cell>
			<Table.Cell>{duration}</Table.Cell>
			<Table.Cell>{`${firstName} ${lastName}`}</Table.Cell>
			<Table.Cell>{email || 'N/A'}</Table.Cell>
			<Table.Cell>{callState}</Table.Cell>
		</Table.Row>
	);
}

export default CallDetailRecordTableRow;
