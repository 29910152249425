import React from 'react';
import { reduxForm } from 'redux-form';
import { Button, Fields, validators } from '../../../../../../../Common/Components';

function CallDetailRecordForm({ onSubmit }) {
	return (
		<React.Fragment>
			<form className="cdr-form" action="" onSubmit={onSubmit}>
				<Fields.SingleDateField
					labelText="From"
					placeholderText="From"
					name="from"
					showTimeSelect
					timeFormat="hh:mm a"
					timeIntervals={60}
					validate={validators.required}
				/>
				<Fields.SingleDateField
					labelText="To"
					placeholderText="To"
					name="to"
					showTimeSelect
					timeFormat="hh:mm a"
					timeIntervals={60}
					validate={validators.required}
				/>
				<div className="cdr-form__btn-group justify-content--flex-end">
					<Button
						type="submit"
						isFull
						buttonSmall
					>
						Get
					</Button>
				</div>
			</form>
		</React.Fragment>
	);
}

export default reduxForm({
	form: 'callDetailsRecordForm',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(CallDetailRecordForm);
