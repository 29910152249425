import TwilioVideo, { LocalAudioTrack, LocalVideoTrack } from "twilio-video";

import { logger } from "../common/logger";
import { HOST_PIP_VIDEO_LOG_MESSAGE } from "../constants";
import { MediaStateType } from "../store/models";
import { ApiResponse } from "./types";

const createLocalTracks = async (
  deviceId: string,
  mediaState: MediaStateType,
  shouldPublishVideoTracks = true // default
): Promise<ApiResponse<TwilioVideo.LocalTrack[]>> => {
  try {
    const localTracks = await TwilioVideo.createLocalTracks({
      audio: true,
      video: shouldPublishVideoTracks
        ? {
            deviceId,
          }
        : false,
    });

    if (mediaState) {
      localTracks.forEach((track) => {
        if (track instanceof LocalAudioTrack) {
          if (mediaState.self.audio === "mute") {
            track.disable();
          } else {
            track.enable();
          }
        }
        if (track instanceof LocalVideoTrack) {
          logger().info(
            HOST_PIP_VIDEO_LOG_MESSAGE + JSON.stringify(track.dimensions)
          );
          if (mediaState.self.video === "mute") {
            track.disable();
          } else {
            track.enable();
          }
        }
      });
    }
    return { data: localTracks };
  } catch (error) {
    logger().error(
      "Error while creating local tracks with device : " + deviceId
    );
    return { error };
  }
};

export default createLocalTracks;
