// import LogService from "./logService";

class SessionStorageService {
  static add(key, value) {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      // LogService.error({
      //   error: JSON.stringify(e, Object.getOwnPropertyNames(e)),
      //   message: `Error when adding key ${key} to sessionStorage`,
      // });
    }
  }

  static get(key) {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (e) {
      // LogService.error({
      //   error: JSON.stringify(e, Object.getOwnPropertyNames(e)),
      //   message: `Error when getting key ${key} from sessionStorage`,
      // });
    }
  }

  static remove(key) {
    sessionStorage.removeItem(key);
  }

  static addTokens(keys, accessToken, refreshToken) {
    try {
      sessionStorage.setItem(keys.access, JSON.stringify(accessToken));
      sessionStorage.setItem(keys.refresh, JSON.stringify(refreshToken));
    } catch (e) {
      // LogService.error({
      //   error: JSON.stringify(e, Object.getOwnPropertyNames(e)),
      //   message: `Error when adding tokens to localStorage`,
      // });
    }
  }
}

export default SessionStorageService;
