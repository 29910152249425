import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectIsLaunchDarklyFetchDone } from "src/domains/Beacon/store/meeting/selectors";
import { getMeetingTokenThunk } from "src/domains/Beacon/store/meeting/thunks/getMeetingTokenThunk";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { useGetQueryParams } from "src/hooks/useGetQueryParams";
import { logger } from "src/logging/logger";

export const useGetMeetingToken = () => {
  const dispatch = useDispatch();
  const launchDarklyFetchDone = useAppSelector(selectIsLaunchDarklyFetchDone);
  const { meeting_token: encodedMeetingToken } = useGetQueryParams(
    "meeting_token"
  );
  const { offerDeviceSelection } = useFeatureFlags();

  useEffect(() => {
    if (!launchDarklyFetchDone) {
      logger().warn(
        "Launch darkly flags aren't ready, will skip useGetMeetingToken..."
      );
      return;
    }

    // Only works when 'offerDeviceSelection' is "true" due that it'll be able to
    // fetch the call details while the user is in PreCall
    if (offerDeviceSelection) {
      logger().info(
        "User has access to PreCall, meeting token will be decoded meanwhile"
      );
      dispatch(
        getMeetingTokenThunk({
          encodedMeetingToken,
        })
      );
    }
  }, [launchDarklyFetchDone]);
};
