import { NHighlightLocationEvent } from "availkit-js/dist/Models/Events/NHighlightLocationEvent";
import { PointerActionKeys, PointerActionType } from "./actionTypes";
import { TelestrationState, TelestrationStylusColor } from "../models";

export const addHighlightPoint = (
  data: NHighlightLocationEvent
): PointerActionType => ({
  type: PointerActionKeys.POINTER_ADD_HIGHLIGHT,
  highlight: data
});

export const removeHighlightPoint = (id: string): PointerActionType => ({
  type: PointerActionKeys.POINTER_REMOVE_HIGHLIGHT,
  id
});

export const updateTelestrationState = (
  telestration: TelestrationState,
  stylusColor: TelestrationStylusColor
): PointerActionType => ({
  type: PointerActionKeys.UPDATE_TELESTRATION_STATE,
  telestration,
  stylusColor
});
