import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import Can from '../../../../../../../../Common/Acl/AccessControl';
/* Components */
import { Button, Icon } from '../../../../../../../../Common/Components';
import AccessControlService from '../../../../../../../../Common/Services/accessControlService';
import AccordionComponent from '../../../../../../Common/AccordionComponent/AccordionComponent';
import { getcurrentUserTypes } from '../../../../../../HomeSelectors';
/* Actions */
import { getUserTherapiesAction, getTherapiesAction } from '../../../../../Organisation/Vendors/VendorsActions';
import { updateUserProceduresAction } from '../../../../UsersActions';
/* Helpers */
import { getAllDescendantUsersIds, getInitialValuesForUserProfileTherapies } from '../../../../UsersSelectors';
import UserService from '../../../../../../../../Common/Services/userService';

class UserTherapies extends PureComponent {
	componentDidMount() {
		const userType = this.props.currentUser && this.props.currentUser.userTypes;
		if (UserService.checkIsProctorOrSpecialist(userType) || UserService.checkIsIndividualSalesRep(userType)) {
			this.props.getTherapies();
		} else {
			this.props.getUserTherapies(this.props.userId);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.userId !== nextProps.userId) {
			nextProps.getUserTherapies(nextProps.userId);
		}
	}

	onSelectAllHandler = (e, id) => {
		const { currentUser } = this.props;
		const isUserProctorOrSpecialist = UserService.checkIsProctorOrSpecialist(currentUser.userTypes);
		const isUserIndividualSalesRep = UserService.checkIsIndividualSalesRep(currentUser.userTypes);
		const therapies = (isUserProctorOrSpecialist || isUserIndividualSalesRep) ? this.props.therapies : this.props.userTherapies;
		therapies.forEach((therapy) => {
			if (therapy.id === id) {
				therapy.procedures.forEach((procedure) => {
					this.props.change(`procedures.${procedure.id}`, e.target.checked);
				});
			}
		});
	};

	handleSubmit = (data) => {
		const procedureIds = this.formatProcedures(data.procedures);
		this.props.updateUserProcedures(this.props.userId, procedureIds);
	};

	formatProcedures = (procedures) => {
		return {
			'ids': procedures ? Object.keys(procedures).filter((key) => {
				if (procedures[key]) {
					return key;
				}
			}) : [],
		};
	};

	render() {
		const { userTherapies, handleSubmit, therapies, currentUser } = this.props;
		const isUserProctorOrSpecialist = UserService.checkIsProctorOrSpecialist(currentUser.userTypes);
		const isUserIndividualSalesRep = UserService.checkIsIndividualSalesRep(currentUser.userTypes);
		let areProceduresDisabled = AccessControlService.doesUserHaveAccess(this.props.currentUser && this.props.currentUser.userTypes, 'edit', 'userProcedures', {
		currentUserId: this.props.currentUser && this.props.currentUser.id,
			userProfileId: this.props.userId,
			descendantUsersIds: this.props.descendantUsersIds,
		});
		if(this.props.currentUser && this.props.currentUser.userTypes[0] == 'SPECIALIST' ) {
			areProceduresDisabled = true
		}
		return (
			<form
				className="avail-profile__edit-form flex-direction--row justify-content--space-between"
				onSubmit={handleSubmit(this.handleSubmit)}
			>
				{!(isUserProctorOrSpecialist || isUserIndividualSalesRep) &&
				<AccordionComponent
					data={userTherapies}
					className="min-height--auto"
					procedureName="procedures"
					onSelectAllHandler={this.onSelectAllHandler}
					initialValues={this.props.initialValues}
					selectedValues={this.props.userProfileTherapiesForm}
					disabledProcedures={!areProceduresDisabled}
				/>
				}
				{
					therapies && therapies.length > 0 &&
					<React.Fragment>
						<AccordionComponent
							data={therapies}
							className="min-height--auto"
							procedureName="procedures"
							onSelectAllHandler={this.onSelectAllHandler}
							initialValues={this.props.initialValues}
							selectedValues={this.props.userProfileTherapiesForm}
							disabledProcedures={!areProceduresDisabled}
						/>
					</React.Fragment>
				}
				<Can
					userTypes={this.props.currentUserTypes}
					action="read"
					context="addProcedures"
					data={{
						currentUserId: this.props.currentUser && this.props.currentUser.id,
						userProfileId: this.props.userId,
						descendantUsersIds: this.props.descendantUsersIds,
					}}
				>
					{!(!(isUserProctorOrSpecialist || isUserIndividualSalesRep) && userTherapies && userTherapies.length == 0) && (
					<div className="user-profile__submit-button--fixed">
						<Button className="button-with-icon align-self--flex-start padding--none" type="submit">
							<Icon className="icon-save icon--orange entity-profile__submit-icon" />
							<span className="entity-profile__submit-title">Save changes</span>
						</Button>
					</div>
					)}
				</Can>
				{
					this.props.currentUser && this.props.currentUser.userTypes[0] == 'SPECIALIST'  && (
						<div className="user-profile__submit-button--fixed">
							<Button className="button-with-icon align-self--flex-start padding--none" type="submit">
								<Icon className="icon-save icon--orange entity-profile__submit-icon" />
								<span className="entity-profile__submit-title">Save changes</span>
							</Button>
						</div>

					)
				}
			</form>
		);
	}
}

UserTherapies.propTypes = {};

const mapStateToProps = state => ({
	initialValues: getInitialValuesForUserProfileTherapies(state),
	userTherapies: state.vendorsReducer.userTherapies,
	therapies: state.vendorsReducer.therapies,
	userProfileTherapiesForm: getFormValues('userProfileTherapiesForm')(state),
	currentUser: state.homeReducer.currentUser,
	descendantUsersIds: getAllDescendantUsersIds(state),
	currentUserTypes: getcurrentUserTypes(state),
});

const mapDispatchToProps = dispatch => ({
	updateUserProcedures: (userId, proceduresId) => dispatch(updateUserProceduresAction(userId, proceduresId)),
	getUserTherapies: userId => dispatch(getUserTherapiesAction(userId)),
	getTherapies: () => dispatch(getTherapiesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'userProfileTherapiesForm',
	enableReinitialize: true,
})(UserTherapies));
