import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, Icon, validators, Fields } from '../../../../../../Common/Components';
import { getAllStatesAction } from '../../../Organisation/OrganisationActions';
import { updateProviderByIdAction } from '../../ProviderActions';
import { uploadLogo } from '../../ProviderUtils';
import { notificationService } from '../../../../../../Common/Services';

class ProviderInfo extends PureComponent {
	componentDidMount() {
		this.props.getAllStates();
	}

	onProviderUpdateHandler = async (data) => {
		const providerData = { ...data };

		try {
			if (providerData.logoLocation && typeof providerData.logoLocation !== 'string') {
				const { logoLocation: logoFile } = providerData;
				const uploaded = await uploadLogo(data.name, logoFile);
				providerData.logoLocation = uploaded;
			}

			this.props.updateProvider(this.props.provider.id, providerData);
		} catch {
			notificationService.error('Error updating HCP Group.');
		}
	};

	render() {
		const { provider, t } = this.props;

		return (
			<form
				className="avail-profile__edit-form flex-direction--row justify-content--space-between"
				onSubmit={this.props.handleSubmit(this.onProviderUpdateHandler)}
			>
				<div>
					{provider && provider.logoLocation && <img src={provider.logoLocation} alt={provider.name || 'HCP Group logo'} />}
					<Fields.FileInputField
						name="logoLocation"
						id="fileItem"
						type="file"
						labelText="Upload Image"
						validate={[
							validators.imageformat,
							validators.imagesize,
						]}
					/>
					<Fields.InputField
						className="avail__input"
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.placeholder')}
						name="name"
						validate={[
							validators.required,
							validators.minimumLength3,
							validators.maximumLength48,
							validators.whitespaceRequirement
						]}
					/>
					<Fields.SelectField
						className="avail__input"
						labelText={t('common.form.state.label')}
						placeholder={t('common.form.state.placeholder')}
						name="state"
						validate={[
							validators.required,
							validators.minimumLength2,
							validators.maximumLength64]}
						search
						options={this.props.states && this.props.states.map(state => ({
							key: state.id,
							value: state.name,
							text: `${state.name} (${state.abbreviation})`,
						}))}
					/>
					<Fields.InputField
						className="avail__input"
						labelText={t('common.form.city.label')}
						placeholder={t('common.form.city.placeholder')}
						name="city"
						validate={[
							validators.required,
							validators.minimumLength2,
							validators.maximumLength64]}
					/>
					<Fields.InputField
						className="avail__input"
						labelText={t('common.form.address.label')}
						placeholder={t('common.form.address.placeholder')}
						name="address"
						validate={[
							validators.required,
							validators.minimumLength3,
							validators.maximumLength128,
							validators.whitespaceRequirement
						
						]}
					/>
				</div>
				<div>
					<Button className="button-with-icon align-self--flex-start padding--none" type="submit">
						<Icon className="icon-save icon--orange entity-profile__submit-icon" />
						<span className="entity-profile__submit-title">Save Changes</span>
					</Button>
				</div>
			</form>
		);
	}
}

ProviderInfo.propTypes = {};

const mapStateToProps = state => ({
	initialValues: state.providerReducer.provider,
	provider: state.providerReducer.provider,
	states: state.organisationsReducer.states,
});

const mapDispatchToProps = dispatch => ({
	updateProvider: (id, data) => dispatch(updateProviderByIdAction(id, data)),
	getAllStates: () => dispatch(getAllStatesAction()),
});

const ProviderInfoWithTranslation = withTranslation()(ProviderInfo);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'providerInfoForm',
	enableReinitialize: true,
})(ProviderInfoWithTranslation)));
