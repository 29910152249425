import { PresetSnapshotState, PresetState } from "../models";
import { PresetActionKeys, PresetActionType } from "./actionTypes";

const initialState: PresetState = {
  preset: {}
};

const preset = (
  state = initialState,
  action: PresetActionType
): PresetState => {
  switch (action.type) {
    case PresetActionKeys.SAVE_PRESET:
      // Perform a deep clone of current layoutFrames and store as a snapshot.
      const presetSnapshotJSON = JSON.stringify(action.presetSnapshot);
      const presetSnapshot: PresetSnapshotState = JSON.parse(
        presetSnapshotJSON
      ) as PresetSnapshotState;

      const newPreset = state.preset;
      newPreset[action.newpreset] = presetSnapshot;

      return {
        ...state,
        preset: newPreset
      };

    default:
      return state;
  }
};

export default preset;
