import clsx from "clsx";

import { Grid, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import { Button } from "avail-web-ui/components/Button";
import { Event, EventStatus } from "avail-web-ui/services/ApiClient/Scheduler";

import styles from "./styles.module.scss";

interface Props {
  event: Event;
  onJoinCall: () => void;
}

export const EventItem = ({ event, onJoinCall }: Props) => {
  const host = event.participants?.find((user) => user.role === "HOST");

  return (
    <Grid
      className={styles.wrapper}
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <div className={styles["event-item-container"]}>
        <Typography variant="subtitle1" gutterBottom>
          {event.surgeonName}
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          className={clsx(styles.title, eventStatusClassNameMap[event.status])}
        >
          {event.subject}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {event.hospitalName}
        </Typography>
        <Typography variant="caption">{`${host?.firstName} ${host?.lastName}`}</Typography>
      </div>

      <Tooltip title={"Join the session call"} aria-label="join event call">
        <span>
          <Button
            theme="primary"
            size="large"
            onClick={onJoinCall}
            variant="contained"
          >
            Join
          </Button>
        </span>
      </Tooltip>
    </Grid>
  );
};

// mapping event status to the styles we want to apply (for now just colors)
const eventStatusClassNameMap = {
  [EventStatus.started]: styles.started,
  [EventStatus.created]: styles.created,
  [EventStatus.ended]: styles.ended,
};
