import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Fields, validators } from '../../../../../../../Common/Components';
import UserService from '../../../../../../../Common/Services/userService';
import { getcurrentUserTypes } from '../../../../../HomeSelectors';
import { getAllHospitalsAction } from '../../../../Organisation/Hospitals/HospitalsActions';

export class VendorsFormPart extends PureComponent {
	componentDidMount() {
		if (!UserService.checkIsHospitalUser(this.props.currentUserTypes[0])) {
			this.props.getAllHospitals();
		}
	}

	render() {
		return (
			<>
				{!UserService.checkIsHospitalUser(this.props.currentUserTypes[0]) &&
				<Fields.SelectField
					labelText="Associate user to hospital"
					search
					placeholder="Select hospital"
					options={this.props.hospitals ? this.props.hospitals.map(brand => ({
						key: brand.id,
						value: brand.id,
						text: brand.name,
					})) : []}
					name="businessUnitId"
					validate={validators.required}
				/>
				}
			</>
		);
	}
}

const mapStateToProps = state => ({
	hospitals: state.hospitalsReducer.hospitals,
	currentUserTypes: getcurrentUserTypes(state),
});

const mapDispatchToProps = dispatch => ({
	getAllHospitals: () => dispatch(getAllHospitalsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorsFormPart);
