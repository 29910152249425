/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FormControl,
  TextField,
  Typography,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";

import { Chip, ChipColorTypes } from "./Chip";
import styles from "./styles.scss";

interface Props<T> {
  name: string;
  label: React.ReactNode;
  description: string;

  // the autocomplete values are used to have a convenient list of options that the user can turn into chips
  // The type of values will ended up being in the list of chips below (for example if you have a list of users then the T type will be User)
  autocomplete?: {
    autocompleteValues: T[];
    getOptionLabel: any;
  };

  // chips is the list of all chip objects that get rendered literally as chips, can be any type you need
  // only other function that needs to match is the chipDisplayValueFunction below in order to extract the display value from your custom type
  // you must mantain this list of chips outside of this component, this component uses setChip() to update the list when the user enters more

  // note if the user enters custom text then the list will be either T or string, like if its a list of users the type can be (User | string)
  chips: (T | string)[];
  setChips: (chips: (T | string)[]) => void;

  chipDisplayValueFunction: (chip: T | string) => string; // used to figure out what field to use to display in the chip UI (something like obj.name if its an object)

  // used to specify what color the chip should be, TODO maybe just expose the renderTags function instead
  chipColorFunction?: (chip: T | string) => ChipColorTypes;

  // inputValue is the free form text the user enters that don't match any of the autocomplete options
  // if the user enters free form text it becomes a regular string which ends up in the list of chips
  inputValue: string;
  setInputValue: (val: string) => void;

  onBlur?: any;

  placeholderLabel?: string;
  maxChipsCount?: number;

  required?: boolean;
  error?: string;
  setError?: (message: string) => void;

  "data-test-id"?: string;
}

export function ChipInput<T>({
  autocomplete,
  error,
  setError,
  description,
  placeholderLabel,
  name,
  maxChipsCount = 3,
  label,
  chips,
  setChips,
  onBlur,
  inputValue,
  setInputValue,
  chipDisplayValueFunction,
  chipColorFunction,
  required = false,
  "data-test-id": dataTestId = "chip-input",
  ...props
}: Props<T>) {
  return (
    <FormControl className={styles.wrapper} error={!!error}>
      <Typography variant="body1" component={"label"}>
        {label}
        {required ? <span className={styles.required}> *</span> : null}
      </Typography>
      <Typography variant="caption" component={"label"}>
        <span>{description}</span>
      </Typography>

      <MuiAutocomplete
        data-test-id={`${dataTestId}-chipinput`}
        onChange={(event, value, reason) => {
          // if we have a validation error (for the entered text) and if the user tries to manually add a
          // new option using the Enter key (which will give us the "create-option" reason) then just return and dont update the the values
          if (error && reason === "createOption") {
            return;
          }
          setChips(value as T[]);
        }}
        onInputChange={(event, value, reason) => {
          // the "reset" reason gets triggered by MUI whenever the user hits Enter, BUT as you can see in the
          // onChange above if there is a validation error on the entered text and they are trying to create a new chip
          // we dont want them to be able to set the chips and we want to keep the input value so they can change it
          // so if there is an error and the reason is "reset" then return out before setting the state
          if (error && reason === "reset") {
            return;
          }

          setInputValue(value);
        }}
        inputValue={inputValue}
        value={chips}
        autoHighlight
        // to get multiple things selected plus the user's custom input we need the "multiple" and "freeSolo" booleans set to true
        multiple={true}
        freeSolo={true}
        disabled={chips.length >= maxChipsCount}
        getOptionDisabled={(options) => chips.length >= maxChipsCount}
        filterSelectedOptions
        renderTags={(chipList, getTagProps) =>
          chipList.map((chip, index) => (
            <Chip
              key={index}
              label={chipDisplayValueFunction(chip)}
              color={chipColorFunction ? chipColorFunction(chip) : "primary"}
              {...getTagProps({ index })}
              // Set disable explicitly after getTagProps, this way even if the Autocomplete above is disabled, the user can still delete chips
              // if chips have to be disabled too then we'll have to figure that out later
              disabled={false}
              data-test-id={`${dataTestId}-chipinput-chip-${index}`}
            />
          ))
        }
        fullWidth
        // only pass in autocomplete options (values and label function) if they are passed in
        // if they aren't then this component just behaves like a custom tag input
        options={autocomplete && autocomplete.autocompleteValues}
        getOptionLabel={autocomplete && autocomplete.getOptionLabel}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              name={name}
              className={styles.input}
              // if there are 0 chips then we want to show the placeholder text
              placeholder={chips.length === 0 ? placeholderLabel : ""}
              required={required}
              variant="outlined"
              onBlur={onBlur}
              data-test-id={`${dataTestId}-chipinput-textfield`}
            />
          </>
        )}
        {...props}
      />
    </FormControl>
  );
}
