import { hasOnlyWhitespace } from "avail-web-ui/constants/validation";
import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { CameraIdentifier } from "portalcall/commoncall/components/Utils/CameraUtils";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "semantic-ui-react";
import { setCallState } from "../../store/meeting/actions";
import {
  AppState,
  CallState,
  PresetSnapshotState,
} from "../../store/models";
import { savePreset } from "../../store/preset/actions";
import "./PresetDialog.scss";

const PresetDialog = () => {
  const [containsWhitespace, setContainsWhiespace] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [presetNameAlreadyExists, setPresetNameAlreadyExists] = useState(false);
  const dispatch = useDispatch();
  let inputtext;

  const { meeting: {cameras, layoutFrames, zoomState}, preset } = useSelector(
    (state: AppState) => state
  );

  const savePresetState = (): void => {
    const newPreset = inputtext.inputRef.current.value.trim();

    /* Save a snapshot of current layoutFrames and current camera's PTZ status */
    const snapshotCameras: Array<AVKCamera | AVKExternalInput> = [];
    layoutFrames.forEach(layoutFrame => {
      cameras.forEach(camera => {
        if (layoutFrame.cameraId === CameraIdentifier(camera)) {
          snapshotCameras.push(camera);
        }
      });
    });

    const layoutFramesClone = JSON.parse(JSON.stringify(layoutFrames));
    const zoomStateClone = JSON.parse(JSON.stringify(zoomState));
    const presetSnapshot: PresetSnapshotState = {
      layoutFrames: layoutFramesClone,
      cameras: snapshotCameras,
      zoomState: zoomStateClone,
    };

    if (newPreset && newPreset !== "") {
      dispatch(savePreset(newPreset, presetSnapshot));
    } else if (newPreset === "") {
      setPresetNameAlreadyExists(true);
    }

    dispatch(setCallState(CallState.INPROGRESS));
  }

  const closePresetDialog = (): void => {
    dispatch(setCallState(CallState.INPROGRESS));
  }

  const checkForPresetName = (): void => {
    const curPresetNames = Object.keys(preset.preset);
    let canEnableSave = true;
    let existingPreset = false;
    let hasWhitespace = false;
    
    if (
      inputtext &&
      inputtext.inputRef.current &&
      inputtext.inputRef.current.value &&
      inputtext.inputRef.current.value.length
    ) {
      if (!hasOnlyWhitespace(inputtext.inputRef.current.value)) {
        canEnableSave = false;
        hasWhitespace = true;
      } else {
        const newPresetName = inputtext.inputRef.current.value.trim();
        let i = 0;
        for (i = 0; i < curPresetNames.length; i++) {
          if (curPresetNames[i] === newPresetName) {
            canEnableSave = false;
            existingPreset = true;
            break;
          }
        }
        if (i === curPresetNames.length) {
          existingPreset = false;
        }
      }
    } else {
      canEnableSave = false;
    }

    setContainsWhiespace(hasWhitespace);
    setEnableSave(canEnableSave);
    setPresetNameAlreadyExists(existingPreset);
  }

  return (
    /*
     * TODO:
     * Ideally, this.updateCallState('answered') needs to be called.
     * Once user is completely in the meeting, this.updateCallState('inprogress') needs to be called.
     */
    <div className="avail-portal-call-overlay">
      <div className="avail-portal-call-overlay-dialog">
        <div className="avail-portal-call-overlay-dialog-title">
          Name new preset
        </div>
        <div className="avail-portal-call-overlay-dialog-button-holder">
          <div className="save-preset-name-label">Name preset</div>
          {presetNameAlreadyExists && (
            <div className="save-preset-name-alreadyexists">
              Another preset with this name already exists
            </div>
          )}
          {containsWhitespace && (
            <div className="save-preset-name-alreadyexists">
              Preset must contain characters
            </div>
          )}
          <Input
            maxLength={25}
            ref={input => (inputtext = input)}
            className="avail-portal-call-overlay-text-input"
            placeholder="Enter name"
            autoFocus
            onChange={() => {
              checkForPresetName();
            }}
          />
          <button
            className={enableSave
              ? "save-preset-button"
              : "save-preset-button-disable"}
            onClick={enableSave ? () => savePresetState() : undefined}
          >
            <div className="avail-portal-call-overlay-dialog-button-text">
              <span>SAVE</span>
            </div>
          </button>
          <div className="avail-portal-call-overlay-dialog-cancel">
            <span onClick={() => closePresetDialog()}>Cancel</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PresetDialog;
