import { NCallAcceptEvent } from "availkit-js/dist/Models/Events/NCallAcceptEvent";
import { NCallDeclineEvent } from "availkit-js/dist/Models/Events/NCallDeclineEvent";
import { NSession } from "availkit-js/dist/Models/NSession";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { notificationService, urlService } from "../../../../Common/Services";
import ApiHelper from "../../../../Common/Services/ApiHelper";
import { AvailKitService } from "src/services/AvailKitService";
import { MEETING } from "./MeetingActionTypes";
import resources from '../../../../Common/Constants/resources';
import { setCallBackAction, setCallingMeetingInfoAction, setTwilioTokenAction } from "./MeetingActions";
import { CALLBACK_MODES } from "./MeetingReducer";
import UserSessionService from 'src/services/UserSessionService';

function* sendMeetingCallAccept(params) {
	try {
		const { meetingInfo } = yield select((state) => state.meetingReducer);

		/* we have refer to same session id in both channels, to indicate that a particular user
		is rejecting a very specific call session */
		let groupSession = new NSession(meetingInfo.session_id);
		groupSession.uniqueIdentifier = meetingInfo.session_id;

		const userEvent = new NCallAcceptEvent(
			params.payload.pubnubChannel,
			groupSession
		);
		userEvent.sender = params.payload.loginId;

		const sessionEvent = new NCallAcceptEvent(
			meetingInfo.session_id,
			groupSession
		);
		sessionEvent.sender = params.payload.loginId;

		yield all([
			yield AvailKitService.instance.eventService.broadcast(userEvent),
			yield AvailKitService.instance.eventService.broadcast(sessionEvent),
			notificationService.success("You successfully accepted the call."),
		]);
	} catch (e) {
		yield notificationService.error(e.message);
	}
}

function* sendMeetingCallReject(params) {
	try {
		const { meetingInfo } = yield select((state) => state.meetingReducer);

		/* we have refer to same session id in both channels, to indicate that a particular
		is rejecting a very specific call session */
		let groupSession = new NSession(meetingInfo.session_id);
		groupSession.uniqueIdentifier = meetingInfo.session_id;

		const userEvent = new NCallDeclineEvent(
			params.payload.pubnubChannel,
			groupSession
		);
		userEvent.sender = params.payload.loginId;

		const sessionEvent = new NCallDeclineEvent(
			meetingInfo.session_id,
			groupSession
		);
		sessionEvent.sender = params.payload.loginId;

		yield all([
			yield AvailKitService.instance.eventService.broadcast(userEvent),
			yield AvailKitService.instance.eventService.broadcast(sessionEvent),
		]);
	} catch (e) {
		yield notificationService.error(e.message);
	}
}

function* sendCallback(params) {
	const meeting = params.payload;
  const deviceId = UserSessionService.getDeviceId();

	try {
		yield put(setCallingMeetingInfoAction(meeting));
		yield put(setCallBackAction(CALLBACK_MODES.calling));

		const response = yield call(
			ApiHelper.post,
			'callevent',
			urlService.formatString(resources.callback, meeting.joinId),
			{ callSid: meeting.callSid, deviceId, callback: true },
			undefined,
			true // return error object
		);
		const twilioToken = response.content.twilioToken;

		yield put(setTwilioTokenAction(twilioToken));
	} catch (e) {
		if (e.status === 409) {
			yield put(setCallBackAction(CALLBACK_MODES.consolebusy))
		} else if (e.status === 404) {
			yield put(setCallBackAction(CALLBACK_MODES.consoleunavailable))
		}
		// TODO: Add additional possible states
    const errorMessage = e.message ? e.message : (e.data && e.data.message ? e.data.message : "");
		if (errorMessage !== "") {
			yield notificationService.error(e.message ? e.message : e.data.message);
		}
	}
}

function* cancelCallback(params) {
	const joinId = params.payload.joinId;
	const callSid = params.payload.callSid;
  const deviceId = UserSessionService.getDeviceId();

	try {
		yield call(
			ApiHelper.remove,
			'callevent',
			urlService.formatString(resources.callback, joinId),
			{ callSid: callSid, deviceId }
		);
	} catch (e) {
		yield notificationService.error('Call back was cancelled.');
	} finally {
		// yield put(setCallingMeetingInfoAction(null));
		yield put(setTwilioTokenAction(''));
	}
}

export default function* meetingSaga() {
	yield takeLatest(MEETING.MEETING_CALL_ACCEPT, sendMeetingCallAccept);
	yield takeLatest(MEETING.MEETING_CALL_REJECT, sendMeetingCallReject);
	yield takeLatest(MEETING.MEETING_SEND_CALLBACK, sendCallback);
	yield takeLatest(MEETING.MEETING_CANCEL_CALLBACK, cancelCallback);
}
