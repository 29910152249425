import { RemoteParticipant, RemoteTrack } from "twilio-video";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleSetAudioOutputDeviceThunk } from "src/domains/Beacon/store/stream/thunks/handleSetAudioOutputDeviceThunk";
import { addTrackListeners } from "src/domains/Beacon/utils/twilio";
import { LoggerLevels, logger } from "src/logging/logger";

// Used when a new Host's track subscription is fired in the `useRoomTrackSubscribed`
export const handleConsoleTrackSubscribedThunk = (
  participant: RemoteParticipant,
  newTrack: RemoteTrack
) => (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Media Devices",
        fileInfo: "handleConsoleTrackSubscribedThunk.ts",
      },
      `Subscribing to new Console's track: ${newTrack?.name} with identity: ${participant.identity}`
    );
    const {
      stream: {
        localMedia: { speaker },
      },
    } = getState();

    if (newTrack.kind === "video") {
      dispatch(streamActions.setConsoleVideoTrack(newTrack));
    } else if (newTrack.kind === "audio") {
      dispatch(streamActions.setConsoleAudioTrack(newTrack));

      // By the moment we set the audio-output device, the mediaElement returned by "attach"
      // could not have changed yet, thus, must get it here and pass it
      const mediaElement = newTrack.attach();
      dispatch(
        handleSetAudioOutputDeviceThunk({
          mediaElement,
          audioOutputDevice: speaker,
        })
      );
    }

    addTrackListeners(newTrack, participant);
  } catch (error: any) {
    logger().error(
      "An error occurred when new Console's track subscribed",
      error?.message
    );
  }
};
