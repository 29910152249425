import { NSessionJoinEvent } from "availkit-js/dist/Models/Events/NSessionJoinEvent";
import { NActor } from "availkit-js/dist/Models/NActor";
import { NSession } from "availkit-js/dist/Models/NSession";
import { eventChannel } from "redux-saga";
import {
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import TwilioVideo, {
  ConnectOptions,
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  NetworkQualityLevel,
  NetworkQualityStats,
  Participant,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrackPublication,
  RemoteVideoTrack,
  Room,
} from "twilio-video";

import { API } from "../../../api";
import { TwilioLogger } from "../../../common/TwilioLogger";
import { logger, LoggerLevels } from "../../../common/logger";
import {
  CONSOLE_VIDEO_LOG_MESSAGE,
  HOST_PIP_VIDEO_LOG_MESSAGE,
  MULTI_PARTY_LAUNCH_MODE,
  NETWORK_QUALITY_CONFIG_LOCAL_VERBOSITY,
  NETWORK_QUALITY_CONFIG_REMOTE_VERBOSITY,
} from "../../../constants";
import {
  initializeMultiPartyEvent,
  setTriggerRefreshFrames,
} from "../../meeting/actions";
import {
  AppState,
  AvailKitState,
  CallModeType,
  FeaturesToSync,
  MeetingStateType,
  MultiPartyCallEventInfo,
  MultiPartyInitializeState,
  PortalIdentity,
  TwilioState,
  UserState,
} from "../../models";
import {
  setHasJoinedRoom,
  setRefreshInProgress,
  startAccessTokenTimer,
} from "../../user/actions";
import { getLocalMediaSaga } from "../../user/sagas/getLocalMedia";
import { TwilioActionKeys } from "../actionTypes";
import {
  connectCallFailed,
  connectCallRequest,
  connectCallSuccess,
  disconnectFromRoomFailed,
  disconnectFromRoomSuccess,
  logActivity,
  nqStartPolling,
  setConsoleHasJoinedRoom,
  setHostHasJoinedRoom,
  setHostTracks,
  setRemoteHasJoinedRoom,
  startTwilioReconnectionTimer,
  updateDimensions,
  updateDominantSpeaker,
  updateDuplicateParticipantDisconnected,
  updateFailed,
  updateLocalNetworkDisconnected,
  updateLocalNetworkQualityLevel,
  updateParticipants,
  updateTracks,
  updateTwilioLogger,
} from "../actions";
import { getLocalTracks } from "./getLocalTracks";

export function* connectTwilioSaga() {
  yield take(TwilioActionKeys.GET_CREDENTIALS_SUCCESS);
  let shouldPublishVideoTracks = true;

  const callMode: CallModeType = yield select(
    (state: AppState) => state.meeting.mode
  );
  const meetingTokenInfo: MultiPartyCallEventInfo = yield select(
    (state: AppState) => state.meeting.multiPartyCallEventInfo
  );
  if (callMode === MULTI_PARTY_LAUNCH_MODE) {
    if (meetingTokenInfo.userRole === "PARTICIPANT") {
      shouldPublishVideoTracks = false;
    }
  }

  const twilioLogger = yield new TwilioLogger(TwilioVideo);
  yield put(updateTwilioLogger(twilioLogger));

  logger().info(`Connecting to twilio (version: ${TwilioVideo.version})
  DSCP requested: Yes`);
  yield call(getLocalMediaSaga); // Need local Media first!
  const { identity, localMedia }: UserState = yield select(
    (state: AppState) => state.user
  );

  const {
    twilioCredentials: { token },
  }: TwilioState = yield select((state: AppState) => state.twilio);

  const localTracks: LocalTrack[] = yield getLocalTracks(
    localMedia,
    shouldPublishVideoTracks
  );

  // if the current user is the host, set their tracks on redux state for VideoCall
  if (callMode === "MP" && meetingTokenInfo.userRole === "HOST") {
    logger().logWithFields(LoggerLevels.info, {
    fileInfo: `sagas/connectTwillio.ts`,
    feature: `store/twilio`,
    },
    `Setting Host tracks: ${localTracks.map(localTrack => {`${localTrack.kind}: ${localTrack.name}`})}`);
    yield put(
      setHostTracks(localTracks as Array<LocalAudioTrack | LocalVideoTrack>)
    );
    yield put(setHostHasJoinedRoom(true));
  }

  if (!identity.login_id || !token) {
    yield put(connectCallFailed());
    yield cancel();
  }

  let options: ConnectOptions = {
    name: identity.login_id,
    tracks: localTracks,
    insights: true,
    dominantSpeaker: true,
    enableDscp: true,
    networkQuality: {
      local: NETWORK_QUALITY_CONFIG_LOCAL_VERBOSITY,
      remote: NETWORK_QUALITY_CONFIG_REMOTE_VERBOSITY,
    },
  };

  // @ts-ignore TODO fix typescript error on below line
  options.preferredVideoCodecs = "auto";

  /*
      Multiparty Settings to mitigate bandwidth congestion from
      affecting other members of the twilio room
    */
  if (callMode === MULTI_PARTY_LAUNCH_MODE) {
    options.bandwidthProfile = {
      video: {
        mode: "collaboration",
        clientTrackSwitchOffControl: 'manual',
        contentPreferencesMode: 'auto',
        dominantSpeakerPriority: "low", // no need to change stream priority of speaker
        trackSwitchOffMode: "predicted",
      },
    };
  }

  const { data, error } = yield call(API.GET.connectTwilioCall, {
    token,
    options,
  });

  if (error) {
    logger().error(
      "Error connecting to Twilio Video.",
      JSON.stringify(error),
      JSON.stringify(options)
    );

    yield put(connectCallFailed());
    yield put(logActivity("Error connecting to Twilio Video."));
    yield cancel();
    return;
  }

  logger().logWithFields(LoggerLevels.info, {
  fileInfo: `sagas/connectTwilio.ts`,
  feature: `store/twilio`,
  },
  `Successfully connected to room: '${identity.login_id}'.`);
  yield put(connectCallSuccess(data));

  const { availKitInstance }: AvailKitState = yield select(
    (state: AppState) => state.availKit
  );

  const { callSid }: MeetingStateType = yield select(
    (state: AppState) => state.meeting
  );

  const { login_id, pubnub_channel }: PortalIdentity = yield select(
    (state: AppState) => state.user.identity
  );

  const { refreshInProgress } = yield select((state: AppState) => state.user);

  /* Do not send events if connect was called by refresh */
  if (!refreshInProgress) {
    try {
      if (availKitInstance) {
        const localActor = new NActor();
        /* This is required to identify if the message is from the same user who has logged from multiple devices */
        localActor.uniqueIdentifier = login_id;

        const groupSession = new NSession(callSid);
        groupSession.uniqueIdentifier = callSid;

        const joinUserEvent = new NSessionJoinEvent(
          pubnub_channel,
          groupSession
        );
        yield availKitInstance.eventService.broadcast(joinUserEvent);

        availKitInstance.eventService.join(callSid);
        const joinSessionEvent = new NSessionJoinEvent(callSid, groupSession);
        yield availKitInstance.eventService.broadcast(joinSessionEvent);
      }
    } catch (e) {
      logger().logWithFields(LoggerLevels.error, {
      fileInfo: `sagas/connectTwillio.ts`,
      feature: `store/twilio`,
      },
      `Unable to send join session event`);
    }
  }

  // Any logic after this should be put in another saga
  yield put(setHasJoinedRoom(true));
  // https://www.youtube.com/watch?v=E_Ci-pAL4eE

  logger().debug("Starting Timed Saga Processes...");
  yield put(nqStartPolling());
  yield put(startTwilioReconnectionTimer());
  yield put(startAccessTokenTimer());

  const theRoom = data as Room;

  /*
    For Portalcall, our video outputs are low priority, should be at the smallest
    rendering dimensions, and subject to bandwidth-related manipulation.
  */
  const localTrackPublications = [
    ...theRoom.localParticipant.videoTracks.values(),
  ];
  localTrackPublications.forEach((lt, i) => {
    lt.setPriority("low");
    console.log(
      `MPL setting local[${i}] ${lt.kind} track [${lt.trackName}] to "LOW"`
    );
  });

  const participants = [...theRoom.participants.values()];
  const remoteParticipantsSize = participants.length;

  /* Update the existing tracks */
  if (remoteParticipantsSize > 0) {
    const tracksToBeUpdated: Array<RemoteAudioTrack | RemoteVideoTrack> = [];

    participants.forEach((participant) => {
      const tracks = participant.tracks;
      let logMessage: string = participant.identity.includes("CONSOLE")
        ? CONSOLE_VIDEO_LOG_MESSAGE
        : HOST_PIP_VIDEO_LOG_MESSAGE;

      tracks.forEach((publication) => {
        const pTracks = publication.track;
        if (pTracks && (pTracks.kind === "video" || pTracks.kind === "audio")) {
          if (pTracks.kind === "video") {
            pTracks.on("dimensionsChanged", (track) => {
              if (track.kind === "video" && track.dimensions) {
                logger().info(logMessage + JSON.stringify(track.dimensions));
              }
              put(updateDimensions(true));
            });
          }
          tracksToBeUpdated.push(pTracks);
        }
      });
    });

    logger().debug("Updating existing participants in the room...");

    yield put(updateParticipants(participants));
    yield put(setRemoteHasJoinedRoom(true));

    if (tracksToBeUpdated.length > 0) {
      yield put(updateTracks(tracksToBeUpdated));
    }
  }

  if (callMode === "MP") {
    // look for the console user in the current participants
    const hasConsoleJoined =
      participants.find((p) => p.identity.includes("CONSOLE")) !== undefined;

    yield put(setConsoleHasJoinedRoom(hasConsoleJoined));
  }

  // TODO do checking for host has joined here

  yield fork(read, data);
}

function* read(room: Room) {
  const callMode: CallModeType = yield select(
    (state: AppState) => state.meeting.mode
  );

  const channel = yield call(subscribe, room, callMode);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

const isAV = (kind: string) => kind === "audio" || kind === "video";

function subscribe(room: Room, callMode) {
  return eventChannel((emit) => {
    const localParticipant = room.localParticipant;
    localParticipant.on(
      "networkQualityLevelChanged",
      (
        networkQualityLevel: NetworkQualityLevel,
        networkQualityStats: NetworkQualityStats
      ) => {
        if (networkQualityLevel <= 2) {
          logger().warn(
            "Network Quality Level changed to " + networkQualityLevel
          );
        }
        emit(updateLocalNetworkQualityLevel(networkQualityLevel));
      }
    );

    room.on("reconnecting", (error: any) => {
      emit(setRefreshInProgress(true));
      logger().info(
        "Twilio: Detected local network issues. Trying to reconnect."
      );
      if (error.code === 53001) {
        logger().error(
          "Twilio Error: " +
            error.code +
            " - Reconnecting your signaling connection!",
          error.message
        );
      } else if (error.code === 53405) {
        logger().error(
          "Twilio Error: " +
            error.code +
            " - Reconnecting your media connection!",
          error.message
        );
      } else {
        logger().error(
          "Twilio Error: " + error.code + " - Reconnecting!",
          error.message
        );
      }
    });

    room.on("reconnected", () => {
      logger().info("Twilio: Local Network reconnected.");

      if (callMode === MULTI_PARTY_LAUNCH_MODE) {
        const initialMultiPartyState: MultiPartyInitializeState = {
          meetingToken: {} as MultiPartyCallEventInfo,
          featuresToSync: FeaturesToSync.presence,
        };

        emit(initializeMultiPartyEvent(initialMultiPartyState));
      }

      emit(updateDuplicateParticipantDisconnected(false));
      emit(updateLocalNetworkDisconnected(false));
      emit(setRefreshInProgress(false));
      if (navigator.userAgent.indexOf("Firefox") !== -1) {
        emit(setTriggerRefreshFrames(true));
      }
    });

    /* LocalParticipant disconnected from the room */
    room.on("disconnected", (currentRoom: Room, err: Error) => {
      if (err) {
        emit(logActivity(`${err}: ${err.message}`));
        logger().error(`${err}: ${err.message}`);
        if (
          err.message ===
          "Participant disconnected because of duplicate identity"
        ) {
          emit(updateDuplicateParticipantDisconnected(true));
        } else {
          emit(updateLocalNetworkDisconnected(true));
        }
        emit(disconnectFromRoomFailed());

        // If disconnected with error, fire the conenct call action to reconnect
        emit(connectCallRequest());
      }
      emit(disconnectFromRoomSuccess());
      emit(logActivity(`You have left the room '${room.name}'.`));
      logger().info(`Local user has left the room '${room.name}'.`);
    });

    /* RemoteParticipant joined the room */
    room.on("participantConnected", (participant: Participant) => {
      const participants = [...room.participants.values()];
      emit(updateParticipants(participants));
      logger().info(
        `'${participant.identity}' connected and has joined the room.`
      );
      emit(logActivity(`'${participant.identity}' joined the room.`));
      emit(setRemoteHasJoinedRoom(true));

      // don't have call mode variable here but we only use this flag in mp mode anyways so its okay to just check all the time

      // look for the console user in the current participants
      const hasConsoleJoined =
        participants.find((p) => p.identity.includes("CONSOLE")) !== undefined;
      emit(setConsoleHasJoinedRoom(hasConsoleJoined));
    });

    /* RemoteParticipant rejoined the room */
    room.on("participantReconnected", (participant: Participant) => {
      const participants = [...room.participants.values()];
      emit(updateParticipants(participants));
      logger().info(
        `'${participant.identity}' reconnected and has joined the room.`
      );
      emit(logActivity(`'${participant.identity}' re-joined the room.`));

      // look for the console user in the current participants
      const hasConsoleJoined =
        participants.find((p) => p.identity.includes("CONSOLE")) !== undefined;
      emit(setConsoleHasJoinedRoom(hasConsoleJoined));
    });

    /* RemoteParticipant left the room */
    room.on("participantDisconnected", (participant: Participant) => {
      const participants = [...room.participants.values()];
      emit(updateParticipants(participants));
      logger().info(
        `'${participant.identity}' has disconnected and left the room.`
      );
      emit(logActivity(`'${participant.identity}' left the room.`));
      emit(setRemoteHasJoinedRoom(false));

      const hasConsoleDisconnected =
        participants.find((p) => p.identity.includes("CONSOLE")) === undefined;
      // if they have disconnected then we have to set the flag to be the inverse, in this case we want it to be set to false if hasConsoleDisconnected is true
      emit(setConsoleHasJoinedRoom(!hasConsoleDisconnected));
    });

    /* RemoteTrack was added by a RemoteParticipant in the room */
    room.on(
      "trackSubscribed",
      (
        track,
        publication: RemoteTrackPublication,
        participant: RemoteParticipant
      ) => {
        logger().verbose(`Subscribed to new ${track.kind} track from ${participant.identity}...`);
        emit(logActivity(`Subscribed to new ${track.kind} track from ${participant.identity}...`));

        publication.on("subscribed", (remoteTrack) => {
          remoteTrack.on("switchedOff", () => {
            logger().info(
              `The ${remoteTrack.kind} RemoteTrack was switched OFF for participant : ${participant.identity}`
            );
          });

          remoteTrack.on("switchedOn", () => {
            logger().info(
              `The ${remoteTrack.kind} RemoteTrack was switched ON for participant : ${participant.identity}`
            );
          });
        });

        const tracks = publication.track;
        let logMessage: string = participant.identity.includes("CONSOLE")
          ? CONSOLE_VIDEO_LOG_MESSAGE
          : HOST_PIP_VIDEO_LOG_MESSAGE;

        if (tracks && (tracks.kind === "video" || tracks.kind === "audio")) {
          if (tracks.kind === "video") {
            tracks.on("dimensionsChanged", (track) => {
              logger().info(
                `dimensionsChanged. This may affect telestration, if already drawn.`
              );
              if (track.dimensions) {
                logger().info(logMessage + JSON.stringify(track.dimensions));
              }
              emit(updateDimensions(true));
            });
          }
          emit(updateTracks([tracks]));
          logger().verbose(`New media track with type '${publication.kind}'`);
          emit(logActivity(`New media track with type '${publication.kind}'`));
        } else {
          logger().verbose("trackSubscribed failed");
          emit(updateFailed());
        }
      }
    );

    /* RemoteTrack was removed by a RemoteParticipant in the room */
    room.on(
      "trackUnsubscribed",
      (track, publication: RemoteTrackPublication) => {
        logger().verbose("Unsubscribing tracks...");
        emit(logActivity("Unsubscribing tracks..."));

        if (track && (track.kind === "video" || track.kind === "audio")) {
          emit(updateTracks([track]));
          logger().verbose(`${publication.kind} track removed: ${track.name}`);
          emit(logActivity(`${publication.kind} track removed: ${track.name}`));
        } else {
          logger().verbose("trackUnsubscribed failed");
          emit(updateFailed());
        }
      }
    );

    // Dominant speaker
    room.on("dominantSpeakerChanged", () => {
      logger().info(
        `Dominant speaker changed in room to: ${room.dominantSpeaker?.identity}`
      );

      // Twilio can also pick up CONSOLE as dominantSpeaker. If it does, just
      // set the dominant speaker to nobody in case there was a previously set user
      if (room.dominantSpeaker?.identity.includes("CONSOLE")) {
        emit(updateDominantSpeaker(""));
        return;
      }

      // if twilio gives us a `null` dominantSpeaker, we set an empty string, which can be used as falsey
      emit(updateDominantSpeaker(room.dominantSpeaker?.identity || ""));
    });

    const updateParticipantTracks = (participant: RemoteParticipant) => {
      participant.tracks.forEach(
        (publication: RemoteTrackPublication, sid: string) => {
          const track = publication.track;
          if (track && isAV(track.kind)) {
            emit(updateTracks([track as RemoteAudioTrack | RemoteVideoTrack]));
            logger().verbose(`updateParticipantTracks( ${track.kind} ) for ${participant.identity}`);
          }
        }
      );
    };

    room.on(
      "trackDisabled",
      (track: RemoteTrackPublication, participant: RemoteParticipant) => {
        logger().info(`Remote Participant (${participant.identity}) trackDisabled( ${track.kind} )`);
        updateParticipantTracks(participant);
      }
    );

    room.on(
      "trackEnabled",
      (track: RemoteTrackPublication, participant: RemoteParticipant) => {
        logger().info(`Remote Participant (${participant.identity}) trackEnabled( ${track.kind} )`);
        updateParticipantTracks(participant);
      }
    );

    // tslint:disable-next-line: no-empty
    return () => {};
  });
}

export function* watchConnectCall() {
  yield takeLatest(TwilioActionKeys.CONNECT_CALL, connectTwilioSaga);
}
