import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import userTypes from '../../../../../../../../Common/Constants/userTypes';
import { multiProfileLoginAction } from '../../../../../../AuthenticationActions';

function MultiProfileLoginSelect({ profiles, hasPhoneNumber, isTwoFactorEnabled, multiProfileLogin }) {
	const handleProfileClick = (profile) => {
		multiProfileLogin({ profile, hasPhoneNumber, isTwoFactorEnabled });
	};

	return (
		<React.Fragment>
			<div className="tabs__wrapper multi-profile-container">
				{profiles && profiles.map((profile) => {
					const isActive = profile.status === 'ACTIVE';
					const brand = profile && profile.brand;
					const provider = profile && profile.provider;
					
					return (
					// eslint-disable-next-line jsx-a11y/click-events-have-key-events
						<div
							key={profile.id}
							className={`multi-profile__well ${isActive ? '' : 'disabled'}`}
							title={isActive ? profile.organization.name : 'Invitation pending...'}
							onClick={isActive ? () => handleProfileClick(profile) : undefined}
						>
							<p className="multi-profile__text"><b>{brand ? `${brand.name} - ` : ''}{profile.organization.name}</b></p>
							<div className="multi-profile__inner">
								<span className="multi-profile__text">{userTypes[profile.role] && userTypes[profile.role].title}</span>
								{(brand && brand.logoLocation
                                    && <img className="multi-profile__img" src={profile.brand.logoLocation} alt={`${profile.brand.name} logo`} />) ||
									(provider && provider.logoLocation
                                    && <img className="multi-profile__img" src={profile.provider.logoLocation} alt={`${profile.provider.name} logo`} />)}
							</div>
						</div>
					);
				})}
			</div>
		</React.Fragment>
	);
}

MultiProfileLoginSelect.propTypes = {
  isTwoFactorEnabled: PropTypes.bool,
	profiles: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => ({
	hasPhoneNumber: state.homeReducer.hasPhoneNumber,
})

const mapDispatchToProps = dispatch => ({
	multiProfileLogin: profile => dispatch(multiProfileLoginAction(profile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiProfileLoginSelect);
