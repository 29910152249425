import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import clsx from "clsx";

import { CircularProgress, Fade } from "@mui/material";

import { Button } from "src/components/Button";
import { BackgroundPatternSVG } from "src/components/CustomIcons/BackgroundPatternSVG";
import { Font } from "src/components/Font";
import { Logo } from "src/components/Logo";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectIsUserHost } from "src/domains/Beacon/store/meeting/selectors";
import { selectLocalMedia } from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { leaveCallThunk } from "src/domains/Beacon/store/twilio/thunks/leaveCall";
import {
  areDevicesReady,
  getLocalVideoStream,
  stopMediaTracks,
} from "src/domains/Beacon/utils/mediaDevices";
import { LoggerLevels, logger } from "src/logging/logger";
import { timeoutCallback } from "src/utils/timeout";

import styles from "./../PreCallModal/styles.scss";
import handlerStyles from "./../styles.scss";

interface IProps {
  localMediaError: string;
  apiErrors: string;
}

const loggerFields = {
  feature: "Media Devices",
  fileInfo: "PreCallPermissionMessages",
};

export const PreCallPermissionMessages = ({
  localMediaError,
  apiErrors,
}: IProps) => {
  const dispatch = useDispatch();
  const [videoStream, setVideoStream] = useState<MediaStream>();
  const [mediaDevicesReady, setMediaDevicesReady] = useState(false);

  const localMedia = useAppSelector(selectLocalMedia);
  const isHostUser = useAppSelector(selectIsUserHost);

  const open = !!(localMediaError || apiErrors || !localMedia);

  const checkDevicesReady = () => {
    // areDevicesReady returns a boolean that checks if we have access to the users mic/cam/speaker devices
    // for example we check to see if the arrays of videoInputs has any devices in it, if that list is not empty
    // then that means the user has given us permission to use their device
    const areReady = areDevicesReady(localMedia.mediaDevices);

    if (areReady) {
      // small delay so the loading indicator below can fade nicely
      timeoutCallback(() => setMediaDevicesReady(true), 800);
    }
  };

  // Detects when media devices are loaded and select a default camera for the host
  // this happens only once, when the PreCall first loads
  useEffect(() => {
    if (
      localMedia?.mediaDevices &&
      !localMedia.camera &&
      !localMedia.microphone &&
      !localMedia.speaker
    ) {
      const loadDefaultDevices = async () => {
        if (isHostUser) {
          const defaultCamera = localMedia?.mediaDevices.videoInputs[0];

          logger().logWithFields(
            LoggerLevels.info,
            loggerFields,
            `Default pre-selected video device: ${JSON.stringify(
              defaultCamera ?? "null"
            )}`
          );

          dispatch(streamActions.setCameraDevice(defaultCamera));
          setVideoStream(await getLocalVideoStream(defaultCamera?.deviceId));
        }
        const defaultMicrophone = localMedia?.mediaDevices.audioInputs[0];
        const defaultSpeaker = localMedia?.mediaDevices.audioOutputs[0];

        logger().logWithFields(
          LoggerLevels.info,
          loggerFields,
          `Default pre-selected audio devices: ${JSON.stringify(
            {
              defaultMicrophone,
              defaultSpeaker,
            } ?? "null"
          )}`
        );

        dispatch(streamActions.setMicrophoneDevice(defaultMicrophone));
        dispatch(streamActions.setSpeakerDevice(defaultSpeaker));
      };
      loadDefaultDevices();
    }
  }, [localMedia?.mediaDevices]);

  useEffect(() => {
    if (localMedia?.mediaDevices) {
      checkDevicesReady();
    }
  }, [localMedia?.mediaDevices]);

  const leaveCallAction = () => {
    // Must clean the local video stream when leaving the call
    stopMediaTracks(videoStream);
    dispatch(leaveCallThunk());
  };

  // so if we have any error from the API, condense them down to a single boolean
  // so we can show if we need to show the error section below

  return (
    <>
      {/* This div sits in the background to prevent weird flashes when moving directly from PRECALL TO LEAVECALL
          if the user clicks on "leave" in the pre call modal then they are taken straight to leave call
          if they click on "join" then they will be in the call and wont see this background anymore
      */}

      <div
        className={clsx(handlerStyles.background, {
          // once the pre call modal is supposed to be closed (meaning open === false)
          // hide this message background (!open will be true, so we apply the "hide" class)
          [handlerStyles.hide]: !open,
        })}
        data-test-id="pre-call-messages-background"
      />

      {/* we only want to show the leave call screen when the call is in the "leave call" state
          it will appear on top of the whole screen only once show is set to true
      */}
      <Fade
        // we'll see this leave call screen when show is set to true
        in={open}
        // appear is weirdly named but setting it to false means it doesn't fade in, it only fades out
        // on initial page load we want this background to show without fading in, when the user joins it fades out as expected using the in={open} prop
        appear={false}
      >
        <div
          data-test-id="pre-call-background-2222222"
          className={handlerStyles.background}
        >
          {/* adding BackgroundPattern here.Making veil visible in PreCallModal obscures Logo */}
          <BackgroundPatternSVG />
          <div className={handlerStyles.content}>
            <Logo type="horizontal-light" />
          </div>
        </div>
      </Fade>

      <div
        className={clsx(styles.messageBackground, {
          // once the pre call modal is supposed to be closed (meaning open === false)
          // hide this message background (!open will be true, so we apply the "hide" class)
          [styles.hide]: !open,
        })}
      >
        {/* show simple loading icon until the media devices are ready,
          if the user explicitly reject the permissions, then we just leave this message up for now,
          in the future we can add a message that says something else

          also need to make sure we dont have any other errors
      */}
        {(!mediaDevicesReady || localMediaError) && !apiErrors ? (
          <>
            {!localMediaError && (
              <CircularProgress classes={{ root: styles.loadingIcon }} />
            )}
            {localMediaError ? (
              <>
                <Font
                  variant="h1"
                  color="light"
                  className={styles.permissionError}
                >
                  Please grant Microphone & Camera permissions in order to join
                  the Avail Experience
                </Font>
                <Button
                  theme="red"
                  onClick={leaveCallAction}
                  label="Leave Event"
                />
              </>
            ) : null}
          </>
        ) : null}
        {/* if we have more errors then turn this into a switch function that renders the error that occurred */}
        {apiErrors ? (
          <>
            <Font variant="h1" color="light" className={styles.permissionError}>
              {apiErrors}
            </Font>
            <Button theme="red" onClick={leaveCallAction} label="Leave Event" />
          </>
        ) : null}
      </div>
    </>
  );
};
