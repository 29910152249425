import React from 'react';
import moment from 'moment';
import { Button } from '../../../../../../../../Common/Components';
import { useTimeout } from './hooks/useTimeout';
import "./MissedCallItem.scss"

function MissedCallItem({ callEvent, action }) {
	const [isExpired, setIsExpired] = React.useState(false);

	const dateTime = moment(callEvent.start).tz(moment.tz.guess());
	const minuteDiff = moment(Date.now()).tz(moment.tz.guess()).diff(dateTime, 'minutes', true);
	const isLessThan15MinutesAgo = minuteDiff <= 15;

	const timeout = isLessThan15MinutesAgo ? 1000 * 60 * (15 - minuteDiff) : 0;

	useTimeout(() => {
		setIsExpired(true);
	}, timeout);

	return (
		<div className="missed-calls__item">
			<section>
				<p className="main">{moment(callEvent.start).tz(moment.tz.guess()).format('dddd MMM Do')}</p>
				<p className="main">{moment(callEvent.start).tz(moment.tz.guess()).format('hh:mmA')}</p>
				<p>{callEvent.procedure && callEvent.procedure.description}</p>
				<p>{callEvent.room && callEvent.room.name}</p>
				<p>{callEvent.hospital.length && callEvent.hospital[0].name}</p>
				<p>{callEvent.hospital.length && callEvent.hospital[0].city}, {callEvent.hospital.length && callEvent.hospital[0].state}</p>
			</section>
			<section>
				<Button 
					isFull
					className={`missed-calls__btn ${isLessThan15MinutesAgo ? 'active' : ''}`}
					disabled={isExpired}
					onClick={action}
				>
					Call Back
				</Button>
			</section>
		</div>
	)
}

export default MissedCallItem;
