import { NetworkQualityLevel } from "twilio-video";

import moment from "moment";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import { NETWORK_QUALITY_CHECK_FREQUENCY } from "src/domains/Beacon/store/twilio/constants";
import { uiActions } from "src/domains/Beacon/store/ui";
import { logger, LoggerLevels } from "src/logging/logger";

// manages the cases for Network status
export const networkStatusThunk = createAsyncThunk<
  void,
  // First argument to the payload creator
  { networkStatus: NetworkQualityLevel; QaAutomationFeatureFlag: boolean },
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "twilio/setNetworkStatus",
  ({ networkStatus, QaAutomationFeatureFlag }, { getState, dispatch }) => {
    try {
      const {
        ui: {
          callState: { openNoNetworkConnectionModal },
          localNetworkDisconnectedTimerId,
          hasPoorNetworkModalBeenShown,
        },
        twilio: { localNetworkDisconnected, localNetworkQualityTimestamp },
      } = getState();

      const timestampMoment = moment(localNetworkQualityTimestamp);
      const currentTime: Date = new Date(Date.now());
      const currentMoment = moment(currentTime);
      const hasTimeIntervalPassed: boolean =
        currentMoment.diff(timestampMoment) >=
        NETWORK_QUALITY_CHECK_FREQUENCY * 1000;

      /**
       * At least 10 seconds has to pass since the last networkQualityLevel change.
       * Network quality is highly variable and can cause Modals to open frequently,
       * creating a poor user experience.
       */
      if (hasTimeIntervalPassed || localNetworkQualityTimestamp === null) {
        dispatch(twilioActions.setLocalNetworkQualityTimestamp(currentTime));
        const cancelLocalNetworkDisconnectedTimer = () => {
          try {
            if (localNetworkDisconnectedTimerId) {
              logger().info(
                `Timer for local network disconnected canceled due to network quality improvement`
              );
              // clear the set timeout with the stored id from redux
              window.clearTimeout(localNetworkDisconnectedTimerId);

              // reset the id in redux back to null just in case
              dispatch(uiActions.setLocalNetworkDisconnectedTimerId(null));
            }
          } catch (error) {
            logger().error(
              "Error clearing timer for local network disconnected",
              JSON.stringify(error)
            );
          }
        };

        if (networkStatus < 2) {
          cancelLocalNetworkDisconnectedTimer();

          // disable NoNetworkModal for QA Automations
          if (!QaAutomationFeatureFlag) {
            // open Poor Network Modal only once per call
            if (!hasPoorNetworkModalBeenShown) {
              dispatch(uiActions.setOpenNoNetworkModal(true));
              dispatch(uiActions.setHasPoorNetworkModalBeenShown(true));
            }
          }

          if (networkStatus === 0) {
            logger().logWithFields(
              LoggerLevels.warn,
              { feature: "twilio/setNetworkStatus" },
              `Starting Network reconnection timer.`
            );

            // start 5 minute timer to show Network Disconnected message
            const id = window.setTimeout(() => {
              logger().warn(
                "Failed to reconnect in 5 minutes, user has been timed out."
              );
              dispatch(twilioActions.setLocalNetworkDisconnected(true));
            }, 5 * 60 * 1000);

            // grab the function id so we can cancel it later
            dispatch(uiActions.setLocalNetworkDisconnectedTimerId(id));
          }
        } else if (
          // In the event that networkStatus recovers from 0 to any value > 0,
          // ensure disconnected status is false and hide NoNetworkModal
          localNetworkDisconnected ||
          openNoNetworkConnectionModal
        ) {
          cancelLocalNetworkDisconnectedTimer();
          dispatch(twilioActions.setLocalNetworkDisconnected(false));
          dispatch(uiActions.setOpenNoNetworkModal(false));
        }
      }
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        { feature: "twilio/setNetworkStatus" },
        "Error setting network status",
        error?.message
      );
      throw new Error("Setting network status failed");
    }
  }
);
