import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting";
import { logger } from "src/logging/logger";
import { callSessionHeartbeat } from "src/services/ApiClient/users";
import UserSessionService from "src/services/UserSessionService";

const MEETING_HEARTBEAT_PERIOD_SECS = 60;
// This thunk starts the set internal that calls the API to track if the user is still active in the call
export function initiateCallSessionHeartbeatThunk() {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    logger().info(`Hearbeat for call session id started`);
    const id = window.setInterval(async () => {
      try {
        const { meeting } = getState();
        const { joinId, callSid } = meeting;

        const deviceId = UserSessionService.getDeviceId();

        await callSessionHeartbeat(joinId, callSid, deviceId);
      } catch (error) {
        logger().error(
          "Error sending call session heartbeat.",
          JSON.stringify(error)
        );
      }
    }, MEETING_HEARTBEAT_PERIOD_SECS * 1000);

    // grab the function id so we can cancel it later
    dispatch(meetingActions.setCallSessionHeartbeatId(id));
  };
}

export function cancelCallSessionHeartbeatThunk() {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const { meeting } = getState();
    const { callSessionHeartbeatFunctionId } = meeting;

    try {
      // clear the set interval with the stored id from redux
      if (callSessionHeartbeatFunctionId) {
        window.clearInterval(callSessionHeartbeatFunctionId);

        // reset the id in redux back to null just in case
        dispatch(meetingActions.setCallSessionHeartbeatId(null));
      }
    } catch (error) {
      logger().error(
        "Error clearing call session heartbeat interval",
        JSON.stringify(error)
      );
    }
  };
}
