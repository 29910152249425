export const download = text => {
	const element = document.createElement('a');
	element.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
	element.setAttribute('download', 'export');

	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
};

export const convertToCSV = usersData => {
	const array = typeof usersData != 'object' ? JSON.parse(usersData) : usersData;
	let str = '';

	for (let i = 0; i < array.length; i++) {
		let line = '';
		for (let index in array[i]) {
			if (line !== '') {
				line += ','
			}

			line += array[i][index];
		}

		str += line + '\r\n';
	}

	return str;
}

export const formatUsersData = usersData => {
	const result = [];
	const headers = {
		'name': 'Name',
		'email': 'Email',
		'userStatus': 'userStatus',
		'userType': 'userTypes',
		'organization': 'Organization Name',
	};
	result.push(headers);

	for (let user in usersData) {
		const {employee, organization} = usersData[user];
		const obj = {
			'name': employee.firstName + ' ' + employee.lastName,
			'email': employee.email,
			'userStatus': employee.userStatus,
			'userType': employee.userTypes,
			'organization': organization.name,
		}

		result.push(obj);
	}

	return JSON.stringify(result);
}
