import { cancel, put, select } from "redux-saga/effects";

import { API } from "../../../api";
import { logger } from "../../../common/logger";
import { CAMERA_NOT_AVAILABLE } from "../../errorCodes";
import { AppState, UserState } from "../../models";
import {
  attachLocalTracks,
  localMediaNotFound,
  logActivity,
  mediaErrorEncountered,
  updateFailed
} from "../actions";

export function* getLocalTracks(
  localMedia: MediaDeviceInfo | null,
  shouldPublishVideoTracks = true
) {
  try {
    logger().verbose("Connecting local media...");
    yield put(logActivity("Connecting local media..."));
    const { mediaState }: UserState = yield select(
      (state: AppState) => state.user
    );

    if (!localMedia) {
      logger().warn("No local media found.");
      yield put(logActivity("No local media found."));
      yield put(localMediaNotFound());
      yield cancel();
      return;
    }

    const { data, error } = yield API.GET.createLocalTracks(
      localMedia.deviceId,
      mediaState,
      shouldPublishVideoTracks
    );

    if (error) {
      logger().error("Error connecting local media.", JSON.stringify(error));

      yield put(mediaErrorEncountered(CAMERA_NOT_AVAILABLE));
      yield put(updateFailed());
      yield put(logActivity("Error connecting local media."));
      yield cancel();
      return;
    }

    logger().verbose("Successfully connected local media.");
    yield put(attachLocalTracks(data));
    yield put(logActivity("Successfully connected local media."));

    return data;
  } catch {
    logger().error("Failed to connect media.");
    yield put(updateFailed());
  }
}
