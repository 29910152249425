import {
  NCameraType,
  NCameraMount,
  NCameraInput,
  AVKCamera
} from "availkit-js/dist/Models/AVKCamera";
import { LayoutFrame } from "portalcall/commoncall/store/models";

/* 
    This utility routines are for Camera and eventuall should 
    be moved into the Nurep project
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NRKStringFromCameraType = (type: NCameraType): string => {
  if (type === NCameraType.PTZ) {
    return "PTZ";
  }
  return "Virtual";
};

const NRKStringFromCameraMount = (mount: NCameraMount): string => {
  if (mount === NCameraMount.FrontFixed) {
    return "Front";
  } else if (mount === NCameraMount.OverheadBoom) {
    return "Overhead";
  }
  return "HDMI";
};

const NRKStringFromCameraInput = (input: NCameraInput): string => {
  if (input === NCameraInput.WireOneHDMIInput) {
    return "Input1";
  } else if (input === NCameraInput.WireTwoHDMIInput) {
    return "Input2";
  }
  return "Physical";
};

const layoutWeight = (aCamera: AVKCamera): number => {
  return (
    (aCamera.type === NCameraType.PTZ ? 100 : 0) +
    (aCamera.mount === NCameraMount.FrontFixed ? 10 : 0) +
    (aCamera.input === NCameraInput.WireOneHDMIInput ? 1 : 0)
  );
};

export function CameraDisplayName(aCamera): string {
  return aCamera.inputName ? aCamera.inputName : aCamera.name;
  // if (
  //   aCamera.mount === NCameraMount.FrontFixed ||
  //   aCamera.mount === NCameraMount.OverheadBoom
  // ) {
  //   return NRKStringFromCameraMount(aCamera.mount);
  // } else {
  //   return NRKStringFromCameraInput(aCamera.input);
  // }
}

export function CameraIdentifier(aCamera): string {
  return aCamera.cameraIdentifier ? aCamera.cameraIdentifier : aCamera.id;
}

export function AVKCameraIdentifier(aCamera): boolean {
  return aCamera.cameraIdentifier ? true : false;
}

export function AVKExternalInputIdentifier(aCamera): boolean {
  return aCamera.id ? true : false;
}

export function AVKCameraCheck(layoutFrame: LayoutFrame): boolean {
  return layoutFrame.cameraLabel === 'Front' || layoutFrame.cameraLabel === 'Overhead';
}

export function CamerasSortedByName(cameras: AVKCamera[]): AVKCamera[] {
  return cameras.sort((a, b) => {
    let aWeight = layoutWeight(a);
    let bWeight = layoutWeight(b);
    // Default to sort big to small
    return bWeight - aWeight;
  });
  // return sorted;
}