import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset, change } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Button, Fields, validators } from '../../../../../../../Common/Components';
import { getAllBrandsAction } from '../../../../Brands/BrandsActions';
import { getAllStatesAction } from '../../../OrganisationActions';
import { getBusinessUnitsForSelectedBrandAction } from '../../VendorsActions';
import './CreateNewVendor.scss';

class CreateNewVendorInfo extends PureComponent {
	state = {
		formType: 'Business Unit',
		name: '',
	};

	componentDidMount() {
		if (this.props.currentBrand) {
			this.props.setBrandValue(this.props.currentBrand.id);
		}

		this.props.getAllBrands();
		this.props.getAllStates();
	}

	selectBrand = (brand) => {
		if (brand !== '') {
			this.props.getBusinessUnitsForSelectedBrand(brand);
		}
		this.props.resetFormState();
	}

	render() {
		const { t } = this.props;

		return (
			<React.Fragment>
				<form onSubmit={this.props.handleSubmit}>
					{!this.props.currentBrand && (
						<Fields.SelectField
							labelText="Vendor"
							placeholder="Select Vendor"
							name="brandId"
							options={this.props.brands ? this.props.brands.map(brand => ({
								key: brand.id,
								value: brand.id,
								text: brand.name,
							})) : []}
							onChange={this.selectBrand}
							validate={validators.required}
						/>
					)}
					<Fields.InputField
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.placeholder')}
						name="name"
						value={this.state.name}
						validate={[validators.required, validators.minimumLength3, validators.maximumLength48]}
					/>
					<Fields.SelectField
						labelText={t('common.form.state.label')}
						placeholder={t('common.form.state.placeholder')}
						name="state"
						validate={[validators.required, validators.minimumLength2, validators.maximumLength64]}
						search
						options={this.props.states ? this.props.states.map(state => ({
							key: state.id,
							value: state.name,
							text: `${state.name} (${state.abbreviation})`,
						})) : []}
					/>
					<Fields.InputField
						labelText={t('common.form.city.label')}
						placeholder={t('common.form.city.placeholder')}
						name="city"
						validate={[validators.required, validators.minimumLength2, validators.maximumLength64]}
					/>
					<Fields.InputField
						labelText={t('common.form.address.label')}
						placeholder={t('common.form.address.placeholder')}
						name="address"
						validate={[validators.required, validators.minimumLength3, validators.maximumLength128]}
					/>
					<div className="button__wrapper">
						<Button
							onClick={this.props.toggleCreateNewVendorModal}
							isGhost
							buttonSmall
						>
							Cancel
						</Button>
						<Button
							type="submit"
							isFull
							buttonSmall
						>
							Next
						</Button>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

CreateNewVendorInfo.propTypes = {
	toggleCreateNewVendorModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	brands: state.brandsReducer.brands,
	currentBrand: state.brandsReducer.currentBrand,
	businessUnitsPerBrand: state.vendorsReducer.businessUnitsPerBrand,
	states: state.organisationsReducer.states,
});

const mapDispatchToProps = dispatch => ({
	getAllBrands: () => dispatch(getAllBrandsAction()),
	getBusinessUnitsForSelectedBrand: organisationId => dispatch(getBusinessUnitsForSelectedBrandAction(organisationId)),
	getAllStates: () => dispatch(getAllStatesAction()),
	resetFormState: () => dispatch(reset('CreateNewVendor')),
	setBrandValue: value => dispatch(change('CreateNewVendor', 'brandId', value)),
});

const CreateNewVendorInfoWithTranslation = withTranslation()(CreateNewVendorInfo);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'CreateNewVendor',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(CreateNewVendorInfoWithTranslation));

