import { useState } from "react";

import moment from "moment";

import { Grid, Typography } from "@mui/material";

import { AutocompleteTextField } from "src/components/legacy/AutocompleteTextField";
import { EVENT_MOMENT_TIME_FORMAT } from "src/domains/Events/EventForm/utils";

import styles from "./styles.scss";

// generates a list that starts at an hour (0-23) and goes to the end of the day
const generateHourList = () => {
  const list = [];
  for (let hour = 0; hour < 24; hour++) {
    // example: 9:00
    const topOfTheHour = moment({ hour }).format(EVENT_MOMENT_TIME_FORMAT);
    // example: 9:30
    const middleOfTheHour = moment({
      hour,
      minute: 30,
    }).format(EVENT_MOMENT_TIME_FORMAT);

    list.push({
      value: topOfTheHour,
      label: topOfTheHour,
    });

    list.push({
      value: middleOfTheHour,
      label: middleOfTheHour,
    });
  }

  return list;
};

interface Props {
  label: string;
  required?: boolean;
  name: string;
  placeholderLabel?: string;

  selectedValue: any;
  inputValue: string;
  onSelect: (val) => void;
  onInputChange: (value, event?, reason?) => void;
  onInputBlur?: () => void;

  disabled?: boolean;
  error?: string;
  "data-test-id"?: string;
}

const DROPDOWN_HOUR_LIST = generateHourList();

export const TimePicker = ({
  label,
  required,
  selectedValue,
  inputValue,
  onSelect,
  onInputChange,
  onInputBlur,
  disabled = false,
  name,
  placeholderLabel = "",
  error = "",
  "data-test-id": dataTestId,
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Grid container direction="column" data-test-id={dataTestId}>
      <Grid item>
        <Typography variant="body1" component={"label"}>
          {label}
          {required ? <span className={styles.required}> *</span> : null}
        </Typography>
      </Grid>
      <Grid item>
        <AutocompleteTextField
          name={name}
          data-test-id={`${dataTestId} textfield`}
          label={""}
          open={open}
          placeholderLabel={placeholderLabel}
          getOptionLabel={(option: any) =>
            `${option.label !== undefined ? option.label : ""}`
          }
          error={error}
          value={selectedValue}
          inputValue={inputValue}
          options={DROPDOWN_HOUR_LIST}
          onChange={(newValue) => {
            onSelect(newValue);
            setOpen(false);
          }}
          isOptionEqualToValue={(option, val) => {
            // if the user types in a custom value then check if it already exists so we can highlight it
            if (inputValue) {
              return option.value === inputValue;
            }

            return option.value === val.value;
          }}
          onInputChange={onInputChange}
          onInputClick={() => {
            if (!disabled) {
              setOpen(true);
            }
          }}
          onClose={(event: any, reason: any) => {
            // only need this for the toggle input button (the up and down arrows)
            if (reason === "toggleInput") {
              setOpen(false);
              event.stopPropagation();
            }
          }}
          onInputBlur={() => {
            setOpen(false);
            if (onInputBlur) {
              onInputBlur();
            }
          }}
          // dont filter the options as the user types since we want to see the full list of times
          filterOptions={(x) => x}
          // need to allow the user to put in custom times like 11:15 PM (validation done in react-hook-form outside of this component)
          freeSolo={true}
          disabled={disabled}
          // we want the popup arrow to always appear
          forcePopupIcon={true}
        />
      </Grid>
    </Grid>
  );
};
