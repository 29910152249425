import "moment-timezone";

import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import moment from "moment";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinkIcon from "@mui/icons-material/Link";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Typography } from "@mui/material";

import { TimePicker } from "src/components/TimePicker/TimePicker";
import { AutocompleteTextField } from "src/components/legacy/AutocompleteTextField";
import { Button } from "src/components/legacy/Button";
import { ChipInput } from "src/components/legacy/ChipInput";
import { DatePicker } from "src/components/legacy/DatePicker";
import { IconButton } from "src/components/legacy/IconButton";
import { PopoverButton } from "src/components/legacy/Popover";
import { TextField } from "src/components/legacy/TextField";
import {
  ALLOWED_SPECIAL_CHARACTERS_MESSAGE,
  ALPHA_NUMERIC_AND_SPECIAL_CHARACTERS_REGEX,
  onlyAllowedSpecialCharacters,
  onlyLettersSpacesPeriodsValidation,
} from "src/constants/validation";
import {
  checkValidDateFormat,
  checkValidTimeFormat,
  EVENT_MOMENT_DATE_FORMAT,
  EVENT_MOMENT_DATE_TIME_FORMAT,
  EVENT_MOMENT_TIME_FORMAT,
  EVENT_MUI_DATE_TIME_FORMAT,
  otherTherapyOrProcedureSelected,
} from "src/domains/Events/EventForm/utils";
import { useGetAllUsersForAutocomplete } from "src/queries/users";
import { history } from "src/reducer";
import { Procedures, Therapies } from "src/services/ApiClient/businessUnit";
import { Hospital } from "src/services/ApiClient/hospital";
import {
  createMultiPartyEvent,
  EventRequestBody,
  updateMultiPartyEvent,
  MultiPartyEvent,
} from "src/services/ApiClient/scheduler";
import { TrialUsageContent } from "src/services/ApiClient/trialUsage";
import { TimeZones, User } from "src/services/ApiClient/users";
import { roundToNearest30Minute } from "src/utils/datetime";

import {
  minLengthField,
  maxLengthField,
  requiredField,
  isValidEmail,
  INVALID_EMAIL_MESSAGE,
  onlyLettersAndSpaces,
  isLessThanOrEqualTo,
  LENGTH_LIMIT_MESSAGE,
  testTextWithCustomRegex,
} from "avail-web-ui/constants/validation";
import DomainConstants from "avail-web-ui/domains/DomainConstants";
import { addNotificationAction } from "avail-web-ui/dux/NotificationSlice";

import styles from "./styles.scss";

// either we get a blank event (meaning we are creating a new one) or an event object (meaning we are editing an existing one)
interface Props {
  event?: MultiPartyEvent;
  mode?: "EDIT" | "CREATE";
  initialFormState: any; // This object will go into the react-hook-form defaultValues (either blank for create, or filled in for edit mode)

  creatorUserId: string;

  // below lists of objects are needed for autocomplete
  hospitals: Hospital[]; // maps to facilities in the UI
  therapies: Therapies[];
  timezones: TimeZones[];
  usage: TrialUsageContent | null; // used to notify user if they are running out of trial minutes
}

// custom ids for dropdowns where we want the user to be able to enter a new custom value
// we watch these keys when the form fields change and can use them to show/hide the custom inputs
const CUSTOM_THERAPY_ID = "CUSTOM_THERAPY_ID";
const CUSTOM_PROCEDURE_ID = "CUSTOM_PROCEDURE_ID";

const MAX_PARTICIPANTS_SELECTION_LIMIT = 3;
const MAX_CUSTOM_TAGS_SELECTION_LIMIT = 5;

const chipDisplayValueFunction = (value: User | string) => {
  if (typeof value === "string") {
    return value;
  } else {
    // else case means this is a User
    if (value?.isMember) {
      return `${value.firstName} ${value.lastName} (${value.email})`;
    } else {
      return value.email;
    }
  }
};

export const EventForm = ({
  event,
  mode,
  initialFormState,
  creatorUserId,
  hospitals,
  therapies,
  timezones,
  usage,
}: Props) => {
  const {
    handleSubmit,
    control,
    getValues,
    formState,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: initialFormState,
  });

  const dispatch = useDispatch();

  const createNewEvent = async (data) => {
    const participants = data[DomainConstants.key.participants].chips.map(
      (user: User | string) => {
        return {
          email: typeof user === "string" ? user.trim() : user.email,
          role: "PARTICIPANT",
        };
      }
    );

    // the host field is a chip input so the user can either select an extisting user from the dropdown
    // or add in a custom email so we need to check which they entered, there will only be 1 so we just get the first item in the chips array
    const hostUserInput = data[DomainConstants.key.host].chips[0];
    const hostUserEmail =
      typeof hostUserInput === "string" ? hostUserInput : hostUserInput.email;

    participants.push({
      email: hostUserEmail,
      role: "HOST",
    });

    const timezone = data[DomainConstants.key.timezone];

    // combine start date and time before sending it to the API
    const startDate = moment(data[DomainConstants.key.dateStart]).format(
      EVENT_MOMENT_DATE_FORMAT
    );
    const formStateTime = data[DomainConstants.key.timeStart];

    // if the user entered a custom time it will be in the input value, otherwise use the "value" from the selected time
    const startTime = formStateTime.inputValue
      ? formStateTime.inputValue
      : formStateTime.selectedValue.value;

    const finalStartDateTime = moment(
      `${startDate} ${startTime}`,
      EVENT_MOMENT_DATE_TIME_FORMAT
    )
      // add timezone that the user selected in the dropdown to the start/end time
      // the true argument says dont convert the time into that timezone
      // this would add or subtract hours to the time if we didn't send true, that would be not what the user wants
      .tz(timezone.id, true)
      .format();

    // also combine end date and time
    const endDate = moment(data[DomainConstants.key.dateEnd]).format(
      EVENT_MOMENT_DATE_FORMAT
    );
    const formEndTime = data[DomainConstants.key.timeEnd];

    const endTime = formEndTime.inputValue
      ? formEndTime.inputValue
      : formEndTime.selectedValue.value;

    const finalEndDateTime = moment(
      `${endDate} ${endTime}`,
      EVENT_MOMENT_DATE_TIME_FORMAT
    )
      // add timezone that the user selected in the dropdown to the start/end time
      .tz(timezone.id, true)
      .format();

    const postData: EventRequestBody = {
      subject: data[DomainConstants.key.title],
      description: "",
      billingUserId: creatorUserId,
      hospitalId: data[DomainConstants.key.facility].id,
      surgeonName: data[DomainConstants.key.surgeon],

      // no need to convert start / end to UTC before we send them to the backend (they do it for us)
      // just need to format it into a string
      startDate: finalStartDateTime,
      endDate: finalEndDateTime,
      timeZoneId: timezone.id,
      // "recurringRule": "string",

      eventTags: data[DomainConstants.key.customTags].chips,
      participants,
    };

    const userEnteredCustomTherapy = otherTherapyOrProcedureSelected(
      data[DomainConstants.key.therapy]
    );

    // if the user enters a custom value for Therapy then we have to put it in a different key when sending it to the backend
    // we also have to set the regular therapyId to be the custom OTHER therapy that the backend gives us
    if (userEnteredCustomTherapy) {
      postData.otherTherapy = data.CUSTOM_THERAPY_ID;
      postData.therapyId = data[DomainConstants.key.therapy].id;
    } else {
      postData.therapyId = data[DomainConstants.key.therapy].id;
    }

    const userEnteredCustomProcedure = otherTherapyOrProcedureSelected(
      data[DomainConstants.key.procedure]
    );

    // if the user enters a custom value for Procedure then we have to put it in a different key when sending it to the backend
    // we also have to set the regular procedureId to be the custom OTHER procedure that the backend gives us
    if (userEnteredCustomProcedure) {
      postData.otherProcedure = data.CUSTOM_PROCEDURE_ID;
      postData.procedureId = data[DomainConstants.key.procedure].id;
    } else {
      postData.procedureId = data[DomainConstants.key.procedure].id;
    }

    try {
      if (mode === "CREATE") {
        await createMultiPartyEvent(postData);
        dispatch(
          addNotificationAction({
            message: "Event Created",
            type: "success",
          })
        );
      } else {
        await updateMultiPartyEvent(event.eventId, postData);
        dispatch(
          addNotificationAction({
            message: "Event Updated",
            type: "success",
          })
        );
      }

      // check if the user tried to create an event that is longer
      // than the trial minutes they have remaining (it'll just show a popup notification)
      usageDateCheck(
        finalStartDateTime,
        finalEndDateTime,
        mode,
        initialFormState
      );

      history.push("/events");
    } catch (error) {
      dispatch(
        addNotificationAction({
          message: error.message,
          type: "error",
        })
      );
      return;
    }
  };

  const copyLink = () => {
    const link = event.eventLink;
    navigator.clipboard.writeText(link);
  };

  // Display a notification warning the trial user the event's
  // minutes are exceeding their usage TRIAL remaining minutes
  const usageDateCheck = (
    startDateTime: string,
    endDateTime: string,
    formMode: "EDIT" | "CREATE",
    initialFormStateValues: any
  ) => {
    if (usage) {
      const { availableTrialMinutes, scheduledTrialMinutes } = usage;

      let showWarning = false;

      const date1 = moment(startDateTime);
      const date2 = moment(endDateTime);
      const eventLength = date2.diff(date1, "minutes"); // in minutes

      // if the user is creating a new event then this check is pretty simple,
      // just check if this new event length + the scheduled ones are greater than the minutes available
      if (formMode === "CREATE") {
        showWarning =
          eventLength + scheduledTrialMinutes > availableTrialMinutes;
      } else {
        // if the user is UPDATING an event then we need to do some math for the trial warning message

        // TODO try to move this whole section to the backend, its a lot of math to show a warning message

        const initialStartDate = moment(
          initialFormStateValues[DomainConstants.key.dateStart]
        );
        const initialEndDate = moment(
          initialFormStateValues[DomainConstants.key.dateEnd]
        );

        const previousEventLength = initialEndDate.diff(
          initialStartDate,
          "minutes"
        ); // in minutes

        if (eventLength === previousEventLength) {
          // if the time didn't change then the event's length is already included in scheduledTrialMinutes
          showWarning = scheduledTrialMinutes > availableTrialMinutes;
        } else if (eventLength < previousEventLength) {
          // if the updated event length is less than the previous event length
          // then we need to subtract the difference from the scheduled minutes then
          // add the new length and compare it to the minutes they have available
          const eventDiffInMinutes = previousEventLength - eventLength;
          showWarning =
            eventLength + (scheduledTrialMinutes - eventDiffInMinutes) >
            availableTrialMinutes;
        } else {
          // if the updated event is longer than the previous event
          // then we need to get the difference in minutes and add only that to the scheduled minutes
          // because the old event length is already a part of the scheduled trial minutes
          const eventDiffInMinutes = eventLength - previousEventLength;

          showWarning =
            eventDiffInMinutes + scheduledTrialMinutes > availableTrialMinutes;
        }
      }

      // if the event length (along with other already scheduled mins)
      // exceeds the available, user should get a warning
      if (showWarning) {
        dispatch(
          addNotificationAction({
            message:
              "The duration of this event exceeds your trial minutes. You will not be able to schedule any more events",
            type: "warning",
          })
        );
      }
    }
  };

  const startDateFieldValue = watch(DomainConstants.key.dateStart);
  const startTimeFieldValue = watch(DomainConstants.key.timeStart);

  // watching a few form field values so we can show/hide other inputs based on their values
  const therapyFormFieldValue = watch(DomainConstants.key.therapy);
  const procedureFormFieldValue = watch(DomainConstants.key.procedure);

  // the list of procedures available in the dropdown come from the selected therapy object
  const proceduresFromSelectedTherapy =
    therapyFormFieldValue && therapyFormFieldValue.procedures;

  const otherTherapySelected = otherTherapyOrProcedureSelected(
    therapyFormFieldValue
  );
  const otherProcedureSelected = otherTherapyOrProcedureSelected(
    procedureFormFieldValue
  );

  // Autocomplete section for host and participants
  // as the user enters some text into the input we update the query which gives us the new list of users
  // that we show in the autocomplete dropdown
  // had to have separate queries for the 2 dropdowns otherwise if you type text in one, you would get the same list of users for the other
  const hostFormFieldValue = watch(DomainConstants.key.host);
  const participantsFormFieldValue = watch(DomainConstants.key.participants);

  const { data: usersAutocompleteDataForHost } = useGetAllUsersForAutocomplete(
    hostFormFieldValue.inputValue
  );
  const {
    data: usersAutocompleteDataForParticipants,
  } = useGetAllUsersForAutocomplete(participantsFormFieldValue.inputValue);

  const eventHasStarted =
    mode === "EDIT" && event && event.status === "STARTED";

  return (
    <form
      data-test-id="createEventForm"
      autoComplete="off"
      // className={styles.form}
      noValidate
      onSubmit={handleSubmit(createNewEvent)}
    >
      <Grid direction="row" spacing={2} container>
        <Grid container direction="column" item lg={6} spacing={2}>
          <Grid item container>
            <Controller
              name={DomainConstants.key.title}
              control={control}
              rules={{
                ...minLengthField(DomainConstants.label.title, 2),
                ...maxLengthField(DomainConstants.label.title, 60),
                ...requiredField(DomainConstants.key.title),
                ...onlyAllowedSpecialCharacters(),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <TextField
                      data-test-id={DomainConstants.key.title}
                      name={DomainConstants.key.title}
                      label={DomainConstants.label.eventTitle}
                      placeholder={DomainConstants.placeholder.eventTitle}
                      required
                      onChange={onChange}
                      value={value}
                      disabled={eventHasStarted} // dont allow editing if event has started
                    />
                    {error && (
                      // TODO move error message to own component
                      <span className={styles.error}>{error.message}</span>
                    )}
                  </>
                );
              }}
            />
          </Grid>

          <Grid item container>
            <Controller
              name={DomainConstants.key.facility}
              control={control}
              rules={{
                ...requiredField(DomainConstants.label.facility),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <AutocompleteTextField
                      placeholderLabel="Select Facility"
                      name={DomainConstants.key.facility}
                      data-test-id={DomainConstants.key.facility}
                      label={DomainConstants.label.facility}
                      getOptionLabel={(option: Hospital) =>
                        `${option.name !== undefined ? option.name : ""}`
                      }
                      value={value}
                      options={hospitals}
                      onChange={onChange}
                      required
                      disabled={eventHasStarted} // dont allow editing if event has started
                    />
                    {error && (
                      <span className={styles.error}>{error.message}</span>
                    )}
                  </>
                );
              }}
            />
          </Grid>

          <Grid direction="row" spacing={2} container item>
            <Grid item lg={6}>
              <Controller
                name={DomainConstants.key.therapy}
                control={control}
                rules={{
                  ...requiredField(DomainConstants.label.therapy),
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <AutocompleteTextField
                        name={DomainConstants.key.therapy}
                        data-test-id={DomainConstants.key.therapy}
                        label={DomainConstants.label.therapy}
                        placeholderLabel="Select Therapy"
                        getOptionLabel={(option: Therapies) =>
                          `${option.name !== undefined ? option.name : ""}`
                        }
                        value={value}
                        options={therapies}
                        onChange={(newValue) => {
                          // since the list of procedures dropdown depends on the therapy selection,
                          // when therapy is updated we need to clear the procedure form field value
                          setValue(DomainConstants.key.procedure, "", {
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                          onChange(newValue);
                        }}
                        required
                        disabled={eventHasStarted} // dont allow editing if event has started
                      />
                      {error && (
                        <span className={styles.error}>{error.message}</span>
                      )}
                    </>
                  );
                }}
              />
            </Grid>
            {otherTherapySelected ? (
              <Grid item lg={6}>
                <Controller
                  name={CUSTOM_THERAPY_ID}
                  control={control}
                  rules={{
                    ...minLengthField(DomainConstants.label.therapy, 3),
                    ...maxLengthField(DomainConstants.label.therapy, 60),
                    ...requiredField(DomainConstants.label.therapy),
                    ...onlyLettersAndSpaces(),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <TextField
                          label={DomainConstants.label.therapy}
                          data-test-id={CUSTOM_THERAPY_ID}
                          placeholder="Enter Other Therapy"
                          required
                          onChange={onChange}
                          value={value}
                          disabled={eventHasStarted} // dont allow editing if event has started
                        />
                        {error && (
                          <span className={styles.error}>{error.message}</span>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid direction="row" spacing={2} container item>
            <Grid item lg={6}>
              <Controller
                name={DomainConstants.key.procedure}
                control={control}
                rules={{
                  ...requiredField(DomainConstants.label.procedure),
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <AutocompleteTextField
                        name={DomainConstants.key.procedure}
                        data-test-id={DomainConstants.key.procedure}
                        label={DomainConstants.label.procedure}
                        placeholderLabel="Select Procedure"
                        getOptionLabel={(option: Procedures) =>
                          `${option.name !== undefined ? option.name : ""}`
                        }
                        value={value}
                        options={proceduresFromSelectedTherapy || []}
                        onChange={onChange}
                        required
                        disabled={eventHasStarted} // dont allow editing if event has started
                      />
                      {error && (
                        <span className={styles.error}>{error.message}</span>
                      )}
                    </>
                  );
                }}
              />
            </Grid>
            {otherProcedureSelected ? (
              <Grid item lg={6}>
                <Controller
                  name={CUSTOM_PROCEDURE_ID}
                  control={control}
                  rules={{
                    ...minLengthField(DomainConstants.label.procedure, 3),
                    ...maxLengthField(DomainConstants.label.procedure, 60),
                    ...requiredField(DomainConstants.label.procedure),
                    ...onlyLettersAndSpaces(),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <TextField
                          label={DomainConstants.label.procedure}
                          data-test-id={CUSTOM_PROCEDURE_ID}
                          placeholder="Enter Other Procedure"
                          required
                          onChange={onChange}
                          value={value}
                          disabled={eventHasStarted} // dont allow editing if event has started
                        />
                        {error && (
                          <span className={styles.error}>{error.message}</span>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid item>
            <Typography variant="h6">Time</Typography>
          </Grid>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container item direction="row" spacing={2}>
              <Grid item lg={3}>
                <Controller
                  // this is START DATE (not time)
                  name={DomainConstants.key.dateStart}
                  control={control}
                  rules={{
                    validate: {
                      all: (value) => {
                        // if value is undefined or null then we need to throw an error
                        if (!value) {
                          return "Start time is required";
                        }

                        if (!checkValidDateFormat(value)) {
                          return "Invalid date time format";
                        }

                        return true;
                      },
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <DatePicker
                          inputFormat={EVENT_MUI_DATE_TIME_FORMAT}
                          label={DomainConstants.label.dateStart}
                          data-test-id={DomainConstants.key.dateStart}
                          required
                          value={value}
                          error={error?.message} // if the error is defined then the input will highlight red
                          onChange={(newValue) => {
                            const endDate = getValues(
                              DomainConstants.key.dateEnd
                            );
                            const startTime = getValues(
                              DomainConstants.key.timeStart
                            );

                            // if the new start date is today...
                            if (
                              moment(newValue, EVENT_MOMENT_DATE_FORMAT).isSame(
                                moment(),
                                "day"
                              )
                            ) {
                              // and if the start time is before the current real time then we have to update the start time
                              // if start time is 11:00 AM and current real time is 12:42 PM, then start time has to be changed to 1:00pm
                              if (
                                moment(
                                  startTime.inputValue,
                                  EVENT_MOMENT_TIME_FORMAT
                                ).isBefore(moment())
                              ) {
                                // take the current time and round it to the nearest 30 min interval
                                const newStartTime = roundToNearest30Minute(
                                  moment()
                                ).format(EVENT_MOMENT_TIME_FORMAT);

                                // then set time start to be that new time
                                setValue(
                                  DomainConstants.key.timeStart,
                                  {
                                    inputValue: newStartTime,
                                    selectedValue: {
                                      label: newStartTime,
                                      value: newStartTime,
                                    },
                                  },
                                  {
                                    shouldDirty: true,
                                    shouldTouch: true,
                                  }
                                );
                              }
                            }

                            // if endDate and newValue (aka startDate) are both set
                            if (endDate && newValue) {
                              // then check if endDate is before (time wise) the new value for start date
                              if (
                                moment(
                                  endDate,
                                  EVENT_MOMENT_DATE_FORMAT
                                ).isBefore(
                                  moment(newValue, EVENT_MOMENT_DATE_FORMAT)
                                )
                              ) {
                                // set end date to be the same as the new start date
                                setValue(
                                  DomainConstants.key.dateEnd,
                                  moment(newValue),
                                  {
                                    shouldDirty: true,
                                    shouldTouch: true,
                                  }
                                );

                                const newTime = moment(
                                  startTime.inputValue,
                                  EVENT_MOMENT_TIME_FORMAT
                                )
                                  .add(1, "hour")
                                  .format(EVENT_MOMENT_TIME_FORMAT);

                                // update the end time to be the start time + 1 hour
                                setValue(
                                  DomainConstants.key.timeEnd,
                                  {
                                    inputValue: newTime,
                                    selectedValue: {
                                      label: newTime,
                                      value: newTime,
                                    },
                                  },
                                  {
                                    shouldDirty: true,
                                    shouldTouch: true,
                                  }
                                );
                              }
                            }

                            onChange(moment(newValue));
                          }}
                          // not setting start time to have a min date because in certain edge cases the user might
                          // want to create an event yesterday for a differnt timezone
                          // minDate={moment.utc().toDate()}
                          maxDate={moment.utc().add(2, "years").toDate()} // start time can't be beyond 2 years
                          disabled={eventHasStarted} // dont allow editing if event has started
                        />
                        {error && (
                          <span className={styles.error}>{error.message}</span>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item lg={3}>
                <Controller
                  name={DomainConstants.key.timeStart}
                  control={control}
                  rules={{
                    validate: {
                      all: (value) => {
                        // if value is undefined or null then we need to throw an error
                        if (value.inputValue === "") {
                          return "Start time is required";
                        } else if (!checkValidTimeFormat(value.inputValue)) {
                          return "Invalid time format";
                        }

                        return true;
                      },
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <TimePicker
                          name={DomainConstants.key.timeStart}
                          label={DomainConstants.label.timeStart}
                          data-test-id={DomainConstants.key.timeStart}
                          placeholderLabel={DomainConstants.label.timeStart}
                          error={error?.message}
                          required
                          selectedValue={value.selectedValue}
                          inputValue={value.inputValue}
                          disabled={eventHasStarted}
                          // if the user enters custom time then we need to do some checks when they click off of the input
                          onInputBlur={() => {
                            // if no error and the input value is not empty
                            if (!error && value.inputValue !== "") {
                              // need to check if start time is past the end time, then update end time + 1 hour

                              const endDate = getValues(
                                DomainConstants.key.dateEnd
                              );

                              const endTime = getValues(
                                DomainConstants.key.timeEnd
                              );

                              // need to check start/end date is the same AND then if the end time is before the start time
                              // if true then move the end time to be start time + 1 hour
                              if (
                                moment(
                                  endDate,
                                  EVENT_MOMENT_DATE_FORMAT
                                ).isSame(
                                  moment(
                                    startDateFieldValue,
                                    EVENT_MOMENT_DATE_FORMAT
                                  ),
                                  "day"
                                ) &&
                                moment(
                                  endTime.inputValue,
                                  EVENT_MOMENT_TIME_FORMAT
                                ).isSameOrBefore(
                                  moment(
                                    value.inputValue,
                                    EVENT_MOMENT_TIME_FORMAT
                                  )
                                )
                              ) {
                                // update the end time to be the start time + 1 hour
                                const newTime = moment(
                                  value.inputValue,
                                  EVENT_MOMENT_TIME_FORMAT
                                )
                                  .add(1, "hour")
                                  .format(EVENT_MOMENT_TIME_FORMAT);

                                setValue(
                                  DomainConstants.key.timeEnd,
                                  {
                                    inputValue: newTime,
                                    selectedValue: {
                                      label: newTime,
                                      value: newTime,
                                    },
                                  },
                                  {
                                    shouldDirty: true,
                                    shouldTouch: true,
                                  }
                                );
                              } else {
                                // we need this else case to trigger revalidation of the end time field
                                // start time changed so we need to check if end time is still valid
                                setValue(
                                  DomainConstants.key.timeEnd,
                                  {
                                    inputValue: endTime.inputValue,
                                    selectedValue: {
                                      label: endTime.inputValue,
                                      value: endTime.inputValue,
                                    },
                                  },
                                  {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                    shouldTouch: true,
                                  }
                                );
                              }
                            }
                          }}
                          onSelect={(newValue) => {
                            const endDate = getValues(
                              DomainConstants.key.dateEnd
                            );

                            const endTime = getValues(
                              DomainConstants.key.timeEnd
                            );

                            // if end time is the same or before the new start time
                            // then move end time to be start time + 1 hour
                            if (
                              newValue &&
                              moment(endDate, EVENT_MOMENT_DATE_FORMAT).isSame(
                                moment(
                                  startDateFieldValue,
                                  EVENT_MOMENT_DATE_FORMAT
                                ),
                                "day"
                              ) &&
                              moment(
                                endTime.inputValue,
                                EVENT_MOMENT_TIME_FORMAT
                              ).isSameOrBefore(
                                moment(newValue.value, EVENT_MOMENT_TIME_FORMAT)
                              )
                            ) {
                              const newTime = moment(
                                newValue.value,
                                EVENT_MOMENT_TIME_FORMAT
                              )
                                .add(1, "hour")
                                .format(EVENT_MOMENT_TIME_FORMAT);

                              // update the end time to be the start time + 1 hour
                              setValue(
                                DomainConstants.key.timeEnd,
                                {
                                  inputValue: newTime,
                                  selectedValue: {
                                    label: newTime,
                                    value: newTime,
                                  },
                                },
                                {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                  shouldTouch: true,
                                }
                              );
                            } else {
                              // we need this else case to trigger revalidation of the end time field
                              // start time changed so we need to check if end time is still valid
                              setValue(
                                DomainConstants.key.timeEnd,
                                {
                                  inputValue: endTime.inputValue,
                                  selectedValue: {
                                    label: endTime.inputValue,
                                    value: endTime.inputValue,
                                  },
                                },
                                {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                  shouldTouch: true,
                                }
                              );
                            }

                            onChange({
                              ...value,
                              selectedValue: newValue,
                              inputValue: "",
                            });
                          }}
                          // setInputValue function sends in the user's custom values they are typing in
                          onInputChange={(newValue: string, e, reason) => {
                            // the user typing comes in as an "input"
                            // we have to remove selected value otherwise MUI doesn't let
                            // use just render the user's entered text
                            if (reason === "input") {
                              onChange({
                                ...value,
                                selectedValue: null,
                                inputValue: newValue,
                              });
                              // if they click on the X button then that is the clear
                            } else if (reason === "clear") {
                              onChange({
                                ...value,
                                inputValue: "",
                                selectedValue: null,
                              });
                              // any other reason we just want to update the value as it comes in
                              // its when the user selects on a dropdown option
                            } else {
                              onChange({
                                ...value,
                                inputValue: newValue,
                              });
                            }
                          }}
                        />
                        {error && (
                          <span className={styles.error}>{error.message}</span>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item lg={3}>
                <Controller
                  name={DomainConstants.key.dateEnd}
                  control={control}
                  rules={{
                    validate: {
                      all: (value) => {
                        // if value is undefined or null then we need to throw an error
                        if (!value) {
                          return "End date is required";
                        }

                        // check the date format
                        if (!checkValidDateFormat(value)) {
                          return "Invalid date time format";
                        }

                        return true;
                      },
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <DatePicker
                          inputFormat={EVENT_MUI_DATE_TIME_FORMAT}
                          label={DomainConstants.label.dateEnd}
                          data-test-id={DomainConstants.key.dateEnd}
                          value={value}
                          required
                          onChange={(newValue) => {
                            onChange(newValue);
                          }}
                          error={error?.message} // if the error is defined then the input will highlight red
                          minDate={
                            startDateFieldValue // if the start value is defined then the min time for end needs equal to start date
                              ? moment(startDateFieldValue).toDate()
                              : moment.utc().toDate() // otherwise just use today
                          }
                          maxDate={
                            startDateFieldValue // if the start value is defined then the max time for end needs to be 2 years
                              ? moment(startDateFieldValue)
                                  .add(2, "years")
                                  .toDate()
                              : moment.utc().add(2, "years").toDate()
                          }
                          disabled={eventHasStarted} // dont allow editing if event has started
                        />
                        {error && (
                          <span className={styles.error}>{error.message}</span>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item lg={3}>
                <Controller
                  name={DomainConstants.key.timeEnd}
                  control={control}
                  rules={{
                    validate: {
                      all: (value) => {
                        // if value is undefined or null then we need to throw an error

                        if (value.inputValue === "") {
                          return "End time is required";
                        } else {
                          if (!checkValidTimeFormat(value.inputValue)) {
                            return "Invalid time format";
                          }
                          // check if end time is before start time

                          const endDate = getValues(
                            DomainConstants.key.dateEnd
                          );

                          // need to check if date is the same AND then if the end time is before the start time
                          if (
                            moment(endDate, EVENT_MOMENT_DATE_FORMAT).isSame(
                              moment(
                                startDateFieldValue,
                                EVENT_MOMENT_DATE_FORMAT
                              ),
                              "day"
                            ) &&
                            moment(
                              value.inputValue,
                              EVENT_MOMENT_TIME_FORMAT
                            ).isSameOrBefore(
                              moment(
                                startTimeFieldValue.inputValue,
                                EVENT_MOMENT_TIME_FORMAT
                              )
                            )
                          ) {
                            return "End time must be after start time";
                          }
                        }

                        return true;
                      },
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <TimePicker
                          name={DomainConstants.key.timeEnd}
                          label={DomainConstants.label.timeEnd}
                          data-test-id={DomainConstants.key.timeEnd}
                          placeholderLabel={DomainConstants.label.timeEnd}
                          required
                          error={error?.message}
                          selectedValue={value.selectedValue}
                          inputValue={value.inputValue}
                          disabled={eventHasStarted}
                          onSelect={(newValue) => {
                            onChange({
                              ...value,
                              selectedValue: newValue,
                              inputValue: "",
                            });
                          }}
                          // setInputValue function sends in the user's custom values they are typing in
                          onInputChange={(newValue: string, e, reason) => {
                            // the user typing comes in as an "input"
                            // we have to remove selected value otherwise MUI doesn't let
                            // use just render the user's entered text
                            if (reason === "input") {
                              onChange({
                                ...value,
                                selectedValue: null,
                                inputValue: newValue,
                              });
                              // if they click on the X button then that is the clear
                            } else if (reason === "clear") {
                              onChange({
                                ...value,
                                inputValue: "",
                                selectedValue: null,
                              });
                              // any other reason we just want to update the value as it comes in
                              // its when the user selects on a dropdown option
                            } else {
                              onChange({
                                ...value,
                                inputValue: newValue,
                              });
                            }
                          }}
                        />
                        {error && (
                          <span className={styles.error}>{error.message}</span>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>

          <Grid item container>
            <Controller
              name={DomainConstants.key.timezone}
              control={control}
              rules={{
                ...requiredField("Time zone"),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <AutocompleteTextField
                      placeholderLabel="Select a time zone"
                      name={DomainConstants.key.timezone}
                      data-test-id={DomainConstants.key.timezone}
                      label={DomainConstants.label.timezone}
                      getOptionLabel={(option: TimeZones) =>
                        // preserving previous template string until feature flag is created
                        // `${option.id} (${option.offset})`
                        `(UTC${option.offset}) ${option.displayName}`
                      }
                      value={value}
                      options={timezones}
                      onChange={onChange}
                      required
                      disabled={eventHasStarted} // dont allow editing if event has started
                    />
                    {error && (
                      <span className={styles.error}>{error.message}</span>
                    )}
                  </>
                );
              }}
            />
          </Grid>

          <Grid item>
            <Typography variant="h6">Participants</Typography>
          </Grid>

          <Grid container item>
            <Controller
              name={DomainConstants.key.surgeon}
              control={control}
              rules={{
                ...minLengthField(DomainConstants.label.specialist, 2),
                ...maxLengthField(DomainConstants.label.title, 60),
                ...requiredField(DomainConstants.key.name),
                ...onlyLettersSpacesPeriodsValidation(),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <TextField
                      data-test-id={DomainConstants.key.surgeon}
                      label={DomainConstants.label.specialist}
                      placeholder={DomainConstants.placeholder.specialist}
                      required
                      onChange={onChange}
                      value={value}
                    />
                    {error && (
                      <span className={styles.error}>{error.message}</span>
                    )}
                  </>
                );
              }}
            />
          </Grid>
          <Grid container item>
            {/* The host value can either be an existing User or a user entered email (guest user) */}
            <Controller
              name={DomainConstants.key.host}
              control={control}
              rules={{
                validate: {
                  all: (value) => {
                    const { inputValue, chips } = value;

                    // if there are no chips and the input is empty then we need to tell them to enter at least 1
                    if (chips.length === 0 && inputValue === "") {
                      return "Please enter at least 1 email";
                    }
                    // // otherwise if there are chips then if its empty its okay
                    else if (inputValue === "") {
                      return true;
                    }

                    if (!isValidEmail(inputValue)) {
                      // if the trimmed version of the email is valid then dont throw the error
                      // the onBlur trims the value and creates the chip if its a guest user (autocomplete selection creates a User chip)
                      if (isValidEmail(inputValue.trim())) {
                        return true;
                      }
                      return INVALID_EMAIL_MESSAGE;
                    }

                    return true;
                  },
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <ChipInput
                      maxChipsCount={1}
                      error={error?.message}
                      placeholderLabel={`Enter ${DomainConstants.label.host}`}
                      data-test-id={DomainConstants.key.host}
                      required
                      autocomplete={{
                        autocompleteValues: usersAutocompleteDataForHost
                          ? usersAutocompleteDataForHost.content
                          : [],
                        getOptionLabel: (option: User | string) => {
                          if (typeof option === "string") {
                            return option;
                          } else {
                            return `${option.firstName} ${option.lastName} (${option.email})`;
                          }
                        },
                      }}
                      chips={value.chips}
                      // the setChips function gets called inside the ChipInput and it simply passes in all the current chips
                      // as an array, so User elements are the ones the user clicked on in the autocomplete dropdown, and the strings are the customly entered ones
                      setChips={(newValue: (User | string)[]) => {
                        onChange({
                          ...value,
                          chips: [...newValue],
                          inputValue: "",
                        });
                      }}
                      inputValue={value.inputValue.trim()}
                      // setInputValue function sends in the user's custom values they are typing in
                      setInputValue={(newValue: string) => {
                        onChange({
                          ...value,
                          inputValue: newValue,
                        });
                      }}
                      onBlur={() => {
                        // so the user might type in an email then instead of clicking on an option in the dropdown, they might click away
                        // expecting the chip input to turn into a chip but that doesn't happen automatically
                        // so we handle that ourselves in this function

                        // only turn a value into a chip if there isn't an error and if the user entered something
                        if (!error && value.inputValue !== "") {
                          let newChipValue;

                          // if we have the autocomplete list of users then we can find the real user to populate the chip with
                          // otherwise we'll just enter the valid email they typed in
                          if (usersAutocompleteDataForHost) {
                            const users = usersAutocompleteDataForHost.content;
                            // we have to actually find the user object given what the user typed in
                            const user = users.find(
                              (userValue) =>
                                userValue.email === value.inputValue.trim()
                            );

                            // if we found the user then put that in the list of chips
                            // if we dont have the user autocomplete values then just store the entered text
                            newChipValue = user || value.inputValue.trim();
                          } else {
                            newChipValue = value.inputValue.trim();
                          }

                          onChange({
                            ...value,
                            chips: [...value.chips, newChipValue],
                            inputValue: "",
                          });
                        }
                      }}
                      chipDisplayValueFunction={chipDisplayValueFunction}
                      chipColorFunction={(chip: User | string) => {
                        if (typeof chip === "string" || !chip.isMember) {
                          return "default";
                        }
                        return "primary";
                      }}
                      name={DomainConstants.key.host}
                      label={DomainConstants.label.host}
                      description={`Enter a single ${DomainConstants.key.host}, either existing user from the dropdown or enter a guest user`}
                    />

                    {error && (
                      <span className={styles.error}>{error.message}</span>
                    )}
                  </>
                );
              }}
            />
          </Grid>

          <Grid container item>
            <Controller
              name={DomainConstants.key.participants}
              control={control}
              rules={{
                validate: {
                  all: (value) => {
                    const { inputValue, chips } = value;

                    // allow no chips since this is an optional field
                    if (chips.length === 0 && inputValue === "") {
                      return true;
                    }

                    // if we have a least 1 chip then we need to check the contents of those chips for validation errors
                    if (chips.length !== 0) {
                      const hostFormValue = getValues(DomainConstants.key.host);

                      const hostUser = hostFormValue.chips[0];
                      if (hostUser) {
                        const hostUserEmail =
                          typeof hostUser === "string"
                            ? hostUser
                            : hostUser.email;

                        // check if host's email is found in the list of participants
                        const foundHostInParticipants = chips.find((chip) => {
                          const participantEmail =
                            typeof chip === "string" ? chip : chip.email;

                          return hostUserEmail === participantEmail;
                        });
                        if (foundHostInParticipants) {
                          return `${DomainConstants.label.host} cannot also be a panelist`;
                        }
                      }
                    }
                    // otherwise if there are chips then if its empty its okay
                    if (chips.length !== 0 && inputValue === "") {
                      return true;
                    }

                    if (!isValidEmail(inputValue)) {
                      // if the trimmed version of the email is valid then dont throw the error
                      // the onBlur trims the value and creates the chip if its a guest user (autocomplete selection creates a User chip)
                      if (isValidEmail(inputValue.trim())) {
                        return true;
                      }

                      return INVALID_EMAIL_MESSAGE;
                    }

                    return true;
                  },
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <ChipInput
                      maxChipsCount={MAX_PARTICIPANTS_SELECTION_LIMIT}
                      data-test-id={DomainConstants.key.participants}
                      error={error?.message}
                      placeholderLabel="Enter user emails"
                      autocomplete={{
                        autocompleteValues: usersAutocompleteDataForParticipants
                          ? usersAutocompleteDataForParticipants.content
                          : [],
                        getOptionLabel: (option: User | string) => {
                          if (typeof option === "string") {
                            return option;
                          } else {
                            return `${option.firstName} ${option.lastName} (${option.email})`;
                          }
                        },
                      }}
                      chips={value.chips}
                      // the setChips function gets called inside the ChipInput and it simply passes in all the current chips
                      // as an array, so User elements are the ones the user clicked on in the autocomplete dropdown, and the strings are the customly entered ones
                      setChips={(newValue: (User | string)[]) => {
                        onChange({
                          ...value,
                          chips: [...newValue],
                          inputValue: "",
                        });
                      }}
                      inputValue={value.inputValue.trim()}
                      // setInputValue function sends in the user's custom values they are typing in
                      setInputValue={(newValue: string) => {
                        onChange({
                          ...value,
                          inputValue: newValue,
                        });
                      }}
                      onBlur={() => {
                        // so the user might type in an email then instead of clicking on an option in the dropdown, they might click away
                        // expecting the chip input to turn into a chip but that doesn't happen automatically
                        // so we handle that ourselves in this function

                        // only turn a value into a chip if there isn't an error and if the user entered something
                        if (!error && value.inputValue !== "") {
                          let newChipValue;

                          // if we have the autocomplete list of users then we can find the real user to populate the chip with
                          // otherwise we'll just enter the valid email they typed in
                          if (usersAutocompleteDataForParticipants) {
                            const users =
                              usersAutocompleteDataForParticipants.content;
                            // we have to actually find the user object given what the user typed in
                            const user = users.find(
                              (userValue) =>
                                userValue.email === value.inputValue.trim()
                            );

                            // if we found the user then put that in the list of chips
                            // if we dont have the user autocomplete values then just store the entered text
                            newChipValue = user || value.inputValue.trim();
                          } else {
                            newChipValue = value.inputValue.trim();
                          }

                          onChange({
                            ...value,
                            chips: [...value.chips, newChipValue],
                            inputValue: "",
                          });
                        }
                      }}
                      chipDisplayValueFunction={chipDisplayValueFunction}
                      chipColorFunction={(chip: User | string) => {
                        if (typeof chip === "string" || !chip.isMember) {
                          return "default";
                        }
                        return "primary";
                      }}
                      name={DomainConstants.key.participants}
                      label={DomainConstants.label.participants}
                      description="Enter up to 3 panelists, separated by the enter key"
                    />

                    {error && (
                      <span className={styles.error}>{error.message}</span>
                    )}
                  </>
                );
              }}
            />
          </Grid>

          <Grid container item>
            <Controller
              name={DomainConstants.key.customTags}
              control={control}
              rules={{
                validate: {
                  all: (value) => {
                    const { inputValue, chips } = value;

                    // Dont need an error for blank because field isn't required and the MUI chip input wont
                    // let user enter empty tags already
                    if (inputValue === "") {
                      return null;
                    }
                    // once there are the max number of chips the user can't enter stuff anyway so clear errors
                    if (chips.length === MAX_CUSTOM_TAGS_SELECTION_LIMIT) {
                      return null;
                    }

                    if (
                      // The "!" is due that the "test" method returns "true" if the text meets the RegEx constraints,
                      // thus, must change it to "false" so the error message isn't shown
                      !testTextWithCustomRegex(
                        inputValue,
                        ALPHA_NUMERIC_AND_SPECIAL_CHARACTERS_REGEX
                      )
                    ) {
                      return ALLOWED_SPECIAL_CHARACTERS_MESSAGE;
                    }

                    if (!isLessThanOrEqualTo(inputValue, 30)) {
                      return LENGTH_LIMIT_MESSAGE("Tags", 30);
                    }
                  },
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <ChipInput
                      maxChipsCount={MAX_CUSTOM_TAGS_SELECTION_LIMIT}
                      data-test-id={DomainConstants.key.customTags}
                      error={error?.message}
                      placeholderLabel="Enter custom tags"
                      autocomplete={{
                        autocompleteValues: [],
                        getOptionLabel: () => {
                          return;
                        },
                      }}
                      chips={value.chips}
                      setChips={(newValue: string[]) => {
                        onChange({
                          ...value,
                          chips: [...newValue],
                          inputValue: "",
                        });
                      }}
                      inputValue={value.inputValue}
                      // setInputValue function sends in the user's custom values they are typing in
                      setInputValue={(newValue: string) => {
                        onChange({
                          ...value,
                          inputValue: newValue,
                        });
                      }}
                      onBlur={() => {
                        // the user might type text and then click away instead of hitting enter (which would make the text into a chip)
                        // so on blur we want to see if can automatically turn it into a chip if its valid
                        if (!error && value.inputValue !== "") {
                          onChange({
                            ...value,
                            chips: [...value.chips, value.inputValue],
                            inputValue: "",
                          });
                        }
                      }}
                      chipDisplayValueFunction={(text: string) => text}
                      name={DomainConstants.key.customTags}
                      label={
                        <Grid
                          container
                          item
                          direction="row"
                          alignItems="center"
                        >
                          <Typography variant="h6">Custom Tags</Typography>

                          <PopoverButton
                            renderButton={(onClick) => {
                              return (
                                <IconButton onClick={onClick}>
                                  <InfoOutlinedIcon />
                                </IconButton>
                              );
                            }}
                            content="Add custom tags or keywords (eg. MEDED2021) to organize and categorize your event information!"
                          />
                        </Grid>
                      }
                      description="Enter up to 5 optional tags, separated by hitting the enter key"
                    />

                    {error && (
                      <span className={styles.error}>{error.message}</span>
                    )}
                  </>
                );
              }}
            />
          </Grid>

          {mode === "EDIT" ? (
            <Grid item container>
              <div className={styles.copyLink}>
                <TextField
                  data-test-id="eventLink"
                  className={styles.linkText}
                  label={DomainConstants.label.eventLink}
                  value={event.eventLink}
                  disabled
                />
                <Button onClick={copyLink} variant="contained" theme="primary">
                  <LinkIcon />
                  <span>Copy Link</span>
                </Button>
              </div>
            </Grid>
          ) : null}

          <Grid direction="row" container item justifyContent="flex-end">
            <Button
              variant="text"
              theme="primary"
              onClick={() => history.push("/events")}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              theme="tertiary"
              type="submit"
              data-test-id="eventSaveButton"
              loading={formState.isSubmitting}
              disabled={!formState.isValid || formState.isSubmitting}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        {/* empty grid item so we only get the form components above only displaying on half width of the page */}
        <Grid lg={6} item />
      </Grid>
    </form>
  );
};
