/**
 * Wraps Material UI ToolTip to use Tungsten design system
 */
import React from "react";

import clsx from "clsx";

import { Tooltip as MUIToolTip, TooltipProps } from "@mui/material";

import { Font } from "src/components/Font";

import styles from "./styles.scss";

export interface Props extends TooltipProps {
  "data-test-id"?: string;
  color?: "black" | "blue" | "red" | "gray";
}

export const Tooltip = (props: Props) => {
  return (
    <MUIToolTip
      classes={{
        tooltip: clsx(styles.root, styles.tooltip, styles[props.color]),
      }}
      data-test-id={props["data-test-id"]}
      title={
        <React.Fragment>
          <Font variant="button" color="light" children={props.title} />
        </React.Fragment>
      }
      {...props}
    />
  );
};
