import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API } from "../../../api";
import { logger } from "../../../common/logger";
import { AVAIL_ACCESS_TOKEN_TIME_MINS } from "../../../constants";
import { AppState, PortalIdentity, UserState } from "../../models";
import { updateAccessToken } from "../actions";
import { UserActionKeys } from "../actionTypes";

export function* refreshAccessTokenSaga() {
  const ourSaga = "Avail access token";
  const ONE_MINUTE_IN_MSEC = 60 * 1000;
  const RETRY_DELAY_MINS = 1;
  let retry: boolean = false;

  while (true) {
    let minutesDownCount: number = retry
      ? RETRY_DELAY_MINS
      : AVAIL_ACCESS_TOKEN_TIME_MINS;
    logger().info(
      `refreshAccessTokenSaga -- Starting timer (t=${minutesDownCount})`
    );

    do {
      yield delay(ONE_MINUTE_IN_MSEC);
      minutesDownCount--;
      if (minutesDownCount % 5 === 0) {
        logger().verbose(`${ourSaga} time remaining: ${minutesDownCount}`);
      } else if (minutesDownCount < 10) {
        logger().warn(
          `${ourSaga} refresh imminent ( starts in ${minutesDownCount} )`
        );
      }
    } while (minutesDownCount > 0);

    logger().info("refreshAccessTokenSaga --  Requesting token refresh");
    const { identity }: UserState = yield select(
      (state: AppState) => state.user
    );

    const { data, error } = yield call(
      API.POST.identity,
      identity.refresh_token
    );
    if (error) {
      logger().error(
        `Error calling Identity API: ${error} with refreshToken: ${identity.refresh_token}`
      );
      retry = true;
    } else {
      const newIdentity = data as PortalIdentity;
      logger().info(
        `Obtained new Access Token \n${newIdentity.access_token.substring(
          0,
          10
        )}...`
      );
      retry = false;
      yield put(updateAccessToken(newIdentity));
    }
  }
}

export function* availAccessTokenTimer() {
  yield takeLatest(
    UserActionKeys.UPDATE_ACCESS_TOKEN_TIMER,
    refreshAccessTokenSaga
  );
}
