import { RootState } from "src/domains/Beacon/store";
import { AppDispatch } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleLayoutChangeThunk } from "src/domains/Beacon/store/stream/thunks/handleLayoutChangeThunk";
import { logger } from "src/logging/logger";

interface IParams {
  externalInputsImageResizing: boolean;
}

// Restores the layouts based on snapshot state, this is it since stopping screen sharing
// the snapshot init is outdated and if user has manipulated the opposite camera while using TPI,
// the cameras won't persist
export const resetLayoutsSnapshotThunk = ({
  externalInputsImageResizing,
}: IParams) => (dispatch: AppDispatch, getState: () => RootState) => {
  logger().info("Restoring layouts from snapshot...");

  try {
    const {
      stream: { layoutFramesSnapshot },
    } = getState();

    // Must restore the cameras from snapshot
    dispatch(
      handleLayoutChangeThunk({
        externalInputsImageResizing,
        newLayoutFrames: layoutFramesSnapshot,
      })
    );
    // Reset the layouts list selection based on the latest snapshot
    dispatch(streamActions.setLayoutFrames(layoutFramesSnapshot));
  } catch (error: any) {
    logger().error(
      `Error while restoring layouts from snapshot.`,
      error?.message
    );
  }
};
