import Twilio, {
  LocalAudioTrack,
  LocalTrackPublication,
  LocalVideoTrack,
} from "twilio-video";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/domains/Beacon/store";
import { AppDispatch } from "src/domains/Beacon/store";
import { LoggerLevels, logger } from "src/logging/logger";

export const publishLocalMediaTracksThunk = createAsyncThunk<
  // Return type of the payload creator, we return nothing so void
  LocalTrackPublication[],
  // First argument to the payload creator, no args so void
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("stream/publishLocalMediaTracksThunk", async (_, { getState }) => {
  logger().info("** Publishing local media tracks **");

  try {
    const { stream, twilio, ui, meeting } = getState();
    const { isUserHost } = meeting;

    const {
      localMedia: { camera, microphone },
      screenShareTrack,
    } = stream;
    const {
      room: { localParticipant },
    } = twilio;
    const {
      microphone: micState,
      pip: { showVideo },
    } = ui;

    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: "publishLocalMediaTracksThunk",
        feature: "Local Media Tracks",
      },
      `Creating LocalMediaTracks using microphone device: ${JSON.stringify(
        microphone ?? "null"
      )} and camera device: ${JSON.stringify(camera ?? "null")}`
    );

    const localMediaTracks = await Twilio.createLocalTracks({
      audio: true,
      video:
        isUserHost && !screenShareTrack ? { deviceId: camera.deviceId } : false,
    });

    const tracks = Array.from(localParticipant.tracks?.values() ?? []).map(
      (trackPublication) => trackPublication.track
    );

    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: "publishLocalMediaTracksThunk",
        feature: "Local Media Tracks",
      },
      `Un-publishing existing tracks: ${JSON.stringify(tracks ?? "null")}`
    );

    await localParticipant.unpublishTracks(tracks);

    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: "publishLocalMediaTracksThunk",
        feature: "Local Media Tracks",
      },
      `localMediaTracks to be published to Twilio: ${JSON.stringify(
        localMediaTracks ?? "null"
      )}`
    );

    // Must check first the mic & camera state in order to disable or not the track
    localMediaTracks.forEach((track) => {
      if (track instanceof LocalAudioTrack) {
        if (micState.muted) {
          logger().logWithFields(
            LoggerLevels.info,
            {
              fileInfo: "publishLocalMediaTracksThunk",
              feature: "Local Media Tracks",
            },
            `Microphone audio is currently muted, disabling it before publishing to Twilio`
          );
          track.disable();
        } else {
          logger().logWithFields(
            LoggerLevels.info,
            {
              fileInfo: "publishLocalMediaTracksThunk",
              feature: "Local Media Tracks",
            },
            `Microphone audio is currently active, enabling it before publishing to Twilio`
          );
          track.enable();
        }
      }

      if (track instanceof LocalVideoTrack) {
        if (!showVideo) {
          logger().logWithFields(
            LoggerLevels.info,
            {
              fileInfo: "publishLocalMediaTracksThunk",
              feature: "Local Media Tracks",
            },
            `Camera video is currently muted, disabling it before publishing to Twilio`
          );
          track.disable();
        } else {
          logger().logWithFields(
            LoggerLevels.info,
            {
              fileInfo: "publishLocalMediaTracksThunk",
              feature: "Local Media Tracks",
            },
            `Camera video is currently active, enabling it before publishing to Twilio`
          );
          track.enable();
        }
      }
    });

    // Must return the published local Media tracks, otherwise, the action thunk will
    // be undefined by the time it haven't finished
    const localMediaTracksPublished = await localParticipant.publishTracks(
      localMediaTracks
    );

    // If there was a refreshFrames, we must persist the screenShare tracks if user is the Host
    if (isUserHost && screenShareTrack) {
      logger().info(
        "** Persisting ScreenShare Tracks from publishLocalMediaTracksThunk **"
      );
      await localParticipant.publishTrack(screenShareTrack);
    }

    logger().info(
      `Local media tracks published successfully to Twilio: ${JSON.stringify(
        localMediaTracksPublished ?? "null"
      )}`
    );

    return localMediaTracksPublished;
  } catch (error: any) {
    logger().error(
      `Error while publishing local media tracks.`,
      error?.message
    );
    throw error;
  }
});
