import React,{Fragment}from 'react';
import { connect } from 'react-redux';
import { newlogo2021 } from '../../../../../../Common/Assets/Images';
import MultiProfileLoginForm from './components/MultiProfileLoginSelect/MultiProfileLoginSelect';
import { Button } from '../../../../../../Common/Components';
import { logoutAction } from '../../../../AuthenticationActions';
import './MultiProfileLogin.scss';

import { LoginPage } from "/src/domains/Login";

function MultiProfileLogin({ user, history, isTwoFactorEnabled, logout }) {
	const redirect = () => {
		logout();
		history.push('/login');
	};

	return (
		<LoginPage className="login" >
      <div className="login__subheading">Choose the identity you want to use for this session.</div>
      {user && <MultiProfileLoginForm profiles={user.profiles} isTwoFactorEnabled={isTwoFactorEnabled} />}
      <Button
        isFull
        isGreen
        className="back-to-login__button"
        onClick={redirect}
      >
        Back to login
      </Button>
		</LoginPage>
	);
}

MultiProfileLogin.propTypes = {};

const mapStateToProps = state => ({
  isTwoFactorEnabled: state.homeReducer.isTwoFactorEnabled,
	user: state.homeReducer.currentUser,
});

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiProfileLogin);
