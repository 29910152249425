import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { elementType } from '../../../../Common/Constants/elementType';
/* Styles */
import './DropdownComponent.scss';
import { Icon } from '../../../../Common/Components';

class DropdownComponent extends PureComponent {
	renderItem = item => (
		<Dropdown.Item
			key={item.label}
			text={item.label}
			onClick={item.action}
			className={classNames('dropdown__menu-item', {
				[`dropdown__menu-item--${item.type || elementType.PRIMARY}`]: true,
			})}
			disabled={item.disabled}
		/>
	);

	renderOptions = () => (
		this.props.dropdownOptions?.map(option => {
      const { IconComponent, } = option;
      return {
        key: option.label,
        disabled: option.disabled,
        value: option.label,
        onClick: !option.disabled && option.action,
        content: (
          <div className={`${option.className} dropdown__menu-item-wrapper`}>
            {option.icon && <Icon className={`dropdown__menu-item__icon ${option.icon}`} />}
            {IconComponent && <IconComponent className="dropdown__menu-item__icon" />}
            <span className="dropdown__menu-item__text">{option.text}</span>
          </div>
        ),
      };
		})
	);

	render() {
		return (
			<Dropdown
				icon={<Icon className="icon-more-vertical-outline" />}
				pointing={this.props.dropDownPosition}
				disabled={this.props.dropdownOptions.length === 0}
				className={`dropdown__menu ${this.props.className}`}
				direction="left"
				options={this.renderOptions()}
			/>
		);
	}
}

DropdownComponent.defaultProps = {
	dropDownPosition: 'top left',
	icon: null,
};

DropdownComponent.propTypes = {
	icon: PropTypes.shape({}),
	dropdownOptions: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		action: PropTypes.func,
	})).isRequired,
	dropDownPosition: PropTypes.string,
};

export default DropdownComponent;
