import { HOSPITAL_NETWORK } from './HospitalNetworkActionTypes';

const initialState = {
	pagination: {
		page: 0,
		size: 10,
		totalPages: 1,
	},
	hospitalNetworks: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case HOSPITAL_NETWORK.SET_ALL_HOSPITAL_NETWORK:
			return {
				...state,
				hospitalNetworks: action.payload,
			};
		case HOSPITAL_NETWORK.SET_HOSPITAL_NETWORK_PAGINATION:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload.pageNumber,
					size: action.payload.pageSize,
					totalPages: action.payload.totalPages,
				},
			};
		default:
			return state;
	}
}
