import { useEffect, useMemo, useRef, useState } from "react";

import clsx from "clsx";

import { Avatar } from "src/components/Avatar";
import { Font } from "src/components/Font";
import { useGetHostUser } from "src/domains/Beacon/hooks/useGetHostUser";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectIsUserHost } from "src/domains/Beacon/store/meeting/selectors";
import {
  selectStreamState,
  selectVideoTrackState,
} from "src/domains/Beacon/store/stream/selectors";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { selectShowPipVideo } from "src/domains/Beacon/store/ui/selectors";
import { isTPITrackActive } from "src/domains/Beacon/utils/tpi";
import { useEventListener } from "src/hooks/useEventListener";
import { LoggerLevels, logger } from "src/logging/logger";

import styles from "./styles.scss";

/**
 * Will take care of displaying the video, exactly what MediaTrack does but
 * only for video, there must be an Audio component for audio from MediaTrack
 */
interface IProps {
  id?: string;
  width?: string;
  height?: string;
  className?: string;
  videoClassName?: string;
  videoStream?: MediaStream; // if passed, the stream will come from the local user's camera device
  "data-test-id"?: string;
}

export const Camera = ({
  id,
  width = "279px",
  height = "163px",
  className,
  videoClassName,
  videoStream,
  "data-test-id": dataTestId,
}: IProps) => {
  const [showAvatar, setShowAvatar] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>();
  // Must use a ref to use it inside event listeners, elements events can't read latest state
  // must use refs for this
  const tpiTrackActiveRef = useRef<boolean>();
  const showAvatarRef = useRef<boolean>(showAvatar);

  const { mpRemoteTracks } = useAppSelector(selectStreamState);
  const { hostHasJoinedRoom } = useAppSelector(selectTwilioState);
  const twilioVideoTrack = useAppSelector(selectVideoTrackState);
  const isHostUser = useAppSelector(selectIsUserHost);
  // Will force the Host's video to reload if Host mutes/un-mutes its video
  const isVideoShown = useAppSelector(selectShowPipVideo);
  const host = useGetHostUser();

  // When the twilioVideoTrack name changes, means that Twilio has publish the video
  // already, otherwise, the twilioVideoTrack is still the previous one
  useEffect(() => {
    // The local Twilio video track is only for the Host, must check first if
    // current participant is the host to use it, otherwise, Panelists will always
    // see the Host's initials due that Twilio video track is always `isEnabled = false`
    if (!isHostUser) {
      return;
    }

    if (twilioVideoTrack?.isEnabled) {
      videoRef.current.srcObject = twilioVideoTrack?.attach().srcObject;
    } else {
      videoRef.current.srcObject = null;
    }
  }, [twilioVideoTrack?.name, twilioVideoTrack?.isEnabled, isVideoShown]);

  // Detects when the host changes tracks and user is a Panelist
  useEffect(() => {
    // Must check first if user is Panelist, otherwise, the `srcObject` will always be null for the Host
    // and its Avatar initials will be shown.
    // This is the similar as above ^ but for Panelists, since they don't have the `twilioVideoTrack`
    // they must get the Host's video track from the state instead
    if (isHostUser) {
      return;
    }

    if (mpRemoteTracks.host.video?.isEnabled) {
      videoRef.current.srcObject = mpRemoteTracks.host?.video?.attach().srcObject;
    } else {
      videoRef.current.srcObject = null;
    }
  }, [mpRemoteTracks.host.video, mpRemoteTracks.host.video?.isEnabled]);

  // Detects when a local video stream is passed to load it into the video tag
  useEffect(() => {
    if (videoStream) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream]);

  // Detects when local video stream tracks changes. This happens during the
  // PreCall or VideoSettings when user toggles the local camera on/off
  useEffect(() => {
    const tracks = videoStream?.getVideoTracks();
    const track = tracks?.[0];
    if (track && !track?.enabled) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream?.getVideoTracks()]);

  // If host's has TPI track inside video track and current user is Panelist,
  // the Panelist will not see the Host's video and should see the Avatar initials then
  const tpiTrackActive =
    !isHostUser && isTPITrackActive(mpRemoteTracks?.host?.video);

  tpiTrackActiveRef.current = useMemo(() => tpiTrackActive, [tpiTrackActive]);
  showAvatarRef.current = useMemo(() => showAvatar, [showAvatar]);

  // This is fired whenever we set a new `srcObject` to the video element,
  // meaning that there's a video stream data available to display
  useEventListener(
    "loadeddata",
    () => {
      logger().logWithFields(
        LoggerLevels.info,
        {
          fileInfo: `Camera`,
          feature: `Camera`,
        },
        `Host's video stream loaded with data...`
      );
      // If TPI is active inside host's video track, must show Host's initials Avatar
      if (tpiTrackActiveRef.current && !showAvatarRef.current) {
        logger().info(
          "Panelist should see the host's initials, TPI is active inside host's video track"
        );
        setShowAvatar(true);
      } else {
        logger().info("Panelist should see host's video, TPI is not active");
        setShowAvatar(false);
      }
    },
    videoRef.current
  );

  // Fired when the `srcObject` is set to null, meaning that there's no video stream data to display
  useEventListener(
    "emptied",
    () => {
      // Must check if avatar is showing and there's no srcObject, otherwise, the event will re-render the
      // component multiple times
      if (!showAvatarRef.current && !videoRef.current?.srcObject) {
        logger().logWithFields(
          LoggerLevels.info,
          {
            fileInfo: `Camera`,
            feature: `Camera`,
          },
          `Host's video stream emptied...`
        );
        // There's no video data available, must show the Avatar
        setShowAvatar(true);
      }
    },
    videoRef.current
  );

  return (
    <div
      id={id}
      key={id}
      className={clsx(styles.videoGrid, className)}
      style={{ width, height }}
      data-test-id={dataTestId}
    >
      {!hostHasJoinedRoom && !isHostUser && (
        <div className={styles.waitingMessage}>
          <Font variant="b1" textAlign="center">
            Please wait for the host to join the event.
          </Font>
        </div>
      )}
      {showAvatar && (
        <div className={styles.waitingMessage} data-test-id="camera-avatar">
          <Avatar
            color={"bright-blue"}
            label={`${host?.firstName[0]}${host?.lastName[0]}`}
          />
        </div>
      )}
      {/* If there's no TPI track active in the host's video, we should show the host's video */}
      {!tpiTrackActive && (
        <video
          ref={videoRef}
          autoPlay
          className={clsx(styles.video, videoClassName)}
          data-test-id="camera-video-tag"
        />
      )}
    </div>
  );
};
