import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './PageTitle.scss';

class PageTitle extends PureComponent {
	render() {
		return (
			<div className="page-title">
				{this.props.title}
			</div>
		);
	}
}

PageTitle.propTypes = {};

export default PageTitle;
