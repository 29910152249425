import { Button } from "src/components/Button";
import { Modal, ModalFooter, ModalHeader } from "src/components/Modal";

import styles from "./styles.scss";

interface IProps {
  open: boolean;
  errorMessage?: string;
  closeModal: () => void;
  leaveCallAction: () => void;
}

export const LeaveCallModal = ({
  open,
  closeModal,
  leaveCallAction,
  errorMessage,
}: IProps) => {
  return (
    <Modal
      open={open}
      transparentVeil={false}
      className={styles.root}
      data-test-id="leave-call-modal"
    >
      <ModalHeader
        title={errorMessage || "Are you sure you want to leave the event?"}
        data-test-id="leave-call-header"
        closeModal={closeModal}
        className={styles.header}
      />
      <ModalFooter>
        <Button
          theme="red"
          data-test-id="leave-call-button"
          onClick={leaveCallAction}
          label="Leave event"
        />
      </ModalFooter>
    </Modal>
  );
};
