import { HOSPITAL_NETWORK } from './HospitalNetworkActionTypes';

export const getAllHospitalNetworksAction = params => ({
	type: HOSPITAL_NETWORK.GET_ALL_HOSPITAL_NETWORK,
	pagination: params,
});

export const createNewHospitalNetworkAction = hospitalNetworkData => ({
	type: HOSPITAL_NETWORK.CREATE_NEW_HOSPITAL_NETWORK,
	payload: hospitalNetworkData,
});

export const deleteHospitalNetworkAction = id => ({
	type: HOSPITAL_NETWORK.DELETE_HOSPITAL_NETWORK,
	payload: id,
});
