import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
/* Components */
import TableBody from './Components/TableBody/TableBody';
import NoSearchResult from '../NoSearchResult/NoSearchResult';
/* Icons & Styles */
import './Table.scss';
import TableHeader from './Components/TableHeader/TableHeader';
import TableFooter from "./Components/TableFooter/TableFooter";

class Table extends PureComponent {
	onSearchTermUpdateHandler = (value, searchParameter) => this.props.onSearchTermUpdateHandler(value, searchParameter);

	onUpdateSearchTerm = (term) => { this.searchParameter = term; };

	renderNoItemsComponent = () => (this.searchParameter ? <NoSearchResult /> : (
		<div
			className={classNames({
				[this.props.noItemsClass]: this.props.noItemsClass,
				'justify-content--space-between': true,
			})}
		>
			{this.props.noEntityComponent}
			{this.props.headerOptionButton}
		</div>
	));

	render() {
		const {
			cdrExport,
			onCdrExportHandler,
			onAddCdrExportTitle,
			userTypes,
			currentUserTypes,
			className,
			columns,
			nameOverride,
			onPageChangeHandler,
			onSortTableHandler,
			openModal,
			openReinvitationModal,
			paginationConfig,
			row,
			sortable,
			tableData,
			search,
			headerOptionButton,
			urlParams,
			tableHeaderClassName,
			tableActions,
			onAddNewHandler,
      disableAddButton,
			onAddExportTitle,
			onExportHandler,
			exportUsersData,
			headerTitle,
			onAddNewTitle,
			permissions,
			consoleData,
			isHospital,
			variablePagination,
			onVariablePaginationChange,
		} = this.props;
		const noTableData = tableData && tableData.length > 0;
		return (
			<div className="height--full flex-direction--column table__container">
				{
					!cdrExport &&
					(
						consoleData === undefined ?
						(
							<TableHeader
								userTypes={userTypes}
								currentUserTypes={currentUserTypes}
								columns={columns}
								onUpdateSearchTerm={term => this.onUpdateSearchTerm(term)}
								nameOverride={nameOverride}
								headerTitle={headerTitle}
								onAddNewHandler={onAddNewHandler}
								onAddNewTitle={onAddNewTitle}
								onSearchTermUpdateHandler={this.onSearchTermUpdateHandler}
								search={search}
								permissions={permissions}
                disableAddButton={disableAddButton}
							/> 
						) :
						(
							<TableHeader
								headerTitle={headerTitle}
								onAddNewHandler={onAddNewHandler}
								onAddNewTitle={onAddNewTitle}
								columns={columns}
								onUpdateSearchTerm={term => this.onUpdateSearchTerm(term)}
								onSearchTermUpdateHandler={this.onSearchTermUpdateHandler}
								search={search}
								consoleData={consoleData}
								currentUser={this.props.currentUser}
								permissions={permissions}
                disableAddButton={disableAddButton}
							/>
						)
					)
				}
				{
					consoleData === undefined ?
						tableData && (
							noTableData ? (
								<div className="height--full justify-content--space-between flex-direction--column">
									<div className="table__wrapper">
										<TableBody
											row={row}
											columns={columns}
											tableData={tableData}
											openModal={openModal}
											openReinvitationModal={openReinvitationModal}
											className={className}
											onSortTableHandler={onSortTableHandler}
											sortable={sortable}
											headerOptionButton={headerOptionButton}
											urlParams={urlParams}
											tableHeaderClassName={tableHeaderClassName}
											tableActions={tableActions}
											isHospital={isHospital}
										/>
									</div>
									<TableFooter
										cdrExport = {cdrExport}
										onCdrExportHandler={onCdrExportHandler}
										onAddCdrExportTitle={onAddCdrExportTitle}
										exportUsersData={exportUsersData} 
										onExportHandler={onExportHandler} 
										onAddExportTitle={onAddExportTitle} 
										paginationConfig={paginationConfig} 
										consoleData={consoleData} 
										currentUser={this.props.currentUser} 
										permissions={permissions} 
										onPageChangeHandler={onPageChangeHandler}
										variablePagination={variablePagination}
										onVariablePaginationChange={onVariablePaginationChange}
									/>
								</div>
							)
								: this.renderNoItemsComponent()
						)
						:
						(
							<div className="height--full justify-content--space-between flex-direction--column">
								<div className="table__wrapper">
									<TableBody
										row={row}
										columns={columns}
										tableData={tableData}
										openModal={openModal}
										openReinvitationModal={openReinvitationModal}
										className={className}
										onSortTableHandler={onSortTableHandler}
										sortable={sortable}
										headerOptionButton={headerOptionButton}
										urlParams={urlParams}
										tableHeaderClassName={tableHeaderClassName}
										tableActions={tableActions}
										isHospital={isHospital}
									/>
								</div>
								<TableFooter
									cdrExport = {cdrExport}
									onCdrExportHandler={onCdrExportHandler}
									onAddCdrExportTitle={onAddCdrExportTitle}
									exportUsersData={exportUsersData} 
									onExportHandler={onExportHandler} 
									onAddExportTitle={onAddExportTitle} 
									paginationConfig={paginationConfig} 
									consoleData={consoleData} 
									currentUser={this.props.currentUser} 
									permissions={permissions} 
									onPageChangeHandler={onPageChangeHandler}
									variablePagination={variablePagination}
									onVariablePaginationChange={onVariablePaginationChange}
								/>
							</div>

						)
				}
			</div>
		);
	}
}

Table.defaultProps = {
	className: '',
	columns: [],
  disableAddButton: false,
	onPageChangeHandler: () => {},
	onSortTableHandler: () => {},
	openModal: () => {},
	openReinvitationModal: () => {},
	paginationConfig: null,
	row: null,
	search: false,
	sortable: false,
	tableData: [],
	variablePagination: false,
};

Table.propTypes = {
	className: PropTypes.string,
	columns: PropTypes.arrayOf(PropTypes.any),
  disableAddButton: PropTypes.bool,
	onPageChangeHandler: PropTypes.func,
	onSortTableHandler: PropTypes.func,
	openModal: PropTypes.func,
	openReinvitationModal: PropTypes.func,
	paginationConfig: PropTypes.shape({}),
	row: PropTypes.shape({}).isRequired || PropTypes.func.isRequired,
	search: PropTypes.bool,
	sortable: PropTypes.bool,
	tableData: PropTypes.arrayOf(PropTypes.any),
	variablePagination: PropTypes.bool,
};

export default Table;
