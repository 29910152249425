import classNames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './Pill.scss';

class Pill extends PureComponent {
	render() {
		return (
			<div
				className={classNames(
					'table__user-type ',
					{
						[`${this.props.class}`]: true,
						'table__user-type--inactive': this.props.isDisabled,
					},
				)}
			>
				{this.props.title}
			</div>
		);
	}
}

Pill.defaultProps = {
	class: '',
	isDisabled: false,
};

Pill.propTypes = {
	class: PropTypes.string,
	isDisabled: PropTypes.bool,
};

export default Pill;
