import { ACCOUNT_CREATION } from './AccountCreationActionTypes';

export const finalizeAccountSetupAction = finishAccountCreation => ({
	type: ACCOUNT_CREATION.FINALIZE_ACCOUNT_SETUP,
	finishAccountCreation,
});

export const getAllTimezonesAction = () => ({
	type: ACCOUNT_CREATION.GET_ALL_TIMEZONES,
});
