export const BILLING = {
	CREATE_PLAN: 'BILLING_CREATE_PLAN',
	UPDATE_PLAN: 'BILLING_UPDATE_PLAN',
	SET_VENDOR_PLAN: 'BILLING_SET_VENDOR_PLAN',
	GET_PLAN_BY_VENDOR_ID: 'BILLING_GET_PLAN_BY_VENDOR_ID',
	SET_PLAN_FOR_VENDOR: 'BILLING_SET_PLAN_FOR_VENDOR',
	CREATE_SUBSCRIPTION: 'BILLING_CREATE_SUBSCRIPTION',
	SET_VENDOR_SUBSCRIPTION: 'BILLING_SET_VENDOR_SUBSCRIPTION',
	GET_INVOICES_BY_VENDOR_ID: 'BILLING_GET_INVOICES_BY_VENDOR_ID',
	SET_VENDOR_INVOICES: 'BILLING_SET_VENDOR_INVOICES',
	GET_USAGE_INFO_BY_VENDOR_ID: 'BILLING_GET_USAGE_INFO_BY_VENDOR_ID',
	SET_USAGE_INFO_BY_VENDOR_ID: 'BILLING_SET_USAGE_INFO_BY_VENDOR_ID',
};
