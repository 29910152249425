import resources from "../../../../Common/Constants/resources";
import { roles } from "../../../../Common/Constants/userTypes";
import {
	securityService,
	userSessionService,
} from "../../../../Common/Services";
import ApiHelper from "../../../../Common/Services/ApiHelper";
import arrayService from "../../../../Common/Services/arrayService";
import notificationService from "../../../../Common/Services/notificationService";
import urlService from "../../../../Common/Services/urlService";
import { AUTH } from "../../../Authentication/AuthenticationActionTypes";
import { logoutAction } from "../../../Authentication/AuthenticationActions";
import { HOME } from "../../HomeActionTypes";
import { USERS } from "./UsersActionTypes";
import { push } from "connected-react-router";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

export function* getUserTypes() {
	try {
		const response = yield call(
			ApiHelper.get,
			"user",
			resources.userGroups
		);
		yield put({
			type: USERS.SET_USER_TYPES,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* getAllUsers(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"coreregistry",
			resources.users,
			action.payload
		);
		yield all([
			put({
				type: USERS.SET_USERS,
				payload: response.content,
			}),
			put({
				type: USERS.SET_USERS_PAGINATION,
				payload: response,
			}),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* employUserInOrganisation(userId, organisationId, managerId) {
	let params = {
		id: userId,
	};

	if (managerId) {
		params = {
			...params,
			managerId,
		};
	}
	yield call(
		ApiHelper.put,
		"coreregistry",
		urlService.formatString(
			resources.employUserInOrganisation,
			organisationId
		),
		params
	);
}

function* createAvailUser(userData) {
	const availOrganizationId = "DEXjJwz9GYM95orZ";
	const userPayload = { ...userData, orgId: availOrganizationId };
	const response = yield call(
		ApiHelper.post,
		"user",
		resources.users,
		userPayload
	);
	yield employUserInOrganisation(response.content.id, availOrganizationId);
}

function* createVendorUser(action, userObject) {
	let user = userObject;
	if (action.payload.usersManager) {
		user = {
			...user,
			managerId: action.payload.usersManager,
		};
	}

	// Obtain the Business Unit ID (orgId)
	const currentUserId = yield select(
		(state) => state.homeReducer.currentUser.id
	);
	let businessUnitId;
	if (!action.payload.businessUnitId) {
		const userOrganisation = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.userOrganisation, currentUserId)
		);
		businessUnitId = userOrganisation.content[0].id;
	} else {
		// eslint-disable-next-line prefer-destructuring
		businessUnitId = action.payload.businessUnitId;
	}

	user = { ...user, orgId: businessUnitId };
	const userResponse = yield call(
		ApiHelper.post,
		"user",
		resources.users,
		user
	);

	yield employUserInOrganisation(
		userResponse.content.id,
		businessUnitId,
		action.payload.usersManager
	);
}

function* createProctorSpecialistUser(action, userObject) {
	let user = userObject;

	if (action.payload.usersManager) {
		user = {
			...user,
			managerId: action.payload.usersManager,
		};
	}

	if (userObject.userType.includes("PROCTOR")) {
		user = { ...user, orgId: action.payload.businessUnitId };
		const userResponse = yield call(
			ApiHelper.post,
			"user",
			resources.users,
			user
		);
		yield employUserInOrganisation(
			userResponse.content.id,
			action.payload.businessUnitId,
			action.payload.usersManager
		);
	} else if (userObject.userType.includes("SPECIALIST")) {
		user = { ...user, orgId: action.payload.providerOrganisationId };
		const userResponse = yield call(
			ApiHelper.post,
			"user",
			resources.users,
			user
		);
		yield employUserInOrganisation(
			userResponse.content.id,
			action.payload.providerOrganisationId
		);
	} else {
		throw new Error("Error creating proctor/HCP");
	}
}

function* createHospitalUser(action, user) {
	const hospitalId = action.payload.businessUnitId
		? action.payload.businessUnitId
		: yield select((state) => state.usersReducer.userOrganisation.id);

	const userResponse = yield call(ApiHelper.post, "user", resources.users, {
		...user,
		orgId: hospitalId,
	});
	yield employUserInOrganisation(
		userResponse.content.id,
		hospitalId,
		action.payload.usersManager
	);
}

function* createIndividualSalesRep(action, user) {
	yield call(ApiHelper.post, "user", resources.users, {
		...user,
		orgId: user.email,
	});
}

export function* createUser(action) {
	const { userType, email, firstName, lastName } = action.payload;

	const userObject = {
		firstName,
		lastName,
		email,
		userType,
	};

	yield put({
		type: HOME.SET_IS_BUTTON_CLICKABLE,
	});

	try {
		switch (userType) {
			case roles.availAdmin: {
				yield createAvailUser(userObject);
				break;
			}
			case roles.proctor:
			case roles.specialist: {
				yield createProctorSpecialistUser(action, userObject);
				break;
			}
			case roles.vendorAdmin:
			case roles.vendorManager:
			case roles.vendorSalesRep: {
				yield createVendorUser(action, userObject);
				break;
			}
			case roles.hospitalAdmin: {
				yield createHospitalUser(action, userObject);
				break;
			}
			case roles.individualSalesRep: {
				yield createIndividualSalesRep(action, userObject);
				break;
			}
			default: {
				// eslint-disable-next-line no-throw-literal
				throw "This user role is not currently available in the system";
			}
		}

		yield all([
			put(push("/users")),
			notificationService.success("You successfully created user"),
			put({ type: HOME.SET_IS_BUTTON_CLICKABLE }),
		]);
	} catch (e) {
		yield all([
			put({ type: HOME.SET_IS_BUTTON_CLICKABLE }),
			notificationService.error(e),
		]);
	}
}

export function* deleteUser(action) {
	try {
		yield call(
			ApiHelper.remove,
			"user",
			urlService.formatString(resources.user, action.payload)
		);
		const currentUsersList = yield select(
			(state) => state.usersReducer.users
		);
		const newUsersList = currentUsersList.map((user) =>
			user.employee.id === action.payload
				? {
						...user,
						employee: {
							...user.employee,
							userStatus: "DELETED",
						},
				  }
				: user
		);
		yield put({
			type: USERS.SET_USERS,
			payload: newUsersList,
		});
		yield notificationService.success("You successfully deleted user");
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* registerUser(action) {
	try {
		yield call(
			ApiHelper.putWithoutToken,
			"user",
			`accounts/${action.payload.userId}`,
			action.payload.userData
		);
		yield all([
			put({
				type: AUTH.LOGOUT_SUCCESS,
			}),
			securityService.logout(),
		]);

		yield all([
			notificationService.success("You successfully registered"),
			put(push("/login")),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* getUserByToken(action) {
	try {
		const response = yield call(
			ApiHelper.getWithoutToken,
			"user",
			`accounts?token=${action.payload}`
		);
		yield put({
			type: USERS.SET_USER,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* getUserById(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"user",
			urlService.formatString(resources.user, action.payload)
		);
		yield put({
			type: USERS.SET_USER,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* updateUserProfile(action) {
	try {
		const response = yield call(
			ApiHelper.put,
			"user",
			urlService.formatString(resources.user, action.userId),
			action.userData
		);
		const settingsResponse = yield call(
			ApiHelper.put,
			"user",
			urlService.formatString(resources.userSettings, action.userId),
			action.userSettings
		);
		const currentUserId = yield select(
			(state) => state.homeReducer.currentUser.id
		);
		if (action.userId === currentUserId) {
			yield put({
				type: HOME.SET_CURRENT_USER_INFO,
				payload: response.content,
			});
		}
		const setting = settingsResponse.content.type;
		const userSettings = {
			[setting]: settingsResponse.content.value,
		};
		yield all([
			put({
				type: USERS.SET_USERS_SETTINGS,
				userSettings,
			}),
			put({
				type: USERS.SET_USER,
				payload: response.content,
			}),
			notificationService.success(
				"You successfully updated user profile"
			),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updateUserSettings(action) {
	try {
		const response = yield call(
			ApiHelper.put,
			"user",
			urlService.formatString(resources.userSettings, action.userId),
			action.userSettings
		);
		if (response.content.type === "EMAIL_NOTIFICATION") {
			yield put({
				type: USERS.SET_USERS_EMAIL_SETTINGS,
				userSettings: {
					EMAIL_NOTIFICATION: action.userSettings.value,
				},
			});
			notificationService.success(
				"You successfully updated user profile"
			);
		}
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updateUserPassword(action) {
	try {
		yield call(
			ApiHelper.put,
			"user",
			urlService.formatString(resources.userPassword, action.userId),
			action.changeData
		);
		yield notificationService.success(
			"You successfully updated user password"
		);
		if (action.expired) {
			yield put(logoutAction());
		}
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* getUserOrganisation(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.userOrganisation, action.payload)
		);
		yield put({
			type: USERS.SET_USER_ORGANISATION,
			payload: response.content[0],
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* getUserSupportsOrganisation(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(
				resources.userSupportsOrganisation,
				action.payload
			)
		);
		yield put({
			type: USERS.SET_USER_SUPPORTS_ORGANISATION,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* getUserProcedures(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.userProcedures, action.payload)
		);
		yield put({
			type: USERS.SET_USER_PROCEDURES,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* updateUserProcedures(action) {
	try {
		const response = yield call(
			ApiHelper.put,
			"coreregistry",
			urlService.formatString(resources.userProcedures, action.userId),
			action.proceduresId
		);
		yield put({
			type: USERS.SET_USER_PROCEDURES,
			payload: response.content,
		});
		yield notificationService.success(
			"You successfully updated user procedures"
		);
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* getUserEmployer(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.userEmployer, action.payload)
		);
		yield put({
			type: USERS.SET_USER_ORGANISATION,
			payload: response.content[0],
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* getUserEmployerByDomain(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.domainBrand, action.payload)
		);

		yield put({
			type: USERS.SET_USER_ORGANISATION,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* assignUserProcedures(action) {
	try {
		yield call(
			ApiHelper.put,
			"coreregistry",
			urlService.formatString(resources.userProcedures, action.userId),
			action.procedures
		);
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* finishAccountSetup(action) {
	try {
		yield all([
			call(
				ApiHelper.put,
				"user",
				urlService.formatString(
					resources.userFinishAccountSetup,
					action.payload
				)
			),
			userSessionService.setAccountSetupStatus(true),
			put({
				type: HOME.GET_ACCOUNT_SETUP_STATUS,
				payload: true,
			}),
			notificationService.success(
				"You successfully finished account setup"
			),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getUserSettings(action) {
	const userId =
		action.payload && action.payload !== "usersetting"
			? action.payload
			: yield select((state) => state.homeReducer.currentUser.id);
	try {
		const response = yield call(
			ApiHelper.get,
			"user",
			urlService.formatString(resources.userSettings, userId)
		);
		const userSettings = {};
		Object.keys(response.content).forEach((key) => {
			const setting = response.content[key].type;
			if (setting === "TIME_ZONE" && action.payload === "usersetting") {
				userSettings[setting] = "usersetting";
			} else {
				userSettings[setting] = response.content[key].value;
			}
		});
		yield put({
			type: USERS.SET_USERS_SETTINGS,
			userSettings,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getAllHospitalsUserApplied(action) {
	const payloadClone = { ...action.payload };
	const userId =
		action.payload.userId !== undefined
			? action.payload.userId
			: action.payload;
	delete payloadClone.hospitalId;
	try {
		const response = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.hospitalApplications, userId)
		);
		const responseContent = response.content.map((item) => ({
			blocked: item.blocked,
			status: item.status,
			...item.hospital,
		}));
		const filteredResponse = arrayService.sortQuery(
			responseContent,
			payloadClone
		);
		yield put({
			type: USERS.SET_ALL_HOSPITALS_USER_APPLIED,
			payload: filteredResponse,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getAllAvailableHospitalsToApply(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(
				resources.availableHospitalsToApply,
				action.payload
			)
		);
		yield put({
			type: USERS.SET_ALL_AVAILABLE_HOSPITALS_TO_APPLY,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getAllApprovedHospitalsForUser(action) {
	const userId = action.payload
		? action.payload
		: yield select((state) => state.homeReducer.currentUser.id);
	try {
		const response = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.approvedHospitalsForUser, userId)
		);
		yield put({
			type: USERS.SET_ALL_APPROVED_HOSPITALS_FOR_USER,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* applyToHospital(action) {
	try {
		yield call(
			ApiHelper.post,
			"coreregistry",
			urlService.formatString(resources.applyToHospital, action.userId),
			action.hospitalIds
		);
		yield getAllHospitalsUserApplied({ payload: action.userId });
		yield all([
			notificationService.success(
				"You successfully applied to hospitals"
			),
			getAllAvailableHospitalsToApply({ payload: action.userId }),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

// For trial users there is a different endpoint we want to use when we are applying/adding hospitals
function* applyToHospitalForTrial(action) {
	try {
		yield call(
			ApiHelper.post,
			"coreregistry",
			urlService.formatString(resources.applyToHospitalForTrial, action.userId),
			action.hospitalIds
		);
		yield getAllHospitalsUserApplied({ payload: action.userId });
		yield all([
			notificationService.success(
				"You successfully applied to hospitals"
			),
			getAllAvailableHospitalsToApply({ payload: action.userId }),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getUserManager(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.myManager, action.payload)
		);

		yield put({
			type: USERS.SET_USER_MANAGER,
			payload: response.content,
		});
	} catch (e) {
		yield put({
			type: USERS.SET_USER_MANAGER,
			payload: null,
		});
	}
}

function* setUserManager(action) {
	try {
		const response = yield call(
			ApiHelper.put,
			"coreregistry",
			urlService.formatString(
				resources.assignUserManager,
				action.userId,
				action.managerId
			)
		);

		yield put({
			type: USERS.SET_USER_MANAGER,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* removeUserManager(action) {
	try {
		yield call(
			ApiHelper.put,
			"coreregistry",
			urlService.formatString(
				resources.removeUserManager,
				action.userId,
				action.managerId
			)
		);

		yield put({
			type: USERS.SET_USER_MANAGER,
			payload: {}, // response.content return is not useful, so just pass empty obj
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getAllManagersUserAppliedTo(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(
				resources.managerUserAppliedTo,
				action.payload
			)
		);
		yield put({
			type: USERS.SET_ALL_MANAGERS_USER_APPLIED_TO,
			payload: response.content,
		});
	} catch (e) {
		yield put({
			type: USERS.SET_ALL_MANAGERS_USER_APPLIED_TO,
			payload: null,
		});
	}
}

function* getAllDescendantUsersForManager(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"user",
			urlService.formatString(resources.myTeam, action.payload)
		);
		yield put({
			type: USERS.SET_ALL_DESCENDANT_USERS_FOR_MANAGER,
			payload: response.content,
		});
	} catch (e) {
		yield put({
			type: USERS.SET_ALL_DESCENDANT_USERS_FOR_MANAGER,
			payload: null,
		});
	}
}

function* removeHospitalApplication(action) {
	try {
		yield call(
			ApiHelper.put,
			"coreregistry",
			urlService.formatString(
				`users/${action.userId}/hospital/${action.hospitalId}/support`
			),
			{
				blocked: action.blockedStatus,
			}
		);
		yield all([
			getAllHospitalsUserApplied({ payload: action.userId }),
			notificationService.success(
				`You successfully ${
					action.blockedStatus ? "blocked" : "unblocked"
				} hospital`
			),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* resendInvitation(action) {
	try {
		yield call(
			ApiHelper.post,
			"user",
			urlService.formatString(resources.resendEmail, action.payload),
			{}
		);
		yield all([
			notificationService.success("You successfully sent invitation"),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* lockUser(action) {
	try {
		yield call(
			ApiHelper.put,
			"user",
			urlService.formatString(resources.lockUser, action.payload.email),
			{}
		);
		const user = yield select((state) => state.usersReducer.user);

		const lockedUser = { ...user, accountLocked: true };

		yield all([
			put({
				type: USERS.SET_USER,
				payload: lockedUser,
			}),
			notificationService.success("You successfully locked user account"),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* unlockUser(action) {
	try {
		yield call(
			ApiHelper.put,
			"user",
			urlService.formatString(resources.unlockUser, action.payload.email),
			{}
		);
		const user = yield select((state) => state.usersReducer.user);

		const unlockedUser = { ...user, accountLocked: false };
		yield all([
			put({
				type: USERS.SET_USER,
				payload: unlockedUser,
			}),
			notificationService.success(
				"You successfully unlocked user account"
			),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* setPassphrase(action) {
	try {
		const data = {
			payload: action.payload.userId,
		};
		const response = yield call(
			ApiHelper.put,
			"user",
			`users/${action.payload.userId}/passphrase_update?passphrase=${action.payload.passphraseData}`
		);
		notificationService.success(response.content.message);
		yield put({
			type: USERS.SET_PASSPHRASE_DATA,
			payload: action.payload.passphraseData,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getPassphrase(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"user",
			urlService.formatString(resources.getPassphrase, action.payload)
		);
		if (response && response.content) {
			yield put({
				type: USERS.SET_PASSPHRASE_DATA,
				payload: response.content.passphrase,
			});
		}
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* getPhoneNumber(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"user",
			urlService.formatString(resources.mfaGetPhoneNumber, action.params)
		);

		yield all([
			put({
				type: USERS.SET_PHONE_NUMBER_DATA,
				payload: response.content.phone,
			}),
			put({
				type: USERS.IS_VALID_PHONE_ADDED,
				payload: response.content.validPhone,
			}),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* addPhoneNumber(action) {
	const { loginId, phoneNumber, countryCode, channelType } = action.payload;

	try {
		const response = yield call(
			ApiHelper.get,
			"user",
			urlService.formatString(
				resources.sendVerifyMfaPhoneOtp,
				loginId,
				phoneNumber,
				countryCode,
				channelType
			)
		);

		if (response.statusCode === 429) {
			// SET_AUTH_ERROR
			yield put({
				type: USERS.SET_AUTH_ERROR,
				payload: response,
			});
		} else {
			yield all([
				put({
					type: USERS.SET_PHONE_OTP_INFO,
					payload: response.content,
				}),
				put({
					type: USERS.SET_PHONE_NUMBER_DATA,
					payload: phoneNumber,
				}),
			]);
		}
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* removePhoneNumber(action) {
	try {
		const response = yield call(
			ApiHelper.remove,
			"user",
			urlService.formatString(
				resources.mfaRemovePhoneNumber,
				action.payload
			)
		);

		yield all([
			yield put({
				type: USERS.SET_AUTH_ERROR,
				payload: null,
			}),
			put({
				type: USERS.SET_PHONE_NUMBER_DATA,
				payload: null,
			}),
			put({
				type: USERS.PHONE_VERIFICATION_SUCCESS,
				payload: null,
			}),
			put({
				type: USERS.IS_VALID_PHONE_ADDED,
				payload: false,
			}),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* resendMfaPhoneVerificationOtp(action) {
	const { loginId, mfaPhoneToken, preferenceType } = action.params;

	try {
		const response = yield call(
			ApiHelper.get,
			"user",
			urlService.formatString(
				resources.resendVerifyMfaPhoneOtp,
				loginId,
				mfaPhoneToken,
				preferenceType
			)
		);

    if (response.statusCode === 429) {
			// SET_AUTH_ERROR
			yield put({
				type: USERS.SET_AUTH_ERROR,
				payload: response,
			});
		} else {
      yield put({
			type: USERS.SET_PHONE_OTP_INFO,
			payload: response.content,
		  });
    }
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* mfaPhoneVerificationOtp(action) {
	const { loginId, mfaPhoneToken, otp } = action.params;

	try {
		const response = yield call(
			ApiHelper.post,
			"user",
			urlService.formatString(resources.mfaPhoneVerifyOTP, loginId),
			{
				mfaPhoneToken,
				otp,
			}
		);

		if (response.statusCode === 400 || 
      response.statusCode === 401 || 
      response.statusCode === 429) {
			// SET_AUTH_ERROR
			yield put({
				type: USERS.SET_AUTH_ERROR,
				payload: response,
			});
		} else {
			yield all([
				put({
					type: USERS.PHONE_VERIFICATION_SUCCESS,
					payload: response.content,
				}),
				put({
					type: USERS.IS_VALID_PHONE_ADDED,
					payload: true,
				}),
			]);
		}
	} catch (e) {
		yield notificationService.error(e);
		
	}
}

export default function* usersSaga() {
	yield takeLatest(USERS.GET_USER_TYPES, getUserTypes);
	yield takeLatest(USERS.GET_USERS, getAllUsers);
	yield takeLatest(USERS.CREATE_USER, createUser);
	yield takeLatest(USERS.DELETE_USER, deleteUser);
	yield takeLatest(USERS.GET_USER_BY_ID, getUserById);
	yield takeLatest(USERS.UPDATE_USER_PROFILE, updateUserProfile);
	yield takeLatest(USERS.GET_USER_ORGANISATION, getUserOrganisation);
	yield takeLatest(
		USERS.GET_USER_SUPPORTS_ORGANISATION,
		getUserSupportsOrganisation
	);
	yield takeLatest(USERS.REGISTER_USER, registerUser);
	yield takeLatest(USERS.GET_USER_BY_TOKEN, getUserByToken);
	yield takeLatest(USERS.GET_USER_EMPLOYER, getUserEmployer);
	yield takeLatest(
		USERS.GET_USER_EMPLOYER_BY_DOMAIN,
		getUserEmployerByDomain
	);
	yield takeLatest(USERS.ASSIGN_USER_PROCEDURES, assignUserProcedures);
	yield takeLatest(USERS.FINISH_ACCOUNT_SETUP, finishAccountSetup);
	yield takeLatest(USERS.GET_USER_PROCEDURES, getUserProcedures);
	yield takeLatest(USERS.UPDATE_USER_PROCEDURES, updateUserProcedures);
	yield takeLatest(USERS.GET_USERS_SETTINGS, getUserSettings);
	yield takeLatest(
		USERS.GET_ALL_HOSPITALS_USER_APPLIED,
		getAllHospitalsUserApplied
	);
	yield takeLatest(
		USERS.GET_ALL_AVAILABLE_HOSPITALS_TO_APPLY,
		getAllAvailableHospitalsToApply
	);
	yield takeLatest(
		USERS.GET_ALL_APPROVED_HOSPITALS_FOR_USER,
		getAllApprovedHospitalsForUser
	);
	yield takeLatest(USERS.APPLY_TO_HOSPITAL, applyToHospital);
	yield takeLatest(USERS.APPLY_TO_HOSPITAL_FOR_TRIAL, applyToHospitalForTrial);
	yield takeLatest(USERS.GET_USER_MANAGER, getUserManager);
	yield takeLatest(USERS.UPDATE_USER_MANAGER, setUserManager);
	yield takeLatest(USERS.REMOVE_USER_MANAGER, removeUserManager);
	yield takeLatest(
		USERS.GET_ALL_MANAGERS_USER_APPLIED_TO,
		getAllManagersUserAppliedTo
	);
	yield takeLatest(
		USERS.GET_ALL_DESCENDANT_USERS_FOR_MANAGER,
		getAllDescendantUsersForManager
	);
	yield takeLatest(
		USERS.REMOVE_HOSPITAL_APPLICATION,
		removeHospitalApplication
	);
	yield takeLatest(USERS.UPDATE_USER_SETTINGS, updateUserSettings);
	yield takeLatest(USERS.UPDATE_USER_PASSWORD, updateUserPassword);
	yield takeLatest(USERS.RESEND_INVITATION, resendInvitation);
	yield takeLatest(USERS.LOCK_USER, lockUser);
	yield takeLatest(USERS.UNLOCK_USER, unlockUser);
	yield takeLatest(USERS.GET_PASSPHRASES_DATA, setPassphrase);
	yield takeLatest(USERS.SET_PASSPHRASE, getPassphrase);
	yield takeLatest(USERS.PHONE_NUMBER, getPhoneNumber);
	yield takeLatest(USERS.ADD_PHONE_NUMBER, addPhoneNumber);
	yield takeLatest(USERS.DELETE_PHONE_NUMBER, removePhoneNumber);
	yield takeLatest(USERS.VERIFY_PHONE_NUMBER_OTP, mfaPhoneVerificationOtp);
	yield takeLatest(
		USERS.VERIFY_RESEND_PHONE_NUMBER_OTP,
		resendMfaPhoneVerificationOtp
	);
}
