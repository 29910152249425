import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Button, Fields } from '../../../../../../../../../../../Common/Components';
import { getInitialValuesForVendorsHospitals } from '../../../../../../VendorsSelectors';

class AddNewHospital extends PureComponent {
	render() {
		const { t } = this.props;

		return (
			<form
				action=""
				onSubmit={this.props.handleSubmit}
			>
				<div>
					{this.props.data && this.props.data.length > 0 &&
						(
							<Fields.MultiselectField
								fluid
								labelText={t('features.org.hospital.create.form.select_hospital.label')}
								placeholder={t('features.org.hospital.create.form.select_hospital.placeholder')}
								name="hospitals"
								multiple
								options={this.props.data.sort((a, b) => a.hospitalName.toUpperCase().localeCompare(b.hospitalName.toUpperCase())).map(hospital => ({
									key: hospital.hospitalId,
									value: hospital.hospitalId,
									label: hospital.hospitalName,
								}))}
							/>
						)
					}

				</div>
				<div className="users__action-buttons">
					<Button
						onClick={this.props.toggleAddNewHospitalModal}
						isGhost
						buttonSmall
					>
						Cancel
					</Button>
					<Button
						type="submit"
						isFull
						buttonSmall
					>
						Add facility
					</Button>
				</div>
			</form>);
	}
}

const mapStateToProps = state => ({
	initialValues: getInitialValuesForVendorsHospitals(state),
});

const AddNewHospitalWithTranslation = withTranslation()(AddNewHospital);

export default connect(mapStateToProps, undefined)(reduxForm({
	form: 'addVendorsHospitalForm',
})(AddNewHospitalWithTranslation));
