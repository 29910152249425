import { UserState } from "../models";
import { UserActionKeys, UserActionType } from "./actionTypes";
import { NActor } from "availkit-js/dist/Models/NActor";

const initialState: UserState = {
  identity: {
    access_token: "",
    token_type: "",
    refresh_token: "",
    expires_in: -1,
    scope: "",
    login_id: "",
    profile_id: "",
    org_id: "",
    org_name: "",
    status: "",
    pubnub_channel: "",
    pubnub_publ_key: "",
    finishedAccountSetup: false,
    pubnub_subs_key: "",
    userTypes: [],
    userId: "",
    pubnub_auth_key: "",
    email: "",
    jti: "",
    isPasswordAutoGenerated: false,
    isTwoFactorAuthEnabled: false,
    isPasswordExpired: false
  },
  actor: new NActor(),
  localMedia: null,
  audioinput: [],
  audiooutput: [],
  videoinput: [],
  hasJoinedRoom: false,
  refreshInProgress: false,
  error: false,
  callEnded: false,
  mediaState: {
    pip: "show",
    self: {
      audio: "unmute",
      video: "unmute",
      sidebar: "inactive"
    },
    console: {
      audio: "unmute",
      video: "unmute"
    }
  }
};

const reducer = (state = initialState, action: UserActionType): UserState => {
  switch (action.type) {
    case UserActionKeys.GET_IDENTITY_REQUEST:
      return state;

    case UserActionKeys.GET_IDENTITY_SUCCESS:
      return { ...state, identity: action.identity, error: false };

    case UserActionKeys.GET_IDENTITY_FAILED:
      return { ...state, error: true };

    case UserActionKeys.GET_LOCAL_MEDIA_REQUEST:
      return state;

    case UserActionKeys.GET_LOCAL_MEDIA_SUCCESS:
      return {
        ...state,
        error: false,
        localMedia: action.mediaDeviceEnumeration.localMedia,
        audioinput: action.mediaDeviceEnumeration.audioinput,
        audiooutput: action.mediaDeviceEnumeration.audiooutput,
        videoinput: action.mediaDeviceEnumeration.videoinput
      };

    case UserActionKeys.GET_LOCAL_MEDIA_FAILED:
      return { ...state, error: true };

    case UserActionKeys.SET_HAS_JOINED_ROOM:
      return { ...state, hasJoinedRoom: action.hasJoinedRoom };

    case UserActionKeys.SET_REFRESH_IN_PROGRESS:
      return { ...state, refreshInProgress: action.refreshInProgress };

    case UserActionKeys.SET_MEDIA_STATE:
      return { ...state, mediaState: action.mediaState };

    case UserActionKeys.SET_END_CALL:
      return { ...state, callEnded: true };

    case UserActionKeys.UPDATE_ACCESS_TOKEN:
      const newIdentity = {
        ...state.identity,
        access_token: action.identity.access_token,
        refresh_token: action.identity.refresh_token
      };
      return { ...state, identity: newIdentity };

    default:
      return state;
  }
};

export default reducer;
