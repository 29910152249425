import { Hospital } from "src/services/ApiClient/hospital";

import { BaseResponse } from "../types";

export enum EventStatus {
  created = "CREATED", // when event is created but no one has joined
  creating = "CREATING", // backend is in process of creating an event (it's done asynchronously)
  started = "STARTED", // once at least 1 person joins
  ended = "ENDED", // when everyone that was in the call has left
  expired = "EXPIRED", // expired events are created events that no one joins for over 1 hour
  cancelled = "CANCELLED", // when we cancel an event through the UI (or api)
}

export type Role = "HOST" | "PARTICIPANT" | "PANELIST" | "AUDIENCE";

export interface Participant {
  email: string;
  firstName: string;
  lastName: string;
  loginId: string;
  role: Role;
  isMember: boolean;
}

export interface MultiPartyEvent {
  billingUserId: string;
  callSid: string;
  endDate: string;
  description: string;
  eventId: string;
  hospitalId: string;
  hospitalName: string;
  hostUserId: string;
  participants: Participant[];
  roomId?: string;
  startDate: string;
  status: EventStatus;
  subject: string;
  surgeonName: string;
  eventTags?: string[];
  otherProcedure?: string;
  otherTherapy?: string;
  parentId?: string; // relates to recurring property
  procedureName?: string; // used for 3rd party integration
  procedureId?: string;
  recurringRule?: string;
  therapyId?: string;
  therapyName?: string; // used for 3rd party integration
  timeZoneId: string;
  eventLink: string; // for copy link button
  creatorUserId: string;
}

// This object has all the full objects the Event needs to fill out the form maybe doesn't belong here?
export interface FullyLoadedEvent extends MultiPartyEvent {
  hospital: Hospital;
}

// TODO figure out best name for this, seems like participant field for the event post
// is smaller than the one it returns
export interface ParticipantForEventRequest {
  email: string;
  role: Role;
}

export interface EventRequestBody {
  // POST body
  description: string;
  billingUserId: string;
  endDate: string;
  hospitalId: string;
  participants: ParticipantForEventRequest[];
  roomId?: string;
  startDate: string;
  subject: string;
  surgeonName: string;
  eventTags?: string[];
  otherProcedure?: string;
  otherTherapy?: string;
  parentId?: string; // relates to recurring property
  procedureId?: string;
  recurringRule?: string;
  therapyId?: string;
  timeZoneId: string;
}

export type EventListResponse = BaseResponse<MultiPartyEvent[]>;
export type EventDetailsResponse = BaseResponse<MultiPartyEvent>;
