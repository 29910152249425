import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
/* Components */
import { EntityProfileSidebar, ProfileBreadcrumbs, TabsWithMenu } from '../../../../../Common';
import DepartmentInfo from './Components/DepartmentInfo/DepartmentInfo';
import DepartmentTherapies from './Components/DepartmentTherapies/DepartmentTherapies';
import Rooms from '../../Components/Rooms/Rooms';
/* Actions & Services */
import { getHospitalByIdAction } from '../../HospitalsActions';
import { createNewRoomAction, getRoomsByDepartmentAction } from './DepartmentsActions';
import { urlService } from '../../../../../../../Common/Services';

class DepartmentProfile extends PureComponent {
	constructor(props) {
		super(props);
		this.hospitalId = urlService.getQueryParam(props.location, 'hospitalId');
		this.departmentId = urlService.getQueryParam(props.location, 'departmentId');
	}

	componentDidMount() {
		if (!this.props.hospital) {
			this.props.getHospitalById(this.hospitalId);
		}
	}

	onCreateNewRoomHandler = (data) => {
		this.props.createNewRoom(this.departmentId, data.room);
	};

	renderOptions = () => {
		const menuOptions = [
			{
				title: this.props.hospital && this.props.hospital.name,
				to: `/hospitals/profile?hospitalId=${this.hospitalId}`,
			},
			{
				title: this.props.department && this.props.department.name,
				label: 'Department name',
				className: 'profile-breadcrumbs__menu-item--active',
				to: `/departments/profile?hospitalId=${this.hospitalId}&departmentId=${this.departmentId}`,
			},
		];
		return menuOptions;
	};

	renderPanes = () => (
		[{
			menuItem: 'General Info',
			render: () => <DepartmentInfo departmentId={this.departmentId} />,
		}, {
			menuItem: 'Rooms',
			render: () =>
				(
					<Rooms
            departmentId={this.departmentId}
						rooms={this.props.departmentRooms}
						hospitalId={this.hospitalId}
						onCreateNewRoomHandler={this.onCreateNewRoomHandler}
            profileType={'department'}
						addingNewRoomDisabled={false}
						urlParams={`?hospitalId=${this.hospitalId}&departmentId=${this.departmentId}`}
						tableActions={false}
					/>
				),
		}, {
			menuItem: 'Therapies & Procedures',
			render: () => <DepartmentTherapies departmentId={this.departmentId} />,
		}]
	);

	render() {
		return (
			<div className="display--flex height--full">
				<EntityProfileSidebar title="Facility profile">
					{this.props.department &&
					<ProfileBreadcrumbs options={this.renderOptions()} />
					}
				</EntityProfileSidebar>
				<div className="vendor-profile__right-side">
					<TabsWithMenu options={this.renderPanes()} />
				</div>
			</div>
		);
	}
}

DepartmentProfile.propTypes = {};

const mapStateToProps = state => ({
	departmentRooms: state.departmentReducer.departmentRooms,
	department: state.departmentReducer.department,
	hospital: state.hospitalsReducer.hospital,
});

const mapDispatchToProps = dispatch => ({
	getRoomsByDepartment: departmentId => dispatch(getRoomsByDepartmentAction(departmentId)),
	createNewRoom: (departmentId, roomData) => dispatch(createNewRoomAction(departmentId, roomData)),
	getHospitalById: hospitalId => dispatch(getHospitalByIdAction(hospitalId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'departmentProfileForm',
	enableReinitialize: true,
})(DepartmentProfile)));

