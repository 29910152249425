import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";

import { Box, Grid } from "@mui/material";

import { Checkbox } from "src/components/legacy/Checkbox";
import { Select } from "src/components/legacy/Select";
import { TextField } from "src/components/legacy/TextField";
import { useOnPageRefresh } from "src/hooks/useOnPageRefresh";
import { history } from "src/reducer";
import { getGuestUserRoles } from "src/services/ApiClient/users";
import UserSessionService from "src/services/UserSessionService";
import { clearUserSession } from "src/utils/clearUserSession";

import { Button } from "avail-web-application/Common/Components";
import { notificationService } from "avail-web-application/Common/Services/";
import userSessionService from "avail-web-application/Common/Services/userSesionService";
import { registerGuestUserAction } from "avail-web-application/Features/Authentication/AuthenticationActions";
import withAuthentication from "avail-web-application/Features/Authentication/Components/withAuthentication";

import {
  minLengthField,
  maxLengthField,
  onlyLetters,
  requiredField,
  surnameValidation,
} from "avail-web-ui/constants/validation";
import DomainConstants from "avail-web-ui/domains/DomainConstants";

import { LoginPage } from "../../../../Login";

interface DispatchProps {
  submitRegisterGuestUser: (data) => any;
}

interface StateProps {
  otpInfo: any;
}

type Props = StateProps & DispatchProps;

function RegisterScreen({ otpInfo, submitRegisterGuestUser }: Props) {
  // TODO: fix typing on this. Tot sure what is going on here with the type but added an `any` to avoid breaking anything
  const { company, firstName, lastName, title, notify } =
    (UserSessionService.getCachedUserInfo() as any) || "{}";
  const [guestRoles, setGuestRoles] = useState([]);
  const [notifications, setNotifications] = useState(notify || true);

  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  useOnPageRefresh(() => clearUserSession());

  useEffect(() => {
    // checks for empty session storage
    if (sessionStorage.length === 0) {
      history.push("/event-login");
    } else {
      async function fetchGuestUserRoles() {
        try {
          const {
            content: { titles },
          } = await getGuestUserRoles();
          const roles = titles.map((role) => ({
            displayText: role,
            value: role,
          }));
          setGuestRoles(roles);
        } catch (e) {
          notificationService.warning(e);
        }
      }
      fetchGuestUserRoles();
    }
  }, []);

  const handleLoginFormSubmit = (loginData) => {
    const acceptedEula = !!userSessionService.getEulaVersion();
    const { username } = otpInfo;
    if (acceptedEula) {
      submitRegisterGuestUser({
        ...loginData,
        username,
      });
    }
  };

  const onNotificationsToggle = () => {
    setNotifications((prev): boolean => !prev);
  };

  return (
    <LoginPage className="login">
      <div className="login__subheading">{DomainConstants.label.loginHub}</div>
      <form
        className="login__form"
        onSubmit={handleSubmit(handleLoginFormSubmit)}
      >
        <Grid direction="row" spacing={4} container>
          <Grid container direction="column" item spacing={3}>
            <Grid item container spacing={1}>
              <Grid item xs={6}>
                <Controller
                  name={DomainConstants.key.firstName}
                  control={control}
                  rules={{
                    ...minLengthField(DomainConstants.label.firstName, 3),
                    ...maxLengthField(DomainConstants.label.firstName, 120),
                    ...requiredField(DomainConstants.label.firstName),
                    ...onlyLetters(),
                  }}
                  defaultValue={firstName || ""}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <TextField
                          label={DomainConstants.label.firstName}
                          placeholder={DomainConstants.placeholder.firstName}
                          required
                          onChange={onChange}
                          value={value}
                        />
                        {error && (
                          // TODO move error message to own component
                          <span style={{ color: "red" }}>{error.message}</span>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={DomainConstants.key.lastName}
                  control={control}
                  rules={{
                    ...minLengthField(DomainConstants.label.lastName, 3),
                    ...maxLengthField(DomainConstants.label.lastName, 120),
                    ...requiredField(DomainConstants.label.lastName),
                    ...surnameValidation(),
                  }}
                  defaultValue={lastName || ""}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <TextField
                          label={DomainConstants.label.lastName}
                          placeholder={DomainConstants.placeholder.lastName}
                          required
                          onChange={onChange}
                          value={value}
                        />
                        {error && (
                          // TODO move error message to own component
                          <span style={{ color: "red" }}>{error.message}</span>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Box component={Grid} item display={{ xs: "block" }}>
              <Controller
                name={DomainConstants.key.title}
                control={control}
                rules={{
                  ...requiredField(DomainConstants.label.title),
                }}
                defaultValue={title || ""}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <Select
                        label={DomainConstants.label.roleSelect}
                        name={DomainConstants.key.title}
                        currentValue={value}
                        menuItems={guestRoles}
                        required
                        onChange={onChange}
                        value={value}
                      />
                      {error && (
                        // TODO move error message to own component
                        <span style={{ color: "red" }}>{error.message}</span>
                      )}
                    </>
                  );
                }}
              />
            </Box>
            <Grid item container>
              <Controller
                name={DomainConstants.key.company}
                control={control}
                rules={{
                  ...minLengthField(DomainConstants.label.workSelect, 3),
                  ...maxLengthField(DomainConstants.label.workSelect, 120),
                  ...requiredField(DomainConstants.label.company),
                }}
                defaultValue={company || ""}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <TextField
                        label={DomainConstants.label.workSelect}
                        placeholder={DomainConstants.placeholder.workplace}
                        required
                        onChange={onChange}
                        value={value}
                      />
                      {error && (
                        // TODO move error message to own component
                        <span style={{ color: "red" }}>{error.message}</span>
                      )}
                    </>
                  );
                }}
              />
            </Grid>
            <Grid item container>
              <Controller
                name={DomainConstants.key.notify}
                control={control}
                defaultValue={notifications}
                render={({ fieldState: { error } }) => {
                  return (
                    <>
                      <Checkbox
                        label={DomainConstants.label.notifications}
                        defaultChecked={notifications}
                        name={DomainConstants.key.notify}
                        onChange={onNotificationsToggle}
                        value={notifications}
                      />
                      {error && (
                        // TODO move error message to own component
                        <span style={{ color: "red" }}>{error.message}</span>
                      )}
                    </>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          data-test-id="loginSubmit"
          disabled={!formState.isValid}
          isFull
          isGreen
          className="login__button singular"
          type="submit"
        >
          Login to Avail
        </Button>
      </form>
    </LoginPage>
  );
}

const mapStateToProps = (state) => ({
  otpInfo: state.authenticationReducer.otpInfo,
});

const mapDispatchToProps = (dispatch) => ({
  submitRegisterGuestUser: (data) => dispatch(registerGuestUserAction(data)),
});

export default withAuthentication(
  connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )(RegisterScreen)
);
