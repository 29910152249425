import moment from "moment";

// Unfornately this is how the backend specifics the therapy/procedure object that lets the user enter a custom
// the therapy object has name and description set to "OTHER"
export const otherTherapyOrProcedureSelected = (value) => {
  return value && value.name === "OTHER" && value.description === "OTHER";
};

// these two formats have to be the equivalent of each other
// unfortunately MUI date picker format has to be slightly different than the moment one
// but they render the same way so dont worry
export const EVENT_MUI_DATE_TIME_FORMAT = "yyyy-MM-dd";

export const EVENT_MOMENT_DATE_FORMAT = "YYYY-MM-DD";

export const EVENT_MOMENT_TIME_FORMAT = "h:mm A";

export const EVENT_MOMENT_DATE_TIME_FORMAT = `${EVENT_MOMENT_DATE_FORMAT} ${EVENT_MOMENT_TIME_FORMAT}`;

// do the validation here where we use the moment format instead since that is what will be sent to the BE
export const checkValidDateFormat = (date: string) => {
  return moment(date, EVENT_MOMENT_DATE_FORMAT, true).isValid();
};

export const checkValidTimeFormat = (time: string) => {
  return moment(time, EVENT_MOMENT_TIME_FORMAT, true).isValid();
};
