/** InputInfo documentation
 */
import React from "react";

import { Font } from "src/components/Font";

import styles from "./styles.scss";

export interface InputInfoProps {
  "data-test-id"?: string;
  isMuted: boolean;
  isBuiltInMic: boolean;
}

export const InputInfo = ({ isMuted, isBuildInMic }) => {
  return (
    <div className={styles.root}>
      <Font variant="b1" color="light" data-test-id="input-info-device">
        {isBuildInMic ? "Built-in Microphone" : "Headset"}
      </Font>
      <Font
        variant="h3"
        color={isMuted ? "red" : "green"}
        data-test-id="input-info-device-status"
      >
        {isMuted ? "Muted" : "Active"}
      </Font>
    </div>
  );
};
