import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import Icon from '../../../../Common/Components/Icon/Icon';
import AccessControlService from '../../../../Common/Services/accessControlService';
import UserService from '../../../../Common/Services/userService';
import { logoutAction } from '../../../Authentication/AuthenticationActions';
import { getcurrentUserTypes } from '../../HomeSelectors';
/* Actions */
import { getUserEmployerAction, getUserProceduresAction, getUserEmployerByDomainAction } from '../Users/UsersActions';
import { getInitialValuesForAccountCreation } from '../Users/UsersSelectors';
import { finalizeAccountSetupAction, getAllTimezonesAction } from './AccountCreationActions';
import AccountCreationFormHospitalsPart
	from './Components/AccountCreationFormHospitalsPart/AccountCreationFormHospitalsPart';
import AccountCreationFormISROrganization
	from './Components/AccountCreationFormISROrganization/AccountCreationFormISROrganization';
/* Components */
import AccountCreationFormTherapiesPart
	from './Components/AccountCreationFormTherapiesPart/AccountCreationFormTherapiesPart';
import TabsWithButtons from '../../Common/TabsWithButtons/TabsWithButtons';
/* Styles & Images */
import { newlogo2021 } from '../../../../Common/Assets/Images';
import './AccountCreation.scss';
import AccountCreationFormUserInfoPart
	from './Components/AccountCreationFormUserInfoPart/AccountCreationFormUserInfoPart';
import { getDomainFromEmail } from './AccountCreationUtils';
import { userHasPhoneNumber } from "src/selectors/homeReducer.ts"

import { LoginPage } from "/src/domains/Login";

class AccountCreation extends PureComponent {
	componentDidMount() {
		const { currentUser } = this.props;

		if (UserService.checkIsIndividualSalesRep(currentUser.userType)) {
			const domain = getDomainFromEmail(currentUser.email);
			this.props.getUserEmployerByDomain(domain); // get org by domain
		} else {
			this.props.getUserEmployer(currentUser.id); // get org by user id
		}

		this.props.getAllTimezones();
		this.props.getUserProcedures(currentUser.id);
	}

	onAccountSetupHandler = (data) => {
    const { finalizeAccountSetup, hasPhoneNumber } = this.props;
    const { location: {state: isTwoFactorAuthEnabled} } = this.props;
		const finishAccountCreation = { ...data, hasPhoneNumber, isTwoFactorAuthEnabled };
		finishAccountCreation.procedures = this.formatProcedures(data.procedures);
		finishAccountCreation.userSettings = {
			type: 'TIME_ZONE',
			value: data.userSettings.timezone,
    };
		finalizeAccountSetup(finishAccountCreation);
	};

	onSelectAllHandler = (e, id) => {
		this.props.therapies.forEach((therapy) => {
			if (therapy.id === id) {
				therapy.procedures.forEach((procedure) => {
					this.props.change(`procedures.${procedure.id}`, e.target.checked);
				});
			}
		});
	};

	formatProcedures = procedures => ({
		'ids': procedures ? Object.keys(procedures).filter((key) => {
			if (procedures[key]) {
				return key;
			}
		}) : [],
	});

	tabs = [];

	renderTabs = () => {
		this.tabs = [
			<AccountCreationFormUserInfoPart key="accountCreationFormUserInfoPart" />,
			...AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'read', 'accountCreationUserInfo') ?
				[<AccountCreationFormISROrganization
					key="accountCreationFormISROrganization"
					userOrganisation={this.props.userOrg}
				/>] : [],
			...AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'read', 'accountCreationProcedures') ?
				[<AccountCreationFormTherapiesPart
					accountCreationForm={this.props.accountCreationForm}
					onSelectAllHandler={this.onSelectAllHandler}
					change={this.props.change}
					initialValues={this.props.initialValues}
					key="accountCreationFormTherapiesPart"
				/>] : [],
			...AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'read', 'accountCreationHospitals') ?
				[<AccountCreationFormHospitalsPart key="accountCreationFormHospitalsPart" />] : [],
		];

		return this.tabs;
	};

	render() {
		return (
			<LoginPage className="login">
        <div className="account-creation__tabs-wrapper">
          <TabsWithButtons
            tabs={this.renderTabs()}
            onSubmitHandler={this.onAccountSetupHandler}
            initialValues={this.props.initialValues}
          />
        </div>
        <div className="align-self--flex-start account-creation__logout">
          <Icon
            className="icon-log-out-outline icon--dark-blue"
            onClick={this.props.logout}
          />
        </div>
      </LoginPage>
		);
	}
}

AccountCreation.defaultProps = {
	currentUser: {},
	currentUserTypes: [],
	initialValues: {},
  timezones: [],
  location: {},
};

AccountCreation.propTypes = {
	currentUser: PropTypes.object,
	currentUserTypes: PropTypes.array,
	initialValues: PropTypes.object,
  timezones: PropTypes.array,
  location: PropTypes.object
};

const mapStateToProps = state => ({
	accountCreationForm: getFormValues('accountCreationForm')(state),
	currentUser: state.homeReducer.currentUser,
	currentUserTypes: getcurrentUserTypes(state),
	initialValues: getInitialValuesForAccountCreation(state),
	userOrg: state.usersReducer.userOrganisation,
  hasPhoneNumber: userHasPhoneNumber(state),
});

const mapDispatchToProps = dispatch => ({
	finalizeAccountSetup: finishAccountCreation => dispatch(finalizeAccountSetupAction(finishAccountCreation)),
	getAllTimezones: () => dispatch(getAllTimezonesAction()),
	getUserEmployer: id => dispatch(getUserEmployerAction(id)),
	getUserEmployerByDomain: domain => dispatch(getUserEmployerByDomainAction(domain)),
	getUserProcedures: id => dispatch(getUserProceduresAction(id)),
	logout: () => dispatch(logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreation);
