import clsx from "clsx";

import { Collapse } from "@mui/material";

import { Button } from "src/components/Button";
import { Font } from "src/components/Font";

import styles from "./styles.scss";

interface IProps {
  open: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

export const DeleteConfirmation = ({ open, onDelete, onCancel }: IProps) => {
  return (
    <Collapse in={open}>
      <div className={styles.wrapper} data-test-id="confirmation-presets">
        <Font variant="h3" color="light" className={styles.question}>
          Are you sure you’d like to delete this preset?
        </Font>
        <div className={styles.buttons}>
          <Button
            data-test-id="confirm-delete-button"
            wrapperClassName={styles.buttonWrapper}
            className={clsx(styles.button)}
            active
            label="Yes"
            onClick={onDelete}
          />
          <Button
            data-test-id="cancel-delete-button"
            wrapperClassName={styles.buttonWrapper}
            className={clsx(styles.button, styles.inactive)}
            label="Cancel"
            onClick={onCancel}
          />
        </div>
      </div>
    </Collapse>
  );
};
