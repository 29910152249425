import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Mic, MicOff } from "@mui/icons-material";

import { Avatar } from "src/components/Avatar";
import { Button } from "src/components/Button";
import { Font } from "src/components/Font";
import { List } from "src/components/List";
import { useAppSelector } from "src/domains/Beacon/store";
import {
  selectCallMeetingModeState,
  selectEventDetails,
  selectIsUserHost,
  selectMeetingCallSid,
} from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { selectRemoteTracks } from "src/domains/Beacon/store/stream/selectors";
import {
  ParticipantTracks,
  TrackObject,
} from "src/domains/Beacon/store/stream/types";
import {
  selectDominantSpeaker,
  selectParticipants,
} from "src/domains/Beacon/store/twilio/selectors";
import { selectMicrophoneMuted } from "src/domains/Beacon/store/ui/selectors";
import { getActiveParticipants } from "src/domains/Beacon/utils/participants";
import { logger } from "src/logging/logger";
import { MultiPartyEvent, Participant } from "src/services/ApiClient/scheduler";
import { AvailKitService } from "src/services/AvailKitService";
import UserSessionService from "src/services/UserSessionService";
import { getUserInitials } from "src/utils/user";

import styles from "./styles.scss";

interface RosterParticipant extends Participant {
  id?: string;
  audioEnabled?: boolean;
}

// TODO move this to a util, or it might already exist?
function getDisplayName(
  participant: RosterParticipant,
  currentUserLoginId: string
) {
  const { firstName, lastName, email } = participant;
  const displayName =
    participant.firstName && participant.lastName
      ? `${firstName} ${lastName}`
      : email;

  return (
    displayName + (participant.loginId === currentUserLoginId ? " (me)" : "")
  );
}

export const ParticipantsContent = () => {
  const [activeParticipants, setActiveParticipants] = useState<
    RosterParticipant[]
  >([]);

  const isCurrentUserHost = useAppSelector(selectIsUserHost);
  const callMode = useAppSelector(selectCallMeetingModeState);
  const callSid = useAppSelector(selectMeetingCallSid);

  const twilioParticipants = useAppSelector(selectParticipants);

  // for MP calls, we'll need event details to show the event name/subject and to get users's names
  // for p2p calls its just the current logged in user + the console so we should have the current user's name already
  const eventDetails = useAppSelector(selectEventDetails) as MultiPartyEvent;
  const eventParticipants = eventDetails?.participants;

  // this has all the video/audio tracks for all participants
  // const participantTracks = useAppSelector(selectParticipantsTracks);
  const { host: hostTracks, participants: participantTracks } = useSelector(
    selectRemoteTracks
  );

  const currentUser = UserSessionService.getCachedUserInfo();
  const currentUserLoginId = currentUser.loginId;
  const localMicMuted = useAppSelector(selectMicrophoneMuted);

  const dominantSpeaker = useAppSelector(selectDominantSpeaker);

  useEffect(() => {
    if (callMode === CallModes.P2P) {
      // for p2p mode we dont have any event details, so just populate the current user as the host
      // TODO need to get the real user object rather than using this one (this works just fine for now)
      // @ts-ignore
      const currentUserWithRole: RosterParticipant = {
        ...currentUser,
        role: "HOST",
      };
      setActiveParticipants([currentUserWithRole]);
    } else {
      // Active participants
      const activeUsers = getActiveParticipants(
        eventDetails?.participants,
        twilioParticipants
      );

      // we need the current user from the event because it has their "role" (host/participant)
      const currentUserFromEvent = eventParticipants?.find(
        (user) => user.loginId === currentUserLoginId
      );

      // include `currentUser` in here because `twilioParticipants` does not include the current user
      const trackedParticipants = [currentUserFromEvent, ...activeUsers];

      setActiveParticipants(trackedParticipants);
    }
  }, [eventParticipants, twilioParticipants, currentUserLoginId]);

  // the "mute" button below is only visible for participants and only if you are the host
  // the host can't use this button to mute themselves (they should use the mute button in the bottom bar)
  const muteParticipantOnClick = (loginId: string) => {
    const availKit = AvailKitService.instance;
    availKit?.avkMediaService.setRemoteMute(callSid, [loginId]);

    logger().info(`Host has muted user id: ${loginId}`);
  };

  return (
    <>
      <div className={styles.header}>
        {createHeader(callMode, eventDetails)}
      </div>
      <div className={styles.listWrapper}>
        <Font variant="h1" color="light" className={styles.participantTitle}>
          Participants ({activeParticipants.length})
        </Font>
        <div className={styles.list}>
          {activeParticipants &&
            createParticipantsList(
              isCurrentUserHost,
              currentUserLoginId,
              localMicMuted,
              callMode,
              activeParticipants,
              hostTracks,
              participantTracks,
              muteParticipantOnClick,
              dominantSpeaker
            )}
        </div>
      </div>
    </>
  );
};

// TODO need to be able to change rightContent with mic: muted/unmuted, green if speaking, and mute button
const participantToListObject = (
  participant: RosterParticipant,
  currentUserLoginId: string
) => ({
  key: participant.loginId,
  title: getDisplayName(participant, currentUserLoginId),
  leftContent: (
    <Avatar
      label={getUserInitials(participant.firstName, participant.lastName)}
    />
  ),
  rightContent: null,
});

const createParticipantsList = (
  isCurrentUserHost: boolean,
  currentUserLoginId: string,
  localMicMuted: boolean,
  mode: CallModes,
  activeParticipants: RosterParticipant[],
  hostTracks: TrackObject,
  participantTracks: ParticipantTracks,
  muteParticipantOnClick: (loginId: string) => void,
  dominantSpeaker: string
) => {
  const listParticipants = activeParticipants
    ?.filter((participant) => participant.role === "PARTICIPANT")
    .map((participant) => {
      const participantObj = participantToListObject(
        participant,
        currentUserLoginId
      );

      // if the current participant we are looking at in the loop is the current logged in user
      // then check their local mic muted flag to know what mic icon to show below
      const participantLocalMicEnabled =
        participant.loginId === currentUserLoginId && !localMicMuted;

      const audioTrackList = participantTracks[participant.loginId];

      const audioTrack =
        audioTrackList && audioTrackList.length === 2 && audioTrackList[1];

      // we default the icon to the Off icon
      let rightContent = (
        <div className={styles.micWrapper}>
          <MicOff
            classes={{
              root: styles.mic,
            }}
          />
        </div>
      );

      // if the participant audio is enabled, either from remote (isEnabled) or locally (mic is not muted)
      // then show the mic on icon
      if ((audioTrack && audioTrack.isEnabled) || participantLocalMicEnabled) {
        rightContent = (
          <div className={styles.micWrapper}>
            <Mic
              classes={{
                root: styles.mic,
              }}
              className={`${
                dominantSpeaker === participant.loginId
                  ? styles.dominantSpeaker
                  : ""
              }`}
            />

            {/* Only the host should see this MUTE button */}
            {isCurrentUserHost && (
              <Button
                label="MUTE"
                variant="contained"
                onClick={() => {
                  muteParticipantOnClick(participant.loginId);
                }}
              />
            )}
          </div>
        );
      }

      // setting the mic status in the right content
      participantObj.rightContent = rightContent;

      return participantObj;
    });

  const listHostParticipant = activeParticipants
    ?.filter((participant) => participant.role === "HOST")
    .map((participant) => {
      const participantObj = participantToListObject(
        participant,
        currentUserLoginId
      );

      const hostAudioTrack = hostTracks && hostTracks.audio;

      // if the current participant we are looking at in the loop is the current logged in user
      // then check their local mic muted flag to know what mic icon to show below
      const participantLocalMicEnabled =
        participant.loginId === currentUserLoginId && !localMicMuted;

      // we default the icon to the Off icon
      let rightContent = (
        <div className={styles.micWrapper}>
          <MicOff
            classes={{
              root: styles.mic,
            }}
          />
        </div>
      );
      // if the host audio is enabled, either from remote (isEnabled) or locally (mic is not muted)
      // then show the mic on icon
      if (
        (hostAudioTrack && hostAudioTrack.isEnabled) ||
        participantLocalMicEnabled
      ) {
        rightContent = (
          <div className={styles.micWrapper}>
            <Mic
              classes={{
                root: styles.mic,
              }}
              className={`${
                dominantSpeaker === participant.loginId
                  ? styles.dominantSpeaker
                  : ""
              }`}
            />
          </div>
        );
      }

      // setting the mic status in the right content
      participantObj.rightContent = rightContent;

      return participantObj;
    });

  if (mode === CallModes.P2P) {
    return (
      <>
        <List items={listHostParticipant} />
      </>
    );
  } else {
    // separate host from panelists visually in two separate lists
    return (
      <>
        <>
          <Font variant="b1" color="light" className={styles.fontMargin}>
            Host
          </Font>
          {listHostParticipant?.length ? (
            <List items={listHostParticipant} />
          ) : (
            <Font variant="b1" color="light" className={styles.fontMargin}>
              Waiting for host...
            </Font>
          )}
        </>
        <>
          {/* only if we have participants then show the header + list of participants */}
          {listParticipants?.length ? (
            <>
              <Font variant="b1" color="light" className={styles.fontMargin}>
                Panelists
              </Font>
              <List items={listParticipants} />
            </>
          ) : null}
        </>
      </>
    );
  }
};

const createHeader = (mode: CallModes, eventDetails: MultiPartyEvent) => {
  if (mode === CallModes.P2P) {
    return (
      <>
        <Font variant="h1" color="light">
          {/* for p2p calls we dont have an event title so just showing that it was an "incoming call" */}
          Incoming Call
        </Font>
        <Font variant="b1" color="light" className={styles.fontMargin}>
          {eventDetails ? eventDetails.hospitalName : ""}
        </Font>
      </>
    );
  } else {
    return (
      <>
        <Font variant="h1" color="light">
          {eventDetails ? eventDetails.subject : "Event"}
        </Font>
        <Font variant="b1" color="light" className={styles.fontMargin}>
          {eventDetails ? eventDetails.hospitalName : ""}
        </Font>
        <Font variant="b1" color="light" className={styles.fontMargin}>
          Surgeon: {eventDetails ? eventDetails.surgeonName : ""}
        </Font>
      </>
    );
  }
};
