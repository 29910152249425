import { select, takeLatest } from "redux-saga/effects";
import { renderTraceIdHeader } from "src/domains/Beacon/utils/renderTraceIdHeader";

import { logger } from "../../../common/logger";
import { ENDPOINTS } from "../../../constants";
import {
  AppState,
  MeetingStateType,
  PortalIdentity
} from "../../models";
import { UserActionKeys } from "../actionTypes";

export function* endCallSaga() {
  const { callSid, joinId }: MeetingStateType = yield select(
    (state: AppState) => state.meeting
  );

  const {
    access_token
  }: PortalIdentity = yield select((state: AppState) => state.user.identity);
  // DELETE https://api.titan-dev.us.avail.io/callevent/users/join/{joinId}
  if (!joinId) {
    return;
  }

  const url = `${ENDPOINTS.DOMAIN}/callevent/users/join/${joinId}`;

  fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      ...renderTraceIdHeader(),
      Accept: "application/json"
    },
    body: JSON.stringify({ callSid })
  })
    .then((res) => {
      try {
        if (res.status === 204) {
          // Page reload resets state, emulating "end call" on the client-side
          // Note we don't provide a query parameter with the call ID; upon
          // reload, the FE code will consider this to be the "end call" state,
          // which shows the appropriate "End call" UX
          window.location.href = "/portalcall";
        }
      } catch (e) {
        logger().error(e);
      }
    })
    .catch((e) => {
      logger().error(e);
    });
}

export function* watchEndCall() {
  yield takeLatest(UserActionKeys.END_CALL, endCallSaga);
}
