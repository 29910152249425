import {
  MediaStateType,
  PortalIdentity,
  MediaDeviceEnumeration
} from "../models";

export enum UserActionKeys {
  GET_IDENTITY = "GET_IDENTITY",
  GET_IDENTITY_REQUEST = "GET_IDENTITY_REQUEST",
  GET_IDENTITY_SUCCESS = "GET_IDENTITY_SUCCESS",
  GET_IDENTITY_FAILED = "GET_IDENTITY_FAILED",

  GET_LOCAL_MEDIA = "GET_LOCAL_MEDIA",
  GET_LOCAL_MEDIA_REQUEST = "GET_LOCAL_MEDIA_REQUEST",
  GET_LOCAL_MEDIA_SUCCESS = "GET_LOCAL_MEDIA_SUCCESS",
  GET_LOCAL_MEDIA_FAILED = "GET_LOCAL_MEDIA_FAILED",

  SET_HAS_JOINED_ROOM = "SET_HAS_JOINED_ROOM",
  SET_REFRESH_IN_PROGRESS = "SET_REFRESH_IN_PROGRESS",

  END_CALL = "END_CALL",
  SET_END_CALL = "SET_END_CALL",

  SET_CALL_STATE = "SET_CALL_STATE",
  SET_MEDIA_STATE = "SET_MEDIA_STATE",

  UPDATE_ACCESS_TOKEN_TIMER = "UPDATE_ACCESS_TOKEN_TIMER",
  UPDATE_ACCESS_TOKEN = "UPDATE_ACCESS_TOKEN"
}

interface GetIdentity {
  type: UserActionKeys.GET_IDENTITY;
}

interface IdentityRequest {
  type: UserActionKeys.GET_IDENTITY_REQUEST;
}

interface IdentitySuccess {
  type: UserActionKeys.GET_IDENTITY_SUCCESS;
  identity: PortalIdentity;
}

interface IdentityFailed {
  type: UserActionKeys.GET_IDENTITY_FAILED;
}

interface GetLocalMedia {
  type: UserActionKeys.GET_LOCAL_MEDIA;
}

interface LocalMediaRequest {
  type: UserActionKeys.GET_LOCAL_MEDIA_REQUEST;
}

interface LocalMediaSuccess {
  type: UserActionKeys.GET_LOCAL_MEDIA_SUCCESS;
  mediaDeviceEnumeration: MediaDeviceEnumeration;
}

interface LocalMediaFailed {
  type: UserActionKeys.GET_LOCAL_MEDIA_FAILED;
}

interface SetJoinedRoom {
  type: UserActionKeys.SET_HAS_JOINED_ROOM;
  hasJoinedRoom: boolean;
}

interface SetRefreshInProgress {
  type: UserActionKeys.SET_REFRESH_IN_PROGRESS;
  refreshInProgress: boolean;
}

interface EndCall {
  type: UserActionKeys.END_CALL;
}

interface SetCallEnded {
  type: UserActionKeys.SET_END_CALL;
}

interface SetMediaState {
  type: UserActionKeys.SET_MEDIA_STATE;
  mediaState: MediaStateType;
}

interface UpdateAccessTokenTimer {
  type: UserActionKeys.UPDATE_ACCESS_TOKEN_TIMER;
}

interface UpdateAccessToken {
  type: UserActionKeys.UPDATE_ACCESS_TOKEN;
  identity: PortalIdentity;
}

export type UserActionType =
  | GetIdentity
  | IdentityRequest
  | IdentitySuccess
  | IdentityFailed
  | GetLocalMedia
  | LocalMediaRequest
  | LocalMediaSuccess
  | LocalMediaFailed
  | SetJoinedRoom
  | SetRefreshInProgress
  | EndCall
  | SetCallEnded
  | SetMediaState
  | UpdateAccessTokenTimer
  | UpdateAccessToken;
