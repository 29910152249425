import React from "react";

import clsx from "clsx";

import { Card } from "src/components/legacy/Card";
import { Logo } from "src/components/legacy/Logo";

import styles from "./styles.scss";

export interface LoginCardProps {
  children: React.ReactNode;
  className?: string;
  "data-test-id"?: string;
}

/**
 * LoginCard wraps all the login or external facing forms (like reset password, etc)
 */
export const LoginCard = ({
  children,
  className,
  "data-test-id": dataTestId,
}: LoginCardProps) => (
  <Card data-test-id={dataTestId} className={clsx(styles.loginCard, className)}>
    <div className={styles.logoContainer}>
      <Logo />
    </div>
    {children}
  </Card>
);
