import { useLocation } from "react-router-dom";

interface QueryParams {
  [key: string]: string | null;
}

// returns an object with all the requested keys (from function params)
export const useGetQueryParams = (...keys: string[]): QueryParams => {
  const { search } = useLocation();
  const values = {};

  keys.forEach((key) => {
    const value = new URLSearchParams(search).get(key);
    values[key] = value;
  });

  return values;
};
