// NOTES: These next expressions are needed to look correctly into a string (from first to last position)
// for invalid characters that don't match with the Regex:
// the $ expression allows to assert the last position of the string
// the ^ will allow to assert the first position of the string
// the + "quantifier" expression allows to find all matching text of a string from first to last position

// REGEX PATTERNS
const LETTERS_SPACES_PERIODS_REGEX = /^([a-zA-Z.]+\s)*[a-zA-Z.]+$/;

// Allowed alpha numerics and special characters based on ticket SOFT-8186
// Must only allow alpha numeric and some special characters
export const ALPHA_NUMERIC_AND_SPECIAL_CHARACTERS_REGEX = /^[a-zA-Z0-9-'/. !@#$%&*+]+$/;

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

// names - alpha of any case, comma, period, apostrophe, right single quotation mark (u2019), space and hyphen (ie a-zA-Z,.'-)
// examples: "Mary-Anne", "John, Jr.", “Baba O’Reily”
const NAME_REGEX = /^[A-Za-z-’'",.+\s]+$/;

// Allows letters and numbers only without spaces
const ONLY_LETTERS_AND_NUMBERS_REGEX = /^[a-zA-Z0-9]*$/;

const ONLY_LETTERS_SPACES_AND_NUMBERS_REGEX = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;

// REGEX FAIL MESSAGES
const LETTERS_SPACES_PERIODS_MESSAGE =
  "Only letters, spaces, and periods are allowed, other special characters or numbers are not permitted";

export const ALLOWED_SPECIAL_CHARACTERS_MESSAGE = `Only letters, numbers, spaces and certain special characters (- / . ! @ # $ % & * +) are allowed.`;

const NAME_REGEX_MESSAGE =
  "Only letters, commas, periods, hyphens, or spaces are allowed, other special characters are not permitted";

export const INVALID_EMAIL_MESSAGE =
  "Please enter valid email address. Ensure it contains no spaces.";

export const ONLY_LETTERS_AND_NUMBER_MESSAGE =
  "Only letters and numbers are allowed, spaces or special characters are not permitted";

export const ONLY_LETTERS_SPACES_AND_NUMBER_MESSAGE =
  "Only letters and numbers are allowed, spaces or special characters are not permitted";

// VALIDATION FUNCTIONS - used for custom validation rather than the simple builtin react-hook-from "rules" prop
export const requiredField = (label: string) => ({
  required: {
    value: true,
    message: `Please enter a ${label}`,
  },
});

// Allows only Alpha numeric and some special characters: - / . ! @ # $ % & * +
// this is based on ticket SOFT-8186
export const onlyAllowedSpecialCharacters = () => {
  return {
    pattern: {
      value: ALPHA_NUMERIC_AND_SPECIAL_CHARACTERS_REGEX,
      message: ALLOWED_SPECIAL_CHARACTERS_MESSAGE,
    },
  };
};

export const onlyLettersSpacesPeriodsValidation = () => {
  return {
    pattern: {
      value: LETTERS_SPACES_PERIODS_REGEX,
      message: LETTERS_SPACES_PERIODS_MESSAGE,
    },
  };
};

export const onlyLettersAndNumbers = () => ({
  pattern: {
    value: ONLY_LETTERS_AND_NUMBERS_REGEX,
    message: ONLY_LETTERS_AND_NUMBER_MESSAGE,
  },
});

export const onlyLettersSpacesAndNumbers = () => ({
  pattern: {
    value: ONLY_LETTERS_SPACES_AND_NUMBERS_REGEX,
    message: ONLY_LETTERS_SPACES_AND_NUMBER_MESSAGE,
  },
});

export const emailField = (label: string, required: boolean) => ({
  required: {
    value: required,
    message: `Please enter ${label}`,
  },
  pattern: {
    value: EMAIL_REGEX,
    message: INVALID_EMAIL_MESSAGE,
  },
});

export const nameValidation = () => {
  return {
    pattern: {
      value: NAME_REGEX,
      message: NAME_REGEX_MESSAGE,
    },
  };
};

export const minLengthField = (label: string, length: number) => ({
  minLength: {
    value: length,
    message: `${label} must be at least ${length} characters`,
  },
  pattern: {
    value: /(?!^ +$)^.+$/i,
    message: "Please do not enter all spaces",
  },
});

export const maxLengthField = (label: string, length: number) => ({
  maxLength: {
    value: length,
    message: `${label} should not be more than ${length} characters`,
  },
});
