import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';

import './ProfileBreadcrumbs.scss';

class ProfileBreadcrumbs extends PureComponent {

  // allowing onClick to be optional
  onClick = (callback) => callback && callback();
  
	render() {
		return (
			<div className="profile-breadcrumbs">
				{
					this.props.options.map((option, idx) => (
						<NavLink
							className={classNames({
								'profile-breadcrumbs__item': true,
								[option.className]: option.className,
							})}
							// eslint-disable-next-line react/no-array-index-key
							key={`${option.title}_${idx}`}
							to={option.to}
							onClick={() => this.onClick(option.onClick)}
						>
							{option.label && (
								<div
									className={
										classNames({
											'profile-breadcrumbs__label': true,
											[option.labelClassName]: option.labelClassName,
										})
									}
								>
									{option.label}
								</div>
							)}
							<Popup
								trigger={
									<div className="profile-breadcrumbs__link">
										{option.title}
									</div>}
								hoverable

								basic
								position="bottom right"
							>
								{option.title}
							</Popup>
						</NavLink>
					))
				}
			</div>
		);
	}
}

export default ProfileBreadcrumbs;
