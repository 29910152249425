import { PointerActionKeys, PointerActionType } from "./actionTypes";
import { PointerState } from "../models";

const initialState: PointerState = {
  telestration: "off",
  color: "green",
  highlights: []
};

const pointer = (
  state = initialState,
  action: PointerActionType
): PointerState => {
  switch (action.type) {
    case PointerActionKeys.POINTER_ADD_HIGHLIGHT:
      return { ...state, highlights: [...state.highlights, action.highlight] };

    case PointerActionKeys.POINTER_REMOVE_HIGHLIGHT:
      const filtered = state.highlights.filter(
        h => h.uniqueIdentifier.toString() !== action.id
      );
      return { ...state, highlights: filtered };

    case PointerActionKeys.UPDATE_TELESTRATION_STATE:
      return {
        ...state,
        telestration: action.telestration,
        color: action.stylusColor
      };

    default:
      return state;
  }
};

export default pointer;
