import { NoiseCancellationEvent } from "availkit-js/dist/Models/Events/NoiseCancellationEvent";

import clsx from "clsx";

import { Switch } from "@mui/material";

import { Font } from "src/components/Font";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { selectStreamState } from "src/domains/Beacon/store/stream/selectors";
import { AvailKitService } from "src/services/AvailKitService";

import styles from "./styles.scss";

export interface NoiseReductionToggleProps {
  "data-test-id"?: string;
}

export const NoiseReductionToggle = ({
  "data-test-id": dataTestId,
}: NoiseReductionToggleProps) => {
  const availKit = AvailKitService.instance;
  const {
    noiseCancellationAvailable,
    noiseCancellationEnabled,
  } = useAppSelector(selectStreamState);
  const { callSid } = useAppSelector(selectMeetingState);

  const handleChange = () => {
    if (availKit) {
      const event = new NoiseCancellationEvent(
        callSid,
        !noiseCancellationEnabled
      );
      availKit.eventService.broadcast(event);
    }
  };

  return (
    <div className={styles.root} data-test-id={dataTestId}>
      <div className={styles.toggleContainer}>
        <Font
          variant="h3"
          color={noiseCancellationAvailable ? "light" : "dark-grey"}
        >
          Noise Reduction
        </Font>
        {/* TODO: make into a  Switch component*/}
        <Switch
          classes={{
            root: clsx(
              styles.switch,
              noiseCancellationEnabled ? styles.on : styles.off
            ),
            thumb: clsx(styles.thumb),
            track: clsx(styles.track),
          }}
          // !noiseCancellationAvailable if audio source is bluetooth OR
          // if the Console does not support noise reduction
          disabled={!noiseCancellationAvailable}
          onChange={handleChange}
          checked={noiseCancellationEnabled}
        />
      </div>
      {/* Tungsten design uses Font.color === "disabled" when enabled */}
      <Font
        variant="b4"
        color={noiseCancellationAvailable ? "disabled" : "dark-grey"}
      >
        Turn on to reduce background noise in the procedure room
      </Font>
    </div>
  );
};
