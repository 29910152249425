import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Videocam, VideocamOff } from "@mui/icons-material";

import { Button } from "src/components/Button";
import { Select } from "src/components/Select";
import { Camera } from "src/domains/Beacon/components/Camera";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectLocalMedia } from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { publishVideoTrackThunk } from "src/domains/Beacon/store/stream/thunks/publishVideoTrackThunk";
import { uiActions } from "src/domains/Beacon/store/ui";
import {
  getLocalVideoStream,
  stopMediaTracks,
} from "src/domains/Beacon/utils/mediaDevices";

import styles from "./styles.scss";

export const VideoSettings = () => {
  const [videoStream, setVideoStream] = useState<MediaStream>();
  const [showCamera, setShowCamera] = useState(true);
  const localMedia = useAppSelector(selectLocalMedia);
  const dispatch = useDispatch();

  // Updates the videoStream state to passed it to the Camera component and show
  // the user's camera
  const setCameraStream = async () => {
    const cameraDevice = localMedia?.camera?.deviceId;
    if (cameraDevice) {
      setVideoStream(await getLocalVideoStream(cameraDevice));
    }
  };

  // When leaving the VideoSettings, the video will be published to Twilio again
  useEffect(() => {
    // Gets the user's local video stream to use it in the Camera component below
    setCameraStream();
    return () => {
      // We publish (not enable) the new VideoTrack due to the new selected camera
      dispatch(publishVideoTrackThunk());
      dispatch(uiActions.resumePipVideo());
    };
  }, []);

  // Detects when the camera device has changed in order to update the local video stream
  useEffect(() => {
    setCameraStream();
  }, [localMedia?.camera?.deviceId]);

  // Turns on/off the local Camera video stream
  const toggleCamera = async () => {
    const [track] = videoStream.getVideoTracks();
    if (track.readyState === "live") {
      stopMediaTracks(videoStream);
    } else {
      setVideoStream(await getLocalVideoStream(localMedia.camera.deviceId));
    }
    setShowCamera(!showCamera);
  };

  const changeVideoInput = (e) => {
    const { videoInputs } = localMedia.mediaDevices;
    const camera = videoInputs.find((v) => v.deviceId === e.target.value);
    dispatch(streamActions.setCameraDevice(camera));
  };

  return (
    <div className={styles.list}>
      <div className={styles.group}>
        <Select
          label="Video Source"
          name="video source"
          onChange={changeVideoInput}
          value={localMedia?.camera?.deviceId || ""}
          options={localMedia?.mediaDevices.videoInputs.map((camera) => ({
            title: camera.label,
            value: camera.deviceId,
          }))}
        />
        <Button
          variant="icon"
          tooltipProps={{
            title: !showCamera ? "Show" : "Stop",
            placement: "top",
          }}
          onClick={toggleCamera}
          startIcon={showCamera ? <Videocam /> : <VideocamOff />}
        />
      </div>
      <Camera width="290px" videoStream={videoStream} />
    </div>
  );
};
