import { CDR } from './CallDetailRecordActionTypes';

export const getCallHistoryAction = params => ({
	type: CDR.GET_CALL_HISTORY,
	payload: params,
});

export const setCallHistoryAction = history => ({
	type: CDR.SET_CALL_HISTORY,
	payload: history,
});

export const setCallHistoryPaginationAction = data => ({
	type: CDR.SET_CALL_HISTORY_PAGINATION,
	payload: data,
});

export const setToAndFromAction = toAndFrom => ({
	type: CDR.SET_TO_FROM,
	payload: toAndFrom,
});
