export const getInitialValuesForConsoleProfile = (state) => {
	const { console } = state.consolesReducer;

	if (console) {
		return {
			serialNumber: console.serialNumber,
			room: console.room,
			macAddress: console.macAddress,
			version: console.version,
			hospitalName: console.hospital.name,
			hospitalId: console.hospital.id,
		};
	}
	return null;
};
