import { call, cancel, put } from "redux-saga/effects";

import { API } from "../../../api";
import { logger } from "../../../common/logger";
import { CAMERA_NOT_DETECTED } from "../../errorCodes";
import { mediaErrorEncountered } from "../../twilio/actions";
import { getLocalMediaFailed, getLocalMediaSuccess } from "../actions";

export function* getLocalMediaSaga() {
  const { data, error } = yield call(API.GET.localMedia);

  if (error || !data || !data.localMedia) {
    logger().error("getLocalMedia failed", JSON.stringify(error));

    yield put(getLocalMediaFailed());
    if (data && !data.localMedia) {
      yield put(mediaErrorEncountered(CAMERA_NOT_DETECTED));
    }
    yield cancel();
    return;
  }

  logger().info("getLocalMedia success.");
  yield put(getLocalMediaSuccess(data));
}
