// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root_ZdyZKj .styles_header_-QVPnp{text-align:center}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/LeaveCallHandler/LeaveCallModal/styles.scss"],"names":[],"mappings":"AACE,0CACE,iBAAA","sourcesContent":[".root {\n  .header {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root_ZdyZKj",
	"header": "styles_header_-QVPnp"
};
export default ___CSS_LOADER_EXPORT___;
