import { EventStatus } from "src/services/ApiClient/scheduler";

import { EventDetailsResponse, EventListResponse, EventRequestBody } from ".";
import { getQueryString } from "../../../utils/ApiHelper";
import ApiQuery from "../ApiQuery";
import { BASE_API_URL } from "../utils";

const BASE_SCHEDULER_API_URL = `${BASE_API_URL}/scheduler/v2`;

export const getUserEventsByUserId = (
  userId: string,
  from: string,
  to: string,
  paged: boolean,
  pageNumber: number,
  pageSize: number,
  sort,
  statuses: EventStatus[]
) => {
  // the statuses query param is repeated for every status that is needed (CREATED, STARTED, etc), so we must construct it individually
  // EG ?from=10%2F21%2F2021&statuses=ENDED&statuses=EXPIRED&statuses=CANCELLED&to=10%2F21%2F2021
  let statusesParam = "";
  if (statuses.length) {
    const param = statuses.map((status) => `statuses=${status}`).join("&");
    statusesParam = `&${param}`;
  }

  return ApiQuery<EventListResponse>(
    `${BASE_SCHEDULER_API_URL}/events/users/${userId}${getQueryString({
      from,
      to,
      paged: `${paged}`,
      page: pageNumber,
      size: pageSize,
      sort,
    })}${statusesParam}`
  );
};

export const createMultiPartyEvent = (body: EventRequestBody) =>
  ApiQuery<undefined, EventRequestBody>(
    `${BASE_SCHEDULER_API_URL}/events`,
    "POST",
    body
  );

export const updateMultiPartyEvent = (
  eventId: string,
  body: EventRequestBody
) =>
  ApiQuery<undefined, EventRequestBody>(
    `${BASE_SCHEDULER_API_URL}/events/${eventId}`,
    "PUT",
    body
  );

export const getEventDetail = (id: string) =>
  ApiQuery<EventDetailsResponse>(`${BASE_SCHEDULER_API_URL}/events/${id}`);

// the API returns the whole event object back but with status set to CANCELLED
export const cancelEvent = (id: string) =>
  ApiQuery<EventDetailsResponse>(
    `${BASE_SCHEDULER_API_URL}/events/${id}/cancel`,
    "POST"
  );
