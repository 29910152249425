import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, reset, change } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Can from '../../../../../../../../Common/Acl/AccessControl';
import {
	Button,
	Fields,
	Icon,
	validators,
} from '../../../../../../../../Common/Components';
import { notificationService } from '../../../../../../../../Common/Services';
import { getcurrentUserTypes } from '../../../../../../HomeSelectors';
import {
	getUserSettingsAction,
	updateUserSettingsAction,
	updateUserPasswordAction,
	setPassphrasesAction,
} from '../../../../UsersActions';
import {
	getAllDescendantUsersIds,
	getInitialValuesForUserPreferences,
} from '../../../../UsersSelectors';


class UserPreferences extends PureComponent {
	componentDidMount() {
		this.props.getUserSettings(this.props.userId);
	}

	handleSubmit = (data) => {
		const changeData = {
			oldPassword: data.old_password,
			password: data.new_password,
			confirmPassword: data.confirm_password,
		};
		if (this.props.initialValues.value !== data.value) {
			this.props.updateUserSettings(this.props.userId, {
				type: 'EMAIL_NOTIFICATION',
				value: data.value,
			});
		}
		if(data.passphrase !== null && data.passphrase !== undefined && this.props.initialValues.passphrase != data.passphrase){
			if(data.passphrase.match(/^\s*$/g)){
				notificationService.error("Empty spaces are not allowed");
			}else{
				const passphraseVal = data.passphrase.trim();
				this.props.setPassphrases({userId:this.props.userId,passphraseData: passphraseVal});
			}
		}
		if (
			data.old_password === undefined || data.old_password === null &&
			data.new_password === undefined || data.new_password === null &&
			data.confirm_password === undefined || data.confirm_password === null
		) { return; }
		this.props.updateUserPassword(this.props.userId, changeData);
	};

	render() {
		return (
			<form
				className="avail-profile__edit-form flex-direction--row justify-content--space-between"
				onSubmit={this.props.handleSubmit(this.handleSubmit)}
			>
				<div className="user-profile__preferences-marginTop">
					<h>Notifications</h>
					<hr className="user-profile__preferences-hor-line" />
					<span className="user-profile__label">Receive Notifications?</span>
					<div className="user-profile__preferences-radio-button-wrapper">
						<Fields.RadioButtonField
							label="Yes"
							name="value"
							value="ALWAYS"
							className="avail__radio-button"
							disabled={this.props.userId !== this.props.currentUser.id}
						/>
						<Fields.RadioButtonField
							label="No"
							name="value"
							value="NEVER"
							className="avail__radio-button"
							disabled={this.props.userId !== this.props.currentUser.id}
						/>
					</div>
					<br />
					<br />
					<h>Passphrase </h>
					<hr className="user-profile__preferences-hor-line" />
					<Fields.InputField
							type="text"
							className="avail__input"
							labelText="Passphrase"
							autoComplete="new-password"
							placeholder="Enter Passphrase"
							name="passphrase"
							value={this.props.passphrase}
							validate={[validators.alphaNumaric,validators.mimimumLength10,validators.maximumLength255]}
							disabled={this.props.userId !== this.props.currentUser.id}
						/>
					<div>
						<br />
						<br />
						<h>Change Password</h>
						<hr className="user-profile__preferences-hor-line" />
						<h>Use a strong password that you're not using elsewhere</h>
						<div className="user-profile__preferences-strongpassword-margintop">
							<Fields.InputField
								type="password"
								className="avail__input"
								labelText="Current Password"
								placeholder="Enter current password"
								name="old_password"
								validate={[
									validators.minimumLength2,
								]}
								disabled={this.props.userId !== this.props.currentUser.id}
							/>
						</div>
						<Fields.InputField
							type="password"
							className="avail__input"
							labelText="New Password"
							placeholder="Enter new password"
							name="new_password"
							validate={[
								validators.numberValidator,
								validators.capitalValidator,
								validators.lowerValidator,
								validators.specialValidator,
								validators.minimumLength8,
								validators.maximumLength15,
								validators.oldPasswordValidator,
							]}
							disabled={this.props.userId !== this.props.currentUser.id}
						/>
						<Fields.InputField
							type="password"
							className="avail__input"
							labelText="Confirm Password"
							placeholder="Retype your password"
							name="confirm_password"
							validate={[validators.newPasswordValidator]}
							disabled={this.props.userId !== this.props.currentUser.id}
						/>
						<br />
					</div>
				</div>

				<Can
					userTypes={this.props.currentUserTypes}
					action="read"
					context="preferences"
					data={{
						currentUserId: this.props.currentUser && this.props.currentUser.id,
						userProfileId: this.props.userId,
						descendantUsersIds: this.props.descendantUsersIds,
					}}
				>
					<div className="user-profile__preferences-height-save-button" >
						<Button
							className="button-with-icon align-self--flex-start padding--none"
							type="submit"
							disabled={this.props.userId !== this.props.currentUser.id}
						>
							<Icon className="icon-save icon--orange entity-profile__submit-icon" />
							<span className="entity-profile__submit-title">Save changes</span>
						</Button>
					</div>
				</Can>
			</form>
		);
	}
}

UserPreferences.propTypes = {};
const afterSubmit = (result, dispatch) => {
	dispatch(change('userPreferenceForm', 'old_password', null));
	dispatch(change('userPreferenceForm', 'new_password', null));
	dispatch(change('userPreferenceForm', 'confirm_password', null));
};

const mapStateToProps = state => ({
	initialValues: getInitialValuesForUserPreferences(state),
	currentUser: state.homeReducer.currentUser,
	currentUserTypes: getcurrentUserTypes(state),
	descendantUsersIds: getAllDescendantUsersIds(state),
});

const mapDispatchToProps = dispatch => ({
	updateUserSettings: (userId, userSettings) =>
		dispatch(updateUserSettingsAction(userId, userSettings)),
	updateUserPassword: (userId, changeData) =>
		dispatch(updateUserPasswordAction(userId, changeData)),
	getUserSettings: userId => dispatch(getUserSettingsAction(userId)),
	resetReduxForm: formName => dispatch(reset(formName)),
	changeFieldValue: (field, value) => dispatch(change('userPreferenceForm', field, value)),
	setPassphrases: (userId, passphraseData) => dispatch(setPassphrasesAction(userId, passphraseData)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(
		reduxForm({
			form: 'userPreferenceForm',
			onSubmitSuccess: afterSubmit,
			enableReinitialize: true,
      touchOnChange: true,
		})(UserPreferences),
	),
);
