import { CDR } from './CallDetailRecordActionTypes';

const initialState = {
	history: [],
	to: null,
	from: null,
	pagination: {
		page: 0,
		pageSize: 10,
		totalPages: 1,
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case CDR.SET_CALL_HISTORY:
			return { ...state, history: action.payload };
		case CDR.SET_CALL_HISTORY_PAGINATION:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload.pageNumber,
					size: action.payload.pageSize,
					totalPages: action.payload.totalPages,
				},
			};
		case CDR.SET_TO_FROM:
			return {
				...state,
				to: action.payload.to,
				from: action.payload.from,
			};
		default:
			return state;
	}
}
