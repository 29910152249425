// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form_trn8BM{width:50%}.styles_form_trn8BM .styles_wrapper_cepiSD{margin-bottom:32px}", "",{"version":3,"sources":["webpack://./src/domains/TrialSignUp/components/DetailsForm/styles.scss"],"names":[],"mappings":"AAAA,oBACE,SAAA,CAEA,2CACE,kBAAA","sourcesContent":[".form {\n  width: 50%;\n\n  .wrapper {\n    margin-bottom: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form_trn8BM",
	"wrapper": "styles_wrapper_cepiSD"
};
export default ___CSS_LOADER_EXPORT___;
