import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { Fields, validators } from '../../../../../../Common/Components';
import { getAllBrandsAction } from '../../../Brands/BrandsActions';
import { getAllStatesAction } from '../../../Organisation/OrganisationActions';

class AccountCreationFormISROrganization extends PureComponent {
	componentDidMount() {
		this.props.getAllBrands();
		this.props.getAllStates();

		this.props.changeBrandId(this.props.userOrganisation.id);
	}

	render() {
		const { userOrganisation } = this.props;

		return (
			<div className="account-creation__therapies-form">
				<div className="account-creation__heading">
					User information
				</div>
				<div className="account-setup__accordion-wrapper">
					<label className="account-setup-org__label">Associated brand</label>
					<div className="account-setup-org">
						<span className="multi-profile__text">{userOrganisation.name}</span>
						<img className="multi-profile__img" src={userOrganisation.logoLocation} alt={`${userOrganisation.name} logo`} />
					</div>

					<Fields.InputField
						labelText="User's address"
						placeholder="Enter your address"
						name="ISROrganization.address"
						validate={[validators.required, validators.minimumLength3, validators.maximumLength128]}
					/>
					<Fields.InputField
						labelText="User's city"
						placeholder="Enter your city"
						name="ISROrganization.city"
						validate={[validators.required, validators.minimumLength2, validators.maximumLength64]}
					/>
					<Fields.SelectField
						className="avail__input"
						labelText="User's State"
						placeholder="Enter your state"
						name="ISROrganization.state"
						validate={[
							validators.required,
							validators.minimumLength2,
							validators.maximumLength64,
						]}
						search
						options={this.props.states && this.props.states.map(state => ({
							key: state.id,
							value: state.name,
							text: `${state.name} (${state.abbreviation})`,
						}))}
					/>
				</div>
			</div>
		);
	}
}

AccountCreationFormISROrganization.defaultProps = {
	brands: [],
	states: [],
};

AccountCreationFormISROrganization.propTypes = {
	brands: PropTypes.array,
	states: PropTypes.array,
};

const mapStateToProps = state => ({
	brands: state.brandsReducer.brands,
	states: state.organisationsReducer.states,
});

const mapDispatchToProps = dispatch => ({
	getAllBrands: () => dispatch(getAllBrandsAction()),
	getAllStates: () => dispatch(getAllStatesAction()),
	changeBrandId: brandId => dispatch(change('accountCreationForm', 'ISROrganization.brandId', brandId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreationFormISROrganization);
