import { reducer as formReducer } from "redux-form";
import createSagaMiddleware from "redux-saga";

import { connectRouter, routerMiddleware } from "connected-react-router";

import { createBrowserHistory } from "history";
import { Action, createStore, compose, applyMiddleware } from "redux";

import { combineReducers } from "@reduxjs/toolkit";

import InterceptorService from "../avail-web-application/src/Common/Services/interceptorService";
import { AUTH } from "../avail-web-application/src/Features/Authentication/AuthenticationActionTypes";
import { logoutAction } from "../avail-web-application/src/Features/Authentication/AuthenticationActions";
import AuthenticationReducer from "../avail-web-application/src/Features/Authentication/AuthenticationReducer";
import ApprovalListReducer from "../avail-web-application/src/Features/Home/Common/ApprovalList/ApprovalListReducer";
import TabsReducer from "../avail-web-application/src/Features/Home/Common/Tabs/TabsReducers";
import AccountCreationReducer from "../avail-web-application/src/Features/Home/Features/AccountCreation/AccountCreationReducer";
import BrandsReducer from "../avail-web-application/src/Features/Home/Features/Brands/BrandsReducer";
import ConsolesReducer from "../avail-web-application/src/Features/Home/Features/Consoles/ConsolesReducer";
import DistributorsReducer from "../avail-web-application/src/Features/Home/Features/Distributors/DistributorsReducer";
import HospitalNetworkReducer from "../avail-web-application/src/Features/Home/Features/HospitalNetwork/HospitalNetworkReducer";
import MeetingReducer from "../avail-web-application/src/Features/Home/Features/Meeting/MeetingReducer";
import BillingReducer from "../avail-web-application/src/Features/Home/Features/Organisation/Billing/BillingReducer";
import CDRReducer from "../avail-web-application/src/Features/Home/Features/Organisation/CallDetailRecord/CallDetailRecordReducer";
import DepartmentReducer from "../avail-web-application/src/Features/Home/Features/Organisation/Hospitals/Features/DepartmentProfile/DepartmentsReducer";
import HospitalsReducer from "../avail-web-application/src/Features/Home/Features/Organisation/Hospitals/HospitalsReducer";
import OrganisationReducer from "../avail-web-application/src/Features/Home/Features/Organisation/OrganisationReducer";
import VendorsReducer from "../avail-web-application/src/Features/Home/Features/Organisation/Vendors/VendorsReducer";
import ProviderReducer from "../avail-web-application/src/Features/Home/Features/Provider/ProviderReducer";
import SchedulerReducer from "../avail-web-application/src/Features/Home/Features/Scheduler/SchedulerReducer";
import UsersReducer from "../avail-web-application/src/Features/Home/Features/Users/UsersReducers";
import { toggleLoader } from "../avail-web-application/src/Features/Home/HomeActions";
import HomeReducer from "../avail-web-application/src/Features/Home/HomeReducer";
import rootSaga from "../avail-web-application/src/rootSaga";
import { AuthReducers } from "../avail-web-ui/src/dux/AuthSlice";
import IdleSaga from "../avail-web-ui/src/dux/IdleSaga";
import { IdleReducers } from "../avail-web-ui/src/dux/IdleSlice";
import { MeetingReducers } from "../avail-web-ui/src/dux/MeetingSlice";
import { NotificationReducers } from "../avail-web-ui/src/dux/NotificationSlice";
import {
  getUserInfo,
  getUserProfiles,
} from "../avail-web-ui/src/services/UserSessionService";
import availKit from "../portalcall/src/commoncall/store/availkit/reducer";
import image from "../portalcall/src/commoncall/store/image/reducer";
import meeting from "../portalcall/src/commoncall/store/meeting/reducer";
import pointer from "../portalcall/src/commoncall/store/pointer/reducer";
import preset from "../portalcall/src/commoncall/store/preset/reducer";
import portalcallSaga from "../portalcall/src/commoncall/store/sagas";
import { TwilioActionKeys } from "../portalcall/src/commoncall/store/twilio/actionTypes";
import twilio from "../portalcall/src/commoncall/store/twilio/reducer";
import user from "../portalcall/src/commoncall/store/user/reducer";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const preloadedState = {
  auth: {
    // TODO - rename to userInfo?
    profile: getUserInfo(),
    profiles: getUserProfiles(),
  },
};

const enhancers = [];
const middleware = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  const { __REDUX_DEVTOOLS_EXTENSION__ } = window as any;

  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === "function") {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

// TODO: Fix typings here
const appReducer = combineReducers({
  // web ui
  auth: AuthReducers,
  idle: IdleReducers,
  web_ui_meeting: MeetingReducers,
  notifications: NotificationReducers,
  router: connectRouter(history),
  // web application
  form: formReducer,
  usersReducer: UsersReducer,
  tabsReducer: TabsReducer,
  vendorsReducer: VendorsReducer,
  hospitalsReducer: HospitalsReducer,
  authenticationReducer: AuthenticationReducer,
  homeReducer: HomeReducer,
  schedulerReducer: SchedulerReducer,
  accountCreationReducer: AccountCreationReducer,
  organisationsReducer: OrganisationReducer,
  departmentReducer: DepartmentReducer,
  brandsReducer: BrandsReducer,
  billingReducer: BillingReducer,
  consolesReducer: ConsolesReducer,
  approvalListReducer: ApprovalListReducer,
  hospitalNetworkReducer: HospitalNetworkReducer,
  providerReducer: ProviderReducer,
  distributorsReducer: DistributorsReducer,
  meetingReducer: MeetingReducer,
  callDetailRecordReducer: CDRReducer,
  // portalcall
  user,
  pointer,
  twilio,
  image,
  preset,
  availKit,
  meeting,
});

// TODO: Move this logic inside the portalcall
// namespaced reducers
const rootReducer = (state: any, action: Action) => {
  if (action.type === TwilioActionKeys.TWILIO_RECONNECTION) {
    // set states to initial state
    state.twilio = undefined;
    state.user = undefined;
  }

  if (action.type === AUTH.LOGOUT) {
    state = { router: state.router };
  }

  return appReducer(state, action);
};

const store = createStore(rootReducer, preloadedState, composedEnhancers);

InterceptorService.setupResponseInterceptors(
  () => store.dispatch(toggleLoader()),
  () => store.dispatch(logoutAction()),
  store
);

sagaMiddleware.run(rootSaga);
sagaMiddleware.run(IdleSaga);
sagaMiddleware.run(portalcallSaga);

export default store;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
