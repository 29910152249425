import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, Icon, validators, Fields } from '../../../../../../../../../Common/Components';
import { getAllStatesAction } from '../../../../../OrganisationActions';
import { updateHospitalAction } from '../../../../HospitalsActions';

class HospitalInfo extends PureComponent {
	componentDidMount() {
		this.props.getAllStates();
	}

	onHospitalUpdateHandler = data => this.props.updateHospital(this.props.hospitalId, data);

	render() {
		const { t } = this.props;
		return (
			<form
				className="avail-profile__edit-form flex-direction--row justify-content--space-between"
				onSubmit={this.props.handleSubmit(this.onHospitalUpdateHandler)}
			>
				<div>
					<Fields.InputField
						className="avail__input"
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.placeholder')}
						name="name"
						validate={[
							validators.required,
							validators.minimumLength3,
							validators.maximumLength48]}
					/>
					<Fields.SelectField
						className="avail__input"
						labelText={t('common.form.state.label')}
						placeholder={t('common.form.state.placeholder')}
						name="state"
						validate={[
							validators.required,
							validators.minimumLength2,
							validators.maximumLength64]}
						search
						options={this.props.states && this.props.states.map(state => ({
							key: state.id,
							value: state.name,
							text: `${state.name} (${state.abbreviation})`,
						}))}
					/>
					<Fields.InputField
						className="avail__input"
						labelText={t('common.form.city.label')}
						placeholder={t('common.form.city.placeholder')}
						name="city"
						validate={[
							validators.required,
							validators.minimumLength2,
							validators.maximumLength64]}
					/>
					<Fields.InputField
						className="avail__input"
						labelText={t('common.form.address.label')}
						placeholder={t('common.form.address.placeholder')}
						name="address"
						validate={[
							validators.required,
							validators.minimumLength3,
							validators.maximumLength128]}
					/>
					<Fields.SelectField
						labelText={t('common.form.network.label')}
						placeholder={t('common.form.network.placeholder')}
						name="networkId"
						options={this.props.hospitalNetworks && this.props.hospitalNetworks.map(hospitalNetwork => ({
							key: hospitalNetwork.id,
							value: hospitalNetwork.id,
							text: hospitalNetwork.name,
						}))}
						onChange={this.selectBrand}
						clearable
					/>
				</div>
				<div>
					<Button className="button-with-icon align-self--flex-start padding--none" type="submit">
						<Icon className="icon-save icon--orange entity-profile__submit-icon" />
						<span className="entity-profile__submit-title">Save Changes</span>
					</Button>
				</div>
			</form>
		);
	}
}

HospitalInfo.propTypes = {};

const mapStateToProps = state => ({
	initialValues: state.hospitalsReducer.hospital,
	hospitalNetworks: state.hospitalNetworkReducer.hospitalNetworks,
	states: state.organisationsReducer.states,
});

const mapDispatchToProps = dispatch => ({
	updateHospital: (id, data) => dispatch(updateHospitalAction(id, data)),
	getAllStates: () => dispatch(getAllStatesAction()),
});

const HospitalInfoWithTranslation = withTranslation()(HospitalInfo);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'hospitalInfoForm',
	enableReinitialize: true,
})(HospitalInfoWithTranslation)));
