import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import DropdownComponent from '../../../../../../../Common/DropdownComponent/DropdownComponent';

class RoomTableRow extends PureComponent {
	redirect = () => {
		this.props.history.push({
			pathname: '/hospitals/rooms/profile',
			search: `${this.props.urlParams}&roomId=${this.props.rowData.id}`,
		});
	};

	openDeleteUserModal = () => this.props.openModal(this.props.rowData);

	render() {
		const { name, type } = this.props.rowData;
		return (
			<Table.Row onClick={this.redirect}>
				<Table.Cell title={[name].join(' ')}>
					<div className="table__username-wrapper display--flex">
						<span className="table__link">{name}</span>
					</div>
				</Table.Cell>
				<Table.Cell title={[type].join(' ')}>{type}</Table.Cell>
				<Table.Cell className="table__action-table-cell">
					<div className="justify-content--flex-end">
						<DropdownComponent
							dropdownOptions={[{
								className: 'dropdown__menu-item--red',
								label: 'Delete',
								text: 'Delete',
								action: this.openDeleteUserModal,
								icon: 'icon-trash-2-outline',
							}]}
						/>
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}

RoomTableRow.defaultProps = {
	rowData: null,
};

RoomTableRow.propTypes = {
	rowData: PropTypes.shape(),
};

export default withRouter(RoomTableRow);
