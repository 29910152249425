import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/domains/Beacon/store";
import { getParticipantAudioTracks } from "src/domains/Beacon/utils/twilio";
import { logger, LoggerLevels } from "src/logging/logger";

/**
 * Unpublish the audio track if the current participant, publishing an empty
 * audio track will and updating every participant's audio for this participant.
 * The update occurs in the TwilioSubscriptions component
 */
// Automatically generates pending, fulfilled and rejected action types
// (see `createAsyncThunk` docs: https://redux-toolkit.js.org/api/createAsyncThunk)
export const unpublishAudioTrackThunk = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  void,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("stream/unpublishAudioTrackThunk", async (_arg, { getState }) => {
  try {
    logger().info("User is Un-publishing audio tracks");
    const { twilio } = getState();
    const {
      room: { localParticipant },
    } = twilio;

    const tracks = getParticipantAudioTracks(localParticipant);

    // Must stop the audio tracks so the indicator in the machine disappears,
    // NOT the red indicator of the browser
    tracks.map((t) => t.stop());

    // Logs in order to know the tracks shape in case of an error
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "unpublishAudioTrackThunk",
      },
      `Un-publishing audio tracks: ${JSON.stringify(tracks)}`
    );

    await localParticipant.unpublishTracks(tracks);
  } catch (error: any) {
    logger().error("Error un-publishing audio tracks", error?.message);
    throw new Error("Error un-publishing audio track.");
  }
});
