import { useQuery } from "react-query";

import { FetchError } from "src/services/ApiClient/ApiQuery";
import {
  EventDetailsResponse,
  EventListResponse,
  EventStatus,
  getEventDetail,
  getUserEventsByUserId,
} from "src/services/ApiClient/scheduler";

const KEY = "user-events";

// TODO figure out a better way to add additional query keys to useQuery from outside
export function useGetUserEvents(
  userId: string,
  queryKeys: any,
  updatedEventId: string,
  options: {
    from: string;
    to: string;
    paged?: boolean;
    pageNumber?: number;
    pageSize?: number;
    sort?: string;
    statuses?: EventStatus[];
  }
) {
  const { from, to, paged, pageNumber, pageSize, sort, statuses } = options;

  return useQuery<EventListResponse, FetchError>(
    // the array of keys is similar to useEffect deps, if key changes then the query runs again
    [`${KEY}-${userId}`, queryKeys, pageNumber, updatedEventId],
    () => {
      return getUserEventsByUserId(
        userId,
        from,
        to,
        paged,
        pageNumber,
        pageSize,
        sort,
        statuses
      );
    }
  );
}

export const useGetEventDetails = (eventId: string) => {
  return useQuery<EventDetailsResponse, FetchError>(
    [`${KEY}-${eventId}`],
    () => {
      return getEventDetail(eventId);
    },
    // setting cache time to 0 since this can be called frequently from the events list page
    // when clicking on edit details shortly after updating an event
    {
      cacheTime: 0,
    }
  );
};
