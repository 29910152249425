import { Typography } from "@mui/material";

import styles from "./styles.scss";

export interface UsagePageProps {
  topText: string;
  bottomText: string;
  remaining: number;
  total: number;
  "data-test-id"?: string;
}

export const TrialDisplay = ({
  remaining,
  total,
  topText,
  bottomText,
  "data-test-id": dataTestId,
}: UsagePageProps) => (
  <div className={styles.container}>
    <Typography className={styles.top}>{topText}</Typography>
    <Typography className={styles.main}>
      <span className={styles.trialRemaining} data-test-id={dataTestId}>
        {remaining}
      </span>
      <span className={styles.divider}>/</span>
      <span className={styles.trialTotal}>{total}</span>
    </Typography>
    <Typography className={styles.bottom}>{bottomText}</Typography>
  </div>
);
