/** NetworkSettings documentation
 */
import React from "react";

import { Font } from "src/components/Font";
import { NetworkMeter } from "src/domains/Beacon/components/SidePanel/SettingsContent/NetworkStatusSettings/NetworkMeter";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";

import styles from "./styles.scss";

export interface NetworkSettingsProps {
  "data-test-id"?: string;
}

export const NetworkStatusSettings = () => {
  const { localNetworkQualityLevel } = useAppSelector(selectTwilioState);
  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <Font variant="h3" color="light" className={styles.title}>
          My Network Connection
        </Font>
        <Font variant="b4" color="disabled" className={styles.description}>
          You can check the status of your network connection.
        </Font>
        <NetworkMeter value={localNetworkQualityLevel} />
      </div>
    </div>
  );
};
