import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectIsLaunchDarklyFetchDone } from "src/domains/Beacon/store/meeting/selectors";
import { joinCallWithoutPreCallThunk } from "src/domains/Beacon/store/meeting/thunks/joinCallWithoutPreCallThunk";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { useGetQueryParams } from "src/hooks/useGetQueryParams";
import { logger } from "src/logging/logger";

export const useJoinCallWithoutPreCall = () => {
  const dispatch = useDispatch();
  const { meeting_token: encodedMeetingToken } = useGetQueryParams(
    "meeting_token"
  );
  const {
    offerDeviceSelection,
    oRtelligence: enableORTelligence,
    externalInputsImageResizing,
  } = useFeatureFlags();
  const launchDarklyFetchDone = useAppSelector(selectIsLaunchDarklyFetchDone);

  useEffect(() => {
    // Must wait for LD flags `variations`, otherwise, this hook will run with intermittent values
    if (!launchDarklyFetchDone) {
      logger().warn(
        "Launch darkly flags aren't ready, will skip useJoinCallWithoutPreCall..."
      );
      return;
    }

    // Must not continue if user is able to see the PreCall screen
    if (offerDeviceSelection) {
      logger().info(
        `User has the ability to see the PreCall screen, must not join the call right-away`
      );
      return;
    }

    logger().info(`** User is joining the call without PreCall screen **`);

    dispatch(
      joinCallWithoutPreCallThunk({
        encodedMeetingToken,
        enableORTelligence,
        externalInputsImageResizing,
      })
    );
  }, [launchDarklyFetchDone, encodedMeetingToken]);
};
