import { BaseResponse } from "../types";

export enum EventStatus {
  created = "CREATED", // when event is created but no one has joined
  started = "STARTED", // once at least 1 person joins
  ended = "ENDED", // when everyone that was in the call has left
  expired = "EXPIRED", // expired events are created events that no one joins for over 1 hour
  cancelled = "CANCELLED", // when we cancel an event through the UI (or api)
}

export type Role = "HOST" | "PARTICIPANT" | "PANELIST" | "AUDIENCE";

export interface Participant {
  email: string;
  firstName: string;
  lastName: string;
  loginId: string;
  role: Role;
}

export interface Event {
  billingUserId: string;
  callSid: string;
  endDate: string;
  description: string;
  eventId: string;
  hospitalId: string;
  hospitalName: string;
  hostUserId: string;
  participants: Participant[];
  roomId?: string;
  startDate: string;
  status: EventStatus;
  subject: string;
  surgeonName: string;
  timeZoneId: string;
}
export interface EventRequestBody {
  description: string;
  billingUserId: string;
  endDate: string;
  hospitalId: string;
  hostUserId: string;
  participants: string[];
  roomId?: string;
  startDate: string;
  subject: string;
  surgeonName: string;
}

export type EventListResponse = BaseResponse<Event[]>;
export type EventDetailsResponse = BaseResponse<Event>;
