import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";
import { NAnnotationPoint } from "availkit-js/dist/Models/NAnnotationPoint";
import { Guid } from "guid-typescript";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { InstructionMemory } from "src/domains/Beacon/components/Easel/constants";
import {
  INITIAL_PEN_WIDTH,
  INITIAL_PEN_COLOR,
} from "src/domains/Beacon/constants";
import {
  TelestrationState,
  PenStyle,
  VideoDimensions,
  INITIAL_DIMENSIONS,
  SendEvent,
} from "src/domains/Beacon/store/telestration/types";
import { TwoDimensionPosition } from "src/services/ApiClient/presence";

export const initialState: TelestrationState = {
  presenceAnnotations: [],
  isDrawModeOn: false,
  penStyle: { width: INITIAL_PEN_WIDTH, color: INITIAL_PEN_COLOR },
  videoDimensions: INITIAL_DIMENSIONS,
  telestrationHistory: [],
  totalAnnotations: new Map<Guid | null, number>(),
  telestrationEventsMap: new Map<string | null, AVKAnnotationEvent[]>(),
  activeTelestrationId: null,
  activeSequence: 0,
  previousPoint: undefined,
  backingInstructions: [],
  sendEvent: null,
  telestrationRetries: new Map<string | null, number>(),
  isResizing: false,
};

export const telestrationSlice = createSlice({
  name: "telestration",
  initialState,
  reducers: {
    setAnnotations: (state, action: PayloadAction<AVKAnnotationEvent[]>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers.
      // so instead of doing something like
      // `const newState = [...state, newItem]`
      // we can do
      // `state.push(newItem)`
      state.presenceAnnotations = action.payload;
    },
    setIsDrawModeOn: (state, action: PayloadAction<boolean>) => {
      state.isDrawModeOn = action.payload;
    },
    setStyle: (state, action: PayloadAction<PenStyle>) => {
      const current = state.penStyle;
      state.penStyle = { ...current, ...action.payload };
    },
    setVideoDimensions: (state, action: PayloadAction<VideoDimensions>) => {
      const current = state.videoDimensions;
      state.videoDimensions = { ...current, ...action.payload };
    },
    setTelestrationHistory: (
      state,
      action: PayloadAction<InstructionMemory[]>
    ) => {
      state.telestrationHistory = action.payload;
    },
    setTotalAnnotations: (
      state,
      action: PayloadAction<Map<Guid | null, number>>
    ) => {
      state.totalAnnotations = action.payload;
    },
    setTelestrationEventsMap: (
      state,
      action: PayloadAction<Map<string | null, AVKAnnotationEvent[]>>
    ) => {
      state.telestrationEventsMap = action.payload;
    },
    // clears all state that affects the UI
    clearTelestrationDrawingState: (state) => {
      state.telestrationHistory = initialState.telestrationHistory;
      state.totalAnnotations = initialState.totalAnnotations;
      state.telestrationEventsMap = initialState.telestrationEventsMap;
      state.presenceAnnotations = [];
    },
    setActiveTelestrationId: (state, action: PayloadAction<Guid>) => {
      state.activeTelestrationId = action.payload;
    },
    setActiveSequence: (state, action: PayloadAction<number>) => {
      state.activeSequence = action.payload;
    },
    setPreviousPoint: (state, action: PayloadAction<TwoDimensionPosition>) => {
      state.previousPoint = action.payload;
    },
    setBackingInstructions: (
      state,
      action: PayloadAction<NAnnotationPoint[]>
    ) => {
      state.backingInstructions = action.payload;
    },
    setSendEvent: (state, action: PayloadAction<SendEvent>) => {
      state.sendEvent = action.payload;
    },
    setTelestrationRetries: (
      state,
      action: PayloadAction<Map<string | null, number>>
    ) => {
      state.telestrationRetries = action.payload;
    },
    setIsResizing: (state, action: PayloadAction<boolean>) => {
      state.isResizing = action.payload;
    },
  },
});

// Export the individual reducer and actions, which are generated with `createSlice`
export const {
  reducer: telestrationReducer,
  actions: telestrationActions,
} = telestrationSlice;
