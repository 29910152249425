import { AvailKitActionKeys, AvailKitActionType } from "./actionTypes";
import { AvailKitState } from "../models";
import AvailKitSingleton from "../../nurep";

const initialState: AvailKitState = {
  availKitInstance: null
};

const availKit = (
  state = initialState,
  action: AvailKitActionType
): AvailKitState => {
  switch (action.type) {
    case AvailKitActionKeys.INITIALIZE_AVAIL_KIT:
      const newState = { ...state };
      newState.availKitInstance = AvailKitSingleton.getInstance(
        action.config,
        action.presenceUUID
      );

      return newState;
    default:
      return state;
  }
};

export default availKit;
