import React from "react";
import "./ActiveSpeaker.scss";

interface Props {
  name: string;
  isSpeaking?: boolean;
}

const ActiveSpeaker: React.FC<Props> = ({ name, isSpeaking }) => {
  const getInitials = () => {
    if (!name) return "";

    const names = name.split(" ");
    return `${names[0][0]} ${names[1][0]}`.toUpperCase();
  };

  return (
    <div className="speaker-container">
      <div
        className={`circle outer animate ${
          isSpeaking === false ? "disable" : ""
        }`}
      ></div>
      <div
        className={`circle middle animate ${
          isSpeaking === false ? "disable" : ""
        }`}
      ></div>

      <div className="circle main">
        <span className="speaker-name">{name}</span>
        <span className="speaker-initials">{getInitials()}</span>
      </div>
    </div>
  );
};

export default ActiveSpeaker;
