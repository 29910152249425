import { DISTRIBUTORS } from './DistributorsActionTypes';

export const getAllDistributorsAction = params => ({
	type: DISTRIBUTORS.GET_ALL_DISTRIBUTORS,
	payload: params,
});

export const createNewDistributorAction = distributorData => ({
	type: DISTRIBUTORS.CREATE_NEW_DISTRIBUTOR,
	payload: distributorData,
});

export const deleteDistributorAction = distributorId => ({
	type: DISTRIBUTORS.DELETE_DISTRIBUTOR,
	payload: distributorId,
});
