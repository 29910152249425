import { Room, Participant } from "twilio-video";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getCredentialsThunk } from "src/domains/Beacon/store/twilio/thunks";
import { refreshFramesThunk } from "src/domains/Beacon/store/twilio/thunks/refreshFramesThunk";
import { setTwilioTokenReconnectionThunk } from "src/domains/Beacon/store/twilio/thunks/setTwilioTokenReconnectionThunk";
import { TwilioState } from "src/domains/Beacon/store/twilio/types";

export const initialState: TwilioState = {
  token: "",
  room: null,
  participants: [],
  // TODO: new participant property
  remoteHasJoinedRoom: false,
  consoleHasJoinedRoom: false,
  hostHasJoinedRoom: false,
  enableCameraDiscovery: false,
  isPollingNQ: false, // Flag for Network Quality polling (done via Twilio)
  callQualityStats: null, // Global stats for call quality
  callStartTime: new Date().getTime(), // Unix time
  callTime: 0, // idk? TODO
  dimensionsNeedUpdate: false,
  duplicateParticipantDisconnected: false,
  localNetworkQualityLevel: -1, // Set at -1 because the range for this is 0-5
  localNetworkDisconnected: false,
  bannerNeedsUpdate: false,
  dominantSpeaker: "", // We store the Avail UUID of the user that is considered the dominate speaker by twilio

  // properties for async handling (pending, success, failure)
  loading: false,
  error: null,

  // if the getCredentials call returns an error we will store it here
  getCredentialsError: null,

  loadingRefreshFrames: false,
  twilioTokenIntervalKey: null, // Must store the timeout for the Twilio token timer to stop it
  localNetworkQualityTimestamp: null,
};

export const twilioSlice = createSlice({
  name: "twilio",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      // so instead of doing something like
      // `const newState = [...state, newItem]`
      // we can do
      // `state.push(newItem)`
      state.token = action.payload;
    },

    setRoom: (state, action: PayloadAction<Room>) => {
      state.room = action.payload;
    },

    setParticipants: (state, action: PayloadAction<Participant[]>) => {
      state.participants = action.payload;
    },

    setHostHasJoinedRoom: (state, action: PayloadAction<boolean>) => {
      state.hostHasJoinedRoom = action.payload;
    },

    setConsoleHasJoinedRoom: (state, action: PayloadAction<boolean>) => {
      state.consoleHasJoinedRoom = action.payload;
    },

    setRemoteHasJoinedRoom: (state, action: PayloadAction<boolean>) => {
      state.remoteHasJoinedRoom = action.payload;
    },
    setDominantSpeaker: (state, action: PayloadAction<string>) => {
      state.dominantSpeaker = action.payload;
    },

    setError: (state, action: PayloadAction<{ message: string } | null>) => {
      state.error = action.payload
        ? {
            message: action.payload.message,
          }
        : null;
    },

    setDuplicateParticipantDisconnected: (
      state: TwilioState,
      action: PayloadAction<boolean>
    ) => {
      state.duplicateParticipantDisconnected = action.payload;
    },

    setLocalNetworkDisconnected: (
      state: TwilioState,
      action: PayloadAction<boolean>
    ) => {
      state.localNetworkDisconnected = action.payload;
    },

    setLoadingRefreshFrames: (
      state: TwilioState,
      action: PayloadAction<boolean>
    ) => {
      state.loadingRefreshFrames = action.payload;
    },

    setTwilioTokenIntervalKey: (
      state: TwilioState,
      action: PayloadAction<NodeJS.Timer>
    ) => {
      state.twilioTokenIntervalKey = action.payload;
    },

    setLocalNetworkQualityLevel: (
      state: TwilioState,
      action: PayloadAction<number>
    ) => {
      state.localNetworkQualityLevel = action.payload;
    },
    setLocalNetworkQualityTimestamp: (
      state: TwilioState,
      action: PayloadAction<Date>
    ) => {
      state.localNetworkQualityTimestamp = action.payload;
    },
  },

  // Extra reducers are for the generated actions/reducers to handle
  // asynchronous requests via `createAsyncThunk`
  extraReducers: (builder) => {
    // getCredentialsThunk
    builder.addCase(getCredentialsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getCredentialsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.getCredentialsError = null;
      state.token = action.payload;
    });

    builder.addCase(getCredentialsThunk.rejected, (state, action) => {
      state.loading = false;
      state.getCredentialsError = {
        message: action.error.message,
      };
    });

    // refreshFramesThunk
    builder.addCase(refreshFramesThunk.pending, (state) => {
      state.loadingRefreshFrames = true;
    });

    builder.addCase(refreshFramesThunk.fulfilled, (state) => {
      state.loadingRefreshFrames = false;
    });

    builder.addCase(refreshFramesThunk.rejected, (state) => {
      state.loadingRefreshFrames = false;
    });

    // setTwilioTokenReconnectionThunk
    builder.addCase(setTwilioTokenReconnectionThunk.pending, (state) => {
      state.loadingRefreshFrames = true;
    });

    builder.addCase(setTwilioTokenReconnectionThunk.fulfilled, (state) => {
      state.loadingRefreshFrames = false;
    });

    builder.addCase(setTwilioTokenReconnectionThunk.rejected, (state) => {
      state.loadingRefreshFrames = false;
    });
  },
});

// Export the individual reducer and actions, which are generated with `createSlice`
export const { reducer: twilioReducer, actions: twilioActions } = twilioSlice;
