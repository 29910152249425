import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { setUserPresetEventThunk } from "src/domains/Beacon/store/preset/thunks/setUserPresetEventThunk";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { closeFullscreenThunk } from "src/domains/Beacon/store/stream/thunks/closeFullscreenThunk";
import { expandFullScreenThunk } from "src/domains/Beacon/store/stream/thunks/expandFullScreenThunk";
import {
  LayoutFrameNames,
  LayoutFrames,
  LayoutType,
} from "src/domains/Beacon/store/stream/types";
import {
  findFullScreenFrameName,
  isFullscreenLayout,
} from "src/domains/Beacon/utils/layouts";
import { logger, LoggerLevels } from "src/logging/logger";

// Handles changing the Layouts based whether Console has EIR capability
export const handlePresetChangeThunk = createAsyncThunk<
  void,
  {
    externalInputsImageResizing: boolean;
    newLayoutFrames?: LayoutFrames | null;
    presetCameras: (AVKCamera | AVKExternalInput)[];
    presetLayout: LayoutType;
  },
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "stream/handlePresetChangeThunk",
  (
    {
      externalInputsImageResizing,
      newLayoutFrames,
      presetCameras,
      presetLayout,
    },
    { getState, dispatch }
  ) => {
    logger().logWithFields(
      LoggerLevels.info,
      { feature: "Presets" },
      `handlePresetChangeThunk - Checking EIR flag`
    );

    const {
      stream: {
        consoleHasExternalInputResizing,
        layoutFrames,
        layoutFramesSnapshot,
        integrationActive: isIntegrationActive,
      },
    } = getState();

    // when we are using an integration, this prevents sending the integration as a layout
    const layoutToUse = isIntegrationActive
      ? layoutFramesSnapshot
      : layoutFrames;
    const desiredLayoutFrames = newLayoutFrames ?? layoutToUse;

    const hasExternalInputResizing =
      externalInputsImageResizing && consoleHasExternalInputResizing;

    // external input resize is only enabled on newest console versions,
    // otherwise, the old camera change event should be used
    if (hasExternalInputResizing) {
      // * broadcastsAVKVideoLayoutChange
      if (!isFullscreenLayout(desiredLayoutFrames)) {
        dispatch(streamActions.setLayoutType(presetLayout));
        dispatch(
          closeFullscreenThunk({
            externalInputsImageResizing,
            newLayoutFrames: desiredLayoutFrames,
          })
        );
      } else {
        dispatch(
          expandFullScreenThunk({
            externalInputsImageResizing,
            newLayoutFrames: desiredLayoutFrames,
            side: findFullScreenFrameName(
              desiredLayoutFrames
            ) as LayoutFrameNames,
          })
        );
      }
    } else {
      // * broadcasts AVKUserPresetEvent
      dispatch(setUserPresetEventThunk({ cameras: presetCameras }));
    }
  }
);
