import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { Button, Fields, validators } from '../../../../../../Common/Components';

class CreateNewHospitalNetwork extends PureComponent {
	render() {
		const { t } = this.props;

		return (
			<React.Fragment>
				<form action="" onSubmit={this.props.handleSubmit}>
					<Fields.InputField
						className="user-profile__general-input"
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.placeholder')}
						name="name"
						validate={[validators.required, validators.minimumLength3, validators.maximumLength48]}
					/>
					<div className="button__wrapper justify-content--flex-end">
						<Button
							onClick={this.props.toggleCreateNewHospitalNetworkModal}
							isGhost
							buttonSmall
						>
							Cancel
						</Button>
						<Button
							type="submit"
							isFull
							buttonSmall
						>
							Create
						</Button>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

const CreateNewHospitalNetworkWithTranslation = withTranslation()(CreateNewHospitalNetwork);

export default reduxForm({
	form: 'newHospitalNetworkForm',
})(CreateNewHospitalNetworkWithTranslation);

