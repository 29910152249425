import { takeLatest, call, put } from 'redux-saga/effects';
import resources from '../../../../../Common/Constants/resources';
import { notificationService } from '../../../../../Common/Services';
import urlService from '../../../../../Common/Services/urlService';
import ApiHelper from '../../../../../Common/Services/ApiHelper';
import { BILLING } from './BillingActionTypes';

const PAYMENT_API_URL = 'payment';

function* createPlan(action) {
	try {
		const response = yield call(ApiHelper.post, PAYMENT_API_URL, urlService.formatString(resources.billing, action.vendorId), action.payload);
		notificationService.success('You successfully created a billing plan');
		yield put({
			type: BILLING.SET_VENDOR_PLAN,
			payload: response,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updatePlan(action) {
	try {
		const response = yield call(ApiHelper.put, PAYMENT_API_URL, urlService.formatString(resources.billing, action.vendorId), action.payload);
		notificationService.success('You successfully updated a billing plan');
		yield put({
			type: BILLING.SET_VENDOR_PLAN,
			payload: response,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}


function* getPlanByVendorId(action) {
	try {
		const response = yield call(ApiHelper.get, PAYMENT_API_URL, urlService.formatString(resources.billing, action.payload));
		yield put({
			type: BILLING.SET_VENDOR_PLAN,
			payload: response,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createSubscription(action) {
	try {
		const response = yield call(ApiHelper.post, PAYMENT_API_URL, urlService.formatString(resources.billingSubscription, action.vendorId), action.payload);
		notificationService.success('You successfully updated billing details');
		yield put({
			type: BILLING.SET_VENDOR_SUBSCRIPTION,
			payload: response,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getInvoicesByVendorId(action) {
	try {
		const response = yield call(ApiHelper.get, PAYMENT_API_URL, urlService.formatString(resources.billingInvoices, action.payload));
		yield put({
			type: BILLING.SET_VENDOR_INVOICES,
			payload: response,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getUsageInfoByVendorId(action) {
	try {
		const response = yield call(ApiHelper.get, PAYMENT_API_URL, urlService.formatString(resources.billingUsageInfo, action.payload));
		yield put({
			type: BILLING.SET_USAGE_INFO_BY_VENDOR_ID,
			payload: response,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}


export default function* billingSaga() {
	yield takeLatest(BILLING.CREATE_PLAN, createPlan);
	yield takeLatest(BILLING.UPDATE_PLAN, updatePlan);
	yield takeLatest(BILLING.GET_PLAN_BY_VENDOR_ID, getPlanByVendorId);
	yield takeLatest(BILLING.CREATE_SUBSCRIPTION, createSubscription);
	yield takeLatest(BILLING.GET_INVOICES_BY_VENDOR_ID, getInvoicesByVendorId);
	yield takeLatest(BILLING.GET_USAGE_INFO_BY_VENDOR_ID, getUsageInfoByVendorId);
}
