import { NIncomingServerCallEvent } from "availkit-js/dist/Models/Events/NIncomingServerCallEvent";
import { renderTraceIdHeader } from "src/domains/Beacon/utils/renderTraceIdHeader";

import { logger } from "../common/logger";
import { AVAIL_DEVICE_INSTANCE_ID_KEY, ENDPOINTS } from "../constants";
import {
  MultiPartyCallEventInfo,
  PortalIdentity,
  TwilioCredentials,
} from "../store/models";
import { ApiResponse } from "./types";

/**
 * Accepts query parameters (identity, callSid) separated by a '/'.
 *
 * Example use: const { data, error } = await API.GET.twilioCredentials(identity, callSid)
 */
const twilioCredentials = async (
  identity: PortalIdentity,
  callevent: NIncomingServerCallEvent,
  multiPartyCallEvent: MultiPartyCallEventInfo
): Promise<ApiResponse<TwilioCredentials>> => {
  try {
    //     https://api.titan-dev.us.avail.io/callevent/users/join/{joinId}
    // {
    //   "brandId": "string",
    //   "callSid": "string",
    //   "deviceId": "string",
    //   "orgId": "string",
    //   "profileId": "string",
    //   "role": "string",
    //   "timeZone": "string"
    // }
    // POST /callevent/event/{eventId}/users/{loginId/JoinId}
    // POST /events/{eventId}/users/{loginId}

    let deviceId = localStorage.getItem(AVAIL_DEVICE_INSTANCE_ID_KEY);
    if (!deviceId) {
      deviceId = "string";
    } else {
      deviceId = JSON.parse(deviceId);
    }

    let callOrEventJoinId = multiPartyCallEvent
      ? multiPartyCallEvent.joinId
      : callevent.join_id;
    deviceId = `PVID-${deviceId.replaceAll("-", "_")}`;
    logger().info("deviceId : ", deviceId);

    logger().info("Trying to get a Twilio Token");
    let url = "";
    let postBody = "";
    if (multiPartyCallEvent) {
      // for multiparty events we now use the v2 callevent endpoint since the events we create in the portal also use v2
      url = `${ENDPOINTS.DOMAIN}/callevent/v2/events/${multiPartyCallEvent.eventId}/users/${multiPartyCallEvent.joinId}`;
      postBody = JSON.stringify({
        deviceId,
        timeZone: "UTC",
      });
    } else {
      url = `${ENDPOINTS.DOMAIN}/callevent/users/join/${callevent.join_id}`;
      postBody = JSON.stringify({
        brandId: "string",
        callSid: callevent.session_id.toLowerCase(),
        deviceId,
        orgId: identity.org_id,
        profileId: identity.profile_id,
        role: callevent.role,
        timeZone: "UTC",
      });
    }
    const access_token = identity.access_token;
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
        ...renderTraceIdHeader(),
        Accept: "application/json",
      },
      body: postBody,
    });

    if (!res.ok) {
      const { message } = await res.json();
      logger().error("Error Retrieving Twilio Token, " + message);
      return { error: message, status: res.status };
    }

    logger().info("Twilio Token Received");
    const resultString = await res.json();
    const data: TwilioCredentials = { token: resultString.content.twilioToken };
    if (multiPartyCallEvent) {
      data.callSid = resultString.content.callSid;
      data.eventId = resultString.content.eventId;
    }
    return { data };
  } catch (error) {
    return { error };
  }
};

export default twilioCredentials;
