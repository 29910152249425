import {
  CallModes,
  MeetingToken,
} from "src/domains/Beacon/store/meeting/types";
import {
  TwilioCredentials,
  TwilioCredentialsResponse,
  TwilioRefreshTokenResponse,
} from "src/services/ApiClient/twilio/types";
import LocalStorageService from "src/services/LocalStorageService";
import UserSessionService from "src/services/UserSessionService";

import ApiQuery from "../ApiQuery";
import { AVAIL_DEVICE_INSTANCE_ID_KEY, CALLEVENT_API_URL } from "../utils";

// getCallSession GET call session data
export const getTwilioCredentials = async (
  mode: CallModes,
  meetingToken: MeetingToken
): Promise<TwilioCredentials> => {
  let deviceId =
    LocalStorageService.get(AVAIL_DEVICE_INSTANCE_ID_KEY) || "string";
  deviceId = `PVID-${deviceId.replaceAll("-", "_")}`;
  // logger().info("deviceId : ", deviceId);

  // logger().info("Trying to get a Twilio Token");
  let url = "";
  let postBody = null;
  if (mode === CallModes.MP) {
    // for multiparty events we use the v2 callevent endpoint since the events we create in the portal also use v2
    url = `${CALLEVENT_API_URL}/v2/events/${meetingToken.eventId}/users/${meetingToken.joinId}`;
    postBody = {
      deviceId,
      timeZone: "UTC",
    };
  } else {
    const user = UserSessionService.getCachedUserInfo();
    url = `${CALLEVENT_API_URL}/users/join/${meetingToken.join_id}`;
    postBody = {
      brandId: "string",
      callSid: meetingToken.session_id.toLowerCase(),
      deviceId,
      orgId: user.orgId,
      profileId: user.id,
      role: meetingToken.role,
      timeZone: "UTC",
    };
  }

  const response = await ApiQuery<TwilioCredentialsResponse>(
    url,
    "POST",
    postBody
  );

  return {
    twilioToken: response.content.twilioToken,
    callSid: response?.content.callSid, // these properties are used in MP events, if they don't exist, no big deal
    eventId: response?.content.eventId,
    // TPI should not be available for a P2P call
    thirdPartyIntegration:
      mode === CallModes.P2P ? [] : response?.content.thirdPartyIntegration,
  };
};

// Calls call event API to leave the call the user is in
export const leaveCall = async (joinId: string, callSid: string) => {
  const url = `${CALLEVENT_API_URL}/users/join/${joinId}`;

  const postBody = {
    callSid,
  };

  // there is no response body so no type necessary
  return await ApiQuery(url, "DELETE", postBody);
};

// Updates twilio token for long duration calls or refresh call
export const refreshTwilioToken = async (joinId: string, callSid: string) => {
  return await ApiQuery<TwilioRefreshTokenResponse>(
    `${CALLEVENT_API_URL}/session/${callSid}/users/${joinId}/refreshToken`
  );
};
