export default [
	{
		value: '12:00am',
	},
	{
		value: '12:15am',
	},
	{
		value: '12:30am',
	},
	{
		value: '12:45am',
	},
	{
		value: '01:00am',
	},
	{
		value: '01:15am',
	},
	{
		value: '01:30am',
	},
	{
		value: '01:45am',
	},
	{
		value: '02:00am',
	},
	{
		value: '02:15am',
	},
	{
		value: '02:30am',
	},
	{
		value: '02:45am',
	},
	{
		value: '03:00am',
	},
	{
		value: '03:15am',
	},
	{
		value: '03:30am',
	},
	{
		value: '03:45am',
	},
	{
		value: '04:00am',
	},
	{
		value: '04:15am',
	},
	{
		value: '04:30am',
	},
	{
		value: '04:45am',
	},
	{
		value: '05:00am',
	},
	{
		value: '05:15am',
	},
	{
		value: '05:30am',
	},
	{
		value: '05:45am',
	},
	{
		value: '06:00am',
	},
	{
		value: '06:15am',
	},
	{
		value: '06:30am',
	},
	{
		value: '06:45am',
	},
	{
		value: '07:00am',
	},
	{
		value: '07:15am',
	},
	{
		value: '07:30am',
	},
	{
		value: '07:45am',
	},
	{
		value: '08:00am',
	},
	{
		value: '08:15am',
	},
	{
		value: '08:30am',
	},
	{
		value: '08:45am',
	},
	{
		value: '09:00am',
	},
	{
		value: '09:15am',
	},
	{
		value: '09:30am',
	},
	{
		value: '09:45am',
	},
	{
		value: '10:00am',
	},
	{
		value: '10:15am',
	},
	{
		value: '10:30am',
	},
	{
		value: '10:45am',
	},
	{
		value: '11:00am',
	},
	{
		value: '11:15am',
	},
	{
		value: '11:30am',
	},
	{
		value: '11:45am',
	},
	{
		value: '12:00pm',
	},
	{
		value: '12:15pm',
	},
	{
		value: '12:30pm',
	},
	{
		value: '12:45pm',
	},
	{
		value: '01:00pm',
	},
	{
		value: '01:15pm',
	},
	{
		value: '01:30pm',
	},
	{
		value: '01:45pm',
	},
	{
		value: '02:00pm',
	},
	{
		value: '02:15pm',
	},
	{
		value: '02:30pm',
	},
	{
		value: '02:45pm',
	},
	{
		value: '03:00pm',
	},
	{
		value: '03:15pm',
	},
	{
		value: '03:30pm',
	},
	{
		value: '03:45pm',
	},
	{
		value: '04:00pm',
	},
	{
		value: '04:15pm',
	},
	{
		value: '04:30pm',
	},
	{
		value: '04:45pm',
	},
	{
		value: '05:00pm',
	},
	{
		value: '05:15pm',
	},
	{
		value: '05:30pm',
	},
	{
		value: '05:45pm',
	},
	{
		value: '06:00pm',
	},
	{
		value: '06:15pm',
	},
	{
		value: '06:30pm',
	},
	{
		value: '06:45pm',
	},
	{
		value: '07:00pm',
	},
	{
		value: '07:15pm',
	},
	{
		value: '07:30pm',
	},
	{
		value: '07:45pm',
	},
	{
		value: '08:00pm',
	},
	{
		value: '08:15pm',
	},
	{
		value: '08:30pm',
	},
	{
		value: '08:45pm',
	},
	{
		value: '09:00pm',
	},
	{
		value: '09:15pm',
	},
	{
		value: '09:30pm',
	},
	{
		value: '09:45pm',
	},
	{
		value: '10:00pm',
	},
	{
		value: '10:15pm',
	},
	{
		value: '10:30pm',
	},
	{
		value: '10:45pm',
	},
	{
		value: '11:00pm',
	},
	{
		value: '11:15pm',
	},
	{
		value: '11:30pm',
	},
	{
		value: '11:45pm',
	},
];
