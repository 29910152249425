import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import resources from '../../../../Common/Constants/resources';
import { notificationService } from '../../../../Common/Services';
import ApiHelper from '../../../../Common/Services/ApiHelper';
import urlService from '../../../../Common/Services/urlService';
import arrayService from '../../../../Common/Services/arrayService';
import { PROVIDER } from './ProviderActionTypes';
import { getAllProvidersAction } from './ProviderActions';

function* getAllProviders(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', resources.providers, action.payload);
		yield all([
			put({
				type: PROVIDER.SET_ALL_PROVIDERS,
				payload: response.content,
			}),
			put({
				type: PROVIDER.SET_PROVIDER_PAGINATION,
				payload: response,
			}),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getProviderUsers(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.profileUsers, action.providerId), action.params);
		const responseContent = response.content.slice().map(item => ({name: `${item.firstName} ${item.lastName}`, ...item}));
		const responseData = arrayService.sortQuery(responseContent, action.params);
		yield put({
			type: PROVIDER.SET_PROVIDER_USERS,
			payload: responseData,
		});
		yield put({
			type: PROVIDER.SET_PROVIDER_USERS_PAGINATION,
			payload: response,
		});
	} catch (e) {
		notificationService.error(e);
	}
}

function* createNewProvider(action) {
	try {
		yield call(ApiHelper.post, 'coreregistry', resources.providers, action.payload);
		yield put(getAllProvidersAction());
		notificationService.success('You successfully created HCP Group');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* deleteProvider(action) {
	try {
		yield call(ApiHelper.remove, 'coreregistry', urlService.formatString(resources.provider, action.payload));
		const currentProviderList = yield select(state => state.providerReducer.providers);
		const newProviderList = currentProviderList.map(provider => (provider.id === action.payload ? {
			...provider,
			active: false,
		} : provider));

		yield put({
			type: PROVIDER.SET_ALL_PROVIDERS,
			payload: newProviderList,
		});
		yield notificationService.success('You successfully deleted HCP Group');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getProviderById(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.provider, action.payload));
		yield put({
			type: PROVIDER.SET_PROVIDER_BY_ID,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updateProviderById(action) {
	try {
		const response = yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.provider, action.providerId), action.providerData);
		yield all([
			put({
				type: PROVIDER.SET_PROVIDER_BY_ID,
				payload: response.content,
			}),
			notificationService.success('You successfully updated HCP Group'),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* providerSaga() {
	yield takeLatest(PROVIDER.GET_ALL_PROVIDERS, getAllProviders);
	yield takeLatest(PROVIDER.CREATE_NEW_PROVIDER, createNewProvider);
	yield takeLatest(PROVIDER.DELETE_PROVIDER, deleteProvider);
	yield takeLatest(PROVIDER.GET_PROVIDER_BY_ID, getProviderById);
	yield takeLatest(PROVIDER.UPDATE_PROVIDER_BY_ID, updateProviderById);
	yield takeLatest(PROVIDER.GET_PROVIDER_USERS, getProviderUsers);
}
