import { useQuery } from "react-query";

import { FetchError } from "src/services/ApiClient/ApiQuery";
import { TherapiesResponse } from "src/services/ApiClient/businessUnit";
import { getUserTherapies } from "src/services/ApiClient/users";

const KEY = "therapies";

export function useGetAllTherapies(userId: string, queryKeys?: any) {
  return useQuery<TherapiesResponse, FetchError>(
    [`${KEY}-${userId}`, queryKeys],
    () => {
      return getUserTherapies(userId);
    }
  );
}
