import React from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';

import ValidationMessage from '../FormFields/ValidationMessage/ValidationMessage';

import '../../../Common/Components/FormFields/FormFields.scss';

export default class extends React.PureComponent {
	state = {
		selectedDate: this.props.input.value || null,
	};

	handleChange = (value) => {
		this.setState({
			selectedDate: value,
		});
		this.props.input.onChange(value);
	}

	render() {
		const {
			input, className, meta: { touched, error, warning }, dayPickerProps, ...props
		} = this.props;
		const doShowError = touched && error;

		return (
			<div
				className={classNames({
					'form-field': true,
					'form-field--error': doShowError,
				})}
			>
				<DatePicker
					className="form-field__date-picker-input"
					{...props}
					onChange={this.handleChange}
					selected={this.state.selectedDate}
					value={this.props.value}
				/>
				{touched
					&& ((error && <ValidationMessage>{error}</ValidationMessage>)
						|| (warning && <ValidationMessage>{warning}</ValidationMessage>))}
			</div>
		);
	}
}
