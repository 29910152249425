export enum PTZToolTips {
  close = "Close",
  kebab = "Pan/Zoom Controls",
  expand = "Expand",
  collapse = "Collapse",
  zoomSlider = "Zoom In/Out",
  panControls = "Pan Controls",
}

export enum PipToolTips {
  expand = "Show Host Video",
  collapse = "Minimize Host Video",
}

// ToolTips for stand alone items
export enum BeaconControlsToolTips {
  drawButton = "Telestrate",
  sideBarButton = "Sidechat",
  showUIButton = "Show Controls",
  hideUIButton = "Hide Controls",
}

// Group any other tooltips together by feature below
