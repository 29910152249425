import { TABS } from './TabsActionTypes';

const initialState = {
	activeIndex: 0,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case TABS.INCREMENT_ACTIVE_INDEX:
			return {
				...state,
				activeIndex: state.activeIndex + 1,
			};
		case TABS.DECREMENT_ACTIVE_INDEX:
			return {
				...state,
				activeIndex: state.activeIndex - 1,
			};
		case TABS.RESET_ACTIVE_INDEX:
			return {
				...state,
				activeIndex: 0,
			};
		default:
			return state;
	}
}
