import { roles } from '../Constants/userTypes';

export default {
	'AVAIL_ADMIN': {
		static: {
			read: [
				'home', 'users', 'brands', 'vendors', 'hospitals', 'consoles', 'hospitalNetwork', 'addNewHospital',
				'createNewDepartment', 'addVendorHospital', 'deleteDomain', 'deleteUser', 'deleteHospital', 'othersScheduler', 'provider', 'preferences', 'billing',
				'createUserFacility', 'vendorBilling', 'distributors', 'cdr', 'addNewDomain', 'editVendorInfo', 'editConsole', 'editRow', 'events',
			],
			edit: ['users', 'brands', 'vendors', 'vendorGeneralInfo', 'vendorProcedures', 'preferences', 'billing', 'brandBilling'],
			visit: [
				'home', 'createUser', 'userProfile', 'users', 'scheduler', 'vendorProfile', 'createHospital',
				'roomProfile', 'departmentProfile', 'createVendor', 'vendors', 'providerProfile', 'hospitalProfile',
				'accountCreation', 'brands', 'billing', 'hospitals', 'consoleProfile', 'consoles', 'hospitalNetwork',
				'provider', 'distributors', 'invoices', 'cdr',
			],
			have: [],
		},
		dynamic: {
			edit: {
				'userProfile': () => true,
				'userProcedures': ({ currentUserId, userProfileId }) => userProfileId && (currentUserId !== userProfileId),
			},
			read: {
				'userProfileHospitalsTab': ({ currentUserId, userProfileId, userProfileType }) => {
					const usersWhoCanSeeHospitals = [roles.vendorManager, roles.vendorSalesRep, roles.individualSalesRep, roles.proctor, roles.specialist];
					return userProfileId && (currentUserId !== userProfileId) && (usersWhoCanSeeHospitals.includes(userProfileType));
				},
				'userProfileTherapiesTab': ({ currentUserId, userProfileId, userProfileType }) => {
					const usersWhoCanSeeTherapies = [roles.vendorManager, roles.vendorSalesRep, roles.individualSalesRep, roles.proctor, roles.specialist];
					return userProfileId && (currentUserId !== userProfileId) && (usersWhoCanSeeTherapies.includes(userProfileType));
				},
				'calendar': ({ currentUserId, userProfileId, userTypes }) => {
					const doesUserTypeHaveCalendar = (userTypes && (!userTypes.includes(roles.vendorAdmin) && !userTypes.includes(roles.hospitalAdmin)));
					return currentUserId !== userProfileId && doesUserTypeHaveCalendar;
				},
				'userProcedures': ({ currentUserId, userProfileId }) => userProfileId && (currentUserId !== userProfileId),
				'addProcedures': ({ currentUserId, userProfileId }) => userProfileId && (currentUserId !== userProfileId),
			},
		},
	},
	'VENDOR_ADMIN': {
		static: {
			read: ['home', 'users', 'businessUnitProfile', 'addVendorHospital', 'editRow', 'createNewDepartment',
				'myOrganization', 'othersScheduler', 'preferences', 'billing', 'viewDomains', 'events'],
			edit: ['users', 'vendorGeneralInfo', 'vendorProcedures', 'userProfile', 'preferences', 'vendorBilling'],
			visit: ['home', 'createUser', 'userProfile', 'users', 'scheduler',
				'vendorProfile', 'accountCreation', 'vendorBilling', 'invoices'],
			have: [],
		},
		dynamic: {
			read: {
				'vendorBilling': ({ userOrganisationId, businessUnitId }) => userOrganisationId === businessUnitId,
			},
		},
	},
	'VENDOR_MANAGER': {
		static: {
			read: ['home', 'users', 'scheduler', 'missedCalls', 'businessUnitProfile', 'userProfileHospitalTab', 'userProfileHospitalsTab', 'editRow',
				'userProfileTherapiesTab', 'vendorApprovalList', 'myOrganization', 'othersScheduler', 'accountCreationProcedures', 'viewDomains', 'vendorProceduresRead',  'events'],
			edit: ['scheduler'],
			visit: ['home', 'createUser', 'userProfile', 'users', 'scheduler', 'missedCalls',
				'vendorProfile', 'accountCreation', 'vendorApprovalList'],
			have: ['scheduler'],
		},
		dynamic: {
			read: {
				'calendar': ({ descendantUsersIds, currentUserId, userProfileId }) => {
					if (currentUserId === userProfileId) return true;
					else if (descendantUsersIds) {
						return descendantUsersIds.includes(userProfileId);
					}
				},
				'addNewHospital': ({ currentUserId, userProfileId }) => currentUserId === userProfileId,
				'addProcedures': ({ descendantUsersIds, currentUserId, userProfileId }) => {
					if (currentUserId === userProfileId) return true;
					else if (descendantUsersIds) {
						return descendantUsersIds.includes(userProfileId);
					}
				},
				'preferences': ({ descendantUsersIds, currentUserId, userProfileId }) => {
					if (currentUserId === userProfileId) return true;
					else if (descendantUsersIds) {
						return descendantUsersIds.includes(userProfileId);
					}
				},
			},
			edit: {
				'userProfile': ({ descendantUsersIds, currentUserId, userProfileId }) => {
					if (currentUserId === userProfileId) return true;
					else if (descendantUsersIds) {
						return descendantUsersIds.includes(userProfileId);
					}
				},
				'userProcedures': ({ descendantUsersIds, currentUserId, userProfileId }) => {
					if (currentUserId === userProfileId) return true;
					else if (descendantUsersIds) {
						return descendantUsersIds.includes(userProfileId);
					}
				},
				'preferences': ({ descendantUsersIds, currentUserId, userProfileId }) => {
					if (currentUserId === userProfileId) return true;
					else if (descendantUsersIds) {
						return descendantUsersIds.includes(userProfileId);
					}
				},
			},
		},
	},
	'VENDOR_SALES_REPRESENTATIVE': {
		static: {
			read: ['scheduler', 'missedCalls', 'userProfileHospitalTab', 'userProfileTherapiesTab', 'myOrganization', 'userProfileHospitalsTab', 'accountCreationProcedures', 'viewDomains', 'vendorProceduresRead', 'editRow', 'events'],
			edit: ['scheduler'],
			visit: ['userProfile', 'scheduler', 'missedCalls', 'vendorProfile', 'accountCreation'],
			have: ['scheduler'],
		},
		dynamic: {
			read: {
				'addNewHospital': ({ currentUserId, userProfileId }) => currentUserId === userProfileId,
				'preferences': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
				'addProcedures': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
				'calendar': ({ currentUserId, userProfileId }) => currentUserId === userProfileId,
			},
			edit: {
				'userProfile': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
				'preferences': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
				'userProcedures': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
			},
		},
	},
	'PROCTOR': {
		static: {
			read: ['scheduler', 'missedCalls', 'userProfileHospitalTab', 'userProfileTherapiesTab', 'userProfileHospitalsTab', 'accountCreationProcedures', 'viewDomains', 'editRow',  'events'],
			edit: ['scheduler'],
			visit: ['userProfile', 'scheduler', 'missedCalls', 'vendorProfile', 'accountCreation'],
			have: ['scheduler'],
		},
		dynamic: {
			read: {
				'addNewHospital': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
				'preferences': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
				'addProcedures': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
			},
			edit: {
				'userProfile': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
				'preferences': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
				'userProcedures': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
			},
		},
	},
	'SPECIALIST': {
		static: {
			read: [
				'scheduler', 'missedCalls', 'userProfileHospitalTab', 'userProfileTherapiesTab', 'userProfileHospitalsTab',
				'accountCreationProcedures', 'editRow', 'events'
			],
			edit: ['scheduler'],
			visit: ['userProfile', 'scheduler', 'missedCalls', 'vendorProfile', 'accountCreation'],
			have: ['scheduler'],
		},
		dynamic: {
			read: {
				'addNewHospital': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
				'preferences': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
			},
			edit: {
				'userProfile': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
				'preferences': ({ currentUserId, userProfileId }) => {
					if (!currentUserId || !userProfileId) return false;
					return currentUserId === userProfileId;
				},
			},
		},
	},
	'HOSPITAL_ADMIN': {
		static: {
			read: [
				'home', 'users', 'hospitalProfile', 'hospitalApprovalList', 'othersScheduler', 'preferences', 'readConsole', 'editRow'
			],
			edit: ['userProfile', 'preferences'],
			visit: ['hospitalApprovalList', 'users', 'userProfile', 'hospitalProfile',
				'roomProfile', 'departmentProfile', 'consoleProfile', 'accountCreation', 'createUser'],
			have: [],
		},
	},
	'INDIVIDUAL_SALES_REPRESENTATIVE': {
		static: {
			read: [
				'accountCreationUserInfo', 'accountCreationHospitals', 'accountCreationProcedures', 'editRow',
				'userProfileHospitalsTab', 'preferences', 'addNewHospital', 'scheduler', 'missedCalls', 'userProfileTherapiesTab',
				'addProcedures', 'events'
			],
			edit: ['userProfile', 'preferences', 'userProcedures'],
			visit: ['accountCreation', 'scheduler', 'missedCalls', 'userProfile'],
			have: ['scheduler'],
		},
		dynamic: {
			read: {
				'addNewHospital': ({ currentUserId, userProfileId }) => currentUserId === userProfileId,
			}
		},
	},
};
