import { AUTH } from "../Authentication/AuthenticationActionTypes";
import { HOME } from "./HomeActionTypes";

const initialState = {
	currentUser: null,
	isButtonClickable: false,
	finishedAccountSetup: true,
	expireAccount: false,
	isLoaderActive: false,
	showIdleAlert: false,
	notifications: [],
  localNetworkDisrupted: false,
	loginMfaToken: null,
	hasPhoneNumber: false,
	user: null
};

export default function (state = initialState, action) {
	switch (action.type) {
		case HOME.SET_CURRENT_USER_INFO:
			return {
				...state,
				currentUser: action.payload,
			};
		case HOME.GET_ACCOUNT_SETUP_STATUS:
			return {
				...state,
				finishedAccountSetup: action.payload,
			};
		case HOME.GET_ACCOUNT_EXPIRE_STATUS:
			return {
				...state,
				expireAccount: action.payload,
			};
		case HOME.SET_IS_BUTTON_CLICKABLE:
			return {
				...state,
				isButtonClickable: !state.isButtonClickable,
			};
		case HOME.TOGGLE_LOADER:
			return {
				...state,
				isLoaderActive: !state.isLoaderActive,
			};
		case HOME.SET_IDLE_ALERT:
			return {
				...state,
				showIdleAlert: action.payload,
			};
		case HOME.SET_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload,
			};
		case HOME.ADD_NOTIFICATION:
			return {
				...state,
				notifications: [...state.notifications, action.payload],
			};
		case HOME.REMOVE_NOTIFICATION:
			return {
				...state,
				notifications: state.notifications.filter(n => n.id !== action.payload)
			}
    case HOME.SET_LOCAL_NETWORK_DISRUPTED:
      return {
        ...state,
        localNetworkDisrupted: action.payload
      }
		case HOME.MFA_LOGIN_SELECT_CHANNEL:
			return {
				...state,
				loginMfaToken: action.payload,
			};
		case HOME.MFA_LOGIN_SETUP_PHONE:
			return {
				...state,
				hasPhoneNumber: action.payload,
			};
		case HOME.SET_USER_FOR_REDIRECT:
			return {
				...state,
				user: action.payload,
			};
		default:
			return state;
	}
}
