import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import {
  CallTypes,
  UserCallHistoryItem,
} from "src/services/ApiClient/trialUsage";

import { formatTime } from "../../utils";
import styles from "./styles.scss";

export interface Props {
  items: UserCallHistoryItem[];
}

export const CallHistoryTable = ({ items }: Props) => {
  return (
    <div>
      <TableContainer>
        <Table className={styles.table} aria-label="Call history table">
          <TableBody>
            {items.map((item) => {
              if (item) {
                // The item can be an event or a p2p call, different data must be displayed depending on this
                return (
                  <TableRow
                    key={item?.callSessionId}
                    className={styles.tableRow}
                  >
                    {/* SHOW: date and time of call */}
                    <TableCell className={styles.tableCell} scope="row">
                      <Typography variant="body1">
                        {formatTime(item?.startDate, item?.endDate)}
                      </Typography>
                    </TableCell>

                    {/* SHOW: Event: title of event | P2P: “Incoming call” (hard coded value) */}
                    <TableCell className={styles.tableCell} align="center">
                      <Typography variant="body1" className={styles.bold}>
                        {item.type === CallTypes.event
                          ? item?.subject
                          : "Incoming call"}
                      </Typography>
                    </TableCell>

                    {/* SHOW:  Event: surgeon name | P2P: - blank */}
                    <TableCell className={styles.tableCell} align="center">
                      <Typography variant="body1">
                        {item.type === CallTypes.event
                          ? item?.surgeonName
                          : "-"}
                      </Typography>
                    </TableCell>

                    {/* SHOW: Facility of call */}
                    <TableCell className={styles.tableCell} align="center">
                      <Typography variant="body1">
                        {item?.hospitalName}
                      </Typography>
                    </TableCell>

                    {/* SHOW: Quantity of minutes */}
                    <TableCell className={styles.tableCell} align="right">
                      <Typography variant="body1" className={styles.bold}>
                        {item?.duration} min
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              } else {
                return null;
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
