import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NetworkQualityLevel } from "twilio-video";
import { BAD_NETWORK_THRESHOLD_LEVEL } from "../../constants";
import { AppState } from "../../store/models";
import { updateBannerNeedsUpdate } from "../../store/twilio/actions";
import "./BadNetworkBanner.scss";
interface StateProps {
  bannerNeedsUpdate: boolean;
  localNetworkQualityLevel: NetworkQualityLevel;
}

interface DispatchProps {
  updateBannerNeedsUpdate: (bannerNeedsUpdate: boolean) => void;
}

type Props = StateProps & DispatchProps;

class BadNetworkBanner extends React.Component<Props> {
  state = {
    showBadNetworkBanner: false,
    badNetworkBannerTimer: -1
  };

  constructor(props: Props) {
    super(props);
    this.clearBadNetworkTimer = this.clearBadNetworkTimer.bind(this);
  }

  clearBadNetworkTimer() {
    this.setState({
      badNetworkBannerTimer: -1,
      showBadNetworkBanner: false
    });
  }

  /*
   * This code is useful, if we were to enable banner on demand
   * Futuritic task.
   * Caller has to invoke, this.props.updateBannerNeedsUpdate(true);
   */
  // showBannerBasedOnInCallFeatures(prevProps: any, prevState: any) {
  //   const { bannerNeedsUpdate, localNetworkQualityLevel } = this.props;
  //   const { showBadNetworkBanner, badNetworkBannerTimer } = this.state;
  //   if (
  //     bannerNeedsUpdate &&
  //     bannerNeedsUpdate !== prevProps.bannerNeedsUpdate
  //   ) {
  //     this.props.updateBannerNeedsUpdate(false);
  //     if (localNetworkQualityLevel <= BAD_NETWORK_THRESHOLD_LEVEL) {
  //       /* If a banner is currently being shown, reset timer */
  //       if (showBadNetworkBanner) {
  //         window.clearTimeout(badNetworkBannerTimer);
  //       }
  //       const newBadNetworkTimer = window.setTimeout(
  //         this.clearBadNetworkTimer,
  //         5000
  //       );
  //       this.setState({
  //         badNetworkBannerTimer: newBadNetworkTimer,
  //         showBadNetworkBanner: true
  //       });
  //     } else {
  //       if (showBadNetworkBanner) {
  //         window.clearTimeout(badNetworkBannerTimer);
  //       }
  //       this.clearBadNetworkTimer();
  //     }
  //   }
  // }

  render() {
    const { localNetworkQualityLevel } = this.props;
    const showBadNetworkBanner =
      localNetworkQualityLevel <= BAD_NETWORK_THRESHOLD_LEVEL &&
      localNetworkQualityLevel !== -1;
    return (
      <div className="bad-network-banner-placeholder">
        {showBadNetworkBanner && (
          <div className="bad-network-banner">
            <div className="bad-network-banner-text">
              <div>
                Bad quality network detected. Some features may not work
                reliably.
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  bannerNeedsUpdate: state.twilio.bannerNeedsUpdate,
  localNetworkQualityLevel: state.twilio.localNetworkQualityLevel
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateBannerNeedsUpdate: (bannerNeedsUpdate: boolean) =>
      dispatch(updateBannerNeedsUpdate(bannerNeedsUpdate))
  };
};

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(BadNetworkBanner);
