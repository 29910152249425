import { ToggleTrackEnableDisable } from "src/domains/Beacon/components/TwilioSubscriptions/common";
import { useDominantSpeakerChanged } from "src/domains/Beacon/components/TwilioSubscriptions/useDominantSpeakerChanged";
import { useNetworkQualityLevelSubscribed } from "src/domains/Beacon/components/TwilioSubscriptions/useNetworkQualityLevelSubscribed";
import { useRoomLocalParticipantDisconnected } from "src/domains/Beacon/components/TwilioSubscriptions/useRoomLocalParticipantDisconnected";
import { useRoomLocalParticipantReconnected } from "src/domains/Beacon/components/TwilioSubscriptions/useRoomLocalParticipantReconnected";
import { useRoomLocalParticipantReconnecting } from "src/domains/Beacon/components/TwilioSubscriptions/useRoomLocalParticipantReconnecting";
import { useRoomParticipantConnected } from "src/domains/Beacon/components/TwilioSubscriptions/useRoomParticipantConnected";
import { useRoomParticipantDisconnected } from "src/domains/Beacon/components/TwilioSubscriptions/useRoomParticipantDisconnected";
import { useRoomParticipantReconnected } from "src/domains/Beacon/components/TwilioSubscriptions/useRoomParticipantReconnected";
import { useRoomTrackSubscribed } from "src/domains/Beacon/components/TwilioSubscriptions/useRoomTrackSubscribed";
import { useRoomTrackUnsubscribed } from "src/domains/Beacon/components/TwilioSubscriptions/useRoomTrackUnsubscribed";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";

const TwilioSubscriptionsInner = () => {
  useRoomTrackSubscribed();
  useRoomTrackUnsubscribed();
  useRoomLocalParticipantReconnecting();
  useRoomLocalParticipantReconnected();
  useRoomLocalParticipantDisconnected();
  useRoomParticipantConnected();
  useRoomParticipantDisconnected();
  useRoomParticipantReconnected();
  useDominantSpeakerChanged();
  useNetworkQualityLevelSubscribed();
  // TODO
  // TODO. useRoomParticipantJoined
  // TODO. useRoomParticipantLeft
  // TODO. useRoomTrackPublished
  // Refer to connectTwilio
  // useRoomParticipantReconnecting

  return (
    <>
      {/** Subscriptions that have common code */}
      <ToggleTrackEnableDisable /> {/** track enabled/disabled events */}
    </>
  );
};

export const TwilioSubscriptions = () => {
  const { room } = useAppSelector(selectTwilioState);
  return room ? <TwilioSubscriptionsInner /> : null;
};
