import { BaseResponse } from "../types";
import { BaseVendor } from "../vendor";

export type UserStatus = "ACTIVE" | "CREATED" | "DELETED";

// TODO finish user type definitions with the rest of the possible types
export type UserTypes =
  | "VENDOR_ADMIN"
  | "VENDOR_MANAGER"
  | "VENDOR_SALES_REPRESENTATIVE" // = VENDOR_FIELD_REPRESENTATIVE
  | "AVAIL_ADMIN"
  | "PROCTOR"
  | "SPECIALIST" // = HCP
  | "INDIVIDUAL_SALES_REPRESENTATIVE" // IFR
  | "GUEST";

// TODO finish user type definitions with the rest of the possible names
export type FriendlyUserNames = "Avail Administrator";

export const isVendorType = (userType: UserTypes) =>
  userType === "VENDOR_ADMIN" ||
  userType === "VENDOR_SALES_REPRESENTATIVE" ||
  userType === "VENDOR_MANAGER";

export type UserMemberTypes = "MEMBER" | "GUEST" | "TRIAL" | "TRIAL_EXPIRED";

export interface CreateUserRequest {
  autoCreated: boolean;
  email: string;
  firstName: string;
  lastName: string;
  managerId?: string;
  orgId: string;
  userType: UserTypes;
}

export interface BaseUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isMember?: boolean; // if the user is a guest user then isMember will be true
  memberType: UserMemberTypes;
}

export interface User extends BaseUser {
  userStatus: UserStatus;
  userTypes: UserTypes[];
}

export interface UserDetails extends BaseUser {
  accountLocked: boolean;
  avatarUrl: string;
  finishedAccountSetup: boolean;
  loginId: string;
  orgId: string;
  status: UserStatus;
  userType: UserTypes;
  userTypes: UserTypes[];
  userTypesFriendlyName: FriendlyUserNames;
  userTypesFriendlyNames: FriendlyUserNames[];
}

export interface UserRole {
  id: string;
  role: string;
  roleDesc: string;
  status: UserStatus;
  organization: {
    id: string;
    name: string;
  };
  brand: {
    id: string;
    name: string;
    logoLocation: string;
  };
  provider: any; // TODO :
}

export interface UserPayload extends UserDetails {
  autoCreated: boolean;
  createdAt: string;
  timezone?: string;
}

// getUserByEmail returns a different set of properties
export interface UserDetailsFromEmail extends BaseUser {
  accountLocked: boolean;
  loginCount: number;
  password: string;
  passwordAutoGenerated: boolean;
  passwordExpAt: string;
  passwordLockCounterUpdatedAt: string;
  twoFactorAuthEnabled: boolean;
}

export interface Organization {
  id: string;
  name: string;
  active: string | null; // TODO: should this be a boolean?
  address: string | null;
  city: string | null;
  state: string | null;
}

export interface Manager {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  userStatus: UserStatus;
  userType: UserTypes;
}

export interface UserAndOrganization {
  employee: User;
  organization: Organization;
}

export interface Brand extends BaseVendor {
  id: string;
}

export interface UserOrganizationDetails {
  active: boolean;
  address: string;
  brand: Brand;
  city: string;
  id: string;
  name: string;
  state: string;
  // TODO add different possible vendorTypes as new type
  vendorType: string;
}

export interface UserGuestRoles {
  titles: [];
}

export interface ChangePassword {
  confirmPassword: string;
  oldPassword: string;
  password: string;
}
export type UserSettingTypes =
  | "EMAIL_NOTIFICATION"
  | "PREFERRED_LANGUAGE"
  | "TWO_FACTOR_AUTH_ENABLED"
  | "TIME_ZONE";
export interface UserSettings {
  type: UserSettingTypes;
  value: string;
}
// example response for user settings, TODO add types for the values according to the type field
// [
//   {type: "EMAIL_NOTIFICATION", value: "ALWAYS"}
//   {type: "PREFERRED_LANGUAGE", value: null}
//   {type: "TWO_FACTOR_AUTH_ENABLED", value: "false"}
//   {type: "TIME_ZONE", value: "America/Los_Angeles"}
// ]

export interface TimeZones {
  // example: {id: "Africa/Abidjan", offset: "+00:00" displayName: "Place in the world"}
  id: string;
  offset: string;
  displayName?: string;
}

// response internal error codes for the domain validation step of the trial sign up
// applies to client function: checkIfTrialAvailibleForOrganization
export enum DomainValidationErrorCodes {
  MEMBER_ACCOUNT_ALREADY_EXISTS = 20001,
  TRIAL_ACCOUNT_ALREADY_EXISTS = 20002,
  MEMBER_ACCOUNT_NOT_ACTIVATED = 20003,
  TRIAL_ACCOUNT_NOT_ACTIVATED = 20004,
  EXPIRED_TRIAL_ACCOUNT_ALREADY_EXISTS = 20005,
  DISABLED_MEMBER_ACCOUNT_ALREADY_EXISTS = 20006,
  ORG_NOT_TRIAL_ELIGIBLE = 20007,
}

export type UserListResponse = BaseResponse<UserAndOrganization[]>;
export type UsersListResponse = BaseResponse<BaseUser[]>;
export type UserDetailResponse = BaseResponse<UserDetails>;
export type UserGuestRoleResponse = BaseResponse<UserGuestRoles>;
export type UserManagerResponse = BaseResponse<Manager>;
export type UserOrganizationResponse = BaseResponse<UserOrganizationDetails[]>;
export type UserDetailsFromEmailResponse = BaseResponse<UserDetailsFromEmail>;

export type TimeZonesResponse = BaseResponse<TimeZones[]>;
export type UserSettingsResponse = BaseResponse<UserSettings[]>;
