import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { handleSetAudioOutputDeviceThunk } from "src/domains/Beacon/store/stream/thunks/handleSetAudioOutputDeviceThunk";
import { LoggerLevels, logger } from "src/logging/logger";

interface IParams {
  customSpeaker?: MediaDeviceInfo;
}

export const handleSetHostAudioOutputDeviceThunk = ({
  customSpeaker,
}: IParams) => (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const {
      stream: {
        localMedia: { speaker },
        mpRemoteTracks: { host },
      },
    } = getState();

    // Needed so we can receive a custom Speaker device because the `localMedia` device could be outdated
    const speakerExpected = customSpeaker ?? speaker;

    logger().info(
      `Host audio-output setup, outputting Host's audio through speaker: ${speakerExpected.label}`
    );

    // Must check if Host's audio is available
    if (host?.audio) {
      const hostMediaElement = host.audio.attach();

      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Media Devices",
          fileInfo: "handleSetHostAudioOutputDeviceThunk.ts",
        },
        `Changing audio-output device with id: ${speakerExpected.deviceId} for host, track name is: ${host.audio.name}`
      );

      dispatch(
        handleSetAudioOutputDeviceThunk({
          mediaElement: hostMediaElement,
          audioOutputDevice: speakerExpected,
        })
      );
    }
  } catch (error: any) {
    logger().error(
      `Error while changing Browser's audio-output device for Host.`,
      error?.message
    );
  }
};
