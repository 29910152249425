import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import userService from '../../../../../../Common/Services/userService';
import AccordionComponent from '../../../../Common/AccordionComponent/AccordionComponent';
import { getTherapiesAction } from '../../../Organisation/Vendors/VendorsActions';

class AccountCreationFormTherapiesPart extends PureComponent {
	componentDidMount() {
    const { 
      currentUser, 
      getTherapies, 
      userOrganisationId 
    } = this.props;
    
    const isVendorUser = userService.checkIsVendorUser(currentUser.userTypes[0]);
		
    if (isVendorUser) {
      getTherapies(userOrganisationId);
    } else {
      // show all therapies & procedures
      getTherapies();
    }
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.userOrganisationId !== nextProps.userOrganisationId) {
			nextProps.getTherapies(nextProps.userOrganisationId);
		}
	}

	onSelectAllHandler = (e, id) => {
		this.props.therapies.forEach((therapy) => {
			if (therapy.id === id) {
				therapy.procedures.forEach((procedure) => {
					this.props.change(`procedures.${procedure.id}`, e.target.checked);
				});
			}
		});
	};

	getTherapiesFormMessage = () => {
		const message = userService.checkIsVendorAdmin(this.props.currentUser.userTypes[0]) ? 'your unit will support' : 'you support';
		return `Choose procedures ${message}`;
	};

	render() {
		return (
			<div className="account-creation__therapies-form">
				<div className="account-creation__heading">
					{this.getTherapiesFormMessage()}
				</div>
				<div className="account-setup__accordion-wrapper">
					<AccordionComponent
						data={this.props.therapies}
						procedureName="procedures"
						onSelectAllHandler={this.onSelectAllHandler}
						initialValues={this.props.initialValues}
						selectedValues={this.props.accountCreationForm}
					/>
				</div>
			</div>
		);
	}
}

AccountCreationFormTherapiesPart.defaultProps = {
	therapies: [],
	currentUser: {},
	userOrganisationId: '',
};

AccountCreationFormTherapiesPart.propTypes = {
	therapies: PropTypes.array,
	currentUser: PropTypes.object,
	userOrganisationId: PropTypes.string,
};

const mapStateToProps = state => ({
	therapies: state.vendorsReducer.therapies,
	currentUser: state.homeReducer.currentUser,
	userOrganisationId: state.usersReducer.userOrganisation && state.usersReducer.userOrganisation.id,
});

const mapDispatchToProps = dispatch => ({
	getTherapies: userOrganisationId => dispatch(getTherapiesAction(userOrganisationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreationFormTherapiesPart);
