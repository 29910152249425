import React, { useState } from "react";

import clsx from "clsx";

import { Grid, Typography, Link as MuiLink } from "@mui/material";

import { Link } from "src/components/legacy/Link";
import { Logo } from "src/components/legacy/Logo";
import { NavBar } from "src/components/legacy/NavBar";
import { EULA_PDF_URL_PATH } from "src/constants";
import { DetailsForm } from "src/domains/TrialSignUp/components/DetailsForm";
import { GetStartedForm } from "src/domains/TrialSignUp/components/GetStartedForm";

import styles from "./styles.scss";

export const TrialSignUp = () => {
  // need to save user's email in a separate slice of state up here because we need it in step 2
  const [userEmail, setUserEmail] = useState<string>();

  // TODO upgrade this to be more readable (perhaps an enum)
  // step 0 is the get started form (with just email)
  // step 1 is the details form (name, facilties, etc details to finish signing up)
  // step 2 is the final "success" screen
  const [step, setStep] = useState(0);

  return (
    <div data-test-id="trial-sign-up" className={styles.container}>
      <NavBar>
        <Logo />
      </NavBar>
      <Grid
        container
        direction="row"
        className={styles.formsContainer}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="column"
          lg={6}
          justifyContent="center"
          alignItems="center"
        >
          {/* this empty grid is necessary to get the spacing correct for the last grid below to appear at the bottom of the page */}
          {/* for xs all the way to just below lg leave it as auto, once its at lg then make sure its full width (12) */}
          <Grid item container xs="auto" lg={12}>
            <> </>
          </Grid>
          <Grid
            item
            container
            lg={10}
            direction="column"
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
          >
            {step === 0 && (
              <GetStartedForm
                setUserEmail={setUserEmail}
                continueToNextStep={() => setStep(1)}
              />
            )}
            {step === 1 && (
              <DetailsForm
                userEmail={userEmail}
                continueToNextStep={() => setStep(2)}
              />
            )}
            {step === 2 && (
              <>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  data-test-id="trial-sign-up-success-container"
                  xs={8}
                >
                  <Grid item container justifyContent="center">
                    <Typography variant="h6">
                      Your account has been successfully created
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="center"
                    className={styles.wrapper}
                  >
                    <Typography className={styles.successMessage} variant="h5">
                      You're almost there. We've sent an email to your inbox.
                      Just click on the link in that email to complete your
                      account signup.
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          {/* this last grid will show at the bottom of the page and it just holds some links */}
          <Grid
            item
            container
            direction="column"
            lg="auto"
            justifyContent="center"
            alignItems="center"
          >
            {/* For first and last step we show this text */}
            {(step === 0 || step === 2) && (
              <>
                <Typography variant="subtitle1">
                  Already have an account?
                </Typography>
                <Typography
                  variant="subtitle1"
                  data-test-id="trial-sign-up-login-link"
                >
                  <Link to="/login">Login</Link>
                </Typography>
              </>
            )}
            {/* this step has the actual form that the user fills out so we have to show our EULA & privacy policy */}
            {step === 1 && (
              <>
                <Typography variant="subtitle1">
                  By clicking Continue, you agree to our{" "}
                  <MuiLink
                    component="a"
                    // this below URL to the EULA is confirmed to not change after taking to PM and marketing
                    href={EULA_PDF_URL_PATH}
                    target="_blank"
                    underline="hover"
                    data-test-id="trial-sign-up-eula-link"
                  >
                    End User License Agreement
                  </MuiLink>
                </Typography>
                <Typography variant="subtitle1">
                  and have read and acknowledged our{" "}
                  <MuiLink
                    component="a"
                    href="https://www.avail.io/privacy/"
                    target="_blank"
                    underline="hover"
                    data-test-id="trial-sign-up-privacy-policy-link"
                  >
                    Privacy Policy
                  </MuiLink>
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          lg={6}
          className={styles.imageWrapper}
          data-test-id="trial-sign-up-image-container"
        >
          <div
            className={clsx(styles.image, {
              // we show a different image depending on what step the user is on
              [styles.step0]: step === 0,
              [styles.step1]: step >= 1,
            })}
          />
        </Grid>
      </Grid>
    </div>
  );
};
