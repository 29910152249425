import moment from "moment";

import { Pagination, Typography } from "@mui/material";

import { UserCallHistoryItem } from "src/services/ApiClient/trialUsage";

import { groupCallsByDays } from "../../utils";
import { CallHistoryTable } from "./CallHistoryTable";
import styles from "./styles.scss";

export interface Props {
  data: UserCallHistoryItem[];
  page: number;
  handlePageChange: (event: any, value: any) => void;
  totalPages?: number;
}

export const CallHistory = ({
  data,
  page,
  totalPages,
  handlePageChange,
}: Props) => {
  const groupedCalls = groupCallsByDays(data);

  // If the data hasn't loaded, don't continue
  if (!groupedCalls) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Typography className={styles.title} variant="h4">
        Call History
      </Typography>

      {/* Call history data */}
      {data.length === 0 ? (
        <div className={styles.noCalls}>
          <Typography data-test-id="call-history-empty" variant="h5">
            No previous calls made.
          </Typography>
        </div>
      ) : (
        <>
          {groupedCalls.map((group) => (
            <div className={styles.day} key={group.day}>
              <Typography variant="subtitle1" className={styles.dayHeader}>
                {/* If the event is today, set the prefix before the date */}
                {moment(group.day).isSame(new Date(), "day") ? "Today, " : null}
                {moment(group.day).format("dddd, MMMM Do")}
              </Typography>

              {/* Display a table for each group of days */}
              <CallHistoryTable key={group.day} items={group.items} />
            </div>
          ))}
          {/* if we have more than 1 total page then show the pagination */}
          {totalPages && totalPages > 1 ? (
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />
          ) : null}
        </>
      )}
    </div>
  );
};
