import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
/* Components */
import ModalComponent from '../../../../Common/Components/Modal/Modal';
import {Table} from '../../Common';
import AddNewEntity from '../../Common/AddNewEntity/AddNewEntity';
import BrandTableRow from './Components/BrandTableRow/BrandTableRow';
import CreateNewBrand from './Features/CreateNewBrand/CreateNewBrand';
/* Actions */
import {createNewBrandAction, deleteBrandAction, getAllBrandsAction} from './BrandsActions';
/* Images & Styles */
import {addNewHospital} from '../../../../Common/Assets/Images';
import './Brands.scss';
import {uploadLogo} from './BrandsUtils';
import {notificationService} from '../../../../Common/Services';

class Brands extends PureComponent {
	state = {
		isCreateNewBrandOpened: false,
		isDeleteModalOpened: false,
		brand: null,
		pageSize: 10,
	};

	columns = [
		{
			title: 'Vendor Name',
			sortValue: 'name',
		},
	];

	componentDidMount() {
		this.params = {
			size: this.state.pageSize,
			page: 0,
		};
	}

	onCreateNewBrandHandler = async (data) => {
		const {domains} = data;
		const lookup = {};
		let check = false;

		for (let i = 0; i < domains.length; i++) {
			if (!lookup[domains[i]]) {
				lookup[domains[i]] = true;
			} else {
				check = true;
				break;
			}
		}
		if (!check) {
			try {
				const {logoLocation: logoFile} = data;
				const uploaded = await uploadLogo(data.name, logoFile);
				const brand = {...data, logoLocation: uploaded};

				this.props.createNewBrand(brand);
			} catch {
				notificationService.error('Error creating vendor.');
			}
			this.toggleCreateNewBrandModal();
		} else {
			this.toggleCreateNewBrandModal();
			notificationService.error("Error creating vendor, duplicate domain entries")
		}
	};

	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = {
			size: this.state.pageSize,
			page: 0,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllBrands(this.params);
	};

	onPageChangeHandler = (e, {activePage}) => {
		this.params = {
			...this.params,
			size: this.state.pageSize,
			page: activePage - 1,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllBrands(this.params);
	};

	handleVariablePaginationChange = (option) => {
		if (!option) return;

		this.setState({pageSize: option});
		this.props.getAllBrands({
			size: option,
			page: 0,
			[this.parameter]: this.sortParams,
		});
	}

	toggleCreateNewBrandModal = () => this.setState(
		{isCreateNewBrandOpened: !this.state.isCreateNewBrandOpened}
	);

	renderCreateNewBrandsModal = () => (
		<ModalComponent
			open={this.state.isCreateNewBrandOpened}
			onCancel={this.toggleCreateNewBrandModal}
			hasActionButtons={false}
			title="Create new Vendor"
		>
			<CreateNewBrand
				onSubmit={this.onCreateNewBrandHandler}
				toggleCreateNewBrandModal={this.toggleCreateNewBrandModal}
			/>
		</ModalComponent>
	);

	onConfirm = () => {
		this.props.deleteBrand(this.state.brand.id);
		this.toggleDeleteModal();
	};

	nextPage = () => this.setState({page: this.state.page + 1});

	previousPage = () => this.setState({page: this.state.page - 1});

	toggleDeleteModal = (brand = {}) => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
			brand,
		});
	};

	renderDeleteBrandModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onConfirm}
			onCancel={this.toggleDeleteModal}
			confirmText="Delete"
		>
			{
				this.state.brand && (
					<span>
						You are about to delete <span className="text--bold">{this.state.brand.name}</span>.
						Would you like to proceed with this action?
					</span>
				)
			}
		</ModalComponent>
	);

	render() {
		return (
			<div className="users__container">
				<Table
					row={BrandTableRow}
					columns={this.columns}
					tableData={this.props.brands}
					openModal={this.toggleDeleteModal}
					// FIXME: fix this handler
					onSortTableHandler={this.onTableFilterHandler}
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					paginationConfig={this.props.pagination}
					onPageChangeHandler={this.onPageChangeHandler}
					className="brand__table"
					noItemsClass="height--full justify-content--center align--center"
					onAddNewHandler={this.toggleCreateNewBrandModal}
					headerTitle="Vendors"
					noEntityComponent={
						<AddNewEntity
							image={addNewHospital}
							addNewEntityHandler={this.toggleCreateNewBrandModal}
							title="Add new Vendor"
						/>
					}
					search
					sortable
					variablePagination
					onVariablePaginationChange={this.handleVariablePaginationChange}
				/>
				{this.renderDeleteBrandModal()}
				{this.renderCreateNewBrandsModal()}
			</div>
		);
	}
}

Brands.defaultProps = {
	brands: [],
	getAllBrands: () => {
	},
	createNewBrand: () => {
	},
	deleteBrand: () => {
	},
};

Brands.propTypes = {
	brands: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
		logoLocation: PropTypes.string,
		domains: PropTypes.array,
	})),
	getAllBrands: PropTypes.func,
	createNewBrand: PropTypes.func,
	deleteBrand: PropTypes.func,
};

const mapStateToProps = state => ({
	brands: state.brandsReducer.brands,
	pagination: state.brandsReducer.pagination,
});

const mapDispatchToProps = dispatch => ({
	getAllBrands: params => dispatch(getAllBrandsAction(params)),
	createNewBrand: brandData => dispatch(createNewBrandAction(brandData)),
	deleteBrand: id => dispatch(deleteBrandAction(id)),
	resetReduxForm: formName => dispatch(reset(formName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
