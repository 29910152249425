import React, { useState } from "react";

import clsx from "clsx";

import { NetworkIcon } from "src/components/NetworkStatus/NetworkIcon";
import { Tooltip } from "src/components/Tooltip";
import { AVAIL_NETWORK_TESTING_URL } from "src/domains/Beacon/constants";

import styles from "./styles.scss";

export interface NetworkStatusProps {
  value: 1 | 2 | 3 | 4 | 5;
}

export const NetworkStatus = ({ value }) => {
  const [hover, setHover] = useState<boolean>(false);
  return (
    <Tooltip title="Network Health">
      <div
        className={clsx(styles.root, { [styles.hover]: hover })}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={() => {
          // opens new window tall enough to show there is a second row of
          // diagnostic tools and wide enough to not have awkward-looking
          // responsive formatting for each diagnostic tool header
          window.open(
            AVAIL_NETWORK_TESTING_URL,
            "networkTestingSuite",
            "location=yes,width=850,height=800"
          );
        }}
      >
        <NetworkIcon value={value} data-test-id="network-status" />
      </div>
    </Tooltip>
  );
};
