import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";
import { NIncomingServerCallEvent } from "availkit-js/dist/Models/Events/NIncomingServerCallEvent";

import {
  CallBackEventStateType,
  CallStateType,
  FreezeFrameStateType,
  LayoutFrame,
  MultiPartyEventDetail,
  MultiPartyCallEventInfo,
  ZoomStateType,
  CallModeType,
  UserRoleType,
  MultiPartyInitializeState,
} from "../models";

export enum MeetingActionKeys {
  SET_CALL_SID = "SET_CALL_SID",
  SET_CALLBACK = "SET_CALLBACK",
  SET_CALL_STATE = "SET_CALL_STATE",
  SET_MULTI_PARTY_CALL_EVENT = "SET_MULTI_PARTY_CALL_EVENT",
  SET_CALLBACK_INFO = "SET_CALLBACK_INFO",
  GET_MULTI_PARTY_CALL_DETAILS = "GET_MULTI_PARTY_CALL_DETAILS",
  INITIALIZE_MULTI_PARTY_CALL = "INITIALIZE_MULTI_PARTY_CALL",
  SET_CURRENT_USER_ROLE = "SET_CURRENT_USER_ROLE",
  SET_MULTI_PARTY_CALL_EVENT_INFO = "SET_MULTI_PARTY_CALL_EVENT_INFO",
  SET_MULTI_PARTY_EVENT_DETAILS = "SET_MULTI_PARTY_EVENT_DETAILS",
  SET_TELESTRATION_HISTORY = "SET_TELESTRATION_HISTORY",
  SET_JOIN_ID = "SET_JOIN_ID",
  SET_CALL_MODE = "SET_CALL_MODE",
  SET_REMOTE_EVENT = "SET_REMOTE_EVENT",
  SET_CAMERAS_LIST = "SET_CAMERAS_LIST",
  SET_CAMERAS_HAVE_UPDATE = "SET_CAMERAS_HAVE_UPDATE",
  SET_LAYOUTFRAMES_LIST = "SET_LAYOUTFRAMES_LIST",
  SET_MENUOPTION_TYPE = "SET_MENUOPTION_TYPE",
  SET_LAYOUT_HAS_UPDATE = "SET_LAYOUT_HAS_UPDATE",
  SET_TRIGGER_REFRESH_FRAMES = "SET_TRIGGER_REFRESH_FRAMES",
  SET_BLUETOOTH_ENABLED = "SET_BLUETOOTH_ENABLED",
  SET_EXTERNAL_INPUT_RESIZING = "SET_EXTERNAL_INPUT_RESIZING",
  SET_NOISE_CANCELLATION_STATE = "SET_NOISE_CANCELLATION_STATE",
  SET_NOISE_CANCELLATION_AVAILABLE = "SET_NOISE_CANCELLATION_AVAILABLE",
  SET_ZOOMSTATE = "SET_ZOOMSTATE",
  SET_SHOULD_ENABLE_IN_CALL_CONTROLS = "SET_SHOULD_ENABLE_IN_CALL_CONTROLS",
  SET_FREEZE_STATE = "SET_FREEZE_STATE",
  SET_FREEZEFRAME_HAS_UPDATE = "SET_FREEZEFRAME_HAS_UPDATE",
}

interface SetCallback {
  type: MeetingActionKeys.SET_CALLBACK;
  callback: boolean;
}

interface SetCallMode {
  type: MeetingActionKeys.SET_CALL_MODE;
  payload: CallModeType;
}

interface SetCallSid {
  type: MeetingActionKeys.SET_CALL_SID;
  callSid: string;
}

interface SetCallState {
  type: MeetingActionKeys.SET_CALL_STATE;
  callState: CallStateType;
}

interface SetCallbackInfo {
  type: MeetingActionKeys.SET_CALLBACK_INFO;
  callbackInfo: CallBackEventStateType;
}

interface SetCurrentUserRole {
  type: MeetingActionKeys.SET_CURRENT_USER_ROLE;
  userRole: UserRoleType;
}

interface GetMultiPartyEventDetails {
  type: MeetingActionKeys.GET_MULTI_PARTY_CALL_DETAILS;
  payload: MultiPartyInitializeState;
}
interface InitializeMultiPartyCall {
  type: MeetingActionKeys.INITIALIZE_MULTI_PARTY_CALL;
  payload: MultiPartyInitializeState;
}

interface SetMultiPartyEventCallInfo {
  type: MeetingActionKeys.SET_MULTI_PARTY_CALL_EVENT_INFO;
  multiPartyCallEventInfo: MultiPartyCallEventInfo;
}
interface SetMultiPartyEventDetails {
  type: MeetingActionKeys.SET_MULTI_PARTY_EVENT_DETAILS;
  multiPartyCallEventDetail: MultiPartyEventDetail;
}

interface SetBluetoothEnabledAction {
  type: MeetingActionKeys.SET_BLUETOOTH_ENABLED;
  bluetoothEnabled: boolean;
}

interface SetNoiseCancellationAction {
  type: MeetingActionKeys.SET_NOISE_CANCELLATION_STATE;
  noiseCancellation: boolean;
}

interface SetNoiseCancellationAvailableAction {
  type: MeetingActionKeys.SET_NOISE_CANCELLATION_AVAILABLE;
  noiseCancellationAvailable: boolean;
}

interface SetTelestrationHistory {
  type: MeetingActionKeys.SET_TELESTRATION_HISTORY;
  telestrationHistory: AVKAnnotationEvent[];
}

interface SetRemoteEvent {
  type: MeetingActionKeys.SET_REMOTE_EVENT;
  callEventInfo: NIncomingServerCallEvent;
}

interface SetCamerasList {
  type: MeetingActionKeys.SET_CAMERAS_LIST;
  cameras: Array<AVKCamera | AVKExternalInput>;
}

interface SetCamerasHaveUpdate {
  type: MeetingActionKeys.SET_CAMERAS_HAVE_UPDATE;
  shouldUpdateCameras: boolean;
}
interface SetJoinId {
  type: MeetingActionKeys.SET_JOIN_ID;
  joinId: string;
}

interface SetEnableInCallControls {
  type: MeetingActionKeys.SET_SHOULD_ENABLE_IN_CALL_CONTROLS;
  enableInCallControls: boolean;
}

interface SetConsoleHasExternalInputResizing {
  type: MeetingActionKeys.SET_EXTERNAL_INPUT_RESIZING;
  consoleHasExternalInputResizing: boolean;
}

interface SetLayoutFramesList {
  type: MeetingActionKeys.SET_LAYOUTFRAMES_LIST;
  layoutFrames: LayoutFrame[];
}

interface SetLayoutHasUpdate {
  type: MeetingActionKeys.SET_LAYOUT_HAS_UPDATE;
  isUpdated: boolean;
}
interface SetTriggerRefreshFrames {
  type: MeetingActionKeys.SET_TRIGGER_REFRESH_FRAMES;
  shouldTriggerRefreshFrames: boolean;
}

interface SetZoomState {
  type: MeetingActionKeys.SET_ZOOMSTATE;
  zoomState: ZoomStateType;
}

interface SetMenuOptionsType {
  type: MeetingActionKeys.SET_MENUOPTION_TYPE;
}

interface SetFreezeFrameState {
  type: MeetingActionKeys.SET_FREEZE_STATE;
  freezeFrameState: FreezeFrameStateType;
}

interface SetFreezeFrameHasUpdate {
  type: MeetingActionKeys.SET_FREEZEFRAME_HAS_UPDATE;
  updateFreezeFrame: boolean;
}

export type MeetingActionType =
  | GetMultiPartyEventDetails
  | InitializeMultiPartyCall
  | SetMultiPartyEventCallInfo
  | SetCallMode
  | SetMultiPartyEventDetails
  | SetBluetoothEnabledAction
  | SetNoiseCancellationAction
  | SetNoiseCancellationAvailableAction
  | SetTelestrationHistory
  | SetCallbackInfo
  | SetCallback
  | SetCallSid
  | SetCallState
  | SetCurrentUserRole
  | SetRemoteEvent
  | SetCamerasList
  | SetCamerasHaveUpdate
  | SetLayoutFramesList
  | SetLayoutHasUpdate
  | SetTriggerRefreshFrames
  | SetZoomState
  | SetConsoleHasExternalInputResizing
  | SetEnableInCallControls
  | SetMenuOptionsType
  | SetJoinId
  | SetFreezeFrameState
  | SetFreezeFrameHasUpdate;
