import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
/* Components */
import ApprovalList from '../../Common/ApprovalList/ApprovalList';
import VendorApprovalTableRow from './Components/VendorApprovalTableRow/VendorApprovalTableRow';

const columns = [
	{
		title: 'Name',
		sortValue: 'name',
	},
	{
		title: 'User role',
		sortValue: 'role',
	},
	{
		title: 'Request status',
		sortValue: 'hospital',
	},
];

class VendorApprovalList extends PureComponent {
	render() {
		return (
			<ApprovalList
				organizationType="vendor"
				tableRowComponent={VendorApprovalTableRow}
				columns={columns}
				hospitalId={this.props.match.params.id}
			/>
		);
	}
}

VendorApprovalList.propTypes = {};

export default VendorApprovalList;
