import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './CreateNewHospital.scss';
import { Button, Fields, validators } from '../../../../../../../Common/Components';
import { getAllHospitalNetworksAction } from '../../../../HospitalNetwork/HospitalNetworkActions';
import { getAllStatesAction } from '../../../OrganisationActions';

class CreateNewHospital extends PureComponent {
	componentDidMount() {
		this.props.getAllHospitalNetworks();
		this.props.getAllStates();
	}

	render() {
		const { t } = this.props;

		return (
			<React.Fragment>
				<form action="" onSubmit={this.props.handleSubmit}>
					<Fields.InputField
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.placeholder')}
						name="name"
						validate={[validators.required, validators.minimumLength3, validators.maximumLength48]}
					/>
					<Fields.SelectField
						labelText={t('common.form.state.label')}
						placeholder={t('common.form.state.placeholder')}
						name="state"
						validate={[validators.required, validators.minimumLength2, validators.maximumLength64]}
						search
						options={this.props.states && this.props.states.map(state => ({
							key: state.id,
							value: state.name,
							text: `${state.name} (${state.abbreviation})`,
						}))}
					/>
					<Fields.InputField
						labelText={t('common.form.city.label')}
						placeholder={t('common.form.city.placeholder')}
						name="city"
						validate={[validators.required, validators.minimumLength2, validators.maximumLength64]}
					/>
					<Fields.InputField
						labelText={t('common.form.address.label')}
						placeholder={t('common.form.address.placeholder')}
						name="address"
						validate={[validators.required, validators.minimumLength3, validators.maximumLength128]}
					/>
					<Fields.SelectField
						labelText={t('common.form.network.label')}
						placeholder={t('common.form.network.placeholder')}
						name="networkId"
						options={this.props.hospitalNetworks && this.props.hospitalNetworks.map(hospitalNetwork => ({
							key: hospitalNetwork.id,
							value: hospitalNetwork.id,
							text: hospitalNetwork.name,
						}))}
						onChange={this.selectBrand}
						clearable
					/>
					<div className="button__wrapper">
						<Button
							onClick={this.props.toggleCreateNewHospitalModal}
							isGhost
							buttonSmall
						>
							Cancel
						</Button>
						<Button
							type="submit"
							isFull
							buttonSmall
						>
							Create
						</Button>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

CreateNewHospital.propTypes = {
	toggleCreateNewHospitalModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	hospitalNetworks: state.hospitalNetworkReducer.hospitalNetworks,
	states: state.organisationsReducer.states,
});

const mapDispatchToProps = dispatch => ({
	getAllHospitalNetworks: () => dispatch(getAllHospitalNetworksAction()),
	getAllStates: () => dispatch(getAllStatesAction()),
});

const CreateNewHospitalWithTranslation = withTranslation()(CreateNewHospital);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'createNewHospitalForm',
})(CreateNewHospitalWithTranslation));
