import { NConfig } from "availkit-js";

export enum AvailKitActionKeys {
  INITIALIZE_AVAIL_KIT = "INITIALIZE_AVAIL_KIT"
}

interface InitializeAvailKit {
  type: AvailKitActionKeys.INITIALIZE_AVAIL_KIT;
  config: NConfig;
  presenceUUID: string;
}

export type AvailKitActionType = InitializeAvailKit;
