import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
/* Components */
import { Fields, validators } from '../../../../../../../Common/Components';
import DaysOfWeekPickerField from '../../DaysOfWeekPickerField/DaysOfWeekPickerField';
/* Styles */
import '../../../Scheduler.scss';
/* Services */

export class CreateEventForm extends PureComponent {
	render() {
		const { hospitals, isRecurringEventChecked, onRecurringEventChecked } = this.props;

		return (
			<React.Fragment>
				<div className="create-event__form-section-wrapper">
					<Fields.CheckboxField
						label="Recurring"
						name="isRecurringEvent"
						checked={isRecurringEventChecked}
						onChange={onRecurringEventChecked}
					/>
				</div>
				<div>
					<div className="create-event__recurring-part">
						<Fields.SingleDateField
							autoComplete="off"
							labelText="Start date"
							name="recurringStartDate"
							validate={validators.required}
							disabled={!isRecurringEventChecked}
						/>
						<Fields.SingleDateField
							autoComplete="off"
							labelText="End date"
							name="recurringEndDate"
							minDate={this.props.selectedDate}
							validate={validators.requiredIfRecurringEvent}
							disabled={!isRecurringEventChecked}
						/>
					</div>
					<div className="create-event__days-picker">
						<label className="create-event__working-days-label">Working days</label>
						<DaysOfWeekPickerField disabled={!isRecurringEventChecked} />
					</div>
					<div>
						<Fields.MultiselectField
							labelText="Facilities"
							placeholder="Select Location(s)"
							name="hospitalIds"
							options={hospitals && hospitals.map(hospital => ({
								key: hospital.id,
								value: hospital.id,
								label: hospital.name,
							}))}
							validate={validators.required}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	createEventFormValues: getFormValues('createEventForm')(state),
	timezone: state.usersReducer.timezone,
});

export default reduxForm({
	form: 'createEventForm',
	destroyOnUnmount: false,
	enableReinitialize: true,
})(connect(mapStateToProps, undefined)(CreateEventForm));
