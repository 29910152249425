/*
 * Coreregistry users:
 * User users: https://api.titan-dev.avail.io/user/swagger-ui.html#/user-controller
 */
import {
  UserListResponse,
  UserDetailResponse,
  CreateUserRequest,
  UserOrganizationResponse,
  UserDetailsFromEmailResponse,
  ChangePassword,
  UserSettings,
  UserPayload,
  UserManagerResponse,
} from ".";
import { getQueryString } from "../../../utils/ApiHelper";
import ApiQuery from "../ApiQuery";
import { HospitalsResponse, ProceduresResponse } from "../BusinessUnit";
import { USER_API_URL, CORE_REGISTRY_API_URL, BASE_API_URL } from "../utils";

export const getUserList = (
  page = 0,
  size: number | "all" = 10,
  sort = "",
  extraParam = ""
) => {
  return ApiQuery<UserListResponse>(
    `${CORE_REGISTRY_API_URL}/users${getQueryString({
      page,
      size,
      sort,
      extraParam,
    })}`
  );
};

// TODO properly type return types for all functions below

export const getHospitalApplicationsForUser = (
  userId: string,
  page = 0,
  size: number | "all" = 10,
  sort = "",
  extraParam = ""
) => {
  return ApiQuery<any>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/hospital-applications${getQueryString(
      {
        page,
        size,
        sort,
        extraParam,
      }
    )}`
  );
};

export const getUserById = (userId: string) => {
  return ApiQuery<UserDetailResponse>(`${USER_API_URL}/users/${userId}`);
};

export const getUserByEmail = (email: string) => {
  return ApiQuery<UserDetailsFromEmailResponse>(
    `${USER_API_URL}/logins/info/${email}`
  );
};

export const createUser = (payload: CreateUserRequest) => {
  return ApiQuery<UserDetailResponse, CreateUserRequest>(
    `${USER_API_URL}/users`,
    "POST",
    payload
  );
};

export const getUserOrg = (userId: string) => {
  return ApiQuery<UserOrganizationResponse>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/employed`
  );
};

export const getUserManager = (userId: string) => {
  return ApiQuery<UserManagerResponse>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/manager`
  );
};

export const getUserProcedures = (userId: string) => {
  return ApiQuery<ProceduresResponse>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/procedures`
  );
};

export const getUserHospitals = (userId: string) => {
  return ApiQuery<HospitalsResponse>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/available-hospitals`
  );
};

export const getTimeZones = () => {
  return ApiQuery<any>(`${USER_API_URL}/settings/timezones`);
};

export const updateUser = (userId: string, payload: UserPayload) => {
  return ApiQuery<any, any>(`${USER_API_URL}/users/${userId}`, "PUT", payload);
};

export const updateUserSettings = (userId: string, payload: UserSettings) => {
  return ApiQuery<any, any>(
    `${USER_API_URL}/settings/${userId}`,
    "PUT",
    payload
  );
};

export const changePassword = (userId: string, payload: ChangePassword) => {
  return ApiQuery<any, any>(
    `${USER_API_URL}/accounts/${userId}/changePassword`,
    "PUT",
    payload
  );
};

export const updateUserHospital = (
  userId: string,
  hospitalId: string,
  payload: { blocked: boolean }
) => {
  return ApiQuery<any, any>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/hospital/${hospitalId}/support`,
    "PUT",
    payload
  );
};

export const deleteUser = async (userId: string) => {
  return await ApiQuery(`${USER_API_URL}/users/${userId}`, "DELETE");
};

export const inviteNewUser = (bodyPayload) => {
  return ApiQuery(`${USER_API_URL}/users`, "POST", bodyPayload);
};

export const getAllUserGroups = () => {
  return ApiQuery(`${USER_API_URL}/users/usergroups`);
};

export const getUserDetail = (userId) => {
  return ApiQuery(`${USER_API_URL}/users/${userId}`);
};

export const getUserSettings = (userId) => {
  return ApiQuery(`${USER_API_URL}/settings/${userId}`);
};

export const getUserSchedules = (userId) => {
  return ApiQuery(`${BASE_API_URL}/scheduler/schedules/users/${userId}`);
};

export const getUserMeetings = (userId, from, to) =>
  ApiQuery(
    `${BASE_API_URL}/callevent/history/users/${userId}${getQueryString({
      from,
      to,
    })}`
  );

export const postResetPw = (data, email) => {
  return ApiQuery(
    `${BASE_API_URL}/user/accounts/resetPassword/email/${email}`,
    "POST",
    data
  );
};

export const postSignup = (email, firstName, lastName, payload) => {
  const body = JSON.stringify({
    email,
    firstName,
    lastName,
    orgId: email,
    userType: "INDIVIDUAL_SALES_REPRESENTATIVE",
  });

  return ApiQuery(`${USER_API_URL}/sign-up`, "POST", body, payload);
};

export const putUpdatePw = (email, password, token) => {
  return ApiQuery(
    `${USER_API_URL}/accounts/changePassword/email/${email}`,
    "PUT",
    JSON.stringify({
      confirmPassword: password,
      email,
      password,
    }),
    { Authorization: `bearer ${token}` }
  );
};

export const reinviteUser = (userId: string) => {
  return ApiQuery(
    `${USER_API_URL}/users/${userId}/resendActivationEmail`,
    "POST",
    {}
  );
};

export const updateUserDetail = (userId: string, payload) => {
  return ApiQuery(`${USER_API_URL}/users/${userId}`, "PUT", payload);
};

export const updateUserProcedures = (userId: string, payload) => {
  return ApiQuery(
    `${CORE_REGISTRY_API_URL}/users/${userId}/procedures`,
    "PUT",
    payload
  );
};
