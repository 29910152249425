import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
/* Components */
import ModalComponent from '../../../../Common/Components/Modal/Modal';
import { Table } from '../../Common';
import AddNewEntity from '../../Common/AddNewEntity/AddNewEntity';
import CreateNewHospitalNetwork from './Features/CreateNewHospitalNetwork/CreateNewHospitalNetwork';
import HospitalNetworkRow from './Components/HospitalNetworkRow/HospitalNetworkRow';
/* Actions */
import { createNewHospitalNetworkAction, getAllHospitalNetworksAction, deleteHospitalNetworkAction } from './HospitalNetworkActions';
/* Images & Styles */
import { addNewHospital } from '../../../../Common/Assets/Images';

class HospitalNetwork extends PureComponent {
	state = {
		isCreateNewHospitalNetworkOpened: false,
		isDeleteModalOpened: false,
		selectedHospitalNetwork: null,
		pageSize: 10,
	};

	columns = [
		{
			title: 'Name',
			sortValue: 'name',
		}, {
			title: 'Facilities',
			sortValue: false,
			excluded: true,
		}
	];

	componentDidMount() {
		this.params = {
			size: this.state.pageSize,
			page: 0,
		};
	}

	onCreateNewHospitalNetworkHandler = (data) => {
		this.toggleCreateNewHospitalNetworkModal();
		this.props.createNewHospitalNetwork(data);
	};

	onDeleteConfirm = () => {
		this.props.deleteHospitalNetwork(this.state.selectedHospitalNetwork.id);
		this.toggleDeleteModal();
	}

	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = {
			size: this.state.pageSize,
			page: 0,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllHospitalNetworks(this.params);
	};

	onPageChangeHandler = (e, { activePage }) => {
		this.params = {
			...this.params,
			size: this.state.pageSize,
			page: activePage - 1,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllHospitalNetworks(this.params);
	};

	handleVariablePaginationChange = (option) => {
		if (!option) return;

		this.setState({ pageSize: option });
		this.props.getAllHospitalNetworks({
			size: option,
			page: 0,
			[this.parameter]: this.sortParams
		});
	}

	toggleDeleteModal = () => this.setState({ isDeleteModalOpened: !this.state.isDeleteModalOpened });
	toggleCreateNewHospitalNetworkModal = () => this.setState({ isCreateNewHospitalNetworkOpened: !this.state.isCreateNewHospitalNetworkOpened });

	renderCreateNewHospitalNetworkModal = () => {
		const { t } = this.props;

		return (
			<ModalComponent
				open={this.state.isCreateNewHospitalNetworkOpened}
				onCancel={this.toggleCreateNewHospitalNetworkModal}
				hasActionButtons={false}
				title={t('features.hospital_network.create.label')}
			>
				<CreateNewHospitalNetwork
					onSubmit={this.onCreateNewHospitalNetworkHandler}
					toggleCreateNewHospitalNetworkModal={this.toggleCreateNewHospitalNetworkModal}
				/>
			</ModalComponent>
		);
	};

	renderDeleteModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onDeleteConfirm}
			onCancel={this.toggleDeleteModal}
			confirmText="Delete"
		>
			{this.state.selectedHospitalNetwork && (
				<span>You are about to delete
					<span className="text--bold">
						{` ${this.state.selectedHospitalNetwork.name}`}
					</span>. Would you like to proceed with this action?
				</span>
			)}
		</ModalComponent>
	);

	render() {
		const { t } = this.props;
		return (
			<div className="users__container">
				<>
					<Table
						row={HospitalNetworkRow}
						columns={this.columns}
						tableData={this.props.hospitalNetworks}
						openModal={(network) => {
							this.setState({ selectedHospitalNetwork: network });
							this.toggleDeleteModal();
						}}
						paginationConfig={this.props.pagination}
						onPageChangeHandler={this.onPageChangeHandler}
						onSortTableHandler={this.onTableFilterHandler}
						onSearchTermUpdateHandler={this.onTableFilterHandler}
						className="hospital-network__table"
						noItemsClass="height--full justify-content--center align--center"
						onAddNewHandler={this.toggleCreateNewHospitalNetworkModal}
						headerTitle={t('features.hospital_network.name_plural')}
						noEntityComponent={
							<AddNewEntity
								image={addNewHospital}
								addNewEntityHandler={this.toggleCreateNewHospitalNetworkModal}
								title={t('features.hospital_network.create.label')}
							/>
						}
						sortable
						search
						variablePagination
						onVariablePaginationChange={this.handleVariablePaginationChange}
					/>
				</>

				{this.renderDeleteModal()}
				{this.renderCreateNewHospitalNetworkModal()}
			</div>
		);
	}
}

HospitalNetwork.defaultProps = {
	hospitalNetworks: [],
	getAllHospitalNetworks: () => {},
	createNewHospitalNetwork: () => {},
};

HospitalNetwork.propTypes = {
	hospitalNetworks: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
	getAllHospitalNetworks: PropTypes.func,
	createNewHospitalNetwork: PropTypes.func,
};

const mapStateToProps = state => ({
	pagination: state.hospitalNetworkReducer.pagination,
	hospitalNetworks: state.hospitalNetworkReducer.hospitalNetworks,
	userType: state.homeReducer.currentUser.userType,
});

const mapDispatchToProps = dispatch => ({
	getAllHospitalNetworks: params => dispatch(getAllHospitalNetworksAction(params)),
	createNewHospitalNetwork: hospitalNetworkData => dispatch(createNewHospitalNetworkAction(hospitalNetworkData)),
	deleteHospitalNetwork: id => dispatch(deleteHospitalNetworkAction(id)),
});

const HospitalNetworkWithTranslation = withTranslation()(HospitalNetwork);

export default connect(mapStateToProps, mapDispatchToProps)(HospitalNetworkWithTranslation);
