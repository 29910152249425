import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting";
import {
  CallModes,
  MeetingState,
} from "src/domains/Beacon/store/meeting/types";
import { logger } from "src/logging/logger";
import { getTwilioCredentials } from "src/services/ApiClient/twilio";
import UserSessionService from "src/services/UserSessionService";

// Automatically generates pending, fulfilled and rejected action types
// (see `createAsyncThunk` docs: https://redux-toolkit.js.org/api/createAsyncThunk)
export const getCredentialsThunk = createAsyncThunk<
  // Return type of the payload creator
  string,
  // First argument to the payload creator
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("twilio/getCredentials", async (_arg, { getState, dispatch }) => {
  try {
    const { meeting } = getState();
    const { mode, meetingToken, isCallback } = meeting;
    const user = UserSessionService.getCachedUserInfo();

    if (shouldCancelCredentialRetrieval(meeting)) {
      // throwing an error here will automatically send a 'rejected' action and
      // the error message would be included in property's `action.error.message`
      throw new Error("Failed to get Twilio credentials.");
    }

    // If the call is a callback, the twilioToken will be included in the event, see useGetMeetingToken
    if (isCallback) {
      const { twilioToken } = meetingToken;
      return twilioToken;
    }

    // If it's not a callback, make a request for the Twilio token
    // If this fails, `createAsyncThunk` sends a 'rejected' action to the store
    const twilioCredentials = await getTwilioCredentials(mode, meetingToken);

    if (mode === CallModes.MP) {
      logger().info(
        `Fetched Twilio credentials for MP call with thirdPartyIntegration list: ${JSON.stringify(
          twilioCredentials?.thirdPartyIntegration ?? "null"
        )}`
      );
      dispatch(meetingActions.setCallSid(twilioCredentials.callSid));
      dispatch(
        meetingActions.setThirdPartyIntegration(
          twilioCredentials.thirdPartyIntegration
        )
      );
    } else if (mode === CallModes.P2P) {
      dispatch(meetingActions.setCallSid(meetingToken.session_id));
    }

    // Must set the field logs
    logger().setCommonFields({
      callSessionId: twilioCredentials.callSid,
      profileId: user.loginId,
    });

    logger().info("Twilio token obtained successfully.");
    return twilioCredentials.twilioToken;
  } catch (error: any) {
    logger().error("Error obtaining twilio token", error?.message);
    throw error; // Using BE error message response
  }
});

function shouldCancelCredentialRetrieval(meeting: MeetingState): boolean {
  const { mode, isCallback, callDetails } = meeting;

  if (mode === CallModes.MP) {
    return false;
  } else if (mode === CallModes.P2P) {
    return false;
  }

  // If the call details are null, cancel the retrieval.
  // This basically boils down to `callDetails` being null or not, `isCallback`
  // shouldn't even be part of this, but leaving it for parity just in case
  if (
    (!isCallback && callDetails == null) ||
    (isCallback && callDetails == null)
  ) {
    return true;
  }
}
