import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
/* Components */
import { Button } from '../../../../../../Common/Components';
import { SearchInput } from '../../../index';
import Can from '../../../../../../Common/Acl/AccessControl';
/* Styles */
import './TableHeader.scss';
import { roles } from '../../../../../../Common/Constants/userTypes';
import VariablePaginationComponent from '../../../VariablePaginationComponent/VariablePaginationComponent';

class TableHeader extends PureComponent {
	renderAddNewButton = () => {
		const addNewComponent = (
			<Button
        disabled={this.props.disableAddButton}
				onClick={this.props.onAddNewHandler}
				isFull
				buttonSmall
			>
				{this.props.onAddNewTitle}
			</Button>
		);
		if (this.props.permissions) {
			return (
				<Can {...this.props.permissions}>
					{addNewComponent}
				</Can>
			);
		}
		return addNewComponent;
	};

	render() {
		const {
			columns,
			nameOverride,
			onUpdateSearchTerm,
			userTypes,
			currentUserTypes,
			consoleData,
			currentUser,
			search,
			headerTitle,
		} = this.props;

		return (
			<div
				className={classNames({
					'table-header': true,
					'table-header--padding-bottom display-flex justify-content--space-between': !search,
					'justify-content--flex-end': !search && !headerTitle,
				})}
			>
				{headerTitle && <div className="table-header__title">{headerTitle}</div>}
				<div
					className={classNames({
						'table-header__actions--padding-top justify-content--space-between align--center': search,
						'justify-content--flex-end': !search,
						'padding--none': !headerTitle,
					})}
				>
					{search && <SearchInput currentUserTypes={currentUserTypes} userTypes={userTypes} onUpdateSearchTerm={onUpdateSearchTerm} nameOverride={nameOverride} columns={columns} onChangeHandler={this.props.onSearchTermUpdateHandler} />}
					{(consoleData && currentUser && currentUser.userTypes[0] === roles.availAdmin || !consoleData) && this.props.onAddNewHandler && this.renderAddNewButton()}
				</div>
			</div>
		);
	}
}

TableHeader.defaultProps = {
	onAddNewTitle: 'Create new',
	headerTitle: '',
  disableAddButton: false
};

TableHeader.propTypes = {
	onAddNewTitle: PropTypes.string,
	headerTitle: PropTypes.string,
  disableAddButton: PropTypes.bool,

};

export default TableHeader;
