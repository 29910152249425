export const HOME = {
	SET_CURRENT_USER_INFO: 'HOME_SET_CURRENT_USER_INFO',
	SET_IS_BUTTON_CLICKABLE: 'HOME_SET_IS_BUTTON_CLICKABLE',
	GET_ACCOUNT_SETUP_STATUS: 'HOME_GET_ACCOUNT_SETUP_STATUS',
	TOGGLE_LOADER: 'HOME_TOGGLE_LOADER',
	GET_ACCOUNT_EXPIRE_STATUS: 'GET_ACCOUNT_EXPIRE_STATUS',
	START_IDLE_TIMER: 'HOME_START_IDLE_TIMER',
	SET_IDLE_ALERT: 'HOME_SET_IDLE_ALERT',
	SET_NOTIFICATIONS: 'HOME_SET_NOTIFICATIONS',
	ADD_NOTIFICATION: 'HOME_ADD_NOTIFICATION',
	REMOVE_NOTIFICATION: 'HOME_REMOVE_NOTIFICATION',
  SET_LOCAL_NETWORK_DISRUPTED: 'SET_LOCAL_NETWORK_DISRUPTED',
	MFA_LOGIN_SELECT_CHANNEL: "HOME_MFA_LOGIN_SELECT_CHANNEL",
	MFA_LOGIN_SETUP_PHONE: "HOME_MFA_LOGIN_SETUP_PHONE",
	SET_USER_FOR_REDIRECT: "HOME_SET_USER_FOR_REDIRECT"
};
