import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState, AppDispatch } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleSetConsoleAudioOutputDeviceThunk } from "src/domains/Beacon/store/stream/thunks/handleSetConsoleAudioOutputDeviceThunk";
import { handleSetHostAudioOutputDeviceThunk } from "src/domains/Beacon/store/stream/thunks/handleSetHostAudioOutputDeviceThunk";
import { handleSetParticipantsAudioOutputThunk } from "src/domains/Beacon/store/stream/thunks/handleSetParticipantsAudioOutputThunk";
import { CallSteps } from "src/domains/Beacon/store/ui/types";
import { restartLocalTrack } from "src/domains/Beacon/utils/mediaDevices";
import { logger, LoggerLevels } from "src/logging/logger";
import { updateLocalMediaDevicesInfo } from "src/services/ApiClient/stream";

// Automatically generates pending, fulfilled and rejected action types
// (see `createAsyncThunk` docs: https://redux-toolkit.js.org/api/createAsyncThunk)
export const updateLocalMediaThunk = createAsyncThunk<
  // Return type of the payload creator, we return nothing so void
  void,
  // First argument to the payload creator, no args so void
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("stream/updateLocalMedia", async (_args, { getState, dispatch }) => {
  try {
    logger().info("Updating local media devices...");
    const {
      stream: { localMedia, videoTrack, audioTrack },
      twilio: { room },
      ui: {
        callState: { callStep },
      },
    } = getState();

    // Logs in order to know the tracks shape in case of an error
    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: "updatedLocalMediaDevices.ts",
        feature: "updateLocalMedia",
      },
      `Change in local media devices detected: ${JSON.stringify(
        localMedia ?? "null"
      )}`
    );

    const updatedLocalMediaDevices = await updateLocalMediaDevicesInfo(
      localMedia
    );

    // Only if room exists we'll have access to the local video and audio tracks
    //  in order to re-sync them using Twilio.
    // Audio output will be synced up again inside the `Audio.tsx` component
    // which will check the browser's `setSink` function to use it
    if (room) {
      if (
        localMedia.camera?.deviceId !==
        updatedLocalMediaDevices.camera?.deviceId
      ) {
        // Must update the video input device only if different from previous
        const { camera } = updatedLocalMediaDevices;
        logger().info(`videoinput changed to ${camera.label}`);
        restartLocalTrack(camera, videoTrack);
      } else {
        logger().logWithFields(
          LoggerLevels.info,
          {
            fileInfo: `updateLocalMediaThunk.ts`,
            feature: `Media device selection`,
          },
          `Skipping videoinput change. ${
            localMedia.camera?.deviceId ===
            updatedLocalMediaDevices.camera?.deviceId
              ? "Camera deviceId did not change"
              : `No camera detected: {localMedia: ${JSON.stringify(
                  localMedia.camera
                )}, updatedLocalMediaDevices: ${JSON.stringify(
                  updatedLocalMediaDevices.camera
                )}}`
          }`
        );
      }

      // Must check groupId cause' when "Same as System" is used, the deviceId is always set to "default"
      // thus, a device change will not be detected, instead the groupId is helpful in this scenario
      if (
        localMedia.microphone?.deviceId !==
          updatedLocalMediaDevices.microphone?.deviceId ||
        localMedia.microphone?.groupId !==
          updatedLocalMediaDevices.microphone?.groupId
      ) {
        // Must update the microphone input device if deviceId or groupId has changed
        const { microphone } = updatedLocalMediaDevices;
        logger().info(`audioinput changed to ${microphone.label}`);

        restartLocalTrack(microphone, audioTrack);
      } else {
        logger().logWithFields(
          LoggerLevels.info,
          {
            fileInfo: `updateLocalMediaThunk.ts`,
            feature: `Media device selection`,
          },
          `Skipping audioinput change. ${
            localMedia.microphone?.deviceId ===
              updatedLocalMediaDevices.microphone?.deviceId ||
            localMedia.microphone?.groupId ===
              updatedLocalMediaDevices.microphone?.groupId
              ? "Microphone deviceId & groupId did not change"
              : `No microphone detected: {localMedia: ${JSON.stringify(
                  localMedia.microphone
                )}, updatedLocalMediaDevices: ${JSON.stringify(
                  updatedLocalMediaDevices.microphone
                )}}`
          }`
        );
        logger().info("Skipping audioinput change. No mic detected ?");
      }
    }

    // Must update the Speaker, in case it changed, only during the in-call, otherwise,
    // it could cause issues with the pre-call flow while joining
    if (
      callStep === CallSteps.IN_CALL &&
      localMedia.speaker.deviceId !== updatedLocalMediaDevices.speaker.deviceId
    ) {
      logger().info(
        "New audio-output device detected while in-call, user will sync-up Speaker for Console, Participants tracks and Host"
      );
      // Must set the audio-output device for Console when user joins the call
      // Must need to use the updated list that's coming from System
      await dispatch(
        handleSetConsoleAudioOutputDeviceThunk({
          customSpeaker: updatedLocalMediaDevices.speaker,
        })
      );

      // Re-syncing each participants track to the new audio output device
      // Must need to use the updated list that's coming from System
      await dispatch(
        handleSetParticipantsAudioOutputThunk({
          customSpeaker: updatedLocalMediaDevices.speaker,
        })
      );

      // Re-syncing Host's track to the new audio output device
      // Must need to use the updated list that's coming from System
      await dispatch(
        handleSetHostAudioOutputDeviceThunk({
          customSpeaker: updatedLocalMediaDevices.speaker,
        })
      );
    }

    dispatch(streamActions.setLocalMedia(updatedLocalMediaDevices));
  } catch (error: any) {
    logger().error("Error when updating local media devices", error?.message);
    // Since this thunk is created using `createAsyncThunk`, throwing an
    // error with this message sends this message specifically down to
    // the store for it to be processed
    throw error;
  }
});
