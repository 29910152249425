import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { selectBusinessUnitAction, selectDistrictAction } from '../../../../../../VendorsActions';
import { getcurrentUserTypes } from '../../../../../../../../../HomeSelectors';
import Can from '../../../../../../../../../../../Common/Acl/AccessControl';
import DropdownComponent from '../../../../../../../../../Common/DropdownComponent/DropdownComponent';

class BusinessUnitsListRow extends PureComponent {
	redirect = () => {
		const { vendorType, id } = this.props.rowData;

		if (vendorType === 'BUSINESS_UNIT') {
			this.props.selectBusinessUnit(id);
		} else {
			this.props.selectDistrict(id);
		}
	};

	openDeleteUserModal = () => this.props.openModal(this.props.rowData);

	render() {
		const { name, vendorType } = this.props.rowData;
		return (
			<Table.Row>
				<Table.Cell onClick={this.redirect} title={name}>
					<div className="table__name-wrapper display--flex">
						<span>{name}</span>
					</div>
				</Table.Cell>
				{ vendorType === 'DISTRICT' && (
					<Can userTypes={this.props.currentUserTypes} action="read" context="deleteDomain">
						<Table.Cell className="table__action-table-cell">
							<div className="justify-content--flex-end">
								<DropdownComponent
									dropdownOptions={[{
										className: 'dropdown__menu-item--red',
										label: 'Delete',
										text: 'Delete',
										action: this.openDeleteUserModal,
										icon: 'icon-trash-2-outline',
									}]}
								/>
							</div>
						</Table.Cell>
					</Can>
				) }
			</Table.Row>
		);
	}
}

const mapStateToProps = state => ({
	currentUserTypes: getcurrentUserTypes(state),
});

const mapDispatchToProps = dispatch => ({
	selectBusinessUnit: businessUnitId => dispatch(selectBusinessUnitAction(businessUnitId)),
	selectDistrict: districtId => dispatch(selectDistrictAction(districtId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessUnitsListRow));
