import { ReactNode } from "react";

import clsx from "clsx";

import { Check } from "@mui/icons-material";
import {
  List as MUIList,
  ListItem as MUIListItem,
  ListProps,
  ListItemButton as MUIListItemButton,
} from "@mui/material";

import { Font } from "src/components/Font";

import styles from "./styles.scss";

interface IListItem {
  key?: string;
  leftContent?: ReactNode;
  title: string;
  subtitle?: string;
  rightContent?: ReactNode;
  id?: any;
  disabled?: boolean;
}

interface IProps extends ListProps {
  items: IListItem[];
  clickable?: boolean;
  theme?: "light" | "dark";
  selectedId?: any;
  onClick?: (e: any) => void;
  "data-test-id"?: string;
  removeDivider?: boolean;
  className?: string;
  listItemClassName?: string;
}

interface IListItemWrapper {
  children: ReactNode;
  item: IListItem;
  index: number;
}

export const List = ({
  items,
  clickable,
  theme = "light",
  selectedId,
  onClick,
  "data-test-id": dataTestId = "list",
  removeDivider = false,
  className,
  listItemClassName,
  ...props
}: IProps) => {
  // This wrapper toggles between a clickable item or simple item in the list
  const ListItemWrapper = ({ children, item, index }: IListItemWrapper) => {
    return (
      <MUIListItem
        disabled={item.disabled}
        key={item.key ?? `list-item-${index}`}
        className={clsx(styles.listItem, {
          [styles.noDivider]: removeDivider,
        })}
        classes={{ root: listItemClassName }}
        data-test-id={`list-item`}
      >
        {clickable ? (
          <MUIListItemButton
            className={clsx(styles.listItemButton)}
            onClick={() => {
              if (clickable && !item.disabled) {
                onClick(item.id);
              }
            }}
          >
            {children}
          </MUIListItemButton>
        ) : (
          <>{children}</>
        )}
      </MUIListItem>
    );
  };
  return (
    <MUIList
      {...props}
      className={clsx(styles.list, { [styles[theme]]: true })}
      data-test-id={dataTestId}
      classes={{ root: className }}
    >
      {items.map((item, i) => (
        <ListItemWrapper key={`list-item-wrapper-${i}`} item={item} index={i}>
          {item.leftContent && (
            <div
              className={styles.leftContent}
              data-test-id={`listItem-left-content`}
            >
              {item.leftContent}
            </div>
          )}
          <div
            className={styles.content}
            data-test-id={`listItem-center-content`}
          >
            {/* TODO depending on the final designs, we might want subtitle above the title or below, we can always add a 2nd subtitle below later */}
            {item.subtitle && (
              <Font variant="b3" color="disabled">
                {item.subtitle}
              </Font>
            )}
            <Font
              variant="h3"
              className={styles.title}
              color={item.disabled ? "disabled" : "light"}
            >
              {item.title}
            </Font>
          </div>
          <div
            className={styles.rightContent}
            data-test-id={`listItem-right-content`}
          >
            {clickable && item.id === selectedId ? (
              <Check
                className={styles.checkIcon}
                data-test-id={`listItem-check-icon`}
              />
            ) : null}
            {item.rightContent}
          </div>
        </ListItemWrapper>
      ))}
    </MUIList>
  );
};
