import React from "react";
import "./UserBusyDialog.scss";

type Props = {
  userMessage: string;
};

const UserBusyDialog: React.FC<Props> = ({ userMessage }) => {
  return (
    <div className="avail-portal-call-overlay">
      <div className="avail-portal-call-overlay-dialog">
        <div className="in-meeting-user-busy-error-title">Busy</div>
        <div className="in-meeting-user-busy-error-message">{userMessage}</div>
        <div className="avail-portal-call-overlay-busy-dialog-button-holder">
          <div className="avail-portal-call-overlay-dialog-button-text">
            <button
              className="confirm-ok-leave-button"
              onClick={(e: any) => {
                window.location.href = "/portalcall";
              }}
            >
              <div className="avail-portal-call-overlay-dialog-button-text">
                <span>OK</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserBusyDialog;
