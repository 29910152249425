import { LocalVideoTrack } from "twilio-video";

import {
  DEFAULT_TWOVIEW_LABEL,
  ORTelligence,
} from "src/domains/Beacon/constants";
import { RootState } from "src/domains/Beacon/store";
import { AppDispatch } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { resetLayoutsSnapshotThunk } from "src/domains/Beacon/store/stream/thunks/resetLayoutsSnapshotThunk";
import { stopShareScreenTrackThunk } from "src/domains/Beacon/store/stream/thunks/stopShareScreenTrack";
import { unpublishVideoTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishVideoTrackThunk";
import { uiActions } from "src/domains/Beacon/store/ui";
import { logger } from "src/logging/logger";
import { getPartnerIframe } from "src/services/ApiClient/integrations";
import { MultiPartyEvent } from "src/services/ApiClient/scheduler";

interface IParams {
  enableORTelligence: boolean;
  externalInputsImageResizing: boolean;
  integrationName: string;
}

export const publishShareScreenTrackThunk = ({
  enableORTelligence,
  externalInputsImageResizing,
  integrationName,
}: IParams) => async (dispatch: AppDispatch, getState: () => RootState) => {
  logger().info("User is publishing screen share tracks to Twilio");

  const {
    ui: {
      sidePanel: {
        layouts: { frameSelected },
      },
    },
    twilio: {
      room: { localParticipant },
    },
    meeting: { callDetails },
  } = getState();

  const {
    hospitalName,
    eventId,
    therapyName,
    procedureName,
  } = callDetails as MultiPartyEvent;

  try {
    // Must stop Host's Pip video track to start sharing screen
    await dispatch(unpublishVideoTrackThunk());

    // Had to use any because getDisplayMedia wasn't recognized as valid construct.
    const navigatorMediaDevices = navigator.mediaDevices as any;

    const displayMediaOptions = {
      preferCurrentTab: true,
      selfBrowserSurface: "include",
      surfaceSwitching: "exclude",
      systemAudio: "include",
      cursor: "always",
      video: {
        displaySurface: "browser",
      },
      audio: false,
    };

    // Must show the screen share modal before getting the captureStream
    dispatch(meetingActions.setIsRequestingScreenSharePermission(true));

    const captureStream = await navigatorMediaDevices.getDisplayMedia(
      displayMediaOptions
    );

    const mediaStreamTrack = captureStream.getVideoTracks()[0];

    // Integrate this code.
    const displayTrack = new LocalVideoTrack(mediaStreamTrack, {
      logLevel: "info",
      name: `avail-integration-${ORTelligence}-${DEFAULT_TWOVIEW_LABEL[frameSelected]}`,
    });

    // In case the user stops sharing screen
    displayTrack.on("stopped", () => {
      // Snapshot has the latest layouts saved, even if opposite frame has change camera,
      // must use an outside action thunk since the state inside this scope will be outdated
      dispatch(resetLayoutsSnapshotThunk({ externalInputsImageResizing }));
      dispatch(stopShareScreenTrackThunk({ enableORTelligence }));
    });

    await localParticipant.publishTrack(displayTrack);
    // Should store the track in order to restore it after a Twilio token refresh
    dispatch(streamActions.setScreenShareTrack(displayTrack));

    // Must not show the screen share permission modal
    dispatch(meetingActions.setIsRequestingScreenSharePermission(false));
    dispatch(streamActions.setIntegrationActive(true));

    const ortPageLinkResponse = await getPartnerIframe(
      procedureName,
      therapyName,
      eventId,
      hospitalName
    );

    dispatch(
      streamActions.setLayoutFrameInfo({
        frameSelected,
        cameraId: integrationName,
        cameraLabel: integrationName,
        isFullScreen: false,
        integrationName,
        integrationLink: ortPageLinkResponse.content?.src,
      })
    );

    // close side panel after sharing successfully goes through
    dispatch(uiActions.setSidePanelOpen(false));
  } catch (error: any) {
    // Must not show the screen share permission modal
    dispatch(meetingActions.setIsRequestingScreenSharePermission(false));
    dispatch(stopShareScreenTrackThunk({ enableORTelligence }));
    // Snapshot has the latest layouts saved, even if opposite frame has change camera,
    // must use an outside action thunk since the state inside this scope will be outdated
    dispatch(resetLayoutsSnapshotThunk({ externalInputsImageResizing }));
    logger().error("Error publishing screen share tracks", error?.message);
    throw new Error("Error publishing new screen share tracks.");
  }
};
