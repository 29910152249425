export const USERS = {
	GET_USER_TYPES: "USERS_GET_USER_TYPES",
	SET_USER_TYPES: "USERS_SET_USER_TYPES",
	SELECT_USER_TYPE: "USERS_SELECT_USER_TYPE",
	GET_USERS: "USERS_GET_USERS",
	SET_USERS: "USERS_SET_USERS",
	CREATE_USER: "USERS_CREATE_USER",
	DELETE_USER: "USERS_DELETE_USER",
	SELECT_USER: "USERS_SELECT_USER",
	SET_USERS_PAGINATION: "USERS_SET_USERS_PAGINATION",
	SET_USER: "USERS_SET_USER",
	REGISTER_USER: "USERS_REGISTER_USER",
	GET_USER_BY_TOKEN: "USERS_GET_USER_BY_TOKEN",
	GET_USER_BY_ID: "USERS_GET_USER_BY_ID",
	UPDATE_USER_PROFILE: "USERS_UPDATE_USER_PROFILE",
	GET_USER_ORGANISATION: "USERS_GET_USER_ORGANISATIONS",
	SET_USER_ORGANISATION: "USERS_SET_USER_ORGANISATION",
	GET_USER_SUPPORTS_ORGANISATION: "USERS_GET_USER_SUPPORTS_ORGANISATION",
	SET_USER_SUPPORTS_ORGANISATION: "USERS_SET_USER_SUPPORTS_ORGANISATION",
	GET_USER_PROCEDURES: "USERS_GET_USER_PROCEDURES",
	SET_USER_PROCEDURES: "USERS_SET_USER_PROCEDURES",
	UPDATE_USER_PROCEDURES: "USERS_UPDATE_USER_PROCEDURES",
	GET_USER_EMPLOYER: "USERS_GET_USER_EMPLOYER",
	GET_USER_EMPLOYER_BY_DOMAIN: "USERS_GET_USER_EMPLOYER_BY_DOMAIN",
	ASSIGN_USER_PROCEDURES: "USERS_ASSIGN_USER_PROCEDURES",
	FINISH_ACCOUNT_SETUP: "USERS_FINISH_ACCOUNT_SETUP",
	GET_USERS_SETTINGS: "USERS_GET_USERS_SETTINGS",
	SET_USERS_SETTINGS: "USERS_SET_USERS_SETTINGS",
	SET_USERS_EMAIL_SETTINGS: "USERS_SET_USERS_EMAIL_SETTINGS",
	SET_USER_PROFILE_INITIAL_VALUES: "USERS_SET_USER_PROFILE_INITIAL_VALUES",
	GET_ALL_HOSPITALS_USER_APPLIED: "USERS_GET_ALL_HOSPITALS_USER_APPLIED",
	SET_ALL_HOSPITALS_USER_APPLIED: "USERS_SET_ALL_HOSPITALS_USER_APPLIED",
	GET_ALL_AVAILABLE_HOSPITALS_TO_APPLY:
		"USERS_GET_ALL_AVAILABLE_HOSPITALS_TO_APPLY",
	SET_ALL_AVAILABLE_HOSPITALS_TO_APPLY:
		"USERS_SET_ALL_AVAILABLE_HOSPITALS_TO_APPLY",
	GET_ALL_APPROVED_HOSPITALS_FOR_USER:
		"USERS_GET_ALL_APPROVED_HOSPITALS_FOR_USER",
	SET_ALL_APPROVED_HOSPITALS_FOR_USER:
		"USERS_SET_ALL_APPROVED_HOSPITALS_FOR_USER",
	APPLY_TO_HOSPITAL: "USERS_APPLY_TO_HOSPITALS",
	APPLY_TO_HOSPITAL_FOR_TRIAL: "USERS_APPLY_TO_HOSPITALS_FOR_TRIAL",
	GET_USER_MANAGER: "USERS_GET_USER_MANAGER",
	SET_USER_MANAGER: "USERS_SET_USER_MANAGER",
	UPDATE_USER_MANAGER: "UPDATE_USER_MANAGER",
	REMOVE_USER_MANAGER: "REMOVE_USER_MANAGER",
	GET_ALL_MANAGERS_USER_APPLIED_TO: "USERS_GET_ALL_MANAGERS_USER_APPLIED_TO",
	SET_ALL_MANAGERS_USER_APPLIED_TO: "USERS_SET_ALL_MANAGERS_USER_APPLIED_TO",
	GET_ALL_DESCENDANT_USERS_FOR_MANAGER:
		"USERS_GET_ALL_DESCENDANT_USERS_FOR_MANAGER",
	SET_ALL_DESCENDANT_USERS_FOR_MANAGER:
		"USERS_SET_ALL_DESCENDANT_USERS_FOR_MANAGER",
	REMOVE_HOSPITAL_APPLICATION: "USERS_REMOVE_HOSPITAL_APPLICATION",
	UPDATE_USER_SETTINGS: "USERS_UPDATE_USER_SETTINGS",
	UPDATE_USER_PASSWORD: "USERS_UPDATE_USER_PASSWORD",
	RESEND_INVITATION: "RESEND_INVITATION",
	LOCK_USER: "LOCK_USER",
	UNLOCK_USER: "UNLOCK_USER",
	GET_PASSPHRASES_DATA: "GET_PASSPHRASES_DATA",
	SET_PASSPHRASE: "SET_PASSPHRASE",
	SET_PASSPHRASE_DATA: "SET_PASSPHRASE_DATA",
	SET_PHONE_NUMBER_DATA: "SET_PHONE_NUMBER_DATA",
	IS_VALID_PHONE_ADDED: "IS_VALID_PHONE_ADDED",
	ADD_PHONE_NUMBER: "USERS_ADD_PHONE_NUMBER",
	DELETE_PHONE_NUMBER: "USERS_DELETE_PHONE_NUMBER",
	VERIFY_PHONE_NUMBER_OTP: "USERS_VERIFY_PHONE_NUMBER_OTP",
	VERIFY_RESEND_PHONE_NUMBER_OTP: "USERS_VERIFY_RESEND_PHONE_NUMBER_OTP",
	SET_PHONE_OTP_INFO: "USERS_SET_PHONE_OTP_INFO",
	PHONE_VERIFICATION_SUCCESS: "USERS_PHONE_VERIFICATION_SUCCESS",
	PHONE_NUMBER: "USERS_PHONE_NUMBER",
	GET_USER_MFA_SETTINGS: "USERS_GET_USER_MFA_SETTINGS",
	SET_ORG_TWO_FACTOR_AUTH: "USERS_SET_ORG_TWO_FACTOR_AUTH",
	SET_AUTH_ERROR: "USERS_SET_AUTH_ERROR",
};
