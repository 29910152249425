import clsx from "clsx";

import { useFreezeFrameImageResize } from "src/domains/Beacon/components/FreezeFrameCanvas/hooks/useFreezeFrameImageResize";
import {
  FREEZE_FRAME_CANVAS_ID,
  FREEZE_FRAME_IMAGE_ID,
} from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectIsUserHost } from "src/domains/Beacon/store/meeting/selectors";
import { selectFreezeFrameState } from "src/domains/Beacon/store/stream/selectors";

import styles from "./styles.scss";

export const FreezeFrameCanvas = () => {
  const isHostUser = useAppSelector(selectIsUserHost);
  const { imageData } = useAppSelector(selectFreezeFrameState);

  // Detects when window resize or SidePanel's transition to re-calculate the video
  // dimensions so to adjust the Freeze Frame image
  useFreezeFrameImageResize(imageData);

  // If host, image will be shown based on Presence response, in the other hand if
  // is participant it should always be img tag not canvas
  const FrozenImage = (
    <div className={clsx(styles.depth, styles.container)}>
      <img
        id={FREEZE_FRAME_IMAGE_ID}
        alt="freeze-frame-img"
        // Must hide the image tag by default so it doesn't appear a broken image,
        // it will be shown when doing Freeze Frame, the handleUnfreezeFrame thunk
        // will again set it to "display: none"
        style={{ display: "none" }}
      />
    </div>
  );

  return (
    <>
      {isHostUser ? (
        <>
          {/* 
            Canvas will show the video snapshot the first time but after a resize 
            or SidePanel interaction, it will be cleared, using the image tag instead
           */}
          <canvas
            id={FREEZE_FRAME_CANVAS_ID}
            className={clsx(styles.depth, styles.canvas)}
          />
          {FrozenImage}
        </>
      ) : (
        FrozenImage
      )}
    </>
  );
};
