import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Button, Fields, validators } from '../../../../../../../../../../../Common/Components';

class CreateNewBusinessUnit extends PureComponent {
	render() {
		const { t } = this.props;
		return (
			<React.Fragment>
				<form action="" onSubmit={this.props.handleSubmit}>
					<Fields.InputField
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.placeholder')}
						name="name"
						validate={[validators.required, validators.minimumLength3, validators.maximumLength48]}
					/>
					<Fields.SelectField
						labelText={t('common.form.state.label')}
						placeholder={t('common.form.state.placeholder')}
						name="state"
						validate={[validators.required, validators.minimumLength2, validators.maximumLength64]}
						search
						options={this.props.states && this.props.states.map(state => ({
							key: state.id,
							value: state.name,
							text: `${state.name} (${state.abbreviation})`,
						}))}
					/>
					<Fields.InputField
						labelText={t('common.form.city.label')}
						placeholder={t('common.form.city.placeholder')}
						name="city"
						validate={[validators.required, validators.minimumLength2, validators.maximumLength64]}
					/>
					<Fields.InputField
						labelText={t('common.form.address.label')}
						placeholder={t('common.form.address.placeholder')}
						name="address"
						validate={[validators.required, validators.minimumLength3, validators.maximumLength128]}
					/>
					<div className="users__action-buttons">
						<Button
							onClick={this.props.toggleCreateNewBusinessUnitModal}
							isGhost
							buttonSmall
						>
							Cancel
						</Button>
						<Button
							type="submit"
							isFull
							buttonSmall
						>
							Create
						</Button>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

CreateNewBusinessUnit.propTypes = {
	toggleCreateNewBusinessUnitModal: PropTypes.func.isRequired,
};

const CreateNewBusinessUnitWithTranslation = withTranslation()(CreateNewBusinessUnit);

export default reduxForm({
	form: 'createNewBusinessUnitForm',
})(CreateNewBusinessUnitWithTranslation);
