import clsx from "clsx";

import { Font } from "src/components/Font";

import styles from "./styles.scss";

export interface IndicatorTagProps {
  color: "orange" | "red" | "green" | "blue";
  "data-test-id"?: string;
  label: string;
}

export const IndicatorTag = ({
  color,
  "data-test-id": dataTestId,
  label,
}: IndicatorTagProps) => {
  return (
    <div
      className={clsx(styles.root, styles.tag, styles[`color--${color}`])}
      data-test-id={dataTestId || null}
      role="tag"
    >
      <Font variant="h3" color="light">
        {label}
      </Font>
    </div>
  );
};
