import { createSlice } from "@reduxjs/toolkit";

import { AppState } from "avail-web-ui/dux/Store";

export interface NotifyState {
  isOpen: boolean;
  message: string;
  type?: INotificationSeverityType;
}

export type INotificationSeverityType =
  | "permission"
  | "error"
  | "warning"
  | "info"
  | "success";

const initialState: NotifyState = {
  isOpen: false,
  type: "success",
  message: "",
};

// TODO currently notificaition slice is just 1 object, in the future we'll probably want a list of notifications
// where we only show the latest one
const NotificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action) => ({ ...action.payload, isOpen: true }),
    closeNotification: (state) => {
      state.isOpen = false;
    },
  },
});

export const {
  addNotification: addNotificationAction,
  closeNotification: closeNotificationAction,
} = NotificationSlice.actions;

export const NotificationReducers = NotificationSlice.reducer;
export const notificationsSelector = (state: AppState) => state.notifications;
