import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Can from '../../../../../../../../../Common/Acl/AccessControl';
import { Button, Fields, Icon, validators } from '../../../../../../../../../Common/Components';
import AccessControlService from '../../../../../../../../../Common/Services/accessControlService';
import { getcurrentUserTypes } from '../../../../../../../HomeSelectors';
import { getAllStatesAction } from '../../../../../OrganisationActions';
import { updateBusinessUnitAction } from '../../../../VendorsActions';
import { getInitialValuesForBusinessUnitGeneralInfo } from '../../../../VendorsSelectors';
import GeneralInfoBrand from './GeneralInfoBrand';

class GeneralInfo extends PureComponent {
	componentDidMount() {
		this.props.getAllStates();
	}

	onUpdateGeneralInfoHandler = (data) => {
		this.props.updateBusinessUnit(this.props.data.id, {
			'name': data.name,
			'address': data.address,
			'state': data.state,
			'city': data.city,
		}, this.props.profileType);
	};

	render() {
		const { profileType, data, t } = this.props;

		return (
			<div className="profile-general-info">
				{profileType === 'Company' && <GeneralInfoBrand brand={data} initialValues={data} />}
				{profileType !== 'Company' &&
					<form className="general-info__form" onSubmit={this.props.handleSubmit(this.onUpdateGeneralInfoHandler)}>
						<div>
							<Fields.InputField
								className="avail__input"
								labelText={t('common.form.name.label')}
								placeholder={t('common.form.name.placeholder')}
								name="name"
								validate={validators.required}
								disabled={!AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'edit', 'vendorGeneralInfo')}
							/>
							<Fields.InputField
								className="avail__input"
								labelText={t('common.form.address.label')}
								placeholder={t('common.form.address.placeholder')}
								name="address"
								validate={validators.required}
								disabled={!AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'edit', 'vendorGeneralInfo')}
							/>
							<Fields.SelectField
								className="avail__input"
								labelText={t('common.form.state.label')}
								placeholder={t('common.form.state.placeholder')}
								name="state"
								validate={validators.required}
								search
								options={this.props.states && this.props.states.map(state => ({
									key: state.id,
									value: state.name,
									text: `${state.name} (${state.abbreviation})`,
								}))}
								disabled={!AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'edit', 'vendorGeneralInfo')}
							/>
							<Fields.InputField
								className="avail__input"
								labelText={t('common.form.city.label')}
								placeholder={t('common.form.city.placeholder')}
								name="city"
								validate={validators.required}
								disabled={!AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'edit', 'vendorGeneralInfo')}
							/>
						</div>
						<Can userTypes={this.props.currentUserTypes} action="edit" context="vendorGeneralInfo">
							<Button className="button-with-icon align-self--flex-start padding--none" type="submit">
								<Icon className="icon-save icon--orange entity-profile__submit-icon" />
								<span className="entity-profile__submit-title">Save Changes</span>
							</Button>
						</Can>
					</form>
				}
			</div>
		);
	}
}

GeneralInfo.defaultProps = {
	states: [],
};

const mapStateToProps = state => ({
	initialValues: getInitialValuesForBusinessUnitGeneralInfo(state),
	currentUserTypes: getcurrentUserTypes(state),
	states: state.organisationsReducer.states,
});

const mapDispatchToProps = dispatch => ({
	updateBusinessUnit: (businessUnitId, data, entityType) => dispatch(updateBusinessUnitAction(businessUnitId, data, entityType)),
	getAllStates: () => dispatch(getAllStatesAction()),
});

const GeneralInfoWithTranslation = withTranslation()(GeneralInfo);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'consoleProfileForm',
	enableReinitialize: true,
})(GeneralInfoWithTranslation));
