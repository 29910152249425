// Redux Toolkit's `createAsyncThunk` API generates thunks that automatically
// dispatch those "start/success/failure" actions for you.
import TwilioVideo, { ConnectOptions } from "twilio-video";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { initiateCallSessionHeartbeatThunk } from "src/domains/Beacon/store/meeting/thunks";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import {
  NETWORK_QUALITY_CONFIG_LOCAL_VERBOSITY,
  NETWORK_QUALITY_CONFIG_REMOTE_VERBOSITY,
} from "src/domains/Beacon/store/twilio/constants";
import { setParticipantsTracksThunk } from "src/domains/Beacon/store/twilio/thunks/setParticipantsTracksThunk";
import { twilioActions } from "src/domains/Beacon/store/twilio/twilioSlice";
import { LoggerLevels, logger } from "src/logging/logger";
import UserSessionService from "src/services/UserSessionService";

interface IParams {
  offerDeviceSelection?: boolean;
}

// This is an example of a normal thunk, in contrary to thunks using `createAsyncThunk`. These can be mixed depending on what is wanted.
// `createAsyncThunk` gives us built in actions being fired for pending(loading)/fulfilled/rejected states (this is ideal when calling external APIs).
// Normal thunks just doing internal stuff (in this case we're initializing the twilio-video lib)
export function connectTwilioThunk({ offerDeviceSelection }: IParams) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    logger().info(`Connecting to twilio (version: ${TwilioVideo.version}`);

    const { meeting, twilio, stream } = getState();
    const { mode } = meeting;
    const { token } = twilio;
    const { screenShareTrack } = stream;
    const user = UserSessionService.getCachedUserInfo();

    if (screenShareTrack) {
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Media Devices",
          fileInfo: "connectTwilio.ts",
        },
        `User has screen sharing tracks, must publish them to Twilio: ${JSON.stringify(
          screenShareTrack ?? "null"
        )}`
      );
    }

    // ConnectOptions docs: https://sdk.twilio.com/js/video/releases/2.3.0/docs/global.html#ConnectOptions
    const options: ConnectOptions = {
      name: user.loginId,
      audio: false, // will not sent audio on init
      video: false, // will not sent video on init
      // Must persist screen sharing track if user uses TPI and Twilio refresh happens
      // when offerDeviceSelection is "true", otherwise, `publishLocalMediaTracksThunk` will take care of persisting it
      tracks:
        screenShareTrack && offerDeviceSelection ? [screenShareTrack] : [],
      // Whether Twilio Insights is enabled or not. Twilio Insights provide call
      // quality analytics and aggregation tools for investigating Twilio calls
      insights: true,
      dominantSpeaker: true,
      // DSCP marks the audio network packets for the purposes of improved packet routing.
      // When enabled, audio packet delivery is prioritized for improved audio quality
      enableDscp: true,
      networkQuality: {
        local: NETWORK_QUALITY_CONFIG_LOCAL_VERBOSITY,
        remote: NETWORK_QUALITY_CONFIG_REMOTE_VERBOSITY,
      },
      preferredVideoCodecs: "auto",
    };

    // Multiparty Settings to mitigate bandwidth congestion from
    // affecting other members of the twilio room
    if (mode === CallModes.MP) {
      options.bandwidthProfile = {
        video: {
          mode: "collaboration",
          clientTrackSwitchOffControl: "manual",
          contentPreferencesMode: "auto",
          dominantSpeakerPriority: "low", // no need to change stream priority of speaker
          trackSwitchOffMode: "predicted",
        },
      };
    }

    // This is where we initiate Twilio Video
    try {
      const twilioRoom = await TwilioVideo.connect(token, options);
      logger().info(`Successfully connected to room: '${user.id}'.`);
      dispatch(twilioActions.setRoom(twilioRoom));

      // once we have successfully connected to the twilio room then initiate the heartbeat interval calls
      // used to check if the user is still on the call
      if (twilioRoom) {
        dispatch(initiateCallSessionHeartbeatThunk());
      }

      // For Portalcall, our video outputs are low priority, should be at the smallest
      // rendering dimensions, and subject to bandwidth-related manipulation.
      const localTrackPublications = [
        ...twilioRoom.localParticipant.videoTracks.values(),
      ];
      localTrackPublications.forEach((localTrack) => {
        localTrack.setPriority("low");
      });

      // TODO: refreshInProgress logic (see `portalcall/src/commoncall/store/twilio/sagas/connectTwilio.ts`)

      // TODO: update local/remote tracks + participants from twilio
      const participants = [...twilioRoom.participants.values()];
      if (participants.length > 0) {
        logger().info(`Updating Participants list...`);
        // Setting console & host's audio/video state for local user's state
        // setParticipantsTracksThunk will change consoleHasJoined appropriately
        dispatch(setParticipantsTracksThunk({ participants }));
        dispatch(twilioActions.setParticipants(participants));
      }
    } catch (error: any) {
      logger().error("Error connecting to Twilio", error?.message);
      dispatch(
        twilioActions.setError({ message: "Error connecting to Twilio Video." })
      );
      throw error;
    }
  };
}
