// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container_IC7Cyb{flex-grow:1;padding:16px 32px 32px}.styles_container_IC7Cyb .styles_title-wrapper_Woo043{align-items:center;direction:\"row\";display:flex}.styles_container_IC7Cyb .styles_title_yt8Ddc{margin:16px 0}.styles_actions_EIJqAr{display:flex;gap:2rem}", "",{"version":3,"sources":["webpack://./src/domains/Events/EditPage/styles.scss"],"names":[],"mappings":"AAAA,yBACE,WAAA,CACA,sBAAA,CAEA,sDACE,kBAAA,CACA,eAAA,CACA,YAAA,CAGF,8CACE,aAAA,CAIJ,uBACE,YAAA,CACA,QAAA","sourcesContent":[".container {\n  flex-grow: 1;\n  padding: 16px 32px 32px;\n\n  .title-wrapper {\n    align-items: center;\n    direction: \"row\";\n    display: flex;\n  }\n\n  .title {\n    margin: 16px 0;\n  }\n}\n\n.actions {\n  display: flex;\n  gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container_IC7Cyb",
	"title-wrapper": "styles_title-wrapper_Woo043",
	"titleWrapper": "styles_title-wrapper_Woo043",
	"title": "styles_title_yt8Ddc",
	"actions": "styles_actions_EIJqAr"
};
export default ___CSS_LOADER_EXPORT___;
