import { renderTraceIdHeader } from "src/domains/Beacon/utils/renderTraceIdHeader";
import { ENDPOINTS } from "../constants";
import { PresenceServerConsoles } from "../store/models";
import { ApiResponse } from "./types";

/**
 * @param payload Defined structure for the metrics backend to process accordingly (NetworkQualityPayload)
 * Example use: const { data, error } = await API.POST.networkQualityPoll(payload)
 */
const getConsolesBySessionId = async (
  callSessionId: string,
  access_token: string
): Promise<ApiResponse<PresenceServerConsoles>> => {
  const url = `${ENDPOINTS.DOMAIN}/presence/callSessions/${callSessionId}/consoles`;

  try {
    const opts = {
      method: "GET",
      headers: {
        "content-type": "application/json;charset=UTF-8",
        ...renderTraceIdHeader(),
        authorization: "Bearer " + access_token
      }
    };

    const res = await fetch(url, opts);
    const response = await res.json();

    return { data: response.content };
  } catch (error) {
    return error;
  }
};

export default getConsolesBySessionId;
