import { NHighlightLocationEvent } from "availkit-js/dist/Models/Events/NHighlightLocationEvent";
import { TelestrationState, TelestrationStylusColor } from "../models";

export enum PointerActionKeys {
  POINTER_ADD_HIGHLIGHT = "POINTER_ADD_HIGHLIGHT",
  POINTER_REMOVE_HIGHLIGHT = "POINTER_REMOVE_HIGHLIGHT",
  UPDATE_TELESTRATION_STATE = "UPDATE_TELESTRATION_STATE"
}

interface PointerAddHighlight {
  type: PointerActionKeys.POINTER_ADD_HIGHLIGHT;
  highlight: NHighlightLocationEvent;
}

interface PointerRemoveHighlight {
  type: PointerActionKeys.POINTER_REMOVE_HIGHLIGHT;
  id: string;
}

interface PointerUpdateTelestrationOnOffStatus {
  type: PointerActionKeys.UPDATE_TELESTRATION_STATE;
  telestration: TelestrationState;
  stylusColor: TelestrationStylusColor;
}

export type PointerActionType =
  | PointerAddHighlight
  | PointerRemoveHighlight
  | PointerUpdateTelestrationOnOffStatus;
