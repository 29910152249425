import React from 'react';
import { Modal, Popup } from 'semantic-ui-react';
import { Icon } from '../../../../../../Common/Components';

import './EventInfoModal.scss';
import { dateTimeService } from '../../../../../../Common/Services';

export class EventInfoModal extends React.PureComponent {
	renderHospitalsInfo = availableHospitals => (
		availableHospitals.length === 1 ? `${availableHospitals[0].name}` :
			`${availableHospitals[0].name} + ${availableHospitals.length - 1} more`
	)

	renderDateDisplay = () => {
		const { isCallEvent, isRecurringEventChecked, selectedDate, recurringStartDate, recurringEndDate, workingDaysOfWeek } = this.props;

		if (isCallEvent) {
			return (
				<div className="event-info__text" >
					<span>{dateTimeService.toCalendarDate(selectedDate)}</span>
				</div>
			);
		} else if (isRecurringEventChecked) {
			return (
				<div
					className="event-info__text"
				>
					<span>{`${dateTimeService.toCalendarDate(recurringStartDate)} - ${dateTimeService.toCalendarDate(recurringEndDate)}`}</span>
					<span>Weekly every {workingDaysOfWeek}</span>
				</div>
			);
		}
		return (
			<div className="event-info__text" >
				<span>{dateTimeService.toCalendarDate(selectedDate)}</span>
			</div>
		);
	}


	render() {
		const {
			open,
			onCancel,
			style,
			elementRef,
			eventStartTimeHours,
			eventEndTimeHours,
			availableHospitals,
			callStatus,
			selectedEvent,
			isCallEvent,
		} = this.props;

		let modalHeader = 'Availability';
		if (this.props.meetingTime) {
			if (callStatus === 'ANSWERED') {
				modalHeader = 'Call Details';
      } else if (callStatus === 'JOINED') {
        modalHeader = 'Joined Call Details';
			} else {
				modalHeader = 'Missed Call Details';
			}
		} else if (this.props.callEvent) {
			modalHeader = 'Event';
		}

		// hospitalId: selectedEventObj.extendedProps.hospitalIds.length ? selectedEventObj.extendedProps.hospitalIds[0] : '',
		// roomId: selectedEventObj.extendedProps.roomId,
		// description: selectedEventObj.extendedProps.description,
		// participants: selectedEventObj.extendedProps.participants.filter(user => user.role !== 'HOST').map(user => user.email),
		return (
			<Modal
				open={open}
				style={style}
				ref={elementRef}
				closeOnDocumentClick
				closeOnDimmerClick
				onClose={onCancel}
				className="event-info__modal"
			>
				<Modal.Header>
					<div className="modal__header-wrapper">
						<span>{modalHeader}</span>
						<div>
							{!this.props.meetingTime && (
								<>
									<Icon className="icon-edit-2-outline modal__action-icon" onClick={this.props.onEditClick} />
									<Icon className="icon-trash-outline modal__action-icon" onClick={this.props.onDeleteClick} />
								</>
							)}
							<Icon className="icon-close-outline icon--dark-blue modal__action-icon" onClick={this.props.onCancelClick} />
						</div>
					</div>
				</Modal.Header>
				<Modal.Content>
					<div className="modal__content-wrapper">
						{isCallEvent ? (
							<div className="event-info__block">
								<Icon className="icon-text" />
								<p className="event-info__text">
									<b>{selectedEvent.extendedProps.description}</b>
								</p>
							</div>
						) : null}
						<div className="event-info__block">
							<Icon
								className="create-event__form-section-icon--purple icon-calendar-outline icon--purple"
							/>
							{this.renderDateDisplay()}
						</div>
						<div className="event-info__block">
							<Icon
								className="create-event__form-section-icon--green icon-clock-outline icon--green"
							/>
							<div
								className="event-info__text"
							>
								{this.props.meetingTime ?
									`${eventStartTimeHours} ${callStatus == 'MISSED' ? '' : (`- ${this.props.endCallTime == null ? 'present' : (this.props.endCallTime != null && eventEndTimeHours == 'Invalid date') ? eventStartTimeHours : eventEndTimeHours}`)}`
									: `${eventStartTimeHours} - ${eventEndTimeHours}`}
							</div>
						</div>
						<div className="event-info__block">
							<Icon
								className="create-event__form-section-icon--orange icon-pin-outline icon--orange"
							/>
							<div>
								{
									availableHospitals && availableHospitals.length !== 0 &&
									<Popup
										className="event-info__hospitals-popup"
										trigger={
											<div className="event-info__text">
												{this.renderHospitalsInfo(availableHospitals)}
											</div>}
										hoverable
										basic
										position="top left"
									>
										{availableHospitals && availableHospitals.map(hospital => (
											<div className="event-info__hospital-item">{hospital.name}</div>
										))
										}
									</Popup>
								}
								{
									availableHospitals && availableHospitals.length === 0 &&
									<div className="event-info__text">No selected hospitals</div>
								}
							</div>
						</div>
						{/* Need to include meeting link, id and passcode here but backend doesn't give me this yet. */}
						{/* {isCallEvent ? (
							<div className="event-info__block">
								<Icon className="icon-link" />
								<p className="event-info__text">
									<b>{selectedEvent.extendedProps.description}</b>
								</p>
							</div>
						) : null} */}
					</div>
				</Modal.Content>
			</Modal>
		);
	}
}

export default EventInfoModal;
