import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  PresetSnapshotState,
  PresetState,
} from "src/domains/Beacon/store/preset/types";

export const initialState: PresetState = {
  // TODO: do the snapshots/presets really need to be sotred in an object?
  // can't they jsut be stored in a normal array??? TODO: speak with Sunil
  snapshots: {},
};

export const presetSlice = createSlice({
  name: "preset",
  initialState,
  reducers: {
    // TODO: Create a `deletePreset()` reducer
    savePreset: {
      reducer(
        state,
        action: PayloadAction<{
          presetName: string;
          snapshot: PresetSnapshotState;
        }>
      ) {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes

        // so instead of doing something like
        // `const newState = [...state, newItem]`
        // we can do
        // `state.push(newItem)`
        state.snapshots[action.payload.presetName] = action.payload.snapshot;
      },
      // `prepare` is used in case we want an action to take in other extra
      // properties other than the traditional `{ type: "ACTION", payload: {} }.
      // In this case, we send `presetName` and `snapshot` separately since
      // nested objects can get messy. Whatever returns from `prepare` is whats
      // sent to the reducer function (that ultimately modifies the state)
      prepare(presetName: string, snapshot: PresetSnapshotState) {
        // Perform a deep clone of current layoutFrames and store as a snapshot.
        const snapshotCopy: PresetSnapshotState = JSON.parse(
          JSON.stringify(snapshot)
        );

        // This gets passed in to the `reducer()` func on `savePreset`
        return {
          payload: {
            presetName,
            snapshot: snapshotCopy,
          },
        };
      },
    },
  },
});

// Export the individual reducer and actions, which are generated with `createSlice`
export const { reducer: presetReducer, actions: presetActions } = presetSlice;
