import { Nurep } from "availkit-js";
import { AVKCallHangupEvent } from "availkit-js/dist/Models/Events/AVKCallHangupEvent";
import { AVKConsoleCallbackAcceptEvent } from "availkit-js/dist/Models/Events/AVKConsoleCallbackAcceptEvent";
import { AVKConsoleCallbackDeclineEvent } from "availkit-js/dist/Models/Events/AVKConsoleCallbackDeclineEvent";
import { AVKConsoleCallbackIncomingEvent } from "availkit-js/dist/Models/Events/AVKConsoleCallbackIncomingEvent";
import { AVKSchedulerEvent } from "availkit-js/dist/Models/Events/AVKSchedulerEvent";
import { AVKSchedulerLastParticipantEvent } from "availkit-js/dist/Models/Events/AVKSchedulerLastParticipantEvent";
import { NCallAcceptEvent } from "availkit-js/dist/Models/Events/NCallAcceptEvent";
import { NCallDeclineEvent } from "availkit-js/dist/Models/Events/NCallDeclineEvent";
import { NIncomingServerCallEvent } from "availkit-js/dist/Models/Events/NIncomingServerCallEvent";
import { NSessionJoinEvent } from "availkit-js/dist/Models/Events/NSessionJoinEvent";
import { NActor } from "availkit-js/dist/Models/NActor";
import { NSession } from "availkit-js/dist/Models/NSession";
import { AVKSchedulerService } from "availkit-js/dist/Services/AVKSchedulerService";
import { AVKSchedulerServiceListener } from "availkit-js/dist/Services/Listeners/AVKSchedulerServiceListener";
import { NTelephonyServiceListener } from "availkit-js/dist/Services/Listeners/NTelephonyServiceListener";
import { NTelephonyService } from "availkit-js/dist/Services/NTelephonyService";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  NetworkQualityLevel,
  Participant,
  RemoteVideoTrack,
  Track,
} from "twilio-video";

import React from "react";
import { connect } from "react-redux";

import { Dispatch } from "redux";

import { LastParticipantModal } from "portalcall/commoncall/components/Modal/LastParticipantModal";

import { logger } from "../../common/logger";
import RoomDebugPanel from "../../components/RoomDebugPanel";
import { MULTI_PARTY_LAUNCH_MODE, P2P_LAUNCH_MODE } from "../../constants";
import {
  getMultiPartyEventDetails,
  initializeMultiPartyEvent,
  setCallInfo,
  setCallSid,
  setCallState,
} from "../../store/meeting/actions";
import {
  AppState,
  CallState,
  CallStateType,
  FeaturesToSync,
  MediaDeviceEnumeration,
  MediaStateType,
  MPLiteRemoteTracks,
  MultiPartyCallEventInfo,
  MultiPartyEventDetail,
  MultiPartyInitializeState,
  PortalIdentity,
  TwilioState,
} from "../../store/models";
import {
  connectCall,
  getTwilioCredentials,
  setHostHasJoinedRoom,
  setHostTracks,
} from "../../store/twilio/actions";
import {
  endCall,
  getIdentity,
  getLocalMediaSuccess,
  setMediaState,
} from "../../store/user/actions";
import { LocalOrRemoteMediaTrack } from "../../types";
import EndCallConfirmation from "../EndCallConfirmation";
import InCallControls from "../InCallControls";
import InMeetingErrorDialog from "../InMeetingErrorDialog";
import LargeMedia from "../LargeMedia";
import Loader from "../Loader";
import MediaTrack from "../MediaTrack";
import PipMedia from "../PipMedia";
import PortalCallLogin from "../PortalCallLogin";
import PresetDialog from "../PresetDialog";
import ReconnectModal from "../ReconnectModal";
import Roster from "../Roster";
import { getLoginId } from "../Roster/utils";
import UserBusyDialog from "../UserBusyDialog";
import "./VideoCall.scss";
import { refreshFrames } from "portalcall/commoncall/store/twilio/actions";

interface StateProps {
  audioinput: MediaDeviceInfo[];
  audiooutput: MediaDeviceInfo[];
  availKit: Nurep | null;
  callInfo: NIncomingServerCallEvent | null;
  callSid: string;
  callState: CallStateType;
  consoleHasJoinedRoom: boolean;
  duplicatedParticipantDisconnected: boolean;
  enableCameraDiscovery: boolean;
  error: boolean;
  errorMessage: string[];
  hostHasJoinedRoom: boolean;
  identity: PortalIdentity;
  launchMode: string;
  localLoading: boolean;
  localMedia: MediaDeviceInfo | null;
  localNetworkDisconnected: boolean;
  localNetworkQualityLevel: NetworkQualityLevel;
  localTracks: LocalOrRemoteMediaTrack[];
  mediaErrorCode: string;
  mediaState: MediaStateType;
  mpEventDetail: MultiPartyEventDetail | null;
  mpLiteRemoteTracks: MPLiteRemoteTracks;
  multiPartyCallEventInfo: MultiPartyCallEventInfo | null;
  participants: Participant[];
  refreshInProgress: boolean;
  remoteLoading: boolean;
  remoteTracks: LocalOrRemoteMediaTrack[];
  twilioState: TwilioState;
  videoinput: MediaDeviceInfo[];
}

interface DispatchProps {
  connectCall: () => void;
  endCall: () => void;
  getIdentity: () => void;
  getLocalMediaSuccess: (data: MediaDeviceEnumeration) => void;
  getMultiPartyEventDetails: (eventInfo) => void;
  getTwilioCredentials: () => void;
  initializeMultiPartyEvent: (eventInfo: MultiPartyInitializeState) => void;
  setCallInfo: (event: NIncomingServerCallEvent) => void;
  setCallState: (callState: CallStateType) => void;
  setCallSid: (callSid: string) => void;
  setHostTracks: (tracks: LocalOrRemoteMediaTrack[]) => void;
  setHostHasJoinedRoom: (data: boolean) => void;
  setMediaState: (mediaState: MediaStateType) => void;
  refreshFrames: () => void;
}

type Props = StateProps & DispatchProps;

type State = {
  networkDebugPanel: boolean;
};

// TODO: this component has gotten way too big
class VideoCall
  extends React.Component<Props, State>
  implements NTelephonyServiceListener, AVKSchedulerServiceListener {
  constructor(props: Props) {
    super(props);
    this.updateDeviceConfiguration = this.updateDeviceConfiguration.bind(this);
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.onStorageChanged = this.onStorageChanged.bind(this);

    this.state = {
      networkDebugPanel: JSON.parse(
        window.sessionStorage.getItem("networkDebugPanel")!
      ),
    };
  }
  handleRefreshFrames = () => this.props.refreshFrames();

  onReceiveEventUpdate(
    schedulerService: AVKSchedulerService,
    event: AVKSchedulerEvent
  ) {
    logger().info(
      "Event was updated while call is in progress... attempting to update Event Details"
    );
    const { multiPartyCallEventInfo } = this.props;
    const updatedEventState: MultiPartyInitializeState = {
      meetingToken: multiPartyCallEventInfo,
      featuresToSync: FeaturesToSync.eventDetails,
    };
    this.props.initializeMultiPartyEvent(updatedEventState);
  }
  onReceiveLastParticipant(
    schedulerService: AVKSchedulerService,
    event: AVKSchedulerLastParticipantEvent
  ): void {}

  onReceiveConsoleCallbackIncoming(
    service: NTelephonyService,
    event: AVKConsoleCallbackIncomingEvent
  ): void {}

  onReceiveConsoleCallbackAcceptEvent(
    service: NTelephonyService,
    event: AVKConsoleCallbackAcceptEvent
  ): void {}

  onReceiveConsoleCallbackDeclineEvent(
    service: NTelephonyService,
    event: AVKConsoleCallbackDeclineEvent
  ): void {}

  onBeforeUnload = (e: any) => {
    if (this.props.callState !== CallState.READY) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  onStorageChanged = (e: any) => {
    const twilioSDKDebugLevel = window.localStorage.getItem(
      "twilioSDKDebugLevel"
    );
    const networkDebugPanel = JSON.parse(
      window.sessionStorage.getItem("networkDebugPanel")!
    );
    this.setState({ networkDebugPanel });

    if (twilioSDKDebugLevel) {
      console.log(`MPL got localstorage event`);
      const twilioLogger = this.props.twilioState.twilioLogger as any;
      twilioLogger.setLogLevel(twilioSDKDebugLevel);
      console.log(`MPL setting twilio debug to: ${twilioSDKDebugLevel}`);
    }
  };

  onCloseNetworkPanel = (): void => {
    window.sessionStorage.setItem("networkDebugPanel", "false");
    this.setState({ networkDebugPanel: false });
  };

  componentDidUpdate(prevProps: Props) {
    const {
      availKit,
      enableCameraDiscovery,
      callSid,
      identity,
      launchMode,
      localNetworkQualityLevel,
      participants,
      mpEventDetail,
      multiPartyCallEventInfo,
      setHostHasJoinedRoom,
      setHostTracks,
      remoteTracks,
    } = this.props;

    if (availKit) {
      availKit.telephonyService.addEventListener(this);
      availKit.avkSchedulerService.addEventListener(this);
      if (
        launchMode === P2P_LAUNCH_MODE &&
        enableCameraDiscovery &&
        prevProps.enableCameraDiscovery !== enableCameraDiscovery
      ) {
        try {
          // TODO - I don't think these two lines actually do anything, test removal
          const localActor = new NActor();
          localActor.uniqueIdentifier = identity.login_id;

          // Only do discoverCameras in P2P
          if (launchMode === P2P_LAUNCH_MODE) {
            availKit.cameraDiscoveryService.discoverCameras(callSid);
          }
        } catch (error) {
          logger().error("Failed to send CameraDiscovery", error);
        }
      }
    }

    if (prevProps.localNetworkQualityLevel !== localNetworkQualityLevel) {
      try {
        const videoTracks: RemoteVideoTrack[] = remoteTracks.filter(
          (t) => t.kind === "video"
        ) as RemoteVideoTrack[];
        videoTracks.forEach((videoTrack, index) => {
          if (videoTrack.dimensions) {
            logger().info(
              "RemoteVideoTrack#" +
                (index + 1) +
                JSON.stringify(videoTrack.dimensions)
            );
          }
        });
      } catch (e) {
        logger().error("Error while logging RemoteVideoTrack dimensions");
        logger().error(e);
      }
    }

    // When a new participant joins/leaves, update host if they join
    if (prevProps.participants.length !== participants.length) {
      const mpParticipants = mpEventDetail?.participants;
      if (mpParticipants?.length) {
        // Mp event call has resolved
        const mpHost = mpParticipants.find((u) => u.role === "HOST");
        const hostOnTwilio = participants.find((u) => {
          const loginId = getLoginId(u.identity);
          return loginId === mpHost?.loginId;
        });

        // if host exists, set hostHasJoinRoom to true and update their tracks
        if (hostOnTwilio) {
          const hostTracks: LocalOrRemoteMediaTrack[] = [];

          // Gather the individual audio and video tracks for the host participant
          const tracks = [
            ...hostOnTwilio.audioTracks,
            ...hostOnTwilio.videoTracks,
          ].map(([key, value]) => value);

          tracks.forEach((publication) => {
            const track = publication.track;
            if (track) {
              hostTracks.push(track);
            }
          });

          setHostHasJoinedRoom(true);
          setHostTracks(hostTracks);
        } else {
          // if current user is not host, that means the host has left the call in this clause, so cleanup must be done
          const currentUserLoginId = identity.login_id;
          const mpEventHost = mpParticipants.find((p) => p.role === "HOST");
          const isCurrentUserHost = currentUserLoginId === mpEventHost!.loginId;

          if (!isCurrentUserHost) {
            setHostHasJoinedRoom(false);
            setHostTracks([]);
          }
        }
      }

      if (
        launchMode === MULTI_PARTY_LAUNCH_MODE &&
        prevProps.participants.length < participants.length
      ) {
        this.props.getMultiPartyEventDetails({
          meetingToken: multiPartyCallEventInfo,
        });
      }
    }

    // TODO: this block/logic is repeated code, CLEAN UP
    // If remoteTracks gets updated, check to see if its a host track to update
    if (prevProps.remoteTracks.length !== remoteTracks.length) {
      const mpParticipants = mpEventDetail?.participants;
      if (mpParticipants?.length) {
        // Mp event call has resolved
        const mpHost = mpParticipants.find((u) => u.role === "HOST");
        const hostOnTwilio = participants.find((u) => {
          const loginId = getLoginId(u.identity);
          return loginId === mpHost?.loginId;
        });

        // if host exists, set hostHasJoinRoom to true and update their tracks
        if (hostOnTwilio) {
          const hostTracks: LocalOrRemoteMediaTrack[] = [];

          // Gather the individual audio and video tracks for the host participant
          const tracks = [
            ...hostOnTwilio.audioTracks,
            ...hostOnTwilio.videoTracks,
          ].map(([key, value]) => value);

          tracks.forEach((publication) => {
            const track = publication.track;
            if (track) {
              hostTracks.push(track);
            }
          });

          setHostHasJoinedRoom(true);
          setHostTracks(hostTracks);
        }
      }
    }

    // set console video for largeMediaTracks
    // if (prevProps.consoleHasJoinedRoom !== consoleHasJoinedRoom) {
    //   console.log("Setting console video");
    //   this.setConsoleVideo();
    // }

    // console.log({ consoleVideoTracks: this.state.consoleVideoTracks });
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onBeforeUnload);
    window.addEventListener("storage", this.onStorageChanged);

    if (navigator && navigator.mediaDevices) {
      navigator.mediaDevices.ondevicechange = this.updateDeviceConfiguration;
    }

    // this.setConsoleVideo();
  }

  // setConsoleVideo() {
  //   const { participants, remoteTracks } = this.props;

  //   const consoleParticipant = participants.find((p) =>
  //     p.identity.includes("CONSOLE")
  //   );
  //   const videoTracks = remoteTracks.filter((t) => t.kind === "video");

  //   // Get the video from remoteTracks that belongs to console
  //   const consoleVideo = (videoTracks as RemoteVideoTrack[]).find((track) => {
  //     const sid = track.sid;
  //     return consoleParticipant?.videoTracks.has(sid);
  //   });

  //   if (consoleVideo) {
  //     this.setState({ consoleVideoTracks: [consoleVideo] });
  //   }
  // }

  shouldUpdateDevices(
    curDevices: MediaDeviceInfo[],
    newDevices: MediaDeviceInfo[]
  ) {
    let shouldUpdate = true;
    if (!curDevices || !newDevices || curDevices.length !== newDevices.length) {
      shouldUpdate = false;
    }

    if (shouldUpdate) {
      for (let i = 0; i < newDevices.length; i++) {
        if (newDevices[i].deviceId !== curDevices[i].deviceId) {
          shouldUpdate = false;
          break;
        }
      }
    }
    return shouldUpdate;
  }

  updateStoreWithLatestDeviceConfiguration(
    newAudioInputs: MediaDeviceInfo[],
    newAudioOutputs: MediaDeviceInfo[],
    newVideoInputs: MediaDeviceInfo[]
  ) {
    const { localMedia } = this.props;
    if (localMedia) {
      this.props.getLocalMediaSuccess({
        localMedia,
        audioinput: newAudioInputs,
        audiooutput: newAudioOutputs,
        videoinput: newVideoInputs,
      });
    }
  }

  updateAudioOutput(
    audiooutput: MediaDeviceInfo[],
    newAudioOutputs: MediaDeviceInfo[]
  ) {
    const audioElement: any | null = document.querySelector(
      ".large-media audio"
    );
    if (audioElement) {
      if (audioElement.setSinkId) {
        if (typeof audioElement.setSinkId === "function") {
          const newAudioOutput = newAudioOutputs[0];
          logger().info("audiooutput changed to " + newAudioOutput.label);
          audioElement.setSinkId(newAudioOutput.deviceId);
        } else {
          logger().info(
            "This browser does not support setting an audio output device"
          );
        }
      }
    }
  }

  updateDeviceConfiguration() {
    const { twilioState, audiooutput, localTracks } = this.props;
    logger().info("Detected a change in device configuration");
    const localParticipant = twilioState.room?.localParticipant;

    navigator.mediaDevices.enumerateDevices().then((availableDevices) => {
      const newAudioInputs = availableDevices.filter(
        (media) => media.kind === "audioinput" && media.deviceId !== "default"
      );
      const newVideoInputs = availableDevices.filter(
        (media) => media.kind === "videoinput" && media.deviceId !== "default"
      );
      const newAudioOutputs = availableDevices.filter(
        (media) => media.kind === "audiooutput"
      );

      logger().info("New Audio Outputs " + JSON.stringify(newAudioOutputs));
      logger().info("New Audio Inputs " + JSON.stringify(newAudioInputs));
      logger().info("New Video Inputs " + JSON.stringify(newVideoInputs));

      if (newAudioInputs.length === 0 || newAudioOutputs.length === 0) {
        logger().info("Skipping device re-configuration");
        return;
      }

      this.updateStoreWithLatestDeviceConfiguration(
        newAudioInputs,
        newAudioOutputs,
        newVideoInputs
      );

      /* Update Audio Output */
      if (newAudioOutputs && newAudioOutputs.length) {
        this.updateAudioOutput(audiooutput, newAudioOutputs);
      } else {
        logger().warn("cannot determine audiooutput. skipping.");
      }

      if (localParticipant) {
        if (newVideoInputs && newVideoInputs.length) {
          for (let i = 0; i < localTracks.length; i++) {
            /* Update Video Input */
            if (localTracks[i] instanceof LocalVideoTrack) {
              const localVideoTrack: LocalVideoTrack = localTracks[
                i
              ] as LocalVideoTrack;
              const newVideoInput = newVideoInputs[0];
              logger().info("videoinput changed to " + newVideoInput.label);
              const constraints: MediaTrackConstraints = {
                deviceId: {
                  exact: newVideoInput.deviceId,
                },
              };

              localVideoTrack
                .stop()
                .restart(constraints)
                .then(() => {
                  logger().info("videoinput successfully changed");
                })
                .catch((reason) => {
                  logger().info(
                    "videoinput change rejected. Trying again." + reason
                  );
                  localVideoTrack
                    .restart(constraints)
                    .then(() => {
                      logger().info(
                        "videoinput successfully changed: Attempt 2"
                      );
                    })
                    .catch((secondattemptreason) => {
                      logger().info(
                        "videoinput change rejected again." +
                          secondattemptreason
                      );
                    });
                });
            }
          }
        } else {
          logger().info("Skipping videoinput change. No camera detected ?");
        }

        if (newAudioInputs && newAudioInputs.length) {
          for (let i = 0; i < localTracks.length; i++) {
            /* Update Audio Input */
            if (localTracks[i] instanceof LocalAudioTrack) {
              const localAudioTrack: LocalAudioTrack = localTracks[
                i
              ] as LocalAudioTrack;
              const newAudioInput = newAudioInputs[0];
              logger().info("audioinput changed to " + newAudioInput.label);
              const constraints: MediaTrackConstraints = {
                deviceId: {
                  exact: newAudioInput.deviceId,
                },
              };

              localAudioTrack
                .stop()
                .restart(constraints)
                .then(() => {
                  logger().info("audioinput successfully changed");
                })
                .catch((reason) => {
                  logger().info(
                    "audioinput change rejected. Trying again." + reason
                  );
                  localAudioTrack
                    .restart(constraints)
                    .then(() => {
                      logger().info(
                        "audioinput successfully changed: Attempt 2"
                      );
                    })
                    .catch((secondattemptreason) => {
                      logger().info(
                        "audioinput change rejected again." +
                          secondattemptreason
                      );
                    });
                });
            }
          }
        } else {
          logger().info("Skipping audioinput change. No mic detected ?");
        }
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onBeforeUnload);
    window.removeEventListener("storage", this.onStorageChanged);

    if (this.props.availKit && this.props.availKit.telephonyService) {
      this.props.availKit.telephonyService.removeEventListener(this);
    }
    if (this.isCallInProgress()) {
      this.props.endCall();
    }
  }

  isCallInProgress = (): boolean => {
    let inProgress = false;
    switch (this.props.callState) {
      case CallState.INCOMING:
      case CallState.ANSWERED:
      case CallState.INPROGRESS:
      case CallState.ENDCALLCONFIRMATION:
      case CallState.NEWPRESET:
        inProgress = true;
        break;
    }
    return inProgress;
  };

  /**
   * Nurep.js event handlers
   */

  didAcceptCall(service: NTelephonyService, event: NCallAcceptEvent): void {}
  didDeclineCall(service: NTelephonyService, event: NCallDeclineEvent): void {}
  didJoinSession(service: NTelephonyService, event: NSessionJoinEvent): void {}

  onReceiveHangupV2(
    service: NTelephonyService,
    event: AVKCallHangupEvent
  ): void {
    logger().info("Received a Hangup event v2: ", JSON.stringify(event));

    if (event.session_id !== this.props.callSid) {
      logger().info("Ignoring the hangup event: ", JSON.stringify(event));
      return;
    }
    if (this.isCallInProgress() && !this.isEndCallInitiatedByRemoteClient()) {
      logger().info(
        "onReceiveHangupV2: isEndCallInitiatedByRemoteClient",
        JSON.stringify(event)
      );
      window.removeEventListener("beforeunload", this.onBeforeUnload);
      if (this.props.availKit && this.props.availKit.telephonyService) {
        this.props.availKit.telephonyService.removeEventListener(this);
      }

      /* This condition is required to address an issue of sudden hangup */
      if (this.props.launchMode !== MULTI_PARTY_LAUNCH_MODE) {
        logger().info(
          "onReceiveHangupV2: sudden hangup - ",
          JSON.stringify(event)
        );
        this.props.endCall();
      }
    }
  }

  // // Invoked after receiving remote hangup event.
  // onReceiveHangup = (service: NTelephonyService, event: NCallHangupEvent) => {
  //   logger().info("Received a Hangup event.", event);

  //   if (
  //     event.sender &&
  //     event.sender !== this.props.identity.login_id &&
  //     this.isCallInProgress() &&
  //     !this.isEndCallInitiatedByRemoteClient()
  //   ) {
  //     window.removeEventListener("beforeunload", this.onBeforeUnload);
  //     if (this.props.availKit && this.props.availKit.telephonyService) {
  //       this.props.availKit.telephonyService.removeEventListener(this);
  //     }
  //     this.props.endCall();
  //   }
  // };

  // Invoked after receiving remote hangup event and processing.
  onEndTelephonySession = (service: NTelephonyService, session: NSession) => {
    // if (session && session.channel && session.channel.uniqueIdentifier) {
    //   logger().info(
    //     `Exiting session channel ${session.channel.uniqueIdentifier.toString()}.`
    //   );
    // } else {
    //   logger().info(`Exiting session channel`);
    // }
    // if (this.isCallInProgress()) {
    //   this.props.endCall();
    //   this.props.setCallState(CallState.READY);
    // }
  };

  onReceiveIncomingServerCall = (
    service: NTelephonyService,
    event: NIncomingServerCallEvent
  ): void => {
    logger().info("Received an incoming call request.", event);

    if (this.props.callSid) {
      logger().info("Ignoring the event, a call is already in progress");
      return;
    }

    this.props.setCallInfo(event);
    this.props.setCallState(CallState.INCOMING);
    this.props.setCallSid(event.session_id);
  };

  // TODO: Make these stubs non-necessary on NTelephonyServiceListener interface?
  onReceiveLastParticipantEvent = (
    service: NTelephonyService,
    event: any
  ): void => {};
  onReceiveIncomingCall = (service: NTelephonyService, event: any): void => {};
  onReceiveUserPreset = (service: any, event: any) => {};
  onReceiveCameraChange = (service: any, event: any) => {};
  onReceiveCameraConfig = (service: any, event: any) => {};
  onReceiveCameraMove = (service: any, event: any) => {};
  onReceiveCameraZoom = (service: any, event: any) => {};
  onReceiveTextMessage = (service: any, event: any) => {};
  onReceiveCameraReset = (service: any, cameraResetEvent: any) => {};
  onReceiveVideoLayoutChange = (service: any, event: any) => {};
  onReceiveVideoSourceChange = (service: any, event: any) => {};
  onReceiveVideoSourcePresetLayout = (service: any, event: any) => {};
  onReceivePreset = (service: any, presetEvent: any) => {};
  onEstablishPreset = (service: any, presetEvent: any) => {};
  onAddActor = (service: any, actor: any, session: any) => {};
  // Completion methods
  // Invoked after publishing a hangup event from within Webcall.
  didEndTelephonySession = (service: any, session: any) => {};
  didBeginTelephonySession = (service: any, session: any) => {};
  didFailToEstablishSessionForUser = (service: any, user: any) => {};

  // PubNub Timeout events for Phantom HangUps
  onReceivePNTimeoutConsoleEvent = (service: any, event: any) => {
    logger().info(
      `Received PubNub Timeout event from Console ${JSON.stringify(event)}`
    );
  };
  onReceivePNTimeoutUserEvent = (service: any, event: any) => {
    logger().info(
      `Received PubNub Timeout event from User ${JSON.stringify(event)}`
    );
  };
  onReceivePNTimeoutWebcallEvent = (service: any, event: any) => {
    logger().info(
      `Received PubNub Timeout event from Webcall ${JSON.stringify(event)}`
    );
  };

  /* tslint:enable no-empty */
  _renderMedia(media: LocalOrRemoteMediaTrack[], kind: Track.Kind) {
    const { launchMode } = this.props;
    if (launchMode === MULTI_PARTY_LAUNCH_MODE && kind === "audio") {
      const remoteAudiotracks: LocalOrRemoteMediaTrack[] = media.filter(
        (m) => m.kind === kind
      );

      const mediaTracks: any[] = [];
      remoteAudiotracks.forEach((remoteAudiotrack) => {
        mediaTracks.push(<MediaTrack track={remoteAudiotrack} kind={kind} />);
      });
      return <div>{mediaTracks}</div>;
    } else {
      if (media && media.length) {
        const track = media.find((m) => m.kind === kind);
        if (track) {
          return (
            <div>
              <MediaTrack track={track} kind={kind} refreshFrames={this.handleRefreshFrames}/>
            </div>
          );
        }
      }
    }
    return null;
  }

  isEndCallInitiatedByRemoteClient() {
    return this.props.callState === CallState.ENDCALLCONFIRMED;
  }

  updateConsoleVideoMute() {
    const { mpLiteRemoteTracks, mediaState } = this.props;

    const disabledVideoTrackNum = mpLiteRemoteTracks.console.findIndex((t) => {
      return t.kind === "video" && !t.isEnabled;
    });
    let consoleVideoMuted = disabledVideoTrackNum >= 0;
    if (
      (consoleVideoMuted && mediaState.console.video === "unmute") ||
      (!consoleVideoMuted && mediaState.console.video === "mute")
    ) {
      this.props.setMediaState({
        ...mediaState,
        console: {
          ...mediaState.console,
          video: consoleVideoMuted ? "mute" : "unmute",
        },
      });
    }
    return consoleVideoMuted;
  }

  reconnectToMeeting = (): void => {
    window.location.reload();
  };

  render() {
    const {
      callState,
      consoleHasJoinedRoom,
      duplicatedParticipantDisconnected,
      error,
      errorMessage,
      hostHasJoinedRoom,
      launchMode,
      localLoading,
      localNetworkDisconnected,
      localTracks,
      mediaErrorCode,
      mediaState,
      mpLiteRemoteTracks,
      refreshInProgress,
      remoteLoading,
      remoteTracks,
    } = this.props;
    // const { consoleVideoTracks } = this.state;

    const shouldShowInCallProgress =
      callState === CallState.INPROGRESS ||
      callState === CallState.ENDCALLCONFIRMATION ||
      callState === CallState.NEWPRESET;
    // const shouldShowPipMedia =
    // mediaState.pip === "show" && mediaState.self.video === "unmute";
    const shouldShowPipMedia = mediaState.pip === "show";
    const isLocalVideoMuted = mediaState.self.video === "mute";

    const isReadyToTakeCall = callState === CallState.READY;
    const shouldShowEndCallConfirmation =
      callState === CallState.ENDCALLCONFIRMATION;
    const shouldShowNewPresetDialog = callState === CallState.NEWPRESET;

    const consoleTracks: LocalOrRemoteMediaTrack[] = mpLiteRemoteTracks.console;
    // if mp mode the check if console has joined (if they haven't then loading is true) otherwise check if anyone has joined
    let largeMediaLoading =
      launchMode === MULTI_PARTY_LAUNCH_MODE
        ? !consoleHasJoinedRoom
        : remoteLoading;
    let messageToDisplayInPip: string =
      launchMode === MULTI_PARTY_LAUNCH_MODE
        ? refreshInProgress
          ? "Refreshing..."
          : "Please wait for the host to join the event"
        : "";

    // largeMediaTracks will always belong to the console, at least for now
    let largeMediaTracks = consoleTracks;

    let shouldRenderAudio =
      launchMode === MULTI_PARTY_LAUNCH_MODE ? true : !largeMediaLoading;

    // in mp meetings if the host has NOT joined then we want the loading indicator
    let pipMediaLoading =
      launchMode === MULTI_PARTY_LAUNCH_MODE
        ? !hostHasJoinedRoom
        : localLoading;
    let pipMediaTracks =
      launchMode === MULTI_PARTY_LAUNCH_MODE
        ? mpLiteRemoteTracks.host
        : localTracks;

    let consoleVideoMuted = this.updateConsoleVideoMute();

    // Ref: SOFT-294
    let shouldShowMediaError = error && mediaErrorCode !== null;

    let isUserBusy: boolean = false;

    let userErrorMessage: string = "";

    if (error) {
      try {
        if (errorMessage && errorMessage[0]) {
          const errorMessageObject = JSON.parse(errorMessage[0]);
          if (errorMessageObject && errorMessageObject.status) {
            if (errorMessageObject.status === 409) {
              isUserBusy = true;
              userErrorMessage = errorMessageObject.message;
              logger().warn("MP: Unable to join : " + userErrorMessage);
              /* Remove onbeforeunload so that the browser does not warn of page navigation */
              window.removeEventListener("beforeunload", this.onBeforeUnload);
            }
          }
        }
      } catch (e) {
        /*Intentionally left blank */
      }
    }

    return (
      <>
        {isReadyToTakeCall && <PortalCallLogin />}
        {shouldShowMediaError && <InMeetingErrorDialog />}
        {isUserBusy && <UserBusyDialog userMessage={userErrorMessage} />}

        {shouldShowInCallProgress &&
          (duplicatedParticipantDisconnected || localNetworkDisconnected) && (
            <ReconnectModal />
          )}
        <LastParticipantModal
          onCleanup={() =>
            window.removeEventListener("beforeunload", this.onBeforeUnload)
          }
        />

        {!isUserBusy && shouldShowInCallProgress && (
          <LargeMedia>
            <Loader
              size={48}
              sizeUnit="px"
              color="#fff"
              loading={largeMediaLoading}
              // either show loading message for MP call otherwise show the p2p loading message (leaving empty for now until PM decides)
              message={
                launchMode === MULTI_PARTY_LAUNCH_MODE
                  ? "Please wait for the procedure room to join the event"
                  : ""
              }
            >
              {consoleVideoMuted && (
                <div className="console-video-muted">
                  <div className="console-video-muted-icon"></div>
                  <div className="console-video-muted-text">
                    <div>The room has stopped sending video</div>
                  </div>
                </div>
              )}
              {!consoleVideoMuted &&
                !largeMediaLoading &&
                this._renderMedia(largeMediaTracks, "video")}
            </Loader>
            {shouldRenderAudio && this._renderMedia(remoteTracks, "audio")}
          </LargeMedia>
        )}
        {!isUserBusy && shouldShowInCallProgress && shouldShowPipMedia && (
          <PipMedia>
            <Loader
              loading={pipMediaLoading}
              size={48}
              sizeUnit="px"
              color="#1A5BA1"
              message={messageToDisplayInPip}
            >
              {!pipMediaLoading &&
                !isLocalVideoMuted &&
                this._renderMedia(pipMediaTracks, "video")}
              {!pipMediaLoading && isLocalVideoMuted && (
                <div className="pipmediavideomute"></div>
              )}
            </Loader>
          </PipMedia>
        )}

        {!isUserBusy && <Roster />}

        {!isUserBusy && shouldShowInCallProgress && <InCallControls />}
        {shouldShowEndCallConfirmation && <EndCallConfirmation />}
        {shouldShowNewPresetDialog && <PresetDialog />}
        {this.state.networkDebugPanel && (
          <RoomDebugPanel closePanel={this.onCloseNetworkPanel} />
          // <RoomDebugPanel />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  audioinput: state.user.audioinput,
  audiooutput: state.user.audiooutput,
  availKit: state.availKit.availKitInstance,
  callInfo: state.meeting.callEventInfo,
  callSid: state.meeting.callSid,
  callState: state.meeting.callState,
  consoleHasJoinedRoom: state.twilio.consoleHasJoinedRoom,
  duplicatedParticipantDisconnected:
    state.twilio.duplicateParticipantDisconnected,
  enableCameraDiscovery: state.twilio.enableCameraDiscovery,
  error: state.twilio.error,
  errorMessage: state.twilio.errorMessage,
  hostHasJoinedRoom: state.twilio.hostHasJoinedRoom,
  identity: state.user.identity,
  launchMode: state.meeting.mode,
  localLoading: !state.user.hasJoinedRoom,
  localMedia: state.user.localMedia,
  participants: state.twilio.participants,
  mpEventDetail: state.meeting.multiPartyCallEventDetail,
  localNetworkQualityLevel: state.twilio.localNetworkQualityLevel,
  localNetworkDisconnected: state.twilio.localNetworkDisconnected,
  localTracks: state.twilio.localTracks,
  mediaErrorCode: state.twilio.mediaErrorCode,
  mediaState: state.user.mediaState,
  mpLiteRemoteTracks: state.twilio.mpLiteRemoteTracks,
  multiPartyCallEventInfo: state.meeting.multiPartyCallEventInfo,
  refreshInProgress: state.user.refreshInProgress,
  remoteLoading: !state.twilio.remoteHasJoinedRoom,
  remoteTracks: state.twilio.remoteTracks,
  twilioState: state.twilio,
  videoinput: state.user.videoinput,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    connectCall: () => dispatch(connectCall()),
    endCall: () => dispatch(endCall()),
    getIdentity: () => dispatch(getIdentity()),
    getMultiPartyEventDetails: (eventInfo) =>
      dispatch(getMultiPartyEventDetails(eventInfo)),
    getTwilioCredentials: () => dispatch(getTwilioCredentials()),
    initializeMultiPartyEvent: (meetingToken: MultiPartyInitializeState) =>
      dispatch(initializeMultiPartyEvent(meetingToken)),
    setCallSid: (callSid: string) => dispatch(setCallSid(callSid)),
    setCallInfo: (event: NIncomingServerCallEvent) =>
      dispatch(setCallInfo(event)),
    setCallState: (callState: CallStateType) =>
      dispatch(setCallState(callState)),
    setHostHasJoinedRoom: (data: boolean) =>
      dispatch(setHostHasJoinedRoom(data)),
    setHostTracks: (tracks: LocalOrRemoteMediaTrack[]) =>
      dispatch(setHostTracks(tracks)),
    getLocalMediaSuccess: (data: MediaDeviceEnumeration) =>
      dispatch(getLocalMediaSuccess(data)),
    setMediaState: (mediaState: MediaStateType) =>
      dispatch(setMediaState(mediaState)),
    refreshFrames: () => dispatch(refreshFrames())
  };
};

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(VideoCall);
