import {
  LocalAudioTrack,
  LocalParticipant,
  LocalVideoTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
} from "twilio-video";

import {
  CallDetails,
  MPUserRoles,
} from "src/domains/Beacon/store/meeting/types";
import { LoggerLevels, logger } from "src/logging/logger";
import { MultiPartyEvent, Participant } from "src/services/ApiClient/scheduler";

/**
 * Checks who published in Twilio room through the Twilio's events and returns
 * if the participant was host|console|panelist
 */
export const getParticipantRole = (
  participant: RemoteParticipant | LocalParticipant,
  participants: Participant[]
): MPUserRoles.HOST | "CONSOLE" | MPUserRoles.PARTICIPANT => {
  if (isConsoleParticipant(participant)) {
    return "CONSOLE";
  } else if (isTwilioHost(participants, participant)) {
    return MPUserRoles.HOST;
  } else {
    return MPUserRoles.PARTICIPANT;
  }
};

interface IWhoPublishedParams {
  participant: RemoteParticipant;
  publication: RemoteTrackPublication;
  callDetails: CallDetails;
  wasHost: (track: RemoteTrack) => void;
  wasConsole: (track: RemoteTrack) => void;
  wasParticipant: (track: RemoteTrack) => void;
}

// This util helps to detect what user published a track by checking the its roles
// and compare it with the Twilio's participants, at the end executes its
// its corresponding callback "who" function
export const whoPublishedTrack = ({
  participant,
  publication,
  callDetails,
  wasHost,
  wasConsole,
  wasParticipant,
}: IWhoPublishedParams) => {
  const { participants } = callDetails as MultiPartyEvent;
  const who = getParticipantRole(participant, participants);
  const newTrack = publication.track;

  switch (who) {
    case MPUserRoles.HOST: {
      wasHost(newTrack);
      break;
    }
    case MPUserRoles.CONSOLE: {
      wasConsole(newTrack);
      break;
    }
    case MPUserRoles.PARTICIPANT: {
      wasParticipant(newTrack);
      break;
    }
  }
};

// Shorthand to get the Portal user's id in the Twilio's participant object
export const getTwilioLoginId = (identity: string): string =>
  identity.split("-")[1];

// Checks if the participant is the console
export const isConsoleParticipant = (
  participant: RemoteParticipant | LocalParticipant
): boolean => {
  return participant.identity.includes("CONSOLE");
};

// Looks for the Console participant in Twilio's remotes participants
export const getConsoleParticipant = (
  participants: RemoteParticipant[]
): RemoteParticipant => {
  return participants.find((p) => p.identity.includes("CONSOLE"));
};

/**
 * Check an individual Twilio's participant if it's the Twilio's host through
 * the list of the Call participants
 */
export const isTwilioHost = (
  participants: Participant[],
  participant: RemoteParticipant | LocalParticipant
) => {
  const callHost = participants.find((u) => u.role === MPUserRoles.HOST);
  const loginId = getTwilioLoginId(participant.identity);
  return loginId === callHost?.loginId;
};

/**
 * Looks for the Twilio's host through the list of the Call participants and
 * Twilio's participants
 */
export const getHostUser = (participants: Participant[]): Participant => {
  return participants.find((u) => u.role === MPUserRoles.HOST);
};

// Gets the local participant's audio tracks, this can be helpful to disable/enable
// all the audio tracks from the user
export const getParticipantAudioTracks = (
  participant: LocalParticipant
): LocalAudioTrack[] => {
  return Array.from(participant.audioTracks?.values() ?? []).map(
    (publication) => publication.track
  );
};

// Gets the local participant's audio tracks, this can be helpful to disable/enable
// all the audio tracks from the user
export const getParticipantVideoTracks = (
  participant: LocalParticipant
): LocalVideoTrack[] => {
  return Array.from(participant.videoTracks?.values() ?? []).map(
    (publication) => publication.track
  );
};

// Enabling the video track of the current participant.
// Based on PortalCall file: InCallControls.tsx - toggleMediaMute()
export const enableVideoTrack = (participant: LocalParticipant) => {
  try {
    logger().info("Enabling Host's video tracks");
    const tracks = getParticipantVideoTracks(participant);
    tracks.forEach((t) => {
      t.enable();
    });
  } catch (error: any) {
    logger().error("Error while enabling Host's video tracks", error?.message);
  }
};

// Disabling the video track of the current participant.
// Based on PortalCall file: InCallControls.tsx - toggleMediaMute()
export const disableVideoTrack = (participant: LocalParticipant) => {
  try {
    logger().info("Disabling Host's video tracks");
    const tracks = getParticipantVideoTracks(participant);
    tracks.forEach((t) => {
      t.disable();
    });
  } catch (error: any) {
    logger().error("Error while disabling Host's video tracks", error?.message);
  }
};

// Enabling the audio track of the current participant.
// Based on PortalCall file: InCallControls.tsx - toggleMediaMute()
export const enableAudioTrack = (participant: LocalParticipant) => {
  try {
    logger().info("Enabling audio tracks");
    const tracks = getParticipantAudioTracks(participant);
    tracks.forEach((t) => {
      t.enable();
    });
  } catch (error: any) {
    logger().error("Error while enabling audio tracks", error?.message);
  }
};

// Disabling the audio track of the current participant.
// Based on PortalCall file: InCallControls.tsx - toggleMediaMute()
export const disableAudioTrack = (participant: LocalParticipant) => {
  try {
    logger().info("Disabling audio tracks");
    const tracks = getParticipantAudioTracks(participant);
    tracks.forEach((t) => {
      t.disable();
    });
  } catch (error: any) {
    logger().error("Error while disabling audio tracks", error?.message);
  }
};

/**
 * Room subscription to detect when a participant publishes a new audio/video
 * track.
 *
 * TODO: For now it only works for host but would it be implemented in a
 * future for console and panelists?
 */

// adds listeners to a Track after being subscribed regardless of kind
export const addTrackListeners = (
  newTrack: RemoteTrack,
  participant: RemoteParticipant
) => {
  newTrack.on("switchedOff", () => {
    logger().logWithFields(
      LoggerLevels.warn,
      { feature: "TwilioSubscriptions/useRoomTrackSubscribed" },
      `The ${newTrack.kind} track was switched OFF for participant: ${participant.identity}`
    );
  });

  newTrack.on("switchedOn", () => {
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "TwilioSubscriptions/useRoomTrackSubscribed",
        fileInfo: `useRoomTrackSubscribed`,
      },
      `The ${newTrack.kind} track was switched ON for participant: ${participant.identity}`
    );
  });

  newTrack.on("started", () => {
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "TwilioSubscriptions/useRoomTrackSubscribed",
        fileInfo: `useRoomTrackSubscribed`,
      },
      `The ${newTrack.kind} track was started for ${participant.identity}`
    );
  });
  newTrack.on("dimensionsChanged", () => {
    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: `useRoomTrackSubscribed`,
        feature: "TwilioSubscriptions/useRoomTrackSubscribed",
      },
      `The ${newTrack.kind} track was dimensionsChanged for ${participant.identity}`
    );
  });
  newTrack.on("disabled", () => {
    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: `useRoomTrackSubscribed`,
        feature: "TwilioSubscriptions/useRoomTrackSubscribed",
      },
      `The ${newTrack.kind} track was disabled for ${participant.identity}`
    );
  });
  newTrack.on("enabled", () => {
    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: `useRoomTrackSubscribed`,
        feature: "TwilioSubscriptions/useRoomTrackSubscribed",
      },
      `The ${newTrack.kind} track was enabled for ${participant.identity}`
    );
  });
  newTrack.on("stopped", () => {
    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: `useRoomTrackSubscribed`,
        feature: "TwilioSubscriptions/useRoomTrackSubscribed",
      },
      `The ${newTrack.kind} track was stopped for ${participant.identity}`
    );
  });
};
