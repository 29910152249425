import { useState, Dispatch, SetStateAction, useEffect } from "react";

/**
 * setState, but only after a 300ms timeout (or whatever you pass in)
 * Useful for loading states when you dont want the loading indicator to flash in/out if API returns really fast
 * 300 ms is so small that it doesn't slow down the user but its long enough to prevent the flashing
 */
export function useDebouncedState<T>(
  initialState: T,
  debounceTimeout = 300
): [T, Dispatch<SetStateAction<T>>, T] {
  const [filteredState, setFilteredState] = useState<T>(initialState);
  const [state, setState] = useState(filteredState);
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    timeout = setTimeout(() => {
      setState(filteredState);
      timeout = undefined;
    }, debounceTimeout);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [filteredState, setState]);
  return [state, setFilteredState, filteredState];
}
