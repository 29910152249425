import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";

import resource from "avail-web-ui/domains/DomainConstants";
import {
  closeNotificationAction,
  notificationsSelector,
} from "avail-web-ui/dux/NotificationSlice";

import styles from "./styles.module.scss";

export const Notification = () => {
  const notification = useSelector(notificationsSelector);

  const dispatch = useDispatch();

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeNotificationAction());
  };

  return (
    <Snackbar
      classes={{ root: styles.root }}
      open={notification.isOpen}
      autoHideDuration={resource.duration.notificationDuration}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
    >
      <Alert
        variant="filled"
        // @ts-expect-error
        severity={notification.type}
        onClose={handleClose}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
};
