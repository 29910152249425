import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Fields, validators } from '../../../../../../Common/Components';
import { getAllHospitalsAction } from '../../../Organisation/Hospitals/HospitalsActions';

class AccountCreationFormHospitalsPart extends PureComponent {
	componentDidMount() {
		this.props.getAllHospitals();
	}

	render() {
		return (
			<div className="account-creation__therapies-form">
				<div className="account-creation__heading">
					Selected preferred hospitals
				</div>
				<div className="account-setup__accordion-wrapper">
					{
						this.props.hospitals && this.props.hospitals.length > 0 && (
							<Fields.MultiselectField
								fluid
								labelText="Add hospital"
								placeholder="Choose hospital from list"
								name="hospitals"
								multiple
								options={this.props.hospitals.map(hospital => ({
									key: hospital.id,
									value: hospital.id,
									label: hospital.name,
								}))}
								validate={validators.required}
							/>
						)
					}
				</div>
			</div>
		);
	}
}

AccountCreationFormHospitalsPart.defaultProps = {
	hospitals: [],
};

AccountCreationFormHospitalsPart.propTypes = {
	hospitals: PropTypes.array,
};

const mapStateToProps = state => ({
	hospitals: state.hospitalsReducer.hospitals,
});

const mapDispatchToProps = dispatch => ({
	getAllHospitals: () => dispatch(getAllHospitalsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreationFormHospitalsPart);
