import { IconButton as MuiIconButton, IconButtonProps } from "@mui/material";

interface Props extends IconButtonProps {
  children: React.ReactNode;
}

// Used for times when you want a round button with just an icon (no text)
export const IconButton = ({ children, ...props }: Props) => {
  return (
    <MuiIconButton color="primary" component="span" {...props}>
      {children}
    </MuiIconButton>
  );
};
