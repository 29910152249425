import { HOSPITALS } from './HospitalsActionTypes';

export const getAllHospitalsAction = params => ({
	type: HOSPITALS.GET_HOSPITALS,
	payload: params,
});

export const getAllHospitalsWithoutPaginationAction = () => ({
	type: HOSPITALS.GET_ALL_HOSPITALS,
});

export const getHospitalByIdAction = id => ({
	type: HOSPITALS.GET_HOSPITAL_BY_ID,
	payload: id,
});

export const createNewHospitalAction = hospitalData => ({
	type: HOSPITALS.CREATE_NEW_HOSPITAL,
	payload: hospitalData,
});

export const deleteHospitalAction = id => ({
	type: HOSPITALS.DELETE_HOSPITAL,
	payload: id,
});

export const updateHospitalAction = (id, data) => ({
	type: HOSPITALS.UPDATE_HOSPITAL,
	id,
	data,
});

export const getHospitalRoomsAction = payload => ({
	type: HOSPITALS.GET_HOSPITAL_ROOMS,
	payload,
});

export const createHospitalRoomAction = (hospitalId, roomData, departments) => ({
	type: HOSPITALS.CREATE_HOSPITAL_ROOM,
	hospitalId,
	roomData,
	departments,
});

export const getAllHospitalAdminsAction = hospitalId => ({
	type: HOSPITALS.HOSPITAL_GET_ALL_HOSPITAL_ADMINS,
	payload: hospitalId,
});

export const createHospitalUserAction = (userData, hospitalId) => ({
	type: HOSPITALS.CREATE_HOSPITAL_USER,
	userData,
	hospitalId,
});

export const deleteHospitalRoomAction = id => ({
	type: HOSPITALS.DELETE_HOSPITAL_ROOM,
	payload: id,
});

export const deleteHospitalDepartmentAction = id => ({
	type: HOSPITALS.DELETE_HOSPITAL_DEPARTMENT,
	payload: id,
});

export const getHospitalOrgSettingsByIdAction = id => ({
	type: HOSPITALS.GET_HOSPITAL_ORG_SETTINGS_BY_ID,
	payload: id,
});

export const toggleMfaOrgLevelSettings = (payload) => ({
	type: HOSPITALS.TOGGLE_MFA_ORG_LEVEL_SETTINGS,
	payload
});
