export const APP_MODE = "portal";

const DOMAIN = `${process.env.REACT_APP_API_URL}`;
const SPLUNK_LOGGING_TOKEN = `${process.env.REACT_APP_SPLUNK_LOGGING_TOKEN}`;
const SPLUNK_LOGGING_URL = `${process.env.REACT_APP_SPLUNK_LOGGING_URL}`;
export const NETWORK_DIAGNOSTICS_URL = `${process.env.REACT_APP_NETWORK_DIAGNOSTICS_URL}`;

export const SPLUNK_CONFIG = {
  token: SPLUNK_LOGGING_TOKEN,
  url: SPLUNK_LOGGING_URL,
};

export const SPLUNK_URL_FALLBACK =
  "https://splunk.internal.avail.io:443/services/collector";

export const ENDPOINTS = {
  DOMAIN,
  CHECK_TOKEN: DOMAIN + "/auth/oauth/check_token",
  REFRESH_TOKEN: DOMAIN + "/auth/token/refresh",
  NETWORK_QUALITY_POLLING: "http://localhost:3333/network-quality",
  BILLING_CALL_QUALITY: "http://localhost:3333/call-quality",
  IMAGE_FREEZE_FRAME: "http://localhost:3333/freeze-frame",
};

export const VALID_CAPTURERS = ["AV.io", "UVCHDCAP"];

export const NETWORK_QUALITY_CHECK_FREQUENCY = 10; // seconds
export const NETWORK_QUALITY_POLLING_FREQUENCY = 60; // seconds
export const NETWORK_QUALITY_CONFIG_LOCAL_VERBOSITY = 1; // LocalParticipant [1 - 3]
export const NETWORK_QUALITY_CONFIG_REMOTE_VERBOSITY = 3; // RemoteParticipants [0 - 3]

export const TWILIO_REFRESH_TOKEN_TIME_MINS = 3 * 60 + 30; // 3.5 hrs
export const AVAIL_ACCESS_TOKEN_TIME_MINS = 3 * 60 + 25; // 5 minutes before Twilio to prevent collision

export const BAD_NETWORK_THRESHOLD_LEVEL = 2; // Twilio has a score 0-5

export const stylusColorMapping = {
  green: "#1ac451",
  red: "#f34949",
  blue: "#0a7dcd",
  yellow: "#dab80f",
  "": "",
};

export const MAX_ANNOTATION_POINTS: number =
  parseInt(`${process.env.REACT_APP_MAX_ANNOTATION_POINTS}`, 10) || 20;

export const AV_FREEZE_FRAME_IMAGE_QUALITY: number =
  parseFloat(`${process.env.REACT_APP_AV_FREEZE_FRAME_IMAGE_QUALITY}`) || 0.3;

export const AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB: number =
  parseInt(`${process.env.REACT_APP_AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB}`, 10) ||
  12;

export const PAN_DELTA: number =
  parseFloat(`${process.env.REACT_APP_PAN_DELTA}`) || 0.02;

export const TILT_DELTA: number =
  parseFloat(`${process.env.REACT_APP_TILT_DELTA}`) || 0.02;

export const AVAIL_LOG_ENV = `${process.env.REACT_APP_AVAIL_LOG_ENV}` || "dev";

export const AVAIL_DEVICE_INSTANCE_ID_KEY = "avail-device-instance-id";

export const MULTI_PARTY_LAUNCH_MODE = "MP";

export const P2P_LAUNCH_MODE = "P2P";

export const HOST_PIP_VIDEO_LOG_MESSAGE =
  "Host PiP video dimensions did change: ";

export const CONSOLE_VIDEO_LOG_MESSAGE =
  "Console video dimensions did change: ";
