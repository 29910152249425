import { call, put, select, take, takeLatest } from "redux-saga/effects";

import { eventChannel } from "@redux-saga/core";

import { logout } from "avail-web-ui/services/UserSessionService";

import { debounce } from "../utils";
import { setShowAlertAction, startIdleTimerAction } from "./IdleSlice";

// Current requirements on portal are as follows:
// - user inactive for 25 minutes
// - alert is displayed for 5 more minutes, warning user they will be logged out if they stay inactive amounting to 30 minutes
// For now, the user will just be logged out after 30 minutes without warning
const USER_IDLE_THRESHOLD = 30; // minute

export default function* idleSaga() {
  yield takeLatest(startIdleTimerAction.toString(), startIdleTimer);
}

function* startIdleTimer() {
  const channel = yield call(subscribeToTimer);
  while (true) {
    const action = yield take(channel);

    if (action.type === "LOGOUT_REQUEST") {
      const { meetingInfo } = yield select((state) => state.meeting);

      // If the user is in a meeting, they shouldn't be logged out.
      // mmetingInfo is null when no meeting is in progress
      if (!meetingInfo) {
        logout();

        const loginLink = `${window.location.origin}/login`;
        location.replace(loginLink); // Why? See `EventsView.tsx` L#139
      }
    }

    yield put(action);
  }
}

function* subscribeToTimer() {
  return eventChannel((emit) => {
    let time;

    function showAlert() {
      // TODO: this should handle the popup alert/warning showing up after 25 minutes of inactivity
      // emit(setShowAlertAction(true));

      emit({ type: "LOGOUT_REQUEST" });
    }

    function resetTimer() {
      emit(setShowAlertAction(false));
      clearInterval(time);
      time = setInterval(showAlert, USER_IDLE_THRESHOLD * 60 * 1000);
    }

    // DOM Events
    window.onload = resetTimer;
    // These events will trigger the timer to reset back to 0
    const events = [
      "onmousemove",
      "onkeypress",
      "onmousedown",
      "ontouchstart",
      "onclick",
    ];
    events.forEach((event) => {
      document[event] = debounce(250, resetTimer);
    });

    return () => {};
  });
}
