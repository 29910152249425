export const getInitialValuesForDepartmentProcedures = (state) => {
	const { departmentProcedures } = state.departmentReducer;
	const procedures = {};
	if (departmentProcedures && departmentProcedures.length) {
		departmentProcedures.map((procedure) => {
				Object.assign(procedures, { [procedure.id]: true });
			});
		return {
			procedures,
		};
	}
};

export const getInitialValuesForRoomProfile = (state) => {
	const { roomDepartments } = state.departmentReducer;
	const { room } = state.departmentReducer;
	let departments;
	if (room) {
		if (roomDepartments && roomDepartments.length) {
			departments = roomDepartments.map(roomDepartment => roomDepartment.id);
		}
		return {
			roomData: {
				name: room.name,
				type: room.type,
			},
			departments,
		};
	}
};
