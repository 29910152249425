import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';

class VendorsHospitalRow extends PureComponent {
	render() {
		const { name } = this.props.rowData;
		return (
			<Table.Row>
				<Table.Cell title={name}>
					<div className="display--flex">
						<span>{name}</span>
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}

export default VendorsHospitalRow;
