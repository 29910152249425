import moment from 'moment';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import resources from '../../../../../Common/Constants/resources';
import { notificationService } from '../../../../../Common/Services';
import ApiHelper from '../../../../../Common/Services/ApiHelper';
import { setCallHistoryAction, setCallHistoryPaginationAction, setToAndFromAction } from './CallDetailRecordActions';
import { CDR } from './CallDetailRecordActionTypes';

function* getCallHistory(action) {
	yield put(setToAndFromAction({ to: action.payload.to, from: action.payload.from }));

	try {
		const response = yield call(ApiHelper.get, 'callevent', resources.history, {
			...action.payload,
			from: moment(action.payload.from).format(),
			to: moment(action.payload.to).format(),
		});

		yield all([
			put(setCallHistoryAction(response.content)),
			put(setCallHistoryPaginationAction(response)),
		]);
		if(response.content.length === 0){
			notificationService.warning("No records found");
		}
	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* vendorsSaga() {
	yield takeLatest(CDR.GET_CALL_HISTORY, getCallHistory);
}
