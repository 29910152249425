// eslint-disable-next-line camelcase
import moment from 'moment';
import { NConfig } from 'availkit-js';
import React from 'react';
import { connect } from 'react-redux';
import resources from '../../../../Common/Constants/resources';
import ApiHelper from '../../../../Common/Services/ApiHelper';
import { AvailKitService } from "src/services/AvailKitService";
import SecurityService from '../../../../Common/Services/securityService';
import { getUserMeetingTimeAction, getUserMissedCallsAction } from '../Scheduler/SchedulerActions';
import CallingNotification from './Components/CallingNotification/CallingNotification';
import IncomingCallNotification from './Components/IncomingCallNotification/IncomingCallNotification';
import {
	cancelCallbackAction,
	sendMeetingCallAcceptAction,
	sendMeetingCallRejectAction,
	setCallBackAction,
	setIncomingCallAction,
	setTwilioTokenAction,
	updateMeetingInfoAction,
	setCallingMeetingInfoAction
} from './MeetingActions';
import {
  setLocalNetworkDisrupted
} from '../../HomeActions';
import { CALLBACK_MODES } from './MeetingReducer';
import { isSafari } from 'src/utils/browsers.ts';
import UserSessionService from 'src/services/UserSessionService';
import { withFeatureFlag } from "src/hooks/useFeatureFlag";
import { logger } from "src/logging/logger.ts"

class Meeting extends React.PureComponent {

	state = {
		pubnubChannel: '',
		loginId: '',
		meetingToken: null
	};

	componentDidUpdate(prevProps, prevState) {
		const { pubnubChannel, loginId } = this.state;
		const { currentUser } = this.props;

		/* If user has logged in and AvailKit is not configured */
		if(currentUser && !pubnubChannel || !loginId) {
			console.log("Configuring AvailKit");
			this.configureAvailKit();
		}
	}

	componentDidMount() {
    this.pubnubStatusCallback = this.pubnubStatusCallback.bind(this);
    this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    this.configureAvailKit();
	}

  configureAvailKit() {
		const refreshToken = SecurityService.getRefreshToken();
		if (!refreshToken) {
			return;
		}

		const params = {
			token: refreshToken,
		};

		/* Make a refresh_token call */
		ApiHelper.post('auth', resources.refreshToken, params)
			.then((newTokenResponse) => {
				const nConfig = new NConfig();
				nConfig.pubnubPublishKey = newTokenResponse.pubnub_publ_key;
				nConfig.pubnubSubscribeKey = newTokenResponse.pubnub_subs_key;
				nConfig.pubnubAuthKey = newTokenResponse.pubnub_auth_key;
				let pubnubChannelId = newTokenResponse.pubnub_channel;
				if (
					!newTokenResponse ||
					!newTokenResponse.pubnub_subs_key ||
					!newTokenResponse.pubnub_publ_key
				) {
					console.error('Keys unavailable. Skipping pubnub config.');
					return;
				}
				if (!pubnubChannelId) {
					pubnubChannelId = newTokenResponse.userId;
				}
				this.setState({
					pubnubChannel: pubnubChannelId,
					loginId: newTokenResponse.login_id,
				});

				const presenceUUID = this.getPresenceUUID();
				const availKit = AvailKitService.getInstance(nConfig, presenceUUID);

				// Join user channel
				availKit.eventService.join(pubnubChannelId);

        window.addEventListener('online',  this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);

				// Listen for events on this component
				availKit.telephonyService.addEventListener(this);
        availKit.eventService.setStatusCallback( this.pubnubStatusCallback );
			})
			.catch((err) => {
				console.error(err);
				return err;
		});
  }


  updateOnlineStatus() {
    console.log("Updating online status " + navigator.onLine);
    if(navigator.offLine) {
      this.props.setLocalNetworkDisrupted(true);
    }
  }

  joinUserPubnubChannel() {
    try {
      // Make an attempt to re-join the pubnubchannel
      const { pubnubChannel} = this.state;
      if(pubnubChannel) {
        const availKit = AvailKitService.instance;
        // Join user channel
        console.log("Making an attempt to rejoin the user channel " + pubnubChannel);
        availKit.eventService.join(pubnubChannel);
      }
    } catch (e) {
      console.log("Error while re-joining");
    }
  }

  pubnubStatusCallback(e) {
    try {
    const theMsg = JSON.stringify( e);

    switch( e?.category) {
      case "PNConnectedCategory" :
        console.log(`PubNub: connection established: ${theMsg}`);
        // Don't update LocalNetworkDisrupted, we want to force user to click
        // reload in the banner
        break;
      case "PNNetworkIssuesCategory" :
        console.info(`PubNub: had issue on network: ${theMsg}`);
        this.props.setLocalNetworkDisrupted(true);
        break;
      case "PNNetworkDownCategory" :
        console.error(`PubNub: network connection is DOWN: ${theMsg}`);
        this.props.setLocalNetworkDisrupted(true);
        break;
      case "PNTimeoutCategory" :
        console.error(`PubNub: timed out connecting... ${theMsg}`);
        this.props.setLocalNetworkDisrupted(true);
        break;
      case "PNNetworkUpCategory" :
        console.info(`PubNub: network is back up`);
        /* If the network is back up, try to establish join user's pubnub channel */
        this.joinUserPubnubChannel();
        break;
      case "PNMalformedResponseCategory" :
        console.warn(`PubNub: failed to parse JSON: ${theMsg}`);
        break
      case "PNReconnectedCategory" :
        console.warn(`PubNub: reconnected to network`);
        break;
      case "PNUnknownCategory" :
        console.warn(`PubNub: encountered unknown error: ${theMsg}`);
        break;
      default:
        console.warn(`PubNub: reported an unrecognized status [${e.category}]: ${theMsg}`);
       break;
    }
  } catch(e) {
    console.error(e);
  }
 }

	getPresenceUUID() {
		const loginId = this.props.currentUser.loginId;
		const deviceId = UserSessionService.getDeviceId();

		return `USER-${loginId}-WEBI-${deviceId.replaceAll("-", "_")}`;
	}

	onReceiveIncomingServerCall = (service, event) => {
		// a call is already in progress, do something;

		// Inflate InccomingCallNotification component
		this.props.updateMeetingInfo(event);
		this.props.setIncomingCall(true); // This ultimately makes the component re-render, so make sure for meetingInfo to be populated beforehand
	};
	// eslint-disable-next-line react/sort-comp
	didAcceptCall = () => {
		this.props.setIncomingCall(false);
	};
	didDeclineCall = () => {
		this.props.setIncomingCall(false);
		this.props.updateMeetingInfo(null);
		this.getMeetingEvents(); // triggers re-render on MissedCalls comp
	};
	didJoinSession = () => {
		this.props.setIncomingCall(false);
		this.props.updateMeetingInfo(null);
		this.getMeetingEvents(); // triggers re-render on MissedCalls comp
	};
	onReceiveHangup = () => {
		this.props.setIncomingCall(false);
		this.props.updateMeetingInfo(null);
		this.getMeetingEvents(); // triggers re-render on MissedCalls comp
	};
	onReceiveHangupV2 = () => {
		this.props.setIncomingCall(false);
		this.getMeetingEvents(); // triggers re-render on MissedCalls comp
		/* Ref: SOFT-275 */
		if(this.props.callbackMode === CALLBACK_MODES.consoleaccepted) {
			// 1. Clone the meeting Info, so that other actions aren't affected.
			// 2. Release availability for the user.
			const meetingInfo = JSON.parse(JSON.stringify(this.props.outgoingMeetingInfo))
			this.props.cancelCallback(meetingInfo);
			this.props.setTwilioToken("");
			this.props.setCallBackAction("");
		}
		this.props.updateMeetingInfo(null);
	};
	onEndTelephonySession = () => {};
	onReceiveUserPreset = () => {};
  onReceiveVideoSourcePresetLayout = (service, event) => {};
	onReceiveIncomingCall = () => {
		this.props.setIncomingCall(false);
	};
	onReceiveCameraChange = () => {};
	onReceiveCameraConfig = () => {};
	onReceiveCameraMove = () => {};
	onReceiveCameraZoom = () => {};
	onReceiveTextMessage = () => {};
	onReceiveCameraReset = () => {};
	onReceivePreset = () => {};
	onEstablishPreset = () => {};
	onAddActor = () => {};
	// eslint-disable-next-line react/sort-comp
	didEndTelephonySession = () => {
		this.props.updateMeetingInfo(null);
	};
	didBeginTelephonySession = () => {};
	didFailToEstablishSessionForUser = () => {};

  onReceivePNTimeoutConsoleEvent = (service, event) => {
    logger().info(
      `Received PubNub Timeout event from Console ${JSON.stringify(event)}`
    );
  };
  onReceivePNTimeoutUserEvent = (service, event) => {
    logger().info(
      `Received PubNub Timeout event from User ${JSON.stringify(event)}`
    );
  };
  onReceivePNTimeoutWebcallEvent = (service, event) => {
    logger().info(
      `Received PubNub Timeout event from Webcall ${JSON.stringify(event)}`
    );
  };

	onReceiveConsoleCallbackIncoming = (service, event) => {}
	onReceiveConsoleCallbackAcceptEvent = (service, event) => {
		const { twilioToken } = this.props;
		this.getMeetingEvents();

		/* Make sure the callback was initiated from the current tab. */
		if(!twilioToken || twilioToken === "") return;

		const callBackEvent = { event: {...event}, callback: true, twilioToken};
		this.props.setCallBackAction(CALLBACK_MODES.consoleaccepted);
		this.setState({meetingToken: btoa(JSON.stringify(callBackEvent))});
		this.props.setTwilioToken("");
	};

	onReceiveConsoleCallbackDeclineEvent = (service, event) => {
		if(event && event.attempts > 1) return;

		if (this.props.outgoingMeetingInfo) {
			this.props.setCallBackAction(CALLBACK_MODES.consoledeclined);
			this.props.cancelCallback(this.props.outgoingMeetingInfo);
		}
		this.getMeetingEvents();
	};

	onCallAccept = () => {
		this.getMeetingEvents();
		this.props.sendMeetingCallAccept({
			pubnubChannel: this.state.pubnubChannel,
			loginId: this.state.loginId,
		});
		this.props.setIncomingCall(false);

		const meetingToken = btoa(JSON.stringify(this.props.meetingInfo));
		window.open(
			`${window.location.origin}${this.props.beaconFlag}${meetingToken}`,
		);
		this.props.setTwilioToken('');
	};

	onCallReject = () => {
		this.getMeetingEvents();
		this.props.sendMeetingCallReject({
			pubnubChannel: this.state.pubnubChannel,
			loginId: this.state.loginId,
		});
		this.props.setIncomingCall(false);
		this.props.updateMeetingInfo(null);
	};

	onCallCancel = () => {
		if (this.props.outgoingMeetingInfo) {
			this.props.cancelCallback(this.props.outgoingMeetingInfo);
		}

		this.props.setCallBackAction('');
		this.getMeetingEvents();
	};

	onCallBackDialogClose = () => {
		this.props.setCallBackAction('');
		this.props.setCallingMeetingInfoAction(null);
		this.getMeetingEvents();
	}

	getMeetingEvents = () => {
		const currentDate = new Date(Date.now());
		const startDay = new Date(currentDate);
		startDay.setDate(startDay.getDate() - 365);
		const startDate = `${moment(new Date(startDay)).format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
		const endDate = `${moment(new Date(currentDate)).add(3, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;

		this.props.getUserMeetingTime(this.props.currentUser.id, startDate, endDate);
		this.props.getUserMissedCalls(this.props.currentUser.id, startDate, endDate);
	}

	onCallbackJoinCall = () => {
		const meetingToken = this.state.meetingToken;
		if(meetingToken) {
			window.open(`${window.location.origin}${this.props.beaconFlag}${meetingToken}`);

      // SOFT-2968 - resolves issue where Safari plays sounds even
      // when audio tags have unmounted
      if (isSafari(window.navigator.userAgent)) {
        window.location.reload();
        console.warn("Refreshing page ...");
      }
			this.setState({meetingToken: null});
			this.onCallBackDialogClose();
		}
	}

	render() {
		const { isCallIncoming, callbackMode, meetingInfo, outgoingMeetingInfo } = this.props;
		let onCancel = this.onCallCancel;

		switch(callbackMode) {
			case CALLBACK_MODES.calling:
				onCancel = this.onCallCancel;
				break;
			case CALLBACK_MODES.consoleaccepted:
				onCancel = this.onCallbackJoinCall;
				break;
			case CALLBACK_MODES.consolebusy:
			case CALLBACK_MODES.consoledeclined:
			case CALLBACK_MODES.consoleunavailable:
				onCancel = this.onCallBackDialogClose;
				break;
			default:
				onCancel = this.onCallBackDialogClose;
				break;
		}

		const shoulShowCallDialog =  callbackMode === CALLBACK_MODES.calling
			|| callbackMode === CALLBACK_MODES.consolebusy
			|| callbackMode === CALLBACK_MODES.consoledeclined
			|| callbackMode === CALLBACK_MODES.consoleunavailable
			|| callbackMode === CALLBACK_MODES.consoleaccepted;

		return (
			<>
				{shoulShowCallDialog && (
					<CallingNotification
						callInfo={outgoingMeetingInfo}
						onCancel={onCancel}
						callbackMode={callbackMode}
					/>
				)}

				{isCallIncoming && (
					<IncomingCallNotification
						callInfo={meetingInfo}
						onAccept={this.onCallAccept}
						onReject={this.onCallReject}
					/>
				)}
			</>
		);
	}
}

const mapStateToProps = state => ({
	callbackMode: state.meetingReducer.callbackMode,
	currentUser: state.homeReducer.currentUser,
	isCallIncoming: state.meetingReducer.isCallIncoming,
	meetingInfo: state.meetingReducer.meetingInfo,
	outgoingMeetingInfo: state.meetingReducer.outgoingMeetingInfo,
	twilioToken: state.meetingReducer.twilioToken,
});

const mapDispatchToProps = dispatch => ({
  setIncomingCall: data => dispatch(setIncomingCallAction(data)),
  setCallingMeetingInfoAction: data => dispatch(setCallingMeetingInfoAction(data)),
	setCallBackAction: string => dispatch(setCallBackAction(string)),
	setTwilioToken: token => dispatch(setTwilioTokenAction(token)),
	updateMeetingInfo: info => dispatch(updateMeetingInfoAction(info)),
	sendMeetingCallAccept: data =>
		dispatch(sendMeetingCallAcceptAction(data)),
	sendMeetingCallReject: data =>
		dispatch(sendMeetingCallRejectAction(data)),
	cancelCallback: info => dispatch(cancelCallbackAction(info)),
	getUserMeetingTime: (userId, from, to) => dispatch(getUserMeetingTimeAction(userId, from, to)),
	getUserMissedCalls: (userId, from, to) => dispatch(getUserMissedCallsAction(userId, from, to)),
  setLocalNetworkDisrupted: (localNetworkDisrupted) => dispatch(setLocalNetworkDisrupted(localNetworkDisrupted))
});

export default connect(mapStateToProps, mapDispatchToProps)(withFeatureFlag(Meeting));
