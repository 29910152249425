import { Fragment } from "react";

import clsx from "clsx";

import CircleIcon from "@mui/icons-material/Circle";

import { Badge, Spacer } from "src/components/Badge/Badge";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectBadges } from "src/domains/Beacon/store/ui/selectors";
import { generateBadge } from "src/domains/Beacon/store/ui/types";
import { UIBadgeTypes } from "src/domains/Beacon/store/ui/types";

import styles from "./styles.scss";

export const BadgeSystem = () => {
  const badges = useAppSelector(selectBadges);
  const noBadges = !Object.values(badges).includes(true);
  const badgesToRender = Object.keys(badges).filter((key) => {
    return badges[key] === true;
  });

  return (
    <div className={clsx(styles.root)} data-test-id="badge-system">
      <CircleIcon
        classes={{
          root: clsx(styles.dot, { [styles.hidden]: noBadges }),
        }}
        data-test-id={"badge-system-circle"}
      />

      {noBadges
        ? null
        : badgesToRender.map((badgeToRender, i) => {
            const badge = generateBadge(UIBadgeTypes[badgeToRender]);
            const nextBadge = badgeToRender[i + 1];
            return (
              // keys need to be on the top level component when doing a map
              <Fragment key={`${badge.type}-badge`}>
                <Badge content={badge.content} data-test-id={badge.type} />
                {nextBadge && <Spacer key={`${badge.type}-spacer`} />}
              </Fragment>
            );
          })}
    </div>
  );
};
