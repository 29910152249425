import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Icon from '../../../../../../Common/Components/Icon/Icon';
/* Icons & Style & Images */
import userService from '../../../../../../Common/Services/userService';
/* Components */
import { Pill, ProfileImage } from '../../../../Common';
import { Button } from '../../../../../../Common/Components';
/* Helpers */
import userTypes from '../../../../../../Common/Constants/userTypes';
import DropdownComponent from '../../../../Common/DropdownComponent/DropdownComponent';
import { getAllUsersAction, selectUserAction } from '../../UsersActions';
import './UserTableRow.scss';
import Can from '../../../../../../Common/Acl/AccessControl';
import { MEMBER_TYPE_DISPLAY_NAMES } from "src/constants/users";
import { locations } from "src/utils/locations";
import { upgradeTrialUser } from 'src/services/ApiClient/users'
import { isTrialUser } from "src/utils/user";
import { PersonAddAltRounded } from "@mui/icons-material";
import { notificationService } from '../../../../../../Common/Services';
import { isAvailAdminUser } from "src/utils/user.ts";

class UserTableRow extends PureComponent {
	redirect = () => {
		this.props.history.push(`/users/profile?userId=${this.props.rowData.employee.id}`);
	};

	openDeleteUserModal = () => {
		this.props.selectUser(this.props.rowData.employee);
		this.props.openModal(this.props.rowData.employee.id);
	};

  goToUsagePage = () => {
    const userId = this.props.rowData.employee.id;

    // Re-direct to usage page with the userId in the URL
    this.props.history.push(`${locations.usage}?userId=${userId}`);
	};

  upgradeTrialUser = async () => {
    const { employee } = this.props.rowData;

    try {
      await upgradeTrialUser(employee.id, {
        ...employee,
        memberType: 'MEMBER'
      });

      notificationService.success('You successfully updated user to full member.');
    } catch {
      notificationService.error("User couldn't be updated to member.");
    }

    // Update users list on table
    this.props.getAllUsers({ size: 10, page: 0 });
  }

	openReinviteUserModal = () => {
		this.props.selectUser(this.props.rowData.employee);
		this.props.openReinvitationModal(this.props.rowData.employee.id);
	};

	redirectToScheduler = () => {
		this.props.history.push({
			pathname: `/scheduler/${this.props.rowData.employee.id}`,
		});
	};

	renderDropdownOptions = userStatus => {
    const { employee } = this.props.rowData;
    const isGuest = employee.userTypes.includes('GUEST');

    // current user: check for avail admin to add `Usage` option
    const { userType: currentUserType } = this.props.currentUser;
    const isCurrentUserAvailAdmin = currentUserType === "AVAIL_ADMIN";

    const dropDownOptions = (
      userStatus !== 'DELETED' ? (userStatus !== 'ACTIVE' && !isGuest) ?
        [
          {
            className: 'dropdown__menu-item--green',
            label: 'Invitation User',
            text: 'Reinvite',
            action: this.openReinviteUserModal,
            icon: 'icon-person-outline',
          },
          {
            className: 'dropdown__menu-item--red',
            label: 'Delete user',
            text: 'Delete',
            action: this.openDeleteUserModal,
            icon: 'icon-trash-2-outline',
          },
        ] : [
          {
            className: 'dropdown__menu-item--red',
            label: 'Delete user',
            text: 'Delete',
            action: this.openDeleteUserModal,
            icon: 'icon-trash-2-outline',
          }
        ] : []
    );

    // Only AVAIL_ADMINS shouls see the 'Usage' and 'Upgrade Account' options for other trial users
    if (isCurrentUserAvailAdmin && isTrialUser(employee)) {
      dropDownOptions.unshift({
        className: 'dropdown__menu-item--blue',
        label: 'Usage',
        text: 'Usage',
        action: this.goToUsagePage,
        icon: 'icon-clock-outline',
      }, {
        className: 'dropdown_menu-item-blue',
        label: 'Upgrade user',
        text: 'Upgrade Account',
        action: this.upgradeTrialUser,
        IconComponent: PersonAddAltRounded,
      });
    }

    return dropDownOptions;
  };

	render() {
		const { employee, organization } = this.props.rowData;
		const isButtonDisabled = employee.userStatus === 'DELETED';
		return (
			<Table.Row
				className={classNames({
					'table__row--inactive': employee.userStatus === 'DELETED',
				})}
			>
				<Table.Cell onClick={this.redirect} title={[`${employee.firstName} ${employee.lastName}`].join(' ')}>
					<div className="table__name-wrapper display--flex">
						<div className="position--relative">
							<ProfileImage
								size="sm"
								className={`profile-image--${userTypes[employee.userTypes[0]].className} `}
								initials={userService.getUserInitials(employee.firstName, employee.lastName)}
							/>
						</div>
						<span className="table__username users__table-username">{`${employee.firstName} ${employee.lastName}`}</span>
					</div>
				</Table.Cell>
				<Table.Cell title={[userTypes[employee.userTypes[0]].title].join(' ')}>
					<Pill
						isDisabled={employee.userStatus === 'DELETED'}
						class={`user-type--${userTypes[employee.userTypes[0]].className} `}
						title={userTypes[employee.userTypes[0]].title}
					/>
				</Table.Cell>
				<Table.Cell title={[employee.email].join(' ')}>{employee.email}</Table.Cell>
				<Table.Cell title={employee.userStatus && employee.userStatus}>
					{employee.userStatus && employee.userStatus === 'ACTIVE' ? employee.userStatus : 'PENDING'}
				</Table.Cell>
				<Table.Cell title={[organization.name].join(' ')}>
					{userService.checkIsIndividualSalesRep(employee.userTypes[0]) ? '/' : organization.name}
				</Table.Cell>
				{isAvailAdminUser(this.props.currentUser.userTypes) && <Table.Cell title={employee.memberType}>
					{MEMBER_TYPE_DISPLAY_NAMES[employee.memberType] ?? ""}
				</Table.Cell>}
				<Table.Cell className="table__action-table-cell">
					<div className="flex-direction--row justify-content--flex-end align--center">
						<Can userTypes={employee.userTypes} action="have" context="scheduler">
							<Button
								disabled={isButtonDisabled}
								className="user-profile__calendar-button padding--none table__action-wrapper button-with-icon"
								onClick={this.redirectToScheduler}
							>
								<Icon className="icon-calendar-outline icon--dark-blue avail__icon--20" />
								<span className="user-profile__calendar-button-title">View calendar</span>
							</Button>
						</Can>
						<DropdownComponent dropdownOptions={this.renderDropdownOptions(employee.userStatus)} />
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}

UserTableRow.propTypes = {};

const mapStateToProps = state => ({
	currentUser: state.homeReducer.currentUser,
});

const mapDispatchToProps = dispatch => ({
	selectUser: id => dispatch(selectUserAction(id)),
  getAllUsers: params => dispatch(getAllUsersAction(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserTableRow));
