import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { Button } from '../../../../../../../Common/Components';
import ModalComponent from '../../../../../../../Common/Components/Modal/Modal';
import { urlService } from '../../../../../../../Common/Services';
// import AccessControlService from '../../../../../../../Common/Services/accessControlService';
import { EntityProfileSidebar, ProfileBreadcrumbs, TabsWithMenu } from '../../../../../Common';
import { getcurrentUserTypes } from '../../../../../HomeSelectors';
import { getCurrentBrandAction, setCurrentBrandAction, deleteBrandAction } from '../../../../Brands/BrandsActions';
import { getProfileInitialDataAction } from '../../VendorsActions';
// import Billing from './../../../Billing/Billing';
import BusinessUnitsList from './Components/BusinessUnitsList/BusinessUnitsList';
import BusinessUnitUsers from './Components/BusinessUnitUsers/BusinessUnitUsers';
import Domains from './Components/Domains/Domains';
import GeneralInfo from './Components/GeneralInfo/GeneralInfo';
import TherapiesAndProcedures from './Components/TherapiesAndProcedures/TherapiesAndProcedures';
import VendorsHospitals from './Components/VendorsHospitals/VendorsHospitals';
import './VendorProfile.scss';
import BUSecurity from "src/domains/BusinessUnits/components/BUSecurity/BUSecurity";
import { TrialSignUpFlagForm } from "src/domains/BusinessUnits/components/TrialSignUpFlagForm";

class VendorProfile extends PureComponent {
	state = {
		activeTabIndex: 0,
    currentActiveTab: '',
		isDeleteModalOpened: false,
		lastActiveTabIndex: 0,
	}

	componentDidMount() {
		const { location } = this.props;

		this.props.getProfileInitialData(location);

		const brandId = urlService.getQueryParam(location, 'brId');
		this.props.getCurrentBrand(brandId);
	}

	componentWillUnmount() {
		this.props.setCurrentBrand(null);
	}

	onClickDeleteHandler = () => {
		this.toggleDeleteModal();
	}

	onDeleteBrandHandler = () => {
		this.props.deleteBrand(this.props.currentBrand.id);
		this.toggleDeleteModal();
	};

	handleTabChange = async (e, data) => {
		this.setState({
			lastActiveTabIndex: this.state.activeTabIndex,
		}, () => {
			this.setState({ activeTabIndex: data.activeIndex });
		});
	};

	toggleDeleteModal = (brand = {}) => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
			brand,
		});
	};

	renderOptions = () => {
		const { currentBrand, selectedDistrict, businessUnit, location } = this.props;
		const districtId = urlService.getQueryParam(location, 'dId');
		const businessUnitId = urlService.getQueryParam(location, 'buId');

		const menuOptions = [{
			title: currentBrand.name,
			key: currentBrand.name,
			className: businessUnitId ? null : 'profile-breadcrumbs__menu-item--active',
			to: `/vendors/profile?brId=${currentBrand.id}`,
			onClick: () => this.setState({ activeTabIndex: 0 }),
		}];

		if (businessUnitId) {
			if (businessUnit) {
        this.setState({ currentActiveTab: businessUnit.name })
				menuOptions.push({
					title: businessUnit.name,
					label: 'Business Unit',
					key: businessUnit.name,
					className: districtId ? null : 'profile-breadcrumbs__menu-item--active',
					to: `/vendors/profile?brId=${currentBrand.id}&buId=${businessUnit.id}`,
					onClick: () => this.setState({ activeTabIndex: 0 }),
				});
			}
		}

		if (districtId) {
			if (selectedDistrict) {
        this.setState({ currentActiveTab: selectedDistrict.name })
				menuOptions.push({
					title: selectedDistrict.name,
					label: 'District',
					key: selectedDistrict.name,
					className: 'profile-breadcrumbs__menu-item--active',
					to: `/vendors/profile?brId=${currentBrand.id}&buId=${selectedDistrict.parentId}&dId=${selectedDistrict.id}`,
					onClick: () => this.setState({ activeTabIndex: 0 }),
				});
			}
		}

		return menuOptions;
	};

	renderTabs = () => {
		const {
			businessUnitsList,
			businessUnit,
			businessUnitDistricts,
			// currentUserTypes,
			// userOrganisation,
			selectedDistrict,
			location,
			currentBrand,
			t,
		} = this.props;

		// TODO need to find a way to organise this options array
		let profileOptions = [{
			menuItem: 'General Info',
			render: () => <GeneralInfo profileType="Company" data={currentBrand} />,
		}, {
			menuItem: 'Domains',
			render: () => <Domains profileType="Company" data={currentBrand} />,
		}, {
			menuItem: 'Business Units',
			render: () =>
				<BusinessUnitsList businessUnitType="Business Unit" title="Business Unit Name" data={businessUnitsList} />,
		}, {
			menuItem: 'Users',
			render: () =>
				<BusinessUnitUsers businessUnitId={currentBrand.id} businessUnitType="brand" />,
		}];
		// {
		// 	menuItem: (AccessControlService.doesUserHaveAccess(currentUserTypes, 'read', 'vendorBilling', {
		// 		userOrganisationId: userOrganisation ? userOrganisation.id : null,
		// 		businessUnitId: selectedDistrict ? selectedDistrict.id : null,
		// 	}) ? 'Billing' : null),
		// 	render: () => (
		// 		<Billing
		// 			businessUnitId={currentBrand.id}
		// 			businessUnitType="BRAND"
		// 			businessUnitName={currentBrand.name}
		// 		/>
		// 	),
		// }];

		if (urlService.getQueryParam(location, 'buId')) {
			profileOptions = [{
				menuItem: 'General Info',
				render: () => <GeneralInfo profileType="Business Unit" data={businessUnit} />,
			}, {
				menuItem: 'Districts',
				render: () =>
					<BusinessUnitsList businessUnitType="District" title="District name" data={businessUnitDistricts} />,
			}, {
				menuItem: 'Users',
				render: () =>
					<BusinessUnitUsers businessUnitId={businessUnit.id} businessUnitType="businessUnit" />,
			}, {
				menuItem: 'Therapies & Procedures',
				render: () => <TherapiesAndProcedures vendorId={businessUnit.id} />,
			}, {
				menuItem: t('features.org.hospital.name_plural'),
				render: () => <VendorsHospitals businessUnitId={businessUnit.id} />,
			// }, {
			// 	menuItem: (AccessControlService.doesUserHaveAccess(currentUserTypes, 'read', 'vendorBilling', {
			// 		userOrganisationId: userOrganisation ? userOrganisation.id : null,
			// 		businessUnitId: businessUnit ? businessUnit.id : null,
			// 	}) ? 'Billing' : null),
			// 	render: () =>
			// 		<Billing businessUnitId={businessUnit.id} businessUnitType="BUSINESS_UNIT" businessUnitName={businessUnit.name} />,
			}
    ];
		}
		
		if (urlService.getQueryParam(location, 'dId')) {
			profileOptions = [{
				menuItem: 'General Info',
				render: () => <GeneralInfo profileType="District" data={selectedDistrict} />,
			}, {
				menuItem: 'Users',
				render: () =>
					<BusinessUnitUsers businessUnitId={selectedDistrict.id} businessUnitType="district" />,
			}, {
				menuItem: 'Therapies & Procedures',
				render: () =>
					<TherapiesAndProcedures vendorId={businessUnit.id} districtId={selectedDistrict.id} />,
			}, {
				menuItem: t('features.org.hospital.name_plural'),
				render: () => <VendorsHospitals businessUnitId={selectedDistrict.id} />,
			// }, {
			// 	menuItem: (AccessControlService.doesUserHaveAccess(currentUserTypes, 'read', 'vendorBilling', {
			// 		userOrganisationId: userOrganisation ? userOrganisation.id : null,
			// 		businessUnitId: selectedDistrict ? selectedDistrict.id : null,
			// 	}) ? 'Billing' : null),
			// 	render: () =>
			// 		<Billing businessUnitId={selectedDistrict.id} businessUnitType="DISTRICT" businessUnitName={selectedDistrict.name} />,
			}];
		}
		
		// TODO: use AccessControlService to check for view/read rights
		if (urlService.getQueryParam(location, 'buId') && 
			this.props.currentUserTypes.includes('VENDOR_ADMIN') &&
      this.state.currentActiveTab === this.props.userOrganisation?.name
		) {
			profileOptions.push({
				menuItem: 'Security',
				render: () => (
					<BUSecurity vendorId={selectedDistrict ? selectedDistrict?.id : businessUnit.id} />
				),
			});
		}

    // only avail admins can see the trial menu, thats for enabling the self sign up flag for this BU
    if (urlService.getQueryParam(location, 'buId') && 
			this.props.currentUserTypes.includes('AVAIL_ADMIN')
		) {
			profileOptions.push({
				menuItem: 'Trial',
				render: () =>
					<TrialSignUpFlagForm businessUnit={businessUnit} />,
			});
		}

		if (urlService.getQueryParam(location, 'brId') && !urlService.getQueryParam(location, 'buId')) {
			profileOptions.push({
				menuItem: (
					<Menu.Item key="del" onClick={this.onClickDeleteHandler}>
						<Button className="vendor-profile__delete-button">
							Delete Vendor
						</Button>
					</Menu.Item>
				),
				render: () => null,
			});
		}

		return profileOptions;
	};

	renderDeleteBrandModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onDeleteBrandHandler}
			onCancel={() => {
				this.toggleDeleteModal();
				this.setState({ activeTabIndex: this.state.lastActiveTabIndex });
			}}
			confirmText="Delete"
		>
			{
				this.state.brand && (
					<span>
						You are about to delete <span className="text--bold">{this.props.currentBrand.name}</span>.
						Would you like to proceed with this action?
					</span>
				)
			}
		</ModalComponent>
	);

	render() {
		const { currentBrand } = this.props;
		return (
			<div className="vendor-profile">
				{currentBrand && (
					<>
						<EntityProfileSidebar title="Organization Structure">
							{this.props.businessUnitsList &&
								<ProfileBreadcrumbs options={this.renderOptions()} />
							}
						</EntityProfileSidebar>
						<div className="vendor-profile__right-side">
							{this.props.businessUnitsList &&
								<TabsWithMenu
									options={this.renderTabs()}
									activeIndex={this.state.activeTabIndex}
									onTabChange={this.handleTabChange}
								/>
							}
						</div>
					</>
				)}

				{this.renderDeleteBrandModal()}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	businessUnit: state.vendorsReducer.selectedBusinessUnit,
	selectedDistrict: state.vendorsReducer.selectedDistrict,
	businessUnitsList: state.vendorsReducer.businessUnitsPerBrand,
	businessUnitDistricts: state.vendorsReducer.businessUnitDistricts,
	therapies: state.departmentReducer.therapies,
	user: state.usersReducer.user,
	currentUserTypes: getcurrentUserTypes(state),
	currentUserId: state.homeReducer.currentUser.id,
	userOrganisation: state.usersReducer.userOrganisation,
	currentBrand: state.brandsReducer.currentBrand,
});

const mapDispatchToProps = dispatch => ({
	getProfileInitialData: url => dispatch(getProfileInitialDataAction(url)),
	getCurrentBrand: id => dispatch(getCurrentBrandAction(id)),
	setCurrentBrand: brand => dispatch(setCurrentBrandAction(brand)),
	deleteBrand: id => dispatch(deleteBrandAction(id)),
});

const VendorProfileWithTranslation = withTranslation()(VendorProfile);

export default connect(mapStateToProps, mapDispatchToProps)(VendorProfileWithTranslation);
