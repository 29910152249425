import { push } from 'connected-react-router';
import { put, takeLatest, call, all, select } from 'redux-saga/effects';

import resources from '../../../../Common/Constants/resources';
import { userSessionService } from '../../../../Common/Services';
import ApiHelper from '../../../../Common/Services/ApiHelper';
import notificationService from '../../../../Common/Services/notificationService';
import urlService from '../../../../Common/Services/urlService';
import { HOME } from '../../HomeActionTypes';
import { USERS } from '../Users/UsersActionTypes';
import { ACCOUNT_CREATION } from './AccountCreationActionTypes';
import userTypes, { roles } from '../../../../Common/Constants/userTypes';
import { postLogin } from "../../../Authentication/AuthenticationSaga";

function* onFinishAccountSetupSuccess(userId, userType, userResponse) {
	const successMessage = userType === 'VENDOR_ADMIN' ? 'You successfully finished Vendor BU profile' : 'You successfully finished your profile';
	yield all([
		call(ApiHelper.put, 'user', urlService.formatString(resources.userFinishAccountSetup, userId)),
		notificationService.success(successMessage),
		userSessionService.setAccountSetupStatus(true),
		put({
			type: HOME.SET_CURRENT_USER_INFO,
			payload: userResponse.content,
		}),
		put({
			type: USERS.SET_USER,
			payload: userResponse.content,
		}),
	]);
}

const hasCurrentUserHisOwnProcedures = (userTypes, usersWithOwnProcedures) => {
	const hasHisOwnProcedures = [];

	usersWithOwnProcedures.forEach((userType) => {
		if (userTypes.includes(userType)) {
			hasHisOwnProcedures.push(true);
		}
	});

	return hasHisOwnProcedures.includes(true);
};

function* createISRUserOrganization(user, ISROrganization, hospitalIds) {
	const organization = {
		...ISROrganization,
		firstName: user.firstName,
		lastName: user.lastName,
	};
	yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.onBoardingIndividualSalesRep, user.id), organization);
	yield call(ApiHelper.post, 'coreregistry', urlService.formatString(resources.applyToHospital, user.id), { 'ids': hospitalIds });
}

function* finalizeAccountSetup(action) {
	let urlToCall;
	let params;

  const { procedures, userSettings, ISROrganization, hospitals, hasPhoneNumber } = action.finishAccountCreation;
  let { user } = action.finishAccountCreation;

	const usersWithOwnProcedures = ['VENDOR_MANAGER', 'VENDOR_SALES_REPRESENTATIVE', 'PROCTOR', 'SPECIALIST', 'INDIVIDUAL_SALES_REPRESENTATIVE'];
	if (hasCurrentUserHisOwnProcedures(user.userTypes, usersWithOwnProcedures)) {
		urlToCall = urlService.formatString(resources.userProcedures, user.id);
		params = procedures;
	}

	if (user.userType === roles.individualSalesRep) {
		user = { ...user, orgId: user.email };
	}

  let isTwoFactorAuthEnabled;
  try {
    // two factor is determined by org settings
    const response = yield call(
      ApiHelper.get,
      "coreregistry",
      urlService.formatString(resources.getOrgSettings, user.orgId)
    );

    const orgSettings = response.content
    // loop through org settings for Two Factor Auth status
    orgSettings.forEach((payload) => {
      if (payload.type === "TWO_FACTOR_AUTH_ENABLED") {
        isTwoFactorAuthEnabled = payload.value === "true"
        return
      }
    })
  } catch (e) {
    yield notificationService.error(e);
  }
	try {
		const userType = user.userTypes[0];

		const actions = [
			call(ApiHelper.put, 'user', urlService.formatString(resources.user, user.id), user),
			...(userType !== roles.hospitalAdmin && userType !== roles.availAdmin && userType !== roles.vendorAdmin) ? [call(ApiHelper.put, 'coreregistry', urlToCall, params)] : [],
			call(ApiHelper.put, 'user', urlService.formatString(resources.userSettings, user.id), userSettings),
		];
		const [userResponse] = yield all(actions);
		if (user.userTypes[0] === roles.individualSalesRep) {
			yield createISRUserOrganization(user, ISROrganization, hospitals);
		}
    yield onFinishAccountSetupSuccess(user.id, user.userTypes[0], userResponse);
    if (!hasPhoneNumber && isTwoFactorAuthEnabled) {
      yield put(push("/add-phone"));
    } else {
		  yield put(push(userTypes[user.userTypes[0]].redirectionRoute));
    }
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getAllTimezones() {
	try {
		const response = yield call(ApiHelper.get, 'user', resources.timezones);
		yield put({
			type: ACCOUNT_CREATION.SET_ALL_TIMEZONES,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* accountCreationSaga() {
	yield takeLatest(ACCOUNT_CREATION.FINALIZE_ACCOUNT_SETUP, finalizeAccountSetup);
	yield takeLatest(ACCOUNT_CREATION.GET_ALL_TIMEZONES, getAllTimezones);
}
