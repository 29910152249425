import { useEffect } from "react";

// executes callback when Page is refreshed
export const useOnPageRefresh = (callback: () => void) => {
  useEffect(() => {
    window.addEventListener("beforeunload", callback);
    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  }, []);
};
