import { MutableRefObject, useCallback, useMemo } from "react";

import { BROWSER_AGENTS } from "src/domains/Beacon/constants";
import { useTestAudioForChrome } from "src/domains/Beacon/hooks/useTestAudioForChrome";
import { useTestAudioForFirefox } from "src/domains/Beacon/hooks/useTestAudioForFirefox";
import { getBrowserName } from "src/utils/browsers";

export const useTestAudio = (
  eAudio: MutableRefObject<HTMLAudioElement>
): [
  () => void,
  (device: MediaDeviceInfo) => void,
  { playing: boolean; frequency: number }
] => {
  const [
    toggleFirefoxAudio,
    setFirefoxSpeakerDevice,
    { playing: firefoxPlaying, frequency: firefoxFrequency, outputDevice },
  ] = useTestAudioForFirefox(eAudio);
  const [
    toggleChromeAudio,
    { playing: chromePlaying, frequency: chromeFrequency },
  ] = useTestAudioForChrome(eAudio);
  /**
   * Memorizes the browser name once, is cleaner (one line only) rather than
   * importing a useState and a useEffect
   */
  const browser = useMemo(() => getBrowserName(), []);

  /**
   * Returns correct browser toggle function based on the user's browser client
   * The 'useCallback' helps in the performance of the audio to not be run
   * with a noticeable lag
   */
  const toggleAudio = useCallback(() => {
    if (browser === BROWSER_AGENTS.Firefox) {
      toggleFirefoxAudio();
    } else {
      toggleChromeAudio();
    }
  }, [firefoxPlaying, chromePlaying, outputDevice]);

  /**
   * Changes the Firefox's output device
   * The 'useCallback' helps in the performance of the audio to not be run
   * with a noticeable lag
   */
  const setSpeakerDevice = useCallback((device: MediaDeviceInfo) => {
    if (browser === BROWSER_AGENTS.Firefox) {
      setFirefoxSpeakerDevice(device);
    }
  }, []);

  // Returns the correct browser playing property based on user's browser
  const playing = useMemo(() => {
    if (browser === BROWSER_AGENTS.Firefox) {
      return firefoxPlaying;
    } else {
      return chromePlaying;
    }
  }, [firefoxPlaying, chromePlaying]);

  // Returns the correct browser playing property based on user's browser
  const frequency = useMemo(() => {
    if (browser === BROWSER_AGENTS.Firefox) {
      return firefoxFrequency;
    } else {
      return chromeFrequency;
    }
  }, [firefoxFrequency, chromeFrequency]);

  return [toggleAudio, setSpeakerDevice, { playing, frequency }];
};
