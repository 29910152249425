import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting/meetingSlice";
import { cancelCallSessionHeartbeatThunk } from "src/domains/Beacon/store/meeting/thunks";
import { CallState } from "src/domains/Beacon/store/meeting/types";
import { handleUnfreezeFrameThunk } from "src/domains/Beacon/store/stream/thunks";
import { unpublishAudioTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishAudioTrackThunk";
import { unpublishVideoTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishVideoTrackThunk";
import { uiActions } from "src/domains/Beacon/store/ui";
import { CallSteps } from "src/domains/Beacon/store/ui/types";
import { logger } from "src/logging/logger";
import { leaveCall } from "src/services/ApiClient/twilio";
import { AvailKitService } from "src/services/AvailKitService";

export const leaveCallThunk = createAsyncThunk<
  // Return type of the payload creator, we return nothing so void
  void,
  // First argument to the payload creator, no args so void
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("twilio/leaveCall", async (_, { getState, dispatch }) => {
  try {
    const { meeting, twilio, stream } = getState();
    const { joinId, callSid, accessTokenIntervalKey, isUserHost } = meeting;
    const { freezeFrame } = stream;
    const { twilioTokenIntervalKey, duplicateParticipantDisconnected } = twilio;
    const availKit = AvailKitService.instance;
    logger().info("Attemping to leave the call...");

    // Must stop the timer loop for Twilio token refresh
    if (twilioTokenIntervalKey) {
      logger().info("** Stopping timed Twilio token reconnection **");
      if (twilioTokenIntervalKey) {
        clearInterval(twilioTokenIntervalKey);
      }
    }

    // Must stop the timer loop for Avail access token refresh
    if (accessTokenIntervalKey) {
      logger().info("** Stopping timed Avail access token reconnection **");
      if (accessTokenIntervalKey) {
        clearInterval(accessTokenIntervalKey);
      }
    }

    // disconnect from twilio
    if (twilio.room) {
      // Can only remove video/audio track if there's a Twilio room
      dispatch(unpublishVideoTrackThunk());
      dispatch(unpublishAudioTrackThunk());

      twilio.room.disconnect();
      logger().info("Disconnected from twilio room during the leave call flow");
    }

    // Should only call the API if there's a call session id
    if (callSid) {
      // calling the DELETE endpoint for the callevent
      logger().info(
        `Leaving call with {joinId: ${joinId}, callSid: ${callSid}}`
      );
      await leaveCall(joinId, callSid);

      // clearing the heart beat interval function once they have actually left the call
      dispatch(cancelCallSessionHeartbeatThunk());
    }

    // changing the call step to the "leave" state and closing the leave call modal
    dispatch(uiActions.setCallStep(CallSteps.LEAVE_CALL));

    // purposely not closing the leave call modal to prevent an visual bug that happens when it fades out
    // the leave call screen will appear on top of it due to the call step change above
    // dispatch(uiActions.setLeaveCallModal(false));

    // duplicateParticipantDisconnected means that the leave Call was forced
    if (!duplicateParticipantDisconnected) {
      // changing call state to the "end call confirmed" state to show client initiated end call
      dispatch(meetingActions.setCallState(CallState.ENDCALLCONFIRMED));
    }

    // if the frame is frozen & is the Host leaving, then clear FreezeFrame
    if (freezeFrame.active && isUserHost) {
      dispatch(handleUnfreezeFrameThunk());
    }

    logger().info("Leaving all PubNub channels");
    availKit?.eventService.leaveAll();
    logger().info("Succesfully ran through all the leaving call flow");
  } catch (error) {
    logger().error("Error trying to leave the call.", JSON.stringify(error));
    throw error;
  }
});

export const leaveCallNoNetworkThunk = createAsyncThunk<
  // Return type of the payload creator, we return nothing so void
  void,
  // First argument to the payload creator, no args so void
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("twilio/leaveCallNoNetwork", (_, { getState, dispatch }) => {
  try {
    logger().info(
      "Attemping to leave the call due to no network connection..."
    );
    const { meeting, twilio } = getState();
    const { accessTokenIntervalKey } = meeting;
    const { twilioTokenIntervalKey } = twilio;

    // Must stop the timer loop for Twilio token refresh
    if (twilioTokenIntervalKey) {
      logger().info("** Stopping timed Twilio token reconnection **");
      if (twilioTokenIntervalKey) {
        clearInterval(twilioTokenIntervalKey);
      }
    }

    // Must stop the timer loop for Avail access token refresh
    if (accessTokenIntervalKey) {
      logger().info("** Stopping timed Avail access token reconnection **");
      if (accessTokenIntervalKey) {
        clearInterval(accessTokenIntervalKey);
      }
    }

    // disconnect from twilio
    if (twilio.room) {
      // Can only remove video/audio track if there's a Twilio room
      dispatch(unpublishVideoTrackThunk());
      dispatch(unpublishAudioTrackThunk());

      twilio.room.disconnect();
      logger().info("Disconnected from twilio room during the leave call flow");
    }

    // Cannot call the API since there is no network. Let heartbeat be a sign of signing off.

    // clearing the heart beat interval function once they have actually left the call
    dispatch(cancelCallSessionHeartbeatThunk());

    // changing the call step to the "leave" state and closing the leave call modal
    dispatch(uiActions.setCallStep(CallSteps.LEAVE_CALL));

    // purposely not closing the leave call modal to prevent an visual bug that happens when it fades out
    // the leave call screen will appear on top of it due to the call step change above

    // changing call state to the "end call confirmed" state to show client initiated end call
    dispatch(meetingActions.setCallState(CallState.ENDCALLCONFIRMED));

    logger().info(
      "Succesfully ran through all the leaving call flow for no network connection"
    );
  } catch (error) {
    logger().error("Error trying to leave the call.", JSON.stringify(error));
    throw error;
  }
});
