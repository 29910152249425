import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";

import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { DeleteOutline } from "@mui/icons-material";

import { Button } from "src/components/Button";
import { Font } from "src/components/Font";
import { List } from "src/components/List";
import { CreatePresetForm } from "src/domains/Beacon/components/SidePanel/PresetsContent/CreatePresetForm";
import { DeleteConfirmation } from "src/domains/Beacon/components/SidePanel/PresetsContent/DeleteConfirmation";
import { getParsedPresetName } from "src/domains/Beacon/components/SidePanel/PresetsContent/utils";
import { useAppSelector } from "src/domains/Beacon/store";
import {
  selectCameras,
  selectIsIntegrationActive,
  selectPresetIdSelected,
  selectPresets,
} from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handlePresetChangeThunk } from "src/domains/Beacon/store/stream/thunks/handlePresetChangeThunk";
import { stopShareScreenTrackThunk } from "src/domains/Beacon/store/stream/thunks/stopShareScreenTrack";
import { LayoutType } from "src/domains/Beacon/store/stream/types";
import { uiActions } from "src/domains/Beacon/store/ui";
import { selectSidePanelState } from "src/domains/Beacon/store/ui/selectors";
import {
  UINotificationTypes,
  UISidePanelContentOptions,
} from "src/domains/Beacon/store/ui/types";
import { getMergedCamerasFromPresetSnapshot } from "src/domains/Beacon/utils/availKit";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { logger, LoggerLevels } from "src/logging/logger";

import styles from "./styles.scss";

export const PresetsContent = () => {
  const [formOpen, setFormOpen] = useState<boolean>();
  const [deletingMode, setDeletingMode] = useState<boolean>(false);
  const [deletePresetId, setDeletePresetId] = useState<string>();
  const presets = useAppSelector(selectPresets);
  const selectedPresetId = useAppSelector(selectPresetIdSelected);
  const {
    externalInputsImageResizing,
    oRtelligence: enableORTelligence,
  } = useFeatureFlags();
  const { open: isSidePanelOpen, content: sidePanelContent } = useAppSelector(
    selectSidePanelState
  );
  const integrationActive = useAppSelector(selectIsIntegrationActive);
  const dispatch = useDispatch();
  const cameras = useAppSelector(selectCameras);

  // is the feature flag on AND if an integration (like ORT) in one of the layout views
  const isIntegrationActive = enableORTelligence && integrationActive;

  // Cleanup of the selected preset must be done cause' user may interact
  // with layouts or PTZ and preset's info will not match afterwards
  useEffect(() => {
    // Must detect when sidePanel closes and content was Presets cause' component
    // doesn't unmounts
    if (
      !isSidePanelOpen &&
      sidePanelContent === UISidePanelContentOptions.PRESETS
    ) {
      dispatch(streamActions.setPresetSelected(null));
    }

    return () => {
      dispatch(streamActions.setPresetSelected(null));
    };
  }, [isSidePanelOpen, sidePanelContent]);

  // Sets the deleting preset id state and opens the Delete Confirmation component
  const handleOpenDeleteConfirmation = (e: any, presetId: string) => {
    // e.stopPropagation prevents the item in the list to  be selected
    e.stopPropagation();
    setDeletingMode(true);
    setDeletePresetId(presetId);
    setFormOpen(false);
  };

  // Will handle removing a preset from the list and closing the Delete Confirmation
  const handleDeletePreset = () => {
    dispatch(streamActions.removePreset(deletePresetId));
    setDeletingMode(false);
    dispatch(
      uiActions.triggerCustomNotification(
        UINotificationTypes.DELETE,
        "The Preset has been deleted",
        "mini"
      )
    );
  };

  // Parses the presets array to the list's object shape
  const presetsList = presets?.map((preset) => ({
    id: preset.id,
    title: preset.name,
    rightContent: (
      <Button
        data-test-id="delete-preset-button"
        variant="icon"
        endIcon={<DeleteOutline />}
        tooltipProps={{
          title: "Delete",
          placement: "bottom",
        }}
        onClick={(e) => handleOpenDeleteConfirmation(e, preset.id)}
      />
    ),
  }));

  // Array of all the presets names already parsed in order to look for duplications
  // when creating a new one, this way is easier rather than doing a .find through the list
  const presetsNames = useMemo(
    () => presets.map((preset) => getParsedPresetName(preset.name)),
    [presets.length]
  );

  // Handles selecting a Preset from the list
  const handleClick = async (presetId: string) => {
    logger().info("User has selected a preset...");

    // stop screen share first since the thunk ends with setting Layouts to pre-TPI snapshot
    if (isIntegrationActive) {
      // await to prevent race condition with setting a Preset
      await dispatch(
        stopShareScreenTrackThunk({
          enableORTelligence,
        })
      );
    }

    const preset = presets.find((p) => p.id === presetId);

    const framedCameraNames: string[] = Object.values(preset.layoutFrames).map(
      (layoutFrame) => layoutFrame.cameraLabel
    );
    logger().logWithFields(
      LoggerLevels.info,
      { feature: "Presets" },
      `User has selected a Preset with cameras: ${framedCameraNames}`
    );

    // Handle Preset Change
    // Merges cameras from the state with snapshotCameras
    const camerasList = cameras as (AVKCamera | AVKExternalInput)[];
    const presetCameras = preset.cameras as (AVKCamera | AVKExternalInput)[];
    const updatedCameras = getMergedCamerasFromPresetSnapshot(
      camerasList,
      presetCameras
    );

    dispatch(streamActions.setCameras([...updatedCameras]));
    dispatch(streamActions.setZoomState(preset.zoomState));
    dispatch(streamActions.setLayoutFrames(preset.layoutFrames));
    dispatch(streamActions.setPresetSelected(presetId));

    // Checks if Console supports EIR in order to initiate a AVKVideoLayoutEvent or
    // a AVKUserPresetEvent instead
    dispatch(
      handlePresetChangeThunk({
        externalInputsImageResizing,
        newLayoutFrames: preset.layoutFrames,
        presetCameras: preset.cameras as (AVKCamera | AVKExternalInput)[],
        presetLayout: preset.presetLayout as LayoutType,
      })
    );
  };

  // Opens/closes the preset form creation when clicking the 'Create New Preset' button
  const toggleOpenForm = () => {
    setFormOpen(!formOpen);
    setDeletingMode(false);
  };

  return (
    <>
      <div className={styles.header}>
        <Font variant="h3" color="light">
          Saved Presets
        </Font>
      </div>
      <div className={styles.container}>
        <div className={styles.listContainer}>
          <List
            data-test-id="presets-list"
            items={presetsList}
            onClick={handleClick}
            selectedId={selectedPresetId}
            clickable
            removeDivider
            className={styles.list}
            listItemClassName={styles.listItem}
          />
          <div className={styles.form}>
            <CreatePresetForm
              formOpen={formOpen}
              toggleOpenForm={toggleOpenForm}
              // No more than 15 presets allowed
              blockCreationForm={presetsList.length === 15}
              presetsNames={presetsNames}
              // if isIntegrationActive is true then we want to disable the create button and show a warning
              disabledCreateButton={isIntegrationActive}
              showIntegrationWarningMessage={isIntegrationActive}
            />
          </div>
        </div>
        <div className={styles.confirmation}>
          <DeleteConfirmation
            open={deletingMode}
            onDelete={handleDeletePreset}
            onCancel={() => setDeletingMode(false)}
          />
        </div>
      </div>
    </>
  );
};
