import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { getTwilioLoginId } from "src/domains/Beacon/utils/twilio";
import { logger } from "src/logging/logger";

// Will detect when the dominant speaker changes so we can show something in the UI
export const useDominantSpeakerChanged = () => {
  const { room } = useAppSelector(selectTwilioState);
  const dispatch = useDispatch();

  useEffect(() => {
    room.on("dominantSpeakerChanged", () => {
      logger().info(
        `Dominant speaker changed in room to: ${room.dominantSpeaker?.identity}`
      );

      // Twilio can also pick up CONSOLE as dominantSpeaker. If it does, just
      // set the dominant speaker to just place holder "console" text
      if (room.dominantSpeaker?.identity.includes("CONSOLE")) {
        dispatch(twilioActions.setDominantSpeaker("console"));
        return;
      }
      // room.dominantSpeaker.identity comes in like this
      // USER-Ar2aQYW029weVvgd-PVID-6929876e_0055_7de9_d2e3_c8cb16337217-l8t0u2aa
      // but we only really need the login ID in order to show in the UI who the dominant speaker is
      // so we use getTwilioLoginId on it first
      // if twilio gives us a `null` dominantSpeaker, we set an empty string, which can be used as falsey
      dispatch(
        twilioActions.setDominantSpeaker(
          room.dominantSpeaker?.identity
            ? getTwilioLoginId(room.dominantSpeaker?.identity)
            : ""
        )
      );
    });
  }, []);
};
