import { useEffect } from "react";

export const useInitGlobalStyles = () => {
  // setting the base font size on the top level "html" dom element to be 16px
  // because thats what all of the REM sass functions expected to be the base
  // but avail-web-app uses semantic css which does a base of 14px
  // this function below will only update the font size for beacon pages
  // TODO once we remove semantic css from avail-web-app we can safely remove this useEffect
  useEffect(() => {
    document.documentElement.style.fontSize = "16px";
  }, []);
};
