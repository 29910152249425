import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  LocalAudioTrackStats,
  LocalVideoTrackStats,
  NetworkQualityStats,
  RemoteAudioTrackStats,
  RemoteParticipant,
  RemoteVideoTrackStats,
  Room,
  StatsReport
} from "twilio-video";
import { MULTI_PARTY_LAUNCH_MODE } from "../../constants";
import {
  AppState,
  MeetingStateType,
  MultiPartyEventDetail,
  UserInfoType,
  TwilioState,
  UserState,
  PortalIdentity
} from "../../store/models";
import { parseAvailUUID } from "../../utils";
import "./RoomDebugPanel.scss";

export interface StateProps {
  room: Room | null;
  twilio: TwilioState;
  user: UserState;
  identity: PortalIdentity;
  meeting: MeetingStateType;
}

export interface DispatchProps {}

interface ParticipantMediaStats {
  availPerson: UserInfoType;
  isConnected: boolean;
  isEvent: boolean;
  audioStats: RemoteAudioTrackStats | null;
  videoStats: RemoteVideoTrackStats | null;
  ntwkQualityStats: NetworkQualityStats | null;
}

interface LocalMediaStats {
  availPerson: UserInfoType;
  isEvent: boolean;
  isConnected: boolean;
  audioStats: LocalAudioTrackStats | null;
  videoStats: LocalVideoTrackStats | null;
}

interface RoomDebugState {
  participantMediaStats: ParticipantMediaStats[];
  localVideoTrackStats: LocalVideoTrackStats | null;
  localAudioTrackStats: LocalAudioTrackStats | null;
  timer: any;
}

interface OwnProps {
  closePanel: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

class RoomDebugPanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.updateMediaStats = this.updateMediaStats.bind(this);
  }

  state: RoomDebugState = {
    participantMediaStats: [],
    timer: 0,
    localVideoTrackStats: null,
    localAudioTrackStats: null
  };

  getTwilioParticipantInfo(loginId: string): RemoteParticipant | null {
    const { room } = this.props.twilio;
    if (!room) {
      return null;
    }

    // Find from Twilio, the participant (if any) whose identity matches the Avail Login ID...
    const twilioParties: RemoteParticipant[] = [
      ...room?.participants.values()
    ].filter((p: RemoteParticipant) => {
      return parseAvailUUID(p.identity).clientId === loginId;
    });

    return twilioParties.length > 0 ? twilioParties[0] : null;
  }

  async getLocalMediaStats() {
    const { room } = this.props.twilio;
    if (!room) {
      return;
    }
    const stats: StatsReport[] = await room.getStats();
    if (stats.length === 0) {
      return;
    }

    this.setState({
      localVideoTrackStats: stats[0].localVideoTrackStats[0],
      localAudioTrackStats: stats[0].localAudioTrackStats[0]
    });
  }

  async getParticipantMediaStats(ev: MultiPartyEventDetail | null) {
    const { room } = this.props.twilio;
    const roomMediaStats: ParticipantMediaStats[] = [];

    if (!room) {
      return roomMediaStats;
    }
    const stats: StatsReport[] = await room.getStats();
    if (stats.length === 0) {
      return roomMediaStats;
    }

    ev?.participants.forEach((availPerson: UserInfoType) => {
      // From the Avail invited Participant, is s/he connected to the room?
      const party = this.getTwilioParticipantInfo(availPerson.loginId);
      let uniqueId = "";
      let remVTStats: RemoteVideoTrackStats[] = [];
      let remATStats: RemoteAudioTrackStats[] = [];
      let NQS: NetworkQualityStats | null = null;

      if (party) {
        // Yes, now get their Media Track SID's so we can look up the
        // stats on the tracks
        const vKeys = [...party.videoTracks.keys()];
        const videoTrackSid = vKeys.length > 0 ? vKeys[0] : "";
        const aKeys = [...party.audioTracks.keys()];
        const audioTrackSid = aKeys.length > 0 ? aKeys[0] : "";

        // Pull the room Stats report to find the records for the detected
        // user audio and video tracks
        remVTStats = stats[0].remoteVideoTrackStats.filter(
          (stat: RemoteVideoTrackStats) => stat.trackSid === videoTrackSid
        );

        remATStats = stats[0].remoteAudioTrackStats.filter(
          (stat: RemoteAudioTrackStats) => stat.trackSid === audioTrackSid
        );
        uniqueId = parseAvailUUID(party.identity).clientId;
        NQS = party.networkQualityStats;
      } else {
        const consoleParticipant = [...room.participants.values()].filter(
          (p: RemoteParticipant) => {
            return parseAvailUUID(p.identity).clientType === "CONSOLE";
          }
        )[0];

        const vKeys = [...consoleParticipant.videoTracks.keys()];
        const videoTrackSid = vKeys.length > 0 ? vKeys[0] : "";
        const aKeys = [...consoleParticipant.audioTracks.keys()];
        const audioTrackSid = aKeys.length > 0 ? aKeys[0] : "";

        // Pull the room Stats report to find the records for the detected
        // user audio and video tracks
        remVTStats = stats[0].remoteVideoTrackStats.filter(
          (stat: RemoteVideoTrackStats) => stat.trackSid === videoTrackSid
        );

        remATStats = stats[0].remoteAudioTrackStats.filter(
          (stat: RemoteAudioTrackStats) => stat.trackSid === audioTrackSid
        );
        uniqueId = parseAvailUUID(consoleParticipant.identity).clientId;
        NQS = consoleParticipant.networkQualityStats;

        availPerson = {
          loginId: uniqueId,
          email: "",
          firstName: "-",
          lastName: "-",
          role: "CONSOLE"
        };
      }

      const userMediaStats: ParticipantMediaStats = {
        availPerson,
        isEvent: true,
        isConnected: party !== null,
        audioStats: remATStats.length > 0 ? remATStats[0] : null,
        videoStats: remVTStats.length > 0 ? remVTStats[0] : null,
        ntwkQualityStats: NQS
      };

      const duplicateParticipants = roomMediaStats.some(
        p => p.availPerson.loginId === uniqueId
      );
      if (!duplicateParticipants) roomMediaStats.push(userMediaStats);
    });
    return roomMediaStats;
  }

  async getPtToPtMediaStats() {
    const { room } = this.props.twilio;
    const roomMediaStats: ParticipantMediaStats[] = [];

    if (!room) {
      return roomMediaStats;
    }
    const stats: StatsReport[] = await room.getStats();
    if (stats.length === 0) {
      return roomMediaStats;
    }

    const nullPerson: UserInfoType = {
      firstName: "",
      lastName: "",
      loginId: "",
      email: "",
      role: "--"
    };

    const remoteMediaStat: ParticipantMediaStats = {
      availPerson: {
        ...nullPerson,
        role: "REMOTE"
      },
      isEvent: false,
      isConnected: true,
      audioStats: stats[0].remoteAudioTrackStats[0],
      videoStats: stats[0].remoteVideoTrackStats[0],
      ntwkQualityStats: null
    };
    roomMediaStats.push(remoteMediaStat);
    return roomMediaStats;
  }

  async updateMediaStats() {
    const { meeting } = this.props;
    if (meeting.mode === MULTI_PARTY_LAUNCH_MODE) {
      // Update all the Twilio Media associations for the intended Avail Participants
      const participantMediaStats = await this.getParticipantMediaStats(
        meeting.multiPartyCallEventDetail
      );
      this.setState({ participantMediaStats: participantMediaStats });
    } else {
      const ptToPtMediaStats = await this.getPtToPtMediaStats();
      this.setState({ participantMediaStats: ptToPtMediaStats });
    }
    this.getLocalMediaStats();
  }

  componentDidMount() {
    this.updateMediaStats();
    const handle = setInterval(this.updateMediaStats, 2000);
    this.setState({ timer: handle });
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  listLocalMediaStats() {
    const { localVideoTrackStats, localAudioTrackStats } = this.state;

    const width = localVideoTrackStats?.dimensions?.width || -1;
    const height = localVideoTrackStats?.dimensions?.height || -1;

    return (
      <tr>
        <td>Local</td>
        <td>{localVideoTrackStats?.codec}</td>
        <td>{localVideoTrackStats?.packetsSent} </td>
        <td>{localVideoTrackStats?.packetsLost} </td>
        <td>
          {width} x {height}{" "}
        </td>
        <td>{localAudioTrackStats?.codec} </td>
        <td>{localAudioTrackStats?.packetsSent} </td>
        <td>{localAudioTrackStats?.packetsLost} </td>
        <td>{localAudioTrackStats?.audioLevel} </td>
        <td>{localAudioTrackStats?.jitter} </td>
      </tr>
    );
  }

  listPartyMediaStats(p: ParticipantMediaStats) {
    const {
      availPerson,
      isConnected,
      audioStats,
      videoStats,
      ntwkQualityStats
    } = p;

    const showIfConnected = (): string => {
      return isConnected ? "n/a" : "";
    };

    const width = videoStats?.dimensions?.width || -1;
    const height = videoStats?.dimensions?.height || -1;
    const vPackets =
      (videoStats as RemoteVideoTrackStats)?.packetsReceived || 0;
    const aPackets =
      (audioStats as RemoteAudioTrackStats)?.packetsReceived || 0;
    const kbps = (n: any) => (n ? `${(n / 1024).toFixed(2)} Kbps` : "");

    return (
      <>
        <tr>
          <td>{availPerson.role}</td>
          <td>{availPerson.loginId}</td>
          <td>{availPerson.firstName}</td>
          <td>{availPerson.lastName}</td>

          <td>{videoStats ? videoStats.codec : showIfConnected()}</td>
          <td>{videoStats ? vPackets : showIfConnected()}</td>
          <td>{videoStats ? videoStats.packetsLost : showIfConnected()}</td>
          <td>
            {width} x {height}
          </td>
          <td>
            {ntwkQualityStats
              ? kbps(ntwkQualityStats.video?.recvStats?.bandwidth?.actual) + "⇒"
              : showIfConnected()}
            <br />
            {ntwkQualityStats
              ? "⇐" + kbps(ntwkQualityStats.video?.sendStats?.bandwidth?.actual)
              : showIfConnected()}
          </td>

          <td>{audioStats ? audioStats.codec : showIfConnected()}</td>
          <td>{audioStats ? aPackets : showIfConnected()}</td>
          <td>{audioStats ? audioStats.packetsLost : showIfConnected()}</td>
          <td>{audioStats ? audioStats.audioLevel : showIfConnected()}</td>
          <td>{audioStats ? audioStats.jitter : showIfConnected()}</td>
          <td>
            {ntwkQualityStats
              ? kbps(ntwkQualityStats.audio?.recvStats?.bandwidth?.actual) + "⇒"
              : showIfConnected()}
            <br />
            {ntwkQualityStats
              ? "⇐" + kbps(ntwkQualityStats.audio?.sendStats?.bandwidth?.actual)
              : showIfConnected()}
          </td>
        </tr>
      </>
    );
  }

  nvPair(n: string, v: string) {
    return (
      <tr>
        <td>{n}</td>
        <td>{v}</td>
      </tr>
    );
  }

  showLocalMediaStats() {
    const headerFields = [
      "Endpoint",
      "Codec",
      "Pkts Sent",
      "Pkt Loss",
      "Dims.",
      "Codec",
      "Pkts Sent",
      "Pkt Loss",
      "Audio Level",
      "Jitter"
    ];

    return (
      <div className="overflowtable">
        <table className="partymediatable">
          <tr>
            {headerFields.map(f => (
              <th>{f}</th>
            ))}
          </tr>
          {this.listLocalMediaStats()}
        </table>
      </div>
    );
  }

  showParticipantMediaStats() {
    const { participantMediaStats } = this.state;
    const partiesMediaStats = participantMediaStats.map(p => {
      return this.listPartyMediaStats(p);
    });
    const headerFields = [
      "Role",
      "Client ID",
      "First Name",
      "Last Name",
      "Codec",
      "Pkts Rcvd",
      "Pkt Loss",
      "Dims",
      "Video B/W",
      "Codec",
      "Pkts Rcvd",
      "Pkt Loss",
      "Audio Level",
      "Jitter",
      "Audio B/W"
    ];

    return (
      <div className="overflowtable">
        <table className="partymediatable">
          <tr>
            {headerFields.map(f => (
              <th>{f}</th>
            ))}
          </tr>
          {partiesMediaStats}
        </table>
      </div>
    );
  }

  showEventInfo(ev: MultiPartyEventDetail) {
    return (
      <>
        {this.nvPair("Event ID", ev.eventId)}
        {this.nvPair("Event Subject", ev.subject)}
        {this.nvPair("Surgeon", ev.surgeonName)}
      </>
    );
  }

  render() {
    const { identity, meeting, closePanel } = this.props;
    const { multiPartyCallEventDetail } = meeting;

    const isEvent = meeting.mode === MULTI_PARTY_LAUNCH_MODE;

    const callSid = isEvent ? meeting.callSid : identity.access_token;

    return (
      <div className="roomdebugpanel">
        <button className="close-panel-button" onClick={closePanel}>
          &times;
        </button>
        <div>
          <table>
            <tr>
              <th>Property</th>
              <th>Current Value</th>
            </tr>
            {this.nvPair("Session Type", isEvent ? `Event` : "Pt-to-Pt")}
            {this.nvPair("Call Session ID", callSid ? callSid : "")}
            {isEvent &&
              multiPartyCallEventDetail &&
              this.showEventInfo(multiPartyCallEventDetail)}
          </table>
        </div>
        {this.showParticipantMediaStats()}
        {this.showLocalMediaStats()}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  room: state.twilio.room,
  twilio: state.twilio,
  user: state.user,
  identity: state.user.identity,
  meeting: state.meeting
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(RoomDebugPanel);
