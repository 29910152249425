// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root_YHOit4.styles_chip_23CeT6{border-radius:4px;margin-left:4px;margin-right:4px}.styles_root_YHOit4.styles_chip_23CeT6.styles_success_U1MLDg{background-color:#69be28}", "",{"version":3,"sources":["webpack://./src/components/legacy/ChipInput/Chip/styles.scss"],"names":[],"mappings":"AAGE,uCACE,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,6DACE,wBAAA","sourcesContent":["@import \"src/colors.scss\";\n\n.root {\n  &.chip {\n    border-radius: 4px;\n    margin-left: 4px;\n    margin-right: 4px;\n\n    &.success {\n      background-color: map-get($theme-colors, \"green\");\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root_YHOit4",
	"chip": "styles_chip_23CeT6",
	"success": "styles_success_U1MLDg"
};
export default ___CSS_LOADER_EXPORT___;
