// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_delete-phone-number-modal_UrEN7W{display:flex;flex-direction:column;justify-content:center}", "",{"version":3,"sources":["webpack://./src/domains/Users/components/UserSecurity/DeleteNumberModal/styles.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,qBAAA,CACA,sBAAA","sourcesContent":[".delete-phone-number-modal {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"delete-phone-number-modal": "styles_delete-phone-number-modal_UrEN7W",
	"deletePhoneNumberModal": "styles_delete-phone-number-modal_UrEN7W"
};
export default ___CSS_LOADER_EXPORT___;
