import { useEffect } from "react";

import { HELP_LINK } from "src/constants";
import { hideReCaptcha } from "src/services/ReCaptchaHelper";

export function EmbeddedHelpCenter() {
  useEffect(hideReCaptcha, []);
  // Sandboxing is not used as the assets we're using is our own and even hosted
  // using the same domain
  return (
    <iframe
      title="help-center"
      width="100%"
      height="100%"
      frameBorder="0"
      src={HELP_LINK}
    />
  );
}
