import React from "react";

import clsx from "clsx";

import { Button as MuiButton, ButtonProps, Typography } from "@mui/material";

import { LoadingSpinner } from "src/components/legacy/LoadingSpinner";

import styles from "./styles.scss";

// we extend the Material-UI Button's props so any custom props we want
// should be defined here otherwise all MUI's props are still valid and get passed down to the MuiButton
interface Props extends ButtonProps {
  text?: string;
  loading?: boolean;
  variant?: "contained" | "outlined" | "text";
  theme?: "primary" | "secondary" | "tertiary" | "green";
  fullWidth?: boolean; // this prop sets the button width to be 100%
}

export const Button = ({
  text,
  size = "large",
  theme = "primary",
  variant = "contained",
  onClick,
  children,
  loading,
  disabled,
  fullWidth = false,
  ...other
}: Props) => {
  const textComponent = <Typography>{text}</Typography>;

  const loadingSpinner = (
    <LoadingSpinner className={styles.loader} size="small" />
  );

  return (
    <MuiButton
      size={size}
      onClick={onClick}
      variant={variant}
      disabled={disabled || loading}
      {...other}
      className={styles.wrapper}
      classes={{
        root: clsx(styles.root, styles[variant], styles[theme], {
          [styles.disabled]: disabled || loading,
          [styles.fullWidth]: fullWidth,
        }),
      }}
    >
      <span
        className={clsx({
          [styles.hidetext]: loading, // if the component is loading then we want the text to be invisible so the loading icon can appear in place
        })}
      >
        {text && textComponent}
        {children}
      </span>
      {loading ? loadingSpinner : null}
    </MuiButton>
  );
};
