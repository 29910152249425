import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import './CallDetailRecord.scss';
import CallDetailRecordTable from './components/CallDetailRecordTable/CallDetailRecordTable';
import CallDetailRecordForm from './components/CallDetailsRecordForm/CallDetailRecordForm';
import { notificationService } from '../../../../../Common/Services';
import { getCallHistoryAction } from './CallDetailRecordActions';

function CallDetailRecord({ callHistory, getCallHistory, pagination, cdrForm }) {
	function hasEmptyInputs() {
		if (!cdrForm || !cdrForm.from || !cdrForm.to) {
			notificationService.warning('Please fill out the dates.');
			return true;
		}
		return false;
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		if (hasEmptyInputs()) return;

		getCallHistory({
			from: cdrForm.from,
			to: cdrForm.to,
			size: 10,
			page: 0,
		});
	};

	return (
		<React.Fragment>
			<h1 className="cdr-header-title">Call Detail Record</h1>
			<CallDetailRecordForm onSubmit={handleSubmit} />
			<CallDetailRecordTable
				cdrForm = {cdrForm}
				callHistory={callHistory}
				pagination={pagination}
				from={cdrForm && cdrForm.from}
				to={cdrForm && cdrForm.to}
			/>
		</React.Fragment>
	);
}

const mapStateToProps = state => ({
	callHistory: state.callDetailRecordReducer.history,
	pagination: state.callDetailRecordReducer.pagination,
	cdrForm: getFormValues('callDetailsRecordForm')(state),
});

const mapDispatchToProps = dispatch => ({
	getCallHistory: params => dispatch(getCallHistoryAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallDetailRecord);
