import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { Button } from '../../../../../../../Common/Components';
import ModalComponent from '../../../../../../../Common/Components/Modal/Modal';
import { urlService } from '../../../../../../../Common/Services';
import { locations } from "src/utils/locations";
/* Components */
import { EntityProfileSidebar, ProfileBreadcrumbs, TabsWithMenu } from '../../../../../Common';
import HospitalApprovalList from '../../../../../Features/HospitalApprovalList/HospitalApprovalList';
import { getcurrentUserTypes } from '../../../../../HomeSelectors';
import { getAllHospitalNetworksAction } from '../../../../HospitalNetwork/HospitalNetworkActions';
import HospitalUsers from '../../Components/HospitalUsers/HospitalUsers';
import Rooms from '../../Components/Rooms/Rooms';
import Can from '../../../../../../../Common/Acl/AccessControl';
/* Actions & Services */
import {
	createHospitalRoomAction,
	deleteHospitalAction,
	getHospitalByIdAction,
	getHospitalOrgSettingsByIdAction
} from "../../HospitalsActions";
import { getDepartmentByHospitalAction, setDepartmentsAction } from '../DepartmentProfile/DepartmentsActions';
import Departments from './Components/Departments/Departments';
import HospitalConsoles from './Components/HospitalConsoles/HospitalConsoles';
import HospitalInfo from './Components/HospitalInfo/HospitalInfo';
import HospitalSecurity from "src/domains/Hospitals/components/HospitalSecurity/HospitalSecurity";

class HospitalProfile extends PureComponent {
	constructor(props) {
		super(props);
		this.hospitalId = urlService.getQueryParam(props.location, 'hospitalId');

		this.state = {
			isDeleteModalOpened: false,
			activeTabIndex: 0,
			lastActiveTabIndex: 0,
		};
	}

	componentDidMount() {
		this.params = {
			hospitalId: this.hospitalId,
			size: this.state.pageSize,
			page: 0,
		};
		this.props.getHospitalById(this.hospitalId);
		this.props.getDepartmentsByHospital(this.params);
		this.props.getAllHospitalNetworks();
		this.props.getHospitalOrgSettings(this.hospitalId);
	}

	componentWillUnmount() {
		this.props.clearHospitalDepartments();
	}

	onCreateNewRoomHandler = data => this.props.createHospitalRoom(this.hospitalId, data.room, { ids: data.departments });

	onClickDeleteHandler = () => {
		this.toggleDeleteModal();
	}

	onDeleteHandler = () => {
		this.props.deleteHospital(this.props.hospital.id);
		this.toggleDeleteModal();
		this.props.history.push(locations.hospitals);
	};

	toggleDeleteModal = () => this.setState({
		isDeleteModalOpened: !this.state.isDeleteModalOpened,
	});

	handleTabChange = async (e, data) => {
		this.setState({
			lastActiveTabIndex: this.state.activeTabIndex,
		}, () => {
			this.setState({ activeTabIndex: data.activeIndex });
		});
	};

	renderOptions = () => (
		[
			{
				title: this.props.hospital.name,
				className: 'profile-breadcrumbs__menu-item--active',
				to: `/hospitals/profile?hospitalId=${this.hospitalId}`,
			},
		]
	);

	renderPanes = () => {
		const menuItems = (
			[{
				menuItem: 'General Info',
				render: () => <HospitalInfo hospitalId={this.hospitalId} />,
			}, {
				menuItem: 'Admins',
				render: () => <HospitalUsers hospitalId={this.hospitalId} />,
			}, {
				menuItem: this.props.t('features.org.hospital.department.name_plural'),
				render: () => <Departments hospitalId={this.hospitalId} />,
			}, {
				menuItem: 'Rooms',
				render: () =>
					(
						<Rooms
							departments={this.props.departments}
							hospitalId={this.hospitalId}
							onCreateNewRoomHandler={this.onCreateNewRoomHandler}
              profileType={'hospital'}
							urlParams={`?hospitalId=${this.hospitalId}`}
						/>
					),
			}, {
				menuItem: 'Consoles',
				render: () =>
					(
						<HospitalConsoles
							hospitalId={this.hospitalId}
						/>
					),
			}]
		);

		// TODO: use AccessControlService to check for view/read rights
		if (this.props.currentUserTypes.includes('HOSPITAL_ADMIN')) {
			menuItems.push({
				menuItem: 'Security',
				render: () => (
					<HospitalSecurity hospitalId={this.hospitalId} />
				),
			});
		}
		if (this.props.currentUserTypes.includes('AVAIL_ADMIN')) {
			menuItems.push({
				menuItem: 'Approval List',
				render: () => (
					<HospitalApprovalList hospitalId={this.hospitalId} />
				),
			});
		}

		menuItems.push({
			menuItem: (
				<Can userTypes={this.props.currentUserTypes} action="read" context="deleteHospital">
					<Menu.Item onClick={this.onClickDeleteHandler}>
						<Button className="vendor-profile__delete-button">
							Delete Facility
						</Button>
					</Menu.Item>
				</Can>
			),
			render: () => null,
		});

		return menuItems;
	}

	renderDeleteModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onDeleteHandler}
			onCancel={() => {
				this.toggleDeleteModal();
				this.setState({ activeTabIndex: this.state.lastActiveTabIndex });
			}}
			confirmText="Delete"
		>
			{this.props.hospital && (
				<span>
					You are about to delete <span className="text--bold">{this.props.hospital.name}</span>.
					Would you like to proceed with this action?
				</span>
			)}
		</ModalComponent>
	);

	render() {
		const { t } = this.props;

		return (
			<div className="display--flex height--full flex--one">
				<EntityProfileSidebar title={t('features.org.hospital.name')}>
					{this.props.hospital &&
					<ProfileBreadcrumbs options={this.renderOptions()} />
					}
				</EntityProfileSidebar>
				<div className="vendor-profile__right-side">
					<TabsWithMenu
						options={this.renderPanes()}
						activeIndex={this.state.activeTabIndex}
						onTabChange={this.handleTabChange}
					/>
				</div>

				{this.renderDeleteModal()}
			</div>
		);
	}
}

HospitalProfile.propTypes = {};

const mapStateToProps = state => ({
	hospital: state.hospitalsReducer.hospital,
	departments: state.departmentReducer.departments,
	currentUserTypes: getcurrentUserTypes(state),
});

const mapDispatchToProps = dispatch => ({
	getHospitalOrgSettings: hospitalId =>  dispatch(getHospitalOrgSettingsByIdAction(hospitalId)),
	getHospitalById: hospitalId => dispatch(getHospitalByIdAction(hospitalId)),
	createHospitalRoom: (hospitalId, roomData, departments) => dispatch(createHospitalRoomAction(hospitalId, roomData, departments)),
	getDepartmentsByHospital: payload => dispatch(getDepartmentByHospitalAction(payload)),
	getAllHospitalNetworks: () => dispatch(getAllHospitalNetworksAction()),
	deleteHospital: id => dispatch(deleteHospitalAction(id)),
	clearHospitalDepartments: () => dispatch(setDepartmentsAction([])),
});

const HospitalProfileWithTranslation = withTranslation()(HospitalProfile);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HospitalProfileWithTranslation));
