import { reduxForm } from "redux-form";

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Button } from "src/components/legacy/Button";
import { reCaptchaWrapper, showReCaptcha } from "src/services/ReCaptchaHelper";

import { Fields, validators } from "avail-web-application/Common/Components";
import userSessionService from "avail-web-application/Common/Services/userSesionService";
import { submitAuthEmailAction } from "avail-web-application/Features/Authentication/AuthenticationActions";
import withAuthentication from "avail-web-application/Features/Authentication/Components/withAuthentication";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

import { LoginPage } from "../../Login";
import "./LoginGuestView.scss";

interface DispatchProps {
  handleSubmit: (data) => any;
  submitAuthEmail: (data) => any;
}

const { label } = DomainConstants;

function LoginGuestView({ handleSubmit, submitAuthEmail }: DispatchProps) {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    showReCaptcha();
  }, []);

  const handleLoginFormSubmit = (loginData) => {
    const acceptedEula = !!userSessionService.getEulaVersion();
    if (acceptedEula) {
      setIsLoading(true);

      reCaptchaWrapper("login", (captchaVersion, captchaToken) =>
        submitAuthEmail({
          ...loginData,
          captchaToken,
          captchaVersion,
        })
      );
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    // have to use login class name since web-app uses hardcoded class names (as opposed to generated class names)
    <LoginPage className="login">
      <div className="login__subheading">{label.loginHub}</div>
      <form
        className="login__form"
        onSubmit={handleSubmit(handleLoginFormSubmit)}
      >
        <Fields.InputField
          data-test-id="username"
          labelText="Email"
          placeholder="Type your email"
          name="username"
          validate={[validators.required, validators.email]}
        />
        <Button
          data-test-id="loginSubmit"
          theme="green"
          className="login__button singular"
          type="submit"
          loading={isLoading}
          // TODO: move styles into custom MUI Button
          style={{
            marginTop: "20px",
            textTransform: "none",
          }}
        >
          {label.next}
        </Button>
      </form>
      <div className="login__sign-up">
        Is your employer an Avail member?
        <br />
        Contact&nbsp;
        <a href="mailto:support@avail.io">Avail Technical Support</a>
        &nbsp;to link your {label.memberHub} profile!
      </div>
    </LoginPage>
  );
}

const mapDispatchToProps = (dispatch) => ({
  submitAuthEmail: (data) => dispatch(submitAuthEmailAction(data)),
});

export default reduxForm({
  form: "loginForm",
})(withAuthentication(connect(null, mapDispatchToProps)(LoginGuestView)));
