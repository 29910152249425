import clsx from "clsx";

import { ICON_SIZES } from "src/components/CustomIcons/constants";

import styles from "./styles.scss";
import { IconProps } from "./types";

export const FourViewsSquareIcon = ({
  color = "white",
  size = "small",
  className,
}: IconProps) => {
  const [width, height] = [ICON_SIZES[size][0], ICON_SIZES[size][1]];
  return (
    <div style={{ width, height }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="4.5"
          y="4.5"
          width="7"
          height="7"
          rx="1"
          className={clsx(styles[color], className)}
        />
        <rect
          x="4.5"
          y="12.5"
          width="7"
          height="7"
          rx="1"
          className={clsx(styles[color], className)}
        />
        <rect
          x="12.5"
          y="4.5"
          width="7"
          height="7"
          rx="1"
          className={clsx(styles[color], className)}
        />
        <rect
          x="12.5"
          y="12.5"
          width="7"
          height="7"
          rx="1"
          className={clsx(styles[color], className)}
        />
      </svg>
    </div>
  );
};
