import moment from "moment";

// simple function that checks if a time is inbetween two other times
// useful to see for example if current time is inbetween a 8 hour range
export const datetimeIsBetween = (
  testTime: moment.Moment,
  startTime: moment.Moment,
  endTime: moment.Moment
) => {
  return testTime.isBetween(startTime, endTime);
};

// simple function to compare two moment times, will return if time A is before time B (to sort by newest to oldest)
export const datetimeIsBefore = (
  timeA: moment.Moment,
  timeB: moment.Moment
) => {
  return timeA.isBefore(timeB);
};

export const roundToNearest30Minute = (time: moment.Moment) => {
  // Current date time
  const now = moment(time);

  // Getting hour and minute
  const hour = time.hour();
  const minute = now.minute();

  // Rounding minute on 30 mins interval
  if (minute <= 30) {
    now.set({ minute: 30 });
  }
  if (minute > 30) {
    now.set({ hour: hour + 1, minute: 0 });
  }
  return now;
};
