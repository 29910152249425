import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { leaveCallThunk } from "src/domains/Beacon/store/twilio/thunks/leaveCall";
import { uiActions } from "src/domains/Beacon/store/ui/uiSlice";
import { logger } from "src/logging/logger";

const DEFAULT_END_CALL_TIMEOUT = 120;

interface IProps {
  duration?: number;
}

// This thunk starts
export function initiateLastParticipantTimerThunk({ duration }: IProps) {
  return (dispatch: AppDispatch) => {
    logger().info(`Timer for last participant remaining started`);

    const id = window.setTimeout(() => {
      logger().info(
        `Timer for last participant remaining hit the specified time so we are forcing the user to leave the call`
      );
      dispatch(leaveCallThunk());
    }, (duration || DEFAULT_END_CALL_TIMEOUT) * 1000);

    // grab the function id so we can cancel it later
    dispatch(uiActions.setLastParticipantTimerId(id));
  };
}

export function cancelInitiateLastParticipantTimerThunk() {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const { ui } = getState();
    const { lastParticipantTimerId } = ui;

    try {
      // close the modal
      dispatch(uiActions.setLastParticipantModalOpen(false));
      logger().info(
        `Timer for last participant remaining canceled by the user, they will remain on the call`
      );

      // clear the set timeout with the stored id from redux
      if (lastParticipantTimerId) {
        window.clearTimeout(lastParticipantTimerId);

        // reset the id in redux back to null just in case
        dispatch(uiActions.setLastParticipantTimerId(null));
      }
    } catch (error) {
      logger().error(
        "Error clearing timer for last participant remaining",
        JSON.stringify(error)
      );
    }
  };
}
