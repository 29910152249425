import { VENDORS } from './VendorsActionTypes';

export const getAllVendorsAction = params => ({
	type: VENDORS.GET_VENDORS,
	payload: params,
});

export const getVendorByIdAction = id => ({
	type: VENDORS.GET_VENDOR_BY_ID,
	payload: id,
});

export const getProfileInitialDataAction = url => ({
	type: VENDORS.GET_BRAND_BUSINESS_UNITS,
	payload: url,
});

export const selectBusinessUnitAction = businessUnitId => ({
	type: VENDORS.SELECT_BUSINESS_UNIT,
	payload: businessUnitId,
});

export const selectDistrictAction = districtId => ({
	type: VENDORS.SELECT_DISTRICT,
	payload: districtId,
});


export const createNewVendorAction = vendorData => ({
	type: VENDORS.CREATE_NEW_VENDOR,
	payload: vendorData,
});


export const createNewBusinessUnitAction = (businessUnitData, businessUnitType) => ({
	type: VENDORS.CREATE_NEW_BUSINESS_UNIT,
	businessUnitData,
	businessUnitType,
});

export const getBusinessUnitUsersAction = (businessUnitType, businessUnitId, params) => ({
	type: VENDORS.GET_BUSINESS_UNIT_USERS,
	businessUnitType,
	businessUnitId,
	params,
});

export const updateBusinessUnitUserAction = (userId) => ({
  type: VENDORS.UPDATE_BUSINESS_UNIT_USER_TO_MEMBER,
  userId
});

export const updateBusinessUnitAction = (businessUnitId, data, entityType) => ({
	type: VENDORS.UPDATE_BUSINESS_UNIT,
	businessUnitId,
	data,
	entityType,
});

export const deleteVendorAction = id => ({
	type: VENDORS.DELETE_VENDOR,
	payload: id,
});

export const deleteVendorDistrictAction = id => ({
	type: VENDORS.DELETE_VENDOR_DISTRICT,
	payload: id,
});

export const getVendorTherapiesAction = vendorId => ({
	type: VENDORS.GET_VENDOR_THERAPIES,
	payload: vendorId,
});

export const assignVendorProceduresAction = (vendorId, procedures) => ({
	type: VENDORS.ASSIGN_VENDOR_PROCEDURES,
	vendorId,
	procedures,
});

export const getVendorsForSelectedBrandAction = brandId => ({
	type: VENDORS.GET_VENDORS_FOR_BRAND,
	payload: brandId,
});

export const getManagersForSelectedBusinessUnitAction = businessUnitId => ({
	type: VENDORS.GET_MANAGERS_FOR_BUSINESS_UNIT,
	payload: businessUnitId,
});

export const getManagersBelowMeAction = () => ({
	type: VENDORS.GET_MANAGERS_BELOW_ME,
});

export const getAdminsAndManagersAction = organisationId => ({
	type: VENDORS.GET_ADMINS_AND_MANAGERS,
	payload: organisationId,
});

export const getVendorsHospitalsAction = payload => ({
	type: VENDORS.GET_VENDORS_HOSPITALS,
	payload,
});

export const getBusinessUnitsForSelectedBrandAction = brandId => ({
	type: VENDORS.GET_BUSINESS_UNITS_FOR_SELECTED_BRAND,
	payload: brandId,
});

export const updateVendorsHospitalsAction = (organisationId, data) => ({
	type: VENDORS.UPDATE_VENDORS_HOSPITALS,
	organisationId,
	data,
});

export const applyToSecondaryManagerAction = (userId, managerId, businessUnitId, businessUnitType, params) => ({
	type: VENDORS.APPLY_TO_SECONDARY_MANAGER,
	userId,
	managerId,
	businessUnitId,
	businessUnitType,
	params,
});

export const getUserTherapiesAction = userId => ({
	type: VENDORS.GET_USER_THERAPIES,
	payload: userId,
});

export const getTherapiesAction = userOrganisationId => ({
	type: VENDORS.GET_THERAPIES,
	payload: userOrganisationId,
});

export const getDistrictAndBUTherapiesAction = (districtId, vendorId) => ({
	type: VENDORS.GET_DISTRICT_AND_BU_THERAPIES,
	districtId,
	vendorId,
});

export const toggleMfaOrgLevelSettings = (payload) => ({
  type: VENDORS.TOGGLE_MFA_ORG_LEVEL_SETTINGS,
  payload
});
