import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { Grid, Typography } from "@mui/material";

import { AutocompleteTextField } from "src/components/legacy/AutocompleteTextField";
import { useAsyncAutocomplete } from "src/components/legacy/AutocompleteTextField/useAsyncAutocomplete";
import { Button } from "src/components/legacy/Button";
import { Checkbox } from "src/components/legacy/Checkbox";
import { FormErrorMessage } from "src/components/legacy/Errors";
import { ActionLink } from "src/components/legacy/Link";
import { TextField } from "src/components/legacy/TextField";
import {
  emailField,
  nameValidation,
  requiredField,
} from "src/constants/validation";
import { BusinessUnit, Facility } from "src/services/ApiClient/businessUnit";
import {
  getStatesForTrial,
  userTrialSignUp,
  getEmployerBusinessUnitsForTrial,
  getEmployerFacilitiesForTrial,
} from "src/services/ApiClient/users";

import { addNotificationAction } from "avail-web-ui/dux/NotificationSlice";

import styles from "./styles.scss";

interface DetailsFormTypes {
  firstName: string;
  lastName: string;
  userEmail: string;
  state: string;
  businessUnit: any | BusinessUnit; // TODO for some reason putting type of an Object makes the "error" obj in the form not work
  facility1: any | Facility; // TODO
  facility2: any | Facility; // TODO
  facility3: any | Facility; // TODO
  marketingEmails: boolean;
}

interface DetailFormProps {
  userEmail?: string;
  continueToNextStep: () => void;
}

export const DetailsForm = ({
  userEmail,
  continueToNextStep,
}: DetailFormProps) => {
  const { handleSubmit, control, formState } = useForm<DetailsFormTypes>({
    mode: "onChange",
    defaultValues: {
      userEmail, // we get this from the props (aka from step 1 of the form)
      firstName: "",
      lastName: "",
      state: "",
      businessUnit: null,
      facility1: null,
      facility2: null,
      facility3: null,
      marketingEmails: true,
    },
  });

  const dispatch = useDispatch();

  const submitForm = async (data: DetailsFormTypes) => {
    const facilitiesIds = [data.facility1.id];

    // facility 2 and 3 are optional so check if they exist before putting their IDs in the list
    if (data.facility2) {
      facilitiesIds.push(data.facility2.id);
    }
    if (data.facility3) {
      facilitiesIds.push(data.facility3.id);
    }

    const postBody = {
      email: data.userEmail,
      firstName: data.firstName,
      lastName: data.lastName,
      state: data.state,
      businessUnitId: data.businessUnit.id, // this is a facility object, get the id from the object
      facilties: facilitiesIds,
      marketingNotify: data.marketingEmails,
    };

    const { response, data: result, error } = await userTrialSignUp(postBody);

    // if we have an error then show a notification error
    if (error) {
      dispatch(
        addNotificationAction({
          message: error.message,
          type: "error",
        })
      );
    } else {
      if (response.ok) {
        // if no error then go the next step
        continueToNextStep();
      } else {
        if (response.status >= 400) {
          // Show the message from the backend here
          dispatch(
            addNotificationAction({
              message: result.message,
              type: "error",
            })
          );
        }
      }
    }
  };

  const [
    statesUserInput,
    setStatesUserInput,
    openStates,
    setOpenStates,
    stateOptionsFromAPI,
    loadingStates,
  ] = useAsyncAutocomplete((userInput: string) =>
    getStatesForTrial(userEmail, userInput)
  );

  const [
    BUUserInput,
    setBUUserInput,
    openBU,
    setOpenBU,
    BUOptionsFromAPI,
    loadingBU,
  ] = useAsyncAutocomplete((userInput: string) =>
    getEmployerBusinessUnitsForTrial(userEmail, userInput)
  );

  const [
    facility1UserInput,
    setFacility1UserInput,
    openFacility1,
    setOpenFacility1,
    facility1OptionsFromAPI,
    loadingFacility1,
  ] = useAsyncAutocomplete((userInput: string) =>
    getEmployerFacilitiesForTrial(userEmail, userInput)
  );

  // the user can add up to 2 more additional optional facility form fields
  // default it to 0 because they are optional and we already have 1 automatically displayed
  const [numberOfFacitilyFormFields, setNumberOfFacitilyFormFields] = useState(
    0
  );

  // ugly code but basically we need 3 facility fields
  // we have to have the state for the user input for each one too so need to have a hook for each
  const [
    facility2UserInput,
    setFacility2UserInput,
    openFacility2,
    setOpenFacility2,
    facility2OptionsFromAPI,
    loadingFacility2,
  ] = useAsyncAutocomplete((userInput: string) =>
    getEmployerFacilitiesForTrial(userEmail, userInput)
  );

  const [
    facility3UserInput,
    setFacility3UserInput,
    openFacility3,
    setOpenFacility3,
    facility3OptionsFromAPI,
    loadingFacility3,
  ] = useAsyncAutocomplete((userInput: string) =>
    getEmployerFacilitiesForTrial(userEmail, userInput)
  );

  return (
    <>
      <form
        data-test-id="trial-details-form"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(submitForm)}
        className={styles.form}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item container justifyContent="center">
            <Typography variant="h6">Just a few more details</Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            className={styles.wrapper}
          >
            {/* TODO since only a few places have thinner weights I just put it here, find a way to bake it into the a separate Font component */}
            <Typography style={{ fontWeight: 300 }} variant="h5">
              Access the network. Get started today.
            </Typography>
          </Grid>

          <Grid direction="row" spacing={2} container item>
            <Grid item lg={6}>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  ...requiredField("first name"),
                  ...nameValidation(),
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <TextField
                        data-test-id="trial-details-first-name"
                        label="First Name"
                        placeholder="Enter first name"
                        required
                        onChange={onChange}
                        value={value}
                      />
                      {error && (
                        <FormErrorMessage
                          message={error.message}
                          data-test-id="trial-details-first-name-error"
                        />
                      )}
                    </>
                  );
                }}
              />
            </Grid>

            <Grid item lg={6}>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  ...requiredField("last name"),
                  ...nameValidation(),
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <TextField
                        data-test-id="trial-details-last-name"
                        label="Last Name"
                        placeholder="Enter last name"
                        required
                        onChange={onChange}
                        value={value}
                      />
                      {error && (
                        <FormErrorMessage
                          message={error.message}
                          data-test-id="trial-details-last-name-error"
                        />
                      )}
                    </>
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid item container>
            <Controller
              name="userEmail"
              control={control}
              rules={{
                ...emailField("Email", false),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <TextField
                      data-test-id="trial-details-user-email"
                      label="Work Email Address"
                      placeholder="Work Email Address"
                      required
                      onChange={onChange}
                      value={value}
                      disabled // disable this field because we already got their email from step 1
                    />
                    {error && (
                      <FormErrorMessage
                        message={error.message}
                        data-test-id="trial-details-user-email-error"
                      />
                    )}
                  </>
                );
              }}
            />
          </Grid>

          <Grid item container>
            <Controller
              name="state"
              control={control}
              rules={{
                ...requiredField("state"),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <AutocompleteTextField
                      placeholderLabel="Select state"
                      name="state"
                      data-test-id="trial-details-state"
                      label="State"
                      getOptionLabel={(option: string) => option}
                      value={value}
                      options={stateOptionsFromAPI}
                      required
                      loading={loadingStates}
                      onChange={(newValue: string) => {
                        onChange(newValue);
                        setStatesUserInput("");
                      }}
                      // if the user hasn't typed anything then let them know that they should type
                      noOptionsText={
                        value === ""
                          ? "Start typing to find options..."
                          : "No options found"
                      }
                      open={openStates}
                      onOpen={() => {
                        setOpenStates(true);
                      }}
                      onClose={() => {
                        setOpenStates(false);
                      }}
                      inputValue={statesUserInput}
                      onInputChange={(val) => setStatesUserInput(val)}
                    />
                    {error && (
                      <FormErrorMessage
                        message={error.message}
                        data-test-id="trial-details-state-error"
                      />
                    )}
                  </>
                );
              }}
            />
          </Grid>

          <Grid item container>
            <Controller
              name="businessUnit"
              control={control}
              rules={{
                ...requiredField("business unit"),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <AutocompleteTextField
                      placeholderLabel="Select Business Unit"
                      name="businessUnit"
                      data-test-id="trial-details-business-unit"
                      label="Employer Business Unit"
                      getOptionLabel={(option: BusinessUnit) =>
                        `${option.name !== undefined ? option.name : ""}`
                      }
                      value={value}
                      options={BUOptionsFromAPI}
                      isOptionEqualToValue={(
                        option: BusinessUnit,
                        val: BusinessUnit
                      ) => {
                        return val.name === option.name;
                      }}
                      required
                      loading={loadingBU}
                      onChange={(newValue) => {
                        onChange(newValue);
                        setBUUserInput("");
                      }}
                      // if the user hasn't typed anything then let them know that they should type
                      noOptionsText={
                        value
                          ? "Start typing to find options..."
                          : "No options found"
                      }
                      open={openBU}
                      onOpen={() => {
                        setOpenBU(true);
                      }}
                      onClose={() => {
                        setOpenBU(false);
                      }}
                      inputValue={BUUserInput}
                      onInputChange={(val) => setBUUserInput(val)}
                    />
                    {error && (
                      <FormErrorMessage
                        message={error.message}
                        data-test-id="trial-details-business-unit-error"
                      />
                    )}
                  </>
                );
              }}
            />
          </Grid>

          <Grid item container>
            <Controller
              name="facility1"
              control={control}
              rules={{
                ...requiredField("facility"),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <AutocompleteTextField
                      placeholderLabel="Select Hospital or Medical Facility"
                      name="facility1"
                      data-test-id="trial-details-facility1"
                      label="Name of Facility"
                      getOptionLabel={(option: Facility) =>
                        `${option.name !== undefined ? option.name : ""}`
                      }
                      value={value}
                      options={facility1OptionsFromAPI}
                      isOptionEqualToValue={(
                        option: Facility,
                        val: Facility
                      ) => {
                        return val.name === option.name;
                      }}
                      required
                      loading={loadingFacility1}
                      onChange={(newValue) => {
                        onChange(newValue);
                        setFacility1UserInput("");
                      }}
                      // if the user hasn't typed anything then let them know that they should type
                      noOptionsText={
                        value
                          ? "Start typing to find options..."
                          : "No options found"
                      }
                      open={openFacility1}
                      onOpen={() => {
                        setOpenFacility1(true);
                      }}
                      onClose={() => {
                        setOpenFacility1(false);
                      }}
                      inputValue={facility1UserInput}
                      onInputChange={(val) => setFacility1UserInput(val)}
                    />
                    {error && (
                      <FormErrorMessage
                        message={error.message}
                        data-test-id="trial-details-facility1-error"
                      />
                    )}
                  </>
                );
              }}
            />
          </Grid>

          {numberOfFacitilyFormFields > 0 && (
            <Grid item container>
              <Controller
                name="facility2"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <AutocompleteTextField
                        placeholderLabel="Select Another Hospital or Medical Facility"
                        name="facility2"
                        data-test-id="trial-details-facility2"
                        label="Name of Second Facility"
                        getOptionLabel={(option: Facility) =>
                          `${option.name !== undefined ? option.name : ""}`
                        }
                        value={value}
                        options={facility2OptionsFromAPI}
                        isOptionEqualToValue={(
                          option: Facility,
                          val: Facility
                        ) => {
                          return val.name === option.name;
                        }}
                        loading={loadingFacility2}
                        onChange={(newValue) => {
                          onChange(newValue);
                          setFacility2UserInput("");
                        }}
                        // if the user hasn't typed anything then let them know that they should type
                        noOptionsText={
                          value
                            ? "Start typing to find options..."
                            : "No options found"
                        }
                        open={openFacility2}
                        onOpen={() => {
                          setOpenFacility2(true);
                        }}
                        onClose={() => {
                          setOpenFacility2(false);
                        }}
                        inputValue={facility2UserInput}
                        onInputChange={(val) => setFacility2UserInput(val)}
                      />
                      {error && (
                        <FormErrorMessage
                          message={error.message}
                          data-test-id="trial-details-facility2-error"
                        />
                      )}
                    </>
                  );
                }}
              />
            </Grid>
          )}

          {numberOfFacitilyFormFields > 1 && (
            <Grid item container>
              <Controller
                name="facility3"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <AutocompleteTextField
                        placeholderLabel="Select Another Hospital or Medical Facility"
                        name="facility3"
                        data-test-id="trial-details-facility3"
                        label="Name of Third Facility"
                        getOptionLabel={(option: Facility) =>
                          `${option.name !== undefined ? option.name : ""}`
                        }
                        value={value}
                        options={facility3OptionsFromAPI}
                        isOptionEqualToValue={(
                          option: Facility,
                          val: Facility
                        ) => {
                          return val.name === option.name;
                        }}
                        loading={loadingFacility3}
                        onChange={(newValue) => {
                          onChange(newValue);
                          setFacility3UserInput("");
                        }}
                        // if the user hasn't typed anything then let them know that they should type
                        noOptionsText={
                          value
                            ? "Start typing to find options..."
                            : "No options found"
                        }
                        open={openFacility3}
                        onOpen={() => {
                          setOpenFacility3(true);
                        }}
                        onClose={() => {
                          setOpenFacility3(false);
                        }}
                        inputValue={facility3UserInput}
                        onInputChange={(val) => setFacility3UserInput(val)}
                      />
                      {error && (
                        <FormErrorMessage
                          message={error.message}
                          data-test-id="trial-details-facility3-error"
                        />
                      )}
                    </>
                  );
                }}
              />
            </Grid>
          )}
          {numberOfFacitilyFormFields < 2 && (
            <Grid item container justifyContent="flex-end">
              <ActionLink
                data-test-id="trial-details-add-another-facility-link"
                onClick={() => {
                  setNumberOfFacitilyFormFields((prevState) => prevState + 1);
                }}
              >
                Add another
              </ActionLink>
            </Grid>
          )}

          <Grid item container>
            <Controller
              name="marketingEmails"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <Checkbox
                      data-test-id="trial-details-marketing-emails"
                      checked={value}
                      onChange={onChange}
                      name="marketingEmails"
                      label="Yes, I want to receive product and promotional emails from Avail!"
                      color="primary"
                    />
                    {error && (
                      <FormErrorMessage
                        message={error.message}
                        data-test-id="trial-details-marketing-emails-error"
                      />
                    )}
                  </>
                );
              }}
            />
          </Grid>

          <Grid item container>
            <Button
              data-test-id="trial-details-form-continue-button"
              theme="green"
              type="submit"
              loading={formState.isSubmitting}
              disabled={!formState.isValid || formState.isSubmitting}
              fullWidth
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
