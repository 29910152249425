import { renderTraceIdHeader } from "src/domains/Beacon/utils/renderTraceIdHeader";
import { logger } from "../common/logger";
import { ENDPOINTS } from "../constants";
import { ApiResponse } from "./types";

/**
 * @param payload Defined structure for the metrics backend to process accordingly (NetworkQualityPayload)
 * Example use: const { data, error } = await API.POST.networkQualityPoll(payload)
 */
const refreshTwilioToken = async (
  access_token: string,
  callSid: string,
  joinId: string
): Promise<ApiResponse<any>> => {
  const url = `${ENDPOINTS.DOMAIN}/callevent/session/${callSid}/users/${joinId}/refreshToken`;
  try {
    const opts = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
        ...renderTraceIdHeader(),
        Accept: "application/json"
      }
    };

    const res = await fetch(url, opts);
    const refreshResponse = await res.json();
    const newToken = refreshResponse.content.twilioToken;

    logger().info(`Obtained fresh twilio token`);
    logger().verbose(
      `Obtained fresh twilio token: ${newToken.substring(0, 10)}...`
    );
    return new Promise((resolve, reject) => {
      resolve({ data: newToken });
    });
  } catch (error) {
    logger().error("Error during Twilio RefreshToken", JSON.stringify(error));
    return error; // error };
  }
};

export default refreshTwilioToken;
