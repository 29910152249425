import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { PresenceServerConsoleDetails } from "src/services/ApiClient/presence";

// Sidebar status must be retrieved only once at the beginning of the call and stored
export const useGetSidebarStatus = (
  sessionDetails: PresenceServerConsoleDetails
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionDetails) {
      const sidebarActive = sessionDetails.sidebarActive;
      dispatch(streamActions.setSidebarState(sidebarActive));
    }
  }, [sessionDetails]);
};
