import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from '../../../../../../../Common';
import InvoiceHistoryTableRow from './Components/InvoiceHistoryTableRow';

class InvoiceHistoryTable extends PureComponent {
	constructor(props) {
		super(props);
		this.columns = [{
			title: 'Invoice Number',
			sortValue: 'name',
		}, {
			title: 'Amount',
			sortValue: 'total',
		}, {
			title: 'Invoice date',
			sortValue: 'date',
		}, {
			title: 'Status',
			sortValue: 'status',
		}];
	}


	render() {
		return (
			<React.Fragment>
				<Table
					row={InvoiceHistoryTableRow}
					columns={this.columns}
					tableData={this.props.invoices}
					paginationConfig={this.props.pagination}
					onPageChangeHandler={this.onPageChangeHandler}
					onSortTableHandler={this.onSortTableHandler}
					className="console__table"
					noItemsClass="height--full justify-content--center align--center"
					headerTitle={this.props.headerTitle}
					sortable
				/>
			</React.Fragment>
		);
	}
}

InvoiceHistoryTable.defaultProps = {
	invoices: [],
	pagination: null,
};

InvoiceHistoryTable.propTypes = {
	invoices: PropTypes.array,
	pagination: PropTypes.object,
};

export default InvoiceHistoryTable;
