import { AppDispatch } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting/meetingSlice";
import { getCallDetailsThunk } from "src/domains/Beacon/store/meeting/thunks/getCallDetailsThunk";
import {
  CallModes,
  MPUserRoles,
  MeetingToken,
} from "src/domains/Beacon/store/meeting/types";
import { logger } from "src/logging/logger";

interface IParams {
  encodedMeetingToken: string;
}

export const getMeetingTokenThunk = ({
  encodedMeetingToken,
}: IParams) => async (dispatch: AppDispatch) => {
  try {
    logger().info("** Obtaining meeting token from URL **");

    // There should always be a meetingToken query param on portalcall
    // If there isn't this will throw an error
    const meetingToken: MeetingToken = JSON.parse(atob(encodedMeetingToken));

    // Meeting token doesn't use any external APIs, it's retrieved from the
    // URL directly and then decoded
    dispatch(meetingActions.setMeetingToken(meetingToken));

    if (meetingToken.mode === CallModes.MP) {
      logger().info("Received a request to launch a MP event");

      // Must show error if meeting token info is incomplete
      if (!meetingToken.eventId && !meetingToken.joinId) {
        logger().error(
          "Received a request to launch a MultiParty Event. No EventId or JoinId."
        );
      }

      dispatch(meetingActions.setMode(CallModes.MP));
      dispatch(meetingActions.setJoinId(meetingToken.joinId));
      dispatch(
        meetingActions.setIsUserHost(meetingToken.userRole === MPUserRoles.HOST)
      );

      // Must set the common fields logs for a MP call
      logger().setCommonFields({
        eventId: meetingToken.eventId ?? "",
        role: meetingToken.userRole?.toUpperCase(),
      });

      // Will get the call details from external API
      await dispatch(getCallDetailsThunk());
    } else {
      // the current user is the host for all P2P calls
      dispatch(meetingActions.setIsUserHost(true));

      // if we have a session id just use it
      if (meetingToken.session_id) {
        logger().info("Received a request to launch a p2p call");
        dispatch(meetingActions.setMode(CallModes.P2P));
        dispatch(meetingActions.setJoinId(meetingToken.join_id));
        dispatch(meetingActions.setCallSid(meetingToken.session_id));

        // Must set the common fields logs for a P2P call
        logger().setCommonFields({
          callSessionId: meetingToken.session_id,
          profileId: meetingToken?.profile_id,
          eventId: "",
          role: "",
        });

        // otherwise if its a callback event then we have to grab the session id in a different way
      } else if (meetingToken.callback) {
        logger().info("Received a request to launch a call from callback");
        dispatch(meetingActions.setMode(CallModes.P2P));
        dispatch(meetingActions.setIsCallback(true));

        // the meeting token event has the info we need to join the call
        const acceptEvent = meetingToken.event;
        const sessionId = acceptEvent._session_id;
        const userJoinId = acceptEvent._user_joinId;
        const userProfileId = acceptEvent._user_profileId;

        dispatch(meetingActions.setJoinId(userJoinId));
        dispatch(meetingActions.setCallSid(sessionId));

        // Must set the common fields logs for a callback call
        logger().setCommonFields({
          callSessionId: sessionId,
          profileId: userProfileId,
        });
      }
    }
  } catch (error: any) {
    logger().error(
      "Error while extracting meeting token. useGetMeetingToken",
      error?.message
    );
  }
};
