import classNames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popup, Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
/* Components */
import { Pill } from '../../../../../../../../Common';
import DropdownComponent from '../../../../../../../../Common/DropdownComponent/DropdownComponent';

class HospitalApplicationRow extends PureComponent {
	openRemoveApplicationModal = () => this.props.openModal(this.props.rowData);

	renderRow = () => {
		const { name, state, status, blocked } = this.props.rowData;

		return (
			<Table.Row>
				<Table.Cell
					title={[name].join(' ')}
					className={classNames({
						'table__row--inactive': blocked,
					})}
				>
					{name}
				</Table.Cell>
				<Table.Cell
					title={[state].join(' ')}
					className={classNames({
						'table__row--inactive': blocked,
					})}
				>
					{state}
				</Table.Cell>
				<Table.Cell
					title={[status].join(' ')}
					className={classNames({
						'table__row--inactive': blocked,
					})}
				>
					<div>
						<Pill
							class={`status status--${status.toLowerCase()} `}
							title={status.toLowerCase()}
						/>
					</div>
				</Table.Cell>
				<Table.Cell
					className="table__cell table__action-table-cell"
					width={3}
					textAlign="right"
					style={{
						overflow: 'visible',
					}}
				>
					<DropdownComponent
						dropdownOptions={[{
							label: blocked ? 'Unblock' : 'Block',
							text: blocked ? 'Unblock' : 'Block',
							action: this.openRemoveApplicationModal,
							icon: 'icon-slash-outline',
						}]}
					/>
				</Table.Cell>
			</Table.Row>
		);
	};

	render() {
		const { blocked } = this.props.rowData;
		return (
			blocked ? (
				<Popup
					position="bottom left"
					trigger={this.renderRow()}
					hoverable
					fluid
					basic
				>
					{blocked ? 'This facility is blocked' : ''}
				</Popup>
			) : this.renderRow()
		);
	}
}

HospitalApplicationRow.defaultProps = {
	rowData: null,
	openModal: () => {},
};

HospitalApplicationRow.propTypes = {
	rowData: PropTypes.object,
	openModal: PropTypes.func,
};

export default withRouter(HospitalApplicationRow);
