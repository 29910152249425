import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { withTranslation } from 'react-i18next';
/* Images */
import { addNewHospital } from '../../../../../Common/Assets/Images';
import CreateNewHospital from './Features/CreateNewHospital/CreateNewHospital';
/* Actions */
import { createNewHospitalAction, deleteHospitalAction, getAllHospitalsAction } from './HospitalsActions';
/* Components */
import { Table } from '../../../Common';
import HospitalsTableRow from './Components/HospitalsTableRow/HospitalsTableRow';
import AddNewEntity from '../../../Common/AddNewEntity/AddNewEntity';
import ModalComponent from '../../../../../Common/Components/Modal/Modal';

import './Hospital.scss';

class Hospitals extends PureComponent {
	state = {
		isDeleteModalOpened: false,
		organisation: null,
		pageSize: 10,
	};

	componentDidMount() {
		this.params = {
			size: this.state.pageSize,
			page: 0,
		};
	}

	onConfirm = () => {
		this.props.deleteHospital(this.state.organisation.id);
		this.toggleModal();
	};

	onPageChangeHandler = (e, { activePage }) => {
		this.params = {
			...this.params,
			size: this.state.pageSize,
			page: activePage - 1,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllHospitals(this.params);
	};

	onCreateNewHospitalHandler = (values) => {
		this.setState({ isCreateNewHospitalModalOpened: !this.state.isCreateNewHospitalModalOpened });
		this.props.createNewHospital(values);
	};

	onSortTableHandler = (clickedColumn) => {
		this.sortParams = clickedColumn;
		this.params = {
			...this.params,
			sort: this.sortParams,
		};
		this.props.getAllHospitals(this.params);
	};

	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;

		this.params = {
			size: this.params.size,
			[this.parameter]: this.sortParams,
		};

    // always move to page 0 when updating the filter
    this.params.page = 0;

		this.props.getAllHospitals(this.params);
	};

	onTableSortHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			...this.params,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllHospitals(this.params);
	};

	handleVariablePaginationChange = (option) => {
		if (!option) return;

		this.setState({ pageSize: option });
		this.props.getAllHospitals({
			size: option,
			page: 0,
			[this.parameter]: this.sortParams 
		});
	}

	params = {
		size: this.state.pageSize,
		page: 0,
	};

	columns = [
		{
			title: 'Name',
			sortValue: 'name',
		},
		{
			title: 'State',
			sortValue: 'state',
		},
		{
			title: 'City',
			sortValue: 'city',
		},
		{
			title: 'Address',
			sortValue: 'address',
		}
	];

	toggleCreateNewHospitalModal = () => {
		this.props.resetReduxForm('hospitalsForm');
		this.setState({ isCreateNewHospitalModalOpened: !this.state.isCreateNewHospitalModalOpened });
	};

	toggleModal = (organisation = {}) => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
			organisation,
		});
	};

	renderCreateNewHospitalModal = () => {
		const { t } = this.props;

		return (
			<ModalComponent
				open={this.state.isCreateNewHospitalModalOpened}
				hasActionButtons={false}
				onCancel={this.toggleCreateNewHospitalModal}
				title={t('features.org.hospital.create.label')}
			>
				<CreateNewHospital
					onSubmit={this.onCreateNewHospitalHandler}
					toggleCreateNewHospitalModal={this.toggleCreateNewHospitalModal}
				/>
			</ModalComponent>
		);
	};

	renderDeleteHospitalModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onConfirm}
			onCancel={this.toggleModal}
			confirmText="Delete"
		>
			{
				this.state.organisation &&
				<span>
					You are about to delete <span className="text--bold">{this.state.organisation.name}</span>.
					Would you like to proceed with this action?
				</span>
			}
		</ModalComponent>
	);

	render() {
		const { t } = this.props;
		
		return (
			<div className="users__container">
				<React.Fragment>
					<Table
						row={HospitalsTableRow}
						columns={this.columns}
						tableData={this.props.hospitals}
						headerTitle={t('features.org.hospital.name_plural')}
						openModal={this.toggleModal}
						paginationConfig={this.props.pagination}
						onPageChangeHandler={this.onPageChangeHandler}
						onSortTableHandler={this.onTableSortHandler}
						onSearchTermUpdateHandler={this.onTableFilterHandler}
						noItemsClass="height--full justify-content--center align--center"
						onAddNewHandler={this.toggleCreateNewHospitalModal}
						noEntityComponent={
							<AddNewEntity
								image={addNewHospital}
								addNewEntityHandler={this.toggleCreateNewHospitalModal}
								title={t('features.org.hospital.create.label')}
							/>
						}
						search
						sortable
						variablePagination
						onVariablePaginationChange={this.handleVariablePaginationChange}
					/>
				</React.Fragment>
				{this.renderDeleteHospitalModal()}
				{this.renderCreateNewHospitalModal()}
			</div>
		);
	}
}

Hospitals.defaultProps = {
	hospitals: null,
};

Hospitals.propTypes = {
	hospitals: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => ({
	hospitals: state.hospitalsReducer.hospitals,
	pagination: state.hospitalsReducer.pagination,
});

const mapDispatchToProps = dispatch => ({
	getAllHospitals: params => dispatch(getAllHospitalsAction(params)),
	deleteHospital: id => dispatch(deleteHospitalAction(id)),
	createNewHospital: hospitalData => dispatch(createNewHospitalAction(hospitalData)),
	resetReduxForm: formName => dispatch(reset(formName)),
});

const HospitalsWithTranslation = withTranslation()(Hospitals);

export default connect(mapStateToProps, mapDispatchToProps)(HospitalsWithTranslation);
