import { RemoteTrackPublication } from "twilio-video";

import { useDispatch } from "react-redux";

import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import { uiActions } from "src/domains/Beacon/store/ui";
import {
  UINotificationTypes,
  UIBadgeTypes,
  generateBadge,
} from "src/domains/Beacon/store/ui/types";
import { logger } from "src/logging/logger";

interface IResult {
  hostTrackEnabled: (publication: RemoteTrackPublication) => void;
  consoleTrackEnabled: (publication: RemoteTrackPublication) => void;
  participantTrackEnabled: (publication: RemoteTrackPublication) => void;
}

// Detects when a user enables unmute its audio/video tracks
export const useRoomTrackEnabled = (): IResult => {
  const dispatch = useDispatch();

  const hostTrackEnabled = (publication: RemoteTrackPublication) => {
    logger().info(`Host trackEnabled( ${publication.kind} )`);
    // Host has enabled its video/audio tracks
    dispatch(twilioActions.setHostHasJoinedRoom(true));
  };

  const consoleTrackEnabled = (publication: RemoteTrackPublication) => {
    logger().info(`Console trackEnabled( ${publication.kind} )`);

    // Console has enabled its video/audio tracks
    dispatch(
      streamActions.setConsoleAudioVideo({ [publication.kind]: "unmute" })
    );

    if (publication.kind === "audio") {
      // trigger audio unmute toast notification
      dispatch(
        uiActions.triggerNotification(UINotificationTypes.CONSOLE_AUDIO_UNMUTED)
      );

      // remove mute badge
      const badge = generateBadge(UIBadgeTypes.CONSOLE_AUDIO_MUTED);
      dispatch(uiActions.removeBadge(badge));
    }
  };

  const participantTrackEnabled = (publication: RemoteTrackPublication) => {
    logger().info(`Remote Participant trackEnabled( ${publication.kind} )`);
    // Participant has enabled its video/audio tracks
    dispatch(twilioActions.setRemoteHasJoinedRoom(true));
  };

  return { hostTrackEnabled, consoleTrackEnabled, participantTrackEnabled };
};
