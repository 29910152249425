export const CAMERA_NOT_DETECTED: string = "CAMERA_NOT_DETECTED";
export const CAMERA_NOT_AVAILABLE: string = "CAMERA_NOT_AVAILABLE";

export const ERROR_CODES = {
  CAMERA_NOT_DETECTED: {
    title: "Camera Not Detected",
    message: "Call cannot proceed.",
    actionMessage: "Please check hardward connections."
  },

  CAMERA_NOT_AVAILABLE: {
    title: "Camera Unavailable",
    message: "Call cannot proceed.",
    actionMessage:
      "Please check camera permissions or if other apps are using camera."
  },
  MICROPHONE_NOT_AVAILABLE: "Unable to detect microphone",
  UNABLE_TO_JOIN: "Unable to join"
};
