import moment from "moment";

import {
  MultiPartyEvent,
  EventStatus,
  Participant,
  Role,
} from "src/services/ApiClient/scheduler";

import { datetimeIsBetween } from "avail-web-ui/utils/datetime";

import {
  createUser,
  CreateUserRequest,
  UserTypes,
} from "../../../avail-web-ui/src/services/ApiClient/Users";

// used in multiple functions
export const DAY_KEY_FORMAT = "MMMM D YYYY"; // "September 28 2021"

export function generateMeetingToken(
  joinId: string,
  eventId: string,
  userRole: Role
) {
  return btoa(
    JSON.stringify({
      mode: "MP",
      userRole,
      joinId,
      eventId,
    })
  );
}

// TODO move to Users
export const autoCreateUser = (
  email: string,
  orgId: string,
  newUserType: UserTypes
) => {
  const newUser: CreateUserRequest = {
    autoCreated: true,
    firstName: "firstName",
    lastName: "lastName",
    email,
    orgId,
    userType: newUserType,
  };
  return createUser(newUser);
};

// For events in the CREATED status, we check if current time is between "start time" and "start time plus 1 hour"
// this function will return true or false if a CREATED event is considered expired, meaning the current time is outside the accepted range
// for all other event types it will return false since we expect this function only to filter on CREATED event

// Unused in events view as of 07/28/21, backend has an EXPIRED flag now, TODO remove this later
export const isCreatedEventExpired = (event: MultiPartyEvent) => {
  if (event.status === EventStatus.created) {
    const currentTime = moment.utc();

    const startTime = moment(event.startDate).utc();
    // we consider created events to be "expired" if the current time is past 1 hour from the start time
    // note that events have an endDate but we were asked to do this logic on the frontend
    const endTime = moment(event.startDate).utc().add(1, "hour");

    // if the time is not between the range then we consider this event expired
    return !datetimeIsBetween(currentTime, startTime, endTime);
  }

  return false;
};

// Checking to see if a user was invited to the event by checking the participants list
export const isUserInvitedToEvent = (event: MultiPartyEvent, email: string) => {
  const foundUser = event.participants.find((user) => user.email === email);

  // if foundUser is not undefined then that means they are invited to this event
  return foundUser !== undefined;
};

export const getMeetingToken = (event: MultiPartyEvent, joinId: string) => {
  const eventId = event.eventId;

  const currentEventParticipant = event.participants.find(
    (user) => user.loginId === joinId
  );

  return generateMeetingToken(
    joinId, // TODO: might not need this in the future?
    eventId,
    currentEventParticipant.role
  );
};

// Groups the events by days in an array with the following structure:
// [
//   { day: 'September 28 2021': events: [Event{}, Event{}] },
//   { day: 'September 29 2021', events: [Event{}] },
// etc
// ]
export function groupByDays(events: MultiPartyEvent[]) {
  const daysGroup = events.reduce((daysArray, current) => {
    let dayName;

    const startDate = moment(current.startDate);
    const endDate = moment(current.endDate);
    const now = moment();
    // If its a multi-day event, just add in the value for today
    if (
      checkMultiDayEvent(current) &&
      now.isBetween(startDate, endDate, "days", "[]")
    ) {
      dayName = moment().format(DAY_KEY_FORMAT);
    } else {
      dayName = moment(current.startDate).format(DAY_KEY_FORMAT);
    }

    // if the day (EG September 28) is not yet stored, do so. Otherwise just go on
    if (daysArray.find((m) => m.day === dayName) === undefined) {
      return [...daysArray, { day: dayName, events: [] }];
    }

    return daysArray;
  }, []);

  return events.reduce(
    (days, current) => {
      // get the day name for the current event

      const startDate = moment(current.startDate);
      const endDate = moment(current.endDate);
      const now = moment();
      // if the event is multi-day, we will only want to "display" it for today
      let dayName;
      if (
        checkMultiDayEvent(current) &&
        now.isBetween(startDate, endDate, "days", "[]")
      ) {
        dayName = moment().format(DAY_KEY_FORMAT); // today
      } else {
        dayName = moment(current.startDate).format(DAY_KEY_FORMAT);
      }

      // look for that dayName in the existing `daysGroup` array
      const dayIndex = daysGroup.findIndex((d) => d.day === dayName);

      // store the event (`current`) in that founded dayName
      days[dayIndex].events.push(current);
      return days;
    },
    [...daysGroup]
  );
}

// returns true if the event spans through more than 1 day
export function checkMultiDayEvent(event: MultiPartyEvent) {
  const startDay = moment(event.startDate).format(DAY_KEY_FORMAT);
  const endDay = moment(event.endDate).format(DAY_KEY_FORMAT);

  return startDay !== endDay;
}

// Removes duplicates from an array of events
export function removeEventDuplicates(events: MultiPartyEvent[]) {
  const ids = events.map((ev) => ev.eventId); // [{id: 1}, {id: 2}, {id: 3}, {id: 2}]
  return events.filter(
    ({ eventId }, index) => !ids.includes(eventId, index + 1)
  );
}

// Return full name if participant is a user or email if a guest user
export const participantDisplayValue = (participant: Participant) => {
  if (participant) {
    if (participant.isMember) {
      return `${participant.firstName} ${participant.lastName}`;
    } else {
      return participant.email;
    }
  }
  return "";
};

// TODO:unit tests for these
export const checkCurrentUserEventCreator = (
  event: MultiPartyEvent,
  userId: string
) => event.creatorUserId === userId;

export const checkEventStarted = (event: MultiPartyEvent) =>
  event.status === EventStatus.started;

// Returns true if the event is scheduled for today or before the start time
// for cases where its a 2 day event, if the user joins on the 2nd day, that means the start date is "before" the current date
export const checkEventJoinable = (event: MultiPartyEvent) =>
  // if start date is 11-1-2021 and current date is 11-1-2021 or 11-2-2021 this returns true
  moment(event.startDate).isSameOrBefore(new Date(), "day");

// Returns true if an event is editable by the current user
export const checkEventEditable = (
  event: MultiPartyEvent,
  isCurrentUserCreator: boolean
) => {
  // If the current user is not the creator, it cannot be edited
  if (!isCurrentUserCreator) {
    return false;
  }

  // For ended events, only those that are ended on the current day can be edited
  if (event.status === EventStatus.ended) {
    return moment(event.endDate).isSameOrAfter(new Date(), "day");
  }

  // If the event status is created or started, it should be editable irrespective of start/end dates
  if (
    event.status === EventStatus.created ||
    event.status === EventStatus.started
  ) {
    return true;
  }
};

// Returns true if event is ENDED or EXPIRED
export const checkEventEnded = (event: MultiPartyEvent) =>
  event.status === EventStatus.ended || event.status === EventStatus.expired;

// Returns true if the current user is a participant in the given event
export const checkCurrentUserAParticipant = (
  event: MultiPartyEvent,
  userLoginId
) => event.participants.some((p) => p.loginId === userLoginId);
