import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { Fields } from '../../../../Common/Components';
import './VariablePaginationComponent.scss';

function VariablePaginationComponent({ onVariablePaginationChange, initialize }) {
	useEffect(() => {
		initialize({
			variablePagination: 10,
		});
	}, []);

	const options = [
		{
			key: 10,
			value: 10,
			text: '10',
		},
		{
			key: 100,
			value: 100,
			text: '100',
		},
		{
			key: 'all',
			value: 'all',
			text: 'All',
		},
	];

	return (
		<>
			<div className="pagination-dropdown">
				<Fields.SelectField
					labelText="Items per page"
					placeholder="10, 100, All"
					name="variablePagination"
					options={options}
					onChange={onVariablePaginationChange}
				/>
			</div>
		</>
	);
}

export default reduxForm({
	form: 'variablePaginationForm',
	initialValues: {
		variablePagination: 10,
	},
})(VariablePaginationComponent);
