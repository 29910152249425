import { put, select, takeLatest } from "redux-saga/effects";
import { AppState } from "../../models";
import {
  disconnectFromRoomFailed,
  disconnectFromRoomSuccess,
  logActivity
} from "../actions";
import { TwilioActionKeys } from "../actionTypes";
import { logger } from "../../../common/logger";

export function* disconnectFromRoom() {
  const room = yield select((state: AppState) => state.twilio.room);

  try {
    room.disconnect();
    yield put(disconnectFromRoomSuccess());
    logger().info(`Local user has left the room '${room.name}'.`);
    yield put(logActivity(`You have left the room '${room.name}'.`));
  } catch (e) {
    yield put(disconnectFromRoomFailed());
    logger().error(
      `Error while trying to leave '${room.name}'`,
      JSON.stringify(e)
    );
    yield put(logActivity(`Error while trying to leave '${room.name}'`));
  }
}

export function* watchDisconnectFromRoom() {
  yield takeLatest(
    TwilioActionKeys.DISCONNECT_CALL_REQUEST,
    disconnectFromRoom
  );
}
