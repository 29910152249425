import React from 'react';
import classNames from 'classnames';

import './DayCheckbox.scss';

export default class DayCheckbox extends React.PureComponent {
	state = { value: this.props.checked };

	componentWillReceiveProps(nextProps) {
		if (nextProps.checked !== this.props.checked) {
			this.setState({
				value: nextProps.checked,
			});
		}
	}

	handleClick = () => {
		if (this.props.disabled) {
			return;
		}
		this.setState((prevState) => {
			const newState = {
				value: !prevState.value,
			};

			this.props.onChange(newState);

			return newState;
		});
	};

	render() {
		const { label, error, ...props } = this.props;

		return (
			<div
				className={classNames({
					'day-checkbox': true,
					'day-checkbox--checked': this.state.value,
					'day-checkbox--disabled': this.props.disabled,
				})}
				onClick={this.handleClick}
			>
				<span className="day-checkbox__label">{label}</span>
				<input className="day-checkbox__input" type="checkbox" {...props} />
			</div>
		);
	}
}
