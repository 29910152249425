// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_user-verification-form_gs3G2o{display:flex;flex-direction:column}.styles_user-verification-form_gs3G2o .styles_send-again-button_PfPBHj{text-decoration:underline}.styles_user-verification-form_gs3G2o .styles_action-buttons_Ce3Xw1{display:flex;justify-content:space-evenly;width:100%}.styles_user-verification-form_gs3G2o .styles_verify-button_\\+pnOVw{align-self:flex-end;margin-top:30px;padding:9px 30px}.styles_user-verification-form_gs3G2o .styles_verify-button_\\+pnOVw.styles_singular-width_2kTN9B{width:50%}", "",{"version":3,"sources":["webpack://./src/domains/Users/components/UserSecurity/PhoneVerificationModal/styles.scss"],"names":[],"mappings":"AAAA,sCACE,YAAA,CACA,qBAAA,CAEA,uEACE,yBAAA,CAGF,oEACE,YAAA,CACA,4BAAA,CACA,UAAA,CAGF,oEACE,mBAAA,CACA,eAAA,CACA,gBAAA,CAEA,iGACE,SAAA","sourcesContent":[".user-verification-form {\n  display: flex;\n  flex-direction: column;\n\n  .send-again-button {\n    text-decoration: underline;\n  }\n\n  .action-buttons {\n    display: flex;\n    justify-content: space-evenly;\n    width: 100%;\n  }\n\n  .verify-button {\n    align-self: flex-end;\n    margin-top: 30px;\n    padding: 9px 30px;\n\n    &.singular-width {\n      width: 50%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-verification-form": "styles_user-verification-form_gs3G2o",
	"userVerificationForm": "styles_user-verification-form_gs3G2o",
	"send-again-button": "styles_send-again-button_PfPBHj",
	"sendAgainButton": "styles_send-again-button_PfPBHj",
	"action-buttons": "styles_action-buttons_Ce3Xw1",
	"actionButtons": "styles_action-buttons_Ce3Xw1",
	"verify-button": "styles_verify-button_+pnOVw",
	"verifyButton": "styles_verify-button_+pnOVw",
	"singular-width": "styles_singular-width_2kTN9B",
	"singularWidth": "styles_singular-width_2kTN9B"
};
export default ___CSS_LOADER_EXPORT___;
