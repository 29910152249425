import { ImageActionKeys, ImageActionType } from "./actionTypes";
import { ImageState } from "../models";

const initialState: ImageState = {
  freezeFrame: "",
  frozen: false,
  error: false,
  leftTransferIdentifier: "",
  rightTransferIdentifier: "",
  fullscreenTransferIdentifier: "",
  frozenFrames: new Set()
};

const image = (state = initialState, action: ImageActionType): ImageState => {
  switch (action.type) {
    case ImageActionKeys.CLEAR_IMAGE_FREEZE:
      return initialState; // return frozen to false
    case ImageActionKeys.SET_IMAGE_FREEZE:
      return {
        ...state,
        frozen: action.frozen
      };

    case ImageActionKeys.SET_LEFT_FROZEN_FRAME:
      return {
        ...state,
        leftTransferIdentifier: action.transferIdentifier
      };
    case ImageActionKeys.SET_RIGHT_FROZEN_FRAME:
      return {
        ...state,
        rightTransferIdentifier: action.transferIdentifier
      };
    case ImageActionKeys.SET_FROZEN_FRAME:
    case ImageActionKeys.SET_FULLSCREEN_FROZEN_FRAME:
      return {
        ...state,
        fullscreenTransferIdentifier: action.transferIdentifier
      };
    case ImageActionKeys.UNSET_LEFT_FROZEN_FRAME:
      return {
        ...state,
        leftTransferIdentifier: ""
      };
    case ImageActionKeys.UNSET_RIGHT_FROZEN_FRAME:
      return {
        ...state,
        rightTransferIdentifier: ""
      };
    case ImageActionKeys.UNSET_FROZEN_FRAME:
    case ImageActionKeys.UNSET_FULLSCREEN_FROZEN_FRAME:
      return {
        ...state,
        fullscreenTransferIdentifier: ""
      };
    case ImageActionKeys.SET_FREEZE_FRAME_IMAGE:
      return {
        ...state,
        freezeFrame: action.data,
        // Used only for MP as of now
        fullscreenTransferIdentifier: action.transferIdentifier
      };
    default:
      return state;
  }
};

export default image;
