import { Nurep } from "availkit-js";
import { NCameraPanTiltEvent } from "availkit-js/dist/Models/Events/NCameraPanTiltEvent";
import { NCameraZoomEvent } from "availkit-js/dist/Models/Events/NCameraZoomEvent";
import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Slider } from '@mui/material';
import { Radio } from "semantic-ui-react";
import { logger, LoggerLevels } from "../../../common/logger";
import { PAN_DELTA, TILT_DELTA } from "../../../constants";
import { setCamerasList, setZoomState } from "../../../store/meeting/actions";
import { AppState, LayoutFrame, ZoomStateType } from "../../../store/models";
import "./CameraControlMenuItem.scss";
import { AVKExternalInputMoveEvent } from "availkit-js/dist/Models/Events/AVKExternalInputMoveEvent";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { CameraIdentifier } from "portalcall/commoncall/components/Utils/CameraUtils";
import { AVKExternalInputShift } from "availkit-js/dist/Models/AVKExternalInputShift";
import { AVKExternalInputPosition } from "availkit-js/dist/Models/AVKExternalInputPosition";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";


const CameraControlMenuItem = () => {
  const dispatch = useDispatch();
  const availKit = useSelector((state: AppState) => state.availKit.availKitInstance);
  const {
    callSid,
    cameras,
    consoleHasExternalInputResizing,
    layoutFrames,
    zoomState,
    joinId
  } = useSelector((state: AppState) => state.meeting);

  const { externalInputsImageResizing } = useFeatureFlags();
  const externalInputResizingAvailable = externalInputsImageResizing && consoleHasExternalInputResizing;
  const areBothCamerasDisabled =
    !zoomState.radioButtonLeft.radioEnabled &&
    !zoomState.radioButtonRight.radioEnabled;
  const cameraControlDisabledClass = areBothCamerasDisabled
    ? "camera-control-menu-item-disabled"
    : "camera-control-menu-item";

  // Image Resizing - AutoZoom will be at 30% mark
  const marks = [{
    value: 40
  }];


  const sendZoomCameraMessage = (camera: AVKCamera | AVKExternalInput) => {
    if ("cameraIdentifier" in camera) {
      const cameraZoomEvent = new NCameraZoomEvent(callSid, camera);
      cameraZoomEvent.sender = joinId;
      logger().logWithFields(LoggerLevels.info, {
      fileInfo: `CameraControlMenuItem/sendZoomCameraMessage/AVKCamera`,
      feature: `Camera Control/Zoom`,
      },
      `${camera.cameraIdentifier} location: [${camera.location.x},${camera.location.y},${camera.location.zoomLevel}]`);

      availKit?.eventService.broadcast(cameraZoomEvent);
    } else {
      const externalInputPTZEvent = new AVKExternalInputMoveEvent(callSid, camera);
      externalInputPTZEvent.sender = joinId;
      logger().logWithFields(LoggerLevels.info, {
      fileInfo: `CameraControlMenuItem/sendZoomCameraMessage/AVKExternalInputMoveEvent`,
      feature: `Camera Control/Zoom`,
      },
      `${camera.id} location: [${camera.position.x},${camera.position.y},${camera.zoomLevel}]`);
      availKit?.eventService.broadcast(externalInputPTZEvent);
    }
  }

  const sendCameraMessage = (theCamera) => {
    // detect if AVKCamera or AVKExternalInput
    if ("cameraIdentifier" in theCamera) {
      const cameraPTZEvent = new NCameraPanTiltEvent(callSid, theCamera);
      cameraPTZEvent.sender = joinId;
      logger().logWithFields(LoggerLevels.info,{
        fileInfo: `CameraControlMenuItem/sendCameraMessage`,
        feature: `Camera Controls/Movement`},
        `${theCamera.cameraIdentifier} location: [${theCamera.location.x},${theCamera.location.y},${theCamera.location.zoomLevel}]`
      );
      availKit?.eventService.broadcast(cameraPTZEvent);
    } else {
      const externalInputPTZEvent = new AVKExternalInputMoveEvent(callSid, theCamera);
      externalInputPTZEvent.sender = joinId;
      logger().logWithFields(LoggerLevels.info,{
        fileInfo: `CameraControlMenuItem`,
        feature: `Camera Controls/Movement`},
        `${theCamera.id}
          position:[${theCamera.position.x}, ${theCamera.position.y}],
          shift: [${theCamera.shift.x}, ${theCamera.shift.y}],
          zoom: ${theCamera.zoomLevel},
          isAutoCrop: ${theCamera.isAutoCrop}.`
      );
      availKit?.eventService.broadcast(externalInputPTZEvent);
    }
  }

  const computeDelta = (key: string, defaultValue: any) => {
    let returnValue = defaultValue;
    const valueString = localStorage.getItem(key);
    if (valueString !== null) {
      try {
        returnValue = parseFloat(valueString);
        if (
          !returnValue ||
          isNaN(returnValue) ||
          returnValue < -1 ||
          returnValue > 1
        ) {
          returnValue = defaultValue;
        }
      } catch (e) {
        returnValue = defaultValue;
      }
    }
    return returnValue;
  }

  const getZoomValueOfSelectedCamera = (): number => {
    const localZoomState: ZoomStateType = { ...zoomState };
    const currentZoomSlider = localZoomState.sliderZoom[getSelectedCamera()];

    return currentZoomSlider.value;
  }

  const getPanDelta = (): number => {
    const zoomValue = getZoomValueOfSelectedCamera();
    const scaledZoomValue = 1 - (zoomValue - 1) / 10;
    // z -> 1  => 0.02 * 1 - 0/10 => 1.0
    // z -> 2  => 0.02 * 1 - 1/10 => 0.9
    // z -> 3  => 0.02 * 1 - 2/10 => 0.8
    // z -> 4  => 0.02 * 1 - 3/10 => 0.7
    // z -> 5  => 0.02 * 1 - 4/10 => 0.6
    // z -> 6  => 0.02 * 1 - 5/10 => 0.5
    // z -> 7  => 0.02 * 1 - 6/10 => 0.4
    // z -> 8  => 0.02 * 1 - 7/10 => 0.3
    // z -> 9  => 0.02 * 1 - 8/10 => 0.2
    // z -> 10 => 0.02 * 1 - 9/10 => 0.1
    let panDelta = PAN_DELTA;
    try {
      panDelta = parseFloat(
        localStorage.getItem("REACT_APP_PAN_DELTA") || "" + panDelta
      );
      if (!panDelta || isNaN(panDelta) || panDelta > 1 || panDelta < -1) {
        panDelta = PAN_DELTA;
      }
    } catch (e) {
      panDelta = PAN_DELTA;
    }
    return computeDelta("PAN_DELTA", panDelta * scaledZoomValue);
  }

  const getTiltDelta = (): number => {
    const zoomValue = getZoomValueOfSelectedCamera();
    const scaledZoomValue = 1 - (zoomValue - 1) / 10;
    // z -> 1  => 0.02 * 1 - 0/10 => 1.0
    // z -> 2  => 0.02 * 1 - 1/10 => 0.9
    // z -> 3  => 0.02 * 1 - 2/10 => 0.8
    // z -> 4  => 0.02 * 1 - 3/10 => 0.7
    // z -> 5  => 0.02 * 1 - 4/10 => 0.6
    // z -> 6  => 0.02 * 1 - 5/10 => 0.5
    // z -> 7  => 0.02 * 1 - 6/10 => 0.4
    // z -> 8  => 0.02 * 1 - 7/10 => 0.3
    // z -> 9  => 0.02 * 1 - 8/10 => 0.2
    // z -> 10 => 0.02 * 1 - 9/10 => 0.1
    let tiltDelta = TILT_DELTA;
    try {
      tiltDelta = parseFloat(
        localStorage.getItem("REACT_APP_TILT_DELTA") || "" + tiltDelta
      );
      if (!tiltDelta || isNaN(tiltDelta) || tiltDelta > 1 || tiltDelta < -1) {
        tiltDelta = TILT_DELTA;
      }
    } catch (e) {
      tiltDelta = TILT_DELTA;
    }
    return computeDelta("TILT_DELTA", tiltDelta * scaledZoomValue);
  }

  const getZoomLevel = (): number => {
    const selectedZoom = zoomState.radioButtonLeft.checked ? zoomState.sliderZoom[0] : zoomState.sliderZoom[1];
    return selectedZoom.value * 10;
  }

  const selectCameraControl = (selectedCallControl: string): void => {
    const cameraId = zoomState.radioButtonLeft.checked ? layoutFrames[0].cameraId : layoutFrames[1].cameraId;

    const theCamera: AVKCamera | AVKExternalInput = cameras.filter((camera: AVKCamera | AVKExternalInput) => CameraIdentifier(camera) === cameraId)[0];
    if (!theCamera) {
      return;
    }
    const localZoomState: ZoomStateType = { ...zoomState };

    switch (selectedCallControl) {
      // modify location for AVKCamera or shift for AVKExternalInput
      case "top-tilt":
        if ("location" in theCamera) {
          theCamera.location.y = Math.min(theCamera.location.y + getTiltDelta(), 1.0)
        } else {
          const shift = new AVKExternalInputShift();
          shift.x = -2;
          shift.y = -2;
          theCamera.shift = shift;
          theCamera.position.y = Math.min(theCamera.position.y + 0.1, 0.5)
        }
        sendCameraMessage(theCamera);
        dispatch(setCamerasList([...cameras]));
        break;
      case "bottom-tilt":
        if ("location" in theCamera) {
          theCamera.location.y = Math.max(theCamera.location.y - getTiltDelta(), -1.0)
        } else {
          const shift = new AVKExternalInputShift();
          shift.x = -2;
          shift.y = -2;
          theCamera.shift = shift;
          theCamera.position.y = Math.max(theCamera.position.y - 0.1, -0.5)
        }
        sendCameraMessage(theCamera);
        dispatch(setCamerasList([...cameras]));
        break;
      case "left-pan":
        if ("location" in theCamera) {
          theCamera.location.x = Math.max(theCamera.location.x - getPanDelta(), -1.0)
        } else {
          const shift = new AVKExternalInputShift();
          shift.x = -2;
          shift.y = -2;
          theCamera.shift = shift;
          theCamera.position.x = Math.max(theCamera.position.x - 0.1, -0.5)
        }
        sendCameraMessage(theCamera);
        dispatch(setCamerasList([...cameras]));
        break;
      case "right-pan":
        if ("location" in theCamera) {
          theCamera.location.x = Math.min(theCamera.location.x + getPanDelta(), 1.0)
        } else {
          const shift = new AVKExternalInputShift();
          shift.x = -2;
          shift.y = -2;
          theCamera.shift = shift;
          theCamera.position.x = Math.min(theCamera.position.x + 0.1, 0.5)
        }
        sendCameraMessage(theCamera);
        dispatch(setCamerasList([...cameras]));
        break;
      case "default-homed":
        if ("location" in theCamera) {
          return;
        } else {
          const position = new AVKExternalInputPosition();
          const shift = new AVKExternalInputShift();
          position.x = 0;
          position.y = 0;
          shift.x = 0;
          shift.y = 0;
          theCamera.isAutoCrop = true;
          theCamera.position = position;
          theCamera.shift = shift;
          theCamera.zoomLevel = 0.4;
          localZoomState.sliderZoom[getSelectedCamera()].value = 4;
        }
        sendCameraMessage(theCamera);
        dispatch(setCamerasList([...cameras]));
        dispatch(setZoomState(localZoomState))
        break;
      case "zoom-in":
        zoomInOut(true);
        break;
      case "zoom-out":
        zoomInOut(false);
        break;
      case "left":
        localZoomState.radioButtonLeft.checked = true;
        localZoomState.radioButtonRight.checked = false;
        dispatch(setZoomState(localZoomState))
        break;
      case "right":
        localZoomState.radioButtonLeft.checked = false;
        localZoomState.radioButtonRight.checked = true;
        dispatch(setZoomState(localZoomState))
        break;
    }
  }

  const onSliderChange = (value: any): void => {
    /* Handle extreme cases */
    if (value > 10) {
      value = 10;
    }
    if (value < 0) {
      value = 0;
    }

    const localZoomState: ZoomStateType = { ...zoomState };
    let currentZoomSlider = localZoomState.sliderZoom[getSelectedCamera()];

    let iValue = parseFloat(value);

    if (isNaN(iValue)) {
      iValue = currentZoomSlider.settings.min;
    } else if (iValue < currentZoomSlider.settings.min) {
      iValue = currentZoomSlider.settings.min;
    } else if (iValue >= currentZoomSlider.settings.max) {
      iValue = currentZoomSlider.settings.max;
    }

    // We have to do this test for actual change in zoom value because the
    // Slider component gets updated by the program changing a parameter, then
    // the UI changing position.
    if (iValue === currentZoomSlider.value) {
      return;
    }

    // Create new array with the selected camera's zoom set to the new value
    // then save in react state
    currentZoomSlider.value = iValue;

    const cameraIndex = cameras.findIndex(
      camera => CameraIdentifier(camera) === currentZoomSlider.cameraId
    );
    const zoomNum =
      (iValue - currentZoomSlider.settings.min) /
      (currentZoomSlider.settings.max - currentZoomSlider.settings.min);

    logger().info(
      `---> Camera [${CameraIdentifier(cameras[cameraIndex])}] ` +
        `Zoom[ ${currentZoomSlider.value} -> ${zoomNum}]`
    );

    if ('location' in cameras[cameraIndex]) {
      cameras[cameraIndex]['location']['zoomLevel'] = zoomNum;
      localZoomState.sliderZoom.forEach((slider, index) => {
        if (slider.cameraId === cameras[cameraIndex]['cameraIdentifier']) {
          localZoomState.sliderZoom[index].value = iValue
        }
      })
      sendZoomCameraMessage(cameras[cameraIndex]);
    } else {
      const positionClone = cameras[cameraIndex]['position']
      const shift = new AVKExternalInputShift();
      const position = new AVKExternalInputPosition();
      shift.x = 0;
      shift.y = 0;
      position.x = -1;
      position.y = -1;
      cameras[cameraIndex]['isAutoCrop'] = value === 4;
      cameras[cameraIndex]['zoomLevel'] = zoomNum;
      cameras[cameraIndex]['shift'] = shift;
      cameras[cameraIndex]['position'] = position;
      sendZoomCameraMessage(cameras[cameraIndex]);
      cameras[cameraIndex]['position'] = positionClone;
    }

    dispatch(setCamerasList([...cameras]));
    dispatch(setZoomState(localZoomState));
  }

  const getSelectedCamera = (): number => {
    return zoomState.radioButtonLeft.checked ? 0 : 1;
  }

  const zoomInOut = (incr: boolean) => {
    const currentZoomSlider = zoomState.sliderZoom[
      getSelectedCamera()
    ];
    const newValue =
      currentZoomSlider.value +
      (incr
        ? currentZoomSlider.settings.step
        : -currentZoomSlider.settings.step);

    onSliderChange(newValue);
  }

  // determines if currently selected radio button corresponds to Front or Overhead camera
  const checkCameraSelected = () => {
    const id = zoomState.sliderZoom[getSelectedCamera()].cameraId;
    return id.includes('Front') || id.includes('Overhead');
  }

  const checkInputSelected = (index) => {
    return (layoutFrames[index]?.cameraLabel === 'Front' || layoutFrames[index]?.cameraLabel === 'Overhead')
  }

  return (
    <div className={cameraControlDisabledClass}>
      {" "}
      <div className="camera-selection-options">
        <Radio
          className="front-camera camera-radio"
          name="selectedcamera"
          label={checkInputSelected(0) ? `${layoutFrames[0]?.cameraLabel} Camera` : layoutFrames[0]?.cameraLabel}
          value={"left"}
          disabled={
            externalInputResizingAvailable ? !zoomState.radioButtonLeft.radioEnabled : checkInputSelected(0)
          }
          checked={zoomState.radioButtonLeft.checked}
          onMouseUp={
            zoomState.radioButtonLeft.radioEnabled
              ? () => selectCameraControl("left")
              : undefined
          }
        />
        <Radio
          className="overhead-camera camera-radio"
          name="selectedcamera"
          label={checkInputSelected(1) ? `${layoutFrames[1]?.cameraLabel} Camera` : layoutFrames[1]?.cameraLabel}
          value={"right"}
          disabled={
            externalInputResizingAvailable ? !zoomState.radioButtonRight.radioEnabled : checkInputSelected(1)
          }
          checked={zoomState.radioButtonRight.checked}
          onMouseUp={
            zoomState.radioButtonRight.radioEnabled
              ? () => selectCameraControl("right")
              : undefined
          }
        />
      </div>
      <div className="tilt-hook-control">
        <div
          className="camera-control-top-tilt"
          onClick={
            areBothCamerasDisabled
              ? undefined
              : () => selectCameraControl("top-tilt")
          }
        />

        <div
          className="camera-control-left-pan"
          onClick={
            areBothCamerasDisabled
              ? undefined
              : () => selectCameraControl("left-pan")
          }
        />

        <div
          className="camera-control-bottom-tilt"
          onClick={
            areBothCamerasDisabled
              ? undefined
              : () => selectCameraControl("bottom-tilt")
          }
        />

        <div
          className="camera-control-right-pan"
          onClick={
            areBothCamerasDisabled
              ? undefined
              : () => selectCameraControl("right-pan")
          }
        />

        <div
          className="camera-control-hook"
          onClick={
            areBothCamerasDisabled && checkCameraSelected()
              ? undefined
              : () => selectCameraControl("default-homed")
          }
        />

        <div className="camera-control-ptz-outer-container">
          <div
            className="camera-control-zoom-in"
            // onClick={
            //   areBothCamerasDisabled
            //     ? undefined
            //     : () => selectCameraControl("zoom-in")
            // }
          />
          <div className="camera-control-ptz-container">
            <Box className="camera-control-slider-container">
                <Slider
                  aria-label="Zoom Slider"
                  className="camera-control-slider"
                  color="primary"
                  disabled={areBothCamerasDisabled}
                  value={getZoomLevel()}
                  marks={externalInputResizingAvailable && !checkCameraSelected() && marks}
                  onChange={(e) => onSliderChange(parseInt((e.target as HTMLInputElement).value) / 10)}
                  size='medium'
                  step={10}
                  track={false}
                  valueLabelDisplay='off'
                />
            </Box>
          </div>
          <div
            className="camera-control-zoom-out"
            // onClick={
            //   areBothCamerasDisabled
            //     ? undefined
            //     : () => selectCameraControl("zoom-out")
            // }
          />
        </div>
      </div>
    </div>
  );

}

export default CameraControlMenuItem;
