import { RootState } from "src/domains/Beacon/store";

// side panel section
export const selectSidePanelState = (state: RootState) => state.ui.sidePanel;
export const selectSidePanelOpen = (state: RootState) =>
  state.ui.sidePanel.open;
export const selectSidePanelContent = (state: RootState) =>
  state.ui.sidePanel.content;
export const selectSidePanelParentContent = (state: RootState) =>
  state.ui.sidePanel.parentContent;
export const selectSidePanelTransitionEnded = (state: RootState) =>
  state.ui.sidePanel.transitionEnded;
export const selectSidePAnelFullyState = (state: RootState) =>
  state.ui.sidePanel.isFully;

export const selectToolMode = (state: RootState) => state.ui.toolMode;

export const selectIsUiHidden = (state: RootState) => state.ui.isUiHidden;

export const selectPipCollapsed = (state: RootState) => state.ui.pip.collapsed;
export const selectShowPipVideo = (state: RootState) => state.ui.pip.showVideo;

// call section
export const selectCallStep = (state: RootState) => state.ui.callState.callStep;
export const selectLeaveCallModalOpen = (state: RootState) =>
  state.ui.callState.openLeaveCallModal;
export const selectNoConnectionModalOpen = (state: RootState) =>
  state.ui.callState.openNoNetworkConnectionModal;

// notifications
export const selectNotifications = (state: RootState) => state.ui.notifications;

// badges
export const selectBadges = (state: RootState) => state.ui.badges;

export const selectMicrophoneMuted = (state: RootState) =>
  state.ui.microphone.muted;

export const selectSidePanelLayouts = (state: RootState) =>
  state.ui.sidePanel.layouts;

export const selectTelestrationPalette = (state: RootState) =>
  state.ui.isTelestrationPaletteHidden;

export const selectIndicatorState = (state: RootState) => state.ui.indicator;

export const selectOpenMinimumBrowserSizeModal = (state: RootState) =>
  state.ui.openMinimumBrowserSizeModal;

export const selectLastParticipantModalOpen = (state: RootState) =>
  state.ui.lastParticipantModalOpen;

export const selectUiState = (state: RootState) => state.ui;
