import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
/* Images */
import { addNewVendor } from '../../../../Common/Assets/Images';
import ModalComponent from '../../../../Common/Components/Modal/Modal';
/* Components */
import CreateNewDistributorModal from './Components/CreateNewDistributorModal/CreateNewDistributorModal';
import DistributorTableRow from './Components/DistributorTableRow/DistributorTableRow';
import { Table, AddNewEntity } from '../../Common';
/* Actions */
import { deleteDistributorAction, getAllDistributorsAction } from './DistributorsActions';

const pageSize = 10;

class Distributors extends PureComponent {
	state = {
		isDeleteModalOpened: false,
		distributor: null,
		isCreateNewDistributorModalOpened: false,
	};

	componentDidMount() {
		this.params = {
			size: pageSize,
			page: 0,
			type: 'DISTRIBUTOR',
		};
		this.props.getAllDistributors(this.params);
	}

	onConfirm = () => {
		//TODO remove comment when delete feature is implemented on BE side
		//this.props.deleteDistributor(this.state.distributor.id);
		this.toggleDeleteModal();
	};

	onPageChangeHandler = (e, { activePage }) => {
		this.params = {
			...this.params,
			size: pageSize,
			page: activePage - 1,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllDistributors(this.params);
	};

	onCreateNewDistributorHandler = (values) => {
		this.setState({ isCreateNewDistributorModalOpened: !this.state.isCreateNewDistributorModalOpened });
		this.props.createNewDistributor(values);
	};

	onSortTableHandler = (clickedColumn) => {
		this.sortParams = clickedColumn;
		this.params = {
			...this.params,
			sort: this.sortParams,
		};
		this.props.getAllDistributors(this.params);
	};

	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = {
			size: pageSize,
			page: 0,
			type: 'DISTRIBUTOR',
			[this.parameter]: this.sortParams,
		};
		this.props.getAllDistributors(this.params);
	};

	toggleCreateNewDistributorModal = () => {
		this.props.resetReduxForm('distributorsForm');
		this.setState({ isCreateNewDistributorModalOpened: !this.state.isCreateNewDistributorModalOpened });
	};

	pageSize = 10;

	columns = [
		{
			title: 'Name',
			sortValue: 'name',
		},
		{
			title: 'Address',
			sortValue: 'address',
		},
		{
			title: 'City',
			sortValue: 'city',
		},
		{
			title: 'State',
			sortValue: 'state',
		},
	];

	toggleDeleteModal = (distributor = {}) => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
			distributor,
		});
	};

	renderCreateNewDistributorModal = () => (
		<ModalComponent
			open={this.state.isCreateNewDistributorModalOpened}
			hasActionButtons={false}
			onCancel={this.toggleCreateNewDistributorModal}
			title="Create new distributor"

		>
			<CreateNewDistributorModal
				onSubmit={this.onCreateNewDistributorHandler}
				toggleCreateNewDistributorModal={this.toggleCreateNewDistributorModal}
			/>
		</ModalComponent>
	);

	renderDeleteDistributorModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onConfirm}
			onCancel={this.toggleDeleteModal}
			confirmText="Delete"
		>
			{
				this.state.distributor && (
					<span>
						You are about to delete <span className="text--bold">{this.state.distributor.name}</span>.
						Would you like to proceed with this action?
					</span>
				)
			}
		</ModalComponent>
	);

	render() {
		return (
			<div className="users__container">
				<Table
					row={DistributorTableRow}
					columns={this.columns}
					tableData={this.props.distributors}
					headerTitle="Distributor list"
					openModal={this.toggleDeleteModal}
					paginationConfig={this.props.pagination}
					onPageChangeHandler={this.onPageChangeHandler}
					onSortTableHandler={this.onTableFilterHandler}
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					noItemsClass="height--full justify-content--center align--center"
					onAddNewHandler={this.toggleCreateNewDistributorModal}
					noEntityComponent={
						<AddNewEntity
							image={addNewVendor}
							addNewEntityHandler={this.toggleCreateNewDistributorModal}
							title="Create new distributor"
						/>
					}
					sortable
					search
				/>
				{this.renderDeleteDistributorModal()}
				{this.renderCreateNewDistributorModal()}
			</div>
		);
	}
}

Distributors.defaultProps = {
	distributors: [],
	pagination: {
		page: null,
		size: null,
		totalPages: null,
	},
};

Distributors.propTypes = {
	distributors: PropTypes.array,
	pagination: PropTypes.object,
};

const mapStateToProps = state => ({
	distributors: state.distributorsReducer.distributors,
	pagination: state.distributorsReducer.pagination,
});

const mapDispatchToProps = dispatch => ({
	resetReduxForm: formName => dispatch(reset(formName)),
	getAllDistributors: params => dispatch(getAllDistributorsAction(params)),
	deleteDistributor: distributorId => dispatch(deleteDistributorAction(distributorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Distributors);
