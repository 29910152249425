import { useDispatch } from "react-redux";

import clsx from "clsx";

import { ChevronLeft, Close } from "@mui/icons-material";

import { Button } from "src/components/Button";
import { Font } from "src/components/Font";
import { LayoutsContent } from "src/domains/Beacon/components/SidePanel/LayoutsContent";
import { LayoutsPresetContent } from "src/domains/Beacon/components/SidePanel/LayoutsPresetsContent";
import { ParticipantsContent } from "src/domains/Beacon/components/SidePanel/ParticipantsContent";
import { PresetsContent } from "src/domains/Beacon/components/SidePanel/PresetsContent";
import { SettingsContent } from "src/domains/Beacon/components/SidePanel/SettingsContent";
import { AudioSettings } from "src/domains/Beacon/components/SidePanel/SettingsContent/AudioSettings";
import { ConsoleAudioSettings } from "src/domains/Beacon/components/SidePanel/SettingsContent/ConsoleAudioSettings";
import { NetworkStatusSettings } from "src/domains/Beacon/components/SidePanel/SettingsContent/NetworkStatusSettings";
import { VideoSettings } from "src/domains/Beacon/components/SidePanel/SettingsContent/VideoSettings";
import { useSidePanelTransition } from "src/domains/Beacon/components/SidePanel/hooks/useSidePanelTransition";
import { DRAWER_WIDTH, SIDE_PANEL_ID } from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import { uiActions } from "src/domains/Beacon/store/ui";
import {
  selectSidePanelContent,
  selectSidePanelOpen,
  selectSidePanelParentContent,
} from "src/domains/Beacon/store/ui/selectors";
import { UISidePanelContentOptions } from "src/domains/Beacon/store/ui/types";
import { useScssVariable } from "src/hooks/useScssVariable";

import styles from "./styles.scss";

export const SidePanel = () => {
  const dispatch = useDispatch();

  const open = useAppSelector(selectSidePanelOpen);
  const content = useAppSelector(selectSidePanelContent);
  const parentContent = useAppSelector(selectSidePanelParentContent);

  // Passes the drawer width to a Scss var, check the './styles.scss'
  const { ref } = useScssVariable<HTMLDivElement>(
    "--drawer-width",
    `${DRAWER_WIDTH}px`
  );

  // Detects when SidePanel transition ends to know with more accuracy if SidePanel
  // is open or close
  useSidePanelTransition(ref.current);

  return (
    <div
      ref={ref}
      className={clsx(styles.drawer, {
        [styles.open]: open,
      })}
      data-test-id="side-panel"
      id={SIDE_PANEL_ID}
    >
      <div className={styles.header} data-test-id="side-panel-header">
        {/* if the parent content value is set then we know what the "back" can go to  */}
        {parentContent !== null ? (
          <Button
            variant="icon"
            endIcon={<ChevronLeft />}
            data-test-id="side-panel-back"
            disabled={false}
            onClick={() => {
              // in this case the back button will return to the whatever the parent content is
              // for example Settings would be the parentContent when the content would be Audio Settings
              dispatch(uiActions.setSidePanelContent(parentContent));
            }}
          />
        ) : (
          <div>&nbsp;</div>
        )}

        {renderTitle(content)}
        <Button
          variant="icon"
          endIcon={<Close />}
          disabled={false}
          data-test-id="side-panel-close-button"
          onClick={() => {
            dispatch(uiActions.setSidePanelOpen(false));
          }}
        />
      </div>
      <div className={styles.content}>{renderContent(content)}</div>
    </div>
  );
};

const renderContent = (content: UISidePanelContentOptions) => {
  switch (content) {
    case UISidePanelContentOptions.PRESETS:
      return <PresetsContent />;
    case UISidePanelContentOptions.LAYOUTS:
      return <LayoutsContent />;
    case UISidePanelContentOptions.PARTICIPANTS:
      return <ParticipantsContent />;
    case UISidePanelContentOptions.SETTINGS:
      // other sub settings content will be rendered by this component
      return <SettingsContent />;
    case UISidePanelContentOptions.AUDIO_SETTINGS:
      return <AudioSettings />;
    case UISidePanelContentOptions.VIDEO_SETTINGS:
      return <VideoSettings />;
    case UISidePanelContentOptions.CONSOLE_SETTINGS:
      return <ConsoleAudioSettings />;
    case UISidePanelContentOptions.NETWORK_STATUS_SETTINGS:
      return <NetworkStatusSettings />;
    case UISidePanelContentOptions.LAYOUTS_PRESETS:
      return <LayoutsPresetContent />;
  }
};

const renderTitle = (content: UISidePanelContentOptions) => {
  switch (content) {
    case UISidePanelContentOptions.PRESETS:
      return (
        <Font color="light" variant="h2">
          Presets
        </Font>
      );
    case UISidePanelContentOptions.LAYOUTS:
      return (
        <Font color="light" variant="h2">
          Layouts
        </Font>
      );
    case UISidePanelContentOptions.PARTICIPANTS:
      return (
        <Font color="light" variant="h2">
          Participants
        </Font>
      );
    case UISidePanelContentOptions.SETTINGS:
      return (
        <Font color="light" variant="h2">
          Settings
        </Font>
      );
    case UISidePanelContentOptions.AUDIO_SETTINGS:
      return (
        <Font color="light" variant="h2">
          Audio Settings
        </Font>
      );
    case UISidePanelContentOptions.VIDEO_SETTINGS:
      return (
        <Font color="light" variant="h2">
          Video Settings
        </Font>
      );
    case UISidePanelContentOptions.CONSOLE_SETTINGS:
      return (
        <Font color="light" variant="h2">
          Console Audio Settings
        </Font>
      );
    case UISidePanelContentOptions.NETWORK_STATUS_SETTINGS:
      return (
        <Font color="light" variant="h2">
          Network Status
        </Font>
      );
    case UISidePanelContentOptions.LAYOUTS_PRESETS:
      return (
        <Font color="light" variant="h2">
          Layouts & Presets
        </Font>
      );
  }
};
