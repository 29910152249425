import React, { CSSProperties } from "react";
import "./Loader.scss";

interface LoaderProps {
  loading: boolean;
  size: number;
  sizeUnit: string;
  color: string;
  message?: string;
  children?: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[];
}

// Simple loading wrapper component that shows a message or just a loading indicator while loading prop is set to true
// once loading is false, the children are rendered instead
const Loader: React.FC<LoaderProps> = ({
  loading,
  children,
  size,
  sizeUnit,
  color,
  message
}) => {
  return (
    <>
      {loading ? (
        <div className="loader-container">
          {message ? (
            <>
              <span className="loader-title">{message}</span>
              <br />
            </>
          ) : null}
          <PuslatingLoadingIcon size={size} sizeUnit={sizeUnit} color={color} />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default Loader;

interface PuslatingLoadingIconProps {
  size: number;
  sizeUnit: string;
  color: string;
}
// Helper component that only renders the cool pulsating loading icon
const PuslatingLoadingIcon = ({
  size,
  sizeUnit,
  color
}: PuslatingLoadingIconProps) => {
  const styles = (i: number): React.CSSProperties => ({
    position: "absolute",
    height: `${size}${sizeUnit}`,
    width: `${size}${sizeUnit}`,
    backgroundColor: color,
    borderRadius: "100%",
    opacity: 0.6,
    top: 0,
    left: 0,
    animationFillMode: "both",
    animation: `bounceLoad 2.1s ${i === 1 ? "1s" : "0s"} infinite ease-in-out`
  });

  const wrapper = (): CSSProperties => ({
    position: "relative",
    width: `${size}${sizeUnit}`,
    height: `${size}${sizeUnit}`
  });

  return (
    <div style={wrapper()}>
      <div style={styles(1)} />
      <div style={styles(2)} />
    </div>
  );
};
