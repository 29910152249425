import { MutableRefObject, useEffect, useRef, useState } from "react";

import { stopMediaTracks } from "src/domains/Beacon/utils/mediaDevices";
import { useEventListener } from "src/hooks/useEventListener";

type OnVoiceRecord = () => void;
type OnStopVoiceRecord = () => void;

export const useAudioRecord = (
  audioRef: MutableRefObject<HTMLAudioElement>,
  microphoneId: string
): [
  OnVoiceRecord,
  OnStopVoiceRecord,
  {
    recording: boolean;
    frequency: number;
    counter: number;
  }
] => {
  const [recording, setRecording] = useState(false);
  const [frequency, setFrequency] = useState<number>(0);
  const [counter, setCounter] = useState(3);
  const [stream, setStream] = useState<MediaStream>();
  const recorderRef = useRef<MediaRecorder>();
  const intervalRef = useRef(null);
  const counterRef = useRef<number>(3);
  const chunksRef = useRef<Blob[]>([]);

  useEffect(() => {
    return () => {
      // This will stop showing the annoying red recording icon in the browser tab
      stopMediaTracks(stream);
    };
  }, []);

  /**
   * Method called in the "start" event of the "recorderRef"
   * This method analyse the stream passed and calculate the pitch's volume in
   * percentage number.
   */
  const analysePitch = (currentStream: MediaStream) => {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(currentStream);
    const data = new Uint8Array(analyser.frequencyBinCount);
    source.connect(analyser);

    /**
     * This inner method will act as a loop until the recorderRef object
     * isn't null, otherwise the method will keep calculating the pitch's
     * volume and updating the frequency state
     */
    const report = () => {
      analyser.getByteFrequencyData(data);
      if (recorderRef.current) {
        const volume = Math.floor((Math.max(...data) / 255) * 100);
        setFrequency(volume);
        requestAnimationFrame(report);
      } else {
        audioContext.close();
      }
    };
    report();
  };

  /**
   * Event executed when the "recorderRef.start" function is called
   * It will execute the "analysePitch" method and start streaming the user's
   * microphone
   */
  useEventListener(
    "start",
    () => {
      setRecording(true);
      analysePitch(recorderRef.current.stream);
      counterRef.current = 3;
      intervalRef.current = setInterval(() => {
        if (counterRef.current === 1) {
          recorderRef.current?.stop();
        }
        counterRef.current = counterRef.current - 1;
        setCounter(counterRef.current);
      }, 1000);
    },
    recorderRef.current
  );

  /**
   * Executed before the "stop" event in order to fetch the data from the
   * user's microphone
   */
  useEventListener(
    "dataavailable",
    (e: BlobEvent) => {
      if (e.data.size > 0) {
        chunksRef.current.push(e.data);
        stopMediaTracks(recorderRef.current.stream);
      }
    },
    recorderRef.current
  );

  /**
   * Will convert the chunks into a readable file to be listened into the
   * audio HTML tag
   */
  useEventListener(
    "stop",
    () => {
      const blob = new Blob(chunksRef.current, {
        type: "audio/ogg; codecs=opus",
      });
      const file = new File([blob], "test");
      const url = window.URL.createObjectURL(file);
      audioRef.current.src = url;
      audioRef.current.play();
      onReset();
    },
    recorderRef.current
  );

  // Will set everything to its default values to let record again
  const onReset = () => {
    recorderRef.current = null;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setRecording(false);
    setCounter(3);
    setFrequency(0);
  };

  // Starts streaming the audio from the user's microphone
  const onVoiceRecord = async () => {
    audioRef.current.pause();
    const newStream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: microphoneId },
      video: false,
    });
    recorderRef.current = new MediaRecorder(newStream, {
      mimeType: "audio/webm",
    });
    recorderRef.current.start();
    chunksRef.current = [];
    setStream(newStream);
  };

  // Stops the voice recording
  const onStopVoiceRecord = () => recorderRef.current.stop();

  return [onVoiceRecord, onStopVoiceRecord, { recording, frequency, counter }];
};
