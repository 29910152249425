import moment from "moment";

import { DAY_KEY_FORMAT } from "src/domains/Events/utils";
import { UserCallHistoryItem } from "src/services/ApiClient/trialUsage";

export interface GroupedCalls {
  day: string; // date (DAY_KEY_FORMAT)
  items: UserCallHistoryItem[]; // p2p or event calls
}

export const groupCallsByDays = (
  items: UserCallHistoryItem[]
): GroupedCalls[] => {
  const daysGroup = items?.reduce((daysArray, current) => {
    const dayName = moment(current.startDate).format(DAY_KEY_FORMAT);

    // if the day (EG September 28) is not yet stored, do so. Otherwise just go on
    if (daysArray.find((m) => m.day === dayName) === undefined) {
      return [...daysArray, { day: dayName, items: [] }];
    }

    return daysArray;
  }, []);

  return items?.reduce(
    (days, current) => {
      // get the day name for the current call
      const dayName = moment(current.startDate).format(DAY_KEY_FORMAT);

      // look for that dayName in the existing `daysGroup` array
      const dayIndex = daysGroup.findIndex((d) => d.day === dayName);

      // store the event (`current`) in that founded dayName
      days[dayIndex].items.push(current);
      return days;
    },
    [...daysGroup]
  );
};

// Will return this format: `1:00PM - 2:30PM`
export function formatTime(startTime: string, endTime: string) {
  const start = moment(startTime).format("h:mmA"); // "3:25 pm"
  const end = moment(endTime).format("h:mmA");

  return `${start} - ${end}`;
}
