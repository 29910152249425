import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
/* Images */
import { addNewVendor } from '../../../../../Common/Assets/Images';

import CreateNewVendor from './Features/CreateNewVendor/CreateNewVendor';
/* Actions */
import { getAllVendorsAction, deleteVendorAction, createNewVendorAction } from './VendorsActions';
/* Components */
import { Table } from '../../../Common';
import VendorTableRow from './Components/VendorTableRow/VendorTableRow';
import AddNewEntity from '../../../Common/AddNewEntity/AddNewEntity';
import ModalComponent from '../../../../../Common/Components/Modal/Modal';

class Vendors extends PureComponent {
	state = {
		isDeleteModalOpened: false,
		organisation: null,
		isCreateNewVendorModalOpened: false,
		pageSize: 10,
	};

	componentDidMount() {
		this.params = {
			size: this.state.pageSize,
			page: 0,
		};
	}

	onConfirm = () => {
		this.props.deleteVendor(this.state.organisation.id);
		this.toggleDeleteModal();
	};

	onPageChangeHandler = (e, { activePage }) => {
		this.params = {
			...this.params,
			size: this.state.pageSize,
			page: activePage - 1,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllVendors(this.params);
	};

	onCreateNewVendorHandler = (values) => {
		this.setState({ isCreateNewVendorModalOpened: !this.state.isCreateNewVendorModalOpened });
		this.props.createNewVendor({
			...values,
			procedures: values.procedures ? Object.keys(values.procedures) : [],
		});
	};


	onSortTableHandler = (clickedColumn) => {
		this.sortParams = clickedColumn;
		this.params = {
			...this.params,
			sort: this.sortParams,
		};
		this.props.getAllVendors(this.params);
	};

	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;

		this.params = {
			size: this.params.size,
			[this.parameter]: this.sortParams,
		};

    // always move to page 0 when updating the filter
    this.params.page = 0;
		this.props.getAllVendors(this.params);
	};

	onTableSortHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			...this.params,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllVendors(this.params);
	};

	handleVariablePaginationChange = (option) => {
		if (!option) return;

		this.setState({ pageSize: option });
		this.props.getAllVendors({
			size: option,
			page: 0,
			[this.parameter]: this.sortParams,
		});
	}

	toggleCreateNewVendorModal = () => {
		this.props.resetReduxForm('vendorsForm');
		this.props.resetReduxForm('CreateNewVendor');
		this.setState({ isCreateNewVendorModalOpened: !this.state.isCreateNewVendorModalOpened, isNext: false });
	};

	nextPage = () => {
		this.setState({ page: this.state.page + 1 });
	}

	previousPage = () => {
		this.setState({ page: this.state.page - 1 });
	}

	columns = [
		{
			title: 'Name',
			sortValue: 'name',
		},
		{
			title: 'Address',
			sortValue: 'address',
		},
		{
			title: 'City',
			sortValue: 'city',
		},
		{
			title: 'State',
			sortValue: 'state',
		},
		{
			title: 'Vendor Name',
			sortValue: 'brandName',
		},
	];

	toggleDeleteModal = (organisation = {}) => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
			organisation,
		});
	};

	renderCreateNewVendorModal = () => (
		<ModalComponent
			open={this.state.isCreateNewVendorModalOpened}
			hasActionButtons={false}
			onCancel={this.toggleCreateNewVendorModal}
			title="Create new Business Unit"
		>

			<CreateNewVendor
				onSubmit={this.onCreateNewVendorHandler}
				toggleCreateNewVendorModal={this.toggleCreateNewVendorModal}
			/>

		</ModalComponent>
	);

	renderDeleteVendorModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onConfirm}
			onCancel={this.toggleDeleteModal}
			confirmText="Delete"
		>
			{
				this.state.organisation && (
					<span>
						You are about to delete <span className="text--bold">{this.state.organisation.name}</span>.
						Would you like to proceed with this action?
					</span>
				)
			}
		</ModalComponent>
	);

	render() {
		return (
			<div className="users__container">
				<Table
					row={VendorTableRow}
					columns={this.columns}
					tableData={this.props.vendors}
					headerTitle="Business Units"
					openModal={this.toggleDeleteModal}
					paginationConfig={this.props.pagination}
					onPageChangeHandler={this.onPageChangeHandler}
					onSortTableHandler={this.onTableSortHandler}
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					noItemsClass="height--full justify-content--center align--center"
					onAddNewHandler={this.toggleCreateNewVendorModal}
					noEntityComponent={
						<AddNewEntity
							image={addNewVendor}
							addNewEntityHandler={this.toggleCreateNewVendorModal}
							title="Add New Vendor BU"
						/>
					}
					sortable
					search
					variablePagination
					onVariablePaginationChange={this.handleVariablePaginationChange}
				/>
				{this.renderDeleteVendorModal()}
				{this.renderCreateNewVendorModal()}
			</div>
		);
	}
}

Vendors.defaultProps = {
	vendors: null,
	getAllVendors: () => { },
	deleteVendor: () => { },
};

Vendors.propTypes = {
	vendors: PropTypes.arrayOf(PropTypes.object),
	getAllVendors: PropTypes.func,
	deleteVendor: PropTypes.func,
};

const mapStateToProps = state => ({
	vendors: state.vendorsReducer.vendors,
	pagination: state.vendorsReducer.pagination,
});

const mapDispatchToProps = dispatch => ({
	getAllVendors: params => dispatch(getAllVendorsAction(params)),
	deleteVendor: id => dispatch(deleteVendorAction(id)),
	resetReduxForm: formName => dispatch(reset(formName)),
	createNewVendor: vendorData => dispatch(createNewVendorAction(vendorData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Vendors);

