import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import resources from '../../../../Common/Constants/resources';
import { notificationService, urlService } from '../../../../Common/Services';
import ApiHelper from '../../../../Common/Services/ApiHelper';
import { HOSPITAL_NETWORK } from './HospitalNetworkActionTypes';

function* getAllHospitalNetworks(action) {
	try {
    const getNetworkListOnly = !action.pagination;
    const networkResourceType = getNetworkListOnly
      ? resources.hospitalNetwork
      : resources.hospitalNetworkDetails;

		const { content, ...paginationInfo} = yield call(ApiHelper.get, 'coreregistry', networkResourceType, action.pagination);

    yield all([
			put({
				type: HOSPITAL_NETWORK.SET_ALL_HOSPITAL_NETWORK,
				payload: content,
			}),
			put({
				type: HOSPITAL_NETWORK.SET_HOSPITAL_NETWORK_PAGINATION,
				payload: paginationInfo,
			}),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createNewHospitalNetwork(action) {
	try {
		yield call(ApiHelper.post, 'coreregistry', resources.hospitalNetwork, action.payload);
		const { content, ...paginationInfo } = yield call(ApiHelper.get, 'coreregistry', resources.hospitalNetworkDetails, {size: 10, page: 0});
		yield all([
			put({
				type: HOSPITAL_NETWORK.SET_ALL_HOSPITAL_NETWORK,
				payload: content
			}),
			notificationService.success('You successfully created Facility network'),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* deleteHospitalNetwork(action) {
	try {
		yield call(ApiHelper.remove, 'coreregistry', urlService.formatString(resources.oneHospitalNetwork, action.payload));

		const currentNetworksList = yield select(state => state.hospitalNetworkReducer.hospitalNetworks);
		const filteredNetworks = currentNetworksList.filter(network => network.id !== action.payload);

		yield put({
			type: HOSPITAL_NETWORK.SET_ALL_HOSPITAL_NETWORK,
			payload: filteredNetworks,
		});

		yield notificationService.success('You successfully deleted facility network.');
	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* hospitalNetworkSaga() {
	yield takeLatest(HOSPITAL_NETWORK.GET_ALL_HOSPITAL_NETWORK, getAllHospitalNetworks);
	yield takeLatest(HOSPITAL_NETWORK.CREATE_NEW_HOSPITAL_NETWORK, createNewHospitalNetwork);
	yield takeLatest(HOSPITAL_NETWORK.DELETE_HOSPITAL_NETWORK, deleteHospitalNetwork);
}
