const DomainConstants = {
  key: {
    action: "actions",
    address: "address",
    admins: "admins",
    always: "always",
    approvalList: "approval-list",
    ascending: "ascending",
    brand: "brand",
    brandID: "brandId",
    businessUnit: "businessUnits",
    calendar: "calendar",
    city: "city",
    clinicalDepartments: "clinical-departments",
    company: "company",
    confirmPassword: "confirmPassword",
    consoles: "consoles",
    currentPassword: "currentPassword",
    customTags: "customTags",
    dateStart: "dateStart",
    dateEnd: "dateEnd",
    debug: "debug",
    debugMode: "debugMode",
    delete: "delete",
    descending: "descending",
    districtName: "districtName",
    districts: "districts",
    domainName: "domainName",
    domains: "domains",
    email: "email",
    facilities: "facilities",
    facility: "facility",
    firstName: "firstName",
    general: "general",
    hospital: "hospital",
    hospitalId: "hospitalId",
    host: "host",
    ids: "ids",
    lastName: "lastName",
    logoLocation: "logoLocation",
    macAddress: "macAddress",
    mfaChannelType: "channelType",
    mfaCountry: "country",
    mfaFrequency: "mfaFrequency",
    mfaPhoneNumber: "phoneNumber",
    mfaPasscode: "passcode",
    name: "name",
    networkId: "networkId",
    never: "never",
    newPassword: "newPassword",
    notifications: "notifications",
    notify: "notify",
    organization: "organization",
    otp: "otp",
    pairingCode: "pairingCode",
    participants: "participants",
    password: "password",
    preferences: "preferences",
    procedure: "procedure",
    profile: "profile",
    role: "role",
    room: "room",
    roomName: "roomName",
    rooms: "rooms",
    serialNum: "serialNumber",
    serialNumber: "serialId",
    softwareVersion: "softwareVersion",
    state: "state",
    status: "status",
    subject: "subject",
    surgeon: "surgeon",
    therapy: "therapy",
    therapies: "therapies",
    timeStart: "timeStart", // named weiredly but wanted to keep them together in this list
    timeEnd: "timeEnd",
    timezone: "timezone",
    title: "title",
    type: "type",
    username: "username",
    userType: "userType",
    users: "users",
    vendor: "vendor",
    version: "version",
  },
  label: {
    action: "Actions",
    add: "Add",
    addAnotherDomain: "Add Another Domain",
    addNewConsole: "Add New Console",
    addNewDomain: "Add new domain",
    address: "Address",
    admins: "Admins",
    approvalList: "Approval List",
    assignedFacility: "Assigned Facility",
    associatedClinicalDepartments: "Associated Clinical Departments",
    associatedTherapies: "Associate therapies to Clinical Department",
    brand: "Brand",
    businessUnit: "Business Units",
    businessUnitName: "Business Unit Name",
    calendar: "UserCalendar",
    cancel: "Cancel",
    changeDebugMode: "Change Debug Mode",
    chooseFile: "Choose File",
    city: "City",
    clinicalDepartments: "Clinical Departments",
    company: "Company",
    confirmPassword: "Confirm Password",
    consoleNotPaired: "Console not paired",
    consolePaired: "Console paired",
    consoleStatus: "Console Status",
    consoles: "Consoles",
    continue: "Continue",
    create: "Create",
    createNewClinicalDepartment: "Create New Clinical Department",
    createNewUser: "Create New User",
    createUser: "Create User",
    createBusinessUnit: "Create Business Unit",
    createFacility: "Create New Facility",
    createNewVendor: "Create New Vendor",
    createProvider: "Create New Provider",
    createRoom: "Create new Room",
    currentPassword: "Current Password",
    customTags: "Custom Tags",
    dateStart: "Start Date",
    dateEnd: "End Date",
    debugMode: "Debug Mode",
    delete: "Delete",
    deleteBU: "Delete Business Unit",
    deleteBrand: "Delete Brand",
    deleteClinicalDepartment: "Delete Clinical Department",
    deleteConsole: "Delete Console",
    deleteFacility: "Delete Facility",
    deleteHospital: "Delete Hospital",
    deleteProvider: "Delete Provider",
    deleteUser: "Delete User",
    department: "Department",
    districtName: "District name",
    districts: "Districts",
    domainName: "Enter Domain name",
    domains: "Domains",
    email: "Email",
    emailAddress: "Email Address",
    enterCurrentPassword: "Enter Current Password",
    enterNewPassword: "Enter New Password",
    eventDenied: "Event Access Denied",
    eventTitle: "Event Title",
    eventLink: "Link",
    facilities: "Facilities",
    facility: "Facility",
    facilityNetworks: "Facility Networks",
    firstName: "First Name",
    general: "General Info",
    hcpGroups: "HCP Groups",
    host: "Event Host",
    incorrectPasscodeTitle:
      "You entered an incorrect One Time Password 5 times.",
    incorrectPasscodeLockout:
      "You entered an incorrect One Time Password too many times.",
    incorrectPasscodeSuspension:
      "Your account is temporarily suspended due to multiple unsuccessful login attempts.",
    itemsPerPage: "Items per page",
    lastName: "Last Name",
    login: "Login",
    loginMain: "Login to Avail",
    loginGuest: "Login to access your guest account",
    loginHub: "Login to your Avail Portal",
    macAddress: "MAC Address",
    manager: "Manager",
    memberHub: "Avail Portal",
    mfa: "Multi Factor Authentication",
    mfaAuthMethod: "Select authentication method",
    mfaCall: "CALL",
    mfaCallTo: "Call to",
    mfaClose: "Close",
    mfaCountry: "Country",
    mfaEmail: "EMAIL",
    mfaEmailChannelText:
      "A one-time password has been sent to the email address ",
    mfaEmailChannelConfirmText: "Please enter it in the field below.",
    mfaSmsChannelText:
      "A text message with a Passcode has been sent to your phone number.",
    mfaCallChannelText:
      "A call with a Passcode has been sent to your phone number.",
    mfaEnterPasscode: "Enter passcode",
    mfaMaybeLater: "MAYBE LATER",
    mfaNotRequired:
      "Your organization does not require Multi Factor Authentication",
    mfaPasscode: "Passcode",
    mfaPhoneSetup:
      " Multi Factor Authentication via Phone Call or Text Message",
    mfaPhoneSetupText:
      "Would you like to save a phone number to authenticate via text or phone call in the future?",
    mfaRequired: "Your organization requires Multi Factor Authentication",
    mfaResendPasscode: "Resend passcode",
    mfaResendPasscodeAgain: "If you have not received your passcode click",
    mfaSelectCountry: "Select Country",
    mfaSendAgain: " send again",
    mfaSendPasscodeMessage: "We are sending message with a passcode to:",
    mfaSendSMSTo: "Send SMS to",
    mfaSms: "SMS",
    mfaSendPasscode: "Send passcode",
    mfaUserSuspendedTitle: "You entered an incorrect Passcode 5 times.",
    mfaPasscodeValid: "Your One Time Password will expire in 30 minutes",
    mfaPasscodeValidLabel: "Your passcode will expire in 30 mins.",
    mfaPhoneAuthenticationTitle: "Add a phone number authentication method",
    mfaPhoneDeleted: "was successfully deleted!",
    mfaPhoneNumber: "Phone number",
    mfaPhoneSuccess: "was successfully verified!",
    mfaTextMessage: "Text message",
    mfaVerify: "VERIFY",
    mfaVerifyNumber: "Verify your phone number",
    mfaVoiceCall: "Voice call",
    mfaYes: "YES",
    mfaYourPhoneNumber: "Your phone number",
    name: "Name",
    network: "Network",
    newPassword: "New Password",
    next: "Next",
    notifications:
      "Yes, Please! I would like to receive news about products, services or promotions from Avail.",
    ok: "ok",
    organization: "Organization",
    oneTimePassword: "One Time Password",
    otpEnterPasscode: "Enter One time password",
    otpResend: "Resend OTP",
    pairingCode: "Pairing Code",
    participants: "Panelists",
    password: "Password",
    passwordMisMatch: "Password does not match",
    preferences: "Preferences",
    previous: "Previous",
    profile: "Profile",
    procedure: "Procedure",
    prvDeleteModelTitle: "Delete Provider",
    refreshTimer: "Refresh Timer",
    returnToLogin: "Go to login",
    retypeNewPassword: "Retype New Password",
    requestStatus: "Request Status",
    role: "Role",
    roleSelect: "I am a...",
    roomName: "Room Name",
    roomType: "Room Type",
    rooms: "Rooms",
    selectRoomType: "Select room type",
    serialID: "Serial ID",
    serialNumber: "Serial Number",
    softwareVersion: "Software Version",
    specialist: "Surgeon",
    state: "State",
    states: "States",
    status: "Status",
    submit: "Submit",
    therapy: "Therapy",
    therapies: "Therapies & Procedures",
    timeStart: "Start Time",
    timeEnd: "End Time",
    timezone: "Timezone",
    title: "Title",
    type: "Type",
    update: "Update",
    upload: "Upload",
    uploadImage: "Upload Image",
    users: "Users",
    updateUserApplication: "Update user application status",
    vendor: "Vendor",
    vendorName: "Vendor Name",
    vendors: "Vendors",
    version: "Version",
    viewCalendar: "View UserCalendar",
    workSelect: "I work for...",
  },
  placeholder: {
    address: "Enter an Address",
    businessUnit: "Enter Business Unit",
    city: "Enter a city",
    domainName: "Domain name",
    email: "Enter your email",
    eventEmail: "Enter attendee email address",
    eventTitle: "Enter event title or description",
    firstName: "Enter your first name",
    lastName: "Enter your last name",
    macAddress: "Enter a Mac Address",
    mfaPhoneNumber: "(_ _ _) _ _ _  _ _  _ _",
    name: "Enter a Name",
    password: "Enter the password",
    select: "Please select",
    serialId: "Enter a serial id",
    specialist: "Enter name of surgeon/specialist",
    streetAddress: "Enter street address",
    userType: "Select user type",
    version: "Enter version",
    workplace: "Please enter workplace",
  },
  duration: {
    notificationDuration: 4000,
  },
  messages: {
    addDomainToBrand: "You successfully updated Vendor",
    blockUserHospital: "You successfully blocked hospital",
    createConsoleFailed: "There is an error, please try again.",
    createConsoleSuccess: "You successfully created Console",
    createDepartmentSuccess: "You successfully created department",
    createHospitalNetworkSuccess:
      "You have successfully created Facility Network",
    createFacilityFailed: "Unable to create Facility",
    createFacilitySuccess: "You successfully created Facility",
    updateDepartmentSuccess: "You have successfully updated Department",
    updateFacilitySuccess: "You successfully updated Facility",
    updateRoomSuccess: "You have successfully edited room",
    createNewProviderFailed: "Unable to create a HCP group",
    createNewVendor: "You successfully created vendor",
    createRoomSuccess: "You successfully created room",
    createRoomFailed: "Unable to create room",
    createVendorFailed: "There is an error, please try again.",
    createVendorSuccess: "You successfully created Vendor BU.",
    deleteBrandError:
      "Unable to delete Vendor, there are active organizations associated with this vendor",
    deleteBrandSuccess: "You successfully deleted the vendor",
    deleteConsoleFailed: "Unable to delete the Console.",
    deleteConsoleSuccess: "You successfully delete Console.",
    deleteDepartmentFailed: "Unable to delete department, please try again!",
    deleteDepartmentSuccess: "You successfully deleted department",
    deleteFacilitySuccess: "You successfully deleted Facility",
    deleteFacilityFailed: "Unable to delete Facility",
    deleteHospitalNetworkError:
      "Unable to delete Network, there are active Facilities associated with this network",
    deleteHospitalNetworkSuccess:
      "You have successfully deleted a HospitalNetwork",
    deleteProviderFailed: "Unable to delete due to active Provider users",
    deleteProviderSuccess: "You have successfully deleted a Provider",
    deleteRoomFailed: "Unable to delete the room, please try again",
    deleteRoomSuccess: "You successfully deleted the room",
    deleteUserFailed: "Unable to delete due to active Users",
    deleteUserSuccess: "You have successfully deleted a User",
    deleteVendorFailed: "Unable to delete due to active business units.",
    deleteVendorSuccess: "You have successfully deleted a Business Unit.",
    hospitalsFetchFailed: "Unable to fetch hospitals.",
    providerSuccess: "You successfully created HCP group",
    statesFetchFailed: "Unable to fetch states.",
    unblockUserHospital: "You successfully unblocked hospital",
    updateBusinessUnit: "You successfully edited Business Unit",
    updateConsoleError: "Please change the Facility to update the Console.",
    updateConsoleSuccess: "You successfully edited Console.",
    updateDebugModeFailed: "Unable to change the debug mode",
    updateDebugModeSuccess: "You successfully updated the debug mode",
    updatePasswordFailed: "Unable to change the password, please try again",
    updatePasswordSuccess: "You successfully updated user password",
    updateUserProfileFailed: "Unable to update User settings, please try again",
    updateUserProfileSuccess: "You successfully updated user profile",
    updateUserStatusSuccess: "You successfully updated user status",
  },
  error: "There is an error, please try again.",
  fetchError: "Unable to fetch results, please reload the page!",
  support: {
    email: "support@avail.com",
    phoneNumber: "+1 833 462 8245",
  },
  urls: {
    beacon: "/beacon?meeting_token=",
    portalcall: "/portalcall?meeting_token=",
    callLink:
      localStorage.getItem("FEATURE_BEACON") === "enabled"
        ? "/beacon?meeting_token="
        : "/portalcall?meeting_token=",
  },
};

export default DomainConstants;
