import { useEffect } from "react";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { logger } from "src/logging/logger";

// LocalParticipant reconnecting to the room
export const useRoomLocalParticipantReconnecting = () => {
  const { room } = useAppSelector(selectTwilioState);

  useEffect(() => {
    room.on("reconnecting", (error: any) => {
      logger().info(
        "Twilio: Detected local network issues. Trying to reconnect."
      );
      if (error.code === 53001) {
        logger().error(
          "Twilio Error: " +
            error.code +
            " - Reconnecting your signaling connection!",
          error.message
        );
      } else if (error.code === 53405) {
        logger().error(
          "Twilio Error: " +
            error.code +
            " - Reconnecting your media connection!",
          error.message
        );
      } else {
        logger().error(
          "Twilio Error: " + error.code + " - Reconnecting!",
          error.message
        );
      }
    });
  }, []);
};
