import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { Button } from "src/components/Button";
import { Select } from "src/components/Select";
import { LevelIndicator } from "src/domains/Beacon/components/LevelIndicator";
import { BROWSER_AGENTS } from "src/domains/Beacon/constants";
import { useAudioRecord } from "src/domains/Beacon/hooks/useAudioRecord";
import { useTestAudio } from "src/domains/Beacon/hooks/useTestAudio";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectLocalMedia } from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { publishAudioTrackThunk } from "src/domains/Beacon/store/stream/thunks/publishAudioTrackThunk";
import { uiActions } from "src/domains/Beacon/store/ui";
import { FEATURE_FLAGS } from "src/featureFlags";
import { isFirefox, isSafari } from "src/utils/browsers";

import styles from "./styles.scss";

export const AudioSettings = () => {
  const micAudioRef = useRef<HTMLAudioElement>();
  const audioRef = useRef<HTMLAudioElement>();
  const localMedia = useAppSelector(selectLocalMedia);
  const [
    toggleAudio,
    setSpeakerDevice,
    { playing, frequency: audioFrequency },
  ] = useTestAudio(audioRef);
  const [
    onVoiceRecord,
    onStopVoiceRecord,
    { recording, frequency, counter },
  ] = useAudioRecord(micAudioRef, localMedia.microphone?.deviceId);
  const dispatch = useDispatch();

  // When leaving the AudioSettings, the audio will be published to Twilio again
  useEffect(() => {
    return () => {
      // We publish (not enable) the new AudioTrack due to the new selected mic/speaker
      dispatch(publishAudioTrackThunk());
      dispatch(uiActions.unMuteMicrophone());
    };
  }, []);

  // Checks the current browser to set the output device, each browser is diff
  // TODO: this will be fine for now but there's a spike coming to solve this
  const onSetOutputDevice = async (deviceId: string) => {
    if (navigator.userAgent.indexOf(BROWSER_AGENTS.Firefox) > -1) {
      const deviceInfo: MediaDeviceInfo = await (navigator.mediaDevices as any).selectAudioOutput();
      setSpeakerDevice(deviceInfo);
    } else {
      (micAudioRef.current as any).setSinkId(deviceId);
      (audioRef.current as any).setSinkId(deviceId);
    }
  };

  // Detects when the user has changed a speaker to use it and output the audio
  useEffect(() => {
    if (localMedia.speaker) {
      onSetOutputDevice(localMedia.speaker.deviceId);
    }
  }, [localMedia.speaker]);

  const changeMicrophoneInput = (e) => {
    const { audioInputs } = localMedia.mediaDevices;
    const microphone = audioInputs.find((a) => a.deviceId === e.target.value);
    dispatch(streamActions.setMicrophoneDevice(microphone));
  };

  const changeSpeakerOutput = (e) => {
    const { audioOutputs } = localMedia.mediaDevices;
    const speaker = audioOutputs.find((a) => a.deviceId === e.target.value);
    dispatch(streamActions.setSpeakerDevice(speaker));
  };

  return (
    <div className={styles.list}>
      <div className={styles.group}>
        <Select
          label="Microphone"
          name="microphone"
          onChange={changeMicrophoneInput}
          value={localMedia?.microphone?.deviceId || ""}
          options={localMedia?.mediaDevices.audioInputs.map((mic) => ({
            title: mic.label,
            value: mic.deviceId,
          }))}
        />
        {FEATURE_FLAGS.showAudioTestMode && (
          <Button
            data-test-id="mic-test-button"
            onClick={() => (!recording ? onVoiceRecord() : onStopVoiceRecord())}
            label={!recording ? "Test" : `Recording ${counter}`}
          />
        )}
        <audio ref={micAudioRef} />
      </div>
      {FEATURE_FLAGS.showAudioTestMode && <LevelIndicator value={frequency} />}
      <div className={styles.group}>
        <Select
          label="Speaker"
          name="speaker"
          disabled={
            // Blocks changing the output device on Firefox and Safari due to
            // the unsupported feature
            // TODO: this will be fine for now but there's a spike coming to solve this
            isFirefox(navigator.userAgent) || isSafari(navigator.userAgent)
          }
          onChange={changeSpeakerOutput}
          value={localMedia?.speaker?.deviceId || ""}
          options={localMedia?.mediaDevices.audioOutputs.map((speaker) => ({
            title: speaker.label,
            value: speaker.deviceId,
          }))}
        />
        {FEATURE_FLAGS.showAudioTestMode && (
          <Button
            data-test-id="speaker-test-button"
            onClick={toggleAudio}
            label={!playing ? "Test" : "Stop"}
          />
        )}
        <audio ref={audioRef} />
      </div>
      {FEATURE_FLAGS.showAudioTestMode && (
        <LevelIndicator value={audioFrequency} />
      )}
    </div>
  );
};
