import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Fields, validators, Button, Icon } from '../../../../../../../../../Common/Components';
import { getcurrentUserTypes } from '../../../../../../../HomeSelectors';
import Can from '../../../../../../../../../Common/Acl/AccessControl';
import { uploadLogo } from '../../../../../../Brands/BrandsUtils';
import { updateBrandAction } from '../../../../../../Brands/BrandsActions';
import { notificationService } from '../../../../../../../../../Common/Services';

class GeneralInfoBrand extends PureComponent {
	state = {
		editView: false,
		disableSave: false,
	}

	onFileChange = (fileDetail) => {
		if (validators.imagesize(fileDetail) === undefined && validators.imageformat(fileDetail) === undefined) {
			this.setState({
				disableSave: false,
			});
		} else {
			this.setState({
				disableSave: true,
			});
		}
	}

	handleSubmit = async (data) => {
		if (data.name === this.props.brand.name && typeof data.logoLocation === 'string') return;

		try {
			if (typeof data.logoLocation !== 'string') {
				const { logoLocation: logoFile } = data;
				const uploaded = await uploadLogo(data.name, logoFile);
				data = { ...data, logoLocation: uploaded };
			}

			this.props.updateBrand(data);
		} catch {
			notificationService.error('Error updating vendor.');
		}
	}

	toggleEditView = () => {
		this.setState({ editView: !this.state.editView }, () => {
			if (!this.state.editView) {
				this.handleSubmit(this.props.updateBrandData.values);
			}
		});
	}


	render() {
		const { editView, disableSave } = this.state;
		const { brand } = this.props;

		return (
			<div className="profile-general-info">
				<form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
					<div>
						<div className="profile-general-info__top">
							{brand.logoLocation && <img src={brand.logoLocation} alt={brand.name || 'Vendor logo'} />}
							{editView &&
							<Fields.FileInputField
								name="logoLocation"
								id="fileItem"
								type="file"
								labelText="Upload Image"
								validate={[
									validators.required,
									validators.imageformat,
									validators.imagesize,
								]}
								onChange={this.onFileChange}
							/>
							}
							<Can userTypes={this.props.currentUserTypes} action="read" context="editVendorInfo">
								{editView ? (
									<Button
										isGhost
										className="profile-general-info__edit"
										type="submit"
										disabled={disableSave}
									>
										<Icon className={`icon-${editView ? 'save' : 'edit'}`} /> {editView ? 'Save' : 'Edit'}
									</Button>
								) : (
									<Button
										isGhost
										className="profile-general-info__edit"
										onClick={this.toggleEditView}
									>
										<Icon className={`icon-${editView ? 'save' : 'edit'}`} /> {editView ? 'Save' : 'Edit'}
									</Button>
								)}
							</Can>
						</div>
						<div className="brand-info__label">Vendor Name</div>

						{editView
							? <Fields.InputField
								className="brand-info__input"
								placeholder={brand.name}
								name="name"
								validate={[
									validators.required,
									validators.minimumLength3,
									validators.maximumLength48,
								]}
							/>
							: <div className="brand-info__prop">{brand.name}</div>}
					</div>
				</form>
			</div>
		);
	}
}

GeneralInfoBrand.defaultProps = {};

const mapStateToProps = state => ({
	currentUserTypes: getcurrentUserTypes(state),
	updateBrandData: state.form.updateBrandForm,
});

const mapDispatchToProps = dispatch => ({
	updateBrand: updatedBrand => dispatch(updateBrandAction(updatedBrand)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'updateBrandForm',
	enableReinitialize: true,
})(GeneralInfoBrand));
