import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../store/models";
import MenuGroup from "./MenuGroup";

interface MenuProps {
  remoteHasJoinedRoom: boolean;
  selectedCallControl: string;
}

type Props = MenuProps;

class CallControlMenu extends React.Component<Props> {
  render() {
    const { remoteHasJoinedRoom, selectedCallControl } = this.props;
    const menuProps = {
      selectedCallControl: selectedCallControl,
    };

    const highlightCallControlClass = `${menuProps.selectedCallControl}-highlight-call-control`;
    const pointedMenuArrowClass = `${menuProps.selectedCallControl}-pointed-menu-arrow-down`;

    return (
      <div className="call-control-highlighter">
        <div className={highlightCallControlClass} />
        <div className={pointedMenuArrowClass} />
        {remoteHasJoinedRoom && <MenuGroup {...menuProps} />}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect<{}, {}, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(CallControlMenu);
