import axios from 'axios';
import { securityService } from '.';
import { renderTraceIdHeader } from "src/domains/Beacon/utils/renderTraceIdHeader";

const previousRequests = {};

const request = ({
	microServiceUrl,
	resourceUrl,
	method,
	data,
	params,
	responseType,
	doSendToken,
	login,
  eula,
	cancelPrevious,
	xHeaders,
}) => {
	let appUrl = process.env.REACT_APP_API_URL;

	// Fix for connecting react-local with backend microservices
	if (appUrl === 'http://localhost:') {
		appUrl +=
			microServiceUrl === 'auth' ? 5000 :
				microServiceUrl === 'user' ? 8082 :
					microServiceUrl === 'coreregistry' ? 8083 : 465;
	}

  const configUrl = resourceUrl === 'eula' 
    ? process.env.REACT_APP_EULA_PATH 
    : `${appUrl}/${(microServiceUrl ? `${microServiceUrl}/` : '')}${resourceUrl}`;

	const config = {
		url: configUrl,
		method,
		responseType: responseType || 'json',
		data,
		params,
	};

  if(microServiceUrl === "auth") {
    config.withCredentials = true;
  }

	if (cancelPrevious && previousRequests[config.url]) {
		previousRequests[config.url].cancel();
	}

	if (doSendToken) {
		config.headers = {
			Authorization: securityService.getTokenForRequest()
		};
	}

	if (login) {
		const { username, password } = data;
		const userDataEncoded = window.btoa(`${username}:${password}`);
		
		config.headers = {
			Authorization: `Basic ${userDataEncoded}`,
			'Content-type': 'application/x-www-form-urlencoded',
		};

		config.data = { username, password };
	}

	if (xHeaders) {
		config.headers = {
			...config.headers,
			...xHeaders
		}
	}

  // Append client version to all requests
  config.headers = {
    ...config?.headers,
    // webpack will replace this value at build time
    "X-AVAIL-CLIENT-VERSION": `${APP_NAME}:${VERSION}`,
    ...(!eula && renderTraceIdHeader()),
  };

	return axios(config);
};

class ApiHelper {
	static get(microServiceUrl, resourceUrl, params) {
		return request({
			microServiceUrl,
			resourceUrl,
			method: 'get',
			params,
			doSendToken: true,
		}).then(
			response => response.data,
			({ response }) => Promise.reject((response && response.data && response.data.message) || 'Some error occurred'),
		);
	}

	static getCSV(microServiceUrl, resourceUrl, params) {
		return request({
			microServiceUrl,
			resourceUrl,
			method: 'get',
			params,
			doSendToken: true,
			responseType: 'text/csv',
		}).then(
			response => response.data,
			({ response }) => Promise.reject((response && response.data && response.data.message) || 'Some error occurred'),
		);
	}

	// returns response as is (without evaulating data yet)
	static getWithResponseType(microServiceUrl, resourceUrl, params, responseType) {
		return request({
			microServiceUrl,
			resourceUrl,
			method: 'get',
			params,
			doSendToken: true,
			responseType,
      eula: true
		}).then(
			response => response,
			({ response }) => Promise.reject((response && response.data && response.data.message) || 'Some error occurred'),
		);
	}

	static getWithoutToken(microServiceUrl, resourceUrl, params) {
		return request({
			microServiceUrl,
			resourceUrl,
			method: 'get',
			params,
			doSendToken: false,
		}).then(
			response => response.data,
			({ response }) => Promise.reject((response && response.data && response.data.message) || 'Some error occurred'),
		);
	}

	static post(microServiceUrl, resourceUrl, newResource, cancelPrevious, returnErrorObject) {
		return request({
			microServiceUrl,
			resourceUrl,
			method: 'post',
			data: newResource,
			doSendToken: true,
			cancelPrevious,
		}).then(
			response => response.data,
			({ response }) => {
				// TODO: This feels very dirty, might have to refactor this behaviour in request, idk
				if (returnErrorObject) return Promise.reject((response) || 'Some error occurred');
				return Promise.reject((response && response.data && response.data.message) || 'Some error occurred');
			}
		);
	}

	static postWithoutToken(microServiceUrl, resourceUrl, newResource, cancelPrevious, xHeaders) {
		return request({
			microServiceUrl,
			resourceUrl,
			method: 'post',
			data: newResource,
			doSendToken: false,
			cancelPrevious,
			xHeaders
		}).then(
			response => response.data,
			({ response }) =>
				Promise.reject((response && response.data && response.data.message) || 'Some error occurred'),
		);
	}

	static login(microServiceUrl, resourceUrl, newResource, cancelPrevious, xHeaders) {
		return request({
			microServiceUrl,
			resourceUrl,
			method: 'post',
			data: newResource,
			doSendToken: false,
			cancelPrevious,
			login: true,
			xHeaders
		}).then(
			response => response.data,
			({ response }) =>
				Promise.reject((response && response.data && response.data.message) || 'Some error occurred'),
		);
	}

	static put(microServiceUrl, resourceUrl, data) {
		return request({
			microServiceUrl,
			resourceUrl,
			method: 'put',
			data,
			doSendToken: true,
		}).then(
			response => response.data,
			({ response }) => Promise.reject((response && response.data && response.data.message) || 'Some error occurred'),
		);
	}

	static putWithoutToken(microServiceUrl, resourceUrl, data) {
		return request({
			microServiceUrl,
			resourceUrl,
			method: 'put',
			data,
			doSendToken: false,
		}).then(
			response => response.data,
			({ response }) => Promise.reject((response && response.data && response.data.message) || 'Some error occurred'),
		);
	}

	static remove(microServiceUrl, resourceUrl, data) {
		return request({
			microServiceUrl,
			resourceUrl,
			method: 'delete',
			doSendToken: true,
			data: data
		}).then(
			response => response.data,
			({ response }) =>
				Promise.reject((response && response.data && response.data.message) || 'Some error occurred'),
		);
	}

	static postMFAAuth(microServiceUrl, resourceUrl, params) {
		return request({
			microServiceUrl,
			resourceUrl,
			method: 'get',
			params,
			doSendToken: false,
			responseType: 'application/json',
		}).then(
			response => response.data,
			({ response }) => Promise.reject((response && response.data && response.data.message) || 'Some error occurred'),
		);
	}
}

export default ApiHelper;
