import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";
import { NAnnotationPoint } from "availkit-js/dist/Models/NAnnotationPoint";

export interface InstructionMemory {
  instructions: NAnnotationPoint[];
  style: string; // hex color
}

export const INITIAL_PREVIOUS_POINT = {
  x: undefined,
  y: undefined,
};

export const INITIAL_TELESTRATION_EVENTS_MAP = new Map<
  string | null,
  AVKAnnotationEvent[]
>();

export const TELESTRATION_EASEL_ID = "telestration-easel";
