import React from "react";

import { Popover as MuiPopover, PopoverProps } from "@mui/material";

import styles from "./styles.scss";

interface Props extends PopoverProps {
  children: React.ReactNode;
}
export const Popover = ({
  children,
  anchorOrigin = { vertical: "top", horizontal: "center" },
  transformOrigin = { vertical: "bottom", horizontal: "center" },
  ...props
}: Props) => {
  return (
    <MuiPopover
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...props}
    >
      {children}
    </MuiPopover>
  );
};

interface PopoverButtonProps {
  renderButton: (onClick: (event: any) => void) => React.ReactNode;
  content: React.ReactNode;
}
// simple wrapper that lets you have a button (or any other clickable element) open up a popover on top of it
export const PopoverButton = ({
  renderButton,
  content,
}: PopoverButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {renderButton(handleClick)}
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <div className={styles.content}>{content}</div>
      </Popover>
    </div>
  );
};
