import clsx from "clsx";

import {
  CheckCircleOutline,
  ErrorOutline,
  WarningAmberOutlined,
} from "@mui/icons-material";

import { Font } from "src/components/Font";

import styles from "./styles.scss";

interface IProps {
  type: "error" | "success" | "warning";
  message: string;
  showHelper?: boolean;
  "data-test-id": string;
  className?: string;
}

export const HelperText = ({
  message,
  type,
  "data-test-id": dataTestId,
  showHelper = true,
  className,
}: IProps) => {
  const HelperIcons = {
    error: <ErrorOutline color="error" data-test-id="error-icon-helper" />,
    success: (
      <CheckCircleOutline color="success" data-test-id="success-icon-helper" />
    ),
    warning: (
      <WarningAmberOutlined
        color="warning"
        data-test-id="warning-icon-helper"
      />
    ),
  };
  return (
    <>
      {showHelper && message ? (
        <div
          className={clsx(styles.helperTextContainer, className)}
          data-test-id={`${dataTestId}`}
        >
          {HelperIcons[type]}
          <Font
            variant="b1"
            className={styles.helperText}
            data-test-id="helper-text"
          >
            {message}
          </Font>
        </div>
      ) : null}
    </>
  );
};
