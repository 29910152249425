import { Nurep } from "availkit-js";

import React from "react";
import { connect } from "react-redux";

import classNames from "classnames";
import { Dispatch } from "redux";

import { setNoiseCancellationAction } from "../../store/meeting/actions";
import { AppState, MediaStateType } from "../../store/models";
import "./NoiseCancellation.scss";

interface DispatchProps {
  setNoiseCancellation: (enabled: boolean) => void;
}

interface StateProps {
  availKit: Nurep | null;
  bluetoothEnabled: boolean | null;
  callSid: string;
  enableInCallControls: boolean;
  mediaState: MediaStateType;
  noiseCancellation: boolean | null;
  refreshInProgress: boolean;
}

type Props = StateProps & DispatchProps;

const NoiseCancellation: React.FC<Props> = ({
  availKit,
  bluetoothEnabled,
  callSid,
  enableInCallControls,
  mediaState,
  noiseCancellation,
  refreshInProgress,
  setNoiseCancellation,
}) => {
  const onToggleClick = () => {
    const noiseCancellationState = !noiseCancellation;
    setNoiseCancellation(noiseCancellationState);
  
    availKit?.avkMediaService.setNoiseCancellation(
      callSid,
      noiseCancellationState
    );
  };

  const shouldEnableInCallControls = enableInCallControls&& !refreshInProgress;

  const noiseReductionEnabled = classNames({
    "noise-reduction-button": noiseCancellation,
    "noise-reduction-button-disabled": !noiseCancellation,
  });

  const noiseReductionDisabled = classNames({
    "noise-reduction-disabled-button-enabled": noiseCancellation,
    "noise-reduction-disabled-button-disabled": !noiseCancellation,
  });

  return (
    <div
      className={
        shouldEnableInCallControls
          ? "noise-reduction-container"
          : "noise-reduction-container-disabled"
      }
    >
      {bluetoothEnabled ? null : (
        <>
          <button
            className={
              shouldEnableInCallControls
                ? noiseReductionEnabled
                : noiseReductionDisabled
            }
            onClick={onToggleClick}
            disabled={!shouldEnableInCallControls}
          >
            <span
              className={
                shouldEnableInCallControls
                  ? "noise-reduction-text"
                  : "noise-reduction-text-disabled"
              }
            >
              Noise Reduction{" "}
              <strong>{noiseCancellation ? "On" : "Off"}</strong>
            </span>
          </button>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  availKit: state.availKit.availKitInstance,
  bluetoothEnabled: state.meeting.bluetoothEnabled,
  callSid: state.meeting.callSid,
  enableInCallControls: state.meeting.enableInCallControls,
  mediaState: state.user.mediaState,
  noiseCancellation: state.meeting.noiseCancellation,
  refreshInProgress: state.user.refreshInProgress,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setNoiseCancellation: (enabled: boolean) =>
      dispatch(setNoiseCancellationAction(enabled)),
  };
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(NoiseCancellation);
