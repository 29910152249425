import { Nurep } from "availkit-js";
import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { AVKLayout } from "availkit-js/dist/Models/AVKLayout";
import {
  AVKClearFreezeFrameEvent,
  AVKUnFreezeDataType,
} from "availkit-js/dist/Models/Events/AVKClearFreezeFrameEvent";
import { AVKConsoleBluetoothAudioEvent } from "availkit-js/dist/Models/Events/AVKConsoleBluetoothAudioEvent";
import { AVKDataType } from "availkit-js/dist/Models/Events/AVKDataTransferEvent";
import { AVKExternalInputMoveEvent } from "availkit-js/dist/Models/Events/AVKExternalInputMoveEvent";
import { AVKFreezeFramePostedEvent } from "availkit-js/dist/Models/Events/AVKFreezeFramePostedEvent";
import { AVKRemoteMutedYouEvent } from "availkit-js/dist/Models/Events/AVKRemoteMutedYouEvent";
import { AVKSidebarEvent } from "availkit-js/dist/Models/Events/AVKSidebarEvent";
import { AVKThumbnailAvailableEvent } from "availkit-js/dist/Models/Events/AVKThumbnailAvailableEvent";
import { AVKVideoLayoutEvent } from "availkit-js/dist/Models/Events/AVKVideoLayoutEvent";
import { AVKVideoSourceAnnouncementEvent } from "availkit-js/dist/Models/Events/AVKVideoSourceAnnouncement";
import { FeatureAvailabilityEvent } from "availkit-js/dist/Models/Events/FeatureAvailabilityEvent";
import { NCameraAnnouncementEvent } from "availkit-js/dist/Models/Events/NCameraAnnouncement";
import { NCameraChangeEvent } from "availkit-js/dist/Models/Events/NCameraChangeEvent";
import { NIncomingServerCallEvent } from "availkit-js/dist/Models/Events/NIncomingServerCallEvent";
import { NoiseCancellationErrorEvent } from "availkit-js/dist/Models/Events/NoiseCancellationErrorEvent";
import { NoiseCancellationEvent } from "availkit-js/dist/Models/Events/NoiseCancellationEvent";
import { NCameraLocation } from "availkit-js/dist/Models/NCameraLocation";
import { AVKTransferOptions } from "availkit-js/dist/Services/AVKDataTransferService";
import { AVKFreezeFrameService } from "availkit-js/dist/Services/AVKFreezeFrameService";
import { AVKMediaService } from "availkit-js/dist/Services/AVKMediaService";
import { AVKSidebarService } from "availkit-js/dist/Services/AVKSidebarService";
import { AVKVideoSourceDiscoveryService } from "availkit-js/dist/Services/AVKVideoSourceDiscoveryService";
import { AVKFreezeFrameServiceListener } from "availkit-js/dist/Services/Listeners/AVKFreezeFrameServiceListener";
import { AVKMediaServiceListener } from "availkit-js/dist/Services/Listeners/AVKMediaServiceListener";
import { AVKSidebarServiceListener } from "availkit-js/dist/Services/Listeners/AVKSidebarServiceListener";
import { AVKVideoSourceDiscoveryServiceListener } from "availkit-js/dist/Services/Listeners/AVKVideoSourceDiscoveryServiceListener";
import { NCameraDiscoveryServiceListener } from "availkit-js/dist/Services/Listeners/NCameraDiscoveryServiceListener";
import { NCameraDiscoveryService } from "availkit-js/dist/Services/NCameraDiscoveryService";
import { Guid } from "guid-typescript";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  NetworkQualityLevel,
  Participant,
  Room,
} from "twilio-video";

import React from "react";
import { connect } from "react-redux";

import classNames from "classnames";
import { Dispatch } from "redux";

import CallControlPanel from "src/domains/PortalCall/components/CallControlPanel/CallControlPanel";
import { withFeatureFlag } from "src/hooks/useFeatureFlag";

import arrayService from "avail-web-application/Common/Services/arrayService";

import { API } from "../../api";
import { logger, LoggerLevels } from "../../common/logger";
import {
  AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB,
  AV_FREEZE_FRAME_IMAGE_QUALITY,
  MULTI_PARTY_LAUNCH_MODE,
  NETWORK_DIAGNOSTICS_URL,
  P2P_LAUNCH_MODE,
} from "../../constants";
import {
  setFreezeFrameImage,
  setFrozenFrame,
  setFullscreenFrozenFrame,
  setImageFreeze,
  setLeftFrozenFrame,
  setRightFrozenFrame,
  unsetFrozenFrame,
  unsetFullscreenFrozenFrame,
  unsetLeftFrozenFrame,
  unsetRightFrozenFrame,
} from "../../store/image/actions";
import {
  setBluetoothEnabledAction,
  setCallState,
  setCamerasList,
  setCurrentUserRole,
  setEnableInCallControls,
  setConsoleHasExternalInputResizingAction,
  setFreezeFrameHasUpdate,
  setFreezeFrameState,
  setLayoutFrames,
  setLayoutHasUpdate,
  setNoiseCancellationAction,
  setNoiseCancellationAvailableAction,
  setTriggerRefreshFrames,
  setZoomState,
} from "../../store/meeting/actions";
import {
  AppState,
  CallState,
  CallStateType,
  FreezeFrameStateType,
  ImageState,
  LayoutFrame,
  MediaStateType,
  MenuOptionType,
  MultiPartyCallEventInfo,
  MultiPartyEventParticipant,
  PortalIdentity,
  PresenceServerConsoleCameraInfo,
  PresenceServerConsoleExternalInputInfo,
  TelestrationState,
  TelestrationStylusColor,
  TwilioCredentials,
  UserInfoType,
  UserRoleType,
  ZoomStateType,
} from "../../store/models";
import { updateTelestrationState } from "../../store/pointer/actions";
import {
  clearTwilioTracks,
  connectCall,
  getTwilioCredentialsSuccess,
  updateDimensions,
  refreshFrames
} from "../../store/twilio/actions";
import {
  setMediaState,
  setRefreshInProgress,
  updateAccessToken,
} from "../../store/user/actions";
import { LocalOrRemoteMediaTrack } from "../../types";
import BadNetworkBanner from "../BadNetworkBanner";
import { getVideoDimensions } from "../Canvas/helpers";
import NoiseCancellation from "../NoiseCancellation";
import {
  AVKCameraIdentifier,
  AVKExternalInputIdentifier,
  CameraDisplayName,
  CameraIdentifier,
  CamerasSortedByName,
} from "../Utils/CameraUtils";
import "./InCallControls.scss";
import { extractConsoleSources } from "src/utils/extractConsoleSources";

const BANNER_DISPLAY_TIME = 10000; // ms

interface StateProps {
  access_token: string;
  availKit: Nurep | null;
  callEventInfo: NIncomingServerCallEvent | null;
  callSid: string;
  callState: CallStateType;
  cameras: Array<AVKCamera | AVKExternalInput>;
  consoleHasJoinedRoom: boolean;
  consoleHasExternalInputResizing: boolean;
  currentUserRole: UserRoleType | null;
  enableInCallControls: boolean;
  eventParticipants: MultiPartyEventParticipant[] | undefined;
  freezeFrameHasUpdate: boolean;
  freezeFrameState: FreezeFrameStateType;
  joinId: string;
  imageFrozen: boolean;
  imageState: ImageState;
  layoutFrames: LayoutFrame[];
  layoutHasUpdate: boolean;
  triggerRefreshFrames: boolean;
  localNetworkQualityLevel: NetworkQualityLevel;
  localTracks: LocalOrRemoteMediaTrack[];
  loginId: string;
  mediaState: MediaStateType;
  mode: string;
  multiPartyCallEventInfo: MultiPartyCallEventInfo | null;
  noiseCancellationAvailable: boolean | null;
  refresh_token: string;
  refreshInProgress: boolean;
  remoteHasJoined: boolean;
  remoteTracks: LocalOrRemoteMediaTrack[];
  ssFFEnabled: boolean;
  twilioCredentials: TwilioCredentials;
  twilioParticipants: Participant[];
  twilioRoom: Room | null;
  zoomState: ZoomStateType;
}

interface DispatchProps {
  setCallState: (callState: CallStateType) => void;
  setMediaState: (mediaState: MediaStateType) => void;
  updateTelestrationState: (
    telestration: TelestrationState,
    stylusColor: TelestrationStylusColor
  ) => void;
  setCameras: (cameras: Array<AVKCamera | AVKExternalInput>) => void;
  setCurrentUserRole: (userRole: UserRoleType) => void;
  updateDimensions: (needsUpdate: boolean) => void;
  setLayoutFrames: (layoutFrames: LayoutFrame[]) => void;
  setZoomState: (zoomState: ZoomStateType) => void;
  setLayoutHasUpdate: (isUpdated: boolean) => void;
  setTriggerRefreshFrames: (shouldTriggerRefreshFrames: boolean) => void;
  setBluetoothEnabled: (bluetoothEnabled: boolean) => void;
  setNoiseCancellation: (enabled: boolean) => void;
  setNoiseCancellationAvailable: (enabled: boolean) => void;
  setConsoleHasExternalInputResizing: (enabled: boolean) => void;
  setRefreshInProgress: (refreshInProgress: boolean) => void;
  setEnableInCallControls: (enableInCallControls: boolean) => void;
  connectCall: () => void;
  refreshFrames: () => void;
  getTwilioCredentialsSuccess: (data: TwilioCredentials) => void;
  setImageFreeze: (frozen: boolean) => void;
  updateAccessToken: (identity: PortalIdentity) => void;
  setFreezeFrameHasUpdate: (update: boolean) => void;
  setFreezeFrameState: (freezeFrameState: FreezeFrameStateType) => void;
  setFrozenFrame: (ftransferId: string) => void;
  unsetFrozenFrame: () => void;
  setLeftFrozenFrame: (transferId: string) => void;
  setRightFrozenFrame: (transferId: string) => void;
  setFullscreenFrozenFrame: (transferId: string) => void;
  unsetLeftFrozenFrame: () => void;
  unsetRightFrozenFrame: () => void;
  unsetFullscreenFrozenFrame: () => void;
  clearFreezeFrameImage: () => void;
  clearTwilioTracks: () => void;
}

interface OwnProps {
  externalInputsImageResizing: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

class InCallControls
  extends React.Component<Props>
  implements
    NCameraDiscoveryServiceListener,
    AVKVideoSourceDiscoveryServiceListener,
    AVKSidebarServiceListener,
    AVKMediaServiceListener,
    AVKFreezeFrameServiceListener {
  sidebarTimeoutId = setTimeout(() => null, 100);
  consoleBannerTimeoutId = setTimeout(() => null, 100);
  state = {
    consoleAudioMuteCollapse: false,
    isUserHost: false,
    menuHidden: false,
    selectedMarker: "",
    selectedCallControl: "",
    showEndCallConfirmation: false,
    sidebarCollapse: false,
    showSidebarBanner: false,
    showSidebarDisabledBanner: false,
    showFreezeFrameMenu: false,
    showBluetoothAudioBanner: false,
  };

  constructor(props: Props) {
    super(props);
    this.setCollapseConsoleAudioMuted = this.setCollapseConsoleAudioMuted.bind(
      this
    );
    this.frameIsFrozen = this.frameIsFrozen.bind(this);
  }
  videoViewFrames() {
    return ["left", "right"];
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const {
      access_token,
      cameras,
      consoleHasJoinedRoom,
      externalInputsImageResizing,
      freezeFrameState,
      freezeFrameHasUpdate,
      imageState,
      mediaState,
      mode,
      ssFFEnabled,
      twilioRoom,
      zoomState,
    } = this.props;

    if (
      twilioRoom &&
      twilioRoom?.participants !== prevProps.twilioRoom?.participants
    ) {
      twilioRoom.participants.forEach((v, k) => {
        const identity = v.identity;
        logger().info("Identity", identity);
        if (identity.includes("CONSOLE")) {
          if (mode === MULTI_PARTY_LAUNCH_MODE && cameras.length === 0) {
            logger().info("Cameras", cameras);
            // only retrieve presence server info if console was in call before this user
            this.getPresenceServerConsoles();
          }
        }
      });
    }

    if (
      !consoleHasJoinedRoom &&
      consoleHasJoinedRoom !== prevProps.consoleHasJoinedRoom
    ) {
      this.props.setEnableInCallControls(false);
    }

    if (
      consoleHasJoinedRoom &&
      consoleHasJoinedRoom !== prevProps.consoleHasJoinedRoom
    ) {
      this.setInCallControls();
    }

    // Data from presence is stored here
    if (
      imageState.freezeFrame &&
      imageState.freezeFrame !== prevProps.imageState.freezeFrame
    ) {
      this.renderFreezeFrameImage(imageState.freezeFrame);
    }

    if (
      freezeFrameHasUpdate &&
      freezeFrameHasUpdate !== prevProps.freezeFrameHasUpdate
    ) {
      this.props.setFreezeFrameHasUpdate(false);
      const frameType = freezeFrameState.freezeType;
      // user clicked on a Freezeframe option, turn off submenu
      if (ssFFEnabled) {
        this.setState({ selectedCallControl: "" });
      }

      // If user clicked on a Un/Freeze option, process it
      if (frameType !== "") {
        if (this.frameIsFrozen(frameType)) {
          // we are frozen already, click means to unfreeze
          this.handleUnfreezeFrame(frameType);
        } else {
          // User wants to freeze
          this.props.updateDimensions(true);
          this.handleFreezeFrame(frameType);
        }
      }
    }

    if (access_token && access_token !== prevProps.access_token) {
      let isUserHost = true;
      if (mode === MULTI_PARTY_LAUNCH_MODE) {
        isUserHost = await this.checkUserRole();
      }
      this.setState({ isUserHost: isUserHost });
    }

    if (mediaState && mediaState !== prevProps.mediaState) {
      if (mediaState.self.sidebar === "active") {
        this.showSidebarBanner();
      }
    }

    if (externalInputsImageResizing !== prevProps.externalInputsImageResizing) {
      this.props.setConsoleHasExternalInputResizing(
        externalInputsImageResizing
      );

      // when Launch Darkly is turned off, check the Front or Overhead camera
      if (!externalInputsImageResizing) {
        let localZoomState: ZoomStateType = { ...zoomState };
        const camera = localZoomState.sliderZoom.filter(
          (zoom) =>
            zoom.cameraId.includes("Front") ||
            zoom.cameraId.includes("Overhead")
        );
        const selectedCamera = `radioButton${camera[0].name}`;
        const otherCamera =
          selectedCamera === "radioButtonRight"
            ? "radioButtonLeft"
            : "radioButtonRight";
        localZoomState[selectedCamera].checked = true;
        localZoomState[otherCamera].checked = false;
        this.props.setZoomState(localZoomState);
      }
    }

    if (this.props.availKit) {
      this.props.availKit.cameraDiscoveryService.addEventListener(this);
      this.props.availKit.avkVideoSourceDiscoveryService.addEventListener(this);
      this.props.availKit.avkSidebarService.addEventListener(this);
      this.props.availKit.avkMediaService.addEventListener(this);
      this.props.availKit.avkFreezeFrameService.addEventListener(this);
    }

    /* Update the Camera layout due to another App module changing Video Frames etc */
    try {
      if (
        this.props.layoutHasUpdate &&
        this.props.layoutHasUpdate !== prevProps.layoutHasUpdate
      ) {
        if (this.props.consoleHasExternalInputResizing) {
          this.handleEIRLayoutChange();
        } else {
          this.handleLayoutChange();
        }
        this.props.setLayoutHasUpdate(false);
      }
    } catch (error) {
      logger().warn("Error while updating Video Layout...");
    }

    /* Trigger Refresh Frames */
    try {
      if (
        this.props.triggerRefreshFrames &&
        this.props.triggerRefreshFrames !== prevProps.triggerRefreshFrames
      ) {
        this.props.setTriggerRefreshFrames(false);
        this.handleRefreshFrames();
      }
    } catch (error) {
      logger().warn("Error while refreshing frames");
    }
  }

  componentWillUnmount() {
    if (this.props.availKit) {
      this.props.availKit.cameraDiscoveryService.removeEventListener(this);
      this.props.availKit.avkVideoSourceDiscoveryService.removeEventListener(
        this
      );
      this.props.availKit.avkSidebarService.removeEventListener(this);
      this.props.availKit.avkMediaService.removeEventListener(this);
    }

    if (this.sidebarTimeoutId) {
      clearTimeout(this.sidebarTimeoutId);
      clearTimeout(this.consoleBannerTimeoutId);
    }
  }

  setupInitialCameras(cameras) {
    const { zoomState } = this.props;
    let sortedCameras = CamerasSortedByName([...cameras]);
    // The App two layout Frames: "left" and "right"
    const frameNames = this.videoViewFrames();
    let appsFrameSet: LayoutFrame[] = [];

    const createMenu = (parentName: string) => {
      let menuOptions = sortedCameras.map((camera: AVKCamera, i) => {
        const menuOp: MenuOptionType = {
          name: CameraDisplayName(camera),
          id: CameraIdentifier(camera),
          memberOf: parentName,
          isVisible: true,
          isDisabled: false,
          css: null,
        };
        return menuOp;
      });
      const fullScreen: MenuOptionType = {
        name: "Full Screen",
        id: parentName + "_Full",
        memberOf: parentName,
        isVisible: true,
        isDisabled: false,
        css: null,
      };
      menuOptions.push(fullScreen);
      return menuOptions;
    };

    frameNames.forEach((theName, i) => {
      const theMenu: MenuOptionType[] = createMenu(theName);

      const lf: LayoutFrame = {
        name: theName,
        isFullScreen: false,
        menuOptions: theMenu,
        cameraId: "",
        cameraLabel: "",
      };
      appsFrameSet.push(lf);
    });

    // set the cameraId and cameralLabel of left and right PTZ options
    appsFrameSet[0].cameraId =
      appsFrameSet.length > 0 ? appsFrameSet[0].menuOptions[0].id : "";
    appsFrameSet[0].cameraLabel =
      appsFrameSet.length > 0 ? appsFrameSet[0].menuOptions[0].name : "";
    appsFrameSet[1].cameraId =
      appsFrameSet.length > 1 ? appsFrameSet[1].menuOptions[1].id : "";
    appsFrameSet[1].cameraLabel =
      appsFrameSet.length > 1 ? appsFrameSet[1].menuOptions[1].name : "";

    this.props.setLayoutFrames([...appsFrameSet]);
    // setup Zoomstate.

    zoomState.sliderZoom[0].cameraId = appsFrameSet[0].menuOptions[0].id;
    let cameraValue = this.getCameraLocationValue(
      zoomState.sliderZoom[0].cameraId
    );
    zoomState.sliderZoom[0].value = cameraValue;
    zoomState.sliderZoom[0].settings.start = cameraValue;

    zoomState.sliderZoom[1].cameraId = appsFrameSet[0].menuOptions[1].id;
    cameraValue = this.getCameraLocationValue(zoomState.sliderZoom[1].cameraId);
    zoomState.sliderZoom[1].value = cameraValue;
    zoomState.sliderZoom[1].settings.start = cameraValue;
    this.props.setZoomState(zoomState);

    /* Enable InCallControls */
    this.setInCallControls();
  }

  getCameraLocationValue(cameraId: string): number {
    const { cameras } = this.props;
    let locationValue = -1;
    cameras.forEach((camera) => {
      if (CameraIdentifier(camera) === cameraId) {
        locationValue = camera["location"]["zoomLevel"];
      }
    });
    return locationValue;
  }

  setInCallControls(): void {
    if (this.props.mode === MULTI_PARTY_LAUNCH_MODE) {
      this.props.setEnableInCallControls(this.state.isUserHost);
    } else {
      this.props.setEnableInCallControls(true);
    }
  }

  /**
   * Nurep.js event handlers
   */
  didDiscoverCameras(
    service: NCameraDiscoveryService,
    event: NCameraAnnouncementEvent,
    cameras: AVKCamera[]
  ) {
    logger().logWithFields(LoggerLevels.info, {
    fileInfo: `InCallControls/didDiscoverCameras`,
    feature: `AvailKit listeners`,
    },
    `didDiscoverCameras, ${Array.from(event.cameras).map(cam => cam.name)}`);
    this.setDiscoveryCameras(cameras, []);
  }

  didDiscoverVideoSources(
    service: AVKVideoSourceDiscoveryService,
    event: AVKVideoSourceAnnouncementEvent
  ) {
    logger().logWithFields(LoggerLevels.info, {
    fileInfo: `InCallControls/didDiscoverVideoSources`,
    feature: `AvailKit listeners`,
    },
    `didDiscoverVideoSources, ${Array.from(event.externalInputs).map(input => input.inputName)}`);
    this.setDiscoveryCameras(event.cameras, event.externalInputs);
  }

  setDiscoveryCameras(
    cameras: AVKCamera[],
    externalInputs: AVKExternalInput[]
  ) {
    this.props.setCameras([...cameras, ...externalInputs]);
    // fire the initial camera layout pubnub message
    this.setupInitialCameras([...cameras, ...externalInputs]);

    this.props.setLayoutHasUpdate(true);
  }

  onAVKThumbnailAvailable(
    service: AVKMediaService,
    event: AVKThumbnailAvailableEvent
  ): void {
    logger().info(`Received onAVKThumbnailAvailable` + event);
  }

  async onReceiveFreezeFramePosted(
    service: AVKFreezeFrameService,
    event: AVKFreezeFramePostedEvent
  ) {
    const { loginId } = this.props;
    // TODO - must handle duplicate PostedFreezeFrame

    // TODO add proper logging

    if (event.sender === loginId) {
      return; // host is the one sending FF
    }

    const { transferIdentifier } = event;
    if (transferIdentifier) {
      const { data: imageData, error } = await API.GET.freezeFrameImageById(
        this.props.callSid,
        this.props.access_token,
        transferIdentifier
      );
      // check for error.
      // if there is an error. we should skip
      if (!error) {
        const largeMedia = document.querySelector(
          ".large-media"
        ) as HTMLElement;
        const { fittedW, fittedH } = mapSizings(
          largeMedia,
          (imageData as unknown) as ImageBitmap
        );

        const imgElement = document.createElement("img");
        imgElement.id = this.getCanvasDOMId("FFO_FULLSCREEN");
        imgElement.src = imageData;
        imgElement.style.position = "absolute";
        imgElement.style.top = "0";
        imgElement.style.left = "0";
        imgElement.style.width = fittedW;
        imgElement.style.height = fittedH;

        largeMedia.appendChild(imgElement);
        // @ts-ignore
        this.props.setFullscreenFrozenFrame(transferIdentifier);
        this.props.setImageFreeze(true);
        this.setState({ selectedCallControl: "freeze-frame" });
      }
    }
  }

  receivedFeatureAvailability(
    service: AVKMediaService,
    event: FeatureAvailabilityEvent
  ) {
    if (event.features.hasOwnProperty("noiseCancellation")) {
      this.props.setNoiseCancellationAvailable(true);
    } else {
      this.props.setNoiseCancellationAvailable(false);
    }
    this.props.setBluetoothEnabled(event.features.bluetoothAudio);
    this.props.setNoiseCancellation(event.features.noiseCancellation!);
    this.props.setConsoleHasExternalInputResizing(
      event.features.externalInputResizing &&
        this.props.externalInputsImageResizing
    );
  }

  onExternalInputMove(
    service: AVKMediaService,
    event: AVKExternalInputMoveEvent
  ): void {
    const {
      access_token,
      callSid,
      callEventInfo: { console_id },
    } = this.props;
    logger().info(
      `onExternalInputMove w/ access_token - ${access_token}, callSid - ${callSid}, console_id - ${console_id}`
    );
  }

  onNoiseCancellationChange(
    service: AVKMediaService,
    event: NoiseCancellationEvent
  ) {
    this.props.setNoiseCancellation(event.noiseCancellation);
  }

  onNoiseCancellationError(
    service: AVKMediaService,
    event: NoiseCancellationErrorEvent
  ) {
    this.props.setNoiseCancellation(event.noiseCancellation);
  }

  renderFreezeFrameImage(imageData: any) {
    const largeMedia = document.querySelector(".large-media") as HTMLElement;
    const { fittedW, fittedH } = mapSizings(largeMedia, imageData);

    const imgElement = document.createElement("img");
    imgElement.id = this.getCanvasDOMId("FFO_FULLSCREEN");
    imgElement.src = imageData;
    imgElement.style.position = "absolute";
    imgElement.style.top = "0";
    imgElement.style.left = "0";
    imgElement.style.width = fittedW;
    imgElement.style.height = fittedH;

    largeMedia.appendChild(imgElement);
    // @ts-ignore
    // this.props.setFullscreenFrozenFrame(transferIdentifier); // CANT REMEMBER WHY WE NEED THIS
    // TODO Freeze frame, the frame size not set properly
    this.props.setImageFreeze(true);
    this.setState({ selectedCallControl: "freeze-frame" });
  }

  // TODO: This is retained for future use when BE implements the service,
  // for now we are using receivedFeatureAvailability
  receivedConsoleCapabilities = (service: any, event: any) => {};

  onReceivedClearFreezeFrame(
    freezeFrameService: AVKFreezeFrameService,
    event: AVKClearFreezeFrameEvent
  ) {
    const { loginId } = this.props;

    if (event.sender === loginId) {
      // noop if current user sent clearFF
      return;
    }

    this.handleUnfreezeFrame("FFO_FULLSCREEN", true);
  }

  onReceivedSidebarStatus(
    sidebarService: AVKSidebarService,
    event: AVKSidebarEvent
  ) {
    const { sidebarActive } = event;

    if (sidebarActive) {
      this.showSidebarBanner();
    } else {
      // if local sidebar state is false and equal to server response
      const sidebarState = this.props.mediaState.self.sidebar === "active";
      if (!sidebarActive === sidebarState) this.showSidebarDisabledBanner();
    }

    const originalState = this.props.mediaState;
    this.props.setMediaState({
      ...originalState,
      self: {
        ...originalState.self,
        sidebar: sidebarActive ? "active" : "inactive",
      },
    });
  }

  onRemoteMutedYou(service: AVKMediaService, event: AVKRemoteMutedYouEvent) {
    const audioMuted = this.props.mediaState.self.audio === "mute";

    // Make sure current user loginId exists in event loginIds to mute
    if ([...event.loginIds].includes(this.props.loginId)) {
      this.toggleAudioMute(audioMuted);
    }
  }

  onConsoleBluetoothAudioChange(
    mediaService: AVKMediaService,
    event: AVKConsoleBluetoothAudioEvent
  ) {
    const { isBluetoothDevice } = event;

    // Show banner when switching audio to console
    if (!isBluetoothDevice) {
      this.setState({
        showBluetoothAudioBanner: true,
      });
      this.props.setBluetoothEnabled(false);

      // clear previous timeout
      if (this.consoleBannerTimeoutId) {
        clearTimeout(this.sidebarTimeoutId);
      }

      this.consoleBannerTimeoutId = setTimeout(() => {
        this.setState({ showBluetoothAudioBanner: false });
      }, BANNER_DISPLAY_TIME);
    } else {
      this.setState({
        showBluetoothAudioBanner: false,
      });
      this.props.setBluetoothEnabled(true);

      // clear previous timeout
      if (this.consoleBannerTimeoutId) {
        clearTimeout(this.sidebarTimeoutId);
      }
    }
  }

  togglePip = (pipEnabled: boolean): void => {
    this.props.setMediaState({
      ...this.props.mediaState,
      pip: pipEnabled ? "hide" : "show",
    });
  };

  toggleVideoMute = (videoMuted: boolean): void => {
    this.toggleMediaMute(!videoMuted, "video"); // Run this before changing mute state
    this.props.setMediaState({
      ...this.props.mediaState,
      self: {
        ...this.props.mediaState.self,
        video: videoMuted ? "unmute" : "mute",
      },
    });
  };

  toggleSidebarStatus = (): void => {
    if (!this.state.isUserHost) {
      // noop; sidebar functionality should be hidden from non-host participants
      return;
    }

    const { mediaState, callSid, availKit } = this.props;
    const sidebarActive = mediaState.self.sidebar === "active";
    availKit?.avkSidebarService.setSidebarStatus(callSid, !sidebarActive);
  };

  toggleAudioMute = (audioMuted: boolean): void => {
    this.toggleMediaMute(!audioMuted, "audio"); // Run this before changing mute state
    this.props.setMediaState({
      ...this.props.mediaState,
      self: {
        ...this.props.mediaState.self,
        audio: audioMuted ? "unmute" : "mute",
      },
    });
  };

  toggleMediaMute(muted: boolean, kind: "audio" | "video") {
    const mediaTracks = this.props.localTracks.filter((t) => t.kind === kind);

    if (muted) {
      mediaTracks.forEach((t) => {
        if (t instanceof LocalAudioTrack) {
          t.disable();
        }
        if (t instanceof LocalVideoTrack) {
          logger().info(`Host has disabled ${kind}`);
          t.disable();
        }
      });
    } else {
      mediaTracks.forEach((t) => {
        if (t instanceof LocalAudioTrack) {
          t.enable();
        }
        if (t instanceof LocalVideoTrack) {
          logger().info(`Host has enabled ${kind}`);
          t.enable();
        }
      });
    }
  }

  toggleMenu = (menuHidden: boolean): void => {
    this.showSidebarBanner();
    this.setState({ menuHidden: !menuHidden });
  };

  // TODO refactor so this is reusable
  showSidebarBanner = () => {
    if (this.props.mediaState.self.sidebar !== "active") return;

    this.setState({ showSidebarBanner: true, sidebarCollapse: false });

    // clear previous timeout
    if (this.sidebarTimeoutId) {
      clearTimeout(this.sidebarTimeoutId);
    }

    this.sidebarTimeoutId = setTimeout(() => {
      this.setState({ sidebarCollapse: true });
    }, BANNER_DISPLAY_TIME);
  };

  showSidebarDisabledBanner() {
    this.setState({ showSidebarBanner: true, sidebarCollapse: false });

    // clear previous timeout
    if (this.sidebarTimeoutId) {
      clearTimeout(this.sidebarTimeoutId);
    }

    this.sidebarTimeoutId = setTimeout(() => {
      this.setState({ sidebarCollapse: true, showSidebarBanner: false });
    }, BANNER_DISPLAY_TIME);
  }

  selectMarkerForTelestration = (selectedMarker: string): void => {
    /* If user clicked on the same pallete, turn off telestration */
    if (this.state.selectedMarker === selectedMarker) {
      this.props.updateTelestrationState(
        "off",
        selectedMarker as TelestrationStylusColor
      );
      selectedMarker = "";
    } else {
      this.props.updateTelestrationState(
        "on",
        selectedMarker as TelestrationStylusColor
      );
      this.props.updateDimensions(true);
    }
    this.setState({ selectedMarker });
  };

  clearTelestration = (): void => {
    this.props.updateTelestrationState("clear", "");
  };

  frameIsFrozen(frameType: string) {
    const { imageState } = this.props;

    switch (frameType) {
      case "FFO_LEFT":
        return (
          imageState.leftTransferIdentifier &&
          imageState.leftTransferIdentifier !== ""
        );
      case "FFO_RIGHT":
        return (
          imageState.rightTransferIdentifier &&
          imageState.rightTransferIdentifier !== ""
        );
      case "FFO_FULLSCREEN":
      default:
        return (
          imageState.fullscreenTransferIdentifier &&
          imageState.fullscreenTransferIdentifier !== ""
        );
    }
  }

  getFrozenFrameInfo(frameType: string) {
    const { imageState } = this.props;

    switch (frameType) {
      case "FFO_LEFT":
        return imageState.leftTransferIdentifier;
      case "FFO_RIGHT":
        return imageState.rightTransferIdentifier;
      case "FFO_FULLSCREEN":
      default:
        return imageState.fullscreenTransferIdentifier;
    }
  }

  getCanvasDOMId(frameType: string) {
    return `${frameType}_CVS`;
  }

  async handleFreezeFrameMP(frameType: string, imageData: string) {
    const { loginId } = this.props;
    const freezeFrameFormData = new FormData();
    const blob = new Blob([imageData], { type: "image/jpeg" });
    freezeFrameFormData.append("content", blob, `${Date.now()}.jpeg`);

    try {
      const { data, error } = await API.POST.uploadFreezeFrame(
        this.props.callSid,
        this.props.access_token,
        freezeFrameFormData
      );

      if (error) {
        throw new Error(error);
      }

      // Multi-party currently only supports full screen FF
      this.props.setFullscreenFrozenFrame(data.id);
      const freezeFramePostedEvent = new AVKFreezeFramePostedEvent(
        this.props.callSid,
        Guid.parse(data.id),
        2
      );
      freezeFramePostedEvent.transferIdentifier = Guid.parse(data.id)
        .toString()
        .toLowerCase() as any;
      freezeFramePostedEvent.sender = loginId;

      this.props.availKit?.eventService.broadcast(freezeFramePostedEvent);
    } catch (error) {
      // Reset any freeze frame states that have been set prior to making API call
      this.handleUnfreezeFrame("FFO_FULLSCREEN", true);
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "FreezeFrame",
        },
        "FreezeFrame cancelled. Screen image couldn't be uploaded."
      );
    }
  }

  handleFreezeFrame(frameType: string): void {
    const video = document.querySelector(
      ".large-media video"
    ) as HTMLVideoElement;

    const ffoToAVKFreezeFrameDataType = (ffo: string): AVKDataType => {
      switch (ffo) {
        case "FFO_LEFT":
          return AVKDataType.FreezeFrameSplitLeftImage;
        case "FFO_RIGHT":
          return AVKDataType.FreezeFrameSplitRightImage;
        default:
        case "FFO_FULLSCREEN":
          return AVKDataType.FreezeFrameImage;
      }
    };

    logger().logWithFields(
      LoggerLevels.info,
      { feature: "FreezeFrame" },
      "User initiated freezeframe."
    );

    const videoArea = getVideoDimensions(video); // actual video bounds

    const { offsetWidth, offsetHeight } = video;

    /* Make sure the imageData Canvas is under Telestration Canvas */
    let width = videoArea.width;
    let height = videoArea.height;
    let left = (offsetWidth - videoArea.width) / 2;
    let top = (offsetHeight - videoArea.height) / 2;

    // Initialize dimensions for extracting from source image
    let sx = 0;
    let sy = 0;
    let sw = width;
    let sh = height;
    switch (frameType) {
      case "FFO_LEFT":
        // Map Source Image dimensional fenceposts
        sx = 0;
        sw = video.videoWidth / 2;
        sh = video.videoHeight;
        sy = 0;
        // resize capture frame to 1/2 frame
        width /= 2;
        break;
      case "FFO_RIGHT":
        sx = video.videoWidth / 2;
        sw = video.videoWidth / 2;
        sh = video.videoHeight;
        sy = 0;
        width /= 2;
        left = offsetWidth / 2; // += width;
        break;
      default:
        sx = 0;
        sw = video.videoWidth;
        sh = video.videoHeight;
        sy = 0;
        break;
    }

    const capturedImageCanvas = document.createElement("canvas");
    capturedImageCanvas.className += "freezeframeimage";
    capturedImageCanvas.id = this.getCanvasDOMId(frameType); // "freezeframeimage";

    const context = capturedImageCanvas.getContext("2d");

    capturedImageCanvas.width = width;
    capturedImageCanvas.height = height;
    capturedImageCanvas.style.position = "absolute";
    capturedImageCanvas.style.top = top + "px";
    capturedImageCanvas.style.left = left + "px";

    if (context) {
      // OLD:
      // context.drawImage( video, 0,0,  capturedImageCanvas.width,capturedImageCanvas.height )
      // NEW:
      // context.drawImage(image,
      //          sx, sy, sWidth, sHeight,
      //          dx, dy, dWidth, dHeight);
      context.drawImage(
        video,
        sx,
        sy,
        sw,
        sh,
        0,
        0,
        capturedImageCanvas.width,
        capturedImageCanvas.height
      );
    }
    /**
     * Provide a means for configuring run time freeze frame compression ratio.
     * In future this may come from a state when UI supports it.
     */

    let imageQuality = AV_FREEZE_FRAME_IMAGE_QUALITY;
    const imageQualityString = localStorage.getItem(
      "AV_FREEZE_FRAME_IMAGE_QUALITY"
    );
    if (imageQualityString !== null) {
      try {
        imageQuality = parseFloat(imageQualityString);
        if (isNaN(imageQuality) || imageQuality < 0.1 || imageQuality > 1) {
          imageQuality = AV_FREEZE_FRAME_IMAGE_QUALITY;
        }
      } catch (e) {
        imageQuality = AV_FREEZE_FRAME_IMAGE_QUALITY;
      }
    }

    // Strip off the header data, `data:image/jpeg;base64,`.
    const imageData = capturedImageCanvas
      .toDataURL("image/jpeg", imageQuality)
      .slice(23);

    const largeMedia = document.querySelector(".large-media") as HTMLElement;
    // const ffDiv = document.getElementById(frameType) as HTMLElement;
    largeMedia.appendChild(capturedImageCanvas);

    const { availKit, callSid } = this.props;

    /* Make provision for configuring chunkSize for adaptive chunkSize in future */
    let chunkSize: number = AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB;
    const chunkSizeString = localStorage.getItem(
      "AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB"
    );
    if (chunkSizeString !== null) {
      try {
        chunkSize = parseInt(chunkSizeString, 10);

        /* Support 1kb to 30kb uploads */
        if (isNaN(chunkSize) || chunkSize > 30 || chunkSize < 1) {
          chunkSize = AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB;
        }
      } catch (e) {
        chunkSize = AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB;
      }
    }
    /* Provide a means for configuring chunkSize, in KB */
    const transferOptions: AVKTransferOptions = {
      chunkSize,
    };

    if (imageData && availKit) {
      if (this.props.mode === MULTI_PARTY_LAUNCH_MODE) {
        this.handleFreezeFrameMP(frameType, imageData);
      } else {
        /*********
         **** FFO: TESTING BYPASS
         */

        const transferIdentifier = availKit.transferServiceV2.transferDataWithOptions(
          imageData,
          ffoToAVKFreezeFrameDataType(frameType),
          callSid,
          transferOptions
        );

        // store frame existence in our state
        switch (frameType) {
          case "FFO_LEFT":
            transferIdentifier &&
              this.props.setLeftFrozenFrame(transferIdentifier);
            break;
          case "FFO_RIGHT":
            transferIdentifier &&
              this.props.setRightFrozenFrame(transferIdentifier);
            break;
          case "FFO_FULLSCREEN":
            transferIdentifier &&
              this.props.setFullscreenFrozenFrame(transferIdentifier);
            break;
        }
      }

      this.props.setImageFreeze(true);
    } else {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "FreezeFrame",
        },
        "FreezeFrame cancelled. Screen image couldn't be captured."
      );
    }
  }

  selectCallControl = (selectedCallControl: string): void => {
    const { ssFFEnabled } = this.props;
    const prevSelectedCallControl = this.state.selectedCallControl;

    const toggleUI = () => {
      return this.state.selectedCallControl === selectedCallControl
        ? ""
        : selectedCallControl;
    };

    /* If user clicked on the same pallete, turn off telestration */
    // if (this.state.selectedCallControl === selectedCallControl) {
    //   selectedCallControl = "";
    // }

    switch (selectedCallControl) {
      case "freeze-frame":
        // delay to prevent spamming of freeze frame
        this.props.setRefreshInProgress(true);
        setTimeout(() => this.props.setRefreshInProgress(false), 500);
        // 1/2021 Feature Delayed due to pending SSFF Release
        if (!ssFFEnabled) {
          const { freezeFrameState } = this.props;
          const isFrozen = this.frameIsFrozen("FFO_FULLSCREEN");
          freezeFrameState.freezeType = "FFO_FULLSCREEN";
          this.props.setFreezeFrameState(freezeFrameState);
          this.props.setFreezeFrameHasUpdate(true);
          if (isFrozen) {
            // if screen's frozen coming into this handler,
            // then we are unfreezing.. turn off gui
            selectedCallControl = "";
            this.clearTelestration();
          }
        } else {
          /* If user clicked on the FF button */
          selectedCallControl = toggleUI();
        }
        break;
      default:
        /* If user clicked on the same button, turn off telestration */
        selectedCallControl = toggleUI();
        break;
    }
    this.setState({ selectedCallControl: selectedCallControl });

    this.houseKeepingOnPreviousCallControl(prevSelectedCallControl);
  };

handleRefreshFrames = () => this.props.refreshFrames();

  handleLayoutChange() {
    const {
      availKit,
      callSid,
      layoutFrames,
      cameras,
      mode,
      currentUserRole,
      zoomState,
    } = this.props;
    const cameraChangeEvent = new NCameraChangeEvent(callSid);
    // Create array of cameras ordered by framed array
    let cameraList = [];

    const fullScreenFrame: LayoutFrame[] = layoutFrames.filter(
      (frame) => frame.isFullScreen
    );
    if (fullScreenFrame.length !== 0) {
      // Find the frame who has Full Screen set
      cameraList = cameras.filter(
        (camera) => CameraIdentifier(camera) === fullScreenFrame[0].cameraId
      );
      logger().info(`Selecting fullscreen: ${fullScreenFrame[0].cameraId}`);
    } else {
      // Create ordered array of the Cameras in each frame
      const framedCameraIds: string[] = layoutFrames.map(
        (camera) => camera.cameraId
      );

      var localList = [];

      framedCameraIds.forEach((cameraId) => {
        localList.push(
          cameras.filter((camera) => CameraIdentifier(camera) === cameraId)[0]
        );
      });

      // make a copy of the array
      cameraList = [...localList];
      logger().info(`Selecting cameras: ${framedCameraIds}`);
    }

    cameraChangeEvent.targetCameras = cameraList;
    if (availKit) {
      let shouldBroadcastInitialCameraChange = true;

      if (mode === MULTI_PARTY_LAUNCH_MODE && currentUserRole !== "HOST") {
        shouldBroadcastInitialCameraChange = false;
      }

      if (shouldBroadcastInitialCameraChange) {
        availKit.eventService.broadcast(cameraChangeEvent);
      }
    }

    // these loop through both lists and modifies the slider state for each newly selected state
    cameraList.forEach((camera) => {
      zoomState.sliderZoom.forEach((input, index) => {
        if (input.cameraId === camera.cameraIdentifier) {
          zoomState.sliderZoom[index].value = camera.location.zoomLevel * 10;
        }
      });
    });
    this.props.setZoomState(zoomState);
  }

  handleEIRLayoutChange() {
    const {
      availKit,
      callSid,
      cameras,
      currentUserRole,
      layoutFrames,
      mode,
      zoomState,
    } = this.props;
    const cameraChangeEvent = new AVKVideoLayoutEvent(callSid);
    const layoutInfo: AVKLayout[] = [];
    // Create array of cameras ordered by framed array
    const filteredAVKCameras = cameras.filter((camera) =>
      AVKCameraIdentifier(camera)
    );
    const filterAVKExternalInputs = cameras.filter((camera) =>
      AVKExternalInputIdentifier(camera)
    );
    let cameraList = [];
    let externalInputList = [];

    const fullScreenFrame: LayoutFrame[] = layoutFrames.filter(
      (frame) => frame.isFullScreen
    );
    if (fullScreenFrame.length !== 0) {
      // Find the frame who has Full Screen set
      cameraList = filteredAVKCameras.filter(
        (camera) => CameraIdentifier(camera) === fullScreenFrame[0].cameraId
      );
      externalInputList = filterAVKExternalInputs.filter(
        (camera) => CameraIdentifier(camera) === fullScreenFrame[0].cameraId
      );
      const newLayout = new AVKLayout();
      newLayout.id = fullScreenFrame[0].cameraId;
      newLayout.label = fullScreenFrame[0].cameraLabel;
      layoutInfo.push(newLayout);
      logger().info(`Selecting fullscreen: ${fullScreenFrame[0].cameraId}`);
    } else {
      // Create ordered array of the Cameras in each frame
      const framedCameraIds: string[] = layoutFrames.map(
        (camera) => camera.cameraId
      );

      var localAVKCameraList = [];
      var localAVKExternalInputList = [];

      framedCameraIds.forEach((cameraId) => {
        localAVKCameraList.push(
          filteredAVKCameras.filter(
            (camera) => CameraIdentifier(camera) === cameraId
          )[0]
        );
        localAVKExternalInputList.push(
          filterAVKExternalInputs.filter(
            (camera) => CameraIdentifier(camera) === cameraId
          )[0]
        );
      });

      // make a copy of the array
      cameraList = [...localAVKCameraList].filter(Boolean);
      externalInputList = [...localAVKExternalInputList].filter(Boolean);
      layoutFrames.forEach((frame) => {
        const newLayout = new AVKLayout();
        newLayout.id = frame.cameraId;
        newLayout.label = frame.cameraLabel;
        layoutInfo.push(newLayout);
      });
      logger().info(`Selecting cameras: ${framedCameraIds}`);
    }

    cameraChangeEvent.cameras = cameraList;
    cameraChangeEvent.externalInputs = externalInputList;
    cameraChangeEvent.layoutInfo = layoutInfo;
    cameraChangeEvent.presetEvent = true;

    if (availKit) {
      let shouldBroadcastInitialCameraChange = true;

      if (mode === MULTI_PARTY_LAUNCH_MODE && currentUserRole !== "HOST") {
        shouldBroadcastInitialCameraChange = false;
      }

      if (shouldBroadcastInitialCameraChange) {
        availKit.eventService.broadcast(cameraChangeEvent);
      }
    }

    // these loop through both lists and modifies the slider state for each newly selected state
    cameraList.forEach((camera) => {
      zoomState.sliderZoom.forEach((input, index) => {
        if (input.cameraId === camera.cameraIdentifier) {
          zoomState.sliderZoom[index].value = camera.location.zoomLevel * 10;
        }
      });
    });
    externalInputList.forEach((externalInput) => {
      zoomState.sliderZoom.forEach((input, index) => {
        if (input.cameraId === externalInput.id) {
          zoomState.sliderZoom[index].value = externalInput.zoomLevel * 10;
        }
      });
    });
    this.props.setZoomState(zoomState);
  }

  handleUnfreezeFrame(frameType: string, shouldSkipBroadcast?: boolean) {
    const {
      availKit,
      callSid,
      access_token,
      imageState,
      multiPartyCallEventInfo,
      loginId,
      setRefreshInProgress,
    } = this.props;

    // delay to prevent spamming of freeze frame
    setRefreshInProgress(true);
    setTimeout(() => setRefreshInProgress(false), 500);

    const ffoToAVKUnFreezeDataType = (ffo: string): AVKUnFreezeDataType => {
      switch (ffo) {
        case "FFO_LEFT":
          return AVKUnFreezeDataType.UnFreezeFrameLeft;
        case "FFO_RIGHT":
          return AVKUnFreezeDataType.UnFreezeFrameRight;
        case "FFO_FULLSCREEN":
        default:
          return AVKUnFreezeDataType.UnFreezeFrameFullScreen;
      }
    };

    // 1. Remove the object.
    // NOTE: at the moment canvas is used by HOST and img tag is used by participants
    const canvasOrImageId = this.getCanvasDOMId(frameType); // "freezeframeimage");

    // Resolves SOFT-3021 & SOFT-3023; This is a hack around unfreezing both
    // types of freeze frames. This is a bit of a hack so should be refactored
    // so that Freeze Frame is only created in one way not with two different ways (img & canvas)
    const freezeFrameImage = document.querySelectorAll(`#${canvasOrImageId}`);

    freezeFrameImage.forEach((freezeFrameElement) => {
      freezeFrameElement.parentNode.removeChild(freezeFrameElement);
    });

    // Remove FF from Redux otherwise video gets set to invisible; LargeMedia.tsx line 91
    this.setState({ selectedCallControl: "" });
    this.props.setImageFreeze(false);
    this.props.clearFreezeFrameImage();

    switch (frameType) {
      case "FFO_LEFT":
        this.props.unsetLeftFrozenFrame();
        break;
      case "FFO_RIGHT":
        this.props.unsetRightFrozenFrame();
        break;
      case "FFO_FULLSCREEN":
        this.props.unsetFullscreenFrozenFrame();
        break;
    }

    if (shouldSkipBroadcast) {
      return;
    }
    /*
      export enum AVKUnFreezeDataType {
        UnFreezeFrameLeft,
        UnFreezeFrameRight,
        UnFreezeFrameFullScreen
        }
      export class AVKClearFreezeFrameEvent(
          sender: NActor,
          channel: NChannel,
          dataType: AVKUnFreezeDataType
        )
     */
    // 2. Send an event to unfreeze on the other side.

    /************
     **** FFO: TESTING BYPASS
     */

    const clearFreezeFrameEvent = new AVKClearFreezeFrameEvent(
      callSid,
      ffoToAVKUnFreezeDataType(frameType)
    );

    // 3. Lookup this freeze frameType  in our set of Frozen Frames
    const ffTransferId = this.getFrozenFrameInfo(frameType);

    if (!ffTransferId) {
      return;
    }

    clearFreezeFrameEvent.transferIdentifier = Guid.parse(ffTransferId)
      .toString()
      .toLowerCase() as any;
    clearFreezeFrameEvent.sender = loginId;

    if (availKit) {
      availKit.eventService.broadcast(clearFreezeFrameEvent);
    }

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "FreezeFrame",
      },
      `User initiated Unfreezeframe. TransferIdentifier: ${ffTransferId}`
    );

    // 4. Cleanup Data
    // if (availKit) {
    //   // 4. clear any buffer in the transfer service.
    //   availKit.transferServicev2.clearTransferData(ffTransferId);
    // }

    // Clean up presence service so it only contains images that are currently frozen
    const { fullscreenTransferIdentifier } = imageState;
    if (
      this.props.mode === MULTI_PARTY_LAUNCH_MODE &&
      fullscreenTransferIdentifier
    ) {
      try {
        API.DELETE.removeFreezeFrameImageById(
          callSid,
          access_token,
          fullscreenTransferIdentifier
        ).catch((error) => {
          logger().error(
            `Error while deleting Freeze Frame image: ${error} with eventId: ${multiPartyCallEventInfo?.eventId!} & access_token: ${access_token}`
          );
        });
      } catch (error) {
        logger().error(
          `Error while deleting Freeze Frame image: ${error} with eventId: ${multiPartyCallEventInfo?.eventId!} & access_token: ${access_token}`
        );
        logger().error("Checking User Role will be skipped...");
      }
    }
  }

  /** Used to take certain actions when call control changes */
  houseKeepingOnPreviousCallControl(prevSelectedCallControl: string): void {
    switch (prevSelectedCallControl) {
      case "freeze-frame":
        break;
    }
  }

  showEndCallConfirmation = (showEndCallConfirmation: boolean): void => {
    this.props.setCallState(CallState.ENDCALLCONFIRMATION);
  };

  openNetworkDiagnostics = (): void => {
    window.open(NETWORK_DIAGNOSTICS_URL, "_blank");
  };

  updateConsoleAudioMute() {
    const { remoteTracks, mediaState, twilioRoom } = this.props;
    const twilioParticipants = twilioRoom?.participants;
    let roomAudioTrack: string = "";

    if (twilioParticipants) {
      for (let participant of twilioParticipants!.values()) {
        if (participant.identity.includes("CONSOLE")) {
          const audioTracks = participant.audioTracks;
          for (let key of audioTracks.keys()) {
            roomAudioTrack = key;
          }
        }
      }
    }

    const disabledAudioTrackNum = remoteTracks.findIndex((t) => {
      return "sid" in t ? t.sid === roomAudioTrack && !t.isEnabled : -1;
    });
    let consoleAudioMuted = disabledAudioTrackNum >= 0;

    if (
      (consoleAudioMuted && mediaState.console.audio === "unmute") ||
      (!consoleAudioMuted && mediaState.console.audio === "mute")
    ) {
      this.props.setMediaState({
        ...mediaState,
        console: {
          ...mediaState.console,
          audio: consoleAudioMuted ? "mute" : "unmute",
        },
      });

      /* If console audio is muted, retain the current state, else reset */
      const consoleAudioMuteCollapse = consoleAudioMuted
        ? this.state.consoleAudioMuteCollapse
        : false;
      this.setState({ consoleAudioMuteCollapse });
    }
    return consoleAudioMuted;
  }

  setCollapseConsoleAudioMuted(shouldCollapse: true) {
    this.setState({ consoleAudioMuteCollapse: shouldCollapse });
  }

  async checkUserRole() {
    const { access_token, loginId, multiPartyCallEventInfo } = this.props;

    const { data: eventInfo, error } = await API.GET.eventInfo(
      multiPartyCallEventInfo?.eventId!,
      access_token
    );

    if (error) {
      logger().error(
        `Error calling Event Info API: ${error} with eventId: ${multiPartyCallEventInfo?.eventId!} & access_token: ${access_token}`
      );
      logger().error("Checking User Role will be skipped...");
      return false;
    } else {
      const loggedUser: UserInfoType[] = eventInfo?.participants.filter(
        (who: { loginId: string }) => loginId === who.loginId
      )!;

      this.props.setCurrentUserRole(
        (loggedUser[0].role as unknown) as UserRoleType
      );
      logger().info("Participant Type : " + loggedUser[0].role);
      return loggedUser[0].role === "HOST";
    }
  }

  async getPresenceServerConsoles() {
    const { access_token, twilioCredentials } = this.props;
    const { data, error } = await API.GET.getConsolesBySessionId(
      twilioCredentials.callSid!,
      access_token
    );

    if (error) {
      logger().error(
        `Error calling Consoles API: ${error} with twilioCredentials: ${twilioCredentials.callSid!} & access_token: ${access_token}`
      );
    } else {
      if (data && Object.keys(data!).length) {
        const consoleParticipant = this.props. twilioParticipants.find((p) => p.identity.includes("CONSOLE"))

        const consoleCameras = extractConsoleSources("cameras", data, consoleParticipant);
        const consoleExternalInputs: AVKExternalInput[] = extractConsoleSources(
          "externalInputs",
          data, consoleParticipant
        );

        const discoveryCameras =
          (consoleCameras.length > 0 &&
            ([...this.createDiscoveryAVKCameras(consoleCameras)] as AVKCamera[])) ||
          [];
        const discoveryExternalInputs =
          (consoleExternalInputs.length > 0 &&
            (this.createDiscoveryAVKExternalInputs(
              consoleExternalInputs
            ) as AVKExternalInput[])) ||
          [];

        this.setDiscoveryCameras(discoveryCameras, discoveryExternalInputs);
      }
    }
  }

  createDiscoveryAVKCameras(data: any) {
    const newData = data.map((camera: PresenceServerConsoleCameraInfo) => {
      const avkCamera = new AVKCamera();
      avkCamera.cameraIdentifier = camera.id;
      avkCamera.input = camera.cameraInput;
      avkCamera.name = camera.name;
      avkCamera.mount = camera.cameraMount;
      avkCamera.type = camera.cameraType;

      avkCamera.location = new NCameraLocation();
      avkCamera.location.x = camera.location.x;
      avkCamera.location.y = camera.location.y;
      avkCamera.location.zoomLevel = camera.location.zoomLevel;

      return avkCamera;
    });

    arrayService.ascSort(newData, "cameraIdentifier");

    return newData;
  }

  createDiscoveryAVKExternalInputs(data: any) {
    const newData = data.map(
      (camera: PresenceServerConsoleExternalInputInfo) => {
        // if not Front or Overhead, then they are external inputs
        const { inputId, inputName, isAutoCrop, zoomLevel } = camera;

        const externalInputSource = new AVKExternalInput(
          inputId,
          inputName,
          isAutoCrop,
          zoomLevel
        );

        // value comes as 0 or 1
        externalInputSource.isAutoCrop = !!camera.isAutoCrop;

        return externalInputSource;
      }
    );

    arrayService.ascSort(newData, "inputName");

    return newData;
  }

  getConsoleAudioMutedClass(): string {
    let consoleAudioMutedClass = "console-audio-muted-banner";
    const { menuHidden } = this.state;
    if (menuHidden) {
      consoleAudioMutedClass += "-double-collapsed";
    } else {
      if (this.state.consoleAudioMuteCollapse) {
        consoleAudioMutedClass += "-collapsed";
      }
    }
    return consoleAudioMutedClass;
  }

  getSidebarBannerClass = (): string => {
    let sidebarClass = "important sidebar-banner";
    const { menuHidden } = this.state;
    if (this.props.mediaState.self.sidebar === "active") {
      if (menuHidden) {
        sidebarClass += "-double-collapsed";
      } else {
        if (this.state.sidebarCollapse) {
          sidebarClass += "-collapsed";
        }
      }
    }
    return sidebarClass;
  };

  render() {
    const {
      consoleAudioMuteCollapse,
      menuHidden,
      selectedMarker,
      selectedCallControl,
      showBluetoothAudioBanner,
      showSidebarBanner,
      isUserHost,
    } = this.state;

    const {
      consoleHasJoinedRoom,
      mediaState,
      layoutFrames,
      enableInCallControls,
      refreshInProgress,
      localNetworkQualityLevel,
      imageFrozen,
      mode,
      noiseCancellationAvailable,
      remoteHasJoined,
      localTracks
    } = this.props;

    const consoleVideoMuted = mediaState.console.video === "mute";

    const shouldEnableInCallControls =
      enableInCallControls && !refreshInProgress && !consoleVideoMuted;

    // media mute if there is are localTracks to mute
    const shouldEnableLocalMediaMute = !refreshInProgress && localTracks.length;
    let shouldEnableRefreshButton;
    if (mode === MULTI_PARTY_LAUNCH_MODE) {
      shouldEnableRefreshButton = consoleHasJoinedRoom && !refreshInProgress;
    } else {
      // P2P
      shouldEnableRefreshButton = remoteHasJoined && !refreshInProgress;
    }
    // only the Host in a MP or P2P call can mute their video
    const shouldEnableVideoMute = shouldEnableLocalMediaMute && !(mode === MULTI_PARTY_LAUNCH_MODE && !isUserHost)

    const shouldExpandCallControl =
      selectedCallControl !== "" && selectedCallControl !== "refresh-frames";

    const videoFrameIcon = (whichFrame: number) => {
      const frames = this.videoViewFrames();
      const otherFrameIsFS = () => {
        var culled = layoutFrames.filter((xt, i) => i !== whichFrame);
        var results = false;
        culled.forEach((frame, j) => {
          results = results || frame.isFullScreen;
        });
        return results;
      };

      if (
        !frames ||
        !layoutFrames ||
        whichFrame >= layoutFrames.length ||
        whichFrame >= frames.length
      ) {
        // if no frames def'd or invalid frame #
        return "no-frame-button";
      }

      // otherwise
      if (layoutFrames[whichFrame].isFullScreen) {
        // if I am full screen
        return "full-frame-button";
      } else if (otherFrameIsFS()) {
        // if someone else is full screen
        return "no-frame-button";
      } else {
        // normal video framing
        return frames[whichFrame] + "-frame-button";
      }
    };

    let consoleAudioMuted = this.updateConsoleAudioMute();

    const handleParticipantControls = (baseClass: string) => {
      return (enableInCallControls || consoleHasJoinedRoom) &&
        !refreshInProgress
        ? classNames(baseClass)
        : classNames(baseClass, "button-disabled");
    };

    const shouldEnableNoiseCancellation =
      (mode === P2P_LAUNCH_MODE ? remoteHasJoined : consoleHasJoinedRoom) &&
      noiseCancellationAvailable;

    // TODO the call-controls-placeholder has to be replaced by a separate component
    return (
      <div className="avail-call-controls-group">
        {false && <BadNetworkBanner></BadNetworkBanner>}
        {shouldEnableNoiseCancellation && <NoiseCancellation />}
        <CallControlPanel
          clearTelestration={this.clearTelestration}
          consoleAudioMuteCollapse={consoleAudioMuteCollapse}
          consoleAudioMuted={consoleAudioMuted}
          consoleHasJoinedRoom={consoleHasJoinedRoom}
          getAudioButtonClass={this.getAudioButtonClass}
          getPipButtonClass={this.getPipButtonClass}
          getSidebarBannerClass={this.getSidebarBannerClass}
          getSidebarButtonClass={this.getSidebarButtonClass}
          getVideoButtonClass={this.getVideoButtonClass}
          handleParticipantControls={handleParticipantControls}
          handleRefreshFrames={this.handleRefreshFrames}
          imageFrozen={imageFrozen}
          isUserHost={isUserHost}
          localNetworkQualityLevel={localNetworkQualityLevel}
          menuHidden={menuHidden}
          openNetworkDiagnostics={this.openNetworkDiagnostics}
          refreshInProgress={refreshInProgress}
          remoteHasJoined={remoteHasJoined}
          selectCallControl={this.selectCallControl}
          selectedCallControl={selectedCallControl}
          selectedMarker={selectedMarker}
          selectMarkerForTelestration={this.selectMarkerForTelestration}
          setCollapseConsoleAudioMuted={this.setCollapseConsoleAudioMuted}
          shouldEnableInCallControls={shouldEnableInCallControls}
          shouldEnableRefreshButton={shouldEnableRefreshButton}
          shouldEnableLocalMediaMute={shouldEnableLocalMediaMute}
          shouldEnableVideoMute={shouldEnableVideoMute}
          shouldExpandCallControl={shouldExpandCallControl}
          showBluetoothAudioBanner={showBluetoothAudioBanner}
          showEndCallConfirmation={this.showEndCallConfirmation}
          showSidebarBanner={showSidebarBanner}
          toggleAudioMute={this.toggleAudioMute}
          toggleMenu={this.toggleMenu}
          togglePip={this.togglePip}
          toggleShowSidebarBanner={this.showSidebarBanner}
          toggleSidebarStatus={this.toggleSidebarStatus}
          toggleVideoMute={this.toggleVideoMute}
          videoFrameIcon={videoFrameIcon}
        />
      </div>
    );
  }

  getAudioButtonClass = (): string => {
    const { mediaState, refreshInProgress, localTracks } = this.props;
    const audioMuted = mediaState.self.audio === "mute";

    let audioButtonClass = audioMuted
      ? "audio-mute-button-muted"
      : "audio-mute-button-unmuted";
    if (refreshInProgress || !localTracks.length) {
      audioButtonClass = audioButtonClass + "-disabled";
    }
    return audioButtonClass;
  };

  getVideoButtonClass = (): string => {
    const { mediaState, refreshInProgress, mode, localTracks } = this.props;
    const { isUserHost } = this.state;
    const videoMuted = mediaState.self.video === "mute";

    let videoButtonClass = videoMuted
      ? "video-mute-button-muted"
      : "video-mute-button-unmuted";

    if (
      refreshInProgress ||
      (mode === MULTI_PARTY_LAUNCH_MODE && !isUserHost) || !localTracks.length
    ) {
      videoButtonClass = videoButtonClass + "-disabled";
    }
    return videoButtonClass;
  };

  getPipButtonClass = (): string => {
    const { mediaState, refreshInProgress } = this.props;
    const pipEnabled = mediaState.pip === "show";

    let pipButtonClass = pipEnabled
      ? "pip-control-button-on"
      : "pip-control-button-off";
    if (refreshInProgress) {
      pipButtonClass = pipButtonClass + "-disabled";
    }
    return pipButtonClass;
  };

  getSidebarButtonClass = (): string => {
    const { mediaState, refreshInProgress } = this.props;
    const sidebarStatus = mediaState.self.sidebar;
    let sidebarButtonClass = `enable-sidebar-button-${sidebarStatus}`;

    if (refreshInProgress) {
      sidebarButtonClass = sidebarButtonClass + "-disabled";
    }

    return sidebarButtonClass;
  };
}

const mapStateToProps = (state: AppState) => ({
  access_token: state.user.identity.access_token,
  availKit: state.availKit.availKitInstance,
  callEventInfo: state.meeting.callEventInfo,
  callSid: state.meeting.callSid,
  callState: state.meeting.callState,
  cameras: state.meeting.cameras,
  consoleHasJoinedRoom: state.twilio.consoleHasJoinedRoom,
  consoleHasExternalInputResizing:
    state.meeting.consoleHasExternalInputResizing,
  currentUserRole: state.meeting.currentUserRole,
  enableInCallControls: state.meeting.enableInCallControls,
  eventParticipants: state.meeting.multiPartyCallEventDetail?.participants,
  freezeFrameHasUpdate: state.meeting.freezeFrameHasUpdate,
  freezeFrameState: state.meeting.freezeFrameState,
  imageFrozen: state.image.frozen,
  imageState: state.image,
  joinId: state.meeting.joinId,
  layoutFrames: state.meeting.layoutFrames,
  layoutHasUpdate: state.meeting.layoutHasUpdate,
  triggerRefreshFrames: state.meeting.triggerRefreshFrames,
  localNetworkQualityLevel: state.twilio.localNetworkQualityLevel,
  localTracks: state.twilio.localTracks,
  loginId: state.user.identity.login_id,
  mediaState: state.user.mediaState,
  mode: state.meeting.mode,
  multiPartyCallEventInfo: state.meeting.multiPartyCallEventInfo,
  noiseCancellationAvailable: state.meeting.noiseCancellationAvailable,
  refresh_token: state.user.identity.refresh_token,
  refreshInProgress: state.user.refreshInProgress,
  remoteTracks: state.twilio.remoteTracks,
  ssFFEnabled: state.meeting.enableSSFF,
  twilioCredentials: state.twilio.twilioCredentials,
  twilioParticipants: state.twilio.participants,
  twilioRoom: state.twilio.room,
  zoomState: state.meeting.zoomState,
  remoteHasJoined: state.twilio.remoteHasJoinedRoom,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setCallState: (callState: CallStateType) =>
      dispatch(setCallState(callState)),
    setMediaState: (mediaState: MediaStateType) =>
      dispatch(setMediaState(mediaState)),
    updateTelestrationState: (
      telestration: TelestrationState,
      stylusColor: TelestrationStylusColor
    ) => dispatch(updateTelestrationState(telestration, stylusColor)),
    setCameras: (cameras: Array<AVKCamera | AVKExternalInput>) =>
      dispatch(setCamerasList(cameras)),
    setCurrentUserRole: (userRole: UserRoleType) =>
      dispatch(setCurrentUserRole(userRole)),
    updateDimensions: (needsUpdate: boolean) =>
      dispatch(updateDimensions(needsUpdate)),
    setLayoutFrames: (layoutFrames: LayoutFrame[]) =>
      dispatch(setLayoutFrames(layoutFrames)),
    setLayoutHasUpdate: (isUpdated: boolean) =>
      dispatch(setLayoutHasUpdate(isUpdated)),
    setTriggerRefreshFrames: (shouldTriggerRefreshFrames: boolean) =>
      dispatch(setTriggerRefreshFrames(shouldTriggerRefreshFrames)),
    setBluetoothEnabled: (enabled: boolean) =>
      dispatch(setBluetoothEnabledAction(enabled)),
    setNoiseCancellation: (enabled: boolean) =>
      dispatch(setNoiseCancellationAction(enabled)),
    setNoiseCancellationAvailable: (enabled: boolean) =>
      dispatch(setNoiseCancellationAvailableAction(enabled)),
    setConsoleHasExternalInputResizing: (enabled: boolean) =>
      dispatch(setConsoleHasExternalInputResizingAction(enabled)),
    setRefreshInProgress: (refreshInProgress: boolean) =>
      dispatch(setRefreshInProgress(refreshInProgress)),
    setZoomState: (zoomState: ZoomStateType) =>
      dispatch(setZoomState(zoomState)),
    setEnableInCallControls: (enableInCallControls: boolean) =>
      dispatch(setEnableInCallControls(enableInCallControls)),
    connectCall: () => dispatch(connectCall()),
    getTwilioCredentialsSuccess: (data: TwilioCredentials) =>
      dispatch(getTwilioCredentialsSuccess(data)),
    setImageFreeze: (frozen: boolean) => dispatch(setImageFreeze(frozen)),
    updateAccessToken: (newIdentity: PortalIdentity) =>
      dispatch(updateAccessToken(newIdentity)),
    setFreezeFrameHasUpdate: (updated: boolean) =>
      dispatch(setFreezeFrameHasUpdate(updated)),
    setFreezeFrameState: (freezeFrameState: FreezeFrameStateType) =>
      dispatch(setFreezeFrameState(freezeFrameState)),
    setFrozenFrame: (transferId: string) =>
      dispatch(setFrozenFrame(transferId)),
    unsetFrozenFrame: () => dispatch(unsetFrozenFrame()),
    setLeftFrozenFrame: (transferId: string) =>
      dispatch(setLeftFrozenFrame(transferId)),
    setRightFrozenFrame: (transferId: string) =>
      dispatch(setRightFrozenFrame(transferId)),
    setFullscreenFrozenFrame: (transferId: string) =>
      dispatch(setFullscreenFrozenFrame(transferId)),
    unsetLeftFrozenFrame: () => dispatch(unsetLeftFrozenFrame()),
    unsetRightFrozenFrame: () => dispatch(unsetRightFrozenFrame()),
    unsetFullscreenFrozenFrame: () => dispatch(unsetFullscreenFrozenFrame()),
    clearFreezeFrameImage: () => dispatch(setFreezeFrameImage("", "")),
    clearTwilioTracks: () => dispatch(clearTwilioTracks()),
    refreshFrames: () =>dispatch(refreshFrames())
  };
};

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(withFeatureFlag(InCallControls));

function mapSizings(me: HTMLElement, bmImage: ImageBitmap): any {
  const imageW = bmImage.width;
  const imageH = bmImage.height;
  const screen = me.getBoundingClientRect();

  const scaleW = screen.width / imageW;
  const scaleH = screen.height / imageH;
  let fittedW = null,
    fittedH = null,
    offsetL = "",
    offsetT = "";

  if (scaleH <= scaleW) {
    // Scaling up is limited by the height of image
    fittedW = "auto"; // (imageW * scaleH)*100/imageW + "%";
    fittedH = "100%";
    offsetL =
      ((screen.width - scaleH * imageW) * 100) / (2 * screen.width) + "%";
    offsetT = "0";
  } else {
    // Scaling up is limited by the width of image
    fittedW = "100%";
    fittedH = "auto"; // (imageH * scaleW)*100/imageH + "%";
    offsetL = "0";
    offsetT =
      ((screen.height - scaleW * imageH) * 100) / (2 * screen.height) + "%";
  }
  return { fittedW, fittedH, offsetL, offsetT };
}
