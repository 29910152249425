import { PresetSnapshotState } from "../models";
import { PresetActionKeys, PresetActionType } from "./actionTypes";

export const savePreset = (
  newpreset: string,
  presetSnapshot: PresetSnapshotState
): PresetActionType => ({
  type: PresetActionKeys.SAVE_PRESET,
  newpreset,
  presetSnapshot
});
