import { MutableRefObject } from "react";

import { TELESTRATION_EASEL_ID } from "src/domains/Beacon/components/Easel/constants";
import { useMouseEventHandler } from "src/domains/Beacon/components/Easel/useMouseEventHandlers";
import { useSendTelestrationEvents } from "src/domains/Beacon/components/Easel/useSendTelestrationEvent";
import { useTelestrationSubscriptions } from "src/domains/Beacon/components/Easel/useTelestrationSubscriptions";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectTelestrationState } from "src/domains/Beacon/store/telestration/selectors";

import styles from "./styles.scss";

interface EaselProps {
  canvasRef: MutableRefObject<HTMLCanvasElement>;
}

export const Easel = ({ canvasRef }: EaselProps) => {
  const {
    videoDimensions: { width, height },
  } = useAppSelector(selectTelestrationState);

  // allows Participants to receive annotationEvents and see live drawings
  useTelestrationSubscriptions();

  // converts Host mouse interactions to drawings
  useMouseEventHandler();

  // send events to PubNub
  useSendTelestrationEvents();

  return (
    <canvas
      className={styles.root}
      data-test-id="telestration-easel"
      width={width}
      height={height}
      ref={canvasRef}
      id={TELESTRATION_EASEL_ID}
    />
  );
};
