import React from "react";
import ReactDOM from "react-dom";

import clsx from "clsx";

import CloseIcon from "@mui/icons-material/Close";
import { Fade, Typography } from "@mui/material";

import { IconButton } from "src/components/legacy/IconButton";
import { useOnEscapeKeyDown } from "src/hooks/useOnEscapeKeyDown";

import styles from "./styles.scss";

interface IModalProps {
  open: boolean;
  id?: string;
  className?: string;
  // when the modal is closing sometimes we want to clear some state, so use onExited prop
  onExited?: () => void;
  children: React.ReactNode;
  "data-test-id"?: string;
  size?: "small" | "medium" | "large";
}

interface IHeaderProps {
  closeFunction: () => void;
  title?: string;
  className?: string;
}

interface IUndismissableHeaderProps {
  title?: string;
  className?: string;
}

interface IBodyProps {
  children: React.ReactNode;
  className?: string;
}

interface IFooterProps {
  children: React.ReactNode;
  className?: string;
}

/** Use this header for modals that should not be "closable" by the user
 */
export const UndismissableModalHeader = (props: IUndismissableHeaderProps) => {
  const title = props.title || "";

  return (
    <div className={clsx(styles.header, props.className)}>
      <div>
        <Typography variant="h1">{title}</Typography>
      </div>
    </div>
  );
};

export const ModalHeader = (props: IHeaderProps) => {
  const title = props.title || "";

  // call the close function passed in (which is the same function we use when the user
  // clicks on the close button) when the "esc" aka "escape" key is hit
  useOnEscapeKeyDown(props.closeFunction);

  return (
    <div className={clsx(styles.header, props.className)}>
      <Typography variant="h6">{title}</Typography>

      <IconButton color="default" onClick={props.closeFunction} size="small">
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export const ModalBody = (props: IBodyProps) => {
  return (
    <div className={clsx(styles.body, props.className)}>{props.children}</div>
  );
};

export const ModalFooter = (props: IFooterProps) => {
  return (
    <div className={clsx(styles.footer, props.className)}>{props.children}</div>
  );
};

const PrivateModal = ({ size = "medium", ...props }: IModalProps) => {
  return (
    <Fade
      // if open is false then we wont see the modal, when open changes the modal will fade in/out
      in={props.open}
      // when the modal is closing sometimes we want to clear some state, so this line lets
      // us run some function when the modal is closing
      addEndListener={props.open === false ? props.onExited : null}
    >
      <div className={clsx(styles.modalVeil)} id={props.id}>
        <div className={clsx(styles.modalWrapper)}>
          <div
            data-test-id={props["data-test-id"] || "modal"}
            className={clsx(styles.modal, styles[size], props.className)}
          >
            {props.children}
          </div>
        </div>
      </div>
    </Fade>
  );
};
export const Modal = (props: IModalProps) => {
  const topReact = document.getElementById("root");
  // creating portal to higher up the DOM tree so it correctly appears
  // on top of everything
  return topReact ? (
    ReactDOM.createPortal(<PrivateModal {...props} />, topReact)
  ) : (
    <PrivateModal {...props} />
  ); // this happens in integration tests
};
