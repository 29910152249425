import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from '../../../../Common/Components/Modal/Modal';
import { useTimer } from './hooks/useTimer';
import { USER_IDLE_THRESHOLD, USER_IDLE_ALERT_THRESHOLD } from '../../../../Common/Constants/settings';

function IdleAlert({ countDownAction, onConfirm }) {
	const { minutes, seconds } = useTimer(USER_IDLE_ALERT_THRESHOLD, 0);

	useEffect(() => {
		// Do something after timer hits 0
		if (minutes === 0 && seconds === 0) {
			countDownAction();
		}
	}, [minutes, seconds]);

	const padded = n => (n > 9 ? n : `0${n}`);

	return (
		<ModalComponent
			open
			onConfirm={onConfirm}
			confirmText="Continue"
		>
			<span>
				You&apos;ve been inactive for {USER_IDLE_THRESHOLD} minutes. Do you want to continue using the Avail Portal?
				<b>(Your session will be closed in {`${padded(minutes)}:${padded(seconds)}`})</b>
			</span>
		</ModalComponent>
	);
}

IdleAlert.defaultProps = {
	countDownAction: () => {},
};

IdleAlert.propTypes = {
	countDownAction: PropTypes.func,
};

export default IdleAlert;
