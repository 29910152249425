import { useScssVariable } from "src/hooks/useScssVariable";

import styles from "./styles.scss";

interface IProps {
  value: number;
}

export const LevelIndicator = ({
  value,
  ...props
}: IProps & React.HTMLAttributes<HTMLDivElement>) => {
  const { ref } = useScssVariable<HTMLDivElement>("--width", `${value}%`);
  useScssVariable<HTMLDivElement>(
    "--transition",
    value === 0 ? `width 300ms` : "",
    ref
  );

  return (
    <div {...props} className={styles.backgroundBar}>
      <div ref={ref} className={styles.progressBar}>
        {value}
      </div>
    </div>
  );
};
