import { useFlags } from "launchdarkly-react-client-sdk";

import { FEATURE_FLAGS } from "src/featureFlags";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

interface Flags {
  externalInputsImageResizing?: boolean;
  // internal LA is one set of features for Tungsten
  tungstenInternalLa?: boolean;
  // external LA is another set, all of this is defined in jira/confluence
  // throughout the code we will wrap the specific features with this flag
  // note that tungstenExternalLa has a prereq of the Internal LA flag to already be true
  tungstenExternalLa?: boolean;
  // callLink isn't a real feature flag, its just a convenient way of getting the URL for either old portalcall or new tungsten UI
  callLink?: string;
  traceIdPortal?: boolean;
  tungsten?: boolean;
  oRtelligence?: boolean;
  enableMultiPaneLayout?: boolean;
  // QE specific flag created to disable features that prevent automated accounts from working smoothly
  QaAutomationFeatureFlag?: boolean;
  offerDeviceSelection: boolean;
}

// Shape of all LD flags available for LD client, this way we have one source of truth
// to find the exact name of a LD flag
export interface LDFlagSet {
  externalInputsImageResizing: boolean;
  fourViewsLayout: boolean;
  oRtelligence: boolean;
  tungsten: boolean;
  tungstenExternalLa: boolean;
  traceIdPortal: boolean;
  QaAutomationFeatureFlag: boolean;
  offerDeviceSelection: boolean;
}

// Wrapper hook around Launch Darkly's useFlags() to have a type-safe approach
// to our feature flags. This can also be useful in case we want to end
// up using any other alternative
export const useFeatureFlags = (): Flags => {
  const {
    externalInputsImageResizing,
    fourViewsLayout: enableMultiPaneLayout,
    oRtelligence,
    tungsten: tungstenInternalLa,
    tungstenExternalLa,
    traceIdPortal,
    QaAutomationFeatureFlag,
    offerDeviceSelection,
  } = useFlags() as LDFlagSet;

  const callLink = tungstenInternalLa
    ? DomainConstants.urls.beacon
    : DomainConstants.urls.portalcall;

  return {
    externalInputsImageResizing,
    enableMultiPaneLayout,
    oRtelligence,
    tungstenInternalLa,
    tungstenExternalLa,
    traceIdPortal,
    callLink,
    QaAutomationFeatureFlag,
    offerDeviceSelection,
  };
};

export const withFeatureFlag = (Component) => (props) => {
  const {
    externalInputsImageResizing,
    oRtelligence,
    traceIdPortal,
    tungsten: tungstenInternalLa,
  } = useFlags() as LDFlagSet;

  const beaconFlag = tungstenInternalLa
    ? DomainConstants.urls.beacon
    : DomainConstants.urls.portalcall;

  FEATURE_FLAGS.enableORTelligence = oRtelligence;
  FEATURE_FLAGS.enableTraceId = traceIdPortal;
  FEATURE_FLAGS.enableExternalInputsImageResizing = externalInputsImageResizing;
  return (
    <Component
      {...props}
      beaconFlag={beaconFlag}
      externalInputsImageResizing={externalInputsImageResizing}
    />
  );
};
