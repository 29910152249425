// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_logo_E2SZQD{align-self:center;height:64px;mix-blend-mode:color-dodge}.styles_logo_E2SZQD.styles_small_X853AF{height:28px}", "",{"version":3,"sources":["webpack://./src/components/Logo/styles.scss"],"names":[],"mappings":"AAAA,oBAEE,iBAAA,CACA,WAAA,CACA,0BAAA,CAEA,wCACE,WAAA","sourcesContent":[".logo {\n  // this prevents the images from being stretched if its in a flex container\n  align-self: center;\n  height: 64px;\n  mix-blend-mode: color-dodge; // prevents white space from becoming see-through\n\n  &.small {\n    height: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "styles_logo_E2SZQD",
	"small": "styles_small_X853AF"
};
export default ___CSS_LOADER_EXPORT___;
