import {
  AVKClearFreezeFrameEvent,
  AVKUnFreezeDataType,
} from "availkit-js/dist/Models/Events/AVKClearFreezeFrameEvent";
import { Guid } from "guid-typescript";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { clearLocalAnnotationsThunk } from "src/domains/Beacon/store/telestration/thunks/clearLocalAnnotationsThunk";
import { uiActions } from "src/domains/Beacon/store/ui";
import {
  clearFreezeFrameCanvas,
  clearFreezeFrameImage,
} from "src/domains/Beacon/utils/canvas";
import { logger, LoggerLevels } from "src/logging/logger";
import { deleteFreezeFrameById } from "src/services/ApiClient/presence";
import { AvailKitService } from "src/services/AvailKitService";
import UserSessionService from "src/services/UserSessionService";

// Handles the unfreeze frame for the created canvas, for now, it's only
// working for fullscreen freeze frame due that PortalCall didn't implement it
export const handleUnfreezeFrameThunk = createAsyncThunk<
  void,
  // First argument to the payload creator
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("stream/handleUnfreezeFrameThunk", async (_arg, { getState, dispatch }) => {
  try {
    logger().info("Unfreezing Frames...");
    const availKit = AvailKitService.instance;
    const user = UserSessionService.getCachedUserInfo();
    const { stream, meeting } = getState();
    const {
      freezeFrame: { transferId },
    } = stream;
    const { callSid, mode, isUserHost } = meeting;

    // Removing canvas created for FreezeFrame
    // NOTE: at the moment canvas is used by HOST and img tag is used by participants
    clearFreezeFrameCanvas();

    // Must clean the Freeze Frame img if Host fetched FF from Presence Server
    clearFreezeFrameImage();

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "FreezeFrame",
      },
      `User initiated Unfreezeframe. TransferIdentifier: ${transferId}`
    );

    // The indicator should disappear
    dispatch(
      uiActions.setIndicator({
        color: null,
        label: "",
        show: false,
      })
    );

    // Telestration state and UI cleared
    dispatch(clearLocalAnnotationsThunk());

    // In MP or P2P, only the Host can call the AVK clearFreezeFrame event
    if (mode === CallModes.P2P || (mode === CallModes.MP && isUserHost)) {
      // Cleaning Freeze Frame through AVK for either P2P or MP call
      const clearFreezeFrameEvent = new AVKClearFreezeFrameEvent(
        callSid,
        AVKUnFreezeDataType.UnFreezeFrameFullScreen
      );
      clearFreezeFrameEvent.transferIdentifier = Guid.parse(transferId)
        .toString()
        .toLowerCase() as any;
      clearFreezeFrameEvent.sender = user.loginId;
      availKit.eventService.broadcast(clearFreezeFrameEvent);

      // If MP call, Presence Server has the Freeze Frame image so it should be
      // deleted as well, only the host can call the API.
      // This is only for MP calls not P2P calls
      if (mode === CallModes.MP) {
        await deleteFreezeFrameById(callSid, transferId);
      }
    }
  } catch (error: any) {
    logger().error(
      "UnfreezeFrame cancelled. Screen image couldn't be deleted.",
      error?.message
    );
    throw error;
  }
});
