import { SCHEDULER } from './SchedulerActionTypes';

export const createCalendarEventsAction = ({ event, selectedDate, isRecurring, userIdFromUrl, timeZone }) => ({
	type: SCHEDULER.CREATE_CALENDAR_EVENTS,
	event,
	selectedDate,
	isRecurring,
	userIdFromUrl,
	timeZone,
});

export const createCallEventsAction = ({ event, selectedDate, userIdFromUrl, timeZone }) => ({
	type: SCHEDULER.CREATE_CALL_EVENTS,
	event,
	selectedDate,
	userIdFromUrl,
	timeZone,
});

export const updateSelectedEventAction = ({ event, eventId, selectedDate, isRecurring, userIdFromUrl, recurringEventGroupId, timeZone }) => ({
	type: SCHEDULER.UPDATE_SELECTED_EVENT,
	event,
	eventId,
	selectedDate,
	isRecurring,
	userIdFromUrl,
	recurringEventGroupId,
	timeZone
});

export const updateSelectedCallEventAction = ({ event, selectedDate, userIdFromUrl, timeZone }) => ({
	type: SCHEDULER.UPDATE_SELECTED_CALL_EVENT,
	payload: { event, selectedDate, userIdFromUrl, timeZone },
});

export const getUserScheduleAction = userId => ({
	type: SCHEDULER.GET_USER_SCHEDULE,
	payload: userId,
});

export const getUserMeetingTimeAction = (userId, from, to) => ({
	type: SCHEDULER.GET_USER_MEETING_TIME,
	payload: {
		userId, from, to,
	},
});

export const getUserMissedCallsAction = (userId, from, to) => ({
	type: SCHEDULER.GET_USER_MISSED_CALLS,
	payload: {
		userId, from, to,
	},
});

export const getUserCallEventsAction = (userId, fromDate, toDate, timeZone) => ({
	type: SCHEDULER.GET_USER_CALL_SCHEDULE,
	payload: { userId, fromDate, toDate, timeZone },
});

export const deleteSelectedEventAction = (eventId, recurringGroupId) => ({
	type: SCHEDULER.DELETE_SELECTED_EVENT,
	eventId,
	recurringGroupId,
});

export const deleteSelectedCallEventAction = eventId => ({
	type: SCHEDULER.DELETE_SELECTED_CALL_EVENT,
	payload: { eventId },
});
