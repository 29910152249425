import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAlert: false,
};

// TODO: should this have it's own slice? Maybe this can be added in a more general slice like `HomeSlice` or `AppSlice`?
const IdleSlice = createSlice({
  name: "idle",
  initialState,
  reducers: {
    setShowAlert: (state, action) => {
      state.showAlert = action.payload;
    },
    startIdleTimer: (state) => {
      return state;
    },
  },
});

export const {
  setShowAlert: setShowAlertAction,
  startIdleTimer: startIdleTimerAction,
} = IdleSlice.actions;

export const IdleReducers = IdleSlice.reducer;
