import { NAnnotationInstruction } from "availkit-js/dist/Models/NAnnotation";
import { NAnnotationPoint } from "availkit-js/dist/Models/NAnnotationPoint";

import { TELESTRATION_EASEL_ID } from "src/domains/Beacon/components/Easel/constants";
import { INITIAL_PEN_WIDTH } from "src/domains/Beacon/constants";
import {
  PenStyle,
  VideoDimensions,
} from "src/domains/Beacon/store/telestration/types";
import { logger } from "src/logging/logger";
import { TwoDimensionPosition } from "src/services/ApiClient/presence/types";

export const rgbToHex = (
  redValue: number,
  greenValue: number,
  blueValue: number
) => {
  const r = Math.floor(redValue * 255).toString(16);
  const g = Math.floor(greenValue * 255).toString(16);
  const b = Math.floor(blueValue * 255).toString(16);

  const convert = (value: string) => {
    return value.length === 1 ? `0${value}` : value;
  };

  return `#${convert(r)}${convert(g)}${convert(b)}`;
};

// requires a 6 character hex color.
// i.e. white !== "#fff" => white === "#ffffff"
export const hexToRgbA = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgb("${r},${g},${b}")`;
};

// returns coordinates relative to LOCAL video element's dimensions
export const denormalizeInstruction = (
  instruction: NAnnotationInstruction,
  videoDimensions: VideoDimensions
): NAnnotationInstruction => {
  if (!instruction.x || !instruction.y) {
    throw new Error("Could not denormalize coordinates for instruction");
  }

  return new NAnnotationPoint(
    // instruction.x and instruction.y are fractions of the total width
    videoDimensions.width * instruction.x,
    videoDimensions.height * instruction.y,
    instruction.widthFromPoint || INITIAL_PEN_WIDTH // if no penWidth given, default to INITIAL_PEN_WIDTH
  );
};

// returns coordinates relative to ANY video element's dimensions
export const normalizeInstruction = (
  anInstruction: NAnnotationInstruction,
  videoDimensions: VideoDimensions,
  penWidth
) => {
  if (!anInstruction.x || !anInstruction.y) {
    throw new Error("Could not normalize coordinates for instruction");
  }

  const aPoint: NAnnotationPoint = new NAnnotationPoint(
    anInstruction.x / videoDimensions.width,
    anInstruction.y / videoDimensions.height,
    penWidth
  );
  return aPoint;
};

export const draw = (
  pointFrom: TwoDimensionPosition,
  pointTo: TwoDimensionPosition,
  style: PenStyle
) => {
  const canvas = document.getElementById(
    TELESTRATION_EASEL_ID
  ) as HTMLCanvasElement;
  const context = canvas.getContext("2d");
  context.beginPath();
  if (context) {
    // styling to the lines
    context.lineWidth = style.width;
    context.strokeStyle = style.color; // color
    context.lineCap = "round"; // end of lines is rounded not squared off
    context.lineJoin = "round"; // rounds corners where lines meet

    context.moveTo(pointFrom.x, pointFrom.y);
    context.lineTo(pointTo.x, pointTo.y);
    context.stroke();
    context.closePath();
  }
};

// used in MP call when drawing telestrationHistory pulled from Presence
export const drawAnnotation = (
  style: string, // expecting HEX color
  instructions: NAnnotationInstruction[],
  videoDimensionsObject: VideoDimensions
) => {
  const canvas = document.getElementById(
    TELESTRATION_EASEL_ID
  ) as HTMLCanvasElement;
  const context = canvas.getContext("2d");
  [...instructions].forEach((instruction, i) => {
    const nextPoint = [...instructions][i + 1];
    if (nextPoint && context) {
      try {
        const pointFrom = denormalizeInstruction(
          instruction,
          videoDimensionsObject
        );
        const pointTo = denormalizeInstruction(
          nextPoint,
          videoDimensionsObject
        );
        const styles = {
          width: instruction.widthFromPoint,
          color: style,
        } as PenStyle;
        draw(
          pointFrom as TwoDimensionPosition,
          pointTo as TwoDimensionPosition,
          styles
        );
      } catch (error: any) {
        logger().warn("Skipping annotation instructions.", error?.message);
      }
    }
  });
};
