import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { NavLink, withRouter } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
/* Components */
import BackButton from '../../../../../../../Common/Components/BackButton/BackButton';
import { EntityProfileContent, EntityProfileSidebar, ProfileBreadcrumbs, TabsWithMenu } from '../../../../../Common';
import RoomInfo from './Components/RoomInfo/RoomInfo';
import RoomProcedures from './Components/RoomProcedures/RoomProcedures';
/* Actions & Services */
import { getHospitalByIdAction } from '../../HospitalsActions';
import {
	getAllDepartmentsRoomIsAssociatedAction,
	getDepartmentAction,
	getRoomAction,
} from '../DepartmentProfile/DepartmentsActions';
import { urlService } from '../../../../../../../Common/Services';

class RoomProfile extends PureComponent {
	constructor(props) {
		super(props);
		this.hospitalId = urlService.getQueryParam(props.location, 'hospitalId');
		this.departmentId = urlService.getQueryParam(props.location, 'departmentId');
		this.roomId = urlService.getQueryParam(props.location, 'roomId');
	}

	componentDidMount() {
		this.props.getRoom(this.roomId);
		if (!this.props.hospital) {
			this.props.getHospitalById(this.hospitalId);
		}
		if (!this.props.department && this.departmentId) {
			this.props.getDepartment(this.departmentId);
		}
		this.props.getAllDepartmentsRoomIsAssociated(this.roomId);
	}

	getDepartmentsTitle = () => this.props.roomDepartments[0].name;

	getDepartmentsLength = () => ` + ${this.props.roomDepartments.length - 1} more`;

	getDepartmentRoute = () => {
		const { roomDepartments } = this.props;
		const departmentRoute = `/hospitals/departments/profile?hospitalId=${this.hospitalId}&departmentId=`;
		return roomDepartments.length ? `${departmentRoute}${roomDepartments[0].id}` : this.departmentId;
	};

	renderPopup = () => (
		<div className="flex-direction--row">
			{this.getDepartmentsTitle()}
			<div>{this.getDepartmentsLength()}</div>
		</div>
	);

	renderOptions = () => {
		const { roomDepartments } = this.props;
		return [
			{
				title: this.props.hospitalName,
				to: `/hospitals/profile?hospitalId=${this.hospitalId}`,
			},
			...roomDepartments && roomDepartments.length ? [{
				title: roomDepartments.length > 1 ? this.renderPopup() : roomDepartments[0].name,
				label: 'Department name',
				to: this.getDepartmentRoute(),
			}] : [],
			{
				title: this.props.room && this.props.room.name,
				label: 'Room Name',
				className: 'profile-breadcrumbs__menu-item--active',
				to: `/hospitals/rooms/profile?hospitalId=${this.hospitalId}&departmentId=${this.departmentId}&roomId=${this.roomId}`,
			},
		];
	};

	renderPairingCode = () => (
		<div className="room-profile__pairing-code">
			{this.props.room && this.props.room.pairingCode}
		</div>
	);

	renderPanes = () => (
		[{
			menuItem: 'General Info',
			render: () => <RoomInfo roomId={this.roomId} hospitalId={this.hospitalId} />,
		}, {
			menuItem: 'Therapies & Procedures',
			render: () => <RoomProcedures roomId={this.roomId} />,
		}, {
			menuItem: 'Pairing Code',
			render: () =>
				this.renderPairingCode(),
		}]
	);

	render() {
		return (
			<div className="display--flex height--full">
				<EntityProfileSidebar title="Facility profile">
					{this.props.room &&
					<ProfileBreadcrumbs options={this.renderOptions()} />
					}
				</EntityProfileSidebar>
				<div className="vendor-profile__right-side">
					<TabsWithMenu options={this.renderPanes()} />
				</div>
			</div>
		);
	}
}

RoomProfile.propTypes = {};

const mapStateToProps = state => ({
	room: state.departmentReducer.room,
	hospitalName: state.hospitalsReducer.hospital && state.hospitalsReducer.hospital.name,
	departmentName: state.departmentReducer.department && state.departmentReducer.department.name,
	roomDepartments: state.departmentReducer.roomDepartments,
});

const mapDispatchToProps = dispatch => ({
	getRoom: roomId => dispatch(getRoomAction(roomId)),
	getHospitalById: hospitalId => dispatch(getHospitalByIdAction(hospitalId)),
	getDepartment: departmentId => dispatch(getDepartmentAction(departmentId)),
	getAllDepartmentsRoomIsAssociated: roomId => dispatch(getAllDepartmentsRoomIsAssociatedAction(roomId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'roomProfileForm',
	enableReinitialize: true,
})(RoomProfile)));
