import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting/meetingSlice";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { logger } from "src/logging/logger";
import {
  getEventDetail,
  MultiPartyEvent,
} from "src/services/ApiClient/scheduler";
import { getUserTherapies } from "src/services/ApiClient/users";
import UserSessionService from "src/services/UserSessionService";

export const getCallDetailsThunk = createAsyncThunk<
  // Return type of the payload creator, we return nothing so void
  void,
  // First argument to the payload creator, no args so void
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("twilio/getCallDetails", async (_, { getState, dispatch }) => {
  try {
    logger().info("** Fetching call details **");
    const {
      meeting: { meetingToken, mode },
    } = getState();
    const user = UserSessionService.getCachedUserInfo();

    // Events only exist in MP calls
    if (mode === CallModes.MP) {
      logger().info("Updating event details");

      const callDetailsResponse = await getEventDetail(meetingToken.eventId);

      const {
        therapyId,
        procedureId,
      } = callDetailsResponse.content as MultiPartyEvent;

      // Must get the therapy and procedure names for 3rd party integrations
      const therapies = await getUserTherapies(user.id);
      const therapy = therapies?.content?.find(
        (therapyObj) => therapyObj.id === therapyId
      );
      const procedure = therapy?.procedures?.find(
        (procedureObj) => procedureObj.id === procedureId
      );

      // if therapy or procedure doesn't return with values, set it to empty string
      // this is only a case for GUEST users
      const callDetails: MultiPartyEvent = {
        ...callDetailsResponse.content,
        therapyName: therapy ? therapy.name : "",
        procedureName: procedure ? procedure.name : "",
      };

      // TODO handle other call detail types (p2p, call back )
      dispatch(meetingActions.setCallDetails(callDetails));

      logger().info("** Successfully updated callDetails for MP call **");
    } else if (mode === CallModes.P2P) {
      logger().warn(
        "Attempted to initialize multi-party call during a non multi-party call. Skipping ..."
      );
    }
  } catch (error: any) {
    logger().error("Error while getting call details", error?.message);
    throw new Error("Error while getting call details");
  }
});
