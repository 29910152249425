import LogService from "./LogService";

class LocalStorageService {
  static add(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      LogService.error({
        error: JSON.stringify(e, Object.getOwnPropertyNames(e)),
        message: `Error when adding key ${key} to localStorage`,
      });
    }
  }

  static get(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      LogService.error({
        error: JSON.stringify(e, Object.getOwnPropertyNames(e)),
        message: `Error when getting key ${key} from localStorage`,
      });
      // TODO: If getting some key related to user fails, it is probably because of some corrpted data caused by a patch or some user action, should we sign user out and go to login?
    }
  }

  static remove(key) {
    localStorage.removeItem(key);
  }
}

export default LocalStorageService;
