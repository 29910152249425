import React from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form';
import { Select } from 'semantic-ui-react';
import FormField from '../FormField';


import '../FormFields.scss';

export default ({
	className,
	...props
}) => {
	const { onChange = () => {} } = props;
	return (<Field
		component={FormField}
		as={Select}
		search
		clearable
		onChange={() => onChange()}
		{...props}
		className={classNames({
			'form-field__select': true,
			[className]: className,
		})}
	/>);
};
