import { DEFAULT_TWOVIEW_LABEL } from "src/domains/Beacon/constants";
import {
  LayoutFrames,
  LayoutFrameNames,
  LayoutTypes,
} from "src/domains/Beacon/store/stream/types";

// returns boolean if there is a fullscreen layout frame
export const isFullscreenLayout = (layout: LayoutFrames): boolean => {
  const frames = Object.keys(layout);
  for (const frame of frames) {
    if (layout[frame].isFullScreen) {
      return true;
    }
  }
  return false;
};

// returns the first fullscreen frame in LayoutFrames
export const findFullScreenFrame = (layoutFrames: LayoutFrames) => {
  const frame = Object.keys(layoutFrames).find(
    (key) => layoutFrames[key].isFullScreen === true
  );
  return layoutFrames[frame];
};

// returns the first fullscreen frame name (key) in LayoutFrames
export const findFullScreenFrameName = (
  layoutFrames: LayoutFrames
  // TODO: fix typing of return
): LayoutFrameNames | string => {
  return Object.keys(layoutFrames).find(
    (key) => layoutFrames[key].isFullScreen === true
  );
};

// returns the frames without Fullscreen
export const filterFullscreenFrames = (frames: any, target: string) => {
  const filteredFullscreenFrames = { ...frames };
  delete filteredFullscreenFrames[target];
  const filteredFrames = Object.values(filteredFullscreenFrames);
  return filteredFrames.includes(true);
};

export function cameraLabelDisplay(side, layoutType): string {
  if (layoutType === LayoutTypes.TWO_VIEW) {
    return DEFAULT_TWOVIEW_LABEL[side];
  } else {
    return side;
  }
}
