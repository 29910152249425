import { useMemo } from "react";
import { useDispatch } from "react-redux";

import { ChevronRight } from "@mui/icons-material";

import { List } from "src/components/List";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectLocalParticipant } from "src/domains/Beacon/store/twilio/selectors";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { uiActions } from "src/domains/Beacon/store/ui";
import { UISidePanelContentOptions } from "src/domains/Beacon/store/ui/types";
import {
  disableAudioTrack,
  disableVideoTrack,
} from "src/domains/Beacon/utils/twilio";

// import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import styles from "./styles.scss";

const StyledChevronIcon = () => <ChevronRight className={styles.icon} />;

export const SettingsContent = () => {
  const dispatch = useDispatch();
  const localParticipant = useAppSelector(selectLocalParticipant);
  const { consoleHasJoinedRoom } = useAppSelector(selectTwilioState);
  // const { tungstenExternalLa } = useFeatureFlags();
  const list = [
    {
      key: "consoleSettings",
      id: "consoleSettings",
      title: "Console Settings",
      rightContent: <StyledChevronIcon />,
      disabled: !consoleHasJoinedRoom,
    },
    // Network Status Content removed from tungstenExternalLa
    // ...(tungstenExternalLa
    ...(false
      ? [
          {
            key: "networkStatus",
            id: "networkStatus",
            title: "Network Status",
            rightContent: <StyledChevronIcon />,
          },
        ]
      : []),
    // Audio/Video settings not implemented yet, are gonna be hidden for now
    ...(false
      ? [
          {
            key: "audio",
            id: "audio",
            title: "Audio Settings",
            rightContent: <StyledChevronIcon />,
          },
        ]
      : []),
    ...(false
      ? [
          {
            key: "video",
            id: "video",
            title: "Video Settings",
            rightContent: <StyledChevronIcon />,
          },
        ]
      : []),
  ];

  // Will open the AudioSettings SidePanel
  const audioSettingsOnClick = () => {
    disableAudioTrack(localParticipant);
    dispatch(uiActions.muteMicrophone());
    dispatch(
      uiActions.setSidePanelContent(UISidePanelContentOptions.AUDIO_SETTINGS)
    );
  };

  // Will open the VideoSettings SidePanel
  const videoSettingsOnClick = () => {
    disableVideoTrack(localParticipant);
    dispatch(uiActions.stopPipVideo());
    dispatch(
      uiActions.setSidePanelContent(UISidePanelContentOptions.VIDEO_SETTINGS)
    );
  };

  // opens ConsoleSettings SidePanel
  const consoleSettingsOnClick = () => {
    dispatch(
      uiActions.setSidePanelContent(UISidePanelContentOptions.CONSOLE_SETTINGS)
    );
  };

  // opens NetworkSettings SidePanel
  const networkSettingsOnClick = () => {
    dispatch(
      uiActions.setSidePanelContent(
        UISidePanelContentOptions.NETWORK_STATUS_SETTINGS
      )
    );
  };

  // based on the id item list selected, will execute its corresponding function
  const clickOptions = useMemo(
    () => ({
      audio: audioSettingsOnClick,
      video: videoSettingsOnClick,
      consoleSettings: consoleSettingsOnClick,
      networkStatus: networkSettingsOnClick,
    }),
    [localParticipant]
  );

  return <List items={list} clickable onClick={(id) => clickOptions[id]()} />;
};
