import { put, takeLatest, call, select, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { notificationService } from '../../../../../Common/Services';
import ApiHelper from '../../../../../Common/Services/ApiHelper';
import { DEPARTMENTS } from '../Hospitals/Features/DepartmentProfile/DepartmentsActionTypes';
import { VENDORS } from './VendorsActionTypes';
import resources from '../../../../../Common/Constants/resources';
import urlService from '../../../../../Common/Services/urlService';
import arrayService from '../../../../../Common/Services/arrayService';

function* getAllVendors(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', resources.vendors, action.payload);
		yield all([
			put({
				type: VENDORS.SET_VENDORS,
				payload: response.content,
			}),
			put({
				type: VENDORS.SET_VENDORS_PAGINATION,
				payload: response,
			}),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getVendorById(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendor, action.payload));
		yield put({
			type: VENDORS.SET_VENDOR,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createNewVendor(action) {
	try {
		yield call(ApiHelper.post, 'coreregistry', resources.vendors, action.payload);
		const size = yield select(state => state.vendorsReducer.pagination.pageSize);
		const page = yield select(state => state.vendorsReducer.pagination.page);
		const response = yield call(ApiHelper.get, 'coreregistry', resources.vendors, {
			size,
			page,
		});
		yield put({
			type: VENDORS.SET_VENDORS,
			payload: response.content,
		});
		yield notificationService.success('You successfully created Vendor BU');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createNewDistrictFromProfile(businessUnitData) {
	const parentId = yield select(state => state.vendorsReducer.selectedBusinessUnit.id);
	const brandId = yield select(state => state.vendorsReducer.selectedBusinessUnit.brand.id);
	const params = {
		...businessUnitData,
		parentId,
		brandId,
	};

	try {
		yield call(ApiHelper.post, 'coreregistry', resources.vendors, params);
		const districtsResponse = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.districtsPerBusinessUnit, parentId));

		yield put({
			type: VENDORS.SET_BUSINESS_UNIT_DISTRICTS,
			payload: districtsResponse.content,
		});
		yield notificationService.success('You successfully created district');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createBusinessUnitFromProfile(businessUnitData) {
	const rootBrandId = businessUnitData.brandId;
	const brandId = yield select(state => (state.vendorsReducer.businessUnitsPerBrand.length === 0 ? rootBrandId : state.vendorsReducer.businessUnitsPerBrand[0].brand.id));
	const params = {
		...businessUnitData,
		brandId,
	};

	try {
		yield call(ApiHelper.post, 'coreregistry', resources.vendors, params);
		const businessUnitsResponse = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.businessUnitsPerBrand, brandId));

		yield put({
			type: VENDORS.SET_BUSINESS_UNITS_FOR_BRAND,
			payload: businessUnitsResponse.content,
		});
		yield notificationService.success('You successfully created business unit');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createNewBusinessUnitFromProfile(action) {
	const { businessUnitType, businessUnitData } = action;
	if (businessUnitType === 'District') {
		yield createNewDistrictFromProfile(businessUnitData);
	} else {
		yield createBusinessUnitFromProfile(businessUnitData);
	}
}


function* deleteVendor(action) {
	try {
		yield call(ApiHelper.remove, 'coreregistry', urlService.formatString(resources.vendor, action.payload));
		const currentVendorList = yield select(state => state.vendorsReducer.vendors);
		const newVendorList = currentVendorList.filter(vendor => vendor.id !== action.payload);

		yield put({
			type: VENDORS.SET_VENDORS,
			payload: newVendorList,
		});
		yield notificationService.success('You successfully deleted Vendor BU');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* deleteVendorDistrict(action) {
	const districtId = action.payload;

	try {
		yield call(ApiHelper.remove, 'coreregistry', urlService.formatString(resources.deleteDistrict, districtId));
		const currentDistricts = yield select(state => state.vendorsReducer.businessUnitDistricts);
		const filteredDistricts = currentDistricts.filter(district => district.id !== districtId);

		yield put({
			type: VENDORS.SET_BUSINESS_UNIT_DISTRICTS,
			payload: filteredDistricts,
		});
		yield notificationService.success('You successfully deleted the district.');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* assignVendorProcedures(action) {
	try {
		yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.vendorProcedures, action.vendorId), action.procedures);
		yield notificationService.success('You successfully updated procedures');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getVendorTherapies(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendorTherapies, action.payload));
		yield put({
			type: VENDORS.SET_VENDOR_THERAPIES,
			payload: response.content,
		});
	} catch (e) {
		yield all([
			put({
				type: VENDORS.SET_VENDOR_THERAPIES,
				payload: null,
			}),
		]);
	}
}

function* getTherapies(action) {
	try {
		let response;
		if (action.payload) {
			response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendorTherapies, action.payload));
		} else {
			response = yield call(ApiHelper.get, 'coreregistry', resources.therapies);
		}
		yield put({
			type: VENDORS.SET_THERAPIES,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getUserTherapies(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.userTherapies, action.payload));
		yield put({
			type: VENDORS.SET_USER_THERAPIES,
			payload: response.content,
		});
	} catch (e) {
		yield all([
			put({
				type: VENDORS.SET_USER_THERAPIES,
				payload: null,
			}),
		]);
	}
}


function* getVendorsForSelectedBrand(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendorsPerBrand, action.payload));
		yield put({
			type: VENDORS.SET_VENDORS_FOR_BRAND,
			payload: response.content,
		});
	} catch (e) {
		notificationService.error(e);
	}
}

function* getManagersForSelectedBusinessUnit(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.managersPerBusinessUnit, action.payload));
		yield put({
			type: VENDORS.SET_MANAGERS_FOR_BUSINESS_UNIT,
			payload: response.content,
		});
	} catch (e) {
		notificationService.error(e);
	}
}

function* getManagersBelowMe() {
	const currentUserId = yield select(state => state.homeReducer.currentUser.id);

	try {
		const response = yield call(ApiHelper.get, 'user', urlService.formatString(resources.myManagers, currentUserId));
		yield put({
			type: VENDORS.SET_MANAGERS_BELOW_ME,
			payload: response.content,
		});
	} catch (e) {
		notificationService.error(e);
	}
}

function* getDistrictsForBusinessUnit(businessUnitId) {
	try {
		const districtsResponse = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.districtsPerBusinessUnit, businessUnitId));

		yield put({
			type: VENDORS.SET_BUSINESS_UNIT_DISTRICTS,
			payload: districtsResponse.content,
		});
	} catch (e) {
		notificationService.error(e);
	}
}

function* getDistrictFromUrl(districtId) {
	try {
		const organisation = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendor, districtId));

		yield put({
			type: VENDORS.SET_SELECTED_DISTRICT,
			payload: organisation.content,
		});
	} catch (e) {
		notificationService.error(e);
	}
}

function* selectDistrict(action) {
	const location = yield select(state => state.router.location);

	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendor, action.payload));
		yield all([
			yield put({
				type: VENDORS.SET_SELECTED_DISTRICT,
				payload: response.content,
			}),
			yield put(push({
				pathname: location.pathname,
				search: `?brId=${response.content.brand.id}&buId=${response.content.parentId}&dId=${response.content.id}`,
			})),
		]);
	} catch (e) {
		notificationService.error(e);
	}
}

function* getBusinessUnitsForSelectedBrand(action) {
	const id = action.payload ? action.payload : action;
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.businessUnitsPerBrand, id));
		yield put({
			type: VENDORS.SET_BUSINESS_UNITS_FOR_BRAND,
			payload: response.content,
		});
	} catch (e) {
		notificationService.error(e);
	}
}

function* getBusinessUnitById(id) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendor, id));
		yield put({
			type: VENDORS.SET_SELECTED_BUSINESS_UNIT,
			payload: response.content,
		});
	} catch (e) {
		notificationService.error(e);
	}
}


function* selectBusinessUnit(action) {
	const businessUnitId = action.payload ? action.payload : action;

	const location = yield select(state => state.router.location);

	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendor, businessUnitId));
		yield all([
			yield put({
				type: VENDORS.SET_SELECTED_BUSINESS_UNIT,
				payload: response.content,
			}),
		]);

		if (location.query.brId && !location.query.buId) {
			yield put(push({
				pathname: location.pathname,
				search: `?brId=${response.content.brand.id}&buId=${response.content.id}`,
			}));
		}

		yield getDistrictsForBusinessUnit(businessUnitId);
	} catch (e) {
		notificationService.error(e);
	}
}

function* getProfileInitialData(action) {
	const brandId = urlService.getQueryParam(action.payload, 'brId');
	const businessUnitId = urlService.getQueryParam(action.payload, 'buId');
	const districtId = urlService.getQueryParam(action.payload, 'dId');

	if (brandId && !businessUnitId) {
		yield getBusinessUnitsForSelectedBrand(brandId);
	}

	if (businessUnitId && !districtId) {
		yield getBusinessUnitsForSelectedBrand(brandId);
		yield selectBusinessUnit(businessUnitId);
	}

	if (districtId) {
		yield getBusinessUnitsForSelectedBrand(brandId);
		yield getBusinessUnitById(businessUnitId);
		yield getDistrictFromUrl(districtId);
	}
	if(businessUnitId || districtId) {
		yield getOrganizationSettings(businessUnitId);
	}
}

function* getAdminsAndManagers(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.BuAdminsAndManagers, action.payload));
		yield put({
			type: VENDORS.SET_ADMINS_AND_MANAGERS,
			payload: response.content,
		});
	} catch (e) {
		notificationService.error(e);
	}
}

function* updateBusinessUnit(action) {
	const brandId = yield select(state => state.vendorsReducer.selectedBusinessUnit.brand.id);
	const params = {
		...action.data,
		brandId,
	};

	const { businessUnitId } = action;
	try {
		const response = yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.vendor, businessUnitId), params);
		yield put({
			type: action.entityType === 'Business Unit' ? VENDORS.SET_SELECTED_BUSINESS_UNIT : VENDORS.SET_SELECTED_DISTRICT,
			payload: response.content,
		});

		if (action.entityType === 'Business Unit') {
			yield getBusinessUnitsForSelectedBrand(response.content.brand.id);
		} else {
			yield getDistrictsForBusinessUnit(response.content.parentId);
		}

		yield notificationService.success('You successfully edited business unit');
	} catch (e) {
		notificationService.error(e);
	}
}

function* getVendorsHospitals(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendorsHospitals, action.payload.vendorId));
		const supportedAndNewHospitals = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendorsSupportedAndNewHospitals, action.payload.vendorId));
		const responseContent = response.content.slice();
		const responseData = arrayService.sortQuery(responseContent, action.payload);

		yield all([
			yield put({
				type: VENDORS.SET_VENDORS_HOSPITALS,
				payload: responseData,
			}),
			yield put({
				type: VENDORS.SET_SUPPORTED_AND_NEW_HOSPITALS,
				payload: supportedAndNewHospitals.content,
			}),
		]);
	} catch (e) {
		notificationService.error(e);
	}
}

function* updateVendorsHospitals(action) {
	try {
		yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.vendorsHospitals, action.organisationId), { 'ids': action.data });
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendorsHospitals, action.organisationId));

		yield put({
			type: VENDORS.SET_VENDORS_HOSPITALS,
			payload: response.content,
		});

		yield notificationService.success('You successfully added new hospitals');
	} catch (e) {
		notificationService.error(e);
	}
}

function* getBusinessUnitUsers(action) {
	try {
		let response = null;
		if (action.businessUnitType === 'brand') {
			response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.brandUsers, action.businessUnitId), action.params);
		} else {
			response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendorUsers, action.businessUnitId), action.params);
		}
		yield put({
			type: VENDORS.SET_BUSINESS_UNIT_USERS,
			payload: response.content,
		});
		yield put({
			type: VENDORS.SET_BUSINESS_UNIT_USERS_PAGINATION,
			payload: response,
		});
	} catch (e) {
		notificationService.error(e);
	}
}

function* applyToSecondaryManager(action) {
	try {
		yield call(ApiHelper.post, 'coreregistry', urlService.formatString(`users/${action.userId}/apply-to-manager/${action.managerId}`));
		yield getBusinessUnitUsers({
			businessUnitType: action.businessUnitType,
			businessUnitId: action.businessUnitId,
			params: action.params,
		});
		yield all([
			notificationService.success('You successfully applied to secondary manager'),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getDistrictAndBUTherapies(action) {
	try {
		const districtTherapies = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendorTherapies, action.districtId));
		const vendorTherapies = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.vendorTherapies, action.vendorId));
		yield all([
			put({
				type: VENDORS.SET_VENDOR_THERAPIES,
				payload: districtTherapies.content,
			}),
			put({
				type: DEPARTMENTS.SET_ALL_THERAPIES,
				payload: vendorTherapies.content
			})
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getOrganizationSettings(action) {
	const orgId = action.payload ? action.payload : action;
	let settings, mfaFreqTypes, mfaFrequency, mfaTwoFactorAuth;
	try {
		settings = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.getOrgSettings, orgId)
		);
		
		mfaFreqTypes = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.orgMfaFrequency, orgId)
		);
	} catch (e) {
		yield notificationService.error(e);
	}
	
	for (let orgSetting of settings.content) {
		if (orgSetting.type === "MFA_REMEMBER_FREQUENCY") {
			mfaFrequency = orgSetting.value;
		}
		if (orgSetting.type === "TWO_FACTOR_AUTH_ENABLED") {
			mfaTwoFactorAuth = orgSetting.value;
		}
	}

	yield all([
		put({
			type: VENDORS.SET_ORG_FREQUENCY_TYPES,
			payload: mfaFreqTypes.content.mfaFrequencyTypes
		}),
		put({
			type: VENDORS.SET_ORG_REMEMBER_FREQUENCY,
			payload: mfaFrequency
		}),
		put({
			type: VENDORS.SET_ORG_TWO_FACTOR_AUTH,
			payload: mfaTwoFactorAuth ? JSON.parse(mfaTwoFactorAuth) : false
		})
	]);
}

function* toggleMfaOrgLevelSettings(action) {
	const { vendorId, isTwoFactorEnabled, mfaFrequency } = action.payload;

	try {
		yield call(
			ApiHelper.put,
			"coreregistry",
			urlService.formatString(resources.getOrgSettings, vendorId), {
				type: "TWO_FACTOR_AUTH_ENABLED",
				value: isTwoFactorEnabled
			}
		);

		if (isTwoFactorEnabled) {
			yield call(
				ApiHelper.put,
				"coreregistry",
				urlService.formatString(resources.getOrgSettings, vendorId), {
					type: "MFA_REMEMBER_FREQUENCY",
					value: mfaFrequency
				}
			);
			
			yield put({
				type: VENDORS.SET_ORG_REMEMBER_FREQUENCY,
				payload: mfaFrequency
			});
		}

		yield put({
			type: VENDORS.SET_ORG_TWO_FACTOR_AUTH,
			payload: isTwoFactorEnabled
		});

	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* vendorsSaga() {
	yield takeLatest(VENDORS.GET_VENDORS, getAllVendors);
	yield takeLatest(VENDORS.GET_VENDOR_BY_ID, getVendorById);
	yield takeLatest(VENDORS.CREATE_NEW_VENDOR, createNewVendor);
	yield takeLatest(VENDORS.DELETE_VENDOR, deleteVendor);
	yield takeLatest(VENDORS.DELETE_VENDOR_DISTRICT, deleteVendorDistrict);
	yield takeLatest(VENDORS.GET_VENDOR_THERAPIES, getVendorTherapies);
	yield takeLatest(VENDORS.ASSIGN_VENDOR_PROCEDURES, assignVendorProcedures);
	yield takeLatest(VENDORS.GET_VENDORS_FOR_BRAND, getVendorsForSelectedBrand);
	yield takeLatest(VENDORS.GET_MANAGERS_FOR_BUSINESS_UNIT, getManagersForSelectedBusinessUnit);
	yield takeLatest(VENDORS.GET_MANAGERS_BELOW_ME, getManagersBelowMe);
	yield takeLatest(VENDORS.GET_BRAND_BUSINESS_UNITS, getProfileInitialData);
	yield takeLatest(VENDORS.SELECT_BUSINESS_UNIT, selectBusinessUnit);
	yield takeLatest(VENDORS.GET_ADMINS_AND_MANAGERS, getAdminsAndManagers);
	yield takeLatest(VENDORS.SELECT_DISTRICT, selectDistrict);
	yield takeLatest(VENDORS.CREATE_NEW_BUSINESS_UNIT, createNewBusinessUnitFromProfile);
	yield takeLatest(VENDORS.UPDATE_BUSINESS_UNIT, updateBusinessUnit);
	yield takeLatest(VENDORS.GET_VENDORS_HOSPITALS, getVendorsHospitals);
	yield takeLatest(VENDORS.UPDATE_VENDORS_HOSPITALS, updateVendorsHospitals);
	yield takeLatest(VENDORS.GET_BUSINESS_UNITS_FOR_SELECTED_BRAND, getBusinessUnitsForSelectedBrand);
	yield takeLatest(VENDORS.GET_DISTRICT_AND_BU_THERAPIES, getDistrictAndBUTherapies);
	yield takeLatest(VENDORS.GET_BUSINESS_UNIT_USERS, getBusinessUnitUsers);
	yield takeLatest(VENDORS.APPLY_TO_SECONDARY_MANAGER, applyToSecondaryManager);
	yield takeLatest(VENDORS.GET_USER_THERAPIES, getUserTherapies);
	yield takeLatest(VENDORS.GET_THERAPIES, getTherapies);
	yield takeLatest(VENDORS.TOGGLE_MFA_ORG_LEVEL_SETTINGS, toggleMfaOrgLevelSettings);
}
