import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Icon, Button } from '../../../../../../../Common/Components';
import AccordionComponent from '../../../../../Common/AccordionComponent/AccordionComponent';

class TherapiesAndProcedures extends PureComponent {
	onSubmit = data => this.props.onSubmit(data);

	onSelectAllHandler = (e, id) => {
		this.props.therapies.forEach((therapy) => {
			if (therapy.id === id) {
				therapy.procedures.forEach((procedure) => {
					this.props.change(`procedures.${procedure.id}`, e.target.checked);
				});
			}
		});
	};

	render() {
		return (
			<div className="therapies-container">
				<form
					className="vendor-therapies-wrapper"
					onSubmit={this.props.handleSubmit(this.onSubmit)}
				>
					<AccordionComponent
						data={this.props.therapies}
						onSelectAllHandler={this.onSelectAllHandler}
						procedureName={this.props.procedureName}
						therapyName={this.props.therapyName}
						selectedValues={this.props.vendorProfileTherapiesForm}
						{...this.props}
					/>
					{
						this.props.onSubmit && (
							<Button className="button-with-icon align-self--flex-start therapies__submit-button padding--none" type="submit">
								<Icon className="icon-save icon--orange entity-profile__submit-icon" />
								<span className="entity-profile__submit-title">Save Changes</span>
							</Button>
						)
					}
				</form>
			</div>
		);
	}
}

TherapiesAndProcedures.propTypes = {};

const mapStateToProps = state => ({
	vendorProfileTherapiesForm: getFormValues('therapiesProceduresForm')(state)
});

export default connect(mapStateToProps)(
	reduxForm({
		form: 'therapiesProceduresForm',
		enableReinitialize: true,
	})(TherapiesAndProcedures),
);
