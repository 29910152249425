import { useEffect, useRef } from "react";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectFreezeFrameState } from "src/domains/Beacon/store/stream/selectors";
import { selectSidePanelTransitionEnded } from "src/domains/Beacon/store/ui/selectors";
import {
  clearFreezeFrameCanvas,
  drawPresenceFreezeFrameImage,
} from "src/domains/Beacon/utils/canvas";
import { useEventListener } from "src/hooks/useEventListener";
import { logger } from "src/logging/logger";

// Allows to calculate the Freeze Frame image dimensions by listening to the window
// resize and SidePanel open/close interaction
export const useFreezeFrameImageResize = (imageData: string) => {
  const hasSidePanelTransitionEnded = useAppSelector(
    selectSidePanelTransitionEnded
  );
  const { active } = useAppSelector(selectFreezeFrameState);
  const imageRef = useRef<string>(imageData);
  const activeRef = useRef<boolean>(active);

  // Must need to update the imageRef, modeRef & activeRef so the "resize" window
  // listener can use them
  useEffect(() => {
    imageRef.current = imageData;
    activeRef.current = active;
  }, [imageData, active]);

  const handleFreezeFrameResize = () => {
    if (activeRef.current) {
      logger().info(
        "Console's video dimensions changed, calculating Freeze Frame's image size"
      );

      // We clear the canvas since it's only for creating the video snapshot
      clearFreezeFrameCanvas();

      // Must adjust the FF image based on th video dimensions.
      drawPresenceFreezeFrameImage(imageRef.current);
    }
  };

  // When SidePanel's transition ends, means that the video dimensions can be re-calculated,
  // only if Freeze Frame is active
  useEffect(() => {
    if (hasSidePanelTransitionEnded && active) {
      handleFreezeFrameResize();
    }
  }, [hasSidePanelTransitionEnded, active]);

  useEventListener("resize", handleFreezeFrameResize);
};
