import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { setAccessTokenReconnectionThunk } from "src/domains/Beacon/store/meeting/thunks/setAccessTokenReconnectionThunk";
import { setTwilioTokenReconnectionThunk } from "src/domains/Beacon/store/twilio/thunks/setTwilioTokenReconnectionThunk";
import { logger, LoggerLevels } from "src/logging/logger";

interface IParams {
  offerDeviceSelection?: boolean;
}

export const refreshFramesThunk = createAsyncThunk<
  // Return type of the payload creator, we return nothing so void
  void,
  // First argument to the payload creator, no args so void
  IParams,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("twilio/refreshFrames", async ({ offerDeviceSelection }, { dispatch }) => {
  try {
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Refresh Frames",
      },
      "User requested refresh frames"
    );

    await dispatch(setAccessTokenReconnectionThunk({}));
    await dispatch(setTwilioTokenReconnectionThunk({ offerDeviceSelection }));
  } catch (error) {
    logger().error("Refresh Frames error", error?.message);
    throw new Error("Refresh Frames error.");
  }
});
