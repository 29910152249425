import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './EntityProfileContent.scss';
import { Button, Icon } from '../../../../Common/Components';
import BackButton from '../../../../Common/Components/BackButton/BackButton';

class EntityProfileContent extends PureComponent {
	render() {
		return (
			<div className="entity-profile__content">
				<div className="entity-profile__header">
					<div>
						<Button className="button-with-icon align-self--flex-start" type="submit">
							<Icon className="icon-save icon--orange entity-profile__submit-icon" />
							<span className="entity-profile__submit-title">Save Changes</span>
						</Button>
					</div>
				</div>
				<div>
					{this.props.children}
				</div>
			</div>
		);
	}
}

EntityProfileContent.propTypes = {};

export default EntityProfileContent;
