import { useSelector } from "react-redux";
import { TypedUseSelectorHook } from "react-redux";

import {
  combineReducers,
  configureStore,
  PreloadedState,
} from "@reduxjs/toolkit";

import { meetingReducer } from "src/domains/Beacon/store/meeting/meetingSlice";
import { presetReducer } from "src/domains/Beacon/store/preset/presetSlice";
import { streamReducer } from "src/domains/Beacon/store/stream/streamSlice";
import { telestrationReducer } from "src/domains/Beacon/store/telestration/telestrationSlice";
import { twilioReducer } from "src/domains/Beacon/store/twilio";
import { uiReducer } from "src/domains/Beacon/store/ui/uiSlice";

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  // Details regarding the meeting details itself like callSid, callMode, meetingToken
  meeting: meetingReducer,
  // Holds state for any media related data like audio/video tracks for local and remote participants, etc
  stream: streamReducer,
  // Handles the saving of presets which include layout frames, camera ptz, and the cameras themselves
  preset: presetReducer,
  // twilio data like twilio token, the room itself, etc
  twilio: twilioReducer,
  // Preferences for elements like side panel open/close, pip hidden/shown.
  ui: uiReducer,
  // Telestration annotations
  telestration: telestrationReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      // Why this? see https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
      getDefaultMiddleware({ serializableCheck: false }),
  });
};

export const store = setupStore();

// get updated state
export const getState = store.getState;

// infer the type from the root reducer above
export type RootState = ReturnType<typeof rootReducer>;

// TODO figure out what this type is, currently used for redux-toolkit test wrapper function in renderWithProviders.ts
export type AppStore = ReturnType<typeof setupStore>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// hooks to automatically type `useDispatch` and `useSelector` to our state
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
