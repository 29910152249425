import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import AccessControlService from '../../../../../../../Common/Services/accessControlService';
import { getcurrentUserTypes } from '../../../../../HomeSelectors';
import { getAllTherapiesAction } from '../../../Hospitals/Features/DepartmentProfile/DepartmentsActions';
import {
	getVendorTherapiesAction,
	assignVendorProceduresAction,
	getDistrictAndBUTherapiesAction,
} from '../../VendorsActions';
import AccordionComponent from '../../../../../Common/AccordionComponent/AccordionComponent';
import { getInitialValuesForBusinessUnitTherapies } from '../../VendorsSelectors';

class TherapiesAndProcedures extends PureComponent {
	componentDidMount() {
		if (this.props.districtId) {
			this.props.getDistrictAndBUTherapies(this.props.districtId, this.props.vendorId);
		} else {
			this.props.getAllTherapies();
		}
	}

	onSelectAllHandler = (e, id) => {
		this.props.therapies.forEach((therapy) => {
			if (therapy.id === id) {
				therapy.procedures.forEach((procedure) => {
					this.props.change(`procedures.${procedure.id}`, e.target.checked);
				});
			}
		});
	};

	handleSubmit = (data) => {
		const procedureIds = this.formatProcedures(data.procedures);
		const id = this.props.districtId || this.props.vendorId;
		this.props.assignVendorProcedures(id, procedureIds);
	};

	formatProcedures = procedures => ({
		'ids': procedures ? Object.keys(procedures).filter((key) => {
			if (procedures[key]) {
				return key;
			}
		}) : [],
	});

	render() {
		return (
			<div className="therapies-container">
				<form className="vendor-therapies-wrapper" onSubmit={this.handleSubmit}>
					<AccordionComponent
						procedureName="procedures"
						data={this.props.therapies}
						onSelectAllHandler={this.onSelectAllHandler}
						initialValues={this.props.initialValues}
						selectedValues={this.props.vendorProfileTherapiesForm}
						disabledProcedures={!AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'edit', 'vendorProcedures')}
					/>
				</form>
			</div>
		);
	}
}

const mapStateToProps = (state, { createNewInitialValues }) => ({
	initialValues: createNewInitialValues === null ? null : getInitialValuesForBusinessUnitTherapies(state),
	therapies: state.departmentReducer.therapies,
	vendorProfileTherapiesForm: getFormValues('CreateNewVendor')(state),
	currentUserTypes: getcurrentUserTypes(state),
});

const mapDispatchToProps = dispatch => ({
	getVendorTherapies: vendorId => dispatch(getVendorTherapiesAction(vendorId)),
	assignVendorProcedures: (vendorId, procedures) => dispatch(assignVendorProceduresAction(vendorId, procedures)),
	getAllTherapies: () => dispatch(getAllTherapiesAction()),
	getDistrictAndBUTherapies: (districtId, vendorId) => dispatch(getDistrictAndBUTherapiesAction(districtId, vendorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
	reduxForm({
		form: 'CreateNewVendor',
		destroyOnUnmount: false,
		forceUnregisterOnUnmount: true,
	})(TherapiesAndProcedures),
);
