import { RemoteParticipant } from "twilio-video";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectEventDetails } from "src/domains/Beacon/store/meeting/selectors";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { uiActions } from "src/domains/Beacon/store/ui";
import { selectBadges } from "src/domains/Beacon/store/ui/selectors";
import {
  isConsoleParticipant,
  isTwilioHost,
} from "src/domains/Beacon/utils/twilio";
import { logger } from "src/logging/logger";
import { MultiPartyEvent } from "src/services/ApiClient/scheduler";

// Will detect when a remote participant disconnects to the Twilio room
export const useRoomParticipantDisconnected = () => {
  const dispatch = useDispatch();

  const { room } = useAppSelector(selectTwilioState);
  const badges = useAppSelector(selectBadges);
  const eventDetails = useAppSelector(selectEventDetails) as MultiPartyEvent;
  const eventParticipants = eventDetails?.participants;

  useEffect(() => {
    const participantDisconnected = (participant: RemoteParticipant) => {
      logger().info(
        `'${participant.identity}' has disconnected and left the room.`
      );
      const participants = [...room.participants.values()];
      const hasConsoleLeft = isConsoleParticipant(participant) === true;

      // we would only have event participants if this is a MP call
      if (eventParticipants) {
        const hostHasLeft =
          isTwilioHost(eventParticipants, participant) === true;

        if (hostHasLeft) {
          dispatch(twilioActions.setHostHasJoinedRoom(false));
        }
      }

      dispatch(twilioActions.setParticipants(participants));
      dispatch(twilioActions.setRemoteHasJoinedRoom(true));

      if (hasConsoleLeft) {
        // FF, Telestration, TPI and SidePanel are cleared inside the AvailKit HangUp event
        // during WebCall's Twilio token refresh process, Console leaves but not intentionally,
        // hence we should not clear anything here

        // clear badges
        if (badges) {
          dispatch(uiActions.clearBadges());
        }
      }
    };

    room.on("participantDisconnected", participantDisconnected);

    return function cleanup() {
      room.removeListener("participantDisconnected", participantDisconnected);
    };

    // don't put dependencies otherwise the above function will run multiple times (it just keeps adding listener)
  }, [eventParticipants, badges]);
};
