import clsx from "clsx";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Button } from "src/components/Button";
import { PTZToolTips } from "src/constants/tooltips";

import styles from "./styles.scss";

export interface KebabProps {
  toggleKebab: () => void;
  disabled: boolean;
  "data-test-id"?: string;
}

export const Kebab = ({
  "data-test-id": dataTestId,
  disabled = false,
  toggleKebab,
}: KebabProps) => {
  return (
    <Button
      variant="icon"
      classes={{
        root: clsx(styles.icon),
      }}
      endIcon={<MoreVertIcon />}
      tooltipProps={{
        title: disabled ? "" : PTZToolTips.kebab,
        placement: "left",
      }}
      disabled={disabled}
      onClick={() => toggleKebab()}
      data-test-id={`${dataTestId}-callcontrols-kebab-open`}
    />
  );
};
