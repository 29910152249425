import classNames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
/* Components */
import DropdownComponent from '../../../../Common/DropdownComponent/DropdownComponent';

class ProviderTableRow extends PureComponent {
	openDeleteProviderModal = () => this.props.openModal(this.props.rowData);

	redirect = () => this.props.history.push(`/provider/profile?providerId=${this.props.rowData.id}`);

	render() {
		const { active, name, address, city, state } = this.props.rowData;
		return (
			<Table.Row
				className={classNames({
					'table__row--inactive': !active,
				})}
			>
				<Table.Cell onClick={this.redirect} title={[name].join(' ')}>
					<div className="table__username-wrapper table__link display--flex">
						<span>{name}</span>
					</div>
				</Table.Cell>
				<Table.Cell title={[address].join(' ')}>{address}</Table.Cell>
				<Table.Cell title={[city].join(' ')}>{city}</Table.Cell>
				<Table.Cell title={[state].join(' ')}>{state}</Table.Cell>
				<Table.Cell className="table__action-table-cell">
					<div className="justify-content--flex-end">
						<DropdownComponent
							dropdownOptions={[
								...active ? [{
									className: 'dropdown__menu-item--red',
									label: 'Delete HCP group',
									text: 'Delete HCP group',
									action: this.openDeleteProviderModal,
									icon: 'icon-trash-2-outline',
								}] : [],
							]}
						/>
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}

ProviderTableRow.defaultProps = {
	rowData: null,
	openModal: () => {},
};

ProviderTableRow.propTypes = {
	rowData: PropTypes.object,
	openModal: PropTypes.func,
};

export default withRouter(ProviderTableRow);
