/**
 * SVG overlay component that creates the increasing triangle appearance for ThicknessSlider
 *
 */
export const TriangleMask = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="132"
        height="36"
        viewBox="0 0 115 16"
        fill="transparent"
      >
        <path
          d="M113.888 0.625742L0 13.5H114C114.552 13.5 115 13.0523 115 12.5V1.61941C115 1.02264 114.481 0.558707 113.888 0.625742Z"
          fill="#031424"
        />
      </svg>
    </div>
  );
};
