import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import DropdownComponent from '../../../../../../../Common/DropdownComponent/DropdownComponent';

class DomainTableRow extends PureComponent {
	onDeleteDomainHandler = () => {
		if (this.props.rowData) {
			this.props.openModal(this.props.rowData);
		}
	};

	render() {
		const domain = this.props.rowData;

		return (
			<Table.Row>
				<Table.Cell title={[domain].join(' ')}>
					<div>{domain}</div>
				</Table.Cell>
				<Table.Cell className="table__action-table-cell">
					<div className="justify-content--flex-end">
						<DropdownComponent
							dropdownOptions={[
								{
									className: 'dropdown__menu-item--red',
									label: 'Delete domain',
									text: 'Delete domain',
									action: this.onDeleteDomainHandler,
									icon: 'icon-trash-2-outline',
								},
							]}
						/>
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}

DomainTableRow.defaultProps = {
	openModal: () => { },
	rowData: null,
};

DomainTableRow.propTypes = {
	rowData: PropTypes.string,
	openModal: PropTypes.func,
};

export default withRouter(connect(undefined, undefined)(DomainTableRow));
