import { urlService } from '../../../../../Common/Services';


export const getInitialValuesForBusinessUnitGeneralInfo = (state) => {
	if (urlService.getQueryParam(state.router.location, 'dId')) {
		const { selectedDistrict } = state.vendorsReducer;
		if (selectedDistrict) {
			return {
				name: selectedDistrict.name,
				address: selectedDistrict.address,
				state: selectedDistrict.state,
				city: selectedDistrict.city,
			};
		}
	} else {
		const { selectedBusinessUnit } = state.vendorsReducer;
		if (selectedBusinessUnit) {
			return {
				name: selectedBusinessUnit.name,
				address: selectedBusinessUnit.address,
				state: selectedBusinessUnit.state,
				city: selectedBusinessUnit.city,
			};
		}
	}

	return null;
};

export const getInitialValuesForBusinessUnitTherapies = (state) => {
	const { vendorTherapies } = state.vendorsReducer;
	const procedures = {};
	if (vendorTherapies && vendorTherapies.length) {
		const therapies = vendorTherapies.reduce((o, key) => {
			return {
				...o,
				[key.id]: true,
			};
		}, {});
		vendorTherapies.reduce((o, key) => {
			key.procedures.map((procedure) => {
				Object.assign(procedures, { [procedure.id]: true });
			});
		}, {});
		return {
			therapies,
			procedures,
		};
	}
};

export const getInitialValuesForVendorsHospitals = (state) => {
	const { vendorsHospitals } = state.vendorsReducer;
	let vendorHospitalIds;
	if (vendorsHospitals.length) {
		vendorHospitalIds = vendorsHospitals.map(vendorHospital => vendorHospital.id);
	}

	return { hospitals: vendorHospitalIds };
};
