import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { Button, Fields } from '../../../../../../Common/Components';
import ModalComponent from '../../../../../../Common/Components/Modal/Modal';
import { urlService } from '../../../../../../Common/Services';
/* Helpers & Services */
import AccessControlService from '../../../../../../Common/Services/accessControlService';
import { EntityProfileSidebar, TabsWithMenu } from '../../../../Common';


import { getcurrentUserTypes } from '../../../../HomeSelectors';
/* Actions */
import {
	getAllAvailableHospitalsToApplyAction,
	getAllDescendantUsersForManagerAction,
	getAllHospitalsUserAppliedAction,
	getAllManagersUserAppliedToAction,
	getUserByIdAction,
	getUserManagerAction,
	getUserProceduresAction,
	getUserSettingsAction,
	deleteUserAction,
	getUserOrganisationAction,
	setUserManagerAction,
	removeUserManagerAction,
	getPassphraseAction,
	getMfaSettings
} from "../../UsersActions";


import {
	getManagersForSelectedBusinessUnitAction,
} from '../../../Organisation/Vendors/VendorsActions';


import { getAllManagersUserAppliedTo } from '../../UsersSelectors';
import UserHospitals from './Features/UserHospitals/UserHospitals';
/* Components */
import UserInfo from './Features/UserInfo/UserInfo';
import UserPreferences from './Features/UserPreferences/UserPreferences';
import UserProfileTherapies from './Features/UserTherapies/UserTherapies';
import UserSecurity from "src/domains/Users/components/UserSecurity/UserSecurity";
import { withTabSwitcher } from '../../../../Common/TabsWithMenu/withTabSwitcher';
import { getPanesTabIndex } from '../../../../Common/TabsWithMenu/utils';
import resources from '../../../../../../Common/Constants/resources';

class UserProfile extends PureComponent {
	constructor(props) {
		super(props);
		this.userId = urlService.getQueryParam(props.location, 'userId');

		this.state = {
			isDeleteModalOpened: false,
			isUpdateManagerModalOpen: false,
			curManagerId: null,
			userId: urlService.getQueryParam(props.location, 'userId'),
		};
	}

	componentDidMount() {
		const { userManager } = this.props;
		this.getUserProfileInitialData(this.userId);

		if (userManager) {
			// eslint-disable-next-line react/no-did-mount-set-state
			this.setState({ curManagerId: userManager.id });
		}
	}

	componentDidUpdate(prevProps) {
		const { userOrganisation, getManagersForSelectedBusinessUnit, userManager } = this.props;
		if (userOrganisation && this.isUserApplicableToUpdateManager()) {
			getManagersForSelectedBusinessUnit(userOrganisation.id);

			if (userManager) {
				// eslint-disable-next-line react/no-did-update-set-state
				this.setState({ curManagerId: userManager.id });
			}
		}

		if (prevProps.location.search !== this.props.location.search) {
			this.getUserProfileInitialData(urlService.getQueryParam(this.props.location, 'userId'));
		}
	}

	onDeleteUserHandler = async () => {
		await this.props.deleteUser(this.props.user.id);
		this.toggleDeleteModal();
		this.props.history.push('/users');
	}

	onUpdateManagerHandler = async () => {
		const { curManagerId } = this.state;
		const { userManager } = this.props;

		if (curManagerId) {
			const updated = await this.props.setUserManager(this.props.user.id, curManagerId);
			// for different api response
			this.setState({
				curManagerId: updated.managerId,
			});
		} else {
			await this.props.removeUserManager(this.props.user.id, userManager.id);
			// for different api response
			this.setState({
				curManagerId: null,
			});
		}

		this.toggleUpdateManagerModal();
	}

	onCancelUpdatingManagerHandler = () => {
		const { userManager } = this.props;

		this.setState({
			curManagerId: userManager ? userManager.id : null,
		});
		this.toggleUpdateManagerModal();
	}

	onSelectManager = (managerId) => {
		this.setState({
			curManagerId: managerId,
		});
		this.toggleUpdateManagerModal();
	}

	getUserProfileInitialData = (userId) => {
		this.props.getUserById(userId);
		this.props.getUserProcedures(userId);
		if (AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'read', 'userProfileTherapiesTab', {
			currentUserId: this.props.currentUserId,
			userProfileId: this.props.user && this.props.user.id,
			userProfileType: this.props.user && this.props.user.userTypes[0],
		})) {
			this.props.getAllHospitalsUserApplied({userId});
		}
		this.props.getAllAvailableHospitalsToApply(userId);
		this.props.getUserManager(userId);
		this.props.getUserSettings(userId);
		this.props.getAllDescendantUsersForManager(userId);
		this.props.getUserOrganisation(userId);
		this.props.getPassphrase(userId);

		if(this.props.currentUser && this.props.currentUser.orgId){
			this.props.getMfaSettings(this.props.currentUser.orgId);
		}
	};

	getAllManagersForUser = () => {
		const managers = [
			<div key={this.props.userManager.id}>
				{this.props.userManager.firstName} {this.props.userManager.lastName}
			</div>,
		];
		if (this.props.managersUserAppliedTo) {
			this.props.managersUserAppliedTo.map(manager => managers.push(
				<div key={manager.id}>{manager.manager.firstName} {manager.manager.lastName}</div>,
			));
		}
		return managers;
	};

	isUserApplicableToUpdateManager = () => {
		const { user } = this.props;
		return !!user && (user.userTypes.indexOf('VENDOR_SALES_REPRESENTATIVE') !== -1
		|| user.userTypes.indexOf('VENDOR_MANAGER') !== -1
		|| user.userTypes.indexOf('VENDOR_ADMIN') !== -1);
	}

	toggleDeleteModal = () => { this.setState({ isDeleteModalOpened: !this.state.isDeleteModalOpened }); };
	togglelockUserModal = () => { this.setState({ islockUserModalOpened: !this.state.islockUserModalOpened }); };
	toggleunlockUserModal = () => { this.setState({ isunlockUserModalOpened: !this.state.isunlockUsereModalOpened }); };
	toggleUpdateManagerModal = () => { this.setState({ isUpdateManagerModalOpen: !this.state.isUpdateManagerModalOpen }); }


	renderDeleteUserModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onDeleteUserHandler}
			onCancel={this.toggleDeleteModal}
			confirmText="Delete"
			closeOnDimmerClick={false}
		>
			<span>
				You are about to delete&nbsp;
				<span className="text--bold">
					{this.props.user && this.props.user.firstName}
					&nbsp;
					{this.props.user && this.props.user.lastName}
				</span>.
				Would you like to proceed with this action?
			</span>
		</ModalComponent>
	);

	renderUpdateManagerModal = () => {
		const { vendorManagers, user, userManager } = this.props;
		const { curManagerId } = this.state;
		const target = vendorManagers && vendorManagers.find(item => item.id === curManagerId);

		return (
			<ModalComponent
				open={this.state.isUpdateManagerModalOpen}
				onConfirm={this.onUpdateManagerHandler}
				onCancel={this.onCancelUpdatingManagerHandler}
				confirmText={curManagerId ? 'Update' : 'Remove'}
				closeOnDimmerClick={false}
			>
				{curManagerId ? (
					<span>
						You are about to assign&nbsp;
						<span className="text--bold">
							{target && target.firstName}
							&nbsp;
							{target && target.lastName}
						</span>
						&nbsp;To be&nbsp;
						<span className="text--bold">
							{user && user.firstName}
							&nbsp;
							{user && user.lastName}
						</span> manager.
						Would you like to proceed with this action?
					</span>
				) : (
					<span>You are about to remove&nbsp;
						<span className="text--bold">
							{user && user.firstName}
							&nbsp;
							{user && user.lastName}
						</span>&apos;s manager&nbsp;
						<span className="text--bold">
							{userManager && userManager.firstName}
							&nbsp;
							{userManager && userManager.lastName}
						</span>.
						Would you like to proceed with this action?
					</span>
				)}
			</ModalComponent>
		);
	};


	renderPanes = () => {
		const { location, selectedTab, } = this.props;
    const { pathname, search } = location;
    const url = `${pathname}${search}`;

		return (
			[{
				menuItem: {
          ...(selectedTab !== resources.tabs.general ? { as: Link, } : {}),
          to: `${url}#${resources.tabs.general}`,
          exact: true,
					key: resources.tabs.general,
					content: 'General Info',
				},
				render: () => <UserInfo userId={this.userId} />,
			}, {
				menuItem: (
					AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'read', 'userProfileTherapiesTab', {
						currentUserId: this.props.currentUserId,
						userProfileId: this.props.user && this.props.user.id,
						userProfileType: this.props.user && this.props.user.userTypes[0],
					}) ? {
            ...(selectedTab !== resources.tabs.therapies ? { as: Link, } : {}),
            to: `${url}#${resources.tabs.therapies}`,
            exact: true,
            key: resources.tabs.therapies,
            content: 'Therapies & Procedures',
          } : null
				),
				render: () => (
					<UserProfileTherapies userId={this.userId} />
				),
			}, {
				menuItem: (
					AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'read', 'userProfileHospitalsTab', {
						currentUserId: this.props.currentUserId,
						userProfileId: this.props.user && this.props.user.id,
						userProfileType: this.props.user && this.props.user.userTypes[0],
					}) ? {
            ...(selectedTab !== resources.tabs.facilities ? { as: Link, } : {}),
            to: `${url}#${resources.tabs.facilities}`,
            exact: true,
            key: resources.tabs.facilities,
            content: 'Facilities',
          } : null
				),
				render: () => <UserHospitals userId={this.userId} user={this.props.user} />,
			}, {
				menuItem: {
          ...(selectedTab !== resources.tabs.preferences ? { as: Link, } : {}),
          to: `${url}#${resources.tabs.preferences}`,
          exact: true,
          key: resources.tabs.preferences,
          content: 'Preferences',
				},
				render: () => <UserPreferences userId={this.userId} />,
			}, {
				menuItem: {
          ...(selectedTab !== resources.tabs.security ? { as: Link, } : {}),
          to: `${url}#${resources.tabs.security}`,
          exact: true,
          key: resources.tabs.security,
          content: 'Security',
				},
				render: () => <UserSecurity userId={this.userId} />,
			}, {
				menuItem: (
					AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'read', 'deleteUser', {
						currentUserId: this.props.currentUserId,
						userProfileId: this.props.user && this.props.user.id,
						userProfileType: this.props.user && this.props.user.userTypes[0],
					}) ? (
							// eslint-disable-next-line react/jsx-indent
							<span className="item" key="del">
								<Button onClick={this.toggleDeleteModal} className="vendor-profile__delete-button">
									Delete User
								</Button>
							</span>
						) : null
				),
				render: () => null,
			}]
		);
	};

	render() {
		const { user, vendorManagers, currentUserTypes } = this.props;
		const { curManagerId } = this.state;	

		return (
      <div className="display--flex height--full flex--one flex-direction--column">
				<div className="display--flex height--full">
					<EntityProfileSidebar title="User profile">
						{user &&
              <div className="profile-breadcrumbs">
                <a
                  aria-current="page"
                  className="profile-breadcrumbs__item user-profile__username active"
                >
                  <div className="profile-breadcrumbs__link">{user.firstName} {user.lastName}</div>
                </a>
                {this.isUserApplicableToUpdateManager() &&
                <div className="manager-dropdown">
                  <Fields.SelectField
                    title="Editable only by Avail Admin"
                    disabled={(currentUserTypes.indexOf('AVAIL_ADMIN') === -1)}
                    clearable
                    multiple={false}
                    className="avail__input"
                    labelText="Manager"
                    placeholder="Select Manager"
                    name="manager"
                    currentValue={curManagerId}
                    options={vendorManagers ? vendorManagers.map(item => ({
                      key: item.id,
                      value: item.id,
                      text: `${item.firstName} ${item.lastName}`,
                    })) : []}
                    onChange={this.onSelectManager}
                    />
                </div>}
              </div>
						}
					</EntityProfileSidebar>
					<div className="vendor-profile__right-side">
						<TabsWithMenu 
              options={this.renderPanes()}
              activeIndex={getPanesTabIndex(this.renderPanes(), this.props.selectedTab) || 0}
            />
					</div>
				</div>
				{this.renderDeleteUserModal()}
				{this.renderUpdateManagerModal()}
			</div>
		);
	}
}

UserProfile.defaultProps = {
	initialValues: null,
};

const mapStateToProps = state => ({
	user: state.usersReducer.user,
	currentUser: state.homeReducer.currentUser,
	currentUserTypes: getcurrentUserTypes(state),
	userManager: state.usersReducer.userManager,
	currentUserId: state.homeReducer.currentUser.id,
	managersUserAppliedTo: getAllManagersUserAppliedTo(state),
	timeZone: state.usersReducer.usersCurrentTimeZone,
	userOrganisation: state.usersReducer.userOrganisation,
	vendorManagers: state.vendorsReducer.managersPerBusinessUnit,
});

const mapDispatchToProps = dispatch => ({
	getUserById: id => dispatch(getUserByIdAction(id)),
	getUserProcedures: id => dispatch(getUserProceduresAction(id)),
	getAllHospitalsUserApplied: userId => dispatch(getAllHospitalsUserAppliedAction(userId)),
	getAllAvailableHospitalsToApply: userId => dispatch(getAllAvailableHospitalsToApplyAction(userId)),
	getUserManager: userId => dispatch(getUserManagerAction(userId)),
	getAllManagersUserAppliedTo: payload => dispatch(getAllManagersUserAppliedToAction(payload)),
	getUserSettings: userId => dispatch(getUserSettingsAction(userId)),
	getAllDescendantUsersForManager: managerId => dispatch(getAllDescendantUsersForManagerAction(managerId)),
	deleteUser: userId => dispatch(deleteUserAction(userId)),
	getUserOrganisation: id => dispatch(getUserOrganisationAction(id)),
	getManagersForSelectedBusinessUnit: vendorId => dispatch(getManagersForSelectedBusinessUnitAction(vendorId)),
	setUserManager: (userId, managerId) => dispatch(setUserManagerAction(userId, managerId)),
	removeUserManager: (userId, managerId) => dispatch(removeUserManagerAction(userId, managerId)),
	getPassphrase: (userId) => dispatch(getPassphraseAction(userId)),
	getMfaSettings: (orgId) => dispatch(getMfaSettings(orgId)),
});

const UserProfileWithTranslation = withTranslation()(UserProfile);

export default withRouter(withTabSwitcher(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'userProfileForm',
	enableReinitialize: true,
})(UserProfileWithTranslation))));

