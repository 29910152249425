export default {
	'AVAIL_ADMIN': {
		title: 'Avail Administrator',
		className: 'avail-admin',
		refuseInvite: ['DISTRIBUTOR_ADMIN', 'DISTRIBUTOR_MANAGER', 'DISTRIBUTOR_SALES_REPRESENTATIVE'],
		redirectionRoute: '/home',

		hasProcedures: false,
	},
	'VENDOR_ADMIN': {
		title: 'Vendor Administrator',
		className: 'vendor-admin',
		canInvite: ['VENDOR_ADMIN', 'VENDOR_MANAGER', 'VENDOR_SALES_REPRESENTATIVE'],
		redirectionRoute: '/home',
		hasProcedures: true,
	},
	'VENDOR_MANAGER': {
		title: 'Vendor Manager',
		className: 'vendor-manager',
		canInvite: ['VENDOR_MANAGER', 'VENDOR_SALES_REPRESENTATIVE'],
		redirectionRoute: '/home',
		hasProcedures: true,
	},
	'VENDOR_SALES_REPRESENTATIVE': {
		title: 'Vendor Field Representative',
		className: 'vendor-sales-rep',
		canInvite: [],
		redirectionRoute: '/scheduler',
		hasProcedures: true,
	},
	'DISTRIBUTOR_ADMIN': {
		title: 'Distributor administrator',
		className: 'distributor-admin',
		hasProcedures: false,
	},
	'DISTRIBUTOR_MANAGER': {
		title: 'Distributor Manager',
		className: 'distributor-manager',
		hasProcedures: false,
	},
	'DISTRIBUTOR_SALES_REPRESENTATIVE': {
		title: 'Distributor Sales Representative',
		className: 'distributor-sales-rep',
		hasProcedures: true,
	},
	'INDIVIDUAL_SALES_REPRESENTATIVE': {
		title: 'Independent Field Representative',
		className: 'individual-sales-rep',
		redirectionRoute: '/scheduler',
		hasProcedures: true,
	},
	'HOSPITAL_ADMIN': {
		title: 'Facility Administrator',
		className: 'hospital-admin',
		canInvite: ['HOSPITAL_ADMIN'],
		redirectionRoute: '/home',
		hasProcedures: false,
	},
	'PROCTOR': {
		title: 'Proctor',
		className: 'proctor',
		redirectionRoute: '/scheduler',
		hasProcedures: true,
	},
	'SPECIALIST': {
		title: 'HCP',
		className: 'specialist',
		redirectionRoute: '/scheduler',
		hasProcedures: true,
	},
	'GUEST': {
		title: 'Guest',
		className: 'guest',
		redirectionRoute: '/not-found',
		hasProcedures: false,
	},
};

export const roles = {
	vendorAdmin: 'VENDOR_ADMIN',
	salesRep: 'SALES_REP',
	vendorManager: 'VENDOR_MANAGER',
	vendorSalesRep: 'VENDOR_SALES_REPRESENTATIVE',
	availAdmin: 'AVAIL_ADMIN',
	guest: 'GUEST',
	hospitalAdmin: 'HOSPITAL_ADMIN',
	proctor: 'PROCTOR',
	specialist: 'SPECIALIST',
	individualSalesRep: 'INDIVIDUAL_SALES_REPRESENTATIVE',
};
