import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { elementType } from '../../../../Common/Constants/elementType';
/* Styles  */
import './Status.scss';

const Status = ({ className, status, absolutePosition, ...props }) => (
	<span
		className={classNames('status', className, {
			[`status--${status || elementType.PRIMARY}`]: true,
			'status--position-absolute': absolutePosition,
		})}
	/>
);

Status.propTypes = {
	status: PropTypes.string.isRequired,
};

export default Status;
