import { Guid } from "guid-typescript";

import UserSessionService from "src/services/UserSessionService";

export function generateTraceId(): string {
  const { loginId } = UserSessionService.getCachedUserInfo() || {};

  const traceIdConstants = `PVID-${UserSessionService.getDeviceId()}-${loginId}`;
  const guidComponent = Guid.create().toString();

  return `${guidComponent}-${traceIdConstants}`;
}
