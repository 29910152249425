// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_action-link_pGwli\\+{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/legacy/Link/styles.scss"],"names":[],"mappings":"AAAA,4BACE,cAAA","sourcesContent":[".action-link {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action-link": "styles_action-link_pGwli+",
	"actionLink": "styles_action-link_pGwli+"
};
export default ___CSS_LOADER_EXPORT___;
