// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_user-security-form_dlTGtx{display:flex;justify-content:flex-start;width:100%}.styles_user-security-form_dlTGtx .styles_delete-form_19BTBO{align-items:stretch;display:flex;gap:8rem;justify-content:flex-start}", "",{"version":3,"sources":["webpack://./src/domains/Users/components/UserSecurity/styles.scss"],"names":[],"mappings":"AAAA,kCACE,YAAA,CACA,0BAAA,CACA,UAAA,CAEA,6DACE,mBAAA,CACA,YAAA,CACA,QAAA,CACA,0BAAA","sourcesContent":[".user-security-form {\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n\n  .delete-form {\n    align-items: stretch;\n    display: flex;\n    gap: 8rem;\n    justify-content: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-security-form": "styles_user-security-form_dlTGtx",
	"userSecurityForm": "styles_user-security-form_dlTGtx",
	"delete-form": "styles_delete-form_19BTBO",
	"deleteForm": "styles_delete-form_19BTBO"
};
export default ___CSS_LOADER_EXPORT___;
