import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { roles } from '../../../../../../Common/Constants/userTypes';
import DropdownComponent from '../../../../Common/DropdownComponent/DropdownComponent';

class HospitalNetworkRow extends PureComponent {
	openDeleteModal = () => {
		this.props.openModal(this.props.rowData);
	};

	renderDropdownOptions = () => {
		const { userType } = this.props;
		const canUserApply = userType === roles.availAdmin;
		return [...(canUserApply) ? [{
			label: 'Delete',
			text: 'Delete',
			action: this.openDeleteModal,
			icon: 'icon-trash-2-outline',
			className: 'dropdown__menu-item--red',
		}] : []];
	};

  renderHospitalList = () => {
		const { hospitalList } = this.props.rowData;

    if (hospitalList) {
      const hospitalSubstr = hospitalList?.slice(0, 2).map(hospital => hospital.hospitalName).join(', ')
      const hospitalStr = hospitalList?.map(hospital => hospital.name).join(', ');
      
      return (
				<Table.Cell title={[hospitalStr].join(' ')}>
          <div className="table__facilities-wrapper display--flex" data-content={[hospitalStr]}>
            <span>{hospitalList.length > 2 ? `${hospitalSubstr}, ...` : hospitalSubstr}</span>
          </div>
        </Table.Cell>
      )
    }
  }

	render() {
		const { name } = this.props.rowData;

		return (
			<Table.Row>
				<Table.Cell title={[name].join(' ')}>
					<div className="table__username-wrapper display--flex">
						<span>{name}</span>
					</div>
				</Table.Cell>
        {this.renderHospitalList()}
				<Table.Cell className="table__action-table-cell">
					<div className="flex-direction--row justify-content--flex-end align--center">
						<DropdownComponent dropdownOptions={this.renderDropdownOptions()} />
						</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}

HospitalNetworkRow.propTypes = {};

const mapStateToProps = state => ({
	userType: state.homeReducer.currentUser.userType,
});

export default connect(mapStateToProps)(HospitalNetworkRow);
