import React from 'react';
import { Field } from 'redux-form';
import { validators } from '../../../../../../Common/Components';
import ValidationMessage from '../../../../../../Common/Components/FormFields/ValidationMessage/ValidationMessage';
import DayCheckbox from '../DayCheckbox/DayCheckbox';

import './DaysOfWeekPickerField.scss';


const dayCheckboxesFieldNames = [
	{
		name: 'SUNDAY',
		label: 'Sun',
	},
	{
		name: 'MONDAY',
		label: 'Mon',
	},
	{
		name: 'TUESDAY',
		label: 'Tue',
	},
	{
		name: 'WEDNESDAY',
		label: 'Wed',
	},
	{
		name: 'THURSDAY',
		label: 'Thu',
	},
	{
		name: 'FRIDAY',
		label: 'Fri',
	},
	{
		name: 'SATURDAY',
		label: 'Sat',
	},
];

const DayCheckboxesField = ({ input, currentValue, meta: { touched, warning, error }, as: As, ...props }) => (
	<div>
		<As {...input} {...props} />
		{touched
		&& ((error && <ValidationMessage>{error}</ValidationMessage>)
			|| (warning && <ValidationMessage>{warning}</ValidationMessage>))}
	</div>
);

class DayCheckboxes extends React.Component {
	static createStateObject(props) {
		if (!props.value) {
			return {
				MONDAY: false,
				TUESDAY: false,
				WEDNESDAY: false,
				THURSDAY: false,
				FRIDAY: false,
				SATURDAY: false,
				SUNDAY: false,
			};
		}

		return {
			MONDAY: props.value.MONDAY,
			TUESDAY: props.value.TUESDAY,
			WEDNESDAY: props.value.WEDNESDAY,
			THURSDAY: props.value.THURSDAY,
			FRIDAY: props.value.FRIDAY,
			SATURDAY: props.value.SATURDAY,
			SUNDAY: props.value.SUNDAY,
		};
	}

	state = DayCheckboxes.createStateObject(this.props);

	componentWillReceiveProps(nextProps) {
		if (JSON.stringify(nextProps.value) !== JSON.stringify(this.props.value)) {
			this.setState(DayCheckboxes.createStateObject(nextProps));
		}
	}

	handleChange = (value, name) => {
		this.setState((prevState) => {
			const newState = {
				...prevState,
				[name]: value.value,
			};

			this.props.onChange(newState);

			return newState;
		});
	};

	render() {
		const fieldsArray = dayCheckboxesFieldNames.map(field => (
			<DayCheckbox
				key={field.name}
				name={field.name}
				label={field.label}
				checked={(this.state && this.state[field.name]) || false}
				onChange={value => this.handleChange(value, field.name)}
				disabled={this.props.disabled}
			/>
		));

		return (
			<div className="day-of-week-picker-field">
				{fieldsArray}
			</div>
		);
	}
}

export default ({ ...props }) => {
	const names = dayCheckboxesFieldNames.map(day => day.name);
	return (
		<Field
			flatNames={names}
			component={DayCheckboxesField}
			as={DayCheckboxes}
			name="workingDaysOfWeek"
			validate={validators.requiredIfRecurringEvent}
			disabled={props.disabled}
		/>
	);
};
