// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_ipad-landing-page_cQybFD{height:33%;margin:auto;padding:5%;text-align:center;width:60%}.styles_error-message_9RO6MQ{padding:5% 20%}.styles_error-message_9RO6MQ h6:nth-child(2){margin-top:5%}.styles_app-store-logo_malhRe{margin:5%}", "",{"version":3,"sources":["webpack://./src/components/legacy/Errors/AppStoreRedirect/styles.scss"],"names":[],"mappings":"AAAA,iCACE,UAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,SAAA,CAGF,6BACE,cAAA,CAEA,6CACE,aAAA,CAIJ,8BACE,SAAA","sourcesContent":[".ipad-landing-page {\n  height: 33%;\n  margin: auto;\n  padding: 5%;\n  text-align: center;\n  width: 60%;\n}\n\n.error-message {\n  padding: 5% 20%;\n\n  h6:nth-child(2) {\n    margin-top: 5%;\n  }\n}\n\n.app-store-logo {\n  margin: 5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ipad-landing-page": "styles_ipad-landing-page_cQybFD",
	"ipadLandingPage": "styles_ipad-landing-page_cQybFD",
	"error-message": "styles_error-message_9RO6MQ",
	"errorMessage": "styles_error-message_9RO6MQ",
	"app-store-logo": "styles_app-store-logo_malhRe",
	"appStoreLogo": "styles_app-store-logo_malhRe"
};
export default ___CSS_LOADER_EXPORT___;
