import { useDispatch } from "react-redux";

import { LeaveCallModal } from "src/domains/Beacon/components/LeaveCallHandler/LeaveCallModal";
import { LeaveCallScreen } from "src/domains/Beacon/components/LeaveCallHandler/LeaveCallScreen";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectDuplicateParticipantDisconnected } from "src/domains/Beacon/store/twilio/selectors";
import { leaveCallThunk } from "src/domains/Beacon/store/twilio/thunks/leaveCall";
import { uiActions } from "src/domains/Beacon/store/ui";
import { selectUiState } from "src/domains/Beacon/store/ui/selectors";
import {
  selectCallStep,
  selectLeaveCallModalOpen,
} from "src/domains/Beacon/store/ui/selectors";
import { CallSteps } from "src/domains/Beacon/store/ui/types";
import { LeaveCallErrorMessages } from "src/domains/Beacon/store/ui/types";
import { logger } from "src/logging/logger";

// All leave call state stuff will go here in this component
// Stuff like the leave call modal, the leave call screen that says you have left, and the feedback page in the future
// using this component as sort of a container for redux state/actions and then passing them down to the leave call modal/screen
export const LeaveCallHandler = () => {
  const dispatch = useDispatch();

  const leaveCallModalOpen = useAppSelector(selectLeaveCallModalOpen);
  const currentCallStep = useAppSelector(selectCallStep);
  const duplicateParticipantDisconnected = useAppSelector(
    selectDuplicateParticipantDisconnected
  );
  const { leaveCallErrorMessage } = useAppSelector(selectUiState);

  const closeLeaveCallModal = () => {
    dispatch(uiActions.setLeaveCallModal(false));
    // clear leaveCallErrorMessage state to restore default
    dispatch(uiActions.setLeaveCallErrorMessage(null));
  };

  const leaveCallAction = () => {
    logger().info(
      "UserAction: User clicked on the leave call confirmation button in the leave call modal"
    );
    // the leave call thunk handles all the necessary actions for the user to leave the call
    dispatch(leaveCallThunk());
  };

  return (
    <>
      <LeaveCallModal
        open={leaveCallModalOpen}
        closeModal={closeLeaveCallModal}
        leaveCallAction={leaveCallAction}
        errorMessage={leaveCallErrorMessage}
      />
      {/* we only want to show the leave call screen when the call is in the "leave call" state
          it will appear on top of the whole screen only once show is set to true
      */}
      <LeaveCallScreen
        // TODO when we have more errors to handle then move this into a getErrorMessage function to show the correct message
        errorMessage={
          duplicateParticipantDisconnected
            ? LeaveCallErrorMessages.DUPLICATE_PARTICIPANT
            : null
        }
        show={currentCallStep === CallSteps.LEAVE_CALL}
      />
    </>
  );
};
