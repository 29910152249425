import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'semantic-ui-react';
import Icon from '../../../../Common/Components/Icon/Icon';

class PaginationComponent extends PureComponent {
	render() {
		const { paginationConfig, onPageChangeHandler } = this.props;

		return (
			<Pagination
				borderless
				defaultActivePage={1}
        activePage={paginationConfig.page + 1}
				totalPages={paginationConfig.totalPages}
				onPageChange={onPageChangeHandler}
				firstItem={{
					content: 'First',
				}}
				lastItem={{
					content: 'Last',
				}}
				prevItem={{
					content: <Icon className="icon-chevron-left-outline" />,
				}}
				nextItem={{
					content: <Icon className="icon-chevron-right-outline" />,
				}}
			/>
		);
	}
}

PaginationComponent.propTypes = {
	paginationConfig: PropTypes.shape().isRequired,
	onPageChangeHandler: PropTypes.func.isRequired,
};

export default PaginationComponent;
