import createSagaMiddleware from "redux-saga";

import { connectRouter, routerMiddleware } from "connected-react-router";

import { createBrowserHistory } from "history";

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { appBuildIsOldVersion } from "avail-web-ui/utils/build";

import { getUserInfo, getUserProfiles } from "../services/UserSessionService";
import { AuthReducers } from "./AuthSlice";
import IdleSaga from "./IdleSaga";
import { IdleReducers } from "./IdleSlice";
import { MeetingReducers } from "./MeetingSlice";
import { NotificationReducers } from "./NotificationSlice";

export const history = createBrowserHistory({
  basename: appBuildIsOldVersion() ? "/event/index.html" : "",
});

const sagaMiddleware = createSagaMiddleware();

const preloadedState = {
  auth: {
    // TODO - rename to userInfo?
    profile: getUserInfo(),
    profiles: getUserProfiles(),
  },
};

const appReducer = combineReducers({
  auth: AuthReducers,
  idle: IdleReducers,
  meeting: MeetingReducers,
  notifications: NotificationReducers,
  router: connectRouter(history),
});

const store = configureStore({
  reducer: appReducer,
  middleware: [sagaMiddleware, routerMiddleware(history)],
  preloadedState,
});

sagaMiddleware.run(IdleSaga);

export default store;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
