import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { AVKExternalInputPosition } from "availkit-js/dist/Models/AVKExternalInputPosition";
import { AVKExternalInputShift } from "availkit-js/dist/Models/AVKExternalInputShift";
import { AVKExternalInputMoveEvent } from "availkit-js/dist/Models/Events/AVKExternalInputMoveEvent";
import { NCameraPanTiltEvent } from "availkit-js/dist/Models/Events/NCameraPanTiltEvent";
import { NCameraZoomEvent } from "availkit-js/dist/Models/Events/NCameraZoomEvent";

import { useDispatch } from "react-redux";

import clsx from "clsx";

import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { IconButton } from "src/components/Button";
import { Tooltip } from "src/components/Tooltip";
import { PAN_DELTA, TILT_DELTA } from "src/constants";
import { PTZToolTips } from "src/constants/tooltips";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import {
  selectCameras,
  selectConsoleHasExternalInputResizing,
  selectDefaultCameraPositions,
  selectLayoutFrames,
  selectZoomState,
} from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import {
  LayoutFrameNames,
  ZoomFrames,
} from "src/domains/Beacon/store/stream/types";
import { CameraIdentifier } from "src/domains/Beacon/utils/availKit";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { logger } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

import styles from "./styles.scss";

export interface InputControlsDirectionalPadProps {
  side: LayoutFrameNames;
  "data-test-id"?: string;
}

export const InputControlsDirectionalPad = ({
  "data-test-id": dataTestId,
  side,
}: InputControlsDirectionalPadProps) => {
  const cameras = useAppSelector(selectCameras);
  const defaultPositions = useAppSelector(selectDefaultCameraPositions);
  const layoutFrames = useAppSelector(selectLayoutFrames);
  const { callSid } = useAppSelector(selectMeetingState);
  const zoomState = useAppSelector(selectZoomState);
  const availKit = AvailKitService.instance;
  const { externalInputsImageResizing } = useFeatureFlags();
  const consoleHasExternalInputResizing = useAppSelector(
    selectConsoleHasExternalInputResizing
  );
  const hasExternalInputResizing =
    consoleHasExternalInputResizing && externalInputsImageResizing;
  const dispatch = useDispatch();

  const checkCameraSelected = () => {
    const id = zoomState[side].cameraId;
    return id.includes("Front") || id.includes("Overhead");
  };

  const createExternalInput = (
    theCamera,
    direction,
    increment,
    maxOrMin,
    maxMinValue,
    isAutoCrop = false
  ): void => {
    // TODO: modify availkit to accept arguments for - AVKExternalInputShift
    const shift = new AVKExternalInputShift();
    shift.x = -2;
    shift.y = -2;
    theCamera.shift = shift;
    theCamera.isAutoCrop = isAutoCrop || theCamera.isAutoCrop;
    theCamera.position[direction] = Math[maxOrMin](
      theCamera.position[direction] + increment,
      maxMinValue
    );
  };

  const homedCamera = (theCamera): void => {
    const name = theCamera.name.toLowerCase();
    theCamera.location.x = defaultPositions[name].x;
    theCamera.location.y = defaultPositions[name].y;
    theCamera.location.zoomLevel = 0;

    const cameraZoomEvent = new NCameraZoomEvent(callSid, theCamera);
    availKit?.eventService.broadcast(cameraZoomEvent);
  };

  const getCameraDelta = (delta) => {
    const zoomValue = zoomState[side].value;
    const scaledZoomValue = 1 - (zoomValue - 1) / 10;
    // z -> 1  => 0.02 * 1 - 0/10 => 1.0
    // z -> 2  => 0.02 * 1 - 1/10 => 0.9
    // z -> 3  => 0.02 * 1 - 2/10 => 0.8
    // z -> 4  => 0.02 * 1 - 3/10 => 0.7
    // z -> 5  => 0.02 * 1 - 4/10 => 0.6
    // z -> 6  => 0.02 * 1 - 5/10 => 0.5
    // z -> 7  => 0.02 * 1 - 6/10 => 0.4
    // z -> 8  => 0.02 * 1 - 7/10 => 0.3
    // z -> 9  => 0.02 * 1 - 8/10 => 0.2
    // z -> 10 => 0.02 * 1 - 9/10 => 0.1
    let cameraDelta = delta === "PAN_DELTA" ? PAN_DELTA : TILT_DELTA;
    try {
      cameraDelta = parseFloat(
        localStorage.getItem(`REACT_APP_${delta}`) || "" + cameraDelta
      );
      if (
        !cameraDelta ||
        isNaN(cameraDelta) ||
        cameraDelta > 1 ||
        cameraDelta < -1
      ) {
        cameraDelta = cameraDelta;
      }
    } catch (e) {
      cameraDelta = cameraDelta;
    }
    return computeDelta("TILT_DELTA", cameraDelta * scaledZoomValue);
  };

  const computeDelta = (key: string, defaultValue: any) => {
    // ensure the value falls within bounds of the "if" assigned valueString
    let returnValue = defaultValue;
    const valueString = localStorage.getItem(key);
    if (valueString !== null) {
      try {
        returnValue = parseFloat(valueString);
        if (
          !returnValue ||
          isNaN(returnValue) ||
          returnValue < -1 ||
          returnValue > 1
        ) {
          returnValue = defaultValue;
        }
      } catch (e) {
        returnValue = defaultValue;
      }
    }
    return returnValue;
  };

  const sendSourceMessage = (source) => {
    // detect if AVKCamera or AVKExternalInput
    if ("cameraIdentifier" in source) {
      const cameraPTZEvent = new NCameraPanTiltEvent(callSid, source);
      logger().info(
        `${source.cameraIdentifier} location: [${source.location.x},${source.location.y},${source.location.zoomLevel}]`
      );
      availKit?.eventService.broadcast(cameraPTZEvent);
    } else {
      const externalInputPTZEvent = new AVKExternalInputMoveEvent(
        callSid,
        source
      );
      logger().info(
        `${source.id}
          position:[${source.position.x}, ${source.position.y}],
          shift: [${source.shift.x}, ${source.shift.y}],
          zoom: ${source.zoomLevel},
          isAutoCrop: ${source.isAutoCrop}.`
      );
      availKit?.eventService.broadcast(externalInputPTZEvent);
    }
  };

  const selectDirectionalControl = (selectedControl: string): void => {
    const cameraId = layoutFrames[side].cameraId;
    const snapshotCameras = JSON.parse(JSON.stringify(cameras));
    const theCamera = snapshotCameras.filter(
      (camera: AVKCamera | AVKExternalInput) =>
        CameraIdentifier(camera) === cameraId
    )[0] as AVKCamera | AVKExternalInput;
    const localZoomState: ZoomFrames = { ...zoomState };
    const activeZoom = { ...localZoomState[side] };

    switch (selectedControl) {
      case "tilt-up":
        if ("cameraIdentifier" in theCamera) {
          theCamera.location.y = Math.min(
            theCamera.location.y + getCameraDelta("TILT_DELTA"),
            1.0
          );
        } else {
          createExternalInput(theCamera, "y", 0.1, "min", 0.5);
        }
        break;
      case "tilt-down":
        if ("cameraIdentifier" in theCamera) {
          theCamera.location.y = Math.max(
            theCamera.location.y - getCameraDelta("TILT_DELTA"),
            -1.0
          );
        } else {
          createExternalInput(theCamera, "y", -0.1, "max", -0.5);
        }
        break;
      case "pan-right":
        if ("cameraIdentifier" in theCamera) {
          theCamera.location.x = Math.min(
            theCamera.location.x + getCameraDelta("TILT_DELTA"),
            1.0
          );
        } else {
          createExternalInput(theCamera, "x", 0.1, "min", 0.5);
        }
        break;
      case "pan-left":
        if ("cameraIdentifier" in theCamera) {
          theCamera.location.x = Math.max(
            theCamera.location.x - getCameraDelta("TILT_DELTA"),
            -1.0
          );
        } else {
          createExternalInput(theCamera, "x", -0.1, "max", -0.5);
        }
        break;
      case "default-homed":
        if ("cameraIdentifier" in theCamera) {
          homedCamera(theCamera);
          activeZoom.value = 0;
          localZoomState[side] = activeZoom;
        } else {
          const position = new AVKExternalInputPosition();
          const shift = new AVKExternalInputShift();
          position.x = 0;
          position.y = 0;
          shift.x = 0;
          shift.y = 0;
          theCamera.isAutoCrop = true;
          theCamera.position = position;
          theCamera.shift = shift;
          theCamera.zoomLevel = 0.4;
          activeZoom.value = 4;
          localZoomState[side] = activeZoom;
        }
        break;
    }

    sendSourceMessage(theCamera);
    dispatch(
      streamActions.setCameras([...snapshotCameras] as (
        | AVKCamera
        | AVKExternalInput
      )[])
    );
    dispatch(streamActions.setZoomState(localZoomState));

    // Must reset the selected preset, info will not match afterwards
    dispatch(streamActions.setPresetSelected(null));
  };

  return (
    <Tooltip title={PTZToolTips.panControls} color="black" placement="left">
      <div className={styles.pad}>
        <IconButton
          aria-label="callcontrols-ptz-up"
          background="dark"
          classes={{
            root: clsx(styles.icon, styles.up),
            disabled: styles.disabled,
          }}
          disabled={!hasExternalInputResizing && !checkCameraSelected()}
          onClick={() => selectDirectionalControl("tilt-up")}
          data-test-id={`${dataTestId}-ptz-up`}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
        <IconButton
          aria-label="callcontrols-ptz-right"
          background="dark"
          classes={{
            root: clsx(styles.icon, styles.right),
            disabled: styles.disabled,
          }}
          disabled={!hasExternalInputResizing && !checkCameraSelected()}
          onClick={() => selectDirectionalControl("pan-right")}
          data-test-id={`${dataTestId}-ptz-right`}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
        <IconButton
          aria-label="callcontrols-ptz-homed"
          background="dark"
          classes={{
            root: clsx(styles.icon, styles.center),
            disabled: styles.disabled,
          }}
          disabled={!hasExternalInputResizing && !checkCameraSelected()}
          onClick={() => selectDirectionalControl("default-homed")}
          data-test-id={`${dataTestId}-ptz-homed`}
        >
          <CircleIcon
            classes={{
              root: clsx(styles.homed),
            }}
          />
        </IconButton>
        <IconButton
          aria-label="callcontrols-ptz-down"
          background="dark"
          classes={{
            root: clsx(styles.icon, styles.down),
            disabled: styles.disabled,
          }}
          disabled={!hasExternalInputResizing && !checkCameraSelected()}
          onClick={() => selectDirectionalControl("tilt-down")}
          data-test-id={`${dataTestId}-ptz-down`}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
        <IconButton
          aria-label="callcontrols-ptz-left"
          background="dark"
          classes={{
            root: clsx(styles.icon, styles.left),
            disabled: styles.disabled,
          }}
          disabled={!hasExternalInputResizing && !checkCameraSelected()}
          onClick={() => selectDirectionalControl("pan-left")}
          data-test-id={`${dataTestId}-ptz-left`}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};
