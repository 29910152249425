import { userSessionService } from "../../../../Common/Services";
import { USERS } from "./UsersActionTypes";

export const initialState = {
	userTypes: [],
	selectedUserType: null,
	users: null,
	selectedUser: null,
	pagination: {
		page: 0,
		size: 10,
		totalPages: 1,
	},
	user: null,
	userOrganisation: null,
	userSupportsOrganisations: null,
	userProcedures: [],
	isUsersLoaderActive: false,
	initialValues: null,
	hospitalApplications: [],
	availableHospitalsToApply: null,
	approvedHospitalsForUser: null,
	userManager: null,
	descendantUsers: null,
	managersUserAppliedTo: null,
	timezone: null,
	emailNotification: null,
	passphrase: null,
	phoneNumber: null,
	validPhone: false,
	mfaOrgLevelTwoFactor: true,
	errorMessage: "",
	otpError: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case USERS.SET_USER_TYPES:
			return {
				...state,
				userTypes: action.payload,
			};
		case USERS.SELECT_USER_TYPE:
			return {
				...state,
				selectedUserType: action.payload,
			};
		case USERS.SELECT_USER:
			return {
				...state,
				selectedUser: action.payload,
			};
		case USERS.SET_USERS:
			return {
				...state,
				users: action.payload,
			};
      
		case USERS.SET_USERS_PAGINATION:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload.pageNumber,
					size: action.payload.pageSize,
					totalPages: action.payload.totalPages,
				},
			};
		case USERS.SET_USER:
			// auto created users have first/last name set as "firstName" and "lastName" so we
			// can just get rid of that so it doesn't appear in the input form and so the user can fill it out themselves
			// After the account is setup we want these fields stil
			// TODO change this to autoCreatedFirstName or find a more elagant way to do this
			const isUserSetup = action.payload.firstName !== "firstName";
			const user =
				action.payload.autoCreated && !isUserSetup
					? {
							...action.payload,
							firstName: null,
							lastName: null,
					  }
					: action.payload;

			return {
				...state,
				user,
			};
		case USERS.SET_USER_ORGANISATION:
			return {
				...state,
				userOrganisation: action.payload,
			};
		case USERS.SET_USER_SUPPORTS_ORGANISATION:
			return {
				...state,
				userSupportsOrganisations: action.payload,
			};
		case USERS.SET_USER_PROCEDURES:
			return {
				...state,
				userProcedures: action.payload,
			};
		case USERS.SET_USERS_SETTINGS:
      const {
        TIME_ZONE,
        EMAIL_NOTIFICATION,
        PREFERRED_LANGUAGE,
        TWO_FACTOR_AUTH_ENABLED
      } = action.userSettings;

			return {
				...state,
				timezone: TIME_ZONE,
				emailNotification: EMAIL_NOTIFICATION,
        preferredLanguage: PREFERRED_LANGUAGE,
        mfaOrgLevelTwoFactor: TWO_FACTOR_AUTH_ENABLED === "true" ? true : false
			};
		case USERS.SET_USERS_EMAIL_SETTINGS:
			return {
				...state,
				timezone: action.userSettings.TIME_ZONE,
				emailNotification: action.userSettings.EMAIL_NOTIFICATION,
			};
		case USERS.SET_USER_PROFILE_INITIAL_VALUES:
			return {
				...state,
				initialValues: action.payload,
			};
		case USERS.SET_ALL_HOSPITALS_USER_APPLIED:
			return {
				...state,
				hospitalApplications: action.payload,
			};
		case USERS.SET_ALL_AVAILABLE_HOSPITALS_TO_APPLY:
			return {
				...state,
				availableHospitalsToApply: action.payload,
			};
		case USERS.SET_ALL_APPROVED_HOSPITALS_FOR_USER:
			return {
				...state,
				approvedHospitalsForUser: action.payload,
			};
		case USERS.SET_USER_MANAGER:
			return {
				...state,
				userManager: action.payload,
			};
		case USERS.SET_ALL_MANAGERS_USER_APPLIED_TO:
			return {
				...state,
				managersUserAppliedTo: action.payload,
			};
		case USERS.SET_ALL_DESCENDANT_USERS_FOR_MANAGER:
			return {
				...state,
				descendantUsers: action.payload,
			};
		case USERS.SET_PASSPHRASE_DATA:
			return {
				...state,
				passphrase: action.payload,
			};
		case USERS.SET_PHONE_NUMBER_DATA:
			return {
				...state,
				phoneNumber: action.payload,
			};

		case USERS.SET_AUTH_ERROR:
			return {
				...state,
				otpError: action.payload,
			};
		case USERS.IS_VALID_PHONE_ADDED:
			return {
				...state,
				validPhone: action.payload,
			};
		case USERS.SET_PHONE_OTP_INFO:
			return {
				...state,
				phoneOtp: action.payload,
			};
		case USERS.PHONE_VERIFICATION_SUCCESS:
			return {
				...state,
				phoneVerified: action.payload,
			};
		default:
			return state;
	}
}
