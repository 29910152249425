import clsx from "clsx";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectLayoutFrames } from "src/domains/Beacon/store/stream/selectors";

import styles from "./styles.scss";

export const Integration = () => {
  const layoutFrames = useAppSelector(selectLayoutFrames);

  return (
    <>
      {Object.keys(layoutFrames).map((key, index) => {
        const iframeLink = layoutFrames[key]?.integrationLink;
        return iframeLink && iframeLink !== "" ? (
          <iframe
            key={`integration-${index}`}
            id={`integration-${key}`}
            data-test-id={`data-test-integration-${key}`}
            src={layoutFrames[key]?.integrationLink}
            className={clsx(styles.iframe, styles[key])}
          />
        ) : null;
      })}
    </>
  );
};
