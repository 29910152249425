import { useState } from "react";

import { DatePicker as MuiDatePicker, DatePickerProps } from "@mui/lab";
import { Grid, Typography, TextField as MuiTextField } from "@mui/material";

import styles from "./styles.scss";

// since we set renderInput internally we dont need anyone to pass it from the outside
interface Props extends Omit<DatePickerProps, "renderInput"> {
  label: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  "data-test-id"?: string;
}

export const DatePicker = ({
  required,
  label,
  error = "",
  disabled = false,
  "data-test-id": dataTestId,
  ...props
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="body1" component={"label"}>
          {label}
          {required ? <span className={styles.required}> *</span> : null}
        </Typography>
      </Grid>
      <Grid item>
        <MuiDatePicker
          {...props}
          open={open}
          disabled={disabled}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              disabled={disabled}
              data-test-id={dataTestId}
              classes={{
                root: styles.textfieldRoot,
              }}
              onClick={() => {
                if (!disabled) {
                  setOpen(true);
                }
              }}
              variant="outlined"
              required={required}
              error={error !== ""}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
