import { MutableRefObject, useEffect, useRef, useState } from "react";

import {
  captureMediaStream,
  stopMediaTracks,
} from "src/domains/Beacon/utils/mediaDevices";
import { useEventListener } from "src/hooks/useEventListener";

import TestAudioMP3 from "../assets/audios/zelda_forest_theme.mp3";
import TestAudioOGG from "../assets/audios/zelda_forest_theme.ogg";

/**
 * Sets the source file based on the type of browser, the source will be '.ogg'
 * if browser can play 'audio/ogg', otherwise it should be able to play .mp3
 */
const setAudioSource = (audioEl: HTMLAudioElement) => {
  if (!audioEl) {
    return;
  }
  if (audioEl.canPlayType("audio/ogg") !== "") {
    audioEl.src = TestAudioOGG;
  } else {
    audioEl.src = TestAudioMP3;
  }
};

export const useTestAudioForChrome = (
  eAudio: MutableRefObject<HTMLAudioElement>
): [() => void, { playing: boolean; frequency: number }] => {
  const playingRef = useRef<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const [frequency, setFrequency] = useState(0);
  const [stream, setStream] = useState<MediaStream>();

  useEffect(() => {
    setAudioSource(eAudio.current);
    return () => {
      // This will stop showing the annoying red recording icon in the browser tab
      stopMediaTracks(stream);
    };
  }, []);

  // Plays or Pauses the audio to test the speakers
  const toggleAudio = () => {
    if (!playing) {
      eAudio.current.currentTime = 0;
      eAudio.current?.play();
    } else {
      eAudio.current.pause();
    }
    playingRef.current = !playing;
    setPlaying(!playing);
  };

  // Catches the stream from the user's microphone
  useEventListener(
    "playing",
    () => {
      const newStream = captureMediaStream(eAudio.current);
      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(newStream);
      const data = new Uint8Array(analyser.frequencyBinCount);
      source.connect(analyser);
      setStream(newStream);

      /**
       * This inner method will act as a loop until the playingRef is false,
       * otherwise the method will keep calculating the pitch's volume and
       * updating the frequency state
       */
      const report = () => {
        analyser.getByteFrequencyData(data);
        if (playingRef.current) {
          const volume = Math.floor((Math.max(...data) / 255) * 100);
          setFrequency(volume);
          requestAnimationFrame(report);
        } else {
          setFrequency(0);
          audioContext.close();
          stopMediaTracks(stream);
        }
      };
      report();
    },
    eAudio.current
  );

  // Triggered when the audioRef is paused
  useEventListener("pause", () => setPlaying(false), eAudio.current);

  // Triggered when the audio playing has ended
  useEventListener("ended", () => setPlaying(false), eAudio.current);

  return [toggleAudio, { playing, frequency }];
};
