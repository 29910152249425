/**
 * Wraps Material UI Button to use Tungsten design system
 */
import clsx from "clsx";

import { Button as MuiButton, ButtonProps } from "@mui/material";

import { Font } from "src/components/Font";
import { Tooltip, Props as TooltipProps } from "src/components/Tooltip";

import styles from "./styles.scss";

// adds new MuiButton.props.variants
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    icon: true;
  }
}

// removing the requirement of having "children" prop
// in the Button use case below we set the children ourselves so
// anyone using Button tooltip props dont need to specify children themselves
type ButtonSpecificTooltipProps = Omit<TooltipProps, "children">;

export interface Props extends ButtonProps {
  "data-test-id"?: string;
  label?: string | number;
  shadow?: boolean;
  variant?: "icon" | ButtonProps["variant"];
  // Indicates if the button in a state of active use. Only used with icon variant
  // EXAMPLE: while telestration is selected, Button is considered "active"
  active?: boolean;
  tooltipProps?: ButtonSpecificTooltipProps;
  className?: string;
  wrapperClassName?: string;
  theme?: "blue" | "red"; // gives background-color
}

export const Button = ({
  active = false,
  "data-test-id": dataTestId,
  disabled = false,
  endIcon,
  label,
  onClick,
  shadow = true,
  size = "medium",
  startIcon,
  variant = "contained",
  tooltipProps,
  className,
  wrapperClassName,
  theme = "blue", // currently only set up for variant icon
  ...props
}: Props) => {
  const isIcon = variant === "icon";

  const ButtonComponent = () => {
    return (
      <MuiButton
        {...props}
        classes={{
          root: clsx(
            styles.root,
            styles[variant],
            styles[`theme-${theme}`],
            className,
            {
              [styles.disabled]: disabled,
              // disabled Button has no shadow
              [styles.shadow]: shadow && !disabled,
              // text variant becomes capitalized on hover unless there is an icon
              [styles.withIcon]: variant === "text" && (startIcon || endIcon),
              // only used with icon variant
              [styles.buttonActive]: isIcon && active,
              [styles.largeButton]: isIcon && size === "large",
              [styles.withLabel]: isIcon && label,
              [styles.singleIcon]: isIcon && !(startIcon && endIcon) && !label,
            }
          ),
        }}
        data-test-id={dataTestId}
        disabled={disabled}
        disableElevation={!shadow}
        endIcon={endIcon}
        onClick={onClick}
        startIcon={startIcon}
        variant={variant}
        size={size}
        role="button" // used for testing icon button without data-test-id
        // Prevents the draggable to be executed before the onClick
        // if the button is inside a draggable component, like the PiP
        onMouseDown={(e) => e.stopPropagation()}
      >
        {label && (
          <span className="button-label">
            <Font
              variant="button"
              data-test-id={dataTestId ? `${dataTestId}-label` : null}
            >
              {label}
            </Font>
          </span>
        )}
        {/* use MUI Icon "circle" for indicator? */}
      </MuiButton>
    );
  };

  // Tooltip only used for icon buttons
  const IconButton = () => {
    // as available colors increase, may been to create a switch/case
    const buttonStateToColor = () => {
      return active ? "blue" : disabled ? "gray" : "black";
    };
    return (
      <Tooltip {...tooltipProps} color={buttonStateToColor()}>
        <span
          data-test-id={dataTestId ? `${dataTestId}-button-wrapper` : null}
          className={wrapperClassName}
        >
          <ButtonComponent />
        </span>
      </Tooltip>
    );
  };

  return variant === "icon" ? <IconButton /> : <ButtonComponent />;
};
