import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TherapiesAndProcedures from '../../../../Components/TherapiesAndProcedures/TherapiesAndProcedures';
import {
	getAllDepartmentsTherapiesRoomIsAssociatedAction,
	getRoomTherapiesAction,
	updateRoomProceduresAction,
} from '../../../DepartmentProfile/DepartmentsActions';
import { getInitialValuesForRoomTherapies } from '../../RoomProfileSelector';

class RoomProcedures extends PureComponent {
	componentDidMount() {
		this.props.getAllDepartmentsTherapiesRoomIsAssociated(this.props.roomId);
		this.props.getRoomTherapies(this.props.roomId);
	}

	onUpdateRoomProcedures = data => this.props.updateRoomProcedures(this.props.roomId, this.formatProcedures(data.procedures));

	formatProcedures = (procedures) => {
		return {
			'ids': procedures ? Object.keys(procedures).filter((key) => {
				if (procedures[key]) {
					return key;
				}
			}) : [],
		};
	};

	render() {
		return (
			<TherapiesAndProcedures
				initialValues={this.props.initialValues}
				therapies={this.props.departmentsTherapies}
				onSubmit={this.onUpdateRoomProcedures}
				procedureName="procedures"
				disabledTherapies
			/>
		);
	}
}

RoomProcedures.propTypes = {};

const mapStateToProps = state => ({
	departmentsTherapies: state.departmentReducer.departmentsTherapies,
	initialValues: getInitialValuesForRoomTherapies(state),
});

const mapDispatchToProps = dispatch => ({
	getAllDepartmentsTherapiesRoomIsAssociated: roomId => dispatch(getAllDepartmentsTherapiesRoomIsAssociatedAction(roomId)),
	getRoomTherapies: roomId => dispatch(getRoomTherapiesAction(roomId)),
	updateRoomProcedures: (roomId, procedures) => dispatch(updateRoomProceduresAction(roomId, procedures)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'roomProceduresForm',
	enableReinitialize: true,
})(RoomProcedures)));
