export function TwilioLogger( twilioVideo )
{
  const { Logger } = twilioVideo;
  const twilio_Logger = Logger.getLogger('twilio-video');
  const originalFactory = twilio_Logger.methodFactory;
  const logLevels = new Set(["SILENT","trace","debug","info","warn","error"]);
  let   ourPrefix = "[TWILIO]";
  let   ourLogLevel = "SILENT";   // Default to no logging

  twilio_Logger.methodFactory = function (methodName, logLevel, loggerName) {
      const method = originalFactory(methodName, logLevel, loggerName);

      return function (datetime, logLevel, component, message, data) {
          method(ourPrefix, datetime, logLevel, component, message, data);
      };
  };

  console.log(`Setting Twilio SDK debug level to: ${ourLogLevel}`)
  twilio_Logger.setLevel(ourLogLevel); 

  // Twilio's logger has a predefined set of logging levels
  //
  this.setLogLevel =function (level) {
    if(logLevels.has(level)){
      ourLogLevel = level;
    } else {
      ourLogLevel = "SILENT"
    }
    twilio_Logger.setLevel(ourLogLevel);
  }

  this.logLevel = function() {
    return ourLogLevel;
  }
}
