import { Controller } from "react-hook-form";

import classNames from "classnames";

import { Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { TextField } from "src/components/legacy/TextField";
import { formatPhoneNumber } from "src/domains/Users/components/UserSecurity/helper";
import { PhoneVerificationModalProps } from "src/domains/Users/components/UserSecurity/types";

import ModalComponent from "avail-web-application/Common/Components/Modal/Modal";

import {
  maxAllowedDigits,
  minAllowedDigits,
  onlyNumbers,
  requiredField,
} from "avail-web-ui/constants/validation";
import DomainConstants from "avail-web-ui/domains/DomainConstants";

import styles from "./styles.scss";

export const PhoneVerificationModal = ({
  formState,
  control,
  openPendingVerification,
  closeVerificationModal,
  handleSubmit,
  verifyPasscode,
  resendNotification,
  userPhoneNumber,
  errorMessage,
  triesRemaining,
}: PhoneVerificationModalProps) => (
  <ModalComponent
    open={openPendingVerification}
    hasActionButtons={false}
    onCancel={closeVerificationModal}
    title={DomainConstants.label.mfaVerifyNumber}
  >
    <form
      className={styles.userVerificationForm}
      onSubmit={handleSubmit(verifyPasscode)}
    >
      <Typography variant="body1" align="left" gutterBottom>
        {DomainConstants.label.mfaSendPasscodeMessage}
      </Typography>
      <Typography variant="h4" align="left" gutterBottom sx={{ mb: 4 }}>
        {formatPhoneNumber(userPhoneNumber)}
      </Typography>
      <Typography variant="subtitle1" align="left" gutterBottom sx={{ mb: 4 }}>
        {DomainConstants.label.mfaResendPasscodeAgain}&nbsp;
        <a
          href="#"
          className={classNames({
            "disable__pointer-events": triesRemaining === 0,
          })}
        >
          <Typography
            variant="subtitle1"
            align="left"
            className={styles.sendAgainButton}
            onClick={(e) => {
              e.preventDefault();
              resendNotification();
            }}
          >
            {DomainConstants.label.mfaSendAgain}
          </Typography>
        </a>
      </Typography>
      <Controller
        name={DomainConstants.key.mfaPasscode}
        control={control}
        rules={{
          ...requiredField(DomainConstants.label.mfaPasscode),
          ...onlyNumbers(),
          ...maxAllowedDigits(DomainConstants.label.mfaPasscode, 6),
          ...minAllowedDigits(DomainConstants.label.mfaPasscode, 6),
        }}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <TextField
                label={DomainConstants.label.mfaPasscode}
                placeholder={DomainConstants.label.mfaEnterPasscode}
                required
                onChange={onChange}
                value={value}
              />
              {error && <span style={{ color: "red" }}>{error.message}</span>}
              {!error && !!errorMessage ? (
                <span style={{ color: "red" }}>{errorMessage}</span>
              ) : (
                !error && <span>{DomainConstants.label.mfaPasscodeValid}</span>
              )}
            </>
          );
        }}
      />
      <Typography
        variant="h6"
        component="div"
        className={styles.actionButtons}
        sx={{ mt: 4 }}
      >
        <Button
          disableRipple
          variant="text"
          onClick={closeVerificationModal}
          className={`${styles.verifyButton} ${styles.singularWidth}`}
        >
          {DomainConstants.label.cancel}
        </Button>
        {/* TODO: move styles later */}
        <Button
          disableRipple
          disabled={
            triesRemaining === 0 || !formState.isValid || formState.isSubmitting
          }
          style={{
            width: "50%",
            backgroundColor: "#3ca65e",
            borderRadius: "2rem",
          }}
          variant="contained"
          type="submit"
          className={`${styles.verifyButton} ${styles.singularWidth}`}
        >
          {DomainConstants.label.mfaVerify}
        </Button>
      </Typography>
    </form>
  </ModalComponent>
);
