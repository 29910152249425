import React from "react";

import clsx from "clsx";

import styles from "./styles.scss";

export interface CardProps {
  children: React.ReactNode;
  className?: string;
  "data-test-id"?: string;
}
/**
 * Card component
 */
export const Card = ({
  children,
  className,
  "data-test-id": dataTestId,
  ...props
}: CardProps) => (
  <div
    data-test-id={dataTestId}
    className={clsx(styles.card, className)}
    {...props}
  >
    {children}
  </div>
);
