import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { Guid } from "guid-typescript";

import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import clsx from "clsx";

import { Collapse } from "@mui/material";

import { Button } from "src/components/Button";
import { HelperText } from "src/components/HelperText";
import { TextField } from "src/components/TextField";
import {
  maxLengthField,
  minLengthField,
  requiredField,
} from "src/constants/validation";
import {
  getPresetCamerasSnapshot,
  isUniquePresetName,
} from "src/domains/Beacon/components/SidePanel/PresetsContent/utils";
import { useAppSelector } from "src/domains/Beacon/store";
import {
  selectCameras,
  selectLayoutFrames,
  selectLayoutType,
  selectZoomState,
} from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { LayoutTypes, Preset } from "src/domains/Beacon/store/stream/types";
import { uiActions } from "src/domains/Beacon/store/ui";
import { UINotificationTypes } from "src/domains/Beacon/store/ui/types";
import { createObjectClone } from "src/domains/Beacon/utils/objects";
import { logger, LoggerLevels } from "src/logging/logger";

import styles from "./styles.scss";

const initialPresetState = {
  name: "",
};

interface IProps {
  formOpen: boolean;
  toggleOpenForm: () => void;
  // In case that the length of 15 presets has been reached, the form will not be seen
  blockCreationForm: boolean;
  // if we want to instead just disable the create button rather than disable the whole form use this
  // also use this with showing some warning message for best effect
  disabledCreateButton?: boolean;
  // very specific warning message related to 3rd party integrations
  // basically we cant the user create new presets if a 3rd party integration is in one of the views
  showIntegrationWarningMessage?: boolean;
  presetsNames: string[];
  showCreateButton?: boolean;
}

export const CreatePresetForm = ({
  formOpen,
  toggleOpenForm,
  blockCreationForm,
  disabledCreateButton = false,
  showIntegrationWarningMessage = false,
  presetsNames,
  showCreateButton = true,
}: IProps) => {
  const currentLayout = useAppSelector(selectLayoutFrames);
  const currentZoomState = useAppSelector(selectZoomState);
  const cameras = useAppSelector(selectCameras);
  const layoutType = useAppSelector(selectLayoutType);
  const dispatch = useDispatch();

  const nameTextfieldRef = useRef(null);

  const setFocus = () => {
    if (nameTextfieldRef.current) {
      nameTextfieldRef.current.focus();
    }
  };

  const { handleSubmit, control, reset, formState } = useForm({
    mode: "onChange",
    defaultValues: initialPresetState,
  });

  // any time the form opens we want to set the focus on the name field right away
  // so the user doesn't have to click on it every time
  useEffect(() => {
    if (formOpen) {
      setFocus();
    }
  }, [formOpen]);

  const handleSavePreset = (data) => {
    logger().info("User has created a new Preset...");
    const snapshotCameras = getPresetCamerasSnapshot(
      cameras as (AVKCamera | AVKExternalInput)[],
      currentLayout
    );

    const filteredSnapshotCameras =
      layoutType === LayoutTypes.TWO_VIEW
        ? snapshotCameras.splice(0, 2)
        : snapshotCameras;

    const preset: Preset = {
      id: Guid.create().toString(), // An internal unique identifier for the preset
      name: data.name.trim(),
      layoutFrames: createObjectClone(currentLayout),
      zoomState: createObjectClone(currentZoomState),
      cameras: filteredSnapshotCameras as (AVKCamera | AVKExternalInput)[],
      // layout type for preset
      presetLayout: layoutType,
    };

    const framedCameraNames: string[] = Object.values(currentLayout).map(
      (layoutFrame) => layoutFrame.cameraLabel
    );
    logger().logWithFields(
      LoggerLevels.info,
      { feature: "Presets" },
      `User created a Preset with cameras: ${framedCameraNames}`
    );

    dispatch(streamActions.addPreset(preset));
    reset();
    toggleOpenForm();

    dispatch(
      uiActions.triggerCustomNotification(
        UINotificationTypes.SUCCESS,
        "New Preset has been saved",
        "mini"
      )
    );
  };

  return (
    <form
      className={styles.container}
      noValidate
      onSubmit={handleSubmit(handleSavePreset)}
      data-test-id="create-preset-form"
    >
      <Collapse in={!formOpen && !blockCreationForm}>
        {showCreateButton && (
          <Button
            data-test-id="create-preset-button"
            className={clsx(styles.button)}
            active
            label="Create New Preset"
            onClick={toggleOpenForm}
            disabled={blockCreationForm || disabledCreateButton}
          />
        )}
        {showIntegrationWarningMessage && (
          <HelperText
            className={styles.integrationWarning}
            data-test-id="preset-integration-warning-message"
            type="warning"
            message={
              "Presets cannot be created while an application stream is being displayed"
            }
            showHelper={true}
          />
        )}
      </Collapse>
      <Collapse in={formOpen && !blockCreationForm}>
        <div className={styles.textfieldContainer}>
          <Controller
            name="name"
            control={control}
            rules={{
              ...minLengthField("Preset name", 3),
              ...maxLengthField("Preset name", 40),
              ...requiredField("Preset name"),
              // Validates that the preset name inserted doesn't exist inside the list
              validate: (presetName: string) =>
                isUniquePresetName(presetsNames, presetName),
            }}
            render={({
              field: { onChange, value, name, ref },
              fieldState: { error },
            }) => {
              return (
                <>
                  <TextField
                    // manually add our own ref alongside react-hook-form so we can set focus
                    // react-hook-form's way of setting focus wasn't working for some reason
                    inputRef={(e) => {
                      ref(e);
                      nameTextfieldRef.current = e; // you can still assign to ref
                    }}
                    data-test-id="preset-name-field"
                    name={name}
                    label="Preset Name"
                    placeholder="Add Preset Name"
                    value={value}
                    onChange={onChange}
                    className={styles.textfield}
                    required
                    error={Boolean(error)}
                    autoComplete="off"
                    // Max is 40 characters but this way the error helper message
                    // can be shown in the input
                    maxLength={41}
                  />
                  <HelperText
                    data-test-id="preset-name-helper"
                    type="error"
                    message={error?.message}
                    showHelper={Boolean(error)}
                  />
                </>
              );
            }}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            data-test-id="save-preset-button"
            className={clsx(styles.button, styles.saveButton)}
            active
            label="Save"
            type="submit"
            disabled={!formState.isValid || formState.isSubmitting}
          />
          <Button
            data-test-id="cancel-preset-button"
            className={clsx(styles.button, styles.cancelButton)}
            label="Cancel"
            onClick={toggleOpenForm}
          />
        </div>
      </Collapse>
    </form>
  );
};
