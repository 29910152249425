export const BRANDS = {
	SET_BRANDS_PAGINATION: 'USERS_SET_BRANDS_PAGINATION',
	GET_ALL_BRANDS: 'BRANDS_GET_ALL_BRANDS',
	SET_ALL_BRANDS: 'BRANDS_SET_ALL_BRANDS',
	GET_CURRENT_BRAND: 'GET_CURRENT_BRAND',
	SET_CURRENT_BRAND: 'SET_CURRENT_BRAND',
	CREATE_NEW_BRAND: 'BRANDS_CREATE_NEW_BRAND',
	UPDATE_BRAND: 'UPDATE_BRAND',
	GET_BRAND_BY_DOMAIN: 'BRANDS_GET_BRAND_BY_DOMAIN',
	DELETE_BRAND: 'BRANDS_DELETE_BRAND',
};
