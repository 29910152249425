import React from "react";
import { MenuOptionType } from "../../../store/models";

interface StateProps {
  onClick: (e: any) => void;
  settings: MenuOptionType;
  currentSelected: string;
  children: React.ReactNode
}

type Props = StateProps;

class MenuItem extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.mouseUp = this.mouseUp.bind(this);
  }

  stateBasedCSS() {
    const defaultCSS = {
      normal: "call-control-menu-item",
      hover: "call-control-menu-item:hover",
      selected: "call-control-menu-item call-control-menu-item-selected",
      disabled: "call-control-menu-item call-control-menu-item-disabled"
    };

    let theCSS = this.props.settings.css
      ? { ...this.props.settings.css }
      : { ...defaultCSS };
    if (this.props.settings.isDisabled) {
      theCSS.normal += " " + theCSS.disabled;
    } else if (this.props.settings.id === this.props.currentSelected) {
      theCSS.normal += " " + theCSS.selected;
    }
    return theCSS.normal;
  }

  mouseUp(e: any) {
    if (this.props.settings.isDisabled) {
      return;
    }
    // should set selected here, but have to send up to level where meeting state is known
    this.props.onClick(e);
  }

  render() {
    return (
      <div
        className={this.stateBasedCSS()}
        onMouseUp={(e: any) => this.mouseUp(e)}
      >
        {this.props.children}
      </div>
    );
  }
}
export default MenuItem;
