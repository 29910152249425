import { Grid, Typography } from "@mui/material";

import styles from "./styles.scss";

interface Props {
  children?: JSX.Element | JSX.Element[] | string; // custom content, the 'description' and 'actionText' will be ignored
  description?: string | JSX.Element; // could be either a description string or custom content
  actionText?: string;
  actionLink?: React.MouseEventHandler<HTMLAnchorElement>;
  show?: boolean;
  type?: "warning-banner" | "error-banner";
  "data-test-id"?: string;
}

export const Banner = ({
  children = null,
  description = "",
  actionText = "",
  actionLink = null,
  show = false,
  type = "warning-banner",
  ...props
}: Props) => {
  if (!show) {
    return null;
  }

  const Wrapper = ({
    children: childrenWrapper,
  }: {
    children: JSX.Element | JSX.Element[] | string;
  }) => (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      data-test-id={props["data-test-id"] ?? "banner"}
      className={`${styles.container} ${styles[type]}`}
    >
      {childrenWrapper}
    </Grid>
  );

  if (children !== null) {
    return <Wrapper>{children}</Wrapper>;
  }

  return (
    <Wrapper>
      <Typography>{description}</Typography>
      <a onClick={actionLink} data-test-id="banner-see-details">
        <Typography className={`${styles.bold} ${styles.link}`}>
          {actionText}
        </Typography>
      </a>
    </Wrapper>
  );
};
