import { NSessionJoinEvent } from "availkit-js/dist/Models/Events/NSessionJoinEvent";
import { NActor } from "availkit-js/dist/Models/NActor";
import { NSession } from "availkit-js/dist/Models/NSession";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { logger } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";
import UserSessionService from "src/services/UserSessionService";

// This is an example of a normal thunk, in contrary to thunks using `createAsyncThunk`. These can be mixed depending on what is wanted.
// `createAsyncThunk` gives us built in actions being fired for pending(loading)/fulfilled/rejected states (this is ideal when calling external APIs).
// Normal thunks just doing internal stuff (in this case we're initializing the twilio-video lib)
export const joinPubnubChannelsThunk = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  try {
    const {
      meeting: { callSid, pubnub_channel },
    } = getState();

    logger().info(`Joining PubNub session channel ${pubnub_channel}`);

    const availKitInstance = AvailKitService.instance;
    const user = UserSessionService.getCachedUserInfo();

    const localActor = new NActor();
    /* This is required to identify if the message is from the same user who has logged from multiple devices */
    localActor.uniqueIdentifier = user.loginId;

    const groupSession = new NSession(callSid);
    groupSession.uniqueIdentifier = callSid;

    const joinUserEvent = new NSessionJoinEvent(pubnub_channel, groupSession);
    joinUserEvent.sender = user.loginId; // same value as store.meeting.joinId
    availKitInstance.eventService.broadcast(joinUserEvent);

    // Must join both channels to listen to every event
    availKitInstance.eventService.join(callSid);
    availKitInstance.eventService.join(pubnub_channel);

    const joinSessionEvent = new NSessionJoinEvent(callSid, groupSession);
    joinSessionEvent.sender = user.loginId; // same value as store.meeting.joinId
    availKitInstance.eventService.broadcast(joinSessionEvent);
  } catch (error: any) {
    logger().error("Error while joining PubNub channels", error?.message);
  }
};
