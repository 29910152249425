import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Fields, validators } from '../../../../../../Common/Components';

class AccountCreationFormUserInfoPart extends PureComponent {
	render() {
		return (
			<div className="account-setup__timezone-wrapper flex-direction--column">
				<div className="account-creation__heading">
					User information
				</div>
				<Fields.InputField
					labelText="First name"
					placeholder="Enter your first name"
					name="user.firstName"
					validate={[validators.required, validators.name, validators.minimumLength2, validators.maximumLength48]}
				/>
				<Fields.InputField
					labelText="Last name"
					placeholder="Enter your last name"
					name="user.lastName"
					validate={[validators.required, validators.name, validators.minimumLength2, validators.maximumLength48]}
				/>
				<Fields.SelectField
					labelText="Timezone"
					placeholder="Select timezone"
					name="userSettings.timezone"
					options={this.props.timezones && this.props.timezones.map(timezone => ({
						key: timezone.id,
						value: timezone.id,
						text: `${timezone.id} (${timezone.offset})`,
					}))}
					validate={validators.required}
				/>
			</div>
		);
	}
}

AccountCreationFormUserInfoPart.defaultProps = {
	timezones: [],
};

AccountCreationFormUserInfoPart.propTypes = {
	timezones: PropTypes.array,
};

const mapStateToProps = state => ({
	timezones: state.accountCreationReducer.timezones,
});

export default connect(mapStateToProps, undefined)(AccountCreationFormUserInfoPart);
