import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";

import { InstructionMemory } from "src/domains/Beacon/components/Easel/constants";
import {
  drawAnnotation,
  rgbToHex,
} from "src/domains/Beacon/components/Easel/helpers";
import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { telestrationActions } from "src/domains/Beacon/store/telestration/telestrationSlice";
import { logger, LoggerLevels } from "src/logging/logger";

export const drawReceivedPointsLiveThunk = (
  annotationEvent: AVKAnnotationEvent
) => (dispatch: AppDispatch, getState: () => RootState) => {
  const {
    telestration: { telestrationHistory, videoDimensions },
  } = getState();

  // Participants draw points received
  try {
    if (annotationEvent.telestrationId !== null) {
      const {
        red,
        green,
        blue,
        backingInstructions,
      } = annotationEvent.annotation;

      const style = rgbToHex(red, green, blue);

      drawAnnotation(style, backingInstructions, videoDimensions);

      const newSet: InstructionMemory = {
        instructions: [...backingInstructions],
        style,
      };

      const newHistory = [...telestrationHistory, newSet];
      dispatch(telestrationActions.setTelestrationHistory(newHistory));
    }
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      {
        feature: "Telestration",
        fileInfo: "drawReceivedPointsLiveThunk.ts",
      },
      "Error happened when drawing received remote annotation",
      error?.message
    );
    throw error;
  }
};
