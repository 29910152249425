import { put, takeLatest, call, select, all } from 'redux-saga/effects';
import ApiHelper from '../../../../../Common/Services/ApiHelper';
import notificationService from '../../../../../Common/Services/notificationService';
import urlService from '../../../../../Common/Services/urlService';
import arrayService from '../../../../../Common/Services/arrayService';
import { HOSPITALS } from './HospitalsActionTypes';
import resources from '../../../../../Common/Constants/resources';
import { DEPARTMENTS } from './Features/DepartmentProfile/DepartmentsActionTypes';

export function* getAllHospitals(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', resources.hospitals, action.payload);
		yield all([
			put({
				type: HOSPITALS.SET_HOSPITALS,
				payload: response.content,
			}),
			put({
				type: HOSPITALS.SET_HOSPITALS_PAGINATION,
				payload: response,
			}),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* getAllHospitalsWithoutPagination() {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', resources.hospitals);
		yield put({
			type: HOSPITALS.SET_ALL_HOSPITALS,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* getHospitalById(action) {
	const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.hospital, action.payload));
	yield put({
		type: HOSPITALS.SET_HOSPITAL,
		payload: response.content,
	});
}

export function* createNewHospital(action) {
	try {
		yield call(ApiHelper.post, 'coreregistry', resources.hospitals, action.payload);
		const size = yield select(state => state.hospitalsReducer.pagination.pageSize);
		const page = yield select(state => state.hospitalsReducer.pagination.page);
		const response = yield call(ApiHelper.get, 'coreregistry', resources.hospitals, {
			size,
			page,
		});

		yield put({
			type: HOSPITALS.SET_HOSPITALS,
			payload: response.content,
		});
		// TODO: Make dynamic with i18n
		yield notificationService.success('You successfully created Facility');
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* deleteHospital(action) {
	try {
		yield call(ApiHelper.remove, 'coreregistry', urlService.formatString(resources.hospital, action.payload));

		const currentHospitalList = yield select(state => state.hospitalsReducer.hospitals);
		const newHospitalList = currentHospitalList.map(hospital => (hospital.id === action.payload ? {
			...hospital,
			active: false,
		} : hospital));

		yield put({
			type: HOSPITALS.SET_HOSPITALS,
			payload: newHospitalList,
		});
		yield notificationService.success('You successfully deleted Facility');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updateHospital(action) {
	try {
		const response = yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.hospital, action.id), action.data);
		yield put({
			type: HOSPITALS.SET_HOSPITAL,
			payload: response.content,
		});

		yield notificationService.success('You successfully updated Facility');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getHospitalRooms(action) {
	const payloadClone = { ...action.payload };
	delete payloadClone.hospitalId;
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.hospitalRooms, action.payload.hospitalId), payloadClone);
		const filteredResponse = arrayService.sortQuery(response.content, payloadClone);
		yield put({
			type: HOSPITALS.SET_HOSPITAL_ROOMS,
			payload: filteredResponse,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createHospitalRoom(action) {
	try {
		const response = yield call(
			ApiHelper.post,
			'coreregistry',
			urlService.formatString(resources.hospitalRooms, action.hospitalId), action.roomData,
		);

		if (action.departments && action.departments.ids) {
			yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.roomDepartments, response.content.id), action.departments);
		}
		yield getHospitalRooms({ payload: { hospitalId: action.hospitalId }});
		yield notificationService.success('You successfully created room');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getAllHospitalAdmins(action) {
	const payloadClone = { type: action.payload.userType, ...action.payload };
	delete payloadClone.hospitalId;
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.hospitalAdmins, action.payload.hospitalId));
		const newResp = response.content.map(resp => ({name: `${resp.employee.firstName} ${resp.employee.lastName}`, type: resp.employee.userTypes[0], ...resp.employee}));
		const filteredResponse = arrayService.sortQuery(newResp, payloadClone);
		yield put({
			type: HOSPITALS.HOSPITAL_SET_ALL_HOSPITAL_ADMINS,
			payload: filteredResponse,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createHospitalUser(action) {
	const userObject = {
		...action.userData,
		userTypes: [action.userData.userType],
		orgId: action.hospitalId,
	};

	try {
		const userResponse = yield call(ApiHelper.post, 'user', resources.users, userObject);

		yield call(
			ApiHelper.put,
			'coreregistry',
			urlService.formatString(resources.employUserInOrganisation, action.hospitalId), { id: userResponse.content.id },
		);
		const hospitalData= {};
		if(action.hospitalId !== null || action.hospitalId !== ""){
    		hospitalData['hospitalId'] = action.hospitalId;
    		hospitalData['page'] = 0;
    		hospitalData['size'] = undefined;
		}
		yield all([
			notificationService.success('You successfully created user'),
			getAllHospitalAdmins({ payload: hospitalData }),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* deleteHospitalRoom(action) {
	const roomId = action.payload;

	try {
		yield call(ApiHelper.remove, 'coreregistry', urlService.formatString(resources.deleteRoom, roomId));

		const currentRoomList = yield select(state => state.hospitalsReducer.hospitalRooms);
		const filteredRoomList = currentRoomList.filter(room => room.id !== roomId);

		yield put({
			type: HOSPITALS.SET_HOSPITAL_ROOMS,
			payload: filteredRoomList,
		});
		yield notificationService.success('You successfully deleted the room.');
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* deleteHospitalDepartment(action) {
	const depId = action.payload;

	try {
		yield call(ApiHelper.remove, 'coreregistry', urlService.formatString(resources.deleteDepartment, depId));

		const currentDepartments = yield select(state => state.departmentReducer.departments);
		const filteredDepartments = currentDepartments.filter(dep => dep.id !== depId);

		yield put({
			type: DEPARTMENTS.SET_DEPARTMENTS_BY_HOSPITAL,
			payload: filteredDepartments,
		});
		yield notificationService.success('You successfully deleted the clinical department.');
	} catch (e) {
		yield notificationService.error(e);
	}
}


function* getHospitalOrgSettingsById(action) {
	const hospitalId = action.payload ? action.payload : action;
	let orgSettings,mfaFreqTypes, mfaFrequency, mfaTwoFactorAuth;
	try {
		orgSettings = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.getOrgSettings, hospitalId)
		);
	
		mfaFreqTypes = yield call(
			ApiHelper.get,
			"coreregistry",
			urlService.formatString(resources.orgMfaFrequency, hospitalId)
		);
	} catch (e) {
		yield notificationService.error(e);
	}
	
	for (let orgSetting of orgSettings.content) {
		if (orgSetting.type === "MFA_REMEMBER_FREQUENCY") {
			mfaFrequency = orgSetting.value;
		}
		if (orgSetting.type === "TWO_FACTOR_AUTH_ENABLED") {
			mfaTwoFactorAuth = orgSetting.value;
		}
	}

	yield all([
		put({
			type: HOSPITALS.SET_ORG_FREQUENCY_TYPES,
			payload: mfaFreqTypes.content.mfaFrequencyTypes
		}),
		put({
			type: HOSPITALS.SET_ORG_REMEMBER_FREQUENCY,
			payload: mfaFrequency
		}),
		put({
			type: HOSPITALS.SET_ORG_TWO_FACTOR_AUTH,
			payload: JSON.parse(mfaTwoFactorAuth)
		})
	]);
}

function* toggleMfaOrgLevelSettings(action){
	const { hospitalId, isTwoFactorEnabled, mfaFrequency } = action.payload;
	try {
		yield call(
			ApiHelper.put,
			"coreregistry",
			urlService.formatString(resources.getOrgSettings, hospitalId), {
				type: "TWO_FACTOR_AUTH_ENABLED",
				value: isTwoFactorEnabled
			}
		);
		yield put({
			type: HOSPITALS.SET_ORG_TWO_FACTOR_AUTH,
			payload: isTwoFactorEnabled
		});

		if (isTwoFactorEnabled) {
			yield call(
				ApiHelper.put,
				"coreregistry",
				urlService.formatString(resources.getOrgSettings, hospitalId), {
					type: "MFA_REMEMBER_FREQUENCY",
					value: mfaFrequency
				}
			);

			yield put({
				type: HOSPITALS.SET_ORG_REMEMBER_FREQUENCY,
				payload: mfaFrequency
			});
		}


	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* hospitalsSaga() {
	yield takeLatest(HOSPITALS.GET_HOSPITALS, getAllHospitals);
	yield takeLatest(HOSPITALS.GET_ALL_HOSPITALS, getAllHospitalsWithoutPagination);
	yield takeLatest(HOSPITALS.GET_HOSPITAL_BY_ID, getHospitalById);
	yield takeLatest(HOSPITALS.CREATE_NEW_HOSPITAL, createNewHospital);
	yield takeLatest(HOSPITALS.DELETE_HOSPITAL, deleteHospital);
	yield takeLatest(HOSPITALS.UPDATE_HOSPITAL, updateHospital);
	yield takeLatest(HOSPITALS.GET_HOSPITAL_ROOMS, getHospitalRooms);
	yield takeLatest(HOSPITALS.CREATE_HOSPITAL_ROOM, createHospitalRoom);
	yield takeLatest(HOSPITALS.HOSPITAL_GET_ALL_HOSPITAL_ADMINS, getAllHospitalAdmins);
	yield takeLatest(HOSPITALS.CREATE_HOSPITAL_USER, createHospitalUser);
	yield takeLatest(HOSPITALS.DELETE_HOSPITAL_ROOM, deleteHospitalRoom);
	yield takeLatest(HOSPITALS.GET_HOSPITAL_ORG_SETTINGS_BY_ID, getHospitalOrgSettingsById);
	yield takeLatest(HOSPITALS.TOGGLE_MFA_ORG_LEVEL_SETTINGS, toggleMfaOrgLevelSettings);
}
