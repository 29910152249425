interface QueryParams {
  [key: string]: string | number;
}

export const getQueryString = (queryParams: QueryParams = {}) => {
  const qs = new URLSearchParams("");

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value === undefined || value === null) {
      return;
    }

    qs.set(key, value.toString());
  });

  return `?${qs.toString()}`;
};
