import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';

import { Fields, Button, validators } from '../../../../Common/Components';
import userSessionService from '../../../../Common/Services/userSesionService';
import { signUpAction } from '../../AuthenticationActions';
import withAuthentication from '../../Components/withAuthentication';
import { reCaptchaWrapper } from 'src/services/ReCaptchaHelper';

import { LoginPage } from "/src/domains/Login";

class SignUp extends React.PureComponent {
	handleLoginFormSubmit = loginData => {
		const acceptedEula = !!userSessionService.getEulaVersion();
		if (acceptedEula) {
			reCaptchaWrapper('signUp', (captchaVersion, captchaToken) => this.props.signUp({
				...loginData,
				captchaToken,
				captchaVersion
			}));
		}
	}

	redirect = () => this.props.history.push('/login');

	render() {
		const { handleSubmit, t } = this.props;

		return (
			<LoginPage className="login">
        <div className="login__subheading">Please create your account</div>
        <form className="login__form" onSubmit={handleSubmit(this.handleLoginFormSubmit)}>
          <Fields.InputField
            labelText={t('common.form.email_own.label')}
            placeholder={t('common.form.email_own.placeholder')}
            name="email"
            validate={[validators.required, validators.email]}
          />
          <Fields.InputField
            labelText={t('common.form.first_name.label')}
            placeholder={t('common.form.first_name.placeholder')}
            name="firstName"
            validate={[validators.required, validators.onlyLetters, validators.minimumLength2, validators.maximumLength48]}
          />
          <Fields.InputField
            labelText={t('common.form.last_name.label')}
            placeholder={t('common.form.last_name.placeholder')}
            name="lastName"
            validate={[validators.required, validators.onlyLetters, validators.minimumLength2, validators.maximumLength48]}
          />
          <div className="justify-content--flex-end">
            <Button
              isGhost
              className="login__button"
              onClick={this.redirect}
            >
              Cancel
            </Button>
            <Button
              isFull
              isGreen
              className="login__button"
              type="submit"
            >
              Sign up to Avail
            </Button>
          </div>
        </form>
			</LoginPage>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	signUp: userData => dispatch(signUpAction(userData)),
});

const SignUpWithTranslation = withTranslation()(SignUp);

export default reduxForm({ form: 'loginForm' })(
	withAuthentication(connect(undefined, mapDispatchToProps)(SignUpWithTranslation)),
);
