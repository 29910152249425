import { RootState } from "src/domains/Beacon/store";

export const selectTelestrationState = (state: RootState) => state.telestration;

export const selectPresenceAnnotations = (state: RootState) =>
  state.telestration.presenceAnnotations;

export const selectIsDrawModeOn = (state: RootState) =>
  state.telestration.isDrawModeOn;

export const selectPenStyle = (state: RootState) => state.telestration.penStyle;
