import "moment-timezone";

import { useState, useEffect } from "react";

import moment from "moment";

import { Grid, Typography } from "@mui/material";

import { Link } from "src/components/legacy/Link";
import { LoadingSpinner } from "src/components/legacy/LoadingSpinner";
import { EVENT_MOMENT_TIME_FORMAT } from "src/domains/Events/EventForm/utils";
import { useGetHospitals } from "src/queries/hospitals";
import { useGetAllTherapies } from "src/queries/therapies";
import { useGetUserTrialUsage } from "src/queries/trialUsage";
import { useGetAllTimezones, useGetUserSettings } from "src/queries/users";
import { User } from "src/services/ApiClient/users";
import UserSessionService from "src/services/UserSessionService";
import { locations } from "src/utils/locations";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

import { EventForm } from "../EventForm";
import styles from "./styles.scss";

const createDefaultValuesObj = (timezone: any, host: User) => {
  // start and end date will be the same below
  const initialDate = moment();

  const startTime = moment()
    .minute(0)
    .second(0)
    .add(1, "hour")
    .format(EVENT_MOMENT_TIME_FORMAT);

  const endTime = moment()
    .minute(0)
    .second(0)
    .add(2, "hour")
    .format(EVENT_MOMENT_TIME_FORMAT);

  return {
    [DomainConstants.key.title]: "",
    [DomainConstants.key.facility]: "",
    [DomainConstants.key.surgeon]: "",

    [DomainConstants.key.therapy]: "",
    [DomainConstants.key.procedure]: "",
    ["CUSTOM_THERAPY_ID"]: "",
    ["CUSTOM_PROCEDURE_ID"]: "",

    [DomainConstants.key.host]: {
      inputValue: "",
      chips: [host],
    },
    [DomainConstants.key.participants]: {
      inputValue: "",
      chips: [],
    },
    [DomainConstants.key.customTags]: {
      inputValue: "",
      chips: [],
    },

    [DomainConstants.key.dateStart]: initialDate,
    [DomainConstants.key.dateEnd]: initialDate,

    [DomainConstants.key.timeStart]: {
      inputValue: startTime,
      selectedValue: {
        value: startTime,
        label: startTime,
      },
    },
    [DomainConstants.key.timeEnd]: {
      inputValue: endTime,
      selectedValue: {
        value: endTime,
        label: endTime,
      },
    },

    [DomainConstants.key.timezone]: timezone,
  };
};

export const EventCreatePage = () => {
  const userInfo = UserSessionService.getCachedUserInfo();

  // getting the full list of all possible timezones on Earth from the backend
  const { data: timeZonesData } = useGetAllTimezones();

  const { data: hospitalsData } = useGetHospitals();

  // getting the logged in user's settings (so we can find their saved timezone)
  const { data: userSettingsData } = useGetUserSettings(userInfo.id);

  const { data: therapiesData } = useGetAllTherapies(userInfo.id);

  // usage data to show notification in case user's usage is exceeding
  const { data: trialUsageData } = useGetUserTrialUsage(userInfo);

  const [initialFormState, setInitialFormState] = useState(null);

  useEffect(() => {
    if (userSettingsData && timeZonesData) {
      const timezone =
        userSettingsData &&
        userSettingsData.content.find(
          (setting) => setting.type === "TIME_ZONE"
        );

      // because the timezones in the Timezone dropdown isn't in the same structure as the user's timezone,
      // we have to find it in the full list of timezones and use that value when setting it in the field in setValue below
      const finalTimezone =
        timeZonesData &&
        timeZonesData.content.find((tz) => tz?.id === timezone?.value);

      // TODO remove this hack once backend updates their side
      // Basically the `userInfo` object doesn't have a isMember flag so when I set the logged in user
      // in the default value it shows up as grey (aka guest user) because we check the isMember flag for the color
      // its safe to always set this to true while we wait for BE because only members can make events
      const userObj: User = {
        ...(userInfo as any),
        isMember: true,
      };
      const defaultValues = createDefaultValuesObj(finalTimezone, userObj);

      setInitialFormState(defaultValues);
    }
    // we have to return the user timezone is the same structure as the timezone data
    // so we can simply set it as the default in the timezone dropdown
  }, [userSettingsData, timeZonesData]);

  return (
    <div className={styles.container}>
      <Link to={locations.events}>{`< Back`}</Link>
      <Typography className={styles.title} variant="h4">
        Create Event
      </Typography>
      {initialFormState ? (
        <EventForm
          mode="CREATE"
          creatorUserId={userInfo.id}
          initialFormState={initialFormState}
          hospitals={hospitalsData ? hospitalsData.content : []}
          timezones={timeZonesData ? timeZonesData.content : []}
          therapies={therapiesData ? therapiesData.content : []}
          usage={trialUsageData ? trialUsageData.content : null}
        />
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingSpinner />
        </Grid>
      )}
    </div>
  );
};
