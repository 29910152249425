import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input, Button } from 'semantic-ui-react';
import { Fields, Icon } from '../../../../Common/Components/index';
import './SearchInput.scss';
import userTypes from '../../../../Common/Constants/userTypes';
import { MEMBER_TYPE_DISPLAY_NAMES } from "src/constants/users";

const statusOptions = {
	CREATED: 'Pending',
	ACTIVE: 'Active',
};

/* 
 * TODO: Convert to functional component `SearchInput` in src/component
 * inline conditionals 👇 look odd, we can find a better way to make `SearchInput` reusable
 */
class SearchInput extends PureComponent {
	state = {
		searchTerm: '',
		selectedName: '',
	};

	onChangeHandler = ({ target }) => {
		if (this.debounce) clearTimeout(this.debounce);
		const { selectedValue } = this.state;

		const searchTerm = target.value ? target.value : '';
		this.setState({ searchTerm });

		this.debounce = setTimeout(() => {
			this.props.onChangeHandler(searchTerm, selectedValue);
		}, 500);
	};

	handleColumnNameChange(value, text) {
		this.setState({ selectedValue: value, selectedName: text });
		this.props.onUpdateSearchTerm(value);

		this.debounce = setTimeout(() => {
			this.props.onChangeHandler(this.state.searchTerm, value);
		}, 500);
	}

	handleOptionChange(value, type) {
		this.props.onChangeHandler(value, type);
	}

	render() {
		const { columns, nameOverride } = this.props;
		const { selectedName } = this.state;
		const keys = Object.keys(userTypes);
    const parsedSelectedName = selectedName.replace(/\s+/g, '').toLocaleLowerCase();
		return (
			<div className="display--flex">
				<AvailDropDown
					title={selectedName || columns[0].title}
					columns={columns}
					nameOverride={nameOverride}
					handleChange={(name, text) => this.handleColumnNameChange(name, text)}
				/>
				{
					parsedSelectedName === 'role' || parsedSelectedName === 'status' || parsedSelectedName === "applicationstatus" || 
          parsedSelectedName === "usertype" ? (
            parsedSelectedName === 'role' ?
                <Fields.SelectField
                  search
                  placeholder="Select role"
                  options={keys.filter(key => !key.includes('DISTRIBUTOR')).map(key => ({
                    key,
                    value: key,
                    text: userTypes[key].title,
                  }))}
                  className="form-field-padding-override"
                  onChange={value => this.handleOptionChange(value, 'userType')}
                  name="userType"
                />
                :
                parsedSelectedName === 'applicationstatus' ? 
                <Fields.SelectField
                search
                placeholder="Select Application Status"
                options={Object.keys(statusOptions).map(key => ({
                  key,
                  value: key,
                  text: statusOptions[key],
                }))}
                className="form-field-padding-override"
                onChange={value => this.handleOptionChange(value, 'applicationStatus')}
                name="userType"
                />
                : 
                parsedSelectedName === 'status' ? 
                <Fields.SelectField
                  search
                  placeholder="Select Status"
                  options={Object.keys(statusOptions).map(key => ({
                    key,
                    value: key,
                    text: statusOptions[key],
                  }))}
                  className="form-field-padding-override"
                  onChange={value => this.handleOptionChange(value, 'userStatus')}
                  name="userType"
                />
                :
                <Fields.SelectField
                  search
                  placeholder="Select Member Type"
                  options={Object.keys(MEMBER_TYPE_DISPLAY_NAMES).map(key => ({
                    key,
                    value: key,
                    text: MEMBER_TYPE_DISPLAY_NAMES[key],
                  }))}
                  className="form-field-padding-override"
                  onChange={value => this.handleOptionChange(value, 'memberType')}
                  name="memberType"
                />
          ) :
							<Input
							className="search__input"
							name="search"
							onChange={this.onChangeHandler}
							placeholder="Search"
							value={this.state.searchTerm}
							icon={(<span className="filter__wrapper"><Icon className="icon-funnel-outline icon--white " /></span>)}
						/>
				}
			</div>
		);
	}
}

const AvailDropDown = ({ title, nameOverride = 'name', columns, handleChange }) => {
	const [options, setOptions] = React.useState([{ value: 0 }]);

	const handleColumnNameChange = (event, elemRef) => {
		const { value } = elemRef;
		let newValue;
		switch (value) {
			case 'name':
				newValue = nameOverride;
				break;
			default:
				newValue = value;
				break;
		}
		const column = columns.filter(c => c.sortValue === newValue)[0];
		handleChange(column.alias || newValue, column.title);
	};

	React.useEffect(() => {
		const newOptions = [];
		columns.forEach((column, index) =>
			!column.excluded && newOptions.push({ key: index, text: column.title, value: column.sortValue === 'firstName' && typeof column.alias === 'undefined' ? 'name' : column.sortValue }));
		setOptions(newOptions);
		handleColumnNameChange(null, { value: newOptions[0].value });
	}, []);

	return (
		<Button.Group color="grey">
			<Button>{title}</Button>
			<Dropdown
				style={{ minWidth: 10 }}
				className="button icon"
				floating
				defaultValue={columns[0].sortValue}
				trigger={<React.Fragment />}
				onChange={(event, elemRef) => handleColumnNameChange(event, elemRef)}
				options={options}
			/>
		</Button.Group>
	);
};

SearchInput.propTypes = {
	onChangeHandler: PropTypes.func.isRequired,
};

export default SearchInput;
