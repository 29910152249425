import { useEffect, useState } from "react";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { getHostUser } from "src/domains/Beacon/utils/twilio";
import { logger } from "src/logging/logger";
import { MultiPartyEvent, Participant } from "src/services/ApiClient/scheduler";
import UserSessionService from "src/services/UserSessionService";

import { UserDetails } from "avail-web-ui/services/ApiClient/Users";

/**
 * This hook works as util to bring the host user due that it's inside the
 * callDetails. Also, in a MP call we need to look for the host, otherwise in a
 * P2P call, the user is the host itself
 */
export const useGetHostUser = (): Participant | UserDetails => {
  const [host, setHost] = useState<Participant | UserDetails>(null);
  const { callDetails, mode } = useAppSelector(selectMeetingState);
  const user = UserSessionService.getCachedUserInfo();

  useEffect(() => {
    try {
      if (mode === CallModes.MP && callDetails) {
        const { participants } = callDetails as MultiPartyEvent;
        const hostFound = getHostUser(participants);
        setHost(hostFound);
      } else if (mode === CallModes.P2P) {
        setHost(user);
      }
    } catch (error: any) {
      logger().error(
        "Error getting the host user from participants list. useGetHostUser",
        error?.message
      );
    }
  }, [callDetails]);

  return host;
};
