import { NConfig, Nurep } from "availkit-js";

import { AppDispatch } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting";
import { getPresenceUUID } from "src/domains/Beacon/utils/availKit";
import { logger } from "src/logging/logger";
import { getRefreshToken } from "src/services/ApiClient/general";
import { AvailKitService } from "src/services/AvailKitService";

// This is an example of a normal thunk, in contrary to thunks using `createAsyncThunk`. These can be mixed depending on what is wanted.
// `createAsyncThunk` gives us built in actions being fired for pending(loading)/fulfilled/rejected states (this is ideal when calling external APIs).
// Normal thunks just doing internal stuff (in this case we're initializing the twilio-video lib)
export const createAvailKitInstanceThunk = () => async (
  dispatch: AppDispatch
) => {
  try {
    logger().info("Setting up PubNub listeners...");

    // In order to initialize Pubnub correctly, we must pass in
    // pubnub keys from our backend
    const {
      pubnub_publ_key,
      pubnub_subs_key,
      pubnub_auth_key,
      pubnub_channel,
    } = await getRefreshToken();

    // set avk keys
    const config = new NConfig();
    config.pubnubPublishKey = pubnub_publ_key;
    config.pubnubSubscribeKey = pubnub_subs_key;
    config.pubnubAuthKey = pubnub_auth_key;

    // TODO: Revisit this. initialize availkit
    const availKit = new Nurep(config, getPresenceUUID());
    // const availKit = AvailKitSingleton.getInstance(config, getPresenceUUID());

    // Listen for different types of events. Currently we only listen for
    // sidebar events
    availKit.eventService.addEventListener(availKit.telephonyService);
    availKit.eventService.addEventListener(availKit.avkAnnotationService);
    availKit.eventService.addEventListener(availKit.cameraDiscoveryService);
    availKit.eventService.addEventListener(
      availKit.avkVideoSourceDiscoveryService
    );
    availKit.eventService.addEventListener(availKit.avkSidebarService);
    availKit.eventService.addEventListener(availKit.avkMediaService);
    availKit.eventService.addEventListener(availKit.avkFreezeFrameService);
    availKit.eventService.addEventListener(availKit.avkSchedulerService);

    AvailKitService.instance = availKit;

    dispatch(meetingActions.setPubNubChannel(pubnub_channel));
    logger().info("PubNub instance created successfully!");
  } catch (error: any) {
    logger().error("Error while creating AvailKit Instance", error?.message);
  }
};
