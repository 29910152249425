import { useQuery } from "react-query";

import { FetchError } from "src/services/ApiClient/ApiQuery";
import {
  getTimeZones,
  getUserList,
  getUserSettings,
  getUsersForAutocomplete,
  getUserById,
  TimeZonesResponse,
  UserListResponse,
  UserSettingsResponse,
  UsersListResponse,
  UserDetailResponse,
} from "src/services/ApiClient/users";

const KEY = "users";

export function useGetAllTimezones(queryKeys?: any) {
  return useQuery<TimeZonesResponse, FetchError>(
    [`${KEY}-timezones`, queryKeys],
    () => {
      return getTimeZones();
    }
  );
}

export function useGetUserSettings(userId: string, queryKeys?: any) {
  return useQuery<UserSettingsResponse, FetchError>(
    [`${KEY}-${userId}-settings`, queryKeys],
    () => {
      return getUserSettings(userId);
    }
  );
}

export function useGetAllUsers(queryKeys?: any) {
  return useQuery<UserListResponse, FetchError>(
    [`${KEY}-useGetAllUsers`, queryKeys],
    () => {
      // TODO allow passing in args when necessary
      return getUserList(0, "all", "name");
    }
  );
}

export function useGetAllUsersForAutocomplete(
  text: string,
  limit = 3,
  queryKeys?: any
) {
  return useQuery<UsersListResponse, FetchError>(
    [`${KEY}-useGetAllUsersForAutocomplete-${text}`, queryKeys],
    () => {
      // we only want to call this endpoint if the user has entered text and has entered
      // at least a few characters (depends on the limit, default is 3 characters)
      if (text && text.length >= limit) {
        return getUsersForAutocomplete(text);
      }
    }
  );
}

export function useGetUserById(userId: string, queryKeys?: any) {
  return useQuery<UserDetailResponse, FetchError>(
    [`${KEY}-useGetUserById`, queryKeys],
    () => {
      if (!userId) {
        return null;
      }

      return getUserById(userId);
    }
  );
}
