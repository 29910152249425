import { AppDispatch } from "src/domains/Beacon/store";
import { getMeetingTokenThunk } from "src/domains/Beacon/store/meeting/thunks/getMeetingTokenThunk";
import { joinCallThunk } from "src/domains/Beacon/store/meeting/thunks/joinCallThunk";
import { getLocalMediaThunk } from "src/domains/Beacon/store/stream/thunks";
import { handleLoadDefaultDevicesThunk } from "src/domains/Beacon/store/stream/thunks/handleLoadDefaultDevicesThunk";
import { logger } from "src/logging/logger";

interface IParams {
  encodedMeetingToken: string;
  // LD flags necessary for the joinCallThunk
  offerDeviceSelection?: boolean;
  enableORTelligence: boolean;
  externalInputsImageResizing: boolean;
}

export const joinCallWithoutPreCallThunk = ({
  encodedMeetingToken,
  // LD flags necessary for the joinCallThunk
  offerDeviceSelection,
  enableORTelligence,
  externalInputsImageResizing,
}: IParams) => async (dispatch: AppDispatch) => {
  try {
    // Must set the call details first using the meeting token
    await dispatch(getMeetingTokenThunk({ encodedMeetingToken }));

    // Get current user's local media (audio/video)
    await dispatch(getLocalMediaThunk());
    await dispatch(handleLoadDefaultDevicesThunk());

    // Will take care of the join Twilio's room process
    await dispatch(
      joinCallThunk({
        offerDeviceSelection,
        enableORTelligence,
        externalInputsImageResizing,
      })
    );
  } catch (error: any) {
    logger().error(
      "There was an error while joining without pre-call screen",
      "User will NOT join the call",
      error.message
    );
  }
};
