import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import ApiHelper from '../../../../Common/Services/ApiHelper';
import resources from '../../../../Common/Constants/resources';
import urlService from '../../../../Common/Services/urlService';
import { DISTRIBUTORS } from './DistributorsActionTypes';
import { notificationService } from '../../../../Common/Services';

function* getAllDistributors(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', resources.distributors, action.payload);
		yield all([
			yield put({
				type: DISTRIBUTORS.SET_ALL_DISTRIBUTORS,
				payload: response.content,
			}),
			put({
				type: DISTRIBUTORS.SET_DISTRIBUTORS_PAGINATION,
				payload: response,
			}),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createNewDistributor(action) {
	try {
		yield call(ApiHelper.post, 'coreregistry', resources.distributors, action.payload);
		const size = yield select(state => state.distributorsReducer.pagination.pageSize);
		const page = yield select(state => state.distributorsReducer.pagination.page);
		const response = yield call(ApiHelper.get, 'coreregistry', resources.distributors, {
			size,
			page,
		});
		yield all([
			put({
				type: DISTRIBUTORS.SET_ALL_DISTRIBUTORS,
				payload: response.content,
			}),
			notificationService.success('You successfully created new distributor'),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* deleteDistributor(action) {
	try {
		yield call(ApiHelper.remove, 'coreregistry', urlService.formatString(resources.distributors, action.payload));
		const currentDistributorList = yield select(state => state.distributorsReducer.distributors);
		const newDistributorList = currentDistributorList.map(distributor => distributor.id === action.payload ? {
			...distributor,
			active: false,
		} : distributor);

		yield put({
			type: DISTRIBUTORS.SET_ALL_DISTRIBUTORS,
			payload: newDistributorList,
		});
		yield notificationService.success('You successfully deleted distributor');
	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* distributorsSaga() {
	yield takeLatest(DISTRIBUTORS.GET_ALL_DISTRIBUTORS, getAllDistributors);
	yield takeLatest(DISTRIBUTORS.CREATE_NEW_DISTRIBUTOR, createNewDistributor);
	yield takeLatest(DISTRIBUTORS.DELETE_DISTRIBUTOR, deleteDistributor);
}
