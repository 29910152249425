import { Nurep } from "availkit-js";
import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";
import { AVKTelestrationClearEvent } from "availkit-js/dist/Models/Events/AVKTelestrationClearEvent";
import { AVKTelestrationEndEvent } from "availkit-js/dist/Models/Events/AVKTelestrationEndEvent";
import { AVKTelestrationEndReportEvent } from "availkit-js/dist/Models/Events/AVKTelestrationEndReportEvent";
import { AVKTelestrationStartEvent } from "availkit-js/dist/Models/Events/AVKTelestrationStartEvent";
import { NAcknowledgement } from "availkit-js/dist/Models/Events/NAcknowledgement";
import { NHighlightLocationEvent } from "availkit-js/dist/Models/Events/NHighlightLocationEvent";
import {
  NAnnotation,
  NAnnotationInstruction,
} from "availkit-js/dist/Models/NAnnotation";
import { NAnnotationPoint } from "availkit-js/dist/Models/NAnnotationPoint";
import { AVKAnnotationService } from "availkit-js/dist/Services/AVKAnnotationService";
import { AVKAnnotationServiceListener } from "availkit-js/dist/Services/Listeners/AVKAnnotationServiceListener";
import { Guid } from "guid-typescript";
import { RemoteVideoTrack } from "twilio-video";

import React from "react";
import { connect } from "react-redux";

import classNames from "classnames";
import { Dispatch } from "redux";

import { logger, LoggerLevels } from "../../common/logger";
import { MAX_ANNOTATION_POINTS, stylusColorMapping } from "../../constants";
import { clearFreezeFrame } from "../../store/image/actions";
import { setMultiPartyTelestrationHistory } from "../../store/meeting/actions";
import {
  AppState,
  MediaStateType,
  TelestrationState,
  TelestrationStylusColor,
} from "../../store/models";
import {
  addHighlightPoint,
  updateTelestrationState,
} from "../../store/pointer/actions";
import { updateDimensions } from "../../store/twilio/actions";
import { LocalOrRemoteMediaTrack } from "../../types";
import "./Canvas.scss";
import {
  denormalizeInstruction,
  getVideoDimensions,
  normalizeInstruction,
  rgbToHex,
  rgbaToHex,
} from "./helpers";

export interface CanvasStateProps {
  availKit: Nurep | null;
  telestration: TelestrationState /* State of the app */;
  telestrationStylusColor: TelestrationStylusColor;
  callSid: string;
  dimensionsNeedUpdate: boolean;
  mediaState: MediaStateType;
  remoteTelestration: AVKAnnotationEvent[];
  loginId: string;
  remoteTracks: LocalOrRemoteMediaTrack[];
  refreshInProgress: boolean;
}

interface InstructionMemory {
  instructions: Array<any>;
  style: string;
}

interface CanvasDispatchProps {
  addHighlightPoint: (highlight: NHighlightLocationEvent) => void;
  clearFreezeFrame: () => void;
  updateTelestrationState: (
    telestration: TelestrationState,
    stylusColor: TelestrationStylusColor
  ) => void;
  updateDimensions: (needsUpdate: boolean) => void;
  setMultiPartyTelestrationHistory: (newState: any) => void;
}

interface CanvasOwnProps {
  width?: number;
  height?: number;
  /* Used to support telestration on canvas element */
  canTelestrate: boolean;
}

type CanvasProps = CanvasStateProps & CanvasDispatchProps & CanvasOwnProps;

export interface CanvasState {
  isTelestrationInProgress: boolean;
  prevX: number;
  prevY: number;
  width: number;
  height: number;
  left: number;
  top: number;
  activeTelestrationId: string | null;
  activeSequence: number;
  telestrationEventsMap: Map<string | null, AVKAnnotationEvent[]> | null;
  telestrationHistory: Array<InstructionMemory>;
  listenerConfigured: boolean;
  totalAnnotations: Map<string | null, number>;
  telestrationRetries: Map<string | null, number>;
}

class Canvas
  extends React.Component<CanvasProps, CanvasState>
  implements AVKAnnotationServiceListener {
  state = {
    isTelestrationInProgress: false,
    prevX: -1,
    prevY: -1,
    width: this.props.width,
    height: this.props.height,
    left: 0,
    top: 0,
    activeTelestrationId: null,
    activeSequence: 0,
    telestrationEventsMap: new Map<string | null, AVKAnnotationEvent[]>(),
    telestrationHistory: [],
    listenerConfigured: false,
    totalAnnotations: new Map<string | null, number>(),
    telestrationRetries: new Map<string | null, number>(),
  };

  backingInstructions!: NAnnotationPoint[];

  canvasRef = React.createRef<HTMLCanvasElement>();

  constructor(props: Readonly<CanvasProps>) {
    super(props);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.backingInstructions = new Array<NAnnotationPoint>();
    this.updateCanvasDimensions = this.updateCanvasDimensions.bind(this);
  }

  onReceiveAcknowledgement(
    annotationService: AVKAnnotationService,
    acknowledgementEvent: NAcknowledgement
  ): void {
    try {
      const { telestrationEventsMap } = this.state;
      if (telestrationEventsMap && telestrationEventsMap.size === 0) {
        return;
      }
      const referencedEventId: string = acknowledgementEvent.referencedEventIdentifier
        .toString()
        .toLowerCase();

      const telestrationIds = Array.from(telestrationEventsMap.keys());
      if (telestrationIds && telestrationIds.length) {
        for (let i = 0; i < telestrationIds.length; i++) {
          const telestrationId = telestrationIds[i];
          const telstrationEvents = telestrationEventsMap.get(telestrationId);
          let eventIndex = -1;
          if (telstrationEvents) {
            for (let i = 0; i < telstrationEvents.length; i++) {
              if (
                telstrationEvents[i] &&
                telstrationEvents[i].uniqueIdentifier === referencedEventId
              ) {
                eventIndex = i;
                break;
              }
            }

            if (eventIndex !== -1) {
              logger().logWithFields(
                LoggerLevels.debug,
                { feature: "Telestration" },
                telstrationEvents[eventIndex].telestrationId +
                  " : Flushing Sequence: " +
                  (telstrationEvents[eventIndex].sequence + 1)
              );
              telstrationEvents.splice(eventIndex, 1);
            }

            telestrationEventsMap.set(telestrationId, telstrationEvents);

            this.setState({ telestrationEventsMap });
            return;
          }
        }
      }
    } catch (e) {
      logger().error(e);
    }
  }

  onAVKTelestrationStart(
    annotationService: AVKAnnotationService,
    event: AVKTelestrationStartEvent
  ): void {}

  onAVKVideoAnnotation(
    annotationService: AVKAnnotationService,
    annotationEvent: AVKAnnotationEvent
  ): void {
    if (annotationEvent.telestrationId !== null) {
      const { red, green, blue } = annotationEvent.annotation;
      const { backingInstructions } = annotationEvent.annotation;
      const style = rgbToHex(red, green, blue);
      const ref = this.canvasRef;

      if (ref && ref.current) {
        /* @ts-ignore */
        this.drawAnnotation(ref.current, style, backingInstructions);
        const newSet: InstructionMemory = {
          instructions: [...backingInstructions],
          /* @ts-ignore */
          style,
        };
        const newHistory = [...this.state.telestrationHistory, newSet];
        this.setState({ telestrationHistory: newHistory });
      }
    }
  }

  onAVKTelestrationEnd(
    annotationService: AVKAnnotationService,
    event: AVKTelestrationEndEvent
  ): void {}

  onAVKTelestrationClearReceived(
    annotationService: AVKAnnotationService,
    event: AVKTelestrationClearEvent
  ): void {
    this.clearLocalAnnotations();
  }

  onReceiveAVKTelestrationEndReport(
    annotationService: AVKAnnotationService,
    event: AVKTelestrationEndReportEvent
  ): void {
    const { availKit } = this.props;
    const {
      telestrationEventsMap,
      totalAnnotations,
      telestrationRetries,
    } = this.state;
    if (!event.telestrationId) {
      return;
    }
    const telestrationId = event.telestrationId.toString().toLowerCase();
    const annotationEvents = telestrationEventsMap.get(
      telestrationId
    ) as AVKAnnotationEvent[];
    let skipResend = false;
    if (!availKit) {
      return;
    }
    if (!annotationEvents) {
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Telestration",
        },
        ": " +
          telestrationId +
          " : All events have been flushed out. Possibly telestration was still active. Skip resend."
      );
      skipResend = true;
    }
    const missingAnnotations = event.missingAnnotations;
    let logMessage = "End Report Received: " + event.telestrationId;

    if (missingAnnotations.length) {
      let logMissingAnnotations: number[] = [...missingAnnotations];
      logMessage += " : Missing Annotations: [";
      logMissingAnnotations.forEach((logMissingAnnotation: number) => {
        const logNumber: number = logMissingAnnotation + 1;
        logMessage += " " + logNumber;
      });
      logMessage += " ]";
      let retry: number | undefined = telestrationRetries.get(telestrationId);
      if (!retry) {
        retry = 1;
      } else {
        retry++;
      }
      if (retry > 3) {
        logMessage += ". Max retries exceeded.";
        skipResend = true;
      } else {
        logMessage += ". Retry Attempt : " + retry;
        telestrationRetries.set(telestrationId, retry);
        this.setState({ telestrationRetries });
      }
    } else {
      skipResend = true;
    }
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Telestration",
      },
      logMessage
    );
    if (skipResend) {
      logger().logWithFields(
        LoggerLevels.debug,
        { feature: "Telestration" },
        " : Flushing all sequences"
      );
      telestrationEventsMap.clear();
      totalAnnotations.delete(telestrationId);
      telestrationRetries.delete(telestrationId);
      this.setState({
        telestrationRetries,
        telestrationEventsMap,
        totalAnnotations,
      });
      return;
    } else {
      /* Send missing events */
      let atleastOneEventResent = false;
      for (let i = 0; i < missingAnnotations.length; i++) {
        logger().logWithFields(
          LoggerLevels.debug,
          {
            feature: "Telestration",
          },
          "Need to resend: " +
            event.telestrationId +
            " : sequence: " +
            (missingAnnotations[i] + 1)
        );

        let currentSequenceResent = false;
        for (let j = 0; j < annotationEvents.length; j++) {
          const annotationEvent = annotationEvents[j];
          logger().logWithFields(
            LoggerLevels.debug,
            {
              feature: "Telestration",
            },
            "Trying to resend: Comparing " +
              event.telestrationId +
              " : sequence: " +
              (missingAnnotations[i] + 1) +
              " against " +
              (annotationEvent.sequence + 1)
          );
          if (annotationEvent.sequence === missingAnnotations[i]) {
            if (annotationEvent) {
              atleastOneEventResent = true;
              currentSequenceResent = true;
              availKit.eventService.broadcast(annotationEvent);
              logger().logWithFields(
                LoggerLevels.info,
                {
                  feature: "Telestration",
                },
                "Annotation Resent. " +
                  event.telestrationId +
                  " : sequence: " +
                  (missingAnnotations[i] + 1)
              );
              break;
            }
          }
        }
        if (!currentSequenceResent) {
          logger().logWithFields(
            LoggerLevels.info,
            {
              feature: "Telestration",
            },
            telestrationId +
              " : Skip Resend : " +
              (missingAnnotations[i] + 1) +
              ". Possibly received during transmission."
          );
        }
      }
      /* If atleast one event was resent, send end of telestration event */
      if (atleastOneEventResent) {
        logger().logWithFields(
          LoggerLevels.debug,
          {
            feature: "Telestration",
          },
          "Preparing to resend End: " + event.telestrationId
        );
        this.sendTelestrationEndEvent();
      }
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { telestration, availKit, callSid } = this.props;
    const { width, height, listenerConfigured } = this.state;

    if (availKit && !listenerConfigured && availKit.avkAnnotationService) {
      availKit.avkAnnotationService.addEventListener(this);
      this.setState({ listenerConfigured: true });
    }

    if (telestration === "clear" && telestration !== prevProps.telestration) {
      const canvas = this.canvasRef.current;
      const context = canvas?.getContext("2d");
      context!.clearRect(0, 0, width, height);

      if (availKit) {
        const teleStrationClearEvent = new AVKTelestrationClearEvent(
          callSid,
          null
        );
        availKit.eventService.broadcast(teleStrationClearEvent);
        logger().logWithFields(
          LoggerLevels.info,
          { feature: "Telestration" },
          "Clear Sent"
        );

        this.setState({ telestrationHistory: [] });

        if (this.props.remoteTelestration.length > 0) {
          this.props.setMultiPartyTelestrationHistory([]);
        }
      }

      /* Reset the previous state of telestration */
      this.props.updateTelestrationState(
        prevProps.telestration,
        prevProps.telestrationStylusColor
      );
    }

    /* Reset canvas dimensions based on incoming video's offset */
    try {
      if (
        this.props.dimensionsNeedUpdate &&
        this.props.dimensionsNeedUpdate !== prevProps.dimensionsNeedUpdate
      ) {
        this.updateCanvasDimensions();
        this.props.updateDimensions(false);
      }
    } catch (error) {
      logger().error("Error while setting canvas dimensions. ignoring...");
    }
  }

  updateCanvasDimensions() {
    logger().info(
      "Updating canvas dimensions. This affects telestration if already drawn"
    );
    const video = document.querySelector(
      ".large-media video"
    ) as HTMLVideoElement;

    /* If video element hasn't been rendered yet, delay the update of canvas dimensions */
    if (!video) {
      return;
    }

    const videoArea = getVideoDimensions(video);

    const { offsetWidth, offsetHeight } = video;

    try {
      const { remoteTracks } = this.props;
      const videoTracks: RemoteVideoTrack[] = remoteTracks.filter(
        (t) => t.kind === "video"
      ) as RemoteVideoTrack[];
      videoTracks.forEach((videoTrack, index) => {
        if (videoTrack.dimensions) {
          logger().info(
            "RemoteVideoTrack#" +
              (index + 1) +
              JSON.stringify(videoTrack.dimensions)
          );
        }
      });
    } catch (e) {
      logger().error("Error while logging RemoteVideoTrack dimensions");
      logger().error(e);
    }

    /* This shall force a re-render and resize the canvas element */
    this.setState(
      {
        width: videoArea.width,
        height: videoArea.height,
        left: (offsetWidth - videoArea.width) / 2,
        top: (offsetHeight - videoArea.height) / 2,
      },
      () => {
        const ref = this.canvasRef;
        [...this.state.telestrationHistory].forEach(
          (teleBlob: InstructionMemory) => {
            ref &&
              ref.current &&
              this.drawAnnotation(
                ref.current,
                teleBlob.style,
                teleBlob.instructions
              );
          }
        );

        this.drawRemoteAnnotations();
      }
    );
  }

  componentDidMount() {
    const { listenerConfigured } = this.state;
    const { availKit } = this.props;
    if (availKit && !listenerConfigured && availKit.avkAnnotationService) {
      availKit.avkAnnotationService.addEventListener(this);
      this.setState({ listenerConfigured: true });
    }

    this.drawRemoteAnnotations();

    // TODO - shouldn't we debounce this?
    window.addEventListener("resize", this.updateCanvasDimensions);
  }

  componentWillUnmount() {
    const { listenerConfigured } = this.state;
    const { availKit } = this.props;
    if (availKit && listenerConfigured) {
      availKit.avkAnnotationService.removeEventListener(this);
    }
    window.removeEventListener("resize", this.updateCanvasDimensions);
  }

  /**
   * Nurep.js event handlers
   */

  onReceiveHighlightEvent = (service: any, event: NHighlightLocationEvent) => {
    try {
      logger().info("A new highlight was received:", JSON.stringify(event));
      const { loginId } = this.props;
      if (event.sender && event.sender === loginId) {
        return;
      }
      const { width } = this.state;
      // mask the highlight event as an NInstruction
      const normalized = denormalizeInstruction(event, { width });
      // Set the proper x and y values
      event.x = normalized.x!;
      event.y = normalized.y!;
      this.props.addHighlightPoint(event);
    } catch (error) {
      logger().warn("Skipping highlight event.", error);
    }
  };

  drawLocalAnnotation(point: any) {
    const canvas = this.canvasRef.current;
    const style = stylusColorMapping[this.props.telestrationStylusColor];
    const width = 2;
    const context = canvas?.getContext("2d");
    if (context) {
      context.beginPath();
      context.lineWidth = width;
      context.strokeStyle = style;
      context.moveTo(this.state.prevX, this.state.prevY);
      context.lineTo(point.x, point.y);
      context.stroke();
      context.closePath();
    }
    this.setState({ prevX: point.x, prevY: point.y });
  }

  // Used only in MP calls
  drawRemoteAnnotations() {
    if (!this.props.remoteTelestration) {
      logger().warn("No telestrations found. Drawing remote telestrations will be skipped ...");
      return;
    }

    this.props.remoteTelestration.forEach(
      (annotationEvent: AVKAnnotationEvent) => {
        /* @ts-ignore */
        annotationEvent!.annotations.forEach((annotation) => {
          const { red, green, blue, alpha } = annotation;
          this.canvasRef.current &&
            this.drawAnnotation(
              this.canvasRef.current,
              rgbaToHex(red, green, blue, alpha),
              annotation.backingInstructions
            );
        });
      }
    );
  }

  clearLocalAnnotations() {
    // TODO add logging
    const { width, height } = this.state;
    const canvas = this.canvasRef.current;
    const context = canvas?.getContext("2d");
    context!.clearRect(0, 0, width, height);
    this.setState({ telestrationHistory: [] });
    this.props.setMultiPartyTelestrationHistory([]);
  }

  /* This code is useful in drawing Annotation during multi-point. */
  drawAnnotation(
    canvas: HTMLCanvasElement,
    style: string, // expecting HEX
    instructions: NAnnotationInstruction[]
  ) {
    const { width } = this.state;
    const context = canvas.getContext("2d");
    [...instructions].forEach((instruction, i) => {
      const nextPoint = [...instructions][i + 1];

      if (nextPoint && context) {
        try {
          const pointFrom = denormalizeInstruction(instruction, {
            width,
          });
          const pointTo = denormalizeInstruction(nextPoint, { width });
          context.beginPath();
          context.lineWidth = instruction.widthFromPoint!;

          context.strokeStyle = style;
          context.moveTo(pointFrom.x!, pointFrom.y!);
          context.lineTo(pointTo.x!, pointTo.y!);
          context.stroke();
        } catch (error) {
          logger().warn("Skipping annotation instructions.", error);
        }
      }
    });
  }

  render() {
    let { width, height, left, top } = this.state;
    const { mediaState } = this.props;

    const divStyle = {
      width: width + "px",
      height: height + "px",
      left: left + "px",
      top: top + "px",
    };

    const annotationClassNames =
      mediaState.console.video === "mute"
        ? classNames("annotation", "annotation-hidden")
        : classNames("annotation");

    return (
      <>
        <canvas
          className={annotationClassNames}
          id="canvas"
          width={width}
          height={height}
          style={divStyle}
          ref={this.canvasRef}
          onMouseLeave={(event) => this.onMouseUp(event)}
          onMouseDown={(event) => this.onMouseDown(event)}
          onMouseUp={(event) => this.onMouseUp(event)}
          onTouchStart={(event) => this.onTouchStart(event)}
          onTouchEnd={(event) => this.onTouchEnd(event)}
        />
      </>
    );
  }

  onTouchEnd(event: React.TouchEvent<HTMLCanvasElement>): void {
    const { telestration } = this.props;
    if (!this.props.canTelestrate) {
      return;
    }
    if (telestration === "on" && this.state.isTelestrationInProgress) {
      event.target.removeEventListener("touchmove", this.onTouchMove);
      if (this.backingInstructions.length > 1) {
        this.sendVideoAnnotationEvent(this.backingInstructions);
        this.backingInstructions.length = 0;
      }
      this.sendTelestrationEndEvent();
    }
  }

  onMouseUp(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
    const { telestration } = this.props;
    if (!this.props.canTelestrate) {
      return;
    }
    if (telestration === "on" && this.state.isTelestrationInProgress) {
      event.target.removeEventListener("mousemove", this.onMouseMove);
      if (this.backingInstructions.length > 1) {
        this.sendVideoAnnotationEvent(this.backingInstructions);
        this.backingInstructions.length = 0;
      }
      this.sendTelestrationEndEvent();
    }
  }

  buildAnnotationPointHelper(anInstruction: any): void {
    const { width, left, top } = this.state;
    const aPoint: NAnnotationPoint = normalizeInstruction(anInstruction, {
      width,
      left,
      top,
    });
    this.backingInstructions.push(aPoint);
  }

  onTouchMove(moveevent: Event): void {
    const touchEvent: React.TouchEvent = (moveevent as unknown) as React.TouchEvent;

    const touches = touchEvent.touches;
    for (let i = 0; i < touches.length; i++) {
      const aTouch = touches.item(i);
      this.helperForTouchOrMouseEvent(aTouch);
    }
  }

  onMouseMove(moveevent: Event): void {
    let anEvent: React.MouseEvent<
      HTMLCanvasElement,
      MouseEvent
    > = (moveevent as unknown) as React.MouseEvent<
      HTMLCanvasElement,
      MouseEvent
    >;

    const { telestration, canTelestrate } = this.props;
    /* Always make sure left button is down while moving */
    if (anEvent.buttons === 1) {
      this.helperForTouchOrMouseEvent(anEvent);
    } else if (
      canTelestrate &&
      telestration === "on" &&
      this.state.isTelestrationInProgress
    ) {
      this.onMouseUp(anEvent);
    }
  }

  helperForTouchOrMouseEvent(aTouch: any) {
    const anInstruction = {
      x: aTouch.pageX - this.state.left,
      y: aTouch.pageY - this.state.top,
    };
    this.drawLocalAnnotation(anInstruction);
    if (aTouch.pageX && aTouch.pageY) {
      this.buildAnnotationPointHelper(anInstruction);
    }

    if (this.backingInstructions.length === MAX_ANNOTATION_POINTS) {
      const points = this.backingInstructions.splice(
        0,
        this.backingInstructions.length
      );
      this.sendVideoAnnotationEvent(points);

      /* Retain the last point */
      this.backingInstructions.push(points[MAX_ANNOTATION_POINTS - 1]);
    }
  }

  sendVideoAnnotationEvent(points: NAnnotationPoint[]) {
    const { availKit, callSid } = this.props;
    const {
      telestrationEventsMap,
      activeTelestrationId,
      activeSequence,
      totalAnnotations,
      telestrationHistory,
    } = this.state;

    const annotation: NAnnotation = new NAnnotation();
    annotation.setRGBA(stylusColorMapping[this.props.telestrationStylusColor]);
    annotation.backingInstructions = points;
    // converting a Guid to string
    const activeTelestrationIdString = activeTelestrationId
      .toString()
      .toLowerCase();

    const annotationEvent = new AVKAnnotationEvent(
      callSid,
      annotation,
      activeTelestrationIdString as any
    );
    annotationEvent.sequence = activeSequence;
    this.setState({ activeSequence: activeSequence + 1 });

    let annotationEvents: AVKAnnotationEvent[] = telestrationEventsMap.get(
      activeTelestrationId
    ) as AVKAnnotationEvent[];

    if (!annotationEvents) {
      annotationEvents = [];
    }

    annotationEvents.push(annotationEvent);
    telestrationEventsMap.set(activeTelestrationId, annotationEvents);
    this.setState({ telestrationEventsMap });

    if (availKit) {
      /* Save the history */
      const newSet: InstructionMemory = {
        instructions: [...points],
        style: stylusColorMapping[this.props.telestrationStylusColor],
      };
      const newHistory = [...telestrationHistory, newSet];
      this.setState({ telestrationHistory: newHistory });

      availKit.eventService.broadcast(annotationEvent);

      totalAnnotations.set(activeTelestrationId, annotationEvent.sequence + 1);

      this.setState({ totalAnnotations });
    }
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Telestration",
      },
      "Annotation Sent: " +
        activeTelestrationId +
        " : sequence  :" +
        (annotationEvent.sequence + 1)
    );
  }

  onTouchStart(event: React.TouchEvent<HTMLCanvasElement>): void {
    const { telestration } = this.props;
    if (!this.props.canTelestrate) {
      return;
    }
    this.backingInstructions.length = 0;
    if (telestration === "on") {
      event.target.addEventListener("touchmove", this.onTouchMove);
      const touches = event.touches;
      for (let i = 0; i < touches.length; i++) {
        const touch = touches.item(i);
        if (i === 0) {
          this.setState({
            isTelestrationInProgress: true,
            prevX: touch.pageX - this.state.left,
            prevY: touch.pageY - this.state.top,
          });
          this.buildAnnotationPointHelper({
            x: touch.pageX - this.state.left,
            y: touch.pageY - this.state.top,
          });

          this.sendTelestrationStartEvent();
        } else {
          this.helperForTouchOrMouseEvent(touch);
        }
      }
    }
  }

  onMouseDown(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
    const { telestration, canTelestrate, refreshInProgress } = this.props;
    if (!canTelestrate || refreshInProgress) {
      return;
    }
    this.backingInstructions.length = 0;
    if (telestration === "on") {
      event.target.addEventListener("mousemove", this.onMouseMove);
      this.setState({
        isTelestrationInProgress: true,
        prevX: event.pageX - this.state.left,
        prevY: event.pageY - this.state.top,
      });

      this.buildAnnotationPointHelper({
        x: event.pageX - this.state.left,
        y: event.pageY - this.state.top,
      });

      this.sendTelestrationStartEvent();
    }
  }

  sendTelestrationStartEvent(): void {
    const { availKit, callSid } = this.props;
    if (availKit) {
      let telestrationId: Guid = Guid.create();
      telestrationId = telestrationId.toString().toLowerCase() as any;
      this.setState({
        activeTelestrationId: telestrationId.toString().toLowerCase(),
        activeSequence: 0,
      });

      const teleStrationStartEvent = new AVKTelestrationStartEvent(
        callSid,
        telestrationId
      );
      availKit.eventService.broadcast(teleStrationStartEvent);
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Telestration",
        },
        "Start Sent: " + telestrationId
      );
    }
  }

  sendTelestrationEndEvent() {
    const { availKit, callSid } = this.props;
    const { activeTelestrationId, totalAnnotations } = this.state;
    if (activeTelestrationId && availKit) {
      const teleStrationEndEvent = new AVKTelestrationEndEvent(
        callSid,
        activeTelestrationId
      );

      let ta = totalAnnotations.get(activeTelestrationId);
      if (!ta) {
        ta = 0;
      }
      teleStrationEndEvent.totalAnnotations = ta;
      availKit.eventService.broadcast(teleStrationEndEvent);
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Telestration",
        },
        "End Sent: " +
          activeTelestrationId +
          " : Total Annotations : " +
          teleStrationEndEvent.totalAnnotations
      );
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  availKit: state.availKit.availKitInstance,
  remoteTracks: state.twilio.remoteTracks,
  telestration: state.pointer.telestration,
  remoteTelestration: state.meeting.telestrationHistory,
  telestrationStylusColor: state.pointer.color,
  callSid: state.meeting.callSid,
  dimensionsNeedUpdate: state.twilio.dimensionsNeedUpdate,
  mediaState: state.user.mediaState,
  loginId: state.user.identity.login_id,
  refreshInProgress: state.user.refreshInProgress,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addHighlightPoint: (highlight: NHighlightLocationEvent) => {
      dispatch(addHighlightPoint(highlight));
    },

    clearFreezeFrame: () => {
      dispatch(clearFreezeFrame());
    },
    updateTelestrationState: (
      telestration: TelestrationState,
      stylusColor: TelestrationStylusColor
    ) => dispatch(updateTelestrationState(telestration, stylusColor)),
    setMultiPartyTelestrationHistory: (newHistory: any) =>
      dispatch(setMultiPartyTelestrationHistory(newHistory)),
    updateDimensions: (needsUpdate: boolean) =>
      dispatch(updateDimensions(needsUpdate)),
  };
};

export default connect<
  CanvasStateProps,
  CanvasDispatchProps,
  CanvasOwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(Canvas);
