import React, { ForwardedRef, forwardRef } from "react";

import clsx from "clsx";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import MicOffIcon from "@mui/icons-material/MicOff";
import RecordVoiceOverOutlined from "@mui/icons-material/RecordVoiceOverOutlined";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import VoiceOverOffIcon from "@mui/icons-material/VoiceOverOff";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { SnackbarCloseReason } from "@mui/material";

import { IconButton } from "src/components/Button";
import { Font } from "src/components/Font";
import {
  UINotificationTypes,
  UINotificationVariants,
} from "src/domains/Beacon/store/ui/types";

import styles from "./styles.scss";

interface Props {
  title: string;
  subtitle?: string;
  // the onClose typing is taken from MUI's snackbar which always wraps any Alerts shown
  onClose: (
    event?: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => void;
  type: UINotificationTypes;
  "data-test-id"?: string;

  variant?: UINotificationVariants;
}

// simple Alert banner, almost always used for notifications, perhaps maybe for big banner errors too
export const Alert = forwardRef(
  (
    {
      title,
      subtitle,
      onClose,
      "data-test-id": dataTestId,
      type,
      variant = "default",
      ...props
    }: Props,
    // this ref is necessary to work with MUI's snackbar component (see NotificationSystem component)
    ref: ForwardedRef<any>
  ) => {
    const isMiniVariant = variant === "mini";
    return (
      <div
        ref={ref}
        className={clsx(styles.root, {
          [styles.mini]: isMiniVariant,
        })}
        data-test-id={dataTestId}
        {...props}
      >
        <div
          className={clsx(styles.icon, {
            [styles.mini]: isMiniVariant,
          })}
        >
          {iconMapToType[type]}
        </div>
        <div className={styles.content}>
          <Font color="light" variant="h3" className={styles.title}>
            {title}
          </Font>
          {subtitle && (
            <Font color="disabled" variant="b1" className={styles.subtitle}>
              {subtitle}
            </Font>
          )}
        </div>
        {onClose && !isMiniVariant && (
          <IconButton
            className={styles.closeButton}
            color="white"
            aria-label="close"
            onClick={onClose}
          >
            <CloseOutlinedIcon />
          </IconButton>
        )}
      </div>
    );
  }
);

// simple map of notification types to the icon we want to render
const iconMapToType = {
  [UINotificationTypes.POOR_NETWORK_CONNECTION]: <SignalCellularAltIcon />,
  [UINotificationTypes.LOCAL_MIC_MUTED]: <MicOffIcon />,
  [UINotificationTypes.CONSOLE_AUDIO_MUTED]: <VolumeOffIcon />,
  [UINotificationTypes.CONSOLE_AUDIO_UNMUTED]: <VolumeUpIcon />,
  [UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BUILT_IN_MIC]: (
    <VolumeUpIcon />
  ),
  [UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BLUETOOTH]: <VolumeUpIcon />,
  [UINotificationTypes.CONSOLE_NOISE_REDUCTION_ON]: <VoiceOverOffIcon />, // TODO add custom icon for noise reduction
  [UINotificationTypes.CONSOLE_NOISE_REDUCTION_OFF]: <VoiceOverOffIcon />,
  [UINotificationTypes.SUCCESS]: <CheckCircleIcon />,
  [UINotificationTypes.DELETE]: <DeleteIcon />,
  [UINotificationTypes.ERROR]: <ErrorIcon />,
  [UINotificationTypes.SIDEBAR_MODE_ON]: <RecordVoiceOverOutlined />,
  [UINotificationTypes.SIDEBAR_MODE_OFF]: <RecordVoiceOverOutlined />,
  [UINotificationTypes.HOST_MUTES_PARTICIPANT]: <MicOffIcon />,
};
