import { AVKCamera, NCameraType } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { AVKVideoSourceAnnouncementEvent } from "availkit-js/dist/Models/Events/AVKVideoSourceAnnouncement";
import { AVKVideoSourceDiscoveryService } from "availkit-js/dist/Services/AVKVideoSourceDiscoveryService";
import { AVKVideoSourceDiscoveryServiceListener } from "availkit-js/dist/Services/Listeners/AVKVideoSourceDiscoveryServiceListener";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectThirdPartyIntegration } from "src/domains/Beacon/store/meeting/selectors";
import { selectConsoleHasExternalInputResizing } from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleLayoutChangeThunk } from "src/domains/Beacon/store/stream/thunks";
import {
  DefaultCameraPosition,
  LayoutFrames,
  ZoomFrames,
} from "src/domains/Beacon/store/stream/types";
import {
  camerasSortedByName,
  getCameraDisplayName,
  getCameraIdentifier,
  videoSourceZoomLevel,
} from "src/domains/Beacon/utils/availKit";
import { FEATURE_FLAGS } from "src/featureFlags";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

// AvailKit takes care of sending and receiving all the events being passed
// in and out of the pubnub channel we are in, we have to set up the individual
// listeners (functions) that respond to certain types of events

// In this case, we listen for events being retrieved by the sidebarService
// for reference, check `AVKSidebarService` on `availkit-js` project.

export const useDidDiscoverVideoSources = () => {
  const dispatch = useDispatch();
  const availKit = AvailKitService.instance;
  const consoleHasExternalInputResizing = useAppSelector(
    selectConsoleHasExternalInputResizing
  );
  const { externalInputsImageResizing } = useFeatureFlags();
  const { enableORTelligence } = FEATURE_FLAGS;
  const thirdPartyIntegration = useAppSelector(selectThirdPartyIntegration);

  const hasExternalInputResizing =
    consoleHasExternalInputResizing && externalInputsImageResizing;

  // will be executed whenever the didDiscoverVideoSources is triggered/called
  const didDiscoverVideoSources = (
    service: AVKVideoSourceDiscoveryService,
    event: AVKVideoSourceAnnouncementEvent
  ) => {
    try {
      logger().info("didDiscoverVideoSources");

      const avkSources = [...event.cameras, ...event.externalInputs] as (
        | AVKCamera
        | AVKExternalInput
      )[];

      if (enableORTelligence) {
        thirdPartyIntegration?.map((integration) => {
          // Must add integrations by checking if enabled only
          if (integration.enabled) {
            const ortIFrame: AVKCamera = new AVKCamera();
            ortIFrame.cameraIdentifier = integration.partner;
            ortIFrame.name = integration.partner;
            ortIFrame.type = NCameraType.DigitalInput;
            avkSources.push(ortIFrame);
          }
        });
      }

      const sortedCameras = camerasSortedByName(avkSources);

      dispatch(streamActions.setCameras(avkSources));

      const defaultLeftTopCamera = sortedCameras[0];
      const defaultRightTopCamera = sortedCameras[1];
      const defaultLeftBottomCamera = sortedCameras[2];
      const defaultRightBottomCamera = sortedCameras[3];

      // set default camera positions
      const avkCameras = [...event.cameras];
      const cameraPositions: DefaultCameraPosition = Object.fromEntries(
        avkCameras.map(({ name, location }) => [
          name.toLowerCase(),
          {
            x: location.x,
            y: location.y,
          },
        ])
      );

      dispatch(streamActions.setDefaultCameraPositions(cameraPositions));

      const layoutFrames: LayoutFrames = {
        leftTop: {
          cameraId: getCameraIdentifier(defaultLeftTopCamera),
          cameraLabel: getCameraDisplayName(defaultLeftTopCamera),
          isFullScreen: false,
        },
        rightTop: {
          cameraId: getCameraIdentifier(defaultRightTopCamera),
          cameraLabel: getCameraDisplayName(defaultRightTopCamera),
          isFullScreen: false,
        },
        leftBottom: {
          cameraId: getCameraIdentifier(defaultLeftBottomCamera),
          cameraLabel: getCameraDisplayName(defaultLeftBottomCamera),
          isFullScreen: false,
        },
        rightBottom: {
          cameraId: getCameraIdentifier(defaultRightBottomCamera),
          cameraLabel: getCameraDisplayName(defaultRightBottomCamera),
          isFullScreen: false,
        },
      };
      dispatch(streamActions.setLayoutFrames(layoutFrames));

      dispatch(
        handleLayoutChangeThunk({
          externalInputsImageResizing,
          newLayoutFrames: layoutFrames,
        })
      );
      // set up zoomState
      const localZoomState: ZoomFrames = {
        leftTop: {
          cameraId: getCameraIdentifier(defaultLeftTopCamera),
          value: videoSourceZoomLevel(defaultLeftTopCamera),
          settings: {
            start: videoSourceZoomLevel(defaultLeftTopCamera),
            min: 0,
            max: 10,
            step: 1,
          },
        },
        rightTop: {
          cameraId: getCameraIdentifier(defaultRightTopCamera),
          value: videoSourceZoomLevel(defaultRightTopCamera),
          settings: {
            start: videoSourceZoomLevel(defaultRightTopCamera),
            min: 0,
            max: 10,
            step: 1,
          },
        },
        leftBottom: {
          cameraId: getCameraIdentifier(defaultLeftBottomCamera),
          value: videoSourceZoomLevel(defaultLeftBottomCamera) * 10,
          settings: {
            start: videoSourceZoomLevel(defaultLeftBottomCamera),
            min: 0,
            max: 10,
            step: 1,
          },
        },
        rightBottom: {
          cameraId: getCameraIdentifier(defaultRightBottomCamera),
          value: videoSourceZoomLevel(defaultRightBottomCamera) * 10,
          settings: {
            start: videoSourceZoomLevel(defaultRightBottomCamera),
            min: 0,
            max: 10,
            step: 1,
          },
        },
      };
      dispatch(streamActions.setZoomState(localZoomState));
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useDidDiscoverVideoSources",
        },
        "Error while receiving didDiscoverVideoSources.",
        error?.message
      );
    }
  };

  useEffect(() => {
    const videoSourceDiscoveryListener: AVKVideoSourceDiscoveryServiceListener = {
      didDiscoverVideoSources,
    };

    availKit?.avkVideoSourceDiscoveryService.addEventListener(
      videoSourceDiscoveryListener
    );

    // When this is unmounted, remove the listeners
    return () =>
      availKit?.avkVideoSourceDiscoveryService.removeEventListener(
        videoSourceDiscoveryListener
      );
  }, [availKit, hasExternalInputResizing, thirdPartyIntegration]);
};
