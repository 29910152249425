import { put, takeLatest, call, select } from 'redux-saga/effects';

import { SCHEDULER } from './SchedulerActionTypes';
import ApiHelper from '../../../../Common/Services/ApiHelper';
import resources from '../../../../Common/Constants/resources';
import { urlService, notificationService } from '../../../../Common/Services';
import {
	createEventObject,
	convertToCalendarEvents,
	convertMeetingToCalendarEvents,
	convertCallEventsToCalendarEvents,
} from './EventObjectBuilder';

function* createCalendarEvents(action) {
	const { event, selectedDate, isRecurring, userIdFromUrl, timeZone } = action;
	const userId = userIdFromUrl || (yield select(state => state.homeReducer.currentUser.id));

	try {
		const response = yield call(ApiHelper.post, 'scheduler', urlService.formatString(resources.userSchedule, userId), createEventObject({
			event,
			selectedDate,
			isRecurring,
			timeZone,
		}));

		const currentUserEventsList = yield select(state => state.schedulerReducer.userEvents);

		const newUserEventsList = currentUserEventsList.concat(convertToCalendarEvents(response.content));

		yield put({
			type: SCHEDULER.SET_USER_SCHEDULE,
			payload: newUserEventsList,
		});

		yield notificationService.success(`You successfully updated ${!userIdFromUrl ? 'your' : ''} schedule`);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createCallEvents(action) {
	// event = formdata/payload
	const { event, selectedDate, userIdFromUrl, timeZone } = action;
	const userId = userIdFromUrl || (yield select(state => state.homeReducer.currentUser.id));

	try {
		const response = yield ApiHelper.post('scheduler', urlService.formatString(resources.createEvent), event);

		const currentCallEventsList = yield select(state => state.schedulerReducer.userCallEvents);
		const newCallEventsList = [...currentCallEventsList, ...convertCallEventsToCalendarEvents([response.content])];

		yield put({
			type: SCHEDULER.SET_USER_CALL_SCHEDULE,
			payload: newCallEventsList,
		});

		yield notificationService.success(`You successfully updated ${!userIdFromUrl ? 'your' : ''} schedule`);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updateSelectedEvent(action) {
	const { event, eventId, selectedDate, isRecurring, userIdFromUrl, timeZone } = action;
	const userId = userIdFromUrl || (yield select(state => state.homeReducer.currentUser.id));

	try {
		const response = yield call(ApiHelper.put, 'scheduler', `schedules/${eventId}/users/${userId}`, createEventObject({
			event,
			selectedDate,
			isRecurring,
			timeZone,
		}));

		let newUserEventsList;
		const currentUserEventsList = yield select(state => state.schedulerReducer.userEvents);

		if (isRecurring) {
			const allScheduleResponse = yield call(ApiHelper.get, 'scheduler', urlService.formatString(resources.userSchedule, userId));
			newUserEventsList = convertToCalendarEvents(allScheduleResponse.content);
		} else {
			newUserEventsList = currentUserEventsList.map(event => (event.id === response.content[0].scheduleId ?
				{
					...event,
					start: response.content[0].scheduledStartDate,
					end: response.content[0].scheduledEndDate,
					groupId: response.content[0].groupId,
					hospitalIds: response.content[0].hospitalIds,
				} :
				event));
		}

		yield put({
			type: SCHEDULER.SET_USER_SCHEDULE,
			payload: newUserEventsList,
		});

		yield notificationService.success(`You successfully edited ${!userIdFromUrl ? 'your' : ''} schedule`);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updateSelectedCallEvent(action) {
	const { event, userIdFromUrl } = action.payload;

	try {
		const response = yield ApiHelper.put('scheduler', urlService.formatString(resources.event, event.id), event);

		const currentUserEventsList = yield select(state => state.schedulerReducer.userCallEvents);

		const newUserEventsList = currentUserEventsList.map(ev => (ev.id === response.content.eventId
			? convertCallEventsToCalendarEvents([response.content])[0]
			: event
		));

		yield put({
			type: SCHEDULER.SET_USER_CALL_SCHEDULE,
			payload: newUserEventsList,
		});

		yield notificationService.success(`You successfully edited ${!userIdFromUrl ? 'your' : ''} schedule`);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getUserSchedule(action) {
	const userId = action.payload ? action.payload : yield select(state => state.homeReducer.currentUser.id);
	try {
		const response = yield call(ApiHelper.get, 'scheduler', urlService.formatString(resources.userSchedule, userId));

		yield put({
			type: SCHEDULER.SET_USER_SCHEDULE,
			// payload: convertToCalendarEvents([...response.content, ...callsResponse.content]),
			payload: convertToCalendarEvents([...response.content]),
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

// GET /scheduler/events/users/{userId}?fromDate=YYYY-MM-DD&toDate=YYYY-MM-DD&timeZone=xxx
function* getUserEventSchedule(action) {
	const userId = action.payload.userId ? action.payload.userId : yield select(state => state.homeReducer.currentUser.id);

	try {
		const response = yield call(
			ApiHelper.get,
			'scheduler',
			`${urlService.formatString(resources.userEvents, userId)}?fromDate=${encodeURIComponent(action.payload.fromDate)}&toDate=${encodeURIComponent(action.payload.toDate)}&timeZone=${encodeURIComponent(action.payload.timeZone)}`,
		);
		yield put({
			type: SCHEDULER.SET_USER_CALL_SCHEDULE,
			payload: convertCallEventsToCalendarEvents([...response.content]),
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getUserMeetingTime(action) {
	const userId = action.payload ? action.payload.userId : yield select(state => state.homeReducer.currentUser.id);

	try {
		const response = yield call(
      ApiHelper.get, 'billing', 
      `${urlService.formatString(resources.meetingTime, userId)}?from=${action.payload.from}&to=${action.payload.to}`
    );

		yield put({
			type: SCHEDULER.SET_USER_MEETING_TIME,
			payload: convertMeetingToCalendarEvents(response.content),
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getUserMissedCalls(action) {
	const userId = action.payload ? action.payload.userId : yield select(state => state.homeReducer.currentUser.id);

	try {
		const response = yield call(
      ApiHelper.get, 'billing', 
      `${urlService.formatString(resources.meetingTime, userId)}?from=${action.payload.from}&to=${action.payload.to}&page=0&states=MISSED&sort=callDateOrCallStartDate,desc`
    );

		yield put({
			type: SCHEDULER.SET_USER_MISSED_CALLS,
			payload: convertMeetingToCalendarEvents(response.content),
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* deleteRecurringEvent(action) {
	try {
		const response = yield call(ApiHelper.remove, 'scheduler', urlService.formatString(resources.deleteRecurringEvent, action.recurringGroupId));
		const currentUserEventsList = yield select(state => state.schedulerReducer.userEvents);
		const newUserEventsList = currentUserEventsList.filter(oldEvent => oldEvent.groupId !== response.content[0].groupId);

		yield put({
			type: SCHEDULER.DELETE_RECURRING_EVENT_SUCCESS,
			payload: newUserEventsList,
		});
		yield notificationService.success('You successfully deleted availability');
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* deleteSelectedEvent(action) {
	if (action.recurringGroupId !== 'null') {
		yield deleteRecurringEvent(action);
	} else {
		try {
			const response = yield call(ApiHelper.remove, 'scheduler', urlService.formatString(resources.deleteEvent, action.eventId));
			yield put({
				type: SCHEDULER.DELETE_SELECTED_EVENT_SUCCESS,
				payload: response.content.scheduleId,
			});
			yield notificationService.success('You successfully deleted availability');
		} catch (e) {
			yield notificationService.error(e);
		}
	}
}

function* deleteCallEvent(action) {
	try {
		const response = yield call(ApiHelper.remove, 'scheduler', urlService.formatString(resources.event, action.payload.eventId));
		yield put({
			type: SCHEDULER.DELETE_SELECTED_CALL_EVENT_SUCCESS,
			payload: response.content.eventId,
		});
		yield notificationService.success('You successfully deleted availability');
	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* hospitalsSaga() {
	yield takeLatest(SCHEDULER.CREATE_CALENDAR_EVENTS, createCalendarEvents);
	yield takeLatest(SCHEDULER.CREATE_CALL_EVENTS, createCallEvents);
	yield takeLatest(SCHEDULER.UPDATE_SELECTED_EVENT, updateSelectedEvent);
	yield takeLatest(SCHEDULER.UPDATE_SELECTED_CALL_EVENT, updateSelectedCallEvent);
	yield takeLatest(SCHEDULER.GET_USER_SCHEDULE, getUserSchedule);
	yield takeLatest(SCHEDULER.GET_USER_CALL_SCHEDULE, getUserEventSchedule);
	yield takeLatest(SCHEDULER.GET_USER_MEETING_TIME, getUserMeetingTime);
	yield takeLatest(SCHEDULER.GET_USER_MISSED_CALLS, getUserMissedCalls);
	yield takeLatest(SCHEDULER.DELETE_SELECTED_EVENT, deleteSelectedEvent);
	yield takeLatest(SCHEDULER.DELETE_SELECTED_CALL_EVENT, deleteCallEvent);
}
