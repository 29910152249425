import { Nurep } from "availkit-js";
import { NHighlightLocationEvent } from "availkit-js/dist/Models/Events/NHighlightLocationEvent";
import { NActor } from "availkit-js/dist/Models/NActor";
import { NAnnotationPoint } from "availkit-js/dist/Models/NAnnotationPoint";
import { NChannel } from "availkit-js/dist/Models/NChannel";

import React from "react";
import { connect } from "react-redux";

import { Dispatch } from "redux";

import { isSafari } from "src/utils/browsers";

import { logger } from "../../common/logger";
import { MULTI_PARTY_LAUNCH_MODE, P2P_LAUNCH_MODE } from "../../constants";
import {
  AppState,
  CallModeType,
  FrozenFrameInfo,
  ImageState,
  MediaStateType,
  UserRoleType
} from "../../store/models";
import { addHighlightPoint } from "../../store/pointer/actions";
import Canvas from "../Canvas";
import {
  getTelestrationLineThickness,
  getVideoDimensions,
  normalizeInstruction
} from "../Canvas/helpers";
import Pointer from "../Pointer";
import "./LargeMedia.scss";

export interface LargeMediaProps {
  freezeFrame: string;
  imageFrozen: boolean;
  children: React.ReactNode;
  actor: NActor;
  callSid: string;
  highlights: Array<NHighlightLocationEvent>;
  availKit: Nurep | null;
  mediaState: MediaStateType;
  frozenFrames: Set<FrozenFrameInfo>;
  imageState: ImageState;
  mode: CallModeType;
  loginId: string;
  currentUserRole: UserRoleType | null;
}

interface LargeMediaDispatchProps {
  addHighlightPoint: (highlight: NHighlightLocationEvent) => void;
}

type Props = LargeMediaProps & LargeMediaDispatchProps;

export interface LargeMediaState {
  largeMediaBounds?: {
    width: number;
    height: number;
  };
  touchPointsForLaserPointer: Array<React.Touch>;
  mousePointForLaserPointer: { x: number; y: number };
  isVideoStreaming: boolean;
}

class LargeMedia extends React.Component<Props, LargeMediaState> {
  constructor(props: Props) {
    super(props);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.showLaserPoint = this.showLaserPoint.bind(this);
  }

  state = {
    largeMediaBounds: {
      width: 0,
      height: 0
    },
    touchPointsForLaserPointer: [] as Array<React.Touch>,
    mousePointForLaserPointer: {
      x: -1,
      y: -1
    },
    isVideoStreaming: false
  };
  containerRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.setLargeMediaBounds(); // run at least once
    window.addEventListener("resize", this.setLargeMediaBounds);
  }

  componentDidUpdate(prevProps: LargeMediaProps, prevState: LargeMediaState) {
    const { freezeFrame, mode } = this.props;
    const { current } = this.containerRef;

    if (!this.state.isVideoStreaming) {
      // Adding here because waiting on container ref instance (current) to be init
      const video = this.containerRef.current?.querySelector("video");
      video?.addEventListener("playing", this.videoIsStreaming);
    }

    if (current && freezeFrame) {
      // TODO: Is there a way to do this without accessing DOM?
      const video = current.querySelector("video");
      if (video) {
        if (mode === P2P_LAUNCH_MODE) {
          current.style.backgroundImage = `url(data:image/jpeg;base64,${freezeFrame}`;
        }

        // return early as Safari has a bug with propogating events from hidden elements
        // this is meant as a temporary solution
        if (isSafari(window.navigator.userAgent)) return;

        current.querySelector("video")!.style.visibility = "hidden";
      }
    } else if (current?.querySelector("video")) {
      current.querySelector("video")!.style.visibility = "visible";
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setLargeMediaBounds);
  }

  videoIsStreaming = () => {
    const { freezeFrame } = this.props;
    const { current } = this.containerRef;

    console.log("Video streaming has started");
    this.setState({ isVideoStreaming: true });
    const video = this.containerRef?.current?.querySelector("video");
    // For now we're only looking for the initial signal to render canvas
    video?.removeEventListener("playing", this.videoIsStreaming);

    // We only set this to hidden for Safari because if it's any other browser we would hide it in componentDidUpdate,
    // which should be called first. We delay setting the video element to work around Safari issue with not propogating
    // errors from hidden elements
    if (current && freezeFrame && isSafari(window.navigator.userAgent)) {
      current.querySelector("video")!.style.visibility = "hidden";
    }
  };

  setLargeMediaBounds = (): void => {
    const ref = this.containerRef;
    if (ref && ref.current) {
      const { width, height } = ref.current.getBoundingClientRect();
      this.setState({
        largeMediaBounds: { width, height }
      });
    }
  };

  isConsoleVideoMuted(): boolean {
    const { mediaState } = this.props;
    return mediaState.console.video === "mute";
  }

  onTouchStart(event: React.TouchEvent<HTMLDivElement>): void {
    if (this.isConsoleVideoMuted()) {
      return;
    }
    const touches = event.touches;
    const touchesArray = [];
    for (let i = 0; i < touches.length; i++) {
      touchesArray.push(touches.item(i));
    }
    this.setState({ touchPointsForLaserPointer: touchesArray });
  }

  onTouchEnd(event: React.TouchEvent<HTMLDivElement>): void {
    if (this.isConsoleVideoMuted()) {
      return;
    }
    const { touchPointsForLaserPointer } = this.state;
    const touches = event.touches;

    let enableLaserPointer = true;
    for (let i = 0; i < touches.length; i++) {
      if (
        touchPointsForLaserPointer[i].pageX !== touches[i].pageX ||
        touchPointsForLaserPointer[i].pageY !== touches[i].pageY
      ) {
        enableLaserPointer = false;
        break;
      }
    }
    if (enableLaserPointer) {
      this.showLaserPoint(touches[0].pageX, touches[0].pageY);

      /* Reset touchpoints */
      this.setState({ touchPointsForLaserPointer: [] as Array<React.Touch> });
    }
  }

  onMouseUp(event: React.MouseEvent) {
    if (this.isConsoleVideoMuted()) {
      return;
    }
    const { mousePointForLaserPointer } = this.state;
    if (
      mousePointForLaserPointer.x === event.pageX &&
      mousePointForLaserPointer.y === event.pageY
    ) {
      this.showLaserPoint(event.pageX, event.pageY);
      /* Reset mousePoint */
      this.setState({ mousePointForLaserPointer: { x: -1, y: -1 } });
    }
  }

  onMouseDown(event: React.MouseEvent) {
    if (this.isConsoleVideoMuted()) {
      return;
    }
    this.setState({
      mousePointForLaserPointer: { x: event.pageX, y: event.pageY }
    });
  }

  showLaserPoint(pageX: number, pageY: number) {
    if (!this.state.isVideoStreaming) {
      logger().info("Laser pointer disabled until video stream begins.");
      return;
    }

    const {
      callSid,
      availKit,
      actor,
      addHighlightPoint,
      loginId,
      currentUserRole,
      mode
    } = this.props;

    if (mode === MULTI_PARTY_LAUNCH_MODE && currentUserRole !== "HOST") {
      return;
    }

    const rawX = pageX - 16;
    const rawY = pageY - 16;

    const sessionIdChannel = NChannel.channelWithIdentifier(callSid);
    const aHighLightEventLocal: NHighlightLocationEvent = new NHighlightLocationEvent(
      callSid,
      rawX,
      rawY
    );
    addHighlightPoint(aHighLightEventLocal);

    const video = document.querySelector(
      ".large-media video"
    ) as HTMLVideoElement;

    /* If video element hasn't been rendered yet, delay the update of canvas dimensions */
    if (!video) {
      return;
    }

    const videoArea = getVideoDimensions(video);
    const { offsetWidth, offsetHeight } = video;
    const left = (offsetWidth - videoArea.width) / 2;
    const top = (offsetHeight - videoArea.height) / 2;
    const anInstruction: NAnnotationPoint = new NAnnotationPoint(
      rawX - left,
      rawY - top,
      getTelestrationLineThickness()
    );
    const normalizedPoint: NAnnotationPoint = normalizeInstruction(
      anInstruction,
      { width: videoArea.width, left, top }
    );
    const aHighLightEventToPublish: NHighlightLocationEvent = new NHighlightLocationEvent(
      callSid,
      normalizedPoint.x,
      normalizedPoint.y
    );

    if (availKit) {
      aHighLightEventToPublish.sender = loginId;
      availKit.eventService.broadcast(aHighLightEventToPublish);
    }
  }

  renderFrameTitle(frameType: string) {
    let title = "Screen Frozen";
    switch (frameType) {
      case "FFO_LEFT":
        title = "Left Screen Frozen";
        break;
      case "FFO_RIGHT":
        title = "Right Screen Frozen";
        break;
    }
    return (
      <div className="freezeframeimageinfo">
        <p>{title}</p>
      </div>
    );
  }

  getCanvasDimensions(canvasDOMId: string) {
    const canvas = document.getElementById(canvasDOMId) as HTMLCanvasElement;
    return canvas
      ? {
          left: canvas.style.left,
          width: canvas.width,
          top: canvas.style.top,
          height: canvas.height
        }
      : {};
  }

  renderFrameBoundary(frameType: string, show: boolean) {
    const dimensions = this.getCanvasDimensions(`${frameType}_CVS`);
    return (
      <div
        className={`titleframeoverlay freezeframeimagediv ${
          show ? "" : "noshowfreezeframe"
        }`}
        style={dimensions}
        id={frameType}
      >
        {this.renderFrameTitle(frameType)}
      </div>
    );
  }

  render() {
    const {
      largeMediaBounds: { width, height },
      isVideoStreaming
    } = this.state;
    const { imageState } = this.props;
    const { highlights } = this.props;
    const freezingLeft = imageState.leftTransferIdentifier !== "";
    const freezingRight = imageState.rightTransferIdentifier !== "";
    const freezingFull = imageState.fullscreenTransferIdentifier !== "";
    const LeftFreezeFrame = this.renderFrameBoundary("FFO_LEFT", freezingLeft);
    const rightFreezeFrae = this.renderFrameBoundary(
      "FFO_RIGHT",
      freezingRight
    );
    const fullFreezeFrame = this.renderFrameBoundary(
      "FFO_FULLSCREEN",
      freezingFull
    );

    return (
      <div className="rm-container aspect-ratio aspect-ratio-16-9">
        <div
          className="large-media"
          ref={this.containerRef}
          onMouseDown={(event) => this.onMouseDown(event)}
          onMouseUp={(event) => this.onMouseUp(event)}
          onTouchStart={(event) => this.onTouchStart(event)}
          onTouchEnd={(event) => this.onTouchEnd(event)}
        >
          {isVideoStreaming && (
            <Canvas width={width} height={height} canTelestrate={true} />
          )}
          {highlights.map((highlight) => (
            <Pointer
              highlight={highlight}
              key={highlight.uniqueIdentifier.toString()}
            />
          ))}
          {this.props.children}
          {LeftFreezeFrame}
          {rightFreezeFrae}
          {fullFreezeFrame}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  callSid: state.meeting.callSid,
  freezeFrame: state.image.freezeFrame,
  imageFrozen: state.image.frozen,
  actor: state.user.actor,
  highlights: state.pointer.highlights,
  availKit: state.availKit.availKitInstance,
  mediaState: state.user.mediaState,
  frozenFrames: state.image.frozenFrames,
  imageState: state.image,
  mode: state.meeting.mode,
  loginId: state.user.identity.login_id,
  currentUserRole: state.meeting.currentUserRole
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addHighlightPoint: (highlight: NHighlightLocationEvent) => {
      dispatch(addHighlightPoint(highlight));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LargeMedia);
