import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Fields, validators } from '../../../../../../../Common/Components';
import { getAllBrandsAction } from '../../../../Brands/BrandsActions';
import {
	getVendorsForSelectedBrandAction,
	getManagersForSelectedBusinessUnitAction,
	getManagersBelowMeAction,
} from '../../../../Organisation/Vendors/VendorsActions';
import UserService from '../../../../../../../Common/Services/userService';

export class VendorsFormPart extends PureComponent {
	state = {
		selectedBrand: null,
		selectedBusinessUnitId: null,
	};

	componentDidMount() {
		if (!UserService.checkIsVendorUser(this.props.currentUserType)) {
			this.props.getAllBrands();
		}

		if (UserService.checkIsVendorManager(this.props.currentUserType)) {
			this.props.getManagersBelowMe();
		}

		if (UserService.checkIsVendorAdmin(this.props.currentUserType)) {
			this.props.getManagersForSelectedBusinessUnit(this.props.currentUserOrganisationId);
			this.props.getVendorsForSelectedBrand(this.props.currentUserBrandId);
		}
	}

	selectBrand = (brand) => {
		this.setState({
			selectedBrand: brand,
		});
		if (brand !== '') {
			this.props.getVendorsForSelectedBrand(brand);
		}
	}

	selectBusinessUnit = (businessUnitId) => {
		this.setState({
			selectedBusinessUnitId: businessUnitId,
		});
		if (businessUnitId !== '') {
			this.props.getManagersForSelectedBusinessUnit(businessUnitId);
		}
	};

	render() {
		return (
			<>
				{
					!UserService.checkIsVendorUser(this.props.currentUserType) &&
					<>
						<Fields.SelectField
							labelText="Associate user to Vendor"
							search
							placeholder="Select Vendor"
							options={this.props.brands ? this.props.brands.map(brand => ({
								key: brand.id,
								value: brand.id,
								text: brand.name,
							})) : []}
							onChange={this.selectBrand}
							name="brand"
							validate={validators.required}
						/>
						{
							this.state.selectedBrand &&
							<Fields.SelectField
								labelText="Associate user to Vendor BU"
								search
								placeholder="Select Vendor BU"
								options={this.props.vendorsPerBrand ? this.props.vendorsPerBrand.map(brand => ({
									key: brand.id,
									value: brand.id,
									text: brand.name,
								})) : []}
								onChange={this.selectBusinessUnit}
								name="businessUnitId"
								validate={validators.required}
							/>
						}
						{
							this.props.selectedUserType !== 'VENDOR_ADMIN' && this.state.selectedBusinessUnitId &&
							<Fields.SelectField
								labelText="Associate user to manager"
								search
								clearable
								placeholder="Select manager"
								options={this.props.managersPerBusinessUnit ? this.props.managersPerBusinessUnit.map(manager => ({
									key: manager.id,
									value: manager.id,
									text: `${manager.firstName} ${manager.lastName}`,
								})) : []}
								name="usersManager"
							/>
						}
					</>
				}
				{
					UserService.checkIsVendorAdmin(this.props.currentUserType) &&
					<>
						<Fields.SelectField
							labelText="Associate user to Vendor BU"
							search
							placeholder="Select Vendor BU"
							options={this.props.vendorsPerBrand ? this.props.vendorsPerBrand.map(brand => ({
								key: brand.id,
								value: brand.id,
								text: brand.name,
							})) : []}
							onChange={this.selectBusinessUnit}
							name="businessUnitId"
							validate={validators.required}
						/>
						<Fields.SelectField
							labelText="Associate user to manager"
							search
							placeholder="Select manager"
							clearable
							options={this.props.managersPerBusinessUnit ? this.props.managersPerBusinessUnit.map(manager => ({
								key: manager.id,
								value: manager.id,
								text: `${manager.firstName} ${manager.lastName}`,
							})) : []}
							name="usersManager"
						/>
					</>
				}
				{
					UserService.checkIsVendorManager(this.props.currentUserType) &&
					<Fields.SelectField
						labelText="Associate user to manager"
						search
						placeholder="Select manager"
						clearable={this.props.selectedUserType === 'VENDOR_MANAGER'}
						options={this.props.managersBelowMe ? this.props.managersBelowMe.map(manager => ({
							key: manager.id,
							value: manager.id,
							text: `${manager.firstName} ${manager.lastName}`,
						})) : []}
						name="usersManager"
						validate={this.props.managersBelowMe && this.props.managersBelowMe.length !== 0 ? validators.required : null}
					/>
				}
			</>
		);
	}
}

const mapStateToProps = state => ({
	brands: state.brandsReducer.brands,
	currentUserOrganisationId: state.usersReducer.userOrganisation && state.usersReducer.userOrganisation.id,
	currentUserBrandId:
		state.usersReducer.userOrganisation && state.usersReducer.userOrganisation.brand && state.usersReducer.userOrganisation.brand.id,
	vendorsPerBrand: state.vendorsReducer.vendorsPerBrand,
	managersPerBusinessUnit: state.vendorsReducer.managersPerBusinessUnit,
	managersBelowMe: state.vendorsReducer.managersBelowMe,
});

const mapDispatchToProps = dispatch => ({
	getAllBrands: () => dispatch(getAllBrandsAction()),
	getVendorsForSelectedBrand: brandId => dispatch(getVendorsForSelectedBrandAction(brandId)),
	getManagersForSelectedBusinessUnit: businessUnitId => dispatch(getManagersForSelectedBusinessUnitAction(businessUnitId)),
	getManagersBelowMe: () => dispatch(getManagersBelowMeAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorsFormPart);
