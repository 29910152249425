import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

export default function (WrappedComponent) {
	class withAuthentication extends Component {
		getRedirectionRoute() {
			if (!this.props.fromState) {
				return '/';
			}

			return this.props.fromState.pathname + this.props.fromState.search;
		}

		render() {
			if (this.props.isLoggedIn) {
				return <Redirect to={this.getRedirectionRoute()} />;
			}

			return <WrappedComponent {...this.props} />;
		}
	}

	const mapStateToProps = state => ({
		isLoggedIn: state.authenticationReducer.isLoggedIn,
		fromState: state.router.location && state.router.location.state && state.router.location.state.from,
	});

	return connect(mapStateToProps)(withAuthentication);
}
