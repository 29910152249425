import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, Icon, validators, Fields } from '../../../../../../../../../Common/Components';
import { getDepartmentAction, updateDepartmentAction } from '../../DepartmentsActions';

class DepartmentInfo extends PureComponent {
	componentDidMount() {
		this.props.getDepartment(this.props.departmentId);
	}

	onDepartmentUpdateHandler = data => this.props.updateDepartment(this.props.departmentId, data);

	render() {
		const { t } = this.props;

		return (
			<form
				className="avail-profile__edit-form flex-direction--row justify-content--space-between"
				onSubmit={this.props.handleSubmit(this.onDepartmentUpdateHandler)}
			>
				<div>
					<Fields.InputField
						className="avail__input"
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.placeholder')}
						name="name"
						validate={[validators.required, validators.minimumLength3, validators.maximumLength48]}
					/>
				</div>
				<div>
					<Button className="button-with-icon align-self--flex-start" type="submit">
						<Icon className="icon-save icon--orange entity-profile__submit-icon" />
						<span className="entity-profile__submit-title">Save Changes</span>
					</Button>
				</div>
			</form>
		);
	}
}

DepartmentInfo.propTypes = {};

const mapStateToProps = state => ({
	initialValues: state.departmentReducer.department,
});

const mapDispatchToProps = dispatch => ({
	getDepartment: departmentId => dispatch(getDepartmentAction(departmentId)),
	updateDepartment: (departmentId, departmentData) => dispatch(updateDepartmentAction(departmentId, departmentData)),
});

const DepartmentInfoWithTranslation = withTranslation()(DepartmentInfo);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'departmentProfileForm',
	enableReinitialize: true,
})(DepartmentInfoWithTranslation)));
