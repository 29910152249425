import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
/* Components */
import { Button, Fields, validators } from '../../../../../../Common/Components';
/* Helpers */
import userTypes from '../../../../../../Common/Constants/userTypes';
import userService from '../../../../../../Common/Services/userService';
import { createHospitalUserAction } from '../../../Organisation/Hospitals/HospitalsActions';
import { getUserTypes } from '../../UsersSelectors';
/* Actions */
import { createUserAction, getUserTypesAction } from '../../UsersActions';

class CreateNewUserModal extends PureComponent {
	componentDidMount() {
		this.props.getUserTypes();
	}

	onCreateUserHandler = (data) => {
		this.props.createHospitalUser(data, this.props.hospitalId);
		this.props.toggleModal();
	};

	render() {
		return (
			<div>
				<form onSubmit={this.props.handleSubmit(this.onCreateUserHandler)}>
					<Fields.InputField
						className="user-profile__general-input"
						labelText="Email"
						placeholder="Enter your email"
						name="email"
						validate={[validators.required, validators.email]}
					/>
					<Fields.InputField
						className="user-profile__general-input"
						labelText="First name"
						placeholder="Enter your first name"
						name="firstName"
						validate={[validators.required, validators.onlyLetters, validators.minimumLength2, validators.maximumLength48]}
					/>
					<Fields.InputField
						className="user-profile__general-input"
						labelText="Last name"
						placeholder="Enter your last name"
						name="lastName"
						validate={[validators.required, validators.onlyLetters, validators.minimumLength2, validators.maximumLength48]}
					/>
					<Fields.SelectField
						labelText="Type"
						placeholder="Select user type"
						name="userType"
						options={userService.filterHospitalUserTypes(this.props.userTypes).map(userType => ({
							key: userType.id,
							value: userType.name,
							text: userTypes[userType.name].title,
						}))}
						validate={validators.required}
					/>
					<div className="create-new-console__button-wrapper justify-content--flex-end button__wrapper">
						<Button isGhost buttonSmall noBorder onClick={this.props.toggleModal}>Cancel</Button>
						<Button isFull buttonSmall type="submit">Create</Button>
					</div>
				</form>
			</div>
		);
	}
}

CreateNewUserModal.propTypes = {};

const mapStateToProps = state => ({
	userTypes: getUserTypes(state),
});

const mapDispatchToProps = dispatch => ({
	getUserTypes: () => dispatch(getUserTypesAction()),
	createUser: data => dispatch(createUserAction(data)),
	createHospitalUser: (userData, hospitalId) => dispatch(createHospitalUserAction(userData, hospitalId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'hospitalUsersForm',
	enableReinitialize: true,
})(CreateNewUserModal));
