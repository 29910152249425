import React from "react";

import clsx from "clsx";

import { FormHelperText } from "@mui/material";
import { default as MUIFormControl } from "@mui/material/FormControl";
import { default as MUIInputLabel } from "@mui/material/InputLabel";
import { default as MUIMenuItem } from "@mui/material/MenuItem";
import { default as MUISelect, SelectProps } from "@mui/material/Select";

import styles from "./styles.scss";

export interface IMenuItem {
  value: string;
  displayText: string;
  disabled?: boolean;
}

interface IProps extends SelectProps {
  allowNoneValue?: boolean;
  label: string;
  name: string;
  menuItems?: IMenuItem[];
  currentValue: string;
  emptyMessage?: string;
  fullWidth?: boolean;
  selectClass?: string;
  // all children of Select must be a MenuItem, but sometimes we need to format them differently
  // use this if you need to customize the elements
  rawMenuItems?: JSX.Element[];
  helperText?: string;
  ["data-testid"]?: string;
}

export const Select = (props: IProps) => {
  const {
    label,
    currentValue,
    id,
    name,
    allowNoneValue,
    menuItems = [],
    variant = "standard",
    emptyMessage,
    fullWidth,
    className,
    rawMenuItems,
    helperText,
    selectClass,
    ["data-testid"]: testId = "select",
    ...rest
  } = props;

  return (
    <div className={clsx(styles.wrapper, { [styles.fullWidth]: fullWidth })}>
      <MUIFormControl
        className={clsx(styles.formControl, className)}
        variant={variant}
        error={!!helperText}
      >
        <MUIInputLabel data-testid={`${testId}-label`} htmlFor={id}>
          {label}
        </MUIInputLabel>
        <MUISelect
          data-testid={testId}
          className={selectClass}
          value={currentValue}
          inputProps={{
            id,
            name,
          }}
          MenuProps={{
            className: styles.menu,
          }}
          {...rest}
        >
          {allowNoneValue && (
            <MUIMenuItem value="">
              <em>None</em>
            </MUIMenuItem>
          )}
          {emptyMessage && menuItems.length === 0 && (
            <MUIMenuItem value="" disabled>
              <em>{emptyMessage}</em>
            </MUIMenuItem>
          )}
          {menuItems.map((item, index) => (
            <MUIMenuItem
              data-testid={`${testId}-menu-item`}
              disabled={item.disabled}
              key={index}
              value={item.value}
            >
              {item.displayText}
            </MUIMenuItem>
          ))}
          {rawMenuItems}
        </MUISelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </MUIFormControl>
    </div>
  );
};
