import { renderTraceIdHeader } from "src/domains/Beacon/utils/renderTraceIdHeader";
import { logger } from "../common/logger";
import { ENDPOINTS } from "../constants";
import { MultiPartyEventDetail } from "../store/models";
import { ApiResponse } from "./types";

/**
 * @param payload Defined structure for the metrics backend to process accordingly (NetworkQualityPayload)
 * Example use: const { data, error } = await API.POST.networkQualityPoll(payload)
 */
const eventInfo = async (
  eventId: string,
  access_token: string
): Promise<ApiResponse<MultiPartyEventDetail>> => {
  const url = `${ENDPOINTS.DOMAIN}/scheduler/events/${eventId}`;

  logger().info("Getting Event Details");
  try {
    const opts = {
      method: "GET",
      headers: {
        "content-type": "application/json;charset=UTF-8",
        ...renderTraceIdHeader(),
        authorization: "Bearer " + access_token
      }
    };

    const res = await fetch(url, opts);
    const data = await res.json();
    if (!res.ok) {
      logger().error("Error while getting Event Details");
    }

    return { data: data.content };
  } catch (error) {
    return { error };
  }
};

export default eventInfo;
