import { PROVIDER } from './ProviderActionTypes';

const initialState = {
	pagination: {
		page: 0,
		size: 10,
		totalPages: 1,
	},
	providers: null,
	provider: null,
	providerUsers: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case PROVIDER.SET_ALL_PROVIDERS:
			return {
				...state,
				providers: action.payload,
			};
		case PROVIDER.SET_PROVIDER_USERS:
			return {
				...state,
				providerUsers: action.payload,
			};
		case PROVIDER.SET_PROVIDER_USERS_PAGINATION:
		case PROVIDER.SET_PROVIDER_PAGINATION:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload.pageNumber,
					size: action.payload.pageSize,
					totalPages: action.payload.totalPages,
				},
			};
		case PROVIDER.SET_PROVIDER_BY_ID:
			return {
				...state,
				provider: action.payload,
			};
		default:
			return state;
	}
}
