import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
/* Components */
import { Button, Fields, validators } from '../../../../../../Common/Components';
import Icon from '../../../../../../Common/Components/Icon/Icon';
import EventScheduler from './Components/EventScheduler';
import PortalScheduler from './Components/PortalScheduler';
import ModalComponent from '../../../../../../Common/Components/Modal/Modal';
import TimeOptions from './TimeOptions';
/* Styles */
import '../../Scheduler.scss';
/* Services */
import { dateTimeService } from '../../../../../../Common/Services';
import { SCHEDULE_EVENT_API_AVAILABLE } from '../../../../../../Common/Constants/settings';

export class CreateEventForm extends PureComponent {
	state = {
		isEditTypeModalOpened: false,
		shouldRecurringBeEdited: this.props.shouldRecurringBeEdited,
		scheduleType: this.props.scheduleType || 'event',
	};

	onCreateEventFormSubmit = (formData) => {
		console.log("in onCreateEventFormSubmit", this.state.scheduleType);
		if (this.state.scheduleType === 'portal') {
			this.props.onCreatePortalEventFormSubmit(formData);
		} else {
			this.props.onCreateEventFormSubmit(formData);
		}
	};

	onEditTypeSelect = () => {
		if (this.state.scheduleType === 'portal') {
			this.props.onCreatePortalEventFormSubmit(this.props.createEventFormValues);
		} else {
			this.props.onCreateEventFormSubmit(this.props.createEventFormValues);
		}
	};

	setScheduleTypeEvent = () => {
		this.setState({ scheduleType: 'event' });
	};

	setScheduleTypePortal = () => {
		this.setState({ scheduleType: 'portal' });
	};

	setShouldRecurringBeEdited = (e, value) => {
		this.setState({
			shouldRecurringBeEdited: value,
		});
	};

	toggleEditTypeModal = () => {
		this.setState({
			isEditTypeModalOpened: !this.state.isEditTypeModalOpened,
		});
	};

	renderUpdateButton = () => {
		const { isRecurringEventChecked, recurringEventGroupId } = this.props;

		if (isRecurringEventChecked && recurringEventGroupId) {
			return (
				<Button
					isFull
					onClick={this.toggleEditTypeModal}
				>
					Update
				</Button>
			);
		}
		return (
			<Button
				isFull
				type="submit"
			>
				Update
			</Button>
		);
	}

	render() {
		const { selectedEventId, selectedDate, handleSubmit } = this.props;
		const { scheduleType } = this.state;

		console.log(this.state.scheduleType);

		return (
			<React.Fragment>
				<form className="create-event__form" onSubmit={handleSubmit(this.onCreateEventFormSubmit)}>
					<div className="user-scheduler-radio-button-wrapper">
						<Fields.RadioButtonField
							label="Availability"
							name="value"
							value="event"
							className="avail__radio-button"
							checked={this.state.scheduleType === 'event'}
							onChange={this.setScheduleTypeEvent}
						/>
						{SCHEDULE_EVENT_API_AVAILABLE ? (
							<Fields.RadioButtonField
								// TODO: Only display the event option to create event if current user is on THEIR OWN CALENDAR
								label="Event"
								name="value"
								value="portal"
								className="avail__radio-button"
								checked={this.state.scheduleType === 'portal'}
								onChange={this.setScheduleTypePortal}
							/>
						) : null}
					</div>
					<div className="create-event__form-section-wrapper">
						<div className="create-event__form-section">
							<span className="create-event__form-section-subtitle">Date</span>
						</div>
						<span className="create-event__form-section-text">{dateTimeService.toCalendarDate(selectedDate)}</span>
					</div>
					<div className="create-event__form-section-wrapper">
						<div className="create-event__inputs">
							<Fields.SelectField
								labelText="From"
								placeholder="From"
								name="eventStartTime"
								noResultsMessage="invalid time"
								options={TimeOptions.map(option => ({
									key: option.value,
									value: option.value,
									text: option.value,
								}))}
								validate={validators.required}
							/>
							<Fields.SelectField
								labelText="Until"
								placeholder="Until"
								name="eventEndTime"
								noResultsMessage="invalid time"
								options={TimeOptions.map(option => ({
									key: option.value,
									value: option.value,
									text: option.value,
								}))}
								validate={validators.required}
							/>
						</div>
					</div>
					{scheduleType === 'event' && <EventScheduler hospitals={this.props.hospitals} onRecurringEventChecked={this.props.onRecurringEventChecked} isRecurringEventChecked={this.props.isRecurringEventChecked} />}
					{scheduleType === 'portal' && <PortalScheduler hospitals={this.props.hospitals} hospitalId={this.props.initialValues.hospitalId} /> }
					<ModalComponent
						hasActionButtons={false}
						open={this.state.isEditTypeModalOpened && scheduleType === 'event'}
						onCancel={this.toggleEditTypeModal}
						title="Edit recurring event"
					>
						<div className="select-edit-type">
							<Icon
								className="select-edit-type__icon create-event__form-section-icon--orange icon-repeat-outline icon--orange"
							/>
							<div className="select-edit-type__radio-group">
								<Fields.RadioButtonField
									label="This event"
									name="shouldRecurringBeEdited"
									value="false"
									className="select-edit-type__option"
									checked={this.state.shouldRecurringBeEdited === 'false'}
									onChange={this.setShouldRecurringBeEdited}
								/>
								<Fields.RadioButtonField
									label="This and following events"
									name="shouldRecurringBeEdited"
									value="true"
									className="select-edit-type__option"
									checked={this.state.shouldRecurringBeEdited === 'true'}
									onChange={this.setShouldRecurringBeEdited}
								/>
							</div>
							<div className="create-event__buttons">
								<Button
									isGhost
									noBorder
									onClick={this.toggleEditTypeModal}
									buttonSmall

								>
									Cancel
								</Button>
								<Button
									isFull
									onClick={this.onEditTypeSelect}
								>
									Update
								</Button>
							</div>
						</div>
					</ModalComponent>
					<div className="create-event__buttons">
						<Button
							isGhost
							noBorder
							onClick={this.props.onCancelClick}
							buttonSmall
						>
							Cancel
						</Button>
						{selectedEventId &&
							<React.Fragment>
								{this.renderUpdateButton()}
							</React.Fragment>
						}
						{!selectedEventId &&
							<Button
								isFull
								type="submit"
							>
								Create
							</Button>
						}
					</div>
				</form>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	createEventFormValues: getFormValues('createEventForm')(state),
	timezone: state.usersReducer.timezone,
});

export default reduxForm({
	form: 'createEventForm',
	destroyOnUnmount: false,
	enableReinitialize: true,
})(connect(mapStateToProps)(CreateEventForm));
