import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { LoginPage } from "src/domains/Login";
import { useOnPageRefresh } from "src/hooks/useOnPageRefresh";
import { AppState, history } from "src/reducer";
import UserSessionService from "src/services/UserSessionService";
import { clearUserSession } from "src/utils/clearUserSession";

import withAuthentication from "avail-web-application/Features/Authentication/Components/withAuthentication";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

const OTPSuspended = () => {
  const authInfo = UserSessionService.getMFAAuthInfo();
  useOnPageRefresh(() => clearUserSession());

  const errorMessage = useSelector(
    (state: AppState) => state.authenticationReducer.errorMessage
  );

  useEffect(() => {
    // checks for empty session storage
    if (authInfo === null) {
      history.push("/login");
    }
  }, []);

  const handleSubmit = () => {
    if (authInfo === "temp_auth_registered") {
      history.push("/login");
    } else if (authInfo === "temp_auth_guest") {
      history.push("/event-login");
    }
  };

  // TODO: move this to interceptor
  const getRemainingTime = (): string => {
    const array: string[] = errorMessage.split(" ");
    return array[array.length - 2];
  };

  return (
    <LoginPage className="login">
      <Grid container spacing={2} direction={"column"}>
        <Grid item>
          <Box m={2}>
            <Typography
              variant="h4"
              align={"center"}
              sx={{ fontWeight: "medium" }}
            >
              {DomainConstants.label.mfaUserSuspendedTitle}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box mb={2}>
            <Typography variant="body1" align={"center"}>
              {DomainConstants.label.incorrectPasscodeSuspension}
              {` Please retry after ${getRemainingTime()} minutes!`}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box m={2}>
            {/* TODO: move styles later */}
            <Button
              fullWidth
              style={{
                backgroundColor: "#7EBB45",
              }}
              onClick={handleSubmit}
            >
              {DomainConstants.label.returnToLogin}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LoginPage>
  );
};

export default withAuthentication(OTPSuspended);
