import { DEPARTMENTS } from './DepartmentsActionTypes';

export const initialState = {
	departments: null,
	departmentProcedures: null,
	department: null,
	departmentRooms: null,
	room: null,
	roomProcedures: null,
	roomTypes: null,
	roomDepartments: null,
	departmentsTherapies: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case DEPARTMENTS.SET_DEPARTMENTS_BY_HOSPITAL:
			return {
				...state,
				departments: action.payload,
			};
		case DEPARTMENTS.SET_ALL_THERAPIES:
			return {
				...state,
				therapies: action.payload,
			};
		case DEPARTMENTS.SET_PROCEDURES_BY_DEPARTMENT:
			return {
				...state,
				departmentProcedures: action.payload,
			};
		case DEPARTMENTS.SET_DEPARTMENT:
			return {
				...state,
				department: action.payload,
			};
		case DEPARTMENTS.SET_ROOMS_BY_DEPARTMENT:
			return {
				...state,
				departmentRooms: action.payload,
			};
		case DEPARTMENTS.SET_ROOM:
			return {
				...state,
				room: action.payload,
			};
		case DEPARTMENTS.SET_ROOM_PROCEDURES:
			return {
				...state,
				roomProcedures: action.payload,
			};
		case DEPARTMENTS.SET_ALL_ROOM_TYPES:
			return {
				...state,
				roomTypes: action.payload,
			};
		case DEPARTMENTS.SET_ALL_DEPARTMENTS_ROOM_IS_ASSOCIATED:
			return {
				...state,
				roomDepartments: action.payload,
			};
		case DEPARTMENTS.SET_ALL_DEPARTMENTS_THERAPIES_ROOM_IS_ASSOCIATED:
			return {
				...state,
				departmentsTherapies: action.payload,
			};
		case DEPARTMENTS.SET_ROOM_THERAPIES:
			return {
				...state,
				roomTherapies: action.payload,
			};
		default:
			return state;
	}
}
