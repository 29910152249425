import { PulseLoader } from "react-spinners";

import clsx from "clsx";

import { Fade } from "@mui/material";

import { Font } from "src/components/Font";

import styles from "./styles.scss";

export interface IProps {
  open: boolean;
  message: string;
}

export const ConsoleNotPresent = ({ open, message }: IProps) => {
  return (
    <>
      <Fade in={open} timeout={200}>
        <div className={clsx(styles.noConsoleMessageContainer)}>
          <div className={clsx(styles.noConsoleMessage)}>
            <PulseLoader
              color={"#0a84ff"} // bright-blue-bdg
              margin={4}
              size={6}
              speedMultiplier={0.25}
            />
            <Font variant="h1" color="light">
              {message}
            </Font>
          </div>
        </div>
      </Fade>
    </>
  );
};
