import {
  ConsoleListResponse,
  ConsolePayload,
  DebugMode,
  GetPresenceServerResponse,
} from ".";
import { getQueryString } from "../../../utils/ApiHelper";
import ApiQuery from "../ApiQuery";
import { CORE_REGISTRY_API_URL, BASE_API_URL } from "../utils";

export const getConsoleList = (
  page: number = 0,
  size: number | "all" = 10,
  sort: string = "",
  extraParam: string = ""
) => {
  return ApiQuery<ConsoleListResponse>(
    `${CORE_REGISTRY_API_URL}/consoles${getQueryString({
      page,
      size,
      sort,
      extraParam,
    })}`
  );
};

export const createConsole = (payload: ConsolePayload) => {
  return ApiQuery(`${CORE_REGISTRY_API_URL}/consoles`, "POST", payload);
};

export const deleteConsole = (consoleId: string) => {
  return ApiQuery(`${CORE_REGISTRY_API_URL}/consoles/${consoleId}`, "DELETE");
};

export const getConsoleDetail = (consoleId: string) =>
  ApiQuery(`${CORE_REGISTRY_API_URL}/consoles/${consoleId}`);

export const updateConsole = (payload: ConsolePayload, consoleId: string) => {
  return ApiQuery(
    `${CORE_REGISTRY_API_URL}/consoles/${consoleId}`,
    "PUT",
    payload
  );
};

export const toggleDebugModal = (payload: DebugMode, consoleId: string) => {
  return ApiQuery(
    `${CORE_REGISTRY_API_URL}/consoles/${consoleId}`,
    "PUT",
    payload
  );
};

export const getConsolesBySessionId = (payload: string) => {
  return ApiQuery<GetPresenceServerResponse>(
    `${BASE_API_URL}/presence/callSessions/${payload}/consoles`
  );
};
