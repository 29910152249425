export const avatar = require('./avatar.jpg').default;
export const loginImage = require('./login.svg').default;
export const new_logo = require('./avail_newlogo.png').default;
export const newlogo2021 = require('./newlogo2021.png').default;
export const users = require('./users.svg').default;
export const onboarding = require('./onboarding.svg').default;
export const LoginBackgroungImage = require('./loginBackgroundImg.png').default;
export const mainBackground = require('./mainBackground.png').default;
export const newUser = require('./new_user.svg').default;
export const addNewHospital = require('./hospital_color.svg').default;
export const addNewVendor = require('./vendor_color.svg').default;
export const createUser = require('./create_user.svg').default;
export const fourOFour = require('./404_lan.svg').default;

export const incomingCall = require('./incoming-call-illustration.svg').default;
export const callAnswer = require('./call-answer.svg').default;
export const callDecline = require('./call-decline.svg').default;

export const warningIcon = require('./alert-symbol-red.png').default;
export const appStoreLogo = require('./app-store-logo.svg').default;
