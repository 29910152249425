import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { withTranslation } from 'react-i18next';
/* Components */
import { Button, Fields, validators } from '../../../../Common/Components';
import ModalComponent from '../../../../Common/Components/Modal/Modal';
import { Table } from '../../Common';
import AddNewEntity from '../../Common/AddNewEntity/AddNewEntity';
import { getAllStatesAction } from '../Organisation/OrganisationActions';
import ProviderTableRow from './Components/ProviderTableRow/ProviderTableRow';
/* Actions */
import { createNewProviderAction, deleteProviderAction, getAllProvidersAction } from './ProviderActions';
/* Images */
import { addNewVendor } from '../../../../Common/Assets/Images';
import { uploadLogo } from './ProviderUtils';
import { notificationService } from '../../../../Common/Services';

class Provider extends PureComponent {
	state = {
		isCreateNewProviderModalOpened: false,
		isDeleteModalOpened: false,
		provider: null,
		pageSize: 10,
	};

	componentDidMount() {
		this.params = {
			size: this.state.pageSize,
			page: 0,
		};
		this.props.getAllStates();
	}

	onCreateProviderHandler = async (data) => {
		const providerData = { ...data };

		try {
			if (providerData.logoLocation && typeof providerData.logoLocation !== 'string') {
				const { logoLocation: logoFile } = providerData;
				const uploaded = await uploadLogo(providerData.name, logoFile);
				providerData.logoLocation = uploaded;
			}

			this.props.createNewProvider(providerData);
		} catch {
			notificationService.error('Error creating HCP Group.');
		}

		this.toggleCreateNewProviderModal();
	};

	onDeleteProviderHandler = () => {
		this.props.deleteProvider(this.state.provider.id);
		this.toggleDeleteModal();
	};

	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = {
			size: this.state.pageSize,
			page: 0,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllProviders(this.params);
	};

	onPageChangeHandler = (e, { activePage }) => {
		this.params = {
			...this.params,
			size: this.state.pageSize,
			page: activePage - 1,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllProviders(this.params);
	};

	handleVariablePaginationChange = (option) => {
		if (!option) return;

		this.setState({ pageSize: option });
		this.props.getAllProviders((option === 'all' ? { ...this.params,
			size: undefined,
			page: 0,
			[this.parameter]: this.sortParams } : { ...this.params,
			size: option,
			page: 0,
			[this.parameter]: this.sortParams 
		}));

	}

	columns = [
		{
			title: 'Name',
			sortValue: 'name',
		},
		{
			title: 'Address',
			sortValue: 'address',
		},
		{
			title: 'City',
			sortValue: 'city',
		},
		{
			title: 'State',
			sortValue: 'state',
		},
	];

	toggleCreateNewProviderModal = () => {
		this.props.resetReduxForm('providerForm');
		this.setState({ isCreateNewProviderModalOpened: !this.state.isCreateNewProviderModalOpened });
	};

	toggleDeleteModal = (provider = {}) => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
			provider,
		});
	};

	renderCreateNewProviderModal = () => {
		const { t } = this.props;

		return (
			<ModalComponent
				open={this.state.isCreateNewProviderModalOpened}
				hasActionButtons={false}
				onCancel={this.toggleCreateNewProviderModal}
				title={t('features.provider.create.label')}
			>
				<form onSubmit={this.props.handleSubmit(this.onCreateProviderHandler)}>
					<Fields.InputField
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.placeholder')}
						name="name"
						validate={[validators.required, validators.minimumLength3, validators.maximumLength48]}
					/>
					<Fields.FileInputField
						name="logoLocation"
						id="fileItem"
						type="file"
						labelText="Upload Image"
						validate={[
							validators.imageformat,
							validators.imagesize,
						]}
					/>
					<Fields.SelectField
						labelText={t('common.form.state.label')}
						placeholder={t('common.form.state.placeholder')}
						name="state"
						validate={[validators.required, validators.minimumLength2, validators.maximumLength64]}
						search
						options={this.props.states && this.props.states.map(state => ({
							key: state.id,
							value: state.name,
							text: `${state.name} (${state.abbreviation})`,
						}))}
					/>
					<Fields.InputField
						labelText={t('common.form.city.label')}
						placeholder={t('common.form.city.placeholder')}
						name="city"
						validate={[validators.required, validators.minimumLength2, validators.maximumLength64]}
					/>
					<Fields.InputField
						labelText={t('common.form.address.label')}
						placeholder={t('common.form.address.placeholder')}
						name="address"
						validate={[validators.required, validators.minimumLength3, validators.maximumLength128]}
					/>

					<div className="button__wrapper">
						<Button
							onClick={this.toggleCreateNewProviderModal}
							isGhost
							buttonSmall
						>
							Cancel
						</Button>
						<Button
							type="submit"
							isFull
							buttonSmall
						>
							Create
						</Button>
					</div>
				</form>
			</ModalComponent>
		);
	};

	renderDeleteProviderModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onDeleteProviderHandler}
			onCancel={this.toggleDeleteModal}
			confirmText="Delete"
		>
			{
				this.state.provider && (
					<span>
						You are about to delete <span className="text--bold">{this.state.provider.name}</span>.
						Would you like to proceed with this action?
					</span>
				)
			}
		</ModalComponent>
	);

	render() {
		const { t } = this.props;

		return (
			<div className="users__container">
				<React.Fragment>
					<Table
						row={ProviderTableRow}
						columns={this.columns}
						tableData={this.props.providers}
						headerTitle={t('features.provider.name_plural')}
						onSortTableHandler={this.onTableFilterHandler}
						onSearchTermUpdateHandler={this.onTableFilterHandler}
						paginationConfig={this.props.pagination}
						onPageChangeHandler={this.onPageChangeHandler}
						openModal={this.toggleDeleteModal}
						noItemsClass="height--full justify-content--center align--center"
						onAddNewHandler={this.toggleCreateNewProviderModal}
						noEntityComponent={
							<AddNewEntity
								image={addNewVendor}
								addNewEntityHandler={this.toggleCreateNewProviderModal}
								title={t('features.provider.create.label')}
							/>
						}
						sortable
						search
						variablePagination
						onVariablePaginationChange={this.handleVariablePaginationChange}
					/>
				</React.Fragment>
				{this.renderDeleteProviderModal()}
				{this.renderCreateNewProviderModal()}
			</div>
		);
	}
}

Provider.propTypes = {};

const mapStateToProps = state => ({
	pagination: state.providerReducer.pagination,
	providers: state.providerReducer.providers,
	states: state.organisationsReducer.states,
});

const mapDispatchToProps = dispatch => ({
	getAllProviders: params => dispatch(getAllProvidersAction(params)),
	resetReduxForm: formName => dispatch(reset(formName)),
	createNewProvider: providerData => dispatch(createNewProviderAction(providerData)),
	deleteProvider: providerId => dispatch(deleteProviderAction(providerId)),
	getAllStates: () => dispatch(getAllStatesAction()),
});

const ProviderWithTranslation = withTranslation()(Provider);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'providerForm',
})(ProviderWithTranslation));
