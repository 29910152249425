import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { TELESTRATION_VIDEO_DIMENSIONS_ADJUST_BUFFER } from "src/domains/Beacon/constants";
import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { drawRemoteAnnotationsThunk } from "src/domains/Beacon/store/telestration/thunks/drawRemoteAnnotationsThunk";
import { logger, LoggerLevels } from "src/logging/logger";
import { PresenceServerConsoleDetails } from "src/services/ApiClient/presence";
import { timeoutCallback } from "src/utils/timeout";

const loggerFields = {
  fileInfo: "useGetTelestrations",
  feature: "Telestration",
};

// Sidebar status must be retrieved only once at the beginning of the call and stored
export const useGetTelestrations = (
  sessionDetails: PresenceServerConsoleDetails
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionDetails) {
      const { telestrations } = sessionDetails;
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Telestration",
        },
        `Getting Telestration annotations from Presence: ${telestrations?.length}`
      );
      // Since this isn't an async function, we can rely that Redux annotations will exist
      // during the annotations thunk
      dispatch(telestrationActions.setAnnotations(telestrations));

      logger().logWithFields(
        LoggerLevels.info,
        loggerFields,
        `Remote annotations have been set in state. Waiting ${TELESTRATION_VIDEO_DIMENSIONS_ADJUST_BUFFER}ms to allow video dimensions calculations to settle before redrawing.`
      );
      // Allow time for Video element dimensions to recalibrate, then draw
      timeoutCallback(() => {
        dispatch(drawRemoteAnnotationsThunk());
      }, TELESTRATION_VIDEO_DIMENSIONS_ADJUST_BUFFER);
    }
  }, [sessionDetails]);
};
