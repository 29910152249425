import moment from 'moment-timezone';

import { checkEventJoinable } from "src/domains/Events/utils";
import { dateTimeService } from '../../../../Common/Services';

const isEventInPast = eventEndDate => dateTimeService.isBefore(eventEndDate, moment());

export const createEventObject = ({ event, selectedDate, isRecurring, timeZone }) => {
	const scheduledStartDate = dateTimeService.toLongFormatDate(selectedDate, event.eventStartTime);
	let scheduledEndDate = dateTimeService.toLongFormatDate(selectedDate, event.eventEndTime);

	scheduledEndDate = dateTimeService.isBefore(scheduledStartDate, scheduledEndDate) ?
		scheduledEndDate :
		scheduledEndDate.add(1, 'd');

	if (isRecurring) {
		return {
			scheduleType: 'RECURRING',
			hospitalIds: event.hospitalIds,
			description: '',
			scheduledStartDate: dateTimeService.toBackEndDate(dateTimeService.toLongFormatDate(event.recurringStartDate, event.eventStartTime)),
			scheduledEndDate: dateTimeService.toBackEndDate(dateTimeService.toLongFormatDate(event.recurringEndDate, event.eventEndTime)),
			scheduledDates: [],
			recurringDates: event.workingDaysOfWeek,
			timeZone,
		};
	}

	return {
		scheduleType: 'MANUAL',
		hospitalIds: event.hospitalIds,
		description: '',
		scheduledStartDate: dateTimeService.toBackEndDate(scheduledStartDate),
		scheduledEndDate: dateTimeService.toBackEndDate(scheduledEndDate),
		scheduledDates: [],
		recurringDates: {},
		timeZone,
	};
};

export const convertToCalendarEvents = (events) => {
	const calendarEvents = [];
	events.map((event) => {
		calendarEvents.push({
			meetingTime: false,
			start: event.scheduledStartDate,
			end: event.scheduledEndDate,
			recurringStartDate: event.recurringStartDate,
			recurringEndDate: event.recurringEndDate,
			hospitalIds: event.hospitalIds,
			id: event.scheduleId,
			groupId: event.groupId,
			recurredDayName: event.recurredDayName,
			classNames: isEventInPast(event.scheduledEndDate) ? ['pastEvent'] : [],
		});
	});

	return calendarEvents;
};

export const convertCallEventsToCalendarEvents = (events) => {
	const calendarEvents = [];
	events.map((event) => {
		calendarEvents.push({
			meetingTime: false,
			callEvent: true,
			start: event.startDate,
			end: event.endDate,
			hospitalIds: [event.hospitalId],
			description: event.description,
			participants: event.participants,
			id: event.eventId,
			roomId: event.roomId,
			classNames: ['answeredCallEvent'],
		});
	});

	return calendarEvents;
};

export const convertMeetingToCalendarEvents = (events) => {
	const calendarEvents = [];

	events.map((event) => {
		const isEndNull = event.endTime === null;
		const end = event.callState === 'ANSWERED' && isEndNull
			? moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
			: event.endTime;

		let className = '';
		if (event.callState === 'ANSWERED') {
			className = 'answeredCallEvent';
		} else if (event.callState === 'MISSED') {
			className = 'missedCallEvent';
		}

		if (event.startTime) {
			calendarEvents.push({
				meetingTime: true,
				callStatus: event.callState,
				endCallTime: event.endTime,
				start: event.startTime,
				end,
				recurringStartDate: null,
				recurringEndDate: null,
				hospitalIds: [event.hospital.id],
				id: event.callSid,
				joinId: event.joinId,
				callSid: event.callSid,
				room: event.room,
				procedure: event.procedure,
				groupId: event.groupId,
				recurredDayName: '',
				classNames: ['meetingEvent', className],
				hospital: [event.hospital],
			});
		}
	});

	return calendarEvents;
};

export const convertMPEventsToCalendarEvents = (events) => {
	const calendarEvents = [];

	events.map((event) => {
    const { status } = event;
    const isEventCurrent = checkEventJoinable(event) && 
      moment(event.endDate).isSameOrAfter(new Date(), "day");

		let className = '';
    if (status === 'ENDED' || status === "EXPIRED" || status === "CANCELLED") {
			className = 'past';
		} else if (status === 'CREATED' || status === "CREATING") {
			className = 'upcoming';
		} else if (status === "STARTED") {
			className = 'current';
    }

    // if the event is ended but is still in the current day, it should be still be in progress
    if (status === "ENDED" && isEventCurrent) {
      className = 'current';
    }

    calendarEvents.push({
      isMpEvent: true,
      ...event, // throw in all the event properties (for details popup)
      subject: event.subject,
      start: event.startDate,
      end: event.endDate,
      id: event.eventId,
      classes: ['mp-event', className],
      // TODO: what other properties might we need for this?
    });
	});

	return calendarEvents;
};
