// Logging disabled until splunk logging is figured out
// import LogService from "./LogService";

export function add(key: string, value: any) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // LogService.error({ error: JSON.stringify(e, Object.getOwnPropertyNames(e)), message: `Error when adding key ${key} to localStorage` });
  }
}

export function get(key: string) {
  try {
    return JSON.parse(localStorage.getItem(key)!);
  } catch (e) {
    // LogService.error({ error: JSON.stringify(e, Object.getOwnPropertyNames(e)), message: `Error when getting key ${key} from localStorage` });
    // TODO: If getting some key related to user fails, it is probably because
    // of some corrpted data caused by a patch or some user action, should we sign user out and go to login?
  }
}

export function remove(key: string): void {
  localStorage.removeItem(key);
}

const LocalStorageService = {
  add,
  get,
  remove,
};

export default LocalStorageService;
