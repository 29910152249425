import { RemoteParticipant } from "twilio-video";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { isConsoleParticipant } from "src/domains/Beacon/utils/twilio";
import { logger } from "src/logging/logger";

// Will detect when a remote participant re-connects to the Twilio room
export const useRoomParticipantReconnected = () => {
  const { room } = useAppSelector(selectTwilioState);
  const dispatch = useDispatch();

  useEffect(() => {
    room.on("participantReconnected", (participant: RemoteParticipant) => {
      logger().info(
        `'${participant.identity}' reconnected and has joined the room.`
      );
      const participants = [...room.participants.values()];
      const hasConsoleRejoined = isConsoleParticipant(participant);
      dispatch(twilioActions.setParticipants(participants));
      dispatch(twilioActions.setRemoteHasJoinedRoom(true));
      dispatch(twilioActions.setConsoleHasJoinedRoom(hasConsoleRejoined));
    });
  }, []);
};
