// Utils related to the /settings endpoints
import { OrganizationSettings } from "src/services/ApiClient/businessUnit";

// settings is an array of objects so we have to find the right object we want
export const getTrialEnabledFlagFromSettings = (
  settings: OrganizationSettings[]
) => {
  // this flag will either hold the trial enabled object (which will have true/false)
  // or it will be undefined which means the flag was never set which will mean false for the UI
  const trialEnabledFlag = settings.find((value) => {
    if (value.type === "TRIAL_ENABLED") {
      return value;
    }
  });

  return trialEnabledFlag
    ? trialEnabledFlag.value === "true" // BE returns this as a string so just do a quick boolean check
    : false;
};
