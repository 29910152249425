import { useQuery } from "react-query";

import { FetchError } from "src/services/ApiClient/ApiQuery";
import {
  ThumbnailListResponse,
  getCameraThumbnail,
} from "src/services/ApiClient/presence";

const KEY = "presence";

export const useGetThumbnails = (callSid: string, toggleFetch: boolean) => {
  return useQuery<ThumbnailListResponse, FetchError>(
    [`${KEY}-${callSid}`, toggleFetch],
    () => {
      return getCameraThumbnail(callSid);
    },
    // Will be called frequently when there are new thumbnails available
    {
      cacheTime: 0,
    }
  );
};
