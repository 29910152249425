import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import { rejoinPubnubChannelsThunk } from "src/domains/Beacon/store/twilio/thunks/rejoinPubnubChannels";
import { uiActions } from "src/domains/Beacon/store/ui";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

export const useStatusCallback = () => {
  const { mode } = useAppSelector(selectMeetingState);
  const availKit = AvailKitService.instance;
  const dispatch = useDispatch();
  const { QaAutomationFeatureFlag } = useFeatureFlags();

  useEffect(() => {
    try {
      const pubnubStatusCallback = (message) => {
        try {
          const theMsg = JSON.stringify(message);

          switch (message?.category) {
            case "PNConnectedCategory":
              logger().info(`PubNub: connection established: ${theMsg}`);
              break;
            case "PNNetworkIssuesCategory":
              logger().info(`PubNub: had issue on network: ${theMsg}`);
              break;
            case "PNNetworkDownCategory":
              dispatch(twilioActions.setLocalNetworkDisconnected(true));

              // disable NoNetworkModal for QA Automations
              if (
                !QaAutomationFeatureFlag &&
                process.env.REACT_APP_AVAIL_LOG_ENV === "qa"
              ) {
                // show modal that indicates Network connection is down and give user an option to leave the call
                dispatch(uiActions.setOpenNoNetworkModal(true));
              }
              logger().info(`PubNub: network connection is DOWN: ${theMsg}`);
              break;
            case "PNTimeoutCategory":
              logger().error(`PubNub: timed out connecting... ${theMsg}`);
              break;
            case "PNNetworkUpCategory":
              dispatch(twilioActions.setLocalNetworkDisconnected(false));
              dispatch(uiActions.setOpenNoNetworkModal(false));
              logger().info(`PubNub: network is back up`);
              if (mode === CallModes.P2P) {
                dispatch(rejoinPubnubChannelsThunk());
              }
              break;
            case "PNMalformedResponseCategory":
              logger().warn(`PubNub: failed to parse JSON: ${theMsg}`);
              break;
            case "PNReconnectedCategory":
              logger().warn(`PubNub: reconnected to network`);
              break;
            case "PNUnknownCategory":
              logger().warn(`PubNub: encountered unknown error: ${theMsg}`);
              break;
            default:
              logger().warn(
                `PubNub: reported an unrecognized status [${message.category}]: ${theMsg}`
              );
              break;
          }
        } catch (e: any) {
          logger().error(e);
        }
      };
      availKit.eventService.setStatusCallback(pubnubStatusCallback);
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useStatusCallback",
        },
        "Error while receiving callback status.",
        error?.message
      );
    }
  }, [availKit]);
};
