import { useDispatch } from "react-redux";

import clsx from "clsx";

import { Button } from "src/components/Button";
import { NoConnectionIcon } from "src/components/CustomIcons/NoConnectionIcon";
import { Font } from "src/components/Font";
import { Modal, ModalFooter, ModalHeader } from "src/components/Modal";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { leaveCallNoNetworkThunk } from "src/domains/Beacon/store/twilio/thunks/leaveCall";
import { uiActions } from "src/domains/Beacon/store/ui";
import { selectNoConnectionModalOpen } from "src/domains/Beacon/store/ui/selectors";

import styles from "./styles.scss";

export enum ModalMessages {
  POOR = "Bad network quality detected. Some features may not work reliably.",
  DISCONNECTED = "You have lost network connection.",
}

export const NetworkConnectionLostModal = () => {
  const dispatch = useDispatch();
  const { localNetworkDisconnected } = useAppSelector(selectTwilioState);
  const open = useAppSelector(selectNoConnectionModalOpen);
  const modalMessage =
    ModalMessages[localNetworkDisconnected ? "DISCONNECTED" : "POOR"];

  return (
    <div
      className={clsx(styles.root, {
        [styles.hidden]: !open,
        [styles.networkLost]: localNetworkDisconnected,
      })}
      data-test-id="reconnecting-modal-wrapper"
    >
      <Modal
        open={open}
        showVeil={localNetworkDisconnected}
        className={clsx(styles.modal)}
        data-test-id="reconnecting-modal"
      >
        {!localNetworkDisconnected && (
          <ModalHeader
            title="" // Intentionally blank, ModalHeader used for close button
            data-test-id="reconnecting-modal-header"
            closeModal={() => {
              dispatch(uiActions.setOpenNoNetworkModal(false));
            }}
          />
        )}
        <NoConnectionIcon data-test-id="no-connection-icon" />
        <Font
          variant="h1"
          color="light"
          className={clsx(styles.content)}
          data-test-id="no-connection-message"
        >
          {modalMessage}
        </Font>
        {localNetworkDisconnected && (
          <ModalFooter>
            <Button
              onClick={() => {
                dispatch(leaveCallNoNetworkThunk());
              }}
              label="Leave Event"
              theme="red"
              className={clsx(styles.button)}
              data-test-id="no-connection-leave-event-button"
            />
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};
