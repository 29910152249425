import { locations } from "src/utils/locations";

import MailMFAAuthentication from "avail-web-application/Features/Authentication/Features/MailMFAAuthentication/MailMFAAuthentication";
import ResetPassword from "avail-web-application/Features/Authentication/Features/ResetPassword/ResetPassword";
import ResetPasswordExpire from "avail-web-application/Features/Authentication/Features/ResetPassword/ResetPasswordExpire";
import RestrictedRoute from "avail-web-application/Features/Home/Common/RestrictedRoute/RestrictedRoute";
import AccountCreation from "avail-web-application/Features/Home/Features/AccountCreation/AccountCreation";
import Brands from "avail-web-application/Features/Home/Features/Brands/Brands";
import Consoles from "avail-web-application/Features/Home/Features/Consoles/Consoles";
import ConsoleProfile from "avail-web-application/Features/Home/Features/Consoles/Features/ConsoleProfile/ConsoleProfile";
import Distributors from "avail-web-application/Features/Home/Features/Distributors/Distributors";
import HospitalApprovalList from "avail-web-application/Features/Home/Features/HospitalApprovalList/HospitalApprovalList";
import HospitalNetwork from "avail-web-application/Features/Home/Features/HospitalNetwork/HospitalNetwork";
import Billing from "avail-web-application/Features/Home/Features/Organisation/Billing/Billing";
import InvoiceHistory from "avail-web-application/Features/Home/Features/Organisation/Billing/Components/InvoiceHistory/InvoiceHistory";
import { CallDetailRecord } from "avail-web-application/Features/Home/Features/Organisation/CallDetailRecord";
import { Hospitals } from "avail-web-application/Features/Home/Features/Organisation/Hospitals";
import CreateNewHospital from "avail-web-application/Features/Home/Features/Organisation/Hospitals/Features/CreateNewHospital/CreateNewHospital";
import DepartmentProfile from "avail-web-application/Features/Home/Features/Organisation/Hospitals/Features/DepartmentProfile/DepartmentProfile";
import HospitalProfile from "avail-web-application/Features/Home/Features/Organisation/Hospitals/Features/HospitalProfile/HospitalProfile";
import RoomProfile from "avail-web-application/Features/Home/Features/Organisation/Hospitals/Features/RoomProfile/RoomProfile";
import { Vendors } from "avail-web-application/Features/Home/Features/Organisation/Vendors";
import CreateNewVendor from "avail-web-application/Features/Home/Features/Organisation/Vendors/Features/CreateNewVendor/CreateNewVendor";
import VendorProfile from "avail-web-application/Features/Home/Features/Organisation/Vendors/Features/VendorProfile/VendorProfile";
import ProviderProfile from "avail-web-application/Features/Home/Features/Provider/Features/ProviderProfile/ProviderProfile";
import Provider from "avail-web-application/Features/Home/Features/Provider/Provider";
import MissedCalls from "avail-web-application/Features/Home/Features/Scheduler/Features/MissedCalls/MissedCalls";
import Scheduler from "avail-web-application/Features/Home/Features/Scheduler/Scheduler";
import { Users } from "avail-web-application/Features/Home/Features/Users";
import CreateNewUser from "avail-web-application/Features/Home/Features/Users/Features/CreateNewUser/CreateNewUser";
import UserProfile from "avail-web-application/Features/Home/Features/Users/Features/UserProfile/UserProfile";
import VendorApprovalList from "avail-web-application/Features/Home/Features/VendorApprovalList/VendorApprovalList";

// This component holds all the routes that logged in users can access, all of these pages are implemented in the old code base (avail-web-application)
export const WebAppRoutes = () => {
  return (
    <>
      <RestrictedRoute
        context="resetPasswordExpire"
        path="/expire-password"
        component={ResetPasswordExpire}
      />
      <RestrictedRoute
        context="resetPassword"
        path="/reset-password"
        component={ResetPassword}
      />
      <RestrictedRoute
        context="mfaAuthentication"
        path="/verify-mfa-otp"
        component={MailMFAAuthentication}
      />
      <RestrictedRoute
        context="createUser"
        path="/users/newUser"
        component={CreateNewUser}
      />
      <RestrictedRoute
        context="userProfile"
        path={locations.userProfile}
        component={UserProfile}
      />
      <RestrictedRoute context="users" exact path="/users" component={Users} />
      <RestrictedRoute
        context="scheduler"
        path="/scheduler"
        component={Scheduler}
      />
      <RestrictedRoute
        context="missedCalls"
        path="/missed-calls"
        component={MissedCalls}
      />
      <RestrictedRoute
        context="vendorProfile"
        path={locations.vendorProfile}
        component={VendorProfile}
      />
      <RestrictedRoute
        context="createHospital"
        path={locations.createNewHospital}
        component={CreateNewHospital}
      />
      <RestrictedRoute
        context="roomProfile"
        path={locations.roomProfile}
        component={RoomProfile}
      />
      <RestrictedRoute
        context="departmentProfile"
        path={locations.departmentProfile}
        component={DepartmentProfile}
      />
      <RestrictedRoute
        context="createVendor"
        path={locations.createNewVendor}
        component={CreateNewVendor}
      />
      <RestrictedRoute
        context="vendors"
        exact
        path={locations.vendors}
        component={Vendors}
      />
      <RestrictedRoute
        context="hospitalProfile"
        path={locations.hospitalProfile}
        component={HospitalProfile}
      />
      <RestrictedRoute
        context="hospitals"
        exact
        path={locations.hospitals}
        component={Hospitals}
      />
      <RestrictedRoute
        context="accountCreation"
        path={locations.accountCreation}
        component={AccountCreation}
      />
      <RestrictedRoute
        context="brands"
        path={locations.brands}
        component={Brands}
      />
      <RestrictedRoute
        context="billing"
        exact
        path={locations.billing}
        component={Billing}
      />
      <RestrictedRoute
        context="invoices"
        path={locations.invoices}
        component={InvoiceHistory}
      />
      <RestrictedRoute
        context="consoleProfile"
        path={locations.consoleProfile}
        component={ConsoleProfile}
      />
      <RestrictedRoute
        context="consoles"
        exact
        path={locations.consoles}
        component={Consoles}
      />
      <RestrictedRoute
        context="hospitalNetwork"
        exact
        path={locations.hospitalNetwork}
        component={HospitalNetwork}
      />
      <RestrictedRoute
        context="hospitalApprovalList"
        exact
        path={locations.hospitalApprovalList}
        component={HospitalApprovalList}
      />
      <RestrictedRoute
        context="vendorApprovalList"
        exact
        path={locations.vendorApprovalList}
        component={VendorApprovalList}
      />
      <RestrictedRoute
        context="providerProfile"
        exact
        path={locations.providerProfile}
        component={ProviderProfile}
      />
      <RestrictedRoute
        context="provider"
        exact
        path={locations.provider}
        component={Provider}
      />
      <RestrictedRoute
        context="distributors"
        exact
        path={locations.distributors}
        component={Distributors}
      />
      <RestrictedRoute
        context="cdr"
        exact
        path={locations.cdr}
        component={CallDetailRecord}
      />
    </>
  );
};
