import { push } from 'connected-react-router';
import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import ApiHelper from '../../Common/Services/ApiHelper';
import { AUTH } from './AuthenticationActionTypes';
import resources from '../../Common/Constants/resources';
import {
	userSessionService,
	notificationService,
	securityService,
	urlService,
} from '../../Common/Services';
import { HOME } from '../Home/HomeActionTypes';
import userTypes, { roles } from '../../Common/Constants/userTypes';
import { logoutAction } from './AuthenticationActions';
import { USERS } from '../Home/Features/Users/UsersActionTypes';
import { createCaptchaHeaders } from "src/services/ReCaptchaHelper";

export function* submitLogin(action) {
		const xHeaders = createCaptchaHeaders(action.payload);
    const { username } = action.payload;
		let response, responseData;
    try {
      response = yield call(
        ApiHelper.login,
        "auth",
        resources.login,
        action.payload,
        false,
        xHeaders
      );
    } catch (e) {
      yield notificationService.error(e);
    }
    yield put({
      type: AUTH.SET_AUTH_ERROR,
      payload: "",
    });

    if (response) {
      responseData = { ...response.content, username };
    }

		if (response?.statusCode >= 400) {
      if (response.statusCode === 401) {
        yield notificationService.error(response.errorMessage);
      } else if (response.statusCode === 429 ) {
        yield put({
          type: AUTH.SET_AUTH_ERROR,
          payload: response?.errorMessage,
        });
        yield userSessionService.setMfaAuthInfo('temp_auth_registered');
        yield put(push(`/otp-suspended`));
      } else if (response.statusCode === 423 ) {
        yield put({
          type: AUTH.SET_AUTH_ERROR,
          payload: response?.errorMessage,
        });
        yield put(push(`/otp-lockout`));
      } else {
        yield notificationService.error(response?.errorMessage);
      }
    } else if (
			response?.content?.mfaToken &&
			Array.isArray(response.content.mfaType)
    ) {
      // MFA user will always go through MFA, even if eventually
      const { phone } = response?.content;
			yield userSessionService.setMfaAuthInfo(responseData);
      yield all([
			  put({
					type: HOME.MFA_LOGIN_SELECT_CHANNEL,
					payload: response?.content,
				}),
        put({
					type: HOME.MFA_LOGIN_SETUP_PHONE,
					payload: phone,
				}),
				put(push("/select-mfa-type")),
			]);
		} else if (
			response?.content?.mfaToken &&
			response?.content?.status === "IN_PROCESS"
		) {
			yield userSessionService.setMfaAuthInfo(responseData);
			yield all([
        put({
          type: AUTH.SET_OTP_INFO,
          payload: responseData,
        }),
				put({
					type: HOME.MFA_LOGIN_SELECT_CHANNEL,
					payload: response?.content,
				}),
				put({
					type: HOME.MFA_LOGIN_SETUP_PHONE,
					payload: responseData?.phone,
				}),
				put(push("/verify-passcode")),
			]);
		} else if (response?.content) {
			const { profiles } = response.content;

			// Check for multi-profile user
			if (profiles?.length) {
				yield all([
					put(push("/multi-profile-login")),
					put({
						type: HOME.SET_CURRENT_USER_INFO,
						payload: response.content,
					}),
				]);
			} else {
				// eslint-disable-next-line no-use-before-define
				yield postLogin(response.content);
			}
		}
}

export function* authenticateEmail(action) {
  const { username } = action.payload;
  const payload = { ...action.payload, password: '' };
  const xHeaders = createCaptchaHeaders(action.payload);
  let response;
  yield put({
    type: AUTH.SET_OTP_INFO,
    payload: { username },
  });

  try {
    response = yield call(ApiHelper.login, 'auth', resources.login, payload, false, xHeaders);
    if (response.statusCode === 401) {
      if (response.errorMessage.includes('User account locked')) {
        yield put({
          type: AUTH.SET_AUTH_ERROR,
          payload: response.errorMessage,
        });
        yield put(push(`/otp-failure`));
        return;
      } else {
        yield userSessionService.setMFAAuthData({});
        yield put(push(`/auth-user-login`));
        return;
      }
    } else if (response.statusCode === 429 ) {
      yield put({
        type: AUTH.SET_AUTH_ERROR,
        payload: response.errorMessage,
      });
      yield userSessionService.setMfaAuthInfo('temp_auth_guest');
      yield put(push(`/otp-suspended`));
    } else if (response.statusCode === 423 ) {
      yield put({
        type: AUTH.SET_AUTH_ERROR,
        payload: response.errorMessage,
      });
      yield put(push(`/otp-lockout`));
    }
  }
  catch (e) {
    yield notificationService.error(e);
  }

  const responseData = { ...response.content, username };
  // redirects to MFA page if it is enabled
  if (response?.content?.twoFactorAuthEnabled) {
    yield userSessionService.setMFAAuthData(responseData);
    yield put({
      type: AUTH.SET_AUTH_ERROR,
      payload: "",
    });
    yield put({
      type: AUTH.SET_OTP_INFO,
      payload: responseData,
    });
    notificationService.success(response.content.message)
    yield put(push(`/verify-guest-otp`));
  }
}

export function* guestUserRegister(action) {
  try {
    const { userId } = JSON.parse(sessionStorage.getItem('cachedUserInfo'));
    const payload = { ...action.payload, userId }
		yield call(ApiHelper.put, 'user', urlService.formatString(resources.guestUserRegister, userId), payload);

    const currentUser = yield call(ApiHelper.get, 'user', urlService.formatString(resources.user, userId));
    yield userSessionService.setCachedUserInfo(currentUser.content);
    yield all([
      put({
        type: USERS.SET_USER,
        payload: currentUser.content,
      }),
      put({
        type: HOME.SET_CURRENT_USER_INFO,
        payload: currentUser.content,
      }),
      put({
        type: AUTH.SUBMIT_LOGIN_SUCCESS,
      })
  ]);

    // Guests should always have a redirect URL because
    // email links are there only want into the app
    const redirectUrl = userSessionService.getRedirectUrl();

		yield put(push(redirectUrl));
  } catch (e) {
		yield notificationService.error(e);
  }
}

export function* multiProfileLogin(action) {
  const { profile, hasPhoneNumber, isTwoFactorEnabled } = action.payload;
  const redirectUrl = userSessionService.getRedirectUrl();
  const resetPassword = userSessionService.getResetPassword();

	try {
		const currentUser = yield select(
			(state) => state.homeReducer.currentUser
		);
    const isTwoFactor = isTwoFactorEnabled || currentUser.isTwoFactorAuthEnabled;
		const updatedUser = yield ApiHelper.post(
			"auth",
			resources.refreshToken,
			{ token: currentUser.refresh_token, profileId: profile.id }
		);

		// eslint-disable-next-line no-throw-literal
		if (updatedUser.error) {
			throw `${updatedUser.error}: ${updatedUser.error_description}`;
		}

		if (!hasPhoneNumber) {
      yield all([
        put({
          type: HOME.SET_USER_FOR_REDIRECT,
          payload: updatedUser,
        }),
      ]);
      yield postLogin(updatedUser);
    } else if (!isTwoFactor || hasPhoneNumber || redirectUrl || resetPassword) {
      // include hasPhoneNumber to avoid push to /add-phone
			yield postLogin({...updatedUser, hasPhoneNumber});
		}
	} catch (e) {
		yield notificationService.error(e);
	}
}

/**
 * Set of tasks run after user successfully logs in
 * @param {*} user User from login response
 */
export function* postLogin(user) {
	// eslint-disable-next-line camelcase
	const { access_token, refresh_token, finishedAccountSetup, isPasswordAutoGenerated,  userId, isPasswordExpired, hasPhoneNumber: userHasPhoneNumber } = user;

	yield securityService.login(access_token, refresh_token);

	const currentUser = yield call(ApiHelper.get, 'user', urlService.formatString(resources.user, userId));

	yield userSessionService.setMFAAuthData(null);
	yield userSessionService.setCachedUserInfo(currentUser.content);
	yield userSessionService.setAccountSetupStatus(finishedAccountSetup);

	yield userSessionService.setExpiredAccount(isPasswordExpired);
	yield userSessionService.setResetPassword(isPasswordAutoGenerated);

	yield all([
    // For some reason this is required to set to support the autoCreated user flow
    put({
			type: USERS.SET_USER,
			payload: currentUser.content,
		}),
		put({
			type: HOME.SET_CURRENT_USER_INFO,
			payload: currentUser.content,
		}),
		put({
			type: HOME.GET_ACCOUNT_SETUP_STATUS,
			payload: finishedAccountSetup,
		}),
		put({
			type: HOME.GET_ACCOUNT_EXPIRE_STATUS,
			payload: isPasswordExpired,
		}),
		put({
			type: AUTH.SUBMIT_LOGIN_SUCCESS,
		}),
	]);

  let isTwoFactorAuthEnabledForOrg;
  try {
      // two factor is determined by org settings
      const response = yield call(
        ApiHelper.get,
        "coreregistry",
        urlService.formatString(resources.getOrgSettings, user.organization.id)
        );

      const orgSettings = response.content
      // loop through org settings for Two Factor Auth status
      orgSettings.forEach((payload) => {
        if (payload.type === "TWO_FACTOR_AUTH_ENABLED") {
          isTwoFactorAuthEnabledForOrg = payload.value === "true"
          return
        }
      })

    } catch (e) {
      yield notificationService.error(e);
      }
	if (isPasswordAutoGenerated) {
		yield put(push('/reset-password'));
	} else if (isPasswordExpired) {
		yield put(push(`/expire-password?userId=${user.userId}`));
	} else if (finishedAccountSetup || currentUser.content.autoCreated) {
    if (!userHasPhoneNumber && isTwoFactorAuthEnabledForOrg) {
      // prompt user to add phone number
      yield put(push("/add-phone"));
    } else {
      const redirectUrl = userSessionService.getRedirectUrl();
      userSessionService.removeRedirectUrl();
      yield redirectUrl
        ? put(push(redirectUrl))
        : put(push(userTypes[currentUser.content.userTypes[0]].redirectionRoute));
    }
  } else {
    yield put(push('/accountCreation', isTwoFactorAuthEnabledForOrg));
	}
}

export function* logout() {
	yield securityService.logout();
	yield put(push('/login'));
	yield put({
		type: AUTH.LOGOUT_SUCCESS,
	});
	window.location.href = `${window.location.origin}?_t=${Date.now()}`
}

export function* guestLogout() {
  // REFACTOR
	yield call(securityService.logout);
	window.location.href = `${window.location.origin}/event-login`
}

function* signUp(action) {
	const xHeaders = createCaptchaHeaders(action.payload);
	const user = {
		...action.payload,
		orgId: action.payload.email, // ISR doesn't have orgId, but prop is necessary, so send email as placeholder
		userType: roles.individualSalesRep,
	};

	try {
		yield call(ApiHelper.postWithoutToken, 'user', 'sign-up', user, false, xHeaders);
		yield all([
			notificationService.success('You successfully signed up to Avail'),
			put(push('/login')),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* forgotPassword(action) {
	try {
		const xHeaders = createCaptchaHeaders(action.payload);
		yield call(ApiHelper.postWithoutToken, 'user', urlService.formatString(resources.forgotPassword, action.payload.email), {}, false, xHeaders);
		yield all([
			notificationService.success('You successfully sent reset password request to Avail.'),
			put(push('/check-email-confirm')),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* resetPassword(action) {
	try {
		yield call(ApiHelper.put, 'user', urlService.formatString(resources.resetPassword,
			action.payload.email), action.payload);

		yield all([
			notificationService.success('You successfully resetted your password.'),
			put(logoutAction()),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* mfaAuthentication(action) {
  try {
		const response = yield call(ApiHelper.postMFAAuth, 'auth', resources.mfaAuthentication, action.payload);
		const { profiles } = response.content;

		if (profiles && profiles.length) { // Check for multi-profile user
			yield put({
				type: HOME.SET_CURRENT_USER_INFO,
				payload: response.content,
			});

			yield put(push(`/multi-profile-login`));
		} else {
			// eslint-disable-next-line no-use-before-define
			yield postLogin(response.content);
		}
	} catch (e) {
		if (e.errorCode === 401){
			yield put(push('/login'));
		}
    yield notificationService.error(e);
	}
}

function* guestUserAuthentication(action) {
  let response;
	try {
		response = yield call(ApiHelper.postMFAAuth, 'auth', resources.mfaAuthentication, action.payload);
		if (response.statusCode === 401) {
      yield put({
        type: AUTH.SET_AUTH_ERROR,
        payload: response.errorMessage,
      });
      return;
		} else if (response.statusCode === 423) {
      yield put(push('/otp-lockout'));
      return;
    } else if (response.statusCode === 429) {
      yield put({
        type: AUTH.SET_AUTH_ERROR,
        payload: response.errorMessage,
      });
      yield put(push('/otp-failure'));
      return;
    }
  } catch (e) {
    yield notificationService.error(e);
	}

  const { access_token, refresh_token, userId } = response.content;
  yield securityService.login(access_token, refresh_token);

  try {
    const { content } = yield call(ApiHelper.get, 'user', urlService.formatString(resources.guestUserRegister, userId));
    yield userSessionService.setCachedUserInfo({...response.content, ...content});
  } catch (e) {
    yield userSessionService.setCachedUserInfo(response.content);
  }

  yield put(push('/guest-user-register'));
}

function* resendOTP(action) {
	try {
		const response = yield call(ApiHelper.get, 'auth', resources.resendOTP, action.payload);
		if (response && response.content && response.content.twoFactorAuthEnabled) {
			yield userSessionService.setMFAAuthData(response.content);
			const expireTime = response.content.otpExpiryTime;
			yield put({
				type: AUTH.SET_OTP_INFO,
				payload: response.content,
			});
			yield put(push('/login'));
			yield put(push('/verify-mfa-otp'));
			notificationService.success(response.content.message);
		}
	} catch (e) {
		if (e.errorCode === 401){
			yield put(push('/login'));
		}
		yield notificationService.error(e);
	}
}

function* resendGuestOTP(action) {
	try {
		const response = yield call(ApiHelper.get, 'auth', resources.resendOTP, action.payload);
		if (response.statusCode === 429) {
      yield put({
        type: AUTH.SET_AUTH_ERROR,
        payload: response.errorMessage,
      });
      yield put(push('/otp-failure'));
    } else if (response?.content?.twoFactorAuthEnabled) {
			yield userSessionService.setMFAAuthData(response.content);
			yield put({
				type: AUTH.SET_OTP_INFO,
				payload: response.content,
			});
      yield put({
        type: AUTH.SET_AUTH_ERROR,
        payload: '',
      });
			yield put(push('/verify-guest-otp'));
			notificationService.success(response.content.message);
		}
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* sendMfaLoginOtp(action) {
	try {
		const response = yield call(
			ApiHelper.get,
			"auth",
			resources.sendMfaLoginOtp,
			action.payload
		);

		yield put({
			type: AUTH.SET_MFA_CHANNEL,
			payload: action.payload.mfa_type,
		});

		if (response && response.mfaToken) {
			yield put(push("/verify-passcode"));
		}
	} catch (e) {
		// TODO: refactor this to get 429, 401 and 423(lock)"
		yield put({
			type: AUTH.SET_AUTH_ERROR,
			payload: e,
		});
	}
}

export function* verifyMfaLoginOtp(action) {
	const { mfaToken, otp, hasPhoneNumber } = action.payload;

	try {
		const response = yield call(
			ApiHelper.get,
			"auth",
			urlService.formatString(resources.verifyMfaLoginOtp, mfaToken, otp)
		);

		if (response.statusCode === 401) {
			yield put({
				type: AUTH.SET_AUTH_ERROR,
				payload: response.errorMessage,
			});
		} else if (response.statusCode === 429) {
			yield put({
				type: AUTH.SET_AUTH_ERROR,
				payload: response.errorMessage,
			});
			yield put(push("/suspended"));
		} else if (response.statusCode === 423) {
			yield put(push("/user-denied"));
		} else if (response?.content) {
      const { content: { profiles}}  = response;
			// Check for multi-profile user
			if (profiles?.length) {
        yield all([
          put({
            type: HOME.SET_CURRENT_USER_INFO,
            payload: response.content,
          }),
          put(push("/multi-profile-login"))
        ]);
			} else {
        yield postLogin({ ...response.content, hasPhoneNumber });
			}
		}
	} catch (e) {
		yield notificationService.error(e);
	}
}

export function* resendMfaLoginOtp(action) {
	const { mfaToken } = action.payload;

	try {
		const response = yield call(
			ApiHelper.get,
			"auth",
			urlService.formatString(resources.resendMfaLoginOtp, mfaToken)
		);

    if (response.statusCode === 429) {
			yield put({
				type: AUTH.SET_AUTH_ERROR,
				payload: response.errorMessage,
			});
		} else if (response && response.content.mfaToken) {
			yield put({
				type: AUTH.SET_AUTH_ERROR,
				payload: "",
			});
      notificationService.success(response.content.message)
			yield put(push("/verify-passcode"));
		}
	} catch (e) {
		if (e.errorCode === 401) {
			yield put(push("/login"));
		}
		yield notificationService.error(e);
	}
}

export function* setupPhoneNumber(action) {
	const { user, setupPhone } = action.payload;
  if (setupPhone)
    yield put(push(`/users/profile?userId=${user.id}#${resources.tabs.security}`));
  else {
    const redirectUrl = userSessionService.getRedirectUrl();
    userSessionService.removeRedirectUrl();
    yield redirectUrl
      ? put(push(redirectUrl))
      : put(push(userTypes[user.userTypes[0]].redirectionRoute));
  }
}

export default function* authSaga() {
	yield takeLatest(AUTH.SUBMIT_LOGIN, submitLogin);
	yield takeLatest(AUTH.CHECK_GUEST_EMAIL, authenticateEmail);
	yield takeLatest(AUTH.MULTI_PROFILE_LOGIN, multiProfileLogin);
	yield takeLatest(AUTH.REGISTER_GUEST_USER, guestUserRegister);
	yield takeLatest(AUTH.LOGOUT, logout);
	yield takeLatest(AUTH.GUEST_LOGOUT, guestLogout);
	yield takeLatest(AUTH.SIGN_UP, signUp);
	yield takeLatest(AUTH.FORGOT_PASSWORD, forgotPassword);
	yield takeLatest(AUTH.RESET_PASSWORD, resetPassword);
	yield takeLatest(AUTH.MAIL_AUTH, mfaAuthentication);
	yield takeLatest(AUTH.AUTH_GUEST_USER, guestUserAuthentication);
	yield takeLatest(AUTH.RESEND_OTP, resendOTP);
	yield takeLatest(AUTH.RESEND_GUEST_OTP, resendGuestOTP);
	yield takeLatest(AUTH.MFA_SEND_LOGIN_OTP, sendMfaLoginOtp);
	yield takeLatest(AUTH.MFA_VERIFY_LOGIN_OTP, verifyMfaLoginOtp);
	yield takeLatest(AUTH.MFA_RESEND_LOGIN_OTP, resendMfaLoginOtp);
	yield takeLatest(AUTH.MFA_SETUP_PHONE_NUMBER, setupPhoneNumber);
}
