import { useQuery } from "react-query";

import { FetchError } from "src/services/ApiClient/ApiQuery";
import {
  getTrialUsageByUserId,
  getUserCallHistoryByUserId,
  TrialUsageResponse,
  UserCallHistoryResponse,
} from "src/services/ApiClient/trialUsage";
import { User, UserDetails } from "src/services/ApiClient/users/types";
import { isTrialUser } from "src/utils/user";

const TRIAL_USAGE_KEY = "user-trial-usage";
const CALL_HISTORY_KEY = "user-call-history";

// This particular hook function checks if the user is a TRIAL type user
// before making any API calls
export const useGetUserTrialUsage = (user: User | UserDetails) => {
  return useQuery<TrialUsageResponse, FetchError>(
    [`${TRIAL_USAGE_KEY}-${user?.id}`],
    () => {
      // if the user is not trial, don't bother calling the balance report API
      const isUserTrial = isTrialUser(user);
      if (!isUserTrial) {
        return null;
      }

      return getTrialUsageByUserId(user?.id);
    },
    {
      enabled: !!user?.id, // don't run this query unless a valid user object is provided
    }
  );
};

export const useGetUserCallHistory = (userId: string, page: number) => {
  return useQuery<UserCallHistoryResponse, FetchError>(
    [`${CALL_HISTORY_KEY}-${userId}-${page}`], // dependencies
    () => {
      return getUserCallHistoryByUserId(userId, page);
    }
  );
};
