import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { Fields, Button, validators } from '../../../../Common/Components';

import {forgotPasswordAction} from '../../AuthenticationActions';
import withAuthentication from '../../Components/withAuthentication';
import { reCaptchaWrapper } from 'src/services/ReCaptchaHelper';

import { LoginPage } from "/src/domains/Login";

class ForgetPassword extends React.PureComponent {
	handleLoginFormSubmit = formData => {
		reCaptchaWrapper('forgotPassword', (captchaVersion, captchaToken) => this.props.forgetPassword({
			email : formData.email.toLowerCase(), 
			captchaToken,
			captchaVersion
		}));
	};

	redirect = () => this.props.history.push('/login');

	render() {
		const { handleSubmit } = this.props;
		return (
			<LoginPage className="login">
        <div className="login__subheading">Please provide your login email address to request a password reset</div>
        <form className="login__form" onSubmit={handleSubmit(this.handleLoginFormSubmit)}>
          <Fields.InputField
            labelText="Email"
            placeholder="Type your email"
            name="email"
            validate={[validators.required, validators.email]}
          />

          <div className="justify-content--flex-end">
            <Button
              isGhost
              className="login__button"
              onClick={this.redirect}
            >
              Cancel
            </Button>
            <Button
              isFull
              isGreen
              className="login__button"
              type="submit"
            >
              Request
            </Button>
          </div>
        </form>
        </LoginPage>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	forgetPassword: userData => dispatch(forgotPasswordAction(userData)),
});

export default reduxForm({ form: 'forgotPasswordForm' })(withAuthentication(connect(undefined, mapDispatchToProps)(ForgetPassword)));
