import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Can from '../../../../../../Common/Acl/AccessControl';
import { getcurrentUserTypes } from '../../../../HomeSelectors';
import Icon from '../../../../../../Common/Components/Icon/Icon';
import './TableBody.scss';

class TableBody extends PureComponent {
	state = {
		activeColumn: '',
	};

	onSortTableHandler = clickedColumn => () => {
		if (this.props.sortable) {
			if (clickedColumn !== this.state.activeColumn) {
				this.direction = 'asc';
			} else {
				this.direction = this.direction === 'asc' ? 'desc' : 'asc';
			}
			this.setState({
				activeColumn: clickedColumn,
			});
			const sortParam = `${clickedColumn},${this.direction}`;
			this.props.onSortTableHandler(sortParam, 'sort');
		}
	};

	render() {
		const Row = this.props.row;
		return (
			<React.Fragment>
				<Table
					fixed
					className={classNames({
						'table-header__wrapper': true,
						[this.props.tableHeaderClassName]: this.props.tableHeaderClassName,
					})}
				>
					<Table.Header>
						<Table.Row>
							{this.props.columns.map(column => (
								<Table.HeaderCell
									className={classNames({
										'table__cell': true,
										'table__cell--active': this.state.activeColumn === column.sortValue,
										'justify-content--flex-end': column.action,
									})}
									key={column.title}
									onClick={column.sortValue ? this.onSortTableHandler(column.sortValue) : null}
								>
									<div className="display--flex align--center">
										<span className="table__cell-value">{column.title}</span>
										{this.state.activeColumn === column.sortValue && <Icon
											className={classNames({
												'table__cell-chevron': true,
												'rotate--180': this.direction === 'desc',
												'icon-chevron-down-outline': true,
											})}
										/>}
									</div>
								</Table.HeaderCell>
							))}

							<Can userTypes={this.props.currentUserTypes} action="read" context="editRow">
								{
									this.props.headerOptionButton ? this.props.headerOptionButton.map(option => (
										<Table.HeaderCell key={option} className="table__cell justify-content--flex-end">
											{option}
										</Table.HeaderCell>
									)) : this.props.tableActions && (
										<Table.HeaderCell
											textAlign="right"
											className="table__cell table__action-table-cell"
											width={3}
										>
											Actions
										</Table.HeaderCell>
									)
								}
							</Can>
						</Table.Row>
					</Table.Header>
				</Table>
				<div className="position--relative table__scrollable-body-wrapper">
					<div
						className={classNames({
							'table__scrollable-body': true,
							[this.props.className]: this.props.className,
						})}
					>
						<Table>
							<Table.Body>
								{this.props.tableData && this.props.tableData.map((row, idx) => (
									<Row
										rowData={row}
										key={row.id || `i${idx}`}
										openReinvitationModal={this.props.openReinvitationModal}
										openModal={this.props.openModal}
										urlParams={this.props.urlParams}
										isHospital={this.props.isHospital}
									/>))}
							</Table.Body>
						</Table>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

TableBody.defaultProps = {
	sortable: false,
	openModal: () => {},
	openReinvitationModal: () => {},
	tableActions: true,
};

TableBody.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	tableData: PropTypes.arrayOf(PropTypes.any).isRequired,
	openModal: PropTypes.func,
	openReinvitationModal: PropTypes.func,
	sortable: PropTypes.bool,
};


const mapStateToProps = state => ({
	currentUserTypes: getcurrentUserTypes(state),
});

export default withRouter(connect(mapStateToProps, undefined)(TableBody));
