import { AvailKitService } from "src/services/AvailKitService";
import SessionStorageService from '../../../../src/services/SessionStorageService';

const AUTH_TOKEN_KEYS = {
  access: 'avail_access_token',
  refresh: 'avail_refresh_token',
};

export default class SecurityService {
  static login(accessToken, refreshToken) {
    SessionStorageService.addTokens(
      AUTH_TOKEN_KEYS,
      accessToken,
      refreshToken,
    );
  }

  static logout() {
    SessionStorageService.remove('cachedUserInfo');
    SessionStorageService.remove('finishedAccountSetup');
    SessionStorageService.remove('avail_access_token');
    SessionStorageService.remove('avail_refresh_token');
    // Close connection on availKit (PubNub)
    const availKit = AvailKitService.instance;
    if (availKit) availKit.eventService.leaveAll();
  }

  static getRefreshToken() {
    return SessionStorageService.get(AUTH_TOKEN_KEYS.refresh);
  }

  static getTokenForRequest() {
    return `Bearer ${this.getToken()}`;
  }

  static getToken() {
    return SessionStorageService.get(AUTH_TOKEN_KEYS.access);
  }

  static isLoggedIn() {
    return SessionStorageService.get(AUTH_TOKEN_KEYS.access) !== null;
  }
}
