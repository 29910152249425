// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper_QzRl3r{align-items:center;display:flex;flex-direction:column}.styles_icon-wrapper_ZrZ\\+O\\+ .styles_icon_6Ue-rX{height:34px;margin-bottom:8px;width:34px}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/InputControls/InputControlsMenu/styles.scss"],"names":[],"mappings":"AACA,uBACE,kBAAA,CACA,YAAA,CACA,qBAAA,CAIA,kDACE,WAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":["\n.wrapper {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n}\n\n.icon-wrapper {\n  .icon {\n    height: 34px;\n    margin-bottom: 8px;\n    width: 34px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper_QzRl3r",
	"icon-wrapper": "styles_icon-wrapper_ZrZ+O+",
	"iconWrapper": "styles_icon-wrapper_ZrZ+O+",
	"icon": "styles_icon_6Ue-rX"
};
export default ___CSS_LOADER_EXPORT___;
