import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';
import classNames from 'classnames';

import DropdownComponent from '../../../../../Common/DropdownComponent/DropdownComponent';
/* Actions */
import { locations } from "src/utils/locations";

import { useHistory } from "react-router-dom";

import { useGetOrganizationSettings } from "src/queries/organizations";
import { useDispatch } from "react-redux";
import { toggleLoader } from "avail-web-application/Features/Home/HomeActions";
import { notificationService } from "avail-web-application/Common/Services";
import { getTrialEnabledFlagFromSettings } from "src/utils/settings";
import {
  updateOrganizationSettings,
} from "src/services/ApiClient/businessUnit";

const VendorTableRow = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  
	const redirect = () => {
		const searchTerm = props.rowData.vendorType === 'DISTRICT' ?
			`?brId=${props.rowData.brand.id}&buId=${props.rowData.parentId}&dId=${props.rowData.id}` :
			`?brId=${props.rowData.brand.id}&buId=${props.rowData.id}`;

		history.push({
			pathname: locations.vendorProfile,
			search: searchTerm,
		});
	};

	const openDeleteUserModal = () => props.openModal(props.rowData);

  const { name, address, city, state, active, brand, id } = props.rowData;

  // since we need a dropdown menu that depends on the row's setting for trial enabled
  // we will call the API using this query for each row
  const { data, isLoading, refetch } = useGetOrganizationSettings(id);

  const isTrialEnabled = data?.content && getTrialEnabledFlagFromSettings(data.content);

  // when the user clicks on the dropdown menu below we call this function
  // used to flip the TRIAL_ENABLED flag from true -> false or vice versa
  const onSubmit = async () => {
    if (data?.content) {
      const payload = {
        type: "TRIAL_ENABLED",
        value: !isTrialEnabled, // we are just trying to flip the flag
      };
  
      // dispatching loading manually since we are using new API client instead of old one
      // only really applies to old UI so perhaps its okay for now
      dispatch(toggleLoader());
      try {
        await updateOrganizationSettings(id, payload);
  
        const message = !isTrialEnabled ? "enabled" : "disabled";
        notificationService.success(
          `You successfully ${message} the trial self sign up flag`
        );

        // refetch the query so the dropdown shows the correct UI below
        // otherwise it will still say "enabled" even though we flipped the flag in this function above
        refetch();
      } catch (error) {
        notificationService.error(
          "Erorr occured while trying to update trial self sign up flag"
        );
      } finally {
        dispatch(toggleLoader());
      }
    }
    
  };

  const dropdownOptions = [];

  if (active) {
    // dropdown menu button to enable or disable trial self sign up
    dropdownOptions.push({
      className: "dropdown__menu-item",
      label: isTrialEnabled ? "Disable Self Signup" : "Enable Self Signup",
      text: isTrialEnabled ? "Disable Self Signup" : "Enable Self Signup",
      action: onSubmit,
      icon: "icon-person-add",
      disabled: isLoading
    });

    dropdownOptions.push({
      className: 'dropdown__menu-item--red',
      label: 'Delete',
      text: 'Delete',
      action: openDeleteUserModal,
      icon: 'icon-trash-2-outline',
    });
  }


  return (
    <Table.Row
      className={classNames({
        'table__row--inactive': !active,
      })}
    >
      <Table.Cell onClick={redirect} title={[name].join(' ')}>
        <div className="table__username-wrapper table__link display--flex">
          <span>{name}</span>
        </div>
      </Table.Cell>
      <Table.Cell title={[address].join(' ')}>{address}</Table.Cell>
      <Table.Cell title={[city].join(' ')}>{city}</Table.Cell>
      <Table.Cell title={[state].join(' ')}>{state}</Table.Cell>
      <Table.Cell title={[brand.name].join(' ')}>{brand.name}</Table.Cell>
      <Table.Cell className="table__action-table-cell">
        <div className="justify-content--flex-end">
          <DropdownComponent
            dropdownOptions={dropdownOptions}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
	
}

export default VendorTableRow;