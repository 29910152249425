import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { AVKUserPresetEvent } from "availkit-js/dist/Models/Events/AVKUserPresetEvent";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/domains/Beacon/store";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

// Will broadcast the preset layout event to Console
export const setUserPresetEventThunk = createAsyncThunk<
  void,
  {
    cameras?: (AVKCamera | AVKExternalInput)[];
  },
  {
    state: RootState;
  }
>("stream/setUserPresetEventThunk", ({ cameras }, { getState }) => {
  try {
    logger().info(
      "setUserPresetEventThunk - User selected a preset",
      "Remote doesn't support EIR"
    );

    const availKit = AvailKitService.instance;
    const {
      meeting: { callSid, joinId },
    } = getState();

    const avkCameras = cameras as AVKCamera[];
    const userPresetEvent = new AVKUserPresetEvent(callSid);
    userPresetEvent.cameras = avkCameras;
    userPresetEvent.sender = joinId;

    availKit?.eventService.broadcast(userPresetEvent);

    const framedCameraIds: string[] = cameras.map(
      (camera) => camera.uniqueIdentifier
    );

    logger().logWithFields(
      LoggerLevels.info,
      { feature: "Presets" },
      `setUserPresetEventThunk - User Preset Event initiated, selected cameras: ${framedCameraIds}`
    );
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      { feature: "Presets" },
      `setUserPresetEventThunk - Error while changing between presets with no EIR flag. ${error?.message}`
    );
    throw new Error("Error setting Preset Layout Event.");
  }
});
