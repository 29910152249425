import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";

import { Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { TextField } from "src/components/legacy/TextField";
import { useOnPageRefresh } from "src/hooks/useOnPageRefresh";
import { history } from "src/reducer";
import { clearUserSession } from "src/utils/clearUserSession";

import {
  submitGuestUserAuthAction,
  submitResendGuestOTPAction,
} from "avail-web-application/Features/Authentication/AuthenticationActions";
import withAuthentication from "avail-web-application/Features/Authentication/Components/withAuthentication";

import { requiredField } from "avail-web-ui/constants/validation";
import DomainConstants from "avail-web-ui/domains/DomainConstants";

import { LoginPage } from "../../Login";

interface DispatchProps {
  resendOTP: (data) => void;
  submitGuestUser: (data) => void;
}

interface StateProps {
  errorMessage: string;
  otpInfo: any;
}

type Props = StateProps & DispatchProps;

function MailOTPAuthentication({
  errorMessage,
  otpInfo,
  resendOTP,
  submitGuestUser,
}: Props) {
  useOnPageRefresh(() => clearUserSession());

  useEffect(() => {
    const mfaAuthDetails = localStorage.getItem("MFAAuthDetails");
    if (!mfaAuthDetails) {
      history.push("/event-login");
    }
  }, []);

  const { control, handleSubmit, formState } = useForm({ mode: "onChange" });

  const handleMailAuthFormSubmit = (data) => {
    const { mfaToken, password, username } = otpInfo;
    if (mfaToken !== null && mfaToken !== undefined) {
      const mfaData = {
        otp: data.otp,
        mfa_token: otpInfo.mfaToken,
        guestUser: true,
        username,
        password,
      };
      submitGuestUser(mfaData);
    }
  };

  const handleResendOTP = () => {
    if (otpInfo.mfaToken !== null && otpInfo.mfaToken !== undefined) {
      const mfaData = {
        mfa_token: otpInfo.mfaToken,
      };
      resendOTP(mfaData);
    }
  };

  return (
    <LoginPage className="login">
      <div className="login__subheading">
        {DomainConstants.label.loginGuest}
      </div>
      <form
        className="login__form"
        onSubmit={handleSubmit(handleMailAuthFormSubmit)}
        data-test-id="otp-form"
      >
        <Controller
          name={DomainConstants.key.otp}
          control={control}
          defaultValue=""
          rules={{
            ...requiredField(DomainConstants.label.oneTimePassword),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <TextField
                  label={DomainConstants.label.oneTimePassword}
                  placeholder={DomainConstants.placeholder.password}
                  required
                  onChange={onChange}
                  value={value}
                />
                {error && (
                  // TODO move error message to own component
                  <span style={{ color: "red" }}>{error.message}</span>
                )}
              </>
            );
          }}
        />
        {/* Non-validation, server side Error Message */}
        <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
        <br />
        <div className="justify-content--flex-end">
          <Button
            disabled={!formState.isValid}
            fullWidth
            style={{ textTransform: "none" }}
            theme="primary"
            type="submit"
          >
            {DomainConstants.label.next}
          </Button>
        </div>
      </form>
      <Typography variant="body1" gutterBottom sx={{ mt: 4 }}>
        We sent a One Time Password to your email.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
        Didn't receive Password?&nbsp;
        <a
          onClick={handleResendOTP}
          className="login__sign-up-button"
          data-test-id="reset-otp-button"
        >
          Resend
        </a>
      </Typography>
    </LoginPage>
  );
}

const mapStateToProps = (state) => ({
  errorMessage: state.authenticationReducer.errorMessage,
  otpInfo: state.authenticationReducer.otpInfo,
});

const mapDispatchToProps = (dispatch) => ({
  submitGuestUser: (MFAData) => dispatch(submitGuestUserAuthAction(MFAData)),
  resendOTP: (MFAData) => dispatch(submitResendGuestOTPAction(MFAData)),
});

export default withAuthentication(
  connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )(MailOTPAuthentication)
);
