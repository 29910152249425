import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './AddNewButtonWithIcon.scss';
import { Icon } from '../../../../Common/Components';

class AddNewButtonWithIcon extends PureComponent {
	onClickHandler = () => this.props.onClick(this.props.argument);

	render() {
		return (
			<a className="align--center add-new-button__wrapper" onClick={this.onClickHandler}>
				<Icon className={this.props.iconClass} />
				<span
					className={classNames({
						'add-new-button__title': true,
						[this.props.titleClass]: this.props.titleClass,
					})}
				>

					{this.props.title}
				</span>
			</a>
		);
	}
}

AddNewButtonWithIcon.propTypes = {};

export default AddNewButtonWithIcon;
