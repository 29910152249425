import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";
import { NIncomingServerCallEvent } from "availkit-js/dist/Models/Events/NIncomingServerCallEvent";

import {
  CallBackEventStateType,
  CallModeType,
  CallStateType,
  FreezeFrameStateType,
  LayoutFrame,
  MultiPartyEventDetail,
  MultiPartyCallEventInfo,
  UserRoleType,
  ZoomStateType,
  MultiPartyInitializeState,
} from "../models";
import { MeetingActionKeys, MeetingActionType } from "./actionTypes";

export const setCallSid = (callSid: string): MeetingActionType => ({
  type: MeetingActionKeys.SET_CALL_SID,
  callSid,
});

export const setCallState = (data: CallStateType): MeetingActionType => ({
  type: MeetingActionKeys.SET_CALL_STATE,
  callState: data,
});

export const getMultiPartyEventDetails = (
  eventDetails: MultiPartyCallEventInfo
) => ({
  type: MeetingActionKeys.GET_MULTI_PARTY_CALL_DETAILS,
  payload: eventDetails
})

export const initializeMultiPartyEvent = (
  meetingToken: MultiPartyInitializeState
) => ({
  type: MeetingActionKeys.INITIALIZE_MULTI_PARTY_CALL,
  payload: meetingToken,
});

export const setMultiPartyEventCallInfo = (
  multiPartyCallEventInfo: MultiPartyCallEventInfo
): MeetingActionType => ({
  type: MeetingActionKeys.SET_MULTI_PARTY_CALL_EVENT_INFO,
  multiPartyCallEventInfo,
});

export const setCallMode = (mode: CallModeType) => ({
  type: MeetingActionKeys.SET_CALL_MODE,
  payload: mode,
});

export const setMultiPartyEventDetails = (
  multiPartyCallEventDetail: MultiPartyEventDetail
): MeetingActionType => ({
  type: MeetingActionKeys.SET_MULTI_PARTY_EVENT_DETAILS,
  multiPartyCallEventDetail,
});

export const setCurrentUserRole = (
  userRole: UserRoleType
): MeetingActionType => ({
  type: MeetingActionKeys.SET_CURRENT_USER_ROLE,
  userRole,
});

export const setMultiPartyTelestrationHistory = (
  telestrationHistory: AVKAnnotationEvent[]
): MeetingActionType => {
  return {
    type: MeetingActionKeys.SET_TELESTRATION_HISTORY,
    telestrationHistory,
  };
};

export const setCallInfo = (
  data: NIncomingServerCallEvent
): MeetingActionType => ({
  type: MeetingActionKeys.SET_REMOTE_EVENT,
  callEventInfo: data,
});

export const setCallbackInfo = (
  data: CallBackEventStateType
): MeetingActionType => ({
  type: MeetingActionKeys.SET_CALLBACK_INFO,
  callbackInfo: data,
});

export const setCallback = (callback: boolean): MeetingActionType => ({
  type: MeetingActionKeys.SET_CALLBACK,
  callback,
});

export const setCamerasList = (cameras: Array<AVKCamera | AVKExternalInput>): MeetingActionType => ({
  type: MeetingActionKeys.SET_CAMERAS_LIST,
  cameras,
});

export const setCamerasHaveupdate = (
  shouldUpdateCameras: boolean
): MeetingActionType => ({
  type: MeetingActionKeys.SET_CAMERAS_HAVE_UPDATE,
  shouldUpdateCameras,
});

export const setEnableInCallControls = (
  enableInCallControls: boolean
): MeetingActionType => ({
  type: MeetingActionKeys.SET_SHOULD_ENABLE_IN_CALL_CONTROLS,
  enableInCallControls,
});

export const setLayoutFrames = (
  layoutFrames: LayoutFrame[]
): MeetingActionType => ({
  type: MeetingActionKeys.SET_LAYOUTFRAMES_LIST,
  layoutFrames,
});

export const setLayoutHasUpdate = (isUpdated: boolean): MeetingActionType => ({
  type: MeetingActionKeys.SET_LAYOUT_HAS_UPDATE,
  isUpdated,
});

export const setTriggerRefreshFrames = (
  shouldTriggerRefreshFrames: boolean
): MeetingActionType => ({
  type: MeetingActionKeys.SET_TRIGGER_REFRESH_FRAMES,
  shouldTriggerRefreshFrames,
});

export const setNoiseCancellationAction = (
  noiseCancellation: boolean
): MeetingActionType => ({
  type: MeetingActionKeys.SET_NOISE_CANCELLATION_STATE,
  noiseCancellation,
});

export const setNoiseCancellationAvailableAction = (
  noiseCancellationAvailable: boolean
): MeetingActionType => ({
  type: MeetingActionKeys.SET_NOISE_CANCELLATION_AVAILABLE,
  noiseCancellationAvailable,
});

export const setConsoleHasExternalInputResizingAction = (
  consoleHasExternalInputResizing: boolean
): MeetingActionType => ({
  type: MeetingActionKeys.SET_EXTERNAL_INPUT_RESIZING,
  consoleHasExternalInputResizing,
});

export const setBluetoothEnabledAction = (
  bluetoothEnabled: boolean
): MeetingActionType => ({
  type: MeetingActionKeys.SET_BLUETOOTH_ENABLED,
  bluetoothEnabled,
});

export const setZoomState = (zoomState: ZoomStateType): MeetingActionType => ({
  type: MeetingActionKeys.SET_ZOOMSTATE,
  zoomState,
});

export const setJoinId = (joinId: string) => ({
  type: MeetingActionKeys.SET_JOIN_ID,
  joinId,
});

export const setFreezeFrameState = (
  freezeFrameState: FreezeFrameStateType
): MeetingActionType => ({
  type: MeetingActionKeys.SET_FREEZE_STATE,
  freezeFrameState,
});

export const setFreezeFrameHasUpdate = (
  updateFreezeFrame: boolean
): MeetingActionType => ({
  type: MeetingActionKeys.SET_FREEZEFRAME_HAS_UPDATE,
  updateFreezeFrame,
});
