import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import UserService from '../../../../../../Common/Services/userService';
import { ProfileImage } from '../../../../Common';
import { getUserByIdAction } from '../../../Users/UsersActions';
import userTypes from '../../../../../../Common/Constants/userTypes';

class UserAvatar extends PureComponent {
	componentDidMount() {
		this.props.getUserById(this.props.userId);
	}

	render() {
		const { user } = this.props;
		return (
			<React.Fragment>
				{
					user &&
					<div className="user-avatar">
						<ProfileImage
							size="md"
							initials={UserService.getUserInitials(user.firstName, user.lastName)}
							className={`profile-image--${userTypes[user.userTypes[0]].className} `}
						/>
						<span className="user-avatar__name">
							{`${user.firstName} ${user.lastName}`}
						</span>
					</div>
				}
			</React.Fragment>
		);
	}
}
const mapStateToProps = state => ({
	user: state.usersReducer.user,
});

const mapDispatchToProps = dispatch => ({
	getUserById: id => dispatch(getUserByIdAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAvatar);
