import { all } from 'redux-saga/effects';

import UsersSaga from './Features/Home/Features/Users/UsersSaga';
import vendorsSaga from './Features/Home/Features/Organisation/Vendors/VendorsSaga';
import hospitalsSaga from './Features/Home/Features/Organisation/Hospitals/HospitalsSaga';
import authSaga from './Features/Authentication/AuthenticationSaga';
import schedulerSaga from './Features/Home/Features/Scheduler/SchedulerSaga';
import accountCreationSaga from './Features/Home/Features/AccountCreation/AccountCreationSaga';
import organisationsSaga from './Features/Home/Features/Organisation/OrganisationSaga';
import departmentsSaga
	from './Features/Home/Features/Organisation/Hospitals/Features/DepartmentProfile/DepartmentsSaga';
import brandsSaga from './Features/Home/Features/Brands/BrandsSaga';
import billingSaga from './Features/Home/Features/Organisation/Billing/BillingSaga';
import consolesSaga from './Features/Home/Features/Consoles/ConsolesSaga';
import approvalListSaga from './Features/Home/Common/ApprovalList/ApprovalListSaga';
import hospitalNetworkSaga from './Features/Home/Features/HospitalNetwork/HospitalNetworkSaga';
import meetingSaga from './Features/Home/Features/Meeting/MeetingSaga';
import providerSaga from './Features/Home/Features/Provider/ProviderSaga';
import distributorsSaga from './Features/Home/Features/Distributors/DistributorsSaga';
import cdrSaga from './Features/Home/Features/Organisation/CallDetailRecord/CallDetailRecordSaga';
import homeSaga from './Features/Home/HomeSaga';

export default function* rootSaga() {
	yield all([
		homeSaga(),
		UsersSaga(),
		vendorsSaga(),
		hospitalsSaga(),
		authSaga(),
		schedulerSaga(),
		accountCreationSaga(),
		departmentsSaga(),
		organisationsSaga(),
		brandsSaga(),
		consolesSaga(),
		approvalListSaga(),
		hospitalNetworkSaga(),
		providerSaga(),
		billingSaga(),
		distributorsSaga(),
		meetingSaga(),
		cdrSaga(),
	]);
}
