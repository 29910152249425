import { BILLING } from './BillingActionTypes';

const initialState = {
	billing: null,
	subscription: null,
	invoices: [],
	usageInfo: null,
	billingCycles: [
		{
			month: 1,
			name: 'Net 30',
		},
		{
			month: 2,
			name: 'Net 60',
		},
		{
			month: 3,
			name: 'Net 90',
		},
	],
	sourceTypes: [
		{
			type: 'card',
			name: 'Credit Card',
		},
		{
			type: 'bank_account',
			name: 'ACH',
		},
	],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case BILLING.SET_VENDOR_PLAN:
		case BILLING.SET_VENDOR_SUBSCRIPTION:
			return {
				...state,
				billing: action.payload,
			};
		case BILLING.SET_VENDOR_INVOICES:
			return {
				...state,
				invoices: action.payload,
			};
		case BILLING.SET_USAGE_INFO_BY_VENDOR_ID:
			return {
				...state,
				usageInfo: action.payload,
			};
		default:
			return state;
	}
}
