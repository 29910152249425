// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root_0TdeZu{padding:24px 16px}.styles_root_0TdeZu .styles_head_A3x8Gp{margin-bottom:16px}.styles_root_0TdeZu .styles_head_A3x8Gp .styles_title_pyo6gQ{margin-bottom:8px}.styles_root_0TdeZu .styles_head_A3x8Gp .styles_description_Z64uB0{margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/SidePanel/SettingsContent/NetworkStatusSettings/styles.scss"],"names":[],"mappings":"AAAA,oBACE,iBAAA,CAEA,wCACE,kBAAA,CAEA,6DAAA,iBAAA,CACA,mEAAA,iBAAA","sourcesContent":[".root {\n  padding: 24px 16px;\n\n  .head {\n    margin-bottom: 16px;\n\n    .title {margin-bottom: 8px;}\n    .description {margin-bottom: 8px;}\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root_0TdeZu",
	"head": "styles_head_A3x8Gp",
	"title": "styles_title_pyo6gQ",
	"description": "styles_description_Z64uB0"
};
export default ___CSS_LOADER_EXPORT___;
