/**
 * PresenceServeConsoles can have multiple keys if different Consoles were used for
 *  this callSid (example: switching Consoles for same call).
 * We search for the PresenceServeConsole key that matches to the active
 * Console in the Twilio call and return the requested source.
 *
 */
import { Participant, RemoteParticipant } from "twilio-video";

import { PresenceServerConsoles } from "portalcall/commoncall/store/models";

export const extractConsoleSources = (
  sourceKey: string,
  obj: PresenceServerConsoles,
  consoleParticipant: Participant | RemoteParticipant
) => {
  // consoleParticipant.identity looks like: [client-type]-[client-id]-[device-type]-[device-instance]
  const consoleId = consoleParticipant.identity.split("-")[1];
  return obj[consoleId][sourceKey];
};
