import { useMemo, useRef } from "react";
import { useDispatch } from "react-redux";

import { SIDE_PANEL_ID } from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import { uiActions } from "src/domains/Beacon/store/ui";
import { selectSidePanelOpen } from "src/domains/Beacon/store/ui/selectors";
import { useTransitionListeners } from "src/hooks/useTransitionListeners";
import { timeoutCallback } from "src/utils/timeout";

// Controls the SidePanel transition state, updating it when the transition ends
// either when opening or closing it
export const useSidePanelTransition = (sidePanelRef: HTMLDivElement) => {
  const open = useAppSelector(selectSidePanelOpen);
  const openRef = useRef<boolean>(open);
  const dispatch = useDispatch();

  // Must update ref cause' listeners cannot read state, one line statement for detecting
  // when open changes its value, cleaner than using a useEffect
  openRef.current = useMemo(() => open, [open]);

  // Allows more accuracy if we want to know when the SidePanel is completely open
  // or close, for ex. when getting the video dimensions for telestration or freezeFrame
  const handleTransitionEnded = (e: any) => {
    if (e.target?.id === SIDE_PANEL_ID) {
      const isSidePanelOpen = openRef.current;
      dispatch(
        uiActions.setSidePanelFullyState(isSidePanelOpen ? "open" : "close")
      );
      dispatch(uiActions.setSidePanelTransitionEnded(true));

      // state === true has kicked off the changes it needs to and is set to a resting state
      // this prevents any useEffect infinite loops
      timeoutCallback(
        () => dispatch(uiActions.setSidePanelTransitionEnded(null)),
        100
      );
    }
  };

  // Must restart the Side Panel transition ended state to false
  const handleTransitionStarted = (e: any) => {
    if (e.target?.id === SIDE_PANEL_ID) {
      dispatch(uiActions.setSidePanelTransitionEnded(false));
    }
  };

  useTransitionListeners(
    sidePanelRef,
    handleTransitionStarted,
    handleTransitionEnded
  );
};
