import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Table } from '../../../../../Common';
import { getCallHistoryAction } from '../../CallDetailRecordActions';
import CallDetailRecordTableRow from '../CallDetailRecordTableRow/CallDetailRecordTableRow';
import ApiHelper from "../../../../../../../Common/Services/ApiHelper";
import resources from "../../../../../../../Common/Constants/resources";
import {notificationService} from "../../../../../../../Common/Services";
import moment from "moment";

function CallDetailRecordTable({ callHistory, pagination, to, from, getCallHistory, cdrForm }) {
	const [pageSize, setPageSize] = useState(10);

	const columns = [
		{
			title: 'Start Date/Time',
			sortValue: 'start',
		},
		{
			title: 'Duration',
			sortValue: 'duration',
		},
		{
			title: 'Username',
			sortValue: 'username',
		},
		{
			title: 'Email',
			sortValue: 'email',
		},
		{
			title: 'Call State',
			sortValue: 'callState',
		},
	];

	const handlePageChange = (e, { activePage }) => {
		getCallHistory({
			size: pageSize,
			page: activePage - 1,
			to,
			from,
		});
	};

	const handleVariablePaginationChange = (option) => {
		if (!option) return;

		setPageSize(option);
		getCallHistory((option === 'all' ? { to, from } : { size: option, page: 0, to, from }));
	};

	function hasEmptyInputs() {
		if (!cdrForm || !cdrForm.from || !cdrForm.to) {
			notificationService.warning('Please fill out the dates.');
			return true;
		}
		return false;
	}
	
	const onCdrExportHandler = async () => {
		if (hasEmptyInputs()) 
			return;

		const { from, to } = cdrForm;
		const csv = await ApiHelper.getCSV('callevent', resources.cdrExport, {
			from: moment(from).format(),
			to: moment(to).format(),
		});
		download(csv);
	}

	const download = (text) => {
		const element = document.createElement('a');
		element.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
		element.setAttribute('download', 'export');

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	};
	
	return (
		<>
			<div className="users__container cdr__height">
				<Table
					row={CallDetailRecordTableRow}
					columns={columns}
					tableData={callHistory}
					paginationConfig={pagination}
					onPageChangeHandler={handlePageChange}
					noItemsClass="height--full justify-content--center align--center"
					tableActions={false}
					variablePagination
					onVariablePaginationChange={handleVariablePaginationChange}
					cdrExport={cdrForm !== undefined}
					onAddCdrExportTitle="Export"
					onCdrExportHandler={onCdrExportHandler}
				/>
			</div>
		</>
	);
}

const mapDispatchToProps = dispatch => ({
	getCallHistory: params => dispatch(getCallHistoryAction(params)),
});

export default connect(null, mapDispatchToProps)(CallDetailRecordTable);
