import { createSelector } from 'reselect';

export const getIsLoggedIn = state => state.authenticationReducer.isLoggedIn;
const getUserByToken = state => state.usersReducer.user;

export const setInitialValues = createSelector(getUserByToken, (user) => {
	if (user) {
		return {
			city: user.city,
			firstName: user.firstName,
			lastName: user.lastName,
			organisationName: user.organisationName || null,
			state: user.state,
			title: user.title,
			unitName: user.unitName || null,
			email: user.email,
		};
	}
	return null;
});
