import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { publishAudioTrackThunk } from "src/domains/Beacon/store/stream/thunks/publishAudioTrackThunk";
import { publishLocalMediaTracksThunk } from "src/domains/Beacon/store/stream/thunks/publishLocalMediaTracksThunk";
import { publishVideoTrackThunk } from "src/domains/Beacon/store/stream/thunks/publishVideoTrackThunk";
import { unpublishAudioTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishAudioTrackThunk";
import { unpublishVideoTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishVideoTrackThunk";
import { clearCanvasThunk } from "src/domains/Beacon/store/telestration/thunks/clearCanvasThunk";
import { connectTwilioThunk } from "src/domains/Beacon/store/twilio/thunks/connectTwilio";
import { twilioActions } from "src/domains/Beacon/store/twilio/twilioSlice";
import { logger } from "src/logging/logger";
import { refreshTwilioToken } from "src/services/ApiClient/twilio";

interface IParams {
  offerDeviceSelection?: boolean;
}

export const setTwilioTokenReconnectionThunk = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  IParams,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "twilio/setTwilioTokenReconnection",
  async ({ offerDeviceSelection }, { getState, dispatch }) => {
    try {
      logger().info("** Refreshing Twilio token **");

      const { meeting, stream, twilio } = getState();
      const { joinId, callSid, isUserHost } = meeting;
      const { integrationActive } = stream;
      const { room } = twilio;

      // Must stop video/audio tracks before reconnecting to Twilio room, otherwise,
      // the Webcam red indicator will persist after leaving the call
      if (isUserHost && !integrationActive) {
        await dispatch(unpublishVideoTrackThunk());
      }
      await dispatch(unpublishAudioTrackThunk());

      logger().info(
        "** setTwilioTokenReconnectionThunk - Refreshing Twilio token **"
      );
      // Must update the twilio token and store
      const response = await refreshTwilioToken(joinId, callSid);
      const twilioToken = response.content?.twilioToken;
      dispatch(twilioActions.setToken(twilioToken));

      // Must clear Telestration before disconnecting from room
      dispatch(clearCanvasThunk());

      // Must disconnect from the room before removing it
      room.disconnect();

      // Must need to clear the audio/video tracks for console, Host & participants
      dispatch(streamActions.clearRemoteTracks());

      // Must clean the room so all the TwilioSubscriptions are reset
      dispatch(twilioActions.setRoom(null));

      // Connects to twilio room with new token
      await dispatch(connectTwilioThunk({ offerDeviceSelection }));

      // If Devices Selection is "true" we can publish a custom audio-input & camera devices
      // otherwise, we'll use default's System audio-input & camera devices
      if (offerDeviceSelection) {
        // Only the Host can publish its video track & only if integration is off,
        // otherwise, must persist screen-sharing track
        if (isUserHost && !integrationActive) {
          logger().info(
            "** setTwilioTokenReconnectionThunk - Publishing local video track since the user is host **"
          );
          await dispatch(publishVideoTrackThunk());
        }
        logger().info(
          "** setTwilioTokenReconnectionThunk - Publishing audio track **"
        );
        await dispatch(publishAudioTrackThunk());
      } else {
        logger().info(
          "** setTwilioTokenReconnectionThunk - Publishing local media tracks **"
        );
        await dispatch(publishLocalMediaTracksThunk());
      }

      logger().info("Twilio Token refreshed successfully");
    } catch (error: any) {
      logger().error(
        "Error while running timed Twilio token refresh",
        error?.message
      );
      throw error; // Using BE error message response
    }
  }
);
