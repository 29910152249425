export const getInitialValuesForRoomTherapies = (state) => {
	const { roomTherapies } = state.departmentReducer;
	const procedures = {};
	if (roomTherapies && roomTherapies.length) {
		const therapies = roomTherapies.reduce((o, key) => ({
			...o,
			[key.id]: true,
		}), {});
		roomTherapies.reduce((o, key) => {
			key.procedures.map((procedure) => {
				Object.assign(procedures, { [procedure.id]: true });
			});
		}, {});
		return {
			therapies,
			procedures,
		};
	}
};
