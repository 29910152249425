import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { setCallState } from "../../store/meeting/actions";
import { AppState, CallState, CallStateType, PortalIdentity } from "../../store/models";
import { endCall } from "../../store/user/actions";
import "./EndCallConfirmation.scss";
import { logger } from "../../common/logger";
import { Room } from "twilio-video";
import SessionStorageService from "../../../../../src/services/SessionStorageService";

interface StateProps {
  callState: string;
  callSid: string;
  userId: string;
  identity: PortalIdentity;
  room: Room | null;
}

interface DispatchProps {
  endCall: () => void;
  setCallState: (callState: CallStateType) => void;
}

type Props = StateProps & DispatchProps;

class EndCallConfirmation extends React.Component<Props> {
  async endCallForTheUser(): Promise<void> {
    const { room } = this.props;
    if (room) {
      room.disconnect();
    }
    this.props.endCall();
  }

  confirmEndCall(): void {
    const { setCallState } = this.props;
    setCallState(CallState.ENDCALLCONFIRMED);
    this.endCallForTheUser()
      .then(() => {
        setCallState(CallState.READY);
        SessionStorageService.remove('avail_refresh_token');
        SessionStorageService.remove('avail_access_token')
      })
      .catch(e => {
        logger().error(e);
      });
  }

  cancelEndCall(): void {
    this.props.setCallState(CallState.INPROGRESS);
  }

  render() {
    return (
      <div className="avail-portal-call-overlay">
        <div className="avail-portal-call-overlay-dialog">
          <div className="avail-portal-call-overlay-dialog-title">
            Please confirm you want to end this call
          </div>
          <div className="avail-portal-call-overlay-dialog-button-holder">
            <button
              className="confirm-end-call-button"
              onClick={() => {
                logger().info(`PortalCall User ${this.props.userId} confirmed end call`)
                this.confirmEndCall()
              }}
            >
              <div className="avail-portal-call-overlay-dialog-button-text">
                <span>END CALL</span>
              </div>
            </button>
            <div className="avail-portal-call-overlay-dialog-cancel">
              <span
                className="cancel-end-call-button"
                onClick={() => {
                  logger().info(`PortalCall User ${this.props.userId} cancelled end call`)
                  this.cancelEndCall()}}
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  callState: state.meeting.callState,
  callSid: state.meeting.callSid,
  identity: state.user.identity,
  userId: state.user.identity.userId,
  room: state.twilio.room
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    endCall: () => dispatch(endCall()),
    setCallState: (callState: CallStateType) =>
      dispatch(setCallState(callState))
  };
};

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(EndCallConfirmation);
