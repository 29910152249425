/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";

import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { AutocompleteTextField } from "src/components/legacy/AutocompleteTextField";
import { Button } from "src/components/legacy/Button";
import { TextField } from "src/components/legacy/TextField";
import { DeleteNumberModal } from "src/domains/Users/components/UserSecurity/DeleteNumberModal";
import { PhoneVerificationModal } from "src/domains/Users/components/UserSecurity/PhoneVerificationModal";
import { PhoneVerifiedModal } from "src/domains/Users/components/UserSecurity/PhoneVerifiedModal";
import { formatPhoneNumber } from "src/domains/Users/components/UserSecurity/helper";
import {
  PhoneSetupFormProps,
  UserSecurityProps,
  UserPasscodeProps,
  SupportedCountryProps,
} from "src/domains/Users/components/UserSecurity/types";

import {
  maxAllowedDigits,
  minAllowedDigits,
  onlyNumbers,
  requiredField,
} from "avail-web-ui/constants/validation";
import DomainConstants from "avail-web-ui/domains/DomainConstants";

import AccessControlService from "../../../../../avail-web-application/src/Common/Services/accessControlService";
import {
  getPhoneNumberAction,
  rmPhoneNumberAction,
  setAuthErrorAction,
  setMFAPhoneOTPAction,
  setMFAVerifyPhoneAction,
  setResendMFAVerifyPhoneAction,
} from "../../../../../avail-web-application/src/Features/Home/Features/Users/UsersActions";
import {
  getAllDescendantUsersIds,
  getInitialValuesForUserSecurity,
} from "../../../../../avail-web-application/src/Features/Home/Features/Users/UsersSelectors";
import { getcurrentUserTypes } from "../../../../../avail-web-application/src/Features/Home/HomeSelectors";
import styles from "./styles.scss";

// remove this when an API is exposed with supported countries.
const supportedCountries: SupportedCountryProps[] = [
  {
    name: "United States",
    value: 1,
  },
];

const UserSecurity = ({
  userId,
  user,
  validPhone,
  initialValues,
  currentUser,
  phoneOtp,
  otpError,
  phoneVerified,
  currentUserTypes,
  descendantUsersIds,
  mfaOrgLevelTwoFactor,
  setAuthErrorAction,
  setMFAPhoneOTPAction,
  getPhoneNumberAction,
  rmPhoneNumberAction,
  setMFAVerifyPhoneAction,
  setResendMFAVerifyPhoneAction,
}: UserSecurityProps) => {
  const { handleSubmit, control, formState, reset } = useForm({
    mode: "onChange",
  });
  const [openPendingVerification, setOpenPendingVerification] = useState(false);
  const [openSuccessVerification, setOpenSuccessVerification] = useState(false);
  const [openDeletePhoneModal, setOpenDeletePhoneModal] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState(
    initialValues?.phoneNumber
  );

  useEffect(() => {
    if (otpError !== null && otpError?.triesRemaining === 0) {
      renderError();
    }

    // TODO: revisit this check
    if (
      !validPhone &&
      initialValues?.phoneNumber &&
      !!initialValues?.phoneNumber &&
      phoneVerified?.status === "SUCCESS"
    ) {
      renderPhoneSuccessModal();
    }

    if (initialValues?.loginId) {
      getPhoneNumberAction(initialValues.loginId);
    }
  }, [initialValues?.loginId, phoneVerified, validPhone, otpError]);

  useEffect(() => {
    // on changing tabs or pages, needs to clear error state
    return () => {
      setAuthErrorAction(null);
    };
  }, []);

  const openVerificationModal = (): void => {
    setOpenPendingVerification(true);
  };

  const closeVerificationModal = (): void => {
    setOpenPendingVerification(false);
  };

  const openSuccessVerificationModal = (): void => {
    setOpenSuccessVerification(true);
  };

  const closeSuccessVerificationModal = (): void => {
    setOpenSuccessVerification(false);
  };

  const openDeleteModal = (): void => {
    setOpenDeletePhoneModal(true);
  };

  const closeDeleteModal = (): void => {
    setOpenDeletePhoneModal(false);
  };

  const onClose = (): void => {
    closeSuccessVerificationModal();
  };

  const renderError = (): void => {
    closeVerificationModal();
  };

  const renderPhoneSuccessModal = (): void => {
    closeVerificationModal();
    openSuccessVerificationModal();
    // resets the form field
    reset({ passcode: "", phoneNumber: "", country: "", channelType: "" });
  };

  const onSubmit = ({
    phoneNumber,
    country,
    channelType,
  }: PhoneSetupFormProps): void => {
    if (validPhone) {
      rmPhoneNumberAction(initialValues?.loginId);
      openDeleteModal();
    } else {
      if (phoneNumber) {
        setUserPhoneNumber(phoneNumber);
      }

      setMFAPhoneOTPAction({
        loginId: initialValues?.loginId,
        channelType,
        phoneNumber,
        countryCode: country.value,
      });
      if (otpError?.triesRemaining !== 0) {
        openVerificationModal();
      }
    }
  };

  const verifyPasscode = ({ passcode }: UserPasscodeProps): void => {
    setMFAVerifyPhoneAction({
      loginId: initialValues?.loginId,
      mfaPhoneToken: phoneOtp.mfaPhoneToken,
      otp: passcode,
    });
  };

  const resendNotification = (): void => {
    setResendMFAVerifyPhoneAction({
      loginId: initialValues?.loginId,
      mfaPhoneToken: phoneOtp.mfaPhoneToken,
      preferenceType: phoneOtp.preferenceType,
    });
  };

  const renderPhoneVerifiedModal = () => {
    return openSuccessVerification ? (
      <PhoneVerifiedModal
        handleSubmit={handleSubmit}
        openSuccessVerification={openSuccessVerification}
        phoneNumber={userPhoneNumber || initialValues?.phoneNumber}
        onClose={onClose}
      />
    ) : null;
  };

  const renderPhoneVerificationModal = () => {
    return openPendingVerification ? (
      <PhoneVerificationModal
        control={control}
        formState={formState}
        openPendingVerification={openPendingVerification}
        closeVerificationModal={closeVerificationModal}
        handleSubmit={handleSubmit}
        verifyPasscode={verifyPasscode}
        userPhoneNumber={userPhoneNumber}
        errorMessage={otpError?.errorMessage}
        resendNotification={resendNotification}
        triesRemaining={otpError?.triesRemaining}
      />
    ) : null;
  };

  return (
    <>
      <form
        className={styles.userSecurityForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography component="div">
          <Typography variant="body1" sx={{ fontSize: "medium" }}>
            {DomainConstants.label.mfa}
          </Typography>
          <hr className="user-profile__preferences-hor-line" />
          <Typography
            variant="body1"
            align="left"
            gutterBottom
            sx={{ mb: 3 }}
            className="user-profile__label"
          >
            {mfaOrgLevelTwoFactor
              ? DomainConstants.label.mfaRequired
              : DomainConstants.label.mfaNotRequired}
          </Typography>
          {user && user.userTypes && initialValues?.email && (
            <Typography variant="h6" component="div" sx={{ mb: 3 }}>
              <Typography
                variant="body1"
                align="left"
                className="user-profile__label"
              >
                {DomainConstants.label.emailAddress}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                {initialValues?.email}
              </Typography>
            </Typography>
          )}
          {validPhone ? (
            <Typography
              variant="h6"
              component="div"
              sx={{ mt: 2 }}
              className={styles.deleteForm}
            >
              <Typography component="div">
                <Typography
                  variant="body1"
                  align="left"
                  className="user-profile__label"
                >
                  {DomainConstants.label.mfaPhoneNumber}
                </Typography>
                <Typography paragraph variant="h5" align="left">
                  {formatPhoneNumber(initialValues?.phoneNumber)}
                </Typography>
              </Typography>
              <Typography
                component="div"
                align="center"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#AB2625",
                    borderRadius: "0.4rem",
                  }}
                  variant="contained"
                  color="error"
                  type="submit"
                >
                  {DomainConstants.label.delete}
                </Button>
              </Typography>
            </Typography>
          ) : (
            <Typography component="div">
              <Typography
                variant="subtitle1"
                align="left"
                sx={{ mb: 2, fontSize: "medium" }}
              >
                {DomainConstants.label.mfaPhoneAuthenticationTitle}
              </Typography>
              <Controller
                name={DomainConstants.key.mfaCountry}
                control={control}
                defaultValue=""
                rules={{
                  ...requiredField(DomainConstants.label.mfaCountry),
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <AutocompleteTextField
                      disabled={
                        !mfaOrgLevelTwoFactor || otpError?.triesRemaining === 0
                      }
                      placeholderLabel={DomainConstants.label.mfaSelectCountry}
                      name={DomainConstants.key.mfaFrequency}
                      label={DomainConstants.label.mfaCountry}
                      getOptionLabel={(option: { name: any }) =>
                        option?.name || ""
                      }
                      value={value}
                      options={supportedCountries}
                      onChange={onChange}
                      required
                    />
                  );
                }}
              />
              <Typography sx={{ mb: 2 }} />
              <Controller
                name={DomainConstants.key.mfaPhoneNumber}
                control={control}
                rules={{
                  ...onlyNumbers(),
                  ...requiredField(DomainConstants.label.mfaPhoneNumber),
                  ...maxAllowedDigits(DomainConstants.label.mfaPhoneNumber, 10),
                  ...minAllowedDigits(DomainConstants.label.mfaPhoneNumber, 10),
                }}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <TextField
                        disabled={
                          !mfaOrgLevelTwoFactor ||
                          otpError?.triesRemaining === 0
                        }
                        label={DomainConstants.label.mfaPhoneNumber}
                        placeholder={DomainConstants.placeholder.mfaPhoneNumber}
                        required
                        onChange={onChange}
                        value={value}
                      />
                      {error && (
                        <span style={{ color: "red" }}>{error.message}</span>
                      )}
                    </>
                  );
                }}
              />
              <Typography component="div" sx={{ mt: 3, mb: 2 }}>
                <FormLabel>Select authentication method</FormLabel>
                <Controller
                  name={DomainConstants.key.mfaChannelType}
                  control={control}
                  rules={{
                    ...requiredField(DomainConstants.key.mfaChannelType),
                  }}
                  defaultValue=""
                  render={({ field: { onChange } }) => {
                    return (
                      <RadioGroup onChange={onChange} row>
                        <FormControlLabel
                          value={DomainConstants.label.mfaSms}
                          control={
                            <Radio
                              color="primary"
                              disabled={
                                !mfaOrgLevelTwoFactor ||
                                otpError?.triesRemaining === 0 ||
                                !AccessControlService.doesUserHaveAccess(
                                  currentUserTypes,
                                  "edit",
                                  "userProfile",
                                  {
                                    currentUserId:
                                      currentUser && currentUser.id,
                                    userProfileId: userId,
                                    descendantUsersIds,
                                  }
                                )
                              }
                            />
                          }
                          label={DomainConstants.label.mfaTextMessage}
                        />
                        <FormControlLabel
                          value={DomainConstants.label.mfaCall}
                          control={
                            <Radio
                              color="primary"
                              disabled={
                                !mfaOrgLevelTwoFactor ||
                                otpError?.triesRemaining === 0 ||
                                !AccessControlService.doesUserHaveAccess(
                                  currentUserTypes,
                                  "edit",
                                  "userProfile",
                                  {
                                    currentUserId:
                                      currentUser && currentUser.id,
                                    userProfileId: userId,
                                    descendantUsersIds,
                                  }
                                )
                              }
                            />
                          }
                          label={DomainConstants.label.mfaVoiceCall}
                        />
                      </RadioGroup>
                    );
                  }}
                />
              </Typography>
              {/* TODO: move styles later */}
              {currentUser && (
                /* 
                  ref: https://gitlab.com/availmedsys/avail-web-applications/avail-ui-spa/-/merge_requests/233#note_710615853
                  removed a condition `!formState.isValid`(a boolean flag for formState object(react-hook-form)) from `disabled` prop 
                  in order to bring back button from inactive -> active status for suspended users.
                  Note: showing an error text underneath the 'verify' button for suspended users,
                        isValid is considering it as a form error and the button is greyed out.
                */
                <Button
                  disableRipple
                  variant="contained"
                  style={{
                    width: "50%",
                    backgroundColor: "#3ca65e",
                    borderRadius: "0.4rem",
                  }}
                  disabled={!mfaOrgLevelTwoFactor || formState.isSubmitting}
                  type="submit"
                >
                  {DomainConstants.label.mfaVerify}
                </Button>
              )}
              {otpError?.triesRemaining === 0 && otpError?.errorMessage && (
                <Typography variant="body1" paragraph sx={{ color: "#fb3640" }}>
                  {otpError?.errorMessage}
                </Typography>
              )}
            </Typography>
          )}
        </Typography>
      </form>
      {renderPhoneVerificationModal()}
      {renderPhoneVerifiedModal()}
      <DeleteNumberModal
        openDeletePhoneModal={openDeletePhoneModal}
        phoneNumber={userPhoneNumber || initialValues?.phoneNumber}
        onClose={closeDeleteModal}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.usersReducer.user,
  currentUser: state.homeReducer.currentUser,
  currentUserTypes: getcurrentUserTypes(state),
  descendantUsersIds: getAllDescendantUsersIds(state),
  initialValues: getInitialValuesForUserSecurity(state),
  otpError: state.usersReducer.otpError,
  mfaOrgLevelTwoFactor: state.usersReducer.mfaOrgLevelTwoFactor,
  phoneOtp: state.usersReducer.phoneOtp,
  validPhone: state.usersReducer.validPhone,
  phoneVerified: state.usersReducer.phoneVerified,
});

const mapDispatchToProps = {
  getPhoneNumberAction,
  rmPhoneNumberAction,
  setAuthErrorAction,
  setMFAPhoneOTPAction,
  setMFAVerifyPhoneAction,
  setResendMFAVerifyPhoneAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSecurity);
