import { useSelector } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { AppState, history } from "src/reducer";
import UserSessionService from "src/services/UserSessionService";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

import { LoginPage } from "../../../Login";

const OTPLockout = () => {
  const isOtp = UserSessionService.getMFAAuthData();
  const redirectUrl = UserSessionService.getRedirectUrl();
  const {
    authenticationReducer: { errorMessage },
  } = useSelector((state: AppState) => state);

  const handleSubmit = () => {
    if (redirectUrl) {
      history.push("/event-login");
    } else {
      history.push("/");
    }
  };

  return (
    <LoginPage className="login">
      <Grid container spacing={2} direction={"column"}>
        <Grid item>
          <Box m={2}>
            <Typography variant="h4" align={"center"}>
              {isOtp ? DomainConstants.label.eventDenied : "Lockout"}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box m={2}>
            <Typography variant="body1" align={"center"}>
              {isOtp ? DomainConstants.label.eventDenied : errorMessage}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box m={2}>
            {/* TODO: move styles later */}
            <Button
              fullWidth
              style={{
                backgroundColor: "#0E2F58",
              }}
              onClick={handleSubmit}
            >
              {DomainConstants.label.ok}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LoginPage>
  );
};

export default OTPLockout;
