import React from 'react';

// Higher Order Component/function (HOC) to handle URL driven tabs (takes in a component)
// This HOC assumes it's fed into a react-router function to have access to props.location
export function withTabSwitcher(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        selectedTab: 'general'
      };
    }

    // Get the current tab via `#` in URL
    componentDidMount() {
      const { hash, pathname, search } = this.props.location;
      if (hash) {
        // set the state to that tab
        const currentTab = hash.substring(hash.indexOf('#') + 1);
        this.setState({ selectedTab: currentTab });
      } 
      // otherwise, append the `#general` to the URL (default)
      else {
        this.props.history.replace({ pathname, search, hash: "#general" })
      }
    }

    componentDidUpdate(prevProps) {
      // Handle deep-linking via anchor/hash changes
      if (prevProps.location.hash !== this.props.location.hash) {
        const { hash, pathname, search } = this.props.location;
        
        if (!hash) {
          this.props.history.replace({ pathname, search, hash: "#general" });
        }

        // When the hash changes, we set `selectedTab` to that
        const newTab = hash.substring(hash.indexOf('#') + 1);
        this.setState({ selectedTab: newTab });
      }
    }

    render() {
      // renders the wrapped component with the fresh data!
      return <WrappedComponent selectedTab={this.state.selectedTab} {...this.props} />;
    }
  };
}