import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { AVKLayout } from "availkit-js/dist/Models/AVKLayout";
import { AVKVideoLayoutEvent } from "availkit-js/dist/Models/Events/AVKVideoLayoutEvent";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { DEFAULT_LAYOUT_POSITION } from "src/domains/Beacon/constants";
import { RootState } from "src/domains/Beacon/store";
import {
  LayoutFrames,
  LayoutTypes,
} from "src/domains/Beacon/store/stream/types";
import {
  AVKCameraIdentifier,
  AVKExternalInputIdentifier,
  CameraIdentifier,
  getCameraIdentifier,
  getExternalInputIdentifier,
} from "src/domains/Beacon/utils/availKit";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

// Will broadcast the video layout change event to Console
export const setVideoLayoutEventThunk = createAsyncThunk<
  void,
  {
    layoutFrames: LayoutFrames;
  },
  {
    state: RootState;
  }
>("stream/setVideoLayoutEventThunk", ({ layoutFrames }, { getState }) => {
  try {
    logger().info("setVideoLayoutEventThunk User selected a preset");

    const availKit = AvailKitService.instance;
    const {
      meeting: { callSid, joinId },
      stream: { cameras, layoutType },
    } = getState();

    const videoLayoutEvent = new AVKVideoLayoutEvent(callSid);
    const fullscreenSource = Object.fromEntries(
      Object.entries(layoutFrames).filter(([_, value]) => value.isFullScreen)
    );
    const filteredAVKCameras = (cameras as AVKCamera[]).filter((camera) =>
      AVKCameraIdentifier(camera)
    );
    const filteredAVKExternalInputs = (cameras as any[]).filter((camera) =>
      AVKExternalInputIdentifier(camera)
    ) as AVKExternalInput[];

    const layoutInfo = new Array();
    const cameraList: AVKCamera[] = [];
    const externalInputList: AVKExternalInput[] = [];

    // If one layout is set to fullscreen, should enter in here, otherwise,
    // means that one of both frames has been modified
    if (Object.keys(fullscreenSource).length !== 0) {
      const fullscreenSourceInfo = Object.values(fullscreenSource)[0];

      cameraList.push(
        ...filteredAVKCameras.filter(
          (camera) => CameraIdentifier(camera) === fullscreenSourceInfo.cameraId
        )
      );
      externalInputList.push(
        ...filteredAVKExternalInputs.filter(
          (camera) => CameraIdentifier(camera) === fullscreenSourceInfo.cameraId
        )
      );

      const newLayout = new AVKLayout();
      newLayout.id = fullscreenSourceInfo.cameraId;
      newLayout.label = fullscreenSourceInfo.cameraLabel;
      layoutInfo.push(newLayout);
      logger().info(`Selecting fullscreen: ${fullscreenSourceInfo.cameraId}`);
    } else {
      const framedCameraIds = Object.values(layoutFrames).map(
        (layoutFrame) => layoutFrame.cameraLabel
      );

      cameraList.push(
        ...filteredAVKCameras.filter(
          (camera) =>
            getCameraIdentifier(camera) === layoutFrames.leftTop.cameraId ||
            getCameraIdentifier(camera) === layoutFrames.rightTop.cameraId ||
            getCameraIdentifier(camera) === layoutFrames.leftBottom.cameraId ||
            getCameraIdentifier(camera) === layoutFrames.rightBottom.cameraId
        )
      );
      externalInputList.push(
        ...filteredAVKExternalInputs.filter(
          (camera) =>
            getExternalInputIdentifier(camera) ===
              layoutFrames.leftTop.cameraId ||
            getExternalInputIdentifier(camera) ===
              layoutFrames.rightTop.cameraId ||
            getExternalInputIdentifier(camera) ===
              layoutFrames.leftBottom.cameraId ||
            getExternalInputIdentifier(camera) ===
              layoutFrames.rightBottom.cameraId
        )
      );

      // TODO: modify 1x1 vs 2x2
      Object.keys(layoutFrames).map((key: string) => {
        const layout = new AVKLayout();
        layout.id = layoutFrames[key].cameraId;
        layout.label = layoutFrames[key].cameraLabel;
        // objects do not return in order, need to place for layoutInfo
        // TODO: replace with new structure
        layoutInfo.splice(DEFAULT_LAYOUT_POSITION[key], 0, layout);
      });
      logger().info(`Selecting cameras: ${framedCameraIds}`);
    }

    const filteredLayoutInfo =
      layoutType === LayoutTypes.TWO_VIEW
        ? layoutInfo.splice(0, 2)
        : layoutInfo;

    videoLayoutEvent.cameras = cameraList;
    videoLayoutEvent.externalInputs = externalInputList;
    videoLayoutEvent.layoutInfo = filteredLayoutInfo.filter(
      (info) => info != null
    );
    videoLayoutEvent.presetEvent = true;
    videoLayoutEvent.sender = joinId;

    availKit.eventService.broadcast(videoLayoutEvent);
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      { feature: "setVideoLayoutEventThunk" },
      `Error while changing a video layout with EIR flag. ${error?.message}`
    );
    throw new Error("Error setting video layout event.");
  }
});
