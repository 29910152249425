import { takeLatest, call, put } from 'redux-saga/effects';
import resources from '../../../../Common/Constants/resources';
import { notificationService, urlService } from '../../../../Common/Services';
import ApiHelper from '../../../../Common/Services/ApiHelper';
import { APPROVAL_LIST } from './ApprovalListActionTypes';

function* getAllUserApplications(action) {
	if(action.payload.applicationStatus !== null && action.payload.applicationStatus !== ""){
		if(action.payload.applicationStatus === "ACTIVE"){
			action.payload.applicationStatus = "Approve";
		}
		if(action.payload.applicationStatus === "CREATED"){
			action.payload.applicationStatus = "Pending";
		}
		if(action.payload.applicationStatus === "DELETED"){
			action.payload.applicationStatus = "Rejected";
		}
	}
	const payloadClone = { ...action.payload };
	delete payloadClone.hospitalId;
	try {
		const apiUrl = action.organizationType === 'vendor' ? resources.applicationForSecondaryManager : resources.applicationsForHospitals;
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(apiUrl, action.payload.hospitalId), payloadClone);
		yield put({
			type: APPROVAL_LIST.SET_ALL_USER_APPLICATIONS,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* setUserStatus(action) {
	try {
		let apiUrl = null;
		if (action.organizationType === 'vendor') {
			apiUrl = `users/${action.hospitalId}/user-applications/${action.userId}`;
		} else {
			apiUrl = `hospitals/${action.hospitalId}/user-applications/${action.userId}`;
		}
		yield call(ApiHelper.put, 'coreregistry', apiUrl, action.userStatus);
		yield getAllUserApplications({
			payload: {
				hospitalId: action.hospitalId,
				organizationType: action.organizationType,
			}
		});
		yield notificationService.success('You successfully updated user status');
	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* approvalListSaga() {
	yield takeLatest(APPROVAL_LIST.GET_ALL_USER_APPLICATIONS, getAllUserApplications);
	yield takeLatest(APPROVAL_LIST.SET_USER_STATUS, setUserStatus);
}
