import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
/* Components */
import { Button } from '../../../../../../Common/Components';
import Icon from '../../../../../../Common/Components/Icon/Icon';
import { Pill, ProfileImage } from '../../../../Common';
import DropdownComponent from '../../../../Common/DropdownComponent/DropdownComponent';
/* Helpers */
import allUserTypes, { roles } from '../../../../../../Common/Constants/userTypes';
import userService from '../../../../../../Common/Services/userService';
import { getcurrentUserTypes } from '../../../../HomeSelectors';
import Can from '../../../../../../Common/Acl/AccessControl';
import { getAllDescendantUsersIds } from '../../../Users/UsersSelectors';

class ProviderUserTableRow extends PureComponent {
	onCalendarClickHandler = () => (
		this.props.history.push({
			pathname: `/scheduler/${this.props.rowData.id}`,
		})
	);

	redirect = () => {
		this.props.history.push(`/users/profile?userId=${this.props.rowData.id}`);
	};

	openAssociateModal = () => this.props.openModal(this.props.rowData);

	renderDropdownOptions = () => {
		const { userTypes, canApply } = this.props.rowData;
		const canUserApply = (userTypes[0] === roles.vendorManager) && (this.props.currentUserTypes[0] === roles.vendorSalesRep) && canApply;
		return [{
			label: 'View user profile',
			text: 'View user profile',
			action: this.redirect,
			icon: 'icon-person-outline',
		}, ...(canUserApply) ? [{
			label: 'Associate to me',
			text: 'Associate to me',
			action: this.openAssociateModal,
			icon: 'icon-plus-circle-outline',
			className: 'dropdown__menu-item--orange',
		}] : []];
	};

	render() {
		const { firstName, lastName, userTypes, status, id } = this.props.rowData;
		return (
			<Table.Row
				className={classNames({
					'table__row--inactive': status === 'DELETED',
				})}
			>
				<Table.Cell onClick={this.redirect} title={[`${firstName} ${lastName}`].join(' ')}>
					<div className="table__name-wrapper display--flex">
						<div className="position--relative">
							<ProfileImage
								size="sm"
								className={`profile-image--${allUserTypes[userTypes[0]].className} `}
								initials={userService.getUserInitials(firstName, lastName)}
							/>
						</div>
						<span className="table__username users__table-username">{`${firstName} ${lastName}`}</span>
					</div>
				</Table.Cell>
				<Table.Cell title={[allUserTypes[userTypes[0]].title].join(' ')}>
					<Pill
						isDisabled={status === 'DELETED'}
						class={`user-type--${allUserTypes[userTypes[0]].className} `}
						title={allUserTypes[userTypes[0]].title}
					/>
				</Table.Cell>
				<Table.Cell
					className="table__action-table-cell"
					style={{
						overflow: 'visible',
					}}
				>
					<div className="flex-direction--row justify-content--flex-end align--center">
						<Can
							userTypes={this.props.currentUserTypes}
							action="read"
							context="calendar"
							data={{
								currentUserId: this.props.currentUserId,
								userProfileId: id,
								descendantUsersIds: this.props.descendantUsersIds,
								userTypes,
							}}
						>
							<Button
								className="user-profile__calendar-button padding--none vendor-profile__view-calendar button-with-icon"
								onClick={this.onCalendarClickHandler}
							>
								<Icon className="icon-calendar-outline user-profile__calendar-button-icon" />
								<span className="user-profile__calendar-button-title">View calendar</span>
							</Button>
						</Can>
						<DropdownComponent dropdownOptions={this.renderDropdownOptions()} />
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}

const mapStateToProps = state => ({
	currentUserTypes: getcurrentUserTypes(state),
	currentUserId: state.homeReducer.currentUser && state.homeReducer.currentUser.id,
	descendantUsersIds: getAllDescendantUsersIds(state),
});

ProviderUserTableRow.propTypes = {};

export default withRouter(connect(mapStateToProps, undefined)(ProviderUserTableRow));
