// TODO: linter complaining! add no-shadow entry in tsconfig.json, or fix this

/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "src/components/legacy/Button";
import { TextField } from "src/components/legacy/TextField";
import { LoginPage } from "src/domains/Login";
import { useOnPageRefresh } from "src/hooks/useOnPageRefresh";
import { AppState, history } from "src/reducer";
import UserSessionService from "src/services/UserSessionService";
import { clearUserSession } from "src/utils/clearUserSession";

import {
  verifyLoginPasscodeAction,
  resendLoginPasscodeAction,
} from "avail-web-application/Features/Authentication/AuthenticationActions";

import {
  maxAllowedDigits,
  onlyNumbers,
  requiredField,
} from "avail-web-ui/constants/validation";
import DomainConstants from "avail-web-ui/domains/DomainConstants";

import styles from "./styles.scss";

const VerifyPasscode = () => {
  useOnPageRefresh(() => clearUserSession());

  const dispatch = useDispatch();
  const { username } = UserSessionService.getMFAAuthInfo() || {};
  const { loginMfaToken, hasPhoneNumber } = useSelector(
    (state: AppState) => state.homeReducer
  );
  const { errorMessage, mfaChannelType } = useSelector(
    (state: AppState) => state.authenticationReducer
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // checks for empty session storage
    if (sessionStorage.getItem("mfaAuthInfo") === null) {
      history.push("/login");
    }
  }, []);

  const { handleSubmit, control, formState } = useForm({
    mode: "onChange",
  });

  const onSubmit = ({ passcode }) => {
    setIsLoading(true);
    dispatch(
      verifyLoginPasscodeAction({
        mfaToken: loginMfaToken?.mfaToken,
        otp: passcode,
        hasPhoneNumber,
      })
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleResendPasscode = () => {
    dispatch(
      resendLoginPasscodeAction({
        mfaToken: loginMfaToken?.mfaToken,
      })
    );
  };

  const preferredChannelText = () => {
    if (!!mfaChannelType && mfaChannelType === DomainConstants.label.mfaSms) {
      return DomainConstants.label.mfaSmsChannelText;
    } else if (
      !!mfaChannelType &&
      mfaChannelType === DomainConstants.label.mfaCall
    ) {
      return DomainConstants.label.mfaCallChannelText;
    } else {
      return (
        <>
          {DomainConstants.label.mfaEmailChannelText}
          <div className={styles.mfaVerifyBold}>{username}.</div>
          <div className={styles.mfaVerifyWidth}>
            {DomainConstants.label.mfaEmailChannelConfirmText}
          </div>
        </>
      );
    }
  };

  return (
    <LoginPage className={styles.mfaVerifyUser}>
      <div className={styles.mfaVerifyText}>{preferredChannelText()}</div>
      <div className={styles.mfaVerifyWidth}>
        <form
          className={styles.mfaVerifyForm}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name={DomainConstants.key.mfaPasscode}
            control={control}
            rules={{
              ...requiredField(DomainConstants.label.mfaPasscode),
              ...onlyNumbers(),
              ...maxAllowedDigits(DomainConstants.label.mfaPasscode, 6),
            }}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <>
                  <TextField
                    label={DomainConstants.label.oneTimePassword}
                    placeholder={DomainConstants.label.otpEnterPasscode}
                    required
                    onChange={onChange}
                    value={value}
                  />
                  {error && (
                    <div className={styles.mfaValidationError}>
                      {error.message}
                    </div>
                  )}
                  {!error && !!errorMessage ? (
                    <div className={styles.mfaValidationError}>
                      {errorMessage}
                    </div>
                  ) : (
                    !error && (
                      <div className={styles.mfaValidation}>
                        {DomainConstants.label.mfaPasscodeValid}
                      </div>
                    )
                  )}
                </>
              );
            }}
          />
          {/* TODO: move styles later */}
          <Button
            fullWidth
            disableRipple
            style={{
              backgroundColor: "#7EBB45",
              borderRadius: "0.3rem",
              marginTop: "2rem",
            }}
            variant="contained"
            className={`${styles.mfaVerifyButton} ${styles.singularWidth}`}
            disabled={!formState.isValid || formState.isSubmitting}
            type="submit"
            loading={isLoading}
          >
            {DomainConstants.label.submit}
          </Button>
          <Button
            variant="text"
            onClick={handleResendPasscode}
            style={{
              textDecoration: "underline",
              color: "#219AE9",
              marginTop: "1.3rem",
            }}
          >
            {DomainConstants.label.otpResend}
          </Button>
        </form>
      </div>
    </LoginPage>
  );
};

export default VerifyPasscode;
