import { useEffect, useState } from "react";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectLocalMedia } from "src/domains/Beacon/store/stream/selectors";
import { areDevicesReady } from "src/domains/Beacon/utils/mediaDevices";
import { timeoutCallback } from "src/utils/timeout";

export const useMediaDevicesReady = () => {
  const localMedia = useAppSelector(selectLocalMedia);

  const [mediaDevicesReady, setMediaDevicesReady] = useState(false);

  const checkDevicesReady = () => {
    // areDevicesReady returns a boolean that checks if we have access to the users mic/cam/speaker devices
    // for example we check to see if the arrays of videoInputs has any devices in it, if that list is not empty
    // then that means the user has given us permission to use their device
    const areReady = areDevicesReady(localMedia.mediaDevices);

    if (areReady) {
      // small delay so the loading indicator below can fade nicely
      timeoutCallback(() => setMediaDevicesReady(true), 800);
    }
  };

  useEffect(() => {
    if (localMedia?.mediaDevices) {
      checkDevicesReady();
    }
  }, [localMedia?.mediaDevices]);

  return { mediaDevicesReady };
};
