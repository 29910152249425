import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/domains/Beacon/store";
import { uiActions } from "src/domains/Beacon/store/ui";
import { UINotificationTypes } from "src/domains/Beacon/store/ui/types";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

// Toggles the sidebar status through AvailKit Service
// also, modifies the Redux Sidebar's status
export const toggleSidebarStatusThunk = createAsyncThunk<
  // Return type of the thunk
  boolean,
  // First argument to the payload creator
  void,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("stream/toggleSidebarStatusThunk", (_arg, { getState, dispatch }) => {
  try {
    logger().info("User is toggling Sidebar status");

    const availKit = AvailKitService.instance;
    const { meeting, stream } = getState();
    const { callSid } = meeting;
    const { sidebar } = stream;

    // toggling the current sidebar status
    const sidebarActive = sidebar === "active";
    availKit?.avkSidebarService.setSidebarStatus(callSid, !sidebarActive);

    // Adding logs in case an error happens
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "toggleSidebarStatusThunk",
      },
      // Must be the opposite, if it's `true`, means that it'll change to `false` through AVK
      `Changing sidebar status to: ${sidebarActive ? "inactive" : "active"}`
    );

    // showing notification when turning on Sidebar
    dispatch(
      uiActions.triggerNotification(
        UINotificationTypes[
          // Must be the opposite, if it's `true`, means that it'll change to `false` through AVK
          sidebarActive ? "SIDEBAR_MODE_OFF" : "SIDEBAR_MODE_ON"
        ]
      )
    );

    // returning the toggled sidebar status
    return !sidebarActive;
  } catch (error: any) {
    logger().error("Toggling Sidebar Status error", error?.message);
    throw new Error("toggleSidebarStatusThunk failed.");
  }
});
