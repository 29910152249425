import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import LinkIcon from "@mui/icons-material/Link";

import './Calendar.scss';

export class Calendar extends React.PureComponent {
	calendarRef = React.createRef()
	renderColumnHeader = date => (
		`<React.Fragment>
			<div class="calendar-header__day-of-week">${moment.tz(date, this.props.timezone).format('ddd')}</div>
			<div class="calendar-header__day"><span>${moment.tz(date, this.props.timezone).format('DD')}</span></div>
		</React.Fragment>`
	);

	// eslint-disable-next-line react/sort-comp
	getCurrentDate = () => {
		const calendarApi = this.calendarRef.current.getApi();

		return {
			startDate: calendarApi.view.currentStart,
			endDate: calendarApi.view.currentEnd,
		};
	}

	onEventRender = (info) => {
		const { el, event } = info;

		if (!event.end) {
			// TODO: Still causing some overlapping issues on columns/cells with many events
			el.classList.add('fc-nested-inset-event');
		}
	}
  
  eventContent = ({ event, el }) => {
    const { isMpEvent, classes, subject, status } = event.extendedProps;

    if (isMpEvent) {
      const start = moment(event.start).format("h:mm");
      const end = moment(event.end).format("h:mm a");

      // TODO: look at this later: Currently I need this to show/hide description in case the event is big enough to have words on it
      const minuteDiff = moment(event.end).diff(moment(event.start), 'minutes');
      const isCurrentDayOrBeyond = moment(event.start).isSameOrAfter(new Date(), "day");

      const content = <div className={`fc-content ${classes.join(' ')}`}>
        <div className="fc-time mp-event__time"><span>{start}-{end}</span></div>
        {minuteDiff < 60 ? null : (
          <p className={`mp-event__title ${minuteDiff > 60 ? 'long' : ''}`}>
            {subject}
          </p>
        )}
        {/* Only show copy link for current day's events that are not ENDED or EXPIRED */}
        {isCurrentDayOrBeyond
          ? (
            <div className="mp-event__copy" onClick={(e) => this.props.onCopyEventLink(e, event)}>
              <LinkIcon className="mp-event__copy-icon" />
              <span>Copy Link</span>
            </div>
          )
          : null}
        
      </div>;

      // FullCalendar does not support content injection with JSX (at least not until the newest release), so we must do things the old way
      ReactDOM.render(content, el);
      return el;
    }
  };

	render() {
		return (
			<div className="calendar-container">
				<div className="calendar">
					<FullCalendar
						ref={this.calendarRef}
						timeZone={this.props.timezone}
						events={this.props.events}
						dateClick={this.props.onDateClick}
						eventClick={this.props.onEventClick}
            datesRender={this.props.onDateRangeChange}
						header={{
							left: 'today',
							center: 'prev next title',
							right: 'dayGridMonth, timeGridWeek, timeGridDay',
						}}
						views={{
							week: {
								titleFormat: {
									month: 'long',
									day: 'numeric',
								},
							},
							day: {
								titleFormat: {
									month: 'long',
									titleRangeSeparator: ',',
									day: 'numeric',
								},
							},
						}}
						eventTimeFormat={{
							hour: 'numeric',
							minute: '2-digit',
							meridiem: 'short',
						}}
						columnHeaderFormat={
							{
								weekday: 'short',
								day: 'numeric',
							}
						}
						columnHeaderHtml={this.renderColumnHeader}
						navLinks
						height="parent"
						nowIndicator
						defaultView="timeGridWeek"
						eventPositioned={this.onEventRender}
            eventRender={this.eventContent}
						slotEventOverlap={false}
						timeGridEventMinHeight={25}
						plugins={[interaction, timeGridPlugin, dayGridPlugin, momentTimezonePlugin]}
					/>
				</div>
			</div>
		);
	}
}

export default Calendar;
