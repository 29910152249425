import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Switch } from "@mui/material";

import { useGetOrganizationSettings } from "src/queries/organizations";
import {
  Brand,
  updateOrganizationSettings,
} from "src/services/ApiClient/businessUnit";
import { getTrialEnabledFlagFromSettings } from "src/utils/settings";

import { Button, Icon } from "avail-web-application/Common/Components";
import { notificationService } from "avail-web-application/Common/Services";
import { toggleLoader } from "avail-web-application/Features/Home/HomeActions";

interface Props {
  businessUnit: Brand;
}

export const TrialSignUpFlagForm = ({ businessUnit }: Props) => {
  const [checked, setChecked] = useState(false);

  const { data, isLoading } = useGetOrganizationSettings(businessUnit.id);

  const dispatch = useDispatch();

  const handleChange = (): void => {
    setChecked((prev): boolean => !prev);
  };

  useEffect(() => {
    if (data?.content) {
      const settings = data.content;

      const trialFlag = getTrialEnabledFlagFromSettings(settings);
      setChecked(trialFlag);
    }
  }, [data]);

  const onSubmit = async () => {
    const payload = {
      type: "TRIAL_ENABLED",
      value: checked ? "true" : "false", // BE expects it to be a string so converting boolean to string
    };

    // dispatching loading manually since we are using new API client instead of old one
    // only really applies to old UI so perhaps its okay for now
    dispatch(toggleLoader());
    try {
      await updateOrganizationSettings(businessUnit.id, payload);

      notificationService.success(
        "You successfully edited the trial self sign up flag"
      );
    } catch (error) {
      notificationService.error(
        "Erorr occured while trying to update trial self sign up flag"
      );
    } finally {
      dispatch(toggleLoader());
    }
  };

  return (
    <>
      {/* need to set height to auto on this div due to css clash with semantic ui */}
      <div
        className="vendor-profile__toggle__switch width--full"
        style={{ height: "auto" }}
      >
        <Button
          className="button-with-icon align-self--flex-start padding--none"
          type="submit"
        >
          <Icon className="icon-save icon--orange entity-profile__submit-icon" />
          <span
            onClick={onSubmit}
            className="entity-profile__submit-title"
            data-test-id="self-sign-up-for-trial-save-button"
          >
            Save Changes
          </span>
        </Button>
      </div>
      <div className="vendor-profile__security-marginTop">
        <h4 data-test-id="self-sign-up-for-trial-page">
          Self Sign-up for Trial
        </h4>
        <hr className="vendor-profile__preferences-hor-line" />
        <div className="vendor-profile__security__header">
          <div className="vendor-profile__hr-text">
            <span
              className="vendor-profile__label"
              data-test-id="self-sign-up-for-trial-text"
            >
              If enabled new users will be allowed to self sign up for a trial
              account using this organization's work email domain. If disabled
              any users trying to sign up with this email domain will not be
              allowed to proceed with self sign-up.
            </span>
          </div>
          <div className="vendor-profile__toggle__switch width--half">
            {isLoading ? null : (
              <Switch
                name="checked"
                color="primary"
                disableRipple
                checked={checked}
                onChange={handleChange}
                data-test-id="self-sign-up-for-trial-switch-button"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
