import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Fields, validators } from '../../../../../../../Common/Components';
import { getAllBrandsAction } from '../../../../Brands/BrandsActions';
import {
	getVendorsForSelectedBrandAction,
	getManagersForSelectedBusinessUnitAction,
} from '../../../../Organisation/Vendors/VendorsActions';
import { getAllProvidersAction } from '../../../../Provider/ProviderActions';

export class ProctorSpecialistFormPart extends PureComponent {
	state = {
		selectedRole: this.props.selectedUserType,
		selectedBrand: null,
		selectedBusinessUnitId: null,
	};

	componentDidMount() {
		if (this.props.selectedUserType === 'PROCTOR') {
			this.props.getAllBrands();
		}

		if (this.props.selectedUserType === 'SPECIALIST') {
			this.props.getAllProviders();
		}
	}

	selectBrand = (brand) => {
		this.setState({
			selectedBrand: brand,
		});
		if (brand !== '') {
			this.props.getVendorsForSelectedBrand(brand);
		}
	}

	selectBusinessUnit = (businessUnitId) => {
		this.setState({
			selectedBusinessUnitId: businessUnitId,
		});
		if (businessUnitId !== '') {
			this.props.getManagersForSelectedBusinessUnit(businessUnitId);
		}
	};

	render() {
		return (
			<>
				{this.state.selectedRole === 'SPECIALIST' &&
					<Fields.SelectField
						labelText="Associate user to provider organisation"
						search
						placeholder="Select provider"
						options={this.props.providers ? this.props.providers.map(provider => ({
							key: provider.id,
							value: provider.id,
							text: provider.name,
						})) : []}
						name="providerOrganisationId"
						validate={validators.required}
					/>
				}
				{this.state.selectedRole === 'PROCTOR' &&
					<>
						<Fields.SelectField
							labelText="Associate user to vendor"
							search
							placeholder="Select Vendor"
							options={this.props.brands ? this.props.brands.map(brand => ({
								key: brand.id,
								value: brand.id,
								text: brand.name,
							})) : []}
							onChange={this.selectBrand}
							name="brand"
							validate={validators.required}
						/>
						{
							this.state.selectedBrand &&
							<Fields.SelectField
								labelText="Asssociate user to Vendor BU"
								search
								placeholder="Select Vendor BU"
								options={this.props.vendorsPerBrand ? this.props.vendorsPerBrand.map(brand => ({
									key: brand.id,
									value: brand.id,
									text: brand.name,
								})) : []}
								onChange={this.selectBusinessUnit}
								name="businessUnitId"
								validate={validators.required}
							/>
						}
						{
							this.state.selectedBusinessUnitId &&
							<Fields.SelectField
								labelText="Associate user to manager"
								search
								placeholder="Select manager"
								options={this.props.managersPerBusinessUnit ? this.props.managersPerBusinessUnit.map(manager => ({
									key: manager.id,
									value: manager.id,
									text: `${manager.firstName} ${manager.lastName}`,
								})) : []}
								name="usersManager"
								// validate={
								// 	this.props.managersPerBusinessUnit
								// 	&& this.props.managersPerBusinessUnit.length !== 0 ? validators.required : null
								// }
							/>
						}
					</>
				}
			</>
		);
	}
}

const mapStateToProps = state => ({
	brands: state.brandsReducer.brands,
	providers: state.providerReducer.providers,
	vendorsPerBrand: state.vendorsReducer.vendorsPerBrand,
	managersPerBusinessUnit: state.vendorsReducer.managersPerBusinessUnit,
});

const mapDispatchToProps = dispatch => ({
	getAllBrands: () => dispatch(getAllBrandsAction()),
	getAllProviders: () => dispatch(getAllProvidersAction()),
	getVendorsForSelectedBrand: brandId => dispatch(getVendorsForSelectedBrandAction(brandId)),
	getManagersForSelectedBusinessUnit: businessUnitId => dispatch(getManagersForSelectedBusinessUnitAction(businessUnitId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProctorSpecialistFormPart);
