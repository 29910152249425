import { InstructionMemory } from "src/domains/Beacon/components/Easel/constants";
import { drawAnnotation } from "src/domains/Beacon/components/Easel/helpers";
import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { logger, LoggerLevels } from "src/logging/logger";

export const drawTelestrationHistoryThunk = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  try {
    logger().info(`Drawing Telestration history thunk...`);

    const {
      telestration: {
        telestrationHistory,
        videoDimensions: videoElementDimensions,
      },
    } = getState();

    if (telestrationHistory?.length === 0) {
      logger().warn(
        "No telestrations found. Drawing telestrations from state will be skipped ..."
      );
      return;
    }

    telestrationHistory.forEach((teleBlob: InstructionMemory) => {
      drawAnnotation(
        teleBlob.style,
        teleBlob.instructions,
        videoElementDimensions
      );
    });

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Telestration",
      },
      `Drawing Telestration history based on state`
    );
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      {
        feature: "Telestration",
      },
      "Error happened when drawing telestration history",
      error?.message
    );
  }
};
