import { AUTH } from './AuthenticationActionTypes';
import { securityService } from '../../Common/Services';
import UserSessionService from '../../Common/Services/userSesionService';

export const submitLoginAction = data => ({
	type: AUTH.SUBMIT_LOGIN,
	payload: data,
});

export const submitAuthEmailAction = data => ({
	type: AUTH.CHECK_GUEST_EMAIL,
	payload: data,
});

export const submitGuestUserAuthAction = data => ({
	type: AUTH.AUTH_GUEST_USER,
	payload: data,
});

export const registerGuestUserAction = data => ({
	type: AUTH.REGISTER_GUEST_USER,
	payload: data,
});

export const multiProfileLoginAction = data => ({
	type: AUTH.MULTI_PROFILE_LOGIN,
	payload: data,
});

export const logoutAction = () => ({
	type: AUTH.LOGOUT,
	payload: {},
});

export const guestLogoutAction = () => ({
	type: AUTH.GUEST_LOGOUT,
	payload: {},
})

export const isLoggedInAction = () => ({
	type: AUTH.IS_LOGGED_IN,
	payload: securityService.isLoggedIn(),
});

export const signUpAction = userData => ({
	type: AUTH.SIGN_UP,
	payload: userData,
});

export const forgotPasswordAction = userData => ({
	type: AUTH.FORGOT_PASSWORD,
	payload: userData,
});

export const resetPasswordAction = userData => ({
	type: AUTH.RESET_PASSWORD,
	payload: userData,
});

export const submitAuthAction = MFAData => ({
	type: AUTH.MAIL_AUTH,
	payload: MFAData,
})

export const submitResendOTPAction = MFAData => ({
	type: AUTH.RESEND_OTP,
	payload: MFAData,
})

export const submitResendGuestOTPAction = MFAData => ({
	type: AUTH.RESEND_GUEST_OTP,
	payload: MFAData,
})

export const getMFAAuthDataAction = () => ({
	type: AUTH.SET_OTP_INFO,
	payload: UserSessionService.getMFAAuthData(),
});

export const sendLoginPasscodeAction = (data) => ({
	type: AUTH.MFA_SEND_LOGIN_OTP,
	payload: data,
});

export const mfaSetupPhoneNumberAction = (data) => ({
	type: AUTH.MFA_SETUP_PHONE_NUMBER,
	payload: data,
});

export const verifyLoginPasscodeAction = (data) => ({
	type: AUTH.MFA_VERIFY_LOGIN_OTP,
	payload: data,
});

export const resendLoginPasscodeAction = (mfaToken) => ({
	type: AUTH.MFA_RESEND_LOGIN_OTP,
	payload: mfaToken,
});
