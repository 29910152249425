import { AVKClearFreezeFrameEvent } from "availkit-js/dist/Models/Events/AVKClearFreezeFrameEvent";
import { AVKFreezeFrameService } from "availkit-js/dist/Services/AVKFreezeFrameService";

import { useDispatch } from "react-redux";

import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { clearLocalAnnotationsThunk } from "src/domains/Beacon/store/telestration/thunks/clearLocalAnnotationsThunk";
import { uiActions } from "src/domains/Beacon/store/ui";
import { clearFreezeFrameImage } from "src/domains/Beacon/utils/canvas";
import { logger, LoggerLevels } from "src/logging/logger";

// Must clean the FF image for all participants when host unfreezes frame
export const useOnReceivedClearFreezeFrame = () => {
  const dispatch = useDispatch();

  // Must need to declare the AVK listener params even if aren't in use, otherwise,
  // AVK will show an error of "malformed event" message
  const onReceivedClearFreezeFrame = (
    _: AVKFreezeFrameService,
    __: AVKClearFreezeFrameEvent
  ) => {
    try {
      logger().info("Received Clear Freeze Frame event");
      // ensure Telestration state and UI cleared for Participants
      dispatch(clearLocalAnnotationsThunk());

      // Participants don't have the FF Canvas, they instead have an img tag,
      // with the FF image
      clearFreezeFrameImage();

      // Stores the FreezeFrame state
      dispatch(
        streamActions.setFreezeFrame({
          type: "fullscreen",
          active: false,
          transferId: null,
          imageData: "",
        })
      );

      // Should hide the orange indictor for FF
      dispatch(
        uiActions.setIndicator({
          color: null,
          label: "",
          show: false,
        })
      );
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "onReceivedClearFreezeFrame",
        },
        "Error while receiving Clear Freeze Frame event.",
        error?.message
      );
    }
  };

  return onReceivedClearFreezeFrame;
};
