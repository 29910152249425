import { createSelector } from 'reselect';
import SessionStorageService from '../../../../src/services/SessionStorageService';
import userService from '../../Common/Services/userService';

export const getcurrentUserTypes = state => state.homeReducer.currentUser && state.homeReducer.currentUser.userTypes;
export const getIsLoaderActive = state => state.homeReducer.isLoaderActive;

export const getDefaultRoute = createSelector(getcurrentUserTypes, (userTypes) => {
	const finishedAccountSetup = SessionStorageService.get('finishedAccountSetup');
	const expiredAccount = SessionStorageService.get('expiredAccount')
	const resetPassword = SessionStorageService.get('resetPassword')
	const userinfo = SessionStorageService.get('cachedUserInfo')
	if(expiredAccount) {
		return '/expire-password?userId='+userinfo.id
	} 
	else if(resetPassword) {
		return '/reset-password'
	}
	if (!finishedAccountSetup &&
		(userService.checkIsVendorManager(userTypes[0])
			|| userService.checkIsVendorSalesRep(userTypes[0])
			|| userService.checkIsVendorAdmin(userTypes[0]))) {
		return '/accountCreation';
	}

  // Guest users should not attempt to access any page other than /event
  // Note: guest will never be presented with a logout. We rely on session storage
  // clearing for the guest user once they close the tab. 
  if (userService.checkIsGuestUser(userTypes[0])) {
    return '/not-found';
  }

	return null;
});
