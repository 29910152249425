import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter, useLocation } from 'react-router-dom';

import { locations } from "src/utils/locations";
import { getIsLoggedIn } from '../../../Features/Authentication/AuthenticationSelectors';
import UserSessionService from '../../Services/userSesionService';

/* This is a list of routes that if a user is trying to access we redirect them 
 * to a separate login page instead of regular login page. 
 * TODO - refactor to only use single login flow; requires Product input
 */
const OTPWhiteList = ["/event"];

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  const { pathname, search } = useLocation();

  const encodedRedirectURL = `${pathname}${search}`;
  // Determine which login path to use
  const isPathWhiteListed = OTPWhiteList.includes(pathname);

  if (!isLoggedIn && isPathWhiteListed) {
    UserSessionService.setRedirectUrl(encodedRedirectURL);
  }


	return <Route
		{...rest}
		render={props => (
			isLoggedIn ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: isPathWhiteListed ? locations.eventLogin : locations.login,
            /* when trying to access a private path, always store URL to 
             * redirect to intended destination
             */
					}}
				/>
			)
		)}
	/>
};

const mapStateToProps = state => ({
	isLoggedIn: getIsLoggedIn(state),
});

export default withRouter(connect(mapStateToProps, undefined)(PrivateRoute));
