import { useLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";

import moment from "moment";

import { TrialBanner } from "src/domains/App/TrialBanner";
import { EventCreatePage } from "src/domains/Events/CreatePage";
import { EventEditPage } from "src/domains/Events/EditPage";
import { EventsListPage } from "src/domains/Events/ListPage";
import { UsagePage } from "src/domains/Usage";
import { FEATURE_FLAGS } from "src/featureFlags";
import { AppState } from "src/reducer";
import { WebAppRoutes } from "src/routes/WebAppRoutes";
import { hideReCaptcha } from "src/services/ReCaptchaHelper";
import { locations } from "src/utils/locations";

import { newlogo2021 } from "avail-web-application/Common/Assets/Images";
import { Loader } from "avail-web-application/Common/Components";
import "avail-web-application/Common/Styles/fonts.scss";
import "avail-web-application/Common/Styles/typography.scss";
import { logoutAction } from "avail-web-application/Features/Authentication/AuthenticationActions";
import Header from "avail-web-application/Features/Home/Common/Header/Header";
import IdleAlert from "avail-web-application/Features/Home/Common/IdleAlert/IdleAlert";
import Meeting from "avail-web-application/Features/Home/Features/Meeting/Meeting";
import {
  getUserMeetingTimeAction,
  getUserMissedCallsAction,
} from "avail-web-application/Features/Home/Features/Scheduler/SchedulerActions";
import {
  startIdleTimerAction,
  setShowIdleAlertAction,
} from "avail-web-application/Features/Home/HomeActions";
import { getDefaultRoute } from "avail-web-application/Features/Home/HomeSelectors";
import "avail-web-application/Features/Home/HomeView.scss";

import { logger } from "portalcall/commoncall/common/logger";

import { SideMenu } from "../domains/App/SideMenu";

const HomeView = () => {
  const [activeIdleModal, setActiveIdleModal] = useState(false);
  const dispatch = useDispatch();
  const ldClient = useLDClient();

  const {
    homeReducer: { currentUser, isLoaderActive, showIdleAlert },
    meetingReducer: { isCallIncoming, meetingInfo },
    router: { location },
  } = useSelector((state: AppState) => state);

  const defaultRoute = useSelector((state: AppState) => getDefaultRoute(state));

  useEffect(() => {
    dispatch(startIdleTimerAction());
    getMeetingTime();
    hideReCaptcha();

    ldClient.identify(
      {
        key: currentUser.email,
        name: currentUser.firstName,
        email: currentUser.email,
      },
      null,
      // below callback runs after the info above is successfully saved
      // the new flags will be instantly available
      () => {
        logger().info("New user's flags available");
      }
    );
  }, []);

  useEffect(() => {
    if (showIdleAlert && !activeIdleModal) {
      toggleIdleAlert();
    }

    // reCaptcha is not used at all on PortalCall but to resolve intermittant
    // issues (https://avail.atlassian.net/browse/SOFT-2792) check for the
    // reCaptcha badge on each update to really guarantee this will not affect users
    const recaptchaBadge = document.getElementsByClassName("grecaptcha-badge");
    if (recaptchaBadge.length > 0) {
      hideReCaptcha();
    }
  }, [activeIdleModal, showIdleAlert]);

  // Get meeting events. In case of recent missed call, show notification
  const getMeetingTime = () => {
    const currentDate = new Date(Date.now());
    const startDay = new Date(currentDate);
    startDay.setDate(startDay.getDate() - 365);
    const startDate = `${moment(new Date(startDay)).format(
      "YYYY-MM-DDTHH:mm:ss.SSS"
    )}Z`;
    const endDate = `${moment(new Date(currentDate))
      .add(3, "day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS")}Z`;

    dispatch(getUserMeetingTimeAction(currentUser.id, startDate, endDate));
    dispatch(getUserMissedCallsAction(currentUser.id, startDate, endDate));
  };

  const toggleIdleAlert = () => {
    setActiveIdleModal(!activeIdleModal);
  };

  return (
    <div className="home">
      {isLoaderActive && (
        <Loader className="loader" color="#0097E9" size={20} />
      )}

      {/* Handles incoming call notification for now */}
      <Meeting />

      <div className="avail-content">
        <TrialBanner />
        {location.pathname !== "/accountCreation" && <Header />}
        <div className="page">
          {location.pathname !== "/accountCreation" && <SideMenu />}
          <div className="flex--one overflow-y--auto">
            <Route
              // context="home"
              path="/home"
              component={() => (
                <div className="justify-content--center align--center height--full home--title flex--one">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="home-view-logo"
                      src={newlogo2021}
                      alt="avail"
                    />
                  </div>
                </div>
              )}
            />

            {/* This component defines all the routes written in the old code under the avail-web-application/ folder */}
            <WebAppRoutes />

            {/* Routes in new code (under top level /src/) go here */}
            <Route exact path={locations.events} component={EventsListPage} />
            {FEATURE_FLAGS.showUsagePage && (
              <Route path={locations.usage} component={UsagePage} />
            )}
            <Route exact path={locations.editEvent} component={EventEditPage} />
            <Route
              exact
              path={locations.createNewEvent}
              component={EventCreatePage}
            />

            {defaultRoute && <Redirect from="/" exact to={defaultRoute} />}
          </div>
        </div>
      </div>

      {!meetingInfo && !isCallIncoming && activeIdleModal && (
        <IdleAlert
          onConfirm={() => {
            dispatch(setShowIdleAlertAction(false));
            toggleIdleAlert();
          }}
          countDownAction={() => {
            dispatch(setShowIdleAlertAction(false));
            toggleIdleAlert();
            dispatch(logoutAction());
          }}
        />
      )}
    </div>
  );
};

export default withLDConsumer({ clientOnly: true })(withRouter(HomeView));
