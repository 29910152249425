import { VENDORS } from './VendorsActionTypes';

const initialState = {
	vendors: null,
	vendor: null,
	vendorsPerBrand: null,
	selectedBusinessUnit: null,
	pagination: {
		page: 0,
		pageSize: 10,
		totalPages: 1,
	},
	vendorTherapies: null,
	businessUnitsPerBrand: null,
	managersPerBusinessUnit: null,
	managersBelowMe: null,
	BuAdminsAndManagers: null,
	businessUnitDistricts: null,
	vendorsHospitals: null,
	vendorsSupportedAndNewHospitals: null,
	businessUnitUsers: null,
	therapies: null,
	mfaOrgLevelFrequency: "every_day",
	mfaOrgLevelTwoFactor: true,
	mfaFrequencyTypes: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case VENDORS.SET_VENDORS:
			return {
				...state,
				vendors: action.payload,
			};
		case VENDORS.SET_VENDORS_PAGINATION:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload.pageNumber,
					size: action.payload.pageSize,
					totalPages: action.payload.totalPages,
				},
			};
		case VENDORS.SET_VENDOR:
			return {
				...state,
				vendor: action.payload,
			};
		case VENDORS.SET_SELECTED_BUSINESS_UNIT:
			return {
				...state,
				selectedBusinessUnit: action.payload,
			};
		case VENDORS.SET_VENDOR_THERAPIES:
			return {
				...state,
				vendorTherapies: action.payload,
			};
		case VENDORS.SET_THERAPIES:
			return {
				...state,
				therapies: action.payload,
			};
		case VENDORS.SET_VENDORS_FOR_BRAND:
			return {
				...state,
				vendorsPerBrand: action.payload,
			};
		case VENDORS.SET_BUSINESS_UNITS_FOR_BRAND:
			return {
				...state,
				businessUnitsPerBrand: action.payload,
			};
		case VENDORS.SET_MANAGERS_FOR_BUSINESS_UNIT:
			return {
				...state,
				managersPerBusinessUnit: action.payload,
			};
		case VENDORS.SET_MANAGERS_BELOW_ME:
			return {
				...state,
				managersBelowMe: action.payload,
			};
		case VENDORS.SET_ADMINS_AND_MANAGERS:
			return {
				...state,
				BuAdminsAndManagers: action.payload,
			};
		case VENDORS.SET_ORG_REMEMBER_FREQUENCY:
			return {
				...state,
				mfaOrgLevelFrequency: action.payload
			};
		case VENDORS.SET_ORG_FREQUENCY_TYPES:
			return {
				...state,
				mfaFrequencyTypes: action.payload
			};
		case VENDORS.SET_ORG_TWO_FACTOR_AUTH:
			return {
				...state,
				mfaOrgLevelTwoFactor: action.payload
			};
		case VENDORS.SET_BUSINESS_UNIT_DISTRICTS:
			return {
				...state,
				businessUnitDistricts: action.payload,
			};
		case VENDORS.SET_SELECTED_DISTRICT:
			return {
				...state,
				selectedDistrict: action.payload,
			};
		case VENDORS.SET_VENDORS_HOSPITALS:
			return {
				...state,
				vendorsHospitals: action.payload,
			};
		case VENDORS.SET_SUPPORTED_AND_NEW_HOSPITALS:
			return {
				...state,
				vendorsSupportedAndNewHospitals: action.payload,
			};
		case VENDORS.SET_BUSINESS_UNIT_USERS:
			return {
				...state,
				businessUnitUsers: action.payload,
			};
    case VENDORS.UPDATE_BUSINESS_UNIT_USER_TO_MEMBER:
      const { userId } = action;

      // This is used to update the UI in real-time
      const updatedUsers = state.businessUnitUsers.map(user => {
        if (user.id === userId) {
          return {
            ...user,
            memberType: "MEMBER",
          }
        }
        return user;
      })

      return {
        ...state,
        businessUnitUsers: updatedUsers
      }
		case VENDORS.SET_BUSINESS_UNIT_USERS_PAGINATION:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload.pageNumber,
					size: action.payload.pageSize,
					totalPages: action.payload.totalPages,
				},
			};
		case VENDORS.SET_USER_THERAPIES:
			return {
				...state,
				userTherapies: action.payload,
			};
		default:
			return state;
	}
}
