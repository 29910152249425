import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { AVKExternalInputPosition } from "availkit-js/dist/Models/AVKExternalInputPosition";
import { AVKExternalInputShift } from "availkit-js/dist/Models/AVKExternalInputShift";
import { AVKExternalInputMoveEvent } from "availkit-js/dist/Models/Events/AVKExternalInputMoveEvent";
import { NCameraZoomEvent } from "availkit-js/dist/Models/Events/NCameraZoomEvent";

import { useDispatch } from "react-redux";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Slider as MUISlider } from "@mui/material";

import { IconButton } from "src/components/Button/IconButton/IconButton";
import { Tooltip } from "src/components/Tooltip";
import { PTZToolTips } from "src/constants/tooltips";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import {
  selectCameras,
  selectConsoleHasExternalInputResizing,
  selectZoomState,
} from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import {
  LayoutFrameNames,
  ZoomFrames,
} from "src/domains/Beacon/store/stream/types";
import { CameraIdentifier } from "src/domains/Beacon/utils/availKit";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { logger } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

import styles from "./styles.scss";

export interface InputControlsSliderProps {
  side: LayoutFrameNames;
  "data-test-id"?: string;
}

export const InputControlsSlider = ({
  "data-test-id": dataTestId,
  side,
}: InputControlsSliderProps) => {
  const cameras = useAppSelector(selectCameras);
  const { callSid } = useAppSelector(selectMeetingState);
  const zoomState = useAppSelector(selectZoomState);
  const availKit = AvailKitService.instance;
  const dispatch = useDispatch();
  const { externalInputsImageResizing } = useFeatureFlags();
  const consoleHasExternalInputResizing = useAppSelector(
    selectConsoleHasExternalInputResizing
  );
  const hasExternalInputResizing =
    consoleHasExternalInputResizing && externalInputsImageResizing;

  const getZoomValue = () => zoomState[side].value * 10;

  const marks = [
    {
      value: 40,
    },
  ];

  const checkCameraSelected = () => {
    const id = zoomState[side].cameraId;
    return id.includes("Front") || id.includes("Overhead");
  };

  const sendZoomCameraMessage = (camera) => {
    if ("cameraIdentifier" in camera) {
      const cameraZoomEvent = new NCameraZoomEvent(callSid, camera);
      availKit?.eventService.broadcast(cameraZoomEvent);
    } else {
      const externalInputPTZEvent = new AVKExternalInputMoveEvent(
        callSid,
        camera
      );
      availKit?.eventService.broadcast(externalInputPTZEvent);
    }
  };

  const onSliderZoomIncrement = (inc: number) => {
    const currentZoom = zoomState[side].value;
    onSliderZoom(currentZoom + inc);
  };

  const onSliderZoom = (evt): void => {
    let value = evt;

    if (value > 10) {
      value = 10;
    }
    if (value < 0) {
      value = 0;
    }

    const localZoomState: ZoomFrames = { ...zoomState };
    const currentZoomSlider = localZoomState[side];
    const localCameras = JSON.parse(JSON.stringify(cameras)) as (
      | AVKCamera
      | AVKExternalInput
    )[];

    // check to ensure the value falls within bounds of settings
    let iValue = parseFloat(value);
    if (isNaN(iValue)) {
      iValue = currentZoomSlider.settings.min;
    } else if (iValue < currentZoomSlider.settings.min) {
      iValue = currentZoomSlider.settings.min;
    } else if (iValue >= currentZoomSlider.settings.max) {
      iValue = currentZoomSlider.settings.max - currentZoomSlider.settings.step;
    }

    // We have to do this test for actual change in zoom value because the
    // Slider component gets updated by the program changing a parameter, then
    // the UI changing position.
    if (iValue === currentZoomSlider.value) {
      return;
    }

    const cameraIndex = localCameras.findIndex(
      (camera: AVKCamera | AVKExternalInput) =>
        CameraIdentifier(camera) === currentZoomSlider.cameraId
    );
    const zoomNum =
      (iValue - currentZoomSlider.settings.min) /
      (currentZoomSlider.settings.max - currentZoomSlider.settings.min);

    logger().info(
      `---> Video Source [${CameraIdentifier(localCameras[cameraIndex])}] ` +
        `Zoom[ ${currentZoomSlider.value} -> ${zoomNum}]`
    );

    const activeVideoSource: AVKCamera | AVKExternalInput =
      localCameras[cameraIndex];
    const activeZoom = { ...localZoomState[side] };

    if ("cameraIdentifier" in activeVideoSource) {
      activeVideoSource.location.zoomLevel = zoomNum;
      activeZoom.value = iValue;
      sendZoomCameraMessage(activeVideoSource);
    } else {
      // TODO - availkit will change to constructor
      const positionClone = activeVideoSource.position;
      const position = new AVKExternalInputPosition();
      const shift = new AVKExternalInputShift();
      position.x = -1;
      position.y = -1;
      shift.x = 0;
      shift.y = 0;
      activeVideoSource.isAutoCrop = value === 4;
      activeVideoSource.zoomLevel = zoomNum;
      activeVideoSource.position = position;
      activeVideoSource.shift = shift;
      activeZoom.value = iValue;
      sendZoomCameraMessage(activeVideoSource);
      activeVideoSource.position = positionClone;
      localCameras[cameraIndex] = activeVideoSource;
    }

    localZoomState[side] = activeZoom;

    dispatch(
      streamActions.setCameras([...localCameras] as (
        | AVKCamera
        | AVKExternalInput
      )[])
    );
    dispatch(streamActions.setZoomState(localZoomState));

    // Must reset the selected preset, info will not match afterwards
    dispatch(streamActions.setPresetSelected(null));
  };

  return (
    <Tooltip title={PTZToolTips.zoomSlider} color="black" placement="left">
      <div className={styles.slider}>
        <IconButton
          aria-label="callcontrols-slider-zoomin"
          background="dark"
          classes={{
            root: styles.icon,
            disabled: styles.disabled,
          }}
          disabled={!hasExternalInputResizing && !checkCameraSelected()}
          onClick={() => onSliderZoomIncrement(1)}
          data-test-id={`${dataTestId}-zoomin`}
        >
          <AddIcon />
        </IconButton>
        <MUISlider
          classes={{
            root: styles.root,
            mark: styles.mark,
            rail: styles.rail, // unselected range
            thumb: styles.thumb, // the grabbing knob
            track: styles.track, // selected range
          }}
          disabled={!hasExternalInputResizing && !checkCameraSelected()}
          step={10}
          marks={hasExternalInputResizing && !checkCameraSelected() && marks}
          onChange={(e) =>
            onSliderZoom(
              parseInt((e.target as HTMLInputElement).value, 10) / 10
            )
          }
          orientation="vertical"
          value={getZoomValue()}
          data-test-id={`${dataTestId}-slider`}
        />
        <IconButton
          aria-label="callcontrols-slider-zoomout"
          background="dark"
          classes={{
            root: styles.icon,
            disabled: styles.disabled,
          }}
          disabled={!hasExternalInputResizing && !checkCameraSelected()}
          onClick={() => onSliderZoomIncrement(-1)}
          data-test-id={`${dataTestId}-zoomout`}
        >
          <RemoveIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};
