import React, { Component } from 'react';
import { Radio } from 'semantic-ui-react';

export default class RadioButton extends Component {
	handleChange() {
		this.props.onChangeHandler(this.props.name);
	}

	render() {
		const { error, className, ...props } = this.props;

		return (
			<div className={className}>
				<Radio
					onChange={this.handleChange}
					{...props}
				/>
			</div>
		);
	}
}