import classNames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Accordion, List } from 'semantic-ui-react';
/* Components */
import { Fields } from '../../../../Common/Components';
import Icon from '../../../../Common/Components/Icon/Icon';
/* Styles */
import './AccordionComponent.scss';

class AccordionComponent extends PureComponent {
	state = { activeIndex: null };

	onAccordionClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	isChecked = (therapyId) => {
		let checked = false;
		const keys = [];
		this.props.data.filter((e) => {
			if (therapyId === e.id) {
				e.procedures.filter((procedure) => {
					if (this.props.selectedValues) {
						if (this.props.selectedValues.procedures) {
							Object.entries(this.props.selectedValues.procedures).forEach(([key, value]) => {
								if (value) {
									if (procedure.id === key) {
										keys.push(key);
									}
									if ((keys.length === e.procedures.length)) {
										checked = true;
									}
								}
							});
						}
					}
				});
			}
		});
		return checked;
	};

	render() {
		const { activeIndex } = this.state;
		return (
			<Accordion className={classNames('accordion-component', this.props.className)}>
				{
					this.props.data && (
						this.props.data.length ? this.props.data.map((therapy, index) => (
							<React.Fragment key={therapy.id}>
								<Accordion.Title
									active={activeIndex === index}
									index={index}
									onClick={this.onAccordionClick}
									className="align--center"
								>
									<Icon
										className={classNames({
											'accordion-component__icon--rotate': activeIndex === index,
											'icon-chevron-right-outline': true,
											'icon--dark-blue': true,
										})}
									/>
									{
										this.props.therapyName ? (
											<Fields.CheckboxField
												label={therapy.name}
												name={`${this.props.therapyName}.${therapy.id}`}
												className="accordion-component__checkbox accordion-component__checkbox-label"
												checked={this.isChecked(therapy.id)}
												disabled={this.props.district || this.props.disabledTherapies}
											/>
										) : <span className="accordion-component__accordion-title">{therapy.name}</span>
									}
								</Accordion.Title>
								{activeIndex === index &&
								<Accordion.Content active={activeIndex === index} className="accordion-component__accordion-list">
									<List>
										{
											(!this.props.district && this.props.procedureName) && (
												<List.Item>
													<div className="form-field">
														<input
															className="av-checkbox"
															type="checkbox"
															checked={this.isChecked(therapy.id)}
															id="selectAll"
															onChange={(e) => {
																this.props.onSelectAllHandler(e, therapy.id);
															}}
															disabled={this.props.disabledProcedures || this.props.district || this.props.readOnly}
														/>
														<label htmlFor="selectAll">Select all</label>
													</div>
												</List.Item>
											)
										}
										{therapy.procedures.map(procedure => (
											<List.Item key={procedure.id}>
												{
													this.props.procedureName ? (
														<Fields.CheckboxField
															label={procedure.name}
															name={`${this.props.procedureName}.${procedure.id}`}
															className="accordion-component__checkbox"
															disabled={this.props.disabledProcedures || this.props.district || this.props.readOnly}
														/>
													) :
														<span className="accordion-component__accordion-subtitle">{procedure.name}</span>
												}
											</List.Item>))}
									</List>
								</Accordion.Content>}
							</React.Fragment>
						)) : <div>Your business unit currently doesn't support any procedures. Please check later.</div>
					)
				}
			</Accordion>
		);
	}
}

AccordionComponent.defaultProps = {
	disabledTherapies: false,
	disabledProcedures: false,
};

AccordionComponent.propTypes = {
	data: PropTypes.array,
	disabledTherapies: PropTypes.bool,
	disabledProcedures: PropTypes.bool,
};

export default AccordionComponent;
