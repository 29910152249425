import { AVKFreezeFrameServiceListener } from "availkit-js/dist/Services/Listeners/AVKFreezeFrameServiceListener";

import { useEffect } from "react";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectIsUserHost } from "src/domains/Beacon/store/meeting/selectors";
import { selectHasConsoleJoined } from "src/domains/Beacon/store/twilio/selectors";
import { AvailKitService } from "src/services/AvailKitService";

import { useOnReceiveFreezeFramePosted } from "./useOnReceiveFreezeFramePosted";
import { useOnReceivedClearFreezeFrame } from "./useOnReceivedClearFreezeFrame";

// TODO: When there's 2 separated listeners of the same service, like for example,
// AVKFreezeFrameServiceListener, the listeners won't work properly, we would have to
// look for a better way to split the services' listeners
export const useFreezeFrameService = () => {
  const availKit = AvailKitService.instance;
  const consoleHasJoined = useAppSelector(selectHasConsoleJoined);
  const isHostUser = useAppSelector(selectIsUserHost);
  const onReceivedClearFreezeFrame = useOnReceivedClearFreezeFrame();
  const onReceiveFreezeFramePosted = useOnReceiveFreezeFramePosted();

  useEffect(() => {
    const freezeFrameListener: AVKFreezeFrameServiceListener = {
      onReceivedClearFreezeFrame,
      onReceiveFreezeFramePosted,
    };

    // Only participants will receive this event cause' host clears its local
    // FF image
    if (consoleHasJoined && !isHostUser) {
      availKit.avkFreezeFrameService.addEventListener(freezeFrameListener);
    }

    return () => {
      availKit.avkFreezeFrameService.removeEventListener(freezeFrameListener);
    };
  }, [availKit, consoleHasJoined, isHostUser]);
};
