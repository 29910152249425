import { BaseResponse } from "../types";

export interface TrialUsageContent {
  // all days added to the customer/user for trial
  addedTrialDays: number;
  // all minutes added to the customer/user for trial
  addedTrialMinutes: number;
  // remaining days in trial for the customer/user
  availableTrialDays: number;
  // remaining minutes in trial for the customer/user
  availableTrialMinutes: number;
  // total days used by the customer/user in P2P calls or MP events
  removedTrialDays: number;
  // total minutes used by the customer/user in P2P calls or MP events
  removedTrialMinutes: number;
  scheduledTrialMinutes: number; // minutes that the trial user is scheduled in events for
  timestamp: string;
  userId: string;
  organizationId: string;
}

export enum CallTypes {
  event = "EVENT",
  p2p = "POINT_TO_POINT",
}

export interface UserCallHistoryEvent {
  callId: string;
  callSessionId: string;
  creatorOrganizationName: string;
  creatorUserId: string;
  description: string | null;
  duration: number;
  durationUnit: string;
  endDate: string; // datetime
  eventId: string;
  hospitalId: string;
  hospitalName: string;
  startDate: string; // datetime
  subject: string;
  surgeonName: string;
  type: CallTypes;
}
// if we have to separate event type and p2p types then just add an OR "|" with the new type on the below line
export type UserCallHistoryItem = UserCallHistoryEvent;

export type UserCallHistoryResponse = BaseResponse<UserCallHistoryItem[]>;
export type TrialUsageResponse = BaseResponse<TrialUsageContent>;
