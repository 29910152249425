export const PROVIDER = {
	SET_PROVIDER_PAGINATION: 'SET_PROVIDER_PAGINATION',
	SET_PROVIDER_USERS: 'SET_PROVIDER_USERS',
	GET_ALL_PROVIDERS: 'PROVIDER_GET_ALL_PROVIDERS',
	SET_ALL_PROVIDERS: 'PROVIDER_SET_ALL_PROVIDERS',
	GET_PROVIDER_USERS: 'GET_PROVIDER_USERS',
	CREATE_NEW_PROVIDER: 'PROVIDER_CREATE_NEW_PROVIDER',
	DELETE_PROVIDER: 'PROVIDER_DELETE_PROVIDER',
	GET_PROVIDER_BY_ID: 'PROVIDER_GET_PROVIDER_BY_ID',
	SET_PROVIDER_BY_ID: 'PROVIDER_SET_PROVIDER_BY_ID',
	UPDATE_PROVIDER_BY_ID: 'PROVIDER_UPDATE_PROVIDER_BY_ID',
	APPLY_TO_SECONDARY_MANAGER: 'APPLY_TO_SECONDARY_MANAGER',
	SET_PROVIDER_USERS_PAGINATION: 'SET_PROVIDER_USERS_PAGINATION',
};
