import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { Button, Fields, validators } from '../../../../../../Common/Components';
import { notificationService } from '../../../../../../Common/Services';
import { getUserByTokenAction, registerUserAction } from '../../../../../Home/Features/Users/UsersActions';
import { setInitialValues } from '../../../../AuthenticationSelectors';

class AccountSetupForm extends PureComponent {
	componentDidMount() {
		const { getUserByToken } = this.props;

		getUserByToken(this.props.token);
	}

	componentDidUpdate() {
		const { user } = this.props;

		if (user && user.existingUser) {
			const userData = {
				...user,
				confirmPassword: null,
				password: null,
			};

			try {
				// automatically register user
				this.props.registerUser({
					userData,
					userId: user.id,
				});
			} catch {
				notificationService.error('Registration has failed.');
			}

			this.props.history.push('/login');
		}
	}

	onSubmit = (data) => {
		const { user } = this.props;

		this.props.registerUser({
			userData: data,
			userId: user && user.id,
		});
	};

	render() {
		const { t, user } = this.props;
    const isAutoCreated = user?.autoCreated;
    const title = isAutoCreated
      ? "Please complete your account"
      : "Please setup your password";

		return (
			<React.Fragment>
        <div className="login__subheading">{title}</div>
				<div className="tabs__wrapper">
					<form className="width--full">
						<Fields.InputField
							placeholder={t('common.form.email.placeholder')}
							name="email"
							validate={validators.required}
							disabled
						/>
						{/* Only show first/last name for users that are auto created because we dont fill those fields for them */}
						{ isAutoCreated ? 
							<React.Fragment>
								<Fields.InputField
									placeholder={t('common.form.first_name.placeholder')}
									name="firstName"
									validate={[validators.required, validators.onlyLetters, validators.minimumLength2, validators.maximumLength48]}
								/>
								<Fields.InputField
									placeholder={t('common.form.last_name.placeholder')}
									name="lastName"
									validate={[validators.required, validators.onlyLetters, validators.minimumLength2, validators.maximumLength48]}
								/>
							</React.Fragment>
						: null}
						
						<Fields.InputField
							placeholder={t('common.form.password.placeholder')}
							name="password"
							validate={[
								validators.required,
								validators.numberValidator,
								validators.capitalValidator,
								validators.lowerValidator,
								validators.specialValidator,
								validators.minimumLength8,
								validators.maximumLength15,
							]}
							type="password"
						/>
						<Fields.InputField
							placeholder={t('common.form.confirm_password.placeholder')}
							name="confirmPassword"
							type="password"
							validate={[validators.required, validators.passwordValidate]}
						/>
						<div className="display--flex justify-content--flex-end account-setup__button">
							<Button isFull isGreen type="submit" onClick={this.props.handleSubmit(this.onSubmit)}>Create Account</Button>
						</div>
					</form>
				</div>
			</React.Fragment>
		);
	}
}

AccountSetupForm.propTypes = {
	token: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
	initialValues: setInitialValues(state),
	user: state.usersReducer.user,
});

const mapDispatchToProps = dispatch => ({
	registerUser: userData => dispatch(registerUserAction(userData)),
	getUserByToken: token => dispatch(getUserByTokenAction(token)),
});

const AccountSetupFormWithTranslation = withTranslation()(AccountSetupForm);

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(reduxForm({
		form: 'accountSetupForm',
	})(AccountSetupFormWithTranslation)),
);
