import { useEffect } from "react";
import { connect } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { useOnPageRefresh } from "src/hooks/useOnPageRefresh";
import { history } from "src/reducer";
import { clearUserSession } from "src/utils/clearUserSession";

import { submitResendGuestOTPAction } from "avail-web-application/Features/Authentication/AuthenticationActions";
import withAuthentication from "avail-web-application/Features/Authentication/Components/withAuthentication";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

import { LoginPage } from "../../../Login";

interface DispatchProps {
  resendOTP: (data) => void;
}

interface StateProps {
  errorMessage: string;
  otpInfo: any;
}

type Props = StateProps & DispatchProps;

function OTPFailure({ errorMessage, otpInfo, resendOTP }: Props) {
  useOnPageRefresh(() => clearUserSession());

  useEffect(() => {
    // checks for empty session storage
    if (localStorage.getItem("MFAAuthDetails") === null) {
      history.push("/event-login");
    }
  }, []);

  const handleResendOTP = () => {
    if (otpInfo.mfaToken) {
      const mfaData = {
        mfa_token: otpInfo.mfaToken,
      };
      resendOTP(mfaData);
    }
  };

  // TODO: move this to interceptor
  const processErrorTimer = (): string => {
    const array: string[] = errorMessage.split(" ");
    return array[array.length - 2];
  };

  const processErrorString = (): string => {
    const array: string[] = errorMessage.split(".");
    return array[array.length - 1];
  };

  return (
    <LoginPage className="login">
      <Grid container spacing={2} direction={"column"}>
        <Grid item>
          <Box m={2}>
            <Typography variant="h4" align={"center"}>
              {DomainConstants.label.incorrectPasscodeTitle}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box m={2}>
            <Typography variant="body1" align={"center"}>
              {processErrorString()}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box m={2}>
            <Typography variant="h2" align={"center"}>
              {processErrorTimer()}
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box m={2}>
            {/* TODO: move styles later */}
            <Button
              fullWidth
              style={{
                backgroundColor: "#7EBB45",
              }}
              onClick={handleResendOTP}
            >
              {DomainConstants.label.refreshTimer}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LoginPage>
  );
}

const mapStateToProps = (state) => ({
  errorMessage: state.authenticationReducer.errorMessage,
  otpInfo: state.authenticationReducer.otpInfo,
});

const mapDispatchToProps = (dispatch) => ({
  resendOTP: (MFAData) => dispatch(submitResendGuestOTPAction(MFAData)),
});

export default withAuthentication(
  connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )(OTPFailure)
);
