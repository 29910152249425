import { APPROVAL_LIST } from './ApprovalListActionTypes';

const initialState = {
	userApplications: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case APPROVAL_LIST.SET_ALL_USER_APPLICATIONS:
			return {
				...state,
				userApplications: action.payload,
			};
		default:
			return state;
	}
}
