import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* Components */
import ConsolesTable from '../../Common/ConsolesTable/ConsolesTable';
/* Actions */
import { getAllConsolesAction } from './ConsolesActions';

import './Consoles.scss';

class Consoles extends PureComponent {
	render() {
		return (
			<div className="users__container">
				<ConsolesTable
					getAllConsoles={params => this.props.getAllConsoles(params)}
					consoles={this.props.consoles}
					headerTitle="Consoles"
				/>
			</div>
		);
	}
}

Consoles.defaultProps = {
	consoles: [],
};

Consoles.propTypes = {
	consoles: PropTypes.array,
};

const mapStateToProps = state => ({
	consoles: state.consolesReducer.consoles,
	hospitals: state.hospitalsReducer.hospitals,
});

const mapDispatchToProps = dispatch => ({
	getAllConsoles: params => dispatch(getAllConsolesAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Consoles);
