import clsx from "clsx";

import { ICON_SIZES } from "src/components/CustomIcons/constants";

import styles from "../styles.scss";
import { IconProps } from "../types";

export const LeftFrameIcon = ({
  color = "white",
  size = "small",
  className,
}: IconProps) => {
  const [width, height] = [ICON_SIZES[size][0], ICON_SIZES[size][1]];
  return (
    <div style={{ width, height }} className={styles.icon}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="7"
          height="15"
          rx="1"
          className={clsx(styles[color], className)}
        />
        <rect
          opacity="0.5"
          x="8"
          width="7"
          height="15"
          rx="1"
          className={clsx(styles[color], className)}
        />
      </svg>
    </div>
  );
};
