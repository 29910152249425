import {
  LocalAudioTrack,
  LocalVideoTrack,
  NetworkQualityLevel,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
  Room
} from "twilio-video";

import { TwilioLogger } from "../../common/TwilioLogger";
import { LocalOrRemoteMediaTrack } from "../../types";
import { NetworkQualityStats } from "../../types/network-quality";
import { TwilioCredentials } from "../models";

export enum TwilioActionKeys {
  GET_CREDENTIALS = "GET_CREDENTIALS",
  GET_CREDENTIALS_REQUEST = "GET_CREDENTIALS_REQUEST",
  GET_CREDENTIALS_SUCCESS = "GET_CREDENTIALS_SUCCESS",
  GET_CREDENTIALS_FAILED = "GET_CREDENTIALS_FAILED",

  CONNECT_CALL = "CONNECT_CALL",
  CONNECT_CALL_REQUEST = "CONNECT_CALL_REQUEST",
  CONNECT_CALL_SUCCESS = "CONNECT_CALL_SUCCESS",
  CONNECT_CALL_FAILED = "CONNECT_CALL_FAILED",
  ATTACH_LOCAL_TRACKS = "ATTACH_LOCAL_TRACKS",
  LOCAL_MEDIA_NOT_FOUND = "LOCAL_MEDIA_NOT_FOUND",

  UPDATE_PARTICIPANTS = "UPDATE_PARTICIPANTS",
  UPDATE_DOMINANT_SPEAKER = "UPDATE_DOMINANT_SPEAKER",
  UPDATE_TRACKS = "UPDATE_TRACKS",
  UPDATE_DIMENSIONS = "UPDATE_DIMENSIONS",
  UPDATE_BANNER_NEEDS_UPDATE = "UPDATE_BANNER_NEEDS_UPDATE",
  UPDATE_DUPLICATE_PARTICIPANT_DISCONNECTED = "UPDATE_DUPLICATE_PARTICIPANT_DISCONNECTED",
  UPDATE_LOCAL_NETWORK_QUALITY_LEVEL = "UPDATE_LOCAL_NETWORK_QUALITY_LEVEL",
  UPDATE_LOCAL_NETWORK_DISCONNECTED = "UPDATE_LOCAL_NETWORK_DISCONNECTED",
  UPDATE_FAILED = "UPDATE_FAILED",

  DISCONNECT_CALL_REQUEST = "DISCONNECT_CALL_REQUEST",
  DISCONNECT_CALL_SUCCESS = "DISCONNECT_CALL_SUCCESS",
  DISCONNECT_CALL_FAILED = "DISCONNECT_CALL_FAILED",

  NETWORK_QUALITY_START_POLLING = "NETWORK_QUALITY_START_POLLING",
  NETWORK_QUALITY_STOP_POLLING = "NETWORK_QUALITY_STOP_POLLING",
  NETWORK_QUALITY_POST_METRICS = "NETWORK_QUALITY_POST_METRICS",
  NETWORK_QUALITY_POST_METRICS_REQUEST = "NETWORK_QUALITY_POST_METRICS_REQUEST",
  CALL_QUALITY_MERGE_SUM = "CALL_QUALITY_MERGE_SUM",

  // set remote will be true when anyone besides the logged in user joins, for p2p calls
  SET_REMOTE_HAS_JOINED_ROOM = "SET_REMOTE_HAS_JOINED_ROOM",

  // specifically when the console has joined for multi-party calls
  SET_CONSOLE_HAS_JOINED_ROOM = "SET_CONSOLE_HAS_JOINED_ROOM",

  // when the host has joined the room for both p2p and mp calls
  SET_HOST_HAS_JOINED_ROOM = "SET_HOST_HAS_JOINED_ROOM",
  SET_HOST_TRACKS = "SET_HOST_TRACKS",

  LOG_ACTIVITY = "twilio/LOG_ACTIVITY",

  TWILIO_RECONNECTION_TIMER = "TWILIO_RECONNECTION_TIMER",
  TWILIO_RECONNECTION = "TWILIO_RECONNECTION",

  CAMERA_ISSUE_ENCOUNTERED = "CAMERA_ISSUE_ENCOUNTERED",

  MEDIA_ERROR_ENCOUNTERED = "MEDIA_ERROR_ENCOUNTERED",

  CLEAR_TWILIO_TRACKS = "CLEAR_TWILIO_TRACKS",

  TWILIO_UPDATE_LOGGER = "TWILIO_UPDATE_LOGGER",
  
  REFRESH_FRAMES = "REFRESH_FRAMES"
}

interface GetTwilioCredentials {
  type: TwilioActionKeys.GET_CREDENTIALS;
}

interface TwilioCredentialsRequest {
  type: TwilioActionKeys.GET_CREDENTIALS_REQUEST;
}

interface TwilioCredentialsSuccess {
  type: TwilioActionKeys.GET_CREDENTIALS_SUCCESS;
  twilioCredentials: TwilioCredentials;
}

interface TwilioCredentialsFailed {
  type: TwilioActionKeys.GET_CREDENTIALS_FAILED;
  errorStatus?: string;
}

interface TwilioConnectCall {
  type: TwilioActionKeys.CONNECT_CALL;
}

interface ConnectCallRequest {
  type: TwilioActionKeys.CONNECT_CALL_REQUEST;
}

interface ConnectCallSuccess {
  type: TwilioActionKeys.CONNECT_CALL_SUCCESS;
  room: Room;
}

interface ConnectCallFailed {
  type: TwilioActionKeys.CONNECT_CALL_FAILED;
}

interface AttachLocalMedia {
  type: TwilioActionKeys.ATTACH_LOCAL_TRACKS;
  tracks: Array<LocalAudioTrack | LocalVideoTrack>;
}

interface LocalMediaNotFound {
  type: TwilioActionKeys.LOCAL_MEDIA_NOT_FOUND;
}

interface CameraIssueEncountered {
  type: TwilioActionKeys.CAMERA_ISSUE_ENCOUNTERED;
}

interface MediaErrorEncountered {
  type: TwilioActionKeys.MEDIA_ERROR_ENCOUNTERED;
  errorCode: string;
}

interface UpdateParticipants {
  type: TwilioActionKeys.UPDATE_PARTICIPANTS;
  participants: Participant[];
}

interface UpdateDominantSpeaker {
  type: TwilioActionKeys.UPDATE_DOMINANT_SPEAKER;
  uuid: string;
}

interface UpdateTracks {
  type: TwilioActionKeys.UPDATE_TRACKS;
  tracks: Array<RemoteAudioTrack | RemoteVideoTrack>;
}

interface UpdateDimensions {
  type: TwilioActionKeys.UPDATE_DIMENSIONS;
  needsUpdate: boolean;
}

interface UpdateBannerNeedsUpdate {
  type: TwilioActionKeys.UPDATE_BANNER_NEEDS_UPDATE;
  bannerNeedsUpdate: boolean;
}
interface UpdateDuplicateParticipantDisconnected {
  type: TwilioActionKeys.UPDATE_DUPLICATE_PARTICIPANT_DISCONNECTED;
  duplicateParticipantDisconnected: boolean;
}

interface UpdateLocalNetworkQualityLevel {
  type: TwilioActionKeys.UPDATE_LOCAL_NETWORK_QUALITY_LEVEL;
  localNetworkQualityLevel: NetworkQualityLevel;
}

interface UpdateLocalNetworkDisconnected {
  type: TwilioActionKeys.UPDATE_LOCAL_NETWORK_DISCONNECTED;
  localNetworkDisconnected: boolean;
}

interface UpdateFailed {
  type: TwilioActionKeys.UPDATE_FAILED;
}

interface DisconnectCallRequest {
  type: TwilioActionKeys.DISCONNECT_CALL_REQUEST;
}

interface DisconnectCallSuccess {
  type: TwilioActionKeys.DISCONNECT_CALL_SUCCESS;
}

interface DisconnectCallFailed {
  type: TwilioActionKeys.DISCONNECT_CALL_FAILED;
}

interface NQPostMetrics {
  type: TwilioActionKeys.NETWORK_QUALITY_POST_METRICS;
}

interface NQPostMetricsRequest {
  type: TwilioActionKeys.NETWORK_QUALITY_POST_METRICS_REQUEST;
}

interface NQStartPolling {
  type: TwilioActionKeys.NETWORK_QUALITY_START_POLLING;
}

interface NQStopPolling {
  type: TwilioActionKeys.NETWORK_QUALITY_STOP_POLLING;
}

interface CQMergeSum {
  type: TwilioActionKeys.CALL_QUALITY_MERGE_SUM;
  stats: NetworkQualityStats;
}

interface SetRemoteHasJoinedRoom {
  type: TwilioActionKeys.SET_REMOTE_HAS_JOINED_ROOM;
  remoteHasJoinedRoom: boolean;
}

interface SetConsoleHasJoinedRoom {
  type: TwilioActionKeys.SET_CONSOLE_HAS_JOINED_ROOM;
  consoleHasJoinedRoom: boolean;
}

interface SetHostHasJoinedRoom {
  type: TwilioActionKeys.SET_HOST_HAS_JOINED_ROOM;
  hostHasJoinedRoom: boolean;
}

interface SetHostTracks {
  type: TwilioActionKeys.SET_HOST_TRACKS;
  tracks: LocalOrRemoteMediaTrack[];
}

interface SetLogActivity {
  type: TwilioActionKeys.LOG_ACTIVITY;
  message: string;
}

interface TwilioReconnectionTimer {
  type: TwilioActionKeys.TWILIO_RECONNECTION_TIMER;
}

interface TwilioReconnection {
  type: TwilioActionKeys.TWILIO_RECONNECTION;
}

interface ClearTwilioTracks {
  type: TwilioActionKeys.CLEAR_TWILIO_TRACKS;
}
interface RefreshFrames {
  type: TwilioActionKeys.REFRESH_FRAMES;
}

interface UpdateTwilioLogger {
  type: TwilioActionKeys.TWILIO_UPDATE_LOGGER;
  logger: typeof TwilioLogger;
}

export type TwilioActionType =
  | GetTwilioCredentials
  | TwilioCredentialsRequest
  | TwilioCredentialsSuccess
  | TwilioCredentialsFailed
  | TwilioConnectCall
  | ConnectCallRequest
  | ConnectCallSuccess
  | ConnectCallFailed
  | AttachLocalMedia
  | LocalMediaNotFound
  | SetRemoteHasJoinedRoom
  | SetConsoleHasJoinedRoom
  | SetHostHasJoinedRoom
  | SetHostTracks
  | SetLogActivity
  | UpdateParticipants
  | UpdateDominantSpeaker
  | UpdateTracks
  | UpdateDimensions
  | UpdateBannerNeedsUpdate
  | UpdateDuplicateParticipantDisconnected
  | UpdateLocalNetworkQualityLevel
  | UpdateLocalNetworkDisconnected
  | UpdateFailed
  | DisconnectCallRequest
  | DisconnectCallSuccess
  | DisconnectCallFailed
  | NQPostMetrics
  | NQPostMetricsRequest
  | NQStartPolling
  | NQStopPolling
  | CQMergeSum
  | TwilioReconnectionTimer
  | TwilioReconnection
  | CameraIssueEncountered
  | ClearTwilioTracks
  | UpdateTwilioLogger
  | MediaErrorEncountered
  | RefreshFrames;
