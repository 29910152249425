import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import DropdownComponent from '../../../../../../../Common/DropdownComponent/DropdownComponent';

class DepartmentTableRow extends PureComponent {
	redirect = () => {
		this.props.history.push({
			pathname: '/hospitals/departments/profile',
			search: `hospitalId=${this.props.urlParams}&departmentId=${this.props.rowData.id}`,
		});
	};

	openDeleteUserModal = () => this.props.openModal(this.props.rowData);

	render() {
		const { name } = this.props.rowData;
		return (
			<Table.Row onClick={this.redirect}>
				<Table.Cell title={[name].join(' ')}>
					<div className="table__name-wrapper display--flex">
						<span>{name}</span>
					</div>
				</Table.Cell>
				<Table.Cell className="table__more-options" textAlign="right" />
				<Table.Cell className="table__action-table-cell">
					<div className="justify-content--flex-end">
						<DropdownComponent
							dropdownOptions={[{
								className: 'dropdown__menu-item--red',
								label: 'Delete',
								text: 'Delete',
								action: this.openDeleteUserModal,
								icon: 'icon-trash-2-outline',
							}]}
						/>
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}

DepartmentTableRow.defaultProps = {
	rowData: null,
};

DepartmentTableRow.propTypes = {
	rowData: PropTypes.object,
};

export default withRouter(DepartmentTableRow);
