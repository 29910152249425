import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  meetingInfo: null,
};

// TODO: should this have it's own slice? Maybe this can be added in a more general slice like `HomeSlice` or `AppSlice`?
const MeetingSlice = createSlice({
  name: "web_ui_meeting",
  initialState,
  reducers: {
    setMeetingInfo: (state, action) => {
      state.meetingInfo = action.payload;
    },
  },
});

export const { setMeetingInfo: setMeetingInfoAction } = MeetingSlice.actions;

export const MeetingReducers = MeetingSlice.reducer;
