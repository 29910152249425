import { Link as ReactRouterLink } from "react-router-dom";

import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";

import styles from "./styles.scss";

interface LinkProps extends MuiLinkProps {
  to: string;
  children: React.ReactNode;
}

// A basic link that expects to have a react router route in the "to" prop
export const Link = ({ to, children }: LinkProps) => {
  return (
    <MuiLink component={ReactRouterLink} to={to} underline="hover">
      {children}
    </MuiLink>
  );
};

interface ActionLinkProps extends MuiLinkProps {
  onClick: () => void;
  children: React.ReactNode;
  "data-test-id"?: string;
}
// An ActionLink is a link that you want something to happen when the user clicks on it (onClick prop)
export const ActionLink = ({
  onClick,
  "data-test-id": dataTestId,
  children,
}: ActionLinkProps) => {
  return (
    <MuiLink
      data-test-id={dataTestId}
      classes={{
        root: styles.actionLink,
      }}
      onClick={onClick}
      underline="hover"
    >
      {children}
    </MuiLink>
  );
};
