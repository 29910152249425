import {
  call,
  cancel,
  put,
  select,
  takeLatest,
  take
} from "redux-saga/effects";
import { API } from "../../../api";
import { logger } from "../../../common/logger";
import { AppState, MeetingStateType, PortalIdentity } from "../../models";
import {
  getTwilioCredentialsFailed,
  getTwilioCredentialsRequest,
  getTwilioCredentialsSuccess,
  logActivity
} from "../actions";
import { TwilioActionKeys } from "../actionTypes";
import { UserActionKeys } from "../../user/actionTypes";
import { endCall } from "../../user/actions";
import { MULTI_PARTY_LAUNCH_MODE } from "../../../constants";
import { setCallSid } from "../../meeting/actions";

function shouldCancelTwilioCredentials(
  identity: PortalIdentity,
  meeting: MeetingStateType
): boolean {
  let shouldCancel = false;
  const { mode, callEventInfo, callback, callbackInfo } = meeting;
  if (mode === MULTI_PARTY_LAUNCH_MODE) {
  } else if (
    identity == null ||
    identity.profile_id == null ||
    (!callback && callEventInfo == null) ||
    (callback && callbackInfo == null)
  ) {
    shouldCancel = true;
  }
  return shouldCancel;
}

export function* getTwilioCredentialsSaga() {
  // Retaining this code for backwards compatibility, if we ever run into issues.
  yield take(UserActionKeys.GET_IDENTITY_SUCCESS);

  yield put(getTwilioCredentialsRequest());

  const identity = yield select((state: AppState) => state.user.identity);
  const { meeting } = yield select((state: AppState) => state);

  const {
    callEventInfo,
    callback,
    callbackInfo,
    multiPartyCallEventInfo
  } = meeting;

  if (shouldCancelTwilioCredentials(identity, meeting)) {
    yield put(getTwilioCredentialsFailed());
    yield cancel();
  }

  if (callback) {
    logger().info("It's a callback.");
    const data = { token: callbackInfo.twilioToken };
    yield put(getTwilioCredentialsSuccess(data));
    return;
  }

  /* If it's not a callback */
  const { data, error, status } = yield call(
    API.GET.twilioCredentials,
    identity,
    callEventInfo,
    multiPartyCallEventInfo
  );

  if (error) {
    logger().error("Error getting credentials", JSON.stringify(error));
    yield put(
      getTwilioCredentialsFailed(
        JSON.stringify({ status: status, message: error })
      )
    );
    yield put(endCall());
    yield cancel();

    return;
  }

  logger().info("Twilio token obtained successfully.");
  yield put(logActivity("Twilio token obtained successfully."));
  yield put(getTwilioCredentialsSuccess(data));

  if (multiPartyCallEventInfo) {
    const callSessionId = data.callSid;
    const profileId = identity.login_id;
    logger().setCommonFields({
      callSessionId,
      profileId
    });

    yield put(setCallSid(callSessionId));
  }
}

export function* watchGetCredentials() {
  yield takeLatest(TwilioActionKeys.GET_CREDENTIALS, getTwilioCredentialsSaga);
}
