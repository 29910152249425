import React, { useState } from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form';
import { Button } from 'semantic-ui-react';
import FormField from '../FormField';

import '../FormFields.scss';

const FileInput = ({ onChange }) => {
	const [filename, setFilename] = useState('');

	const onFileChange = (e) => {
		setFilename(e.target.files[0].name);
		onChange(e); // forward
	};

	return (
		<>
			<Button as="label" htmlFor="file" type="button">
				Choose file
			</Button>
			<span className="form-field__file-input">{filename}</span>
			<input
				type="file"
				id="file"
				style={{ display: 'none' }}
				onChange={onFileChange}
			/>
		</>
	)
};

export default ({ className, ...props }) => (
	<Field
		component={FormField}
		as={FileInput}
		isFileInput
		{...props}
		className={classNames({
			[className]: className,
		})}
	/>
);
