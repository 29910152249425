import { logger } from "./common/logger";
import { VALID_CAPTURERS } from "./constants";
import { AvailUUIDType } from "./store/models";

/**
 * Have the Webcall Electron app exit gracefully.
 */
export function gracefulExit() {
  logger().info("EXITING...");
}

/**
 *
 * @param name label for video input (via Chrome navigator)
 */
export const isValidCapturer = (name: string) => {
  // ["AV.io", "UVCHDCAP"]
  return VALID_CAPTURERS.some((label) =>
    name.toUpperCase().includes(label.toUpperCase())
  );
};

/**
 * Sum out the properties of two object - assuming both objs
 * have the same structure (works on deeply nested objects too).
 * @param {any} obj1
 * @param {any} obj2
 * @returns new object with averaged out data
 */
export function deepMergeSum(obj1: any, obj2: any) {
  return Object.keys(obj1).reduce((acc: any, key) => {
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (value1 != null && value2 != null && typeof value2 === "object") {
      acc[key] = deepMergeSum(value1, value2);
    } else if (obj2.hasOwnProperty(key)) {
      if (value1 == null && value2 == null) {
        // both are null
        acc[key] = null;
      } else if (value1 == null) {
        // only value1 is null
        acc[key] = value2;
      } else if (value2 == null) {
        // only value2 is null
        acc[key] = value1;
      } else {
        // neither is null, try adding
        try {
          acc[key] = value1 + value2; // Add values
        } catch {
          acc[key] = value2;
        }
      }
    }

    return acc;
  }, {});
}

/**
 * Average out propery values on an object based on a variable `avgComparator`.
 * @param {any} obj1
 * @param {number} avgComparator number to divide by
 */
export function averageObjectProps(obj1: any, avgComparator: number) {
  return Object.keys(obj1).reduce((acc: any, key) => {
    const value1 = obj1[key];

    if (value1 != null && typeof value1 === "object") {
      acc[key] = averageObjectProps(value1, avgComparator);
    } else {
      try {
        const average = value1 / avgComparator;
        acc[key] = Number(average.toFixed(2));
      } catch {
        acc[key] = null;
      }
    }

    return acc;
  }, {});
}

export function parseAvailUUID(availUuid: string): AvailUUIDType {
  const fields = availUuid.split("-");
  const getField = (num: number): string =>
    num < fields.length ? fields[num] : "";

  const theUuid: AvailUUIDType = {
    clientType: getField(0),
    clientId: getField(1),
    deviceType: getField(2),
    deviceInstance: getField(3),
  };
  return theUuid;
}
