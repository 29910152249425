import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";

import { Button } from "src/components/Button";
import { Font } from "src/components/Font";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "src/components/Modal";
import { useAppSelector } from "src/domains/Beacon/store";
import { uiActions } from "src/domains/Beacon/store/ui";
import { selectOpenMinimumBrowserSizeModal } from "src/domains/Beacon/store/ui/selectors";
import { useEventListener } from "src/hooks/useEventListener";
import { logger } from "src/logging/logger";

import styles from "./styles.scss";

const MIN_WIDTH = 1000;
const MIN_HEIGHT = 500;

export const MinimumBrowserSizeModal = () => {
  const shouldOpenModal = useAppSelector(selectOpenMinimumBrowserSizeModal);
  const openModalRef = useRef<boolean>(shouldOpenModal);
  const dispatch = useDispatch();

  // Listener must know the latests state of the open modal
  openModalRef.current = useMemo(() => shouldOpenModal, [shouldOpenModal]);

  const handleCloseModal = () =>
    dispatch(uiActions.setOpenMinimumBrowserSizeModal(false));

  const handleWindowResize = () => {
    // innerWidth is way more accurate than outerWidth, the content will shrink
    // based on the innerWidth
    const isMinimumBrowserSizeModalOpen = openModalRef.current;

    // both width and height have to be bigger than the min for the boolean to be false
    // either have to be smaller than the min for it to be true

    // if the boolean is true then we show the size warning modal
    if (window.innerWidth > MIN_WIDTH && window.innerHeight > MIN_HEIGHT) {
      if (isMinimumBrowserSizeModalOpen) {
        dispatch(uiActions.setOpenMinimumBrowserSizeModal(false));
        logger().info(
          "User's browser width and height is greater than the minimum, hiding the size warning modal "
        );
      }
    } else {
      if (!isMinimumBrowserSizeModalOpen) {
        dispatch(uiActions.setOpenMinimumBrowserSizeModal(true));
        logger().info(
          "User's browser width or height is too small, showing the size warning modal"
        );
      }
    }
  };

  // When page refreshes, must need to detect if window is under the proper size
  useEffect(() => {
    handleWindowResize();
  }, []);

  useEventListener("resize", handleWindowResize);

  return (
    <Modal
      open={shouldOpenModal}
      data-test-id="minimum-size-browser-modal"
      unmountOnExit
      showVeil
    >
      <ModalHeader closeModal={handleCloseModal} title="" />
      <ModalBody className={styles.modalBody}>
        <Font variant="h1" color="light" textAlign="center">
          We recommend maximizing your browser window for the best viewing
          experience
        </Font>
      </ModalBody>
      <ModalFooter>
        <Button
          data-test-id="minimum-size-browser-modal-button"
          onClick={handleCloseModal}
          label="Got it"
        />
      </ModalFooter>
    </Modal>
  );
};
