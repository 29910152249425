import {
  MediaDevicesInfo,
  LocalMediaDeviceEnumeration,
} from "src/domains/Beacon/store/stream/types";
import {
  getAudioInputDevices,
  getAudioOutputDevices,
  getCameraDevices,
  getDefaultAudioInputDevice,
  getDefaultOutputDevice,
  stopMediaTracks,
} from "src/domains/Beacon/utils/mediaDevices";
import { LoggerLevels, logger } from "src/logging/logger";

const enumerateUserMedia = async (): Promise<MediaDevicesInfo> => {
  // Asking for video & audio permissions to the user
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: true,
  });
  if (stream) {
    // This is part of the browser APIs and this grabs all the audio/video
    // device information from the current user's computer into an array
    const devices = await navigator.mediaDevices.enumerateDevices();

    // cleans the stream after enumerating the devices, removing the Browser's red indicator
    stopMediaTracks(stream);
    return {
      audioInputs: getAudioInputDevices(devices),
      audioOutputs: getAudioOutputDevices(devices),
      videoInputs: getCameraDevices(devices),
    };
  }
};

// Gets all the user's local media when loading Beacon the first time
export const getLocalMediaDevicesInfo = async (): Promise<
  LocalMediaDeviceEnumeration
> => {
  try {
    const deviceEnumeration = await enumerateUserMedia();

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Media Devices",
        fileInfo: "stream/client.ts",
      },
      // Must add "null" to not fire an error when deviceEnumeration is "undefined"
      `User media devices are: ${JSON.stringify(deviceEnumeration ?? "null")}`
    );

    return {
      camera: null,
      microphone: null,
      speaker: null,
      mediaDevices: deviceEnumeration,
    };
  } catch {
    // This catch happens when the user denies video & audio permissions
    throw new Error("Error getting media devices permissions.");
  }
};

// Updates all the user's media devices list
export const updateLocalMediaDevicesInfo = async (
  localMedia: LocalMediaDeviceEnumeration
): Promise<LocalMediaDeviceEnumeration> => {
  logger().info("Detected a change in device configuration");

  const deviceEnumeration = await enumerateUserMedia();

  logger().logWithFields(
    LoggerLevels.info,
    {
      feature: "Media Devices",
      fileInfo: "stream/client.ts",
    },
    `Change in media devices detected: ${JSON.stringify(
      deviceEnumeration ?? "null"
    )}`
  );

  // checks if selected devices exists on the enumerated list, otherwise, set
  // the first option of each of them
  const camera = deviceEnumeration.videoInputs.find(
    (v) => v.deviceId === localMedia.camera?.deviceId
  );

  // Microphone selected by the user outside the app (System preferences) and that is marked as "default"
  const defaultMicrophone = getDefaultAudioInputDevice(
    deviceEnumeration.audioInputs
  );

  // Speaker selected by the user outside the app (System preferences) and that is marked as "default"
  const defaultSpeaker = getDefaultOutputDevice(deviceEnumeration.audioOutputs);

  // Must look if the current microphone selected still exists, otherwise, we use the "default" one coming from System
  const microphone =
    defaultMicrophone ??
    deviceEnumeration.audioInputs.find(
      (a) => a.deviceId === localMedia.microphone?.deviceId
    );

  // Must look if the current speaker selected still exists, otherwise, we use the "default" one coming from System
  const speaker =
    defaultSpeaker ??
    localMedia?.mediaDevices.audioOutputs.find(
      (a) => a.deviceId === localMedia.speaker?.deviceId
    );

  return {
    camera,
    microphone,
    speaker,
    mediaDevices: deviceEnumeration,
  };
};
