import React from 'react';
import { reduxForm } from 'redux-form';
import { Button } from '../../../../../../../Common/Components';
import TherapiesAndProcedures from './TherapiesAndProcedures';
import './CreateNewVendor.scss';

const CreateNewVendorTherapies = (props) => {
	const { handleSubmit, previousPage } = props;

	return (

		<form onSubmit={handleSubmit}>
			<div>
				<div>
					<TherapiesAndProcedures createNewInitialValues={null} />
				</div>
			</div>
			<div className="button_wrapper">
				<Button type="button" className="previous btn btn-primary btn-sm" onClick={previousPage}>Previous</Button>
				<Button
					type="submit"
					isFull
					buttonSmall
					className="next"
				>
					Next
				</Button>
			</div>
		</form>
	);
};

export default reduxForm({
	form: 'CreateNewVendor',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(CreateNewVendorTherapies);

