import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { createAvailKitInstanceThunk } from "src/domains/Beacon/store/twilio/thunks/createAvailKitInstanceThunk";
import { joinPubnubChannelsThunk } from "src/domains/Beacon/store/twilio/thunks/joinPubnubChannels";
import { logger } from "src/logging/logger";

export const useJoinPubnubChannel = () => {
  const { callSid } = useAppSelector(selectMeetingState);
  const dispatch = useDispatch();

  // More temporary code: initialize availkit. Since Beacon is currently
  // running isolated, there is no availKit instance available
  // Once we merge Beacon into all the Portal flow, availKit will be available
  // at this point because it initialized within portal, therefore we wouldn't
  // have to do this
  useEffect(() => {
    try {
      const joinPubnubChannel = async () => {
        // Creating AvailKit instance
        await dispatch(createAvailKitInstanceThunk());
        // Joining PubNub channels based on previous instance created
        await dispatch(joinPubnubChannelsThunk());
      };

      if (callSid) {
        joinPubnubChannel();
      }
    } catch (error: any) {
      logger().error("Error while starting PubNub", error?.message);
    }
  }, [callSid]);
};
