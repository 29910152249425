import { NConfig, Nurep } from "availkit-js";
import { NIncomingServerCallEvent } from "availkit-js/dist/Models/Events/NIncomingServerCallEvent";
import { Guid } from "guid-typescript";

import React from "react";
import { connect } from "react-redux";

import { Dispatch } from "redux";

import "./App.scss";
import { logger } from "./commoncall/common/logger";
import VideoCall from "./commoncall/components/VideoCall";
import {
  AVAIL_DEVICE_INSTANCE_ID_KEY,
  MULTI_PARTY_LAUNCH_MODE,
  P2P_LAUNCH_MODE,
} from "./commoncall/constants";
import { initializeAvailKit } from "./commoncall/store/availkit/actions";
import {
  initializeMultiPartyEvent,
  setCallback,
  setCallbackInfo,
  setCallInfo,
  setCallMode,
  setCallSid,
  setCallState,
  setJoinId,
  setMultiPartyEventCallInfo,
} from "./commoncall/store/meeting/actions";
import {
  AppState,
  CallBackEventStateType,
  CallModeType,
  CallState,
  CallStateType,
  FeaturesToSync,
  MeetingStateType,
  MultiPartyCallEventInfo,
  MultiPartyInitializeState,
  PortalIdentity,
} from "./commoncall/store/models";
import {
  connectCall,
  getTwilioCredentials,
  updateLocalNetworkDisconnected,
} from "./commoncall/store/twilio/actions";
import {
  endCall,
  getIdentity,
  getLocalMedia,
} from "./commoncall/store/user/actions";
import { gracefulExit } from "./commoncall/utils";

interface StateProps {
  identity: PortalIdentity;
  callEnded: boolean;
  meeting: MeetingStateType;
  availKit: Nurep | null;
  callMode: CallModeType;
}

interface DispatchProps {
  setCallSid: (callSid: string) => void;
  setCallMode: (mode: CallModeType) => void;
  setJoinId: (joinId: string) => void;
  getIdentity: () => void;
  getTwilioCredentials: () => void;
  getLocalMedia: () => void;
  connectCall: () => void;
  endCall: () => void;
  setCallState: (callState: CallStateType) => void;
  initializeAvailKit: (config: NConfig, presenceUUID: string) => void;
  setCallInfo: (callInfo: NIncomingServerCallEvent) => void;
  setCallback: (callback: boolean) => void;
  setCallbackInfo: (callbackInfo: CallBackEventStateType) => void;
  setMultiPartyEventCallInfo: (
    multiPartyCallEventInfo: MultiPartyCallEventInfo
  ) => void;
  initializeMultiPartyEvent: (eventInfo: MultiPartyInitializeState) => void;
  updateLocalNetworkDisconnected: (localNetworkDisconnected: boolean) => void;
}

type Props = StateProps & DispatchProps;

class App extends React.Component<Props> {
  encodedMeetingToken = this.extractParam(
    "meeting_token",
    window.location.href
  );

  state = {
    joinedChannel: false,
    availKitInitialized: false,
    loggerFieldsConfigured: false,
    loggerConfigured: false,
  };

  extractParam(key: string, url: string) {
    if (typeof url === "undefined") url = window.location.href;
    const match = url.match("[?&]" + key + "=([^&]+)");
    return match ? match[1] : "";
  }

  constructor(props: Props) {
    super(props);
    this.pubnubStatusCallback = this.pubnubStatusCallback.bind(this);
  }

  componentDidMount() {
    logger().info("Portalcall Launched");
    if (this.encodedMeetingToken !== "") {
      try {
        const meetingToken = JSON.parse(atob(this.encodedMeetingToken));
        if (meetingToken) {
          if (meetingToken.mode) {
            if (meetingToken.mode === MULTI_PARTY_LAUNCH_MODE) {
              this.props.setCallMode(meetingToken.mode);
              const multiPartyMeetingToken = (meetingToken as unknown) as MultiPartyCallEventInfo;
              if (
                multiPartyMeetingToken.eventId &&
                multiPartyMeetingToken.joinId
              ) {
                logger().info(
                  "Received a request to launch a MultiParty Event : " +
                    multiPartyMeetingToken.eventId
                );
                if (!navigator.onLine) {
                  this.props.updateLocalNetworkDisconnected(true);
                  return;
                }
                const initialState: MultiPartyInitializeState = {
                  meetingToken: multiPartyMeetingToken,
                  featuresToSync: FeaturesToSync.all,
                };
                this.props.initializeMultiPartyEvent(initialState);
                this.props.setMultiPartyEventCallInfo(multiPartyMeetingToken);
                this.props.setJoinId(multiPartyMeetingToken.joinId);
                this.dispatchActions();
                logger().setCommonFields({
                  eventId: meetingToken.eventId ?? "",
                  role: meetingToken.userRole?.toUpperCase(),
                });
              } else {
                logger().error(
                  "Received a request to launch a MultiParty Event. No EventId or JoinId."
                );
              }
            }
          } else {
            /* Non-Callback case */
            if (meetingToken.session_id) {
              logger().info("Received a request to launch a call");
              this.props.setCallInfo(meetingToken);
              this.props.setCallSid(meetingToken.session_id);

              this.props.setJoinId(meetingToken.join_id);
              logger().setCommonFields({
                callSessionId: meetingToken.session_id,
                profileId: meetingToken.profile_id,
                eventId: "",
                role: "",
              });
              this.setState({ loggerFieldsConfigured: true });
              this.dispatchActions();
            } else if (meetingToken.callback === true) {
              logger().info(
                "Received a request to launch a call from callback"
              );
              const acceptEvent = meetingToken.event;

              const session_id = acceptEvent._session_id;
              const user_profile_id = acceptEvent._user_profileId;
              const user_joinId = acceptEvent._user_joinId;

              this.props.setCallback(true);
              this.props.setJoinId(user_joinId);
              this.props.setCallSid(session_id);

              logger().setCommonFields({
                callSessionId: session_id,
                profileId: user_profile_id,
              });

              const callBackEventStateType: CallBackEventStateType = {
                twilioToken: meetingToken.twilioToken,
                event: acceptEvent,
              };

              this.props.setCallbackInfo(callBackEventStateType);
              logger().setCommonFields({
                callSessionId: session_id,
                profileId: user_profile_id,
                eventId: "",
                role: "",
              });
              this.setState({ loggerFieldsConfigured: true });
              this.dispatchActions();
            }
          }
        } else {
          this.props.getIdentity();
          // where to set call mode to p2p?
        }
      } catch (e) {
        logger().error("Error while extracting meeting token");
      }
    }
  }

  formatUUIDFields(field: string) {
    return field.replaceAll("-", "_");
  }

  getPresenceUUID() {
    let availDeviceInstance = localStorage.getItem(
      AVAIL_DEVICE_INSTANCE_ID_KEY
    );

    if (!availDeviceInstance) {
      availDeviceInstance = Guid.create().toString();
      logger().info("Setting device-instance for presence");
      localStorage.setItem(AVAIL_DEVICE_INSTANCE_ID_KEY, availDeviceInstance);
    }

    const presenceUUID =
      "USER-" +
      this.formatUUIDFields(this.props.identity.login_id) +
      "-PVID-" +
      this.formatUUIDFields(availDeviceInstance.replaceAll('"', ""));
    return presenceUUID;
  }

  rejoinPubnubChannels() {
    const { callSid } = this.props.meeting;
    try {
      // Make an attempt to re-join the pubnubchannel
      const { pubnub_channel } = this.props.identity;
      if (pubnub_channel) {
        const availKit = this.props.availKit;
        // Join user channel
        logger().info(
          "Making an attempt to rejoin the session channel " + pubnub_channel
        );
        availKit.eventService.join(pubnub_channel);
        availKit.eventService.join(callSid);
      }
    } catch (e) {
      logger().error("Error while re-joining");
    }
  }

  pubnubStatusCallback(e) {
    try {
      const theMsg = JSON.stringify(e);
      const { callMode } = this.props;

      switch (e?.category) {
        case "PNConnectedCategory":
          logger().info(`PubNub: connection established: ${theMsg}`);
          break;
        case "PNNetworkIssuesCategory":
          logger().info(`PubNub: had issue on network: ${theMsg}`);
          break;
        case "PNNetworkDownCategory":
          logger().info(`PubNub: network connection is DOWN: ${theMsg}`);
          break;
        case "PNTimeoutCategory":
          logger().error(`PubNub: timed out connecting... ${theMsg}`);
          break;
        case "PNNetworkUpCategory":
          logger().info(`PubNub: network is back up`);
          if (callMode === P2P_LAUNCH_MODE) {
            this.rejoinPubnubChannels();
          }
          break;
        case "PNMalformedResponseCategory":
          logger().warn(`PubNub: failed to parse JSON: ${theMsg}`);
          break;
        case "PNReconnectedCategory":
          logger().warn(`PubNub: reconnected to network`);
          break;
        case "PNUnknownCategory":
          logger().warn(`PubNub: encountered unknown error: ${theMsg}`);
          break;
        default:
          logger().warn(
            `PubNub: reported an unrecognized status [${e.category}]: ${theMsg}`
          );
          break;
      }
    } catch (e) {
      logger().error(e);
    }
  }

  componentDidUpdate() {
    if (this.props.identity && this.props.identity.pubnub_channel) {
      const {
        finishedAccountSetup,
        pubnub_auth_key,
        pubnub_publ_key,
        pubnub_subs_key,
        pubnub_channel,
      } = this.props.identity;
      const { availKit } = this.props;

      if (
        pubnub_channel &&
        finishedAccountSetup &&
        !this.state.availKitInitialized
      ) {
        /*TODO, presence needs to be implemented */
        // Setup PubNub keys
        const config = new NConfig();
        config.pubnubPublishKey = pubnub_publ_key;
        config.pubnubSubscribeKey = pubnub_subs_key;
        config.pubnubAuthKey = pubnub_auth_key;

        this.props.initializeAvailKit(config, this.getPresenceUUID());
        this.setState({ availKitInitialized: true });
        return;
      }
      if (availKit && !this.state.joinedChannel) {
        this.setState({ joinedChannel: true });
        availKit.eventService.join(pubnub_channel);
        availKit.eventService.setStatusCallback(this.pubnubStatusCallback);
      }
      if (
        availKit &&
        this.state.loggerFieldsConfigured &&
        !this.state.loggerConfigured
      ) {
        availKit.configureLogger(logger);
        this.setState({ loggerConfigured: true });
      }
    }
  }

  componentWillUnmount() {
    const { availKit } = this.props;
    availKit && availKit.eventService.leaveAll();
  }

  dispatchActions() {
    this.props.getIdentity();
    this.props.getTwilioCredentials();
    this.props.setCallState(CallState.INPROGRESS);
    this.props.connectCall();
  }

  render() {
    const meetingToken =
      this.encodedMeetingToken && JSON.parse(atob(this.encodedMeetingToken));
    if (this.props.callEnded) {
      logger().info("Gracefully exiting!");
      gracefulExit();
    }

    return (
      <>
        <VideoCall />
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  identity: state.user.identity,
  callEnded: state.user.callEnded,
  meeting: state.meeting,
  availKit: state.availKit.availKitInstance,
  callMode: state.meeting.mode,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setCallSid: (callSid: string) => dispatch(setCallSid(callSid)),
    setCallMode: (mode: CallModeType) => dispatch(setCallMode(mode)),
    getIdentity: () => dispatch(getIdentity()),
    getTwilioCredentials: () => dispatch(getTwilioCredentials()),
    getLocalMedia: () => dispatch(getLocalMedia()),
    connectCall: () => dispatch(connectCall()),
    endCall: () => dispatch(endCall()),
    setCallState: (callState: CallStateType) =>
      dispatch(setCallState(callState)),
    initializeAvailKit: (config: NConfig, presenceUUID: string) =>
      dispatch(initializeAvailKit(config, presenceUUID)),
    setCallInfo: (event: NIncomingServerCallEvent) =>
      dispatch(setCallInfo(event)),
    setMultiPartyEventCallInfo: (multiPartyEvent: MultiPartyCallEventInfo) =>
      dispatch(setMultiPartyEventCallInfo(multiPartyEvent)),
    initializeMultiPartyEvent: (meetingToken: MultiPartyInitializeState) =>
      dispatch(initializeMultiPartyEvent(meetingToken)),
    setCallback: (callback: boolean) => dispatch(setCallback(callback)),
    setCallbackInfo: (event: CallBackEventStateType) =>
      dispatch(setCallbackInfo(event)),
    setJoinId: (joinId: string) => dispatch(setJoinId(joinId)),
    updateLocalNetworkDisconnected: (localNetworkDisconnected: boolean) =>
      dispatch(updateLocalNetworkDisconnected(localNetworkDisconnected)),
  };
};

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(App);
