export default {
	applicationForSecondaryManager: 'users/{0}/user-applications',
	applicationsForHospitals: 'hospitals/{0}/user-applications',
	applyToHospital: 'users/{0}/apply-to-hospitals',
	applyToHospitalForTrial: 'users/{0}/auto-approve-hospitals',
	assignTherapiesToDepartment: 'departments/{0}/therapies',
	assignUserToOrganisation: 'users/{0}/organizations',
	availableHospitalsToApply: 'users/{0}/available-hospitals',
	approvedHospitalsForUser: 'users/{0}/approved-hospitals',
	billing: 'vendors/{0}',
	billingSubscription: 'vendors/{0}/subscription',
	brand: 'brands/{0}',
	brands: 'brands',
	brandLogo: 'brands/logo',
	brandUsers: 'brands/{0}/users',
	billingInvoices: 'vendors/{0}/invoices',
	billingUsageInfo: 'vendors/{0}/usage-info',
	BuAdminsAndManagers: 'vendors/{0}/admins-managers',
	businessUnitsPerBrand: 'brands/{0}/units',
	callback: 'users/join/{0}',
	cdrExport: 'history/export',
	checkIsTokenValid: 'users/refresh',
	console: 'consoles/{0}',
	consoles: 'consoles',
	createEvent: 'events',
	deleteEvent: 'schedules/{0}',
	deleteRecurringEvent: 'schedules/recurring/{0}',
	deleteDepartment: 'departments/{0}',
	deleteDistrict: 'vendors/{0}',
	deleteRoom: 'rooms/{0}',
	department: 'departments/{0}',
	departmentRooms: 'departments/{0}/rooms',
	departmentProcedures: 'departments/{0}/procedures',
	departmentTherapies: 'departments/{0}/therapies',
	distributors: 'distributors',
	districtsPerBusinessUnit: 'vendors/{0}/districts',
	domainBrand: 'brands/domain/{0}',
	employUserInOrganisation: 'organizations/{0}/user',
	eula: 'eula',
	history: 'history',
	hospital: 'hospitals/{0}',
	hospitalAdmins: 'hospitals/{0}/admins',
	hospitalApplications: 'users/{0}/hospital-applications',
	hospitalConsoles: 'hospitals/{0}/consoles',
	hospitalDepartments: 'hospitals/{0}/departments',
	hospitalNetwork: 'hospital-networks',
	hospitalNetworkDetails: 'hospital-networks/hospitalNetworkDetails',
	hospitalNetworkFacilities: 'hospital-networks/{0}/hospitals',
	hospitalRooms: 'hospitals/{0}/rooms',
	hospitals: 'hospitals',
	login: 'login',
	managersPerBusinessUnit: 'vendors/{0}/managers',
	managerUserAppliedTo: 'users/{0}/manager-applications',
	myManager: 'users/{0}/manager',
	myManagers: 'hierarchy/{0}/descendants/managers',
	myTeam: 'hierarchy/{0}/descendants',
	onBoardingIndividualSalesRep: 'isr/{0}',
	oneHospitalNetwork: 'hospital-networks/{0}',
	organisation: 'organizations/{0}',
	organisations: 'organizations',
	provider: 'providers/{0}',
	providers: 'providers',
	providerLogo: 'providers/{0}/logo',
	refreshToken: 'token/refresh',
	refreshTokenForProfile: 'token/refresh/profile/{0}',
	room: 'rooms/{0}',
	roomDepartments: 'rooms/{0}/departments',
	roomProcedures: 'rooms/{0}/procedures',
	roomTherapies: 'rooms/{0}/therapies',
	roomTypes: 'rooms/room-types',
	therapies: 'therapies',
	timezones: 'settings/timezones',
	user: 'users/{0}',
	userEmployer: 'users/{0}/employed',
	userFinishAccountSetup: 'users/{0}/finish-setup',
	userGroups: 'users/usergroups',
	userOrganisation: 'users/{0}/employed',
	userProcedures: 'users/{0}/procedures',
	users: 'users',
	userSchedule: 'schedules/users/{0}',
	userEvents: 'events/users/{0}',
	event: 'events/{0}',
	userSettings: 'settings/{0}',
	userSupportsOrganisation: 'users/{0}/supports',
	userTherapies: 'users/{0}/available-therapies',
	vendor: 'vendors/{0}',
	vendorProcedures: 'vendors/{0}/procedures',
	vendors: 'vendors',
	vendorsHospitals: 'vendors/{0}/hospitals',
	vendorsPerBrand: 'brands/{0}/vendors',
	vendorsSupportedAndNewHospitals: 'vendors/{0}/hospital-info',
	vendorTherapies: 'vendors/{0}/therapies',
	vendorUsers: 'vendors/{0}/users',
	mfaGetPhoneNumber: 'logins/{0}/settings/mfa-preferences',
	mfaRemovePhoneNumber: 'logins/{0}/settings/mfa-phone-remove',
	mfaPhoneVerifyOTP: 'logins/{0}/settings/mfa-phone-otp',
	sendVerifyMfaPhoneOtp: 'logins/{0}/settings/mfa-phone-otp?phone={1}&country_code={2}&channel_type={3}',
	resendVerifyMfaPhoneOtp: 'logins/{0}/settings/mfa-phone-resend-otp?mfa_phone_token={1}&channel_type={2}',
	profileUsers: 'providers/{0}/specialists',
	userPassword: 'accounts/{0}/changePassword',
	resendEmail: 'users/{0}/resendActivationEmail',
	meetingTime: 'history/users/{0}',
	forgotPassword: 'accounts/resetPassword/email/{0}',
	resetPassword: 'accounts/changePassword/email/{0}',
	lockUser: 'accounts/email/{0}/lockUser',
	unlockUser: 'accounts/email/{0}/unlockUser',
	assignUserManager: 'users/{0}/assignManager/{1}',
	removeUserManager: 'users/{0}/removeManager/{1}',
  guestUserRegister: 'accounts/register/{0}',
	mfaAuthentication: 'verify-mfa-otp',
	resendOTP: 'resend-mfa-otp',
	getPassphrase: 'users/{0}/passphrase_retrieve',
	getOrgSettings: 'organizations/{0}/settings',
	orgMfaFrequency: 'organizations/{0}/settings/mfa-frequency',
	sendMfaLoginOtp: "send-mfa-otp",
	verifyMfaLoginOtp: "verify-mfa-otp?mfa_token={0}&otp={1}",
	resendMfaLoginOtp: "resend-mfa-otp?mfa_token={0}",

  tabs: {
    general: 'general',
    therapies: 'therapies',
    facilities: 'facilities',
    preferences: 'preferences',
    security: 'security'
  }
};
