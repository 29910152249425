export const DRAWER_WIDTH = 320;
export const CAMERA_PIP_WIDTH = "239px";
export const CAMERA_PIP_HEIGHT = "153px";
export const ORTelligence = "ORtelligence";
export interface IPosition {
  x: number;
  y: number;
  browserY?: number;
}
export const DEFAULT_MARGINS: IPosition = {
  x: 30,
  y: 80,
  browserY: 30, // y margin for the full screen browser
};

export const DEFAULT_PIP_POSITION: IPosition = {
  x: 0,
  y: 0,
};

// TODO: will be replaced with new data structure
// state for Webcall to display correctly
export const DEFAULT_LAYOUT_POSITION = {
  leftTop: 0,
  rightTop: 1,
  leftBottom: 2,
  rightBottom: 3,
};

// TWO_VIEW does not display the bottom values
// focus on modifying the top values to display LEFT | RIGHT
export const DEFAULT_TWOVIEW_LABEL = {
  leftTop: "left",
  leftBottom: "leftBottom",
  rightTop: "right",
  rightBottom: "rightBottom",
};

export const BROWSER_AGENTS = {
  EdgeBlink: "MSIE Blink",
  Firefox: "Firefox",
  Safari: "Safari",
  Chrome: "Chrome",
  OperaBlink: "OperaBlink",
  OperaPresto: "OperaPresto",
};

export const HOST_PIP_VIDEO_LOG_MESSAGE =
  "Host PiP video dimensions did change: ";
export const CONSOLE_VIDEO_LOG_MESSAGE =
  "Console video dimensions did change: ";

export const AVAIL_DEVICE_INSTANCE_ID_KEY = "avail-device-instance-id";

export const MULTI_PARTY_LAUNCH_MODE = "MP";
export const P2P_LAUNCH_MODE = "P2P";

// Tungsten colors
export const PEN_COLOR_TO_HEX = {
  red: "#ff005c", // $pen-red
  blue: "#0094ff", // $pen-blue
  green: "#00ff53", // $pen-green
  yellow: "#fff500", // $pen-yellow
  black: "#181819", // $pen-black
  white: "#ffffff", // $pen-white - requires 6 characters for NAnnotation.setRGBA
};
export const HEX_TO_PEN_COLOR = {
  [PEN_COLOR_TO_HEX.red]: "red",
  [PEN_COLOR_TO_HEX.blue]: "blue",
  [PEN_COLOR_TO_HEX.green]: "green",
  [PEN_COLOR_TO_HEX.yellow]: "yellow",
  [PEN_COLOR_TO_HEX.black]: "black",
  [PEN_COLOR_TO_HEX.white]: "white",
};
// Telestration
export const MAX_ANNOTATION_POINTS: number =
  parseInt(`${process.env.REACT_APP_MAX_ANNOTATION_POINTS}`, 10) || 20;

export const AV_FREEZE_FRAME_IMAGE_QUALITY: number =
  parseFloat(`${process.env.REACT_APP_AV_FREEZE_FRAME_IMAGE_QUALITY}`) || 0.3;

export const AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB: number =
  parseInt(`${process.env.REACT_APP_AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB}`, 10) ||
  12;
export const INITIAL_PEN_WIDTH = 4;

export const INITIAL_PEN_COLOR = PEN_COLOR_TO_HEX.green;

export const FREEZE_FRAME_CANVAS_ID = "FREEZE_FRAME_CVS";

export const FREEZE_FRAME_IMAGE_ID = "FREEZE_FRAME_IMG";

export const CONSOLE_VIDEO_ELEMENT_ID = "console-video";

export const PIP_AUDIO_ELEMENT_ID = "pip-audio";
export const PARTICIPANT_AUDIO_ELEMENT_ID = "participant-audio";
// Time to allow for Video element resize side effects to resolve before redrawing.
export const TELESTRATION_VIDEO_DIMENSIONS_ADJUST_BUFFER = 250;

// SidePanel
// Transition listener must match the sidePanel id of the element, in order to ignore
// child elements in the listener
export const SIDE_PANEL_ID = "sidebar-panel";

// Sidebar Popover
export const ACTIVATE_SIDEBAR_TITLE = "Sidechat";
export const DEACTIVATE_SIDEBAR_TITLE =
  "Are you sure you want to turn off Sidechat?";
export const ACTIVATE_SIDEBAR_MESSAGE =
  "Start a conversation with remote participants without interrupting the operating room.";
export const DEACTIVATE_SIDEBAR_MESSAGE =
  "The procedure room will be able to hear you.";

export const ORT_INTEGRATION_HIP_URL =
  process.env.REACT_APP_ORT_INTEGRATION_HIP_URL ||
  "https://dev.ortelligence.com/usr/OR/begin-surgery;message=UDO0MppPor21Lds6zV75hEjd7wUXPor21Ld0z8deDIlrcpZbtdnX0NYMl32";

export const AVAIL_NETWORK_TESTING_URL = "https://networktest.avail.io/";

// Easter Egg Keys - keys used in local storage to enable features not otherwise
// part of the Product release

/**
 * Renders "Same as System" as an option within Audio device selection.
 * New option represents "Match the system default as it changes", follows
 *  a convention used in Zoom browser calls and Google Meet where they allow
 *  use of the system default when allowed by the browser.
 * Code in mediaDevices.ts currently addresses Chrome when using Easter Egg.
 * If we release this as a feature, then we'll need to integrate logic from
 *  findDefaultDeviceOriginal with if(isSameAsSystemEnabled)
 * */
export const AV_SELECTION_SAME_AS_SYSTEM = "av-selection-same-as-system";
