import classNames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
/* Components */
import DropdownComponent from '../../../DropdownComponent/DropdownComponent';
import {Button} from "../../../../../../Common/Components";
import Icon from "../../../../../../Common/Components/Icon/Icon";
import Can from '../../../../../../Common/Acl/AccessControl';
import { getcurrentUserTypes } from '../../../../HomeSelectors';
import {selectUserAction} from "../../../../Features/Users/UsersActions";
import {selectConsoles} from "../../../../Features/Consoles/ConsolesActions";
import {AddNewButtonWithIcon} from "../../../index";
import { CheckboxField } from '../../../../../../Common/Components/FormFields';
import { Checkbox } from '../../../../../../Common/Components'


class ConsoleTableRow extends PureComponent {
	onDeleteConsoleHandler = () => {
		if (this.props.rowData.active) {
			this.props.openModal(this.props.rowData);
		}
	};

	onActivateConsoleHandler = (argument) => {
		this.props.openReinvitationModal(this.props.rowData)
	}

	redirect = () => this.props.history.push(`/consoles/${this.props.rowData.id}/profile`);

	render() {
		const { serialNumber, hospital, active, room, debugMode } = this.props.rowData;
		const { isHospital } = this.props
		
		return (
			<Table.Row
				className={classNames({
					'table__row--inactive': !active,
				})}
			>
				<Table.Cell onClick={this.redirect} title={[serialNumber].join(' ')}>
					<div className="table__link">{serialNumber}</div>
				</Table.Cell>
				<Table.Cell title={[hospital.name].join(' ')}>{hospital.name}</Table.Cell>
				<Table.Cell title={[room && room.name].join(' ')}>{room ? room.name : '/'}</Table.Cell>
				<Table.Cell title={[room ? 'Console paired' : 'Console not paired'].join(' ')}>
					<span
						className={classNames({
							'console-row__status': !room,
							'console-row__status--paired': room,
						})}
					>
						{room ? 'Console paired' : 'Console not paired'}
					</span>
				</Table.Cell>
				{!isHospital && (
					<Table.Cell title={[room && room.name].join(' ')}>
						<div className="justify-content--flex-end">
							<Checkbox 
								label=""
								name={`debugMode_${serialNumber}`}
								isChecked={debugMode}
								onChangeHandler={this.onActivateConsoleHandler}
							/>
						</div>
					</Table.Cell>
				)}
				<Can userTypes={this.props.currentUserTypes} action="read" context="editConsole">
					<Table.Cell className="table__action-table-cell">
						<div className="justify-content--flex-end">
							<DropdownComponent
								dropdownOptions={[
									...active ? [{
										className: 'dropdown__menu-item--red',
										label: 'Delete console',
										text: 'Delete',
										action: this.onDeleteConsoleHandler,
										icon: 'icon-trash-2-outline',
									}] : [],
								]}
							/>
						</div>
					</Table.Cell>
				</Can>
			</Table.Row>
		);
	}
}

ConsoleTableRow.defaultProps = {
	openModal: () => {},
	rowData: null,
};

ConsoleTableRow.propTypes = {
	rowData: PropTypes.object,
	openModal: PropTypes.func,
	openReinvitationModal: PropTypes.func,
};

const mapStateToProps = state => ({
	currentUserTypes: getcurrentUserTypes(state),
});

const mapDispatchToProps = dispatch => ({
	selectConsole: id => dispatch(selectConsoles(id)),
});

export default withRouter(connect(mapStateToProps, undefined)(ConsoleTableRow));
