import { renderTraceIdHeader } from "src/domains/Beacon/utils/renderTraceIdHeader";
import { ENDPOINTS } from "../constants";
import { ApiResponse } from "./types";

export interface BillingCallQualityPayload {
  sid: string;
  send_audio_quality: number | null;
  send_video_quality: number | null;
  receive_audio_quality: number | null;
  receive_video_quality: number | null;
}

/**
 * @param payload Defined structure for the metrics backend to process accordingly (BillingCallQualityPayload)
 * Example use: const { data, error } = await API.PUT.billingCallQuality(payload)
 */
const billingCallQuality = async (
  payload: BillingCallQualityPayload | null
): Promise<ApiResponse<any>> => {
  try {
    const url = ENDPOINTS.BILLING_CALL_QUALITY;
    const res = await fetch(url, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        ...renderTraceIdHeader(),
      },
      body: JSON.stringify(payload)
    });

    return { data: res };
  } catch (error) {
    return { error };
  }
};

export default billingCallQuality;
