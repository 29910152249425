import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(XHR)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		lng: 'en',
		react: {
			useSuspense: false,
		},
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		backend: {
			// for all available options read the backend's repository readme file
			loadPath: '/locales/{{lng}}/{{ns}}.json',
		},
	});

// In case we want to use namespaces (separate files) later on
// https://www.i18next.com/principles/namespaces
