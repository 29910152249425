import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Button } from "src/components/legacy/Button";
import { Fields, validators } from '../../../../Common/Components';
import './Login.scss';
import { submitLoginAction } from '../../AuthenticationActions';
import DomainConstants from "avail-web-ui/domains/DomainConstants";
import withAuthentication from '../../Components/withAuthentication';
import userSessionService from '../../../../Common/Services/userSesionService';
import { reCaptchaWrapper, showReCaptcha } from 'src/services/ReCaptchaHelper';
import { LoginPage } from "/src/domains/Login";

const { label } = DomainConstants
class LoginView extends React.PureComponent {
	constructor(props) {
		super(props);
    this.state = { isLoading: false };
		showReCaptcha();
	}

	handleLoginFormSubmit = async (loginData) => {
		const acceptedEula = !!userSessionService.getEulaVersion();
		if (acceptedEula) {
      this.setState({ isLoading: true });
			reCaptchaWrapper('login', (captchaVersion, captchaToken) => this.props.submitLogin({
				...loginData,
				captchaToken,
				captchaVersion
			}));
		}
    setTimeout(() => {
      this.setState({ isLoading: false})
    }, 1000);
	};

	redirect = () => this.props.history.push('/sign-up');

	redirectForget = () => this.props.history.push('/forgot-password');

	render() {
		const { handleSubmit } = this.props;

		return (
      // have to use login class name since web-app uses hardcoded class names (as opposed to generated class names)
      <LoginPage className="login">
        <div className="login__subheading">{label.loginHub}</div>
        <form className="login__form"
        onSubmit={handleSubmit(this.handleLoginFormSubmit)}>
          <Fields.InputField
            data-test-id="username"
            labelText="Email"
            placeholder="Type your email"
            name="username"
            validate={[validators.required, validators.email]}
          />
          <Fields.InputField
            data-test-id="password"
            labelText="Password"
            placeholder="Type your password"
            name="password"
            type="password"
            validate={validators.required}
          />
          <Button
            data-test-id="loginSubmit"
            theme="green"
            className="login__button singular"
            type="submit"
            loading={this.state.isLoading}
            // TODO: move styles into custom MUI Button
            style={{
              marginTop: "20px",
              textTransform: "none",
            }}
          >
            {label.loginMain}
          </Button>
        </form>
        <div className="login__sign-up">
          <a onClick={this.redirectForget} className="login__sign-up-button">Forgot password?</a>
        </div>
        <div className="login__sign-up">
          Are you ready to join the Avail Network?<br />Contact an&nbsp;
          <a href="https://avail.io/contact" target="_blank" className="login__sign-up-button">Avail Sales Representative</a>
          &nbsp;to get started!
        </div>
        <div className="login__sign-up">
          Is your employer an Avail member?<br />Contact&nbsp;
          <a href="mailto:support@avail.io">Avail Technical Support</a>
          &nbsp;to link your {label.memberHub} profile!
        </div>
      </LoginPage>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	submitLogin: data => dispatch(submitLoginAction(data)),
});

export default reduxForm({
	form: 'loginForm',
})(withAuthentication(withRouter(connect(null, mapDispatchToProps)(LoginView))));
