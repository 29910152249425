import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting/meetingSlice";
import { logger } from "src/logging/logger";
import { getRefreshToken } from "src/services/ApiClient/general";
import SecurityService from "src/services/SecurityService";
import { timeoutCallback } from "src/utils/timeout";

export const setAccessTokenReconnectionThunk = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  { retries?: number },
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "meeting/setAccessTokenReconnection",
  async ({ retries = 0 }, { dispatch }) => {
    try {
      logger().info("** Refreshing Avail Access Token **");

      // Must update the access & refresh tokens that are used in ApiQuery
      const {
        access_token,
        refresh_token,
        pubnub_channel,
      } = await getRefreshToken();

      dispatch(meetingActions.setPubNubChannel(pubnub_channel));

      // Updates the tokens inside the sessionStorage since the ApiClient inside
      // src/services/ApiClient uses it
      SecurityService.login(access_token, refresh_token);

      logger().info("Avail Access Token refreshed successfully");
    } catch (error: any) {
      const retry = retries + 1;
      // Re-trying to refresh access token after 1 min
      timeoutCallback(
        () => dispatch(setAccessTokenReconnectionThunk({ retries: retry })),
        60 * 1000 // 1min timeout
      );
      logger().error(
        `Error while running timed Avail access token refresh, reconnection retry number ${retry}`,
        error?.message
      );
      throw error; // Using BE error message response
    }
  }
);
