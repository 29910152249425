/** Palette
 * Tungsten design, for use with Telestration
 * pencil thickness, color selection, and clear button
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import clsx from "clsx";

import Brightness1Icon from "@mui/icons-material/Brightness1";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { FormControl, Radio, RadioGroup, RadioProps } from "@mui/material";

import { Button } from "src/components/Button";
import { ThicknessSlider } from "src/domains/Beacon/components/Palette/ThicknessSlider/ThicknessSlider";
import {
  PEN_COLOR_TO_HEX,
  HEX_TO_PEN_COLOR,
} from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { selectPenStyle } from "src/domains/Beacon/store/telestration/selectors";
import { clearLocalAnnotationsThunk } from "src/domains/Beacon/store/telestration/thunks/clearLocalAnnotationsThunk";
import { logger, LoggerLevels } from "src/logging/logger";

import styles from "./styles.scss";

export interface PaletteProps {
  "data-test-id"?: string;
  hidden: boolean;
}

type colorsEnum = "red" | "blue" | "green" | "yellow" | "black" | "white";

interface ColorRadioProps extends Omit<RadioProps, "color"> {
  color: colorsEnum;
}
const ColorRadio = ({ color, checked, onClick }: ColorRadioProps) => {
  return (
    <Radio
      onClick={onClick}
      checked={checked}
      classes={{ root: styles[`radio-${color}`], checked: styles.checked }}
      icon={<Brightness1Icon className={styles.icon} />}
      checkedIcon={<Brightness1Icon fontSize="large" className={styles.icon} />}
      value={color}
      data-test-id={`color-radio-${color}`}
    />
  );
};

const RESET_STATE = {
  red: false,
  blue: false,
  green: false,
  yellow: false,
  black: false,
  white: false,
};

export const Palette = ({ hidden }) => {
  const dispatch = useDispatch();
  const { width: penWidth, color: penColor } = useAppSelector(selectPenStyle);

  // * state that only services radio button UI
  const [selectedColor, setSelectedColor] = useState({
    ...RESET_STATE,
    green: true,
  });

  // * handlers
  const handleSelect = (evt) => {
    const color = evt.target.value;
    if (selectedColor[color] || !color) {
      // if color is already active, do nothing
      // !color occurs when clicking on selectedColor indicator
      return;
    }
    dispatch(
      telestrationActions.setStyle({
        color: PEN_COLOR_TO_HEX[color],
      })
    );
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "telestration/Palette",
      },
      `pen color has changed to ${color}`
    );
  };

  const handleThicknessChange = (evt) => {
    const penThickness = evt.target.value;
    dispatch(telestrationActions.setStyle({ width: penThickness }));
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "telestration/Palette",
      },
      `pen thickness has changed to ${penThickness}`
    );
  };

  useEffect(() => {
    // use penColor in Redux to look up the color name from the hex color
    const colorName = HEX_TO_PEN_COLOR[penColor];
    // when penColor is changed, we update setSelectedColor
    setSelectedColor({ ...RESET_STATE, [colorName]: true });
  }, [penColor]);

  return (
    <div
      className={clsx(styles.root, { [styles.hidden]: hidden })}
      data-test-id="telestration-palette"
    >
      <div className={styles.sliderContainer}>
        <ThicknessSlider onChange={handleThicknessChange} value={penWidth} />
      </div>
      <FormControl>
        <RadioGroup className={styles.radiosContainer}>
          {Object.keys(RESET_STATE).map((color: colorsEnum, index) => {
            return (
              <ColorRadio
                onClick={(event) => handleSelect(event)}
                checked={selectedColor[color]}
                color={color}
                key={`color-radio-${index}`}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <Button
        variant="icon"
        endIcon={<DeleteOutlineIcon fontSize="large" />}
        tooltipProps={{ title: "Clear" }}
        onClick={() => {
          dispatch(clearLocalAnnotationsThunk()); // thunk clears data & UI
        }}
        data-test-id="palette-clear-button"
      />
    </div>
  );
};
