import {
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
} from "twilio-video";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  CONSOLE_VIDEO_LOG_MESSAGE,
  HOST_PIP_VIDEO_LOG_MESSAGE,
} from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { handleConsoleTrackSubscribedThunk } from "src/domains/Beacon/store/twilio/thunks/handleConsoleTrackSubscribedThunk";
import { handleHostTrackSubscribedThunk } from "src/domains/Beacon/store/twilio/thunks/handleHostTrackSubscribedThunk";
import { handleParticipantTrackSubscribedThunk } from "src/domains/Beacon/store/twilio/thunks/handleParticipantTrackSubscribedThunk";
import {
  isConsoleParticipant,
  whoPublishedTrack,
} from "src/domains/Beacon/utils/twilio";
import { logger } from "src/logging/logger";

export const useRoomTrackSubscribed = () => {
  const { room } = useAppSelector(selectTwilioState);
  const { callDetails, mode } = useAppSelector(selectMeetingState);

  const dispatch = useDispatch();

  useEffect(() => {
    room.on(
      "trackSubscribed",
      (
        track: RemoteTrack,
        publication: RemoteTrackPublication,
        participant: RemoteParticipant
      ) => {
        const isConsole = isConsoleParticipant(participant);

        logger().verbose("Subscribed to new tracks..." + track.name);

        const publicationTrack = publication.track;

        // TODO: this can be moved to another Twilio's hook named "trackDimensionsChanged"
        if (publicationTrack.kind === "video") {
          publicationTrack.on("dimensionsChanged", (remoteTrack) => {
            logger().info(
              `dimensionsChanged. This may affect telestration, if already drawn.`,
              participant
            );

            if (remoteTrack.dimensions) {
              const logMessage = isConsole
                ? CONSOLE_VIDEO_LOG_MESSAGE
                : HOST_PIP_VIDEO_LOG_MESSAGE;

              logger().info(
                `${logMessage} ${JSON.stringify(remoteTrack.dimensions)}`
              );
            }
          });
        }

        // Detects if in MP call, the host|console changes its video/audio input
        if (mode === CallModes.MP) {
          whoPublishedTrack({
            participant,
            publication,
            callDetails,
            wasHost: (newTrack) =>
              dispatch(handleHostTrackSubscribedThunk(participant, newTrack)),
            wasConsole: (newTrack) =>
              dispatch(
                handleConsoleTrackSubscribedThunk(participant, newTrack)
              ),
            wasParticipant: (newTrack) =>
              dispatch(
                handleParticipantTrackSubscribedThunk(participant, newTrack)
              ),
          });
        } else if (mode === CallModes.P2P) {
          const newTrack = publication.track;
          if (isConsole) {
            // Console has published its video/audio tracks to Twilio
            logger().info(`Subscribed to Console's ${newTrack.kind} track`);
            dispatch(handleConsoleTrackSubscribedThunk(participant, newTrack));
          } else {
            dispatch(handleHostTrackSubscribedThunk(participant, newTrack));
          }
        }
      }
    );
  }, []);
};
