import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, Icon, validators, Fields } from '../../../../../../../../../Common/Components';
import {
	getDepartmentByHospitalAction,
	getRoomAction,
	updateRoomAction,
} from '../../../DepartmentProfile/DepartmentsActions';
import { getInitialValuesForRoomProfile } from '../../../DepartmentProfile/DepartmentsSelectors';

class RoomInfo extends PureComponent {
	componentDidMount() {
		this.props.getRoom(this.props.roomId);
		this.props.getDepartmentsByHospital({hospitalId: this.props.hospitalId});
	}

	onRoomUpdateHandler = data => this.props.updateRoom(this.props.roomId, data.roomData, { ids: data.departments });

	render() {
		const { t } = this.props;

		return (
			<form
				className="avail-profile__edit-form flex-direction--row justify-content--space-between"
				onSubmit={this.props.handleSubmit(this.onRoomUpdateHandler)}
			>
				<div>
					<Fields.InputField
						className="avail__input"
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.placeholder')}
						name="roomData.name"
						validate={[validators.required, validators.minimumLength3, validators.maximumLength48]}
					/>
					<Fields.InputField
						className="user-profile__general-input avail__input"
						labelText={t('common.form.type.label')}
						placeholder={t('common.form.type.placeholder')}
						name="roomData.type"
						validate={validators.required}
						disabled
					/>
					{this.props.departments && this.props.departments.length > 0 &&
					(
						<Fields.MultiselectField
							labelText="Associated Clinical Departments"
							placeholder="Associate room to Clinical Department(s)"
							name="departments"
							options={this.props.departments.map(department => ({
								key: department.id,
								value: department.id,
								label: department.name,
							}))}
							validate={validators.multiselectRequired}
						/>
					)}

				</div>
				<div>
					<Button className="button-with-icon align-self--flex-start" type="submit">
						<Icon className="icon-save icon--orange entity-profile__submit-icon" />
						<span className="entity-profile__submit-title">Save Changes</span>
					</Button>
				</div>
			</form>);
	}
}

RoomInfo.propTypes = {};

const mapStateToProps = state => ({
	initialValues: getInitialValuesForRoomProfile(state),
	departments: state.departmentReducer.departments,
});

const mapDispatchToProps = dispatch => ({
	getRoom: roomId => dispatch(getRoomAction(roomId)),
	updateRoom: (roomId, roomData, departmentIds) => dispatch(updateRoomAction(roomId, roomData, departmentIds)),
	getDepartmentsByHospital: hospitalId => dispatch(getDepartmentByHospitalAction(hospitalId)),
});

const RoomInfoWithTranslation = withTranslation()(RoomInfo);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'roomProfileForm',
	enableReinitialize: true,
  touchOnChange: true,
})(RoomInfoWithTranslation)));
