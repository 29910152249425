import { useRef } from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";

import clsx from "clsx";

import { Add, HorizontalRule } from "@mui/icons-material";

import { Button } from "src/components/Button";
import { Font } from "src/components/Font";
import { PipToolTips } from "src/constants/tooltips";
import { Camera } from "src/domains/Beacon/components/Camera";
import {
  CAMERA_PIP_HEIGHT,
  CAMERA_PIP_WIDTH, // PIP_AUDIO_ELEMENT_ID,
} from "src/domains/Beacon/constants";
import { useGetHostUser } from "src/domains/Beacon/hooks/useGetHostUser";
import { useAppSelector } from "src/domains/Beacon/store";
import { uiActions } from "src/domains/Beacon/store/ui";
import { selectPipCollapsed } from "src/domains/Beacon/store/ui/selectors";

import { usePipPosition } from "../../hooks/usePipPosition";
import styles from "./styles.scss";

interface IProps {
  "data-test-id"?: string;
}

export const Pip = ({ "data-test-id": dataTestId }: IProps) => {
  const pipRef = useRef<any>();
  // this hook handles all the maths required for positioning of the draggable
  const [onDrag, onStop, { position, bounds }] = usePipPosition(pipRef);
  const dispatch = useDispatch();
  const isPipCollapsed = useAppSelector(selectPipCollapsed);
  const host = useGetHostUser();

  const togglePipVisibility = () =>
    dispatch(uiActions.setPipCollapsed(!isPipCollapsed));

  return (
    <>
      <div className={clsx({ [styles.grid]: true })}>
        <Draggable
          bounds={bounds}
          position={position}
          onDrag={onDrag}
          onStop={onStop}
          nodeRef={pipRef}
          disabled={isPipCollapsed}
        >
          <div
            ref={pipRef}
            className={clsx(styles.root, {
              [styles.hiddenPip]: isPipCollapsed,
            })}
            data-test-id={dataTestId}
          >
            <Camera
              width={CAMERA_PIP_WIDTH}
              height={CAMERA_PIP_HEIGHT}
              className={clsx(styles.camera, {
                [styles.hiddenPip]: isPipCollapsed,
              })}
              data-test-id="pip-camera"
            />
            <div
              className={clsx(styles.nameContainer, {
                [styles.hiddenPip]: isPipCollapsed,
              })}
              data-test-id="pip-hostname"
            >
              <div className={styles.userTypeContainer}>
                <Font variant="b1" color="light">
                  Host
                </Font>
              </div>
              <Font variant="b1" color="light">
                {`${host?.firstName} ${host?.lastName}`}
              </Font>
            </div>
            <div className={styles.sideTools}>
              <div className={styles.sideToolsButtons}>
                <Button
                  variant="icon"
                  endIcon={
                    isPipCollapsed ? (
                      <Add className={styles.icon} data-test-id="show-icon" />
                    ) : (
                      <HorizontalRule
                        className={styles.icon}
                        data-test-id="hide-icon"
                      />
                    )
                  }
                  tooltipProps={{
                    title: PipToolTips[isPipCollapsed ? "expand" : "collapse"],
                    placement: "top",
                  }}
                  onClick={togglePipVisibility}
                  data-test-id="hide-pip-button"
                />
              </div>
            </div>
          </div>
        </Draggable>
      </div>
    </>
  );
};
