import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { withTranslation } from 'react-i18next';

import Can from '../../../../../../../../../Common/Acl/AccessControl';
import { Table } from '../../../../../../../Common';
import ModalComponent from '../../../../../../../../../Common/Components/Modal/Modal';
import { Button } from '../../../../../../../../../Common/Components';
import { getcurrentUserTypes } from '../../../../../../../HomeSelectors';
import VendorsHospitalRow from './Components/VendorsHospitalRow/VendorsHospitalRow';
import { getVendorsHospitalsAction, updateVendorsHospitalsAction } from '../../../../VendorsActions';
import AddNewHospital from './Components/AddNewHospital/AddNewHospital';

const columns = [{
	title: 'Name',
	sortValue: 'name',
}];

class VendorsHospitals extends PureComponent {
	state = {
		isAddHospitalModalOpened: false,
	}

	componentDidMount() {
		this.params = {
			vendorId: this.props.businessUnitId,
			page: 0,
		};
		this.props.getVendorsHospitals(this.params);
	}

	onAddHospitalHandler = (data) => {
		this.setState({ isAddHospitalModalOpened: !this.state.isAddHospitalModalOpened });
		this.props.updateVendorsHospitals(this.props.businessUnitId, data.hospitals);
	};

	onAddHospitalToggleHandler = () => this.setState({ isAddHospitalModalOpened: !this.state.isAddHospitalModalOpened });

	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			vendorId: this.props.businessUnitId,
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			vendorId: this.props.businessUnitId,
			size: this.params.size,
			page: this.params.page,
			[this.parameter]: this.sortParams,
		};
		this.props.getVendorsHospitals(this.params);
	};

	toggleAddHospitalModal = () => {
		this.props.resetReduxForm('addVendorsHospitalForm');
		this.setState({ isAddHospitalModalOpened: !this.state.isAddHospitalModalOpened });
	};

	render() {
		const { t } = this.props;

		return (
			<div className="business-units-list">
				<ModalComponent
					open={this.state.isAddHospitalModalOpened}
					title={t('features.org.hospital.create.label_add')}
					onCancel={this.onAddHospitalToggleHandler}
					hasActionButtons={false}
				>
					<AddNewHospital
						data={this.props.vendorsSupportedAndNewHospitals}
						toggleAddNewHospitalModal={this.onAddHospitalToggleHandler}
						businessUnitId={this.props.businessUnitId}
						onSubmit={this.onAddHospitalHandler}
					/>
				</ModalComponent>
				<Can userTypes={this.props.currentUserTypes} action="read" context="addVendorHospital">
					<div className="justify-content--flex-end">
						<Button isFull buttonSmall onClick={this.toggleAddHospitalModal}>
							{t('features.org.hospital.create.label_add')}
						</Button>
					</div>
				</Can>
				<Table
					className="table__profile-wrapper"
					tableHeaderClassName="table__profile-wrapper"
					columns={columns}
					row={VendorsHospitalRow}
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					onSortTableHandler={this.onTableFilterHandler}
					search
					sortable
					noEntityComponent={
						<div className="justify-content--space-between width--full">
							<div className="profile__right-content-wrapper">No hospitals available</div>
						</div>}
					tableData={this.props.vendorsHospitals}
					tableActions={false}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	vendorsHospitals: state.vendorsReducer.vendorsHospitals,
	vendorsSupportedAndNewHospitals: state.vendorsReducer.vendorsSupportedAndNewHospitals,
	currentUserTypes: getcurrentUserTypes(state),
});

const mapDispatchToProps = dispatch => ({
	resetReduxForm: formName => dispatch(reset(formName)),
	getVendorsHospitals: payload => dispatch(getVendorsHospitalsAction(payload)),
	updateVendorsHospitals: (businessUnitId, data) => dispatch(updateVendorsHospitalsAction(businessUnitId, data)),
});

const VendorsHospitalsWithTranslation = withTranslation()(VendorsHospitals);

export default connect(mapStateToProps, mapDispatchToProps)(VendorsHospitalsWithTranslation);
