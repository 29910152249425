import React, { useEffect } from 'react';
import { reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from '../../../../../../../Common/Components';
import AddNewHospital from './AddNewHospital';
import { getAllHospitalsWithoutPaginationAction } from '../../../../../Features/Organisation/Hospitals/HospitalsActions';
import './CreateNewVendor.scss';

const CreateNewVendorHospitals = (props) => {
	useEffect(() => {
		props.getAllHospitals();
	}, []);

	const onFormSubmit = () => {
		try {
			props.handleSubmit();
		} finally {
			props.resetFormState();
		}
	};

	const {
		pristine,
		previousPage,
		submitting,
		hospitals,
	} = props;

	return (
		<form onSubmit={onFormSubmit}>
			<div>
				<AddNewHospital data={hospitals} />
			</div>

			<div className="button_wrapper">
				<Button type="button" onClick={previousPage}>
					Previous
				</Button>
				<Button
					type="submit"
					isFull
					buttonSmall
					disabled={pristine || submitting}
				>
					Submit
				</Button>
			</div>
		</form>
	);
};

const mapDispatchToProps = dispatch => ({
	getAllHospitals: () => dispatch(getAllHospitalsWithoutPaginationAction()),
	resetFormState: () => dispatch(reset('CreateNewVendor')),
});

const mapStateToProps = state => ({
	hospitals: state.hospitalsReducer.allHospitals,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(reduxForm({
	form: 'CreateNewVendor',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(CreateNewVendorHospitals));
