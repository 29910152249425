/** Font documentation
 * Allows us to use Type Scales defined in Figma Design System
 * https://www.figma.com/file/5PDkIJQPyBc6ToCRnfXrI5/Avail-%7C-Design-System?node-id=612%3A1638
 *
 * Text Styles are written as 'PROJECT/TEXT_STYLE' (example "Browser/H3")
 * Component takes a Text Style in lowercase from Figma (i.e. "h3" from example)
 * as variant prop to:
 *  1. match styles
 *  2. dynamically create the relevant semantic HTML element
 */
import React from "react";

import clsx from "clsx";

import styles from "./styles.scss";

export type Variants =
  | "h1"
  | "h2"
  | "h3"
  | "b1"
  | "b2"
  | "b3"
  | "b4"
  | "button";

export type Colors =
  | "light"
  | "dark"
  | "disabled"
  | "red"
  | "green"
  | "dark-grey";

interface Props {
  variant: Variants;
  children: React.ReactNode;
  color?: Colors;
  "data-test-id"?: string;
  className?: string;
  textAlign?: "left" | "right" | "center";
}

const mapVariantToHtmlElement = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  b1: "p",
  b2: "p",
  b3: "p",
  b4: "p",
  button: "span",
};

export const Font = ({
  variant,
  children,
  color,
  className,
  "data-test-id": dataTestId,
  textAlign = "left",
}: Props) => {
  // DynamicallyCreatedHtmlElement is typed to prevent following error:
  // Argument of type 'string' is not assignable to parameter of type 'typeof Component'
  // removed type for DynamicallyCreatedHtmlElement, React.FunctionalComponent was raising a type error due to props mismatch with HtmlElement: typeof React.Component
  // TODO: convert to use React.createElement()
  const DynamicallyCreatedHtmlElement = (
    //  typed to cause "p" to return a <p>, prevents:
    // JSX element type 'HtmlElement' does not have any construct or call signatures.
    HtmlElement
  ) => (
    <HtmlElement
      className={clsx(
        styles.root,
        styles[variant],
        styles[color],
        styles[`text--${textAlign}`],
        className
      )}
      data-test-id={dataTestId}
    >
      {children}
    </HtmlElement>
  );

  return DynamicallyCreatedHtmlElement(mapVariantToHtmlElement[variant]);
};
