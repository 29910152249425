import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import clsx from "clsx";

import { CircularProgress, Grid } from "@mui/material";

import { Button } from "src/components/Button";
import { Font } from "src/components/Font";
import {
  Modal,
  ModalBody,
  ModalFooter,
  UndismissableModalHeader,
} from "src/components/Modal";
import { Select } from "src/components/Select";
import { Camera } from "src/domains/Beacon/components/Camera";
import { useAppSelector } from "src/domains/Beacon/store";
import {
  selectCallMeetingModeState,
  selectEventDetails,
  selectIsUserHost,
} from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import {
  selectLocalMedia,
  selectStreamState,
} from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { getLocalMediaThunk } from "src/domains/Beacon/store/stream/thunks";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { leaveCallThunk } from "src/domains/Beacon/store/twilio/thunks/leaveCall";
import {
  areDevicesReady,
  getLocalVideoStream,
  stopMediaTracks,
} from "src/domains/Beacon/utils/mediaDevices";
import { LoggerLevels, logger } from "src/logging/logger";
import { MultiPartyEvent } from "src/services/ApiClient/scheduler";
import { isFirefox, isSafari } from "src/utils/browsers";
import { timeoutCallback } from "src/utils/timeout";

import styles from "./styles.scss";

interface IProps {
  open: boolean;
  joinCallAction: () => void;
}

export const PreCallModal = ({ open, joinCallAction }: IProps) => {
  const dispatch = useDispatch();

  const [videoStream, setVideoStream] = useState<MediaStream>();
  const [mediaDevicesReady, setMediaDevicesReady] = useState(false);
  const localMedia = useAppSelector(selectLocalMedia);
  const isHostUser = useAppSelector(selectIsUserHost);

  const stream = useAppSelector(selectStreamState);
  const localMediaError = stream.localMediaError;

  const callMode = useAppSelector(selectCallMeetingModeState);
  const eventDetails = useAppSelector(selectEventDetails) as MultiPartyEvent;

  const twilioState = useAppSelector(selectTwilioState);
  const getCredentialsError = twilioState.getCredentialsError;

  useEffect(() => {
    const getLocalMedia = async () => {
      try {
        // Get current user's local media (audio/video)
        await dispatch(getLocalMediaThunk());
      } catch (error: any) {
        logger().error(
          "There was an error while getting users local media in the pre call modal",
          error.message
        );
      }
    };
    getLocalMedia();
  }, []);

  const checkDevicesReady = () => {
    // areDevicesReady returns a boolean that checks if we have access to the users mic/cam/speaker devices
    // for example we check to see if the arrays of videoInputs has any devices in it, if that list is not empty
    // then that means the user has given us permission to use their device
    const areReady = areDevicesReady(localMedia.mediaDevices);

    if (areReady) {
      // small delay so the loading indicator below can fade nicely
      timeoutCallback(() => setMediaDevicesReady(true), 800);
    }
  };

  // Detects when media devices are loaded and select a default camera for the host
  // this happens only once, when the PreCall first loads
  useEffect(() => {
    if (
      localMedia?.mediaDevices &&
      !localMedia.camera &&
      !localMedia.microphone &&
      !localMedia.speaker
    ) {
      const loadDefaultDevices = async () => {
        if (isHostUser) {
          const defaultCamera = localMedia?.mediaDevices.videoInputs[0];

          logger().logWithFields(
            LoggerLevels.info,
            {
              feature: "Media Devices",
              fileInfo: "PreCallModal",
            },
            `Default pre-selected video device: ${JSON.stringify(
              defaultCamera ?? "null"
            )}`
          );

          dispatch(streamActions.setCameraDevice(defaultCamera));
          setVideoStream(await getLocalVideoStream(defaultCamera?.deviceId));
        }
        const defaultMicrophone = localMedia?.mediaDevices.audioInputs[0];
        const defaultSpeaker = localMedia?.mediaDevices.audioOutputs[0];

        logger().logWithFields(
          LoggerLevels.info,
          {
            feature: "Media Devices",
            fileInfo: "PreCallModal",
          },
          `Default pre-selected audio devices: ${JSON.stringify(
            {
              defaultMicrophone,
              defaultSpeaker,
            } ?? "null"
          )}`
        );

        dispatch(streamActions.setMicrophoneDevice(defaultMicrophone));
        dispatch(streamActions.setSpeakerDevice(defaultSpeaker));
      };
      loadDefaultDevices();
    }
  }, [localMedia?.mediaDevices]);

  useEffect(() => {
    if (localMedia?.mediaDevices) {
      checkDevicesReady();
    }
  }, [localMedia?.mediaDevices]);

  // Not doing show/hide camera off functionality for now

  // const [showCamera, setShowCamera] = useState(true);
  // const toggleCamera = async () => {
  //   const [track] = videoStream.getVideoTracks();
  //   if (track.readyState === "live") {
  //     stopMediaTracks(videoStream);
  //   } else {
  //     setVideoStream(await getLocalVideoStream(localMedia.camera.deviceId));
  //   }
  //   setShowCamera(!showCamera);
  // };

  const onJoinCall = () => {
    const { camera, microphone, speaker } = localMedia;

    // Must clean the local video stream before joining the call
    stopMediaTracks(videoStream);

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Media Devices",
        fileInfo: "PreCallModal",
      },
      `Joining call, user's media devices selected for this call are: ${JSON.stringify(
        {
          camera,
          microphone,
          speaker,
        } ?? "null"
      )}`
    );

    joinCallAction();
  };

  const changeVideoInput = async (e) => {
    // Must clean the previous video stream if exists, otherwise, Browser's red indicator
    // will persists forever
    stopMediaTracks(videoStream);
    const { videoInputs } = localMedia.mediaDevices;
    const camera = videoInputs.find((v) => v.deviceId === e.target.value);

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Media Devices",
        fileInfo: "PreCallModal",
      },
      // "null" when stringify is "undefined"
      `New camera device selected: ${JSON.stringify(camera ?? "null")}`
    );

    dispatch(streamActions.setCameraDevice(camera));
    setVideoStream(await getLocalVideoStream(camera?.deviceId));
  };

  const changeMicrophoneInput = (e) => {
    const { audioInputs } = localMedia.mediaDevices;
    const microphone = audioInputs.find((a) => a.deviceId === e.target.value);

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Media Devices",
        fileInfo: "PreCallModal",
      },
      // "null" when stringify is "undefined"
      `New microphone device selected: ${JSON.stringify(microphone ?? "null")}`
    );

    dispatch(streamActions.setMicrophoneDevice(microphone));
  };

  const changeSpeakerOutput = (e) => {
    const { audioOutputs } = localMedia.mediaDevices;
    const speaker = audioOutputs.find((a) => a.deviceId === e.target.value);

    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: "Media Devices",
        feature: "PreCallModal",
      },
      // "null" when stringify is "undefined"
      `New speaker device selected: ${JSON.stringify(speaker ?? "null")}`
    );

    dispatch(streamActions.setSpeakerDevice(speaker));
  };

  const eventTitle = eventDetails ? eventDetails.subject : "Event";

  const leaveCallAction = () => {
    // Must clean the local video stream when leaving the call
    stopMediaTracks(videoStream);
    dispatch(leaveCallThunk());
  };

  // so if we have any error from the API, condense them down to a single boolean
  // so we can show if we need to show the error section below
  const apiErrors = getCredentialsError;

  return (
    <>
      <div
        className={clsx(styles.messageBackground, {
          // once the pre call modal is supposed to be closed (meaning open === false)
          // hide this message background (!open will be true, so we apply the "hide" class)
          [styles.hide]: !open,
        })}
      >
        {/* show simple loading icon until the media devices are ready,
          if the user explicitly reject the permissions, then we just leave this message up for now,
          in the future we can add a message that says something else

          also need to make sure we dont have any other errors
      */}
        {(!mediaDevicesReady || localMediaError) && !apiErrors ? (
          <>
            {/* TODO move the CircularProgress to a Loading component */}
            <CircularProgress classes={{ root: styles.loadingIcon }} />
            {localMediaError ? (
              <>
                <Font
                  variant="h1"
                  color="light"
                  className={styles.permissionError}
                >
                  Please grant Microphone & Camera permissions in order to join
                  the Avail Experience
                </Font>
                <Button
                  theme="red"
                  onClick={leaveCallAction}
                  label="Leave Event"
                />
              </>
            ) : null}
          </>
        ) : null}

        {/* if we have more errors then turn this into a switch function that renders the error that occurred */}
        {apiErrors ? (
          <>
            <Font variant="h1" color="light" className={styles.permissionError}>
              {getCredentialsError.message}
            </Font>
            <Button theme="red" onClick={leaveCallAction} label="Leave Event" />
          </>
        ) : null}
      </div>

      <Modal
        className={clsx(styles.modal, styles.width, {
          [styles.hostView]: isHostUser,
        })}
        showVeil={false}
        size="medium"
        // show this modal when the media devices are ready
        open={open && mediaDevicesReady && !localMediaError && !apiErrors}
        data-test-id="pre-call-check-modal"
        // just for slight optimization, unmount when we close this modal since it doesn't need to appear ever again
        unmountOnExit={true}
      >
        <UndismissableModalHeader
          className={styles.modalHeader}
          title={callMode === CallModes.P2P ? "Incoming Call" : eventTitle}
        />
        <ModalBody>
          <Grid justifyContent="flex-start">
            <Font className={styles.cameraLabel} variant="b1" color="light">
              Select Input / Output devices
            </Font>
          </Grid>
          <div className={styles.container}>
            {isHostUser && (
              <div className={styles.cameraSection}>
                <Camera width={"290px"} videoStream={videoStream} />
                {/* Not doing on/off buttons for cam/mic for now */}
                {/* <div className={styles.buttons}>
                  <Button
                    variant="icon"
                    onClick={() => null}
                    startIcon={true ? <Mic /> : <MicOff />}
                  />

                  <Button
                    variant="icon"
                    onClick={toggleCamera}
                    startIcon={showCamera ? <Videocam /> : <VideocamOff />}
                  />
                </div> */}
              </div>
            )}
            <div className={styles.mediaSection}>
              {isHostUser && (
                <div className={styles.select}>
                  <Select
                    label="Video Source"
                    name="video source"
                    onChange={changeVideoInput}
                    value={localMedia?.camera?.deviceId || ""}
                    options={localMedia?.mediaDevices.videoInputs.map(
                      (camera) => ({
                        title: camera.label,
                        value: camera.deviceId,
                      })
                    )}
                  />
                </div>
              )}

              <div className={styles.select}>
                <Select
                  label="Microphone"
                  name="microphone"
                  onChange={changeMicrophoneInput}
                  value={localMedia?.microphone?.deviceId || ""}
                  options={localMedia?.mediaDevices.audioInputs.map((mic) => ({
                    title: mic.label,
                    value: mic.deviceId,
                  }))}
                />
              </div>

              <Select
                label="Speaker"
                name="speaker"
                disabled={
                  // these browsers do not support changing Audio Output Device
                  isFirefox(navigator.userAgent) ||
                  isSafari(navigator.userAgent)
                }
                onChange={changeSpeakerOutput}
                value={localMedia?.speaker?.deviceId || ""}
                options={localMedia?.mediaDevices.audioOutputs.map(
                  (speaker) => ({
                    title: speaker.label,
                    value: speaker.deviceId,
                  })
                )}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button
            data-test-id="pre-call-join-button"
            onClick={onJoinCall}
            label="Join"
            disabled={!mediaDevicesReady}
          />
          <Button
            theme="red"
            onClick={leaveCallAction}
            label="Leave Event"
            className={styles.button}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};
