import { useHistory } from "react-router";

import moment from "moment";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";

import { Button } from "src/components/legacy/Button";
import { Chip } from "src/components/legacy/ChipInput/Chip";
import { DetailItem } from "src/domains/Events/Detail/DetailItem";
import {
  checkCurrentUserAParticipant,
  checkCurrentUserEventCreator,
  checkEventEditable,
  checkEventJoinable,
  checkEventStarted,
  participantDisplayValue,
} from "src/domains/Events/utils";
import { MultiPartyEvent, EventStatus } from "src/services/ApiClient/scheduler";

import DomainConstants from "avail-web-ui/domains/DomainConstants";
import { getUserInfo } from "avail-web-ui/services/UserSessionService";

import styles from "./styles.scss";

interface Props {
  event: MultiPartyEvent | null;
  open: boolean;
  onCancel: () => void;
  onStart: () => void;
}

export function EventDetailPopup({ event, open, onCancel, onStart }: Props) {
  const history = useHistory();
  const userInfo = getUserInfo();

  // Since multiple types of events can be injected as `event` prop on the mounted
  // `DetailPopup` component, this check must be made to make sure it is a multiparty event
  if (!event || !event.participants) {
    return null;
  }

  const host = event?.participants.find((p) => p.role === "HOST");
  const panelists = event?.participants.filter((p) => p.role !== "HOST");

  const isUserCreator = checkCurrentUserEventCreator(event, userInfo.id);
  const hasEventStarted = checkEventStarted(event);
  const isUserParticipant = checkCurrentUserAParticipant(
    event,
    userInfo.loginId
  );
  const isEventCurrent =
    (event.status === EventStatus.ended ||
      event.status === EventStatus.created ||
      event.status === EventStatus.started) &&
    checkEventJoinable(event) &&
    moment(event.endDate).isSameOrAfter(new Date(), "day");
  const isEventEditable = checkEventEditable(event, isUserCreator);

  // if an event status is "ENDED" but it's today, it should be joinable
  const isEndedEventActive =
    event.status === EventStatus.ended && checkEventJoinable(event);

  return (
    <Modal
      className={styles.modal}
      aria-labelledby="event-details-popup"
      open={open}
      onClose={onCancel}
    >
      <div className={styles.details}>
        <IconButton
          className={styles.close}
          color="default"
          onClick={onCancel}
          size="large"
        >
          <CloseIcon />
        </IconButton>

        <Typography className={styles.title} variant="h5">
          {event.subject}
          {hasEventStarted ? (
            <Chip
              label="In Progress"
              size="small"
              className={styles.chip}
              color="success"
            />
          ) : null}
        </Typography>

        {isUserCreator
          ? event.eventTags.map((tag) => {
              return (
                <Chip
                  label={tag}
                  key={tag}
                  size="small"
                  className={styles.chip}
                  color="primary"
                />
              );
            })
          : null}

        <DetailItem label="Facility" text={event.hospitalName} />

        <div className={styles.detailSection}>
          <Typography variant="h6">Time</Typography>
        </div>

        <div className={styles.eventDates}>
          <DetailItem label="Start Date" text={formatDate(event.startDate)} />
          <DetailItem label="Start Time" text={formatTime(event.startDate)} />
          <DetailItem label="End Date" text={formatDate(event.endDate)} />
          <DetailItem label="End Time" text={formatTime(event.endDate)} />
        </div>

        {/* Hiding the timezone because we format the time to have the timezone abbreviation instead */}
        {/* <DetailItem label="Time Zone" text={event.timeZoneId} inline /> */}

        <div className={styles.detailSection}>
          <Typography variant="h6">Participants</Typography>
        </div>

        <DetailItem label="Surgeon" text={event.surgeonName} />
        <DetailItem
          label={DomainConstants.label.host}
          text={participantDisplayValue(host)}
        />
        <DetailItem
          label="Panelists"
          text={panelists.map(participantDisplayValue).join(", ")}
        />
        <div className={styles.actions}>
          <Button
            onClick={() => history.push(`/events/${event.eventId}`)}
            variant="text"
            disabled={!isEventEditable}
          >
            Edit Details
          </Button>

          <Button
            onClick={onStart}
            theme={hasEventStarted || isEndedEventActive ? "green" : "primary"}
            disabled={!isUserParticipant || !isEventCurrent}
          >
            {hasEventStarted || isEndedEventActive ? "Join" : "Start"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function formatDate(datetime: string) {
  return moment(datetime).format("M/DD/YYYY");
}

function formatTime(datetime: string) {
  const time = moment(datetime).format("h:mm A");

  // the time variable above is formated to the user's local timezone,
  // so show that local timezone name also
  const t = moment.tz.guess();
  const tzName = moment.tz(t).zoneAbbr();
  return `${time} ${tzName}`;
}
