import { Participant } from "twilio-video";

import { getTwilioLoginId } from "src/domains/Beacon/utils/twilio";
import { Participant as EventParticipant } from "src/services/ApiClient/scheduler";

export const getActiveParticipants = (
  eventParticipants: EventParticipant[],
  twilioParticipants: Participant[]
) => {
  if (eventParticipants) {
    const activeUsers = eventParticipants.filter((participant) => {
      return twilioParticipants.some((twilioParticipant) => {
        const loginId = getTwilioLoginId(twilioParticipant.identity);
        return loginId === participant.loginId;
      });
    });

    return [...activeUsers];
  }
  return [];
};
