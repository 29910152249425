import { RootState } from "src/domains/Beacon/store";
import { AppDispatch } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { logger, LoggerLevels } from "src/logging/logger";
import { getPartnerIframe } from "src/services/ApiClient/integrations";
import { MultiPartyEvent } from "src/services/ApiClient/scheduler";

interface IParams {
  integrationName: string;
}

// TODO: This thunk will be removed later, currently we need to check if ORT iframe
// is fetched based on the procedure and therapy names in the event, just to make sure if show/hide it
// inside the Layouts SidePanel
// Pre-Fetches the iframe for TPI in order to enable/disable the TPI option
// inside Layouts, we need to check if therapy and procedure names matches with ORT's list
export const preFetchIframeThunk = ({ integrationName }: IParams) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  logger().info("** Pre-fetching TPI iframe ** ");

  const {
    meeting: { callDetails, thirdPartyIntegration },
  } = getState();

  logger().logWithFields(
    LoggerLevels.info,
    {
      fileInfo: `preFetchIframeThunk`,
      feature: `ThirdPartyIntegrations`,
    },
    `Using thirdPartyIntegration list coming from BE: ${JSON.stringify(
      thirdPartyIntegration ?? "null"
    )}`
  );

  const integration = thirdPartyIntegration?.find(
    (tpi) => tpi.partner === integrationName
  );

  try {
    const {
      hospitalName,
      eventId,
      therapyName,
      procedureName,
    } = callDetails as MultiPartyEvent;

    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: `preFetchIframeThunk`,
        feature: `ThirdPartyIntegrations`,
      },
      `Integration to be pre-fetched: ${JSON.stringify(integration ?? "null")}`
    );

    // The "?" will prevent a bug in case we receive an empty array
    if (integration?.enabled) {
      await getPartnerIframe(procedureName, therapyName, eventId, hospitalName);
      dispatch(streamActions.setThirdPartyIntegrationAvailable(true));
      logger().info(
        `TPI iframe fetched successfully ${integrationName} is active.`
      );
    } else {
      // integration may be undefined, prevents error message
      logger().logWithFields(
        LoggerLevels.info,
        {
          fileInfo: `preFetchIframeThunk`,
          feature: `ThirdPartyIntegrations`,
        },
        `Integration has no 'enabled' key: ${JSON.stringify(
          integration ?? "null"
        )}`
      );
    }

    dispatch(meetingActions.updateThirdPartyIntegration(integration));
  } catch (error: any) {
    logger().error(
      `Error while pre-fetching TPI iframe, ${integrationName} will be disabled.`,
      error?.message
    );

    // Must disable the TPI in the Layouts in case the iframe doesn't load as expected
    dispatch(
      meetingActions.updateThirdPartyIntegration({
        ...integration,
        enabled: false,
      })
    );
  }
};
