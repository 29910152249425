import { call, cancel, put, select, takeLatest } from "redux-saga/effects";
import {
  getIdentityFailed,
  getIdentityRequest,
  getIdentitySuccess
} from "../actions";
import { UserActionKeys } from "../actionTypes";
import { API } from "../../../api";
import { logger } from "../../../common/logger";
import { AppState, UserState } from "portalcall/commoncall/store/models";

export function* getIdentitySaga() {
  yield put(getIdentityRequest());

  const { identity }: UserState = yield select(
    (state: AppState) => state.user
  );

  const { data, error } = yield call(API.POST.identity, identity.refresh_token);

  if (error) {
    yield put(getIdentityFailed());
    yield cancel();
    return;
  }

  logger().info("Identity obtained successfully.");
  yield put(getIdentitySuccess(data));
}

export function* watchGetIdentity() {
  yield takeLatest(UserActionKeys.GET_IDENTITY, getIdentitySaga);
}
