import { ImageActionKeys, ImageActionType } from "./actionTypes";

export const clearFreezeFrame = (): ImageActionType => ({
  type: ImageActionKeys.CLEAR_IMAGE_FREEZE
});

export const setImageFreeze = (frozen: boolean): ImageActionType => ({
  type: ImageActionKeys.SET_IMAGE_FREEZE,
  frozen
});

export const setFrozenFrame = (
  transferIdentifier: string
): ImageActionType => ({
  type: ImageActionKeys.SET_FROZEN_FRAME,
  transferIdentifier
});

export const unsetFrozenFrame = (): ImageActionType => ({
  type: ImageActionKeys.UNSET_FROZEN_FRAME
});

export const setLeftFrozenFrame = (
  transferIdentifier: string
): ImageActionType => ({
  type: ImageActionKeys.SET_LEFT_FROZEN_FRAME,
  transferIdentifier
});

export const unsetLeftFrozenFrame = (): ImageActionType => ({
  type: ImageActionKeys.UNSET_LEFT_FROZEN_FRAME
});

export const setRightFrozenFrame = (
  transferIdentifier: string
): ImageActionType => ({
  type: ImageActionKeys.SET_RIGHT_FROZEN_FRAME,
  transferIdentifier
});
export const unsetRightFrozenFrame = (): ImageActionType => ({
  type: ImageActionKeys.UNSET_RIGHT_FROZEN_FRAME
});

export const setFullscreenFrozenFrame = (
  transferIdentifier: string
): ImageActionType => ({
  type: ImageActionKeys.SET_FULLSCREEN_FROZEN_FRAME,
  transferIdentifier
});

export const unsetFullscreenFrozenFrame = (): ImageActionType => ({
  type: ImageActionKeys.UNSET_FULLSCREEN_FROZEN_FRAME
});

export const setFreezeFrameImage = (
  imageData: string,
  transferIdentifier: string
): ImageActionType => ({
  type: ImageActionKeys.SET_FREEZE_FRAME_IMAGE,
  data: imageData,
  transferIdentifier
});
