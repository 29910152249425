import { call, select, put, takeLatest } from "redux-saga/effects";
import { API } from "../../../api";
import { logger } from "../../../common/logger";
import {
  AppState,
  PortalIdentity,
  TwilioCredentials
} from "../../models";
import {
  setRefreshInProgress,
  updateAccessToken, } from "../../user/actions";
import {
  clearTwilioTracks,
  connectCall,
  getTwilioCredentialsSuccess
} from "../actions";
import { TwilioActionKeys } from "../actionTypes";

// export async function* refreshFramesSaga () {
export function* refreshFramesSaga () {
    setRefreshInProgress(true);
    logger().info("User requested refresh frames");
      const { twilio, user, meeting} = yield select((state: AppState) => state);

      const {room: twilioRoom} = twilio;
      const {identity:{access_token, refresh_token}} = user
      const {joinId, callSid} = meeting

    const { data, error } = yield call( API.POST.identity,refresh_token);
    if (error) {
      logger().error(
        `Error calling Identity API: ${error} with refreshToken: ${refresh_token}, accessToken: ${access_token}`
      );
      logger().error("Refresh frames will be skipped...");
    } else {
      const newIdentity: PortalIdentity = data!;
      logger().info(
        `Obtained new Access Token \n${newIdentity.access_token.substring(
          0,
          10
        )}...`
      );
      put(updateAccessToken(newIdentity));

      if (callSid && joinId) {

        const { data, error } = yield call( API.GET.refreshTwilioToken,
          newIdentity.access_token,
          callSid,
          joinId
        );

        if (error) {
          logger().error(
            "Error while refreshing twilio token using access_token:",
            newIdentity.access_token,
            newIdentity.refresh_token,
            access_token,
            refresh_token,
            JSON.stringify(error)
          );
        }

        if (twilioRoom && data) {
          const newTwilioCreds: TwilioCredentials = {
            token: data,
          };

          twilioRoom.disconnect();
          yield put(clearTwilioTracks());
          yield put  (connectCall());
          yield put(getTwilioCredentialsSuccess(newTwilioCreds));
        } else {
          logger().warn(
            "Refresh frames skipped, since twilio token wasn't refreshed."
          );
        }
      }
    }
}

export function* watchRefreshFrames() {
  yield takeLatest(TwilioActionKeys.REFRESH_FRAMES, refreshFramesSaga)
}