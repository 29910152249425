import React, { PureComponent } from 'react';
import { Table } from '../../../../Common';

import OrganisationUsersRow from './Components/OrganisationUsersRow/OrganisationUsersRow';


class OrganisationUsers extends PureComponent {
	render() {
		return (
			<div>
				<Table
					row={OrganisationUsersRow}
					tableData={this.props.organisationUsers}
					noEntityComponent={<div className="profile__right-content-wrapper">No users available</div>}
					tableActions={false}
					{...this.props}
				/>
			</div>
		);
	}
}

export default OrganisationUsers;
