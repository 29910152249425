import React from "react";

import clsx from "clsx";

import { LoginCard } from "./components/LoginCard";
import styles from "./styles.scss";

export interface LoginPageProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * TODO once we have new routes this will be the new LoginPage
 * we can move login routes up from web-app App.tsx into spa App.tsx
 */
export const LoginPage = ({ children, className }: LoginPageProps) => (
  <div data-test-id="login" className={clsx(styles.container, className)}>
    <LoginCard data-test-id="login-card">{children}</LoginCard>
  </div>
);
