import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { LayoutsSection } from "src/domains/Beacon/components/SidePanel/LayoutsPresetsContent/LayoutsSection";
import { PresetsSection } from "src/domains/Beacon/components/SidePanel/LayoutsPresetsContent/PresetsSection";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectStreamState } from "src/domains/Beacon/store/stream/selectors";
import { handleSidePanelCloseWhenLayoutsOrPresetsOpen } from "src/domains/Beacon/store/stream/thunks/handleSidePanelCloseWhenNoConsole";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";

import styles from "./styles.scss";

export interface LayoutsPresetContentProps {
  "data-test-id"?: string;
}

export const LayoutsPresetContent = () => {
  const dispatch = useDispatch();
  const { consoleHasJoinedRoom } = useAppSelector(selectTwilioState);
  const { console: consoleState } = useAppSelector(selectStreamState);

  useEffect(() => {
    const isConsoleVideoMuted =
      consoleHasJoinedRoom && consoleState.video === "mute";

    if (!isConsoleVideoMuted) {
      return;
    }
    // When Console's video is muted
    // close SidePanel and reset SidePanel Content
    dispatch(handleSidePanelCloseWhenLayoutsOrPresetsOpen());
  }, [consoleHasJoinedRoom, consoleState]);

  return (
    <div className={styles.root}>
      <div className={styles.layouts}>
        <LayoutsSection />
      </div>
      <div className={styles.presets}>
        <PresetsSection />
      </div>
    </div>
  );
};
