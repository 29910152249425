import { RemoteParticipant } from "twilio-video";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { handleParticipantConnectedThunk } from "src/domains/Beacon/store/twilio/thunks/handleParticipantConnectedThunk";

// Will detect when a remote participant connects to the Twilio room
export const useRoomParticipantConnected = () => {
  const { room } = useAppSelector(selectTwilioState);
  const dispatch = useDispatch();

  useEffect(() => {
    // Calling an action thunk to handle it due that they have the latest Redux state,
    // without having to modify the dependency array and remove/add the listener each time
    room.on("participantConnected", (participant: RemoteParticipant) =>
      dispatch(handleParticipantConnectedThunk(participant))
    );
  }, []);
};
