import { RootState, AppDispatch } from "src/domains/Beacon/store";
import { handleSetAudioOutputDeviceThunk } from "src/domains/Beacon/store/stream/thunks/handleSetAudioOutputDeviceThunk";
import { LoggerLevels, logger } from "src/logging/logger";

interface IParams {
  customSpeaker?: MediaDeviceInfo;
}

export const handleSetParticipantsAudioOutputThunk = ({
  customSpeaker,
}: IParams) => (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const {
      stream: {
        localMedia: { speaker },
        mpRemoteTracks: { participants },
      },
    } = getState();

    // Needed so we can receive a custom Speaker device because the `localMedia` device could be outdated
    const speakerExpected = customSpeaker ?? speaker;

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Media Devices",
        fileInfo: "handleSetParticipantsAudioOutputThunk.ts",
      },
      `Changing audio-output device for participants`
    );

    Object.keys(participants)?.map((loginId) => {
      const participantTrack = participants[loginId][1];
      // Must check that participant's audio track is not empty
      if (participantTrack) {
        const mediaElement = participantTrack.attach();

        logger().logWithFields(
          LoggerLevels.info,
          {
            feature: "Media Devices",
            fileInfo: "handleSetParticipantsAudioOutputThunk.ts",
          },
          `Changing audio-output device with id: ${speakerExpected.deviceId} for participant loginId: ${loginId}, track name is: ${participantTrack.name}`
        );

        dispatch(
          handleSetAudioOutputDeviceThunk({
            mediaElement,
            audioOutputDevice: speakerExpected,
          })
        );
      }
    });
  } catch (error: any) {
    logger().error(
      `Error while changing Browser's audio-output device for participants.`,
      error?.message
    );
  }
};
