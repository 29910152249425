/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Fields, Button, validators } from '../../../../Common/Components';
import { submitAuthAction, submitResendOTPAction, getMFAAuthDataAction } from '../../AuthenticationActions';
import withAuthentication from '../../Components/withAuthentication';

import { LoginPage } from "/src/domains/Login";
class MailMFAAuthentication extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	async componentDidMount() {
		await this.props.getMFAAuthData();
		if (this.props.otpInfo === null || this.props.otpInfo.mfaToken === null) {
			this.props.history.push('/login');
		}

	}

	handleMailAuthFormSubmit = (data) => {
		if (this.props.otpInfo.mfaToken !== null && this.props.otpInfo.mfaToken !== undefined) {
			const mfaData = {
				otp: data.otp,
				mfa_token: this.props.otpInfo.mfaToken,
			}
			this.props.submitMFAAuth(mfaData);
		} else {
			this.props.history.push('/login');
		}
	};

	redirect = () => this.props.history.push('/login');

	handleResendOTP = () => {
		if (this.props.otpInfo.mfaToken !== null && this.props.otpInfo.mfaToken !== undefined) {
			const mfaData = {
				mfa_token: this.props.otpInfo.mfaToken,
			}
			this.props.resendOTP(mfaData);
		} else {
			this.props.history.push('/login');
		}
	}

	render() {
		const { handleSubmit } = this.props;
		return (
			<LoginPage className="login">
        <div className="login__subheading">Two-factor authentication
        </div>
        <div className="login__resend" style={{ marginBottom: '30px' }}>
          A verification code has been sent to your email address, if <br />you have not received your email:&nbsp;
          <a onClick={this.handleResendOTP} className="login__sign-up-button" data-test-id="reset-otp-button">Resend</a>
        </div>
        <form className="login__form" onSubmit={handleSubmit(this.handleMailAuthFormSubmit)} data-test-id="otp-form" >
          <Fields.InputField
            labelText="Verification code"
            placeholder="Type your verification code"
            name="otp"
            type="text"
            validate={[validators.required, validators.onlyNumbers]}
          />
          <br />
          <div className="justify-content--flex-end">
            <Button
              isGhost
              className="login__button"
              onClick={this.redirect}
            >
              Cancel
            </Button>
            <Button
              isFull
              isGreen
              className="login__button"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
			</LoginPage>
		);
	}
}

MailMFAAuthentication.defaultProps = {
	otpInfo: {},
	getAllBrands: () => { },
	createNewBrand: () => { },
};

MailMFAAuthentication.propTypes = {
	otpInfo: PropTypes.object,
	submitMFAAuth: PropTypes.func,
	resendOTP: PropTypes.func,
	getMFAAuthData: PropTypes.func,
};

const mapStateToProps = state => ({
	otpInfo: state.authenticationReducer.otpInfo,
});

const mapDispatchToProps = dispatch => ({
	submitMFAAuth: MFAData => dispatch(submitAuthAction(MFAData)),
	resendOTP: MFAData => dispatch(submitResendOTPAction(MFAData)),
	getMFAAuthData: () => dispatch(getMFAAuthDataAction()),
});

export default reduxForm({
	form: 'GmailMFAAuthForm',
})(withAuthentication(withRouter(connect(mapStateToProps, mapDispatchToProps)(MailMFAAuthentication))));
