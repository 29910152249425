import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
/* Components */
import Consoles from '../../../../../../../Common/ConsolesTable/ConsolesTable';
/* Actions */
import { getConsolesByHospitalAction } from '../../../../../../Consoles/ConsolesActions';

class HospitalConsoles extends PureComponent {
	render() {
		return (
			<Consoles
				isHospital
				consoles={this.props.hospitalConsoles}
				hospitalId={this.props.hospitalId}
				getAllConsoles={payload => this.props.getConsolesByHospital(payload)}
			/>
		);
	}
}

const mapStateToProps = state => ({
	hospitalConsoles: state.consolesReducer.hospitalConsoles,
});

const mapDispatchToProps = dispatch => ({
	getConsolesByHospital: payload => dispatch(getConsolesByHospitalAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'consoleForm',
})(HospitalConsoles));
