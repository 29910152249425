// https://api.titan-dev.avail.io/presence/swagger-ui.html#/call-session-controller/getCallSessionUsingGET
import { Guid } from "guid-typescript";
import { renderTraceIdHeader } from "src/domains/Beacon/utils/renderTraceIdHeader";
import { logger, LoggerLevels } from "../common/logger";
import { ENDPOINTS } from "../constants";
import { PresenceServerSession } from "../store/models";
import { ApiResponse } from "./types";

export const callSessionBySessionId = async (
  callSessionId: string,
  access_token: string
): Promise<ApiResponse<PresenceServerSession>> => {
  const url = `${ENDPOINTS.DOMAIN}/presence/callSessions/${callSessionId}`;

  logger().info("Obtaining callsession details from Presence Service.");
  try {
    const opts = {
      method: "GET",
      headers: {
        "content-type": "application/json;charset=UTF-8",
        ...renderTraceIdHeader(),
        authorization: "Bearer " + access_token
      }
    };

    const res = await fetch(url, opts);
    if (!res.ok) {
      logger().error(
        "Obtaining callsession details from Presence Service. Failed."
      );
    }
    const response = await res.json();

    return { data: response.content };
  } catch (error) {
    return error;
  }
};

export const telestrationsBySessionId = async (
  callSessionId: string,
  access_token: string
  // TODO Get real types here
): Promise<ApiResponse<any>> => {
  const url = `${ENDPOINTS.DOMAIN}/presence/callSessions/${callSessionId}/telestrations`;

  logger().info("Obtaining Telestration details from Presence Service");
  try {
    const opts = {
      method: "GET",
      headers: {
        "content-type": "application/json;charset=UTF-8",
        ...renderTraceIdHeader(),
        authorization: "Bearer " + access_token
      }
    };

    const res = await fetch(url, opts);
    if (!res.ok) {
      logger().info(
        "Obtaining Telestration details from Presence Service. Failed."
      );
    }
    const response = await res.json();

    return { data: response.content };
  } catch (error) {
    return error;
  }
};

export async function uploadFreezeFrame(
  callSessionId: string,
  access_token: string,
  formData: any
) {
  const url = `${ENDPOINTS.DOMAIN}/presence/callSessions/${callSessionId}/freezeFrames`;
  logger().logWithFields(
    LoggerLevels.info,
    { feature: "FreezeFrame" },
    "Freeze Frame upload initiated"
  );

  try {
    const opts = {
      method: "POST",
      headers: {
        authorization: "Bearer " + access_token,
        Accept: "*/*"
        // allow browser to set content-type to add delimitter as needed
      },
      body: formData
    };

    const res = await fetch(url, opts);
    if (!res.ok) {
      throw new Error("Freeze Frame upload failed");
    }
    const response = await res.json();
    return { data: response.content };
  } catch (error) {
    logger().logWithFields(
      LoggerLevels.error,
      { feature: "FreezeFrame" },
      "Freeze Frame upload failed."
    );
    return { error };
  }
}

export async function removeFreezeFrameImageById(
  callSessionId: string,
  access_token: string,
  transferIdentifier: string
) {
  const url = `${ENDPOINTS.DOMAIN}/presence/callSessions/${callSessionId}/freezeFrames/${transferIdentifier}`;
  logger().logWithFields(
    LoggerLevels.info,
    { feature: "FreezeFrame" },
    "Freeze Frame remove initiated."
  );
  try {
    const opts = {
      method: "DELETE",
      headers: {
        authorization: "Bearer " + access_token
      }
    };

    const res = await fetch(url, opts);
    if (!res.ok) {
      logger().logWithFields(
        LoggerLevels.error,
        { feature: "FreezeFrame" },
        "Freeze Frame remove failed."
      );
    }
    const response = await res.json();
    return { data: response.content };
  } catch (error) {
    return error;
  }
}

export async function allFreezeFrameImages(
  callSessionId: string,
  access_token: string
) {
  const url = `${ENDPOINTS.DOMAIN}/presence/callSessions/${callSessionId}/freezeFrames`;

  logger().logWithFields(
    LoggerLevels.info,
    { feature: "FreezeFrame" },
    "Get all Freeze Frame images initiated."
  );
  try {
    const opts = {
      method: "GET",
      headers: {
        authorization: "Bearer " + access_token
      }
    };

    const res = await fetch(url, opts);
    if (!res.ok) {
      logger().logWithFields(
        LoggerLevels.error,
        { feature: "FreezeFrame" },
        "Get all Freeze Frame images failed."
      );
    }
    const response = await res.json();

    return { data: response.content };
  } catch (error) {
    return error;
  }
}

export async function freezeFrameImageById(
  callSessionId: string,
  access_token: string,
  transferIdentifier: Guid
) {
  const url = `${ENDPOINTS.DOMAIN}/presence/callSessions/${callSessionId}/freezeFrames/${transferIdentifier}/content`;
  logger().logWithFields(
    LoggerLevels.info,
    { feature: "FreezeFrame" },
    "Get Freeze Frame image by Id initiated : " + transferIdentifier
  );
  try {
    const opts = {
      method: "GET",
      headers: {
        authorization: "Bearer " + access_token
      }
    };

    const res = await fetch(url, opts);
    const resBlob = await res.blob();
    const objectURL = await resBlob.text();

    if (res.ok) {
      return { data: `data:image/jpeg;base64,${objectURL}`, error: null };
    } else {
      logger().logWithFields(
        LoggerLevels.error,
        { feature: "FreezeFrame" },
        "Get Freeze Frame image by Id failed : " + transferIdentifier
      );
      return { error: "error", data: "" };
    }
  } catch (error) {
    return { error, data: "" };
  }
}

export async function allExternalInputsById(
  callSessionId: string,
  access_token: string,
  consoleId: string,
) {
  const url = `${ENDPOINTS.DOMAIN}/presence/callSessions/${callSessionId}/consoles/${consoleId}/externalInputs`;
  logger().info("Obtaining all External Input details from Presence Service");

  try {
    const opts = {
      method: "GET",
      headers: {
        "content-type": "application/json;charset=UTF-8",
        ...renderTraceIdHeader(),
        authorization: "Bearer " + access_token
      }
    };

    const res = await fetch(url, opts);
    if (!res.ok) {
      logger().info(
        "Obtaining External Input details from Presence Service. Failed."
      );
    }
    const response = await res.json();

    return { data: response.content };
  } catch (error) {
    return { error, data: "" };
  }
}