import React from "react";

import newlogo2021 from "src/../public/newlogo2021.png";

import styles from "./styles.scss";

export interface LogoProps {
  "data-test-id"?: string;
}
/**
 * Logo component that should be used through out the app, TODO add logo icon variant also and size options (small/large)
 */
export const Logo = ({ "data-test-id": dataTestId }: LogoProps) => (
  <img
    data-test-id={dataTestId}
    className={styles.logo}
    src={newlogo2021}
    alt="loginLogo"
  />
);
