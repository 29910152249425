import {
  AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB,
  AV_FREEZE_FRAME_IMAGE_QUALITY,
  CONSOLE_VIDEO_ELEMENT_ID,
  DRAWER_WIDTH,
  FREEZE_FRAME_CANVAS_ID,
  FREEZE_FRAME_IMAGE_ID,
} from "src/domains/Beacon/constants";
import { logger } from "src/logging/logger";

export const getConsoleVideoElement = (): HTMLVideoElement => {
  const videoEl = document.getElementById(
    CONSOLE_VIDEO_ELEMENT_ID
  ) as HTMLVideoElement;
  return videoEl;
};

// Get the dimensions of the Console's video stream
export const getVideoDimensions = (): {
  width: number;
  height: number;
  offsetLeft: number;
  offsetTop: number;
  videoContainerWidth?: number;
  offsetWidth?: number;
  videoContainerHeight?: number;
  offsetHeight?: number;
} => {
  const videoEl = getConsoleVideoElement();
  const videoContainer = videoEl?.parentElement as HTMLDivElement;

  // offset = actual dimensions, video = original dimensions (default: 1280x720)
  const {
    offsetWidth,
    offsetHeight,
    videoWidth,
    videoHeight,
    offsetLeft,
    offsetTop,
  } = videoEl;

  const offsetRatio = offsetWidth / offsetHeight;
  const videoRatio = videoWidth === videoHeight ? 1 : videoWidth / videoHeight; // ternary prevents 0/0 = NaN

  let width = offsetWidth;
  let height = offsetHeight;

  // If the video element is short and wide (landscape)
  if (offsetRatio > videoRatio) {
    width = height * videoRatio;
  }
  // If the video element is tall and thin (portrait), or exactly equal to the original ratio
  else {
    height = width / videoRatio;
  }

  logger().info(
    `Getting new video dimensions ${JSON.stringify({ width, height })}`
  );

  return {
    width,
    height,
    offsetLeft,
    offsetTop,
    offsetWidth: (videoContainer?.clientWidth - width) / 2 || 0,
    offsetHeight: (videoContainer?.clientHeight - height) / 2 || 0,
  };
};

// Provides a means for configuring run time freeze frame compression ratio.
export const getImageQuality = (): number => {
  let imageQuality = AV_FREEZE_FRAME_IMAGE_QUALITY;
  const imageQualityString = localStorage.getItem(
    "AV_FREEZE_FRAME_IMAGE_QUALITY"
  );
  if (imageQualityString !== null) {
    try {
      imageQuality = parseFloat(imageQualityString);
      if (isNaN(imageQuality) || imageQuality < 0.1 || imageQuality > 1) {
        imageQuality = AV_FREEZE_FRAME_IMAGE_QUALITY;
      }
    } catch (e) {
      imageQuality = AV_FREEZE_FRAME_IMAGE_QUALITY;
    }
  }

  return imageQuality;
};

// Strip off the header data, `data:image/jpeg;base64,`.
export const getCanvasImageData = (canvas: HTMLCanvasElement): string => {
  const imageQuality = getImageQuality();
  const imageUrl = canvas.toDataURL("image/jpeg", imageQuality);
  return imageUrl;
};

// Make provision for configuring chunkSize for adaptive chunkSize in future
export const getChunkSize = (): number => {
  let chunkSize: number = AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB;
  const chunkSizeString = localStorage.getItem(
    "AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB"
  );
  if (chunkSizeString !== null) {
    try {
      chunkSize = parseInt(chunkSizeString, 10);

      /* Support 1kb to 30kb uploads */
      if (isNaN(chunkSize) || chunkSize > 30 || chunkSize < 1) {
        chunkSize = AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB;
      }
    } catch (e) {
      chunkSize = AV_FREEZE_FRAME_CHUNK_SIZE_IN_KB;
    }
  }
  return chunkSize;
};

// Removes the canvasEl from its parent
// The canvas could be an image for the participants or a CanvasEl for the Host
export const clearFreezeFrameCanvas = () => {
  const canvasEl = document.getElementById(
    FREEZE_FRAME_CANVAS_ID
  ) as HTMLCanvasElement;
  const context = canvasEl?.getContext("2d");
  context?.clearRect(0, 0, canvasEl.width, canvasEl.height);
};

// Removes the img tag for participants if Freeze Frame was cleared, it happens
// in the useOnReceivedClearFreezeFrame event for participants
export const clearFreezeFrameImage = () => {
  const imgEl = document.getElementById(
    FREEZE_FRAME_IMAGE_ID
  ) as HTMLImageElement;
  imgEl.src = "";
  // Must hide the image tag, otherwise a broken image will appear
  imgEl.style.display = "none";
};

// Drawing the video inside a created canvas and returning the canvas
export const captureVideoOnCanvas = (): HTMLCanvasElement => {
  const videoEl = getConsoleVideoElement();

  const capturedImageCanvas = document.getElementById(
    FREEZE_FRAME_CANVAS_ID
  ) as HTMLCanvasElement;

  const videoArea = getVideoDimensions(); // actual video bounds

  const { offsetWidth, offsetHeight } = videoEl;

  // Make sure the imageData Canvas is under Telestration Canvas
  const videoWidth = videoArea.width;
  const videoHeight = videoArea.height;
  const videoLeft = (offsetWidth - videoArea.width) / 2;
  const videoTop = (offsetHeight - videoArea.height) / 2;

  // Initialize dimensions for extracting from source image
  const sourceImageX = 0;
  const sourceImageY = 0;
  const sourceImageWidth = videoEl.videoWidth;
  const sourceImageHeight = videoEl.videoHeight;

  // Creation of the context in order to capture the video
  const context = capturedImageCanvas.getContext("2d");

  capturedImageCanvas.width = videoWidth;
  capturedImageCanvas.height = videoHeight;
  capturedImageCanvas.style.position = "absolute";
  capturedImageCanvas.style.top = videoTop + "px";
  capturedImageCanvas.style.left = videoLeft + "px";

  // Drawing the video inside the Canvas context
  context.drawImage(
    videoEl,
    sourceImageX,
    sourceImageY,
    sourceImageWidth,
    sourceImageHeight,
    0,
    0,
    capturedImageCanvas.width,
    capturedImageCanvas.height
  );

  return capturedImageCanvas;
};

// Calculates the size of the image freeze frame coming from Presence server
// in order to draw it inside the Canvas
export const mapSizings = (videoContainer: HTMLElement, bmImage: any): any => {
  const imageW = bmImage.width;
  const imageH = bmImage.height;
  const screen = videoContainer.getBoundingClientRect();

  const scaleW = screen.width / imageW;
  const scaleH = screen.height / imageH;
  let fittedW = null;
  let fittedH = null;
  let offsetL = "";
  let offsetT = "";

  if (scaleH <= scaleW) {
    // Scaling up is limited by the height of image
    fittedW = "auto"; // (imageW * scaleH)*100/imageW + "%";
    fittedH = "100%";
    offsetL =
      ((screen.width - scaleH * imageW) * 100) / (2 * screen.width) + "%";
    offsetT = "0";
  } else {
    // Scaling up is limited by the width of image
    fittedW = "100%";
    fittedH = "auto"; // (imageH * scaleW)*100/imageH + "%";
    offsetL = "0";
    offsetT =
      ((screen.height - scaleW * imageH) * 100) / (2 * screen.height) + "%";
  }
  return { fittedW, fittedH, offsetL, offsetT };
};

// draws the image freeze frame coming from Presence Server, this is executed
// once the user has refreshed the page and console has already joined
export const drawPresenceFreezeFrameImage = (imageUrl: string) => {
  const videoArea = getVideoDimensions(); // actual video bounds

  const imgEl = document.getElementById(
    FREEZE_FRAME_IMAGE_ID
  ) as HTMLImageElement;

  // Must set the image properties to show it for either host or participant
  imgEl.src = imageUrl;
  imgEl.style.display = "block";
  imgEl.style.height = `${videoArea.height}px`;
};

// Calculates the position of the video in the screen, with sidePanel open/close
export const getVideoPositioning = (
  isSidePanelOpen: boolean
): {
  height: number;
  width: number;
  top: number;
  left: number;
  multiPaneHeight: number;
  multiPaneWidth: number;
  multiPaneMiddle: number;
} => {
  const videoArea = getVideoDimensions();
  const height = videoArea.height;
  const width = videoArea.width;
  const multiPaneHeight = height / 2;
  const multiPaneWidth = width / 2;
  // Must divide into 2 due that the video is positioned at the middle of the window
  // with this we are just getting the gap between the video and the window from the top,
  // not from both top and bottom
  const top = (window.innerHeight - videoArea.height) / 2;
  const multiPaneMiddle = top / 2;
  const left =
    (window.innerWidth -
      videoArea.width -
      (isSidePanelOpen ? DRAWER_WIDTH : 0)) /
    2;

  return {
    height,
    width,
    top,
    left,
    multiPaneHeight,
    multiPaneWidth,
    multiPaneMiddle,
  };
};
