import React from 'react';
import {roles} from '../../../../../../Common/Constants/userTypes';
import {PaginationComponent} from "../../../index";
import {Button} from "../../../../../../Common/Components";
import Can from "../../../../../../Common/Acl/AccessControl";
import classNames from "classnames";
import VariablePaginationComponent from '../../../VariablePaginationComponent/VariablePaginationComponent';
import "./TableFooter.scss";

export default function TableFooter(
	{
		cdrExport,
		onCdrExportHandler,
		onAddCdrExportTitle,
		onExportHandler,
		variablePagination,
		onVariablePaginationChange,
		exportUsersData,
		onAddExportTitle,
		permissions,
		consoleData,
		currentUser,
		paginationConfig,
		onPageChangeHandler,
	}
) {
	
	const renderExportButton = () => {
		const addNewComponent = (
			<Button
				onClick={onExportHandler}
				isFull
				buttonSmall
			>
				{onAddExportTitle}
			</Button>
		);
		if (permissions) {
			return (
				<Can {...permissions}>
					{addNewComponent}
				</Can>
			);
		}
		return addNewComponent;
	};
	
	const renderCdrExportButton = () => {
		const addNewComponent = (
			<Button
				onClick={onCdrExportHandler}
				isFull
				buttonSmall
			>
				{onAddCdrExportTitle}
			</Button>
		);
		if (permissions) {
			return (
				<Can {...permissions}>
					{addNewComponent}
				</Can>
			);
		}
		return addNewComponent;
	};

	return (

		<div className={classNames({
			'width--full': exportUsersData || paginationConfig || variablePagination,
			'justify-content--space-between align--center': paginationConfig,
			'table-footer__container': true,
		})}>
			<div className={classNames({
				'width--full justify-content--center align--center': !cdrExport && (exportUsersData || variablePagination || paginationConfig),
			})}>
				{
					paginationConfig &&
					<PaginationComponent paginationConfig={paginationConfig} onPageChangeHandler={onPageChangeHandler}/>
				}
			</div>

			<div className="table-footer__stub">
				<div className={classNames({
					'position--relative ': variablePagination || exportUsersData && variablePagination,
					'justify-content--space-between align-center': true,
					'variable-pagination': true,
				})}>
					{
						variablePagination &&
						<VariablePaginationComponent onVariablePaginationChange={onVariablePaginationChange}/>
					}
				</div>

				<div className={classNames({
					'position--relative': exportUsersData && roles.availAdmin,
					'table-footer__button': true,
				})}>
					{
						cdrExport && onCdrExportHandler && renderCdrExportButton()
					}
					{
						exportUsersData &&
						(consoleData && currentUser && currentUser.userTypes[0] === roles.availAdmin || !consoleData) &&
						onExportHandler && renderExportButton()
					}
				</div>
			</div>
		</div>
	);
}
