import userTypes, { roles } from '../Constants/userTypes';

export default class UserService {
	static checkIsHospitalUser = userType => userTypes[userType] && userTypes[userType].title === 'Facility Administrator';

	static checkIsVendorManager = userType => userTypes[userType] && userTypes[userType].title === 'Vendor Manager';

	static checkIsVendorSalesRep = userType => userTypes[userType] && userTypes[userType].title === 'Vendor Field Representative';

	static checkIsVendorAdmin = userType => userTypes[userType] && userTypes[userType].title === 'Vendor Administrator';

	static getUserInitials = (firstName, lastName) => `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase();
  
  static checkIsGuestUser = (userType) => !!userTypes[userType] && (userTypes[userType].title.toLowerCase() === 'guest');

	static checkIsVendorUser = (userType) => {
		const userTypeTitle = userTypes[userType] && (userTypes[userType].title || null);
		return (userTypeTitle === 'Vendor Administrator')
			|| (userTypeTitle === 'Vendor Manager')
			|| (userTypeTitle === 'Vendor Field Representative'); // TODO: Will need refactoring
	};

	static checkIsProctorOrSpecialist = selectedUserTypes => (
		(selectedUserTypes.includes('PROCTOR'))
		|| (selectedUserTypes.includes('SPECIALIST')
		|| (selectedUserTypes.includes('AVAIL_ADMIN')))
	);

	static checkIsIndividualSalesRep = selectedUserTypes => (
		selectedUserTypes && selectedUserTypes.includes(roles.individualSalesRep)
	);

	static filterHospitalUserTypes = users => users.filter(user => user.name === 'HOSPITAL_ADMIN');
}

