// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root_tfM0ke{padding:24px 16px}.styles_root_tfM0ke .styles_head_KGpVFK{margin-bottom:16px}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/SidePanel/SettingsContent/ConsoleAudioSettings/styles.scss"],"names":[],"mappings":"AAAA,oBACE,iBAAA,CAEA,wCACE,kBAAA","sourcesContent":[".root {\n  padding: 24px 16px;\n\n  .head {\n    margin-bottom: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root_tfM0ke",
	"head": "styles_head_KGpVFK"
};
export default ___CSS_LOADER_EXPORT___;
