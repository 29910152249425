import { CONSOLES } from './ConsolesActionTypes';

const initialState = {
	consoles: null,
	pagination: {
		page: 0,
		size: 10,
		totalPages: 1,
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case CONSOLES.SET_ALL_CONSOLES:
			return {
				...state,
				consoles: action.payload,
			};
		case CONSOLES.SET_CONSOLES_PAGINATION:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload.pageNumber,
					size: action.payload.pageSize,
					totalPages: action.payload.totalPages,
				},
			};
		case CONSOLES.SET_CONSOLE:
			return {
				...state,
				console: action.payload,
			};
		case CONSOLES.SET_CONSOLES_BY_HOSPITAL:
			return {
				...state,
				hospitalConsoles: action.payload,
			};
		case CONSOLES.SELECT_CONSOLES:
			return {
				...state,
				selectedConsoles: action.payload,
			}
		default:
			return state;
	}
}
