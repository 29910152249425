import moment from "moment";

import { RefreshTokenResponse } from "src/services/ApiClient/general/types";
import {
  BASE_API_URL,
  CORE_REGISTRY_API_URL,
} from "src/services/ApiClient/utils";
import UserSessionService from "src/services/UserSessionService";

import {
  GeneralNetworksResponse,
  GeneralStatesResponse,
  GeneralTherapiesResponse,
} from ".";
import { getQueryString } from "../../../utils/ApiHelper";
import ApiQuery from "../ApiQuery";

export const getAllStates = () =>
  ApiQuery<GeneralStatesResponse>(`${CORE_REGISTRY_API_URL}/address/states`);

export const getAllTherapies = () =>
  ApiQuery<GeneralTherapiesResponse>(`${CORE_REGISTRY_API_URL}/therapies`);

export const getAllNetworks = () =>
  ApiQuery<GeneralNetworksResponse>(
    `${CORE_REGISTRY_API_URL}/hospital-networks`
  );

export const getCallHistoryList = (
  page = 0,
  size = 10,
  sort = "",
  extraParam = "",
  from = moment().subtract(6, "month").format(),
  to = moment().format()
) => {
  return ApiQuery(
    `${BASE_API_URL}/callevent/history${getQueryString({
      page,
      size,
      sort,
      extraParam,
      from,
      to,
    })}`
  );
};

// Gets user details info based on refresh token
export const getRefreshToken = () => {
  const refreshToken = UserSessionService.getRefreshToken();

  return ApiQuery<RefreshTokenResponse, { token: string }>(
    `${BASE_API_URL}/auth/token/refresh`,
    "POST",
    { token: refreshToken }
  );
};
