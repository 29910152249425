// Will load all of the video/audio tracks of the participants just once the
import { RemoteParticipant, RemoteTrackPublication } from "twilio-video";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { twilioActions } from "src/domains/Beacon/store/twilio/twilioSlice";
import { uiActions } from "src/domains/Beacon/store/ui";
import { generateBadge, UIBadgeTypes } from "src/domains/Beacon/store/ui/types";
import {
  isConsoleParticipant,
  whoPublishedTrack,
} from "src/domains/Beacon/utils/twilio";
import { logger, LoggerLevels } from "src/logging/logger";

// Must update local user's state with the console & host's audio/video state.
// Must be only once when user joins the call, the we let it know if console is
// audio/video muted, host has joined and should resume pip video
export const setParticipantsTracksThunk = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  { participants: RemoteParticipant[] },
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "twilio/setParticipantsTracks",
  ({ participants }, { getState, dispatch }) => {
    try {
      logger().info(
        "Setting participants tracks (connecting to twilio or participant connects)"
      );
      const { meeting } = getState();
      const { mode, callDetails } = meeting;

      // Updates local state if participant is the Host and has already joined the call
      const setHostState = () => {
        dispatch(twilioActions.setHostHasJoinedRoom(true));
        dispatch(uiActions.resumePipVideo());
      };

      // Updates local state if participant is the Console and has already joined the call,
      // we update if the audio/video are muted/unmuted
      const setConsoleState = (publication: RemoteTrackPublication) => {
        dispatch(twilioActions.setConsoleHasJoinedRoom(true));
        const isTrackEnabled = publication.isTrackEnabled;
        logger().info(
          `Console's ${publication?.kind} is ${
            isTrackEnabled ? "enabled" : "disabled"
          }`
        );
        dispatch(
          streamActions.setConsoleAudioVideo({
            [publication.kind]: isTrackEnabled ? "unmute" : "mute",
          })
        );
        if (publication.kind === "audio" && !isTrackEnabled) {
          const badge = generateBadge(UIBadgeTypes.CONSOLE_AUDIO_MUTED);
          dispatch(uiActions.addBadge(badge));
        }
      };

      // Must map the participants when joining the call, in order to know the status
      // of the host & console for their video/audio state
      participants.forEach((participant) => {
        const publicationTracks = participant.tracks;
        publicationTracks.forEach((publication) => {
          logger().info(
            `Setting participant's track ${publication?.trackName}`
          );
          if (mode === CallModes.MP) {
            whoPublishedTrack({
              participant,
              publication,
              callDetails,
              wasHost: () => setHostState(),
              wasConsole: () => setConsoleState(publication),
              // TODO: Set default state per participant, in order to know if one of them
              // is audio muted when joining the call
              wasParticipant: () => null,
            });
          } else if (mode === CallModes.P2P) {
            const isConsole = isConsoleParticipant(participant);
            if (isConsole) {
              logger().info(
                "Setting Console's video and audio tracks for the first time"
              );
              setConsoleState(publication);
            } else {
              setHostState();
            }
          }
        });
      });

      logger().logWithFields(
        LoggerLevels.info,
        { feature: "setParticipantsTracks" },
        `Setting participants tracks (connecting to twilio or participant connects)`
      );
    } catch (error: any) {
      logger().error(
        "Error while setting up participants tracks",
        error?.message
      );
      throw new Error("Error while setting up participants tracks.");
    }
  }
);
