import { BaseUser } from "src/services/ApiClient/users";
import { UserTypes } from "src/services/ApiClient/users";

export const isTrialUser = (currentUser: BaseUser) =>
  currentUser.memberType === "TRIAL" ||
  currentUser.memberType === "TRIAL_EXPIRED";

export const isAvailAdminUser = (userTypes: UserTypes[]) =>
  userTypes.includes("AVAIL_ADMIN");

export const getUserInitials = (firstName: string, lastName: string) =>
  `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase();
