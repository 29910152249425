import { APPROVAL_LIST } from './ApprovalListActionTypes';

export const getAllUserApplicationsAction = payload => ({
	type: APPROVAL_LIST.GET_ALL_USER_APPLICATIONS,
	payload,
});

export const setUserStatusAction = (hospitalId, userId, userStatus, organizationType) => ({
	type: APPROVAL_LIST.SET_USER_STATUS,
	hospitalId,
	userId,
	userStatus,
	organizationType,
});
