import { useEffect } from "react";

/**
 * It handles the removal of the listener when component unmounts protecting
 * from future memory leaks
 */
export const useEventListener = (
  name,
  callback,
  element: Window | HTMLElement | any = window,
  dependency?: any
) => {
  useEffect(() => {
    element?.addEventListener(name, callback);
    return () => element?.removeEventListener(name, callback);
  }, [name, element, dependency]);
};
