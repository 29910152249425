/** Popover Component
 * The main export here is Popover, where you pass children to be wrapped.
 * On a click, the PopoverBase will appear.
 * Content-wise, the PopoverBase is pre-set with a standard title, description, and
 * footer (as a ReactNode, optional) layout. Other Popovers can be created to match other layouts
 * as the needs/designs arise.
 *
 * You can also configure the Popover to: (handled via CSS)
 *    - appear above or below the children.
 *    - "point" from the center, left, or right of the adjacent Popover side.
 *    - show the Arrow pointer at the given pointing position.
 */
import React from "react";

import clsx from "clsx";

import {
  Popover as MUIPopover,
  PopoverProps as MUIPopoverProps,
} from "@mui/material";

import { Font } from "src/components/Font";

import styles from "./styles.scss";

interface PopoverBaseProps extends MUIPopoverProps {
  "data-test-id"?: string;
  className?: string;
  contentProps: {
    title: string;
    description: string;
    footer?: React.ReactNode;
  };
  popoverPosition?: "top" | "bottom";
  pointingPosition?: "left" | "right" | "center";
  showArrow?: boolean;
}

const PopoverBase = ({
  className,
  popoverPosition = "top",
  pointingPosition = "left",
  showArrow = true,
  contentProps,
  "data-test-id": dataTestId,
  ...props
}: PopoverBaseProps) => {
  return (
    <MUIPopover
      // MUI Paper element that is the parent to the Popover
      PaperProps={{
        classes: { root: clsx(styles.paper) },
      }}
      // location on the anchorElement that Popover positions itself relative to
      anchorOrigin={{
        vertical: popoverPosition,
        horizontal: "center",
      }}
      // part of Popover touching anchorOrigin
      transformOrigin={{
        vertical: popoverPosition === "bottom" ? "top" : "bottom",
        horizontal: pointingPosition,
      }}
      {...props}
    >
      <div
        className={clsx(
          styles.wrapper,
          styles.root,
          styles[`position--${popoverPosition}--${pointingPosition}`]
        )}
        data-test-id={dataTestId}
        role="popover"
      >
        <Arrow
          showArrow={showArrow}
          horizontal={pointingPosition}
          vertical={popoverPosition === "bottom" ? "top" : "bottom"}
        />

        {/* START body for a Popover */}
        <Font variant="h3" color="light" className={clsx(styles.title)}>
          {contentProps.title}
        </Font>
        <Font variant="b2" color="light">
          {contentProps.description}
        </Font>
        {contentProps.footer && (
          <div className={clsx(styles.footer)}>{contentProps.footer}</div>
        )}
        {/* END body for a Popover */}
      </div>
    </MUIPopover>
  );
};

interface ArrowProps {
  showArrow: boolean;
  vertical: "top" | "bottom";
  horizontal: "left" | "right" | "center";
  "data-test-id"?: string;
}

// Arrow pointing from top or bottom of the Popover
const Arrow = ({
  showArrow,
  vertical,
  horizontal,
  "data-test-id": dataTestId,
}: ArrowProps) => {
  return (
    <div
      className={clsx(
        styles.arrow,
        showArrow ? styles.showArrow : null,
        styles[`vertical--${vertical}`],
        styles[`horizontal--${horizontal}`]
      )}
      data-test-id={dataTestId ? `${dataTestId}-arrow` : null}
      role="popover-arrow"
    />
  );
};

export interface PopoverProps {
  contentProps: {
    title: string;
    description: string;
    footer?: React.ReactNode;
  };
  children: React.ReactNode; // the element to receive a Popover
  popoverPosition?: "top" | "bottom";
  pointingPosition?: "left" | "right" | "center";
  showArrow?: boolean;
  "data-test-id"?: string;
  open?: boolean; // for a controlled open/hide state
  onClose?: () => void; // for a controlled open/hide state when clicking outside the Popover
}

// wrapper that lets you pass a child to open a popover for
export const Popover = ({
  children,
  contentProps,
  pointingPosition = "left", // points from the left side of the Popover
  popoverPosition = "top", // Popover appears above children
  showArrow = true,
  "data-test-id": dataTestId,
  ...props
}: PopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  return (
    <span className="popover-wrapper">
      <span
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {children}
      </span>
      <PopoverBase
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        contentProps={contentProps}
        showArrow={showArrow}
        pointingPosition={pointingPosition}
        popoverPosition={popoverPosition}
        data-test-id={dataTestId}
        {...props}
      />
    </span>
  );
};
