// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root_YP3hwn.styles_notification_NOh\\+Ql{pointer-events:none}.styles_root_YP3hwn.styles_default_TtOrhQ{top:84px}", "",{"version":3,"sources":["webpack://./src/components/NotificationSystem/styles.scss"],"names":[],"mappings":"AACE,gDACE,mBAAA,CAIF,0CACE,QAAA","sourcesContent":[".root {\n  &.notification {\n    pointer-events: none;\n\n  }\n\n  &.default {\n    top: 84px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root_YP3hwn",
	"notification": "styles_notification_NOh+Ql",
	"default": "styles_default_TtOrhQ"
};
export default ___CSS_LOADER_EXPORT___;
