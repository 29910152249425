import React from 'react';

export const useTimeout = (callback, delay) => {
	const savedCallback = React.useRef();

	React.useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	React.useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setTimeout(tick, delay);
			return () => clearTimeout(id);
		}
	}, [delay]);
};
