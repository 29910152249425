import {
  UserCallHistoryResponse,
  TrialUsageResponse,
} from "src/services/ApiClient/trialUsage";

import ApiQuery from "../ApiQuery";
import { BASE_API_URL } from "../utils";

export const getTrialUsageByUserId = (userId: string) => {
  return ApiQuery<TrialUsageResponse>(
    `${BASE_API_URL}/billing/users/${userId}/balance-report`
  );
};

export const getUserCallHistoryByUserId = (userId: string, page: number) => {
  return ApiQuery<UserCallHistoryResponse>(
    `${BASE_API_URL}/billing/users/${userId}/calls-with-duration?page=${page}&size=10`
  );
};
