/*
 * Coreregistry users:
 * User users: https://api.titan-dev.avail.io/user/swagger-ui.html#/user-controller
 */
import { renderTraceIdHeader } from "src/domains/Beacon/utils/renderTraceIdHeader";

import {
  UserListResponse,
  UserDetailResponse,
  UserGuestRoleResponse,
  CreateUserRequest,
  UserOrganizationResponse,
  UserDetailsFromEmailResponse,
  ChangePassword,
  UserSettings,
  UserPayload,
  UserManagerResponse,
  TimeZonesResponse,
  UserSettingsResponse,
  UsersListResponse,
} from ".";
import { getQueryString } from "../../../utils/ApiHelper";
import ApiQuery, { API_MESSAGE_5XX } from "../ApiQuery";
import {
  BusinessUnit,
  Facility,
  HospitalsResponse,
  ProceduresResponse,
  TherapiesResponse,
} from "../businessUnit";
import { USER_API_URL, CORE_REGISTRY_API_URL, BASE_API_URL } from "../utils";

export const getUserList = (
  page = 0,
  size: number | "all" = 10,
  sort = "",
  extraParam = ""
) => {
  return ApiQuery<UserListResponse>(
    `${CORE_REGISTRY_API_URL}/users${getQueryString({
      page,
      size,
      sort,
      extraParam,
    })}`
  );
};

// TODO properly type return types for all functions below

export const getHospitalApplicationsForUser = (
  userId: string,
  page = 0,
  size: number | "all" = 10,
  sort = "",
  extraParam = ""
) => {
  return ApiQuery<any>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/hospital-applications${getQueryString(
      {
        page,
        size,
        sort,
        extraParam,
      }
    )}`
  );
};

export const getUserById = (userId: string) => {
  return ApiQuery<UserDetailResponse>(`${USER_API_URL}/users/${userId}`);
};

export const getUserByEmail = (email: string) => {
  return ApiQuery<UserDetailsFromEmailResponse>(
    `${USER_API_URL}/logins/info/${email}`
  );
};

// endpoint used for autocomplete to find all users in the env
// example:
// https://api.titan-dev.avail.io/user/logins/searchWithOrOperator?email=kra&firstname=kra&lastname=kra
export const getUsersForAutocomplete = (text: string) => {
  return ApiQuery<UsersListResponse>(
    `${USER_API_URL}/logins/searchWithOrOperator?email=${text}&firstname=${text}&lastname=${text}`
  );
};

export const createUser = (payload: CreateUserRequest) => {
  return ApiQuery<UserDetailResponse, CreateUserRequest>(
    `${USER_API_URL}/users`,
    "POST",
    payload
  );
};

export const getUserOrg = (userId: string) => {
  return ApiQuery<UserOrganizationResponse>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/employed`
  );
};

export const getUserManager = (userId: string) => {
  return ApiQuery<UserManagerResponse>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/manager`
  );
};

export const getUserProcedures = (userId: string) => {
  return ApiQuery<ProceduresResponse>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/procedures`
  );
};

export const getUserHospitals = (userId: string) => {
  return ApiQuery<HospitalsResponse>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/available-hospitals`
  );
};

export const getUserTherapies = (userId: string) => {
  return ApiQuery<TherapiesResponse>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/available-therapies`
  );
};

export const getTimeZones = () => {
  return ApiQuery<TimeZonesResponse>(`${USER_API_URL}/settings/timezones`);
};

export const updateUser = (userId: string, payload: UserPayload) => {
  return ApiQuery<any, any>(`${USER_API_URL}/users/${userId}`, "PUT", payload);
};

export const updateUserSettings = (userId: string, payload: UserSettings) => {
  return ApiQuery<any, any>(
    `${USER_API_URL}/settings/${userId}`,
    "PUT",
    payload
  );
};

export const changePassword = (userId: string, payload: ChangePassword) => {
  return ApiQuery<any, any>(
    `${USER_API_URL}/accounts/${userId}/changePassword`,
    "PUT",
    payload
  );
};

export const updateUserHospital = (
  userId: string,
  hospitalId: string,
  payload: { blocked: boolean }
) => {
  return ApiQuery<any, any>(
    `${CORE_REGISTRY_API_URL}/users/${userId}/hospital/${hospitalId}/support`,
    "PUT",
    payload
  );
};

export const deleteUser = (userId: string) => {
  return ApiQuery(`${USER_API_URL}/users/${userId}`, "DELETE");
};

export const inviteNewUser = (bodyPayload) => {
  return ApiQuery(`${USER_API_URL}/users`, "POST", bodyPayload);
};

export const getAllUserGroups = () => {
  return ApiQuery(`${USER_API_URL}/users/usergroups`);
};

export const getGuestUserRoles = () => {
  return ApiQuery<UserGuestRoleResponse>(`${USER_API_URL}/users/titles`);
};

export const getUserDetail = (userId) => {
  return ApiQuery(`${USER_API_URL}/users/${userId}`);
};

export const getUserSettings = (userId) => {
  return ApiQuery<UserSettingsResponse>(`${USER_API_URL}/settings/${userId}`);
};

export const getUserSchedules = (userId) => {
  return ApiQuery(`${BASE_API_URL}/scheduler/schedules/users/${userId}`);
};

export const getUserMeetings = (userId, from, to) =>
  ApiQuery(
    `${BASE_API_URL}/callevent/history/users/${userId}${getQueryString({
      from,
      to,
    })}`
  );

export const postResetPw = (data, email) => {
  return ApiQuery(
    `${BASE_API_URL}/user/accounts/resetPassword/email/${email}`,
    "POST",
    data
  );
};

export const postSignup = (email, firstName, lastName, payload) => {
  const body = JSON.stringify({
    email,
    firstName,
    lastName,
    orgId: email,
    userType: "INDIVIDUAL_SALES_REPRESENTATIVE",
  });

  return ApiQuery(`${USER_API_URL}/sign-up`, "POST", body, payload);
};

export const putUpdatePw = (email, password, token) => {
  return ApiQuery(
    `${USER_API_URL}/accounts/changePassword/email/${email}`,
    "PUT",
    JSON.stringify({
      confirmPassword: password,
      email,
      password,
    }),
    { Authorization: `bearer ${token}` }
  );
};

export const upgradeTrialUser = (userId: string, payload) => {
  return ApiQuery(
    `${USER_API_URL}/users/${userId}/member-type`,
    "PUT",
    payload
  );
};

export const reinviteUser = (userId: string) => {
  return ApiQuery(
    `${USER_API_URL}/users/${userId}/resendActivationEmail`,
    "POST",
    {}
  );
};

export const updateUserDetail = (userId: string, payload) => {
  return ApiQuery(`${USER_API_URL}/users/${userId}`, "PUT", payload);
};

export const updateUserProcedures = (userId: string, payload) => {
  return ApiQuery(
    `${CORE_REGISTRY_API_URL}/users/${userId}/procedures`,
    "PUT",
    payload
  );
};

// Functions for signing up for trial APIs
// since they are public APIs (meaning they dont need an auth token)
// we have them here without using the core ApiQuery helper

// Tiny helper function for using fetch
const fetchRequest = async (url: string, method: string, body?: any) => {
  try {
    const opts = {
      method,
      headers: {
        "content-type": "application/json;charset=UTF-8",
        ...renderTraceIdHeader(),
        // TODO headers: cors? recaptcha?
        // "X-AVAIL-CAPTCHA-TOKEN": "asdf",
      },
      body: body ? JSON.stringify(body) : undefined,
    };

    const response = await fetch(url, opts);

    // throw an error if we have a server error
    if (response.status >= 500) {
      throw new Error(API_MESSAGE_5XX);
    }

    // 4xx errors have to be handled by the component using these API functions currently
    return { response };
  } catch (error) {
    return { error };
  }
};

export const checkIfTrialAvailibleForOrganization = async (
  email: string
): Promise<any> => {
  const url = `${USER_API_URL}/sign-up?id=${email}`;

  const { response, error } = await fetchRequest(url, "GET");

  return {
    response,
    // weird issue from BE, they only give us data when we have an error message
    data: response.ok ? null : await response.json(),
    error,
  };
};

export const getEmployerBusinessUnitsForTrial = async (
  email: string,
  filter: string
): Promise<BusinessUnit | []> => {
  const url = `${USER_API_URL}/sign-up/businesses?email=${email}&filter=${filter}`;

  const { response, error } = await fetchRequest(url, "GET");
  if (error) {
    return [];
  }
  const data: any = await response.json();

  if (data?.content) {
    return data.content;
  } else {
    return [];
  }
};

export const getEmployerFacilitiesForTrial = async (
  email: string,
  filter: string
): Promise<Facility | []> => {
  const url = `${USER_API_URL}/sign-up/facilities?email=${email}&filter=${filter}`;

  const { response, error } = await fetchRequest(url, "GET");

  if (error) {
    return [];
  }
  const data: any = await response.json();

  if (data?.content) {
    return data.content;
  } else {
    return [];
  }
};

export const getStatesForTrial = async (
  email: string,
  filter: string
): Promise<any> => {
  const url = `${USER_API_URL}/sign-up/states?email=${email}&filter=${filter}`;

  const { response, error } = await fetchRequest(url, "GET");

  if (error) {
    return [];
  }
  const data: any = await response.json();

  if (data?.content) {
    return data.content.states;
  } else {
    return [];
  }
};

export const userTrialSignUp = async (postBody: {
  email: string;
  firstName: string;
  lastName: string;
  businessUnitId: string;
  state: string;
  facilties: string[];
  marketingNotify: boolean;
}): Promise<any> => {
  const url = `${USER_API_URL}/sign-up`;

  // map args to BE defined keys
  const body = {
    email: postBody.email,
    firstName: postBody.firstName,
    lastName: postBody.lastName,
    organizationId: postBody.businessUnitId,
    state: postBody.state,
    facilityIds: postBody.facilties,
    notify: postBody.marketingNotify,
  };

  const { response, error } = await fetchRequest(url, "POST", body);

  const data = await response.json();

  return { response, data, error };
};

// Heartbeat for when the user joins a call to make sure that they are still active
export const callSessionHeartbeat = async (
  joinId: string,
  callSid: string,
  deviceId: string
) => {
  const url = `${BASE_API_URL}/callevent/users/join/${joinId}`;

  const postBody = {
    callSid: callSid.toLowerCase(),
    deviceId,
    timeZone: "UTC",
  };

  return await ApiQuery(url, "PUT", postBody);
};
