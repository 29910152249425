import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
/* Styles */
import './ProfileImage.scss';

const ProfileImage = ({ image, size, className, initials, userType, ...props }) => {
	return (
		image ?
			<img src={image} className={`profile-image profile-image--${size}`} alt="profile" />
			:
			<div
				className={classNames(
					`profile-image profile-image__initials align--center justify-content--center profile-image--${size} ${className}`
				)}
			>
				{initials}
			</div>
	)
};

ProfileImage.propTypes = {
	image: PropTypes.string,
	size: PropTypes.string.isRequired,
};

export default ProfileImage;
