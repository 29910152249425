import React from "react";

import clsx from "clsx";

import horizontalDark from "src/../public/images/logos/logo-horizontal-dark.png";
import horizontalLight from "src/../public/images/logos/logo-horizontal-light.png";
import horizontalDefault from "src/../public/images/logos/logo-horizontal.png";
import symbolDark from "src/../public/images/logos/logo-symbol-dark.png";
import symbolLight from "src/../public/images/logos/logo-symbol-light.png";
import symbolDefault from "src/../public/images/logos/logo-symbol.png";
import verticalDark from "src/../public/images/logos/logo-vertical-dark.png";
import verticalLight from "src/../public/images/logos/logo-vertical-light.png";
import verticalDefault from "src/../public/images/logos/logo-vertical.png";

import styles from "./styles.scss";

export type LogoType =
  | "symbol"
  | "symbol-light"
  | "symbol-dark"
  | "horizontal"
  | "horizontal-light"
  | "horizontal-dark"
  | "vertical"
  | "vertical-light"
  | "vertical-dark";

export interface LogoProps {
  type: LogoType;
  size?: "small" | "default"; // TODO expand these options as necessary
  "data-test-id"?: string;
}

/**
 * Logo component that should be used through out the app
 */
export const Logo = ({
  type = "symbol",
  size = "default",
  "data-test-id": dataTestId,
}: LogoProps) => {
  const logo = getLogoImage(type);
  return (
    <img
      data-test-id={dataTestId}
      className={clsx(styles.logo, styles[size])}
      src={logo}
      alt="logo"
    />
  );
};

const getLogoImage = (type: LogoType) => {
  switch (type) {
    case "symbol":
      return symbolDefault;
    case "symbol-light":
      return symbolLight;
    case "symbol-dark":
      return symbolDark;
    case "vertical":
      return verticalDefault;
    case "vertical-light":
      return verticalLight;
    case "vertical-dark":
      return verticalDark;
    case "horizontal":
      return horizontalDefault;
    case "horizontal-light":
      return horizontalLight;
    case "horizontal-dark":
      return horizontalDark;
  }
};
