import { HOME } from './HomeActionTypes';
import UserSessionService from '../../Common/Services/userSesionService';

export const setCurrentUserInfoAction = (user = null) => ({
	type: HOME.SET_CURRENT_USER_INFO,
	payload: user ? user : UserSessionService.getCachedUserInfo(),
});

export const toggleLoader = () => ({
	type: HOME.TOGGLE_LOADER,
});

export const startIdleTimerAction = () => ({
	type: HOME.START_IDLE_TIMER,
});

export const setShowIdleAlertAction = bool => ({
	type: HOME.SET_IDLE_ALERT,
	payload: bool,
});

export const setNotificationsAction = notifs => ({
	type: HOME.SET_NOTIFICATIONS,
	payload: notifs,
});

export const addNotificationAction = notif => ({
	type: HOME.ADD_NOTIFICATION,
	payload: notif,
});

export const removeNotificationAction = id => ({
	type: HOME.REMOVE_NOTIFICATION,
	payload: id,
});

export const setLocalNetworkDisrupted = (localNetworkDisrupted) => ({
  type: HOME.SET_LOCAL_NETWORK_DISRUPTED,
  payload: localNetworkDisrupted
})
