import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
/* Components */
import { Button } from '../../../../Common/Components';
import ModalComponent from '../../../../Common/Components/Modal/Modal';
import { PageTitle, Table } from '../index';
/* Actions */
import { setUserStatusAction } from './ApprovalListActions';

class ApprovalList extends PureComponent {
	constructor(props) {
		super(props);
		this.columns = [
			{
				title: 'Name',
				sortValue: 'firstName',
				alias: 'name',
			},
			{
				title: 'Role',
				alias: 'role',
				excluded: false,
			},
			{
				title: 'Vendor',
				sortValue: 'brandName',
				alias: 'brandName',
				excluded: false,
			},
			{
				title: 'Application Status',
				sortValue: 'applicationStatus',
				excluded: false,
			},
		];
		this.state = {
			isConfirmationModalOpened: false,
		};
	}

	componentDidMount() {
		this.params = {
			hospitalId: this.props.hospitalId,
			size: this.state.pageSize,
			page: 0,
		};
		this.props.getAllUserApplications(this.params);
	}

	onChangeUserStatusHandler = () => {
		this.props.setUserStatus(this.props.hospitalId, this.user.id, this.userStatus, this.props.organizationType);
		this.toggleConfirmationModal();
	};

	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			hospitalId: this.props.hospitalId,
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			hospitalId: this.props.hospitalId,
			size: this.params.size,
			page: this.params.page,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllUserApplications(this.params);
	};

	onTableSortHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			...this.params,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllUserApplications(this.params);
	};

	getModalText = () => (
		<span>
			Are you sure that you want to {this.userStatus.approved ? 'add' : 'remove'}
			<span className="text--bold">{` ${this.user.firstName} ${this.user.lastName} `}</span>
			{this.userStatus.approved ? 'to' : 'from'} your {this.props.organizationType === 'vendor' ? 'vendor management profile' : 'hospital system'}?
		</span>
	);

	toggleConfirmationModal = (isUserApproved = {}, user) => {
		this.userStatus = isUserApproved;
		this.user = user;
		this.setState({ isConfirmationModalOpened: !this.state.isConfirmationModalOpened });
	};

	userStatus = { approved: false };

	renderConfirmationModal = () => (
		<ModalComponent
			open={this.state.isConfirmationModalOpened}
			title={`${this.userStatus.approved ? 'Approve' : 'Reject'} user`}
			onCancel={this.toggleConfirmationModal}
			hasActionButtons={false}
		>
			{
				this.user && this.getModalText()
			}
			<div className="modal__action-buttons">
				<Button
					onClick={this.toggleConfirmationModal}
					isGhost
					buttonSmall
				>
					Cancel
				</Button>
				<Button
					onClick={this.onChangeUserStatusHandler}
					isFull
					buttonSmall
				>
					Yes, {this.userStatus.approved ? 'approve' : 'reject'}
				</Button>
			</div>
		</ModalComponent>
	);

	render() {
		return (
			<div className="users__container">
				<Table
					nameOverride="firstName"
					row={this.props.tableRowComponent}
					columns={this.columns}
					tableData={this.props.userApplications}
					openModal={this.toggleConfirmationModal}
					paginationConfig={this.props.pagination}
					onPageChangeHandler={this.onPageChangeHandler}
					onSortTableHandler={this.onTableSortHandler}
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					noItemsClass="height--full justify-content--center align--center"
					headerTitle="Approval List"
					search
					noEntityComponent={
						<div className="profile__right-content-wrapper">No user applications available</div>
					}
					sortable
				/>
				{this.renderConfirmationModal()}
			</div>
		);
	}
}

ApprovalList.propTypes = {};

const mapStateToProps = state => ({
	userApplications: state.approvalListReducer.userApplications,
});

const mapDispatchToProps = dispatch => ({
	setUserStatus: (hospitalId, userId, userStatus, organizationType) => dispatch(setUserStatusAction(hospitalId, userId, userStatus, organizationType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'approvalListForm'
})(ApprovalList));
