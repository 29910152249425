import { useEffect, useState, useCallback } from "react";

// Made a small debounce function for the API calls in the hook below, nothing special
const debounce = (fn: any, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (...args: any[]) {
    // const context = this;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(null, args), ms);
  };
};

// setting min limit of characters before we call the API
// we can make this an arg to the hook if necessary
const MIN_AMOUNT_OF_CHARACTERS_TO_CALL_API = 2;

// This hook is used to call an API function with some user input
// imagine the user types "gok" then we would send that info the backend
// and they would return us a list of options to put in our textfield
// see AutocompleteTextField's story file for example
export function useAsyncAutocomplete(
  apiFunction: (userInput: string) => Promise<any>,
  // if this boolean is set to true then we call the API for autocomplete results before the
  // user types anything, so as soon as they click the input. This will return a big list for the user to scroll through
  // if its false then the user has to start typing before they see results
  callAPIOnClick = true
) {
  const [userInput, setUserInput] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const updateOptions = async (input) => {
    setLoading(true);
    setOptions([]);

    // pass user input to api function to do autocomplete in the backend
    const results = await apiFunction(input);

    // once we have the results we will store that in the options list
    if (results) {
      setOptions(results);
      setLoading(false);
    }
  };

  // we need this to be a consistent function that never changes otherwise we lose
  // the debounce effect if we recreate the debounce function every time we run it
  const debouncedAPICall = useCallback(
    debounce((input) => updateOptions(input), 500),
    []
  );

  useEffect(() => {
    // either we want to call the API right away if callAPIOnClick is true or
    // we want to call the api function if there is some text and if the user enters at least a few characters
    if (
      callAPIOnClick ||
      (userInput !== "" &&
        userInput.length >= MIN_AMOUNT_OF_CHARACTERS_TO_CALL_API)
    ) {
      debouncedAPICall(userInput);
    }
  }, [userInput]);

  // just reseting some state when the autocomplete dropdown closes
  useEffect(() => {
    if (!open) {
      setLoading(false);
    }
  }, [open]);

  return [userInput, setUserInput, open, setOpen, options, loading] as [
    string,
    (newValue: string) => void,
    boolean,
    (open: boolean) => void,
    any[],
    boolean
  ];
}
