import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  handleFreezeFrameThunk,
  handleUnfreezeFrameThunk,
} from "src/domains/Beacon/store/stream/thunks";
import { publishAudioTrackThunk } from "src/domains/Beacon/store/stream/thunks/publishAudioTrackThunk";
import { publishLocalMediaTracksThunk } from "src/domains/Beacon/store/stream/thunks/publishLocalMediaTracksThunk";
import { publishVideoTrackThunk } from "src/domains/Beacon/store/stream/thunks/publishVideoTrackThunk";
import { toggleSidebarStatusThunk } from "src/domains/Beacon/store/stream/thunks/toggleSidebarStatusThunk";
import { unpublishAudioTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishAudioTrackThunk";
import { unpublishVideoTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishVideoTrackThunk";
import {
  ParticipantAudioTrackPayload,
  LocalOrRemoteMediaTrack,
  MPRemoteTracks,
  StreamState,
  CallParticipantAudioVideoMuteStatus,
  LayoutFrames,
  FreezeFrame,
  Preset,
  ZoomFrames,
  IHandleFreezeFrame,
  DefaultCameraPosition,
  LayoutIntegrationLink,
  LayoutFrameInfo,
  LayoutFrameNames,
  LayoutTypes,
  LayoutType,
  ConsoleCapabilities,
  LocalMediaDeviceEnumeration,
} from "src/domains/Beacon/store/stream/types";

const defaultLayoutFramesState: LayoutFrames = {
  leftTop: {
    cameraId: "",
    cameraLabel: "",
    isFullScreen: false,
  },
  rightTop: {
    cameraId: "",
    cameraLabel: "",
    isFullScreen: false,
  },
  leftBottom: {
    cameraId: "",
    cameraLabel: "",
    isFullScreen: false,
  },
  rightBottom: {
    cameraId: "",
    cameraLabel: "",
    isFullScreen: false,
  },
};

const defaultZoomState: ZoomFrames = {
  leftBottom: {
    cameraId: "",
    value: 0,
    settings: {
      start: 4,
      min: 0,
      max: 10,
      step: 1,
    },
  },
  leftTop: {
    cameraId: "",
    value: 0,
    settings: {
      start: 0,
      min: 0,
      max: 10,
      step: 1,
    },
  },
  rightBottom: {
    cameraId: "",
    value: 0,
    settings: {
      start: 4,
      min: 0,
      max: 10,
      step: 1,
    },
  },
  rightTop: {
    cameraId: "",
    value: 0,
    settings: {
      start: 0,
      min: 0,
      max: 10,
      step: 1,
    },
  },
};

export const initialState: StreamState = {
  integrationActive: false, // Let know if a 3rd party integration is being shown
  cameras: [],
  console: {
    audio: "unmute",
    video: "unmute",
  },
  defaultCameraPositions: {
    front: {
      x: 0,
      y: 0,
    },
    overhead: {
      x: 0,
      y: -1,
    },
  },
  freezeFrame: {
    type: "fullscreen",
    active: false,
    transferId: null,
    imageData: "",
  },
  layoutFrames: defaultLayoutFramesState,
  layoutFramesSnapshot: defaultLayoutFramesState,
  layoutType: LayoutTypes.TWO_VIEW,
  pip: "show",
  presets: {
    selectedPresetId: null,
    options: [],
  },
  self: {
    audio: "unmute",
    video: "unmute",
  },
  sidebar: "inactive",

  // localMedia has all the available media devices and also the selected
  // camera, microphone and speaker to be used in the call
  localMedia: null,
  videoTrack: null,
  audioTrack: null,
  screenShareTrack: null,

  // remote
  remoteTracks: [],
  // TODO: Have remoteTracks look like this:
  // remoteTracks {
  //   console: [], // this refers to MP AND P2P
  //   host: [], // this refers to MP
  //   participants: [], // this refers to MP
  // }
  // When we get the remote tracks from twilio, we're able to filter them out
  // based on their UUIDs. The console one for example, we get the twilioParticipant like this:
  // twilioParticipants.find((p) => p.identity.includes("CONSOLE"))
  // this twilioParticipant includes properties for audio and video tracks,
  // which we can plug into the commented object above this

  // Array track composition will be as:
  // position 0: videoTrack
  // position 1: audioTrack
  mpRemoteTracks: {
    console: {
      audio: null,
      video: null,
    },
    host: {
      video: null,
      audio: null,
    },
    participants: {},
  },
  isVideoTrackPublished: false, // If published, Host can mute/unmute its video, button wil be enabled
  videoTrackPublishLoading: false,
  isAudioTrackPublished: false, // If published, Participant/Host can mute/unmute its audio, button will be enabled
  audioTrackPublishLoading: false,

  // async stuff
  localMediaLoading: false,
  localMediaError: null,
  loadingSidebar: false,
  loadingFreezeFrame: false,
  error: null,

  // console's available features returned from console or presence server
  bluetoothEnabled: false,
  noiseCancellationEnabled: false,
  noiseCancellationAvailable: false,
  consoleHasExternalInputResizing: false,

  // current zoom state of the displayed sources
  zoomState: defaultZoomState,
  // Must store zoom state before TPI to reset PTZ and zoom level
  zoomStateSnapshot: defaultZoomState,
  consoleAudioIsBuiltInMic: true,
  // call has TPI enabled
  thirdPartyIntegrationAvailable: false,

  // Work as a toggle in order to fire react-query and fetch for the new thumbnails in Presence
  // doesn't matter the value since we won't use it
  toggleThumbnailsFetch: false,

  // Will have all the available Console capabilities list with a value "true" or "false" on each of them
  // that way we can access them through the app in order to enable/disable features easier
  consoleCapabilities: {
    // false by default since must wait for capabilities list coming from receivedFeatureAvailability listener
    fourViewsLayout: false,
  },
};

export const streamSlice = createSlice({
  name: "stream",
  initialState,
  reducers: {
    clearRemoteTracks: (state: StreamState) => {
      state.mpRemoteTracks = {
        ...state.mpRemoteTracks,
        console: {
          audio: null,
          video: null,
        },
        host: {
          video: null,
          audio: null,
        },
        participants: {},
      };
    },
    setHostVideoTrack: (
      state: StreamState,
      action: PayloadAction<RemoteVideoTrack>
    ) => {
      state.mpRemoteTracks.host = {
        ...state.mpRemoteTracks.host,
        video: action.payload,
      };
    },

    setHostAudioTrack: (
      state: StreamState,
      action: PayloadAction<RemoteAudioTrack>
    ) => {
      state.mpRemoteTracks.host = {
        ...state.mpRemoteTracks.host,
        audio: action.payload,
      };
    },

    setParticipantAudioTrack: (
      state: StreamState,
      action: PayloadAction<ParticipantAudioTrackPayload>
    ) => {
      const { loginId, audioTrack } = action.payload;
      state.mpRemoteTracks.participants = {
        ...state.mpRemoteTracks.participants,
        [loginId]: [null, audioTrack],
      };
    },

    setConsoleVideoTrack: (
      state: StreamState,
      action: PayloadAction<RemoteVideoTrack>
    ) => {
      state.mpRemoteTracks.console.video = action.payload;
    },

    setConsoleAudioTrack: (
      state: StreamState,
      action: PayloadAction<RemoteAudioTrack>
    ) => {
      state.mpRemoteTracks.console.audio = action.payload;
    },

    setRemoteTracks: (
      state,
      action: PayloadAction<LocalOrRemoteMediaTrack[]>
    ) => {
      state.remoteTracks = action.payload;
    },

    setMpRemoteTracks: (state, action: PayloadAction<MPRemoteTracks>) => {
      state.mpRemoteTracks = action.payload;
    },

    setSidebarState: (state, action: PayloadAction<boolean>) => {
      state.sidebar = action.payload ? "active" : "inactive";
    },

    setCameraDevice: (
      state: StreamState,
      action: PayloadAction<MediaDeviceInfo>
    ) => {
      state.localMedia.camera = action.payload;
    },

    setMicrophoneDevice: (
      state: StreamState,
      action: PayloadAction<MediaDeviceInfo>
    ) => {
      state.localMedia.microphone = action.payload;
    },

    setSpeakerDevice: (
      state: StreamState,
      action: PayloadAction<MediaDeviceInfo>
    ) => {
      state.localMedia.speaker = action.payload;
    },

    setConsoleAudioVideo: (
      state: StreamState,
      action: PayloadAction<CallParticipantAudioVideoMuteStatus>
    ) => {
      state.console = { ...state.console, ...action.payload };
    },

    setCameras: (
      state: StreamState,
      action: PayloadAction<(AVKCamera | AVKExternalInput)[]>
    ) => {
      state.cameras = action.payload;
    },

    setLayoutFrames: (
      state: StreamState,
      action: PayloadAction<LayoutFrames>
    ) => {
      state.layoutFrames = action.payload;
    },
    setLayoutFramesSnapshot: (
      state: StreamState,
      action: PayloadAction<LayoutFrames>
    ) => {
      state.layoutFramesSnapshot = action.payload;
    },

    setFreezeFrame: (
      state: StreamState,
      action: PayloadAction<FreezeFrame>
    ) => {
      state.freezeFrame = { ...state.freezeFrame, ...action.payload };
    },

    setBluetoothEnabled: (state, action: PayloadAction<boolean>) => {
      state.bluetoothEnabled = action.payload;
    },

    setNoiseCancellationAvailable: (state, action: PayloadAction<boolean>) => {
      state.noiseCancellationAvailable = action.payload;
    },

    setNoiseCancellationEnabled: (state, action: PayloadAction<boolean>) => {
      state.noiseCancellationEnabled = action.payload;
    },

    setConsoleHasExternalInputResizing: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.consoleHasExternalInputResizing = action.payload;
    },

    setZoomState: (state, action: PayloadAction<ZoomFrames>) => {
      state.zoomState = action.payload;
    },
    setZoomStateSnapshot: (
      state: StreamState,
      action: PayloadAction<ZoomFrames>
    ) => {
      state.zoomStateSnapshot = action.payload;
    },

    addPreset: (state: StreamState, action: PayloadAction<Preset>) => {
      state.presets.options = [...state.presets.options, action.payload];
      // when adding a Preset, it becomes the selectedPreset
      state.presets.selectedPresetId = action.payload.id;
    },

    removePreset: (state: StreamState, action: PayloadAction<string>) => {
      const presetId = action.payload;
      if (presetId === state.presets.selectedPresetId) {
        // removing current selectedPresetId, set to null
        state.presets.selectedPresetId = null;
      }
      // remove requested presetId from options
      state.presets.options = state.presets.options.filter((preset) =>
        preset.id === presetId ? null : preset
      );
    },

    setPresetSelected: (state: StreamState, action: PayloadAction<string>) => {
      const presetId = action.payload;
      state.presets.selectedPresetId = presetId;
    },

    setConsoleAudioIsBuiltInMic: (
      state: StreamState,
      action: PayloadAction<boolean>
    ) => {
      state.consoleAudioIsBuiltInMic = action.payload;
    },

    setDefaultCameraPositions: (
      state,
      action: PayloadAction<DefaultCameraPosition>
    ) => {
      state.defaultCameraPositions = action.payload;
    },

    setIntegrationActive: (state, action: PayloadAction<boolean>) => {
      state.integrationActive = action.payload;
    },

    setLayoutFrameInfo: (
      state,
      action: PayloadAction<
        LayoutFrameInfo & { frameSelected: LayoutFrameNames }
      >
    ) => {
      const { frameSelected } = action.payload;

      state.layoutFrames = {
        ...state.layoutFrames,
        [frameSelected]: {
          ...action.payload,
        },
      };
    },
    setLayoutFrameSnapshotInfo: (
      state,
      action: PayloadAction<
        LayoutFrameInfo & { frameSelected: LayoutFrameNames }
      >
    ) => {
      const { frameSelected } = action.payload;

      state.layoutFramesSnapshot = {
        ...state.layoutFramesSnapshot,
        [frameSelected]: {
          ...action.payload,
        },
      };
    },

    setLayoutIntegrationLink: (
      state,
      action: PayloadAction<LayoutIntegrationLink>
    ) => {
      const {
        frameSelected,
        integrationName,
        integrationLink,
      } = action.payload;

      state.layoutFrames = {
        ...state.layoutFrames,
        [frameSelected]: {
          ...state.layoutFrames[frameSelected],
          integrationName,
          integrationLink,
        },
      };
    },

    setScreenShareTrack: (state, action: PayloadAction<LocalVideoTrack>) => {
      state.screenShareTrack = action.payload;
    },
    setLayoutType: (state, action: PayloadAction<LayoutType>) => {
      state.layoutType = action.payload;
    },
    setThirdPartyIntegrationAvailable: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.thirdPartyIntegrationAvailable = action.payload;
    },
    toggleThumbnailsFetch: (state) => {
      // Will fire the react-query thumbnails fetch
      state.toggleThumbnailsFetch = !state.toggleThumbnailsFetch;
    },

    setConsoleCapabilities: (
      state,
      action: PayloadAction<ConsoleCapabilities>
    ) => {
      state.consoleCapabilities = action.payload;
    },
    setLocalMedia: (
      state,
      action: PayloadAction<LocalMediaDeviceEnumeration>
    ) => {
      state.localMedia = action.payload;
    },
    setLocalMediaError: (state, action: PayloadAction<string>) => {
      state.localMediaError = { message: action.payload };
    },
  },

  // Extra reducers are for the generated actions/reducers to handle
  // asynchronous requests via `createAsyncThunk`
  extraReducers: (builder) => {
    // TODO: there seems to be some repetition on these builder cases for
    // pending/fulfilled/rejected states. Maybe there's a way to optimize it?

    // publishVideoTrackThunk
    builder.addCase(publishVideoTrackThunk.pending, (state) => {
      state.videoTrackPublishLoading = true;
      state.isVideoTrackPublished = false;
    });

    builder.addCase(publishVideoTrackThunk.fulfilled, (state, action) => {
      state.error = null;
      state.videoTrack = action.payload;
      state.videoTrackPublishLoading = false;
      // Participant will be able tu mute/unmute itself
      state.isVideoTrackPublished = true;
    });

    builder.addCase(publishVideoTrackThunk.rejected, (state, action) => {
      state.videoTrackPublishLoading = false;
      state.isVideoTrackPublished = false;
      state.error = {
        message: action.error.message,
      };
    });

    // publishAudioTrackThunk
    builder.addCase(publishAudioTrackThunk.pending, (state) => {
      state.audioTrackPublishLoading = true;
      state.isAudioTrackPublished = false;
    });

    builder.addCase(publishAudioTrackThunk.fulfilled, (state, action) => {
      state.error = null;
      state.audioTrack = action.payload;
      state.audioTrackPublishLoading = false;
      // Participant will be able tu mute/unmute itself
      state.isAudioTrackPublished = true;
    });

    builder.addCase(publishAudioTrackThunk.rejected, (state, action) => {
      state.audioTrackPublishLoading = false;
      state.isAudioTrackPublished = false;
      state.error = {
        message: action.error.message,
      };
    });

    // publishLocalMediaTracksThunk
    builder.addCase(publishLocalMediaTracksThunk.pending, (state) => {
      state.videoTrackPublishLoading = true;
      state.isVideoTrackPublished = false;
      state.isAudioTrackPublished = false;
    });

    builder.addCase(publishLocalMediaTracksThunk.fulfilled, (state, action) => {
      state.error = null;
      state.videoTrackPublishLoading = false;

      // Must get the `track` from the `publication` since it's type of `LocalTrack`
      // and can be converted and used as `LocalVideoTrack` or `LocalAudioTrack` in our side
      const videoTrack = action.payload
        // Must filter first to have only video tracks
        ?.filter((trackPublication) => trackPublication.kind === "video")
        // Must return the track from the `publication`
        .map((trackPublication) => trackPublication.track) as LocalVideoTrack[];

      const audioTrack = action.payload
        // Must filter first to have only audio tracks
        ?.filter((trackPublication) => trackPublication.kind === "audio")
        // Must return the track from the `publication`
        .map((trackPublication) => trackPublication.track) as LocalAudioTrack[];

      // We just have one video and audio tracks in the array but because we did a ^ filter
      // we should use the `0` index instead
      state.videoTrack = videoTrack?.[0] ?? null;
      state.audioTrack = audioTrack?.[0] ?? null;

      // Participant will be able tu mute/unmute itself
      state.isVideoTrackPublished = true;
      state.isAudioTrackPublished = true;
    });

    builder.addCase(publishLocalMediaTracksThunk.rejected, (state, action) => {
      state.videoTrackPublishLoading = false;
      state.isVideoTrackPublished = false;
      state.error = {
        message: action.error.message,
      };
    });

    // unpublishVideoTrackThunk
    builder.addCase(unpublishVideoTrackThunk.pending, (state) => {
      state.videoTrackPublishLoading = true;
    });

    builder.addCase(unpublishVideoTrackThunk.fulfilled, (state) => {
      state.error = null;
      state.videoTrack = null;
      state.videoTrackPublishLoading = false;
      // Participant will NOT be able tu mute/unmute itself
      state.isVideoTrackPublished = false;
    });

    builder.addCase(unpublishVideoTrackThunk.rejected, (state, action) => {
      state.videoTrackPublishLoading = false;
      state.error = {
        message: action.error.message,
      };
    });

    // TODO: Do we need the audioTrack locally?
    // unpublishAudioTrackThunk
    builder.addCase(unpublishAudioTrackThunk.pending, (state) => {
      state.audioTrackPublishLoading = true;
    });

    builder.addCase(unpublishAudioTrackThunk.fulfilled, (state) => {
      state.error = null;
      state.audioTrack = null;
      state.audioTrackPublishLoading = false;
      // Participant will NOT be able tu mute/unmute itself
      state.isAudioTrackPublished = false;
    });

    builder.addCase(unpublishAudioTrackThunk.rejected, (state, action) => {
      state.audioTrackPublishLoading = false;
      state.error = {
        message: action.error.message,
      };
    });

    // handleFreezeFrameThunk
    builder.addCase(handleFreezeFrameThunk.pending, (state) => {
      state.loadingFreezeFrame = true;
    });

    builder.addCase(
      handleFreezeFrameThunk.fulfilled,
      (state, action: PayloadAction<IHandleFreezeFrame>) => {
        const { transferId, imageData } = action.payload;
        state.loadingFreezeFrame = false;
        state.freezeFrame = {
          ...state.freezeFrame,
          type: "fullscreen",
          active: true,
          transferId,
          imageData,
        };
      }
    );

    builder.addCase(handleFreezeFrameThunk.rejected, (state, action) => {
      state.loadingFreezeFrame = false;
      state.error = {
        message: action.error.message,
      };
    });

    // handleUnfreezeFrameThunk
    builder.addCase(handleUnfreezeFrameThunk.pending, (state) => {
      state.loadingFreezeFrame = true;
    });

    builder.addCase(handleUnfreezeFrameThunk.fulfilled, (state) => {
      state.loadingFreezeFrame = false;
      state.freezeFrame = {
        ...state.freezeFrame,
        type: "fullscreen",
        active: false,
        transferId: null,
        imageData: "",
      };
    });

    builder.addCase(handleUnfreezeFrameThunk.rejected, (state, action) => {
      state.loadingFreezeFrame = false;
      state.error = {
        message: action.error.message,
      };
    });

    // toggleSidebarStatus
    builder.addCase(toggleSidebarStatusThunk.pending, (state) => {
      state.loadingSidebar = true;
    });

    builder.addCase(toggleSidebarStatusThunk.fulfilled, (state, action) => {
      state.loadingSidebar = false;
      const activateSidebar = action.payload;
      state.sidebar = activateSidebar ? "active" : "inactive";
    });

    builder.addCase(toggleSidebarStatusThunk.rejected, (state) => {
      state.loadingSidebar = false;
    });
  },
});

// Export the individual reducer and actions, which are generated with `createSlice`
export const { reducer: streamReducer, actions: streamActions } = streamSlice;
