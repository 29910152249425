import {
  AVKCamera,
  NCameraInput,
  NCameraMount,
  NCameraType,
} from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";
import { NCameraLocation } from "availkit-js/dist/Models/NCameraLocation";
import { Guid } from "guid-typescript";

import { AVAIL_DEVICE_INSTANCE_ID_KEY } from "src/domains/Beacon/constants";
import { createObjectClone } from "src/domains/Beacon/utils/objects";
import { logger } from "src/logging/logger";
import {
  PresenceServerConsoleInfo,
  PresenceServerConsoles,
} from "src/services/ApiClient/console";
import UserSessionService from "src/services/UserSessionService";

import { PresenceServerConsoleExternalInputInfo } from "portalcall/commoncall/store/models";

const layoutWeight = (aCamera: AVKCamera | AVKExternalInput): number => {
  if (aCamera instanceof AVKCamera) {
    return (
      (aCamera.type === NCameraType.PTZ ? 100 : 0) +
      (aCamera.mount === NCameraMount.FrontFixed ? 10 : 0) +
      (aCamera.input === NCameraInput.WireOneHDMIInput ? 1 : 0)
    );
  }
};

export function camerasSortedByName(
  cameras: (AVKCamera | AVKExternalInput)[]
): (AVKCamera | AVKExternalInput)[] {
  return cameras.sort((a, b) => {
    const aWeight = layoutWeight(a);
    const bWeight = layoutWeight(b);
    // Default to sort big to small
    return bWeight - aWeight;
  });
}

// TODO: the parameter type should be AVKCamera but there's an error type
// for the property inputName, it doesn't exist on AVKCamera
// check file portalcall/src/commoncall/components/Utils/CameraUtils.tsx
export const getCameraDisplayName = (avkCamera: any): string => {
  return avkCamera?.inputName ?? avkCamera.name;
};

// TODO: the parameter type should be AVKCamera but there's an error type
// for the property id, it doesn't exist on AVKCamera
// check file portalcall/src/commoncall/components/Utils/CameraUtils.tsx
export const getCameraIdentifier = (avkCamera: any): string =>
  avkCamera?.cameraIdentifier ?? avkCamera.id;
export const getExternalInputIdentifier = (avkExternalInput: any): string =>
  avkExternalInput?.id ?? avkExternalInput.id;

export const formatUUIDFields = (field: string) => {
  return field.replaceAll("-", "_");
};

// Generates a unique UUID for the user to use AvailKit and PunNub
export const getPresenceUUID = () => {
  const user = UserSessionService.getCachedUserInfo();
  let availDeviceInstance = localStorage.getItem(AVAIL_DEVICE_INSTANCE_ID_KEY);

  if (!availDeviceInstance) {
    availDeviceInstance = Guid.create().toString();
    logger().info("Setting device-instance for presence");
    localStorage.setItem(AVAIL_DEVICE_INSTANCE_ID_KEY, availDeviceInstance);
  }

  const loginId = formatUUIDFields(user.loginId);
  const deviceInstance = formatUUIDFields(
    availDeviceInstance.replaceAll('"', "")
  );

  return `USER-${loginId}-PVID-${deviceInstance}`;
};

// Converts the cameras from presence server to AVKCamera objects
export const createDiscoveryAVKCameras = (
  sources: PresenceServerConsoleInfo[]
) => {
  return sources.map((camera: PresenceServerConsoleInfo) => {
    const avkCamera = new AVKCamera();
    avkCamera.cameraIdentifier = camera.id;
    avkCamera.input = camera.cameraInput;
    avkCamera.name = camera.name;
    avkCamera.mount = camera.cameraMount;
    avkCamera.type = camera.cameraType;

    avkCamera.location = new NCameraLocation();
    avkCamera.location.x = camera.location.x;
    avkCamera.location.y = camera.location.y;
    avkCamera.location.zoomLevel = camera.location.zoomLevel;

    return avkCamera;
  });
};

// Converts the external cameras from presence server to AVKExternalInput objects
export const createDiscoveryAVKExternalInputs = (sources: any) => {
  return sources.map((camera: PresenceServerConsoleExternalInputInfo) => {
    // if not Front or Overhead, then they are external inputs
    const { inputId, inputName, isAutoCrop, zoomLevel } = camera;

    const externalInputSource = new AVKExternalInput(
      inputId,
      inputName,
      isAutoCrop,
      zoomLevel
    );

    return externalInputSource;
  });
};

// Checks if param camera is an AVKCamera
export const AVKCameraIdentifier = (source: AVKCamera): boolean =>
  source.cameraIdentifier !== undefined;

// Checks if param camera is an AVKExternalInput
export const AVKExternalInputIdentifier = (source: AVKExternalInput): boolean =>
  source.id !== undefined;

export function CameraIdentifier(source: AVKCamera | AVKExternalInput): string {
  if ("cameraIdentifier" in source) {
    return source.cameraIdentifier;
  } else {
    return source.id;
  }
}

export function videoSourceZoomLevel(
  source: AVKCamera | AVKExternalInput
): number {
  if ("cameraIdentifier" in source) {
    return source.location.zoomLevel;
  } else {
    return source.zoomLevel;
  }
}

// Allows to pass the cameras and get a specified camera by its id
export const getCameraByIdentifier = (
  cameras: (AVKCamera | AVKExternalInput)[],
  cameraId: string
) => cameras.find((camera) => getCameraIdentifier(camera) === cameraId);

// Extracts an AVK camera from a preset snapshot
export const getCameraFromPresetSnapshot = (
  cameraIdentifier: string,
  camerasList: (AVKCamera | AVKExternalInput)[]
): AVKCamera | AVKExternalInput => {
  const cameraRef = camerasList.find(
    (camera) => CameraIdentifier(camera) === cameraIdentifier
  );
  return cameraRef;
};

// Merges the cameras from Redux with the preset's cameras that user has selected
export const getMergedCamerasFromPresetSnapshot = (
  camerasList: (AVKCamera | AVKExternalInput)[],
  presetCameras: (AVKCamera | AVKExternalInput)[]
): (AVKCamera | AVKExternalInput)[] => {
  const updatedCameras = camerasList.map((camera) => {
    const cameraRef = getCameraFromPresetSnapshot(
      CameraIdentifier(camera),
      presetCameras
    );

    if (cameraRef) {
      return createObjectClone(cameraRef);
    } else {
      return createObjectClone(camera);
    }
  });

  return updatedCameras;
};

/**
 * consoles can have multiple keys if different Consoles were used for
 *  this callSid (example: switching Consoles for same call).
 * Keys are UUIDs and do not necessarily iterate in insertion order.
 * If the key belongs to the callSid's active Console, cameras and
 *  externalInputs has a length.
 */
// TODO: when SOFT-7598 is merged, make consistent with src/utils/extractConsoleSources
const getSourceFromPresenceConsoleServer = (
  consoles: PresenceServerConsoles,
  sourceKey: string
) => {
  let sources = [];
  Object.values(consoles).forEach((key) => {
    if (key[sourceKey].length > 0) {
      sources = [...key[sourceKey]];
    }
  });
  return sources;
};

export const getCamerasFromPresenceConsoleServer = (
  consoles: PresenceServerConsoles
) => getSourceFromPresenceConsoleServer(consoles, "cameras");

export const getExternalInputsFromPresenceConsoleServer = (
  consoles: PresenceServerConsoles
) => getSourceFromPresenceConsoleServer(consoles, "externalInputs");
