// this object represents the current scope of in-development flags,
// we're using this a simple way to hide/show features
export const FEATURE_FLAGS = {
  showUsagePage: true, // for the usage page, for trial usage and call history
  showTrialSignUpPage: true, // for trial sign up page
  showBeaconPage: true, // for the beacon page (new portalcall UI)
  showAudioTestMode: false, // for AudioSettings SidePanel on Beacon
  enableORTelligence: false,
  enableExternalInputsImageResizing: false,
  enableTraceId: false,
  offerDeviceSelection: false,
};
