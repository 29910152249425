import { AVKCamera, NCameraType } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleLayoutChangeThunk } from "src/domains/Beacon/store/stream/thunks";
import {
  DefaultCameraPosition,
  LayoutFrames,
  ZoomFrames,
} from "src/domains/Beacon/store/stream/types";
import {
  createDiscoveryAVKCameras,
  createDiscoveryAVKExternalInputs,
  getCamerasFromPresenceConsoleServer,
  getExternalInputsFromPresenceConsoleServer,
  videoSourceZoomLevel,
  camerasSortedByName,
  getCameraDisplayName,
  getCameraIdentifier,
} from "src/domains/Beacon/utils/availKit";
import { FEATURE_FLAGS } from "src/featureFlags";
import { logger, LoggerLevels } from "src/logging/logger";
import { getConsolesBySessionId } from "src/services/ApiClient/console";

interface IParams {
  externalInputsImageResizing: boolean;
}

export const resetConsoleToDefaultStateThunk = ({
  externalInputsImageResizing,
}: IParams) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const { meeting, stream, twilio } = getState();
    const { callSid, thirdPartyIntegration } = meeting;
    const { consoleHasExternalInputResizing } = stream;
    const { consoleHasJoinedRoom } = twilio;

    // Do not proceed if the Console isn't in the room.
    if (!consoleHasJoinedRoom) {
      logger().logWithFields(
        LoggerLevels.info,
        {
          fileInfo: `resetConsoleToDefaultStateThunk`,
          feature: `preset/thunks`,
        },
        `Console has not joined Twilio call. Skipping a reset of Server Console to defaults.`
      );
      return;
    }

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "resetConsoleToDefaultStateThunk",
      },
      "Restoring Server Console state to default, Host has joined and will reset cameras, externalInputs, zoomState, cameras positions",
      "Console and Portal will sync up again"
    );

    const { enableORTelligence } = FEATURE_FLAGS;

    const hasExternalInputResizing =
      consoleHasExternalInputResizing && externalInputsImageResizing;

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "resetConsoleToDefaultStateThunk",
      },
      hasExternalInputResizing
        ? "EIR enabled, will use latest VideoLayout event to broadcast"
        : "EIR disabled, will use UserPreset event to broadcast"
    );

    const response = await getConsolesBySessionId(callSid);
    const consoles = response?.content;

    const consoleCameras = getCamerasFromPresenceConsoleServer(consoles);
    const consoleExternalInputs: AVKExternalInput[] = getExternalInputsFromPresenceConsoleServer(
      consoles
    );
    const cameras =
      (consoleCameras.length > 0 &&
        ([...createDiscoveryAVKCameras(consoleCameras)] as AVKCamera[])) ||
      [];

    const externalInputs =
      (consoleExternalInputs.length > 0 &&
        (createDiscoveryAVKExternalInputs(
          consoleExternalInputs
        ) as AVKExternalInput[])) ||
      [];

    const sortedCameras = camerasSortedByName([...cameras, ...externalInputs]);

    const avkSources = [...cameras, ...externalInputs];

    // Means that cameras fetched from Presence Console are empty,
    // possibly Console hasn't joined the call or cameras weren't loaded properly
    if (sortedCameras.length <= 0 || avkSources.length <= 0) {
      logger().warn(
        "There was an error while fetching cameras from Presence Console, cameras are empty or Console hasn't joined"
      );
    }

    if (enableORTelligence) {
      thirdPartyIntegration?.map((integration) => {
        // Must add integrations by checking if enabled only
        if (integration.enabled) {
          const ortIFrame: AVKCamera = new AVKCamera();
          ortIFrame.cameraIdentifier = integration.partner;
          ortIFrame.name = integration.partner;
          ortIFrame.type = NCameraType.DigitalInput;
          avkSources.push(ortIFrame);
        }
      });

      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "resetConsoleToDefaultStateThunk",
        },
        "TPI list available, pushing list into Layouts options"
      );

      dispatch(streamActions.setCameras(avkSources));
    } else {
      dispatch(streamActions.setCameras(avkSources));
    }

    // Setting the defaults inputs
    // leftTop = Front camera
    // rightTop = Overhead camera
    // leftBottom = Input 1
    // rightBottom = Input 2
    const defaultLeftTopCamera = sortedCameras?.[0];
    const defaultRightTopCamera = sortedCameras?.[1];
    const defaultLeftBottomCamera = sortedCameras?.[2];
    const defaultRightBottomCamera = sortedCameras?.[3];

    // set default camera positions
    const avkCameras = [...cameras];
    const cameraPositions: DefaultCameraPosition = Object.fromEntries(
      avkCameras.map(({ name, location }) => [
        name.toLowerCase(),
        {
          x: location.x,
          y: location.y,
        },
      ])
    );

    dispatch(streamActions.setDefaultCameraPositions(cameraPositions));

    const layoutFrames: LayoutFrames = {
      leftTop: {
        cameraId: getCameraIdentifier(defaultLeftTopCamera),
        cameraLabel: getCameraDisplayName(defaultLeftTopCamera),
        isFullScreen: false,
      },
      rightTop: {
        cameraId: getCameraIdentifier(defaultRightTopCamera),
        cameraLabel: getCameraDisplayName(defaultRightTopCamera),
        isFullScreen: false,
      },
      leftBottom: {
        cameraId: getCameraIdentifier(defaultLeftBottomCamera),
        cameraLabel: getCameraDisplayName(defaultLeftBottomCamera),
        isFullScreen: false,
      },
      rightBottom: {
        cameraId: getCameraIdentifier(defaultRightBottomCamera),
        cameraLabel: getCameraDisplayName(defaultRightBottomCamera),
        isFullScreen: false,
      },
    };

    dispatch(streamActions.setLayoutFrames(layoutFrames));
    // selecting Presets will use layoutFramesSnapshot if there is 3rdPartyIntegration
    // Initiate a value for that matches layoutFrames to prevent disabling PTZControls
    dispatch(streamActions.setLayoutFramesSnapshot(layoutFrames));

    // set up zoomState
    const localZoomState: ZoomFrames = {
      leftTop: {
        cameraId: getCameraIdentifier(defaultLeftTopCamera),
        value: videoSourceZoomLevel(defaultLeftTopCamera),
        settings: {
          start: videoSourceZoomLevel(defaultLeftTopCamera),
          min: 0,
          max: 10,
          step: 1,
        },
      },
      rightTop: {
        cameraId: getCameraIdentifier(defaultRightTopCamera),
        value: videoSourceZoomLevel(defaultRightTopCamera),
        settings: {
          start: videoSourceZoomLevel(defaultRightTopCamera),
          min: 0,
          max: 10,
          step: 1,
        },
      },
      leftBottom: {
        cameraId: getCameraIdentifier(defaultLeftBottomCamera),
        value: videoSourceZoomLevel(defaultLeftBottomCamera) * 10,
        settings: {
          start: videoSourceZoomLevel(defaultRightBottomCamera) * 10,
          min: 0,
          max: 10,
          step: 1,
        },
      },
      rightBottom: {
        cameraId: getCameraIdentifier(defaultRightBottomCamera),
        value: videoSourceZoomLevel(defaultRightBottomCamera) * 10,
        settings: {
          start: videoSourceZoomLevel(defaultRightBottomCamera) * 10,
          min: 0,
          max: 10,
          step: 1,
        },
      },
    };

    dispatch(streamActions.setZoomState(localZoomState));

    dispatch(
      handleLayoutChangeThunk({
        externalInputsImageResizing,
        newLayoutFrames: layoutFrames,
      })
    );
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      {
        feature: "resetConsoleToDefaultStateThunk",
      },
      "Error while getting Server Console from Presence and restoring to default Console state.",
      error?.message
    );
    throw error;
  }
};
