import { RemoteTrackPublication } from "twilio-video";

import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectFreezeFrameState } from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleUnfreezeFrameThunk } from "src/domains/Beacon/store/stream/thunks";
import { handleTurnOffDrawModeThunk } from "src/domains/Beacon/store/telestration/thunks/handleTurnOffDrawModeThunk";
import { uiActions } from "src/domains/Beacon/store/ui";
import {
  generateBadge,
  UIBadgeTypes,
  UINotificationTypes,
} from "src/domains/Beacon/store/ui/types";
import { logger } from "src/logging/logger";

interface IResult {
  hostTrackDisabled: (publication: RemoteTrackPublication) => void;
  consoleTrackDisabled: (publication: RemoteTrackPublication) => void;
  participantTrackDisabled: (publication: RemoteTrackPublication) => void;
}

export const useRoomTrackDisabled = (): IResult => {
  const freezeFrameState = useAppSelector(selectFreezeFrameState);
  const dispatch = useDispatch();

  const hostTrackDisabled = (publication: RemoteTrackPublication) => {
    logger().info(`Host trackDisabled( ${publication.kind} )`);
  };

  const consoleTrackDisabled = (publication: RemoteTrackPublication) => {
    logger().info(`Console trackDisabled( ${publication.kind} )`);

    dispatch(
      streamActions.setConsoleAudioVideo({ [publication.kind]: "mute" })
    );

    if (publication.kind === "video") {
      if (freezeFrameState.active) {
        // Participants and host can call this thunk cause' if Console leaves
        // the FreezeFrameClear event for Participants isn't received
        dispatch(handleUnfreezeFrameThunk());
      }

      // When Console mutes video, we turn off DrawMode
      dispatch(handleTurnOffDrawModeThunk());
    }

    if (publication.kind === "audio") {
      // trigger audio mute toast notification
      dispatch(
        uiActions.triggerNotification(UINotificationTypes.CONSOLE_AUDIO_MUTED)
      );

      // trigger mute badge
      const badge = generateBadge(UIBadgeTypes.CONSOLE_AUDIO_MUTED);
      dispatch(uiActions.addBadge(badge));
    }
  };

  const participantTrackDisabled = (publication: RemoteTrackPublication) => {
    logger().info(`Remote Participant trackDisabled( ${publication.kind} )`);
  };

  return { hostTrackDisabled, consoleTrackDisabled, participantTrackDisabled };
};
