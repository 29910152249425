import React, { forwardRef, ForwardedRef } from "react";

import clsx from "clsx";

import styles from "./styles.scss";

interface NavBarProps {
  "data-test-id"?: string;
  className?: string;
  children: React.ReactNode;
}

/**
 * Simple nav bar that is full width, used to wrap components in a bar layout
 * This component doesn't perscribe a lot of style itself, its more of a container component
 */
export const NavBar = forwardRef(
  (
    { "data-test-id": dataTestId, className, children }: NavBarProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        data-test-id={dataTestId}
        className={clsx(styles.navbar, className)}
      >
        {children}
      </div>
    );
  }
);
