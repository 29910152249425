import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Button, Fields, validators } from '../../../../../../Common/Components';
import { createNewDistributorAction } from '../../DistributorsActions';
import { getAllStatesAction } from '../../../Organisation/OrganisationActions';

class CreateNewDistributorModal extends PureComponent {
	componentDidMount() {
		this.props.getAllStates();
	}

	onCreateNewDistributorHandler = (data) => {
		this.props.createNewDistributor(data);
		this.props.toggleCreateNewDistributorModal();
	};

	render() {
		const { t } = this.props;

		return (
			<form onSubmit={this.props.handleSubmit(this.onCreateNewDistributorHandler)}>
				<Fields.InputField
					labelText={t('common.form.name.label')}
					placeholder={t('common.form.name.placeholder')}
					name="name"
					validate={[validators.required, validators.minimumLength3, validators.maximumLength48]}
				/>
				<Fields.SelectField
					labelText={t('common.form.state.label')}
					placeholder={t('common.form.state.placeholder')}
					name="state"
					validate={[validators.required, validators.minimumLength2, validators.maximumLength64]}
					search
					options={this.props.states && this.props.states.map(state => ({
						key: state.id,
						value: state.name,
						text: `${state.name} (${state.abbreviation})`,
					}))}
				/>
				<Fields.InputField
					labelText={t('common.form.city.label')}
					placeholder={t('common.form.city.placeholder')}
					name="city"
					validate={[validators.required, validators.minimumLength2, validators.maximumLength64]}
				/>
				<Fields.InputField
					labelText={t('common.form.address.label')}
					placeholder={t('common.form.address.placeholder')}
					name="address"
					validate={[validators.required, validators.minimumLength3, validators.maximumLength128]}
				/>
				<div className="button__wrapper">
					<Button
						onClick={this.props.toggleCreateNewDistributorModal}
						isGhost
						buttonSmall
					>
						Cancel
					</Button>
					<Button
						type="submit"
						isFull
						buttonSmall
					>
						Create
					</Button>
				</div>
			</form>
		);
	}
}

CreateNewDistributorModal.defaultProps = {
	states: [],
};

CreateNewDistributorModal.propTypes = {
	states: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
	states: state.organisationsReducer.states,
});

const mapDispatchToProps = dispatch => ({
	getAllStates: () => dispatch(getAllStatesAction()),
	createNewDistributor: distributorData => dispatch(createNewDistributorAction(distributorData)),
});

const CreateNewDistributorModalWithTranslation = withTranslation()(CreateNewDistributorModal);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'createNewDistributorForm',
})(CreateNewDistributorModalWithTranslation));
