import {
  MediaStateType,
  PortalIdentity,
  MediaDeviceEnumeration
} from "../models";
import { UserActionKeys, UserActionType } from "./actionTypes";

export const getIdentity = (): UserActionType => ({
  type: UserActionKeys.GET_IDENTITY
});

export const getIdentityRequest = (): UserActionType => ({
  type: UserActionKeys.GET_IDENTITY_REQUEST
});

export const getIdentitySuccess = (data: PortalIdentity): UserActionType => ({
  type: UserActionKeys.GET_IDENTITY_SUCCESS,
  identity: data
});

export const getIdentityFailed = (): UserActionType => ({
  type: UserActionKeys.GET_IDENTITY_FAILED
});

export const getLocalMedia = (): UserActionType => ({
  type: UserActionKeys.GET_LOCAL_MEDIA
});

export const getLocalMediaRequest = (): UserActionType => ({
  type: UserActionKeys.GET_LOCAL_MEDIA_REQUEST
});

export const getLocalMediaSuccess = (
  data: MediaDeviceEnumeration
): UserActionType => ({
  type: UserActionKeys.GET_LOCAL_MEDIA_SUCCESS,
  mediaDeviceEnumeration: data
});

export const getLocalMediaFailed = (): UserActionType => ({
  type: UserActionKeys.GET_LOCAL_MEDIA_FAILED
});

export const setHasJoinedRoom = (data: boolean): UserActionType => ({
  type: UserActionKeys.SET_HAS_JOINED_ROOM,
  hasJoinedRoom: data
});

export const setRefreshInProgress = (
  refreshInProgress: boolean
): UserActionType => ({
  type: UserActionKeys.SET_REFRESH_IN_PROGRESS,
  refreshInProgress
});

export const setMediaState = (data: MediaStateType): UserActionType => ({
  type: UserActionKeys.SET_MEDIA_STATE,
  mediaState: data
});

export const endCall = (): UserActionType => ({
  type: UserActionKeys.END_CALL
});

export const setEndCall = (): UserActionType => ({
  type: UserActionKeys.SET_END_CALL
});

export const startAccessTokenTimer = (): UserActionType => ({
  type: UserActionKeys.UPDATE_ACCESS_TOKEN_TIMER
});

export const updateAccessToken = (
  identity: PortalIdentity
): UserActionType => ({
  type: UserActionKeys.UPDATE_ACCESS_TOKEN,
  identity
});
