import React from 'react';
import { connect } from 'react-redux';
import MissedCallItem from './components/MissedCallItem/MissedCallItem';
import { sendCallbackAction } from '../../../Meeting/MeetingActions';
import { EntityProfileSidebar, ProfileImage } from '../../../../Common';
import userTypes from '../../../../../../Common/Constants/userTypes';
import UserService from '../../../../../../Common/Services/userService';
import "./MissedCalls.scss"

const MISSED_CALLS_LIMIT = 10;

function MissedCalls({ currentUser, meetingTime: meetings, sendCallback }) {
	const missedCalls = meetings.filter(meeting => meeting.callStatus === "MISSED");
	const mostRecent = [...missedCalls].reverse().slice(0, MISSED_CALLS_LIMIT);
	// Currently limited to 10 most recent missed calls

	return (
		<div className="missed-calls__sidebar-container">
			<EntityProfileSidebar title="Missed Calls">
				<div className="missed-calls__sidebar">
					<ProfileImage
						size="md"
						initials={UserService.getUserInitials(currentUser.firstName, currentUser.lastName)}
						className={`profile-image--${userTypes[currentUser.userTypes[0]].className} display--inline`}
					/>
					<span className="user-name">{`${currentUser.firstName} ${currentUser.lastName}`}</span>
				</div>
			</EntityProfileSidebar>

			<div className="missed-calls__right-side">
				{mostRecent.length === 0 
					? <h1 className="missed_calls__none--text">No missed calls.</h1> 
					: (
						<>
							{mostRecent.map(meeting => (
								<MissedCallItem 
									callEvent={meeting} 
									key={meeting.id} 
									action={() => sendCallback(meeting)}
								/>
							))}
						</>
					)
				}
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	currentUser: state.homeReducer.currentUser,
	meetingTime: state.schedulerReducer.meetingTime,
});

const mapDispatchToProps = dispatch => ({
	sendCallback: meeting => dispatch(sendCallbackAction(meeting)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissedCalls);
