import React from 'react';
import { Button } from '../../../../Common/Components';
import "./NotificationBanner.scss"

const NotificationBanner = ({ bgColor, time, text, action, actionText, icon }) => (
	<div className={`notification-banner color-${bgColor}`}>
		<section>
      {icon ? <span><img className="banner-icon" src={icon} ></img></span>: null}
			{time ? <span className="banner-element text bold">{time}</span> : null}
			<span className="banner-element text">{text}</span>
		</section>

		{action
			? <section>
					<Button isFull className="banner-btn" onClick={action}>{actionText}</Button>
				</section>
			: null
		}
	</div>
)

export default NotificationBanner;
