import resources from '../../../../Common/Constants/resources';
import { urlService, notificationService } from '../../../../Common/Services';
import ApiHelper from '../../../../Common/Services/ApiHelper';

export const uploadLogo = async (providerName, logoFile) => {
	// eslint-disable-next-line no-undef
	const formData = new FormData();
	formData.append('file', logoFile);

	try {
		const fileResponse = await ApiHelper.post(
			'coreregistry',
			urlService.formatString(resources.providerLogo, providerName),
			formData,
		);

		return fileResponse.content.uploadedLocation;
	} catch {
		notificationService.error('Failed to upload image.');
		throw new Error('Failed to upload image.'); // Bubble up
	}
};
