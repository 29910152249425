import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";

import { useDispatch } from "react-redux";

import clsx from "clsx";

import { ChevronRight } from "@mui/icons-material";

import { Button } from "src/components/Button";
import {
  LeftFrameIcon,
  RightFrameIcon,
  TwoViewsSquareIcon,
} from "src/components/CustomIcons";
import { Font } from "src/components/Font";
import { List } from "src/components/List";
import { ORTelligence } from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import {
  selectCameras,
  selectIsIntegrationActive,
  selectLayoutFrames,
  selectLayoutFramesSnapshot,
  selectZoomState,
  selectZoomStateSnapshot,
} from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleLayoutChangeThunk } from "src/domains/Beacon/store/stream/thunks";
import { publishShareScreenTrackThunk } from "src/domains/Beacon/store/stream/thunks/publishShareScreenTrackThunk";
import { unpublishShareScreenTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishShareScreenTrackThunk";
import { unpublishVideoTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishVideoTrackThunk";
import {
  LayoutFrameNames,
  LayoutFrames,
  ZoomFrames,
} from "src/domains/Beacon/store/stream/types";
import { uiActions } from "src/domains/Beacon/store/ui";
import { selectSidePanelLayouts } from "src/domains/Beacon/store/ui/selectors";
import { UISidePanelContentOptions } from "src/domains/Beacon/store/ui/types";
import {
  getCameraDisplayName,
  getCameraIdentifier,
  videoSourceZoomLevel,
} from "src/domains/Beacon/utils/availKit";
import { createObjectClone } from "src/domains/Beacon/utils/objects";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { logger } from "src/logging/logger";

import styles from "./styles.scss";

export const LayoutsContent = () => {
  const { frameSelected } = useAppSelector(selectSidePanelLayouts);
  const layoutFrames = useAppSelector(selectLayoutFrames);
  const layoutFramesSnapshot = useAppSelector(selectLayoutFramesSnapshot);
  const cameras = useAppSelector(selectCameras) as AVKCamera[];
  const zoomState = useAppSelector(selectZoomState);
  const zoomStateSnapshot = useAppSelector(selectZoomStateSnapshot);
  const {
    externalInputsImageResizing,
    oRtelligence: enableORTelligence,
  } = useFeatureFlags();
  const isIntegrationActive = useAppSelector(selectIsIntegrationActive);
  const dispatch = useDispatch();

  // Common function that happens when selecting a layout
  const dispatchLayoutChange = async (desiredLayoutFrames: LayoutFrames) => {
    await dispatch(
      handleLayoutChangeThunk({
        externalInputsImageResizing,
        newLayoutFrames: desiredLayoutFrames,
      })
    );
  };

  const items =
    cameras?.map((camera) => {
      const cameraId = getCameraIdentifier(camera);
      return {
        id: cameraId,
        title: getCameraDisplayName(camera),
        // we want a subtitle for ORTelligence so add that to just that item
        // if we have to make more special cases then we can move this into its own section
        // rather than in this map loop
        subtitle:
          enableORTelligence && cameraId === ORTelligence ? "APPLICATION" : "",
        zoom: videoSourceZoomLevel(camera),
        // Only one 3rd party integration must be active if shown
        disabled: isIntegrationActive && cameraId === ORTelligence,
      };
    }) ?? [];

  // changes the layouts state and executes AvailKit event for layout change
  const handleClick = async (cameraId: any) => {
    logger().info("Handling layout change to : " + cameraId);

    // Construct a new layoutFrame
    const camera = items.find((item) => item.id === cameraId);

    const newLayoutFrames: LayoutFrames = createObjectClone({
      ...layoutFrames,
      [frameSelected]: {
        cameraId: camera.id,
        cameraLabel: camera.title,
        isFullScreen: layoutFrames[frameSelected].isFullScreen,
      },
    });

    const newZoomState: ZoomFrames = {
      ...zoomState,
      [frameSelected]: {
        cameraId: camera.id,
        value: camera.zoom * 10,
        settings: zoomState[frameSelected].settings,
      },
    };

    // TODO: name ORTelligence to something generic as a 3rd party integration
    // User has selected a third party integration app
    if (enableORTelligence && cameraId === ORTelligence) {
      if (!isIntegrationActive) {
        logger().info("User has selected a third party integration layout");

        // Must let know that there's an integration been active
        dispatch(streamActions.setIntegrationActive(true));

        // Taking snapshot before changing to integration
        // persist to Redux to prevent sideeffects when we stop screen sharing
        dispatch(streamActions.setLayoutFramesSnapshot(layoutFrames));
        dispatch(streamActions.setZoomStateSnapshot(zoomState));

        // Must stop Host's Pip video track to start sharing screen
        await dispatch(unpublishVideoTrackThunk());

        // Will take care of sharing screen and reset Layouts and Host's Pip video
        // if screen share stops
        await dispatch(
          publishShareScreenTrackThunk({
            enableORTelligence,
            externalInputsImageResizing,
            integrationName: cameraId,
          })
        );
      }
      // 3. If an integration is active and the other layout frame changes input, the layout frame with integration must be preserved
    } else {
      // If there's an integration being shown, then must check if integration's
      // stream should end or preserved
      if (isIntegrationActive) {
        logger().info("A third party integration layout is still active");
        const integrationLink = layoutFrames[frameSelected]?.integrationLink;

        // If the frame selected has the integration running, then must be stopped and
        // changed to the desired layout selected
        if (integrationLink && integrationLink !== "") {
          logger().info(
            "Third party integration layout has been changed, screen sharing stopped"
          );
          await dispatch(unpublishShareScreenTrackThunk());

          // Re-setting the new layouts to the new selected by the user
          await dispatchLayoutChange(newLayoutFrames);

          // Re-setting the new layouts selected by the user
          dispatch(streamActions.setLayoutFrames(newLayoutFrames));
          dispatch(streamActions.setZoomState(newZoomState));

          // Must deactivate integration to allow the user to select another one
          dispatch(streamActions.setIntegrationActive(false));
        } else {
          const layoutFrameToPreserve =
            frameSelected === "leftTop" ? "rightTop" : "leftTop";

          logger().info(
            `Opposite Integration layout side changed, preserving the TPI layout on side ${layoutFrameToPreserve}`
          );

          const cameraToPreserve = layoutFramesSnapshot[layoutFrameToPreserve];
          const zoomToPreserve = zoomStateSnapshot[layoutFrameToPreserve];

          const layoutFramesToPreserve = {
            ...newLayoutFrames,
            [layoutFrameToPreserve]: cameraToPreserve,
          };

          const zoomStateToPreserve = {
            ...newZoomState,
            [layoutFrameToPreserve]: zoomToPreserve,
          };

          // Must store the new layout change of the opposite side but keeping
          // the integration's background camera
          // persist to Redux to prevent sideeffects when we stop screen sharing
          dispatch(
            streamActions.setLayoutFramesSnapshot(layoutFramesToPreserve)
          );
          dispatch(streamActions.setZoomStateSnapshot(zoomStateToPreserve));

          // Will broadcast the new layouts event to Console
          await dispatchLayoutChange(layoutFramesToPreserve);

          // This will update the Layouts list selection.
          // Must keep the integration layout option selected but with the new
          // layout camera selected in the opposite frame
          dispatch(streamActions.setLayoutFrames(newLayoutFrames));
          dispatch(streamActions.setZoomState(zoomStateToPreserve));
        }
      } else {
        // Normal layout selection happened
        logger().info("User has selected a normal layout option");
        await dispatchLayoutChange(newLayoutFrames);
        dispatch(streamActions.setLayoutFrames(newLayoutFrames));
        dispatch(streamActions.setZoomState(newZoomState));
      }
    }
  };

  // Sets the new state of the selected layout frame side either left | right
  const handleChangeFrame = (side: LayoutFrameNames) => {
    dispatch(uiActions.setLayoutFrame(side));
  };

  const handleOpenPresets = () => {
    dispatch(uiActions.setSidePanelContent(UISidePanelContentOptions.PRESETS));
  };

  const leftTopFrameActive = frameSelected === "leftTop";
  const rightTopFrameActive = frameSelected === "rightTop";

  return (
    <>
      <div className={styles.header}>
        <TwoViewsSquareIcon color="white" containerClassName={styles.icon} />
        <Font variant="h3" color="light">
          2 View Layout
        </Font>
      </div>
      <div className={styles.container}>
        <div className={styles.buttons}>
          <Button
            data-test-id="left-frame-button"
            variant="icon"
            wrapperClassName={styles.buttonWrapper}
            className={clsx(styles.button, {
              [styles.inactive]: !leftTopFrameActive,
            })}
            tooltipProps={{
              title: "Left frame",
              placement: "top",
            }}
            startIcon={<LeftFrameIcon size="xs" />}
            active={leftTopFrameActive}
            onClick={() => handleChangeFrame("leftTop")}
          />
          <Button
            data-test-id="right-frame-button"
            variant="icon"
            wrapperClassName={styles.buttonWrapper}
            className={clsx(styles.button, {
              [styles.inactive]: !rightTopFrameActive,
            })}
            tooltipProps={{
              title: "Right frame",
              placement: "top",
            }}
            startIcon={<RightFrameIcon size="xs" />}
            active={rightTopFrameActive}
            onClick={() => handleChangeFrame("rightTop")}
          />
        </div>
        <div className={styles.list}>
          <List
            items={items}
            onClick={handleClick}
            selectedId={layoutFrames[frameSelected]?.cameraId}
            clickable
          />
        </div>
        <div className={styles.presets}>
          <Button
            data-test-id="presets-button"
            wrapperClassName={styles.buttonWrapper}
            className={clsx(styles.presetsButton)}
            active
            label="Go To Presets"
            endIcon={<ChevronRight />}
            onClick={handleOpenPresets}
          />
        </div>
      </div>
    </>
  );
};
