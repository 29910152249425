import { multiply, format } from 'mathjs';

class NumberService {
	static formatWithDelimiters(number, precision = 2, thousands = '', decimal = '.') {
    if (isNaN(number) || number == null) { // eslint-disable-line
			return 0;
		}

		const precNumber = this.toDollars(number).toFixed(precision);

		const parts = precNumber.split('.');

		const dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${thousands}`);

		const cents = parts[1] ? decimal + parts[1] : '';

		return dollars + cents;
	}


	static toCents(numberToConvert) {
		return this.format(this.multiply(numberToConvert, 100));
	}

	static toDollars(number) {
		return number / 100.0;
	}

	static multiply(...args) {
		return this.format(multiply(...args));
	}

	static format(numberToFormat, precision = 14) {
		return Number(format(Number(numberToFormat), precision));
	}

	static formatCurrency(numberToFormat, precision = 2) {
		return numberToFormat.toFixed(precision).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	}
}


export default NumberService;
