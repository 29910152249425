import React from "react";
import { Route, Switch } from "react-router-dom";

import { BeaconWithState as Beacon } from "src/domains/Beacon";
import { EmbeddedHelpCenter } from "src/domains/HelpCenter/EmbeddedHelpCenter/EmbeddedHelpCenter";
import { TrialSignUp } from "src/domains/TrialSignUp";
import { FEATURE_FLAGS } from "src/featureFlags";
import HomeView from "src/routes/HomeView";

import { EventLandingPage } from "avail-web-ui/domains/Events/EventLandingPage";

import FourOFourPage from "../../avail-web-application/src/Common/Components/404Page/404Page";
import PrivateRoute from "../../avail-web-application/src/Common/Components/PrivateRoute/PrivateRoute";
import {
  AccountSetup,
  Checkemail,
  ForgetPassword,
  Login,
  LoginGuestView,
  RegisterScreen,
  SelectChannel,
  VerifyPasscode,
  SetupPhone,
  MailMFAAuthentication,
  MailOTPAuthentication,
  OTPFailure,
  OTPLockout,
  OTPSuspended,
  PasswordScreen,
  ResetPassword,
  ResetPasswordExpire,
  SignUp,
  UserSuspendedView,
  UserDenied,
} from "../../avail-web-application/src/Features/Authentication/Features";
import MultiProfileLogin from "../../avail-web-application/src/Features/Authentication/Features/Login/Features/MultiProfileLogin/MultiProfileLogin";
import PortalCallApp from "../../portalcall/src/App";

// In the future as the routes grow, we can split them into sub routers
export const AppRoutes = () => {
  return (
    <Switch>
      <Route path="/accountSetup" component={AccountSetup} />
      <Route path="/login" component={Login} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/forgot-password" component={ForgetPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/expire-password" component={ResetPasswordExpire} />
      <Route path="/multi-profile-login" component={MultiProfileLogin} />
      <Route path="/check-email-confirm" component={Checkemail} />
      <Route path="/not-found" component={FourOFourPage} />
      <Route path="/verify-mfa-otp" component={MailMFAAuthentication} />
      <Route path="/verify-guest-otp" component={MailOTPAuthentication} />
      <Route path="/portalcall" component={PortalCallApp} />
      <Route path="/event-login" component={LoginGuestView} />
      <Route path="/guest-user-register" component={RegisterScreen} />
      <Route path="/auth-user-login" component={PasswordScreen} />
      <Route path="/otp-failure" component={OTPFailure} />
      <Route path="/otp-lockout" component={OTPLockout} />
      <Route path="/otp-suspended" component={OTPSuspended} />
      <Route path="/select-mfa-type" component={SelectChannel} />
      <Route path="/verify-passcode" component={VerifyPasscode} />
      <Route path="/user-denied" component={UserDenied} />
      <Route path="/suspended" component={UserSuspendedView} />
      {FEATURE_FLAGS.showTrialSignUpPage && (
        <Route path="/trial-sign-up" component={TrialSignUp} />
      )}

      <Route path="/beacon" component={Beacon} />

      {/* /event and /help-center route cannot go inside HomeView
       * as it should NOT render navbar and sidebars, but they are private meaning the user has to be logged in
       */}
      <PrivateRoute path="/event" component={EventLandingPage} />
      <PrivateRoute path="/help-center" component={EmbeddedHelpCenter} />
      <PrivateRoute path="/add-phone" component={SetupPhone} />

      {/* All logged in routes go here in the HomeView component  */}
      <PrivateRoute path="/" component={HomeView} />
    </Switch>
  );
};
