import { AVKTelestrationClearEvent } from "availkit-js/dist/Models/Events/AVKTelestrationClearEvent";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { initialState } from "src/domains/Beacon/store/telestration";
import { clearLocalAnnotationsThunk } from "src/domains/Beacon/store/telestration/thunks/clearLocalAnnotationsThunk";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

// does all the actions necessary to turn off draw mode
export const handleTurnOffDrawModeThunk = createAsyncThunk<
  void,
  // First argument to the payload creator
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("telestration/handleTurnOffDrawModeThunk", (_arg, { getState, dispatch }) => {
  try {
    logger().info("Turning off telestration");
    const availKit = AvailKitService.instance;

    const { meeting } = getState();
    const { callSid, joinId } = meeting;

    const teleStrationClearEvent = new AVKTelestrationClearEvent(callSid, null);
    availKit.eventService.broadcast(teleStrationClearEvent);

    logger().logWithFields(
      LoggerLevels.info,
      { feature: "handleTurnOffDrawModeThunk" },
      "Turning off Draw Mode"
    );
    teleStrationClearEvent.sender = joinId;

    // clear user drawings
    dispatch(clearLocalAnnotationsThunk());
    // reset penStyle
    dispatch(telestrationActions.setStyle(initialState.penStyle));
    // close DrawTools
    dispatch(telestrationActions.setIsDrawModeOn(false));
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      { feature: "handleTurnOffDrawModeThunk" },
      "Error turning off telestration",
      error?.message
    );
    throw new Error("Clearing telestration failed");
  }
});
