import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { Grid, Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { FormErrorMessage } from "src/components/legacy/Errors";
import { TextField } from "src/components/legacy/TextField";
import { history } from "src/reducer";
import {
  checkIfTrialAvailibleForOrganization,
  DomainValidationErrorCodes,
} from "src/services/ApiClient/users";

import { emailField } from "avail-web-ui/constants/validation";
import { addNotificationAction } from "avail-web-ui/dux/NotificationSlice";

import styles from "./styles.scss";

interface FormProps {
  setUserEmail: (email: string) => void;
  continueToNextStep: () => void;
}

export const GetStartedForm = ({
  setUserEmail,
  continueToNextStep,
}: FormProps) => {
  const { handleSubmit, control, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      userEmail: "",
    },
  });

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState(0);

  const submitEmail = async (data) => {
    const email = data.userEmail;

    const {
      response,
      data: result,
      error,
    } = await checkIfTrialAvailibleForOrganization(email);

    // if we have an error then show a notification error
    if (error) {
      dispatch(
        addNotificationAction({
          message: error.message,
          type: "error",
        })
      );
    } else {
      if (response.ok) {
        // if no error then go the next step
        setUserEmail(email);

        // go to the next step of the trial sign up
        continueToNextStep();
      } else {
        // check error codes here
        if (response.status >= 400) {
          setErrorMessage(result.message);
          setErrorCode(result.errorCode);
        }
      }
    }
  };

  const clearErrors = () => {
    setErrorCode(0);
    setErrorMessage("");
  };

  const getErrorActionUI = (code: number) => {
    switch (code) {
      case DomainValidationErrorCodes.TRIAL_ACCOUNT_ALREADY_EXISTS:
      case DomainValidationErrorCodes.MEMBER_ACCOUNT_ALREADY_EXISTS:
        return (
          <Button
            data-test-id="trial-sign-up-error-login-button"
            theme="green"
            size="large"
            fullWidth
            onClick={() => {
              history.push("/login");
            }}
          >
            Login
          </Button>
        );
      case DomainValidationErrorCodes.EXPIRED_TRIAL_ACCOUNT_ALREADY_EXISTS:
      case DomainValidationErrorCodes.DISABLED_MEMBER_ACCOUNT_ALREADY_EXISTS:
        return (
          <Button
            data-test-id="trial-sign-up-contact-us-button"
            theme="green"
            size="large"
            fullWidth
            onClick={() => {
              window.open("https://www.avail.io/contact/", "_blank");
            }}
          >
            Contact us
          </Button>
        );

      case DomainValidationErrorCodes.ORG_NOT_TRIAL_ELIGIBLE:
        return (
          <>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              data-test-id="trial-sign-up-org-not-elgigble-for-trial"
            >
              <Grid item container justifyContent="center">
                <Typography
                  className={styles.trialNotEligibleTitle}
                  variant="h6"
                >
                  We're sorry! Your organization is not eligible for trial sign
                  up
                </Typography>
              </Grid>
              <Grid item container alignItems="center" justifyContent="center">
                <Typography
                  className={styles.trialNotEligibleMessage}
                  variant="h5"
                >
                  Please contact us to speak with an Avail Sales Representative
                  about joining the Avail Network
                </Typography>
              </Grid>
            </Grid>
            <Button
              data-test-id="trial-sign-up-contact-us-button"
              theme="green"
              size="large"
              fullWidth
              onClick={() => {
                window.open("https://www.avail.io/contact/", "_blank");
              }}
            >
              Contact us
            </Button>
          </>
        );
      case DomainValidationErrorCodes.TRIAL_ACCOUNT_NOT_ACTIVATED:
        // no specific text or button for this code, just show the BE error message
        return null;
    }
  };

  const errorComponent = (
    <>
      {/* basically show the BE error message for all error codes, except this one
        we define the error message in here, in the above function */}
      {errorCode !== DomainValidationErrorCodes.ORG_NOT_TRIAL_ELIGIBLE && (
        <Typography
          data-test-id="trial-sign-up-error-message"
          className={styles.errorMessage}
          variant="h5"
        >
          {errorMessage}
        </Typography>
      )}

      <Grid direction="column" container>
        {getErrorActionUI(errorCode)}
      </Grid>
      <Grid
        direction="row"
        container
        item
        justifyContent="space-around"
        className={styles.errorCancelButtonWrapper}
      >
        {/* extra divs are just for easy padding */}
        <div />
        <Button
          variant="text"
          theme="primary"
          onClick={() => clearErrors()}
          data-test-id="trial-sign-up-error-cancel-button"
        >
          Cancel
        </Button>

        <div />
      </Grid>
    </>
  );
  const formFields = (
    <>
      <Grid item container justifyContent="center">
        <Typography variant="h6">Let's get you started</Typography>
      </Grid>
      <Grid item container justifyContent="center" className={styles.wrapper}>
        <Typography style={{ fontWeight: 300 }} variant="h5">
          What is your work email address?
        </Typography>
      </Grid>

      <Grid item container className={styles.wrapper}>
        <Controller
          name={"userEmail"}
          control={control}
          rules={{
            ...emailField("Email", true),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <TextField
                  data-test-id="trial-sign-up-user-email"
                  label="Work Email Address"
                  placeholder="Work Email Address"
                  required
                  onChange={onChange}
                  value={value}
                />
                {error && <FormErrorMessage message={error.message} />}
              </>
            );
          }}
        />
      </Grid>
      <Grid item container>
        <Button
          data-test-id="trial-get-started-next-button"
          theme="green"
          type="submit"
          loading={formState.isSubmitting}
          disabled={!formState.isValid || formState.isSubmitting}
          fullWidth
        >
          Next
        </Button>
      </Grid>
    </>
  );
  return (
    <>
      <form
        data-test-id="trial-get-started-form"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(submitEmail)}
        className={styles.form}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {errorMessage ? errorComponent : formFields}
        </Grid>
      </form>
    </>
  );
};
