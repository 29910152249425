import {Logger} from "splunk-logging"
Logger.prototype._post = function(requestOptions, callback) {
	// Do nothing
	// TODO: Migrate this logging stuff to the portalcall implementation
}

const SPLUNK_LOGGING_TOKEN = process.env.REACT_APP_SPLUNK_LOGGING_TOKEN || '';
const SPLUNK_LOGGING_URL = process.env.REACT_APP_SPLUNK_LOGGING_URL || '';
const PORTAL_LOG_ENV = process.env.REACT_APP_AVAIL_LOG_ENV || "dev";

const logLevels = {
	off: 0,
	error: 1,
	warn: 2,
	info: 3,
	verbose: 4,
	debug: 5
}

// Init
const logger = new Logger({
	token: SPLUNK_LOGGING_TOKEN,
	url: SPLUNK_LOGGING_URL
});

class LogService {
	static commonFields = {
		component: "PORTAL_FRONTEND",
		env: PORTAL_LOG_ENV,
		loggerKey: "build",
		tic: -1,
		message: null
	};

	static _baseLogLevel = logLevels.info;

	static sendLog(severity, message) {
		// Filter whether this message gets posted to Splunk
		if (severity > this._baseLogLevel) return;

		const payload = {
			...this.commonFields,
			message,
			tic: Date.now(),
			level: logLevels[severity]
		};

		// Workaround for CORS issue on localhost
		if (window.location.hostname === "localhost") return;

		logger.send(payload, function (err, resp, body) {
			if (err) console.error("error:", err);
			// If successful, body will be { text: 'Success', code: 0 }
		});
	}

	static info(message) {
		try {
			this.sendLog(logLevels.info, message);
			console.info(message);
		} catch (e) { }
	}

	static warn(message) {
		try {
			this.sendLog(logLevels.warn, message);
			console.warn(message);
		} catch (e) { }
	}

	static error(message) {
		try {
			this.sendLog(logLevels.error, message);
			console.error(message);
		} catch (e) { }
	}

	static debug(message) {
		try {
			this.sendLog(logLevels.debug, message);
			console.debug(message);
		} catch (e) { }
	}

	static verbose(message) {
		try {
			this.sendLog(logLevels.verbose, message);
			console.log(message);
		} catch (e) { }
	}
}

export default LogService;
