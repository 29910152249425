export const AUTH = {
	SUBMIT_LOGIN: 'AUTH_SUBMIT_LOGIN',
	CHECK_GUEST_EMAIL: 'CHECK_GUEST_EMAIL',
	AUTH_GUEST_USER: 'AUTH_GUEST_USER',
	REGISTER_GUEST_USER: 'REGISTER_GUEST_USER',
	SUBMIT_LOGIN_SUCCESS: 'AUTH_SUBMIT_LOGIN_SUCCESS',
	MULTI_PROFILE_LOGIN: 'AUTH_MULTI_PROFILE_LOGIN',
	LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
	LOGOUT: 'AUTH_LOGOUT',
	GUEST_LOGOUT: 'AUTH_GUEST_LOGOUT',
	IS_LOGGED_IN: 'AUTH_IS_LOGGED_IN',
	SIGN_UP: 'AUTH_SIGN_UP',
	FORGOT_PASSWORD: 'FORGOT_PASSWORD',
	RESET_PASSWORD: 'RESET_PASSWORD',
	MAIL_AUTH: 'MAIL_AUTH',
	RESEND_OTP: 'RESEND_OTP',
	RESEND_GUEST_OTP: 'RESEND_GUEST_OTP',
	SET_OTP_INFO: 'SET_OTP_INFO',
	SET_AUTH_ERROR: 'SET_AUTH_ERROR',
	MFA_SEND_LOGIN_OTP: "MFA_SEND_LOGIN_OTP",
	MFA_VERIFY_LOGIN_OTP: "MFA_VERIFY_LOGIN_OTP",
	MFA_RESEND_LOGIN_OTP: "MFA_RESEND_LOGIN_OTP",
	MFA_SETUP_PHONE_NUMBER: "MFA_SETUP_PHONE_NUMBER",
	SET_MFA_CHANNEL: "SET_MFA_CHANNEL"
};
