import billingCallQuality from "./billingCallQuality";
import connectTwilioCall from "./connectTwilioCall";
import createLocalTracks from "./createLocalTracks";
import eventInfo from "./eventInfo";
import identity from "./identity";
import localMedia from "./localMedia";
import meetingHeartbeat from "./meetingHeartbeat";
import networkQualityPoll from "./networkQualityPoll";
import getConsolesBySessionId from "./getConsolesBySessionId";
import refreshTwilioToken from "./refreshTwilioToken";
import twilioCredentials from "./twilioCredentials";
import {
  callSessionBySessionId,
  telestrationsBySessionId,
  uploadFreezeFrame,
  freezeFrameImageById,
  removeFreezeFrameImageById,
  allFreezeFrameImages
} from "./presenceService";

export const API = {
  GET: {
    createLocalTracks,
    connectTwilioCall,
    eventInfo,
    localMedia,
    getConsolesBySessionId,
    refreshTwilioToken,
    twilioCredentials,
    callSessionBySessionId,
    telestrationsBySessionId,
    freezeFrameImageById,
    allFreezeFrameImages
  },

  POST: {
    identity,
    meetingHeartbeat,
    networkQualityPoll,
    uploadFreezeFrame
  },

  PUT: {
    billingCallQuality
  },

  DELETE: {
    removeFreezeFrameImageById
  }
};
