import { RemoteParticipant, RemoteTrackPublication } from "twilio-video";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useRoomTrackDisabled } from "src/domains/Beacon/components/TwilioSubscriptions/useRoomTrackDisabled";
import { useRoomTrackEnabled } from "src/domains/Beacon/components/TwilioSubscriptions/useRoomTrackEnabled";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { selectFreezeFrameState } from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import {
  getTwilioLoginId,
  isConsoleParticipant,
  whoPublishedTrack,
} from "src/domains/Beacon/utils/twilio";

// Wraps the two hooks for enable/disable room events in Twilio
// detecting both of them and setting the new tracks to the redux state
// this helps to avoid duplicated code in Twilio subscriptions
export const ToggleTrackEnableDisable = () => {
  const { room } = useAppSelector(selectTwilioState);
  const { callDetails, mode } = useAppSelector(selectMeetingState);
  const freezeFrameState = useAppSelector(selectFreezeFrameState);
  const dispatch = useDispatch();
  const {
    hostTrackEnabled,
    consoleTrackEnabled,
    participantTrackEnabled,
  } = useRoomTrackEnabled();

  const {
    hostTrackDisabled,
    consoleTrackDisabled,
    participantTrackDisabled,
  } = useRoomTrackDisabled();

  // Sets the new host's video/audio tracks to the local state
  const setHostTrack = (
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) => {
    // prints the corresponding logger when enabled/disabled
    if (publication.isTrackEnabled) {
      hostTrackEnabled(publication);
    } else {
      hostTrackDisabled(publication);
    }
    const { track } = publication;
    if (participant.tracks.has(track?.sid)) {
      if (track.kind === "video") {
        dispatch(streamActions.setHostVideoTrack(track));
      } else if (track.kind === "audio") {
        dispatch(streamActions.setHostAudioTrack(track));
      }
    }
  };

  // Sets the new console's video/audio tracks to the local state
  const setConsoleTrack = (
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) => {
    // prints the corresponding logger when enabled/disabled
    if (publication.isTrackEnabled) {
      consoleTrackEnabled(publication);
    } else {
      consoleTrackDisabled(publication);
    }

    const { track } = publication;
    if (participant.tracks.has(track?.sid)) {
      if (track.kind === "video") {
        dispatch(streamActions.setConsoleVideoTrack(track));
      } else if (track.kind === "audio") {
        dispatch(streamActions.setConsoleAudioTrack(track));
      }
    }
  };

  // Sets the new participant's video/audio tracks to the local state
  const setParticipantTrack = (
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) => {
    // prints the corresponding logger when enabled/disabled
    if (publication.isTrackEnabled) {
      participantTrackEnabled(publication);
    } else {
      participantTrackDisabled(publication);
    }
    const { track } = publication;
    if (participant.tracks.has(track?.sid)) {
      if (track.kind === "video") {
        // TODO: panelist with video? could be implemented in a future
      } else if (track.kind === "audio") {
        const loginId = getTwilioLoginId(participant.identity);
        dispatch(
          streamActions.setParticipantAudioTrack({
            loginId,
            audioTrack: track,
          })
        );
      }
    }
  };

  useEffect(() => {
    // Detects when a track is enabled for host|console|participant
    const onTrackEnabled = (
      publication: RemoteTrackPublication,
      participant: RemoteParticipant
    ) => {
      if (mode === CallModes.MP) {
        whoPublishedTrack({
          participant,
          publication,
          callDetails,
          wasHost: () => setHostTrack(publication, participant),
          wasConsole: () => setConsoleTrack(publication, participant),
          wasParticipant: () => setParticipantTrack(publication, participant),
        });
      } else if (mode === CallModes.P2P) {
        const isConsole = isConsoleParticipant(participant);
        if (isConsole) {
          setConsoleTrack(publication, participant);
        } else {
          setHostTrack(publication, participant);
        }
      }
    };

    // Detects when a track is disabled for host|console|participant
    const onTrackDisabled = (
      publication: RemoteTrackPublication,
      participant: RemoteParticipant
    ) => {
      if (mode === CallModes.MP) {
        whoPublishedTrack({
          participant,
          publication,
          callDetails,
          wasHost: () => setHostTrack(publication, participant),
          wasConsole: () => setConsoleTrack(publication, participant),
          wasParticipant: () => setParticipantTrack(publication, participant),
        });
      } else if (mode === CallModes.P2P) {
        const isConsole = isConsoleParticipant(participant);
        if (isConsole) {
          setConsoleTrack(publication, participant);
        } else {
          setHostTrack(publication, participant);
        }
      }
    };

    room.on("trackEnabled", onTrackEnabled);
    room.on("trackDisabled", onTrackDisabled);

    // have to do the clean up because of the redux variables in this use effect dependency
    return function cleanup() {
      room.removeListener("trackEnabled", onTrackEnabled);
      room.removeListener("trackDisabled", onTrackDisabled);
    };
  }, [mode, freezeFrameState.active]);

  return null;
};
