import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import Can from '../../../../../../../../Common/Acl/AccessControl';
/* Components */
import { Button, Fields, validators } from '../../../../../../../../Common/Components';
import ModalComponent from '../../../../../../../../Common/Components/Modal/Modal';
import { Table } from '../../../../../../Common';
import { getcurrentUserTypes } from '../../../../../../HomeSelectors';
/* Actions */
import { applyToHospitalAction, applyToHospitalForTrialAction, getAllHospitalsUserAppliedAction, removeHospitalApplicationAction } from '../../../../UsersActions';
/* Selectors */
import { getAllDescendantUsersIds } from '../../../../UsersSelectors';
import HospitalApplicationRow from './Components/HospitalApplicationRow/HospitalApplicationRow';
import { isTrialUser } from "src/utils/user";

class UserHospitals extends PureComponent {
	state = {
		isAddNewHospitalModalOpened: false,
	};

	componentDidMount() {
		this.params = {
			userId: this.props.userId,
		};
		this.props.getAllHospitalsUserApplied(this.params);
	}

	onAddNewHospitalHandler = (data) => {
    // trial users have a different endpoint we want to use when adding new hospitals
    // the body/response and everything else is the same, the only difference its that they get auto approved in the BE
    if (isTrialUser(this.props.user)) {
      this.props.applyToHospitalForTrial(this.props.userId, { 'ids': data.hospitals });
    } else {
      this.props.applyToHospital(this.props.userId, { 'ids': data.hospitals });
    }

		this.toggleAddNewHospitalModal();
	};

	onRemoveApplicationHandler = () => {
		this.props.removeHospitalApplication(this.props.userId, this.state.application.id, !this.state.application.blocked);
		this.toggleRemoveApplicationModal();
	};

	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = {
			userId: this.props.userId,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllHospitalsUserApplied(this.params);
	};

	getBlockedStatus = () => (this.state.application && this.state.application.blocked ? 'Unblock' : 'Block');

	toggleAddNewHospitalModal = () => {
		this.props.resetReduxForm('userHospitalsForm');
		this.setState({ isAddNewHospitalModalOpened: !this.state.isAddNewHospitalModalOpened });
	};

	toggleRemoveApplicationModal = (application = null) => {
		this.setState({
			isRemoveApplicationModalOpened: !this.state.isRemoveApplicationModalOpened,
			application,
		});
	};

	columns = [{
		title: 'Name',
		sortValue: 'name',
	}, {
		title: 'State',
		sortValue: 'state',
	}, {
		title: 'Request status',
		sortValue: 'status',
	}];
	
	toggleCreateNewUserFacility = () => {
		// this.props.resetReduxForm('createNewBusinessUnitForm');
		this.setState({ isAddNewHospitalModalOpened: !this.state.isAddNewHospitalModalOpened });
	};

	renderAddNewHospital = () => {
		const { t, user } = this.props;

		return (
			<ModalComponent
				open={this.state.isAddNewHospitalModalOpened}
				hasActionButtons={false}
				onCancel={this.toggleAddNewHospitalModal}
				title={t('features.org.hospital.create.label_add')}
			>
				<form
					className="align--center flex-direction--column"
					onSubmit={this.props.handleSubmit(this.onAddNewHospitalHandler)}
				>
          <div style={{ height: 200 }}>
            <Fields.MultiselectField
              // we want to hide the select all button if the user we are looking at is a trial user
              hideSelectAllOption={user && isTrialUser(this.props.user)}
              labelText={t('features.org.hospital.create.form.select_hospital.label')}
              placeholder={t('features.org.hospital.create.form.select_hospital.placeholder')}
              name="hospitals"
              options={this.props.availableHospitalsToApply && this.props.availableHospitalsToApply.map(hospital => ({
                key: hospital.id,
                value: hospital.id,
                label: hospital.name,
              }))}
              validate={validators.required}
            />
          </div>
					<div className="button__wrapper width--full">
						<Button isGhost buttonSmall noBorder onClick={this.toggleAddNewHospitalModal}>Cancel</Button>
						<Button isFull buttonSmall type="submit">Add</Button>
					</div>
				</form>
			</ModalComponent>
		);
	}

	renderRemoveApplicationModal = () => (
		<ModalComponent
			open={this.state.isRemoveApplicationModalOpened}
			onConfirm={this.onRemoveApplicationHandler}
			onCancel={this.toggleRemoveApplicationModal}
			confirmText={this.getBlockedStatus()}
			title={`${this.getBlockedStatus()} facility`}
		>
			{this.state.application && (
				<span>
					Are you sure that you want to {this.state.application.blocked ? 'unblock' : 'block'} facility
					<span className="text--bold">
						{` ${this.state.application.name}`}
					</span>?
				</span>
			)}
		</ModalComponent>
	);

	render() {
		const { t, user } = this.props;

		return (
			<div>
				<Table
					tableHeaderClassName="table__profile-wrapper"
					row={HospitalApplicationRow}
					tableData={this.props.hospitalApplications}
					columns={this.columns}
					urlParams={this.props.hospitalId}
					onAddNewHandler={this.toggleAddNewHospitalModal}
					openModal={this.toggleRemoveApplicationModal}
					onAddNewTitle={t('features.org.hospital.create.label_add')}
          // TODO find a new home for this check
          // basically for trial users if they have 3 or more hospitals then disable the add button
          disableAddButton={user && isTrialUser(this.props.user) && this.props.hospitalApplications.length >= 3}
					onSortTableHandler={this.onTableFilterHandler}
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					permissions={{
						userTypes: this.props.currentUserTypes,
						action: 'read',
						context: 'addNewHospital',
						data: {
							currentUserId: this.props.currentUserId,
							userProfileId: this.props.userId,
							descendantUsersIds: this.props.descendantUsersIds,
						},
					}}
					search
					sortable
					noEntityComponent={
						<div className="justify-content--space-between width--full">
							<div className="profile__right-content-wrapper">No hospital applications available</div>
						</div>
					}
				/>
				{this.renderAddNewHospital()}
				{this.renderRemoveApplicationModal()}
			</div>
		);
	}
}

UserHospitals.propTypes = {};

const mapStateToProps = state => ({
	hospitalApplications: state.usersReducer.hospitalApplications,
	availableHospitalsToApply: state.usersReducer.availableHospitalsToApply,
	currentUserTypes: getcurrentUserTypes(state),
	currentUserId: state.homeReducer.currentUser && state.homeReducer.currentUser.id,
	descendantUsersIds: getAllDescendantUsersIds(state),
});

const mapDispatchToProps = dispatch => ({
	resetReduxForm: formName => dispatch(reset(formName)),
	applyToHospital: (userId, hospitalIds) => dispatch(applyToHospitalAction(userId, hospitalIds)),
	applyToHospitalForTrial: (userId, hospitalIds) => dispatch(applyToHospitalForTrialAction(userId, hospitalIds)),
	getAllHospitalsUserApplied: userId => dispatch(getAllHospitalsUserAppliedAction(userId)),
	removeHospitalApplication: (userId, hospitalId, blockedStatus) => dispatch(removeHospitalApplicationAction(userId, hospitalId, blockedStatus)),
});

const UserHospitalsWithTranslation = withTranslation()(UserHospitals);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'userHospitalsForm',
	enableReinitialize: true,
})(UserHospitalsWithTranslation));
