import React from "react";

import clsx from "clsx";

import { CallMade } from "@mui/icons-material";

import { Button } from "src/components/Button";
import { Font } from "src/components/Font";
import { AVAIL_NETWORK_TESTING_URL } from "src/domains/Beacon/constants";

import styles from "./styles.scss";

export interface NetworkMeterProps {
  value: number;
  "data-test-id"?: string;
}

const valueToStatus = {
  1: "Poor",
  2: "Poor",
  3: "Unstable",
  4: "Good",
  5: "Good",
};

export const NetworkMeter = ({ value }) => {
  const valueToClassName = (location) => {
    return value !== undefined && location <= value
      ? valueToStatus[value].toLowerCase()
      : "inactive";
  };

  return (
    <div className={styles.root}>
      <div className={styles.statusButtonContainer}>
        <Font variant="h2" color="light" data-test-id="network-meter-status">
          {valueToStatus[value]}
        </Font>
        <Button
          variant="outlined"
          label="Test"
          endIcon={<CallMade />}
          onClick={() => {
            // opens new window tall enough to show there is a second row of
            // diagnostic tools and wide enough to not have awkward-looking
            // responsive formatting for each diagnostic tool header
            window.open(
              AVAIL_NETWORK_TESTING_URL,
              "networkTestingSuite",
              "location=yes,width=850,height=800"
            );
          }}
          data-test-id="network-meter-test-button"
          className={styles.testButton}
        />
      </div>
      <div className={styles.meter}>
        <div
          className={clsx(
            styles.meterBar,
            styles[`status--${valueToClassName(1)}`]
          )}
          data-test-id="network-meter-bar-1"
        />
        <div
          className={clsx(
            styles.meterBar,
            styles[`status--${valueToClassName(2)}`]
          )}
          data-test-id="network-meter-bar-2"
        />
        <div
          className={clsx(
            styles.meterBar,
            styles[`status--${valueToClassName(3)}`]
          )}
          data-test-id="network-meter-bar-3"
        />
        <div
          className={clsx(
            styles.meterBar,
            styles[`status--${valueToClassName(4)}`]
          )}
          data-test-id="network-meter-bar-4"
        />
        <div
          className={clsx(
            styles.meterBar,
            styles[`status--${valueToClassName(5)}`]
          )}
          data-test-id="network-meter-bar-5"
        />
      </div>
      {value < 4 && (
        <Font variant="b2" color="disabled">
          If you are experiencing poor call quality, please check your internet
          connection
        </Font>
      )}
    </div>
  );
};
