export const VENDORS = {
	GET_VENDORS: 'VENDORS_GET_VENDORS',
	SET_VENDORS: 'VENDORS_SET_VENDORS',
	GET_VENDOR_BY_ID: 'VENDORS_GET_VENDOR_BY_ID',
	SET_VENDOR: 'VENDORS_SET_VENDOR',
	CREATE_NEW_VENDOR: 'VENDORS_CREATE_NEW_VENDOR',
	DELETE_VENDOR: 'VENDORS_DELETE_VENDOR',
	DELETE_VENDOR_DISTRICT: 'VENDORS_DELETE_VENDOR_DISTRICT',
	SET_VENDORS_PAGINATION: 'VENDORS_SET_VENDORS_PAGINATION',
	SET_VENDOR_THERAPIES: 'VENDORS_SET_VENDOR_THERAPIES',
	GET_VENDOR_THERAPIES: 'USERS_GET_VENDOR_THERAPIES',
	ASSIGN_VENDOR_PROCEDURES: 'VENDORS_ASSIGN_VENDOR_PROCEDURES',
	GET_VENDORS_FOR_BRAND: 'VENDORS_GET_VENDORS_FOR_BRAND',
	SET_VENDORS_FOR_BRAND: 'VENDORS_SET_VENDORS_FOR_BRAND',
	GET_MANAGERS_FOR_BUSINESS_UNIT: 'VENDORS_GET_MANAGERS_FOR_BUSINESS_UNIT',
	SET_MANAGERS_FOR_BUSINESS_UNIT: 'VENDORS_SET_MANAGERS_FOR_BUSINESS_UNIT',
	GET_MANAGERS_BELOW_ME: 'VENDORS_GET_MANAGERS_BELOW_ME',
	SET_MANAGERS_BELOW_ME: 'VENDORS_SET_MANAGERS_BELOW_ME',
	GET_BRAND_BUSINESS_UNITS: 'VENDORS_GET_BRAND_BUSINESS_UNITS',
	SET_SELECTED_BUSINESS_UNIT: 'VENDORS_SET_SELECTED_BUSINESS_UNIT',
	SET_BUSINESS_UNITS_FOR_BRAND: 'VENDORS_SET_BUSINESS_UNITS_FOR_BRAND',
	SELECT_BUSINESS_UNIT: 'VENDORS_SELECT_BUSINESS_UNIT',
	GET_ADMINS_AND_MANAGERS: 'VENDORS_GET_ADMINS_AND_MANAGERS',
	SET_ADMINS_AND_MANAGERS: 'VENDORS_SET_ADMINS_AND_MANAGERS',
	SET_BUSINESS_UNIT_DISTRICTS: 'VENDORS_SET_BUSINESS_UNIT_DISTRICTS',
	SET_SELECTED_DISTRICT: 'VENDORS_SET_SELECTED_DISTRICT',
	SELECT_DISTRICT: 'VENDORS_SELECT_DISTRICT',
	CREATE_NEW_BUSINESS_UNIT: 'VENDORS_CREATE_NEW_BUSINESS_UNIT',
	UPDATE_BUSINESS_UNIT: 'VENDORS_UPDATE_BUSINESS_UNIT',
	UPDATE_BUSINESS_UNIT_USER_TO_MEMBER: 'VENDORS_UPDATE_BUSINESS_UNIT_USER_TO_MEMBER',
	GET_VENDORS_HOSPITALS: 'VENDORS_GET_VENDORS_HOSPITALS',
	SET_VENDORS_HOSPITALS: 'VENDORS_SET_VENDORS_HOSPITALS',
	SET_SUPPORTED_AND_NEW_HOSPITALS: 'VENDORS_SET_SUPPORTED_AND_NEW_HOSPITALS',
	UPDATE_VENDORS_HOSPITALS: 'VENDORS_UPDATE_VENDORS_HOSPITALS',
	GET_BUSINESS_UNITS_FOR_SELECTED_BRAND: 'VENDORS_GET_BUSINESS_UNITS_FOR_SELECTED_BRAND',
	SET_BUSINESS_UNIT_USERS: 'VENDORS_SET_BUSINESS_UNIT_USERS',
	SET_BUSINESS_UNIT_USERS_PAGINATION: 'VENDORS_SET_BUSINESS_UNIT_USERS_PAGINATION',
	GET_BUSINESS_UNIT_USERS: 'VENDORS_GET_BUSINESS_UNIT_USERS',
	APPLY_TO_SECONDARY_MANAGER: 'VENDORS_APPLY_TO_SECONDARY_MANAGER',
	SET_USER_THERAPIES: 'VENDORS_SET_USER_THERAPIES',
	GET_USER_THERAPIES: 'VENDORS_GET_USER_THERAPIES',
	GET_DISTRICT_AND_BU_THERAPIES: 'VENDORS_GET_DISTRICT_AND_BU_THERAPIES',
	SET_DISTRICT_AND_BU_THERAPIES: 'VENDORS_SET_DISTRICT_AND_BU_THERAPIES',
	GET_THERAPIES: 'VENDORS_GET_THERAPIES',
	SET_THERAPIES: 'VENDORS_SET_THERAPIES',
	SET_ORG_REMEMBER_FREQUENCY:'VENDORS_SET_ORG_REMEMBER_FREQUENCY',
	SET_ORG_FREQUENCY_TYPES:'VENDORS_SET_ORG_FREQUENCY_TYPES',
	SET_ORG_TWO_FACTOR_AUTH:'VENDORS_SET_ORG_TWO_FACTOR_AUTH',
	TOGGLE_MFA_ORG_LEVEL_SETTINGS: 'VENDORS_TOGGLE_MFA_ORG_LEVEL_SETTINGS',
};
