// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../avail-web-application/src/Common/Assets/Images/availtrial2.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../../avail-web-application/src/Common/Assets/Images/availtrial1.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container_\\+SsJKR{display:flex;flex-direction:column;height:100%;overflow:auto}.styles_container_\\+SsJKR .styles_image-wrapper_FjDQMN{position:relative}.styles_container_\\+SsJKR .styles_image-wrapper_FjDQMN .styles_image_AJ\\+CHA{background-size:cover;height:100%;width:100%}.styles_container_\\+SsJKR .styles_image-wrapper_FjDQMN .styles_image_AJ\\+CHA.styles_step0_i4hzbr{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.styles_container_\\+SsJKR .styles_image-wrapper_FjDQMN .styles_image_AJ\\+CHA.styles_step1_6ZGAPL{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.styles_container_\\+SsJKR .styles_forms-container_ohqq0B{height:100%}.styles_container_\\+SsJKR .styles_wrapper_qhNHmv{margin-bottom:32px}.styles_container_\\+SsJKR .styles_success-message_oyGE3h{font-weight:300;text-align:center}", "",{"version":3,"sources":["webpack://./src/domains/TrialSignUp/styles.scss"],"names":[],"mappings":"AAEA,0BACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,aAAA,CAEA,uDACE,iBAAA,CAEA,6EACE,qBAAA,CACA,WAAA,CACA,UAAA,CAEA,iGACE,wDAAA,CAGF,iGACE,wDAAA,CAKN,yDACE,WAAA,CAGF,iDACE,kBAAA,CAGF,yDACE,eAAA,CACA,iBAAA","sourcesContent":["@import \"/src/colors.scss\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow: auto;\n\n  .image-wrapper {\n    position: relative;\n\n    .image {\n      background-size: cover;\n      height: 100%;\n      width: 100%;\n\n      &.step0 {\n        background-image: url(\"../../../avail-web-application/src/Common/Assets/Images/availtrial2.png\");\n      }\n\n      &.step1 {\n        background-image: url(\"../../../avail-web-application/src/Common/Assets/Images/availtrial1.png\");\n      }\n    }    \n  }\n  \n  .forms-container {\n    height: 100%;\n  }\n\n  .wrapper {\n    margin-bottom: 32px;\n  }\n\n  .success-message {\n    font-weight: 300;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container_+SsJKR",
	"image-wrapper": "styles_image-wrapper_FjDQMN",
	"imageWrapper": "styles_image-wrapper_FjDQMN",
	"image": "styles_image_AJ+CHA",
	"step0": "styles_step0_i4hzbr",
	"step1": "styles_step1_6ZGAPL",
	"forms-container": "styles_forms-container_ohqq0B",
	"formsContainer": "styles_forms-container_ohqq0B",
	"wrapper": "styles_wrapper_qhNHmv",
	"success-message": "styles_success-message_oyGE3h",
	"successMessage": "styles_success-message_oyGE3h"
};
export default ___CSS_LOADER_EXPORT___;
