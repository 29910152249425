import { BRANDS } from './BrandsActionTypes';

const initialState = {
	brands: null,
	currentBrand: null,
	pagination: {
		page: 0,
		size: 10,
		totalPages: 1,
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case BRANDS.SET_ALL_BRANDS:
			return {
				...state,
				brands: action.payload,
			};
		case BRANDS.SET_BRANDS_PAGINATION:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload.pageNumber,
					size: action.payload.pageSize,
					totalPages: action.payload.totalPages,
				},
			};
		case BRANDS.SET_CURRENT_BRAND:
			return { ...state, currentBrand: action.payload };
		default:
			return state;
	}
}
