import React from "react";
import "./CallingNotification.scss";
import { incomingCall, callDecline } from "../../../../../../Common/Assets/Images";
import ringToneMP3 from "../../../../../../Common/Assets/Sounds/availcall-ringtone.mp3";
import ringToneOgg from "../../../../../../Common/Assets/Sounds/availcall-ringtone.ogg";
import { CALLBACK_MODES } from "../../MeetingReducer";

const CallingNotification = ({ callInfo, onCancel, callbackMode }) => {
	const setCallSVG = callbackMode => {
		switch (callbackMode) {
			case CALLBACK_MODES.calling:
			case CALLBACK_MODES.consoleaccepted:
				return incomingCall
			case CALLBACK_MODES.consolebusy:
			case CALLBACK_MODES.consoledeclined:
			case CALLBACK_MODES.consoleunavailable:
				return callDecline
			default:
				return incomingCall // default to this
		}
	}

	const hospital_name = callInfo 
		&& callInfo.hospital 
		&& callInfo.hospital[0] 
		&& callInfo.hospital[0].name 
			? callInfo.hospital[0].name 
			: '';

	return (
		<div className="call-notification">
			{(callbackMode === CALLBACK_MODES.calling || callbackMode === CALLBACK_MODES.consoleaccepted) && <audio autoPlay={true} loop={true}>
				<source src={ringToneMP3} type="audio/mpeg" />
				<source src={ringToneOgg} type="audio/ogg" />
			</audio>}
			<div className={`call-button ${callbackMode === CALLBACK_MODES.consoleunavailable ? 'unavailable' : ''}
					${callbackMode === CALLBACK_MODES.consolebusy ? 'busy' : ''}
					${callbackMode === CALLBACK_MODES.consoledeclined ? 'declined' : ''}
					${callbackMode === CALLBACK_MODES.consoleaccepted ? 'accepted' : ''}`}
				style={{ backgroundImage: `url("${setCallSVG(callbackMode)}")` }}
			/>

			{callbackMode === CALLBACK_MODES.calling && <div className="call-text">Calling...</div>}
			{callbackMode === CALLBACK_MODES.consolebusy && <div className="call-text">Busy</div>}
			{callbackMode === CALLBACK_MODES.consoledeclined && <div className="call-text">Call Declined</div>}
			{callbackMode === CALLBACK_MODES.consoleunavailable && <div className="call-text">Unavailable</div>}
			{callbackMode === CALLBACK_MODES.consoleaccepted && <div className="call-text">Accepted</div>}

			<div className="caller-details-text">
				{hospital_name}
			</div>

			<div className="call-action-buttons">
				{callbackMode === CALLBACK_MODES.calling && <button
					className="action-call-button"
					onClick={onCancel}
				>
					Cancel
				</button>}

				{(callbackMode === CALLBACK_MODES.consolebusy
					|| callbackMode === CALLBACK_MODES.consoledeclined
					|| callbackMode === CALLBACK_MODES.consoleunavailable) && (
						<button
							className="action-call-button"
							onClick={onCancel}
						>
							Close
						</button>
					)}

				{callbackMode === CALLBACK_MODES.consoleaccepted && (
					<button
						className="accepted-action-call-button"
						onClick={onCancel}
					>
						Join Call
					</button>
				)}
			</div>
		</div>
	)
};

export default CallingNotification;
