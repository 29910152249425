import { renderTraceIdHeader } from "src/domains/Beacon/utils/renderTraceIdHeader";
import { ENDPOINTS } from "../constants";
import { PortalIdentity } from "../store/models";
import { ApiResponse } from "./types";

/**
 * @param payload Defined structure for the metrics backend to process accordingly (NetworkQualityPayload)
 * Example use: const { data, error } = await API.POST.networkQualityPoll(payload)
 */
const meetingHeartbeat = async (
  identity: PortalIdentity,
  callSid: string,
  joinId: string
): Promise<ApiResponse<any>> => {
  const url = `${ENDPOINTS.DOMAIN}/callevent/users/join/${joinId}`;
  const access_token = identity.access_token;
  const payload = {
    callSid: callSid.toLowerCase(),
    deviceId: "string",
    timeZone: "UTC"
  };

  try {
    const opts = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
        ...renderTraceIdHeader(),
        Accept: "application/json"
      },
      body: JSON.stringify(payload)
    };

    const res = await fetch(url, opts);
    const resultString = await res.json();

    return new Promise((resolve, reject) => {
      resolve(resultString);
    });
  } catch (error) {
    return error;
  }
};

export default meetingHeartbeat;
