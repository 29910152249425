import clsx from "clsx";

import { Typography } from "@mui/material";

import styles from "./styles.scss";

export function DetailItem({ label, text, inline = false }) {
  return (
    <div className={clsx(styles.detailItem, { [styles.special]: inline })}>
      <Typography variant="caption" className={styles.label}>
        {label}
      </Typography>
      <Typography className={styles.detailText} variant="body1">
        {text}
      </Typography>
    </div>
  );
}
