import Twilio, { LocalVideoTrack } from "twilio-video";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/domains/Beacon/store";
import { getParticipantVideoTracks } from "src/domains/Beacon/utils/twilio";
import { logger, LoggerLevels } from "src/logging/logger";

/**
 * Allows to publish the new video track if the current participant,
 * publishing the video track will update every participant's video for this participant.
 * The update occurs in the TwilioSubscriptions component
 */
// Automatically generates pending, fulfilled and rejected action types
// (see `createAsyncThunk` docs: https://redux-toolkit.js.org/api/createAsyncThunk)
export const publishVideoTrackThunk = createAsyncThunk<
  // Return type of the payload creator
  LocalVideoTrack,
  // First argument to the payload creator
  void,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("stream/publishVideoTrackThunk", async (_arg, { getState }) => {
  try {
    logger().info("User is publishing video tracks to Twilio");

    const { stream, twilio, ui } = getState();
    const {
      localMedia: { camera },
    } = stream;
    const {
      room: { localParticipant },
    } = twilio;
    const {
      pip: { showVideo },
    } = ui;

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "publishVideoTrackThunk",
      },
      `Creating LocalVideoTrack using camera device: ${JSON.stringify(
        camera ?? "null"
      )}`
    );

    const videoTrack = await Twilio.createLocalVideoTrack({
      deviceId: camera.deviceId,
    });

    const tracks = getParticipantVideoTracks(localParticipant);

    await localParticipant.unpublishTracks(tracks);

    // logger added to identify another error in intermittent case of blank Console video in call.
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "publishVideoTrackThunk",
      },
      `VideoTracks to be published to Twilio: ${JSON.stringify(videoTrack)}`
    );

    // Must check first the video state in order to disable or not the track
    if (!showVideo) {
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "publishAudioTrackThunk",
        },
        `Camera video is currently muted, disabling it before publishing to Twilio`
      );
      videoTrack.disable();
    }

    const { track } = await localParticipant.publishTrack(videoTrack);
    return track as LocalVideoTrack;
  } catch (error: any) {
    logger().error("Error publishing video tracks", error?.message);
    throw new Error("Error publishing new video tracks.");
  }
});
