import GA4React from "ga-4-react";
import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Dispatch } from "redux";

import { UnsupportedIpadError } from "src/components/legacy/Errors/AppStoreRedirect";
import { EulaModal } from "src/components/legacy/Modal/EulaModal";
import { GOOGLE_ANALYTICS_ID } from "src/constants";
import { withFeatureFlag } from "src/hooks/useFeatureFlag";
import { getAppVersionTag } from "src/utils/appVersionTag";

import { isLoggedInAction } from "../avail-web-application/src/Features/Authentication/AuthenticationActions";
import { setCurrentUserInfoAction } from "../avail-web-application/src/Features/Home/HomeActions";
import { Notification } from "../avail-web-ui/src/components/Notification";
import { AppRoutes } from "./routes";

const ga4react = new GA4React(GOOGLE_ANALYTICS_ID);

/**
 * Hidden div with the Git Tag Version in it.
 */
const HiddenVersionTagDiv = () => (
  <div
    key="version-tag"
    style={{ display: "none" }}
  >{`Version: ${getAppVersionTag()}`}</div>
);

interface DispatchProps {
  isLoggedIn: () => void;
  setCurrentUserInfo: () => void;
}

type Props = DispatchProps;

class App extends React.Component<Props & RouteComponentProps> {
  componentDidMount() {
    ga4react.initialize();
    /**
     * moved from componentWillMount
     * componentWillMount deprecated in React 18 - unsafe for async rendering
     * */
    this.props.isLoggedIn();
    this.props.setCurrentUserInfo();
  }

  isIpad = () => {
    // TODO: remove duplicate
    return false;
  };

  render() {
    return this.isIpad() ? (
      <UnsupportedIpadError />
    ) : (
      <div className="app-container">
        <HiddenVersionTagDiv />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          closeButton={false}
        />
        {/* Notification system for web-ui notifications  */}
        <Notification />
        <AppRoutes />

        {/*
          Slight hack to not show EulaModal in trial sign up page (to streamline sign up process)

          TODO: find a better long term solution for this, can't use useHistory because we are technically
          outside of the react router context so using window.location directly
        */}
        {window.location.href.includes("trial-sign-up") ? null : (
          /*
            This modal will be rendered as a child to application root using React portal feature
          */
          <EulaModal storageType="local" />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  isLoggedIn: () => dispatch(isLoggedInAction()),
  setCurrentUserInfo: () => dispatch(setCurrentUserInfoAction()),
});

export default withRouter(
  connect<null, DispatchProps>(null, mapDispatchToProps)(withFeatureFlag(App))
);

// Webpack will replace this variable with the actual value from
// package.json during build time
declare const APP_NAME; // tell TypeScript this is a known value
declare const VERSION;
// eslint-disable-next-line no-console
console.info(
  `%cAvail Client Version: ${APP_NAME}:${VERSION}`,
  "font-weight:bold"
);
