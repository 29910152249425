import { NConfig, Nurep } from "availkit-js";

import LogService from "src/services/LogService";

const defaultConfig = new NConfig();
defaultConfig.pubnubPublishKey = "demo";
defaultConfig.pubnubSubscribeKey = "demo";
defaultConfig.pubnubAuthKey = "demo";

// Class that works as a singleton to get one and only one instance
// of AvailKit throughout a call
export class AvailKitService {
  static getInstance(config?, presenceUUID?) {
    // If config is not provided, this means AVK is probably already instantiated
    if (!config) {
      // if the instance already exists, just return that
      if (AvailKitService.instance) {
        return AvailKitService.instance;
      } else {
        // To avoid errors, instantiate AVK with default config (to avoid errors in
        // runtime) and return that instead of setting the static `instance` value
        return new Nurep(defaultConfig, presenceUUID);
      }
    }

    // If config IS provided, create a new instance of AVK and replace the static value of `instance`
    if (!AvailKitService.instance) {
      const availKit = new Nurep(config, presenceUUID);

      LogService.info(
        `AvailKitJS client version ${availKit.version} initialized.`
      );

      availKit.eventService.addEventListener(availKit.telephonyService);
      availKit.eventService.addEventListener(availKit.avkSchedulerService);
      AvailKitService.instance = availKit;
    }

    // If there's already an instance available return the instance
    return AvailKitService.instance;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static instance: Nurep | null = null;
}
