import { UserDetails, UserRole } from "src/services/ApiClient/users";

import SessionStorageService from "../../../src/services/SessionStorageService";
import LocalStorageService from "./LocalStorageService";

const AVAIL_ACCESS_TOKEN = "avail_access_token";
const AVAIL_REFRESH_TOKEN = "avail_refresh_token";
const CACHED_USER_INFO = "cachedUserInfo";
const CACHED_USER_PROFILES = "user_profiles";

export function setCachedUserInfo(user: UserDetails) {
  SessionStorageService.add(CACHED_USER_INFO, user);
}

export function setCachedUserProfiles(profiles: UserRole[]) {
  LocalStorageService.add(CACHED_USER_PROFILES, profiles);
}

export function setRefreshToken(refreshToken: string) {
  SessionStorageService.add(AVAIL_REFRESH_TOKEN, refreshToken);
}

export function login(accessToken: string, refreshToken: string) {
  SessionStorageService.add(AVAIL_ACCESS_TOKEN, accessToken);
  SessionStorageService.add(AVAIL_REFRESH_TOKEN, refreshToken);
}

export function isLoggedIn() {
  return (
    !!SessionStorageService.get(AVAIL_ACCESS_TOKEN) &&
    !!SessionStorageService.get(CACHED_USER_INFO)
  );
}

export function logout() {
  SessionStorageService.remove(CACHED_USER_INFO);
  SessionStorageService.remove(CACHED_USER_PROFILES);
  SessionStorageService.remove(AVAIL_ACCESS_TOKEN);
  SessionStorageService.remove(AVAIL_REFRESH_TOKEN);

  // TODO determine if cleanup is needed for Availkit PubNub disconnect?
}

export function getRefreshToken(): string {
  return SessionStorageService.get(AVAIL_REFRESH_TOKEN);
}

export function getTokenForRequest() {
  return `Bearer ${getToken()}`;
}

export function getUserInfo(): UserDetails {
  return SessionStorageService.get(CACHED_USER_INFO);
}

export function getUserProfiles(): UserRole[] {
  return LocalStorageService.get(CACHED_USER_PROFILES);
}

function getToken(): string {
  return SessionStorageService.get(AVAIL_ACCESS_TOKEN);
}
