// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_navbar_RxZqKZ{align-items:center;display:flex;flex-direction:row;height:64px;padding:0 1rem}", "",{"version":3,"sources":["webpack://./src/components/legacy/NavBar/styles.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,WAAA,CACA,cAAA","sourcesContent":[".navbar {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  height: 64px;\n  padding: 0 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "styles_navbar_RxZqKZ"
};
export default ___CSS_LOADER_EXPORT___;
