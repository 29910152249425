import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";

import { Switch } from "@mui/material";

import { AutocompleteTextField } from "src/components/legacy/AutocompleteTextField";

import {
  Button,
  Icon,
} from "../../../../../avail-web-application/src/Common/Components";
import { toggleMfaOrgLevelSettings } from "../../../../../avail-web-application/src/Features/Home/Features/Organisation/Vendors/VendorsActions";

// TODO: move this to more generic place when duplicate entry exists or
// move this later to appropriate folder/file.
interface MFAFrequencyTypes {
  name: string;
  value: string;
}

interface Props {
  vendorId: string;
  mfaOrgLevelFrequency: string;
  mfaFrequencyTypes: MFAFrequencyTypes[];
  mfaOrgLevelTwoFactor: boolean;
  toggleMfaOrgLevelSettings: ({
    vendorId,
    isTwoFactorEnabled,
    mfaFrequency,
  }: {
    vendorId: string;
    isTwoFactorEnabled: boolean;
    mfaFrequency: string;
  }) => void;
}

const BUSecurity = ({
  vendorId,
  mfaOrgLevelFrequency,
  mfaFrequencyTypes,
  mfaOrgLevelTwoFactor,
  // TODO: remove this eslint comment later
  // eslint-disable-next-line @typescript-eslint/no-shadow
  toggleMfaOrgLevelSettings,
}: Props) => {
  const { handleSubmit, control } = useForm({
    mode: "onChange",
  });
  const [checked, setChecked] = useState(mfaOrgLevelTwoFactor);

  const handleChange = (): void => {
    setChecked((prev): boolean => !prev);
  };

  const onSubmit = ({ mfaFrequency }): void => {
    toggleMfaOrgLevelSettings({
      vendorId,
      isTwoFactorEnabled: checked,
      mfaFrequency: mfaFrequency?.value,
    });
  };

  const getMfaFrequency = () => {
    for (const option of mfaFrequencyTypes) {
      if (option.value === mfaOrgLevelFrequency) {
        return option;
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="vendor-profile__toggle__switch width--full">
        <Button
          className="button-with-icon align-self--flex-start padding--none"
          type="submit"
        >
          <Icon className="icon-save icon--orange entity-profile__submit-icon" />
          <span className="entity-profile__submit-title">Save Changes</span>
        </Button>
      </div>
      <div className="vendor-profile__security-marginTop">
        <h4>Multi Factor Authentication</h4>
        <hr className="vendor-profile__preferences-hor-line" />
        <div className="vendor-profile__security__header">
          <div className="vendor-profile__hr-text">
            <span className="vendor-profile__label">
              Mandate Multi Factor Authentication for all user within
              organization.
            </span>
          </div>
          <div className="vendor-profile__toggle__switch width--half">
            <Switch
              name="checked"
              color="primary"
              disableRipple
              checked={checked}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="vendor-profile__break-line">
          <Controller
            name="mfaFrequency"
            control={control}
            defaultValue={getMfaFrequency()}
            rules={{
              required: checked,
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <AutocompleteTextField
                    disabled={!checked}
                    placeholderLabel="Set Days"
                    name="mfaFrequency"
                    label="Set frequency for Multifactor Authentication"
                    getOptionLabel={(option: { name: any }) =>
                      option?.name || ""
                    }
                    value={value}
                    options={mfaFrequencyTypes}
                    onChange={onChange}
                    required={checked}
                  />
                </>
              );
            }}
          />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state: {
  vendorsReducer: {
    mfaFrequencyTypes: MFAFrequencyTypes[];
    mfaOrgLevelFrequency: string;
    mfaOrgLevelTwoFactor: boolean;
  };
}) => ({
  mfaFrequencyTypes: state.vendorsReducer.mfaFrequencyTypes,
  mfaOrgLevelFrequency: state.vendorsReducer.mfaOrgLevelFrequency,
  mfaOrgLevelTwoFactor: state.vendorsReducer.mfaOrgLevelTwoFactor,
});

// TODO: replace with useDispatcher from redux
const mapDispatchToProps = {
  toggleMfaOrgLevelSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(BUSecurity);
