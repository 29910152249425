function useDeviceDetection() {
  const isIpad = false;

  const isDesktop = !/Android|iPhone|iPod/i.test(navigator.userAgent);

  return {
    isIpad,
    isDesktop,
  };
}

export default useDeviceDetection;
