// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_helper-text-container_8zse7-{align-items:center;display:flex;justify-content:flex-start}.styles_helper-text-container_8zse7->svg{font-size:1.4em;margin-right:5px}.styles_helper-text-container_8zse7- .styles_helper-text_pDex33{color:#fff !important;margin-top:0px}", "",{"version":3,"sources":["webpack://./src/components/HelperText/styles.scss"],"names":[],"mappings":"AAEA,qCACE,kBAAA,CACA,YAAA,CACA,0BAAA,CACA,yCACE,eAAA,CACA,gBAAA,CAGF,gEACE,qBAAA,CACA,cAAA","sourcesContent":["@import \"src/colors.scss\";\n\n.helper-text-container {\n  align-items: center;\n  display: flex;\n  justify-content: flex-start;\n  & > svg {\n    font-size: 1.4em;\n    margin-right: 5px;\n  }\n\n  .helper-text {\n    color: $white !important;\n    margin-top: 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helper-text-container": "styles_helper-text-container_8zse7-",
	"helperTextContainer": "styles_helper-text-container_8zse7-",
	"helper-text": "styles_helper-text_pDex33",
	"helperText": "styles_helper-text_pDex33"
};
export default ___CSS_LOADER_EXPORT___;
