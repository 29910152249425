import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";
import { NAnnotationPoint } from "availkit-js/dist/Models/NAnnotationPoint";
import { Guid } from "guid-typescript";

import { InstructionMemory } from "src/domains/Beacon/components/Easel/constants";
import { TwoDimensionPosition } from "src/services/ApiClient/presence";

export interface PenStyle {
  width?: 1 | 2 | 3 | 4 | 5;
  color?: string | null;
}

export interface TelestrationState {
  presenceAnnotations: AVKAnnotationEvent[]; // Presence points - aka remoteHistory
  isDrawModeOn: boolean; // Palette is open on Host
  penStyle: PenStyle;
  videoDimensions: VideoDimensions; // dimensions of the video element
  isResizing: boolean;
  // state used to draw and send points
  telestrationHistory: InstructionMemory[];
  totalAnnotations: Map<Guid | null, number>;
  telestrationEventsMap: Map<string | null, AVKAnnotationEvent[]>;
  activeTelestrationId: Guid | null;
  activeSequence: number;
  previousPoint: TwoDimensionPosition;
  backingInstructions: NAnnotationPoint[];
  sendEvent: SendEvent;
  telestrationRetries: Map<string | null, number>; // used in receiveAVKTelestrationEndReportThunk
}

export interface VideoDimensions {
  // dimensions of visible video
  width?: number;
  height?: number;
  // dimensions of aspect ratio bars
  offsetTop: number; // TODO: used in older code, verify if this is used for FreezeFrame
  offsetLeft: number; // TODO: used in older code, verify if this is used for FreezeFrame
  offsetWidth?: number;
  offsetHeight?: number;
}

export const INITIAL_DIMENSIONS: VideoDimensions = {
  width: 0,
  height: 0,
  offsetTop: 0,
  offsetLeft: 0,
  offsetWidth: 0,
  offsetHeight: 0,
};
export const sendEventTypes = {
  start: "START",
  end: "END",
  annotation: "ANNOTATION",
};
export type SendEventType = "START" | "END" | "ANNOTATION" | null;

export interface SendEvent {
  type: SendEventType;
  payload?: NAnnotationPoint[];
}
