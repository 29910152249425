import {
  ClinicalDepartment,
  DepartmentTherapies,
  FacilityDetailResponse,
  HospitalDepartmentListResponse,
  HospitalDepartmentResponse,
  HospitalListResponse,
  HospitalPayload,
  HospitalRoomListResponse,
  HospitalRoomResponse,
  HospitalTherapiesResponse,
  RoomData,
  RoomTypesResponse,
  UserAndOrgResponse,
  UserApplicationResponse,
  UserApplicationStatus,
} from ".";
import { getQueryString } from "../../../utils/ApiHelper";
import ApiQuery from "../ApiQuery";
import { Facility } from "../businessUnit";
import { ConsoleListResponse } from "../console";
import { CORE_REGISTRY_API_URL } from "../utils";

export const getAllHospitals = (
  page: number = 0,
  size: number | "all" = 10,
  sort: string = "",
  extraParam: string = ""
) => {
  return ApiQuery<HospitalListResponse>(
    `${CORE_REGISTRY_API_URL}/hospitals${getQueryString({
      page,
      size,
      sort,
      extraParam,
    })}`
  );
};

export const getHospitalAdmins = (
  hospitalId: string,
  page: number = 0,
  size: number | "all" = 10,
  sort: string = "",
  extraParam: string = ""
) => {
  return ApiQuery<UserAndOrgResponse>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}/admins${getQueryString({
      page,
      size,
      sort,
      extraParam,
    })}`
  );
};

export const getHospitalDepartmentList = (
  hospitalId: string,
  page: number = 0,
  size: number | "all" = 10,
  sort: string = "",
  extraParam: string = ""
) => {
  return ApiQuery<HospitalDepartmentListResponse>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}/departments${getQueryString(
      {
        page,
        size,
        sort,
        extraParam,
      }
    )}`
  );
};

export const getHospitalRoomList = (
  hospitalId: string,
  page: number = 0,
  size: number | "all" = 10,
  sort: string = "",
  extraParam: string = ""
) => {
  return ApiQuery<HospitalRoomListResponse>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}/rooms${getQueryString({
      page,
      size,
      sort,
      extraParam,
    })}`
  );
};

export const getHospitalApplications = (
  hospitalId: string,
  page: number = 0,
  size: number | "all" = 10,
  sort: string = "",
  extraParam: string = ""
) => {
  return ApiQuery<UserApplicationResponse>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}/user-applications${getQueryString(
      {
        page,
        size,
        sort,
        extraParam,
      }
    )}`
  );
};

export const getHospitalConsoles = (
  hospitalId: string,
  page: number = 0,
  size: number | "all" = 10,
  sort: string = "",
  extraParam: string = ""
) => {
  return ApiQuery<ConsoleListResponse>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}/consoles${getQueryString({
      page,
      size,
      sort,
      extraParam,
    })}`
  );
};

export const getDepartmentDetail = (departmentId: string) => {
  return ApiQuery<HospitalDepartmentResponse>(
    `${CORE_REGISTRY_API_URL}/departments/${departmentId}`
  );
};

// TODO properly type return types below

export const createHospital = (payload: HospitalPayload) => {
  return ApiQuery<any, any>(
    `${CORE_REGISTRY_API_URL}/hospitals`,
    "POST",
    payload
  );
};

export const updateHospital = (payload: Facility, hospitalId: string) => {
  return ApiQuery<any, any>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}`,
    "PUT",
    payload
  );
};

export const deleteHospital = (hospitalId: string) => {
  return ApiQuery<any>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}`,
    "DELETE"
  );
};

// clinical departments
export const createClinicalDepartment = (
  hospitalId: string,
  payload: ClinicalDepartment
) => {
  return ApiQuery<any, any>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}/departments`,
    "POST",
    payload
  );
};

export const createClinicalDepartmentTherapies = (
  departmentId: string,
  payload: DepartmentTherapies
) => {
  return ApiQuery<any, any>(
    `${CORE_REGISTRY_API_URL}/departments/${departmentId}/therapies`,
    "PUT",
    payload
  );
};

export const getDepartmentTherapies = (departmentId: string) => {
  return ApiQuery<HospitalTherapiesResponse>(
    `${CORE_REGISTRY_API_URL}/departments/${departmentId}/therapies`
  );
};

export const deleteClinicalDepartment = (departmentId: string) => {
  return ApiQuery<any>(
    `${CORE_REGISTRY_API_URL}/departments/${departmentId}`,
    "DELETE"
  );
};

// Rooms
export const getRoomTypes = () => {
  return ApiQuery<RoomTypesResponse>(
    `${CORE_REGISTRY_API_URL}/rooms/room-types`
  );
};

export const createRoom = (hospitalId: string, payload: RoomData) => {
  return ApiQuery<any, any>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}/rooms`,
    "POST",
    payload
  );
};

export const createRoomDepartment = (
  roomId: string,
  payload: DepartmentTherapies
) => {
  return ApiQuery<any, any>(
    `${CORE_REGISTRY_API_URL}/rooms/${roomId}/departments`,
    "PUT",
    payload
  );
};

export const deleteRoom = (roomId: string) => {
  return ApiQuery<any>(`${CORE_REGISTRY_API_URL}/rooms/${roomId}`, "DELETE");
};

export const getHospitalDetail = (hospitalId: string) => {
  return ApiQuery<FacilityDetailResponse>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}`
  );
};

export const getRoom = (roomId: string) => {
  return ApiQuery<HospitalRoomResponse>(
    `${CORE_REGISTRY_API_URL}/rooms/${roomId}`
  );
};

export const getRoomDepartment = (roomId: string) => {
  return ApiQuery<HospitalDepartmentResponse>(
    `${CORE_REGISTRY_API_URL}/rooms/${roomId}/departments`
  );
};

export const getHospitalDepartments = (hospitalId: string) => {
  return ApiQuery<HospitalDepartmentListResponse>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}/departments`
  );
};

export const updateRoom = (roomId: string, payload: RoomData) => {
  return ApiQuery<any, any>(
    `${CORE_REGISTRY_API_URL}/rooms/${roomId}`,
    "PUT",
    payload
  );
};

export const getRoomDepartmentTherapies = (roomId: string) => {
  return ApiQuery<any>(
    `${CORE_REGISTRY_API_URL}/rooms/${roomId}/department-therapies`
  );
};

export const getRoomTherapies = (roomId: string) => {
  return ApiQuery<HospitalTherapiesResponse>(
    `${CORE_REGISTRY_API_URL}/rooms/${roomId}/room-therapies`
  );
};

// Approvals/Rejections
export const updateUserApplicationStatus = (
  hospitalId: string,
  userId: string,
  payload: UserApplicationStatus
) => {
  return ApiQuery<any, any>(
    `${CORE_REGISTRY_API_URL}/hospitals/${hospitalId}/user-applications/${userId}`,
    "PUT",
    payload
  );
};
