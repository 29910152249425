import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { Fields, Button, validators } from '../../../../Common/Components';

import { resetPasswordAction, logoutAction } from '../../AuthenticationActions';

import { LoginPage } from "/src/domains/Login";

class ResetPassword extends React.PureComponent {
	handleResetPasswordSubmit = (formData) => {
		const data = {
			email: this.props.currentUser.email,
			password: formData.new_password,
			confirmPassword: formData.confirm_password,
		};
		this.props.resetPassword(data);
	};


	render() {
		const { handleSubmit } = this.props;
		return (
			<LoginPage className="login">
        <div className="login__subheading">Please enter your new password here</div>
        <form className="login__form" onSubmit={handleSubmit(this.handleResetPasswordSubmit)}>

          <Fields.InputField
            type="password"
            className="avail__input"
            labelText="New Password"
            placeholder="Enter new password"
            name="new_password"
            validate={[
              validators.required,
              validators.numberValidator,
              validators.capitalValidator,
              validators.lowerValidator,
              validators.specialValidator,
              validators.minimumLength8,
              validators.maximumLength15,
            ]}
          />
          <Fields.InputField
            type="password"
            className="avail__input"
            labelText="Confirm Password"
            placeholder="Retype your password"
            name="confirm_password"
            validate={[
              validators.required,
              validators.newPasswordValidator,
            ]}
          />

          <div className="justify-content--flex-end">
            <Button
              isGhost
              className="login__button"
              onClick={this.props.logout}
            >
              Cancel
            </Button>
            <Button
              isFull
              isGreen
              className="login__button"
              type="submit"
            >
              Change Password
            </Button>
          </div>
        </form>
			</LoginPage>
		);
	}
}

const mapStateToProps = state => ({
	currentUser: state.homeReducer.currentUser,
});

const mapDispatchToProps = dispatch => ({
	resetPassword: userData => dispatch(resetPasswordAction(userData)),
	logout: () => dispatch(logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'resetPasswordForm',
})(ResetPassword));
