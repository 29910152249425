import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { reduxForm, FieldArray } from 'redux-form';
import { Button, Fields, validators } from '../../../../../../Common/Components';
import { domains } from './domain';

class CreateNewBrand extends PureComponent {
	componentWillMount() {
		this.props.initialize({ domains: [''] });
	}

	render() {
		const { handleSubmit, toggleCreateNewBrandModal, t } = this.props;

		return (
			<React.Fragment>
				<form action="" onSubmit={handleSubmit}>
					<Fields.InputField
						className="user-profile__general-input"
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.placeholder')}
						name="name"
						validate={[
							validators.required,
							validators.minimumLength3,
							validators.maximumLength48,
						]}
					/>

					<Fields.FileInputField
						name="logoLocation"
						id="fileItem"
						type="file"
						labelText="Upload Image"
						validate={[
							validators.imagerequired,
							validators.imageformat,
							validators.imagesize,
						]}
					/>
					<br />
					<label>Email Domain name </label>
					<FieldArray
						name="domains"
						className="user-profile__general-input"
						component={domains}

					/>
					<div className="button__wrapper justify-content--flex-end">
						<Button
							onClick={toggleCreateNewBrandModal}
							isGhost
							buttonSmall
						>
							Cancel
						</Button>
						<Button
							type="submit"
							isFull
							buttonSmall
						>
							Create
						</Button>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

CreateNewBrand.defaultProps = {
	handleSubmit: () => { },
	toggleCreateNewBrandModal: () => { },
};

CreateNewBrand.propTypes = {
	handleSubmit: PropTypes.func,
	toggleCreateNewBrandModal: PropTypes.func,
};

const CreateNewBrandWithTranslation = withTranslation()(CreateNewBrand);

export default reduxForm({
	form: 'brandsForm',
})(CreateNewBrandWithTranslation);
