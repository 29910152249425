// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form_vx0MNc{width:50%}.styles_form_vx0MNc .styles_wrapper_NXIsY5{margin-bottom:32px}.styles_form_vx0MNc .styles_error-message_VFKnap{margin-bottom:32px;text-align:center}.styles_form_vx0MNc .styles_error-cancel-button-wrapper_5CuaPz{margin-top:24px}.styles_form_vx0MNc .styles_trial-not-eligible-title_x5dxKG{text-align:center}.styles_form_vx0MNc .styles_trial-not-eligible-message_TYpsbS{font-weight:300;margin-bottom:32px;margin-top:16px;text-align:center}.styles_modal_1Y2pKb.styles_custom-width_4bKHNr{width:460px}.styles_modal_1Y2pKb .styles_header_vbunoC{padding:32px 16px}.styles_modal_1Y2pKb .styles_body_6Ccp4k{padding:24px 16px}", "",{"version":3,"sources":["webpack://./src/domains/TrialSignUp/components/GetStartedForm/styles.scss"],"names":[],"mappings":"AAAA,oBACE,SAAA,CAEA,2CACE,kBAAA,CAGF,iDACE,kBAAA,CACA,iBAAA,CAGF,+DACE,eAAA,CAGF,4DACE,iBAAA,CAGF,8DACE,eAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA,CAKF,gDACE,WAAA,CAGF,2CACE,iBAAA,CAGF,yCACE,iBAAA","sourcesContent":[".form {\n  width: 50%;\n\n  .wrapper {\n    margin-bottom: 32px;\n  }\n\n  .error-message {\n    margin-bottom: 32px;\n    text-align: center;\n  }\n\n  .error-cancel-button-wrapper {\n    margin-top: 24px;\n  }\n\n  .trial-not-eligible-title {\n    text-align: center;\n  }\n  \n  .trial-not-eligible-message {\n    font-weight: 300;\n    margin-bottom: 32px;\n    margin-top: 16px;\n    text-align: center;\n  }\n}\n\n.modal {\n  &.custom-width {\n    width: 460px;\n  }\n\n  .header {\n    padding: 32px 16px;\n  }\n\n  .body {\n    padding: 24px 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form_vx0MNc",
	"wrapper": "styles_wrapper_NXIsY5",
	"error-message": "styles_error-message_VFKnap",
	"errorMessage": "styles_error-message_VFKnap",
	"error-cancel-button-wrapper": "styles_error-cancel-button-wrapper_5CuaPz",
	"errorCancelButtonWrapper": "styles_error-cancel-button-wrapper_5CuaPz",
	"trial-not-eligible-title": "styles_trial-not-eligible-title_x5dxKG",
	"trialNotEligibleTitle": "styles_trial-not-eligible-title_x5dxKG",
	"trial-not-eligible-message": "styles_trial-not-eligible-message_TYpsbS",
	"trialNotEligibleMessage": "styles_trial-not-eligible-message_TYpsbS",
	"modal": "styles_modal_1Y2pKb",
	"custom-width": "styles_custom-width_4bKHNr",
	"customWidth": "styles_custom-width_4bKHNr",
	"header": "styles_header_vbunoC",
	"body": "styles_body_6Ccp4k"
};
export default ___CSS_LOADER_EXPORT___;
