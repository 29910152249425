import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import Can from '../../../../../../../../../Common/Acl/AccessControl';
import { Button } from '../../../../../../../../../Common/Components';
import ModalComponent from '../../../../../../../../../Common/Components/Modal/Modal';
import { Table } from '../../../../../../../Common';
import { getcurrentUserTypes } from '../../../../../../../HomeSelectors';
import { getAllStatesAction } from '../../../../../OrganisationActions';
import { createNewBusinessUnitAction, createNewVendorAction, deleteVendorDistrictAction } from '../../../../VendorsActions';
import CreateNewVendor from '../../../CreateNewVendor/CreateNewVendor';
import BusinessUnitListRow from './Components/BusinessUnitsListRow/BusinessUnitListRow';
import CreateNewBusinessUnit from './Components/CreateNewBusinessUnit/CreateNewBusinessUnit';
import { arrayService } from '../../../../../../../../../Common/Services';

class BusinessUnitsList extends PureComponent {
	state = {
		isCreateNewBusinessUnitModalOpened: false,
		isDeleteModalOpened: false,
		selectedDistrict: null,
		currentData: this.props.data,
		defaultData: this.props.data,
	};

	componentDidMount() {
		this.props.getAllStates();
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			columns: [{
				title: nextProps.title,
			}],
      currentData: nextProps.data,
      defaultData: nextProps.data,
		});
	}

	onCreateNewVendorHandler = (values) => {
		this.setState({ isCreateNewBusinessUnitModalOpened: !this.state.isCreateNewBusinessUnitModalOpened });

		this.props.createNewBusinessUnit({
			...values,
			procedures: values.procedures ? Object.keys(values.procedures) : [],
		}, this.props.businessUnitType);
	};

	onCreateNewBusinessUnitToggleHandler =
		() => this.setState({ isCreateNewBusinessUnitModalOpened: !this.state.isCreateNewBusinessUnitModalOpened });

	onConfirmDelete = () => {
		this.props.deleteDistrict(this.state.selectedDistrict.id);
		this.toggleDeleteModal();
	}

	onSortTableHandler = (value) => {
		this.params = { ...this.params,
			sort: value };
		const sortedData = arrayService.sortQuery(this.state.currentData, this.params);
		this.setState({ currentData: [...sortedData] });
	};

	onTableFilterHandler = (value) => {
		this.params = {
			...this.params,
			name: value,
		};
		const sortedData = arrayService.sortQuery(this.state.defaultData, this.params);
		this.setState({ currentData: sortedData === undefined ? [...this.state.defaultData] : [...sortedData] });
	};

	toggleCreateNewBusinessUnitModal = () => {
		this.props.resetReduxForm('createNewBusinessUnitForm');
		this.setState({ isCreateNewBusinessUnitModalOpened: !this.state.isCreateNewBusinessUnitModalOpened });
	};

	toggleDeleteModal = (selectedDistrict) => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
			selectedDistrict,
		});
	}

	renderDeleteModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onConfirmDelete}
			onCancel={this.toggleDeleteModal}
			confirmText="Delete"
		>
			{
				this.state.selectedDistrict &&
				<span>
					You are about to delete district <span className="text--bold">{this.state.selectedDistrict.name}</span>.
					Would you like to proceed with this action?
				</span>
			}
		</ModalComponent>
	);

	render() {
		return (
			<div className="business-units-list">
				<ModalComponent
					open={this.state.isCreateNewBusinessUnitModalOpened}
					title={`Add new ${this.props.businessUnitType}`}
					onCancel={this.onCreateNewBusinessUnitToggleHandler}
					hasActionButtons={false}
				>
					{this.props.businessUnitType === 'Business Unit'
						? <CreateNewVendor
							onSubmit={this.onCreateNewVendorHandler}
							toggleCreateNewVendorModal={this.toggleCreateNewBusinessUnitModal}
						/>
						: <CreateNewBusinessUnit
							businessUnitType={this.props.businessUnitType}
							onSubmit={this.onCreateNewVendorHandler}
							toggleCreateNewBusinessUnitModal={this.toggleCreateNewBusinessUnitModal}
							states={this.props.states}
						/>
					}
				</ModalComponent>

				<Can userTypes={this.props.currentUserTypes} action="read" context="createNewDepartment">
					<div className="justify-content--flex-end">
						<Button isFull buttonSmall onClick={this.toggleCreateNewBusinessUnitModal}>
							{`Create ${this.props.businessUnitType}`}
						</Button>
					</div>
				</Can>
				<Table
					row={BusinessUnitListRow}
					columns={[{
						title: this.props.title,
						sortValue: 'name',
					}]}
					tableData={this.state.currentData}
					tableActions={false}
					openModal={this.toggleDeleteModal}
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					onSortTableHandler={this.onSortTableHandler}
					search
					sortable
				/>

				{this.renderDeleteModal()}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	currentUserTypes: getcurrentUserTypes(state),
	states: state.organisationsReducer.states,
});

const mapDispatchToProps = dispatch => ({
	resetReduxForm: formName => dispatch(reset(formName)),
	createNewBusinessUnit: (businessUnitData, type) => dispatch(createNewBusinessUnitAction(businessUnitData, type)),
	createNewVendor: data => dispatch(createNewVendorAction(data)),
	deleteDistrict: id => dispatch(deleteVendorDistrictAction(id)),
	getAllStates: () => dispatch(getAllStatesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessUnitsList);
