// constants relating to logging and Splunk

const SPLUNK_LOGGING_TOKEN = `${process.env.REACT_APP_SPLUNK_LOGGING_TOKEN}`;
const SPLUNK_LOGGING_URL = `${process.env.REACT_APP_SPLUNK_LOGGING_URL}`;
export const NETWORK_DIAGNOSTICS_URL = `${process.env.REACT_APP_NETWORK_DIAGNOSTICS_URL}`;

export const SPLUNK_CONFIG = {
  token: SPLUNK_LOGGING_TOKEN,
  url: SPLUNK_LOGGING_URL,
};

export const SPLUNK_URL_FALLBACK =
  "https://splunk.internal.avail.io:443/services/collector";

export const AVAIL_LOG_ENV = `${process.env.REACT_APP_AVAIL_LOG_ENV}` || "dev";
