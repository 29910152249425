import { useDispatch, useSelector } from "react-redux";

import { logger } from "../../common/logger";
import { setCallState } from "../../store/meeting/actions";
import { AppState, CallState } from "../../store/models";
import { endCall } from "../../store/user/actions";
import "./styles.scss";


const ReconnectModal = () => {
  const dispatch = useDispatch();

  const { duplicateParticipantDisconnected, localNetworkDisconnected,  } = useSelector(
    (state: AppState) => state.twilio
  );

  const endCallForTheUser = async () => {
    await dispatch(endCall());
  }

  const confirmEndCall = () => {
    dispatch(setCallState(CallState.ENDCALLCONFIRMED));
    endCallForTheUser()
      .then(() => {
        dispatch(setCallState(CallState.READY));
      })
      .finally(() => {
        dispatch(setCallState(CallState.READY));
        window.location.href = "/portalcall";
      })
      .catch((e) => {
        logger().error(e);
        dispatch(setCallState(CallState.READY));
        window.location.href = "/portalcall";
      });
  }

  return (
    <div className="avail-portal-call-overlay">
      <div className="avail-portal-call-overlay-dialog">
        <div className="in-meeting-error-alert-symbol-warning"></div>
        <div className="in-meeting-network-error-message">
          {localNetworkDisconnected && 'You have been disconnected due to network conditions'}
          {duplicateParticipantDisconnected && 'Call disconnected because you joined from another active session'}
        </div>
        <div className="avail-portal-call-overlay-dialog-end-button-holder">
          <button className="confirm-end-call-button">
            <div
              className="avail-portal-call-overlay-dialog-button-text"
              onClick={() => confirmEndCall()}
            >
              END CALL
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReconnectModal;