import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { setCameraChangeEventThunk } from "src/domains/Beacon/store/preset/thunks/setCameraChangeEventThunk";
import { setVideoLayoutEventThunk } from "src/domains/Beacon/store/preset/thunks/setVideoLayoutEventThunk";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { LayoutFrames } from "src/domains/Beacon/store/stream/types";
import { logger } from "src/logging/logger";

// Handles changing the Layouts based whether Console has EIR capability
export const handleLayoutChangeThunk = createAsyncThunk<
  void,
  {
    externalInputsImageResizing: boolean;
    newLayoutFrames?: LayoutFrames | null;
  },
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "stream/handleLayoutChangeThunk",
  (
    { externalInputsImageResizing, newLayoutFrames },
    { getState, dispatch }
  ) => {
    logger().info("Handling layout event thunk...");

    const {
      stream: {
        consoleHasExternalInputResizing,
        layoutFrames,
        layoutFramesSnapshot,
        integrationActive: isIntegrationActive,
      },
    } = getState();

    // when we are using an integration, this prevents sending the integration as a layout
    const layoutToUse = isIntegrationActive
      ? layoutFramesSnapshot
      : layoutFrames;
    const desiredLayoutFrames = newLayoutFrames ?? layoutToUse;

    const hasExternalInputResizing =
      externalInputsImageResizing && consoleHasExternalInputResizing;

    //  change local layout/cameras
    dispatch(streamActions.setLayoutFrames(desiredLayoutFrames));

    // send event to change Console layout/cameras
    if (hasExternalInputResizing) {
      dispatch(setVideoLayoutEventThunk({ layoutFrames: desiredLayoutFrames }));
    } else {
      dispatch(
        setCameraChangeEventThunk({ layoutFrames: desiredLayoutFrames })
      );
    }
  }
);
