import { useState, useEffect } from "react";

import clsx from "clsx";

import { Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { Modal } from "src/components/legacy/Modal";
import { CallHistory } from "src/domains/Usage/components/CallHistory/CallHistory";
import { TrialDisplay } from "src/domains/Usage/components/TrialDisplay";
import { useGetQueryParams } from "src/hooks/useGetQueryParams";
import {
  useGetUserCallHistory,
  useGetUserTrialUsage,
} from "src/queries/trialUsage";
import { useGetUserById } from "src/queries/users";
import UserSessionService from "src/services/UserSessionService";

import styles from "./styles.scss";

export interface UsagePageProps {
  children: React.ReactNode;
  className?: string;
}

// This page will vary depending on if a `userId` query-param is
// present or not. If is is, we must display that user's usage
// info instead of current user's (default)
export const UsagePage = ({ className }: UsagePageProps) => {
  const currentUser = UserSessionService.getCachedUserInfo();
  const [usageUserId, setUsageUserId] = useState(currentUser.id);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const { userId } = useGetQueryParams("userId");

  const isCurrentUserAvailAdmin = currentUser.userType === "AVAIL_ADMIN";

  useEffect(() => {
    if (userId) {
      setUsageUserId(userId);
    }
  }, [userId]);

  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Call History
  // the page in the UI has to start at 1 due to MUI component, but the BE expects it to start at 0
  // TODO figure out a cleaner place to put this, for now this should work just fine since
  // its only about the pagaination on only this page
  const { data: historyData } = useGetUserCallHistory(usageUserId, page - 1);
  const callHistory = historyData?.content;

  // User in case query-param exists
  const { data: userResponseData } = useGetUserById(userId);
  const userData = userResponseData?.content;

  // Usage
  const userForUsage = userId ? userData : currentUser; // if its another user's usage page, use their data, otherwise currentUser
  const { data: usageData } = useGetUserTrialUsage(userForUsage);
  const usage = usageData?.content;

  /**
   * If it's current user's Usage info = show
   * If an Avail Admin wanna see a user's Usage = show
   * Any other user type = hide
   */
  if (userForUsage?.id !== currentUser.id) {
    if (!isCurrentUserAvailAdmin) {
      return null;
    }
  }

  // availableTrialMinutes can be negative from the API, so if its greater than 0 then show it
  // otherwise just show 0 instead
  const availableTrialMinutes =
    usage?.availableTrialMinutes > 0 ? usage.availableTrialMinutes : 0;

  return (
    <>
      <div data-test-id="usage" className={clsx(styles.container, className)}>
        <div className={styles.usageHeader}>
          {/* we only want to show this header / message to trial users, not Avail Admins */}
          {!isCurrentUserAvailAdmin ? (
            <div className={styles.textContainer}>
              <Typography
                data-test-id="trial-usage-header"
                className={styles.header}
                variant="h4"
              >
                Welcome to your Trial!
              </Typography>
              <Typography
                data-test-id="trial-usage-message"
                className={styles.trialMessage}
                variant="body1"
              >
                Let’s checkout your trial usage! View your call details, minutes
                balance and more. Your trial account may be upgraded at any
                time. We hope you enjoy your Avail experience!
              </Typography>
            </div>
          ) : null}

          {!isCurrentUserAvailAdmin ? (
            <Button
              onClick={() => setUpgradeModalOpen(true)}
              data-test-id="usage-upgrade-button"
            >
              Upgrade
            </Button>
          ) : null}
        </div>

        <Typography className={styles.title} variant="h4">
          {/* we only want to show the user's first/last name to Avail Admins, not trial users themselves */}
          {`${
            isCurrentUserAvailAdmin && userData
              ? `${userData.firstName} ${userData.lastName}'s`
              : ""
          } Usage`}
        </Typography>

        <div className={styles.trialDisplay}>
          <TrialDisplay
            data-test-id="trial-display-days"
            remaining={usage?.availableTrialDays}
            total={usage?.addedTrialDays}
            topText="Trial Days Remaining"
            bottomText="days"
          />

          <TrialDisplay
            data-test-id="trial-display-mins"
            remaining={availableTrialMinutes}
            total={usage?.addedTrialMinutes}
            topText="Trial Minutes Remaining"
            bottomText="minutes"
          />
        </div>

        <CallHistory
          data={callHistory}
          page={page}
          handlePageChange={handlePageChange}
          totalPages={historyData?.totalPages}
        />
      </div>

      <Modal
        className={`${styles.modal} ${styles.small}`}
        aria-labelledby="event-details-popup"
        open={upgradeModalOpen}
        onExited={() => setUpgradeModalOpen(false)}
      >
        <div className={styles.modal}>
          <div
            data-test-id="usage-upgrade-modal"
            className={styles.modalContent}
          >
            <span
              onClick={() => setUpgradeModalOpen(false)}
              className={styles.modalExit}
            >
              x
            </span>
            <Typography variant="h5">
              Please contact your company’s admin to upgrade your plan.
            </Typography>
          </div>
        </div>
      </Modal>
    </>
  );
};
