import { BILLING } from './BillingActionTypes';

export const createPlanAction = (vendorId, billingData) => ({
	type: BILLING.CREATE_PLAN,
	vendorId,
	payload: billingData,
});

export const getPlanByVendorIdAction = vendorId => ({
	type: BILLING.GET_PLAN_BY_VENDOR_ID,
	payload: vendorId,
});

export const updatePlanAction = (vendorId, billingData) => ({
	type: BILLING.UPDATE_PLAN,
	vendorId,
	payload: billingData,
});

export const createSubscriptionAction = (vendorId, subscriptionData) => ({
	type: BILLING.CREATE_SUBSCRIPTION,
	vendorId,
	payload: subscriptionData,
});

export const getInvoicesByVendorIdAction = vendorId => ({
	type: BILLING.GET_INVOICES_BY_VENDOR_ID,
	payload: vendorId,
});

export const getUsageInfoByVendorIdAction = vendorId => ({
	type: BILLING.GET_USAGE_INFO_BY_VENDOR_ID,
	payload: vendorId,
});

