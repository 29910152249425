import React, { useEffect, useState } from "react";
import RosterStrip from "./RosterStrip";
import RosterOpen from "./RosterOpen";
import "./Roster.scss";
import {
  AppState,
  MediaStateType,
  MultiPartyEventParticipant
} from "../../store/models";
import { connect } from "react-redux";
import { LocalAudioTrack, Participant } from "twilio-video";
import { LocalOrRemoteMediaTrack } from "../../types";
import { RosterParticipant } from "./types";
import { setMediaState } from "../../store/user/actions";
import { Dispatch } from "redux";
import { getLoginId } from "./utils";
import { Nurep } from "availkit-js";

interface StateProps {
  eventParticipants: MultiPartyEventParticipant[] | undefined;
  twilioParticipants: Participant[];
  localTracks: LocalOrRemoteMediaTrack[];
  remoteTracks: LocalOrRemoteMediaTrack[];
  mediaState: MediaStateType;
  currentUserLoginId: string;
  availKit: Nurep | null;
  callSid: string;
}

interface DispatchProps {
  setMediaState: (mediaState: MediaStateType) => void;
}

type Props = StateProps & DispatchProps;

function Roster({
  eventParticipants,
  twilioParticipants,
  localTracks,
  remoteTracks,
  mediaState,
  currentUserLoginId,
  availKit,
  callSid,
  setMediaState
}: Props) {
  const [open, setOpen] = useState(false);
  // Active participants will be equal to those who are actually in the call (not only invited or part of the event)
  const [activeParticipants, setActiveParticipants] = useState<
    RosterParticipant[]
  >([]);

  const currentUser = eventParticipants?.find(
    user => user.loginId === currentUserLoginId
  );

  const getUpdatedAudioTracks = (
    participants: MultiPartyEventParticipant[]
  ): MultiPartyEventParticipant[] => {
    const currentParticipant = participants.find(
      u => u.loginId === currentUserLoginId
    );
    const others = participants.filter(u => u.loginId !== currentUserLoginId);

    if (eventParticipants) {
      // Audio tracks
      twilioParticipants.forEach(participant => {
        const loginId = getLoginId(participant.identity);

        const tracks = participant.audioTracks;
        tracks.forEach(audio => {
          const enabled = audio.isTrackEnabled;

          // get participant's index
          const participantUserIdx = others.findIndex(
            user => user.loginId === loginId
          );

          // assign that participant's `audioEnabled` to whatever twilio provided
          if (participantUserIdx >= 0 && others[participantUserIdx]) {
            (others[
              participantUserIdx
            ] as RosterParticipant).audioEnabled = enabled;
          }
        });
      });

      // Audio tracks for current participant
      if (participants && currentParticipant) {
        const currentUserAudio = localTracks.find(
          track => track.kind === "audio"
        );

        if (currentUserAudio) {
          (currentParticipant as RosterParticipant).audioEnabled =
            currentUserAudio.isEnabled;
        }
      }

      return [currentParticipant!, ...others];
    }
    return [];
  };

  useEffect(() => {
    // Active participants
    if (eventParticipants) {
      const active = eventParticipants.filter(participant => {
        return twilioParticipants.some(twilioParticipant => {
          const loginId = getLoginId(twilioParticipant.identity);
          return loginId === participant.loginId;
        });
      });

      // include `currentUser` in here because `twilioParticipants` does not include the current user
      const trackedParticipants = getUpdatedAudioTracks([
        currentUser!,
        ...active
      ]);

      setActiveParticipants(trackedParticipants);
    }
  }, [eventParticipants, twilioParticipants, localTracks, currentUserLoginId]);

  // Update mic mute/unmute on local current user
  useEffect(() => {
    if (currentUser) {
      const localAudioEnabled = mediaState.self.audio === "unmute";
      const updatedCurrentUser: RosterParticipant = {
        ...currentUser,
        audioEnabled: localAudioEnabled
      };
      const participants = activeParticipants.filter(
        u => u.loginId !== currentUserLoginId
      );

      setActiveParticipants([...participants, updatedCurrentUser]);
    }
  }, [mediaState]);

  // activeParticipants is intentionally left out in the dependencies here to avoid circular dependency
  const isConsolePresentInTheCall = (): boolean => {
    let consolePresent = false;
    for (let i = 0; i < twilioParticipants.length; i++) {
      if (twilioParticipants[i].identity.search("CONSOLE") !== -1) {
        consolePresent = true;
        break;
      }
    }
    return consolePresent;
  };

  // When remote tracks update (audio user mute/unmuting themselves), update the roster UI accordingly
  useEffect(() => {
    // Only run this when participants are in sync. If another user leaves the call, that user's tracks will disable automatically
    if (
      isConsolePresentInTheCall() ||
      activeParticipants.length - 1 === twilioParticipants.length
    ) {
      const updatedParticipantTracks = getUpdatedAudioTracks(
        activeParticipants
      );
      setActiveParticipants(updatedParticipantTracks);
    }
  }, [remoteTracks]);
  // activeParticipants is intentionally left out in the dependencies here to avoid circular dependency

  const toggleMediaMute = (forUser: RosterParticipant) => {
    let mediaTracks;

    // if the current user is trying to toggle mute on themselves, handle localTracks logic
    if (currentUserLoginId === forUser.loginId) {
      mediaTracks = localTracks.filter(t => t.kind === "audio");
      const localAudioEnabled = mediaState.self.audio === "unmute";

      if (localAudioEnabled) {
        mediaTracks.forEach(t => t instanceof LocalAudioTrack && t.disable());
      } else {
        mediaTracks.forEach(t => t instanceof LocalAudioTrack && t.enable());
      }

      // Update `activeParticipants` with currentUser's `audioEnabled` status for UI update
      const strippedParticipants = activeParticipants.filter(
        u => u.loginId !== currentUserLoginId
      );
      setActiveParticipants([
        ...strippedParticipants,
        { ...currentUser!, audioEnabled: !localAudioEnabled }
      ]);

      setMediaState({
        ...mediaState,
        self: {
          ...mediaState.self,
          audio: localAudioEnabled ? "mute" : "unmute"
        }
      });
    } else {
      // if current user (host) is trying to mute someone else (who is unmuted), broadcast the pubnub message
      const host = activeParticipants.find(u => u.role === "HOST");

      if (currentUserLoginId === host?.loginId && forUser.audioEnabled) {
        availKit?.avkMediaService.setRemoteMute(callSid, [forUser.loginId]);

        // Update `activeParticipants` with modified user's `audioEnabled` status for UI update
        const strippedParticipants = activeParticipants.filter(
          u => u.loginId !== forUser.loginId
        );
        setActiveParticipants([
          ...strippedParticipants,
          { ...forUser, audioEnabled: false }
        ]);
      }
    }
  };

  // Don't show the roster until we get a response from the event API
  if (!eventParticipants) return null;

  return (
    <>
      <RosterStrip
        open={open}
        setOpen={setOpen}
        participants={activeParticipants}
        currentUserLoginId={currentUserLoginId}
        onMuteClick={toggleMediaMute}
      />
      <RosterOpen open={open} setOpen={setOpen} />
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  eventParticipants: state.meeting.multiPartyCallEventDetail?.participants,
  twilioParticipants: state.twilio.participants,
  localTracks: state.twilio.localTracks,
  remoteTracks: state.twilio.remoteTracks,
  mediaState: state.user.mediaState,
  currentUserLoginId: state.user.identity.login_id,
  availKit: state.availKit.availKitInstance,
  callSid: state.meeting.callSid
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setMediaState: (mediaState: MediaStateType) =>
      dispatch(setMediaState(mediaState))
  };
};

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(Roster);
