import React from "react";

import clsx from "clsx";

import {
  Grid,
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
  Typography,
} from "@mui/material";

import styles from "./styles.scss";

// extending MUI props and adding our own on top of them
// this way all of the MUI props still work except the ones we override
type Props = MUITextFieldProps & {
  label: string;
  className?: string;
};

export const TextField = ({
  required = false,
  label,
  className,
  ...props
}: Props) => (
  <Grid container direction="column">
    <Grid item>
      <Typography variant="body1" component={"label"}>
        {label}
        {required ? <span className={styles.required}> *</span> : null}
      </Typography>
    </Grid>
    <Grid item>
      <MUITextField
        className={clsx(styles.root, className)}
        {...props}
        required={required}
        variant="outlined"
      />
    </Grid>
  </Grid>
);
