import versionFile from "../../.git-versioning.json";

/**
 * Reads the .git-versioning.json file and creates the Git Version Tag name
 */
export const getAppVersionTag = () => {
  const externalVersion = versionFile["external-version"];
  const sprintNumber = versionFile["sprint-number"];
  const gitTag = `${externalVersion}-${sprintNumber}`;
  // eslint-disable-next-line no-console
  console.info(`%cVersion: ${gitTag}`, "font-weight:bold");
  return gitTag;
};
