import { LoggerLevels, logger } from "src/logging/logger";

interface IParams {
  audioOutputDevice: MediaDeviceInfo;
  mediaElement: HTMLMediaElement;
}

export const handleSetAudioOutputDeviceThunk = ({
  audioOutputDevice,
  mediaElement,
}: IParams) => async () => {
  try {
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Media Devices",
        fileInfo: "handleSetAudioOutputDeviceThunk.ts",
      },
      `Request to change the audio output device to: ${audioOutputDevice.label} with id: ${audioOutputDevice.deviceId}`
    );

    if ((mediaElement as any).setSinkId) {
      // Chrome, MS Edge
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Media Devices",
          fileInfo: "handleSetAudioOutputDeviceThunk.ts",
        },
        `Browser supports changing audio output to specific device. Changing to ${audioOutputDevice.label} with id: ${audioOutputDevice.deviceId}`
      );

      await (mediaElement as any).setSinkId(audioOutputDevice.deviceId);
    } else if ((navigator.mediaDevices as any).selectAudioOutput) {
      // Firefox
      logger().logWithFields(
        LoggerLevels.warn,
        {
          feature: "Media Devices",
          fileInfo: "handleSetAudioOutputDeviceThunk.ts",
        },
        `Browser supports changing to System Default audio output only. Forcing a change to System Default audio output.`
      );

      await (navigator.mediaDevices as any).selectAudioOutput();
    } else {
      logger().logWithFields(
        LoggerLevels.warn,
        {
          feature: "Media Devices",
          fileInfo: "handleSetAudioOutputDeviceThunk.ts",
        },
        `Browser limitation - Cannot change audio output device during the ongoing call.`
      );
    }
  } catch (error: any) {
    logger().error(
      `Error while changing Browser's audio output device.`,
      error?.message
    );
  }
};
