import { useSelector } from "react-redux";
import { Transition } from "semantic-ui-react";

import classNames from "classnames";

import { AppState } from "src/reducer";

import { logger } from "portalcall/commoncall/common/logger";
import CallControlMenu from "portalcall/commoncall/components/CallControlMenu";
import "portalcall/commoncall/components/InCallControls/InCallControls.scss";
import { MULTI_PARTY_LAUNCH_MODE } from "portalcall/commoncall/constants";

const CallControlPanel = ({
  clearTelestration,
  consoleAudioMuteCollapse, // local state
  consoleAudioMuted,
  consoleHasJoinedRoom = false,
  getAudioButtonClass,
  getPipButtonClass,
  getSidebarBannerClass,
  getSidebarButtonClass,
  getVideoButtonClass,
  handleParticipantControls,
  handleRefreshFrames,
  imageFrozen,
  isUserHost, // local state
  localNetworkQualityLevel, // NO FOR SC
  menuHidden, // local state
  openNetworkDiagnostics, // NO FOR SC
  refreshInProgress, // state props
  remoteHasJoined = false,
  selectCallControl,
  selectedCallControl, // local state
  selectedMarker, // local state
  selectMarkerForTelestration,
  setCollapseConsoleAudioMuted,
  shouldEnableInCallControls,
  shouldEnableRefreshButton,
  shouldEnableLocalMediaMute,
  shouldEnableVideoMute,
  shouldExpandCallControl,
  showBluetoothAudioBanner, // local state
  showEndCallConfirmation, // local state
  showSidebarBanner, // local state
  toggleAudioMute,
  toggleMenu,
  togglePip,
  toggleShowSidebarBanner,
  toggleSidebarStatus,
  toggleVideoMute,
  videoFrameIcon,
}) => {
  const {
    meeting: { mode },
    user: {
      mediaState,
      identity: { userId },
    },
  } = useSelector((state: AppState) => state);

  const audioMuted = mediaState.self.audio === "mute";
  const pipEnabled = mediaState.pip === "show";
  const sidebarActivated = mediaState.self.sidebar === "active";
  const videoMuted = mediaState.self.video === "mute";

  const controlDisplayClass = (baseClass: string) => {
    return shouldEnableInCallControls
      ? classNames(baseClass)
      : classNames(baseClass, "button-disabled");
  };

  const getConsoleAudioMutedClass = (): string => {
    let consoleAudioMutedClass = "console-audio-muted-banner";
    if (menuHidden) {
      consoleAudioMutedClass += "-double-collapsed";
    } else {
      if (consoleAudioMuteCollapse) {
        consoleAudioMutedClass += "-collapsed";
      }
    }
    return consoleAudioMutedClass;
  };

  const remoteHasJoinedRoom = consoleHasJoinedRoom || remoteHasJoined;

  const leftFrameClass = imageFrozen
    ? classNames(videoFrameIcon(0), "button-disabled")
    : classNames(videoFrameIcon(0));

  const pinchZoomClass = imageFrozen
    ? classNames("pinch-zoom-button", "button-disabled")
    : classNames("pinch-zoom-button");

  const presetButtonClass = imageFrozen
    ? classNames("preset-button", "button-disabled")
    : classNames("preset-button");

  const procedureControlsClass = shouldEnableInCallControls
    ? "procedure-controls"
    : "procedure-controls-disabled";

  const rightFrameClass = imageFrozen
    ? classNames(videoFrameIcon(1), "button-disabled")
    : classNames(videoFrameIcon(1));

  const telestrationControlsClass = shouldEnableInCallControls
    ? "telestration-controls"
    : "telestration-controls-disabled";

  return (
    <>
      <div className={menuHidden ? "banner-group-minimized" : "banner-group"}>
        {consoleAudioMuted && (
          <div className={getConsoleAudioMutedClass()}>
            <div className="console-audio-muted-icon" />
            <div className="console-audio-muted-text">
              <div>Room audio is muted</div>
            </div>
            <div
              className="console-autio-muted-banner-dismiss"
              onClick={() => setCollapseConsoleAudioMuted(true)}
            />
          </div>
        )}
        <Transition
          visible={showBluetoothAudioBanner}
          animation={"fade up"}
          duration={50}
          unmountOnHide={true}
          mountOnShow={true}
        >
          <div className="banner important">
            <div className="speaker-banner-icon" />
            <div className="sidebar-banner-text">
              <div>Console is switching from Headset to Room Audio.</div>
            </div>
          </div>
        </Transition>
        <Transition
          visible={showSidebarBanner}
          animation={"fade up"}
          duration={50}
          unmountOnHide={true}
          mountOnShow={true}
        >
          <div
            className={getSidebarBannerClass()}
            onMouseEnter={() => toggleShowSidebarBanner()}
          >
            {sidebarActivated ? (
              <>
                <div className="sidebar-active-icon" />
                <div className="sidebar-banner-text">
                  <div>The Host has enabled sidebar mode.</div>
                </div>
              </>
            ) : (
              <>
                <div className="sidebar-inactive-icon" />
                <div className="sidebar-banner-text">
                  <div>The Host has disabled sidebar mode.</div>
                </div>
              </>
            )}
          </div>
        </Transition>
      </div>
      <div className="call-controls-group">
        <Transition.Group animation={"fade down"} duration={50}>
          {!menuHidden && (
            <div className="call-controls-placeholder important">
              <div className={procedureControlsClass}>
                {shouldExpandCallControl && (
                  <CallControlMenu
                    {...{
                      remoteHasJoinedRoom,
                      selectedCallControl,
                    }}
                  />
                )}
                <div
                  className={controlDisplayClass(leftFrameClass)}
                  onClick={
                    shouldEnableInCallControls && !imageFrozen
                      ? () => selectCallControl("left-frame")
                      : undefined
                  }
                />
                <div
                  className={controlDisplayClass(rightFrameClass)}
                  onClick={
                    shouldEnableInCallControls && !imageFrozen
                      ? () => selectCallControl("right-frame")
                      : undefined
                  }
                />
                <div
                  className={controlDisplayClass(pinchZoomClass)}
                  onClick={
                    shouldEnableInCallControls && !imageFrozen
                      ? () => selectCallControl("pinch-zoom")
                      : undefined
                  }
                />
                <div
                  className={controlDisplayClass("freeze-frame-button")}
                  onClick={
                    shouldEnableInCallControls
                      ? () => selectCallControl("freeze-frame")
                      : undefined
                  }
                />
                <div
                  className={handleParticipantControls("refresh-frames-button")}
                  onClick={
                    shouldEnableRefreshButton
                      ? () => handleRefreshFrames()
                      : undefined
                  }
                />
                <div
                  className={controlDisplayClass(presetButtonClass)}
                  onClick={
                    shouldEnableInCallControls && !imageFrozen
                      ? () => selectCallControl("preset")
                      : undefined
                  }
                />
              </div>
              <div className={telestrationControlsClass}>
                <div
                  className={
                    selectedMarker === "green"
                      ? "selected-green-pallete"
                      : "green-pallete"
                  }
                  onClick={
                    shouldEnableInCallControls
                      ? () => selectMarkerForTelestration("green")
                      : undefined
                  }
                />
                <div
                  className={
                    selectedMarker === "blue"
                      ? "selected-blue-pallete"
                      : "blue-pallete"
                  }
                  onClick={
                    shouldEnableInCallControls
                      ? () => selectMarkerForTelestration("blue")
                      : undefined
                  }
                />
                <div
                  className={
                    selectedMarker === "yellow"
                      ? "selected-yellow-pallete"
                      : "yellow-pallete"
                  }
                  onClick={
                    shouldEnableInCallControls
                      ? () => selectMarkerForTelestration("yellow")
                      : undefined
                  }
                />
                <div
                  className={
                    selectedMarker === "red"
                      ? "selected-red-pallete"
                      : "red-pallete"
                  }
                  onClick={
                    shouldEnableInCallControls
                      ? () => selectMarkerForTelestration("red")
                      : undefined
                  }
                />
                <div
                  className={"eraser-pallete"}
                  onClick={
                    shouldEnableInCallControls
                      ? () => clearTelestration()
                      : undefined
                  }
                />
              </div>
              <div className="call-controls">
                <div
                  className={getPipButtonClass()}
                  onClick={
                    !refreshInProgress ? () => togglePip(pipEnabled) : undefined
                  }
                />
                <div
                  className={getVideoButtonClass()}
                  onClick={
                    shouldEnableVideoMute
                      ? () => toggleVideoMute(videoMuted)
                      : undefined
                  }
                />
                {isUserHost && mode === MULTI_PARTY_LAUNCH_MODE && (
                  <div
                    className={getSidebarButtonClass()}
                    onClick={
                      !refreshInProgress
                        ? () => toggleSidebarStatus()
                        : undefined
                    }
                  />
                )}
                <div
                  className={getAudioButtonClass()}
                  onClick={
                    shouldEnableLocalMediaMute
                      ? () => toggleAudioMute(audioMuted)
                      : undefined
                  }
                />
                <div
                  className="end-call-button"
                  onClick={() => {
                    showEndCallConfirmation(true);
                    logger().info(
                      `PortalCall User '${userId}' clicked end call button`
                    );
                  }}
                />
                <div
                  className={"signal-strength-" + localNetworkQualityLevel}
                  onClick={() => openNetworkDiagnostics()}
                />
              </div>
            </div>
          )}
        </Transition.Group>
        {menuHidden && (
          <div className="menu-show" onClick={() => toggleMenu(menuHidden)} />
        )}
        {!menuHidden && (
          <div className="menu-hide" onClick={() => toggleMenu(menuHidden)} />
        )}
      </div>
    </>
  );
};

export default CallControlPanel;
