import { NIncomingCallEvent } from "availkit-js/dist/Models/Events/NIncomingCallEvent";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CallBackEventStateType,
  CallModes,
  CallState,
  MeetingState,
  MeetingToken,
} from "src/domains/Beacon/store/meeting/types";
import { ThirdPartyIntegration } from "src/services/ApiClient/integrations";
import { MultiPartyEvent } from "src/services/ApiClient/scheduler";

export const initialState: MeetingState = {
  meetingToken: null,
  callSid: "",
  pubnub_channel: "", // Must connect to new PubNub session channel after tokens refresh
  joinId: "",
  callState: CallState.READY,
  callDetails: null,
  isCallback: false,
  enableInCallControls: false,
  mode: CallModes.P2P,
  isUserHost: false,
  isRequestingScreenSharePermission: false,
  triggerRefreshFrames: false,
  callSessionHeartbeatFunctionId: null,
  accessTokenIntervalKey: null,
  thirdPartyIntegration: [],
  // Allows to know when user has fetched features from presence, must only fetch once in MP calls
  hasFetchedFeaturesFromPresence: false,
  launchDarklyFetchDone: false, // Necessary to wait until LD fetches the latest values
};

export const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    setCallDetails: (
      state,
      action: PayloadAction<
        NIncomingCallEvent | MultiPartyEvent | CallBackEventStateType | null
      >
    ) => {
      state.callDetails = action.payload;
    },

    setCallSid: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      // so instead of doing something like
      // `const newState = [...state, newItem]`
      // we can do
      // `state.push(newItem)`
      state.callSid = action.payload;
    },

    setMeetingToken: (state, action: PayloadAction<MeetingToken>) => {
      state.meetingToken = action.payload;
    },

    setJoinId: (state, action: PayloadAction<string>) => {
      state.joinId = action.payload;
    },

    setCallState: (state, action: PayloadAction<CallState>) => {
      state.callState = action.payload;
    },

    setIsCallback: (state, action: PayloadAction<boolean>) => {
      state.isCallback = action.payload;
    },

    setIsRequestingScreenSharePermission: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isRequestingScreenSharePermission = action.payload;
    },

    setEnableInCallControls: (state, action: PayloadAction<boolean>) => {
      state.enableInCallControls = action.payload;
    },

    setMode: (state, action: PayloadAction<CallModes>) => {
      state.mode = action.payload;
    },

    setIsUserHost: (state, action: PayloadAction<boolean>) => {
      state.isUserHost = action.payload;
    },

    setTriggerRefreshFrames: (
      state: MeetingState,
      action: PayloadAction<boolean>
    ) => {
      state.triggerRefreshFrames = action.payload;
    },

    setCallSessionHeartbeatId: (state, action: PayloadAction<number>) => {
      state.callSessionHeartbeatFunctionId = action.payload;
    },

    setAccessTokenIntervalKey: (
      state: MeetingState,
      action: PayloadAction<NodeJS.Timer>
    ) => {
      state.accessTokenIntervalKey = action.payload;
    },

    setThirdPartyIntegration: (
      state,
      action: PayloadAction<ThirdPartyIntegration[]>
    ) => {
      state.thirdPartyIntegration = action.payload;
    },

    updateThirdPartyIntegration: (
      state,
      action: PayloadAction<ThirdPartyIntegration>
    ) => {
      const updatedIntegration = action.payload;

      // Updates a specific TPI from the list
      state.thirdPartyIntegration = state.thirdPartyIntegration?.map(
        (integration) =>
          integration.partner === updatedIntegration?.partner
            ? updatedIntegration
            : integration
      );
    },

    setPubNubChannel: (state, action: PayloadAction<string>) => {
      state.pubnub_channel = action.payload;
    },

    setHasFetchedFeaturesFromPresence: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hasFetchedFeaturesFromPresence = action.payload;
    },

    setLaunchDarklyFetchDone: (state, action: PayloadAction<boolean>) => {
      state.launchDarklyFetchDone = action.payload;
    },
  },
});

// Export the individual reducer and actions, which are generated with `createSlice`
export const {
  reducer: meetingReducer,
  actions: meetingActions,
} = meetingSlice;
