import React from "react";

import styles from "./styles.scss";

export interface NavBarProps {
  "data-test-id"?: string;
  children: React.ReactNode;
}
/**
 * Simple nav bar that is full width, used to wrap components in a bar layout
 */
export const NavBar = ({
  "data-test-id": dataTestId,
  children = null,
}: NavBarProps) => {
  return (
    <div data-test-id={dataTestId} className={styles.navbar}>
      {children}
    </div>
  );
};
