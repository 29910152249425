import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { DraggableEventHandler } from "react-draggable";

import { useAppSelector } from "src/domains/Beacon/store";
import {
  selectIsUiHidden,
  selectSidePanelOpen,
} from "src/domains/Beacon/store/ui/selectors";
import { useEventListener } from "src/hooks/useEventListener";

import {
  DRAWER_WIDTH,
  DEFAULT_PIP_POSITION,
  DEFAULT_MARGINS,
} from "../constants";

interface IPosition {
  x: number;
  y: number;
}

interface IBounds {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export const usePipPosition = (
  pipRef: MutableRefObject<any>
): [
  DraggableEventHandler,
  DraggableEventHandler,
  { position: IPosition; bounds: IBounds }
] => {
  // Refs are needed to be used inside event listeners
  const positionRef = useRef(DEFAULT_PIP_POSITION); // x, y of the pip inside pip's parent element
  const distanceRef = useRef(DEFAULT_PIP_POSITION.x); // distance between pip and pip's parent element
  const isUiHidden = useAppSelector(selectIsUiHidden);
  const isSidePanelOpen = useAppSelector(selectSidePanelOpen);
  const sidePanelOpen = useRef<boolean>(isSidePanelOpen);
  const [position, setPosition] = useState(DEFAULT_PIP_POSITION);
  const [resizing, setResizing] = useState(false);
  const parentElement = useMemo(() => pipRef.current?.parentElement, [
    pipRef.current?.parentElement,
  ]);
  const onStop = (_, data) => setPosition({ x: data.x, y: data.y });
  const onDrag = (_, data) => {
    const newPosition = {
      y: data.y,
      x: data.x,
    };
    positionRef.current = newPosition;
    /**
     * normal pip's x position is based on the top-left corner
     * we need pip's x position of the top-right corner
     * then distance can be calculated ↔
     */
    const pipPositionX = newPosition.x + pipRef.current?.offsetWidth;
    distanceRef.current = parentElement.offsetWidth - pipPositionX;
    setPosition(newPosition);
  };

  /**
   * Will move the pip to the left when Drawer opens based on Drawer's width
   * with a persistance x distance between the Pip and its parent bounds
   */
  useEffect(() => {
    const newPosition = {
      y: position.y,
      x: isSidePanelOpen
        ? position.x - DRAWER_WIDTH <= 0
          ? DEFAULT_MARGINS.x
          : position.x - DRAWER_WIDTH
        : position.x + DRAWER_WIDTH,
    };
    positionRef.current = newPosition;
    sidePanelOpen.current = isSidePanelOpen;
    setPosition(newPosition);
  }, [isSidePanelOpen]);

  /**
   * Whenever the parent element is ready, will set a default pip position (x, y)
   * based on the pip's parent element size (height and width), it will locate
   * the Pip into the bottom-right corner of the parent
   */
  useEffect(() => {
    const parentHeight = parentElement?.offsetHeight;
    const parentWidth = parentElement?.offsetWidth;
    const pipHeight = pipRef.current?.offsetHeight;
    const pipWidth = pipRef.current?.offsetWidth;
    const initialPosition = {
      y: parentHeight - pipHeight - DEFAULT_MARGINS.y,
      x: parentWidth - pipWidth - DEFAULT_MARGINS.x,
    };
    positionRef.current = initialPosition;
    const pipPositionX = initialPosition.x + pipWidth;
    distanceRef.current = parentElement?.offsetWidth - pipPositionX;
    setPosition(initialPosition);
  }, [parentElement]);

  /**
   * Calculates the bounds of the Pip when: SidePanel opens, UI is hidden,
   * there's a window resizing and when the Beacon page is loaded
   */
  const calculateBounds = (): IBounds => {
    const pipHeight = pipRef.current?.offsetHeight;
    const pipWidth = pipRef.current?.offsetWidth;
    const parentHeight = parentElement?.offsetHeight;
    const parentWidth = parentElement?.offsetWidth;
    // if the UI is hidden, the Pip will have more top and bottom space
    return {
      left: DEFAULT_MARGINS.x,
      right:
        parentWidth -
        (pipWidth + DEFAULT_MARGINS.x) +
        (isSidePanelOpen ? -DRAWER_WIDTH : 0),
      top: !isUiHidden ? DEFAULT_MARGINS.y : DEFAULT_MARGINS.browserY + 23,
      bottom:
        parentHeight -
        (pipHeight +
          (!isUiHidden ? DEFAULT_MARGINS.y : DEFAULT_MARGINS.browserY)),
    };
  };

  /**
   * EventListeners can't read state, so refs are needed for persistent data
   * Will relocate the Pip with a persistance x distance between the Pip and its
   * parent bounds, also detects when the Drawer opens to recalculate it
   */
  useEventListener("resize", () => {
    let resizeTimeout = null;
    const parentWidth = pipRef.current?.parentElement.offsetWidth;
    const pipWidth = pipRef.current?.offsetWidth;
    const newPosition = {
      y: positionRef.current.y,
      x:
        parentWidth -
        distanceRef.current -
        pipWidth +
        (sidePanelOpen.current ? -DRAWER_WIDTH : 0),
    };
    positionRef.current = newPosition;
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      window.dispatchEvent(new Event("resizeend"));
      clearTimeout(resizeTimeout);
    }, 250);
    setPosition(newPosition);
    setResizing(true);
  });

  // detects window resize ended to re-calculate the Pip bounds
  useEventListener("resizeend", () => {
    setResizing(false);
  });

  // re-calculates only when necessary to improve the performance
  const bounds = useMemo(() => calculateBounds(), [
    isSidePanelOpen,
    isUiHidden,
    pipRef.current,
    resizing,
  ]);

  return [onDrag, onStop, { position, bounds }];
};
