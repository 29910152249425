import "moment-timezone";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import moment from "moment";

import { Grid, Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { Chip } from "src/components/legacy/ChipInput/Chip";
import { Link } from "src/components/legacy/Link";
import { LoadingSpinner } from "src/components/legacy/LoadingSpinner";
import { EVENT_MOMENT_TIME_FORMAT } from "src/domains/Events/EventForm/utils";
import {
  checkEventStarted,
  checkEventJoinable,
  checkCurrentUserAParticipant,
  getMeetingToken,
} from "src/domains/Events/utils";
import { useDebouncedState } from "src/hooks/useDebouncedState";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { useGetEventDetails } from "src/queries/events";
import { useGetHospitals } from "src/queries/hospitals";
import { useGetAllTherapies } from "src/queries/therapies";
import { useGetUserTrialUsage } from "src/queries/trialUsage";
import { useGetAllTimezones, useGetUserSettings } from "src/queries/users";
import { history } from "src/reducer";
import { cancelEvent } from "src/services/ApiClient/scheduler";
import UserSessionService from "src/services/UserSessionService";
import { locations } from "src/utils/locations";

import ModalComponent from "avail-web-application/Common/Components/Modal/Modal";

import DomainConstants from "avail-web-ui/domains/DomainConstants";
import { setMeetingInfoAction } from "avail-web-ui/dux/MeetingSlice";
import { addNotificationAction } from "avail-web-ui/dux/NotificationSlice";

import { EventForm } from "../EventForm";
import styles from "./styles.scss";

export const EventEditPage = () => {
  const userInfo = UserSessionService.getCachedUserInfo();
  const dispatch = useDispatch();
  const { callLink } = useFeatureFlags();

  // take the event id from the URL
  const { eventId } = useParams<{
    eventId: string;
  }>();

  const { data: hospitalsData } = useGetHospitals();
  const { data: timeZonesData } = useGetAllTimezones();
  // getting the logged in user's settings (so we can find their saved timezone)
  const { data: userSettingsData } = useGetUserSettings(userInfo.id);
  const { data: therapiesData } = useGetAllTherapies(userInfo.id);
  const { data: eventData } = useGetEventDetails(eventId);
  const event = eventData?.content;

  const [initialFormState, setInitialFormState] = useDebouncedState(null);

  useEffect(() => {
    // only once all of the queries are ready then we can finally set the default form state
    if (
      eventData &&
      userSettingsData &&
      timeZonesData &&
      hospitalsData &&
      therapiesData
    ) {
      const hospital = hospitalsData.content?.find(
        (hospitalObj) => hospitalObj.id === event.hospitalId
      );

      const therapy = therapiesData.content?.find(
        (therapyObj) => therapyObj.id === event.therapyId
      );

      // The procedure will be found in the therapy's procedures field
      // Apparently procedures is sometimes not found in the response data, so default to null and don't throw errors if the property isn't included
      const procedure = therapy?.procedures?.find(
        (procedureObj) => procedureObj.id === event.procedureId
      );

      const timezone = timeZonesData.content?.find(
        (tz) => tz.id === event.timeZoneId
      );

      const host = event.participants.find((p) => p.role === "HOST");
      const hostChips = host ? [host] : [];
      const participants = event.participants.filter((p) => p.role !== "HOST");

      const eventStartDateTime = moment(event.startDate);
      const startTime = eventStartDateTime
        // need to convert the time into the timezone that the user saved it as in the form
        // so it displays the same value they entered (same with end time below)
        .tz(timezone.id)
        .format(EVENT_MOMENT_TIME_FORMAT);

      const eventEndDateTime = moment(event.endDate);
      const endTime = eventEndDateTime
        .tz(timezone.id)
        .format(EVENT_MOMENT_TIME_FORMAT);

      // since the actual form stores full objects in the state, we have to populate full objects in the
      // default values otherwise they won't show up in the UI
      // full objects meaning like hosptial instead of just hospitalId
      const defaultValues = {
        [DomainConstants.key.title]: event.subject,
        [DomainConstants.key.facility]: hospital,
        [DomainConstants.key.surgeon]: event.surgeonName,

        [DomainConstants.key.therapy]: therapy,
        [DomainConstants.key.procedure]: procedure,

        // TODO import these ids properly
        ["CUSTOM_THERAPY_ID"]: event.otherTherapy || "",
        ["CUSTOM_PROCEDURE_ID"]: event.otherProcedure || "",

        [DomainConstants.key.host]: {
          inputValue: "",
          chips: hostChips,
        },
        [DomainConstants.key.participants]: {
          inputValue: "",
          chips: [...participants],
        },
        [DomainConstants.key.customTags]: {
          inputValue: "",
          chips: [...event.eventTags],
        },
        // split date and time for start/end below
        [DomainConstants.key.timeStart]: startTime,
        [DomainConstants.key.timeEnd]: endTime,

        [DomainConstants.key.dateStart]: eventStartDateTime,
        [DomainConstants.key.dateEnd]: eventEndDateTime,

        [DomainConstants.key.timeStart]: {
          inputValue: startTime,
          selectedValue: {
            value: startTime,
            label: startTime,
          },
        },
        [DomainConstants.key.timeEnd]: {
          inputValue: endTime,
          selectedValue: {
            value: endTime,
            label: endTime,
          },
        },

        [DomainConstants.key.timezone]: timezone,
      };

      setInitialFormState(defaultValues);
    }
  }, [
    eventData,
    userSettingsData,
    timeZonesData,
    hospitalsData,
    therapiesData,
  ]);

  const [openCancelEventModal, setOpenCancelEventModal] = useState(false);

  // using a simple boolean state to show loading on the "Cancel Event" button below
  const [callingCancelEventAPI, setCallingCancelEventAPI] = useState(false);

  // usage data to show notification in case user's usage is exceeding
  const { data: trialUsageData } = useGetUserTrialUsage(userInfo);

  const handleJoinClick = () => {
    if (event) {
      dispatch(setMeetingInfoAction(event));
      const meetingToken = getMeetingToken(event, userInfo.loginId);
      window.open(`${callLink}${meetingToken}`, "_blank");
    }
  };

  const cancelEventHandler = async () => {
    if (eventData && eventData.content) {
      setCallingCancelEventAPI(true);
      try {
        await cancelEvent(eventData.content.eventId);
        dispatch(
          addNotificationAction({
            message: "Event Cancelled",
            type: "success",
          })
        );

        history.push("/events");
      } catch (error) {
        setCallingCancelEventAPI(false);
        setOpenCancelEventModal(false);
        dispatch(
          addNotificationAction({
            message: error.message,
            type: "error",
          })
        );
        return;
      }
    }
  };

  return (
    <div className={styles.container}>
      <Link to={locations.events}>{`< Back`}</Link>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div className={styles.titleWrapper}>
          <Typography className={styles.title} variant="h4">
            Edit Event
          </Typography>
          {eventData && eventData.content?.status === "STARTED" && (
            <Chip label="In Progress" size="small" color="success" />
          )}
        </div>

        <div className={styles.actions}>
          {event && checkEventJoinable(event) ? (
            <Button
              onClick={handleJoinClick}
              theme={checkEventStarted(event) ? "green" : "primary"}
              disabled={!checkCurrentUserAParticipant(event, userInfo.loginId)}
            >
              {checkEventStarted(event) ? "Join" : "Start"}
            </Button>
          ) : null}

          <Button
            theme="secondary"
            loading={callingCancelEventAPI}
            disabled={eventData && eventData.content?.status === "STARTED"} // if the event has started then dont let the user cancel it
            onClick={() => setOpenCancelEventModal(true)}
          >
            Cancel Event
          </Button>
        </div>
      </Grid>

      {initialFormState ? (
        <EventForm
          event={eventData?.content}
          mode="EDIT"
          initialFormState={initialFormState}
          creatorUserId={userInfo.id}
          hospitals={hospitalsData ? hospitalsData.content : []}
          timezones={timeZonesData ? timeZonesData.content : []}
          therapies={therapiesData ? therapiesData.content : []}
          usage={trialUsageData ? trialUsageData.content : null}
        />
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingSpinner />
        </Grid>
      )}

      <ModalComponent
        open={openCancelEventModal}
        onConfirm={cancelEventHandler}
        onCancel={() => setOpenCancelEventModal(false)}
        confirmText="Cancel Event"
      >
        <div>Are you sure you want to cancel this event?</div>
      </ModalComponent>
    </div>
  );
};
