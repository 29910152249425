import React from "react";

import clsx from "clsx";

import { CircularProgress } from "@mui/material";

import styles from "./styles.scss";

export interface ISpinnerProps {
  size?: "small" | "medium" | "large";
  className?: string;
  "data-testid"?: string;
}

// mapping Material-ui number sizing to 3 defined sizes so we dont have to think about it and keep it consistent
const sizeMap = {
  small: 20,
  medium: 40,
  large: 50,
};

// Loading spinner used when needing some UI indicator that something is happening behind the scenes
// use size to make the spinner fit into whatever container you need
export const LoadingSpinner = ({
  size = "medium",
  className,
  ...rest
}: ISpinnerProps) => (
  <CircularProgress
    size={sizeMap[size]}
    {...rest}
    className={clsx(styles.spinner, className)}
  />
);
