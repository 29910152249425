import { Font } from "src/components/Font";

import styles from "./styles.scss";

export interface BadgeProps {
  content: string;
  "data-test-id"?: string;
}

export const Badge = ({
  content,
  "data-test-id": dataTestId = null,
}: BadgeProps) => {
  return (
    <span className={styles.badge} data-test-id={dataTestId}>
      <Font
        variant="b2"
        color="light"
        data-test-id={dataTestId ? `${dataTestId}-text` : null}
      >
        {content}
      </Font>
    </span>
  );
};

export const Spacer = () => {
  return <div className={styles.badgeSpacer} />;
};
