// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper_L5dvSY .styles_form-control_wUHjaH{min-width:100%}.styles_wrapper_L5dvSY.styles_full-width_d1pd1G,.styles_wrapper_L5dvSY.styles_full-width_d1pd1G>.styles_form-control_wUHjaH{width:100%}.styles_menu_a0GlzX{max-height:500px}", "",{"version":3,"sources":["webpack://./src/components/legacy/Select/styles.scss"],"names":[],"mappings":"AACE,mDACE,cAAA,CAGF,4HAEE,UAAA,CAIJ,oBACE,gBAAA","sourcesContent":[".wrapper {\n  .form-control {\n    min-width: 100%;\n  }\n\n  &.full-width,\n  &.full-width > .form-control {\n    width: 100%;\n  }\n}\n\n.menu {\n  max-height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper_L5dvSY",
	"form-control": "styles_form-control_wUHjaH",
	"formControl": "styles_form-control_wUHjaH",
	"full-width": "styles_full-width_d1pd1G",
	"fullWidth": "styles_full-width_d1pd1G",
	"menu": "styles_menu_a0GlzX"
};
export default ___CSS_LOADER_EXPORT___;
