import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { generateNotification } from "src/components/NotificationSystem";
import { LayoutFrameNames } from "src/domains/Beacon/store/stream/types";
import {
  CallSteps,
  UIBadge,
  UIIndicator,
  UIInputSelection,
  UINotification,
  UINotificationTypes,
  UINotificationVariants,
  UISidePanelContentOptions,
  UIState,
  UIToolMode,
  UIBadgeTypes,
} from "src/domains/Beacon/store/ui/types";

export const initialState: UIState = {
  sidePanel: {
    open: false,
    content: UISidePanelContentOptions.SETTINGS,
    layouts: {
      frameSelected: "leftTop",
    },
    parentContent: null,
    transitionEnded: null,
    isFully: "close", // Must need it to calculate video dimensions with sidePanel fully open/close
  },
  isUiHidden: false,
  toolMode: null,
  // call state section, move to own slice if it gets too big
  callState: {
    callStep: CallSteps.PRE_CALL,
    openLeaveCallModal: false,
    openNoNetworkConnectionModal: false,
  },

  inputSelection: {
    fullscreenPanel: "", // TODO: are empty strings valid here?
    leftPanel: "",
    rightPanel: "",
  },
  pip: {
    collapsed: false,
    showVideo: true,
  },
  notifications: [],
  isNotificationLimited: {
    [UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BUILT_IN_MIC]: false,
    [UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BLUETOOTH]: false,
    [UINotificationTypes.CONSOLE_AUDIO_MUTED]: false,
    [UINotificationTypes.CONSOLE_AUDIO_UNMUTED]: false,
  },
  badges: {
    [UIBadgeTypes.CONSOLE_AUDIO_MUTED]: false,
    [UIBadgeTypes.SIDEBAR_ON]: false,
    [UIBadgeTypes.SIDEBAR_OFF]: false,
    [UIBadgeTypes.NOISE_REDUCTION_ON]: false,
  },
  microphone: {
    muted: false,
  },
  isTelestrationPaletteHidden: true,
  indicator: {
    color: null,
    label: "",
    show: false,
  },
  openMinimumBrowserSizeModal: false,

  lastParticipantModalOpen: false,
  lastParticipantTimerId: null,
  localNetworkDisconnectedTimerId: null,
  leaveCallErrorMessage: null,
  hasPoorNetworkModalBeenShown: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setSidePanelOpen: (state, action: PayloadAction<boolean>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      // so instead of doing something like
      // `const newState = [...state, newItem]`
      // we can do
      // `state.push(newItem)`
      state.sidePanel.open = action.payload;
    },

    setSidePanelContent: (
      state,
      action: PayloadAction<UISidePanelContentOptions>
    ) => {
      state.sidePanel.content = action.payload;

      // for content that has sub content UI we need to know what the parent is so we can return to it with the Back button
      // for example Settings would be the parentContent when the content would be Audio Settings
      if (
        [
          UISidePanelContentOptions.AUDIO_SETTINGS,
          UISidePanelContentOptions.VIDEO_SETTINGS,
          UISidePanelContentOptions.CONSOLE_SETTINGS,
          UISidePanelContentOptions.NETWORK_STATUS_SETTINGS,
        ].includes(action.payload)
      ) {
        state.sidePanel.parentContent = UISidePanelContentOptions.SETTINGS;
      } else {
        state.sidePanel.parentContent = null;
      }
    },

    setSidePanelTransitionEnded: (state, action: PayloadAction<boolean>) => {
      state.sidePanel.transitionEnded = action.payload;
    },

    setSidePanelFullyState: (
      state,
      action: PayloadAction<"open" | "close">
    ) => {
      state.sidePanel.isFully = action.payload;
    },

    setUiHidden: (state, action: PayloadAction<boolean>) => {
      state.isUiHidden = action.payload;
    },

    toggleUiHidden: (state) => {
      const hiddenStatus = state.isUiHidden;
      state.isUiHidden = !hiddenStatus;
    },

    setToolMode: (state, action: PayloadAction<UIToolMode | null>) => {
      state.toolMode = action.payload;
    },

    setCallStep: (state, action: PayloadAction<CallSteps>) => {
      state.callState.callStep = action.payload;
    },
    setLeaveCallModal: (state, action: PayloadAction<boolean>) => {
      state.callState.openLeaveCallModal = action.payload;
    },
    setOpenNoNetworkModal: (state, action: PayloadAction<boolean>) => {
      state.callState.openNoNetworkConnectionModal = action.payload;
    },

    setInputSelection: (state, action: PayloadAction<UIInputSelection>) => {
      state.inputSelection = action.payload;
    },

    setPipCollapsed: (state: UIState, action: PayloadAction<boolean>) => {
      state.pip.collapsed = action.payload;
    },

    stopPipVideo: (state: UIState) => {
      state.pip.showVideo = false;
    },

    resumePipVideo: (state: UIState) => {
      state.pip.showVideo = true;
    },

    muteMicrophone: (state: UIState) => {
      state.microphone.muted = true;
    },

    unMuteMicrophone: (state: UIState) => {
      state.microphone.muted = false;
    },
    triggerNotification: (
      state: UIState,
      action: PayloadAction<UINotificationTypes>
    ) => {
      const type = action.payload;
      const { isNotificationLimited } = state;

      // Check if the type has a limit on how often it can be seen
      if (isNotificationLimited?.[type] === true) {
        // type is in isNotificationLimited AND is true.
        // do not add to state.notifications => do not render notification
        return;
      } else if (isNotificationLimited?.[type] === false) {
        // type is in isNotificationLimited AND is false.
        // make the value true to prevent the notification from being rendered again
        isNotificationLimited[type] = true;
      }
      // create the notification with the predefined text given the type passed in, then add it to the state
      const notification = generateNotification(type);
      state.notifications = [...state.notifications, notification];
    },
    addNotification: (
      state: UIState,
      action: PayloadAction<UINotification>
    ) => {
      state.notifications = [...state.notifications, action.payload];
    },
    setLayoutFrame: (
      state: UIState,
      action: PayloadAction<LayoutFrameNames>
    ) => {
      state.sidePanel.layouts.frameSelected = action.payload;
    },
    setIndicator: (state: UIState, action: PayloadAction<UIIndicator>) => {
      state.indicator = action.payload;
    },
    // if we want users to be able to pass in multiple args then we have to do this "prepare" function from redux-toolkit
    // for example in a component we would call this like: uiActions.triggerCustomNotification(UINotificationTypes.SUCCESS, "title", "subtitle", "mini")
    triggerCustomNotification: {
      reducer: (
        state: UIState,
        action: PayloadAction<{
          notificationType:
            | UINotificationTypes.SUCCESS
            | UINotificationTypes.DELETE
            | UINotificationTypes.ERROR;
          title: string;
          variant?: UINotificationVariants;
          subtitle?: string;
        }>
      ) => {
        const notification = {
          title: action.payload.title,
          subtitle: action.payload.subtitle || "",
          type: action.payload.notificationType,
          variant: action.payload.variant || "default",
        };
        state.notifications = [...state.notifications, notification];
      },
      prepare: (
        notificationType:
          | UINotificationTypes.SUCCESS
          | UINotificationTypes.DELETE
          | UINotificationTypes.ERROR,
        title: string,
        variant?: UINotificationVariants,
        subtitle?: string
      ) => {
        return {
          payload: { notificationType, title, subtitle, variant },
        };
      },
    },
    // This function is for the NotificationSystem for Beacon which handles active notifications and removing them from the list when necessary
    setNotifications: (
      state: UIState,
      action: PayloadAction<UINotification[]>
    ) => {
      state.notifications = action.payload;
    },
    addBadge: (state: UIState, action: PayloadAction<UIBadge>) => {
      const newBadges = state.badges;
      newBadges[action.payload.type] = true;
      state.badges = newBadges;
    },
    removeBadge: (state: UIState, action: PayloadAction<UIBadge>) => {
      const newBadges = state.badges;
      newBadges[action.payload.type] = false;
      state.badges = newBadges;
    },
    clearBadges: (state: UIState) => {
      state.badges = initialState.badges;
    },
    setOpenMinimumBrowserSizeModal: (state, action: PayloadAction<boolean>) => {
      state.openMinimumBrowserSizeModal = action.payload;
    },
    setLastParticipantModalOpen: (state, action: PayloadAction<boolean>) => {
      state.lastParticipantModalOpen = action.payload;
    },
    setLastParticipantTimerId: (state, action: PayloadAction<number>) => {
      state.lastParticipantTimerId = action.payload;
    },
    setLocalNetworkDisconnectedTimerId: (
      state,
      action: PayloadAction<number>
    ) => {
      state.localNetworkDisconnectedTimerId = action.payload;
    },
    setLeaveCallErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.leaveCallErrorMessage = action.payload;
    },
    setHasPoorNetworkModalBeenShown: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hasPoorNetworkModalBeenShown = action.payload;
    },
  },
});

// Export the individual reducer and actions, which are generated with `createSlice`
export const { reducer: uiReducer, actions: uiActions } = uiSlice;
