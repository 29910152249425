import { FeaturesConfig } from "availkit-js/dist/Models/Events/FeatureAvailabilityEvent";

import { AppDispatch } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { checkConsoleCapability } from "src/domains/Beacon/utils/consoleCapabilities";
import { logger } from "src/logging/logger";

interface IParams {
  features: FeaturesConfig;
}

// Allows to know what are the Console capabilities in order to enable/disable features in our side
export const setConsoleCapabilitiesThunk = ({ features }: IParams) => (
  dispatch: AppDispatch
) => {
  try {
    // Must check each console capability if exists based on the ConsoleCapabilitiesKeys
    dispatch(
      streamActions.setConsoleCapabilities({
        // The 'checkConsoleCapability' function will just allow the values from the ConsoleCapabilitiesKeys enum
        fourViewsLayout: checkConsoleCapability(
          "FOUR_VIEWS_LAYOUT",
          features as FeaturesConfig
        ),
      })
    );
  } catch (error: any) {
    logger().error(
      "There was an error while checking Console capabilities",
      error?.message
    );
  }
};
