import { push } from 'connected-react-router';
import { all, call, put, takeLatest, select, } from 'redux-saga/effects';
import resources from '../../../../Common/Constants/resources';
import { notificationService, urlService } from '../../../../Common/Services';
import ApiHelper from '../../../../Common/Services/ApiHelper';
import { setCurrentBrandAction } from './BrandsActions';
import { BRANDS } from './BrandsActionTypes';

function* getAllBrands(params) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', resources.brands, params.payload);
		yield all([
			put({
				type: BRANDS.SET_ALL_BRANDS,
				payload: response.content,
			}),
			put({
				type: BRANDS.SET_BRANDS_PAGINATION,
				payload: response,
			}),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getCurrentBrand(action) {
	try {
		const response = yield ApiHelper.get(
			'coreregistry',
			urlService.formatString(resources.brand, action.payload),
		);

		yield put(setCurrentBrandAction(response.content));
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createNewBrand(action) {
	try {
		yield call(ApiHelper.post, 'coreregistry', resources.brands, action.payload);
		const response = yield call(ApiHelper.get, 'coreregistry', resources.brands, { size: 10, page: 0 });
		yield all([
			put({
				type: BRANDS.SET_ALL_BRANDS,
				payload: response.content,
			}),
			notificationService.success('You successfully created vendor'),
		]);
	} catch (e) {
		yield notificationService.error(e === 'Input parameters can not be null or empty' ? 'Missing Required Fields' : e);
	}
}

function* updateBrand(action) {
	try {
		const updatedBrand = yield call(
			ApiHelper.put,
			'coreregistry',
			urlService.formatString(resources.brand, action.payload.id),
			action.payload,
		);

		yield all([
			put(setCurrentBrandAction(updatedBrand.content)),
			notificationService.success('You successfully updated vendor.'),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* deleteBrand(action) {
	try {
		yield call(
			ApiHelper.remove,
			'coreregistry',
			urlService.formatString(resources.brand, action.payload),
		);
    const currentBrandList = yield select(state => state.brandsReducer.brands);
    const filteredBrands = currentBrandList.filter((brand) => brand.id !== action.payload);
    
    yield put({
      type: BRANDS.SET_ALL_BRANDS,
      payload: filteredBrands,
    })
		yield all([
			put(push('/brands')),
			notificationService.success('You successfully deleted the vendor.'),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* brandsSaga() {
	yield takeLatest(BRANDS.GET_ALL_BRANDS, getAllBrands);
	yield takeLatest(BRANDS.GET_CURRENT_BRAND, getCurrentBrand);
	yield takeLatest(BRANDS.CREATE_NEW_BRAND, createNewBrand);
	yield takeLatest(BRANDS.UPDATE_BRAND, updateBrand);
	yield takeLatest(BRANDS.DELETE_BRAND, deleteBrand);
}
