import { BRANDS } from './BrandsActionTypes';

export const getAllBrandsAction = payload => ({
	type: BRANDS.GET_ALL_BRANDS,
	payload,
});

export const getCurrentBrandAction = brandId => ({
	type: BRANDS.GET_CURRENT_BRAND,
	payload: brandId,
});

export const getBrandByDomain = domain => ({
	type: BRANDS.GET_BRAND_BY_DOMAIN,
	payload: domain,
});

export const setCurrentBrandAction = brand => ({
	type: BRANDS.SET_CURRENT_BRAND,
	payload: brand,
});

export const createNewBrandAction = brandData => ({
	type: BRANDS.CREATE_NEW_BRAND,
	payload: brandData,
});

export const updateBrandAction = brandData => ({
	type: BRANDS.UPDATE_BRAND,
	payload: brandData,
});

export const deleteBrandAction = brandId => ({
	type: BRANDS.DELETE_BRAND,
	payload: brandId,
});
