import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting";
import { getCallDetailsThunk } from "src/domains/Beacon/store/meeting/thunks/getCallDetailsThunk";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { logger } from "src/logging/logger";
import { isFirefox } from "src/utils/browsers";

// LocalParticipant reconnected to the room
export const useRoomLocalParticipantReconnected = () => {
  const { room } = useAppSelector(selectTwilioState);
  const dispatch = useDispatch();

  useEffect(() => {
    room.on("reconnected", () => {
      logger().info("Twilio: Local Network reconnected.");

      // Getting the call details again to store it in the redux state
      dispatch(getCallDetailsThunk());
      // TODO: create and dispatch the following thunks or functions
      // based on the file initializeMultiPartyEvent:
      // joinAvailKitPresenceService - this thunk or function should connect the availKit instance to Presence Service
      // getSidebarStatus - to reset the redux store for the Sidebar state
      // getTelestrations - to get the telestrations history and store it

      dispatch(twilioActions.setDuplicateParticipantDisconnected(false));
      dispatch(twilioActions.setLocalNetworkDisconnected(false));
      // TODO: Do we have a loading spinner state in UI?
      // emit(setRefreshInProgress(false));
      if (isFirefox(navigator.userAgent)) {
        dispatch(meetingActions.setTriggerRefreshFrames(true));
      }
    });
  }, []);
};
