import React from "react";

import { Font } from "src/components/Font";
import { NoiseReductionToggle } from "src/domains/Beacon/components/SidePanel/SettingsContent/ConsoleAudioSettings/NoiseReductionToggle";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectIsUserHost } from "src/domains/Beacon/store/meeting/selectors";
import { selectStreamState } from "src/domains/Beacon/store/stream/selectors";

import { InputInfo } from "./InputInfo";
import styles from "./styles.scss";

export interface ConsoleAudioSettingsProps {
  "data-test-id"?: string;
}

export const ConsoleAudioSettings = () => {
  const {
    console: { audio: consoleAudioMuteStatus },
    consoleAudioIsBuiltInMic,
    noiseCancellationAvailable,
  } = useAppSelector(selectStreamState);
  const isUserHost = useAppSelector(selectIsUserHost);

  // only a Host can see NoiseReductionToggle when
  // Console supports noise cancellation and is not using Bluetooth
  const showNoiseCancellationToggle =
    isUserHost && consoleAudioIsBuiltInMic && noiseCancellationAvailable;

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <Font variant="h3" color="light">
          Console Audio Source
        </Font>
        <Font variant="b4" color="disabled">
          View procedure room audio source and current status
        </Font>
      </div>

      <InputInfo
        isBuildInMic={consoleAudioIsBuiltInMic}
        isMuted={consoleAudioMuteStatus === "mute"}
      />
      {showNoiseCancellationToggle && <NoiseReductionToggle />}
    </div>
  );
};
