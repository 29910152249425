import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import resources from '../../../../Common/Constants/resources';
import ApiHelper from '../../../../Common/Services/ApiHelper';
import notificationService from '../../../../Common/Services/notificationService';
import urlService from '../../../../Common/Services/urlService';
import { CONSOLES } from './ConsolesActionTypes';

function* getAllConsoles(params) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', resources.consoles, params.payload);
		yield all([
			put({
				type: CONSOLES.SET_ALL_CONSOLES,
				payload: response.content,
			}),
			put({
				type: CONSOLES.SET_CONSOLES_PAGINATION,
				payload: response,
			}),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getConsoleById(action) {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.console, action.payload));
		yield put({
			type: CONSOLES.SET_CONSOLE,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getConsolesByHospital(action) {
	const payloadClone = { ...action.payload };
	delete payloadClone.hospitalId;
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', urlService.formatString(resources.hospitalConsoles, action.payload.hospitalId), payloadClone);
		yield put({
			type: CONSOLES.SET_CONSOLES_BY_HOSPITAL,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* createNewConsole(action) {
	try {
		yield call(ApiHelper.post, 'coreregistry', resources.consoles, action.consoleData);
		yield notificationService.success('You successfully created console');
		if (action.isHospital) {
			yield getConsolesByHospital({ payload: action.param });
		} else {
			yield getAllConsoles({ payload: action.param });
		}
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* deleteConsole(action) {
	try {
		yield call(ApiHelper.remove, 'coreregistry', urlService.formatString(resources.console, action.consoleId));
		let currentConsoleList = null;
		let type = '';
		if (action.hospitalId) {
			currentConsoleList = yield select(state => state.consolesReducer.hospitalConsoles);
			type = CONSOLES.SET_CONSOLES_BY_HOSPITAL;
		} else {
			currentConsoleList = yield select(state => state.consolesReducer.consoles);
			type = CONSOLES.SET_ALL_CONSOLES;
		}
		const newConsoleList = currentConsoleList.filter(console => console.id !== action.consoleId);
		yield all([
			put({
				type,
				payload: newConsoleList,
			}),
			notificationService.success('You successfully deleted console'),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}


function* activateConsole(action) {
	try {
		yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.console, action.consoleId), action.data);
		let currentConsoleList = null;
		let type = '';
		currentConsoleList = yield select(state => state.consolesReducer.consoles);
		type = CONSOLES.SET_ALL_CONSOLES;
		const newConsoleList = currentConsoleList.map(console => (console.id === action.consoleId ? {
			...console,
			debugMode: action.data.debugMode,
		} : console));
		yield all([
			put({
				type,
				payload: newConsoleList,
			}),
			notificationService.success('You successfully update change for debug mode'),
		]);
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* updateConsole(action) {
	try {
		yield call(ApiHelper.put, 'coreregistry', urlService.formatString(resources.console, action.consoleId), action.data);
		yield notificationService.success('You successfully edited console');
	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* consolesSaga() {
	yield takeLatest(CONSOLES.CREATE_NEW_CONSOLE, createNewConsole);
	yield takeLatest(CONSOLES.GET_ALL_CONSOLES, getAllConsoles);
	yield takeLatest(CONSOLES.DELETE_CONSOLE, deleteConsole);
	yield takeLatest(CONSOLES.ACTIVATE_CONSOLES, activateConsole);
	yield takeLatest(CONSOLES.GET_CONSOLE_BY_ID, getConsoleById);
	yield takeLatest(CONSOLES.UPDATE_CONSOLE, updateConsole);
	yield takeLatest(CONSOLES.GET_CONSOLES_BY_HOSPITAL, getConsolesByHospital);
}
