import { DISTRIBUTORS } from './DistributorsActionTypes';

export const initialState = {
	distributors: null,
	pagination: {
		page: 0,
		pageSize: 10,
		totalPages: 1,
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case DISTRIBUTORS.SET_ALL_DISTRIBUTORS:
			return {
				...state,
				distributors: action.payload,
			};
		case DISTRIBUTORS.SET_DISTRIBUTORS_PAGINATION:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload.pageNumber,
					size: action.payload.pageSize,
					totalPages: action.payload.totalPages,
				},
			};
		default:
			return state;
	}
}
