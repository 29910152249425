import { Nurep } from "availkit-js";
import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";
import { AVKConsoleCallbackAcceptEvent } from "availkit-js/dist/Models/Events/AVKConsoleCallbackAcceptEvent";
import { FeatureAvailabilityEvent } from "availkit-js/dist/Models/Events/FeatureAvailabilityEvent";
import { NHighlightLocationEvent } from "availkit-js/dist/Models/Events/NHighlightLocationEvent";
import { NIncomingServerCallEvent } from "availkit-js/dist/Models/Events/NIncomingServerCallEvent";
import { NActor } from "availkit-js/dist/Models/NActor";
import { Participant, Room, NetworkQualityLevel } from "twilio-video";

import { TwilioLogger } from "portalcall/commoncall/common/TwilioLogger";

import { LocalOrRemoteMediaTrack } from "../types";
import { NetworkQualityStats } from "../types/network-quality";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";

export enum FeaturesToSync {
  all = "all",
  eventDetails = "eventDetails",
  presence = "presence",
}

export enum CallState {
  READY = "ready",
  INCOMING = "incoming",
  ANSWERED = "answered",
  DECLINED = "declined",
  INPROGRESS = "inprogress",
  ENDCALLCONFIRMATION = "endcallconfirmation",
  ENDCALLCONFIRMED = "endcallconfirmed",
  NEWPRESET = "newpreset",
}

export interface AvailUUIDType {
  clientType: string;
  clientId: string;
  deviceType: string;
  deviceInstance: string;
}

export interface MediaDeviceEnumeration {
  localMedia: MediaDeviceInfo;
  audioinput: MediaDeviceInfo[];
  audiooutput: MediaDeviceInfo[];
  videoinput: MediaDeviceInfo[];
}

export interface TwilioCredentials {
  token: string;
  callSid?: string;
  eventId?: string;
}

export interface MPLiteRemoteTracks {
  console: LocalOrRemoteMediaTrack[];
  host: LocalOrRemoteMediaTrack[];
  participants: LocalOrRemoteMediaTrack[];
}
export interface MultiPartyInitializeState {
  meetingToken: MultiPartyCallEventInfo;
  featuresToSync: FeaturesToSync;
}

export interface AvailMediaError {
  title: string;
  message: string;
  actionMessage: string;
}

export interface TwilioState {
  twilioCredentials: TwilioCredentials;
  room: Room | null;
  localTracks: LocalOrRemoteMediaTrack[];
  remoteTracks: LocalOrRemoteMediaTrack[];
  mpLiteRemoteTracks: MPLiteRemoteTracks;
  participants: Participant[];
  error: boolean;
  errorMessage: string[];
  mediaErrorCode: string;
  remoteHasJoinedRoom: boolean;
  consoleHasJoinedRoom: boolean;
  hostHasJoinedRoom: boolean;
  enableCameraDiscovery: boolean;
  isPollingNQ: boolean;
  statsCQ: NetworkQualityStats | null; // averages for whole call
  callStartTime: number; // tentative
  callTime: number;
  log: string[];
  dimensionsNeedUpdate: boolean;
  duplicateParticipantDisconnected: boolean;
  localNetworkQualityLevel: NetworkQualityLevel;
  localNetworkDisconnected: boolean;
  bannerNeedsUpdate: boolean;
  dominantSpeaker: string; // Avail UUID
  twilioLogger: typeof TwilioLogger | undefined; // TODO: Storing instances in Redux is an anti-pattern. We should re-consider the code-flow around this.
}

export interface PortalIdentity {
  access_token: string;
  token_type: string;
  refresh_token: string;
  login_id: string;
  expires_in: number;
  scope: "" | "read" | "write";
  profile_id: string;
  org_id: string;
  org_name: string;
  status: string;
  pubnub_channel: string;
  pubnub_publ_key: string;
  finishedAccountSetup: boolean;
  pubnub_subs_key: string;
  userTypes: [];
  userId: string;
  pubnub_auth_key: string;
  email: string;
  jti: string;
  isPasswordAutoGenerated: boolean;
  isTwoFactorAuthEnabled: boolean;
  isPasswordExpired: boolean;
}

export interface UserInfoType {
  loginId: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
}

// export interface EventInfoType {
//   content: EventInfo
// }

/*
  ready - The user is ready to accept the calls.
  incoming - There is an incoming call.
  answered - The user just answered the call.
  declined - The user just declined the call.
  inprogress - A call is in progress.
*/
export type CallStateType = CallState;

export interface ZoomSettingsType {
  start: number;
  min: number;
  max: number;
  step: number;
  onChange: any;
}

export interface ZoomSliderState {
  name: string;
  cameraId: string;
  value: number;
  settings: ZoomSettingsType;
}

export interface RadioButtonState {
  radioEnabled: boolean;
  checked: boolean;
}

export interface ZoomStateType {
  sliderZoom: ZoomSliderState[];
  radioButtonLeft: RadioButtonState;
  radioButtonRight: RadioButtonState;
}

export interface CallBackEventStateType {
  twilioToken: string;
  event: AVKConsoleCallbackAcceptEvent;
}
export interface MenuOptionType {
  name: string;
  id: string;
  memberOf: string;
  isVisible: boolean;
  isDisabled: boolean;
  css: null | {
    normal: string;
    hover: string;
    selected: string;
    disabled: string;
  };
}

export interface LayoutFrame {
  name: string;
  isFullScreen: boolean;
  menuOptions: MenuOptionType[];
  cameraId: string;
  cameraLabel: string;
}

export interface FreezeFrameStateType {
  menuOptions: MenuOptionType[];
  freezeType: string;
  isFullScreen: boolean;
}

export type UserRoleType = "PARTICIPANT" | "HOST";

export interface MultiPartyEventParticipant {
  // storing participant to display roster
  email: string;
  firstName: string;
  lastName: string;
  loginId: string;
  role: UserRoleType;
}

export interface MultiPartyEventDetail {
  billingUserId: string;
  callSid: string;
  description: string;
  endDate: string;
  eventId: string;
  hospitalId: string;
  hostUserId: string;
  participants: MultiPartyEventParticipant[];
  roomId: string;
  startDate: string;
  status: "CREATED" | "ENDED";
  subject: string;
  surgeonName: string;
}

export interface MultiPartyCallEventInfo {
  mode: "MP";
  joinId: string;
  eventId: string;
  eventDetails: any;
  userRole?: UserRoleType; // for user role
}

export interface PresenceServerSession {
  // TODO - there is more fields but as they are subject to change and not currently used, we'll leave them out
  sidebar: boolean;
  features?: FeatureAvailabilityEvent;
}

export interface PresenceServerConsoles {
  [console: string]: PresenceServerConsole;
}

interface PresenceServerConsole {
  joinTime: string;
  isActive: boolean;
  cameras: PresenceServerConsoleCameraInfo[];
  createdOn: string;
  lastUpdatedOn: string;
}

export interface PresenceServerConsoleCameraInfo {
  id: string;
  name: string;
  cameraType: number;
  cameraMount: number;
  cameraInput: number;
  location: PresenceServerConsoleInfoLocation;
  createdOn: string;
  lastUpdatedOn: string;
}
export interface PresenceServerConsoleExternalInputInfo {
  inputId: string;
  inputName: string;
  isAutoCrop: boolean;
  uniqueIdentifier: string;
  zoomLevel: number;
  externalInputPosition: PresenceServerConsoleExternalInputInfoCoordinates[];
  externalInputShift: PresenceServerConsoleExternalInputInfoCoordinates[];
}

interface PresenceServerConsoleInfoLocation {
  x: number;
  y: number;
  zoomLevel: number;
  createdOn: string;
  lastUpdatedOn: string;
}

interface PresenceServerConsoleExternalInputInfoCoordinates {
  x: number;
  y: number;
}

export type CallModeType = "MP" | "P2P" | ""; // empty string = no mode has been set

export interface MeetingStateType {
  callState: CallStateType;
  callSid: string;
  consoleHasExternalInputResizing: boolean;
  currentUserRole: UserRoleType | null;
  mode: CallModeType;
  multiPartyCallEventInfo: MultiPartyCallEventInfo | null; // info returned from avail-web-ui
  multiPartyCallEventDetail: MultiPartyEventDetail | null; // info returned from mplite
  telestrationHistory: AVKAnnotationEvent[];
  callEventInfo: NIncomingServerCallEvent | null; // holds remote end info
  callback: boolean;
  callbackInfo: CallBackEventStateType | null;
  cameras: Array<AVKCamera | AVKExternalInput>;
  camerasHaveUpdate: boolean;
  layoutFrames: LayoutFrame[];
  layoutHasUpdate: boolean;
  triggerRefreshFrames: boolean;
  enableInCallControls: boolean;
  noiseCancellation: boolean | null;
  noiseCancellationAvailable: boolean | null;
  bluetoothEnabled: boolean | null;
  zoomState: ZoomStateType;
  freezeFrameState: FreezeFrameStateType;
  freezeFrameHasUpdate: boolean;
  enableSSFF: boolean;
  joinId: string;
}

export interface MediaStateType {
  pip: "hide" | "show";
  self: {
    audio: "mute" | "unmute";
    video: "mute" | "unmute";
    // TODO consider if this should be in a different property than self, maybe shared?
    sidebar: "active" | "inactive";
  };
  console: {
    audio: "mute" | "unmute";
    video: "mute" | "unmute";
  };
}

export interface UserState {
  actor: NActor;
  identity: PortalIdentity;
  localMedia: MediaDeviceInfo | null;
  videoinput: MediaDeviceInfo[];
  audioinput: MediaDeviceInfo[];
  audiooutput: MediaDeviceInfo[];
  hasJoinedRoom: boolean;
  refreshInProgress: boolean;
  callEnded: boolean;
  error: boolean;
  mediaState: MediaStateType;
}

export type TelestrationState = "on" | "off" | "clear";
export type TelestrationStylusColor = "red" | "green" | "blue" | "yellow" | "";

export interface PointerState {
  telestration: TelestrationState;
  color: TelestrationStylusColor;
  highlights: NHighlightLocationEvent[];
}

export interface Point {
  x: number;
  y: number;
}

export interface FrozenFrameInfo {
  frameType: string;
  transferIdentifier: string;
}

export interface ImageState {
  freezeFrame: string;
  frozen: boolean;
  error: boolean;
  leftTransferIdentifier?: string;
  rightTransferIdentifier?: string;
  fullscreenTransferIdentifier?: string;
  frozenFrames: Set<FrozenFrameInfo>;
}

export interface PresetSnapshotState {
  layoutFrames: LayoutFrame[];
  cameras: Array<AVKCamera | AVKExternalInput>;
  zoomState: ZoomStateType;
}

export interface PresetState {
  preset: Record<string, PresetSnapshotState>;
}

export interface AvailKitState {
  availKitInstance: Nurep | null;
}

export interface AppState {
  user: UserState;
  meeting: MeetingStateType;
  pointer: PointerState;
  twilio: TwilioState;
  image: ImageState;
  preset: PresetState;
  availKit: AvailKitState;
}