export const NoConnectionIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="no-network-connection-icon"
    >
      <g clipPath="url(#clip0_7422_6632)">
        <path
          d="M42 21.9993L46 17.9993C38.54 10.5393 28.26 7.6993 18.6 9.3793L23.76 14.5393C30.36 14.4993 36.98 16.9793 42 21.9993ZM38 25.9993C35.84 23.8393 33.28 22.2993 30.56 21.3393L36.6 27.3793L38 25.9993ZM18 33.9993L24 39.9993L30 33.9993C26.7 30.6793 21.32 30.6793 18 33.9993ZM6.82 3.2793L4 6.0993L10.1 12.1993C7.18 13.6593 4.44 15.5793 2 17.9993L6 21.9993C8.46 19.5393 11.3 17.6793 14.34 16.4393L18.82 20.9193C15.58 21.7793 12.54 23.4793 10 25.9993L14 29.9993C16.7 27.2993 20.22 25.9193 23.78 25.8793L37.94 40.0393L40.76 37.2193L6.82 3.2793Z"
          fill="#F80000"
        />
      </g>
      <defs>
        <clipPath id="clip0_7422_6632">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
