import { SCHEDULER } from './SchedulerActionTypes';

export const initialState = {
	userEvents: [],
	userCallEvents: [],
	meetingTime: [],
  missedCalls: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SCHEDULER.SET_USER_SCHEDULE:
			return {
				...state,
				userEvents: action.payload,
			};
		case SCHEDULER.SET_USER_CALL_SCHEDULE:
			return {
				...state,
				userCallEvents: action.payload,
			};
		case SCHEDULER.SET_USER_MEETING_TIME:
			return {
				...state,
				meetingTime: action.payload,
			};
    case SCHEDULER.SET_USER_MISSED_CALLS:
      return {
        ...state,
        missedCalls: action.payload,
      };
		case SCHEDULER.DELETE_SELECTED_EVENT_SUCCESS:
			return {
				...state,
				userEvents: state.userEvents.filter(event => event.id !== action.payload),
			};
		case SCHEDULER.DELETE_SELECTED_CALL_EVENT_SUCCESS:
			return {
				...state,
				userCallEvents: state.userCallEvents.filter(event => event.id !== action.payload),
			};
		case SCHEDULER.DELETE_RECURRING_EVENT_SUCCESS:
			return {
				...state,
				userEvents: action.payload,
			};
		default:
			return state;
	}
}
