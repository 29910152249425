import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import classNames from 'classnames';
import Can from '../../../../../../Common/Acl/AccessControl';
import { Popup, } from "semantic-ui-react";

/* Components */
import { EntityProfileSidebar } from '../../../../Common';
import { Button, Fields, Icon, validators } from '../../../../../../Common/Components';
import { getcurrentUserTypes } from '../../../../HomeSelectors';
/* Actions */
import { getAllHospitalsAction } from '../../../Organisation/Hospitals/HospitalsActions';
import { getConsoleByIdAction, updateConsoleAction, deleteConsoleAction } from '../../ConsolesActions';

import { getInitialValuesForConsoleProfile } from '../../ConsolesSelectors';
import ModalComponent from '../../../../../../Common/Components/Modal/Modal';

import './ConsoleProfile.scss'


class ConsoleProfile extends PureComponent {
	constructor (props) {
		super(props)

		this.state = {
			isDeleteModalOpened: false
		}
	}
	componentDidMount() {
		const consoleId = this.props.match.params.id;
		this.props.getConsoleById(consoleId);
		this.props.getAllHospitals();
	}

	onCreateConsoleHandler = (data) => {
		const consoleId = this.props.match.params.id;
		this.props.updateConsole(consoleId, {
			'hospitalId': data.hospitalId,
			'macAddress': data.macAddress,
			'serialNumber': data.serialNumber,
			'version': data.version,
		});
	};

	onClickDeleteHandler = () => {
		this.toggleDeleteModal();
	}

	onDeleteConsoleHandler = async () => {
		await this.props.deleteConsole(this.props.console.id);
		this.toggleDeleteModal();
		this.props.history.push('/consoles');
	}

	toggleDeleteModal = () => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
		});
	}

	renderDeleteConsoleModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onDeleteConsoleHandler}
			onCancel={this.toggleDeleteModal}
			confirmText="Delete"
		>
			<span>
				You are about to delete <span className="text--bold">{this.props.console && this.props.console.serialNumber}</span>.
				Would you like to proceed with this action?
			</span>
		</ModalComponent>
	);
    
	render() {
		return (
			<form className="console__profile avail-profile__edit-form" onSubmit={this.props.handleSubmit(this.onCreateConsoleHandler)}>
				<EntityProfileSidebar title="Console Profile">
					<div className="console-profile__sidebar-container">
            <Popup
								trigger={<div className="console-profile__serial-number">{this.props.console && this.props.console.serialNumber}</div>}
								hoverable
								basic
								position="bottom right"
							>
                <p>{this.props.console?.serialNumber}</p>
              </Popup>
						<div
							className={classNames({
								'console__pairing-status': true,
								'console__pairing-status--paired': this.props.initialValues && this.props.initialValues.room,
							})}
						>
							{this.props.initialValues && this.props.initialValues.room ? 'Console paired' : 'Console not paired'}
						</div>
					</div>
				</EntityProfileSidebar>
				<div className="console-profile display--flex justify-content--space-between">
					<div>
						{this.props.hospitals && (
							<React.Fragment>
								<Can
									userTypes={this.props.currentUserTypes}
									action="read"
									context="editConsole"
								>
									<Fields.SelectField
										labelText="Assigned facility"
										placeholder="Please select hospital"
										name="hospitalId"
										options={this.props.hospitals.map(hospital => ({
											key: hospital.id,
											value: hospital.id,
											text: hospital.name,
										}))}
										validate={validators.required}
										className="avail__input console__dropdown"
									/>
								</Can>
								<Can
									userTypes={this.props.currentUserTypes}
									action="read"
									context="readConsole"
								>
									<Fields.SelectField
										labelText="Assigned facility"
										placeholder="Please select hospital"
										name="hospitalId"
										options={this.props.hospitals.map(hospital => ({
											key: hospital.id,
											value: hospital.id,
											text: hospital.name,
										}))}
										validate={validators.required}
										className="avail__input console__dropdown"
										disabled
									/>
								</Can>
							</React.Fragment>
						)}
						{this.props.initialValues && this.props.initialValues.room && (
							<Fields.InputField
								className="user-profile__general-input avail__input"
								labelText="Room Name"
								placeholder="Please enter room name"
								name="room.name"
								validate={validators.required}
								disabled
							/>
						)}
						<Fields.InputField
							className="user-profile__general-input avail__input"
							labelText="Console MAC address"
							placeholder="Please enter console MAC address"
							name="macAddress"
							validate={[validators.required, validators.macAddress]}
							disabled
						/>
						
						<Can
							userTypes={this.props.currentUserTypes}
							action="read"
							context="editConsole"
						>
							<Fields.InputField
								className="user-profile__general-input avail__input"
								labelText="Console version"
								placeholder="Please enter console version"
								name="version"
							/>
						</Can>
						<Can
							userTypes={this.props.currentUserTypes}
							action="read"
							context="readConsole"
						>
							<Fields.InputField
								className="user-profile__general-input avail__input"
								labelText="Console version"
								placeholder="Please enter console version"
								name="version"
								disabled
							/>
						</Can>
						{this.props.console && (
							<Can
								userTypes={this.props.currentUserTypes}
								action="read"
								context="editConsole"
							>
								<div className="delete--button">
									<Button onClick={this.onClickDeleteHandler} className="console__delete-button">
										Delete Console
									</Button>
								</div>
							</Can>
						)}
					</div>
					<div>
						<Can
							userTypes={this.props.currentUserTypes}
							action="read"
							context="editConsole"
						>
							<Button className="button-with-icon align-self--flex-start" type="submit">
								<Icon className="icon-save icon--orange entity-profile__submit-icon" />
								<span className="entity-profile__submit-title">Save Changes</span>
							</Button>
						</Can>
					</div>
				</div>
				{this.renderDeleteConsoleModal()}
			</form>
		);
	}
}

ConsoleProfile.propTypes = {};

const mapStateToProps = state => ({
	initialValues: getInitialValuesForConsoleProfile(state),
	hospitals: state.hospitalsReducer.hospitals,
	console: state.consolesReducer.console,
	currentUserTypes: getcurrentUserTypes(state),
});

const mapDispatchToProps = dispatch => ({
	getConsoleById: consoleId => dispatch(getConsoleByIdAction(consoleId)),
	getAllHospitals: params => dispatch(getAllHospitalsAction(params)),
	updateConsole: (consoleId, data) => dispatch(updateConsoleAction(consoleId, data)),
	deleteConsole: (consoleId, hospitalId) => dispatch(deleteConsoleAction(consoleId, hospitalId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'consoleProfileForm',
	enableReinitialize: true,
})(ConsoleProfile));
