import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
/* Actions & Helpers */
import userTypes from '../../../../../../Common/Constants/userTypes';
import userService from '../../../../../../Common/Services/userService';
import { setUserStatusAction } from '../../../../Common/ApprovalList/ApprovalListActions';
/* Components */
import { AddNewButtonWithIcon, Pill, ProfileImage } from '../../../../Common';

class HospitalApprovalTableRow extends PureComponent {
	onOpenConfirmationModal = isApproved => this.props.openModal(isApproved, this.props.rowData.user);

	renderTableAction = (status) => {
		switch (status) {
			case 'PENDING':
			case 'TRIAL':
				return (
					<div className="flex-direction--row justify-content--flex-end">
						<AddNewButtonWithIcon
							title="Approve"
							titleClass="table__action-title"
							iconClass="icon-checkmark-outline icon--green table__action-icon table__action-icon--blue"
							onClick={this.onOpenConfirmationModal}
							argument={{ approved: true }}
						/>
						<AddNewButtonWithIcon
							title="Reject"
							titleClass="table__action-title table__action-title--red"
							iconClass="icon-close-outline icon--red-secondary table__action-icon table__action-icon--red"
							onClick={this.onOpenConfirmationModal}
							argument={{ approved: false }}
						/>
					</div>
				);
			case 'APPROVED':
				return (
					<div className="justify-content--flex-end">
						<AddNewButtonWithIcon
							title="Reject"
							titleClass="table__action-title table__action-title--red"
							iconClass="icon-close-outline icon--red-secondary table__action-icon table__action-icon--red"
							onClick={this.onOpenConfirmationModal}
							argument={{ approved: false }}
						/>
					</div>
				);
			case 'REJECTED':
				return (
					<div className="justify-content--flex-end">
						<AddNewButtonWithIcon
							title="Approve"
							titleClass="table__action-title"
							iconClass="icon-checkmark-outline icon--green table__action-icon table__action-icon--blue"
							onClick={this.onOpenConfirmationModal}
							argument={{ approved: true }}
						/>
					</div>
				);
			default:
				return null;
		}
	};

	renderBrands = brands => (
		brands && brands.length > 0 ? brands.map((brand, index) => (
			`${brand.name}${index !== brands.length - 1 ? ', ' : ''}`
		)) : '/'
	);

	render() {
		const { user, status } = this.props.rowData;

		return (
			user ? (
				<Table.Row>
					<Table.Cell onClick={this.redirect} title={[user.firstName].join(' ')}>
						<div className="align--center display--flex">
							<div className="position--relative">
								<ProfileImage
									size="sm"
									className={`profile-image--${userTypes[user.userTypes[0]].className} `}
									initials={userService.getUserInitials(user.firstName, user.lastName)}
								/>
							</div>
							<span className="table__username users__table-username">{`${user.firstName} ${user.lastName}`}</span>
						</div>
					</Table.Cell>
					<Table.Cell title={[userTypes[user.userTypes[0]].title].join(' ')}>
						<Pill
							isDisabled={status === 'DELETED'}
							class={`user-type--${userTypes[user.userTypes[0]].className} `}
							title={userTypes[user.userTypes[0]].title}
						/>
					</Table.Cell>
					<Table.Cell>{this.renderBrands(user.brands)}</Table.Cell>
					<Table.Cell title={[status].join(' ')}>
						<Pill
							class={`status status--${status.toLowerCase()} `}
							title={status.toLowerCase()}
						/>
					</Table.Cell>
					<Table.Cell className="table__action-table-cell">
						{this.renderTableAction(status)}
					</Table.Cell>
				</Table.Row>
			) : null
		);
	}
}

HospitalApprovalTableRow.propTypes = {};

const mapDispatchToProps = dispatch => ({
	setUserStatus: (hospitalId, userId, status) => dispatch(setUserStatusAction(hospitalId, userId, status)),
});

export default withRouter(connect(undefined, mapDispatchToProps)(HospitalApprovalTableRow));

