import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { Fields, Button, validators } from '../../../../Common/Components';

import { logoutAction } from '../../AuthenticationActions';
import {
	updateUserPasswordAction,
} from '../../../Home/Features/Users/UsersActions';
import { urlService } from '../../../../Common/Services';

import { LoginPage } from "/src/domains/Login";
class ResetPasswordExpire extends React.PureComponent {
    constructor(props) {
		super(props);
		this.userId = urlService.getQueryParam(props.location, 'userId');

    }
	
    handleResetPasswordSubmit = (data) => {
		const changeData = {
			oldPassword: data.old_password,
			password: data.new_password,
			confirmPassword: data.confirm_password,
		};
		
		let expired = true
		
		this.props.updateUserPassword(this.userId, changeData, expired);
	};


	render() {
		const { handleSubmit } = this.props;
		return (
			<LoginPage className="login">
        <div className="login__subheading">Please update your expired password</div>
        <form className="login__form" onSubmit={handleSubmit(this.handleResetPasswordSubmit)}>
          <Fields.InputField
            type="password"
            className="avail__input"
            labelText="Current Password"
            placeholder="Enter old password"
            name="old_password"
            validate={[
              validators.required
            ]}
          />
          <Fields.InputField
            type="password"
            className="avail__input"
            labelText="New Password"
            placeholder="Enter new password"
            name="new_password"
            validate={[
              validators.required,
              validators.numberValidator,
              validators.capitalValidator,
              validators.lowerValidator,
              validators.specialValidator,
              validators.minimumLength8,
              validators.maximumLength15,
              validators.oldPasswordValidator,
            ]}
          />
          <Fields.InputField
            type="password"
            className="avail__input"
            labelText="Confirm Password"
            placeholder="Retype your password"
            name="confirm_password"
            validate={[
              validators.required,
              validators.newPasswordValidator,
            ]}
          />

          <div className="justify-content--flex-end">
            <Button
              isGhost
              className="login__button"
              onClick={this.props.logout}
            >
              Cancel
            </Button>
            <Button
              isFull
              isGreen
              className="login__button"
              type="submit"
            >
              Change Password
            </Button>
          </div>
        </form>
			</LoginPage>
		);
	}
}

const mapStateToProps = state => ({
	currentUser: state.homeReducer.currentUser,
});

const mapDispatchToProps = dispatch => ({
	updateUserPassword: (userId, changeData, expired) =>
		dispatch(updateUserPasswordAction(userId, changeData, expired)),
	logout: () => dispatch(logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'resetPasswordExpireForm',
})(ResetPasswordExpire));
