import { PresetSnapshotState } from "../models";

export enum PresetActionKeys {
  SAVE_PRESET = "SAVE_PRESET",
  SAVE_PRESET_OK = "SAVE_PRESET_OK"
}

interface SavePreset {
  type: PresetActionKeys.SAVE_PRESET;
  newpreset: string;
  presetSnapshot: PresetSnapshotState;
}

interface PresetSavedAck {
  type: PresetActionKeys.SAVE_PRESET_OK;
}

export type PresetActionType = SavePreset | PresetSavedAck;
