// Clears timeouts without doing it manually and avoid memory leaks in a future
export const timeoutCallback = (callback, time: number = 100) => {
  const timer = setTimeout(() => {
    callback();
    if (timer) {
      clearTimeout(timer);
    }
  }, time);
  return timer;
};
