import React from "react";
import svgOpen from "../../static/roster-panel-open.svg";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function RosterOpen({ open, setOpen }: Props) {
  return (
    <button
      className={`roster-button roster-button__open ${open ? "off" : ""}`}
      onClick={() => setOpen(true)}
    >
      <img src={svgOpen} alt="close roster" />
    </button>
  );
}

export default RosterOpen;
