import { TABS } from './TabsActionTypes';

export const incrementActiveIndexAction = () => ({
	type: TABS.INCREMENT_ACTIVE_INDEX,
});

export const decrementActiveIndexAction = () => ({
	type: TABS.DECREMENT_ACTIVE_INDEX,
});

export const resetActiveIndexAction = () => ({
	type: TABS.RESET_ACTIVE_INDEX,
});
