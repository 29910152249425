import { NModel } from "availkit-js";

import { UserDetails } from "src/services/ApiClient/users/types";

import LocalStorageService from "./LocalStorageService";
import SessionStorageService from "./SessionStorageService";

const ACCOUNT_SETUP_FINISHED = "finishedAccountSetup";
const AVAIL_ACCESS_TOKEN = "avail_access_token";
const AVAIL_REFRESH_TOKEN = "avail_refresh_token";
const CACHED_USER_INFO = "cachedUserInfo";
const DEVICE_ID_KEY = "avail-device-instance-id";
const EULA_VERSION = "eulaVersion";
const MFA_AUTH_DATA = "MFAAuthDetails";
const MFA_AUTH_INFO = "mfaAuthInfo";
const REDIRECT_URL = "redirectUrl";

export default class UserSessionService {
  static getAccountSetupStatus(status) {
    return SessionStorageService.add(ACCOUNT_SETUP_FINISHED, status);
  }

  static getAccessToken() {
    return SessionStorageService.get(AVAIL_ACCESS_TOKEN);
  }

  static getRefreshToken() {
    return SessionStorageService.get(AVAIL_REFRESH_TOKEN);
  }

  static setAccountSetupStatus(status) {
    SessionStorageService.add(ACCOUNT_SETUP_FINISHED, status);
  }

  static getCachedUserInfo(): UserDetails {
    return SessionStorageService.get(CACHED_USER_INFO);
  }

  static setCachedUserInfo(user) {
    SessionStorageService.add(CACHED_USER_INFO, user);
  }

  static getEulaVersion(storageType: "local" | "session" = "local") {
    return storageType === "local"
      ? LocalStorageService.get(EULA_VERSION)
      : SessionStorageService.get(EULA_VERSION);
  }

  static setEulaVersion(version, storageType: "local" | "session" = "local") {
    if (storageType === "local") {
      LocalStorageService.add(EULA_VERSION, version);
    } else {
      SessionStorageService.add(EULA_VERSION, version);
    }
  }

  static getMFAAuthData() {
    return LocalStorageService.get(MFA_AUTH_DATA);
  }

  static getMFAAuthInfo() {
    return SessionStorageService.get(MFA_AUTH_INFO);
  }

  static getRedirectUrl() {
    return SessionStorageService.get(REDIRECT_URL);
  }

  static removeRedirectUrl() {
    SessionStorageService.remove(REDIRECT_URL);
  }

  static setExpiredAccount(status) {
    SessionStorageService.add("expiredAccount", status);
  }
  static setResetPassword(status) {
    SessionStorageService.add("resetPassword", status);
  }

  static setMFAAuthData(MFAData) {
    LocalStorageService.add(MFA_AUTH_DATA, MFAData);
  }

  static getDeviceId() {
    let id = LocalStorageService.get(DEVICE_ID_KEY);

    if (!id) {
      // @ts-ignore
      id = new NModel().uniqueIdentifier; // Guid
      UserSessionService.setDeviceId(id);
    }

    return id;
  }

  static setDeviceId(id) {
    LocalStorageService.add(DEVICE_ID_KEY, id);
  }
}
