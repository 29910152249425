import { useFreezeFrameService } from "src/domains/Beacon/subscriptions/FreezeFrameService/useFreezeFrameService";
import { useCameraDiscovery } from "src/domains/Beacon/subscriptions/useCameraDiscovery";
import { useDidDiscoverCameras } from "src/domains/Beacon/subscriptions/useDidDiscoverCameras";
import { useDidDiscoverVideoSources } from "src/domains/Beacon/subscriptions/useDidDiscoverVideoSources";
import { useMediaSubscriptions } from "src/domains/Beacon/subscriptions/useMediaSubscriptions";
import { useOnConsoleVideoTurnedOff } from "src/domains/Beacon/subscriptions/useOnConsoleVideoTurnedOff";
import { useSidebarSubscriptions } from "src/domains/Beacon/subscriptions/useSidebarSubscriptions";
import { useStatusCallback } from "src/domains/Beacon/subscriptions/useStatusCallback";
import { useTelephonyServiceSubscriptions } from "src/domains/Beacon/subscriptions/useTelephonyServiceSubscriptions";
import { AvailKitService } from "src/services/AvailKitService";

// This subscriptions "component" is the master subscription component.
// Can also be referred as a subscription provider.

// This component takes care of initializing all of the subscriptions necessary
// for AvailKit events to be listened to once availKit has been initialized.
// This way we don't need to have a lot of extra logic within our components
// to handle availkit events.
export const AvailKitSubscriptions = () => {
  const availKit = AvailKitService.instance;

  return availKit ? <SubscriptionComponent /> : null;
};

// These subscription hooks have some common logic going on in which they
// - update state after receiving events from availkit
// - add listeners to corresponding services
// - set individual trigger functions that react upon events being received (EG `onReceivedSidebarStatus`)

// See individual subscription hooks for more details
const SubscriptionComponent = () => {
  // TODO. useCameraDiscoverySubscriptions();
  // TODO. useChangeLayoutSubscriptions();
  // TODO. useConsoleAudioDeviceChangeSubscriptions(); // Changing from BlueTooth to Console Audio.
  // useTelestrationSubscriptions() -> lives with Easel & useDrawingHook. Dependent upon useDrawingHook state.
  // TODO. useFreezeFrameSubscriptions()
  // TODO: useMediaStateChangeSubscriptions.
  // Multiparty - Have it as a placeholder to track participants mute/unmute statuses.
  useStatusCallback();
  useSidebarSubscriptions();
  useCameraDiscovery();
  useDidDiscoverCameras();
  useDidDiscoverVideoSources();

  useTelephonyServiceSubscriptions();

  useMediaSubscriptions();

  // Freeze Frame Service
  // separated listeners of the same service cause errors
  useFreezeFrameService();

  // run some code when the console turns off their video
  useOnConsoleVideoTurnedOff();

  return null;
};
