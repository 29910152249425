import React from 'react';
import { Label } from 'semantic-ui-react';
import "./NotificationBadge.scss"

const NotificationBadge = ({ color, floating, size, text }) => (
	<Label color={color} floating={floating} size={size} circular>
		{text}
	</Label>
)

export default NotificationBadge;
