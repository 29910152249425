import { RootState } from "src/domains/Beacon/store";
import { AppDispatch } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { publishVideoTrackThunk } from "src/domains/Beacon/store/stream/thunks/publishVideoTrackThunk";
import { getParticipantVideoTracks } from "src/domains/Beacon/utils/twilio";
import { logger, LoggerLevels } from "src/logging/logger";

export const unpublishShareScreenTrackThunk = () => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  try {
    logger().info("User is un-publishing screen share tracks to Twilio");

    const {
      twilio: {
        room: { localParticipant },
      },
    } = getState();

    const tracks = getParticipantVideoTracks(localParticipant);

    // Must stop the tracks
    tracks?.map((t) => t.stop());

    // Logs in order to know the tracks shape in case of an error
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "unpublishShareScreenTrackThunk",
      },
      `Un-publishing screen share tracks: ${JSON.stringify(tracks)}`
    );

    // Should remove the participant's video tracks for screen sharing
    await localParticipant.unpublishTracks(tracks);
    // Should clear screen-sharing track in order to not persist it after a Twilio token refresh
    dispatch(streamActions.setScreenShareTrack(null));

    // Must need to reset Host's Pip video
    await dispatch(publishVideoTrackThunk());
  } catch (error: any) {
    logger().error("Error un-publishing screen share tracks", error?.message);
    throw new Error("Error un-publishing new screen share tracks.");
  }
};
