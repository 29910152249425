import { FEATURE_FLAGS } from "src/featureFlags";
import SecurityService from "src/services/SecurityService";
import { generateTraceId } from "src/utils/generateTraceId";

import { unAuthorizedHandler } from "./ApiHandlers";
import {
  // ErrorResponse,
  // SuccessResponse,
  Headers,
  Options,
  HTTPMethods,
} from "./types";

// TODO: ask product about this message
export const API_MESSAGE_5XX =
  "A server error occured, please contact your admin or reach Avail Technical Support at 1-833-GO AVAIL (462-8245) or support@avail.io.";

export const API_MESSAGE_UNKNOWN_ERROR =
  "A server error occured, please contact your admin or reach Avail Technical Support at 1-833-GO AVAIL (462-8245) or support@avail.io.";
// just a basic error class that has reponse in it so we can reference info from the response when we need it (like status code)
export class FetchError extends Error {
  constructor(public response: Response, message?: string) {
    super(message);
    this.response = response;
  }
}

// TODO: Try to find a way to send a config object with custom options so it can be
// more handy to modify the way we wanna get the response from fetch
async function ApiQuery<T, G = void>(
  endpoint: string,
  method: HTTPMethods = "GET",
  postBody: G | null = null,
  headers: Headers = {},
  isBlob = false
): Promise<T> {
  const options: Options = {
    method,
    url: endpoint,
    ...(!!postBody && {
      body: postBody instanceof FormData ? postBody : JSON.stringify(postBody),
    }),
  };
  const token = SecurityService.getTokenForRequest();
  const enableTraceId = FEATURE_FLAGS.enableTraceId;

  // Allow overwrite of properties even if different case is used
  const headersLC = {};
  Object.entries(headers).forEach(
    ([prop, value]) => (headersLC[prop.toLowerCase()] = value)
  );

  options.headers = {
    "content-type": "application/json;charset=UTF-8",
    ...(enableTraceId && { "X-AVAIL-TRACEID": generateTraceId() }),
    accept: "application/json, text/plain, */*",
    authorization: token,
    ...headersLC,
  };

  if (options.body instanceof FormData) {
    delete options.headers["content-type"];
  }

  // TODO: add more reinforced ERROR HANDLING
  // try {
  let response = await fetch(endpoint, options);

  if (response.status === 401) {
    // Refresh tokens
    await unAuthorizedHandler();

    // retry
    const newToken = SecurityService.getTokenForRequest();
    options.headers.authorization = newToken;
    response = await fetch(endpoint, options);
  }

  let data = null;
  try {
    if (isBlob) {
      const resBlob = await response.blob();
      const objectURL = await resBlob.text();
      data = `data:image/jpeg;base64,${objectURL}`;
    } else {
      data = await response.json();
    }
  } catch {
    // if the data is not json then its fine leaving it as null
    // previously we didn't do a catch so it would just error out so this change shouldn't break anything that wasn't broken already
    data = null;
  }

  if (response.ok) {
    // if we need a component to handle response codes in the future we should also return the response
    // but that will a bit of a rewrite on all places that use this API client (not that big of a deal)
    // for now most things will just want to handle the body json data so we are okay
    return data;
  } else if (response.status >= 500) {
    // handle 500 errors with a special message
    throw new FetchError(response, API_MESSAGE_5XX);
  } else {
    // must throw any error to let us use react-query to handle errors in components
    // non react-query code that is use a client function must catch errors themselves then use the error object

    // this case is usually for 4xx errors that return errors in the data,
    // data in this case should be a json object so we should have the data.message value
    if (data) {
      throw new FetchError(response, data.message);
    } else {
      // if for some reason we had a 4xx error but with no reponse then show the unknown error message
      throw new FetchError(response, API_MESSAGE_UNKNOWN_ERROR);
    }
  }
}

export default ApiQuery;
