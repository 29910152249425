/** Indicator Component
 * A border box overlay.
 * Use this as a child of an *explicitly positioned parent*.
 * Has an optional label tag to denote state/reason to draw focus.
 */
import React from "react";

import clsx from "clsx";

import { IndicatorTag } from "src/components/Indicator/IndicatorTag/IndicatorTag";

import styles from "./styles.scss";

export interface IndicatorProps {
  color: "orange" | "red" | "green" | "blue";
  label?: string;
  "data-test-id"?: string;
  show: boolean;
  className?: string;
}

export const Indicator = ({
  color,
  label,
  show = false,
  "data-test-id": dataTestId = "indicator",
  className,
}: IndicatorProps) => {
  return (
    <div
      className={clsx(styles.root, className, styles[`color--${color}`], {
        [styles.shown]: show,
      })}
      data-test-id={dataTestId}
    >
      {label && (
        <IndicatorTag
          color={color}
          label={label} // label given a default value "", required prop
          data-test-id={`${dataTestId}-tag`}
        />
      )}
    </div>
  );
};
