import { RemoteVideoTrack } from "twilio-video";

import { useEffect, useRef, useState } from "react";

import clsx from "clsx";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectIsUserHost } from "src/domains/Beacon/store/meeting/selectors";
import {
  selectStreamState,
  selectVideoTrackState,
} from "src/domains/Beacon/store/stream/selectors";
import { selectShowPipVideo } from "src/domains/Beacon/store/ui/selectors";

import styles from "./styles.scss";

/**
 * Will take care of displaying the video, exactly what MediaTrack does but
 * only for video, there must be an Audio component for audio from MediaTrack
 */
interface IProps {
  id?: string;
  width?: string;
  height?: string;
  className?: string;
  videoClassName?: string;
  videoStream?: MediaStream; // if passed, the stream will come from the local user's camera device
  "data-test-id"?: string;
}

export const MediaIntegration = ({
  id,
  className,
  videoClassName,
  videoStream,
  "data-test-id": dataTestId,
}: IProps) => {
  // this state helps to re-render the video when changes
  const [reloadVideo, setReloadVideo] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>();
  const { mpRemoteTracks } = useAppSelector(selectStreamState);
  const twilioVideoTrack = useAppSelector(selectVideoTrackState);
  const isHostUser = useAppSelector(selectIsUserHost);
  // Will force the Host's video to reload if Host mutes/un-mutes its video
  const isVideoShown = useAppSelector(selectShowPipVideo);

  // Load state means that the component should re-render in order to show
  // the new video track, otherwise, we would have to manipulate the UI in order
  // to load the new video
  useEffect(() => {
    if (reloadVideo) {
      videoRef.current.load();
      setReloadVideo(false);
    }
  }, [reloadVideo]);

  /**
   * When the twilioVideoTrack name changes, means that Twilio has publish the video
   * already, otherwise, the twilioVideoTrack is still the previous one
   */
  useEffect(() => {
    if (twilioVideoTrack?.isEnabled) {
      videoRef.current.srcObject = twilioVideoTrack?.attach().srcObject;
    } else {
      videoRef.current.srcObject = null;
    }
    setReloadVideo(true);
  }, [twilioVideoTrack?.name, twilioVideoTrack?.isEnabled, isVideoShown]);

  // Detects when the host changes tracks and user is a Panelist
  useEffect(() => {
    if (!isHostUser) {
      if (mpRemoteTracks.host.video?.isEnabled) {
        videoRef.current.srcObject = mpRemoteTracks.host?.video?.attach().srcObject;
      } else {
        videoRef.current.srcObject = null;
      }
      setReloadVideo(true);
    }
  }, [mpRemoteTracks.host.video, mpRemoteTracks.host.video?.isEnabled]);

  // Detects when a local video stream is passed to load it into the video tag
  useEffect(() => {
    if (videoStream) {
      videoRef.current.srcObject = videoStream;
      setReloadVideo(true);
    }
  }, [videoStream]);

  /**
   * Detects when local video stream tracks changes. This happens during the
   * PreCall or VideoSettings when user toggles the local camera on/off
   */
  useEffect(() => {
    const tracks = videoStream?.getVideoTracks();
    const track = tracks?.[0];
    if (track && !track?.enabled) {
      videoRef.current.srcObject = videoStream;
      setReloadVideo(true);
    }
  }, [videoStream?.getVideoTracks()]);
  const remoteVideoTrack: RemoteVideoTrack = mpRemoteTracks?.host?.video;

  const remoteVideoTrackName = remoteVideoTrack?.name;
  const isORTTelligenceTrackParticipant =
    !isHostUser &&
    remoteVideoTrackName &&
    remoteVideoTrackName.indexOf("avail-integration-") !== -1;

  if (!isORTTelligenceTrackParticipant) {
    return <></>;
  }
  const consoleVideoContainer = document.getElementById(
    "console-video-container"
  );
  return (
    <div
      id={"avail-media-integration-container"}
      key={id}
      className={clsx(styles.mediaIntegration, className)}
      data-test-id={dataTestId}
    >
      <div id={"avail-media-integration-video-delimiter"}>
        <video
          id={"avail-media-integration-video"}
          ref={videoRef}
          autoPlay
          className={clsx(styles.video, videoClassName)}
          style={{ width: consoleVideoContainer?.offsetWidth }}
          data-test-id="media-integration-video-tag"
        />
      </div>
    </div>
  );
};
