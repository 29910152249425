import { createAsyncThunk } from "@reduxjs/toolkit";

import { TELESTRATION_EASEL_ID } from "src/domains/Beacon/components/Easel/constants";
import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { logger, LoggerLevels } from "src/logging/logger";

// "Local  : just on user's machine"
export const clearCanvasThunk = createAsyncThunk<
  void,
  // First argument to the payload creator
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("telestration/clearCanvasThunk", (_arg, { getState }) => {
  try {
    logger().info("Clearing telestration canvas UI...");
    const { telestration } = getState();

    const { videoDimensions } = telestration;
    const canvas = document.getElementById(
      TELESTRATION_EASEL_ID
    ) as HTMLCanvasElement;
    // clears Easel UI
    if (!canvas) {
      return;
    }

    logger().info(
      `Retrieving video dimensions from state ${JSON.stringify(
        videoDimensions
      )}`
    );

    const { width, height } = videoDimensions;

    const context = canvas.getContext("2d");
    context!.clearRect(0, 0, width, height);
    logger().info(`Telestration canvas UI cleared successfully!`);
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      { feature: "clearCanvasThunk" },
      "Error clearing telestration canvas",
      error?.message
    );
    throw new Error("Clearing telestration UI canvas failed");
  }
});
