// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_iframe_LXZ8Qj{height:100%;position:absolute;width:50%;z-index:99}.styles_iframe_LXZ8Qj.styles_leftTop_porQ4K{left:0}.styles_iframe_LXZ8Qj.styles_rightTop_3bsWkj{right:0}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/Integration/styles.scss"],"names":[],"mappings":"AACA,sBACE,WAAA,CACA,iBAAA,CACA,SAAA,CACA,UAAA,CAEA,4CACE,MAAA,CAGF,6CACE,OAAA","sourcesContent":["/* stylelint-disable selector-class-pattern */\n.iframe {\n  height: 100%;\n  position: absolute;\n  width: 50%;\n  z-index: 99;\n\n  &.leftTop {\n    left: 0;\n  }\n\n  &.rightTop {\n    right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframe": "styles_iframe_LXZ8Qj",
	"leftTop": "styles_leftTop_porQ4K",
	"rightTop": "styles_rightTop_3bsWkj"
};
export default ___CSS_LOADER_EXPORT___;
