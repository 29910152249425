import { Room, TwilioError } from "twilio-video";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { leaveCallThunk } from "src/domains/Beacon/store/twilio/thunks/leaveCall";
import { logger, LoggerLevels } from "src/logging/logger";

// LocalParticipant disconnected from the room
export const useRoomLocalParticipantDisconnected = () => {
  const { room } = useAppSelector(selectTwilioState);
  const dispatch = useDispatch();

  useEffect(() => {
    room.on("disconnected", (currentRoom: Room, error: TwilioError) => {
      logger().logWithFields(
        LoggerLevels.info,
        {
          fileInfo: `useRoomTrackSubscribed`,
          feature: `TwilioSubscriptions`,
        },
        `Local user has left the room '${room?.name}'.`
      );
      if (error) {
        logger().logWithFields(
          LoggerLevels.error,
          {
            fileInfo: `useRoomTrackSubscribed`,
            feature: `TwilioSubscriptions`,
          },
          `${error}: $error.message`
        );
        // this error code refers to a participant joining the same call from two different browser tabs
        // TODO define a enum or something for the errors
        if (error.code === 53205) {
          // we want to set this error boolean to true, then put the user in the leave call state so we can show the error
          logger().logWithFields(
            LoggerLevels.error,
            {
              fileInfo: `useRoomLocalParticipantDisconnected`,
              feature: `TwilioSubscriptions`,
            },
            `Duplicate identity found in Twilio Room (TwilioError:53205), Leaving Call`
          );

          dispatch(twilioActions.setDuplicateParticipantDisconnected(true));
          // Leave call, this error causes local user to not see or hear other Participants
          dispatch(leaveCallThunk());
        } else {
          dispatch(twilioActions.setLocalNetworkDisconnected(true));
        }
        // Will do the same as for disconnectFromRoomFailed but with an error message instead
        dispatch(
          twilioActions.setError({ message: "Disconnected from room failed." })
        );

        // TODO: the connectCallRequest does nothing, seems like it isn't implemented
        // so is omitted here...
        // emit(connectCallRequest());
      }
      // When disconnected successfully from the room, there's nothing more to update
      // then setting an error as null cause' all worked fine
      dispatch(twilioActions.setError(null));
    });
  }, []);
};
