import { Fade, Grid } from "@mui/material";

import { Font } from "src/components/Font";
import { Logo } from "src/components/Logo";

import styles from "./styles.scss";

interface IProps {
  errorMessage?: string | null;
  show: boolean;
}

// This component shows up after the user offically leaves the call
export const LeaveCallScreen = ({ show, errorMessage }: IProps) => {
  return (
    <Fade
      // we'll see this leave call screen when show is set to true
      in={show}
    >
      <div data-test-id="leave-call-screen" className={styles.background}>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <Logo type="horizontal-light" />
            <Grid container direction="column" alignItems="center">
              {/* For now just a basic message until we get to fleshing out this screen later */}

              {errorMessage ? (
                <>
                  <Font
                    variant="h1"
                    color="light"
                    className={styles.textWrapper}
                  >
                    {errorMessage}
                  </Font>
                  <Font
                    variant="h1"
                    color="disabled"
                    className={styles.textWrapper}
                  >
                    Please close this tab
                  </Font>
                </>
              ) : (
                <>
                  <Font
                    variant="h1"
                    color="light"
                    className={styles.textWrapper}
                  >
                    This event has ended
                  </Font>

                  <Font
                    variant="h1"
                    color="disabled"
                    className={styles.textWrapper}
                  >
                    You can now close this window
                  </Font>
                </>
              )}

              {/* Hiding the "back to avail portal" button for now, users have to close this tab to leave */}
              {/* <Button
                data-test-id="leave-call-screen-return-to-portal-button"
                onClick={() => {
                  // this will take the user back to the portal home page
                  window.location.href = "/";
                }}
                label="Back to Avail Portal"
                className={styles.buttonWrapper}
              /> */}
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {/* this bottom section will be for messages that belong at the bottom of the screen
                  once we have the feedback UI it will go here
              */}
            </Grid>
          </div>
        </div>
      </div>
    </Fade>
  );
};
