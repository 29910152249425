import { useCallback, useEffect } from "react";

// Eventually expand this to support other keys. Maybe there is something we can use that doesn't require keycodes
export const useOnEscapeKeyDown = (onCloseFunction: () => void) => {
  const onEscapeKeyDownFn = useCallback((event) => {
    if (event.keyCode === 27) {
      onCloseFunction();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", onEscapeKeyDownFn, false);

    return () => {
      document.removeEventListener("keydown", onEscapeKeyDownFn, false);
    };
  }, []);
};
