import resources from '../../../../Common/Constants/resources';
import {notificationService} from '../../../../Common/Services';
import ApiHelper from '../../../../Common/Services/ApiHelper';

export const uploadLogo = async (brandName, logoFile) => {
	// eslint-disable-next-line no-undef
	const formData = new FormData();
	formData.append('file', logoFile);
	formData.append('brandName', brandName);

	try {
		const fileResponse = await ApiHelper.post(
			'coreregistry',
			resources.brandLogo,
			formData,
		);

		return fileResponse.content.uploadedLocation;
	} catch {
		notificationService.error('Failed to upload image.');
		throw new Error('Failed to upload image.'); // Bubble up
	}
};
