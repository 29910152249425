/**
 * Listens for HangupEvents retrieved by telephonyService.
 *
 * Subscriptions addressing:
 * - intentional local user hang up
 * - HangupEvents from Console on the user channel
 */
import { AVKCallHangupEvent } from "availkit-js/dist/Models/Events/AVKCallHangupEvent";
import { AVKLastParticipantEvent } from "availkit-js/dist/Models/Events/AVKLastParticipantEvent";
import { NSessionJoinEvent } from "availkit-js/dist/Models/Events/NSessionJoinEvent";
import { NSession } from "availkit-js/dist/Models/NSession";
import { NTelephonyServiceListener } from "availkit-js/dist/Services/Listeners/NTelephonyServiceListener";
import { NTelephonyService } from "availkit-js/dist/Services/NTelephonyService";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { selectFreezeFrameState } from "src/domains/Beacon/store/stream/selectors";
import {
  streamActions,
  initialState,
} from "src/domains/Beacon/store/stream/streamSlice";
import { handleUnfreezeFrameThunk } from "src/domains/Beacon/store/stream/thunks";
import { handleSidePanelCloseWhenLayoutsOrPresetsOpen } from "src/domains/Beacon/store/stream/thunks/handleSidePanelCloseWhenNoConsole";
import { stopShareScreenTrackThunk } from "src/domains/Beacon/store/stream/thunks/stopShareScreenTrack";
import { handleTurnOffDrawModeThunk } from "src/domains/Beacon/store/telestration/thunks/handleTurnOffDrawModeThunk";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import { leaveCallThunk } from "src/domains/Beacon/store/twilio/thunks/leaveCall";
import { uiActions } from "src/domains/Beacon/store/ui";
import {
  selectSidePanelContent,
  selectSidePanelParentContent,
} from "src/domains/Beacon/store/ui/selectors";
import { selectCallStep } from "src/domains/Beacon/store/ui/selectors";
import { initiateLastParticipantTimerThunk } from "src/domains/Beacon/store/ui/thunks/lastParticipantThunks";
import { UISidePanelContentOptions } from "src/domains/Beacon/store/ui/types";
import { CallSteps } from "src/domains/Beacon/store/ui/types";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

export const useTelephonyServiceSubscriptions = () => {
  const { callSid, mode, joinId } = useAppSelector(selectMeetingState);
  const freezeFrameState = useAppSelector(selectFreezeFrameState);
  const callStep = useAppSelector(selectCallStep);
  const sidePanelContent = useAppSelector(selectSidePanelContent);
  const parentContent = useAppSelector(selectSidePanelParentContent);
  const availKit = AvailKitService.instance;
  const dispatch = useDispatch();
  const {
    externalInputsImageResizing,
    oRtelligence: enableORTelligence,
    tungstenExternalLa,
  } = useFeatureFlags();

  const onReceiveHangupV2 = (
    service: NTelephonyService,
    event: AVKCallHangupEvent
  ) => {
    try {
      logger().info("Received a Hangup event v2: ", JSON.stringify(event));

      if (event.session_id !== callSid) {
        logger().info("Ignoring the hangup event: ", JSON.stringify(event));
        // Must compare both callSessionIDs for registration
        logger().info(
          `Ignoring the hangup event, sessionID received: ${event.session_id}`,
          `Current sessionID: ${callSid}`
        );
        return;
      }

      if (callStep === CallSteps.IN_CALL) {
        logger().info(
          "onReceiveHangupV2: isEndCallInitiatedByRemoteClient",
          JSON.stringify(event)
        );

        // Must clear FF, Telestration, TPI and SidePanel when Console leaves either in MP or P2P calls
        if (event.type === "CONSOLE_HANGUP") {
          dispatch(twilioActions.setConsoleHasJoinedRoom(false));
          logger().info(
            "onReceiveHangupV2: Clearing Console's video & audio tracks"
          );
          // Must clear video & audio tracks in order to restore them once Console rejoins
          // otherwise, the video won't load properly
          dispatch(streamActions.setConsoleVideoTrack(null));
          dispatch(streamActions.setConsoleAudioTrack(null));

          dispatch(stopShareScreenTrackThunk({ enableORTelligence }));

          dispatch(handleSidePanelCloseWhenLayoutsOrPresetsOpen());

          // if the frame is frozen, then go through the regular unfreeze flow
          // equivalent of clicking the Unfreeze button in the Top Bar
          if (freezeFrameState.active) {
            // Participants and host can call this thunk cause' if Console leaves
            // the FreezeFrameClear event for Participants isn't received
            dispatch(handleUnfreezeFrameThunk());
          }

          // Turn off Draw Mode, clear telestration from state, clear UI
          // Valid regardless of DrawMode, persisted telestrations can be drawn without Draw Mode on e.g. Panelist
          dispatch(handleTurnOffDrawModeThunk());

          // reset console features to initialState
          dispatch(
            streamActions.setBluetoothEnabled(initialState.bluetoothEnabled)
          );
          dispatch(
            streamActions.setNoiseCancellationEnabled(
              initialState.noiseCancellationEnabled
            )
          );
          dispatch(
            streamActions.setNoiseCancellationAvailable(
              initialState.noiseCancellationAvailable
            )
          );
          dispatch(
            streamActions.setConsoleHasExternalInputResizing(
              initialState.consoleHasExternalInputResizing
            )
          );

          // Close SidePanel when Console leaves if Layouts or Console Settings open
          if (
            sidePanelContent ===
            (UISidePanelContentOptions.CONSOLE_SETTINGS ||
              UISidePanelContentOptions.LAYOUTS ||
              UISidePanelContentOptions.LAYOUTS_PRESETS)
          ) {
            dispatch(uiActions.setSidePanelOpen(false));
          }

          // TODO other clean up can go here like turning off SideBar mode when console leaves
        }

        /* This condition is required to address an issue of sudden hangup */
        // Must leave call when Console leaves call in P2P call
        if (mode !== CallModes.MP) {
          logger().info(
            "onReceiveHangupV2: sudden hangup - ",
            JSON.stringify(event)
          );
          dispatch(leaveCallThunk());
        }
      }
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useTelephonyServiceSubscriptions",
        },
        "Error while receiving Hangup event",
        error
      );
    }
  };

  const onEndTelephonySession = (_: NTelephonyService, __: NSession) => {
    try {
      logger().info("onEndTelephonySession event received");
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useTelephonyServiceSubscriptions",
        },
        "Error while receiving EndTelephonySession event"
      );
    }
  };

  const onReceiveCameraChange = () =>
    // service: NTelephonyService,
    // event: NCameraChangeEvent
    {};

  const onReceiveVideoLayoutChange = () =>
    // service: NTelephonyService,
    // event: AVKVideoLayoutEvent
    {};

  const onReceiveLastParticipantEvent = (
    service: NTelephonyService,
    event: AVKLastParticipantEvent
  ) => {
    try {
      // only want to run this function if the tungstenExternalLa flag is set to True
      if (!tungstenExternalLa) {
        return;
      }
      logger().info(
        "Received a last participant (end call) event v2 - onReceiveLastParticipantEvent, event:",
        event
      );
      dispatch(uiActions.setLastParticipantModalOpen(true));
      dispatch(
        initiateLastParticipantTimerThunk({ duration: event.popup_duration })
      );
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "onReceiveLastParticipantEvent",
        },
        "Error while receiving onReceiveLastParticipantEvent event"
      );
    }
  };

  const onReceivePNTimeoutConsoleEvent = (service: any, event: any) => {
    logger().info(
      `Received PubNub Timeout event from Console ${JSON.stringify(event)}`
    );
  };

  const onReceivePNTimeoutUserEvent = (service: any, event: any) => {
    logger().info(
      `Received PubNub Timeout event from User ${JSON.stringify(event)}`
    );
  };

  const onReceivePNTimeoutWebcallEvent = (service: any, event: any) => {
    logger().info(
      `Received PubNub Timeout event from Webcall ${JSON.stringify(event)}`
    );
  };
  const didJoinSession = (
    service: NTelephonyService,
    event: NSessionJoinEvent
  ) => {
    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: `useTelephonyServiceSubscriptions`,
        feature: `subscriptions`,
      },
      `${event.availTraceId} (${
        event.sender === joinId ? "You" : "Remote Client"
      }) did join PubNub session`
    );
  };

  useEffect(() => {
    const hangUpServiceListener: Partial<NTelephonyServiceListener> = {
      onReceiveHangupV2,
      onEndTelephonySession,
      // these below listeners are not hangupEvents, but resolve errors for lack of the listeners
      // TODO: move these to on subscriptions file for layout/camerachange events
      onReceiveCameraChange,
      onReceiveVideoLayoutChange,
      onReceiveLastParticipantEvent,
      didJoinSession,

      // PubNub Timeout events
      onReceivePNTimeoutConsoleEvent,
      onReceivePNTimeoutUserEvent,
      onReceivePNTimeoutWebcallEvent,
    };

    availKit.telephonyService.addEventListener(hangUpServiceListener);

    // When this is unmounted, remove the listeners
    return () =>
      availKit?.telephonyService.removeEventListener(hangUpServiceListener);
  }, [
    availKit,
    callSid,
    mode,
    callStep,
    freezeFrameState,
    externalInputsImageResizing,
    enableORTelligence,
    sidePanelContent,
    parentContent,
  ]);
};
