import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { Button } from '../../../../Common/Components';

import { signUpAction } from '../../AuthenticationActions';
import withAuthentication from '../../Components/withAuthentication';

import { LoginPage } from "/src/domains/Login";
class CheckEmail extends React.PureComponent {

	redirect = () => this.props.history.push('/login');

	render() {
		const { handleSubmit } = this.props;
		return (
			<LoginPage className="login">
        <div className="login__subheading">Please check your email for your temporary login instructions</div>
        <form className="login__form">

          <div className="justify-content--flex-end">
            <Button
              isFull
              isGreen
              className="login__button singular"
              onClick={this.redirect}
            >
              Okay Got It
            </Button>
          </div>
        </form>
			</LoginPage>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	checkemail: userData => dispatch(signUpAction(userData)),
});

export default reduxForm({ form: 'loginForm' })(withAuthentication(connect(undefined, mapDispatchToProps)(CheckEmail)));
