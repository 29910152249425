// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_icon_yLJcYd{background-color:rgba(3,20,36,.75) !important;color:#fff !important;height:34px;opacity:.5;width:34px}.styles_icon_yLJcYd:hover{background-color:rgba(3,20,36,.75) !important;opacity:1}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/InputControls/Kebab/styles.scss"],"names":[],"mappings":"AAGA,oBACE,6CAAA,CACA,qBAAA,CACA,WAAA,CACA,UAAA,CACA,UAAA,CAEA,0BACE,6CAAA,CACA,SAAA","sourcesContent":["/* stylelint-disable declaration-no-important */\n@import \"/src/colors.scss\";\n\n.icon {\n  background-color: $opacity-midnight-75 !important;\n  color: $white !important;\n  height: 34px;\n  opacity: 0.5;\n  width: 34px;\n\n  &:hover {\n    background-color: $opacity-midnight-75 !important;\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "styles_icon_yLJcYd"
};
export default ___CSS_LOADER_EXPORT___;
