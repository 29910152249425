import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Grid,
  TextField,
  Typography,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./styles.scss";

interface Props {
  name: string;
  label: string;
  // TODO properly type the below "any" values, need to handle generic types
  // because the shape of value/options/getOptionLabel/onChange can change depending on the objects that are used (see dropdown story for example)
  value: any;
  options: any;
  onChange: any;
  getOptionLabel: any;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  "data-test-id"?: string;
  defaultValue?: any;
  required?: boolean;
  placeholderLabel?: string;
  popupIconSize?: "inherit" | "large" | "medium" | "small";
  variant?: "filled" | "outlined" | "standard";
  disabled?: boolean;
  // show the open close popup icons
  forcePopupIcon?: boolean;
  // hide the clear X button
  disableClearable?: boolean;

  // loading prop shows a spinner on the right hand side of the input, useful for async autocomplete
  loading?: boolean;

  // if you need to control the open/close state of the autocomplete dropdown then use these
  open?: boolean;
  onOpen?: () => void;
  onClose?: (event: any, reason: any) => void;

  // if you need to control the input value that the user types use these two props
  inputValue?: string;
  // newValue, event, reason args necessary because we pass this function to MUI's Autocomplete
  // newValue is just the new value of the input box after the user types
  // event is just regular react event (probably dont need to use)
  // reason refers to why MUI ran this function (might be user types new character or the user hits the 'clear' button)
  onInputChange?: (newValue: string, event?: any, reason?: string) => void;
  onInputClick?: () => void;
  onInputBlur?: () => void;

  error?: string;

  // this is the text that appears when there are no options in the autocomplete dropdown
  noOptionsText?: string;
  freeSolo?: boolean;
  // function that filters the autocomplete dropdown, usually the autocomplete list is filtered by what the user types
  // sometimes we need it to not filter at all if we want to see all values all the time
  // TODO need to type this with a type passed in from outside instead of just any, something like:
  // (val: T) => T
  filterOptions?: any;
}

// data-test-id={`${dataTestId}-label`}
export const AutocompleteTextField = ({
  variant = "outlined",
  popupIconSize = "large",
  required = false,
  value,
  defaultValue,
  options,
  getOptionLabel,
  name,
  label,
  onChange,
  placeholderLabel,
  disabled,
  loading = false,
  open,
  onOpen,
  onClose,
  inputValue,
  onInputChange,
  onInputClick,
  onInputBlur,
  isOptionEqualToValue,
  filterOptions,
  freeSolo = false,
  forcePopupIcon = undefined,
  disableClearable = false,
  noOptionsText = "No options found",
  error = "",
  "data-test-id": dataTestId,
}: Props) => {
  return (
    <Grid container direction="column" data-test-id={dataTestId}>
      <Grid item>
        <Typography variant="body1" component={"label"}>
          {label}
          {required ? <span className={styles.required}> *</span> : null}
        </Typography>
      </Grid>
      <Grid item>
        <MuiAutocomplete
          // we have to set onChange to pass in the new value into the onChange coming in via props
          // otherwise the value never changes on the outside, Autocomplete has to be a controlled component (manage value outside of it)
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          value={value}
          autoHighlight
          fullWidth
          defaultValue={defaultValue}
          classes={{
            option: styles.option,
          }}
          options={options}
          noOptionsText={noOptionsText}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          freeSolo={freeSolo}
          popupIcon={
            <ExpandMoreIcon fontSize={popupIconSize} component="svg" />
          }
          disableClearable={disableClearable}
          forcePopupIcon={forcePopupIcon}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              placeholder={placeholderLabel}
              required={required}
              variant={variant}
              onClick={onInputClick}
              onBlur={onInputBlur}
              error={error !== ""}
              disabled={disabled}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress
                        data-test-id="autocomplete-loading-icon"
                        color="inherit"
                        size={20}
                      />
                    ) : null}

                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          disabled={disabled}
          loading={loading}
          filterOptions={filterOptions}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          inputValue={inputValue}
          // we only want to pass in onInputChange if it exists
          // and we only want to send the newValue to that function
          onInputChange={
            onInputChange
              ? (event, newValue, reason) => {
                  onInputChange(newValue, event, reason);
                }
              : undefined
          }
        />
      </Grid>
    </Grid>
  );
};
