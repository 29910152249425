import classNames from 'classnames';
import moment from 'moment';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import {
	numberService,
} from '../../../../../../../../../../Common/Services';
import {
	Icon,
} from '../../../../../../../../../../Common/Components';


class InvoiceHistoryTableRow extends PureComponent {
	render() {
		const { name, total, date, status, link } = this.props.rowData;
		const moneyFormat = n => numberService.formatCurrency(numberService.toDollars(n));

		return (
			<Table.Row >
				<Table.Cell title={name}>
					{name}
				</Table.Cell>
				<Table.Cell title={total}>$ {moneyFormat(total)}</Table.Cell>
				<Table.Cell title={date}>{moment.tz(date, this.props.timezone).format('MMMM d, Y')}</Table.Cell>
				<Table.Cell title={[status === 'paid' ? 'Payed' : 'Unpayed'].join(' ')}>
					<span
						className={classNames({
							'invoice-row__status': status === 'paid',
							'invoice-row__status--unpaid': status !== 'paid',
						})}
					>
						{status === 'paid' ? 'Payed' : 'Unpayed'}
					</span>
				</Table.Cell>
				<Table.Cell textAlign="right" className="table__action-table-cell">
					<div className="table__link">
						<a href={link}><Icon className="download-icon" /><span className="download-text">Download</span></a>
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}

InvoiceHistoryTableRow.defaultProps = {
	rowData: null,
};

InvoiceHistoryTableRow.propTypes = {
	rowData: PropTypes.object,
};

export default withRouter(connect(undefined, undefined)(InvoiceHistoryTableRow));
