import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { LoggerLevels, logger } from "src/logging/logger";

export const handleLoadDefaultDevicesThunk = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  try {
    const {
      meeting: { isUserHost },
      stream: { localMedia },
    } = getState();

    if (isUserHost) {
      const defaultCamera = localMedia?.mediaDevices.videoInputs[0];

      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Media Devices",
          fileInfo: "handleLoadDefaultDevicesThunk.ts",
        },
        `Default pre-selected video device: ${JSON.stringify(
          defaultCamera ?? "null"
        )}`
      );

      dispatch(streamActions.setCameraDevice(defaultCamera));
    }
    const defaultMicrophone = localMedia?.mediaDevices.audioInputs[0];
    const defaultSpeaker = localMedia?.mediaDevices.audioOutputs[0];

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Media Devices",
        fileInfo: "handleLoadDefaultDevicesThunk.ts",
      },
      `Default pre-selected audio devices: ${JSON.stringify(
        {
          defaultMicrophone,
          defaultSpeaker,
        } ?? "null"
      )}`
    );

    dispatch(streamActions.setMicrophoneDevice(defaultMicrophone));
    dispatch(streamActions.setSpeakerDevice(defaultSpeaker));
  } catch (error: any) {
    logger().error(
      "Error while selecting default audio devices",
      error?.message
    );
  }
};
