import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { withTranslation } from 'react-i18next';

import { Button, Fields, validators } from '../../../../../../../../../Common/Components';
import ModalComponent from '../../../../../../../../../Common/Components/Modal/Modal';
import { notificationService, arrayService } from '../../../../../../../../../Common/Services';
import { Table } from '../../../../../../../Common';
import { updateBrandAction } from '../../../../../../Brands/BrandsActions';
import Can from '../../../../../../../../../Common/Acl/AccessControl';
import { getcurrentUserTypes } from '../../../../../../../HomeSelectors';
import DomainTableRow from './DomainTableRow';


class Domains extends PureComponent {
	state = {
		isCreateNewModalOpened: false,
		isDeleteModalOpened: false,
		selectedDomain: null,
		currentDomains: this.props.data.domains,
		defaultDomains: this.props.data.domains,
	}

	onCreateDomainHandler = ({ domainName }) => {
		const { domains } = this.props.data;
		if (domains.indexOf(domainName) <= -1) {
			this.toggleCreateDomainModal();

			const brand = this.props.data;
			const updatedBrand = {...brand, domains: [...brand.domains, domainName]};
			this.props.updateBrand(updatedBrand);
      this.setState({ currentDomains: updatedBrand.domains });
		} else {
			this.toggleCreateDomainModal();
			notificationService.error("Domain already added to this vendor")
		}
	};

	onDeleteDomainHandler = () => {
		this.toggleDeleteModal();
		const { selectedDomain } = this.state;
		const { data: brand } = this.props;

		// Can't delete ALL domains
		if (brand.domains.length === 1) {
			notificationService.warning('There must be at least one existing domain.');
			return;
		}

		if (selectedDomain) {
			const filteredDomains = brand.domains.filter(d => d !== selectedDomain);
			const updatedBrand = { ...brand, domains: filteredDomains };
			this.props.updateBrand(updatedBrand);
      this.setState({ currentDomains: filteredDomains });
		}
	}

	onSortTableHandler = (value) => {
		this.params = {
			...this.params,
			sort: value };
		const sortedData = arrayService.sortArray(this.state.defaultDomains, this.params);
		this.setState({ currentDomains: [...sortedData] });
	};

	onTableFilterHandler = (value) => {
		this.params = {
			...this.params,
			name: value,
		};
		const sortedData = arrayService.sortArray(this.state.defaultDomains, this.params);
		this.setState({ currentDomains: sortedData === undefined ? [...this.state.defaultDomains] : [...sortedData] });
	};

	toggleCreateDomainModal = () => {
		this.props.resetReduxForm();
		this.setState({ isCreateNewModalOpened: !this.state.isCreateNewModalOpened });
	}

	toggleDeleteModal = (selectedDomain) => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
			selectedDomain,
		});
	};

	renderCreateNewDomainModal = () => {
		const { t } = this.props;

		return (
			<ModalComponent
				open={this.state.isCreateNewModalOpened}
				title="Add new domain"
				onCancel={this.toggleCreateDomainModal}
				hasActionButtons={false}
			>
				<form
					onSubmit={this.props.handleSubmit(this.onCreateDomainHandler)}
					className="justify-content--space-evenly display--flex align--center flex-direction--column"
				>
					<div>
						<Fields.InputField
							labelText={t('common.form.name.label')}
							placeholder={t('common.form.name.placeholder')}
							name="domainName"
							validate={[
								validators.required, 
								validators.domainValidator,
								validators.domainExtensionValidator
							]}
						/>
					</div>
					<div className="button__wrapper width--full">
						<Button isGhost buttonSmall noBorder onClick={this.toggleCreateDomainModal}>Cancel</Button>
						<Button isFull buttonSmall type="submit">Create</Button>
					</div>
				</form>
			</ModalComponent>
		);
	}

	renderDeleteDomainModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onDeleteDomainHandler}
			onCancel={this.toggleDeleteModal}
			confirmText="Delete"
		>
			<span>
				You are about to delete console <span className="text--bold">{this.state.selectedDomain}</span>.
				Would you like to proceed with this action?
			</span>
		</ModalComponent>
	);

	render() {
		const { profileType } = this.props;
		return (
			<div className="profile-general-info">
				{profileType === 'Company' && (
					<React.Fragment>
						<Can userTypes={this.props.currentUserTypes} action="read" context="addNewDomain">
							<Table
								row={DomainTableRow}
								columns={[{
									title: 'Domain',
									sortValue: 'name',
								}]}
								tableData={this.state.currentDomains}
								openModal={this.toggleDeleteModal}
								noItemsClass="height--full justify-content--center align--center"
								onAddNewHandler={this.toggleCreateDomainModal}
								onAddNewTitle="Add New"
								onSearchTermUpdateHandler={this.onTableFilterHandler}
								onSortTableHandler={this.onSortTableHandler}
								search
								sortable
								noEntityComponent={
									<div className="profile__right-content-wrapper">No domains available</div>
								}
							/>
						</Can>
						<Can userTypes={this.props.currentUserTypes} action="read" context="viewDomains">
							<Table
								row={DomainTableRow}
								columns={[{
									title: 'Domain',
									sortValue: 'name',
								}]}
								tableData={this.state.currentDomains}
								openModal={this.toggleDeleteModal}
								noItemsClass="height--full justify-content--center align--center"
								onAddNewTitle="Add New"
								onSearchTermUpdateHandler={this.onTableFilterHandler}
								onSortTableHandler={this.onSortTableHandler}
								search
								sortable
								tableActions={false}
								noEntityComponent={
									<div className="profile__right-content-wrapper">No domains available</div>
								}
							/>
						</Can>
						{this.renderDeleteDomainModal()}
						{this.renderCreateNewDomainModal()}
					</React.Fragment>
				)}
			</div>
		);
	}
}

Domains.defaultProps = {
	states: [],
};

const mapDispatchToProps = dispatch => ({
	updateBrand: brandData => dispatch(updateBrandAction(brandData)),
	resetReduxForm: () => dispatch(reset('updateBrandDomainForm')),
});

const mapStateToProps = state => ({
	currentUserTypes: getcurrentUserTypes(state),
});

const DomainsWithTranslation = withTranslation()(Domains);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'updateBrandDomainForm',
	enableReinitialize: true,
})(DomainsWithTranslation));
