import { history } from "src/reducer";
import SecurityService from "src/services/SecurityService";

export async function unAuthorizedHandler() {
  // Attempt to refresh token
  const refreshToken = SecurityService.getRefreshToken();
  // const authURL = `${process.env.REACT_APP_API_URL}/auth/token/refresh?refresh_token=${refreshToken}`;
  const authURL = `${process.env.REACT_APP_API_URL}/auth/token/refresh`;
  const response = await fetch(authURL, {
    method: "POST",
    body: JSON.stringify({ token: refreshToken }),
  });
  const { access_token, refresh_token, message } = await response.json();

  if (response.ok && response.status === 200 && access_token && refresh_token) {
    SecurityService.login(access_token, refresh_token);
  } else {
    SecurityService.logout();
    history.push("/");
    throw Error(message);
  }
}
