import { MutableRefObject, useEffect, useRef } from "react";

/**
 * Will modify a SCSS variable from React in real time. Its use is only for
 * specific cases where you want to add some transition to a component or wanna
 * have a centralized constant in React like the Drawer, in case the Drawer
 * constant width changes, this hook will help to pass the new value to SCSS.
 */
export function useScssVariable<T>(
  property: string,
  value: string,
  customRef?: MutableRefObject<T>
): { ref: MutableRefObject<T> } {
  const ref = useRef<T>();
  useEffect(() => {
    ((customRef ? customRef.current : ref.current) as any)?.style.setProperty(
      property,
      value
    );
  }, [value]);
  return { ref };
}
