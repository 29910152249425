export const SCHEDULER = {
	CREATE_CALENDAR_EVENTS: 'SCHEDULER_CREATE_CALENDAR_EVENTS',
	CREATE_CALL_EVENTS: 'SCHEDULER_CREATE_CALL_EVENTS',
	UPDATE_SELECTED_EVENT: 'SCHEDULER_UPDATE_SELECTED_EVENT',
	UPDATE_SELECTED_CALL_EVENT: 'SCHEDULER_UPDATE_SELECTED_CALL_EVENT',
	GET_USER_SCHEDULE: 'SCHEDULER_GET_USER_SCHEDULE',
	GET_USER_CALL_SCHEDULE: 'SCHEDULER_GET_USER_CALL_SCHEDULE',
	SET_USER_SCHEDULE: 'SCHEDULER_SET_USER_SCHEDULE',
	SET_USER_CALL_SCHEDULE: 'SCHEDULER_SET_USER_CALL_SCHEDULE',
	DELETE_SELECTED_EVENT: 'SCHEDULER_DELETE_SELECTED_EVENT',
	DELETE_SELECTED_CALL_EVENT: 'SCHEDULER_DELETE_SELECTED_CALL_EVENT',
	DELETE_SELECTED_EVENT_SUCCESS: 'SCHEDULER_DELETE_SELECTED_EVENT_SUCCESS',
	DELETE_SELECTED_CALL_EVENT_SUCCESS: 'SCHEDULER_DELETE_SELECTED_CALL_EVENT_SUCCESS',
	DELETE_RECURRING_EVENT_SUCCESS: 'SCHEDULER_DELETE_RECURRING_EVENT_SUCCESS',
	GET_USER_MEETING_TIME: 'CALLTIME_GET_USER_MEETING_TIME',
	SET_USER_MEETING_TIME: 'CALLTIME_SET_USER_MEETING_TIME',
	GET_USER_MISSED_CALLS: 'CALLTIME_GET_USER_MISSED_CALLS',
	SET_USER_MISSED_CALLS: 'CALLTIME_SET_USER_MISSED_CALLS',
};
