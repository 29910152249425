import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { reduxForm } from 'redux-form';
import { Tab } from 'semantic-ui-react';
import { Button } from '../../../../Common/Components';
import { decrementActiveIndexAction, incrementActiveIndexAction } from '../Tabs/TabsActions';

class TabsWithButtons extends PureComponent {
	onSubmitHandler = data => this.isLastTabActive ? this.onSubmitForm(data) : this.onContinueHandler();

	onContinueHandler = () => this.props.incrementActiveIndex();

	onBackHandler = () => this.props.decrementActiveIndex();

	onSubmitForm = data => this.props.onSubmitHandler(data);

	render() {
		this.isLastTabActive = this.props.activeIndex === this.props.tabs.length - 1;
		return (
			<React.Fragment>
				<Tab
					activeIndex={this.props.activeIndex}
					panes={this.props.tabs.map(tab => (
						{
							render: () => (
								<form className="height--full" onSubmit={this.props.handleSubmit(this.onSubmitHandler)}>
									{React.cloneElement(
										tab,
										{ ...this.props },
									)}
									<div className="tabs-with-button__button-wrapper">
										<div className="account-creation__button-wrapper">
											<div className="align--center">
												{this.props.tabs.map((singleTab, index) => (
													<span
														key={singleTab.key}
														className={classNames({
															'tabs-with-button__counter': true,
															'tabs-with-button__counter--active': index === this.props.activeIndex,
														})}
													/>))}
											</div>
										</div>
										{this.props.activeIndex !== 0 && (
											<Button
												className="button--small"
												isGhost
												onClick={this.onBackHandler}
											>
												Back
											</Button>
										)}
										<Button
											className="button--small"
											isFull
											type="submit"
										>
											{this.isLastTabActive ? 'Finish' : 'Next'}
										</Button>
									</div>
								</form>
							),
						}
					))}
				/>
			</React.Fragment>
		);
	}
}

TabsWithButtons.propTypes = {
	decrementActiveIndex: PropTypes.func.isRequired,
	incrementActiveIndex: PropTypes.func.isRequired,
	onSubmitHandler: PropTypes.func.isRequired,
	activeIndex: PropTypes.number.isRequired,
	tabs: PropTypes.arrayOf(PropTypes.node).isRequired,
};

const mapStateToProps = state => ({
	activeIndex: state.tabsReducer.activeIndex,
});

const mapDispatchToProps = dispatch => ({
	incrementActiveIndex: () => dispatch(incrementActiveIndexAction()),
	decrementActiveIndex: () => dispatch(decrementActiveIndexAction()),
});


export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'accountCreationForm',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	enableReinitialize: true,
})(TabsWithButtons));
