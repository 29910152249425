import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { EventDetailPopup } from "src/domains/Events/Detail/DetailPopup";
import { EventItem } from "src/domains/Events/ListPage/Item";
import {
  getMeetingToken,
  groupByDays,
  removeEventDuplicates,
} from "src/domains/Events/utils";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { useGetUserEvents } from "src/queries/events";
import { useGetUserTrialUsage } from "src/queries/trialUsage";
import { EventStatus, MultiPartyEvent } from "src/services/ApiClient/scheduler";
import { AvailKitService } from "src/services/AvailKitService";

import { setMeetingInfoAction } from "avail-web-ui/dux/MeetingSlice";
import { addNotificationAction } from "avail-web-ui/dux/NotificationSlice";
import { getUserInfo } from "avail-web-ui/services/UserSessionService";

import styles from "./styles.scss";

const PAGE_SIZE = 50;
const SORT_PARAM = "startDate,asc";
const ACCEPTED_STATUSES = [
  EventStatus.created,
  EventStatus.creating,
  EventStatus.started,
  EventStatus.ended,
];

export function EventsListPage(eventUpdateTrigger) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = getUserInfo();
  const availKit = AvailKitService.instance;
  const { callLink } = useFeatureFlags();

  const [events, setEvents] = useState<MultiPartyEvent[]>([]);
  const [groupedEvents, setGroupedEvents] = useState([]);
  // This is used to trigger the get user events query to re-run whenever we get updated events
  const [updatedEventId, setUpdatedEventId] = useState("");

  // Relates to the `View Details` popup
  const [selectedEvent, setSelectedEvent] = useState<MultiPartyEvent | null>(
    null
  );
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  // pageNumber is sent to the backend api
  // since we have the `Load More` button logic, the `useGetUserEvents` query
  // is set up so that whenever `pageNumber` is updated, the query is re-run
  const [pageNumber, setPageNumber] = useState(0); // start from page 0
  const [showLoadMore, setShowLoadMore] = useState(false);

  // This is to check if a TRIAL user's current balance is exceeding,
  // to disable the 'Create Event' button if so
  const [isTrialExceeded, setIsTrialExceeded] = useState(false);

  // from/to currently set from now (start of day) to 2 years ahead (based on requirements)
  const from = moment().startOf("day").format();
  const to = moment().add(2, "year").format();
  const { error, data, isLoading } = useGetUserEvents(
    userInfo.id,
    eventUpdateTrigger,
    updatedEventId,
    {
      from,
      to,
      paged: true,
      pageNumber,
      pageSize: PAGE_SIZE,
      sort: SORT_PARAM,
      statuses: ACCEPTED_STATUSES,
    }
  );

  // Trial usage data to hide create-button if user is out of mins
  const { data: trialUsageData } = useGetUserTrialUsage(userInfo);
  const trialUsageInfo = trialUsageData?.content;

  useEffect(() => {
    if (trialUsageInfo) {
      const { scheduledTrialMinutes, availableTrialMinutes } = trialUsageInfo;

      // if the user has more scheduled minutes than available, create button must be disabled
      if (scheduledTrialMinutes >= availableTrialMinutes) {
        setIsTrialExceeded(true);
      } else {
        // this ELSE case is if the user creates/updates and event and then the API doesn't
        // give us the new trial minutes until the 2nd API call,
        // in the 2nd call the available minutes might be less than scheduled so we want to set this to false in that case
        setIsTrialExceeded(false);
      }
    }
  }, [trialUsageData]);

  // Subscribe to scheduler service on pubnub for event update triggers
  // Note: This effect runs on every re-render to make sure availkit correctly adds the
  // listener once it boots up (AVK bootup sequence is external to this component).
  useEffect(() => {
    if (availKit) {
      // the `addEventListener` filters out any repeated references with a `Set` object, so
      // we are guaranteed not to have a bunch of listeners since this runs on every re-render
      availKit.avkSchedulerService.addEventListener(schedulerServiceListener);
    }
    // Unsubscribe on unmount
    return () => {
      if (availKit) {
        availKit.avkSchedulerService.removeEventListener(
          schedulerServiceListener
        );
      }
    };
  });

  const schedulerServiceListener = {
    onReceiveEventUpdate: (_, event) => {
      // Updating the event id causes the update to be passed into the react-query dependencies on the getUserEvents query
      setUpdatedEventId(`${event.eventId}-${new Date().getTime()}`);
    },
  };

  // transform data from our query above
  useEffect(() => {
    if (data) {
      // Don't show the 'Load More' button if this is the last page of data
      if (data.lastPage) {
        setShowLoadMore(false);
      } else {
        setShowLoadMore(true);
      }

      // Merge the updated events with the current list (for load more button option)
      const allEvents = [...events, ...data.content];

      // Remove duplicates
      const uniqueEvents = removeEventDuplicates(allEvents);

      setEvents(uniqueEvents);

      // Group the events by days and then sort them
      const groupedByDays = groupByDays(uniqueEvents);
      const sortedGrouped = [...groupedByDays].sort((a, b) => {
        const date1 = moment(a.day);
        const date2 = moment(b.day);
        return moment.utc(date1.unix()).diff(moment.utc(date2.unix()));
      });
      setGroupedEvents(sortedGrouped);
    }
  }, [data]);

  // if our query returns an error we just want to show a notification for it for now
  useEffect(() => {
    if (error) {
      dispatch(
        addNotificationAction({
          message: error.message,
          type: "error",
        })
      );
    }
  }, [error]);

  // Since the DetailsPopup component depends on `selectedEvent` have
  // the set function called only after `selectedEvent` has updated
  useEffect(() => {
    if (selectedEvent === null) {
      setIsDetailsOpen(false);
    } else {
      setIsDetailsOpen(true);
    }
  }, [selectedEvent]);

  const handleJoinClick = (event: MultiPartyEvent) => {
    // TODO: we need a way to know when the user has jumped out of a call in order to set the `meetingInfo` back to `null`
    dispatch(setMeetingInfoAction(event));

    const meetingToken = getMeetingToken(event, userInfo.loginId);
    window.open(`${callLink}${meetingToken}`, "_blank");
  };

  const handleDetailsClick = (event: MultiPartyEvent) =>
    setSelectedEvent(event);

  const onDetailsModalClose = () => setSelectedEvent(null);

  const loadMoreEvents = () => {
    // Updating the page number state triggers the get events query to re-run
    setPageNumber(pageNumber + 1);
  };

  // TODO move this perm check to util if we need it more places
  const blacklist = ["PROCTOR", "HOSPITAL_ADMIN"];
  const currentUserType = getUserInfo().userType;
  const isAllowedToSchedule =
    blacklist.find((userType) => currentUserType === userType) === undefined;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Upcoming Events</h1>
        {isAllowedToSchedule && (
          <Button
            theme="tertiary"
            onClick={() => {
              history.push(`/events/create/new`);
            }}
            // if the user is an expired trial member then dont let them create new events
            disabled={
              userInfo.memberType === "TRIAL_EXPIRED" || isTrialExceeded
            }
          >
            Create Event
          </Button>
        )}
      </div>

      {/* TODO: Search bar parked for now... */}
      {/* <div className={styles.toolbar}>
        <p>July</p>
        <input />
      </div> */}

      <div data-test-id="events-list">
        {events.length === 0 ? (
          <div className={styles.noEvents}>
            <Typography variant="h5" className={styles.dayHead}>
              No upcoming events scheduled.
            </Typography>
          </div>
        ) : (
          <>
            {groupedEvents.map((dayObject) => (
              <div className={styles.day} key={dayObject.day}>
                <Typography variant="h6" className={styles.dayHead}>
                  {moment(dayObject.day).isSame(new Date(), "day")
                    ? "Today, "
                    : null}
                  {moment(dayObject.day).format("dddd, MMMM Do")}
                </Typography>

                <div className={styles.events}>
                  <ul>
                    {dayObject.events.map((event, idx) => (
                      <EventItem
                        key={`${event.eventId}-${idx}`}
                        event={event}
                        onJoinCall={() => handleJoinClick(event)}
                        onDetailsClick={() => handleDetailsClick(event)}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      {showLoadMore ? (
        <div className={styles.loadMore}>
          <Button
            theme="tertiary"
            onClick={loadMoreEvents}
            disabled={isLoading}
          >
            {/* TODO: show a spinner instead of dots? */}
            {isLoading ? "..." : "Load More"}
          </Button>
        </div>
      ) : null}

      <EventDetailPopup
        open={isDetailsOpen}
        onCancel={onDetailsModalClose}
        onStart={() => handleJoinClick(selectedEvent)}
        event={selectedEvent}
      />
    </div>
  );
}
