import React from 'react';
import cx from 'classnames';
import { Button, Fields, validators } from '../../../../../../Common/Components';
import './brand.scss';

const Style = {
	width: 250,
};

const labelStyle = {
	left: 85,
};

export const customInput = (props) => {
	const { input, type, meta } = props;
	return (
		<div
			className={cx(
				'custom-input-container',
				{ 'flex-row-reverse': type === 'checkbox' },
				{ dirty: meta.dirty || meta.initial },
			)}
			style={{width: 250}}
		>
			<Fields.InputField
				style={Style}
				{...input}
				type={type}
				autoFocus={props.autoFocus}
				placeholder="Domain name"
			/>
		</div>
	);
};

export const domains = ({ fields }) => (
	<div className="custom-field-array-container" >
		{fields.map((code, index) => (
			<div
				key={index}
				className="field-array-item justify-content--flex-end"
				style={{justifyContent: 'flex-start'}}
			>
				<Fields.InputField
					className="user-profile__general-input"
					name={code}
					type="text"
					component={customInput}
					style={{width: 'inherit !important'}}
					validate={[
						validators.required,
						validators.domainValidator,
						validators.domainExtensionValidator,
					 ]}
					autoFocus
				/>
				{
					fields.length > 1 && 
					<Button
						className="user-profile__general-input-index"
						isGhost
						type="button"
						onClick={() => fields.remove(index)}
					>
						&times;
					</Button>
				}
			</div>
		))}
		<Button type="button" 
		className="user-profile__general-input-button2"
		onClick={() => fields.push()}>
			Add {!fields.length ? 'Domain(s)' : 'Another Domain'}
		</Button>
	</div>
);
