export const DEPARTMENTS = {
	CREATE_NEW_DEPARTMENT: 'DEPARTMENTS_CREATE_NEW_DEPARTMENT',
	CREATE_ROOM: 'DEPARTMENTS_CREATE_ROOM',
	DELETE_DEPARTMENT: 'DEPARTMENTS_DELETE_DEPARTMENT',
	DELETE_ROOM: 'DEPARTMENTS_DELETE_ROOM',
	GET_ALL_ROOM_TYPES: 'DEPARTMENTS_GET_ALL_ROOM_TYPES',
	GET_ALL_THERAPIES: 'DEPARTMENTS_GET_ALL_THERAPIES',
	GET_DEPARTMENT: 'DEPARTMENTS_GET_DEPARTMENT',
	GET_DEPARTMENTS_BY_HOSPITAL: 'DEPARTMENTS_GET_DEPARTMENTS_BY_HOSPITAL',
	GET_ROOM: 'DEPARTMENTS_GET_ROOM',
	GET_ROOM_PROCEDURES: 'DEPARTMENTS_GET_ROOM_PROCEDURES',
	GET_ROOMS_BY_DEPARTMENT: 'DEPARTMENTS_GET_ROOMS_BY_DEPARTMENT',
	GET_PROCEDURES_BY_DEPARTMENT: 'DEPARTMENTS_GET_PROCEDURES_BY_DEPARTMENT',
	SET_ALL_ROOM_TYPES: 'DEPARTMENTS_SET_ALL_ROOM_TYPES',
	SET_ALL_THERAPIES: 'DEPARTMENTS_SET_ALL_THERAPIES',
	SET_DEPARTMENT: 'DEPARTMENTS_SET_DEPARTMENT',
	SET_DEPARTMENTS_BY_HOSPITAL: 'DEPARTMENTS_SET_DEPARTMENTS_BY_HOSPITAL',
	SET_PROCEDURES_BY_DEPARTMENT: 'DEPARTMENTS_SET_PROCEDURES_BY_DEPARTMENT',
	SET_ROOM: 'DEPARTMENTS_SET_ROOM',
	SET_ROOM_PROCEDURES: 'DEPARTMENTS_SET_ROOM_PROCEDURES',
	SET_ROOMS_BY_DEPARTMENT: 'DEPARTMENTS_SET_ROOMS_BY_DEPARTMENT',
	UPDATE_DEPARTMENT: 'DEPARTMENTS_UPDATE_DEPARTMENT',
	UPDATE_ROOM: 'DEPARTMENTS_UPDATE_ROOM',
	UPDATE_DEPARTMENT_PROCEDURES: 'DEPARTMENT_UPDATE_DEPARTMENT_PROCEDURES',
	UPDATE_DEPARTMENT_THERAPIES: 'DEPARTMENT_UPDATE_DEPARTMENT_THERAPIES',
	GET_ALL_DEPARTMENTS_ROOM_IS_ASSOCIATED: 'DEPARTMENT_GET_ALL_DEPARTMENTS_ROOM_IS_ASSOCIATED',
	SET_ALL_DEPARTMENTS_ROOM_IS_ASSOCIATED: 'DEPARTMENT_SET_ALL_DEPARTMENTS_ROOM_IS_ASSOCIATED',
	GET_ALL_DEPARTMENTS_THERAPIES_ROOM_IS_ASSOCIATED: 'DEPARTMENT_GET_ALL_DEPARTMENTS_THERAPIES_ROOM_IS_ASSOCIATED',
	SET_ALL_DEPARTMENTS_THERAPIES_ROOM_IS_ASSOCIATED: 'DEPARTMENT_SET_ALL_DEPARTMENTS_THERAPIES_ROOM_IS_ASSOCIATED',
	GET_ROOM_THERAPIES: 'DEPARTMENT_GET_ROOM_THERAPIES',
	SET_ROOM_THERAPIES: 'DEPARTMENT_SET_ROOM_THERAPIES',
	UPDATE_ROOM_PROCEDURES: 'DEPARTMENT_UPDATE_ROOM_PROCEDURES',
};
