import { AVKDataType } from "availkit-js/dist/Models/Events/AVKDataTransferEvent";
import { AVKFreezeFramePostedEvent } from "availkit-js/dist/Models/Events/AVKFreezeFramePostedEvent";
import { AVKTransferOptions } from "availkit-js/dist/Services/AVKDataTransferService";
import { Guid } from "guid-typescript";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { handleSidePanelCloseWhenLayoutsOrPresetsOpen } from "src/domains/Beacon/store/stream/thunks/handleSidePanelCloseWhenNoConsole";
import { uiActions } from "src/domains/Beacon/store/ui";
import {
  captureVideoOnCanvas,
  clearFreezeFrameCanvas,
  getCanvasImageData,
  getChunkSize,
} from "src/domains/Beacon/utils/canvas";
import { logger, LoggerLevels } from "src/logging/logger";
import { uploadFreezeFrame } from "src/services/ApiClient/presence";
import { AvailKitService } from "src/services/AvailKitService";
import UserSessionService from "src/services/UserSessionService";

// Handles the freeze frame by creating canvas and taking a screen shot of the UI
// for now is working for fullscreen freeze frame due that PortalCall didn't implement it
export const handleFreezeFrameThunk = createAsyncThunk<
  { transferId: string; imageData: string },
  // First argument to the payload creator
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("stream/handleFreezeFrameThunk", async (_arg, { getState, dispatch }) => {
  try {
    logger().info("Freezing Frames...");
    const availKit = AvailKitService.instance;
    const user = UserSessionService.getCachedUserInfo();
    const { meeting } = getState();
    const { mode, callSid } = meeting;

    logger().logWithFields(
      LoggerLevels.info,
      { feature: "FreezeFrame" },
      "User initiated freezeframe."
    );

    // captures the video on a canvas in order to show the freeze frame effect
    const capturedImageCanvas = captureVideoOnCanvas();

    // Captures Canvas to a base64 image
    const imageData = getCanvasImageData(capturedImageCanvas);

    // Strip off the header data, `data:image/jpeg;base64,`.
    const imageWithoutDataType = imageData.slice(23);

    // when freeze is successful we run this function
    const handleDispatchesOnFreeze = () => {
      // if the presets or layouts side panel is open, we want to close it
      dispatch(handleSidePanelCloseWhenLayoutsOrPresetsOpen());

      // set the UI indicator to be on with the label set to "frozen"
      dispatch(
        uiActions.setIndicator({
          color: "orange",
          label: "Frozen",
          show: true,
        })
      );
    };

    if (mode === CallModes.MP) {
      try {
        const freezeFrameFormData = new FormData();

        const blob = new Blob([imageWithoutDataType], { type: "image/jpeg" });
        freezeFrameFormData.append("content", blob, `${Date.now()}.jpeg`);
        const { content } = await uploadFreezeFrame(
          callSid,
          freezeFrameFormData
        );

        const freezeFramePostedEvent = new AVKFreezeFramePostedEvent(
          callSid,
          Guid.parse(content.id),
          2
        );
        freezeFramePostedEvent.transferIdentifier = Guid.parse(content.id)
          .toString()
          .toLowerCase() as any;

        freezeFramePostedEvent.sender = user.loginId;

        availKit.eventService.broadcast(freezeFramePostedEvent);

        handleDispatchesOnFreeze();

        return { transferId: content.id, imageData };
      } catch (error: any) {
        // Call the utils for cleaning the Canvas element
        clearFreezeFrameCanvas();
        logger().error("MP handle Freeze Frame error", error?.message);
        // Throwing error so the outside try-catch catches it, too
        throw new Error();
      }
    } else if (mode === CallModes.P2P) {
      // Make provision for configuring chunkSize for adaptive chunkSize in future
      const chunkSize = getChunkSize();

      // Provide a means for configuring chunkSize, in KB
      const transferOptions: AVKTransferOptions = {
        chunkSize,
      };

      const transferId = availKit.transferServiceV2.transferDataWithOptions(
        imageWithoutDataType,
        AVKDataType.FreezeFrameImage,
        callSid,
        transferOptions
      );

      handleDispatchesOnFreeze();

      return { transferId, imageData };
    }
  } catch (error: any) {
    logger().error(
      "FreezeFrame cancelled. Screen image couldn't be uploaded.",
      error?.message
    );
    throw error;
  }
});
