/* eslint-disable max-len */
const fileSizeLimit = 1024 * 1024;
export const required = value => (value ? undefined : 'Required');
export const multiselectRequired = value => value?.length === 0 ? 'Required' : undefined;
export const imagerequired = value => (value ? undefined : 'Image Required');
export const email = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? 'Invalid email address' : undefined);
export const passwordValidate = (value, allValues) => (value !== allValues.password ? 'Password does not match' : undefined);
export const newPasswordValidator = (value, allValues) => (value !== allValues.new_password ? 'Password does not match' : undefined);
export const oldPasswordValidator = (value, allValues) => ((value && allValues.old_password) && value === allValues.old_password ? 'Current password should not be same as new password' : undefined);
export const onlyLetters = value => (value && !/^[a-zA-Z\s]*$/.test(value) ? 'Only letters allowed' : undefined);
export const name = value => (value && !/^[A-Za-z-’'",.+\s]+$/.test(value) ? 'Only letters, commas, periods, hyphens, or spaces allowed' : undefined);
export const onlyNumbers = value => (onlyNumbers && !/^[0-9\s]*$/.test(value) ? 'Only numbers allowed' : undefined);
export const positiveNumbers = value => (value <= 0 ? 'Positive number expected' : undefined);
export const requiredIfRecurringEvent = (value, allValues) => (allValues.isRecurringEvent && !value ? 'Required' : undefined);
export const minimumLength3 = value => (value && value.length < 3 ? 'Minimum length is 3 characters' : undefined);
export const minimumLength2 = value => (value && value.length < 2 ? 'Minimum length is 2 characters' : undefined);
export const minimumLength8 = value => (value && value.length < 8 ? 'Minimum length is 8 characters' : undefined);
export const mimimumLength10 = value => (value && value.length < 10 ? 'Minimum length is 10 characters' : undefined);
export const maximumLength255 = value => (value && value.length >255 ? 'Maximum length is 255 characters' : undefined);
export const maximumLength5 = value => (value && value.length > 5 ? 'Maximum length is 5 characters' : undefined);
export const exactLength17 = value => (value && value.length !== 17 ? 'Length must be 17 characters' : undefined);
export const maximumLength15 = value => (value && value.length > 15 ? 'Maximum length is 15 characters' : undefined);
export const maximumLength48 = value => (value && value.length > 48 ? 'Maximum length is 48 characters' : undefined);
export const maximumLength64 = value => (value && value.length > 64 ? 'Maximum length is 64 characters' : undefined);
export const maximumLength128 = value => (value && value.length > 128 ? 'Maximum length is 128 characters' : undefined);
export const maximumLength254 = value => (value && value.length > 254 ? 'Maximum length is 254 characters' : undefined);
export const macAddress = value => (value && !/^[a-fA-F0-9[:-]{17}|[a-fA-F0-9]{12}$/i.test(value) ? 'Invalid MAC address' : undefined);
export const passwordCharacterValidator = value => (value && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%\^\*&])(?=.{8, 15})/.test(value) ? 'one capital,one number and one speceial character' : undefined);
export const numberValidator = value => (value && !/\d/.test(value) ? 'one upper,one lower, one number and one special character' : undefined);
export const capitalValidator = value => (value && !/[A-Z]/.test(value) ? 'one upper,one lower, one number and one special character' : undefined);
export const lowerValidator = value => (value && !/[a-z]/.test(value) ? 'one upper,one lower,one number and one special character' : undefined);
export const specialValidator = value => (value && !/[!@#$%^&*()_-]/.test(value) ? 'one upper,one lower,one number and one special character' : undefined);
export const imageformat = value => (value && value.name && !/\.(jpg|jpeg|png|gif|bmp)$/.test(value.name) ? 'need correct image format: jpg,jpeg,gif,bmp,png,' : undefined);
export const imagesize = value => (value && value.size && value.size > fileSizeLimit ? 'Image size should be less than 1 MB' : undefined);
export const domainValidator = value => (value && !/^([a-zA-Z0-9]{1,2}|([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]))(?:\.[a-zA-Z]{2,})+$/.test(value) ? 'invalid domain name' : undefined);
export const whitespaceRequirement = value => (value && !/^(?![\s])/.test(value) ? "Can't start with whitespace" : undefined);
export const alphaNumaric = value => (value && !/^[a-zA-Z0-9 ]*$/.test(value) ? 'Only alphanumeric allowed' : undefined);
export const domainExtensionValidator = value => value.endsWith('.jpeg') || value.endsWith('.jpg') || value.endsWith('.png') || value.endsWith('.gif') || value.endsWith('.bmp') || value.endsWith('.docs') || value.endsWith('.xls') ||
value.endsWith('.image') || value.endsWith('.doc') || value.endsWith('.pdf') || value.endsWith('.text') ? 'invalid domain extension' : undefined;
export const otpRequired = value => (value ? undefined : 'Please enter One Time Password to verify');
