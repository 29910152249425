import { NConfig, Nurep } from "availkit-js";

const defaultConfig = new NConfig();
defaultConfig.pubnubPublishKey = "demo";
defaultConfig.pubnubSubscribeKey = "demo";

export default class AvailKitSingleton {
  static getInstance(config: NConfig, presenceUUID: string) {
    if (!AvailKitSingleton.instance) {
      const availKit = new Nurep(config, presenceUUID);
      availKit.eventService.addEventListener(availKit.telephonyService);
      availKit.eventService.addEventListener(availKit.avkAnnotationService);
      availKit.eventService.addEventListener(availKit.avkVideoSourceDiscoveryService);
      availKit.eventService.addEventListener(availKit.cameraDiscoveryService);
      availKit.eventService.addEventListener(availKit.avkSidebarService);
      availKit.eventService.addEventListener(availKit.avkMediaService);
      availKit.eventService.addEventListener(availKit.avkFreezeFrameService);
      availKit.eventService.addEventListener(availKit.avkSchedulerService);

      AvailKitSingleton.instance = availKit;
    }
    return AvailKitSingleton.instance;
  }

  private static instance: Nurep | null = null;
  private constructor() {}
}
