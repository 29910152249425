import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../Common/Components/Icon/Icon';

import './AddNewEntity.scss';

class AddNewEntity extends PureComponent {
	render() {
		return (
			<div className="add-new-organization">
				<div className="add-new-organization__container">
					<img src={this.props.image} alt="" />
					<span className="add-new-organization__title">{this.props.title}</span>
					<button className="add-new-organization__button" onClick={this.props.addNewEntityHandler}>
						<Icon className="icon-plus-outline icon--orange" />
					</button>
				</div>
			</div>
		);
	}
}

AddNewEntity.defaultProps = {
	image: '',
};

AddNewEntity.propTypes = {
	title: PropTypes.string.isRequired,
	addNewEntityHandler: PropTypes.func.isRequired,
	image: PropTypes.string,
};

export default AddNewEntity;
