export const MEETING = {
	MEETING_CALL_SET_INCOMING: 'MEETING_CALL_SET_INCOMING',
	MEETING_CALL_SET_CALLING: 'MEETING_CALL_SET_CALLING',
	MEETING_CALL_SET_CALLING_MEETING_INFO: 'MEETING_CALL_SET_CALLING_MEETING_INFO',
	MEETING_UPDATE_CALL_INFO: 'MEETING_UPDATE_CALL_INFO',
	MEETING_CALL_ACCEPT: 'MEETING_CALL_ACCEPT',
	MEETING_CALL_REJECT: 'MEETING_CALL_REJECT',
	MEETING_SEND_CALLBACK: 'MEETING_SEND_CALLBACK',
	MEETING_CANCEL_CALLBACK: 'MEETING_CANCEL_CALLBACK',
	MEETING_SET_TWILIO_TOKEN: 'MEETING_SET_TWILIO_TOKEN',
};
