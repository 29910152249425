// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container_qb-4Fj{flex-grow:1;padding:16px 32px 32px}.styles_container_qb-4Fj .styles_title_xjr1zD{margin:16px 0}", "",{"version":3,"sources":["webpack://./src/domains/Events/CreatePage/styles.scss"],"names":[],"mappings":"AAAA,yBACE,WAAA,CACA,sBAAA,CAEA,8CACE,aAAA","sourcesContent":[".container {\n  flex-grow: 1;\n  padding: 16px 32px 32px;\n\n  .title {\n    margin: 16px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container_qb-4Fj",
	"title": "styles_title_xjr1zD"
};
export default ___CSS_LOADER_EXPORT___;
