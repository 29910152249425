import React, { PureComponent } from 'react';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Fields } from '../../../../../../../Common/Components';

class AddNewHospital extends PureComponent {
	render() {
		const { t } = this.props;

		return (
			<form
				action=""
				onSubmit={this.props.handleSubmit}
			>
				<div>
					{this.props.data && this.props.data.length > 0 && (
						<Fields.MultiselectField
							fluid
							labelText={t('features.org.hospital.create.form.select_hospital.label')}
							placeholder={t('features.org.hospital.create.form.select_hospital.placeholder')}
							name="hospitals"
							multiple
							defaultAllSelected

							options={this.props.data.map(hospital => ({
								key: hospital.id,
								value: hospital.id,
								label: hospital.name,
							}))}
						/>
					)}
				</div>
			</form>);
	}
}

const AddNewHospitalWithTranslation = withTranslation()(AddNewHospital);

export default reduxForm({
	form: 'CreateNewVendor',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(AddNewHospitalWithTranslation);
