import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '../../../../../../../Common/Components';
import { reduxForm } from 'redux-form';
/* Components */
import ModalComponent from '../../../../../../../Common/Components/Modal/Modal';
import { Table } from '../../../../../Common';
import CreateNewUserModal from '../../../../Users/Features/CreateNewUserModal/CreateNewUserModal';
import OrganisationUsers from '../../../Components/OrganisationUsers/OrganisationUsers';
/* Actions */
import { createUserAction } from '../../../../Users/UsersActions';
import { getAllHospitalAdminsAction } from '../../HospitalsActions';

class HospitalUsers extends PureComponent {
	state = {
		isCreateUserModalOpened: false,
	};

	componentDidMount() {
		this.params = {
			hospitalId: this.props.hospitalId,
			size: this.state.pageSize,
			page: 0,
		};
		this.props.getAllHospitalAdmins(this.params);
	}
	
	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			hospitalId: this.props.hospitalId,
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			...this.params,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllHospitalAdmins(this.params);
	};

	onTableSortHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			...this.params,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllHospitalAdmins(this.params);
	};

	toggleCreateUserModal = () => this.setState({ isCreateUserModalOpened: !this.state.isCreateUserModalOpened });

	renderCreateUserModal = () => (
		<ModalComponent
			open={this.state.isCreateUserModalOpened}
			hasActionButtons={false}
			onCancel={this.toggleCreateUserModal}
			title="Create new user"
		>
			<CreateNewUserModal
				toggleModal={this.toggleCreateUserModal}
				createUser={this.props.createUser}
				hospitalId={this.props.hospitalId}
			/>
		</ModalComponent>
	);

	columns = [
		{
			title: 'Name',
			sortValue: 'name',
			excluded: false,
		}, {
			title: 'Role',
			sortValue: 'userType',
			excluded: false,
		},
	];

	render() {
		return (
			<React.Fragment>
				<OrganisationUsers
					columns={this.columns}
					organisationUsers={this.props.hospitalAdmins}
					onAddNewHandler={this.toggleCreateUserModal}
					onAddNewTitle="Create user"
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					onSortTableHandler={this.onTableFilterHandler}
					search
					sortable
					noEntityComponent={
						<div className="justify-content--space-between width--full">
							<div className="profile__right-content-wrapper">No users available</div>
						</div>
					}
				/>
				{this.renderCreateUserModal()}
			</React.Fragment>
		);
	}
}

HospitalUsers.propTypes = {};

const mapStateToProps = state => ({
	hospitalAdmins: state.hospitalsReducer.hospitalAdmins,
});

const mapDispatchToProps = dispatch => ({
	getAllHospitalAdmins: hospitalId => dispatch(getAllHospitalAdminsAction(hospitalId)),
	createUser: data => dispatch(createUserAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'businessUnitUsersForm',
})(HospitalUsers));