// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_eula-modal_LzAmh\\+{width:60% !important}.styles_eula-modal_LzAmh\\+ .modal__content-wrapper{width:90% !important}.styles_eula-modal_LzAmh\\+ .styles_eula-container_cOuVrx{overflow:hidden;padding-top:76vh;position:relative}.styles_eula-modal_LzAmh\\+ .styles_eula-data_jZFY-w{border:0;height:100%;left:0;position:absolute;top:0;width:100%}", "",{"version":3,"sources":["webpack://./src/components/legacy/Modal/EulaModal/styles.scss"],"names":[],"mappings":"AAEA,2BACE,oBAAA,CAEA,mDACE,oBAAA,CAGF,yDACE,eAAA,CACA,gBAAA,CACA,iBAAA,CAGF,oDACE,QAAA,CACA,WAAA,CACA,MAAA,CACA,iBAAA,CACA,KAAA,CACA,UAAA","sourcesContent":["/* stylelint-disable */\n\n.eula-modal {\n  width: 60% !important;\n\n  :global(.modal__content-wrapper) {\n    width: 90% !important;\n  }\n\n  .eula-container {\n    overflow: hidden;\n    padding-top: 76vh;\n    position: relative;\n  }\n\n  .eula-data {\n    border: 0;\n    height: 100%;\n    left: 0;\n    position: absolute;\n    top: 0;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eula-modal": "styles_eula-modal_LzAmh+",
	"eulaModal": "styles_eula-modal_LzAmh+",
	"eula-container": "styles_eula-container_cOuVrx",
	"eulaContainer": "styles_eula-container_cOuVrx",
	"eula-data": "styles_eula-data_jZFY-w",
	"eulaData": "styles_eula-data_jZFY-w"
};
export default ___CSS_LOADER_EXPORT___;
