import { useMemo } from "react";
import { useDispatch } from "react-redux";

import clsx from "clsx";

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Refresh,
  Settings,
  PeopleAlt,
  GridView,
  Pause,
  List,
} from "@mui/icons-material";

import { BadgeSystem } from "src/components/BadgesSystem";
import { Button } from "src/components/Button";
import { Logo } from "src/components/Logo";
import { NavBar } from "src/components/NavBar";
import { NetworkStatus } from "src/components/NetworkStatus/NetworkStatus";
import { BeaconControlsToolTips } from "src/constants/tooltips";
import { DRAWER_WIDTH } from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectEventDetails } from "src/domains/Beacon/store/meeting/selectors";
import {
  selectFreezeFrameLoading,
  selectFreezeFrameState,
  selectStreamState,
} from "src/domains/Beacon/store/stream/selectors";
import {
  handleFreezeFrameThunk,
  handleUnfreezeFrameThunk,
} from "src/domains/Beacon/store/stream/thunks";
import { closeFullscreenThunk } from "src/domains/Beacon/store/stream/thunks/closeFullscreenThunk";
import {
  selectIsLoadingRefreshFrames,
  selectParticipants,
  selectTwilioState,
} from "src/domains/Beacon/store/twilio/selectors";
import { refreshFramesThunk } from "src/domains/Beacon/store/twilio/thunks/refreshFramesThunk";
import { uiActions } from "src/domains/Beacon/store/ui";
import {
  selectIsUiHidden,
  selectSidePanelContent,
} from "src/domains/Beacon/store/ui/selectors";
import { selectNoConnectionModalOpen } from "src/domains/Beacon/store/ui/selectors";
import { UISidePanelContentOptions } from "src/domains/Beacon/store/ui/types";
import { getActiveParticipants } from "src/domains/Beacon/utils/participants";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { useScssVariable } from "src/hooks/useScssVariable";
import { MultiPartyEvent } from "src/services/ApiClient/scheduler";

import styles from "./styles.scss";

export interface IProps {
  isHost: boolean;
  sidePanelOpen: boolean;
}

export const TopBar = ({ isHost, sidePanelOpen }: IProps) => {
  const { consoleHasJoinedRoom, localNetworkQualityLevel } = useAppSelector(
    selectTwilioState
  );
  const streamState = useAppSelector(selectStreamState);
  const freezeFrameState = useAppSelector(selectFreezeFrameState);
  const sidePanelContent = useAppSelector(selectSidePanelContent);
  const isFreezeFrameLoading = useAppSelector(selectFreezeFrameLoading);
  const isUiHidden = useAppSelector(selectIsUiHidden);
  const isLoadingRefreshFrames = useAppSelector(selectIsLoadingRefreshFrames);
  const noConnectionModalOpen = useAppSelector(selectNoConnectionModalOpen);
  const {
    externalInputsImageResizing,
    tungstenExternalLa,
    offerDeviceSelection,
  } = useFeatureFlags();

  const settingsSubContent = [
    UISidePanelContentOptions.SETTINGS,
    UISidePanelContentOptions.AUDIO_SETTINGS,
    UISidePanelContentOptions.VIDEO_SETTINGS,
    UISidePanelContentOptions.CONSOLE_SETTINGS,
    UISidePanelContentOptions.NETWORK_STATUS_SETTINGS,
  ];
  // Passes the drawer width to a Scss var, './styles.scss'
  const { ref } = useScssVariable<HTMLDivElement>(
    "--drawer-width",
    `${DRAWER_WIDTH}px`
  );
  const dispatch = useDispatch();

  // button is active is the panel is open and the content matches what this button controls
  // we set all the variables at the top here because we need to use them to determine if we need to open/close the side panel (see usage below)
  const participantsActive =
    sidePanelOpen &&
    sidePanelContent === UISidePanelContentOptions.PARTICIPANTS;
  const presetsActive =
    sidePanelOpen && sidePanelContent === UISidePanelContentOptions.PRESETS;
  const layoutsActive =
    sidePanelOpen && sidePanelContent === UISidePanelContentOptions.LAYOUTS;
  const layoutsPresetsActive =
    sidePanelOpen &&
    sidePanelContent === UISidePanelContentOptions.LAYOUTS_PRESETS;
  const settingsActive =
    sidePanelOpen && settingsSubContent.includes(sidePanelContent);
  // consoleVideo-related conditions that require a group of buttons to be disabled
  const isConsoleVideoMuted =
    !consoleHasJoinedRoom ||
    streamState.console.video === "mute" ||
    noConnectionModalOpen;

  // state necessary to get the current Participant count
  const twilioParticipants = useAppSelector(selectParticipants);
  // we need the event details to get the active users to show the participant count
  // TODO handle the p2p case (event details show up for MP events)
  const eventDetails = useAppSelector(selectEventDetails) as MultiPartyEvent;
  // only need to update this list of active participants when twilio participants list updates
  const activeParticipants = useMemo(() => {
    return getActiveParticipants(
      eventDetails?.participants,
      twilioParticipants
    );
  }, [twilioParticipants, eventDetails?.participants]);

  const isFrameFrozen = freezeFrameState.active;

  const isRefreshFramesDisabled =
    isLoadingRefreshFrames || isConsoleVideoMuted || isFrameFrozen;

  const {
    layoutFrames: { leftTop: leftScreen, rightTop: rightScreen },
  } = streamState;
  const isFullScreen = leftScreen.isFullScreen || rightScreen.isFullScreen;

  const handleCloseFullscreen = (): void => {
    if (!isFullScreen) {
      return;
    }
    dispatch(closeFullscreenThunk({ externalInputsImageResizing }));
  };

  const handleFreezeFrame = () => {
    if (isFrameFrozen) {
      dispatch(handleUnfreezeFrameThunk());
    } else {
      dispatch(handleFreezeFrameThunk());
    }
  };

  // Handles show/hide the Top & Bottom bars in the UI
  const handleToggleUI = () => {
    if (isUiHidden) {
      dispatch(uiActions.setUiHidden(false));
      // please note how we dont reopen the PIP or side panel here, that is expected behavior
    } else {
      // when we hide all the UI, we should also close the side panel
      dispatch(uiActions.setUiHidden(true));
      dispatch(uiActions.setSidePanelOpen(false));
      dispatch(uiActions.setPipCollapsed(true));
    }
  };

  const handleRefreshFrames = () =>
    dispatch(refreshFramesThunk({ offerDeviceSelection }));

  return (
    <>
      <NavBar
        ref={ref}
        className={clsx(styles.root, {
          [styles.drawerOpened]: sidePanelOpen,
          [styles.hidden]: isUiHidden,
        })}
        data-test-id="topbar"
      >
        <div className={styles.wrapper}>
          <div className={styles.leftSection} data-test-id="left-section">
            <Logo data-test-id="logo" type="horizontal-light" size="small" />
            <BadgeSystem />
          </div>
          <div className={styles.rightSection} data-test-id="right-section">
            {tungstenExternalLa && localNetworkQualityLevel > -1 && (
              <NetworkStatus value={localNetworkQualityLevel} />
            )}
            <Button
              data-test-id="participants-button"
              variant="icon"
              endIcon={<PeopleAlt />}
              // we always start at 1 because of the current logged in user
              // add the current length of active users to get the final count
              label={1 + activeParticipants.length}
              size="large"
              active={participantsActive}
              tooltipProps={{
                title: "Participants",
                placement: "bottom",
              }}
              onClick={() => {
                dispatch(
                  uiActions.setSidePanelContent(
                    UISidePanelContentOptions.PARTICIPANTS
                  )
                );
                dispatch(uiActions.setSidePanelOpen(!participantsActive));
              }}
            />

            {/* Refresh frames not implemented yet will be available only for Tungsten External LA */}
            {tungstenExternalLa && (
              <Button
                data-test-id="refresh-button"
                variant="icon"
                endIcon={<Refresh />}
                disabled={isRefreshFramesDisabled}
                tooltipProps={{
                  title: "Refresh",
                  placement: "bottom",
                }}
                onClick={handleRefreshFrames}
              />
            )}
            {/* modular buttons that change if host, participant, SideBar, etc. */}
            {isHost && (
              <div
                className={styles.modularContainer}
                data-test-id="modular-container"
              >
                <Button
                  data-test-id="freeze-frame-button"
                  variant="icon"
                  endIcon={<Pause />}
                  disabled={isConsoleVideoMuted || isFreezeFrameLoading}
                  tooltipProps={{
                    title: !isFrameFrozen ? "Freeze" : "Unfreeze",
                    placement: "bottom",
                  }}
                  onClick={handleFreezeFrame}
                  active={isFrameFrozen}
                />
                {tungstenExternalLa ? (
                  <Button
                    data-test-id="layouts-presets-side-panel-button"
                    variant="icon"
                    endIcon={<GridView />}
                    active={layoutsPresetsActive}
                    disabled={
                      !consoleHasJoinedRoom ||
                      isConsoleVideoMuted ||
                      isFrameFrozen
                    }
                    tooltipProps={{
                      title: "Layouts & Presets",
                      placement: "bottom",
                    }}
                    onClick={() => {
                      dispatch(
                        uiActions.setSidePanelContent(
                          UISidePanelContentOptions.LAYOUTS_PRESETS
                        )
                      );
                      dispatch(
                        uiActions.setSidePanelOpen(!layoutsPresetsActive)
                      );
                      // LayoutsPresetContent does not need an initial layoutFrame selected
                      // prevents the blue Left Indicator being rendered when initially opening LayoutsPresetContent
                      dispatch(uiActions.setLayoutFrame(null));
                    }}
                  />
                ) : (
                  <>
                    <Button
                      data-test-id="layouts-sidepanel-button"
                      variant="icon"
                      endIcon={<GridView />}
                      active={layoutsActive}
                      disabled={isConsoleVideoMuted || isFrameFrozen}
                      tooltipProps={{
                        title: "Layouts",
                        placement: "bottom",
                      }}
                      onClick={() => {
                        dispatch(
                          uiActions.setSidePanelContent(
                            UISidePanelContentOptions.LAYOUTS
                          )
                        );
                        dispatch(uiActions.setSidePanelOpen(!layoutsActive));
                        handleCloseFullscreen();
                      }}
                    />
                    <Button
                      variant="icon"
                      endIcon={<List />}
                      active={presetsActive}
                      disabled={isConsoleVideoMuted || isFrameFrozen}
                      tooltipProps={{
                        title: "Presets",
                        placement: "bottom",
                      }}
                      onClick={() => {
                        dispatch(
                          uiActions.setSidePanelContent(
                            UISidePanelContentOptions.PRESETS
                          )
                        );
                        dispatch(uiActions.setSidePanelOpen(!presetsActive));
                        handleCloseFullscreen();
                      }}
                    />
                  </>
                )}
              </div>
            )}
            <Button
              variant="icon"
              endIcon={<Settings />}
              active={settingsActive}
              tooltipProps={{
                title: "Settings",
                placement: "bottom",
              }}
              onClick={() => {
                dispatch(
                  uiActions.setSidePanelContent(
                    UISidePanelContentOptions.SETTINGS
                  )
                );
                dispatch(uiActions.setSidePanelOpen(!settingsActive));
              }}
              data-test-id="settings-button"
            />
            <Button
              variant="icon"
              endIcon={<KeyboardArrowUp />}
              size="medium"
              tooltipProps={{
                title: BeaconControlsToolTips.hideUIButton,
                placement: "bottom",
              }}
              onClick={handleToggleUI}
            />
          </div>
        </div>
      </NavBar>
      {/* appears when NavBar not visible */}
      <span
        className={clsx(styles.showUiButton, {
          [styles.shown]: isUiHidden,
        })}
      >
        <Button
          variant="icon"
          endIcon={<KeyboardArrowDown />}
          size="medium"
          tooltipProps={{
            title: BeaconControlsToolTips.showUIButton,
            placement: "bottom",
          }}
          onClick={handleToggleUI}
          data-test-id="show-ui-button"
        />
      </span>
    </>
  );
};
