import { MEETING } from './MeetingActionTypes';

export const CALLBACK_MODES = {
	calling: "calling",
	consolebusy: "consolebusy",
	consoledeclined: "consoledeclined",
	consoleunavailable: "consoleunavailable",
	consoleaccepted: "consoleaccepted",
	unknown: "unknown",
	other: "other",
}

const initialState = {
	isCallIncoming: false,
	callbackMode: "", // one of CALLBACK_MODES
	outgoingMeetingInfo: null,
	meetingInfo: null,
	twilioToken: '',
};

export default function (state = initialState, action) {
	switch (action.type) {
		case MEETING.MEETING_CALL_SET_INCOMING:
			return { ...state, isCallIncoming: action.payload };

		case MEETING.MEETING_UPDATE_CALL_INFO:
			return { ...state, meetingInfo: action.payload };

		case MEETING.MEETING_CALL_SET_CALLING:
			return { ...state, callbackMode: action.payload }

		case MEETING.MEETING_CALL_SET_CALLING_MEETING_INFO:
			return { ...state, outgoingMeetingInfo: action.payload }

		case MEETING.MEETING_SET_TWILIO_TOKEN:
			return { ...state, twilioToken: action.payload }

		default:
			return state;
	}
}
