import React from "react";
import "./IncomingCallNotification.scss";
import {
	callAnswer,
	callDecline,
	incomingCall,
} from "../../../../../../Common/Assets/Images";
import ringToneMP3 from "../../../../../../Common/Assets/Sounds/availcall-ringtone.mp3";
import ringToneOgg from "../../../../../../Common/Assets/Sounds/availcall-ringtone.ogg";

const IncomingCallNotification = ({ callInfo, onAccept, onReject }) => (
	<div className="incoming-call-notification" data-test-id="incoming-call-modal">
		<audio autoPlay loop>
			<source src={ringToneMP3} type="audio/mpeg" />
			<source src={ringToneOgg} type="audio/ogg" />
		</audio>
		<div
			className="incoming-call-button"
			style={{ backgroundImage: `url("${incomingCall}")` }}
		/>
		<div className="incoming-call-text">Incoming Call...</div>
		<div className="caller-details-text">
			{callInfo.hospital_name} is calling you.
		</div>
		<div className="call-answer-decline-buttons">
			<button
				className="answer-call-button"
        data-test-id="incoming-call-accept"
				style={{ backgroundImage: `url("${callAnswer}")` }}
				onClick={onAccept}
			/>
			{/* onClick={() => this.updateCallState('inprogress')} */}
			<div className="answer-button-label">ANSWER</div>
			<button
				className="decline-call-button"
        data-test-id="incoming-call-modal-decline"
				style={{ backgroundImage: `url("${callDecline}")` }}
				onClick={onReject}
			/>
			{/* onClick={() => this.updateCallState('ready')} */}
			<div className="decline-button-label">DECLINE</div>
		</div>
	</div>
);

export default IncomingCallNotification;
