import { useEventListener } from "src/hooks/useEventListener";

// Can listen for element transitions, more precisely when have started or ended
export const useTransitionListeners = (
  element: Window | HTMLElement | any = window,
  transitionStarted: (e: any) => void,
  transitionEnded: (e: any) => void
) => {
  // Allows more accuracy if we want to know when the SidePanel is completely open
  // or close, for ex. when getting the video dimensions for telestration or freezeFrame
  const handleTransitionEnded = (e: any) => {
    e.stopPropagation();
    transitionEnded(e);
  };

  // Must restart the Side Panel transition ended state to false
  const handleTransitionStarted = (e: any) => {
    e.stopPropagation();
    transitionStarted(e);
  };

  useEventListener("transitionend", handleTransitionEnded, element);
  useEventListener("transitionstart", handleTransitionStarted, element);
};
