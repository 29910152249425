import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, reset } from 'redux-form';
/* Components */
import ModalComponent from '../../../../Common/Components/Modal/Modal';
import UserTableRow from './Components/UserTableRow/UserTableRow';
import { Table, AddNewEntity } from '../../Common';
import { getcurrentUserTypes } from '../../HomeSelectors';
/* Actions */
import {
	getAllUsersAction,
	deleteUserAction,
	createUserAction,
	resendActiveEmail,
	getUserTypesAction,
} from './UsersActions';
/* Styles & Images */
import { newUser } from '../../../../Common/Assets/Images';
import './Users.scss';
import { getUserTypes } from './UsersSelectors';
import resources from '../../../../Common/Constants/resources';
import ApiHelper from '../../../../Common/Services/ApiHelper';
import {convertToCSV, download, formatUsersData} from './UserExportHelper';
import {roles} from "../../../../Common/Constants/userTypes";
import {notificationService} from "../../../../Common/Services";
import { isAvailAdminUser } from "src/utils/user.ts";

export class Users extends PureComponent {
	state = {
		isDeleteUserModalOpened: false,
		isReinvitationModalOpened: false,
		pageSize: 10,
		value: '',
		parameter: 'name',
	};

  columns = [
    {
      title: 'Name',
      sortValue: 'firstName',
      alias: 'name',
    }, {
      title: 'Role',
    }, {
      title: 'Email',
      sortValue: 'email',
    },
    {
      title: 'Status',
      sortValue: 'userStatus',
    },
    {
      title: 'Organization',
      sortValue: 'organizationName',
    },
  ];

	componentDidMount() {
		this.props.getUserTypes();
		this.params = {
			size: this.state.pageSize,
			page: 0,
		};
    const isAvailAdmin = isAvailAdminUser(this.props.currentUserTypes);
    isAvailAdmin && this.columns.push({
      title: 'User Type',
      sortValue: 'memberType',
    });
	}

	onDeleteUserConfirm = () => {
		this.props.deleteUser(this.props.selectedUser.id);
		this.toggleDeleteUserModal();
	};

	onReinvitationConfirm = () => {
		this.props.resendActiveEmail(this.props.selectedUser.id);
		this.toggleReinvitationModal();
	};

	onPageChangeHandler = (e, { activePage }) => {
		this.params = {
			...this.params,
			size: this.state.pageSize,
			page: activePage - 1,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllUsers(this.params);
	};

	onTableFilterHandler = (value, parameter = 'name') => {
    this.setState({value: value});
    this.sortParams = value;

    if (parameter !== this.state.parameter && parameter !== 'userType') {
      this.setState({value: ''});
      this.sortParams = '';
    }

    this.setState({parameter: parameter});
    this.parameter = parameter;
    this.params = parameter === 'name' ? {
      size: this.state.pageSize,
    } : this.params;
    this.params = {
      size: this.state.pageSize,
      [this.parameter]: this.sortParams,
    };

    // always move to page 0 when updating the filter
    this.params.page = 0;
    this.props.getAllUsers(this.params);
	};

	onTableSortHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			...this.params,
			[this.parameter]: this.sortParams,
		};
		this.props.getAllUsers(this.params);
	};

	handleVariablePaginationChange = (option) => {
		if (!option) return;

		this.setState({ pageSize: option });
		this.props.getAllUsers({
			size: option,
			page: 0,
			[this.parameter]: this.sortParams 
		});
	}

	toggleDeleteUserModal = () => {
		this.setState({
			isDeleteUserModalOpened: !this.state.isDeleteUserModalOpened,
		});
	};

	toggleReinvitationModal = () => {
		this.setState({
			isReinvitationModalOpened: !this.state.isReinvitationModalOpened,
		});
	}

	redirect = () => {
		this.props.history.push('/users/newUser');
	};

	renderDeleteUserModal = () => (
		<ModalComponent
			open={this.state.isDeleteUserModalOpened}
			onConfirm={this.onDeleteUserConfirm}
			onCancel={this.toggleDeleteUserModal}
			confirmText="Delete"
		>
			{
				this.props.selectedUser && (
					<span>You are about to delete
						<span className="text--bold">
							{` ${this.props.selectedUser.firstName} ${this.props.selectedUser.lastName}`}
						</span>. Would you like to proceed with this action?
					</span>
				)
			}
		</ModalComponent>
	);

	renderReinvitationModal = () => (
		<ModalComponent
			open={this.state.isReinvitationModalOpened}
			onConfirm={this.onReinvitationConfirm}
			onCancel={this.toggleReinvitationModal}
			confirmText="Resend Invitation"
		>
			{
				this.props.selectedUser && (
					<span>You are about to invite
						<span className="text--bold">
							{` ${this.props.selectedUser.firstName} ${this.props.selectedUser.lastName}`}
						</span>. Would you like to proceed with this action?
					</span>
				)
			}
		</ModalComponent>
	);

	onExportHandler = async () => {
		const {value, parameter} = this.state;

		const response = await ApiHelper.get('coreregistry', resources.users, {
			[parameter]: value,
		});

    const newResponse = response.content.map(entry => ({
      ...entry,
      employee: {
        ...entry.employee,
        userStatus: entry.employee.userStatus === 'ACTIVE' ? entry.employee.userStatus : 'PENDING'
      }
    }))
		
		if(newResponse.length !== 0){
			const usersData = formatUsersData(newResponse);
			const csv = convertToCSV(usersData);
			download(csv);
		}else{
			notificationService.warning('There are no records found');
		}
	}
	
	render() {
		return (
			<React.Fragment>
				<div className="users__container">
					<React.Fragment>
						<React.Fragment>
							<Table
								exportUsersData={this.props.currentUserTypes[0] === roles.availAdmin}
								nameOverride="firstName"
								userTypes={this.props.userTypes}
								currentUserTypes={this.props.currentUserTypes[0]}
								row={UserTableRow}
								columns={this.columns}
								tableData={this.props.users}
								headerTitle="Users"
								openModal={this.toggleDeleteUserModal}
								openReinvitationModal={this.toggleReinvitationModal}
								paginationConfig={this.props.pagination}
								onPageChangeHandler={this.onPageChangeHandler}
								onSortTableHandler={this.onTableSortHandler}
								onSearchTermUpdateHandler={this.onTableFilterHandler}
								onAddNewHandler={this.redirect}
								onAddExportTitle="Export"
								onAddNewTitle="Invite New"
								className="users__table"
								onExportHandler={this.onExportHandler}
								noItemsClass="height--full justify-content--center align--center"
								noEntityComponent={
									<AddNewEntity
										image={newUser}
										addNewEntityHandler={this.redirect}
										title="Add New User"
									/>
								}
								search
								sortable
								variablePagination
								onVariablePaginationChange={this.handleVariablePaginationChange}
							/>
						</React.Fragment>
						{this.renderDeleteUserModal()}
						{this.renderReinvitationModal()}
					</React.Fragment>
				</div>
			</React.Fragment>
		);
	}
}

Users.propTypes = {
	createUser: PropTypes.func,
	deleteUser: PropTypes.func,
	getAllUsers: PropTypes.func,
	resetReduxForm: PropTypes.func,
	selectedUser: PropTypes.object,
	users: PropTypes.array,
};

const mapStateToProps = state => ({
	users: state.usersReducer.users,
	userTypes: getUserTypes(state),
	currentUserTypes: getcurrentUserTypes(state),
	selectedUser: state.usersReducer.selectedUser,
	pagination: state.usersReducer.pagination,
	isUsersLoaderActive: state.usersReducer.isUsersLoaderActive,
});

const mapDispatchToProps = dispatch => ({
	getAllUsers: params => dispatch(getAllUsersAction(params)),
	deleteUser: id => dispatch(deleteUserAction(id)),
	resetReduxForm: formName => dispatch(reset(formName)),
	createUser: data => dispatch(createUserAction(data)),
	getUserTypes: () => dispatch(getUserTypesAction()),
	resendActiveEmail: data => dispatch(resendActiveEmail(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'usersForm',
})(Users));
