import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { uiActions } from "src/domains/Beacon/store/ui";
import {
  generateBadge,
  UIBadgeTypes,
  UINotificationTypes,
} from "src/domains/Beacon/store/ui/types";
import { logger, LoggerLevels } from "src/logging/logger";
import { timeoutCallback } from "src/utils/timeout";

// Handles the unfreeze frame for the created canvas, for now, it's only
// working for fullscreen freeze frame due that PortalCall didn't implement it
export const handleSideBarThunk = ({
  sidebarActive,
}: {
  sidebarActive: boolean;
}) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const {
      meeting: { isUserHost: isHostUser },
    } = getState();

    logger().logWithFields(
      LoggerLevels.info,
      {
        fileInfo: `handleSideBarThunk.ts`,
        feature: `store/stream/thunks`,
      },
      `handling Side Bar state for sidebarActive: ${sidebarActive}`
    );

    if (sidebarActive) {
      // Must remove the previous sidebar-off badge if exists
      const sidebarOffBadge = generateBadge(UIBadgeTypes.SIDEBAR_OFF);
      dispatch(uiActions.removeBadge(sidebarOffBadge));

      const badge = generateBadge(UIBadgeTypes.SIDEBAR_ON);
      dispatch(uiActions.addBadge(badge));
    } else {
      // Must remove the previous sidebar-on badge if exists
      const sidebarOnBadge = generateBadge(UIBadgeTypes.SIDEBAR_ON);
      dispatch(uiActions.removeBadge(sidebarOnBadge));

      const badge = generateBadge(UIBadgeTypes.SIDEBAR_OFF);
      dispatch(uiActions.addBadge(badge));

      // Must remove the Sidebar Off badge after 2 secs, otherwise, it will remain
      // during all the call
      timeoutCallback(() => dispatch(uiActions.removeBadge(badge)), 2000);
    }

    // Dispatch sidebar status to state so we can update the UI
    await dispatch(streamActions.setSidebarState(sidebarActive));

    // Panelists should see the Sidebar notification, they cannot see the Sidebar button
    if (!isHostUser) {
      dispatch(
        uiActions.triggerNotification(
          sidebarActive
            ? UINotificationTypes.SIDEBAR_MODE_ON
            : UINotificationTypes.SIDEBAR_MODE_OFF
        )
      );
    }
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      {
        fileInfo: `handleSideBarThunk.ts`,
        feature: `store/stream/thunks`,
      },
      `Error while handling SideBar status,
        ${error?.message}`
    );
    throw error;
  }
};
