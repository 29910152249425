import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
/* Actions */
import { getUserTypes } from '../../UsersSelectors';
import { createUserAction, getUserTypesAction, getUserOrganisationAction } from '../../UsersActions';
import { getAllOrganisationsAction } from '../../../Organisation/OrganisationActions';
/* Components */
import { Button, Fields, validators } from '../../../../../../Common/Components';
import VendorsFormPart from './Components/VendorsFormPart';
/* Helpers */
import userTypes from '../../../../../../Common/Constants/userTypes';
import UserService from '../../../../../../Common/Services/userService';
import { getcurrentUserTypes } from '../../../../HomeSelectors';
/* Styles & Images */
import { newlogo2021, users } from '../../../../../../Common/Assets/Images';
import './CreateNewUser.scss';
import HospitalsFormPart from './Components/HospitalsFormPart';
import ProctorSpecialistFormPart from './Components/ProctorSpecialistFormPart';

export class CreateNewUser extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedUserType: null,
		};
	}

	componentDidMount() {
		this.props.getUserTypes();

		if (UserService.checkIsVendorUser(this.props.currentUserTypes)) {
			this.props.getUserOrganisation(this.props.currentUserId);
		}

		if (!UserService.checkIsVendorManager(this.props.currentUserTypes[0])) {
			this.props.getAllOrganisations();
		}
	}

	onInviteUserHandler = (data) => {
		this.props.createUser(data);
	};

	selectUserType = (userType) => {
		this.setState({
			selectedUserType: userType,
		});
	};

	filteredUserTypes = () => (
		this.props.userTypes.filter((type) => {
			if (userTypes[this.props.currentUserTypes[0]].canInvite) {
				return userTypes[this.props.currentUserTypes[0]].canInvite.includes(type.name);
			} else if (userTypes[this.props.currentUserTypes[0]].refuseInvite) {
				return !userTypes[this.props.currentUserTypes[0]].refuseInvite.includes(type.name);
			}
			return this.props.userTypes;
		})
	);

	render() {
		const { selectedUserType } = this.state;

		return (
			<div className="create-new-user">
				<div className="create-new-user__form">
					<div className="create-new-user-heading">Invite new user to Avail</div>
					<form onSubmit={this.props.handleSubmit(this.onInviteUserHandler)}>
						<Fields.InputField
							labelText="First name"
							placeholder="Enter your first name"
							name="firstName"
							validate={[validators.required, validators.onlyLetters, validators.minimumLength2, validators.maximumLength48]}
						/>
						<Fields.InputField
							labelText="Last name"
							placeholder="Enter your last name"
							name="lastName"
							validate={[validators.required, validators.onlyLetters, validators.minimumLength2, validators.maximumLength48]}
						/>
						<Fields.InputField
							labelText="Email"
							placeholder="Enter your email"
							name="email"
							validate={[validators.required, validators.email, validators.minimumLength3, validators.maximumLength254]}
						/>
						{
							this.props.userTypes && (
								<Fields.SelectField
									labelText="User role"
									search
									placeholder="Select role"
									options={this.filteredUserTypes() ? this.filteredUserTypes().map(type => ({
										key: type.id,
										value: type.name,
										text: userTypes[type.name].title,
									})) : []}
									onChange={this.selectUserType}
									name="userType"
									validate={validators.required}
								/>
							)
						}
						{UserService.checkIsVendorUser(selectedUserType) &&
							<VendorsFormPart
								currentUserType={this.props.currentUserTypes[0]}
								selectedUserType={this.state.selectedUserType}
							/>
						}
						{UserService.checkIsHospitalUser(selectedUserType) &&
							<HospitalsFormPart
								currentUserType={this.props.currentUserTypes[0]}
								selectedUserType={this.state.selectedUserType}
							/>
						}
						{UserService.checkIsProctorOrSpecialist([selectedUserType]) &&
							<ProctorSpecialistFormPart
								selectedUserType={this.state.selectedUserType}
							/>
						}
						<div className="users__action-buttons">
							<Button
								type="submit"
								isFull
								disabled={this.props.isButtonClickable}
							>
								Invite
							</Button>
						</div>
					</form>
				</div>
				<div className="create-new-user__right">
					<img className="login__image" src={users} alt="loginImage" />
				</div>
			</div>
		);
	}
}

CreateNewUser.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	toggleInviteUserModal: PropTypes.func,
	userTypes: PropTypes.array,
};

const mapStateToProps = state => ({
	userTypes: getUserTypes(state),
	currentUserTypes: getcurrentUserTypes(state),
	currentUserId: state.homeReducer.currentUser.id,
	organisations: state.organisationsReducer.organisations,
	isButtonClickable: state.homeReducer.isButtonClickable,
});

const mapDispatchToProps = dispatch => ({
	getUserOrganisation: userId => dispatch(getUserOrganisationAction(userId)),
	getUserTypes: () => dispatch(getUserTypesAction()),
	getAllOrganisations: () => dispatch(getAllOrganisationsAction()),
	createUser: data => dispatch(createUserAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'createNewUserForm',
})(CreateNewUser));
