import { AVKTelestrationClearEvent } from "availkit-js/dist/Models/Events/AVKTelestrationClearEvent";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { clearCanvasThunk } from "src/domains/Beacon/store/telestration/thunks/clearCanvasThunk";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

// "Local  : just on user's machine"
export const clearLocalAnnotationsThunk = createAsyncThunk<
  void,
  // First argument to the payload creator
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("telestration/clearLocalAnnotationsThunk", (_arg, { getState, dispatch }) => {
  try {
    logger().info("Deleting telestrations");
    const availKit = AvailKitService.instance;

    const { meeting, twilio } = getState();
    const { callSid, isUserHost, joinId } = meeting;
    const { hostHasJoinedRoom, consoleHasJoinedRoom } = twilio;

    dispatch(telestrationActions.clearTelestrationDrawingState());

    // clear Easel UI
    dispatch(clearCanvasThunk());

    // Host sends clearEvent OR Participant if there is not a host available
    // clearEvent is sent to ensure Console and Presence telestrations are cleared
    if (isUserHost || (!hostHasJoinedRoom && consoleHasJoinedRoom)) {
      const teleStrationClearEvent = new AVKTelestrationClearEvent(
        callSid,
        null
      );
      teleStrationClearEvent.sender = joinId;
      // broadcasts clearEvent for Console and Participants
      availKit.eventService.broadcast(teleStrationClearEvent);

      logger().logWithFields(
        LoggerLevels.info,
        { feature: "clearLocalAnnotationsThunk" },
        `Clearing local telestration state. ${joinId} sent AVKTelestrationClearEvent.`
      );
    }
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      { feature: "clearLocalAnnotationsThunk" },
      "Error deleting telestrations",
      error?.message
    );
    throw new Error("Clearing telestration State and UI failed");
  }
});
