import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';
import ModalComponent from '../../../../Common/Components/Modal/Modal';
/* Services */
import { dateTimeService, urlService } from '../../../../Common/Services';
import { getUserEventsByUserId } from './SchedulerApiClient';
import { getMeetingToken } from "src/domains/Events/utils";
/* Components */
import { Calendar } from '../../Common';
import { getAllApprovedHospitalsForUserAction, getUserSettingsAction } from '../Users/UsersActions';
import CreateEventForm from './Components/CreateEventForm/CreateEventForm';
import EventInfoModal from './Components/EventInfoModal/EventInfoModal';
import UserAvatar from './Components/UserAvatar/UserAvatar';
import { EventDetailPopup } from "src/domains/Events/Detail/DetailPopup";
import { convertMPEventsToCalendarEvents } from './EventObjectBuilder';
/* Styles */
import './Scheduler.scss';
/* Actions */
import {
	createCalendarEventsAction,
	deleteSelectedEventAction,
	getUserMeetingTimeAction,
	getUserScheduleAction,
	updateSelectedEventAction,
	createCallEventsAction,
	deleteSelectedCallEventAction,
} from './SchedulerActions';
import { setMeetingInfoAction } from "avail-web-ui/dux/MeetingSlice";
import DomainConstants from "avail-web-ui/domains/DomainConstants";
import { withFeatureFlag } from "src/hooks/useFeatureFlag";

const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

export class Scheduler extends PureComponent {
	constructor(props) {
		super(props);
		this.calendarRef = React.createRef();
		this.state = {
			createEventModalOffsetX: null,
			eventInfoModalOffsetX: null,
			isCreateEventModalOpened: false,
			isDeleteEventModalOpened: false,
			isEventInfoModalOpened: false,
			selectedEventId: null,
			isSelectedEventCallEvent: false,
			selectedEventObj: null,
			isRecurringEventChecked: false,
			selectedDate: null,
			recurringStartDate: null,
			recurringEndDate: null,
			recurringDates: null,
			workingDaysOfWeek: null,
			recurringEventGroupId: null,
			hospitalIds: null,
			userIdFromUrl: urlService.getParams(props.location.pathname, '/scheduler/:id'),
			selectedOption: 'optionBrowser',
			userTimeZone: null,
			meetingTime: false,
			rawDateStr: null,
      userMPEvents: [],
      isEventDetailPopupOpen: false,
      currentDateRange: {
        from: '',
        to: '',
      },
		};
	}

	componentDidMount() {
		this.props.getUserSchedule(this.state.userIdFromUrl);
		this.props.getHospitals(this.state.userIdFromUrl);

		this.props.getUserSettings('usersetting');
		const watchinit = setInterval(this.watchSetting, 100);
		// eslint-disable-next-line react/no-did-mount-set-state
		this.setState({
			watchinit,
		});
	}

  componentDidUpdate(prevProps, prevState) {
    // Every time the user moves dates around on the calendar (eg tomorrow,
    // next week, next month) call the api for events/calls for that date range
    if (prevState.currentDateRange.from !== this.state.currentDateRange.from) { // Date range has changed
      this.getMeetingTime();
      this.getUserEvents();
    }
  }

  async getUserEvents() {
    const { id } = this.props.currentUser;
    const { userIdFromUrl } = this.state;
    const userId = userIdFromUrl ? userIdFromUrl : id;

    const { currentDateRange } = this.state;
    const start = currentDateRange.from;
    const end = currentDateRange.to;

    const from = `${moment(new Date(start)).format(API_DATE_FORMAT)}Z`;
    const to = `${moment(new Date(end)).format(API_DATE_FORMAT)}Z`;

    const userEvents = await getUserEventsByUserId(userId, from, to);
    const filteredEvents = userEvents.filter((event) => event.status !== "CANCELLED");

    const calendarEvents = convertMPEventsToCalendarEvents(filteredEvents);
    // This isn't going to be used anywhere else so instead of saving it to redux, just keep it here
    this.setState({ userMPEvents: calendarEvents });
  }

	onCreateEventConfirm = (formData) => {
		this.toggleUpdateEventModal();
		const { eventStartTime, eventEndTime } = formData;
		const { selectedDate, selectedEventId, eventStartTimeHours, rawDateStr, userIdFromUrl, recurringEventGroupId, isRecurringEventChecked, selectedOption, userTimeZone } = this.state;
		const startOffset = moment(eventStartTime, ['h:mmA']).diff(moment(eventStartTimeHours, ['h:mmA']));
		const endOffset = moment(eventEndTime, ['h:mmA']).diff(moment(eventStartTimeHours, ['h:mmA']));
		const newFormData = { ...formData, eventStartTime: moment(rawDateStr).add(startOffset, 'ms').format('hh:mma'), eventEndTime: moment(rawDateStr).add(endOffset, 'ms').format('hh:mma') };

		if (selectedEventId) {
			const isSingleEventSwitchingToRecurring = (isRecurringEventChecked && recurringEventGroupId === 'null');
			const shouldRecurringEventBeEdited = formData.shouldRecurringBeEdited === 'true' || isSingleEventSwitchingToRecurring;

			this.props.updateSelectedEvent(
				newFormData,
				selectedEventId,
				selectedDate,
				shouldRecurringEventBeEdited,
				userIdFromUrl,
				recurringEventGroupId,
				(selectedOption === 'optionBrowser' ? moment.tz.guess() : userTimeZone),
			);

			return this.setState({
				selectedEventId: null,
				isRecurringEventChecked: false,
			});
		}

		this.props.createCalendarEvents(
			newFormData,
			selectedDate,
			isRecurringEventChecked,
			userIdFromUrl,
			(selectedOption === 'optionBrowser' ? moment.tz.guess() : userTimeZone),
		);
	};

	onCreatePortalEventConfirm = async (formData) => {
		this.toggleUpdateEventModal();
		const { eventStartTime, eventEndTime } = formData;
		const { selectedDate, userIdFromUrl, selectedOption, userTimeZone, selectedEventId } = this.state;
		const { currentUser } = this.props;

		const startDate = moment(`${selectedDate.toDateString()}, ${eventStartTime}`, 'ddd MMM DD YYYY, hh:mma');
		const endDate = moment(`${selectedDate.toDateString()}, ${eventEndTime}`, 'ddd MMM DD YYYY, hh:mma');

		const payload = {
			...formData,
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
			hostUserId: currentUser.id,
			subject: '',
			eventStartTime: undefined,
			eventEndTime: undefined,
			id: this.state.selectedEventId || undefined,
		};

		if (selectedEventId) {
			this.props.updateSelectedCallEvent(
				payload,
				selectedDate,
				userIdFromUrl,
				(selectedOption === 'optionBrowser' ? moment.tz.guess() : userTimeZone),
			);

			return this.setState({
				selectedEventId: null,
				selectedEventObj: null,
			});
		}

		this.props.createCallEvents(
			payload,
			selectedDate,
			userIdFromUrl,
			(selectedOption === 'optionBrowser' ? moment.tz.guess() : userTimeZone),
		);
	};

	onDeleteEventConfirm = () => {
		const { selectedEventId, recurringEventGroupId, isSelectedEventCallEvent } = this.state;

		if (isSelectedEventCallEvent) {
			this.props.deleteSelectedCallEvent(selectedEventId);
		} else {
			this.props.deleteSelectedEvent(selectedEventId, recurringEventGroupId);
		}

		this.setState({
			selectedEventId: null,
			recurringEventGroupId: null,
			isRecurringEventChecked: false,
			isEventInfoModalOpened: !this.state.isEventInfoModalOpened,
		});
		this.toggleDeleteModal();
	};

	getMeetingTime = () => {
    const { currentDateRange } = this.state;
    const start = currentDateRange.from;
    const end = currentDateRange.to;

		const from = `${moment(new Date(start)).format(API_DATE_FORMAT)}Z`;
		const to = `${moment(new Date(end)).format(API_DATE_FORMAT)}Z`;

		let userId = this.state.userIdFromUrl;
		if (!userId) {
			userId = this.props.currentUser.id;
		}

		this.props.getUserMeetingTime(userId, from, to);
	}

	setRecurringDates = (eventGroupId) => {
		const timeZone = this.state.selectedOption === 'optionBrowser' ? moment.tz.guess() : this.state.userTimeZone;
		const sameGroupOfEvents = this.props.userEvents.filter(event => event.groupId === eventGroupId);
		let workingDays = {};

		sameGroupOfEvents.forEach((event) => {
			const dayName = moment(event.start).tz(timeZone).format('dddd').toUpperCase();

			if (!Object.keys(workingDays).includes(dayName)) {
				workingDays = {
					...workingDays,
					[dayName]: true,
				};
			}
		});

		return workingDays;
	}

	getWorkingDaysOfWeek = (eventGroupId) => {
		const timeZone = this.state.selectedOption === 'optionBrowser' ? moment.tz.guess() : this.state.userTimeZone;
		const sameGroupOfEvents = this.props.userEvents.filter(event => event.groupId === eventGroupId);
		const workingDaysOfWeek = [];

		sameGroupOfEvents.forEach((event) => {
			const startDay = moment(event.start).tz(timeZone).format('dddd');

			if (!workingDaysOfWeek.includes(`${startDay}, `)) {
				workingDaysOfWeek.push(`${startDay}, `);
			}
		});

		return workingDaysOfWeek;
	}

	setCreateEventModalRef = (node) => {
		this.createEventModalRef = node;
	};

	setEventInfoModalRef = (node) => {
		this.eventInfoModalRef = node;
	};

	watchSetting = () => {
		if (this.props.timezone === 'usersetting') {
			this.props.getUserSettings(this.state.userIdFromUrl);
			clearInterval(this.state.watchinit);
			const userzone = setInterval(this.watchUserTimeZone, 100);
			this.setState({
				userzone,
			});
		}
	}

	watchUserTimeZone = () => {
		if (this.state.userTimeZone == null) {
			if (this.props.timezone !== 'usersetting') {
				this.setState({
					userTimeZone: this.props.timezone,
				});
				this.props.getUserSettings();
				clearInterval(this.state.userzone);
			}
		}
	}

	toggleUpdateEventModal = () => {
		this.setState({
			isCreateEventModalOpened: !this.state.isCreateEventModalOpened,
			isRecurringEventChecked: false,
			recurringEventGroupId: null,
			selectedEventObj: null,
			isSelectedEventCallEvent: false,
		});
	};

	toggleDeleteModal = () => {
		this.setState({
			isDeleteEventModalOpened: !this.state.isDeleteEventModalOpened,
		});
	};

	toggleEventInfoModal = () => {
		this.setState({
			isEventInfoModalOpened: !this.state.isEventInfoModalOpened,
		});
	};

	calculateModalXPosition = (dateClickEvent, modalElement) => (
		dateClickEvent.x > document.body.clientWidth / 2 ?
			dateClickEvent.x - modalElement.clientWidth :
			dateClickEvent.x
	);

	handleDateClick = (selectedDate) => {
		const { date, jsEvent, dateStr } = selectedDate;
		const { selectedOption, userTimeZone } = this.state;

		const timezone = selectedOption === 'optionBrowser' ? moment.tz.guess() : userTimeZone;

		if (dateTimeService.isBefore(date, moment())) {
			return;
		}

		this.setState({
			selectedDate: date,
			recurringStartDate: date,
			recurringEndDate: moment(date).add(7, 'd').toDate(),
			eventStartTimeHours: moment(date).tz(timezone).format('hh:mma'),
			eventEndTimeHours: moment(date).tz(timezone).add(1, 'h').format('hh:mma'),
			rawDateStr: date,
			isCreateEventModalOpened: !this.state.isCreateEventModalOpened,
			selectedEventId: null,
			recurringDates: null,
			hospitalIds: null,
		}, () => {
			this.setState({
				createEventModalOffsetX: this.calculateModalXPosition(jsEvent, this.createEventModalRef.ref),
			});
		});
	};

  handleDateRangeChange = (info) => {
    this.setState({ currentDateRange: {
      from: info.view.activeStart,
      to: info.view.activeEnd
    }});
  }

	filterHospitalsAvailibility = (hospitals, hospitalIds) => (
		hospitals.filter(hospital => (
			hospitalIds.includes(hospital.id)
		))
	);

  handleCopyLinkClick = (e, selectedEvent) => {
    e.stopPropagation(); // Don't show the details popup if the user only clicked on the copy link link

    const { eventLink } = selectedEvent.extendedProps;

    // TODO: add a tooltip to let user know the link is in clipboard?
    navigator.clipboard.writeText(eventLink);
  }

	handleEventClick = (selectedEvent) => {
		const { event, jsEvent } = selectedEvent;

    if (event.extendedProps.isMpEvent) {
      // Show the details popup for the event
      this.setState({ selectedEventObj: event, isEventDetailPopupOpen: true });
      return
    }

		this.setState({
			callStatus: event.extendedProps.callStatus,
			meetingTime: event.extendedProps.meetingTime,
			endCallTime: event.extendedProps.endCallTime,
			selectedDate: event.start,
			recurringStartDate: moment(event.extendedProps.meetingTime ? event.start : event.extendedProps.recurringStartDate).tz(this.state.selectedOption === 'optionProfile' ? this.state.userTimeZone : moment.tz.guess()).toDate(),
			recurringEndDate: moment(event.extendedProps.meetingTime ? event.end : event.extendedProps.recurringEndDate).tz(this.state.selectedOption === 'optionProfile' ? this.state.userTimeZone : moment.tz.guess()).toDate(),
			eventStartTimeHours: moment(event.start).tz(this.state.selectedOption === 'optionProfile' ? this.state.userTimeZone : moment.tz.guess()).format('hh:mma'),
			eventEndTimeHours: moment(event.end).tz(this.state.selectedOption === 'optionProfile' ? this.state.userTimeZone : moment.tz.guess()).format('hh:mma'),
			rawDateStr: event.start,
			isEventInfoModalOpened: !this.state.isEventInfoModalOpened,
			selectedEventId: event.id,
			isSelectedEventCallEvent: event.extendedProps.callEvent || false,
			selectedEventObj: event,
			isRecurringEventChecked: event.extendedProps.meetingTime ? false : event.groupId !== 'null',
			recurringEventGroupId: event.groupId,
			recurringDates: this.setRecurringDates(event.groupId),
			workingDaysOfWeek: this.getWorkingDaysOfWeek(event.groupId),
			hospitalIds: event.extendedProps.hospitalIds,
			availableHospitals: event.extendedProps.meetingTime ? event.extendedProps.hospital : this.filterHospitalsAvailibility(this.props.hospitals, event.extendedProps.hospitalIds),
		}, () => {
			this.setState({
				eventInfoModalOffsetX: this.calculateModalXPosition(jsEvent, this.eventInfoModalRef.ref),
			});
		});
	};

	handleRecurringEventChecked = (isChecked) => {
		this.setState({
			isRecurringEventChecked: isChecked,
		});
	};

	switchToEditMode = () => {
		this.setState({
			isEventInfoModalOpened: !this.state.isEventInfoModalOpened,
			isCreateEventModalOpened: !this.state.isCreateEventModalOpened,
		});
	}

	handleOptionChange = (e, data) => {
		this.setState({ selectedOption: data.value });
		moment.tz.setDefault(data.value === 'optionProfile' ? this.state.userTimeZone : moment.tz.guess());
	};

	renderCreateEventModal = () => {
		const timeZone = this.state.selectedOption === 'optionBrowser' ? moment.tz.guess() : this.state.userTimeZone;
		const { selectedEventObj, isSelectedEventCallEvent } = this.state;

		return (
			<ModalComponent
				hasActionButtons={false}
				title={isSelectedEventCallEvent ? 'Event' : 'Availability'}
				dimmerEffect={null}
				onCancel={this.toggleUpdateEventModal}
				elementRef={this.setCreateEventModalRef}
				open={this.state.isCreateEventModalOpened}
				style={{
          position: 'absolute',
          left: this.state.createEventModalOffsetX,
        }}
				className="padding--none"
			>
				<CreateEventForm
					selectedDate={moment(this.state.selectedDate).tz(timeZone)}
					selectedEventId={this.state.selectedEventId}
					onCancelClick={this.toggleUpdateEventModal}
					onRecurringEventChecked={this.handleRecurringEventChecked}
					onCreateEventFormSubmit={this.onCreateEventConfirm}
					onCreatePortalEventFormSubmit={this.onCreatePortalEventConfirm}
					isRecurringEventChecked={this.state.isRecurringEventChecked}
					isEditTypeModalOpened={this.state.isEditTypeModalOpened}
					shouldRecurringBeEdited="false"
					recurringEventGroupId={this.state.recurringEventGroupId}
					hospitals={this.props.hospitals}
					initialValues={isSelectedEventCallEvent ? {
						eventStartTime: this.state.eventStartTimeHours,
						eventEndTime: this.state.eventEndTimeHours,
						hospitalId: selectedEventObj.extendedProps.hospitalIds.length ? selectedEventObj.extendedProps.hospitalIds[0] : '',
						roomId: selectedEventObj.extendedProps.roomId,
						description: selectedEventObj.extendedProps.description,
						participants: selectedEventObj.extendedProps.participants.filter(user => user.role !== 'HOST').map(user => user.email),
					} : {
						eventStartTime: this.state.eventStartTimeHours,
						eventEndTime: this.state.eventEndTimeHours,
						recurringStartDate: this.state.selectedDate,
						recurringEndDate: this.state.recurringEventGroupId !== 'null' ? this.state.recurringEndDate : this.state.selectedDate,
						isRecurringEvent: this.state.isRecurringEventChecked,
						workingDaysOfWeek: this.state.recurringDates,
						hospitalIds: this.state.hospitalIds,
					}}
					scheduleType={isSelectedEventCallEvent ? 'portal' : undefined}
				/>
			</ModalComponent>
		);
	}

	renderEventInfoModal = () => (
		<EventInfoModal
			open={this.state.isEventInfoModalOpened}
			onCancelClick={this.toggleEventInfoModal}
			elementRef={this.setEventInfoModalRef}
			onEditClick={this.switchToEditMode}
			onDeleteClick={this.toggleDeleteModal}
			selectedDate={this.state.selectedDate}
			recurringStartDate={this.state.recurringStartDate}
			recurringEndDate={this.state.recurringEndDate}
			workingDaysOfWeek={this.state.workingDaysOfWeek}
			eventStartTimeHours={this.state.eventStartTimeHours}
			eventEndTimeHours={this.state.eventEndTimeHours}
			isRecurringEventChecked={this.state.isRecurringEventChecked}
			availableHospitals={this.state.availableHospitals}
			meetingTime={this.state.meetingTime}
			isCallEvent={this.state.isSelectedEventCallEvent}
			selectedEvent={this.state.selectedEventObj}
			callStatus={this.state.callStatus}
			endCallTime={this.state.endCallTime}
			style={{
        position: 'absolute',
        left: this.state.eventInfoModalOffsetX,
      }}
		/>
	);

  onEventDetailsModalClose = () => this.setState({ isEventDetailPopupOpen: false });
  onEventDetailsJoinCall = () => {
    const event = this.state.selectedEventObj?.extendedProps;

    if (event) {
      this.props.setMeetingInfo(event);

      const meetingToken = getMeetingToken(event, this.props.currentUser.loginId);
      this.setState({ isEventDetailPopupOpen: false, selectedEventObj: null });
      window.open(`${this.props.beaconFlag}${meetingToken}`, "_blank");
    }
  }

	render() {
		const { recurringEventGroupId, userMPEvents } = this.state;
		return (
			<div className="scheduler">
				{(this.state.userIdFromUrl || this.props.currentUser) &&
					<UserAvatar userId={this.state.userIdFromUrl ? this.state.userIdFromUrl : this.props.currentUser.id} />
				}
				<div
					className={classNames({
						'scheduler__current-timezone': true,
						'scheduler__current-timezone--move-left': false,
					})}
				>
					<div className="scheduler__current-timezone-label">Timezone</div>
					<div>
						<Select
							name="selectedOption"
							options={[{
								key: 'optionBrowser',
								value: 'optionBrowser',
								text: moment.tz.guess(),
							}, {
								key: 'optionProfile',
								value: 'optionProfile',
								text: this.state.userTimeZone,
							}]}
							onChange={this.handleOptionChange}
							value={this.state.selectedOption}
						/>
					</div>
				</div>

				<Calendar
					ref={this.calendarRef}
					onDateClick={this.handleDateClick}
          onDateRangeChange={this.handleDateRangeChange}
					onEventClick={this.handleEventClick}
          onCopyEventLink={this.handleCopyLinkClick}
					events={[...this.props.userEvents, ...this.props.userCallEvents, ...this.props.meetingTime, ...userMPEvents]}
					timezone={this.state.selectedOption === 'optionProfile' ? this.state.userTimeZone : moment.tz.guess()}
				/>
				<ModalComponent
					open={this.state.isDeleteEventModalOpened}
					onConfirm={this.onDeleteEventConfirm}
					onCancel={this.toggleDeleteModal}
					confirmText="Delete"
				>
					{recurringEventGroupId
						? <div>You are about to delete recurring event series. Are you sure?</div>
						: <div>You are about to delete selected event. Are you sure?</div>
					}
				</ModalComponent>
				{this.state.selectedDate && this.renderCreateEventModal()}
				{this.renderEventInfoModal()}

        <EventDetailPopup
          open={this.state.isEventDetailPopupOpen}
          onCancel={this.onEventDetailsModalClose}
          onStart={this.onEventDetailsJoinCall}
          event={this.state.selectedEventObj?.extendedProps}
        />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	userEvents: state.schedulerReducer.userEvents,
	userCallEvents: state.schedulerReducer.userCallEvents,
	timezone: state.usersReducer.timezone,
	hospitals: state.usersReducer.approvedHospitalsForUser,
	currentUser: state.homeReducer.currentUser,
	meetingTime: state.schedulerReducer.meetingTime,
});

const mapDispatchToProps = dispatch => ({
	createCalendarEvents: (event, selectedDate, isRecurring, userIdFromUrl, timeZone) => dispatch(createCalendarEventsAction({
		event,
		selectedDate,
		isRecurring,
		userIdFromUrl,
		timeZone,
	})),
	createCallEvents: (event, selectedDate, isRecurring, userIdFromUrl, timeZone) => dispatch(createCallEventsAction({
		event,
		selectedDate,
		userIdFromUrl,
		timeZone,
	})),
	updateSelectedEvent: (event, eventId, selectedDate, isRecurring, userIdFromUrl, recurringEventGroupId, timeZone) =>
		dispatch(updateSelectedEventAction({
			event,
			eventId,
			selectedDate,
			isRecurring,
			userIdFromUrl,
			recurringEventGroupId,
			timeZone,
		})),
	updateSelectedCallEvent: (event, selectedDate, userIdFromUrl, timeZone) => dispatch(updateSelectedEventAction({
		event,
		selectedDate,
		userIdFromUrl,
		timeZone,
	})),
	deleteSelectedEvent: (eventId, recurringGroupId) => dispatch(deleteSelectedEventAction(eventId, recurringGroupId)),
	deleteSelectedCallEvent: eventId => dispatch(deleteSelectedCallEventAction(eventId)),
	getUserSchedule: userId => dispatch(getUserScheduleAction(userId)),
	getUserMeetingTime: (userId, from, to) => dispatch(getUserMeetingTimeAction(userId, from, to)),
	getUserSettings: userId => dispatch(getUserSettingsAction(userId)),
	getHospitals: userId => dispatch(getAllApprovedHospitalsForUserAction(userId)),
	setMeetingInfo: event => dispatch(setMeetingInfoAction(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withFeatureFlag(Scheduler));
