import { renderTraceIdHeader } from "src/domains/Beacon/utils/renderTraceIdHeader";
import UserSessionService from "src/services/UserSessionService";
import { ENDPOINTS } from "../constants";
import { PortalIdentity } from "../store/models";
import { ApiResponse } from "./types";

/**
 * Example use: const { data, error } = await API.GET.identity()
 */
const identity = async (
  refreshToken?: string
): Promise<ApiResponse<PortalIdentity>> => {
  try {
    const refreshTokenToUse = UserSessionService.getRefreshToken();
    const access_token = UserSessionService.getAccessToken();

    const res = await fetch(ENDPOINTS.REFRESH_TOKEN, {
      method: "POST",
      body: JSON.stringify({ token: refreshTokenToUse }),
      headers: {
        "content-type": "application/json;charset=UTF-8",
        ...renderTraceIdHeader(),
        authorization: "Bearer " + access_token
      }
    });

    if (res.ok) {
      const data: PortalIdentity = await res.json();
      return { data };
    } else {
      const error = new Error(
        `Error ${res.status}: ${res.statusText}. ` +
          `Response: ${await res.text()}`
      );
      throw error;
    }
  } catch (error) {
    return { error };
  }
};

export default identity;