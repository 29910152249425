import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Input } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
/* Actions */
import {
	updateUserProfileAction,
	getUserOrganisationAction,
	getUserSupportsOrganisationAction,
	lockAccountAction,
	unlockAccountAction,
} from '../../../../UsersActions';
import { getAllTimezonesAction } from '../../../../../AccountCreation/AccountCreationActions';
/* Components */
import { Button, Fields, Icon, validators } from '../../../../../../../../Common/Components';
import ProfileImage from '../../../../../../Common/ProfileImage/ProfileImage';
import Can from '../../../../../../../../Common/Acl/AccessControl';
/* Services & Helpers */
import {
	getAllDescendantUsersIds,
	getInitialValuesForUserProfile,
} from '../../../../UsersSelectors';
import userTypes, { roles } from '../../../../../../../../Common/Constants/userTypes';
import userService from '../../../../../../../../Common/Services/userService';
import AccessControlService from '../../../../../../../../Common/Services/accessControlService';
import GrammarService from '../../../../../../../../Common/Services/grammarService';
import { getcurrentUserTypes } from '../../../../../../HomeSelectors';
import ModalComponent from '../../../../../../../../Common/Components/Modal/Modal';
import { getAllHospitalsAction } from '../../../../../../Features/Organisation/Hospitals/HospitalsActions';

class UserInfo extends PureComponent {
	state = {
		isLockAccountModal: false,
		isUnlockAccountModal: false,
	}

	componentDidMount() {
		this.props.getAllTimezones();
		this.props.getAllHospitals();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.user !== nextProps.user) {
			if (nextProps.user && userService.checkIsProctorOrSpecialist(nextProps.user.userTypes)) {
				this.props.getUserSupportsOrganisation(this.props.userId);
			} else {
				this.props.getUserOrganisation(this.props.userId);
			}
		}
	}

	onCalendarClickHandler = () => (
		this.props.history.push({
			pathname: `/scheduler/${this.props.userId}`,
		})
	);

	onLockAccountHandler = () => {
		this.props.lockAccount(this.props.user);
		this.toggleLockModal();
	}

	onUnlockAccountHandler = () => {
		this.props.unlockAccount(this.props.user);
		this.toggleUnlockModal();
	}

	toggleUnlockModal = () => {
		this.setState({
			isUnlockAccountModal: !this.state.isUnlockAccountModal,
		});
	}

	toggleLockModal = () => {
		this.setState({
			isLockAccountModal: !this.state.isLockAccountModal,
		});
	};

	handleSubmit = (data) => {
		this.props.updateUserProfile(this.props.userId, data, {
			type: 'TIME_ZONE',
			value: data.timezone,
		});
	};

	formatVendorType = (vendorType) => {
		if (vendorType) {
			const removeUnderScore = vendorType.replace('_', ' ');
			return GrammarService.capitalFirstletter(removeUnderScore);
		}
		return null;
	};

	formatUserTypes = (types) => {
		let typesString = '';

		types.map((type) => {
			typesString = typesString.concat(`${userTypes[type].title}, `);
		});

		return typesString.substring(0, typesString.length - 2);
	};

	shouldRenderOrganization = () => {
		const { user } = this.props;
		const userType = user && user.userTypes[0];
		const canUserTypeSeeOrganization = user &&
			(userType !== roles.individualSalesRep && userType !== roles.availAdmin && !userService.checkIsProctorOrSpecialist(userType));
		return (canUserTypeSeeOrganization && this.props.userOrganisation);
	};

	validateHospital = (hospitalId) => {
		const { hospitals } = this.props;
		return hospitals && hospitals.filter(hospital => hospital.id === hospitalId).length === 1;
	}


	renderLockAccountModal = () => (
		<ModalComponent
			open={this.state.isLockAccountModal}
			onConfirm={this.onLockAccountHandler}
			onCancel={this.toggleLockModal}
			confirmText="Lock"
		>
			<span>
				You are about to lock <span className="text--bold">{this.props.user && this.props.user.email }</span>.
				Would you like to proceed with this action?
			</span>
		</ModalComponent>
	);

	renderUnlockAccountModal = () => (
		<ModalComponent
			open={this.state.isUnlockAccountModal}
			onConfirm={this.onUnlockAccountHandler}
			onCancel={this.toggleUnlockModal}
			confirmText="Unlock"
		>
			<span>
				You are about to unlock <span className="text--bold">{this.props.user && this.props.user.email }</span>.
				Would you like to proceed with this action?
			</span>
		</ModalComponent>
	);

	render() {
		return (
			<div>
				<form
					className="avail-profile__edit-form flex-direction--row justify-content--space-between"
					onSubmit={this.props.handleSubmit(this.handleSubmit)}
				>
					<div>
						{
							this.props.user && this.props.user.userTypes && (
								<ProfileImage
									size="lg"
									initials={userService.getUserInitials(this.props.user.firstName, this.props.user.lastName)}
									className={`profile-image--${userTypes[this.props.user.userTypes[0]].className} `}
								/>
							)
						}
						<Fields.InputField
							className="avail__input"
							labelText="First name"
							placeholder="Enter your first name"
							name="firstName"
							validate={[validators.required, validators.onlyLetters, validators.minimumLength2, validators.maximumLength48]}
							disabled={!AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'edit', 'userProfile', {
								currentUserId: this.props.currentUser && this.props.currentUser.id,
								userProfileId: this.props.userId,
								descendantUsersIds: this.props.descendantUsersIds,
							})}
						/>
						<Fields.InputField
							className="avail__input"
							labelText="Last name"
							placeholder="Enter your last name"
							name="lastName"
							validate={[validators.required, validators.onlyLetters, validators.minimumLength2, validators.maximumLength48]}
							disabled={!AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'edit', 'userProfile', {
								currentUserId: this.props.currentUser && this.props.currentUser.id,
								userProfileId: this.props.userId,
								descendantUsersIds: this.props.descendantUsersIds,
							})}
						/>
						{
							this.props.initialValues && this.props.initialValues.userTypes && (
								<div className="flex-direction--column avail__input-wrapper">
									<span>Type</span>
									<Input
										className="avail__input"
										name="userType"
										value={this.formatUserTypes(this.props.initialValues.userTypes)}
										disabled
									/>
								</div>
							)
						}
						<Fields.InputField
							className="avail__input"
							labelText="Email"
							placeholder="Enter your email address"
							name="email"
							type="email"
							validate={[validators.required, validators.email]}
							disabled
						/>
						<Fields.SelectField
							className="avail__input"
							labelText="Timezone"
							placeholder="Select timezone"
							name="timezone"
							options={this.props.timezones ? this.props.timezones.map(timezone => ({
								key: timezone.id,
								value: timezone.id,
								text: `${timezone.id} (${timezone.offset})`,
							})) : []}
							validate={validators.required}
							disabled={!AccessControlService.doesUserHaveAccess(this.props.currentUserTypes, 'edit', 'userProfile', {
								currentUserId: this.props.currentUser && this.props.currentUser.id,
								userProfileId: this.props.userId,
								descendantUsersIds: this.props.descendantUsersIds,
							})}
						/>
						{this.props.user && userService.checkIsProctorOrSpecialist(this.props.user.userTypes) &&
						<React.Fragment>
							{this.props.userSupportsOrganisations && this.props.userSupportsOrganisations.map((organisation) => {
								if (!this.validateHospital(organisation.id)) {
									return (
										<div className="flex-direction--column avail__input-wrapper" key={organisation.id}>
											<span>Organization ({this.formatVendorType(organisation.vendorType) || 'HCP Group'})</span>
											<Input
												className="avail__input"
												name="userOrganisation"
												value={organisation.name}
												disabled
											/>
										</div>
									);
								}
							}) }
						</React.Fragment>
						}
						{this.shouldRenderOrganization() &&
						<React.Fragment>
							{
								this.props.userOrganisation && (
									<div className="flex-direction--column avail__input-wrapper">
										<span>Organization ({this.formatVendorType(this.props.userOrganisation.vendorType) || 'Facility'})</span>
										<Input
											className="avail__input"
											name="userOrganisation"
											value={this.props.userOrganisation.name}
											disabled
										/>
									</div>
								)
							}
						</React.Fragment>
						}
						{this.props.currentUser && this.props.currentUser.userTypes[0] === 'AVAIL_ADMIN' && (
							<div>
								{this.props.user && this.props.user.accountLocked ? (
									<div className="vendor-profile__UnlockUser-button">
										<Button onClick={this.toggleUnlockModal} >Unlock Account</Button>
									</div>
								) : (
									<div className="vendor-profile__lockUser-button">
										<Button onClick={this.toggleLockModal}>Lock Account</Button>
									</div>
								)}

							</div>
						)}
					</div>
					<div className="user-profile__submit-button--fixed">
						<div className="flex-direction--row">
							<Can
								userTypes={this.props.currentUserTypes}
								action="read"
								context="calendar"
								data={{
									currentUserId: this.props.currentUser && this.props.currentUser.id,
									userProfileId: this.props.userId,
									userTypes: this.props.user && this.props.user.userTypes,
									descendantUsersIds: this.props.descendantUsersIds,
								}}
							>
								<Button
									className="user-profile__calendar-button padding--none button-with-icon"
									onClick={this.onCalendarClickHandler}
								>
									<Icon className="icon-calendar-outline user-profile__calendar-button-icon" />
									<span className="user-profile__calendar-button-title">View calendar</span>
								</Button>
							</Can>
							<Can
								userTypes={this.props.currentUserTypes}
								action="edit"
								context="userProfile"
								data={{
									currentUserId: this.props.currentUser && this.props.currentUser.id,
									userProfileId: this.props.userId,
									descendantUsersIds: this.props.descendantUsersIds,
								}}
							>
								<Button className="button-with-icon align-self--flex-start padding--none" type="submit">
									<Icon className="icon-save icon--orange entity-profile__submit-icon" />
									<span className="entity-profile__submit-title">Save changes</span>
								</Button>
							</Can>
						</div>
					</div>
				</form>
				{this.renderLockAccountModal()}
				{this.renderUnlockAccountModal()}
			</div>
		);
	}
}

UserInfo.propTypes = {
	user: PropTypes.object,
};

const mapStateToProps = state => ({
	initialValues: getInitialValuesForUserProfile(state),
	userOrganisation: state.usersReducer.userOrganisation,
	userSupportsOrganisations: state.usersReducer.userSupportsOrganisations,
	timezones: state.accountCreationReducer.timezones,
	currentUser: state.homeReducer.currentUser,
	descendantUsersIds: getAllDescendantUsersIds(state),
	currentUserTypes: getcurrentUserTypes(state),
	user: state.usersReducer.user,
	hospitals: state.hospitalsReducer.hospitals,
});

const mapDispatchToProps = dispatch => ({
	updateUserProfile: (userId, userData, userSettings) => dispatch(updateUserProfileAction(userId, userData, userSettings)),
	getAllTimezones: () => dispatch(getAllTimezonesAction()),
	getUserOrganisation: id => dispatch(getUserOrganisationAction(id)),
	getUserSupportsOrganisation: id => dispatch(getUserSupportsOrganisationAction(id)),
	lockAccount: payload => dispatch(lockAccountAction(payload)),
	unlockAccount: payload => dispatch(unlockAccountAction(payload)),
	getAllHospitals: params => dispatch(getAllHospitalsAction(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'userProfileGeneralForm',
	enableReinitialize: true,
})(UserInfo)));
