import { Room } from "twilio-video";

import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting";
import { setAccessTokenReconnectionThunk } from "src/domains/Beacon/store/meeting/thunks/setAccessTokenReconnectionThunk";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import {
  AVAIL_ACCESS_TOKEN_TIME,
  TWILIO_REFRESH_TOKEN_TIME,
} from "src/domains/Beacon/store/twilio/constants";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { setTwilioTokenReconnectionThunk } from "src/domains/Beacon/store/twilio/thunks/setTwilioTokenReconnectionThunk";
import { selectCallStep } from "src/domains/Beacon/store/ui/selectors";
import { CallSteps } from "src/domains/Beacon/store/ui/types";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { logger } from "src/logging/logger";

export const useStartRefreshTokensTimers = () => {
  const callStep = useAppSelector(selectCallStep);
  const { room: twilioRoom } = useAppSelector(selectTwilioState);
  const { offerDeviceSelection } = useFeatureFlags();
  const offerDeviceSelectionRef = useRef<boolean>(offerDeviceSelection);
  const callStepRef = useRef<CallSteps>(callStep);
  const twilioRoomRef = useRef<Room>(twilioRoom);
  const dispatch = useDispatch();

  // Listeners cannot read React state, thus, must check when values change to use them
  callStepRef.current = useMemo(() => callStep, [callStep]);
  twilioRoomRef.current = useMemo(() => twilioRoom, [twilioRoom]);
  offerDeviceSelectionRef.current = useMemo(() => offerDeviceSelection, [
    offerDeviceSelection,
  ]);

  const startAccessTokenRefreshTimer = () => {
    let timeoutLimit = AVAIL_ACCESS_TOKEN_TIME;
    logger().info(
      `Timed Avail access token reconnection started, scheduled in ${timeoutLimit}mins`
    );

    const intervalCode = setInterval(async () => {
      timeoutLimit--;
      if (timeoutLimit % 2 === 0) {
        logger().verbose(
          `Avail access token reconnection time remaining: ${timeoutLimit}mins`
        );
      } else if (timeoutLimit < 5) {
        logger().warn(
          `Avail access token refresh imminent ( starts in ${timeoutLimit}mins )`
        );
      }

      // When minutes run out, must clear the current interval number
      if (timeoutLimit === 0) {
        if (
          callStepRef.current === CallSteps.IN_CALL &&
          twilioRoomRef.current
        ) {
          logger().info("Access Token callStep", callStepRef.current);
          await dispatch(setAccessTokenReconnectionThunk({})); // Getting new Access Token
        }

        if (intervalCode) {
          logger().warn("Clearing Access Token timer");
          clearInterval(intervalCode);
        }
      }
    }, 60 * 1000); // Interval set 1min

    // Must store the interval key in order to stop it if user leaves the call
    dispatch(meetingActions.setAccessTokenIntervalKey(intervalCode));
  };

  const startTwilioTokenRefreshTimer = () => {
    let timeoutLimit = TWILIO_REFRESH_TOKEN_TIME;
    logger().info(
      `Timed Twilio token reconnection started, scheduled in ${timeoutLimit}mins`
    );

    const intervalCode = setInterval(async () => {
      timeoutLimit--;
      if (timeoutLimit % 2 === 0) {
        logger().verbose(
          `Twilio token reconnection time remaining: ${timeoutLimit}mins`
        );
      } else if (timeoutLimit < 5) {
        logger().warn(
          `Twilio token refresh imminent ( starts in ${timeoutLimit}mins )`
        );
      }

      // When minutes run out, we restart both timers, reconnect to Twilio
      // and clear the current interval number
      if (timeoutLimit === 0) {
        if (
          callStepRef.current === CallSteps.IN_CALL &&
          twilioRoomRef.current
        ) {
          logger().info("Twilio Token callStep", callStepRef.current);
          startAccessTokenRefreshTimer(); // Restarting Access token timer
          startTwilioTokenRefreshTimer(); // Restarting Twilio token timer
          await dispatch(
            setTwilioTokenReconnectionThunk({
              offerDeviceSelection: offerDeviceSelectionRef.current,
            })
          ); // Twilio token reconnection
        }

        if (intervalCode) {
          logger().warn("Clearing Twilio Token timer");
          clearInterval(intervalCode);
        }
      }
    }, 60 * 1000); // Interval set 1min

    // Must store the interval key in order to stop it if user leaves the call
    dispatch(twilioActions.setTwilioTokenIntervalKey(intervalCode));
  };

  // Timers started when user first joins
  useEffect(() => {
    startAccessTokenRefreshTimer();
    startTwilioTokenRefreshTimer();
  }, []);
};
