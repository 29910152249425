import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleLayoutChangeThunk } from "src/domains/Beacon/store/stream/thunks/handleLayoutChangeThunk";
import {
  LayoutFrameNames,
  LayoutFrames,
} from "src/domains/Beacon/store/stream/types";
import { LayoutTypes } from "src/domains/Beacon/store/stream/types";
import { logger } from "src/logging/logger";

// expands fullscreen view, default is to expand left side of TwoView
export const expandFullScreenThunk = createAsyncThunk<
  void,
  {
    externalInputsImageResizing: boolean;
    // TODO: extend as we increase Layouts to include 2X2
    side: LayoutFrameNames;
    newLayoutFrames?: LayoutFrames;
  },
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "stream/expandFullScreenThunk",
  (
    { externalInputsImageResizing, side, newLayoutFrames },
    { getState, dispatch }
  ) => {
    const {
      stream: { layoutFrames },
    } = getState();
    logger().info(`Expanding ${side} to fullscreen`);

    const fullScreenLayoutFrames = {
      ...layoutFrames,
      [side]: {
        ...layoutFrames[side],
        isFullScreen: !layoutFrames[side].isFullScreen,
      },
    } as LayoutFrames;

    dispatch(streamActions.setLayoutType(LayoutTypes.FULLSCREEN));
    dispatch(
      handleLayoutChangeThunk({
        externalInputsImageResizing,
        newLayoutFrames: newLayoutFrames ?? fullScreenLayoutFrames,
      })
    );
  }
);
