import {
  BarDimensions,
  barColors,
  barsDimensions,
} from "src/components/NetworkStatus/constants";

interface NetworkIconProps {
  value: number;
}

interface RectProps {
  barDimensions: BarDimensions;
  networkStrength: number;
  barNumber: number;
  "data-test-id": string;
}

// individual bar in the svg icon
const Rect = ({
  barDimensions,
  networkStrength,
  barNumber,
  "data-test-id": dataTestId,
}: RectProps) => {
  const inactiveBar = barNumber > networkStrength;
  return (
    <rect
      x={barDimensions.x.toString()}
      y={barDimensions.y.toString()}
      width="3"
      height={barDimensions.height.toString()}
      fill={inactiveBar ? barColors[0] : barColors[networkStrength]}
      // opacity applied to inactive bars when networkStrength is not 0
      opacity={networkStrength !== 0 && inactiveBar ? "0.1" : null}
      data-test-id={dataTestId}
    />
  );
};

export const NetworkIcon = ({ value }: NetworkIconProps) => {
  const dataTestId = "network-status";
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id={dataTestId}
    >
      <mask
        id="mask0_1828_5599"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_1828_5599)">
        {barsDimensions.map((bar, i) => {
          return (
            <Rect
              barDimensions={bar}
              networkStrength={value}
              barNumber={i + 1}
              key={`networkIconBar${i + 1}`}
              data-test-id={`${dataTestId}-${i + 1}`}
            />
          );
        })}
      </g>
    </svg>
  );
};
