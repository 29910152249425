import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";
import { NAcknowledgement } from "availkit-js/dist/Models/Events/NAcknowledgement";

import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { logger, LoggerLevels } from "src/logging/logger";

export const onReceiveAcknowledgementThunk = (
  acknowledgementEvent: NAcknowledgement
) => (dispatch, getState) => {
  const {
    telestration: { telestrationEventsMap },
  } = getState();
  try {
    if (telestrationEventsMap?.size === 0) {
      return;
    }
    const referencedEventId: string = acknowledgementEvent.referencedEventIdentifier
      .toString()
      .toLowerCase();

    const telestrationIds = Array.from(telestrationEventsMap.keys());
    if (telestrationIds && telestrationIds.length) {
      telestrationIds.forEach((telestrationId: string) => {
        const telestrationEvents: AVKAnnotationEvent[] = telestrationEventsMap.get(
          telestrationId
        );
        let eventIndex = -1;
        if (telestrationEvents) {
          telestrationEvents.forEach((telestrationEvent, index) => {
            if (telestrationEvent?.uniqueIdentifier === referencedEventId) {
              eventIndex = index;
              // line below is present in Canvas, but results in the commented error
              // Retained in case issues arise
              // break;
            }
          });
        }

        if (eventIndex !== -1) {
          logger().logWithFields(
            LoggerLevels.debug,
            {
              feature: "Telestration",
              fileInfo: "onReceiveAcknowledgementThunk",
            },
            telestrationEvents[eventIndex].telestrationId +
              " : Flushing Sequence: " +
              (telestrationEvents[eventIndex].sequence + 1)
          );
          telestrationEvents.splice(eventIndex, 1);
        }
        // updating mapped state
        const telestrationEventsMapCopy: Map<
          string | null,
          AVKAnnotationEvent[]
        > = new Map(telestrationEventsMap);
        telestrationEventsMapCopy.set(telestrationId, telestrationEvents);
        dispatch(
          telestrationActions.setTelestrationEventsMap(
            telestrationEventsMapCopy
          )
        );
        return;
      });
    }
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      {
        feature: "Telestration",
        fileInfo: "onReceiveAcknowledgementThunk",
      },
      "Error occurred while receiving Acknowledgement event",
      error?.message
    );
  }
};
