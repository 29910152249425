import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class NoSearchResult extends PureComponent {
	render() {
		return (
			<div className="align--center justify-content--center flex--one">
				No items available. Please change your search parameters
			</div>
		);
	}
}

NoSearchResult.propTypes = {};

export default NoSearchResult;
