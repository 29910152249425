import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";
import { AVKExternalInput } from "availkit-js/dist/Models/AVKExternalInput";

import { LayoutFrames } from "src/domains/Beacon/store/stream/types";
import { getCameraIdentifier } from "src/domains/Beacon/utils/availKit";
import {
  findFullScreenFrameName,
  isFullscreenLayout,
} from "src/domains/Beacon/utils/layouts";
import { createObjectClone } from "src/domains/Beacon/utils/objects";

// Checks if the preset name introduced is unique inside the presets names array
export const isUniquePresetName = (
  presetsNames: string[],
  presetName: string
) =>
  // .replace() allows "one" and "one  " to be created
  !presetsNames.includes(presetName.toLowerCase().trim()) ||
  "This name already exists.";

// Returns the preset's name in order to later validates if it's duplicated
// when creating a new preset
export const getParsedPresetName = (name: string) =>
  name.toLowerCase().replace(" ", "");

// Must return the AVK cameras in the correct order, the preset must store, in an array,
// the left camera in the position 0 and the right one in position 1,
// otherwise, when selecting a preset, cameras will be displayed incorrectly
export const getPresetCamerasSnapshot = (
  cameras: (AVKCamera | AVKExternalInput)[],
  layoutFrames: LayoutFrames
): (AVKCamera | AVKExternalInput)[] => {
  if (isFullscreenLayout(layoutFrames)) {
    // fullscreen layouts only send the one camera
    const fullScreenKey = findFullScreenFrameName(layoutFrames);
    const fullScreenCamera = cameras.find(
      (camera) =>
        getCameraIdentifier(camera) === layoutFrames[fullScreenKey].cameraId
    );
    return [createObjectClone(fullScreenCamera)];
  } else {
    const leftTopCameraId = layoutFrames.leftTop.cameraId;
    const rightTopCameraId = layoutFrames.rightTop.cameraId;
    const leftBottomCameraId = layoutFrames.leftBottom.cameraId;
    const rightBottomCameraId = layoutFrames.rightBottom.cameraId;

    const leftTopCamera = cameras.find(
      (camera) => getCameraIdentifier(camera) === leftTopCameraId
    );
    const rightTopCamera = cameras.find(
      (camera) => getCameraIdentifier(camera) === rightTopCameraId
    );
    const leftBottomCamera = cameras.find(
      (camera) => getCameraIdentifier(camera) === leftBottomCameraId
    );
    const rightBottomCamera = cameras.find(
      (camera) => getCameraIdentifier(camera) === rightBottomCameraId
    );

    // Must return the left and right cameras in position 0 and 1 respectively
    return [
      createObjectClone(leftTopCamera),
      createObjectClone(rightTopCamera),
      createObjectClone(leftBottomCamera),
      createObjectClone(rightBottomCamera),
    ];
  }
};
