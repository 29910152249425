import { useQuery } from "react-query";

import { FetchError } from "src/services/ApiClient/ApiQuery";
import {
  BusinessUnitSettingsResponse,
  getOrganizationSettings,
} from "src/services/ApiClient/businessUnit";

const KEY = "organizations";

export function useGetOrganizationSettings(orgId: string, queryKeys?: any) {
  return useQuery<BusinessUnitSettingsResponse, FetchError>(
    [`${KEY}-${orgId}`, queryKeys],
    () => {
      return getOrganizationSettings(orgId);
    }
  );
}
