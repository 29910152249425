import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography } from "@mui/material";

import { Button } from "src/components/legacy/Button";
import { formatPhoneNumber } from "src/domains/Users/components/UserSecurity/helper";
import { DeleteNumberModalProps } from "src/domains/Users/components/UserSecurity/types";

import ModalComponent from "avail-web-application/Common/Components/Modal/Modal";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

import styles from "./styles.scss";

export const DeleteNumberModal = ({
  openDeletePhoneModal,
  phoneNumber,
  onClose,
}: DeleteNumberModalProps) => (
  <ModalComponent open={openDeletePhoneModal} hasActionButtons={false}>
    <div className={styles.deletePhoneNumberModal}>
      <Typography variant="body1" align="center" gutterBottom paragraph>
        {DomainConstants.label.mfaYourPhoneNumber}
      </Typography>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        paragraph
        sx={{ fontWeight: "medium" }}
      >
        {formatPhoneNumber(phoneNumber)}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        paragraph
        gutterBottom
        sx={{ mb: 4 }}
      >
        {DomainConstants.label.mfaPhoneDeleted}
      </Typography>
      <CheckCircleIcon
        sx={{
          color: "#3ca65e",
          fontSize: "75px",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
        }}
        color="primary"
        fontSize="large"
      />
      <Typography variant="h6" component="div" align="center" sx={{ mt: 4 }}>
        <Button disableRipple variant="text" onClick={onClose}>
          {DomainConstants.label.mfaClose}
        </Button>
      </Typography>
    </div>
  </ModalComponent>
);
