import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { urlService } from '../../../../../../../Common/Services';
import InvoiceHistoryTable from './Components/InvoiceHistoryTable/InvoiceHistoryTable';
import { getInvoicesByVendorIdAction } from '../../BillingActions';
import BackButton from '../../../../../../../Common/Components/BackButton/BackButton';
import './InvoiceHistory.scss';

class InvoiceHistory extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			userIdFromUrl: urlService.getParams(props.location.pathname, '/invoices/:id'),
		};
	}

	componentDidMount() {
		this.props.getInvoices(this.state.userIdFromUrl);
	}
	render() {
		return (
			<div className="height--full invoice-container">
				<div className="invoice-history__back"><BackButton /></div>
				{this.props.invoices.length ? (
					<InvoiceHistoryTable invoices={this.props.invoices} 	headerTitle="Invoice History" />) :
					(<div>Business unit doesn&apos;t have any invoices.</div>)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	invoices: state.billingReducer.invoices,
});

const mapDispatchToProps = dispatch => ({
	getInvoices: vendorId => dispatch(getInvoicesByVendorIdAction(vendorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceHistory);
