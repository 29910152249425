import { ORGANISATION } from './OrganisationActionTypes';

const initialState = {
	organisations: null,
	states: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ORGANISATION.SET_ALL_ORGANISATIONS:
			return {
				...state,
				organisations: action.payload,
			};
		case ORGANISATION.SET_ALL_STATES:
			return {
				...state,
				states: action.payload,
			};
		default:
			return state;
	}
}
