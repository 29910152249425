import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import Can from '../../../../../../../../../Common/Acl/AccessControl';
import AccessControlService from '../../../../../../../../../Common/Services/accessControlService';
import { getcurrentUserTypes } from '../../../../../../../HomeSelectors';
import { getAllTherapiesAction } from '../../../../../Hospitals/Features/DepartmentProfile/DepartmentsActions';

import {
	getVendorTherapiesAction,
	assignVendorProceduresAction,
	getDistrictAndBUTherapiesAction,
} from '../../../../VendorsActions';
import AccordionComponent from '../../../../../../../Common/AccordionComponent/AccordionComponent';
import { Button, Icon } from '../../../../../../../../../Common/Components';
import { getInitialValuesForBusinessUnitTherapies } from '../../../../VendorsSelectors';

class TherapiesAndProcedures extends PureComponent {
	componentDidMount() {
		if (this.props.districtId) {
			this.props.getDistrictAndBUTherapies(this.props.districtId, this.props.vendorId);
		} else {
			this.props.getVendorTherapies(this.props.vendorId);
			this.props.getAllTherapies();
		}
	}

	onSelectAllHandler = (e, id) => {
		this.props.therapies.forEach((therapy) => {
			if (therapy.id === id) {
				therapy.procedures.forEach((procedure) => {
					this.props.change(`procedures.${procedure.id}`, e.target.checked);
				});
			}
		});
	};

	handleSubmit = (data) => {
		const procedureIds = this.formatProcedures(data.procedures);
		const id = this.props.districtId || this.props.vendorId;
		this.props.assignVendorProcedures(id, procedureIds);
	};

	formatProcedures = procedures => ({
		'ids': procedures ? Object.keys(procedures).filter((key) => {
			if (procedures[key]) {
				return key;
			}
		}) : [],
	});

  // Used for READ mode to ONLY display selected therapies and procedures
  getSelectedTherapiesAndProcedures = (
    allTherapiesAndProcedures, 
    selectedTherapies, 
    selectedProcedures
  ) => {
    return allTherapiesAndProcedures
      .filter((therapy) => !!selectedTherapies[therapy.id])
      .map((therapy) => {
        const updatedTherapies = therapy.procedures.filter(({id}) => !!selectedProcedures[id]);
        return {
          ...therapy, 
          procedures: updatedTherapies
        }
      });
  }

	render() {
    const { therapies, initialValues } = this.props;
    if (!therapies || !initialValues) return null;
		return (
			<div className="therapies-container">
				<form className="vendor-therapies-wrapper" onSubmit={this.props.handleSubmit(this.handleSubmit)}>
					<Can userTypes={this.props.currentUserTypes} action="read" context="vendorProceduresRead">
						<AccordionComponent
							procedureName="procedures"
							data={this.getSelectedTherapiesAndProcedures(therapies, initialValues.therapies, initialValues.procedures)}
							onSelectAllHandler={this.onSelectAllHandler}
							initialValues={initialValues}
							selectedValues={this.props.vendorProfileTherapiesForm}
							district={this.props.districtId ? true : false}
							disabled={this.props.districtId ? true : false}
							readOnly={true}
						/>
					</Can>
					<Can userTypes={this.props.currentUserTypes} action="edit" context="vendorProcedures">
						<AccordionComponent
							procedureName="procedures"
							data={this.props.therapies}
							onSelectAllHandler={this.onSelectAllHandler}
							initialValues={this.props.initialValues}
							selectedValues={this.props.vendorProfileTherapiesForm}
							district={this.props.districtId ? true : false}
							disabled={this.props.districtId ? true : false}
						/>
						{!!!this.props.districtId && (
							<Button
								className="button-with-icon align-self--flex-start therapies__submit-button padding--none"
								type="submit"
							>
								<Icon className="icon-save icon--orange entity-profile__submit-icon" />
								<span className="entity-profile__submit-title">Save Changes</span>
							</Button>
						)}
					</Can>
				</form>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	initialValues: getInitialValuesForBusinessUnitTherapies(state),
	therapies: state.departmentReducer.therapies,
	vendorProfileTherapiesForm: getFormValues('vendorProfileTherapiesForm')(state),
	currentUserTypes: getcurrentUserTypes(state),
});

const mapDispatchToProps = dispatch => ({
	getVendorTherapies: vendorId => dispatch(getVendorTherapiesAction(vendorId)),
	assignVendorProcedures: (vendorId, procedures) => dispatch(assignVendorProceduresAction(vendorId, procedures)),
	getAllTherapies: () => dispatch(getAllTherapiesAction()),
	getDistrictAndBUTherapies: (districtId, vendorId) => dispatch(getDistrictAndBUTherapiesAction(districtId, vendorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'vendorProfileTherapiesForm',
	enableReinitialize: true,
})(TherapiesAndProcedures));
