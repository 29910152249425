import { AppDispatch } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { logger, LoggerLevels } from "src/logging/logger";
import { getLocalMediaDevicesInfo } from "src/services/ApiClient/stream";

const loggerFields = {
  feature: "Media Devices",
  fileInfo: "stream/thunks/getLocalMedia.tsx",
};

// Automatically generates pending, fulfilled and rejected action types
// (see `createAsyncThunk` docs: https://redux-toolkit.js.org/api/createAsyncThunk)
export const getLocalMediaThunk = () => async (dispatch: AppDispatch) => {
  try {
    logger().logWithFields(
      LoggerLevels.info,
      loggerFields,
      "Getting local media devices permissions"
    );

    const localMediaDevices = await getLocalMediaDevicesInfo();
    dispatch(streamActions.setLocalMedia(localMediaDevices));
  } catch (error: any) {
    /** Permissions have been denied by browser, we cannot ask for permissions
     * again. When we set setLocalMediaError, it opens a warning screen for the
     * user to let them know they cannot proceed without allowing permissions.
     */
    dispatch(streamActions.setLocalMediaError(error.message));
    logger().logWithFields(LoggerLevels.warn, loggerFields, error.message);
    // Since this thunk is created using `createAsyncThunk`, throwing an
    // error with this message sends this message specifically down to
    // the store for it to be processed
    throw error;
  }
};
