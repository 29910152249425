import { AvailKitActionKeys, AvailKitActionType } from "./actionTypes";
import { NConfig } from "availkit-js";

export const initializeAvailKit = (
  config: NConfig,
  presenceUUID: string
): AvailKitActionType => ({
  type: AvailKitActionKeys.INITIALIZE_AVAIL_KIT,
  config,
  presenceUUID
});
