import clsx from "clsx";

import {
  StandardTextFieldProps,
  TextField as MUITextField,
} from "@mui/material";

import styles from "./styles.scss";

interface IProps extends StandardTextFieldProps {
  name: string;
  label: string;
  className?: string;
  theme?: "light" | "dark";
  inputClass?: string;
  "data-test-id"?: string;
  required?: boolean;
  error?: boolean;
  maxLength?: number;
}

export const TextField = ({
  name,
  label,
  className,
  theme = "dark",
  inputClass,
  "data-test-id": dataTestId = "textfield",
  required = false,
  error = false,
  maxLength,
  ...props
}: IProps) => {
  return (
    <MUITextField
      name={name}
      label={label}
      className={clsx(styles.formControl, className, {
        [styles.notRequired]: !required,
        [styles.noError]: !error,
      })}
      inputProps={{
        className: clsx(styles.input, styles[theme], inputClass),
        "data-test-id": `${dataTestId}-input`,
        maxLength,
      }}
      data-test-id={dataTestId}
      error={error}
      {...props}
    />
  );
};
