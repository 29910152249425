import { Dispatch } from "redux";
import React from "react";
import { logger, LoggerLevels } from "src/logging/logger";
import { timeoutCallback } from "src/utils/timeout";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteVideoTrack,
  Track
} from "twilio-video";

export interface MediaTracProps {
  track:
    | LocalAudioTrack
    | LocalVideoTrack
    | RemoteAudioTrack
    | RemoteVideoTrack
    | null; // For tests => can't mock Video/Audio tracks on non-browser environment
  kind: Track.Kind;
  refreshFrames?: () => void;
}

export interface MediaTrackState {}

class MediaTrack extends React.Component<MediaTracProps, MediaTrackState> {
  mediaRef: any = React.createRef<HTMLMediaElement>();
  componentDidMount() {
    /**
     * The attach method returns <video /> or <audio /> with a srcObject,
     * which JSX doesn't recognize. Instead, add it manually on the ref.
     */
    if (this.mediaRef.current && this.props.track) {
      this.mediaRef.current.srcObject = this.props.track.attach().srcObject;
      if(this.props.track.kind === "video" && this.props.track.isStarted === false){
        logger().logWithFields(LoggerLevels.info, {fileInfo: "MediaTrack.tsx"}, `Video track ${this.props.track.name} has not started.`)
        /**
         * If we get here, it means that Console has joined the room and we are
         * subscribed to a track that hasn't started yet.
         * refreshFramesSaga will wait a given amount of time
         * to allow the track to start on its own before refreshing the call
         */
        const refreshFramesCallback = () => {
          if(this.props.track.isStarted === false){
            logger().logWithFields(LoggerLevels.info, {fileInfo: "MediaTrack.tsx"}, `Video track ${this.props.track.name} has not started for five seconds. Refreshing frames.`)
          this.props.refreshFrames()
        }
        }
        // if video track not started, wait 5 seconds before refreshFramesSaga
        timeoutCallback(refreshFramesCallback, 5 * 1000)
      }
    }
  }

  renderAudio = (kind: Track.Kind) => {
    if (kind === "audio") {
      return (
        <audio ref={this.mediaRef} autoPlay={true} data-testid="audio-tag" />
      );
    } else {
      return null;
    }
  };

  renderVideo = (kind: Track.Kind) => {
    if (kind === "video") {
      return (
        <video ref={this.mediaRef} autoPlay={true} data-testid="video-tag" />
      );
    } else {
      return null;
    }
  };

  render() {
    const { kind } = this.props;

    return (
      <>
        {this.renderAudio(kind)}
        {this.renderVideo(kind)}
      </>
    );
  }
}

export default MediaTrack;
