import { ENABLE_RECAPTCHA } from "src/constants";

import { notificationService } from "avail-web-application/Common/Services";

export function createCaptchaHeaders({ captchaToken, captchaVersion }) {
  return ENABLE_RECAPTCHA
    ? {
        "X-AVAIL-CAPTCHA-TOKEN": captchaToken,
        "X-AVAIL-CAPTCHA-VERSION": captchaVersion,
      }
    : {};
}

const primaryCaptchaVersion = "V3";

// Make sure grecaptcha is available before executing callback
// Pass callback the reCaptcha token
// TODO figure out types for action/callback
export function reCaptchaWrapper(action, callback) {
  const { grecaptcha } = window;
  const { execute, ready } = grecaptcha;
  ready(async () => {
    try {
      const token = await execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, {
        action,
      });
      callback(primaryCaptchaVersion, token);
    } catch (e) {
      notificationService.error(
        "Error while attempting to execute captcha: ",
        e
      );
      // eslint-disable-next-line no-console
      console.error(e);
    }
  });
}

function updateVisibility(visibilityStyle: "hidden" | "visible") {
  window.grecaptcha.ready(() => {
    // There will likely only be one badge but set all styles just in case
    const recaptchaBadges = document.getElementsByClassName("grecaptcha-badge");

    if (recaptchaBadges.length > 0) {
      // Note HTMLCollections cannot be iterated directly that's why the use
      // of Array.prototype is used here
      Array.prototype.forEach.call(recaptchaBadges, (badge) => {
        badge.style.visibility = visibilityStyle;
      });
    }
  });
}

export function hideReCaptcha() {
  updateVisibility("hidden");
}

export function showReCaptcha() {
  updateVisibility("visible");
}
