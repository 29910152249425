// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_modal_QIhTk8{padding:32px}.styles_modal_QIhTk8 .styles_leave-button_Ef-ZVK{margin:12px;width:100%}.styles_modal-body_QbqQa5{min-height:50px}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/LastParticipantRemainingModal/styles.scss"],"names":[],"mappings":"AACA,qBACE,YAAA,CAEA,iDAEE,WAAA,CAEA,UAAA,CAKJ,0BACE,eAAA","sourcesContent":["\n.modal {\n  padding: 32px;\n\n  .leave-button {\n  \n    margin: 12px;\n  \n    width: 100%;\n\n  }\n}\n\n.modal-body {\n  min-height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "styles_modal_QIhTk8",
	"leave-button": "styles_leave-button_Ef-ZVK",
	"leaveButton": "styles_leave-button_Ef-ZVK",
	"modal-body": "styles_modal-body_QbqQa5",
	"modalBody": "styles_modal-body_QbqQa5"
};
export default ___CSS_LOADER_EXPORT___;
