import { IndicatorProps } from "src/components/Indicator/Indicator";
import { LayoutFrameNames } from "src/domains/Beacon/store/stream/types";

export interface IPipState {
  collapsed: boolean;
  showVideo: boolean;
}

export interface IMicrophoneState {
  muted: boolean;
}

export interface UIState {
  sidePanel: UISidePanelState;
  isUiHidden: boolean;
  toolMode: UIToolMode | null; // determines if user is drawing (telestration) or using the pointer. Has to be one or the other
  // UI state around joining/leaving calls
  callState: {
    callStep: CallSteps;
    openLeaveCallModal: boolean;
    openNoNetworkConnectionModal: boolean;
  };
  inputSelection: UIInputSelection; // determines which input is shown on which panel
  pip: IPipState;
  notifications: UINotification[];
  isNotificationLimited: LimitedNotifications;
  microphone: IMicrophoneState;
  isTelestrationPaletteHidden: boolean;
  indicator: UIIndicator;
  badges: UIBadges;
  openMinimumBrowserSizeModal: boolean;
  lastParticipantModalOpen: boolean;
  // once the modal is open, the user has a few minutes to say that they want to stay,
  // otherwise we force them to leave the call at the end of the timer
  lastParticipantTimerId: number | null;
  localNetworkDisconnectedTimerId: number | null;
  leaveCallErrorMessage: string | null;
  hasPoorNetworkModalBeenShown: boolean;
}

export interface UISidePanelState {
  open: boolean;
  content: UISidePanelContentOptions; // determines what the sidePanel is gonna show

  // parentContent is used as to define what the parent of the current content is if it needs one
  // for example Settings would be the parentContent when the content would be Audio Settings
  parentContent: UISidePanelContentOptions | null;

  // various state necessary for different side panel features goes below

  layouts: {
    frameSelected: LayoutFrameNames;
  }; // current view, map of video section to input

  // Indicator when the SidePanel animation ends by opening or closing it
  // true - transition just finished,
  // false - transition in process,
  // null - resting state between transitions to prevent useEffects from infinite loops
  transitionEnded: boolean | null;
  isFully: "open" | "close"; // Must need it to calculate video dimensions with sidePanel fully open/close
}

export enum CallSteps {
  PRE_CALL = "pre-call",
  IN_CALL = "in-call",
  // the user can choose to leave the call, thats what we use the below state for
  LEAVE_CALL = "leave-call",
  // in the future the user will also be able to end the call for everyone, add that step when necessary
}

// This is set up to have content+subcontent 'tabs' individually and conditionally render
// TODO: Might be an easier way to handle this but I thought since there aren't many options
// anyway, it could be done like this
export enum UISidePanelContentOptions {
  PARTICIPANTS = "participants",
  PRESETS = "presets",
  LAYOUTS = "layouts",
  SETTINGS = "settings", // settings content just show a menu of sub sections the user can click on
  // below values are sub sections of settings, they exist at the top level her because
  // we may have to allow the user to jump straight into a sub setting menu from a button somewhere
  AUDIO_SETTINGS = "audio-settings",
  VIDEO_SETTINGS = "video-settings",
  NETWORK_STATUS_SETTINGS = "network-settings",
  CONSOLE_SETTINGS = "console-settings",
  SEND_REPORT = "send-report",
  LAYOUTS_PRESETS = "layouts-presets",
}

export enum UIToolMode {
  DRAW = "draw",
  LASER = "laser",
}

export interface UIInputSelection {
  leftPanel: string; // TODO: should these be strings? maybe be their own object or just id each input with strings
  rightPanel: string;
  fullscreenPanel: string;
}

// We want to define all the possible notification types to ensure the icon + text appears automatically for hardcoded types
// For example POOR_NETWORK_CONNECTION has a specific icon and text message we always want to show
// for generic success/error notifications, you have to pass in your own title/subtitle (icon will automatically be success/error icon)
export enum UINotificationTypes {
  POOR_NETWORK_CONNECTION = "POOR_NETWORK_CONNECTION",
  LOCAL_MIC_MUTED = "LOCAL_MIC_MUTED",
  CONSOLE_AUDIO_MUTED = "CONSOLE_AUDIO_MUTED",
  CONSOLE_AUDIO_UNMUTED = "CONSOLE_AUDIO_UNMUTED",
  CONSOLE_AUDIO_SWITCHING_TO_BUILT_IN_MIC = "CONSOLE_AUDIO_SWITCHING_TO_BUILT_IN_MIC",
  CONSOLE_AUDIO_SWITCHING_TO_BLUETOOTH = "CONSOLE_AUDIO_SWITCHING_TO_BLUETOOTH",
  CONSOLE_NOISE_REDUCTION_ON = "CONSOLE_NOISE_REDUCTION_ON",
  CONSOLE_NOISE_REDUCTION_OFF = "CONSOLE_NOISE_REDUCTION_OFF",
  SIDEBAR_MODE_ON = "SIDEBAR_MODE_ON",
  SIDEBAR_MODE_OFF = "SIDEBAR_MODE_OFF",
  HOST_MUTES_PARTICIPANT = "HOST_MUTES_PANELIST",
  // more generic types that devs can pass in messages for
  SUCCESS = "SUCCESS", // for successful actions like created or saved
  DELETE = "DELETE", // for successful delete actions
  ERROR = "ERROR", // for errors that occurred
}
export type UINotificationVariants = "default" | "mini";
// In the future this should be moved to the top level src when its easier to deal with (a lot of changes are required to do that)
export interface UINotification {
  title: string;
  subtitle?: string;
  type: UINotificationTypes;
  // the smaller notifications should be set to mini variant otherwise default is the standard one everyone should use
  variant?: UINotificationVariants;
}

// The Indicator state is basically the same as the props without the dataTestId
// this way the state is flexible to just pass the props to the component without
// modifying this type
export type UIIndicator = Omit<IndicatorProps, "data-test-id">;

// These notifications have a design-imposed limit on how many times it can be seen.
// IF the notification (key) has been previously seen THEN its value is true.
export interface LimitedNotifications {
  [UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BUILT_IN_MIC]: boolean;
  [UINotificationTypes.CONSOLE_AUDIO_SWITCHING_TO_BLUETOOTH]: boolean;
  [UINotificationTypes.CONSOLE_AUDIO_MUTED]: boolean;
  [UINotificationTypes.CONSOLE_AUDIO_UNMUTED]: boolean;
}

// Defines possible badge types
export enum UIBadgeTypes {
  CONSOLE_AUDIO_MUTED = "CONSOLE_AUDIO_MUTED",
  SIDEBAR_ON = "SIDEBAR_ON",
  SIDEBAR_OFF = "SIDEBAR_OFF",
  NOISE_REDUCTION_ON = "NOISE_REDUCTION_ON",
}

export interface UIBadge {
  content: string;
  type: UIBadgeTypes;
}

// returns a full badge object with the correct values
export const generateBadge = (type: UIBadgeTypes): UIBadge => {
  switch (type) {
    case UIBadgeTypes.CONSOLE_AUDIO_MUTED:
      return {
        content: "Console Muted",
        type: UIBadgeTypes.CONSOLE_AUDIO_MUTED,
      };
    case UIBadgeTypes.SIDEBAR_ON:
      return {
        content: "Sidechat On",
        type: UIBadgeTypes.SIDEBAR_ON,
      };
    case UIBadgeTypes.SIDEBAR_OFF:
      return {
        content: "Sidechat Off",
        type: UIBadgeTypes.SIDEBAR_OFF,
      };
    case UIBadgeTypes.NOISE_REDUCTION_ON:
      return {
        content: "Noise Reduction On",
        type: UIBadgeTypes.NOISE_REDUCTION_ON,
      };
  }
};

export interface UIBadges {
  [UIBadgeTypes.CONSOLE_AUDIO_MUTED]: boolean;
  [UIBadgeTypes.SIDEBAR_ON]: boolean;
  [UIBadgeTypes.SIDEBAR_OFF]: boolean;
  [UIBadgeTypes.NOISE_REDUCTION_ON]: boolean;
}

export enum LeaveCallErrorMessages {
  DUPLICATE_PARTICIPANT = "You have joined this call from another tab or browser",
  CONSOLE_VIDEO_NOT_STARTED = "Console is experiencing some network connection issues and could not share video.",
}
