export const CONSOLES = {
	CREATE_NEW_CONSOLE: 'CONSOLES_CREATE_NEW_CONSOLE',
	GET_ALL_CONSOLES: 'CONSOLES_GET_ALL_CONSOLES',
	SET_ALL_CONSOLES: 'CONSOLES_SET_ALL_CONSOLES',
	DELETE_CONSOLE: 'CONSOLES_DELETE_CONSOLE',
	GET_CONSOLE_BY_ID: 'CONSOLES_GET_CONSOLE_BY_ID',
	SET_CONSOLE: 'CONSOLES_SET_CONSOLE',
	SET_CONSOLES_PAGINATION: 'SET_CONSOLES_PAGINATION',
	UPDATE_CONSOLE: 'CONSOLES_UPDATE_CONSOLE',
	GET_CONSOLES_BY_HOSPITAL: 'CONSOLES_GET_CONSOLES_BY_HOSPITAL',
	SET_CONSOLES_BY_HOSPITAL: 'CONSOLES_SET_CONSOLES_BY_HOSPITAL',
	SELECT_CONSOLES: 'CONSOLES_SELECT_CONSOLES',
	ACTIVATE_CONSOLES: 'CONSOLES_ACTIVATE_CONSOLES',

};
