import { AVKSidebarEvent } from "availkit-js/dist/Models/Events/AVKSidebarEvent";
import { AVKSidebarServiceListener } from "availkit-js/dist/Services/Listeners/AVKSidebarServiceListener";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { handleSideBarThunk } from "src/domains/Beacon/store/stream/thunks/handleSideBarThunk";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

// AvailKit takes care of sending and receiving all the events being passed
// in and out of the pubnub channel we are in, we have to set up the individual
// listeners (functions) that respond to certain types of events

// In this case, we listen for events being retrieved by the sidebarService
// for reference, check `AVKSidebarService` on `availkit-js` project.

export const useSidebarSubscriptions = () => {
  const dispatch = useDispatch();
  const availKit = AvailKitService.instance;

  // This function is triggered by AvailKit when this event is
  // fired through pubnub
  const onReceivedSidebarStatus = (_, event: AVKSidebarEvent) => {
    try {
      logger().info("Received Sidebar Status.");

      dispatch(handleSideBarThunk({ sidebarActive: event.sidebarActive }));
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useSidebarSubscriptions",
        },
        "Error while receiving Sidebar status.",
        error?.message
      );
    }
  };

  useEffect(() => {
    // Listeners must be objects. Although not necessary, they can be
    // explicitly typed using corresponding 'Listener' types on availkit.
    const sidebarServiceListener: AVKSidebarServiceListener = {
      onReceivedSidebarStatus,
    };

    // We must attach our listener availkit for it to actually work
    availKit.avkSidebarService.addEventListener(sidebarServiceListener);

    // When this is unmounted, remove the listeners
    return () =>
      availKit?.avkSidebarService.removeEventListener(sidebarServiceListener);
  }, [availKit]);
};
