import resources from "../../../../Common/Constants/resources";
import { urlService } from "../../../../Common/Services";
import ApiHelper from "../../../../Common/Services/ApiHelper";

export const getUserEventsByUserId = async (userId, from, to) => {
  const response = await ApiHelper.get(
    'scheduler/v2', 
    `${urlService.formatString(resources.userEvents, userId)}?from=${from}&to=${to}`
  )

  const events = response.content;
  return events;
}
