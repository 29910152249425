// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module_spinner_htt8xj{color:#1c6eae}", "",{"version":3,"sources":["webpack://./avail-web-ui/src/components/LoadingSpinner/styles.module.scss"],"names":[],"mappings":"AAEA,8BACE,aAAA","sourcesContent":["@import \"/src/colors.scss\";\n\n.spinner {\n  color: map-get($theme-colors, \"primary\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "styles-module_spinner_htt8xj"
};
export default ___CSS_LOADER_EXPORT___;
