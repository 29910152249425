import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../store/models";
import "./PortalCallLogin.scss";
import newlogo2021 from "/public/newlogo2021.png";

type Props = {};

class PortalCallLogin extends React.Component<Props> {
  render() {
    return (
      <div className="login avail-login">
        <div className="logo-container">
          <img className="avail-center-logo" src={newlogo2021} alt="logo" />
        </div>

        <div className="avail-session-text-info">
          <div className="avail-session-info">Your session has ended.</div>
        </div>
        <div className="avail-session-text-info">
          <div className="avail-session-instruction">
            You may now close this window
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect<{}, {}, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(PortalCallLogin);
