import React from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import clsx from "clsx";

import { IconButton } from "@mui/material";

import Icon from "avail-web-application/Common/Components/Icon/Icon";

import styles from "./SideMenuItem.scss";

interface ISideMenuItemProps {
  title: string;
  "data-test-id"?: string;
  iconOverride?: JSX.Element;
  asIconButton?: boolean;
  to?: string;
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  openInNewTab?: boolean;
  iconName?: string;
}

export const SideMenuItem = ({
  title,
  "data-test-id": dataTestId,
  iconOverride,
  asIconButton = false,
  to,
  isDisabled,
  onClick,
  openInNewTab,
  iconName,
}: ISideMenuItemProps) => {
  const pathname = useLocation().pathname;
  const history = useHistory();
  const navigateToUrl = () => history.push(to);

  const onKeyPressHandler = (event) => {
    if (openInNewTab) {
      event.preventDefault();
      window.open(`${to}`, "_blank").focus();
      return;
    }

    if (!isDisabled) {
      navigateToUrl();
    }
  };

  const Wrapper = ({ children }) => (
    <div className={styles["link-container"]}>
      <NavLink
        to={onClick ? pathname : to}
        activeClassName={clsx({
          [styles["link-active"]]: !onClick,
        })}
        onClick={(e) => (onClick ? onClick(e) : onKeyPressHandler(e))}
        data-test-id={dataTestId}
      >
        <div
          title={title}
          onKeyPress={onKeyPressHandler}
          className={clsx({
            [styles.link]: true,
            [styles["link-disabled"]]: isDisabled,
          })}
        >
          <div
            className={`${styles["icon-container"]} ${styles["flex-direction-column"]}`}
          >
            {asIconButton ? (
              <IconButton className={styles[`dark-icon-button`]}>
                {children}
              </IconButton>
            ) : (
              children
            )}
            <span className={styles["active-circle"]} />
          </div>
          <div className={styles.label}>
            <span>{title}</span>
          </div>
        </div>
      </NavLink>
    </div>
  );

  return (
    <Wrapper>
      {!!iconOverride ? (
        iconOverride
      ) : (
        <Icon
          className={clsx({
            [`icon--${asIconButton ? "white" : "blue"}`]: true,
            [styles["sidemnu-icon"]]: true,
            [iconName]: iconName,
          })}
        />
      )}
    </Wrapper>
  );
};
