import { AVKCamera } from "availkit-js/dist/Models/AVKCamera";

import { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { Skeleton } from "@mui/material";

import {
  FourViewsSquareIcon,
  FullscreenIcon,
  TwoViewsSquareIcon,
} from "src/components/CustomIcons";
import { Font } from "src/components/Font";
import { Select } from "src/components/Select";
import { CameraInputSelectors } from "src/domains/Beacon/components/SidePanel/LayoutsPresetsContent/CameraInputSelectors";
import { ORTelligence } from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingCallSid } from "src/domains/Beacon/store/meeting/selectors";
import {
  selectConsoleCapabilities,
  selectStreamState,
  selectToggleThumbnailsFetch,
} from "src/domains/Beacon/store/stream/selectors";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleLayoutChangeThunk } from "src/domains/Beacon/store/stream/thunks";
import { closeFullscreenThunk } from "src/domains/Beacon/store/stream/thunks/closeFullscreenThunk";
import { expandFullScreenThunk } from "src/domains/Beacon/store/stream/thunks/expandFullScreenThunk";
import { publishShareScreenTrackThunk } from "src/domains/Beacon/store/stream/thunks/publishShareScreenTrackThunk";
import { unpublishShareScreenTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishShareScreenTrackThunk";
import { unpublishVideoTrackThunk } from "src/domains/Beacon/store/stream/thunks/unpublishVideoTrackThunk";
import {
  LayoutFrameNames,
  LayoutFrames,
  ZoomFrames,
  LayoutTypes,
} from "src/domains/Beacon/store/stream/types";
import { uiActions } from "src/domains/Beacon/store/ui";
import {
  getCameraDisplayName,
  getCameraIdentifier,
  videoSourceZoomLevel,
} from "src/domains/Beacon/utils/availKit";
import {
  findFullScreenFrameName,
  isFullscreenLayout,
} from "src/domains/Beacon/utils/layouts";
import { createObjectClone } from "src/domains/Beacon/utils/objects";
import { useFeatureFlags } from "src/hooks/useFeatureFlag";
import { logger } from "src/logging/logger";
import { useGetThumbnails } from "src/queries/presence";

import styles from "./styles.scss";

interface CameraItems {
  id: string;
  title: string;
  subtitle: string;
  zoom: number;
  disabled: boolean;
  leftSide?: ReactNode;
}

export const LayoutsSection = () => {
  const {
    layoutFrames,
    layoutFramesSnapshot,
    cameras,
    zoomState,
    zoomStateSnapshot,
    presets: { selectedPresetId },
    integrationActive: isIntegrationActive,
    layoutType,
  } = useAppSelector(selectStreamState);
  const callSid = useAppSelector(selectMeetingCallSid);
  const toggleThumbnailsFetch = useAppSelector(selectToggleThumbnailsFetch);
  // All Console available capabilities in an object shape { [key: string]: boolean }
  const consoleCapabilities = useAppSelector(selectConsoleCapabilities);

  const {
    externalInputsImageResizing,
    enableMultiPaneLayout,
    oRtelligence: enableORTelligence,
  } = useFeatureFlags();

  // Must check the LD flag and also if Console has this capability available
  const enableFourViewsLayout =
    enableMultiPaneLayout && consoleCapabilities.fourViewsLayout;

  const layouts = {
    [LayoutTypes.FULLSCREEN]: {
      title: "Fullscreen",
      id: LayoutTypes.FULLSCREEN,
      leftIcon: <FullscreenIcon />,
    },
    [LayoutTypes.TWO_VIEW]: {
      title: "2 Views",
      id: LayoutTypes.TWO_VIEW,
      leftIcon: <TwoViewsSquareIcon />,
    },
    ...(enableFourViewsLayout && {
      [LayoutTypes.FOUR_VIEW]: {
        title: "4 View",
        id: LayoutTypes.FOUR_VIEW,
        leftIcon: <FourViewsSquareIcon />,
      },
    }),
  };

  // The 'toggleThumbnailsFetch' fires the react-query hook in order to fetch the new
  // thumbnails and update the cameras list, this way we won't store the images into Redux state
  // nor inside a useState
  const { data: thumbnailsData, isLoading } = useGetThumbnails(
    callSid,
    toggleThumbnailsFetch
  );

  const dispatch = useDispatch();

  const cameraItems: CameraItems[] = useMemo(
    () =>
      cameras?.map((camera) => {
        const cameraId = getCameraIdentifier(camera);
        const thumbnailInfo = thumbnailsData?.content.find(
          (info) => info.thumbnailId === cameraId
        );
        const thumbnailImage = thumbnailInfo?.content ?? "";

        // Must show the Skeleton of a rectangle Thumbnail while fetching, it appears
        // too fast but it's better than a broken image
        const ThumbnailSkeleton = isLoading ? (
          <Skeleton
            variant="rectangular"
            width={80}
            height={45}
            className={styles.thumbnail}
          />
        ) : null;

        return {
          id: cameraId,
          title: getCameraDisplayName(camera),
          // we want a subtitle for ORTelligence so add that to just that item
          // if we have to make more special cases then we can move this into its own section
          // rather than in this map loop
          subtitle:
            enableORTelligence && cameraId === ORTelligence
              ? "APPLICATION"
              : "",
          zoom: videoSourceZoomLevel(camera as AVKCamera),
          // Only one 3rd party integration must be active if shown
          disabled:
            cameraId === ORTelligence &&
            (isIntegrationActive || layoutType === LayoutTypes.FULLSCREEN),
          // Shows the layout name if there's no thumbnail available, otherwise, a broken image appears
          leftSide:
            // If thumbnailsFetch is loading, will show the Skeleton, otherwise,
            // must check if thumbnail is available to display or not
            ThumbnailSkeleton ??
            (thumbnailImage ? (
              <img
                src={thumbnailImage}
                width={80}
                height={45}
                className={styles.thumbnail}
              />
            ) : null),
        };
      }) ?? [],
    [isIntegrationActive, layoutType, cameras, thumbnailsData, isLoading]
  );

  const [fullScreenFrameName, setFullScreenFrameName] = useState<string>(
    findFullScreenFrameName(layoutFrames)
  );

  // Common function that happens when selecting a layout

  // changes the layouts state and executes AvailKit event for layout change
  const handleLayoutSelect = async (
    cameraId: any,
    frameSelected: LayoutFrameNames,
    type: string,
    framesOfLayout
  ) => {
    logger().info("Handling layout change to : " + cameraId);

    // Construct a new layoutFrame
    const camera = cameraItems.find((item) => item.id === cameraId);

    const newLayoutFrames: LayoutFrames = createObjectClone({
      ...framesOfLayout,
      [frameSelected]: {
        cameraId: camera.id,
        cameraLabel: camera.title,
        isFullScreen: type === LayoutTypes.FULLSCREEN,
      },
    });

    const newZoomState: ZoomFrames = {
      ...zoomState,
      [frameSelected]: {
        cameraId: camera.id,
        value: camera.zoom * 10,
        settings: zoomState[frameSelected].settings,
      },
    };

    // TODO: name ORTelligence to something generic as a 3rd party integration
    // User has selected a third party integration app
    if (enableORTelligence && cameraId === ORTelligence) {
      if (!isIntegrationActive) {
        logger().info("User has selected a third party integration layout");

        // Must let know that there's an integration been active
        dispatch(streamActions.setIntegrationActive(true));

        // Taking snapshot before changing to integration
        // persist to Redux to prevent sideeffects when we stop screen sharing
        dispatch(streamActions.setLayoutFramesSnapshot(layoutFrames));
        dispatch(streamActions.setZoomStateSnapshot(zoomState));

        // Must stop Host's Pip video track to start sharing screen
        await dispatch(unpublishVideoTrackThunk());

        // Will take care of sharing screen and reset Layouts and Host's Pip video
        // if screen share stops
        await dispatch(
          publishShareScreenTrackThunk({
            enableORTelligence,
            externalInputsImageResizing,
            integrationName: cameraId,
          })
        );
      }
      // 3. If an integration is active and the other layout frame changes input, the layout frame with integration must be preserved
    } else {
      // If there's an integration being shown, then must check if integration's
      // stream should end or preserved
      if (isIntegrationActive) {
        logger().info("A third party integration layout is still active");
        const integrationLink = layoutFrames[frameSelected]?.integrationLink;

        // If the frame selected has the integration running, then must be stopped and
        // changed to the desired layout selected
        if (integrationLink && integrationLink !== "") {
          logger().info(
            "Third party integration layout has been changed, screen sharing stopped"
          );
          await dispatch(unpublishShareScreenTrackThunk());

          // Re-setting the new layouts to the new selected by the user
          await dispatch(
            handleLayoutChangeThunk({
              externalInputsImageResizing,
              newLayoutFrames,
            })
          );

          // Re-setting the new layouts selected by the user
          dispatch(streamActions.setLayoutFrames(newLayoutFrames));
          dispatch(streamActions.setZoomState(newZoomState));

          // Must deactivate integration to allow the user to select another one
          dispatch(streamActions.setIntegrationActive(false));
        } else {
          const layoutFrameToPreserve =
            frameSelected === "leftTop" ? "rightTop" : "leftTop";

          logger().info(
            `Opposite Integration layout side changed, preserving the TPI layout on side ${layoutFrameToPreserve}`
          );

          const cameraToPreserve = layoutFramesSnapshot[layoutFrameToPreserve];
          const zoomToPreserve = zoomStateSnapshot[layoutFrameToPreserve];

          const layoutFramesToPreserve = {
            ...newLayoutFrames,
            [layoutFrameToPreserve]: cameraToPreserve,
          };

          const zoomStateToPreserve = {
            ...newZoomState,
            [layoutFrameToPreserve]: zoomToPreserve,
          };

          // Must store the new layout change of the opposite side but keeping
          // the integration's background camera
          // persist to Redux to prevent sideeffects when we stop screen sharing
          dispatch(
            streamActions.setLayoutFramesSnapshot(layoutFramesToPreserve)
          );
          dispatch(streamActions.setZoomStateSnapshot(zoomStateToPreserve));

          // This will update the Layouts list selection.
          // Must keep the integration layout option selected but with the new
          // layout camera selected in the opposite frame
          dispatch(streamActions.setZoomState(zoomStateToPreserve));
          // Will broadcast the new layouts event to Console
          dispatch(
            handleLayoutChangeThunk({
              externalInputsImageResizing,
              newLayoutFrames,
            })
          );
        }
      } else {
        // Normal layout selection happened
        logger().info("User has selected a normal layout option");
        dispatch(streamActions.setZoomState(newZoomState));
        await dispatch(
          handleLayoutChangeThunk({
            externalInputsImageResizing,
            newLayoutFrames,
          })
        );
      }
    }
    // when there is an active preset, any change to layouts clears selectedPreset
    if (selectedPresetId) {
      dispatch(streamActions.setPresetSelected(null));
    }
  };

  const layoutTypeItems = Object.values(layouts);

  const handleLayoutTypeSelect = (event) => {
    const newLayoutType = event.target.value;
    dispatch(streamActions.setLayoutType(newLayoutType));
    if (newLayoutType === LayoutTypes.FULLSCREEN) {
      dispatch(
        expandFullScreenThunk({ externalInputsImageResizing, side: "leftTop" })
      );
    } else {
      dispatch(closeFullscreenThunk({ externalInputsImageResizing }));
    }
    // when there is an active preset, any change to layouts clears selectedPreset
    if (selectedPresetId) {
      dispatch(streamActions.setPresetSelected(null));
    }
  };

  const onOpen = (
    side: "leftTop" | "leftBottom" | "rightTop" | "rightBottom"
  ) => {
    // show blue Indicator on the given side
    dispatch(uiActions.setLayoutFrame(side));
  };

  // clear blue Indicator when CameraInputSelector is closed
  const onClose = () => {
    /**
     * TODO: ensure that this DOESN'T get dispatched when selecting ORT
     * publishShareScreenTrackThunk does not complete until after onClose
     * is called, resulting in ORT failing on WebCall
     */
    // dispatch(uiActions.setLayoutFrame(null));
  };

  // onChange for CameraInputSelectors
  // set up to work for TwoView and FullScreen only
  const onChange = (
    event,
    side: "leftTop" | "leftBottom" | "rightTop" | "rightBottom"
  ) => {
    handleLayoutSelect(event.target.value, side, layoutType, layoutFrames);
  };

  // allows LayoutTypeSelector to change dynamically when choosing preset or
  // using PTZ controls to expand/collapse fullscreen
  useEffect(() => {
    dispatch(
      streamActions.setLayoutType(
        isFullscreenLayout(layoutFrames) ? LayoutTypes.FULLSCREEN : layoutType
      )
    );
    setFullScreenFrameName(findFullScreenFrameName(layoutFrames) || null);
  }, [layoutFrames]);

  return (
    <>
      <div className={styles.header}>
        <Font variant="h2" color="light">
          Layouts
        </Font>
      </div>
      <div className={styles.container}>
        <Select
          label={null}
          name="views type"
          onChange={handleLayoutTypeSelect}
          value={layoutType}
          options={layoutTypeItems.map((type) => ({
            title: type?.title,
            value: type?.id,
            leftSide: type?.leftIcon,
            disabled:
              // The fourViewsLayout option will be disabled if the LD flag and Console capability aren't enabled,
              // user will see the option in the dropdown but no able to click it
              type?.id === LayoutTypes.FOUR_VIEW && !enableFourViewsLayout,
          }))}
          selectClass={styles.layoutsSelect}
          listClass={styles.layoutsList}
          menuItemClass={styles.menuItem}
        />
        <CameraInputSelectors
          layoutFrames={layoutFrames}
          fullScreenFrameName={fullScreenFrameName}
          layoutType={layoutType}
          onChange={onChange}
          onOpen={onOpen}
          onClose={onClose}
          options={cameraItems.map((item) => ({
            title: item?.title,
            value: item?.id,
            disabled: item?.disabled,
            leftSide: item?.leftSide,
          }))}
        />
      </div>
    </>
  );
};
