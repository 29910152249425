import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, reset } from 'redux-form';
import { withTranslation } from 'react-i18next';
/* Components */
import { Button, Fields, validators } from '../../../../../../../../../Common/Components';
import { maximumLength48 } from '../../../../../../../../../Common/Components/FormFields/validators';
import ModalComponent from '../../../../../../../../../Common/Components/Modal/Modal';
import { Table } from '../../../../../../../Common';
import DepartmentTableRow from '../../../DepartmentProfile/Components/DepartmentTableRow/DepartmentTableRow';
/* Actions */
import {
	createNewDepartmentAction,
	getAllTherapiesAction,
	getDepartmentByHospitalAction,
	deleteDepartmentAction,
} from '../../../DepartmentProfile/DepartmentsActions';

class Departments extends PureComponent {
	state = {
		isCreateNewDepartmentModalOpened: false,
		isDeleteModalOpened: false,
		selectedDepartment: null,
	};

	componentDidMount() {
		this.params = {
			hospitalId: this.props.hospitalId,
			size: this.state.pageSize,
			page: 0,
		};
		this.props.getDepartmentsByHospital(this.params);
		this.props.getAllTherapies();
	}

	onConfirmDelete = () => {
		this.props.deleteDepartment(this.state.selectedDepartment.id);
		this.toggleDeleteModal();
	}

	onCreateNewDepartmentHandler = (data) => {
		const { hospitalId } = this.props;
		const therapiesIds = this.formatTherapies(data.therapies);
		this.props.createNewDepartment(hospitalId, data.departmentData, therapiesIds);
		this.toggleCreateDepartmentModal();
	};

	onSortTableHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = {
			hospitalId: this.props.hospitalId,
			size: this.state.pageSize,
			page: 0,
			[this.parameter]: this.sortParams,
		};
		this.props.getDepartmentsByHospital(this.params);
	};

	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			hospitalId: this.props.hospitalId,
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			hospitalId: this.props.hospitalId,
			size: this.params.size,
			page: this.params.page,
			[this.parameter]: this.sortParams,
		};
		this.props.getDepartmentsByHospital(this.params);
	};

	onTableSortHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			size: this.state.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			...this.params,
			[this.parameter]: this.sortParams,
		};
		this.props.getDepartmentsByHospital(this.params);
	};

	toggleCreateDepartmentModal = () => {
		this.props.resetReduxForm('departmentsForm');
		this.setState({ isCreateNewDepartmentModalOpened: !this.state.isCreateNewDepartmentModalOpened });
	};

	toggleDeleteModal = (selectedDepartment) => {
		this.setState({
			isDeleteModalOpened: !this.state.isDeleteModalOpened,
			selectedDepartment,
		});
	}

	formatTherapies = therapies => ({
		'ids': therapies ? Object.keys(therapies).map((key) => {
			if (therapies[key]) {
				return therapies[key];
			}
		}) : [],
	});

	renderCreateNewDepartmentModal = () => {
		const { t } = this.props;

		return (
			<ModalComponent
				open={this.state.isCreateNewDepartmentModalOpened}
				hasActionButtons={false}
				onCancel={this.toggleCreateDepartmentModal}
				title={t('features.org.hospital.department.create.label')}
			>
				<form
					className="align--center flex-direction--column"
					onSubmit={this.props.handleSubmit(this.onCreateNewDepartmentHandler)}
				>
					<Fields.InputField
						labelText={t('common.form.name.label')}
						placeholder={t('common.form.name.placeholder')}
						name="departmentData.name"
						validate={[validators.required, validators.minimumLength3, maximumLength48]}
					/>
					<Fields.MultiselectField
						labelText={t('features.org.hospital.department.create.form.therapies.label')}
						placeholder={t('features.org.hospital.department.create.form.therapies.placeholder')}
						name="therapies"
						options={this.props.therapies && this.props.therapies.map(therapy => ({
							key: therapy.id,
							value: therapy.id,
							label: therapy.name,
						}))}
						validate={validators.required}
					/>
					<div className="button__wrapper width--full">
						<Button isGhost buttonSmall noBorder onClick={this.toggleCreateDepartmentModal}>Cancel</Button>
						<Button isFull buttonSmall type="submit">Create</Button>
					</div>
				</form>
			</ModalComponent>
		);
	};

	renderDeleteModal = () => (
		<ModalComponent
			open={this.state.isDeleteModalOpened}
			onConfirm={this.onConfirmDelete}
			onCancel={this.toggleDeleteModal}
			confirmText="Delete"
		>
			{
				this.state.selectedDepartment &&
				<span>
					You are about to delete department <span className="text--bold">{this.state.selectedDepartment.name}</span>.
					Would you like to proceed with this action?
				</span>
			}
		</ModalComponent>
	);

	render() {
		const { t } = this.props;

		return (
			<div>
				<Table
					className="table__profile-wrapper"
					columns={[{
						title: 'Name',
						sortValue: 'name',
					}]}
					tableHeaderClassName="table__profile-wrapper"
					row={DepartmentTableRow}
					tableData={this.props.departments}
					openModal={this.toggleDeleteModal}
					onSortTableHandler={this.onTableSortHandler}
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					search
					sortable
					tableActions={false}
					urlParams={this.props.hospitalId}
					onAddNewHandler={this.toggleCreateDepartmentModal}
					onAddNewTitle={t('features.org.hospital.department.create.label')}
					noEntityComponent={
						<div className="justify-content--space-between width--full">
							<div className="profile__right-content-wrapper">No departments available</div>
						</div>
					}
				/>
				{this.renderCreateNewDepartmentModal()}
				{this.renderDeleteModal()}
			</div>
		);
	}
}

Departments.propTypes = {};

const mapStateToProps = state => ({
	departments: state.departmentReducer.departments,
	therapies: state.departmentReducer.therapies,
});

const mapDispatchToProps = dispatch => ({
	getDepartmentsByHospital: payload => dispatch(getDepartmentByHospitalAction(payload)),
	deleteDepartment: id => dispatch(deleteDepartmentAction(id)),
	getAllTherapies: () => dispatch(getAllTherapiesAction()),
	createNewDepartment: (hospitalId, departmentData, therapies) => dispatch(createNewDepartmentAction(hospitalId, departmentData, therapies)),
	resetReduxForm: formName => dispatch(reset(formName)),
});

const DepartmentsWithTranslation = withTranslation()(Departments);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'departmentsForm',
	enableReinitialize: true,
})(DepartmentsWithTranslation)));
