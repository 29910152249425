import svgMicOnEnabled from "../../static/roster-mic-on-enabled.svg";
import svgMicOffDisabled from "../../static/roster-mic-off-disabled.svg";
import svgClose from "../../static/roster-panel-close.svg";
import { RosterParticipant } from "./types";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  participants: RosterParticipant[];
  onMuteClick: (forUser: RosterParticipant) => void;
  currentUserLoginId: string;
}

function getDisplayName (participant: RosterParticipant) {
  const { firstName, lastName, email } = participant;
  return participant.firstName && participant.lastName
    ? `${firstName} ${lastName}`
    : email;
}

function RosterStrip({
  participants,
  open,
  setOpen,
  onMuteClick,
  currentUserLoginId
}: Props) {
  const currentUser = participants.find(
    user => user.loginId === currentUserLoginId
  );
  const participantsWithoutCurrentUser = participants.filter(
    user => user.loginId !== currentUserLoginId
  );

  return (
    <div className={`roster-container ${open ? "strip-open" : ""}`}>
      <div className="roster-header">
        <h2>Participants</h2>
        <button className="roster-button" onClick={() => setOpen(false)}>
          <img src={svgClose} alt="close roster" />
        </button>
      </div>

      <div className="roster-content">
        <div className="roster-content__item">
          {currentUser && (
            <>
              <div
                className={`${
                  currentUser.role === "HOST" ? "host" : "participant"
                }`}
              >
                {`${currentUser.firstName} ${currentUser.lastName}`}
              </div>
              <button
                className="roster-button"
                onClick={() => onMuteClick(currentUser)}
              >
                <img
                  src={
                    currentUser.audioEnabled
                      ? svgMicOnEnabled
                      : svgMicOffDisabled
                  }
                  alt={
                    currentUser.audioEnabled ? "audio unmuted" : "audio muted"
                  }
                />
              </button>
            </>
          )}
        </div>

        <hr />

        <ul className="roster-content__list">
          {participantsWithoutCurrentUser.map(user => (
            <div className="roster-content__item" key={user.loginId}>
              <li
                className={`${user.role === "HOST" ? "host" : "participant"}`}
              >
                {getDisplayName(user)}
              </li>
              <button
                className="roster-button"
                onClick={() => onMuteClick(user)}
              >
                <img
                  src={user.audioEnabled ? svgMicOnEnabled : svgMicOffDisabled}
                  alt={user.audioEnabled ? "audio unmuted" : "audio muted"}
                />
              </button>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default RosterStrip;
