import { USERS } from './UsersActionTypes';

export const getUserTypesAction = () => ({
	type: USERS.GET_USER_TYPES,
});

export const getAllUsersAction = params => ({
	type: USERS.GET_USERS,
	payload: params,
});

export const selectUserTypeAction = selectedUserType => ({
	type: USERS.SELECT_USER_TYPE,
	payload: selectedUserType,
});

export const createUserAction = userData => ({
	type: USERS.CREATE_USER,
	payload: userData,
});

export const resendActiveEmail = userData => ({
	type: USERS.RESEND_INVITATION,
	payload: userData,
});

export const deleteUserAction = id => ({
	type: USERS.DELETE_USER,
	payload: id,
});

export const selectUserAction = id => ({
	type: USERS.SELECT_USER,
	payload: id,
});

export const registerUserAction = userData => ({
	type: USERS.REGISTER_USER,
	payload: userData,
});

export const getUserByTokenAction = token => ({
	type: USERS.GET_USER_BY_TOKEN,
	payload: token,
});

export const getUserByIdAction = id => ({
	type: USERS.GET_USER_BY_ID,
	payload: id,
});

export const updateUserProfileAction = (userId, userData, userSettings) => ({
	type: USERS.UPDATE_USER_PROFILE,
	userId,
	userData,
	userSettings,
});

export const getUserSettingsAction = userId => ({
	type: USERS.GET_USERS_SETTINGS,
	payload: userId,
});

export const getUserOrganisationAction = id => ({
	type: USERS.GET_USER_ORGANISATION,
	payload: id,
});

export const getUserSupportsOrganisationAction = id => ({
	type: USERS.GET_USER_SUPPORTS_ORGANISATION,
	payload: id,
});

export const getUserEmployerAction = id => ({
	type: USERS.GET_USER_EMPLOYER,
	payload: id,
});

export const getUserEmployerByDomainAction = domain => ({
	type: USERS.GET_USER_EMPLOYER_BY_DOMAIN,
	payload: domain,
});

export const getUserProceduresAction = id => ({
	type: USERS.GET_USER_PROCEDURES,
	payload: id,
});

export const updateUserProceduresAction = (userId, proceduresId) => ({
	type: USERS.UPDATE_USER_PROCEDURES,
	userId,
	proceduresId,
});

export const getAllHospitalsUserAppliedAction = payload => ({
	type: USERS.GET_ALL_HOSPITALS_USER_APPLIED,
	payload,
});

export const getAllAvailableHospitalsToApplyAction = userId => ({
	type: USERS.GET_ALL_AVAILABLE_HOSPITALS_TO_APPLY,
	payload: userId,
});

export const getAllApprovedHospitalsForUserAction = userId => ({
	type: USERS.GET_ALL_APPROVED_HOSPITALS_FOR_USER,
	payload: userId,
});

export const applyToHospitalAction = (userId, hospitalIds) => ({
	type: USERS.APPLY_TO_HOSPITAL,
	userId,
	hospitalIds,
});

export const applyToHospitalForTrialAction = (userId, hospitalIds) => ({
	type: USERS.APPLY_TO_HOSPITAL_FOR_TRIAL,
	userId,
	hospitalIds,
});

export const getUserManagerAction = userId => ({
	type: USERS.GET_USER_MANAGER,
	payload: userId,
});


export const setUserManagerAction = (userId, managerId) => ({
	type: USERS.UPDATE_USER_MANAGER,
	userId,
	managerId,
});

export const removeUserManagerAction = (userId, managerId) => ({
	type: USERS.REMOVE_USER_MANAGER,
	userId,
	managerId,
});

export const getAllManagersUserAppliedToAction = userId => ({
	type: USERS.GET_ALL_MANAGERS_USER_APPLIED_TO,
	payload: userId,
});

export const getAllDescendantUsersForManagerAction = managerId => ({
	type: USERS.GET_ALL_DESCENDANT_USERS_FOR_MANAGER,
	payload: managerId,
});

export const removeHospitalApplicationAction = (userId, hospitalId, blockedStatus) => ({
	type: USERS.REMOVE_HOSPITAL_APPLICATION,
	userId,
	hospitalId,
	blockedStatus,
});

export const updateUserSettingsAction = (userId, userSettings) => ({
	type: USERS.UPDATE_USER_SETTINGS,
	userId,
	userSettings,
});

export const updateUserPasswordAction = (userId, changeData, expired) => ({
	type: USERS.UPDATE_USER_PASSWORD,
	userId,
	changeData,
	expired,
});

export const lockAccountAction = payload => ({
	type: USERS.LOCK_USER,
	payload,
});

export const unlockAccountAction = payload => ({
	type: USERS.UNLOCK_USER,
	payload,
});

export const setPassphrasesAction = params => ({
	type: USERS.GET_PASSPHRASES_DATA,
	payload: params,
})

export const getPassphraseAction = data => ({
	type: USERS.SET_PASSPHRASE,
	payload: data,
})

export const setMFAPhoneOTPAction = params => ({
	type: USERS.ADD_PHONE_NUMBER,
	payload: params,
})

export const rmPhoneNumberAction = loginId => ({
	type: USERS.DELETE_PHONE_NUMBER,
	payload: loginId
})

export const setMFAVerifyPhoneAction = params => ({
	type: USERS.VERIFY_PHONE_NUMBER_OTP,
	params
})

export const setResendMFAVerifyPhoneAction = params => ({
	type: USERS.VERIFY_RESEND_PHONE_NUMBER_OTP,
	params
})

export const getPhoneNumberAction = params => ({
	type: USERS.PHONE_NUMBER,
	params
});

export const getMfaSettings = params => ({
	type: USERS.GET_USER_MFA_SETTINGS,
	params
})

export const setAuthErrorAction = payload => ({
  type: USERS.SET_AUTH_ERROR,
  payload
});