import { watchRefreshFrames } from "portalcall/commoncall/store/twilio/sagas/refreshFrames";
import { watchConnectCall } from "./connectTwilio";
import { watchDisconnectFromRoom } from "./disconnectFromRoom";
import { watchGetCredentials } from "./getTwilioCredentials";
import { watchTwilioReconnection } from "./reconnection";

export default [
  watchGetCredentials(),
  watchConnectCall(),
  watchDisconnectFromRoom(),
  /* watchNetworkQualityPoll(), disabled 2.0.12 */
  watchTwilioReconnection(),
  watchRefreshFrames()
];
