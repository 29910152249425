import { RemoteParticipant } from "twilio-video";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { getCallDetailsThunk } from "src/domains/Beacon/store/meeting/thunks/getCallDetailsThunk";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { setParticipantsTracksThunk } from "src/domains/Beacon/store/twilio/thunks/setParticipantsTracksThunk";
import { twilioActions } from "src/domains/Beacon/store/twilio/twilioSlice";
import { isConsoleParticipant } from "src/domains/Beacon/utils/twilio";
import { LoggerLevels, logger } from "src/logging/logger";
import { MultiPartyEvent } from "src/services/ApiClient/scheduler";

// Called when Twilio's room 'participantConnected' event is fired,
// the action thunk gets the latest Redux state without having to modify a dependency array
export const handleParticipantConnectedThunk = (
  participant: RemoteParticipant
) => (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    logger().info(
      `'${participant.identity}' connected and has joined the room.`
    );

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "handleParticipantConnectedThunk",
      },
      `'${participant.identity}' connected and has joined the room.`
    );

    const { twilio, meeting } = getState();
    const { room } = twilio;
    const { callDetails, mode } = meeting;
    // Participants only exist in MP, otherwise, must return an empty []
    const meetingParticipants =
      (callDetails as MultiPartyEvent)?.participants ?? [];

    // Must remove Console from the Twilio's participants list,
    // must only detect when a participant has joined
    const twilioParticipants = [...room.participants.values()].filter(
      (remoteParticipant) => {
        const isConsole = isConsoleParticipant(remoteParticipant);
        if (!isConsole) {
          return remoteParticipant;
        }
      }
    );

    // room.participants don't count the local one, must add it manually
    const allCallParticipants = [...twilioParticipants, room.localParticipant];

    dispatch(twilioActions.setParticipants(twilioParticipants));
    dispatch(twilioActions.setRemoteHasJoinedRoom(true));

    // is console if participant's identity includes "CONSOLE"
    const hasConsoleJoined = isConsoleParticipant(participant);
    if (hasConsoleJoined) {
      // here is where we know when the Console joins, the participantDisconnected
      // event will take care of setting "consoleHasJoined" to false when Console leaves
      dispatch(twilioActions.setConsoleHasJoinedRoom(hasConsoleJoined));
    }

    dispatch(setParticipantsTracksThunk({ participants: [participant] }));

    // Only in MP we want to fetch the Call Details
    if (mode === CallModes.MP) {
      if (allCallParticipants.length !== meetingParticipants?.length) {
        logger().info(
          "There's a different Twilio participants-list length than in the call details...",
          `Twilio participants length: ${allCallParticipants.length} and Call Details participants ${meetingParticipants.length}`
        );
        logger().logWithFields(
          LoggerLevels.info,
          {
            feature: "handleParticipantConnectedThunk",
          },
          `Participants-list length in Twilio room is different than in Call Details, must fetch latest Call Details`
        );
        dispatch(getCallDetailsThunk());
      }
    }
  } catch (error) {
    logger().logWithFields(
      LoggerLevels.error,
      {
        feature: "handleParticipantConnectedThunk",
      },
      `Error while participant ${participant?.identity} tried to join the room`,
      error?.message
    );
    throw error;
  }
};
