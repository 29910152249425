import { AUTH } from "./AuthenticationActionTypes";

export const initialState = {
	isLoggedIn: false,
	shouldRememberFromState: true,
	gmailAuthRes: null,
	resendOTPRes: null,
	otpInfo: null,
	errorMessage: "",
	mfaChannelType: "",
};

export default function (state = initialState, action) {
	switch (action.type) {
		case AUTH.SUBMIT_LOGIN_SUCCESS:
			return {
				...state,
				isLoggedIn: true,
			};
		case AUTH.LOGOUT_SUCCESS:
			return {
				...state,
				isLoggedIn: false,
				shouldRememberFromState: false,
			};
		case AUTH.IS_LOGGED_IN:
			return {
				...state,
				isLoggedIn: action.payload,
			};
		case AUTH.MAIL_AUTH:
			return {
				...state,
				gmailAuthRes: action.payload,
			};
		case AUTH.RESEND_OTP:
			return {
				...state,
				resendOTPRes: action.payload,
			};
		case AUTH.RESEND_GUEST_OTP:
			return {
				...state,
				resendOTPRes: action.payload,
			};
		case AUTH.SET_OTP_INFO:
			return {
				...state,
				otpInfo: action.payload,
			};
		case AUTH.SET_AUTH_ERROR:
			return {
				...state,
				errorMessage: action.payload,
			};
		case AUTH.SET_MFA_CHANNEL:
			return {
				...state,
				mfaChannelType: action.payload,
			};
		default:
			return state;
	}
}
