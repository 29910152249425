import SessionStorageService from '../../../../src/services/SessionStorageService';
import LocalStorageService from './localStorageService';

const CACHED_USER_INFO = 'cachedUserInfo';
const ACCOUNT_SETUP_FINISHED = 'finishedAccountSetup';
const EULA_VERSION = 'eulaVersion';
const MFA_AUTH_DATA = 'MFAAuthDetails';
const REDIRECT_URL = 'redirectUrl';
const RESET_PASSWORD = 'resetPassword';
const MFA_AUTH_INFO = 'mfaAuthInfo';

export default class UserSessionService {
	static setMfaAuthInfo(data){
		SessionStorageService.add(MFA_AUTH_INFO, data);
	}

	static getMfaAuthInfo(){
		SessionStorageService.get(MFA_AUTH_INFO);
	}

	static setCachedUserInfo(user) {
		SessionStorageService.add(CACHED_USER_INFO, user);
  }

	static getCachedUserInfo() {
		return SessionStorageService.get(CACHED_USER_INFO);
	}

	static setAccountSetupStatus(status) {
		SessionStorageService.add(ACCOUNT_SETUP_FINISHED, status);
	}

	static getAccountSetupStatus(status) {
		return SessionStorageService.add(ACCOUNT_SETUP_FINISHED, status);
	}

	static setExpiredAccount(status) {
		SessionStorageService.add('expiredAccount', status);
	}

	static getResetPassword(status) {
		SessionStorageService.get(RESET_PASSWORD);
	}

	static setResetPassword(status) {
		SessionStorageService.add('resetPassword', status);
	}

	static getEulaVersion() {
		return LocalStorageService.get(EULA_VERSION);
	}

	static setEulaVersion(version) {
		LocalStorageService.add(EULA_VERSION, version || '');
	}

	static setMFAAuthData(MFAData) {
		LocalStorageService.add(MFA_AUTH_DATA, MFAData);
	}

	static getMFAAuthData() {
		return LocalStorageService.get(MFA_AUTH_DATA)
  }

  static setRedirectUrl(redirectUrl) {
    SessionStorageService.add(REDIRECT_URL, redirectUrl);
  }
  
  static getRedirectUrl() {
    return SessionStorageService.get(REDIRECT_URL);
  }

  static removeRedirectUrl() {
    SessionStorageService.remove(REDIRECT_URL) 
  }
}

