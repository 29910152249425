import { AVKAnnotationEvent } from "availkit-js/dist/Models/Events/AVKAnnotationEvent";
import { AVKTelestrationEndReportEvent } from "availkit-js/dist/Models/Events/AVKTelestrationEndReportEvent";
import { Guid } from "guid-typescript";

import { INITIAL_TELESTRATION_EVENTS_MAP } from "src/domains/Beacon/components/Easel/constants";
import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

export const onReceiveAVKTelestrationEndReportThunk = (
  event: AVKTelestrationEndReportEvent
) => (dispatch, getState) => {
  const availKit = AvailKitService.instance;
  const {
    telestration: {
      telestrationEventsMap,
      totalAnnotations,
      telestrationRetries,
    },
  } = getState();

  try {
    if (!event?.telestrationId) {
      return;
    }

    const telestrationId = event.telestrationId;
    const telestrationIdString = event.telestrationId.toString().toLowerCase();
    const annotationEvents = telestrationEventsMap.get(
      telestrationIdString
    ) as AVKAnnotationEvent[];
    let skipResend = false;

    if (!availKit) {
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Telestration",
          fileInfo: "receiveAVKTelestrationEndReportThunk",
        },
        "Received AVKTelestrationEndReport. Returning as there is no AvailKit instance."
      );
      return;
    }

    if (!annotationEvents) {
      logger().logWithFields(
        LoggerLevels.info,
        {
          feature: "Telestration",
          fileInfo: "receiveAVKTelestrationEndReportThunk",
        },
        ": " +
          telestrationId +
          " : All events have been flushed out. Possibly telestration was still active. Skip resend."
      );
      skipResend = true;
    }

    const missingAnnotations = event.missingAnnotations;
    let logMessage =
      "End Report Received: " + event.telestrationId.toString().toLowerCase();

    if (missingAnnotations.length) {
      const logMissingAnnotations: number[] = [...missingAnnotations];
      logMessage += " : Missing Annotations: [";
      logMissingAnnotations.forEach((logMissingAnnotation: number) => {
        const logNumber: number = logMissingAnnotation + 1;
        logMessage += " " + logNumber;
      });
      logMessage += " ]";

      let retry: number | undefined = telestrationRetries.get(
        telestrationIdString
      );
      retry = retry ? retry++ : 1;
      if (retry > 3) {
        logMessage += ". Max retries exceeded.";
        skipResend = true;
      } else {
        logMessage += ". Retry Attempt : " + retry;

        // updating mapped state
        const telestrationRetriesCopy: Map<string | null, number> = new Map(
          telestrationRetries
        );
        telestrationRetriesCopy.set(telestrationIdString, retry);
        dispatch(
          telestrationActions.setTelestrationRetries(telestrationRetriesCopy)
        );
      }
    } else {
      skipResend = true;
    }

    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Telestration",
        fileInfo: "receiveAVKTelestrationEndReportThunk",
      },
      logMessage
    );

    if (skipResend) {
      logger().logWithFields(
        LoggerLevels.debug,
        {
          feature: "Telestration",
          fileInfo: "receiveAVKTelestrationEndReportThunk",
        },
        " : Flushing all sequences"
      );

      dispatch(
        telestrationActions.setTelestrationEventsMap(
          INITIAL_TELESTRATION_EVENTS_MAP
        )
      );

      // updating mapped state
      const totalAnnotationsCopy: Map<Guid | null, number> = new Map(
        totalAnnotations
      );
      totalAnnotationsCopy.delete(telestrationId);
      dispatch(telestrationActions.setTotalAnnotations(totalAnnotationsCopy));

      // updating mapped state
      const telestrationRetriesCopy: Map<string | null, number> = new Map(
        telestrationRetries
      );
      totalAnnotationsCopy.delete(telestrationId);
      dispatch(
        telestrationActions.setTelestrationRetries(telestrationRetriesCopy)
      );
      return;
    } else {
      /* Send missing events */
      let atLeastOneEventResent = false;
      missingAnnotations.forEach((missingAnnotation) => {
        logger().logWithFields(
          LoggerLevels.debug,
          {
            feature: "Telestration",
            fileInfo: "receiveAVKTelestrationEndReportThunk",
          },
          "Need to resend: " +
            event.telestrationId.toString().toLowerCase() +
            " : sequence: " +
            (missingAnnotation + 1)
        );

        let currentSequenceResent = false;
        annotationEvents.forEach((annotationEvent) => {
          logger().logWithFields(
            LoggerLevels.debug,
            {
              feature: "Telestration",
              fileInfo: "receiveAVKTelestrationEndReportThunk",
            },
            "Trying to resend: Comparing " +
              annotationEvent.telestrationId.toString().toLowerCase() +
              " : sequence: " +
              (missingAnnotation + 1) +
              " against " +
              (annotationEvent.sequence + 1)
          );
          if (annotationEvent.sequence === missingAnnotation) {
            if (annotationEvent) {
              atLeastOneEventResent = true;
              currentSequenceResent = true;

              availKit.eventService.broadcast(annotationEvent);
              logger().logWithFields(
                LoggerLevels.info,
                {
                  feature: "Telestration",
                  fileInfo: "receiveAVKTelestrationEndReportThunk",
                },
                "Annotation Resent. " +
                  event.telestrationId +
                  " : sequence: " +
                  (missingAnnotation + 1)
              );
              // line below is present in Canvas, but results in the commented error
              // Retained in case issues arise
              // break; // !! Jump target cannot cross function
            }
          }
        });

        if (!currentSequenceResent) {
          logger().logWithFields(
            LoggerLevels.info,
            {
              feature: "Telestration",
              fileInfo: "receiveAVKTelestrationEndReportThunk",
            },
            telestrationId +
              " : Skip Resend : " +
              (missingAnnotation + 1) +
              ". Possibly received during transmission."
          );
        }
        /* If at least one event was resent, send end of telestration event */
        if (atLeastOneEventResent) {
          logger().logWithFields(
            LoggerLevels.debug,
            {
              feature: "Telestration",
              fileInfo: "receiveAVKTelestrationEndReportThunk",
            },
            "Preparing to resend End: " + event.telestrationId
          );
          dispatch(telestrationActions.setSendEvent({ type: "END" }));
        }
      });
    }
  } catch (error: any) {
    logger().logWithFields(
      LoggerLevels.error,
      {
        feature: "Telestration",
        fileInfo: "receiveAVKTelestrationEndReportThunk",
      },
      "Error occurred while receiving AVKTelestrationEndReport event",
      error?.message
    );
  }
};
