import React from "react";
import { connect } from "react-redux";

import { Dispatch } from "redux";

import { ERROR_CODES } from "../../../commoncall/store/errorCodes";
import { logger } from "../../common/logger";
import { setCallState } from "../../store/meeting/actions";
import { AppState, AvailMediaError, CallState, CallStateType } from "../../store/models";
import { endCall } from "../../store/user/actions";
import "./InMeetingErrorDialog.scss";

interface StateProps {
  twilioError: boolean;
  twilioErrorMessage: string[];
  mediaErrorCode: string;
}

interface DispatchProps {
  endCall: () => void;
  setCallState: (callState: CallStateType) => void;
}

type Props = StateProps & DispatchProps;

class InMeetingErrorDialog extends React.Component<Props> {
  async endCallForTheUser(): Promise<void> {
    this.props.endCall();
  }

  confirmEndCall(): void {
    this.props.setCallState(CallState.ENDCALLCONFIRMED);
    this.endCallForTheUser()
      .then(() => {
        this.props.setCallState(CallState.READY);
      })
      .catch((e) => {
        logger().error(e);
      });
  }

  render() {
    const { mediaErrorCode } = this.props;
    const mediaError: AvailMediaError = ERROR_CODES[mediaErrorCode];
    const shouldShowActionMessage: boolean = mediaError.actionMessage !== null;

    return (
      <div className="avail-portal-call-overlay">
        <div className="avail-portal-call-overlay-dialog">
          <div className="in-meeting-error-alert-symbol-warning"></div>
          <div className="in-meeting-error-title">{mediaError.title}</div>
          {shouldShowActionMessage && (
            <div className="in-meeting-error-message">{mediaError.message}</div>
          )}
          <div className="in-meeting-error-action-message">
            {mediaError.actionMessage}
          </div>
          <div className="avail-portal-call-overlay-dialog-end-button-holder">
            <button className="confirm-end-call-button">
              <div
                className="avail-portal-call-overlay-dialog-button-text"
                onClick={() => this.confirmEndCall()}
              >
                END CALL
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  twilioError: state.twilio.error,
  twilioErrorMessage: state.twilio.errorMessage,
  mediaErrorCode: state.twilio.mediaErrorCode
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    endCall: () => dispatch(endCall()),
    setCallState: (callState: CallStateType) =>
      dispatch(setCallState(callState))
  };
};

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(InMeetingErrorDialog);
