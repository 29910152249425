import { PROVIDER } from './ProviderActionTypes';

export const getAllProvidersAction = params => ({
	type: PROVIDER.GET_ALL_PROVIDERS,
	payload: params,
});

export const createNewProviderAction = providerData => ({
	type: PROVIDER.CREATE_NEW_PROVIDER,
	payload: providerData,
});

export const applyToSecondaryManagerAction = (userId, managerId, providerId, providerType, params) => ({
	type: PROVIDER.APPLY_TO_SECONDARY_MANAGER,
	userId,
	managerId,
	providerId,
	providerType,
	params,
});

export const deleteProviderAction = providerId => ({
	type: PROVIDER.DELETE_PROVIDER,
	payload: providerId,
});

export const getProviderByIdAction = providerId => ({
	type: PROVIDER.GET_PROVIDER_BY_ID,
	payload: providerId,
});

export const updateProviderByIdAction = (providerId, providerData) => ({
	type: PROVIDER.UPDATE_PROVIDER_BY_ID,
	providerId,
	providerData,
});

export const getProviderUsersAction = (providerType, providerId, params) => ({
	type: PROVIDER.GET_PROVIDER_USERS,
	providerType,
	providerId,
	params,
});
