import { Box, Grid, Typography } from "@mui/material";

import { LoginPage } from "src/domains/Login";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

export const UserDenied = () => (
  <LoginPage className="login">
    <Grid container spacing={2} direction={"column"}>
      <Grid item>
        <Box m={2}>
          <Typography variant="h4" align={"center"}>
            {DomainConstants.label.mfaUserSuspendedTitle}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box m={2}>
          <Typography variant="body1" align={"center"}>
            You have reached the passcode entry limit. Please, contact the
            support center at <b>{DomainConstants.support.email}</b> or call{" "}
            <b>{DomainConstants.support.phoneNumber}</b>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </LoginPage>
);
