export const HOSPITALS = {
	GET_HOSPITALS: 'HOSPITALS_GET_HOSPITALS',
	SET_HOSPITALS: 'HOSPITALS_SET_HOSPITALS',
	GET_ALL_HOSPITALS: 'HOSPITALS_GET_ALL_HOSPITALS',
	SET_ALL_HOSPITALS: 'HOSPITALS_SET_ALL_HOSPITALS',
	GET_HOSPITAL_BY_ID: 'HOSPITALS_GET_HOSPITAL_BY_ID',
	SET_HOSPITAL: 'HOSPITALS_SET_HOSPITAL',
	CREATE_NEW_HOSPITAL: 'HOSPITALS_CREATE_NEW_HOSPITAL',
	DELETE_HOSPITAL: 'HOSPITALS_DELETE_HOSPITAL',
	SET_HOSPITALS_PAGINATION: 'HOSPITALS_SET_HOSPITALS_PAGINATION',
	UPDATE_HOSPITAL: 'HOSPITALS_UPDATE_HOSPITAL',
	GET_HOSPITAL_ROOMS: 'HOSPITALS_GET_HOSPITAL_ROOMS',
	SET_HOSPITAL_ROOMS: 'HOSPITALS_SET_HOSPITAL_ROOMS',
	CREATE_HOSPITAL_ROOM: 'HOSPITALS_CREATE_HOSPITAL_ROOM',
	HOSPITAL_GET_ALL_HOSPITAL_ADMINS: 'HOSPITALS_GET_ALL_HOSPITAL_ADMINS',
	HOSPITAL_SET_ALL_HOSPITAL_ADMINS: 'HOSPITALS_SET_ALL_HOSPITAL_ADMINS',
	CREATE_HOSPITAL_USER: 'HOSPITALS_CREATE_HOSPITAL_USER',
	DELETE_HOSPITAL_ROOM: 'HOSPITALS_DELETE_HOSPITAL_ROOM',
	DELETE_HOSPITAL_DEPARTMENT: 'HOSPITALS_DELETE_HOSPITAL_DEPARTMENT',
	SET_ORG_REMEMBER_FREQUENCY: 'HOSPITALS_SET_ORG_REMEMBER_FREQUENCY',
	SET_ORG_TWO_FACTOR_AUTH: 'HOSPITALS_SET_ORG_TWO_FACTOR_AUTH',
	SET_ORG_FREQUENCY_TYPES: 'HOSPITALS_SET_ORG_FREQUENCY_TYPES',
	GET_HOSPITAL_ORG_SETTINGS_BY_ID: 'HOSPITALS_GET_HOSPITAL_ORG_SETTINGS_BY_ID',
	TOGGLE_MFA_ORG_LEVEL_SETTINGS: 'HOSPITALS_TOGGLE_MFA_ORG_LEVEL_SETTINGS',
};
