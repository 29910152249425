import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { uiActions } from "src/domains/Beacon/store/ui";
import { UISidePanelContentOptions } from "src/domains/Beacon/store/ui/types";
import { logger } from "src/logging/logger";

// closes side panel view when LAYOUTS or PRESETS view is open
export const handleSidePanelCloseWhenLayoutsOrPresetsOpen = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "ui/handleSidePanelCloseWhenLayoutsOrPresetsOpen",
  (_arg, { getState, dispatch }) => {
    const { ui } = getState();

    const sidePanelContent = ui.sidePanel.content;

    // if the presets or layouts side panel is open, we want to close it
    if (
      sidePanelContent === UISidePanelContentOptions.LAYOUTS ||
      sidePanelContent === UISidePanelContentOptions.PRESETS ||
      sidePanelContent === UISidePanelContentOptions.LAYOUTS_PRESETS
    ) {
      logger().info(
        "Closing side panel since it was open on LAYOUTS or PRESETS view"
      );
      // then close the side panel and set the content back to Settings content
      dispatch(uiActions.setSidePanelOpen(false));
      dispatch(
        uiActions.setSidePanelContent(UISidePanelContentOptions.SETTINGS)
      );
    }
  }
);
