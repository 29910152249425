import { P2P_LAUNCH_MODE } from "../../constants";
import { CallState, MeetingStateType } from "../models";
import { MeetingActionKeys, MeetingActionType } from "./actionTypes";

const initialState: MeetingStateType = {
  bluetoothEnabled: null,
  callSid: "",
  callState: CallState.READY,
  callEventInfo: null,
  callback: false,
  callbackInfo: null,
  cameras: [],
  camerasHaveUpdate: false,
  currentUserRole: null,
  enableInCallControls: false,
  consoleHasExternalInputResizing: false,
  layoutFrames: [],
  layoutHasUpdate: false,
  triggerRefreshFrames: false,
  mode: P2P_LAUNCH_MODE,
  // mode: "", // TODO: this is what we want ideally
  multiPartyCallEventInfo: null,
  multiPartyCallEventDetail: null,
  noiseCancellation: null,
  noiseCancellationAvailable: null,
  telestrationHistory: [],
  zoomState: {
    sliderZoom: [
      {
        name: "Left",
        cameraId: "",
        value: 0,
        settings: {
          start: 0,
          min: 0,
          max: 10,
          step: 1,
          onChange: null,
        },
      },
      {
        name: "Right",
        cameraId: "",
        value: 0,
        settings: {
          start: 0,
          min: 0,
          max: 10,
          step: 1,
          onChange: null,
        },
      },
    ],
    radioButtonLeft: {
      radioEnabled: true,
      checked: true,
    },
    radioButtonRight: {
      radioEnabled: true,
      checked: false,
    },
  },
  freezeFrameState: {
    menuOptions: [
      {
        name: "Left Screen",
        id: "FFO_LEFT",
        memberOf: "FFO",
        isVisible: true,
        isDisabled: false,
        css: null,
      },
      {
        name: "Right Screen",
        id: "FFO_RIGHT",
        memberOf: "FFO",
        isVisible: true,
        isDisabled: false,
        css: null,
      },
      {
        name: "Fullscreen",
        id: "FFO_FULLSCREEN",
        memberOf: "FFO",
        isVisible: true,
        isDisabled: false,
        css: null,
      },
    ],
    freezeType: "",
    isFullScreen: false,
  },
  freezeFrameHasUpdate: false,
  enableSSFF: false, // 1/2021 Disabling SSFF for future release
  joinId: "",
};

const meeting = (
  state = initialState,
  action: MeetingActionType
): MeetingStateType => {
  switch (action.type) {
    case MeetingActionKeys.SET_CALL_SID:
      return { ...state, callSid: action.callSid };

    case MeetingActionKeys.SET_CALL_STATE:
      return { ...state, callState: action.callState };

    case MeetingActionKeys.SET_CALLBACK:
      return { ...state, callback: action.callback };

    case MeetingActionKeys.SET_CALLBACK_INFO:
      return { ...state, callbackInfo: action.callbackInfo };

    case MeetingActionKeys.SET_MULTI_PARTY_CALL_EVENT_INFO:
      return {
        ...state,
        mode: "MP",
        multiPartyCallEventInfo: action.multiPartyCallEventInfo,
      };

    case MeetingActionKeys.SET_CALL_MODE:
      return {
        ...state,
        mode: action.payload,
      };

    case MeetingActionKeys.SET_CURRENT_USER_ROLE:
      return {
        ...state,
        currentUserRole: action.userRole,
      };

    case MeetingActionKeys.SET_MULTI_PARTY_EVENT_DETAILS:
      return {
        ...state,
        multiPartyCallEventDetail: action.multiPartyCallEventDetail,
      };

    case MeetingActionKeys.SET_TELESTRATION_HISTORY:
      return {
        ...state,
        telestrationHistory: action.telestrationHistory,
      };

    case MeetingActionKeys.SET_REMOTE_EVENT:
      return { ...state, callEventInfo: action.callEventInfo };

    case MeetingActionKeys.SET_CAMERAS_LIST:
      return { ...state, cameras: action.cameras };

    case MeetingActionKeys.SET_CAMERAS_HAVE_UPDATE:
      return { ...state, camerasHaveUpdate: action.shouldUpdateCameras };

    case MeetingActionKeys.SET_LAYOUTFRAMES_LIST:
      return { ...state, layoutFrames: JSON.parse(JSON.stringify(action.layoutFrames)) };

    case MeetingActionKeys.SET_LAYOUT_HAS_UPDATE:
      return { ...state, layoutHasUpdate: action.isUpdated };

    case MeetingActionKeys.SET_TRIGGER_REFRESH_FRAMES:
      return {
        ...state,
        triggerRefreshFrames: action.shouldTriggerRefreshFrames,
      };

    case MeetingActionKeys.SET_ZOOMSTATE:
      return { ...state, zoomState: JSON.parse(JSON.stringify(action.zoomState)) };

    case MeetingActionKeys.SET_SHOULD_ENABLE_IN_CALL_CONTROLS:
      return {
        ...state,
        enableInCallControls: action.enableInCallControls,
      };

    case MeetingActionKeys.SET_EXTERNAL_INPUT_RESIZING:
      return {
        ...state,
        consoleHasExternalInputResizing: action.consoleHasExternalInputResizing,
      };

    case MeetingActionKeys.SET_BLUETOOTH_ENABLED:
      return {
        ...state,
        bluetoothEnabled: action.bluetoothEnabled,
      };
    case MeetingActionKeys.SET_NOISE_CANCELLATION_STATE:
      return {
        ...state,
        noiseCancellation: action.noiseCancellation,
      };
    case MeetingActionKeys.SET_NOISE_CANCELLATION_AVAILABLE:
      return {
        ...state,
        noiseCancellationAvailable: action.noiseCancellationAvailable,
      };

    case MeetingActionKeys.SET_JOIN_ID:
      return { ...state, joinId: action.joinId };

    case MeetingActionKeys.SET_FREEZE_STATE:
      return {
        ...state,
        freezeFrameState: action.freezeFrameState,
      };

    case MeetingActionKeys.SET_FREEZEFRAME_HAS_UPDATE:
      return {
        ...state,
        freezeFrameHasUpdate: action.updateFreezeFrame,
      };

    default:
      return state;
  }
};

export default meeting;
