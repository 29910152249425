import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { logger } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

// This is an example of a normal thunk, in contrary to thunks using `createAsyncThunk`. These can be mixed depending on what is wanted.
// `createAsyncThunk` gives us built in actions being fired for pending(loading)/fulfilled/rejected states (this is ideal when calling external APIs).
// Normal thunks just doing internal stuff (in this case we're initializing the twilio-video lib)
export const rejoinPubnubChannelsThunk = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  try {
    logger().info("Re-joining PubNub channels and session");

    const {
      meeting: { callSid, pubnub_channel },
    } = getState();

    const availKitInstance = AvailKitService.instance;
    logger().info(
      `Making an attempt to rejoin the session channel ${pubnub_channel}`
    );
    availKitInstance.eventService.join(pubnub_channel);
    availKitInstance.eventService.join(callSid);
  } catch (error: any) {
    logger().error("Error while joining PubNub channels", error?.message);
  }
};
