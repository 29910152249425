import { RemoteParticipant, RemoteTrack } from "twilio-video";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import { handleSetAudioOutputDeviceThunk } from "src/domains/Beacon/store/stream/thunks/handleSetAudioOutputDeviceThunk";
import { getTwilioLoginId } from "src/domains/Beacon/utils/twilio";
import { LoggerLevels, logger } from "src/logging/logger";

// Used when a new participant's track subscription is fired in the `useRoomTrackSubscribed`
export const handleParticipantTrackSubscribedThunk = (
  participant: RemoteParticipant,
  newTrack: RemoteTrack
) => (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    logger().logWithFields(
      LoggerLevels.info,
      {
        feature: "Media Devices",
        fileInfo: "handleParticipantTrackSubscribedThunk.ts",
      },
      `Subscribing to new Participant's track: ${newTrack?.name} with identity: ${participant.identity}`
    );
    const {
      stream: {
        localMedia: { speaker },
      },
    } = getState();

    if (newTrack.kind === "video") {
      // TODO: panelist with video? could be implemented in a future
      logger().warn(
        `Participant's video received with identity: ${participant.identity} and track name: ${newTrack?.name}, will be ignore since only Host streams video`
      );
    } else if (newTrack.kind === "audio") {
      const loginId = getTwilioLoginId(participant.identity);
      dispatch(
        streamActions.setParticipantAudioTrack({
          loginId,
          audioTrack: newTrack,
        })
      );

      // By the moment we set the audio-output device, the mediaElement returned by "attach"
      // could not have changed yet, thus, must get it here and pass it
      const mediaElement = newTrack.attach();
      dispatch(
        handleSetAudioOutputDeviceThunk({
          mediaElement,
          audioOutputDevice: speaker,
        })
      );
    }
  } catch (error: any) {
    logger().error(
      "An error occurred when new Participant's track subscribed",
      error?.message
    );
  }
};
