import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
/* Components */
import ModalComponent from '../../../../../../../../../Common/Components/Modal/Modal';
import { Table } from '../../../../../../../Common';
import BusinessUnitUserTableRow from '../BusinessUnitUserTableRow/BusinessUnitUserTableRow';
/* Actions */
import { applyToSecondaryManagerAction, getBusinessUnitUsersAction } from '../../../../VendorsActions';
import { getAllDescendantUsersForManagerAction } from '../../../../../../Users/UsersActions';
import { isAvailAdminUser } from "src/utils/user.ts";

class BusinessUnitUsers extends PureComponent {
	state = {
		isAssociateMeModalOpened: false,
		user: null,
	};

	componentDidMount() {
		this.params = {
			size: this.pageSize,
			page: 0,
		};
		this.props.getBusinessUnitUsers(this.props.businessUnitType, this.props.businessUnitId, this.params);
		this.props.getAllDescendantUsersForManager(this.props.currentUser && this.props.currentUser.id);
    const isAvailAdmin = isAvailAdminUser(this.props.currentUser.userTypes);
    isAvailAdmin && this.columns.push({
      title: 'User Type',
      sortValue: 'memberType',
      excluded: false,
    });
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.businessUnitId !== nextProps.businessUnitId) {
			this.params = {
				size: this.pageSize,
				page: 0,
			};
			nextProps.getBusinessUnitUsers(nextProps.businessUnitType, nextProps.businessUnitId, this.params);
		}
	}

	onAssociateMeConfirm = () => {
		this.props.applyToSecondaryManager(this.props.currentUser.id, this.state.user.id, this.props.businessUnitId, this.props.businessUnitType, this.params);
		this.toggleAssociateMeModal();
	};

	onTableFilterHandler = (value, parameter = 'name') => {
		this.sortParams = value;
		this.parameter = parameter;
		this.params = parameter === 'name' ? {
			size: this.pageSize,
			page: 0,
		} : this.params;
		this.params = {
			...this.params,
			[this.parameter]: this.sortParams,
		};
		this.props.getBusinessUnitUsers(this.props.businessUnitType, this.props.businessUnitId, this.params);
	};

	onPageChangeHandler = (e, { activePage }) => {
		this.params = {
			...this.params,
			size: this.pageSize,
			page: activePage - 1,
			[this.parameter]: this.sortParams,
		};
		this.props.getBusinessUnitUsers(this.props.businessUnitType, this.props.businessUnitId, this.params);
	};

	toggleAssociateMeModal = (user) => {
		this.setState({
			isAssociateMeModalOpened: !this.state.isAssociateMeModalOpened,
			user,
		});
	};

	pageSize = 10;

	columns = [
		{
			title: 'Name',
			sortValue: 'firstName',
			alias: 'name',
			excluded: false,
		}, {
			title: 'Role',
			excluded: false,
		},
	];

	renderAssociateMeModal = () => (
		<ModalComponent
			open={this.state.isAssociateMeModalOpened}
			onConfirm={this.onAssociateMeConfirm}
			onCancel={this.toggleAssociateMeModal}
			confirmText="Associate me"
			title="Associate to secondary manager"
		>
			{this.state.user && (
				<span>
				Are you sure that you want associate
					<span className="text--bold">
						{` ${this.state.user.firstName} ${this.state.user.lastName} `}
					</span>
					as your secondary manager?
				</span>
			)}
		</ModalComponent>
	);

	render() {
		return (
			<div>
				<Table
					row={BusinessUnitUserTableRow}
					columns={this.columns}
					tableData={this.props.businessUnitUsers}
					openModal={this.toggleAssociateMeModal}
					paginationConfig={this.props.pagination}
					onPageChangeHandler={this.onPageChangeHandler}
					onSortTableHandler={this.onTableFilterHandler}
					onSearchTermUpdateHandler={this.onTableFilterHandler}
					noEntityComponent={
						<div className="profile__right-content-wrapper">No users available</div>
					}
					search
					sortable
				/>
				{this.renderAssociateMeModal()}
			</div>
		);
	}
}

BusinessUnitUsers.propTypes = {};

const mapStateToProps = state => ({
	businessUnitUsers: state.vendorsReducer.businessUnitUsers,
	pagination: state.vendorsReducer.pagination,
	currentUser: state.homeReducer.currentUser,
});

const mapDispatchToProps = dispatch => ({
	getBusinessUnitUsers: (businessUnitType, businessUnitId, params) => dispatch(getBusinessUnitUsersAction(businessUnitType, businessUnitId, params)),
	applyToSecondaryManager: (userId, managerId, businessUnitId, businessUnitType, params) => dispatch(applyToSecondaryManagerAction(userId, managerId, businessUnitId, businessUnitType, params)),
	getAllDescendantUsersForManager: managerId => dispatch(getAllDescendantUsersForManagerAction(managerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'businessUnitUsersForm',
})(BusinessUnitUsers));
