export default class arrayService {
	static ascSort(content, key) {
		return content.sort((a, b) => a[key].localeCompare(b[key]));
	}

	static descSort(content, key) {
		return content.sort((a, b) => b[key].localeCompare(a[key]));
	}

	static sortQuery(content, params) {
		const { name, sort, type } = params;

		if (sort) {
			const [key, order] = sort.split(',');
			const sortedData = order === 'asc' ? this.ascSort(content, key) : this.descSort(content, key);

			if (name || type) {
				const filterType = name ? 'name' : 'type';
				return sortedData.filter(data => data[filterType].toLowerCase().indexOf(name || type) !== -1);
			}

			return sortedData;
		}

		if (name || type) {
			const filterType = name ? 'name' : 'type';
			const filterStr = name ? name.toLowerCase() : type.toLowerCase();
			return content.filter(data => data[filterType].toLowerCase().indexOf(filterStr) !== -1);
		}

		return content;
	}

	static sortArray(content, params) {
		const { name, sort } = params;

		if (sort) {
			const order = sort.split(',')[1];
			if (order === 'asc') {
				return content.sort((a, b) => {
					if (a < b) { return -1; }
					if (a > b) { return 1; }
					return 0;
				});
			}
			return content.sort((a, b) => {
				if (a < b) { return 1; }
				if (a > b) { return -1; }
				return 0;
			});
		}

		if (name) {
			return content.filter(str => str.toLowerCase().indexOf(name) !== -1);
		}
	}
}
