import { useEffect } from "react";

import { Paper, Typography } from "@mui/material";

import { IPAD_APP_STORE_LINK } from "src/constants";
import LogService from "src/services/LogService";
import { hideReCaptcha } from "src/services/ReCaptchaHelper";

import {
  newlogo2021,
  appStoreLogo,
} from "avail-web-application/Common/Assets/Images/index.js";

import styles from "./styles.scss";

export function UnsupportedIpadError() {
  useEffect(() => {
    hideReCaptcha();
    LogService.error({
      message: "Browser failed to redirect to App or App Store",
    });
  }, []);

  return (
    <section className={styles.ipadLandingPage}>
      <img className="logo" src={newlogo2021} alt="logo" width={"300px"} />
      <Paper elevation={3} className={styles.errorMessage}>
        <Typography variant={"h6"}>
          In order to use Avail on iPad, you must first download the app from
          the App Store
        </Typography>
        <Typography variant={"subtitle1"}>
          Tap the button below to get started.
        </Typography>
        <img
          className={styles.appStoreLogo}
          onClick={() => (window.location.href = IPAD_APP_STORE_LINK)}
          src={appStoreLogo}
        />
        <Typography variant={"subtitle2"}>
          Need help? Contact{" "}
          <a href="mailto:support@avail.io">Avail Technical Support</a>
        </Typography>
      </Paper>
    </section>
  );
}
