// REGEX PATTERNS
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const ONLY_LETTERS_REGEX = /^[A-Za-z]*$/;
const WHITESPACE_REGEX = /\S/;
// below regex will be only true for words and single spaces between them: "test", "test this"
// these will return an error: "  test", "test  test", "test  "
const ONLY_LETTERS_AND_SPACES_REGEX = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
const ONLY_LETTERS_AND_NUMBERS_REGEX = /^[a-zA-Z0-9]*$/;

// letters, numbers, spaces, and period
// making this its own variable for convience, can revisit when we get more special regexs
const SURGEON_REGEX = /^([a-zA-Z.]+\s)*[a-zA-Z.]+$/;

// last name - alpha of any case, comma, period, apostrophe and hyphen (ie a-zA-Z,.'-)
const SURNAME_REGEX = /^[A-Za-z-'",.+\s]*$/;

// validation functions (used for custom validation rather than the simple builtin react-hook-from "rules" prop)
export const isValidEmail = (email: string) => EMAIL_REGEX.test(email);
export const isOnlyLettersAndSpaces = (value: string) =>
  ONLY_LETTERS_AND_SPACES_REGEX.test(value);
export const isOnlyLettersAndNumbers = (value: string) =>
  ONLY_LETTERS_AND_NUMBERS_REGEX.test(value);
export const hasOnlyWhitespace = (value: string) =>
  WHITESPACE_REGEX.test(value);

export const testTextWithCustomRegex = (value: string, regex: RegExp) =>
  regex.test(value);

export const isLessThanOrEqualTo = (value: string, length: number) =>
  value.length <= length;

// messages
export const INVALID_EMAIL_MESSAGE =
  "Please enter valid email address. Ensure it contains no spaces.";
export const ONLY_LETTERS_ERROR_MESSAGE =
  "Only letters are allowed, numbers or special characters are not permitted";
export const ONLY_LETTERS_AND_NUMBER_ERROR_MESSAGE =
  "Only letters and numbers are allowed, spaces or special characters are not permitted";

export const LENGTH_LIMIT_MESSAGE = (label: string, length: number) =>
  `${label} should not be more than ${length} characters`;

// react-hook-form validation functions below

export const minLengthField = (label: string, length: number) => ({
  minLength: {
    value: length,
    message: `${label} must be at least ${length} characters`,
  },
  pattern: {
    value: /(?!^ +$)^.+$/i,
    message: "Please do not enter all spaces",
  },
});

export const maxLengthField = (label: string, length: number) => ({
  maxLength: {
    value: length,
    message: `${label} should not be more than ${length} characters`,
  },
});

// copied to src/constants/validation.ts
export const requiredField = (label: string) => ({
  required: {
    value: true,
    message: `Please enter a ${label}`,
  },
});

// copied to src/constants/validation.ts with related variables
export const emailField = (label: string, required: boolean) => ({
  required: {
    value: required,
    message: `Please enter ${label}`,
  },
  pattern: {
    value: EMAIL_REGEX,
    message: INVALID_EMAIL_MESSAGE,
  },
});

export const macAddress = () => ({
  pattern: {
    value: /^[a-fA-F0-9[:-]{17}|[a-fA-F0-9]{12}$/i,
    message: "Invalid MAC address",
  },
});

export const domainValidator = () => ({
  pattern: {
    value: /^([a-zA-Z0-9]{1,2}|([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]))(?:\.[a-zA-Z]{2,})+$/,
    message: "Invalid domain name",
  },
});

export const numberValidator = () => ({
  pattern: {
    value: /\d/,
    message: "one upper,one lower, one number and one special character",
  },
});

export const capitalValidator = () => ({
  pattern: {
    value: /[A-Z]/,
    message: "one upper,one lower, one number and one special character",
  },
});

export const lowerValidator = () => ({
  pattern: {
    value: /[a-z]/,
    message: "one upper,one lower,one number and one special character",
  },
});

export const specialValidator = () => ({
  pattern: {
    value: /[!@#$%^&*()_-]/,
    message: "one upper,one lower,one number and one special character",
  },
});

export const passwordLength8 = (length: number) => ({
  maxLength: {
    value: length,
    message: `Minimum length is ${length} characters`,
  },
});

export const passwordLength15 = (length: number) => ({
  maxLength: {
    value: length,
    message: `Maximum length is ${length} characters`,
  },
});

export const onlyNumbers = () => ({
  pattern: {
    value: /^[0-9]*$/,
    message: "Only numbers allowed",
  },
});

export const onlyLetters = () => ({
  pattern: {
    value: ONLY_LETTERS_REGEX,
    message: ONLY_LETTERS_ERROR_MESSAGE,
  },
});

export const onlyLettersAndSpaces = () => ({
  pattern: {
    value: ONLY_LETTERS_AND_SPACES_REGEX,
    message: ONLY_LETTERS_ERROR_MESSAGE,
  },
});

export const surgeonValidation = () => ({
  pattern: {
    value: SURGEON_REGEX,
    message:
      "Only letters, spaces, and periods are allowed, other special characters or numbers are not permitted",
  },
});

export const surnameValidation = () => ({
  pattern: {
    value: SURNAME_REGEX,
    message:
      "Only letters, commas, periods, hyphens, or quotes are allowed, other special characters are not permitted",
  },
});

export const maxAllowedDigits = (label: string, length: number) => ({
  maxLength: {
    value: length,
    message: `${label} should not be more than ${length} digits`,
  },
});

export const minAllowedDigits = (label: string, length: number) => ({
  minLength: {
    value: length,
    message: `${label} must be at least ${length} digits`,
  },
});
