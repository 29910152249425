import moment from 'moment';

export const getUserTypes = state => state.usersReducer.userTypes;
export const getUserOrganisationId = state => state.usersReducer.userOrganisation && state.usersReducer.userOrganisation.id;

export const getInitialValuesForUserProfile = (state) => {
	const { user, timezone } = state.usersReducer;
	if (user) {
		return {
			...user,
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			title: user.title,
			userTypes: user.userTypes,
			timezone: timezone || moment.tz.guess(),
		};
	}

	return null;
};


export const getInitialValuesForUserOrganisation = (state) => {
	const { userOrganisation } = state.usersReducer;

	if (userOrganisation) {
		return {
			address: userOrganisation && userOrganisation.address,
			city: userOrganisation && userOrganisation.city,
			state: userOrganisation && userOrganisation.state,
			name: userOrganisation && userOrganisation.name,
			active: userOrganisation && userOrganisation.active,
		};
	}
	return null;
};

export const getInitialValuesForUserProfileTherapies = (state) => {
	const { userProcedures } = state.usersReducer;
	if (userProcedures && userProcedures.length) {
		const procedures = userProcedures.reduce((o, key) => {
			return {
				...o,
				[key.id]: true,
			};
		}, {});
		return {
			procedures,
		};
	}
};

export const getInitialValuesForVendorProfileTherapies = (state) => {
	if (state.vendorsReducer.vendorTherapies) {
		const { vendorTherapies } = state.vendorsReducer.vendorTherapies[0].procedures;
		const procedures = vendorTherapies.reduce((o, key) => {
			return {
				...o,
				[key.id]: true,
			};
		}, {});
		return {
			procedures,
		};
	}
};

export const getInitialValuesForAccountCreation = (state) => {
	const { userOrganisation, userProcedures } = state.usersReducer;
	const { currentUser } = state.homeReducer;
	
	let initialValues = null;
	if (userOrganisation) {
		initialValues = {
			...initialValues,
			name: userOrganisation.name,
		};
	}
	if (userProcedures) {
		const procedures = userProcedures.length && userProcedures.reduce((o, key) => {
			return {
				...o,
				[key.id]: true,
			};
		}, {});
		initialValues = {
			...initialValues,
			procedures,
		};
	}
	if (currentUser) {
		initialValues = {
			...initialValues,
			user: {
				...currentUser,
			},
		};
	}
  // setting the timezone value to just guess where the user is
	// we dont need the full list of timezones from the API here because that is used in the form anyway
  // in AccountCreation if the user changes timezone then that will be updated correctly
  initialValues = {
    ...initialValues,
    userSettings: {
      timezone: moment.tz.guess(),
    },
  };
	
	return initialValues;
};

export const getAllManagersUserAppliedTo = (state) => {
	const { managersUserAppliedTo } = state.usersReducer;
	if (managersUserAppliedTo) {
		return managersUserAppliedTo.filter(manager => manager.status === 'APPROVED');
	}
};

export const getAllDescendantUsersIds = (state) => {
	const { descendantUsers } = state.usersReducer;
	if (descendantUsers && descendantUsers.length) {
		return descendantUsers.map(user => user.id);
	}
};

export const getInitialValuesForUserPreferences = (state) => {
	const { emailNotification, passphrase } = state.usersReducer;
	if (emailNotification && passphrase !== undefined) {
		return {
			value: emailNotification,
			passphrase: passphrase
		};
	} else{
		return {value: emailNotification,}
	}
};

export const getInitialValuesForUserSecurity = (state) => {
	const { isValidOTP, validPhone, phoneNumber, user } = state.usersReducer;

	return !!user ? {
		email: user.email,
		loginId: user.loginId,
		phoneNumber,
		validPhone,
		isValidOTP
	} : null;
};
