import React, { PureComponent } from 'react';

import './EntityProfileSidebar.scss';
import BackButton from '../../../../Common/Components/BackButton/BackButton';

class EntityProfileSidebar extends PureComponent {
	render() {
		return (
			<div className="entity-profile__sidebar">
				<BackButton />
				<span className="entity-profile__title">{this.props.title}</span>
				{this.props.children}
			</div>
		);
	}
}

EntityProfileSidebar.propTypes = {};

export default EntityProfileSidebar;
