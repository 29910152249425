import { FeaturesConfig } from "availkit-js/dist/Models/Events/FeatureAvailabilityEvent";

import { ConsoleCapabilitiesKeys } from "src/domains/Beacon/store/stream/types";

// Will allow to check for Console capabilities inside a features list coming form AVK,
// the only available capabilities keys are determined by ConsoleCapabilitiesKeys enum which will be our
// only source of truth
export const checkConsoleCapability = (
  capabilityKey: keyof typeof ConsoleCapabilitiesKeys,
  features: FeaturesConfig
) => {
  const capabilityName = ConsoleCapabilitiesKeys[capabilityKey];
  return features?.hasOwnProperty(capabilityName) ?? false;
};
