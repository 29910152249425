import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { Room } from "twilio-video";
import { API } from "../../../api";
import { logger } from "../../../common/logger";
import { TWILIO_REFRESH_TOKEN_TIME_MINS } from "../../../constants";
import {
  AppState,
  MeetingStateType,
  PortalIdentity,
  TwilioCredentials
} from "../../models";
import { setRefreshInProgress } from "../../user/actions";
import {
  clearTwilioTracks,
  connectCall,
  getTwilioCredentialsSuccess
} from "../actions";
import { TwilioActionKeys } from "../actionTypes";

export function* reconnectionTimerSaga() {
  logger().info("reconnectionTimerSaga -- Starting timer");

  let minutesDownCount: number = TWILIO_REFRESH_TOKEN_TIME_MINS;
  do {
    yield delay(60 * 1000);
    minutesDownCount--;
    if (minutesDownCount % 5 === 0) {
      logger().verbose(
        `Twilio reconnection time remaining: ${minutesDownCount}`
      );
    } else if (minutesDownCount < 10) {
      logger().warn(
        `Twilio token refresh imminent ( starts in ${minutesDownCount} )`
      );
    }
  } while (minutesDownCount > 0);

  logger().info("reconnectionTimerSaga -- ** Refreshing Twilio token **");

  const { callSid, joinId }: MeetingStateType = yield select(
    (state: AppState) => state.meeting
  );
  const { access_token }: PortalIdentity = yield select(
    (state: AppState) => state.user.identity
  );

  const { data, error } = yield call(
    API.GET.refreshTwilioToken,
    access_token,
    callSid,
    joinId
  );

  if (error) {
    logger().error(`Failed to acquire new Twilio token: ${error}`);
  } else {
    logger().debug("Got new twilio credentials");
    const newTwilioCreds: TwilioCredentials = {
      token: data
    };

    yield call(reconnectionSaga, newTwilioCreds);
  }
}

export function* reconnectionSaga(newTwilioCreds: TwilioCredentials) {
  logger().info("Twilio is reconnecting!");
  const room: Room = yield select((state: AppState) => state.twilio.room);
  room.disconnect();

  /* This helps to log the state so that if there is an issue in future, we
  can have more information to debug. */
  yield put(setRefreshInProgress(true));
  logger().info("Clearing current remote tracks");
  yield put(clearTwilioTracks());
  yield put(connectCall());
  yield put(getTwilioCredentialsSuccess(newTwilioCreds));
}

export function* watchTwilioReconnection() {
  yield takeLatest(
    TwilioActionKeys.TWILIO_RECONNECTION_TIMER,
    reconnectionTimerSaga
  );
}
