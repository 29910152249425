import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import { selectStreamState } from "src/domains/Beacon/store/stream/selectors";
import { handleSidePanelCloseWhenLayoutsOrPresetsOpen } from "src/domains/Beacon/store/stream/thunks/handleSidePanelCloseWhenNoConsole";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { logger } from "src/logging/logger";

// when the console video turns off we want to run some code
export const useOnConsoleVideoTurnedOff = () => {
  const dispatch = useDispatch();

  const { consoleHasJoinedRoom } = useAppSelector(selectTwilioState);
  const { console: consoleState } = useAppSelector(selectStreamState);
  const isConsoleVideoTurnedOff =
    consoleHasJoinedRoom && consoleState.video === "mute";

  useEffect(() => {
    // if the console video is turned off then we want to make sure to close the side panel
    // if the side panel is open on certain views
    if (isConsoleVideoTurnedOff) {
      logger().info("Console has turned off video");
      dispatch(handleSidePanelCloseWhenLayoutsOrPresetsOpen());
    }
  }, [isConsoleVideoTurnedOff]);
};
