/** IconButton
 * Extends MUI IconButton, to be used for small icon buttons.
 * Differs from Button variation=icon:
 *  - takes an svg Icon component and handles color
 *  - is a round button element, not square
 * Requires an aria-label to force accessibility
 */
import React from "react";

import clsx from "clsx";

import {
  IconButton as MUIIconButton,
  IconButtonProps as MUIIconButtonProps,
} from "@mui/material";

import styles from "./styles.scss";

// adds new MuiIconButton.props.color for Tungsten colors
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    white: true;
    black: true;
    blue: true;
    red: true;
    gray: true;
  }
}

export interface IconButtonProps extends MUIIconButtonProps {
  "data-test-id"?: string;
  children: React.ReactNode;
  "aria-label": string; // required for accessibility
  color?: "white" | "black" | "blue" | "gray" | "red";
  background?: "dark" | "light";
  className?: string;
}

export const IconButton = ({
  children,
  background,
  color = "blue",
  className,
  ...props
}: IconButtonProps) => {
  return (
    <MUIIconButton
      classes={{
        root: clsx(
          styles.root,
          styles[`child--${color}`],
          styles[`background--${background}`],
          className
        ),
      }}
      component="span"
      aria-label={props["aria-label"]}
      data-test-id={props["data-test-id"] || null}
      {...props}
    >
      {children}
    </MUIIconButton>
  );
};
