import {
  AVKCamera,
  NCameraMount,
  NCameraType,
} from "availkit-js/dist/Models/AVKCamera";
import { AVKUserPresetEvent } from "availkit-js/dist/Models/Events/AVKUserPresetEvent";
import { NCameraAnnouncementEvent } from "availkit-js/dist/Models/Events/NCameraAnnouncement";
import { NCameraDiscoveryServiceListener } from "availkit-js/dist/Services/Listeners/NCameraDiscoveryServiceListener";
import { NCameraDiscoveryService } from "availkit-js/dist/Services/NCameraDiscoveryService";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/domains/Beacon/store";
import {
  selectIsUserHost,
  selectMeetingState,
  selectThirdPartyIntegration,
} from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { streamActions } from "src/domains/Beacon/store/stream/streamSlice";
import {
  DefaultCameraPosition,
  LayoutFrames,
  ZoomFrames,
} from "src/domains/Beacon/store/stream/types";
import {
  camerasSortedByName,
  getCameraDisplayName,
  getCameraIdentifier,
  videoSourceZoomLevel,
} from "src/domains/Beacon/utils/availKit";
import { FEATURE_FLAGS } from "src/featureFlags";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

// AvailKit takes care of sending and receiving all the events being passed
// in and out of the pubnub channel we are in, we have to set up the individual
// listeners (functions) that respond to certain types of events

// In this case, we listen for events being retrieved by the sidebarService
// for reference, check `AVKSidebarService` on `availkit-js` project.

export const useDidDiscoverCameras = () => {
  const { mode, callSid } = useAppSelector(selectMeetingState);
  const isHostUser = useAppSelector(selectIsUserHost);
  const dispatch = useDispatch();
  const availKit = AvailKitService.instance;
  const { enableORTelligence } = FEATURE_FLAGS;
  const thirdPartyIntegration = useAppSelector(selectThirdPartyIntegration);

  // will be executed whenever the didDiscoverCameras is triggered/called
  const didDiscoverCameras = (
    service: NCameraDiscoveryService,
    event: NCameraAnnouncementEvent,
    cameras: AVKCamera[]
  ) => {
    try {
      logger().info("didDiscoverCameras");

      const avkCameras: AVKCamera[] = [...cameras];
      if (enableORTelligence) {
        thirdPartyIntegration?.map((integration) => {
          // Must add integrations by checking if enabled only
          if (integration.enabled) {
            const ortIFrame: AVKCamera = new AVKCamera();
            ortIFrame.cameraIdentifier = integration.partner;
            ortIFrame.name = integration.partner;
            ortIFrame.type = NCameraType.DigitalInput;
            avkCameras.push(ortIFrame);
          }
        });
      }

      const sortedCameras = camerasSortedByName(avkCameras);

      dispatch(streamActions.setCameras(avkCameras));

      const defaultLeftTopCamera = sortedCameras[0];
      const defaultRightTopCamera = sortedCameras[1];
      const defaultLeftBottomCamera = sortedCameras[2];
      const defaultRightBottomCamera = sortedCameras[3];

      // set default camera positions
      const cameraPositions: DefaultCameraPosition = Object.fromEntries(
        avkCameras.slice(0, 2).map(({ name, location }) => [
          name.toLowerCase(),
          {
            x: location.x,
            y: location.y,
          },
        ])
      );

      dispatch(streamActions.setDefaultCameraPositions(cameraPositions));

      const layoutFrames: LayoutFrames = {
        leftTop: {
          cameraId: getCameraIdentifier(defaultLeftTopCamera),
          cameraLabel: getCameraDisplayName(defaultLeftTopCamera),
          isFullScreen: false,
        },
        rightTop: {
          cameraId: getCameraIdentifier(defaultRightTopCamera),
          cameraLabel: getCameraDisplayName(defaultRightTopCamera),
          isFullScreen: false,
        },
        leftBottom: {
          cameraId: getCameraIdentifier(defaultLeftBottomCamera),
          cameraLabel: getCameraDisplayName(defaultLeftBottomCamera),
          isFullScreen: false,
        },
        rightBottom: {
          cameraId: getCameraIdentifier(defaultRightBottomCamera),
          cameraLabel: getCameraDisplayName(defaultRightBottomCamera),
          isFullScreen: false,
        },
      };
      dispatch(streamActions.setLayoutFrames(layoutFrames));

      // set up zoomState
      const localZoomState: ZoomFrames = {
        leftTop: {
          cameraId: getCameraIdentifier(defaultLeftTopCamera),
          value: videoSourceZoomLevel(defaultLeftTopCamera),
          settings: {
            start: videoSourceZoomLevel(defaultLeftTopCamera),
            min: 0,
            max: 10,
            step: 1,
          },
        },
        rightTop: {
          cameraId: getCameraIdentifier(defaultRightTopCamera),
          value: videoSourceZoomLevel(defaultRightTopCamera),
          settings: {
            start: videoSourceZoomLevel(defaultRightTopCamera),
            min: 0,
            max: 10,
            step: 1,
          },
        },
        leftBottom: {
          cameraId: getCameraIdentifier(defaultLeftBottomCamera),
          value: videoSourceZoomLevel(defaultLeftBottomCamera),
          settings: {
            start: videoSourceZoomLevel(defaultLeftBottomCamera),
            min: 0,
            max: 10,
            step: 1,
          },
        },
        rightBottom: {
          cameraId: getCameraIdentifier(defaultRightBottomCamera),
          value: videoSourceZoomLevel(defaultRightBottomCamera),
          settings: {
            start: videoSourceZoomLevel(defaultRightBottomCamera),
            min: 0,
            max: 10,
            step: 1,
          },
        },
      };
      dispatch(streamActions.setZoomState(localZoomState));

      // TODO: set the zoom state and camera location
      // check file portalcall/src/commoncall/components/InCallControls/InCallControls.tsx
      // inside the function setupInitialCameras()

      if (mode === CallModes.MP && isHostUser) {
        const userPresetEvent = new AVKUserPresetEvent(callSid);

        const mountCameras: AVKCamera[] = (new Set(
          [...event.cameras].filter((camera) => {
            return (
              camera.mount === NCameraMount.FrontFixed ||
              camera.mount === NCameraMount.OverheadBoom
            );
          })
        ) as unknown) as AVKCamera[];

        userPresetEvent.cameras = mountCameras.splice(0, 2);
        availKit.eventService.broadcast(userPresetEvent);
      }
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useDidDiscoverCameras",
        },
        "Error while receiving didDiscoverCameras.",
        error?.message
      );
    }
  };

  useEffect(() => {
    const cameraDiscoveryListener: NCameraDiscoveryServiceListener = {
      didDiscoverCameras,
    };

    availKit?.cameraDiscoveryService.addEventListener(cameraDiscoveryListener);

    // When this is unmounted, remove the listeners
    return () =>
      availKit?.cameraDiscoveryService.removeEventListener(
        cameraDiscoveryListener
      );
  }, [availKit, thirdPartyIntegration]);
};
