import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import { Participant } from "twilio-video";
import {
  AppState,
  MediaStateType,
  MultiPartyEventDetail,
  MultiPartyEventParticipant
} from "../../store/models";
import ActiveSpeaker from "../ActiveSpeaker";
import { getLoginId } from "../Roster/utils";
import "./PipMedia.scss";

interface StateProps {
  dominantSpeaker: string; // Avail UUID
  eventParticipants: MultiPartyEventParticipant[] | undefined;
  mpEventDetail: MultiPartyEventDetail | null;
  mediaState: MediaStateType;
  twilioParticipants: Participant[];
  currentUserLoginId: string;
  children: React.ReactNode
}

type Props = StateProps;

const PipMedia: React.FC<Props> = ({
  children,
  dominantSpeaker,
  eventParticipants,
  mediaState,
  twilioParticipants,
  currentUserLoginId
}) => {
  const [activeDrags, setActiveDrags] = useState(0);
  const [isDominantSpeakerHost, setIsDominantSpeakerHost] = useState(false);
  const [
    activeSpeaker,
    setActiveSpeaker
  ] = useState<MultiPartyEventParticipant | null>(null);

  const getHost = () => {
    return eventParticipants?.find(u => u.role === "HOST");
  };

  // Dominant speaker loginId -> event participant mapping
  useEffect(() => {
    // get loginId for new dominantSpeaker
    const speakerLoginId = getLoginId(dominantSpeaker);

    if (eventParticipants) {
      const activeSpeaker = eventParticipants.find(
        user => user.loginId === speakerLoginId
      );
      const host = getHost();

      if (activeSpeaker) {
        // check if current dominantSpeaker is host
        if (host?.loginId === activeSpeaker.loginId) {
          setIsDominantSpeakerHost(true);
        } else {
          setIsDominantSpeakerHost(false);
        }

        setActiveSpeaker(activeSpeaker ? activeSpeaker : null);
      } else {
        setIsDominantSpeakerHost(false);
      }
    }
  }, [dominantSpeaker, eventParticipants]);

  const onStart = () => setActiveDrags(activeDrags + 1);
  const onStop = () => setActiveDrags(activeDrags - 1);

  const getParticipantName = (host?: boolean) => {
    const speaker = host ? getHost() : activeSpeaker;

    const first = speaker?.firstName ? speaker.firstName : "";
    const last = speaker?.lastName ? speaker.lastName : "";
    if (!first || !last) return "";

    return `${first} ${last}`;
  };

  // Different displays (video vs avatar) should be shown on pip depending on whether the host is the dominant speaker
  const renderPip = () => {
    let isHostVideoEnabled = true;

    const host = getHost();
    if (!host) return children;

    const hostOnTwilio = twilioParticipants.find(u =>
      u.identity.includes(host.loginId)
    );
    if (hostOnTwilio) {
      const hostVideo = hostOnTwilio.videoTracks;

      if (hostVideo) {
        const hostVideoTracks = [...hostVideo].map(([key, value]) => value);

        // If the host video tracks map exists, we can assume the host will only be transmitting one video track
        if (hostVideoTracks) {
          isHostVideoEnabled = hostVideoTracks[0]?.track?.isEnabled!;
        }
      }
    } else if (currentUserLoginId === host?.loginId) {
      // host is the current user
      isHostVideoEnabled = mediaState.self.video === "unmute";
    }

    if (dominantSpeaker && isDominantSpeakerHost && isHostVideoEnabled) {
      return children;
    } else if (dominantSpeaker) {
      return <ActiveSpeaker name={getParticipantName()} />;
    } else if (!dominantSpeaker && !isHostVideoEnabled) {
      // if nobody is dominant speaker, show host avatar
      return (
        <ActiveSpeaker name={getParticipantName(true)} isSpeaking={false} />
      );
    } else {
      return children;
    }
  };

  return (
    <Draggable onStart={onStart} onStop={onStop} bounds="body">
      <div className="pip-media">{renderPip()}</div>
    </Draggable>
  );
};

const mapStateToProps = (state: AppState) => ({
  dominantSpeaker: state.twilio.dominantSpeaker,
  eventParticipants: state.meeting.multiPartyCallEventDetail?.participants,
  mpEventDetail: state.meeting.multiPartyCallEventDetail,
  mediaState: state.user.mediaState,
  twilioParticipants: state.twilio.participants,
  currentUserLoginId: state.user.identity.login_id
});
// ! FIX: ignoring as mapStateToPros is not assignable with the given types.
// @ts-ignore
export default connect<StateProps, undefined, {}, AppState>(mapStateToProps)(
  PipMedia
);
