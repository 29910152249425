import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
/* Components */
import ApprovalList from '../../Common/ApprovalList/ApprovalList';
import { getAllUserApplicationsAction } from '../../Common/ApprovalList/ApprovalListActions';
import HospitalApprovalTableRow from './Components/HospitalApprovalTableRow/HospitalApprovalTableRow';

const columns = [
	{
		title: 'Name',
		sortValue: 'name',
	},
	{
		title: 'User role',
		sortValue: 'role',
	},
	{
		title: 'Vendor',
		sortValue: 'brand',
	},
	{
		title: 'Request status',
		sortValue: 'hospital',
	},
];

class HospitalApprovalList extends PureComponent {
	render() {
		const { hospitalId, match } = this.props;

		return (
			<ApprovalList
				data={this.props.userApplications}
				onChangeUserStatusHandler={this.onChangeUserStatusHandler}
				tableRowComponent={HospitalApprovalTableRow}
				columns={columns}
				hospitalId={hospitalId || match.params.id}
				organizationType="hospital"
				getAllUserApplications={payload => this.props.getAllUserApplications(payload)}
			/>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	getAllUserApplications: payload =>
		dispatch(getAllUserApplicationsAction(payload)),
});

HospitalApprovalList.propTypes = {};

export default connect(null, mapDispatchToProps)(HospitalApprovalList);
