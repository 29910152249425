import { takeLatest, call, put } from 'redux-saga/effects';
import resources from '../../../../Common/Constants/resources';
import { notificationService } from '../../../../Common/Services';
import ApiHelper from '../../../../Common/Services/ApiHelper';
import { ORGANISATION } from './OrganisationActionTypes';

function* getAllOrganisations() {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', resources.organisations);
		yield put({
			type: ORGANISATION.SET_ALL_ORGANISATIONS,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

function* getAllStates() {
	try {
		const response = yield call(ApiHelper.get, 'coreregistry', 'address/states');
		yield put({
			type: ORGANISATION.SET_ALL_STATES,
			payload: response.content,
		});
	} catch (e) {
		yield notificationService.error(e);
	}
}

export default function* organisationsSaga() {
	yield takeLatest(ORGANISATION.GET_ALL_ORGANISATIONS, getAllOrganisations);
	yield takeLatest(ORGANISATION.GET_ALL_STATES, getAllStates);
}
