import TwilioVideo, { ConnectOptions } from "twilio-video";
import { ApiResponse } from "./types";

/**
 * Start a connection to Twilio call.
 * @returns Room
 * @example connectTwilioCall({ token, room })
 */
const connectTwilioCall = async ({
  token,
  options
}: {
  token: string;
  options: ConnectOptions;
}): Promise<ApiResponse<TwilioVideo.Room>> => {
  try {
    const twilioRoom = await TwilioVideo.connect(token, options);

    return { data: twilioRoom };
  } catch (error) {
    return { error };
  }
};

export default connectTwilioCall;
